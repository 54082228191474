<template>
  <div class="loader-img">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; display: block"
      width="91px"
      height="91px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="translate(50,50)">
        <circle
          cx="0"
          cy="0"
          r="8.333333333333334"
          fill="none"
          stroke="#e15b64"
          stroke-width="3.0000000000000004"
          stroke-dasharray="26.179938779914945 26.179938779914945"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0 0 0;360 0 0"
            times="0;1"
            dur="0.36101083032490977s"
            calcMode="spline"
            keySplines="0.2 0 0.8 1"
            begin="0"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
        <circle
          cx="0"
          cy="0"
          r="16.666666666666668"
          fill="none"
          stroke="#f47e60"
          stroke-width="3.0000000000000004"
          stroke-dasharray="52.35987755982989 52.35987755982989"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0 0 0;360 0 0"
            times="0;1"
            dur="0.36101083032490977s"
            calcMode="spline"
            keySplines="0.2 0 0.8 1"
            begin="-0.07220216606498195"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
        <circle
          cx="0"
          cy="0"
          r="25"
          fill="none"
          stroke="#f8b26a"
          stroke-width="3.0000000000000004"
          stroke-dasharray="78.53981633974483 78.53981633974483"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0 0 0;360 0 0"
            times="0;1"
            dur="0.36101083032490977s"
            calcMode="spline"
            keySplines="0.2 0 0.8 1"
            begin="-0.1444043321299639"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
        <circle
          cx="0"
          cy="0"
          r="33.333333333333336"
          fill="none"
          stroke="#abbd81"
          stroke-width="3.0000000000000004"
          stroke-dasharray="104.71975511965978 104.71975511965978"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0 0 0;360 0 0"
            times="0;1"
            dur="0.36101083032490977s"
            calcMode="spline"
            keySplines="0.2 0 0.8 1"
            begin="-0.21660649819494585"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
        <circle
          cx="0"
          cy="0"
          r="41.666666666666664"
          fill="none"
          stroke="#849b87"
          stroke-width="3.0000000000000004"
          stroke-dasharray="130.89969389957471 130.89969389957471"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0 0 0;360 0 0"
            times="0;1"
            dur="0.36101083032490977s"
            calcMode="spline"
            keySplines="0.2 0 0.8 1"
            begin="-0.2888086642599278"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "Loader",
};
</script>
<style scoped>
.loader-img {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
}
</style>
