<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-white border-bottom px-3 py-0"
  >
    <a class="navbar-brand" href="#">
      <img class="w-100 h-100" :src="logo" alt="Logo" />
      <h3 class="m-0 p-0">Sahastrabahu</h3>
      <!-- <img class="w-100 h-100" :src="logoName" alt="Logo name"> -->
    </a>
  </nav>
</template>

<script>
import Logo from "../../assets/logo-1.png";
import LogoName from "../../assets/logo-name.svg";
export default {
  data() {
    return {
      logo: Logo,
      logoName: LogoName,
    };
  },
};
</script>

<style scoped>
.navbar-brand {
  max-width: 200px;
  height: 75px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.navbar-brand h3 {
  color: #083b66;
  font-weight: 900;
}
</style>
