export const alphabet_L = [
  {
    word: "L",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>L </p>",
      },
    ],
  },
  {
    word: "L1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L1 <strong class='noun'> n. </strong> (also l) (pl. Ls or l's) 1 twelfth letter of the alphabet. 2 (as a roman numeral) 50.</p>",
      },
    ],
  },
  {
    word: "L2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L2 <strong class='sing'> abbr. </strong> (also l.) 1 learner driver. 2 lake.</p>",
      },
    ],
  },
  {
    word: "L",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L <strong class='sing'> abbr. </strong> (also l.) 1 left. 2 line. 3 litre(s).</p>",
      },
    ],
  },
  {
    word: "£",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>£ <strong class='sing'> abbr. </strong> Pound(s) (money). [latin libra]</p>",
      },
    ],
  },
  {
    word: "La",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>La <strong class='sing'> abbr. </strong> Los angeles.</p>",
      },
    ],
  },
  {
    word: "La",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>La <strong class='prep'> symb. </strong> Lanthanum.</p>",
      },
    ],
  },
  {
    word: "La",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>La <strong class='var'> var. </strong> Of *lah.</p>",
      },
    ],
  },
  {
    word: "Lab.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lab. <strong class='abbr'> Abbr. </strong> Labour.</p>",
      },
    ],
  },
  {
    word: "Lab",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lab <strong class='noun'> n. </strong> Colloq. Laboratory. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Label",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Label <strong class='noun'> —n. </strong> 1 piece of paper etc. Attached to an object to give information about it. 2 short classifying phrase applied to a person etc. 3 logo, title, or trademark of a company. —v. (-ll-; us -l-) 1 attach a label to. 2 (usu. Foll. By as) assign to a category. 3 replace (an atom) by an atom of a usu. Radioactive isotope as a means of identification. [french]</p>",
      },
    ],
  },
  {
    word: "Labial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labial <strong class='adj'> —adj.</strong> 1 a of the lips. B of, like, or serving as a lip. 2 (of a sound) requiring partial or complete closure of the lips. <strong class='noun'> —n. </strong> Labial sound (e.g. P, m, v). [latin labia lips]</p>",
      },
    ],
  },
  {
    word: "Labium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labium <strong class='noun'> n. </strong> (pl. Labia) (usu. In pl.) Each fold of skin of the two pairs enclosing the vulva. [latin, = lip]</p>",
      },
    ],
  },
  {
    word: "Labor  etc. ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labor etc.  <strong class='archaic_it_was'>Us & austral.</strong> Var. Of *labour etc.</p>",
      },
    ],
  },
  {
    word: "Laboratory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laboratory <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> room, building, or establishment for scientific experiments, research, chemical manufacture, etc. [latin: related to *laborious]</p>",
      },
    ],
  },
  {
    word: "Laborer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laborer <strong class='noun'> n. </strong> (brit. Labourer) person doing unskilled, usu. Manual, work for wages.</p>",
      },
    ],
  },
  {
    word: "Laborious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laborious <strong class='adj'> adj. </strong> 1 needing hard work or toil. 2 (esp. Of literary style) showing signs of toil. laboriously <strong class='adv'> adv. </strong> [latin: related to *labour]</p>",
      },
    ],
  },
  {
    word: "Labour ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labour  <strong class='archaic_it_was'>(Us & austral. Labor)</strong> <strong class='noun'> —n. </strong> 1 physical or mental work; exertion. 2 a workers, esp. Manual, considered as a political and economic force. B (labour) labour party. 3 process of childbirth. 4 particular task. —v. 1 work hard; exert oneself. 2 a elaborate needlessly (don't labour the point). B (as laboured adj.) Done with great effort; not spontaneous.</p>",
      },
    ],
  },
  {
    word: "Labour camp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labour camp <strong class='noun'> n. </strong> Prison camp enforcing a regime of hard labour.</p>",
      },
    ],
  },
  {
    word: "Labour day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labour day <strong class='noun'> n. </strong> May 1 (or in the us and canada the first monday in september), celebrated in honour of working people.</p>",
      },
    ],
  },
  {
    word: "Labourer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labourer <strong class='noun'> n. </strong> (us laborer) person doing unskilled, usu. Manual, work for wages.</p>",
      },
    ],
  },
  {
    word: "Labour exchange",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labour exchange <strong class='noun'> n. </strong> Colloq. Or hist. Employment exchange.</p>",
      },
    ],
  },
  {
    word: "Labour party",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labour party <strong class='noun'> n. </strong> Political party formed to represent the interests of working people.</p>",
      },
    ],
  },
  {
    word: "Labour-saving",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labour-saving <strong class='adj'> adj. </strong> Designed to reduce or eliminate work.</p>",
      },
    ],
  },
  {
    word: "Labrador",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labrador <strong class='noun'> n. </strong> Retriever of a breed with a black or golden coat. [labrador in canada]</p>",
      },
    ],
  },
  {
    word: "Laburnum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laburnum <strong class='noun'> n. </strong> Tree with drooping golden flowers yielding poisonous seeds. [latin]</p>",
      },
    ],
  },
  {
    word: "Labyrinth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Labyrinth <strong class='noun'> n. </strong> 1 complicated network of passages etc. 2 intricate or tangled arrangement. 3 the complex structure of the inner ear. labyrinthine adj. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Lac",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lac <strong class='noun'> n. </strong> Resinous substance secreted as a protective covering by a se asian insect. [hindustani]</p>",
      },
    ],
  },
  {
    word: "Lace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lace <strong class='noun'> —n. </strong> 1 fine open fabric or trimming, made by weaving thread in patterns. 2 cord etc. Passed through holes or hooks for fastening shoes etc. —v. (-cing) 1 (usu. Foll. By up) fasten or tighten with a lace or laces. 2 add spirits to (a drink). 3 (often foll. By through) pass (a shoelace etc.) Through. [latin laqueus noose]</p>",
      },
    ],
  },
  {
    word: "Lacerate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lacerate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 mangle or tear (esp. Flesh etc.). 2 cause pain to (the feelings etc.). laceration <strong class='noun'> n. </strong> [latin lacer torn]</p>",
      },
    ],
  },
  {
    word: "Lace-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lace-up <strong class='noun'> —n. </strong> Shoe fastened with a lace. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> (of a shoe etc.) Fastened by a lace or laces.</p>",
      },
    ],
  },
  {
    word: "Lachrymal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lachrymal <strong class='adj'> adj. </strong> (also lacrimal) of or for tears (lacrimal duct). [latin lacrima tear]</p>",
      },
    ],
  },
  {
    word: "Lachrymose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lachrymose <strong class='adj'> adj. </strong> Formal given to weeping; tearful.</p>",
      },
    ],
  },
  {
    word: "Lack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lack <strong class='noun'> —n. </strong> (usu. Foll. By of) want, deficiency. —v. Be without or deficient in. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Lackadaisical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lackadaisical <strong class='adj'> adj. </strong> Unenthusiastic; listless; idle. lackadaisically <strong class='adv'> adv. </strong> [from archaic lackaday]</p>",
      },
    ],
  },
  {
    word: "Lackey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lackey <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 servile follower; toady. 2 footman, manservant. [catalan alacay]</p>",
      },
    ],
  },
  {
    word: "Lacking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lacking <strong class='adj'> adj. </strong> Absent or deficient (money was lacking; is lacking in determination).</p>",
      },
    ],
  },
  {
    word: "Lackluster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lackluster <strong class='adj'> adj. </strong> (brit. Lacklustre) 1 lacking in vitality etc. 2 dull.</p>",
      },
    ],
  },
  {
    word: "Lacklustre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lacklustre <strong class='adj'> adj. </strong> (us lackluster) 1 lacking in vitality etc. 2 dull.</p>",
      },
    ],
  },
  {
    word: "Laconic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laconic <strong class='adj'> adj. </strong> Terse, using few words. laconically <strong class='adv'> adv. </strong> [greek lakon spartan]</p>",
      },
    ],
  },
  {
    word: "Lacquer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lacquer <strong class='noun'> —n. </strong> 1 varnish made of shellac or a synthetic substance. 2 substance sprayed on the hair to keep it in place. —v. Coat with lacquer. [french lacre *lac]</p>",
      },
    ],
  },
  {
    word: "Lacrimal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lacrimal <strong class='var'> var. </strong> Of *lachrymal.</p>",
      },
    ],
  },
  {
    word: "Lacrosse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lacrosse <strong class='noun'> n. </strong> Game like hockey, but with the ball carried in a crosse. [french la the, *crosse]</p>",
      },
    ],
  },
  {
    word: "Lactate1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lactate1 <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (of mammals) secrete milk. [as *lactation]</p>",
      },
    ],
  },
  {
    word: "Lactate2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lactate2 <strong class='noun'> n. </strong> Salt or ester of lactic acid.</p>",
      },
    ],
  },
  {
    word: "Lactation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lactation <strong class='noun'> n. </strong> 1 secretion of milk. 2 suckling. [latin: related to *lactic]</p>",
      },
    ],
  },
  {
    word: "Lacteal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lacteal <strong class='adj'> —adj.</strong> 1 of milk. 2 conveying chyle etc. <strong class='noun'> —n. </strong> (in pl.) Anat. Vessels which absorb fats. [latin lacteus: related to *lactic]</p>",
      },
    ],
  },
  {
    word: "Lactic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lactic <strong class='adj'> adj. </strong> Of milk. [latin lac lactis milk]</p>",
      },
    ],
  },
  {
    word: "Lactic acid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lactic acid <strong class='noun'> n. </strong> Acid formed esp. In sour milk.</p>",
      },
    ],
  },
  {
    word: "Lactose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lactose <strong class='noun'> n. </strong> Sugar that occurs in milk.</p>",
      },
    ],
  },
  {
    word: "Lacuna",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lacuna <strong class='noun'> n. </strong> (pl. Lacunae or -s) 1 gap. 2 missing portion etc., esp. In an ancient ms etc. [latin: related to *lake1]</p>",
      },
    ],
  },
  {
    word: "Lacy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lacy <strong class='adj'> adj. </strong> (-ier, -iest) of or resembling lace fabric.</p>",
      },
    ],
  },
  {
    word: "Lad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lad <strong class='noun'> n. </strong> 1 boy, youth. 2 <strong class='colloq'> colloq. </strong> Man. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Ladder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ladder <strong class='noun'> —n. </strong> 1 set of horizontal bars fixed between two uprights and used for climbing up or down. 2 vertical strip of unravelled stitching in a stocking etc. 3 hierarchical structure, esp. As a means of career advancement. —v. 1 cause a ladder in (a stocking etc.).</p>",
      },
    ],
  },
  {
    word: "Ladder-back",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ladder-back <strong class='noun'> n. </strong> Upright chair with a back resembling a ladder.</p>",
      },
    ],
  },
  {
    word: "Lade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lade <strong class='verb'> v. </strong> (-ding;  <strong class='archaic_it_was'>past part.</strong> Laden) 1 a load (a ship). B ship (goods). 2 (as laden adj.) (usu. Foll. By with) loaded, burdened. [old english]</p>",
      },
    ],
  },
  {
    word: "La-di-da",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>La-di-da <strong class='adj'> adj. </strong> Colloq. Pretentious or snobbish, esp. In manner or speech. [imitative]</p>",
      },
    ],
  },
  {
    word: "Ladies' man",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ladies' man <strong class='noun'> n. </strong> (also lady's man) man fond of female company.</p>",
      },
    ],
  },
  {
    word: "Ladle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ladle <strong class='noun'> —n. </strong> Deep long-handled spoon used for serving liquids. —v. (-ling) (often foll. By out) transfer (liquid) with a ladle. [old english]</p>",
      },
    ],
  },
  {
    word: "Lady",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lady <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a woman regarded as being of superior social status or as having refined manners. B (lady) title of peeresses, female relatives of peers, the wives and widows of knights, etc. 2 (often attrib.) Woman; female (ask that lady; lady butcher). 3 <strong class='colloq'> colloq. </strong> Wife, girlfriend. 4 ruling woman (lady of the house). 5 (the ladies or ladies') women's public lavatory. [old english, = loaf-kneader]</p>",
      },
    ],
  },
  {
    word: "Ladybird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ladybird <strong class='noun'> n. </strong> Small beetle, usu. Red with black spots.</p>",
      },
    ],
  },
  {
    word: "Lady chapel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lady chapel <strong class='noun'> n. </strong> Chapel dedicated to the virgin mary.</p>",
      },
    ],
  },
  {
    word: "Lady day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lady day <strong class='noun'> n. </strong> Feast of the annunciation, 25 mar.</p>",
      },
    ],
  },
  {
    word: "Lady-in-waiting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lady-in-waiting <strong class='noun'> n. </strong> Lady attending a queen or princess.</p>",
      },
    ],
  },
  {
    word: "Lady-killer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lady-killer <strong class='noun'> n. </strong> Habitual seducer of women.</p>",
      },
    ],
  },
  {
    word: "Ladylike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ladylike <strong class='adj'> adj. </strong> Like or befitting a lady.</p>",
      },
    ],
  },
  {
    word: "Ladyship",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ladyship <strong class='noun'> n. </strong> her (or your) ladyship respectful form of reference or address to a lady.</p>",
      },
    ],
  },
  {
    word: "Lady's slipper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lady's slipper <strong class='noun'> n. </strong> Plant of the orchid family with a slipper-shaped lip on its flowers.</p>",
      },
    ],
  },
  {
    word: "Lag1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lag1 <strong class='noun'> —v. </strong> <strong class='gg'> (-gg-) </strong> fall behind; not keep pace. <strong class='noun'> —n. </strong> Delay. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Lag2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lag2 <strong class='noun'> —v. </strong> <strong class='gg'> (-gg-) </strong> enclose in heat-insulating material. <strong class='noun'> —n. </strong> Insulating cover. [old norse]</p>",
      },
    ],
  },
  {
    word: "Lag3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lag3 <strong class='noun'> n. </strong> Slang habitual convict. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Lager",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lager <strong class='noun'> n. </strong> A kind of light effervescent beer. [german, = store]</p>",
      },
    ],
  },
  {
    word: "Lager lout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lager lout <strong class='noun'> n. </strong> Colloq. Youth behaving violently etc. As a result of excessive drinking.</p>",
      },
    ],
  },
  {
    word: "Laggard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laggard <strong class='noun'> n. </strong> Person who lags behind.</p>",
      },
    ],
  },
  {
    word: "Lagging",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lagging <strong class='noun'> n. </strong> Material used to lag a boiler etc. Against loss of heat.</p>",
      },
    ],
  },
  {
    word: "Lagoon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lagoon <strong class='noun'> n. </strong> Stretch of salt water separated from the sea by a sandbank, reef, etc. [latin *lacuna pool]</p>",
      },
    ],
  },
  {
    word: "Lah",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lah <strong class='noun'> n. </strong> (also la) <strong class='mus'> mus. </strong> Sixth note of a major scale. [latin labii, word arbitrarily taken]</p>",
      },
    ],
  },
  {
    word: "Laid  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laid  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>lay1.</p>",
      },
    ],
  },
  {
    word: "Laid-back",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laid-back <strong class='adj'> adj. </strong> Relaxed; easygoing.</p>",
      },
    ],
  },
  {
    word: "Laid paper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laid paper <strong class='noun'> n. </strong> Paper with the surface marked in fine ribs.</p>",
      },
    ],
  },
  {
    word: "Laid up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laid up <strong class='adj'> adj. </strong> Confined to bed or the house.</p>",
      },
    ],
  },
  {
    word: "Lain ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lain  <strong class='archaic_it_was'>past part. Of *</strong> lie1.</p>",
      },
    ],
  },
  {
    word: "Lair",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lair <strong class='noun'> n. </strong> 1 wild animal's resting-place. 2 person's hiding-place. [old english]</p>",
      },
    ],
  },
  {
    word: "Laird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laird <strong class='noun'> n. </strong> Scot. Landed proprietor. [from *lord]</p>",
      },
    ],
  },
  {
    word: "Laissez-faire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laissez-faire <strong class='noun'> n. </strong> (also laisser-faire) policy of non-interference. [french, = let act]</p>",
      },
    ],
  },
  {
    word: "Laity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laity <strong class='noun'> n. </strong> Lay people, as distinct from the clergy. [from *lay2]</p>",
      },
    ],
  },
  {
    word: "Lake1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lake1 <strong class='noun'> n. </strong> Large body of water surrounded by land. [latin lacus]</p>",
      },
    ],
  },
  {
    word: "Lake2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lake2 <strong class='noun'> n. </strong> 1 reddish pigment <strong class='archaic_it_was'> orig. </strong> Made from lac. 2 pigment obtained by combining an organic colouring matter with a metallic oxide, hydroxide, or salt. [var. Of *lac]</p>",
      },
    ],
  },
  {
    word: "Lake district",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lake district <strong class='noun'> n. </strong> (also the lakes) region of lakes in cumbria.</p>",
      },
    ],
  },
  {
    word: "Lakh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lakh <strong class='noun'> n. </strong> Ind. (usu. Foll. By of) hundred thousand (rupees etc.). [hindustani lakh]</p>",
      },
    ],
  },
  {
    word: "Lam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lam <strong class='verb'> v. </strong> (-mm-) slang thrash; hit. [perhaps scandinavian]</p>",
      },
    ],
  },
  {
    word: "Lama",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lama <strong class='noun'> n. </strong> Tibetan or mongolian buddhist monk. [tibetan]</p>",
      },
    ],
  },
  {
    word: "Lamasery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamasery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> monastery of lamas. [french]</p>",
      },
    ],
  },
  {
    word: "Lamb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamb <strong class='noun'> —n. </strong> 1 young sheep. 2 its flesh as food. 3 mild, gentle, or kind person. —v. Give birth to lambs. the lamb (or lamb of god) name for christ. [old english]</p>",
      },
    ],
  },
  {
    word: "Lambada",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lambada <strong class='noun'> n. </strong> Fast erotic brazilian dance in which couples dance with their stomachs touching each other. [portuguese, = a beating]</p>",
      },
    ],
  },
  {
    word: "Lambaste",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lambaste <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (also lambast) <strong class='colloq'> colloq. </strong> Thrash, beat. [from *lam, *baste1]</p>",
      },
    ],
  },
  {
    word: "Lambda",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lambda <strong class='noun'> n. </strong> Eleventh letter of the greek alphabet (l, l). [greek]</p>",
      },
    ],
  },
  {
    word: "Lambent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lambent <strong class='adj'> adj. </strong> 1 (of a flame or a light) playing on a surface. 2 (of the eyes, sky, wit, etc.) Lightly brilliant. lambency <strong class='noun'> n. </strong> [latin lambo lick]</p>",
      },
    ],
  },
  {
    word: "Lambswool",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lambswool <strong class='noun'> n. </strong> Soft fine wool from a young sheep.</p>",
      },
    ],
  },
  {
    word: "Lame",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lame <strong class='adj'> —adj.</strong> 1 disabled in the foot or leg. 2 a (of an excuse etc.) Unconvincing; feeble. B (of verse etc.) Halting. —v. (-ming) make lame; disable. lamely <strong class='adv'> adv. </strong> Lameness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Lamé",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamé <strong class='noun'> n. </strong> Fabric with gold or silver threads interwoven. [french]</p>",
      },
    ],
  },
  {
    word: "Lame duck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lame duck <strong class='noun'> n. </strong> Helpless person or firm.</p>",
      },
    ],
  },
  {
    word: "Lament",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lament <strong class='noun'> —n. </strong> 1 passionate expression of grief. 2 song etc. Of mourning etc. —v. (also absol.) 1 express or feel grief for or about. 2 (as lamented adj.) Used to refer to a recently dead person. lament for (or over) mourn or regret. [latin lamentor]</p>",
      },
    ],
  },
  {
    word: "Lamentable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamentable <strong class='adj'> adj. </strong> Deplorable, regrettable. lamentably adv.</p>",
      },
    ],
  },
  {
    word: "Lamentation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamentation <strong class='noun'> n. </strong> 1 lamenting. 2 lament.</p>",
      },
    ],
  },
  {
    word: "Lamina",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamina <strong class='noun'> n. </strong> (pl. -nae) thin plate or scale. laminar adj. [latin]</p>",
      },
    ],
  },
  {
    word: "Laminate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laminate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> 1 beat or roll into thin plates. 2 overlay with metal plates, a plastic layer, etc. 3 split into layers. <strong class='noun'> —n. </strong> Laminated structure, esp. Of layers fixed together. <strong class='adj'> —adj.</strong> In the form of thin plates. lamination n.</p>",
      },
    ],
  },
  {
    word: "Lammas",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lammas <strong class='noun'> n. </strong> (in full lammas day) first day of august, formerly kept as harvest festival. [old english: related to *loaf1, *mass2]</p>",
      },
    ],
  },
  {
    word: "Lamp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamp <strong class='noun'> n. </strong> 1 device for producing a steady light, esp.: a an electric bulb, and usu. Its holder. B an oil-lamp. C a gas-jet and mantle. 2 device producing esp. Ultraviolet or infrared radiation. [greek lampas torch]</p>",
      },
    ],
  },
  {
    word: "Lampblack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lampblack <strong class='noun'> n. </strong> Pigment made from soot.</p>",
      },
    ],
  },
  {
    word: "Lamplight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamplight <strong class='noun'> n. </strong> Light from a lamp.</p>",
      },
    ],
  },
  {
    word: "Lamplighter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamplighter <strong class='noun'> n. </strong> Hist. Person who lit street lamps.</p>",
      },
    ],
  },
  {
    word: "Lampoon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lampoon <strong class='noun'> —n. </strong> Satirical attack on a person etc. —v. Satirize. lampoonist <strong class='noun'> n. </strong> [french lampon]</p>",
      },
    ],
  },
  {
    word: "Lamppost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamppost <strong class='noun'> n. </strong> Tall post supporting a street-light.</p>",
      },
    ],
  },
  {
    word: "Lamprey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lamprey <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> eel-like aquatic animal with a sucker mouth. [latin lampreda]</p>",
      },
    ],
  },
  {
    word: "Lampshade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lampshade <strong class='noun'> n. </strong> Translucent cover for a lamp.</p>",
      },
    ],
  },
  {
    word: "Lancastrian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lancastrian <strong class='noun'> —n. </strong> 1 native of lancashire or lancaster. 2 hist. Member or supporter of the house of lancaster in the wars of the roses. <strong class='adj'> —adj.</strong> Of or concerning lancashire or lancaster, or the house of lancaster. [lancaster in lancashire]</p>",
      },
    ],
  },
  {
    word: "Lance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lance <strong class='noun'> —n. </strong> Long spear, esp. One used by a horseman. —v. (-cing) 1 prick or cut open with a lancet. 2 pierce with a lance. [french from latin]</p>",
      },
    ],
  },
  {
    word: "Lance-corporal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lance-corporal <strong class='noun'> n. </strong> Lowest rank of nco in the army.</p>",
      },
    ],
  },
  {
    word: "Lanceolate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lanceolate <strong class='adj'> adj. </strong> Shaped like a lance-head, tapering at each end.</p>",
      },
    ],
  },
  {
    word: "Lancer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lancer <strong class='noun'> n. </strong> 1 hist. Soldier of a cavalry regiment armed with lances. 2 (in pl.) A quadrille. B music for this.</p>",
      },
    ],
  },
  {
    word: "Lancet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lancet <strong class='noun'> n. </strong> Small broad two-edged surgical knife with a sharp point.</p>",
      },
    ],
  },
  {
    word: "Lancet arch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lancet arch <strong class='noun'> n. </strong> (also lancet light or window) narrow arch or window with a pointed head.</p>",
      },
    ],
  },
  {
    word: "Land",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Land <strong class='noun'> —n. </strong> 1 solid part of the earth's surface. 2 a expanse of country; ground, soil. B this in relation to its use, quality, etc., or as a basis for agriculture. 3 country, nation, state. 4 a landed property. B (in pl.) Estates. —v. 1 a set or go ashore. B (often foll. By at) disembark. 2 bring (an aircraft) to the ground or another surface. 3 alight on the ground etc. 4 bring (a fish) to land. 5 (also refl.; often foll. By up) <strong class='colloq'> colloq. </strong> Bring to, reach, or find oneself in a certain situation or place. 6 <strong class='colloq'> colloq. </strong> A deal (a person etc. A blow etc.). B (foll. By with) present (a person) with (a problem, job, etc.). 7 <strong class='colloq'> colloq. </strong> Win or obtain (a prize, job, etc.). how the land lies what is the state of affairs. Land on one's feet attain a good position, job, etc., by luck. landless adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Land-agent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Land-agent <strong class='noun'> n. </strong> 1 steward of an estate. 2 agent for the sale of estates.</p>",
      },
    ],
  },
  {
    word: "Landau",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landau <strong class='noun'> n. </strong> Four-wheeled enclosed carriage with a divided top. [landau in germany]</p>",
      },
    ],
  },
  {
    word: "Landed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landed <strong class='adj'> adj. </strong> 1 owning land. 2 consisting of land.</p>",
      },
    ],
  },
  {
    word: "Landfall",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landfall <strong class='noun'> n. </strong> Approach to land, esp. After a sea or air journey.</p>",
      },
    ],
  },
  {
    word: "Landfill",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landfill <strong class='noun'> n. </strong> 1 waste material etc. Used to landscape or reclaim land. 2 process of disposing of rubbish in this way.</p>",
      },
    ],
  },
  {
    word: "Land-girl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Land-girl <strong class='noun'> n. </strong> Woman doing farm work, esp. In wartime.</p>",
      },
    ],
  },
  {
    word: "Landing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landing <strong class='noun'> n. </strong> 1 platform at the top of or part way up a flight of stairs. 2 coming to land. 3 place where ships etc. Land.</p>",
      },
    ],
  },
  {
    word: "Landing-craft",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landing-craft <strong class='noun'> n. </strong> Craft designed for putting troops and equipment ashore.</p>",
      },
    ],
  },
  {
    word: "Landing-gear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landing-gear <strong class='noun'> n. </strong> Undercarriage of an aircraft.</p>",
      },
    ],
  },
  {
    word: "Landing-stage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landing-stage <strong class='noun'> n. </strong> Platform for disembarking goods and passengers.</p>",
      },
    ],
  },
  {
    word: "Landlady",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landlady <strong class='noun'> n. </strong> 1 woman who owns and lets land or premises. 2 woman who keeps a public house, boarding-house, etc.</p>",
      },
    ],
  },
  {
    word: "Land line",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Land line <strong class='noun'> n. </strong> Means of telecommunication over land.</p>",
      },
    ],
  },
  {
    word: "Landlocked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landlocked <strong class='adj'> adj. </strong> Almost or entirely enclosed by land.</p>",
      },
    ],
  },
  {
    word: "Landlord",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landlord <strong class='noun'> n. </strong> 1 man who owns and lets land or premises. 2 man who keeps a public house, boarding-house, etc.</p>",
      },
    ],
  },
  {
    word: "Landlubber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landlubber <strong class='noun'> n. </strong> Person unfamiliar with the sea.</p>",
      },
    ],
  },
  {
    word: "Landmark",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landmark <strong class='noun'> n. </strong> 1 conspicuous object in a district, landscape, etc. 2 prominent and critical event etc.</p>",
      },
    ],
  },
  {
    word: "Land mass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Land mass <strong class='noun'> n. </strong> Large area of land.</p>",
      },
    ],
  },
  {
    word: "Land-mine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Land-mine <strong class='noun'> n. </strong> Explosive mine laid in or on the ground.</p>",
      },
    ],
  },
  {
    word: "Landowner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landowner <strong class='noun'> n. </strong> Owner of (esp. Much) land. landowning adj. & n.</p>",
      },
    ],
  },
  {
    word: "Landscape",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landscape <strong class='noun'> —n. </strong> 1 scenery as seen in a broad view. 2 (often attrib.) Picture representing this; this genre of painting. —v. (-ping) improve (a piece of land) by landscape gardening. [dutch landscap]</p>",
      },
    ],
  },
  {
    word: "Landscape gardening",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landscape gardening <strong class='noun'> n. </strong> Laying out of grounds to resemble natural scenery.</p>",
      },
    ],
  },
  {
    word: "Landslide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landslide <strong class='noun'> n. </strong> 1 sliding down of a mass of land from a mountain, cliff, etc. 2 overwhelming victory in an election.</p>",
      },
    ],
  },
  {
    word: "Landslip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Landslip <strong class='noun'> n. </strong> = *landslide 1.</p>",
      },
    ],
  },
  {
    word: "Lane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lane <strong class='noun'> n. </strong> 1 narrow road. 2 division of a road for a stream of traffic. 3 strip of track etc. For a competitor in a race. 4 path regularly followed by a ship, aircraft, etc. 5 gangway between crowds of people. [old english]</p>",
      },
    ],
  },
  {
    word: "Language",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Language <strong class='noun'> n. </strong> 1 use of words in an agreed way as a method of human communication. 2 system of words of a particular community or country etc. 3 a faculty of speech. B style of expression; use of words, etc. (poetic language). 4 system of symbols and rules for writing computer programs. 5 any method of communication. 6 professional or specialized vocabulary. [latin lingua tongue]</p>",
      },
    ],
  },
  {
    word: "Language laboratory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Language laboratory <strong class='noun'> n. </strong> Room equipped with tape recorders etc. For learning a foreign language.</p>",
      },
    ],
  },
  {
    word: "Languid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Languid <strong class='adj'> adj. </strong> Lacking vigour; idle; inert. languidly <strong class='adv'> adv. </strong> [related to *languish]</p>",
      },
    ],
  },
  {
    word: "Languish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Languish <strong class='verb'> v. </strong> Lose or lack vitality. languish for droop or pine for. Languish under suffer under (depression, confinement, etc.). [latin langueo]</p>",
      },
    ],
  },
  {
    word: "Languor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Languor <strong class='noun'> n. </strong> 1 lack of energy; idleness. 2 soft or tender mood or effect. 3 oppressive stillness. languorous adj.</p>",
      },
    ],
  },
  {
    word: "Lank",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lank <strong class='adj'> adj. </strong> 1 (of hair, grass, etc.) Long and limp. 2 thin and tall. [old english]</p>",
      },
    ],
  },
  {
    word: "Lanky",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lanky <strong class='adj'> adj. </strong> (-ier, -iest) ungracefully thin and long or tall. lankiness n.</p>",
      },
    ],
  },
  {
    word: "Lanolin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lanolin <strong class='noun'> n. </strong> Fat found on sheep's wool and used in cosmetics etc. [latin lana wool, oleum *oil]</p>",
      },
    ],
  },
  {
    word: "Lantern",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lantern <strong class='noun'> n. </strong> 1 lamp with a transparent case protecting a flame etc. 2 raised structure on a dome, room, etc., glazed to admit light. 3 light-chamber of a lighthouse. [greek lampter torch]</p>",
      },
    ],
  },
  {
    word: "Lantern jaws",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lantern jaws <strong class='n_pl'> n.pl. </strong> Long thin jaws and chin.</p>",
      },
    ],
  },
  {
    word: "Lanthanide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lanthanide <strong class='noun'> n. </strong> Any element of the lanthanide series. [german: related to *lanthanum]</p>",
      },
    ],
  },
  {
    word: "Lanthanide series",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lanthanide series <strong class='noun'> n. </strong> Chem. Series of 15 metallic elements from lanthanum to lutetium in the periodic table, having similar chemical properties.</p>",
      },
    ],
  },
  {
    word: "Lanthanum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lanthanum <strong class='noun'> n. </strong> Metallic element, first of the lanthanide series. [greek lanthano escape notice]</p>",
      },
    ],
  },
  {
    word: "Lanyard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lanyard <strong class='noun'> n. </strong> 1 cord worn round the neck or the shoulder, to which a knife etc. May be attached. 2 <strong class='naut'> naut. </strong> Short rope or line used for securing, tightening, etc. [french laniere, assimilated to *yard1]</p>",
      },
    ],
  },
  {
    word: "Laodicean  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laodicean  <strong class='archaic_it_was'>half-hearted,</strong> esp. In religion or politics. [laodicea in asia minor (rev. 3:16)]</p>",
      },
    ],
  },
  {
    word: "Lap1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lap1 <strong class='noun'> n. </strong> 1 front of the body from the waist to the knees of a sitting person. 2 clothing covering this.  in the lap of the gods beyond human control. In the lap of luxury in extremely luxurious surroundings. [old english]</p>",
      },
    ],
  },
  {
    word: "Lap2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lap2 <strong class='noun'> —n. </strong> 1 a one circuit of a racetrack etc. B section of a journey etc. 2 a amount of overlapping. B overlapping part. 3 single turn of thread etc. Round a reel etc. —v. (-pp-) 1 lead or overtake (a competitor in a race) by one or more laps. 2 (often foll. By about, round) fold or wrap (a garment etc.) Round. 3 (usu. Foll. By in) enfold in wraps etc. 4 (as lapped adj.) (usu. Foll. By in) enfolded caressingly. 5 cause to overlap. [probably from *lap1]</p>",
      },
    ],
  },
  {
    word: "Lap3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lap3 <strong class='noun'> —v. </strong> (-pp-) 1 a (esp. Of an animal) drink with the tongue. B (usu. Foll. By up, down) consume (liquid) greedily. C (usu. Foll. By up) consume (gossip, praise, etc.) Greedily. 2 (of waves etc.) Ripple; make a lapping sound against (the shore). <strong class='noun'> —n. </strong> 1 a act of lapping. B amount of liquid taken up. 2 sound of wavelets. [old english]</p>",
      },
    ],
  },
  {
    word: "Lap-dog",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lap-dog <strong class='noun'> n. </strong> Small pet dog.</p>",
      },
    ],
  },
  {
    word: "Lapel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lapel <strong class='noun'> n. </strong> Part of either side of a coat-front etc., folded back against itself. [from *lap1]</p>",
      },
    ],
  },
  {
    word: "Lapidary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lapidary <strong class='adj'> —adj.</strong> 1 concerned with stone or stones. 2 engraved upon stone. 3 concise, well-expressed, epigrammatic. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> cutter, polisher, or engraver, of gems. [latin lapis lapid- stone]</p>",
      },
    ],
  },
  {
    word: "Lapis lazuli",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lapis lazuli <strong class='noun'> n. </strong> 1 blue mineral used as a gemstone. 2 bright blue pigment. 3 its colour. [related to *lapidary, *azure]</p>",
      },
    ],
  },
  {
    word: "Laplander",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laplander <strong class='noun'> n. </strong> Native or inhabitant of lapland; lapp. [as *lapp]</p>",
      },
    ],
  },
  {
    word: "Lap of honour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lap of honour <strong class='noun'> n. </strong> Ceremonial circuit of a racetrack etc. By a winner.</p>",
      },
    ],
  },
  {
    word: "Lapp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lapp <strong class='noun'> n. </strong> 1 member of a mongol people of <strong class='noun'> n. </strong> Scandinavia and nw russia. 2 their language. [swedish]</p>",
      },
    ],
  },
  {
    word: "Lappet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lappet <strong class='noun'> n. </strong> 1 small flap or fold of a garment etc. 2 hanging piece of flesh. [from *lap1]</p>",
      },
    ],
  },
  {
    word: "Lapse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lapse <strong class='noun'> —n. </strong> 1 slight error; slip of memory etc. 2 weak or careless decline into an inferior state. 3 (foll. By of) passage of time. —v. <strong class='sing'>(-sing)</strong> 1 fail to maintain a position or standard. 2 (foll. By into) fall back into an inferior or previous state. 3 (of a right or privilege etc.) Become invalid through disuse, failure to renew, etc. 4 (as lapsed adj.) That has lapsed. [latin lapsus from labor laps- slip]</p>",
      },
    ],
  },
  {
    word: "Laptop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laptop <strong class='noun'> n. </strong> (often attrib.) Portable microcomputer suitable for use while travelling.</p>",
      },
    ],
  },
  {
    word: "Lapwing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lapwing <strong class='noun'> n. </strong> Plover with a shrill cry. [old english: related to *leap, *wink: from its mode of flight]</p>",
      },
    ],
  },
  {
    word: "Larboard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Larboard <strong class='noun'> n. </strong> & adj. Archaic = *port3. [originally ladboard, perhaps ‘side on which cargo was taken in’: related to *lade]</p>",
      },
    ],
  },
  {
    word: "Larceny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Larceny <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> theft of personal property. larcenous adj. [anglo-french from latin latrocinium] <strong class='usage'> Usage:- </strong>in 1968 larceny was replaced as a statutory crime in english law by theft.</p>",
      },
    ],
  },
  {
    word: "Larch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Larch <strong class='noun'> n. </strong> 1 deciduous coniferous tree with bright foliage. 2 its wood. [latin larix -icis]</p>",
      },
    ],
  },
  {
    word: "Lard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lard <strong class='noun'> —n. </strong> Pig fat used in cooking etc. —v. 1 insert strips of fat or bacon in (meat etc.) Before cooking. 2 (foll. By with) garnish (talk etc.) With strange terms. [french = bacon, from latin lardum]</p>",
      },
    ],
  },
  {
    word: "Larder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Larder <strong class='noun'> n. </strong> Room or large cupboard for storing food.</p>",
      },
    ],
  },
  {
    word: "Lardy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lardy <strong class='adj'> adj. </strong> Like lard.</p>",
      },
    ],
  },
  {
    word: "Lardy-cake",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lardy-cake <strong class='noun'> n. </strong> Cake made with lard, currants, etc.</p>",
      },
    ],
  },
  {
    word: "Large",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Large <strong class='adj'> adj. </strong> 1 of relatively great size or extent. 2 of the larger kind (large intestine). 3 comprehensive. 4 pursuing an activity on a large scale (large farmer). at large 1 at liberty. 2 as a body or whole. 3 at full length, with all details. largeness <strong class='noun'> n. </strong> Largish adj. [latin largus copious]</p>",
      },
    ],
  },
  {
    word: "Large as life",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Large as life <strong class='adj'> adj. </strong> Colloq. In person, esp. Prominently.</p>",
      },
    ],
  },
  {
    word: "Largely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Largely <strong class='adv'> adv. </strong> To a great extent (largely my own fault).</p>",
      },
    ],
  },
  {
    word: "Large-scale",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Large-scale <strong class='adj'> adj. </strong> Made or occurring on a large scale.</p>",
      },
    ],
  },
  {
    word: "Largesse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Largesse <strong class='noun'> n. </strong> (also largess) money or gifts freely given. [latin largus: related to *large]</p>",
      },
    ],
  },
  {
    word: "Largo  mus.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Largo mus. <strong class='adv'> —adv. </strong> & adj. In a slow tempo and dignified style. <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> largo passage or movement. [italian, = broad]</p>",
      },
    ],
  },
  {
    word: "Lariat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lariat <strong class='noun'> n. </strong> 1 lasso. 2 tethering-rope. [spanish la reata]</p>",
      },
    ],
  },
  {
    word: "Lark1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lark1 <strong class='noun'> n. </strong> Small bird with a tuneful song, esp. The skylark. [old english]</p>",
      },
    ],
  },
  {
    word: "Lark2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lark2 <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> 1 frolic; amusing incident. 2 type of activity (fed up with this digging lark). —v. (foll. By about) play tricks. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Larkspur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Larkspur <strong class='noun'> n. </strong> Plant with a spur-shaped calyx.</p>",
      },
    ],
  },
  {
    word: "Larva",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Larva <strong class='noun'> n. </strong> (pl. -vae) stage of an insect's development between egg and pupa. larval adj. [latin, = ghost]</p>",
      },
    ],
  },
  {
    word: "Laryngeal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laryngeal <strong class='adj'> adj. </strong> Of the larynx.</p>",
      },
    ],
  },
  {
    word: "Laryngitis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laryngitis <strong class='noun'> n. </strong> Inflammation of the larynx.</p>",
      },
    ],
  },
  {
    word: "Larynx",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Larynx <strong class='noun'> n. </strong> (pl. Larynges or -xes) hollow organ in the throat holding the vocal cords. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Lasagne",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lasagne <strong class='noun'> n. </strong> Pasta in the form of sheets. [italian pl., from latin lasanum cooking-pot]</p>",
      },
    ],
  },
  {
    word: "Lascivious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lascivious <strong class='adj'> adj. </strong> 1 lustful. 2 inciting to lust. lasciviously <strong class='adv'> adv. </strong> [latin]</p>",
      },
    ],
  },
  {
    word: "Laser",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laser <strong class='noun'> n. </strong> Device that generates an intense beam of coherent light, or other electromagnetic radiation, in one direction. [light amplification by stimulated emission of radiation]</p>",
      },
    ],
  },
  {
    word: "Lash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lash <strong class='noun'> —v. </strong> 1 make a sudden whiplike movement. 2 beat with a whip etc. 3 (often foll. By against, down, etc.) (of rain etc.) Beat, strike. 4 criticize harshly. 5 rouse, incite. 6 (foll. By down, together, etc.) Fasten with a cord etc. <strong class='noun'> —n. </strong> 1 sharp blow made by a whip etc. 2 flexible end of a whip. 3 eyelash. lash out 1 speak or hit out angrily. 2 <strong class='colloq'> colloq. </strong> Spend money extravagantly. [imitative]</p>",
      },
    ],
  },
  {
    word: "Lashings",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lashings <strong class='n_pl'> n.pl. </strong> Colloq. (foll. By of) plenty.</p>",
      },
    ],
  },
  {
    word: "Lass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lass <strong class='noun'> n. </strong> Esp. Scot. & n.engl. Or poet. Girl. [old norse]</p>",
      },
    ],
  },
  {
    word: "Lassa fever",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lassa fever <strong class='noun'> n. </strong> Acute febrile viral disease of tropical africa. [lassa in nigeria]</p>",
      },
    ],
  },
  {
    word: "Lassitude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lassitude <strong class='noun'> n. </strong> 1 languor. 2 disinclination to exert oneself. [latin lassus tired]</p>",
      },
    ],
  },
  {
    word: "Lasso",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lasso <strong class='noun'> —n. </strong> (pl. -s or -es) rope with a noose at one end, esp. For catching cattle. —v. (-es, -ed) catch with a lasso. [spanish lazo: related to *lace]</p>",
      },
    ],
  },
  {
    word: "Last1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last1 <strong class='adj'> —adj.</strong> 1 after all others; coming at or belonging to the end. 2 most recent; next before a specified time (last christmas). 3 only remaining (last chance). 4 (prec. By the) least likely or suitable (the last person i'd want). 5 lowest in rank (last place). <strong class='adv'> —adv. </strong> 1 after all others (esp. In <strong class='n_pl'> comb. </strong> : last-mentioned). 2 on the most recent occasion (when did you last see him?). 3 lastly. <strong class='noun'> —n. </strong> 1 person or thing that is last, last-mentioned, most recent, etc. 2 (prec. By the) last mention or sight etc. (shall never hear the last of it). 3 last performance of certain acts (breathed his last). 4 (prec. By the) the end; death (fighting to the last). at last (or long last) in the end; after much delay. [old english, = latest]</p>",
      },
    ],
  },
  {
    word: "Last2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last2 <strong class='verb'> v. </strong> 1 remain unexhausted or alive for a specified or considerable time (food to last a week). 2 continue for a specified time (match lasts an hour). last out be strong enough or sufficient for the whole of a given period. [old english]</p>",
      },
    ],
  },
  {
    word: "Last3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last3 <strong class='noun'> n. </strong> Shoemaker's model for shaping a shoe etc. stick to one's last not meddle in what one does not understand. [old english]</p>",
      },
    ],
  },
  {
    word: "Last-ditch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last-ditch <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of an attempt etc.) Final, desperate.</p>",
      },
    ],
  },
  {
    word: "Lasting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lasting <strong class='adj'> adj. </strong> Permanent; durable.</p>",
      },
    ],
  },
  {
    word: "Lastly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lastly <strong class='adv'> adv. </strong> Finally; in the last place.</p>",
      },
    ],
  },
  {
    word: "Last minute",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last minute <strong class='noun'> n. </strong> (also last moment) the time just before an important event (often (with hyphen) attrib.: last-minute panic).</p>",
      },
    ],
  },
  {
    word: "Last name",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Last name <strong class='noun'> n. </strong> Surname.</p>",
      },
    ],
  },
  {
    word: "Last post",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last post <strong class='noun'> n. </strong> Bugle-call at military funerals or as a signal to retire for the night.</p>",
      },
    ],
  },
  {
    word: "Last rites",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last rites <strong class='n_pl'> n.pl. </strong> Rites for a person about to die.</p>",
      },
    ],
  },
  {
    word: "Last straw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last straw <strong class='noun'> n. </strong> (prec. By the) slight addition to a burden that makes it finally unbearable.</p>",
      },
    ],
  },
  {
    word: "Last trump",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last trump <strong class='noun'> n. </strong> (prec. By the) trumpet-blast to wake the dead on judgement day.</p>",
      },
    ],
  },
  {
    word: "Last word",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Last word <strong class='noun'> n. </strong> (prec. By the) 1 final or definitive statement. 2 (often foll. By in) latest fashion.</p>",
      },
    ],
  },
  {
    word: "Lat.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lat. <strong class='abbr'> Abbr. </strong> Latitude.</p>",
      },
    ],
  },
  {
    word: "Latch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latch <strong class='noun'> —n. </strong> 1 bar with a catch and lever as a fastening for a gate etc. 2 spring-lock preventing a door from being opened from the outside without a key. —v. Fasten with a latch. latch on <strong class='adj'> (often foll. By to) </strong> <strong class='colloq'> colloq. </strong> 1 attach oneself (to). 2 understand. On the latch fastened by the latch (sense 1) only. [old english]</p>",
      },
    ],
  },
  {
    word: "Latchkey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latchkey <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> key of an outer door.</p>",
      },
    ],
  },
  {
    word: "Late",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Late <strong class='adj'> —adj.</strong> 1 after the due or usual time; occurring or done after the proper time. 2 a far on in the day or night or in a specified period. B far on in development. 3 flowering or ripening towards the end of the season. 4 no longer alive; no longer having the specified status, former (my late husband; the late prime minister). 5 of recent date. <strong class='adv'> —adv. </strong> 1 after the due or usual time. 2 far on in time. 3 at or till a late hour. 4 at a late stage of development. 5 formerly but not now (late of the scillies). late in the day <strong class='colloq'> colloq. </strong> At a late stage in the proceedings. lateness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Latecomer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latecomer <strong class='noun'> n. </strong> Person who arrives late.</p>",
      },
    ],
  },
  {
    word: "Lateen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lateen <strong class='adj'> adj. </strong> (of a ship) rigged with a lateen sail. [french voile latine latin sail]</p>",
      },
    ],
  },
  {
    word: "Lateen sail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lateen sail <strong class='noun'> n. </strong> Triangular sail on a long yard at an angle of 45° to the mast.</p>",
      },
    ],
  },
  {
    word: "Lately",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lately <strong class='adv'> adv. </strong> Not long ago; recently. [old english: related to *late]</p>",
      },
    ],
  },
  {
    word: "Latent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latent <strong class='adj'> adj. </strong> Existing but not developed or manifest; concealed, dormant. latency <strong class='noun'> n. </strong> [latin lateo be hidden]</p>",
      },
    ],
  },
  {
    word: "Latent heat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latent heat <strong class='noun'> n. </strong> Physics heat required to convert a solid into a liquid or vapour, or a liquid into a vapour, without change of temperature.</p>",
      },
    ],
  },
  {
    word: "Lateral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lateral <strong class='adj'> —adj.</strong> 1 of, at, towards, or from the side or sides. 2 descended from the sibling of a person in direct line. <strong class='noun'> —n. </strong> Lateral shoot or branch. laterally <strong class='adv'> adv. </strong> [latin latus later- side]</p>",
      },
    ],
  },
  {
    word: "Lateral thinking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lateral thinking <strong class='noun'> n. </strong> Method of solving problems other than by using conventional logic.</p>",
      },
    ],
  },
  {
    word: "Latex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latex <strong class='noun'> n. </strong> (pl. -xes) 1 milky fluid of esp. The rubber tree. 2 synthetic product resembling this. [latin, = liquid]</p>",
      },
    ],
  },
  {
    word: "Lath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lath <strong class='noun'> n. </strong> (pl. Laths) thin flat strip of wood. [old english]</p>",
      },
    ],
  },
  {
    word: "Lathe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lathe <strong class='noun'> n. </strong> Machine for shaping wood, metal, etc., by rotating the article against cutting tools. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Lather",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lather <strong class='noun'> —n. </strong> 1 froth produced by agitating soap etc. And water. 2 frothy sweat. 3 state of agitation. —v. 1 (of soap etc.) Form a lather. 2 cover with lather. 3 <strong class='colloq'> colloq. </strong> Thrash. [old english]</p>",
      },
    ],
  },
  {
    word: "Latin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latin <strong class='noun'> —n. </strong> Language of ancient rome and its empire. <strong class='adj'> —adj.</strong> 1 of or in latin. 2 of the countries or peoples using languages descended from latin. 3 of the roman catholic church. [latin latium district around rome]</p>",
      },
    ],
  },
  {
    word: "Latin america",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latin america <strong class='noun'> n. </strong> Parts of central and s. America where spanish or portuguese is the main language.</p>",
      },
    ],
  },
  {
    word: "Latinate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latinate <strong class='adj'> adj. </strong> Having the character of latin.</p>",
      },
    ],
  },
  {
    word: "Latinize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latinize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) give a latin form to. latinization n.</p>",
      },
    ],
  },
  {
    word: "Latish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latish <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Fairly late.</p>",
      },
    ],
  },
  {
    word: "Latitude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latitude <strong class='noun'> n. </strong> 1 a angular distance on a meridian north or south of the equator. B (usu. In pl.) Regions or climes. 2 tolerated variety of action or opinion. latitudinal adj. [latin latus broad]</p>",
      },
    ],
  },
  {
    word: "Latitudinarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latitudinarian <strong class='adj'> —adj.</strong> Liberal, esp. In religion. <strong class='noun'> —n. </strong> Latitudinarian person.</p>",
      },
    ],
  },
  {
    word: "Latrine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latrine <strong class='noun'> n. </strong> Communal lavatory, esp. In a camp. [latin latrina]</p>",
      },
    ],
  },
  {
    word: "Latter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latter <strong class='adj'> adj. </strong> 1 a second-mentioned of two, or last-mentioned of three or more. B (prec. By the; usu. Absol.) The second- or last-mentioned person or thing. 2 nearer the end (latter part of the year). 3 recent. 4 of the end of a period, the world, etc. [old english, = later] <strong class='usage'> Usage:- </strong>the use of latter to mean ‘last mentioned of three or more’ is considered incorrect by some people.</p>",
      },
    ],
  },
  {
    word: "Latter-day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latter-day <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Modern, contemporary.</p>",
      },
    ],
  },
  {
    word: "Latter-day saints",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latter-day saints <strong class='n_pl'> n.pl. </strong> Mormons' name for themselves.</p>",
      },
    ],
  },
  {
    word: "Latterly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latterly <strong class='adv'> adv. </strong> 1 recently. 2 in the latter part of life or a period.</p>",
      },
    ],
  },
  {
    word: "Lattice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lattice <strong class='noun'> n. </strong> 1 structure of crossed laths or bars with spaces between, used as a screen, fence, etc. 2 regular periodic arrangement of atoms, ions, or molecules. latticed adj. [french lattis from latte *lath]</p>",
      },
    ],
  },
  {
    word: "Lattice window",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lattice window <strong class='noun'> n. </strong> Window with small panes set in diagonally crossing strips of lead.</p>",
      },
    ],
  },
  {
    word: "Latvian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Latvian <strong class='noun'> —n. </strong> 1 a native or national of latvia in eastern europe. B person of latvian descent. 2 language of latvia. <strong class='adj'> —adj.</strong> Of latvia, its people, or language.</p>",
      },
    ],
  },
  {
    word: "Laud",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laud <strong class='noun'> —v. </strong> Praise or extol. <strong class='noun'> —n. </strong> 1 praise; hymn of praise. 2 (in pl.) The first morning prayer of the roman catholic church. [latin laus laud-]</p>",
      },
    ],
  },
  {
    word: "Laudable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laudable <strong class='adj'> adj. </strong> Commendable. laudability <strong class='noun'> n. </strong> Laudably adv. <strong class='usage'> Usage:- </strong>laudable is sometimes confused with laudatory.</p>",
      },
    ],
  },
  {
    word: "Laudanum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laudanum <strong class='noun'> n. </strong> Solution prepared from opium. [perhaps from medieval latin]</p>",
      },
    ],
  },
  {
    word: "Laudatory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laudatory <strong class='adj'> adj. </strong> Praising. <strong class='usage'> Usage:- </strong>laudatory is sometimes confused with laudable.</p>",
      },
    ],
  },
  {
    word: "Laugh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laugh <strong class='noun'> —v. </strong> 1 make the sounds and movements usual in expressing lively amusement, scorn, etc. 2 express by laughing. 3 (foll. By at) ridicule, make fun of. <strong class='noun'> —n. </strong> 1 sound, act, or manner of laughing. 2 <strong class='colloq'> colloq. </strong> Comical thing. laugh off get rid of (embarrassment or humiliation) by joking. Laugh up one's sleeve laugh secretly. [old english]</p>",
      },
    ],
  },
  {
    word: "Laughable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laughable <strong class='adj'> adj. </strong> Ludicrous; amusing. laughably adv.</p>",
      },
    ],
  },
  {
    word: "Laughing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laughing <strong class='noun'> n. </strong> Laughter. no laughing matter serious matter. laughingly adv.</p>",
      },
    ],
  },
  {
    word: "Laughing-gas",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laughing-gas <strong class='noun'> n. </strong> Nitrous oxide as an anaesthetic.</p>",
      },
    ],
  },
  {
    word: "Laughing jackass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laughing jackass <strong class='noun'> n. </strong> = *kookaburra.</p>",
      },
    ],
  },
  {
    word: "Laughing stock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laughing stock <strong class='noun'> n. </strong> Person or thing open to general ridicule.</p>",
      },
    ],
  },
  {
    word: "Laughter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laughter <strong class='noun'> n. </strong> Act or sound of laughing. [old english]</p>",
      },
    ],
  },
  {
    word: "Launch1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Launch1 <strong class='noun'> —v. </strong> 1 set (a vessel) afloat. 2 hurl or send forth (a weapon, rocket, etc.). 3 start or set in motion (an enterprise, person, etc.). 4 formally introduce (a new product) with publicity etc. 5 (foll. By out, into, etc.) A make a start on (an enterprise etc.). B burst into (strong language etc.). <strong class='noun'> —n. </strong> Act of launching. [anglo-norman launcher: related to *lance]</p>",
      },
    ],
  },
  {
    word: "Launch2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Launch2 <strong class='noun'> n. </strong> 1 large motor boat. 2 man-of-war's largest boat. [spanish lancha]</p>",
      },
    ],
  },
  {
    word: "Launcher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Launcher <strong class='noun'> n. </strong> Structure to hold a rocket during launching.</p>",
      },
    ],
  },
  {
    word: "Launch pad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Launch pad <strong class='noun'> n. </strong> (also launching pad) platform with a supporting structure, for launching rockets from.</p>",
      },
    ],
  },
  {
    word: "Launder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Launder <strong class='verb'> v. </strong> 1 wash and iron (clothes etc.). 2 <strong class='colloq'> colloq. </strong> Transfer (funds) to conceal their origin. [french: related to *lave]</p>",
      },
    ],
  },
  {
    word: "Launderette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Launderette <strong class='noun'> n. </strong> (also laundrette) establishment with coin-operated washing-machines and driers for public use.</p>",
      },
    ],
  },
  {
    word: "Laundress",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laundress <strong class='noun'> n. </strong> Woman who launders, esp. Professionally.</p>",
      },
    ],
  },
  {
    word: "Laundry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laundry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a place for washing clothes etc. B firm washing clothes etc. Commercially. 2 clothes or linen for laundering or newly laundered.</p>",
      },
    ],
  },
  {
    word: "Laureate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laureate <strong class='adj'> —adj.</strong> Wreathed with laurel as a mark of honour. <strong class='noun'> —n. </strong> = *poet laureate. laureateship <strong class='noun'> n. </strong> [related to *laurel]</p>",
      },
    ],
  },
  {
    word: "Laurel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laurel <strong class='noun'> n. </strong> 1 = *bay2. 2 (in sing. Or pl.) Wreath of bay-leaves as an emblem of victory or poetic merit. 3 any of various plants with dark-green glossy leaves. look to one's laurels beware of losing one's pre-eminence. Rest on one's laurels see *rest1. [latin laurus bay]</p>",
      },
    ],
  },
  {
    word: "Lav",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lav <strong class='noun'> n. </strong> Colloq. Lavatory. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Lava",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lava <strong class='noun'> n. </strong> Matter flowing from a volcano and solidifying as it cools. [latin lavo wash]</p>",
      },
    ],
  },
  {
    word: "Lavatorial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lavatorial <strong class='adj'> adj. </strong> Of or like lavatories; (esp. Of humour) relating to excretion.</p>",
      },
    ],
  },
  {
    word: "Lavatory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lavatory <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 receptacle for urine and faeces, usu. With a means of disposal. 2 room or compartment containing this. [latin: related to *lava]</p>",
      },
    ],
  },
  {
    word: "Lavatory paper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lavatory paper <strong class='noun'> n. </strong> = *toilet paper.</p>",
      },
    ],
  },
  {
    word: "Lave",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lave <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> literary 1 wash, bathe. 2 (of water) wash against; flow along. [latin lavo wash]</p>",
      },
    ],
  },
  {
    word: "Lavender",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lavender <strong class='noun'> n. </strong> 1 a evergreen shrub with purple aromatic flowers. B its flowers and stalks dried and used to scent linen etc. 2 pale mauve colour. [latin lavandula]</p>",
      },
    ],
  },
  {
    word: "Lavender-water",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lavender-water <strong class='noun'> n. </strong> Light perfume made with distilled lavender.</p>",
      },
    ],
  },
  {
    word: "Laver",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laver <strong class='noun'> n. </strong> Edible seaweed. [latin]</p>",
      },
    ],
  },
  {
    word: "Lavish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lavish <strong class='adj'> —adj.</strong> 1 giving or producing in large quantities; profuse. 2 generous. —v. (often foll. By on) bestow or spend (money, effort, praise, etc.) Abundantly. lavishly <strong class='adv'> adv. </strong> [french lavasse deluge: related to *lave]</p>",
      },
    ],
  },
  {
    word: "Law",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Law <strong class='noun'> n. </strong> 1 a rule enacted or customary in a community and recognized as commanding or forbidding certain actions. B body of such rules. 2 controlling influence of laws; respect for laws. 3 laws collectively as a social system or subject of study. 4 binding force (her word is law). 5 (prec. By the) a the legal profession. B <strong class='colloq'> colloq. </strong> The police. 6 (in pl.) Jurisprudence. 7 a the judicial remedy. B the lawcourts as providing this (go to law). 8 rule of action or procedure. 9 regularity in natural occurrences (laws of nature; law of gravity). 10 divine commandments. be a law unto oneself do what one considers right; disregard custom. Lay down the law be dogmatic or authoritarian. Take the law into one's own hands redress a grievance by one's own means, esp. By force. [old english from old norse, = thing laid down]</p>",
      },
    ],
  },
  {
    word: "Law-abiding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Law-abiding <strong class='adj'> adj. </strong> Obedient to the laws.</p>",
      },
    ],
  },
  {
    word: "Lawbreaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawbreaker <strong class='noun'> n. </strong> Person who breaks the law. lawbreaking <strong class='noun'> n. </strong> & adj.</p>",
      },
    ],
  },
  {
    word: "Lawcourt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawcourt <strong class='noun'> n. </strong> Court of law.</p>",
      },
    ],
  },
  {
    word: "Lawful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawful <strong class='adj'> adj. </strong> Conforming with or recognized by law; not illegal. lawfully <strong class='adv'> adv. </strong> Lawfulness n.</p>",
      },
    ],
  },
  {
    word: "Lawgiver",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawgiver <strong class='noun'> n. </strong> Person who formulates laws; legislator.</p>",
      },
    ],
  },
  {
    word: "Lawless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawless <strong class='adj'> adj. </strong> 1 having no laws or law enforcement. 2 disregarding laws. lawlessness n.</p>",
      },
    ],
  },
  {
    word: "Law lord",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Law lord <strong class='noun'> n. </strong> Member of the house of lords qualified to perform its legal work.</p>",
      },
    ],
  },
  {
    word: "Lawmaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawmaker <strong class='noun'> n. </strong> Legislator.</p>",
      },
    ],
  },
  {
    word: "Lawn1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawn1 <strong class='noun'> n. </strong> Piece of closely-mown grass in a garden etc. [french launde glade]</p>",
      },
    ],
  },
  {
    word: "Lawn2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawn2 <strong class='noun'> n. </strong> Fine linen or cotton. [probably from laon in france]</p>",
      },
    ],
  },
  {
    word: "Lawnmower",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawnmower <strong class='noun'> n. </strong> Machine for cutting lawns.</p>",
      },
    ],
  },
  {
    word: "Lawn tennis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawn tennis <strong class='noun'> n. </strong> Tennis played with a soft ball on outdoor grass or a hard court.</p>",
      },
    ],
  },
  {
    word: "Lawrencium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawrencium <strong class='noun'> n. </strong> Artificially made transuranic metallic element. [lawrence, name of a physicist]</p>",
      },
    ],
  },
  {
    word: "Lawsuit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawsuit <strong class='noun'> n. </strong> Bringing of a dispute, claim, etc. Before a lawcourt.</p>",
      },
    ],
  },
  {
    word: "Lawyer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lawyer <strong class='noun'> n. </strong> Legal practitioner, esp. A solicitor.</p>",
      },
    ],
  },
  {
    word: "Lax",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lax <strong class='adj'> adj. </strong> 1 lacking care or precision. 2 not strict. laxity <strong class='noun'> n. </strong> Laxly <strong class='adv'> adv. </strong> Laxness <strong class='noun'> n. </strong> [latin laxus loose]</p>",
      },
    ],
  },
  {
    word: "Laxative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laxative <strong class='adj'> —adj.</strong> Facilitating evacuation of the bowels. <strong class='noun'> —n. </strong> Laxative medicine. [latin: related to *lax]</p>",
      },
    ],
  },
  {
    word: "Lay1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lay1 <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Laid) 1 place on a surface, esp. Horizontally or in the proper or specified place. 2 put or bring into the required position or state (lay carpet). 3 make by laying (lay foundations). 4 (often absol.) (of a hen bird) produce (an egg). 5 cause to subside or lie flat. 6 (usu. Foll. By on); attribute or impute (blame etc.). 7 prepare or make ready (a plan or trap). 8 prepare (a table) for a meal. 9 arrange the material for (a fire). 10 put down as a wager; stake. 11 (foll. By with) coat or strew (a surface). 12 slang offens. Have sexual intercourse with (esp. A woman). <strong class='noun'> —n. </strong> 1 way, position, or direction in which something lies. 2 slang offens. Partner (esp. Female) in, or act of, sexual intercourse. lay about one hit out on all sides. Lay aside 1 put to one side. 2 cease to consider. Lay at the door of impute to. Lay bare expose, reveal. Lay claim to claim as one's own. Lay down 1 put on a flat surface. 2 give up (an office). 3 formulate (a rule). 4 store (wine) for maturing. 5 sacrifice (one's life). Lay (one's) hands on obtain, locate. Lay hands on seize or attack. Lay hold of seize. Lay in provide oneself with a stock of. Lay into <strong class='colloq'> colloq. </strong> Punish or scold harshly. Lay it on thick (or with a trowel) <strong class='colloq'> colloq. </strong> Flatter or exaggerate grossly. Lay low overthrow or humble. Lay off 1 discharge (unneeded workers) temporarily; make redundant. 2 <strong class='colloq'> colloq. </strong> Desist. Lay on 1 provide. 2 impose. 3 inflict (blows). 4 spread on (paint etc.). Lay open 1 break the skin of. 2 (foll. By to) expose (to criticism etc.). Lay out 1 spread out, expose to view. 2 prepare (a corpse) for burial. 3 <strong class='colloq'> colloq. </strong> Knock unconscious. 4 arrange (grounds etc.) According to a design.</p>",
      },
    ],
  },
  {
    word: "Lay2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lay2 <strong class='adj'> adj. </strong> 1 a non-clerical. B not ordained into the clergy. 2 a not professionally qualified. B of or done by such persons. [greek laos people]</p>",
      },
    ],
  },
  {
    word: "Lay3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lay3 <strong class='noun'> n. </strong> 1 short poem meant to be sung. 2 song. [french]</p>",
      },
    ],
  },
  {
    word: "Lay4  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lay4  <strong class='slang_v'>past of *</strong> lie1.</p>",
      },
    ],
  },
  {
    word: "Layabout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Layabout <strong class='noun'> n. </strong> Habitual loafer or idler.</p>",
      },
    ],
  },
  {
    word: "Lay-by",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lay-by <strong class='noun'> n. </strong> (pl. -bys) area at the side of a road where vehicles may stop.</p>",
      },
    ],
  },
  {
    word: "Layer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Layer <strong class='noun'> —n. </strong> 1 thickness of matter, esp. One of several, covering a surface. 2 person or thing that lays. 3 hen that lays eggs. 4 shoot fastened down to take root while attached to the parent plant. —v. 1 arrange in layers. 2 cut (hair) in layers. 3 propagate (a plant) by a layer.</p>",
      },
    ],
  },
  {
    word: "Layette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Layette <strong class='noun'> n. </strong> Set of clothing etc. For a newborn child. [french from dutch]</p>",
      },
    ],
  },
  {
    word: "Lay figure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lay figure <strong class='noun'> n. </strong> 1 jointed figure of a human body used by artists for arranging drapery on etc. 2 unrealistic character in a novel etc. [dutch led joint]</p>",
      },
    ],
  },
  {
    word: "Layman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Layman <strong class='noun'> n. </strong> (fem. Laywoman) 1 non-ordained member of a church. 2 person without professional or specialized knowledge.</p>",
      },
    ],
  },
  {
    word: "Lay-off",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lay-off <strong class='noun'> n. </strong> Temporary discharge of workers; a redundancy.</p>",
      },
    ],
  },
  {
    word: "Layout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Layout <strong class='noun'> n. </strong> 1 way in which land, a building, printed matter, etc., is arranged or set out. 2 something arranged in a particular way; display.</p>",
      },
    ],
  },
  {
    word: "Lay reader",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lay reader <strong class='noun'> n. </strong> Lay person licensed to conduct some religious services.</p>",
      },
    ],
  },
  {
    word: "Laze",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Laze <strong class='noun'> —v. </strong> (-zing) 1 spend time idly. 2 (foll. By away) pass (time) idly. <strong class='noun'> —n. </strong> Spell of lazing. [back-formation from *lazy]</p>",
      },
    ],
  },
  {
    word: "Lazy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lazy <strong class='adj'> adj. </strong> (-ier, -iest) 1 disinclined to work, doing little work. 2 of or inducing idleness. lazily <strong class='adv'> adv. </strong> Laziness <strong class='noun'> n. </strong> [perhaps from low german]</p>",
      },
    ],
  },
  {
    word: "Lazybones",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lazybones <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> <strong class='colloq'> colloq. </strong> Lazy person.</p>",
      },
    ],
  },
  {
    word: "Lb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lb <strong class='sing'> abbr. </strong> Pound(s) (weight). [latin libra]</p>",
      },
    ],
  },
  {
    word: "Lbc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lbc <strong class='sing'> abbr. </strong> London broadcasting company.</p>",
      },
    ],
  },
  {
    word: "L.b.w.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L.b.w. <strong class='abbr'> Abbr. </strong> Leg before wicket.</p>",
      },
    ],
  },
  {
    word: "L.c.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L.c. <strong class='abbr'> Abbr. </strong> 1 = *loc. Cit. 2 lower case.</p>",
      },
    ],
  },
  {
    word: "Lcd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lcd <strong class='sing'> abbr. </strong> 1 liquid crystal display. 2 lowest (or least) common denominator.</p>",
      },
    ],
  },
  {
    word: "Lcm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lcm <strong class='sing'> abbr. </strong> Lowest (or least) common multiple.</p>",
      },
    ],
  },
  {
    word: "L/cpl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L/cpl <strong class='sing'> abbr. </strong> Lance-corporal.</p>",
      },
    ],
  },
  {
    word: "Ld.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ld. <strong class='abbr'> Abbr. </strong> Lord.</p>",
      },
    ],
  },
  {
    word: "Lea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lea <strong class='sing'> abbr. </strong> Local education authority.</p>",
      },
    ],
  },
  {
    word: "Lea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lea <strong class='noun'> n. </strong> Poet. Meadow, field. [old english]</p>",
      },
    ],
  },
  {
    word: "Leach",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leach <strong class='verb'> v. </strong> 1 make (a liquid) percolate through some material. 2 subject (bark, ore, ash, or soil) to the action of percolating fluid. 3 (foll. By away, out) remove (soluble matter) or be removed in this way. [old english]</p>",
      },
    ],
  },
  {
    word: "Lead1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lead1 <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Led) 1 cause to go with one, esp. By guiding or going in front. 2 a direct the actions or opinions of. B (often foll. By to, or to + infin.) Guide by persuasion or example (what led you to think that). 3 (also absol.) Provide access to; bring to a certain position (gate leads you into a field; road leads to lincoln). 4 pass or go through (a life etc. Of a specified kind). 5 a have the first place in. B (absol.) Go first; be ahead in a race etc. C (absol.) Be pre-eminent in some field. 6 be in charge of (leads a team). 7 (also absol.) Play (a card) or a card of (a particular suit) as first player in a round. 8 (foll. By to) result in. 9 (foll. By with) (of a newspaper or news broadcast) have as its main story (led with the royal wedding). 10 (foll. By through) make (a liquid, strip of material, etc.) Pass through a certain course. <strong class='noun'> —n. </strong> 1 guidance given by going in front; example. 2 a leading place (take the lead). B amount by which a competitor is ahead of the others. 3 clue. 4 strap etc. For leading a dog etc. 5 conductor (usu. A wire) conveying electric current to an appliance. 6 a chief part in a play etc. B person playing this. C (attrib.) Chief performer or instrument of a specified type (lead guitar). 7 cards a act or right of playing first. B card led. lead by the nose cajole into compliance. Lead off begin. Lead on entice dishonestly. Lead up the garden path <strong class='colloq'> colloq. </strong> Mislead. Lead up to form a preparation for; direct conversation towards. [old english]</p>",
      },
    ],
  },
  {
    word: "Lead2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lead2 <strong class='noun'> —n. </strong> 1 heavy bluish-grey soft metallic element. 2 a graphite. B thin length of this in a pencil. 3 lump of lead used in sounding water. 4 (in pl.) A strips of lead covering a roof. B piece of lead-covered roof. 5 (in pl.) Lead frames holding the glass of a lattice etc. 6 blank space between lines of print. —v. 1 cover, weight, or frame with lead. 2 space (printed matter) with leads. [old english]</p>",
      },
    ],
  },
  {
    word: "Leaden",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leaden <strong class='adj'> adj. </strong> 1 of or like lead. 2 heavy or slow. 3 lead-coloured.</p>",
      },
    ],
  },
  {
    word: "Leader",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leader <strong class='noun'> n. </strong> 1 a person or thing that leads. B person followed by others. 2 principal player in a music group or of the first violins in an orchestra. 3 = *leading article. 4 shoot of a plant at the apex of a stem or of the main branch. leadership n.</p>",
      },
    ],
  },
  {
    word: "Lead-free",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lead-free <strong class='adj'> adj. </strong> (of petrol) without added lead compounds.</p>",
      },
    ],
  },
  {
    word: "Lead-in",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lead-in <strong class='noun'> n. </strong> Introduction, opening, etc.</p>",
      },
    ],
  },
  {
    word: "Leading1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leading1 <strong class='adj'> adj. </strong> Chief; most important.</p>",
      },
    ],
  },
  {
    word: "Leading2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leading2 <strong class='noun'> n. </strong> Printing = *lead2 <strong class='noun'> n. </strong> 6.</p>",
      },
    ],
  },
  {
    word: "Leading aircraftman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leading aircraftman <strong class='noun'> n. </strong> Rank above aircraftman in the raf.</p>",
      },
    ],
  },
  {
    word: "Leading article",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leading article <strong class='noun'> n. </strong> Newspaper article giving editorial opinion.</p>",
      },
    ],
  },
  {
    word: "Leading light",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leading light <strong class='noun'> n. </strong> Prominent and influential person.</p>",
      },
    ],
  },
  {
    word: "Leading note",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leading note <strong class='noun'> n. </strong> Mus. Seventh note of a diatonic scale.</p>",
      },
    ],
  },
  {
    word: "Leading question",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leading question <strong class='noun'> n. </strong> Question prompting the answer wanted. <strong class='usage'> Usage:- </strong>leading question does not mean a ‘principal’ or ‘loaded’ or ‘searching’ question.</p>",
      },
    ],
  },
  {
    word: "Lead pencil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lead pencil <strong class='noun'> n. </strong> Pencil of graphite in wood.</p>",
      },
    ],
  },
  {
    word: "Lead-poisoning",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lead-poisoning <strong class='noun'> n. </strong> Poisoning by absorption of lead into the body.</p>",
      },
    ],
  },
  {
    word: "Leaf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leaf <strong class='noun'> —n. </strong> (pl. Leaves) 1 each of several flattened usu. Green structures of a plant, growing usu. On the side of a stem. 2 a foliage regarded collectively. B state of bearing leaves (tree in leaf). 3 single thickness of paper. 4 very thin sheet of metal etc. 5 hinged part, extra section, or flap of a table etc. —v. 1 put forth leaves. 2 (foll. By through) turn over the pages of (a book etc.). leafage <strong class='noun'> n. </strong> Leafy adj. (-ier, -iest). [old english]</p>",
      },
    ],
  },
  {
    word: "Leaflet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leaflet <strong class='noun'> —n. </strong> 1 sheet of paper, pamphlet, etc. Giving information. 2 young leaf. 3 bot. Division of a compound leaf. —v. (-t-) distribute leaflets (to).</p>",
      },
    ],
  },
  {
    word: "Leaf-mould",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leaf-mould <strong class='noun'> n. </strong> Soil or compost consisting chiefly of decayed leaves.</p>",
      },
    ],
  },
  {
    word: "Leaf-stalk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leaf-stalk <strong class='noun'> n. </strong> Stalk joining a leaf to a stem.</p>",
      },
    ],
  },
  {
    word: "League1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>League1 <strong class='noun'> —n. </strong> 1 people, countries, groups, etc., combining for a particular purpose. 2 agreement to combine in this way. 3 group of sports clubs which compete for a championship. 4 class of contestants etc. —v. (-gues, -gued, -guing) (often foll. By together) join in a league. in league allied, conspiring. [latin ligo bind]</p>",
      },
    ],
  },
  {
    word: "League2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>League2 <strong class='noun'> n. </strong> Hist. Varying measure of distance, usu. About three miles. [latin from celtic]</p>",
      },
    ],
  },
  {
    word: "League table",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>League table <strong class='noun'> n. </strong> List in ranked order of success etc.</p>",
      },
    ],
  },
  {
    word: "Leak",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leak <strong class='noun'> —n. </strong> 1 a hole through which matter passes accidentally in or out. B matter passing through thus. C act of passing through thus. 2 a similar escape of electrical charge. B charge that escapes. 3 disclosure of secret information. —v. 1 a pass through a leak. B lose or admit through a leak. 2 disclose (secret information). 3 (often foll. By out) become known. have (or take) a leak slang urinate. leaky adj. (-ier, -iest). [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Leakage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leakage <strong class='noun'> n. </strong> Action or result of leaking.</p>",
      },
    ],
  },
  {
    word: "Lean1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lean1 <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Leaned or leant) 1 (often foll. By across, back, over, etc.) Be or place in a sloping position; incline from the perpendicular. 2 (foll. By against, on, upon) (cause to) rest for support against etc. 3 (foll. By on, upon) rely on. 4 (foll. By to, towards) be inclined or partial to. <strong class='noun'> —n. </strong> Deviation from the perpendicular; inclination. lean on <strong class='colloq'> colloq. </strong> Put pressure on (a person) to act in a certain way. Lean over backwards see *backwards. [old english]</p>",
      },
    ],
  },
  {
    word: "Lean2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lean2 <strong class='adj'> —adj.</strong> 1 (of a person or animal) thin; having no superfluous fat. 2 (of meat) containing little fat. 3 meagre. <strong class='noun'> —n. </strong> Lean part of meat. leanness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Leaning",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leaning <strong class='noun'> n. </strong> Tendency or partiality.</p>",
      },
    ],
  },
  {
    word: "Lean-to",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lean-to <strong class='noun'> n. </strong> (pl. -tos) building with its roof leaning against a larger building or a wall.</p>",
      },
    ],
  },
  {
    word: "Lean years",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lean years <strong class='n_pl'> n.pl. </strong> Years of scarcity.</p>",
      },
    ],
  },
  {
    word: "Leap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leap <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Leaped or leapt) jump or spring forcefully. <strong class='noun'> —n. </strong> Forceful jump. by leaps and bounds with startlingly rapid progress. Leap in the dark daring step or enterprise. [old english]</p>",
      },
    ],
  },
  {
    word: "Leap-frog",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leap-frog <strong class='noun'> —n. </strong> Game in which players vault with parted legs over others bending down. —v. <strong class='gg'> (-gg-) </strong> 1 perform such a vault (over). 2 overtake alternately.</p>",
      },
    ],
  },
  {
    word: "Leap year",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leap year <strong class='noun'> n. </strong> Year with 366 days (including 29th feb. As an intercalary day).</p>",
      },
    ],
  },
  {
    word: "Learn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Learn <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Learned or learnt) 1 gain knowledge of or skill in. 2 commit to memory. 3 (foll. By of) be told about. 4 (foll. By that, how, etc.) Become aware of. 5 receive instruction. 6 archaic or dial. Teach. [old english]</p>",
      },
    ],
  },
  {
    word: "Learned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Learned <strong class='adj'> adj. </strong> 1 having much knowledge acquired by study. 2 showing or requiring learning (a learned work). 3 (of a publication) academic.</p>",
      },
    ],
  },
  {
    word: "Learner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Learner <strong class='noun'> n. </strong> 1 person who is learning a subject or skill. 2 (in full learner driver) person who is learning to drive but has not yet passed a driving test.</p>",
      },
    ],
  },
  {
    word: "Learning",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Learning <strong class='noun'> n. </strong> Knowledge acquired by study.</p>",
      },
    ],
  },
  {
    word: "Lease",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lease <strong class='noun'> —n. </strong> Contract by which the owner of property allows another to use it for a specified time, usu. In return for payment. —v. <strong class='sing'>(-sing)</strong> grant or take on lease. new lease of (us on) life improved prospect of living, or of use after repair. [anglo-french lesser let, from latin laxo loosen]</p>",
      },
    ],
  },
  {
    word: "Leasehold",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leasehold <strong class='noun'> n. </strong> 1 holding of property by lease. 2 property held by lease. leaseholder n.</p>",
      },
    ],
  },
  {
    word: "Leash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leash <strong class='noun'> —n. </strong> Strap for holding a dog etc.; lead. —v. 1 put a leash on. 2 restrain. straining at the leash eager to begin. [french lesse: related to *lease]</p>",
      },
    ],
  },
  {
    word: "Least",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Least <strong class='adj'> —adj.</strong> 1 smallest, slightest. 2 (of a species etc.) Very small. <strong class='noun'> —n. </strong> The least amount. <strong class='adv'> —adv. </strong> In the least degree. at least 1 at any rate. 2 (also at the least) not less than. In the least (or the least) (usu. With neg.) At all (not in the least offended). To say the least putting the case moderately. [old english, superlative of *less]</p>",
      },
    ],
  },
  {
    word: "Least common denominator",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Least common denominator <strong class='noun'> n. </strong> = *lowest common denominator.</p>",
      },
    ],
  },
  {
    word: "Least common multiple",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Least common multiple <strong class='noun'> n. </strong> = *lowest common multiple.</p>",
      },
    ],
  },
  {
    word: "Leather",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leather <strong class='noun'> —n. </strong> 1 material made from the skin of an animal by tanning etc. 2 piece of leather for polishing with. 3 leather part(s) of a thing. 4 slang cricket-ball or football. 5 (in pl.) Leather clothes. —v. 1 beat, thrash. 2 cover with leather. 3 polish or wipe with a leather. [old english]</p>",
      },
    ],
  },
  {
    word: "Leatherback",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leatherback <strong class='noun'> n. </strong> Large marine turtle with a leathery shell.</p>",
      },
    ],
  },
  {
    word: "Leather-bound",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leather-bound <strong class='adj'> adj. </strong> Bound in leather.</p>",
      },
    ],
  },
  {
    word: "Leatherette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leatherette <strong class='noun'> n. </strong> Propr. Imitation leather.</p>",
      },
    ],
  },
  {
    word: "Leather-jacket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leather-jacket <strong class='noun'> n. </strong> Crane-fly grub with a tough skin.</p>",
      },
    ],
  },
  {
    word: "Leathery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leathery <strong class='adj'> adj. </strong> 1 like leather. 2 tough.</p>",
      },
    ],
  },
  {
    word: "Leave1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leave1 <strong class='verb'> v. </strong> (-ving; past and  <strong class='archaic_it_was'>past part.</strong> Left) 1 a go away from. B (often foll. By for) depart. 2 cause to or let remain; depart without taking. 3 (also absol.) Cease to reside at or belong to or work for. 4 abandon; cease to live with (one's family etc.). 5 have remaining after one's death. 6 bequeath. 7 (foll. By to + infin.) Allow (a person or thing) to do something independently. 8 (foll. By to) commit to another person etc. (leave that to me). 9 a abstain from consuming or dealing with. B (in passive; often foll. By over) remain over. 10 a deposit or entrust (a thing) to be attended to in one's absence (left a message with his secretary). B depute (a person) to perform a function in one's absence. 11 allow to remain or cause to be in a specified state or position (left the door open; left me exhausted). leave alone refrain from disturbing, not interfere with. Leave a person cold not impress or excite a person. Leave off 1 come to or make an end. 2 discontinue. Leave out omit; exclude. [old english]</p>",
      },
    ],
  },
  {
    word: "Leave2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leave2 <strong class='noun'> n. </strong> 1 (often foll. By to + infin.) Permission. 2 a (in full leave of absence) permission to be absent from duty. B period for which this lasts. on leave legitimately absent from duty. Take one's leave (of) bid farewell (to). Take leave of one's senses go mad. [old english]</p>",
      },
    ],
  },
  {
    word: "Leaved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leaved <strong class='adj'> adj. </strong> Having a leaf or leaves, esp. (in <strong class='n_pl'> comb. </strong> ) Of a specified kind or number (four-leaved clover).</p>",
      },
    ],
  },
  {
    word: "Leaven",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leaven <strong class='noun'> —n. </strong> 1 substance causing dough to ferment and rise. 2 pervasive transforming influence; admixture. —v. 1 ferment (dough) with leaven. 2 permeate and transform; modify with a tempering element. [latin levo lift]</p>",
      },
    ],
  },
  {
    word: "Leaves",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Leaves <strong class='pl'> pl. </strong> Of *leaf.</p>",
      },
    ],
  },
  {
    word: "Leave-taking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leave-taking <strong class='noun'> n. </strong> Act of taking one's leave.</p>",
      },
    ],
  },
  {
    word: "Leavings",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leavings <strong class='n_pl'> n.pl. </strong> Things left over.</p>",
      },
    ],
  },
  {
    word: "Lebanese",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lebanese <strong class='adj'> —adj.</strong> Of lebanon. <strong class='noun'> —n. </strong> <strong class='pl_same'> (pl. Same) </strong> 1 native or national of lebanon. 2 person of lebanese descent.</p>",
      },
    ],
  },
  {
    word: "Lech",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lech <strong class='colloq'> colloq. </strong> —v. (often foll. By after) lust. <strong class='noun'> —n. </strong> 1 lecherous man. 2 lust. [back-formation from *lecher]</p>",
      },
    ],
  },
  {
    word: "Lecher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lecher <strong class='noun'> n. </strong> Lecherous man. [french lechier live in debauchery]</p>",
      },
    ],
  },
  {
    word: "Lecherous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lecherous <strong class='adj'> adj. </strong> Lustful, having excessive sexual desire. lecherously adv.</p>",
      },
    ],
  },
  {
    word: "Lechery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lechery <strong class='noun'> n. </strong> Excessive sexual desire.</p>",
      },
    ],
  },
  {
    word: "Lectern",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lectern <strong class='noun'> n. </strong> 1 stand for holding a book in a church etc. 2 similar stand for a lecturer etc. [latin lectrum from lego read]</p>",
      },
    ],
  },
  {
    word: "Lecture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lecture <strong class='noun'> —n. </strong> 1 talk giving specified information to a class etc. 2 long serious speech, esp. As a reprimand. —v. <strong class='adj'> (-ring) </strong> 1 (often foll. By on) deliver lecture(s). 2 talk seriously or reprovingly to. lectureship <strong class='noun'> n. </strong> [latin: related to *lectern]</p>",
      },
    ],
  },
  {
    word: "Lecturer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lecturer <strong class='noun'> n. </strong> Person who lectures, esp. As a teacher in higher education.</p>",
      },
    ],
  },
  {
    word: "Led",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Led <strong class='sing'> abbr. </strong> Light-emitting diode.</p>",
      },
    ],
  },
  {
    word: "Led  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Led  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>lead1.</p>",
      },
    ],
  },
  {
    word: "Lederhosen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lederhosen <strong class='n_pl'> n.pl. </strong> Leather shorts as worn by some men in bavaria etc. [german, = leather trousers]</p>",
      },
    ],
  },
  {
    word: "Ledge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ledge <strong class='noun'> n. </strong> Narrow horizontal or shelflike projection. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Ledger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ledger <strong class='noun'> n. </strong> Main record of the accounts of a business. [dutch]</p>",
      },
    ],
  },
  {
    word: "Lee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lee <strong class='noun'> n. </strong> 1 shelter given by a close object (under the lee of). 2 (in full lee side) side away from the wind. [old english]</p>",
      },
    ],
  },
  {
    word: "Leech",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leech <strong class='noun'> n. </strong> 1 bloodsucking worm formerly much used medically. 2 person who sponges on others. [old english]</p>",
      },
    ],
  },
  {
    word: "Leek",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leek <strong class='noun'> n. </strong> 1 plant of the onion family with flat leaves forming a cylindrical bulb, used as food. 2 this as a welsh national emblem. [old english]</p>",
      },
    ],
  },
  {
    word: "Leer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leer <strong class='noun'> —v. </strong> Look slyly, lasciviously, or maliciously. <strong class='noun'> —n. </strong> Leering look. [perhaps from obsolete leer cheek]</p>",
      },
    ],
  },
  {
    word: "Leery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leery <strong class='adj'> adj. </strong> (-ier, -iest) slang 1 knowing, sly. 2 (foll. By of) wary.</p>",
      },
    ],
  },
  {
    word: "Lees",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lees <strong class='n_pl'> n.pl. </strong> 1 sediment of wine etc. 2 dregs. [french]</p>",
      },
    ],
  },
  {
    word: "Leeward",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leeward <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> On or towards the side sheltered from the wind. <strong class='noun'> —n. </strong> Leeward region or side.</p>",
      },
    ],
  },
  {
    word: "Leeway",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leeway <strong class='noun'> n. </strong> 1 allowable scope of action. 2 sideways drift of a ship to leeward of the desired course.</p>",
      },
    ],
  },
  {
    word: "Left1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Left1 <strong class='adj'> —adj.</strong> 1 on or towards the west side of the human body, or of any object, when facing north. 2 (also left) polit. Of the left. <strong class='adv'> —adv. </strong> On or to the left side. <strong class='noun'> —n. </strong> 1 left-hand part, region, or direction. 2 boxing a left hand. B blow with this. 3 (often left) group or section favouring socialism; socialists collectively. [old english, originally = ‘weak, worthless’]</p>",
      },
    ],
  },
  {
    word: "Left2  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Left2  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>leave1.</p>",
      },
    ],
  },
  {
    word: "Left bank",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Left bank <strong class='noun'> n. </strong> Bank of a river on the left facing downstream.</p>",
      },
    ],
  },
  {
    word: "Left-hand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Left-hand <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> 1 on or towards the left side of a person or thing. 2 done with the left hand. 3 (of a screw) = *left-handed 4b.</p>",
      },
    ],
  },
  {
    word: "Left-handed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Left-handed <strong class='adj'> adj. </strong> 1 naturally using the left hand for writing etc. 2 (of a tool etc.) For use by the left hand. 3 (of a blow) struck with the left hand. 4 a turning to the left. B (of a screw) turned anticlockwise to tighten. 5 awkward, clumsy. 6 a (of a compliment) ambiguous. B of doubtful sincerity. left-handedly <strong class='adv'> adv. </strong> Left-handedness n.</p>",
      },
    ],
  },
  {
    word: "Left-hander",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Left-hander <strong class='noun'> n. </strong> 1 left-handed person. 2 left-handed blow.</p>",
      },
    ],
  },
  {
    word: "Leftism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leftism <strong class='noun'> n. </strong> Socialist political principles. leftist <strong class='noun'> n. </strong> & adj.</p>",
      },
    ],
  },
  {
    word: "Left luggage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Left luggage <strong class='noun'> n. </strong> Luggage deposited for later retrieval.</p>",
      },
    ],
  },
  {
    word: "Leftmost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leftmost <strong class='adj'> adj. </strong> Furthest to the left.</p>",
      },
    ],
  },
  {
    word: "Leftover",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leftover <strong class='noun'> —n. </strong> (usu. In pl.) Surplus items (esp. Of food). <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Remaining over, surplus.</p>",
      },
    ],
  },
  {
    word: "Leftward",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leftward <strong class='adv'> —adv. </strong> (also leftwards) towards the left. <strong class='adj'> —adj.</strong> Going towards or facing the left.</p>",
      },
    ],
  },
  {
    word: "Left wing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Left wing <strong class='noun'> —n. </strong> 1 more socialist section of a political party or system. 2 left side of a football etc. Team on the field. <strong class='adj'> —adj.</strong> (left-wing) socialist, radical. left-winger n.</p>",
      },
    ],
  },
  {
    word: "Lefty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lefty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> <strong class='colloq'> colloq. </strong> 1 polit. Often derog. Left-winger. 2 left-handed person.</p>",
      },
    ],
  },
  {
    word: "Leg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leg <strong class='noun'> n. </strong> 1 each of the limbs on which a person or animal walks and stands. 2 leg of an animal or bird as food. 3 part of a garment covering a leg. 4 support of a chair, table, etc. 5 cricket the half of the field (divided lengthways) in which the batsman's feet are placed. 6 a section of a journey. B section of a relay race. C stage in a competition. leg it <strong class='gg'> (-gg-) </strong> <strong class='colloq'> colloq. </strong> Walk or run hard. Not have a leg to stand on be unable to support one's argument by facts or sound reasons. On one's last legs near death or the end of usefulness etc. legged adj. (also in <strong class='n_pl'> comb. </strong> ). [old norse]</p>",
      },
    ],
  },
  {
    word: "Legacy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legacy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 gift left in a will. 2 thing handed down by a predecessor. [latin lego bequeath]</p>",
      },
    ],
  },
  {
    word: "Legal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legal <strong class='adj'> adj. </strong> 1 of or based on law; concerned with law. 2 appointed or required by law. 3 permitted by law. legally <strong class='adv'> adv. </strong> [latin lex leg- law]</p>",
      },
    ],
  },
  {
    word: "Legal aid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legal aid <strong class='noun'> n. </strong> State assistance for legal advice or action.</p>",
      },
    ],
  },
  {
    word: "Legalese",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legalese <strong class='noun'> n. </strong> Colloq. Technical language of legal documents.</p>",
      },
    ],
  },
  {
    word: "Legalistic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legalistic <strong class='adj'> adj. </strong> Adhering excessively to a law or formula. legalism <strong class='noun'> n. </strong> Legalist n.</p>",
      },
    ],
  },
  {
    word: "Legality",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 lawfulness. 2 (in pl.) Obligations imposed by law.</p>",
      },
    ],
  },
  {
    word: "Legalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 make lawful. 2 bring into harmony with the law. legalization n.</p>",
      },
    ],
  },
  {
    word: "Legal separation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legal separation <strong class='see'> see *</strong> separation.</p>",
      },
    ],
  },
  {
    word: "Legal tender",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legal tender <strong class='noun'> n. </strong> Currency that cannot legally be refused in payment of a debt.</p>",
      },
    ],
  },
  {
    word: "Legate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legate <strong class='noun'> n. </strong> Ambassador of the pope. [latin lego depute]</p>",
      },
    ],
  },
  {
    word: "Legatee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legatee <strong class='noun'> n. </strong> Recipient of a legacy. [latin lego bequeath]</p>",
      },
    ],
  },
  {
    word: "Legation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legation <strong class='noun'> n. </strong> 1 diplomatic minister and his or her staff. 2 this minister's official residence. [latin: related to *legate]</p>",
      },
    ],
  },
  {
    word: "Legato  mus.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legato mus. <strong class='adv'> —adv. </strong> & adj. In a smooth flowing manner. <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 legato passage. 2 legato playing. [italian, = bound, from ligo bind]</p>",
      },
    ],
  },
  {
    word: "Leg before",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leg before <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> (in full leg before wicket) cricket (of a batsman) out because of stopping the ball, other than with the bat or hand, which would otherwise have hit the wicket. <strong class='noun'> —n. </strong> Such a dismissal.</p>",
      },
    ],
  },
  {
    word: "Leg-bye",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leg-bye <strong class='noun'> n. </strong> Cricket run scored from a ball that touches the batsman.</p>",
      },
    ],
  },
  {
    word: "Legend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legend <strong class='noun'> n. </strong> 1 a traditional story; myth. B these collectively. 2 <strong class='colloq'> colloq. </strong> Famous or remarkable event or person. 3 inscription. 4 explanation on a map etc. Of symbols used. [latin legenda what is to be read]</p>",
      },
    ],
  },
  {
    word: "Legendary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legendary <strong class='adj'> adj. </strong> 1 of, based on, or described in a legend. 2 <strong class='colloq'> colloq. </strong> Remarkable.</p>",
      },
    ],
  },
  {
    word: "Legerdemain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legerdemain <strong class='noun'> n. </strong> 1 sleight of hand. 2 trickery, sophistry. [french, = light of hand]</p>",
      },
    ],
  },
  {
    word: "Leger line",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leger line <strong class='noun'> n. </strong> Mus. Short line added for notes above or below the range of a staff. [var. Of *ledger]</p>",
      },
    ],
  },
  {
    word: "Legging",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legging <strong class='noun'> n. </strong> (usu. In pl.) 1 close-fitting knitted trousers for women or children. 2 stout protective outer covering for the lower leg.</p>",
      },
    ],
  },
  {
    word: "Leggy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leggy <strong class='adj'> adj. </strong> (-ier, -iest) 1 long-legged. 2 long-stemmed and weak. legginess n.</p>",
      },
    ],
  },
  {
    word: "Legible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legible <strong class='adj'> adj. </strong> Clear enough to read; readable. legibility <strong class='noun'> n. </strong> Legibly <strong class='adv'> adv. </strong> [latin lego read]</p>",
      },
    ],
  },
  {
    word: "Legion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legion <strong class='noun'> —n. </strong> 1 division of 3,000–6,000 men in the ancient roman army. 2 large organized body. <strong class='predic'> —predic. </strong> <strong class='adj'> Adj. </strong> Great in number (his good works were legion). [latin legio -onis]</p>",
      },
    ],
  },
  {
    word: "Legionary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legionary <strong class='adj'> —adj.</strong> Of a legion or legions. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> member of a legion.</p>",
      },
    ],
  },
  {
    word: "Legionnaire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legionnaire <strong class='noun'> n. </strong> Member of a legion. [french: related to *legion]</p>",
      },
    ],
  },
  {
    word: "Legionnaires' disease",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legionnaires' disease <strong class='noun'> n. </strong> Form of bacterial pneumonia.</p>",
      },
    ],
  },
  {
    word: "Legislate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legislate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> make laws. legislator <strong class='noun'> n. </strong> [from *legislation]</p>",
      },
    ],
  },
  {
    word: "Legislation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legislation <strong class='noun'> n. </strong> 1 law-making. 2 laws collectively. [latin lex legis law, latus  <strong class='archaic_it_was'>past part.</strong> Of fero carry]</p>",
      },
    ],
  },
  {
    word: "Legislative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legislative <strong class='adj'> adj. </strong> Of or empowered to make legislation.</p>",
      },
    ],
  },
  {
    word: "Legislature",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legislature <strong class='noun'> n. </strong> Legislative body of a state.</p>",
      },
    ],
  },
  {
    word: "Legit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legit <strong class='adj'> adj. </strong> Colloq. Legitimate (in sense 2). [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Legitimate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legitimate <strong class='adj'> adj. </strong> 1 (of a child) born of parents married to each other. 2 lawful, proper, regular. 3 logically acceptable. legitimacy <strong class='noun'> n. </strong> Legitimately <strong class='adv'> adv. </strong> [latin legitimo legitimize, from lex legis law]</p>",
      },
    ],
  },
  {
    word: "Legitimatize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legitimatize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) legitimize.</p>",
      },
    ],
  },
  {
    word: "Legitimize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legitimize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 make legitimate. 2 serve as a justification for. legitimization n.</p>",
      },
    ],
  },
  {
    word: "Legless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legless <strong class='adj'> adj. </strong> 1 having no legs. 2 slang very drunk.</p>",
      },
    ],
  },
  {
    word: "Lego",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lego <strong class='noun'> n. </strong> Propr. Toy consisting of interlocking plastic building blocks. [danish legetøj toys]</p>",
      },
    ],
  },
  {
    word: "Leg-of-mutton sleeve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leg-of-mutton sleeve <strong class='noun'> n. </strong> Sleeve which is full and loose on the upper arm but close-fitting on the forearm.</p>",
      },
    ],
  },
  {
    word: "Leg-pull",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leg-pull <strong class='noun'> n. </strong> Colloq. Hoax.</p>",
      },
    ],
  },
  {
    word: "Leg-room",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leg-room <strong class='noun'> n. </strong> Space for the legs of a seated person.</p>",
      },
    ],
  },
  {
    word: "Legume",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Legume <strong class='noun'> n. </strong> 1 leguminous plant. 2 edible part of a leguminous plant. [latin legumen -minis from lego pick, because pickable by hand]</p>",
      },
    ],
  },
  {
    word: "Leguminous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leguminous <strong class='adj'> adj. </strong> Of the family of plants with seeds in pods (e.g. Peas and beans).</p>",
      },
    ],
  },
  {
    word: "Leg up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leg up <strong class='noun'> n. </strong> Help given to mount a horse etc., or to overcome an obstacle or problem; boost.</p>",
      },
    ],
  },
  {
    word: "Leg warmer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leg warmer <strong class='noun'> n. </strong> Either of a pair of tubular knitted garments covering the leg from ankle to knee or thigh.</p>",
      },
    ],
  },
  {
    word: "Lei",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lei <strong class='noun'> n. </strong> Polynesian garland of flowers. [hawaiian]</p>",
      },
    ],
  },
  {
    word: "Leisure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leisure <strong class='noun'> n. </strong> 1 free time. 2 enjoyment of free time. at leisure 1 not occupied. 2 in an unhurried manner. At one's leisure when one has time. [anglo-french leisour from latin licet it is allowed]</p>",
      },
    ],
  },
  {
    word: "Leisure centre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leisure centre <strong class='noun'> n. </strong> Public building with sports facilities etc.</p>",
      },
    ],
  },
  {
    word: "Leisured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leisured <strong class='adj'> adj. </strong> Having ample leisure.</p>",
      },
    ],
  },
  {
    word: "Leisurely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leisurely <strong class='adj'> —adj.</strong> Unhurried, relaxed. <strong class='adv'> —adv. </strong> Without hurry. leisureliness n.</p>",
      },
    ],
  },
  {
    word: "Leisurewear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leisurewear <strong class='noun'> n. </strong> Informal clothes, esp. Sportswear.</p>",
      },
    ],
  },
  {
    word: "Leitmotif",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leitmotif <strong class='noun'> n. </strong> (also leitmotiv) recurrent theme in a musical etc. Composition representing a particular person, idea, etc. [german: related to *lead1, *motive]</p>",
      },
    ],
  },
  {
    word: "Lemming",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lemming <strong class='noun'> n. </strong> Small arctic rodent reputed to rush into the sea and drown during migration. [norwegian]</p>",
      },
    ],
  },
  {
    word: "Lemon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lemon <strong class='noun'> n. </strong> 1 a yellow oval citrus fruit with acidic juice. B tree bearing it. 2 pale yellow colour. 3 <strong class='colloq'> colloq. </strong> Person or thing regarded as a failure. lemony adj. [arabic laimun]</p>",
      },
    ],
  },
  {
    word: "Lemonade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lemonade <strong class='noun'> n. </strong> 1 drink made from lemon juice. 2 synthetic substitute for this.</p>",
      },
    ],
  },
  {
    word: "Lemon balm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lemon balm <strong class='noun'> n. </strong> Bushy plant smelling and tasting of lemon.</p>",
      },
    ],
  },
  {
    word: "Lemon curd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lemon curd <strong class='noun'> n. </strong> (also lemon cheese) creamy conserve made from lemons.</p>",
      },
    ],
  },
  {
    word: "Lemon geranium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lemon geranium <strong class='noun'> n. </strong> Lemon-scented pelargonium.</p>",
      },
    ],
  },
  {
    word: "Lemon sole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lemon sole <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> flat-fish of the plaice family. [french limande]</p>",
      },
    ],
  },
  {
    word: "Lemur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lemur <strong class='noun'> n. </strong> Tree-dwelling primate of madagascar. [latin lemures ghosts]</p>",
      },
    ],
  },
  {
    word: "Lend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lend <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Lent) 1 (usu. Foll. By to) grant (to a person) the use of (a thing) on the understanding that it or its equivalent shall be returned. 2 allow the use of (money) at interest. 3 bestow or contribute (lends a certain charm). lend an ear listen. Lend a hand help. Lend itself to (of a thing) be suitable for. lender <strong class='noun'> n. </strong> [old english: related to *loan]</p>",
      },
    ],
  },
  {
    word: "Length",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Length <strong class='noun'> n. </strong> 1 measurement or extent from end to end. 2 extent in or of time. 3 distance a thing extends. 4 length of a horse, boat, etc., as a measure of the lead in a race. 5 long stretch or extent. 6 degree of thoroughness in action (went to great lengths). 7 piece of a certain length (length of cloth). 8 prosody quantity of a vowel or syllable. 9 cricket a distance from the batsman at which the ball pitches. B proper amount of this. 10 length of a swimming-pool as a measure of distance swum. at length 1 in detail. 2 after a long time. [old english: related to *long1]</p>",
      },
    ],
  },
  {
    word: "Lengthen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lengthen <strong class='verb'> v. </strong> Make or become longer.</p>",
      },
    ],
  },
  {
    word: "Lengthways",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lengthways <strong class='adv'> adv. </strong> In a direction parallel with a thing's length.</p>",
      },
    ],
  },
  {
    word: "Lengthwise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lengthwise <strong class='adv'> —adv. </strong> Lengthways. <strong class='adj'> —adj.</strong> Lying or moving lengthways.</p>",
      },
    ],
  },
  {
    word: "Lengthy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lengthy <strong class='adj'> adj. </strong> (-ier, -iest) of unusual or tedious length. lengthily <strong class='adv'> adv. </strong> Lengthiness n.</p>",
      },
    ],
  },
  {
    word: "Lenient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lenient <strong class='adj'> adj. </strong> Merciful, not severe. lenience <strong class='noun'> n. </strong> Leniency <strong class='noun'> n. </strong> Leniently <strong class='adv'> adv. </strong> [latin lenis gentle]</p>",
      },
    ],
  },
  {
    word: "Lens",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lens <strong class='noun'> n. </strong> 1 piece of a transparent substance with one or (usu.) Both sides curved for concentrating or dispersing light-rays esp. In optical instruments. 2 combination of lenses used in photography. 3 transparent substance behind the iris of the eye. 4 = *contact lens. [latin lens lent- lentil (from the similarity of shape)]</p>",
      },
    ],
  },
  {
    word: "Lent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lent <strong class='noun'> n. </strong> Eccl. Period of fasting and penitence from ash wednesday to holy saturday. lenten adj. [old english, = spring]</p>",
      },
    ],
  },
  {
    word: "Lent  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lent  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>lend.</p>",
      },
    ],
  },
  {
    word: "Lentil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lentil <strong class='noun'> n. </strong> 1 pea-like plant. 2 its seed, esp. Used as food. [latin lens]</p>",
      },
    ],
  },
  {
    word: "Lento  mus.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lento mus. <strong class='adj'> —adj.</strong> Slow. <strong class='adv'> —adv. </strong> Slowly. [italian]</p>",
      },
    ],
  },
  {
    word: "Leo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 constellation and fifth sign of the zodiac (the lion). 2 person born when the sun is in this sign. [latin]</p>",
      },
    ],
  },
  {
    word: "Leonine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leonine <strong class='adj'> adj. </strong> 1 like a lion. 2 of or relating to lions. [latin: related to *leo]</p>",
      },
    ],
  },
  {
    word: "Leopard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leopard <strong class='noun'> n. </strong> Large african or asian animal of the cat family with a black-spotted yellowish or all black coat, panther. [greek leon lion, pardos panther]</p>",
      },
    ],
  },
  {
    word: "Leotard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leotard <strong class='noun'> n. </strong> Close-fitting one-piece garment worn by dancers etc. [léotard, name of a trapeze artist]</p>",
      },
    ],
  },
  {
    word: "Leper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leper <strong class='noun'> n. </strong> 1 person with leprosy. 2 person who is shunned. [greek lepros scaly]</p>",
      },
    ],
  },
  {
    word: "Lepidopterous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lepidopterous <strong class='adj'> adj. </strong> Of the order of insects with four scale-covered wings, including butterflies and moths. lepidopterist <strong class='noun'> n. </strong> [greek lepis -idos scale, pteron wing]</p>",
      },
    ],
  },
  {
    word: "Leprechaun",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leprechaun <strong class='noun'> n. </strong> Small mischievous sprite in irish folklore. [irish lu small, corp body]</p>",
      },
    ],
  },
  {
    word: "Leprosy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leprosy <strong class='noun'> n. </strong> Contagious disease that damages the skin and nerves. leprous adj. [related to *leper]</p>",
      },
    ],
  },
  {
    word: "Lesbian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lesbian <strong class='noun'> —n. </strong> Homosexual woman. <strong class='adj'> —adj.</strong> Of female homosexuality. lesbianism <strong class='noun'> n. </strong> [lesbos, name of an island in the aegean sea]</p>",
      },
    ],
  },
  {
    word: "Lese-majesty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lese-majesty <strong class='noun'> n. </strong> 1 treason. 2 insult to a sovereign or ruler. 3 presumptuous conduct. [french lèse-majesté injured sovereignty]</p>",
      },
    ],
  },
  {
    word: "Lesion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lesion <strong class='noun'> n. </strong> 1 damage. 2 injury. 3 morbid change in the functioning or texture of an organ etc. [latin laedo laes- injure]</p>",
      },
    ],
  },
  {
    word: "Less",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Less <strong class='adj'> —adj.</strong> 1 smaller in extent, degree, duration, number, etc. 2 of smaller quantity, not so much (less meat). 3 <strong class='colloq'> colloq. </strong> Fewer (less biscuits). <strong class='adv'> —adv. </strong> To a smaller extent, in a lower degree. <strong class='noun'> —n. </strong> Smaller amount, quantity, or number (will take less; for less than £10). <strong class='prep'> —prep. </strong> Minus (made £1,000 less tax). [old english] <strong class='usage'> Usage:- </strong>the use of less to mean ‘fewer’, as in sense 3, is regarded as incorrect in standard english.</p>",
      },
    ],
  },
  {
    word: "-less",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-less <strong class='slang_v'> suffix </strong>forming adjectives and adverbs: 1 from nouns, meaning ‘not having, without, free from’ (powerless). 2 from verbs, meaning ‘not accessible to, affected by, or performing the action of the verb’ (fathomless; ceaseless). [old english]</p>",
      },
    ],
  },
  {
    word: "Lessee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lessee <strong class='noun'> n. </strong> (often foll. By of) person holding a property by lease. [french: related to *lease]</p>",
      },
    ],
  },
  {
    word: "Lessen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lessen <strong class='verb'> v. </strong> Make or become less, diminish.</p>",
      },
    ],
  },
  {
    word: "Lesser",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lesser <strong class='adj'> adj. </strong> (usu. Attrib.) Not so great as the other(s) (lesser evil; lesser mortals).</p>",
      },
    ],
  },
  {
    word: "Lesson",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lesson <strong class='noun'> n. </strong> 1 spell of teaching. 2 (in pl.; foll. By in) systematic instruction. 3 thing learnt by a pupil. 4 experience that serves to warn or encourage (let that be a lesson). 5 passage from the bible read aloud during a church service. [french leçon from latin lego lect-]</p>",
      },
    ],
  },
  {
    word: "Lessor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lessor <strong class='noun'> n. </strong> Person who lets a property by lease. [anglo-french: related to *lease]</p>",
      },
    ],
  },
  {
    word: "Lest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lest <strong class='conj'> conj. </strong> Formal 1 in order that not, for fear that (lest he forget). 2 that (afraid lest we should be late). [old english: related to *less] <strong class='usage'> Usage:- </strong>lest is followed by should or the subjunctive (see examples above).</p>",
      },
    ],
  },
  {
    word: "Let1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Let1 <strong class='noun'> —v. </strong> (-tt-; past and  <strong class='archaic_it_was'>past part.</strong> Let) 1 a allow to, not prevent or forbid. B cause to (let me know). 2 (foll. By into) allow to enter. 3 grant the use of (rooms, land, etc.) For rent or hire. 4 allow or cause (liquid or air) to escape (let blood). 5 aux. Supplying the first and third persons of the imperative in exhortations (let us pray), commands (let it be done at once; let there be light), assumptions, etc. (let ab equal cd). <strong class='noun'> —n. </strong> Act of letting a house, room, etc. let alone 1 not to mention, far less or more (hasn't got a television, let alone a video). 2 = let be. Let be not interfere with, attend to, or do. Let down 1 lower. 2 fail to support or satisfy, disappoint. 3 lengthen (a garment). 4 deflate (a tyre). Let down gently reject or disappoint without humiliating. Let drop (or fall) drop (esp. A word or hint) intentionally or by accident. Let go 1 release. 2 a (often foll. By of) lose one's hold. B lose hold of. Let oneself go 1 act spontaneously. 2 neglect one's appearance or habits. Let in 1 allow to enter (let the dog in; let in a flood of light). 2 (foll. By for) involve (a person, often oneself) in loss or difficulty. 3 (foll. By on) allow (a person) to share a secret, privileges, etc. Let loose release, unchain. Let off 1 a fire (a gun). B explode (a bomb). 2 allow or cause (steam etc.) To escape. 3 a not punish or compel. B (foll. By with) punish lightly.</p>",
      },
    ],
  },
  {
    word: "Lb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lb <strong class='sing'> abbr. </strong> Pound(s) (weight). [latin libra]</p>",
      },
    ],
  },
  {
    word: "Lbc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lbc <strong class='sing'> abbr. </strong> London broadcasting company.</p>",
      },
    ],
  },
  {
    word: "L.b.w.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L.b.w. <strong class='abbr'> Abbr. </strong> Leg before wicket.</p>",
      },
    ],
  },
  {
    word: "L.c.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L.c. <strong class='abbr'> Abbr. </strong> 1 = *loc. Cit. 2 lower case.</p>",
      },
    ],
  },
  {
    word: "Lcd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lcd <strong class='sing'> abbr. </strong> 1 liquid crystal display. 2 lowest (or least) common denominator.</p>",
      },
    ],
  },
  {
    word: "Lcm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lcm <strong class='sing'> abbr. </strong> Lowest (or least) common multiple.</p>",
      },
    ],
  },
  {
    word: "L/cpl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L/cpl <strong class='sing'> abbr. </strong> Lance-corporal.</p>",
      },
    ],
  },
  {
    word: "Ld.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ld. <strong class='abbr'> Abbr. </strong> Lord.</p>",
      },
    ],
  },
  {
    word: "Lea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lea <strong class='sing'> abbr. </strong> Local education authority.</p>",
      },
    ],
  },
  {
    word: "Lea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lea <strong class='noun'> n. </strong> Poet. Meadow, field. [old english]</p>",
      },
    ],
  },
  {
    word: "Let2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Let2 <strong class='noun'> —n. </strong> Obstruction of a ball or player in tennis etc., requiring the ball to be served again. —v. (-tt-; past and  <strong class='archaic_it_was'>past part.</strong> Letted or let) archaic hinder, obstruct. without let or hindrance unimpeded. [old english: related to *late]</p>",
      },
    ],
  },
  {
    word: "-let",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-let <strong class='slang_v'> suffix </strong>forming nouns, usu. Diminutive (flatlet) or denoting articles of ornament or dress (anklet). [french]</p>",
      },
    ],
  },
  {
    word: "Let-down",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Let-down <strong class='noun'> n. </strong> Disappointment.</p>",
      },
    ],
  },
  {
    word: "Lethal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lethal <strong class='adj'> adj. </strong> Causing or sufficient to cause death. lethally <strong class='adv'> adv. </strong> [latin letum death]</p>",
      },
    ],
  },
  {
    word: "Lethargy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lethargy <strong class='noun'> n. </strong> 1 lack of energy. 2 morbid drowsiness. lethargic adj. Lethargically <strong class='adv'> adv. </strong> [greek lethargos forgetful]</p>",
      },
    ],
  },
  {
    word: "Let-out",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Let-out <strong class='noun'> n. </strong> Colloq. Opportunity to escape a commitment etc.</p>",
      },
    ],
  },
  {
    word: "Letter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Letter <strong class='noun'> —n. </strong> 1 character representing one or more of the sounds used in speech. 2 a written or printed message, usu. Sent in an envelope by post. B (in pl.) Addressed legal or formal document. 3 precise terms of a statement, the strict verbal interpretation (letter of the law). 4 (in pl.) A literature. B acquaintance with books, erudition. —v. 1 inscribe letters on. 2 classify with letters. to the letter with adherence to every detail. [french from latin littera]</p>",
      },
    ],
  },
  {
    word: "Letter-bomb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Letter-bomb <strong class='noun'> n. </strong> Terrorist explosive device in the form of a postal packet.</p>",
      },
    ],
  },
  {
    word: "Letter-box",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Letter-box <strong class='noun'> n. </strong> Box or slot into which letters are posted or delivered.</p>",
      },
    ],
  },
  {
    word: "Lettered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lettered <strong class='adj'> adj. </strong> Well-read or educated.</p>",
      },
    ],
  },
  {
    word: "Letterhead",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Letterhead <strong class='noun'> n. </strong> 1 printed heading on stationery. 2 stationery with this.</p>",
      },
    ],
  },
  {
    word: "Letter of credit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Letter of credit <strong class='noun'> n. </strong> Letter from a bank authorizing the bearer to draw money from another bank.</p>",
      },
    ],
  },
  {
    word: "Letterpress",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Letterpress <strong class='noun'> n. </strong> 1 printed words of an illustrated book. 2 printing from raised type.</p>",
      },
    ],
  },
  {
    word: "Lettuce",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lettuce <strong class='noun'> n. </strong> Plant with crisp leaves used in salads. [latin lactuca from lac lact- milk]</p>",
      },
    ],
  },
  {
    word: "Let-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Let-up <strong class='noun'> n. </strong> Colloq. 1 reduction in intensity. 2 relaxation of effort.</p>",
      },
    ],
  },
  {
    word: "Leuco-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leuco- <strong class='n_pl'> comb. </strong> Form white. [greek leukos white]</p>",
      },
    ],
  },
  {
    word: "Leucocyte",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leucocyte <strong class='noun'> n. </strong> White blood cell.</p>",
      },
    ],
  },
  {
    word: "Leukaemia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leukaemia <strong class='noun'> n. </strong> (us leukemia) malignant disease in which the bone-marrow etc. Produces too many leucocytes. [greek leukos white, haima blood]</p>",
      },
    ],
  },
  {
    word: "Leukemia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leukemia <strong class='noun'> n. </strong> (brit. Leukaemia) malignant disease in which the bone-marrow etc. Produces too many leucocytes. [greek leukos white, haima blood]</p>",
      },
    ],
  },
  {
    word: "Levant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Levant <strong class='noun'> n. </strong> (prec. By the) archaic eastern mediterranean countries. [french, = point of sunrise, from latin levo lift]</p>",
      },
    ],
  },
  {
    word: "Levantine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Levantine <strong class='adj'> —adj.</strong> Of or trading to the levant. <strong class='noun'> —n. </strong> Native or inhabitant of the levant.</p>",
      },
    ],
  },
  {
    word: "Levee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Levee <strong class='noun'> n. </strong> Us 1 embankment against river floods. 2 natural embankment built up by a river. 3 landing-place. [french levée  <strong class='archaic_it_was'>past part.</strong> Of lever raise: related to *levy]</p>",
      },
    ],
  },
  {
    word: "Level",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Level <strong class='noun'> —n. </strong> 1 horizontal line or plane. 2 height or value reached; position on a real or imaginary scale (eye level; sugar level; danger level). 3 social, moral, or intellectual standard. 4 plane of rank or authority (talks at cabinet level). 5 instrument giving a line parallel to the plane of the horizon. 6 level surface. 7 flat tract of land. <strong class='adj'> —adj.</strong> 1 flat and even; not bumpy. 2 horizontal. 3 (often foll. By with) a on the same horizontal plane as something else. B having equality with something else. 4 even, uniform, equable, or well-balanced. —v. (-ll-; us -l-) 1 make level. 2 raze. 3 (also absol.) Aim (a missile or gun). 4 (also absol.; foll. By at, against) direct (an accusation etc.). do one's level best <strong class='colloq'> colloq. </strong> Do one's utmost. Find one's level reach the right social, intellectual, etc. Position. Level down bring down to a standard. Level off make or become level. Level out make or become level. Level up bring up to a standard. On the level 1 honestly, without deception. 2 honest, truthful. On a level with 1 in the same horizontal plane as. 2 equal with. [latin diminutive of libra balance]</p>",
      },
    ],
  },
  {
    word: "Level crossing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Level crossing <strong class='noun'> n. </strong> Crossing of a railway and a road, or two railways, at the same level.</p>",
      },
    ],
  },
  {
    word: "Leveler",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leveler <strong class='noun'> n. </strong> (brit. Leveller) 1 person who advocates the abolition of social distinctions. 2 person or thing that levels.</p>",
      },
    ],
  },
  {
    word: "Level-headed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Level-headed <strong class='adj'> adj. </strong> Mentally well-balanced, sensible. level-headedness n.</p>",
      },
    ],
  },
  {
    word: "Leveller",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leveller <strong class='noun'> n. </strong> (us leveler) 1 person who advocates the abolition of social distinctions. 2 person or thing that levels.</p>",
      },
    ],
  },
  {
    word: "Level pegging",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Level pegging <strong class='noun'> n. </strong> Equality of scores etc.</p>",
      },
    ],
  },
  {
    word: "Lever",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lever <strong class='noun'> —n. </strong> 1 bar resting on a pivot, used to prise. 2 bar pivoted about a fulcrum (fixed point) which can be acted upon by a force (effort) in order to move a load. 3 projecting handle moved to operate a mechanism. 4 means of exerting moral pressure. —v. 1 use a lever. 2 (often foll. By away, out, up, etc.) Lift, move, etc. With a lever. [latin levo raise]</p>",
      },
    ],
  },
  {
    word: "Leverage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leverage <strong class='noun'> n. </strong> 1 action or power of a lever. 2 power to accomplish a purpose.</p>",
      },
    ],
  },
  {
    word: "Leveraged buyout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leveraged buyout <strong class='noun'> n. </strong> Buyout in which outside capital is used to enable the management to buy up the company. <strong class='usage'> Usage:- </strong>the pronunciation is american because the practice takes place mainly in the us.</p>",
      },
    ],
  },
  {
    word: "Leveret",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leveret <strong class='noun'> n. </strong> Young hare, esp. One in its first year. [latin lepus lepor- hare]</p>",
      },
    ],
  },
  {
    word: "Leviathan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leviathan <strong class='noun'> n. </strong> 1 bibl. Sea-monster. 2 very large or powerful thing. [latin from hebrew]</p>",
      },
    ],
  },
  {
    word: "Levis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Levis <strong class='n_pl'> n.pl. </strong> (also levi's propr.) Type of (orig. Blue) denim jeans or overalls reinforced with rivets. [levi strauss, name of the manufacturer]</p>",
      },
    ],
  },
  {
    word: "Levitate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Levitate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 rise and float in the air (esp. With reference to spiritualism). 2 cause to do this. levitation <strong class='noun'> n. </strong> [latin levis light, after *gravitate]</p>",
      },
    ],
  },
  {
    word: "Levity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Levity <strong class='noun'> n. </strong> Lack of serious thought, frivolity. [latin levis light]</p>",
      },
    ],
  },
  {
    word: "Levy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Levy <strong class='noun'> —v. </strong> (-ies, -ied) 1 impose or collect compulsorily (payment etc.). 2 enrol (troops etc.). 3 wage (war). <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 a collecting of a contribution, tax, etc. B contribution etc. Levied. 2 a act of enrolling troops etc. B (in pl.) Troops enrolled. [latin levo raise]</p>",
      },
    ],
  },
  {
    word: "Lewd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lewd <strong class='adj'> adj. </strong> 1 lascivious. 2 obscene. [old english, originally = lay, vulgar]</p>",
      },
    ],
  },
  {
    word: "Lexical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lexical <strong class='adj'> adj. </strong> 1 of the words of a language. 2 of or as of a lexicon. [greek lexikos, lexikon: see *lexicon]</p>",
      },
    ],
  },
  {
    word: "Lexicography",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lexicography <strong class='noun'> n. </strong> Compiling of dictionaries. lexicographer <strong class='noun'> n. </strong> [from *lexicon, *-graphy]</p>",
      },
    ],
  },
  {
    word: "Lexicon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lexicon <strong class='noun'> n. </strong> 1 dictionary, esp. Of greek, hebrew, syriac, or arabic. 2 vocabulary of a person etc. [greek lexis word]</p>",
      },
    ],
  },
  {
    word: "Leyden jar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Leyden jar <strong class='noun'> n. </strong> Early capacitor consisting of a glass jar with layers of metal foil on the outside and inside. [leyden (now leiden) in holland]</p>",
      },
    ],
  },
  {
    word: "Lf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lf <strong class='sing'> abbr. </strong> Low frequency.</p>",
      },
    ],
  },
  {
    word: "Li",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Li <strong class='prep'> symb. </strong> Lithium.</p>",
      },
    ],
  },
  {
    word: "Liability",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liability <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 being liable. 2 troublesome responsibility; handicap. 3 (in pl.) Debts etc. For which one is liable.</p>",
      },
    ],
  },
  {
    word: "Liable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liable <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> 1 legally bound. 2 (foll. By to) subject to. 3 (foll. By to + infin.) Under an obligation. 4 (foll. By to) exposed or open to (something undesirable). 5 (foll. By to + infin.) Apt, likely (it is liable to rain). 6 (foll. By for) answerable. [french lier bind, from latin ligo] <strong class='usage'> Usage:- </strong>use of liable in sense 5, though common, is considered incorrect by some people.</p>",
      },
    ],
  },
  {
    word: "Liaise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liaise <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> (foll. By with, between) <strong class='colloq'> colloq. </strong> Establish cooperation, act as a link. [back-formation from *liaison]</p>",
      },
    ],
  },
  {
    word: "Liaison",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liaison <strong class='noun'> n. </strong> 1 communication or cooperation. 2 illicit sexual relationship. [french lier bind: see *liable]</p>",
      },
    ],
  },
  {
    word: "Liana",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liana <strong class='noun'> n. </strong> Climbing plant of tropical forests. [french]</p>",
      },
    ],
  },
  {
    word: "Liar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liar <strong class='noun'> n. </strong> Person who tells a lie or lies.</p>",
      },
    ],
  },
  {
    word: "Lib.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lib. <strong class='abbr'> Abbr. </strong> Liberal.</p>",
      },
    ],
  },
  {
    word: "Lib",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lib <strong class='noun'> n. </strong> Colloq. (in names of political movements) liberation. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Libation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Libation <strong class='noun'> n. </strong> 1 pouring out of a drink-offering to a god. 2 such a drink-offering. [latin]</p>",
      },
    ],
  },
  {
    word: "Libel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Libel <strong class='noun'> —n. </strong> 1 law a published false statement that is damaging to a person's reputation. B act of publishing this. 2 false and defamatory misrepresentation or statement. —v. (-ll-; us -l-) 1 defame by libellous statements. 2 law publish a libel against. libellous adj. [latin libellus diminutive of liber book]</p>",
      },
    ],
  },
  {
    word: "Liberal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liberal <strong class='adj'> —adj.</strong> 1 abundant, ample. 2 giving freely, generous. 3 open-minded. 4 not strict or rigorous. 5 for the general broadening of the mind (liberal studies). 6 a favouring moderate political and social reform. B (liberal) of or characteristic of liberals. <strong class='noun'> —n. </strong> 1 person of liberal views. 2 (liberal) supporter or member of a liberal party. liberalism <strong class='noun'> n. </strong> Liberality <strong class='noun'> n. </strong> Liberally <strong class='adv'> adv. </strong> [latin liber free] <strong class='usage'> Usage:- </strong>in the uk the name liberal was discontinued in official political use in 1988 when the party regrouped to form the social and liberal democrats. In 1989 this name was officially replaced by liberal democratic party.</p>",
      },
    ],
  },
  {
    word: "Liberal democrat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liberal democrat <strong class='noun'> n. </strong> Member of the party formed from the liberal party and the social democratic party. <strong class='usage'> Usage:- </strong>see note at liberal.</p>",
      },
    ],
  },
  {
    word: "Liberalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liberalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) make or become more liberal or less strict. liberalization n.</p>",
      },
    ],
  },
  {
    word: "Liberate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liberate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 (often foll. By from) set free. 2 free (a country etc.) From an oppressor or enemy. 3 (often as liberated adj.) Free (a person) from rigid social conventions. liberation <strong class='noun'> n. </strong> Liberator <strong class='noun'> n. </strong> [latin liberare liberat- from liber free]</p>",
      },
    ],
  },
  {
    word: "Libertine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Libertine <strong class='noun'> —n. </strong> Licentious person, rake. <strong class='adj'> —adj.</strong> Licentious. [latin, = freedman, from liber free]</p>",
      },
    ],
  },
  {
    word: "Liberty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liberty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 freedom from captivity etc. 2 right or power to do as one pleases. 3 (usu. In pl.) Right or privilege granted by authority. at liberty 1 free. 2 (foll. By to + infin.) Permitted. Take liberties (often foll. By with) behave in an unduly familiar manner. [latin: related to *liberal]</p>",
      },
    ],
  },
  {
    word: "Libidinous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Libidinous <strong class='adj'> adj. </strong> Lustful. [latin: related to *libido]</p>",
      },
    ],
  },
  {
    word: "Libido",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Libido <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> psychic drive or energy, esp. That associated with sexual desire. libidinal adj. [latin, = lust]</p>",
      },
    ],
  },
  {
    word: "Libra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Libra <strong class='noun'> n. </strong> 1 constellation and seventh sign of the zodiac (the scales). 2 person born when the sun is in this sign. [latin, = pound weight]</p>",
      },
    ],
  },
  {
    word: "Librarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Librarian <strong class='noun'> n. </strong> Person in charge of or assisting in a library. librarianship n.</p>",
      },
    ],
  },
  {
    word: "Library",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Library <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 collection of books. 2 room or building where these are kept. 3 a similar collection of films, records, computer routines, etc. B place where these are kept. 4 set of books issued in similar bindings. [latin liber book]</p>",
      },
    ],
  },
  {
    word: "Libretto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Libretto <strong class='noun'> n. </strong> (pl. -ti or -s) text of an opera etc. librettist <strong class='noun'> n. </strong> [italian, = little book]</p>",
      },
    ],
  },
  {
    word: "Lice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lice <strong class='pl'> pl. </strong> Of *louse.</p>",
      },
    ],
  },
  {
    word: "Licence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Licence <strong class='noun'> n. </strong> (us license) 1 official permit to own or use something, do something, or carry on a trade. 2 permission. 3 liberty of action, esp. When excessive. 4 writer's or artist's deliberate deviation from fact, correct grammar, etc. (poetic licence). [latin licet it is allowed]</p>",
      },
    ],
  },
  {
    word: "License1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>License1 <strong class='noun'> n. </strong> (brit. Licence) 1 official permit to own or use something, do something, or carry on a trade. 2 permission. 3 liberty of action, esp. When excessive. 4 writer's or artist's deliberate deviation from fact, correct grammar, etc. (poetic licence). [latin licet it is allowed]</p>",
      },
    ],
  },
  {
    word: "License2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>License2 <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 grant a licence to. 2 authorize the use of (premises) for a certain purpose.</p>",
      },
    ],
  },
  {
    word: "Licensee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Licensee <strong class='noun'> n. </strong> Holder of a licence, esp. To sell alcoholic liquor.</p>",
      },
    ],
  },
  {
    word: "Licentiate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Licentiate <strong class='noun'> n. </strong> Holder of a certificate of professional competence. [medieval latin: related to *licence]</p>",
      },
    ],
  },
  {
    word: "Licentious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Licentious <strong class='adj'> adj. </strong> Sexually promiscuous. [latin: related to *licence]</p>",
      },
    ],
  },
  {
    word: "Lichee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lichee <strong class='var'> var. </strong> Of *lychee.</p>",
      },
    ],
  },
  {
    word: "Lichen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lichen <strong class='noun'> n. </strong> Plant composed of a fungus and an alga in association, growing on and colouring rocks, tree-trunks, etc. [greek leikhen]</p>",
      },
    ],
  },
  {
    word: "Lich-gate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lich-gate <strong class='noun'> n. </strong> (also lych-gate) roofed gateway to a churchyard where a coffin awaits the clergyman's arrival. [from lich = corpse]</p>",
      },
    ],
  },
  {
    word: "Licit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Licit <strong class='adj'> adj. </strong> Formal permitted, lawful. [latin: related to *licence]</p>",
      },
    ],
  },
  {
    word: "Lick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lick <strong class='noun'> —v. </strong> 1 pass the tongue over. 2 bring into a specified condition by licking (licked it all up; licked it clean). 3 (of a flame etc.) Play lightly over. 4 <strong class='colloq'> colloq. </strong> Defeat. 5 <strong class='colloq'> colloq. </strong> Thrash. <strong class='noun'> —n. </strong> 1 act of licking with the tongue. 2 <strong class='colloq'> colloq. </strong> Fast pace (at a lick). 3 smart blow. lick a person's boots be servile. Lick into shape make presentable or efficient. Lick one's lips (or chops) look forward with relish. Lick one's wounds be in retirement regaining strength etc. After defeat. [old english]</p>",
      },
    ],
  },
  {
    word: "Lick and a promise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lick and a promise <strong class='noun'> n. </strong> Colloq. Hasty performance of a task, esp. Washing oneself.</p>",
      },
    ],
  },
  {
    word: "Licorice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Licorice <strong class='var'> var. </strong> Of *liquorice.</p>",
      },
    ],
  },
  {
    word: "Lid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lid <strong class='noun'> n. </strong> 1 hinged or removable cover, esp. For a container. 2 = *eyelid. put the lid on <strong class='colloq'> colloq. </strong> 1 be the culmination of. 2 put a stop to. lidded adj. (also in <strong class='n_pl'> comb. </strong> ). [old english]</p>",
      },
    ],
  },
  {
    word: "Lido",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lido <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> public open-air swimming-pool or bathing-beach. [lido, name of a beach near venice]</p>",
      },
    ],
  },
  {
    word: "Lie1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lie1 <strong class='noun'> —v. </strong> (lies; lying; past lay;  <strong class='archaic_it_was'>past part.</strong> Lain) 1 be in or assume a horizontal position on a surface; be at rest on something. 2 (of a thing) rest flat on a surface. 3 remain undisturbed or undiscussed etc. (let matters lie). 4 a be kept, remain, or be in a specified state or place (lie hidden; lie in wait; books lay unread). B (of abstract things) exist; be in a certain position or relation (answer lies in education). 5 a be situated (village lay to the east). B be spread out to view. <strong class='noun'> —n. </strong> Way, direction, or position in which a thing lies. lie down assume a lying position; have a short rest. Lie down under accept (an insult etc.) Without protest. Lie in stay in bed late in the morning. Lie low 1 keep quiet or unseen. 2 be discreet about one's intentions. Lie with be the responsibility of (a person) (decision lies with you). Take lying down (usu. With neg.) Accept (an insult etc.) Without protest. [old english] <strong class='usage'> Usage:- </strong>the transitive use of lie, meaning lay, as in lie her on the bed, is incorrect in standard english.</p>",
      },
    ],
  },
  {
    word: "Lie2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lie2 <strong class='noun'> —n. </strong> 1 intentionally false statement (tell a lie). 2 something that deceives. —v. (lies, lied, lying) 1 tell a lie or lies. 2 (of a thing) be deceptive. give the lie to show the falsity of (a supposition etc.). [old english]</p>",
      },
    ],
  },
  {
    word: "Lied",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lied <strong class='noun'> n. </strong> (pl. Lieder) german song, esp. Of the romantic period. [german]</p>",
      },
    ],
  },
  {
    word: "Lie-detector",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lie-detector <strong class='noun'> n. </strong> Instrument supposedly determining whether a person is lying, by testing for certain physiological changes.</p>",
      },
    ],
  },
  {
    word: "Lie-down",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lie-down <strong class='noun'> n. </strong> Short rest.</p>",
      },
    ],
  },
  {
    word: "Liege  usu. Hist.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liege usu. Hist. <strong class='adj'> —adj.</strong> Entitled to receive, or bound to give, feudal service or allegiance. <strong class='noun'> —n. </strong> 1 (in full liege lord) feudal superior or sovereign. 2 (usu. In pl.) Vassal, subject. [medieval latin laeticus, probably from germanic]</p>",
      },
    ],
  },
  {
    word: "Lie-in",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lie-in <strong class='noun'> n. </strong> Prolonged stay in bed in the morning.</p>",
      },
    ],
  },
  {
    word: "Lien",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lien <strong class='noun'> n. </strong> Law right to hold another's property until a debt on it is paid. [latin ligo bind]</p>",
      },
    ],
  },
  {
    word: "Lie of the land",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lie of the land <strong class='noun'> n. </strong> State of affairs.</p>",
      },
    ],
  },
  {
    word: "Lieu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lieu <strong class='noun'> n. </strong> in lieu 1 instead. 2 (foll. By of) in the place of. [latin locus place]</p>",
      },
    ],
  },
  {
    word: "Lieut.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lieut. <strong class='abbr'> Abbr. </strong> Lieutenant.</p>",
      },
    ],
  },
  {
    word: "Lieutenant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lieutenant <strong class='noun'> n. </strong> 1 a army officer next in rank below captain. B naval officer next in rank below lieutenant commander. 2 deputy. lieutenancy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. [french: related to *lieu place, *tenant holder]</p>",
      },
    ],
  },
  {
    word: "Lieutenant colonel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lieutenant colonel <strong class='noun'> n. </strong> (also lieutenant commander or general) officers ranking next below colonel, commander, or general.</p>",
      },
    ],
  },
  {
    word: "Life",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life <strong class='noun'> n. </strong> (pl. Lives) 1 capacity for growth, functional activity, and continual change until death. 2 living things and their activity (insect life; is there life on mars?). 3 a period during which life lasts, or the period from birth to the present time or from the present time to death (have done it all my life; will regret it all my life). B duration of a thing's existence or ability to function. 4 a person's state of existence as a living individual (sacrificed their lives). B living person (many lives were lost). 5 a individual's actions or fortunes; manner of existence (start a new life). B particular aspect of this (private life). 6 business and pleasures of the world (in paris you really see life). 7 energy, liveliness (full of life). 8 biography. 9 <strong class='colloq'> colloq. </strong> = *life sentence. for dear (or one's) life as if or in order to escape death. For life for the rest of one's life. Not on your life <strong class='colloq'> colloq. </strong> Most certainly not. [old english]</p>",
      },
    ],
  },
  {
    word: "Life assurance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life assurance <strong class='noun'> n. </strong> = *life insurance.</p>",
      },
    ],
  },
  {
    word: "Lifebelt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifebelt <strong class='noun'> n. </strong> Buoyant belt for keeping a person afloat.</p>",
      },
    ],
  },
  {
    word: "Lifeblood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifeblood <strong class='noun'> n. </strong> 1 blood, as being necessary to life. 2 vital factor or influence.</p>",
      },
    ],
  },
  {
    word: "Lifeboat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifeboat <strong class='noun'> n. </strong> 1 special boat for rescuing those in distress at sea. 2 ship's small boat for use in emergency.</p>",
      },
    ],
  },
  {
    word: "Lifebuoy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifebuoy <strong class='noun'> n. </strong> Buoyant support for keeping a person afloat.</p>",
      },
    ],
  },
  {
    word: "Life cycle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life cycle <strong class='noun'> n. </strong> Series of changes in the life of an organism, including reproduction.</p>",
      },
    ],
  },
  {
    word: "Lifeguard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifeguard <strong class='noun'> n. </strong> Expert swimmer employed to rescue bathers from drowning.</p>",
      },
    ],
  },
  {
    word: "Life guards",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life guards <strong class='n_pl'> n.pl. </strong> Regiment of the royal household cavalry.</p>",
      },
    ],
  },
  {
    word: "Life insurance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life insurance <strong class='noun'> n. </strong> Insurance for a sum to be paid after a set period or on the death of the insured person if earlier.</p>",
      },
    ],
  },
  {
    word: "Life-jacket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life-jacket <strong class='noun'> n. </strong> Buoyant jacket for keeping a person afloat.</p>",
      },
    ],
  },
  {
    word: "Lifeless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifeless <strong class='adj'> adj. </strong> 1 dead. 2 unconscious. 3 lacking movement or vitality. lifelessly <strong class='adv'> adv. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Lifelike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifelike <strong class='adj'> adj. </strong> Closely resembling life or the person or thing represented.</p>",
      },
    ],
  },
  {
    word: "Lifeline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifeline <strong class='noun'> n. </strong> 1 rope etc. Used for life-saving. 2 sole means of communication or transport.</p>",
      },
    ],
  },
  {
    word: "Lifelong",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifelong <strong class='adj'> adj. </strong> Lasting a lifetime.</p>",
      },
    ],
  },
  {
    word: "Life peer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life peer <strong class='noun'> n. </strong> Peer whose title lapses on death.</p>",
      },
    ],
  },
  {
    word: "Life-preserver",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life-preserver <strong class='noun'> n. </strong> 1 short stick with a heavily loaded end. 2 life-jacket etc.</p>",
      },
    ],
  },
  {
    word: "Lifer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifer <strong class='noun'> n. </strong> Slang person serving a life sentence.</p>",
      },
    ],
  },
  {
    word: "Life sciences",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life sciences <strong class='n_pl'> n.pl. </strong> Biology and related subjects.</p>",
      },
    ],
  },
  {
    word: "Life sentence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life sentence <strong class='noun'> n. </strong> Sentence of imprisonment for an indefinite period.</p>",
      },
    ],
  },
  {
    word: "Life-size",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life-size <strong class='adj'> adj. </strong> (also -sized) of the same size as the person or thing represented.</p>",
      },
    ],
  },
  {
    word: "Lifestyle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifestyle <strong class='noun'> n. </strong> Way of life of a person or group.</p>",
      },
    ],
  },
  {
    word: "Life-support machine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Life-support machine <strong class='noun'> n. </strong> Respirator.</p>",
      },
    ],
  },
  {
    word: "Lifetime",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lifetime <strong class='noun'> n. </strong> Duration of a person's life.</p>",
      },
    ],
  },
  {
    word: "Lift",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lift <strong class='noun'> —v. </strong> 1 (often foll. By up, off, out, etc.) Raise or remove to a higher position. 2 go up; be raised; yield to an upward force. 3 give an upward direction to (the eyes or face). 4 elevate to a higher plane of thought or feeling. 5 (of fog etc.) Rise, disperse. 6 remove (a barrier or restriction). 7 transport (supplies, troops, etc.) By air. 8 <strong class='colloq'> colloq. </strong> A steal. B plagiarize (a passage of writing etc.). 9 dig up (esp. Potatoes etc.). <strong class='noun'> —n. </strong> 1 lifting or being lifted. 2 ride in another person's vehicle (gave them a lift).</p>",
      },
    ],
  },
  {
    word: "Lift-off",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lift-off <strong class='noun'> n. </strong> Vertical take-off of a spacecraft or rocket.</p>",
      },
    ],
  },
  {
    word: "Ligament",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ligament <strong class='noun'> n. </strong> Band of tough fibrous tissue linking bones. [latin ligo bind]</p>",
      },
    ],
  },
  {
    word: "Ligature",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ligature <strong class='noun'> —n. </strong> 1 tie or bandage. 2 <strong class='mus'> mus. </strong> Slur, tie. 3 two or more letters joined, e.g. Æ. 4 bond; thing that unites. —v. <strong class='adj'> (-ring) </strong> bind or connect with a ligature. [latin ligo bind] <strong class='usage'> Usage:- </strong>sense 3 of this word is sometimes confused with digraph, which means ‘two separate letters together representing one sound’.</p>",
      },
    ],
  },
  {
    word: "Light1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light1 <strong class='noun'> —n. </strong> 1 the natural agent (electromagnetic radiation) that stimulates sight and makes things visible. 2 the medium or condition of the space in which this is present (just enough light to see). 3 appearance of brightness (saw a distant light). 4 source of light, e.g. The sun, a lamp, fire, etc. 5 (often in pl.) Traffic-light. 6 a flame or spark serving to ignite. B device producing this. 7 aspect in which a thing is regarded (appeared in a new light). 8 a mental illumination. B spiritual illumination by divine truth. 9 vivacity etc. In a person's face, esp. In the eyes. 10 eminent person (leading light). 11 bright parts of a picture etc. 12 window or opening in a wall to let light in. —v. (past lit;  <strong class='archaic_it_was'>past part.</strong> Lit or lighted) (attrib.) 1 set burning; begin to burn. 2 (often foll. By up) provide with light or lighting; make prominent by means of light. 3 show (a person) the way or surroundings with a light. 4 (usu. Foll. By up) (of the face or eyes) brighten with animation, pleasure, etc. <strong class='adj'> —adj.</strong> 1 well provided with light; not dark. 2 (of a colour) pale (light blue; light-blue ribbon). bring (or come) to light reveal or be revealed. In a good (or bad) light giving a favourable (or unfavourable) impression. In the light of taking account of. Light up 1 <strong class='colloq'> colloq. </strong> Begin to smoke a cigarette etc. 2 = sense 2 of v. 3 = sense 4 of v. lightish adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Light2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light2 <strong class='adj'> —adj.</strong> 1 not heavy. 2 a relatively low in weight, amount, density, intensity, etc. (light arms, traffic, metal, rain). B deficient in weight (light coin). 3 a carrying or suitable for small loads (light railway). B (of a ship) unladen. C carrying only light arms, armaments, etc. 4 (of food) easy to digest. 5 (of entertainment, music, etc.) Intended for amusement only; not profound. 6 (of sleep or a sleeper) easily disturbed. 7 easily borne or done (light duties). 8 nimble; quick-moving (light step; light rhythm). 9 (of a building etc.) Graceful, elegant. 10 a free from sorrow; cheerful (light heart). B giddy (light in the head). <strong class='adv'> —adv. </strong> 1 in a light manner (tread light; sleep light). 2 with a minimum load (travel light). —v. (past and  <strong class='archaic_it_was'>past part.</strong> Lit or lighted) (foll. By on, upon) come upon or find by chance. make light of treat as unimportant. lightish adj. Lightly <strong class='adv'> adv. </strong> Lightness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Light-bulb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light-bulb <strong class='noun'> n. </strong> Glass bulb containing an inert gas and a metal filament, providing light when an electric current is passed through it.</p>",
      },
    ],
  },
  {
    word: "Lighten1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lighten1 <strong class='verb'> v. </strong> 1 a make or become lighter in weight. B reduce the weight or load of. 2 bring relief to (the mind etc.). 3 mitigate (a penalty).</p>",
      },
    ],
  },
  {
    word: "Lighten2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lighten2 <strong class='verb'> v. </strong> 1 shed light on. 2 make or grow bright.</p>",
      },
    ],
  },
  {
    word: "Lighter1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lighter1 <strong class='noun'> n. </strong> Device for lighting cigarettes etc.</p>",
      },
    ],
  },
  {
    word: "Lighter2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lighter2 <strong class='noun'> n. </strong> Boat, usu. Flat-bottomed, for transferring goods from a ship to a wharf or another ship. [dutch: related to *light2 in the sense ‘unload’]</p>",
      },
    ],
  },
  {
    word: "Lighter-than-air",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lighter-than-air <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of an aircraft) weighing less than the air it displaces.</p>",
      },
    ],
  },
  {
    word: "Light-fingered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light-fingered <strong class='adj'> adj. </strong> Given to stealing.</p>",
      },
    ],
  },
  {
    word: "Light flyweight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light flyweight <strong class='noun'> n. </strong> 1 amateur boxing weight up to 48 kg. 2 amateur boxer of this weight.</p>",
      },
    ],
  },
  {
    word: "Light-footed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light-footed <strong class='adj'> adj. </strong> Nimble.</p>",
      },
    ],
  },
  {
    word: "Light-headed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light-headed <strong class='adj'> adj. </strong> Giddy, delirious. light-headedness n.</p>",
      },
    ],
  },
  {
    word: "Light-hearted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light-hearted <strong class='adj'> adj. </strong> 1 cheerful. 2 (unduly) casual. light-heartedly adv.</p>",
      },
    ],
  },
  {
    word: "Light heavyweight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light heavyweight <strong class='noun'> n. </strong> 1 weight in certain sports between middleweight and heavyweight, in amateur boxing 75–81 kg: also called *cruiserweight. 2 sportsman of this weight.</p>",
      },
    ],
  },
  {
    word: "Lighthouse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lighthouse <strong class='noun'> n. </strong> Tower etc. Containing a beacon light to warn or guide ships at sea.</p>",
      },
    ],
  },
  {
    word: "Light industry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light industry <strong class='noun'> n. </strong> Manufacture of small or light articles.</p>",
      },
    ],
  },
  {
    word: "Lighting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lighting <strong class='noun'> n. </strong> 1 equipment in a room or street etc. For producing light. 2 arrangement or effect of lights.</p>",
      },
    ],
  },
  {
    word: "Lighting-up time",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lighting-up time <strong class='noun'> n. </strong> Time after which vehicles must show the prescribed lights.</p>",
      },
    ],
  },
  {
    word: "Light meter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light meter <strong class='noun'> n. </strong> Instrument for measuring the intensity of the light, esp. To show the correct photographic exposure.</p>",
      },
    ],
  },
  {
    word: "Light middleweight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light middleweight <strong class='noun'> n. </strong> 1 weight in amateur boxing of 67–71 kg. 2 amateur boxer of this weight.</p>",
      },
    ],
  },
  {
    word: "Lightning",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lightning <strong class='noun'> —n. </strong> Flash of bright light produced by an electric discharge between clouds or between clouds and the ground. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Very quick. [from *lighten2]</p>",
      },
    ],
  },
  {
    word: "Lightning-conductor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lightning-conductor <strong class='noun'> n. </strong> (also lightning-rod) metal rod or wire fixed to an exposed part of a building or to a mast to divert lightning into the earth or sea.</p>",
      },
    ],
  },
  {
    word: "Lights",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lights <strong class='n_pl'> n.pl. </strong> Lungs of sheep, pigs, etc., used as a food esp. For pets. [from *light2: cf. *lung]</p>",
      },
    ],
  },
  {
    word: "Lightship",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lightship <strong class='noun'> n. </strong> Moored or anchored ship with a beacon light.</p>",
      },
    ],
  },
  {
    word: "Lightweight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lightweight <strong class='adj'> —adj.</strong> 1 of below average weight. 2 of little importance or influence. <strong class='noun'> —n. </strong> 1 lightweight person, animal, or thing. 2 a weight in certain sports between featherweight and welterweight, in amateur boxing 57–60 kg. B sportsman of this weight.</p>",
      },
    ],
  },
  {
    word: "Light welterweight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light welterweight <strong class='noun'> n. </strong> 1 weight in amateur boxing of 60–63.5 kg. 2 amateur boxer of this weight.</p>",
      },
    ],
  },
  {
    word: "Light-year",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Light-year <strong class='noun'> n. </strong> Distance light travels in one year, nearly 6 million million miles.</p>",
      },
    ],
  },
  {
    word: "Ligneous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ligneous <strong class='adj'> adj. </strong> 1 (of a plant) woody. 2 of the nature of wood. [latin lignum wood]</p>",
      },
    ],
  },
  {
    word: "Lignite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lignite <strong class='noun'> n. </strong> Brown coal of woody texture.</p>",
      },
    ],
  },
  {
    word: "Lignum vitae",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lignum vitae <strong class='noun'> n. </strong> A hard-wooded tree. [latin, = wood of life]</p>",
      },
    ],
  },
  {
    word: "Likable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Likable <strong class='var'> var. </strong> Of *likeable.</p>",
      },
    ],
  },
  {
    word: "Like1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Like1 <strong class='adj'> —adj.</strong> (more like, most like) 1 a having some or all of the qualities of another, each other, or an original. B resembling in some way, such as (good writers like dickens). 2 characteristic of (not like them to be late). 3 in a suitable state or mood for (felt like working; felt like a cup of tea). <strong class='prep'> —prep. </strong> In the manner of; to the same degree as (drink like a fish; acted like an idiot). <strong class='adv'> —adv. </strong> 1 slang so to speak (did a quick getaway, like). 2 <strong class='colloq'> colloq. </strong> Likely, probably (as like as not). — <strong class='conj'> conj. </strong> Colloq. 1 as (cannot do it like you do). 2 as if (ate like they were starving). <strong class='noun'> —n. </strong> 1 counterpart; equal; similar person or thing. 2 (prec. By the) thing or things of the same kind (will never do the like again). and the like and similar things. Like anything <strong class='colloq'> colloq. </strong> Very much, vigorously. The likes of <strong class='colloq'> colloq. </strong> A person such as. More like it <strong class='colloq'> colloq. </strong> Nearer what is required. What is he (or it etc.) Like? What sort of person is he (or thing is it etc.)? [old english] <strong class='usage'> Usage:- </strong>the use of like as a conjunction is considered incorrect by some people.</p>",
      },
    ],
  },
  {
    word: "Like2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Like2 <strong class='noun'> —v. </strong> (-king) 1 find agreeable or enjoyable. 2 a choose to have; prefer (like my tea weak). B wish for or be inclined to (would like a nap; should like to come). <strong class='noun'> —n. </strong> (in pl.) Things one likes or prefers. [old english] <strong class='usage'> Usage:- </strong>the use of like as a conjunction is considered incorrect by some people.</p>",
      },
    ],
  },
  {
    word: "Like2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Like2 <strong class='noun'> —v. </strong> (-king) 1 find agreeable or enjoyable. 2 a choose to have; prefer (like my tea weak). B wish for or be inclined to (would like a nap; should like to come). <strong class='noun'> —n. </strong> (in pl.) Things one likes or prefers. [old english]</p>",
      },
    ],
  },
  {
    word: "-like",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-like <strong class='n_pl'> comb. </strong> Form forming adjectives from nouns, meaning ‘similar to, characteristic of’ (doglike; shell-like; tortoise-like). <strong class='usage'> Usage:- </strong>in formations not generally current the hyphen should be used. It may be omitted when the first element is of one syllable, unless it ends in -l.</p>",
      },
    ],
  },
  {
    word: "Likeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Likeable <strong class='adj'> adj. </strong> (also likable) pleasant; easy to like. likeably adv.</p>",
      },
    ],
  },
  {
    word: "Likelihood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Likelihood <strong class='noun'> n. </strong> Probability. in all likelihood very probably.</p>",
      },
    ],
  },
  {
    word: "Likely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Likely <strong class='adj'> —adj.</strong> (-ier, -iest) 1 probable; such as may well happen or be true. 2 to be reasonably expected (not likely to come now). 3 promising; apparently suitable (a likely spot). <strong class='adv'> —adv. </strong> Probably. not likely! Colloq. Certainly not, i refuse. [old norse: related to *like1]</p>",
      },
    ],
  },
  {
    word: "Like-minded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Like-minded <strong class='adj'> adj. </strong> Having the same tastes, opinions, etc.</p>",
      },
    ],
  },
  {
    word: "Liken",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liken <strong class='verb'> v. </strong> (foll. By to) point out the resemblance of (a person or thing to another). [from *like1]</p>",
      },
    ],
  },
  {
    word: "Likeness",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Likeness <strong class='noun'> n. </strong> 1 (usu. Foll. By between, to) resemblance. 2 (foll. By of) semblance or guise (in the likeness of a ghost). 3 portrait, representation.</p>",
      },
    ],
  },
  {
    word: "Likewise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Likewise <strong class='adv'> adv. </strong> 1 also, moreover. 2 similarly (do likewise).</p>",
      },
    ],
  },
  {
    word: "Liking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liking <strong class='noun'> n. </strong> 1 what one likes; one's taste (is it to your liking?). 2 (foll. By for) regard or fondness; taste or fancy.</p>",
      },
    ],
  },
  {
    word: "Lilac",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lilac <strong class='noun'> —n. </strong> 1 shrub with fragrant pinkish-violet or white blossoms. 2 pale pinkish-violet colour. <strong class='adj'> —adj.</strong> Of this colour. [persian]</p>",
      },
    ],
  },
  {
    word: "Liliaceous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liliaceous <strong class='adj'> adj. </strong> Of the lily family. [related to *lily]</p>",
      },
    ],
  },
  {
    word: "Lilliputian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lilliputian <strong class='noun'> —n. </strong> Diminutive person or thing. <strong class='adj'> —adj.</strong> Diminutive. [lilliput in swift's gulliver's travels]</p>",
      },
    ],
  },
  {
    word: "Lilo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lilo <strong class='noun'> n. </strong> (also li-lo propr.) <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> type of inflatable mattress. [from lie low]</p>",
      },
    ],
  },
  {
    word: "Lilt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lilt <strong class='noun'> —n. </strong> 1 light springing rhythm. 2 tune with this. —v. (esp. As lilting adj.) Speak etc. With a lilt; have a lilt. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Lily",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lily <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 bulbous plant with large trumpet-shaped flowers on a tall stem. 2 heraldic fleur-de-lis. [latin lilium]</p>",
      },
    ],
  },
  {
    word: "Lily-livered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lily-livered <strong class='adj'> adj. </strong> Cowardly.</p>",
      },
    ],
  },
  {
    word: "Lily of the valley",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lily of the valley <strong class='noun'> n. </strong> Plant with white bell-shaped fragrant flowers.</p>",
      },
    ],
  },
  {
    word: "Lily white",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lily white <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> (as adj. Often hyphenated) pure white.</p>",
      },
    ],
  },
  {
    word: "Limb1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limb1 <strong class='noun'> n. </strong> 1 arm, leg, or wing. 2 large branch of a tree. 3 branch of a cross. out on a limb isolated. [old english]</p>",
      },
    ],
  },
  {
    word: "Limb2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limb2 <strong class='noun'> n. </strong> Specified edge of the sun, moon, etc. [latin limbus hem, border]</p>",
      },
    ],
  },
  {
    word: "Limber1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limber1 <strong class='adj'> —adj.</strong> 1 lithe. 2 flexible. —v. (usu. Foll. By up) 1 make (oneself or a part of the body etc.) Supple. 2 warm up in preparation for athletic etc. Activity. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Limber2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limber2 <strong class='noun'> —n. </strong> Detachable front part of a gun-carriage. —v. Attach a limber to. [perhaps from latin limo -onis shaft]</p>",
      },
    ],
  },
  {
    word: "Limber2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limber2 <strong class='noun'> —n. </strong> Detachable front part of a gun-carriage. —v. Attach a limber to. [perhaps from latin limo -onis shaft]</p>",
      },
    ],
  },
  {
    word: "Limbo1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limbo1 <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 (in some christian beliefs) supposed abode of the souls of unbaptized infants, and of the just who died before christ. 2 intermediate state or condition of awaiting a decision etc. [latin in limbo: related to *limb2]</p>",
      },
    ],
  },
  {
    word: "Limbo2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limbo2 <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> w. Indian dance in which the dancer bends backwards to pass under a horizontal bar which is progressively lowered. [w. Indian word, perhaps = *limber1]</p>",
      },
    ],
  },
  {
    word: "Lime1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lime1 <strong class='noun'> —n. </strong> 1 (in full quicklime) white substance (calcium oxide) obtained by heating limestone. 2 (in full slaked lime) calcium hydroxide obtained by reacting quicklime with water, used as a fertilizer and in making mortar. —v. (-ming) treat with lime. limy adj. (-ier, -iest). [old english]</p>",
      },
    ],
  },
  {
    word: "Lime2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lime2 <strong class='noun'> n. </strong> 1 a fruit like a lemon but green, rounder, smaller, and more acid. B tree which produces this fruit. 2 (in full lime-green) yellowish-green colour. [french from arabic]</p>",
      },
    ],
  },
  {
    word: "Lime3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lime3 <strong class='noun'> n. </strong> (in full lime-tree) tree with heart-shaped leaves and fragrant creamy blossom. [alteration of line = old english lind = *linden]</p>",
      },
    ],
  },
  {
    word: "Limekiln",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limekiln <strong class='noun'> n. </strong> Kiln for heating limestone.</p>",
      },
    ],
  },
  {
    word: "Limelight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limelight <strong class='noun'> n. </strong> 1 intense white light used formerly in theatres. 2 (prec. By the) the glare of publicity.</p>",
      },
    ],
  },
  {
    word: "Limerick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limerick <strong class='noun'> n. </strong> Humorous five-line verse with a rhyme-scheme aabba. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Limestone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limestone <strong class='noun'> n. </strong> Rock composed mainly of calcium carbonate.</p>",
      },
    ],
  },
  {
    word: "Limey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limey <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> us slang offens. British person (orig. A sailor) or ship. [from *lime2, because of the former enforced consumption of lime juice in the british navy]</p>",
      },
    ],
  },
  {
    word: "Limit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limit <strong class='noun'> —n. </strong> 1 point, line, or level beyond which something does not or may not extend or pass. 2 greatest or smallest amount permissible. —v. (-t-) 1 set or serve as a limit to. 2 (foll. By to) restrict. be the limit <strong class='colloq'> colloq. </strong> Be intolerable. Within limits with some degree of freedom. limitless adj. [latin limes limit- boundary, frontier]</p>",
      },
    ],
  },
  {
    word: "Limitation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limitation <strong class='noun'> n. </strong> 1 limiting or being limited. 2 limit (of ability etc.) (often in pl.: know one's limitations). 3 limiting circumstance.</p>",
      },
    ],
  },
  {
    word: "Limited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limited <strong class='adj'> adj. </strong> 1 confined within limits. 2 not great in scope or talents. 3 restricted to a few examples (limited edition). 4 (after a company name) being a limited company.</p>",
      },
    ],
  },
  {
    word: "Limited company",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limited company <strong class='noun'> n. </strong> (also limited liability company) company whose owners are legally responsible only to a specified amount for its debts.</p>",
      },
    ],
  },
  {
    word: "Limn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limn <strong class='verb'> v. </strong> Archaic paint. [french luminer from latin lumino *illuminate]</p>",
      },
    ],
  },
  {
    word: "Limo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> us <strong class='colloq'> colloq. </strong> Limousine. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Limousine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limousine <strong class='noun'> n. </strong> Large luxurious car. [french]</p>",
      },
    ],
  },
  {
    word: "Limp1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limp1 <strong class='noun'> —v. </strong> Walk or proceed lamely or awkwardly. <strong class='noun'> —n. </strong> Lame walk. [perhaps from obsolete limphalt: related to *halt2]</p>",
      },
    ],
  },
  {
    word: "Limp2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limp2 <strong class='adj'> adj. </strong> 1 not stiff or firm. 2 without energy or will. limply <strong class='adv'> adv. </strong> Limpness <strong class='noun'> n. </strong> [perhaps from *limp1]</p>",
      },
    ],
  },
  {
    word: "Limpet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limpet <strong class='noun'> n. </strong> Marine gastropod with a conical shell, sticking tightly to rocks. [old english]</p>",
      },
    ],
  },
  {
    word: "Limpet mine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limpet mine <strong class='noun'> n. </strong> Delayed action mine attached to a ship's hull.</p>",
      },
    ],
  },
  {
    word: "Limpid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Limpid <strong class='adj'> adj. </strong> Clear, transparent. limpidity <strong class='noun'> n. </strong> [latin]</p>",
      },
    ],
  },
  {
    word: "Linage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linage <strong class='noun'> n. </strong> 1 number of lines in printed or written matter. 2 payment by the line.</p>",
      },
    ],
  },
  {
    word: "Linchpin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linchpin <strong class='noun'> n. </strong> 1 pin passed through an axle-end to keep a wheel in position. 2 person or thing vital to an organization etc. [old english lynis = axle-tree]</p>",
      },
    ],
  },
  {
    word: "Linctus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linctus <strong class='noun'> n. </strong> Syrupy medicine, esp. A soothing cough mixture. [latin lingo lick]</p>",
      },
    ],
  },
  {
    word: "Linden",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linden <strong class='noun'> n. </strong> Lime-tree. [old english lind(e)]</p>",
      },
    ],
  },
  {
    word: "Line1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Line1 <strong class='noun'> —n. </strong> 1 continuous mark made on a surface. 2 similar mark, esp. A furrow or wrinkle. 3 use of lines in art. 4 a straight or curved continuous extent of length without breadth. B track of a moving point. 5 contour or outline (has a slimming line). 6 a curve connecting all points having a specified common property. B (the line) the equator. 7 a limit or boundary.</p>",
      },
    ],
  },
  {
    word: "Line2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Line2 <strong class='verb'> v. </strong> (-ning) 1 cover the inside surface of (a garment, box, etc.) With a layer of usu. Different material. 2 serve as a lining for. 3 <strong class='colloq'> colloq. </strong> Fill, esp. Plentifully. [obsolete line linen used for linings]</p>",
      },
    ],
  },
  {
    word: "Lineage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lineage <strong class='noun'> n. </strong> Lineal descent; ancestry. [latin: related to *line1]</p>",
      },
    ],
  },
  {
    word: "Lineal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lineal <strong class='adj'> adj. </strong> 1 in the direct line of descent or ancestry. 2 linear. lineally adv.</p>",
      },
    ],
  },
  {
    word: "Lineament",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lineament <strong class='noun'> n. </strong> (usu. In pl.) Distinctive feature or characteristic, esp. Of the face. [latin: related to *line1]</p>",
      },
    ],
  },
  {
    word: "Linear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linear <strong class='adj'> adj. </strong> 1 of or in lines. 2 long and narrow and of uniform breadth. linearity <strong class='noun'> n. </strong> Linearly adv.</p>",
      },
    ],
  },
  {
    word: "Linear b",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linear b <strong class='noun'> n. </strong> Form of bronze age writing found in greece: an earlier undeciphered form (linear a) also exists.</p>",
      },
    ],
  },
  {
    word: "Lineation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lineation <strong class='noun'> n. </strong> Marking with or drawing of lines.</p>",
      },
    ],
  },
  {
    word: "Line-drawing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Line-drawing <strong class='noun'> n. </strong> Drawing in which images are produced with lines.</p>",
      },
    ],
  },
  {
    word: "Linen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linen <strong class='noun'> —n. </strong> 1 cloth woven from flax. 2 (collect.) Articles made or <strong class='archaic_it_was'> orig. </strong> Made of linen, as sheets, shirts, underwear, etc. <strong class='adj'> —adj.</strong> Made of linen. [old english: related to latin linum flax]</p>",
      },
    ],
  },
  {
    word: "Linen basket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linen basket <strong class='noun'> n. </strong> Basket for dirty washing.</p>",
      },
    ],
  },
  {
    word: "Line of fire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Line of fire <strong class='noun'> n. </strong> Expected path of gunfire etc.</p>",
      },
    ],
  },
  {
    word: "Line of vision",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Line of vision <strong class='noun'> n. </strong> Straight line along which an observer looks.</p>",
      },
    ],
  },
  {
    word: "Line-out",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Line-out <strong class='noun'> n. </strong> (in rugby) parallel lines of opposing forwards at right angles to the touchline for the throwing in of the ball.</p>",
      },
    ],
  },
  {
    word: "Line printer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Line printer <strong class='noun'> n. </strong> Machine that prints output from a computer a line at a time.</p>",
      },
    ],
  },
  {
    word: "Liner1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liner1 <strong class='noun'> n. </strong> Ship or aircraft etc. Carrying passengers on a regular line.</p>",
      },
    ],
  },
  {
    word: "Liner2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liner2 <strong class='noun'> n. </strong> Removable lining.</p>",
      },
    ],
  },
  {
    word: "Linesman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linesman <strong class='noun'> n. </strong> Umpire's or referee's assistant who decides whether a ball has fallen within the playing area or not.</p>",
      },
    ],
  },
  {
    word: "Line-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Line-up <strong class='noun'> n. </strong> 1 line of people for inspection. 2 arrangement of persons in a team, band, etc.</p>",
      },
    ],
  },
  {
    word: "Ling1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ling1 <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> long slender marine fish. [probably dutch]</p>",
      },
    ],
  },
  {
    word: "Ling2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ling2 <strong class='noun'> n. </strong> Any of various heathers. [old norse]</p>",
      },
    ],
  },
  {
    word: "-ling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ling <strong class='slang_v'> suffix </strong>1 denoting a person or thing: a connected with (hireling). B having the property of being (weakling) or undergoing (starveling). 2 denoting a diminutive (duckling), often derogatory (lordling). [old english]</p>",
      },
    ],
  },
  {
    word: "Linger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linger <strong class='verb'> v. </strong> 1 stay about. 2 (foll. By over, on, etc.) Dally (linger over dinner; lingered on the final note). 3 (esp. Of an illness) be protracted. 4 (often foll. By on) be slow in dying. [old english lengan: related to *long1]</p>",
      },
    ],
  },
  {
    word: "Lingerie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lingerie <strong class='noun'> n. </strong> Women's underwear and nightclothes. [french linge linen]</p>",
      },
    ],
  },
  {
    word: "Lingo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lingo <strong class='noun'> n. </strong> (pl. -s or -es) <strong class='colloq'> colloq. </strong> 1 foreign language. 2 vocabulary of a special subject or group. [probably from portuguese lingoa from latin lingua tongue]</p>",
      },
    ],
  },
  {
    word: "Lingua franca",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lingua franca <strong class='noun'> n. </strong> (pl. Lingua francas) 1 language used in common by speakers with different native languages. 2 system for mutual understanding. [italian, = frankish tongue]</p>",
      },
    ],
  },
  {
    word: "Lingual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lingual <strong class='adj'> adj. </strong> 1 of or formed by the tongue. 2 of speech or languages. lingually <strong class='adv'> adv. </strong> [latin lingua tongue, language]</p>",
      },
    ],
  },
  {
    word: "Linguist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linguist <strong class='noun'> n. </strong> Person skilled in languages or linguistics.</p>",
      },
    ],
  },
  {
    word: "Linguistic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linguistic <strong class='adj'> adj. </strong> Of language or the study of languages. linguistically adv.</p>",
      },
    ],
  },
  {
    word: "Linguistics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linguistics <strong class='noun'> n. </strong> The study of language and its structure.</p>",
      },
    ],
  },
  {
    word: "Liniment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liniment <strong class='noun'> n. </strong> Embrocation. [latin linio smear]</p>",
      },
    ],
  },
  {
    word: "Lining",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lining <strong class='noun'> n. </strong> Material which lines a surface etc.</p>",
      },
    ],
  },
  {
    word: "Link",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Link <strong class='noun'> —n. </strong> 1 one loop or ring of a chain etc. 2 a connecting part; one in a series. B state or means of connection. 3 cuff-link. —v. 1 (foll. By together, to, with) connect or join (two things or one to another). 2 clasp or intertwine (hands or arms). 3 (foll. By on, to, in to) be joined; attach oneself to (a system, company, etc.). link up (foll. By with) connect or combine. [old norse]</p>",
      },
    ],
  },
  {
    word: "Linkage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linkage <strong class='noun'> n. </strong> 1 linking or being linked, esp. The linking of quite different political issues in negotiations. 2 link or system of links.</p>",
      },
    ],
  },
  {
    word: "Linkman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linkman <strong class='noun'> n. </strong> Person providing continuity in a broadcast programme.</p>",
      },
    ],
  },
  {
    word: "Links",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Links <strong class='n_pl'> n.pl. </strong> (treated as sing. Or pl.) Golf-course. [old english, = rising ground]</p>",
      },
    ],
  },
  {
    word: "Link-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Link-up <strong class='noun'> n. </strong> Act or result of linking up.</p>",
      },
    ],
  },
  {
    word: "Linnaean",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linnaean <strong class='adj'> adj. </strong> Of linnaeus or his system of classifying plants and animals. <strong class='usage'> Usage:- </strong>this word is spelt linnean in linnean society.</p>",
      },
    ],
  },
  {
    word: "Linnet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linnet <strong class='noun'> n. </strong> Brown-grey finch. [french linette from lin flax, because it eats flax-seed]</p>",
      },
    ],
  },
  {
    word: "Lino",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lino <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> linoleum. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Linocut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linocut <strong class='noun'> n. </strong> 1 design carved in relief on a block of linoleum. 2 print made from this.</p>",
      },
    ],
  },
  {
    word: "Linoleum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linoleum <strong class='noun'> n. </strong> Canvas-backed material thickly coated with a preparation of linseed oil and powdered cork etc., esp. As a floor covering. [latin linum flax, oleum oil]</p>",
      },
    ],
  },
  {
    word: "Linseed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linseed <strong class='noun'> n. </strong> Seed of flax. [old english: related to *line1]</p>",
      },
    ],
  },
  {
    word: "Linseed oil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linseed oil <strong class='noun'> n. </strong> Oil extracted from linseed and used in paint and varnish.</p>",
      },
    ],
  },
  {
    word: "Linsey-woolsey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Linsey-woolsey <strong class='noun'> n. </strong> Fabric of coarse wool woven on a cotton warp. [probably from lindsey in suffolk + *wool]</p>",
      },
    ],
  },
  {
    word: "Lint",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lint <strong class='noun'> n. </strong> 1 linen or cotton with a raised nap on one side, used for dressing wounds. 2 fluff. [perhaps from french linette from lin flax]</p>",
      },
    ],
  },
  {
    word: "Lintel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lintel <strong class='noun'> n. </strong> Horizontal timber, stone, etc., across the top of a door or window. [french: related to *limit]</p>",
      },
    ],
  },
  {
    word: "Lion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lion <strong class='noun'> n. </strong> 1 (fem. Lioness) large tawny flesh-eating wild cat of africa and s. Asia. 2 (the lion) zodiacal sign or constellation leo. 3 brave or celebrated person. [latin leo]</p>",
      },
    ],
  },
  {
    word: "Lion-heart",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lion-heart <strong class='noun'> n. </strong> Courageous person. lion-hearted adj.</p>",
      },
    ],
  },
  {
    word: "Lionize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lionize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) treat as a celebrity.</p>",
      },
    ],
  },
  {
    word: "Lion's share",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lion's share <strong class='noun'> n. </strong> Largest or best part.</p>",
      },
    ],
  },
  {
    word: "Lip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lip <strong class='noun'> —n. </strong> 1 either of the two fleshy parts forming the edges of the mouth-opening. 2 edge of a cup, vessel, etc., esp. The part shaped for pouring from. 3 <strong class='colloq'> colloq. </strong> Impudent talk. —v. (-pp-) 1 touch with the lips; apply the lips to. 2 touch lightly. lipped adj. (also in <strong class='n_pl'> comb. </strong> ). [old english]</p>",
      },
    ],
  },
  {
    word: "Lipid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lipid <strong class='noun'> n. </strong> Any of a group of fatlike substances that are insoluble in water but soluble in organic solvents, including fatty acids, oils, waxes, and steroids. [greek lipos fat]</p>",
      },
    ],
  },
  {
    word: "Liposuction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liposuction <strong class='noun'> n. </strong> Technique in cosmetic surgery for removing excess fat from under the skin by suction.</p>",
      },
    ],
  },
  {
    word: "Lip-read",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lip-read <strong class='verb'> v. </strong> Understand (speech) from observing a speaker's lip-movements.</p>",
      },
    ],
  },
  {
    word: "Lip-service",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lip-service <strong class='noun'> n. </strong> Insincere expression of support etc.</p>",
      },
    ],
  },
  {
    word: "Lipstick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lipstick <strong class='noun'> n. </strong> Stick of cosmetic for colouring the lips.</p>",
      },
    ],
  },
  {
    word: "Liquefy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquefy <strong class='verb'> v. </strong> (-ies, -ied) make or become liquid. liquefaction <strong class='noun'> n. </strong> [latin: related to *liquid]</p>",
      },
    ],
  },
  {
    word: "Liqueur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liqueur <strong class='noun'> n. </strong> Any of several strong sweet alcoholic spirits. [french]</p>",
      },
    ],
  },
  {
    word: "Liquid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquid <strong class='adj'> —adj.</strong> 1 having a consistency like that of water or oil, flowing freely but of constant volume. 2 having the qualities of water in appearance. 3 (of sounds) clear and pure. 4 (of assets) easily converted into cash. <strong class='noun'> —n. </strong> 1 liquid substance. 2 phonet. Sound of l or r. [latin liqueo be liquid]</p>",
      },
    ],
  },
  {
    word: "Liquidate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquidate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 wind up the affairs of (a firm) by ascertaining liabilities and apportioning assets. 2 pay off (a debt). 3 wipe out, kill. liquidator <strong class='noun'> n. </strong> [medieval latin: related to *liquid]</p>",
      },
    ],
  },
  {
    word: "Liquidation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquidation <strong class='noun'> n. </strong> Liquidating, esp. Of a firm. go into liquidation (of a firm etc.) Be wound up and have its assets apportioned.</p>",
      },
    ],
  },
  {
    word: "Liquid crystal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquid crystal <strong class='noun'> n. </strong> Turbid liquid with some order in its molecular arrangement.</p>",
      },
    ],
  },
  {
    word: "Liquid crystal display",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquid crystal display <strong class='noun'> n. </strong> Visual display in electronic devices, in which the reflectivity of a matrix of liquid crystals changes as a signal is applied.</p>",
      },
    ],
  },
  {
    word: "Liquidity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquidity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 state of being liquid. 2 availability of liquid assets.</p>",
      },
    ],
  },
  {
    word: "Liquidize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquidize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) reduce to a liquid state.</p>",
      },
    ],
  },
  {
    word: "Liquidizer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquidizer <strong class='noun'> n. </strong> (also -iser) machine for liquidizing foods.</p>",
      },
    ],
  },
  {
    word: "Liquor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquor <strong class='noun'> n. </strong> 1 alcoholic (esp. Distilled) drink. 2 other liquid, esp. That produced in cooking. [latin: related to *liquid]</p>",
      },
    ],
  },
  {
    word: "Liquorice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liquorice <strong class='noun'> n. </strong> (also licorice) 1 black root extract used as a sweet and in medicine. 2 plant from which it is obtained. [greek glukus sweet, rhiza root]</p>",
      },
    ],
  },
  {
    word: "Lira",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lira <strong class='noun'> n. </strong> (pl. Lire pronunc. Same or) 1 chief monetary unit of italy. 2 chief monetary unit of turkey. [latin libra pound]</p>",
      },
    ],
  },
  {
    word: "Lisle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lisle <strong class='noun'> n. </strong> Fine cotton thread for stockings etc. [lille in france]</p>",
      },
    ],
  },
  {
    word: "Lisp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lisp <strong class='noun'> —n. </strong> Speech defect in which s is pronounced like th in thick and z is pronounced like th in this. —v. Speak or utter with a lisp. [old english]</p>",
      },
    ],
  },
  {
    word: "Lissom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lissom <strong class='adj'> adj. </strong> Lithe, agile. [ultimately from *lithe]</p>",
      },
    ],
  },
  {
    word: "List1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>List1 <strong class='noun'> —n. </strong> 1 number of items, names, etc., written or printed together as a record or aid to memory. 2 (in pl.) A palisades enclosing an area for a tournament. B scene of a contest. —v. 1 make a list of. 2 enter in a list. 3 (as listed adj.) A (of securities) approved for dealings on the stock exchange. B (of a building) of historical importance and officially protected. enter the lists issue or accept a challenge. [old english]</p>",
      },
    ],
  },
  {
    word: "List2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>List2 <strong class='noun'> —v. </strong> (of a ship etc.) Lean over to one side. <strong class='noun'> —n. </strong> Process or instance of listing. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Listen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Listen <strong class='verb'> v. </strong> 1 a make an effort to hear something. B attentively hear a person speaking. 2 (foll. By to) a give attention with the ear. B take notice of; heed. 3 (also listen out) (often foll. By for) seek to hear by waiting alertly. listen in 1 tap a telephonic communication. 2 use a radio receiving set. [old english]</p>",
      },
    ],
  },
  {
    word: "Listener",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Listener <strong class='noun'> n. </strong> 1 person who listens. 2 person who listens to the radio.</p>",
      },
    ],
  },
  {
    word: "Listeria",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Listeria <strong class='noun'> n. </strong> Any of several bacteria infecting humans and animals eating contaminated food. [lister, name of a surgeon]</p>",
      },
    ],
  },
  {
    word: "Listless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Listless <strong class='adj'> adj. </strong> Lacking energy or enthusiasm. listlessly <strong class='adv'> adv. </strong> Listlessness <strong class='noun'> n. </strong> [from obsolete list inclination]</p>",
      },
    ],
  },
  {
    word: "List price",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>List price <strong class='noun'> n. </strong> Price of something as shown in a published list.</p>",
      },
    ],
  },
  {
    word: "Lit  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lit  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>light1, *light2.</p>",
      },
    ],
  },
  {
    word: "Litany",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litany <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a series of supplications to god recited by a priest etc. With set responses by the congregation. B (the litany) that in the book of common prayer. 2 tedious recital (litany of woes). [greek litaneia prayer]</p>",
      },
    ],
  },
  {
    word: "Litchi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litchi <strong class='var'> var. </strong> Of *lychee.</p>",
      },
    ],
  },
  {
    word: "Liter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liter <strong class='noun'> n. </strong> (brit. Litre) metric unit of capacity equal to 1 cubic decimetre (1.76 pints). [greek litra]</p>",
      },
    ],
  },
  {
    word: "Literacy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Literacy <strong class='noun'> n. </strong> Ability to read and write. [latin littera letter]</p>",
      },
    ],
  },
  {
    word: "Literal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Literal <strong class='adj'> —adj.</strong> 1 taking words in their basic sense without metaphor or allegory. 2 corresponding exactly to the original words (literal translation). 3 prosaic; matter-of-fact. 4 so called without exaggeration (literal bankruptcy). 5 of a letter or the letters of the alphabet. <strong class='noun'> —n. </strong> Misprint. literally <strong class='adv'> adv. </strong> [latin littera letter]</p>",
      },
    ],
  },
  {
    word: "Literalism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Literalism <strong class='noun'> n. </strong> Insistence on a literal interpretation; adherence to the letter. literalist n.</p>",
      },
    ],
  },
  {
    word: "Literary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Literary <strong class='adj'> adj. </strong> 1 of or concerned with books or literature etc. 2 (of a word or idiom) used chiefly by writers; formal. literariness <strong class='noun'> n. </strong> [latin: related to *letter]</p>",
      },
    ],
  },
  {
    word: "Literate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Literate <strong class='adj'> —adj.</strong> Able to read and write; educated. <strong class='noun'> —n. </strong> Literate person.</p>",
      },
    ],
  },
  {
    word: "Literati",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Literati <strong class='n_pl'> n.pl. </strong> The class of learned people.</p>",
      },
    ],
  },
  {
    word: "Literature",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Literature <strong class='noun'> n. </strong> 1 written works, esp. Those valued for form and style. 2 writings of a country or period or on a particular subject. 3 literary production. 4 <strong class='colloq'> colloq. </strong> Printed matter, leaflets, etc.</p>",
      },
    ],
  },
  {
    word: "Lithe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lithe <strong class='adj'> adj. </strong> Flexible, supple. [old english]</p>",
      },
    ],
  },
  {
    word: "Lithium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lithium <strong class='noun'> n. </strong> Soft silver-white metallic element. [greek lithion from lithos stone]</p>",
      },
    ],
  },
  {
    word: "Litho",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litho <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> = *lithography. —v. (-oes, -oed) lithograph. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Lithograph",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lithograph <strong class='noun'> —n. </strong> Lithographic print. —v. Print by lithography. [greek lithos stone]</p>",
      },
    ],
  },
  {
    word: "Lithography",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lithography <strong class='noun'> n. </strong> Process of printing from a plate so treated that ink adheres only to the design to be printed. lithographer <strong class='noun'> n. </strong> Lithographic adj. Lithographically adv.</p>",
      },
    ],
  },
  {
    word: "Lithuanian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lithuanian <strong class='noun'> —n. </strong> 1 a native or national of lithuania in eastern europe. B person of lithuanian descent. 2 language of lithuania. <strong class='adj'> —adj.</strong> Of lithuania, its people, or language.</p>",
      },
    ],
  },
  {
    word: "Litigant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litigant <strong class='noun'> —n. </strong> Party to a lawsuit. <strong class='adj'> —adj.</strong> Engaged in a lawsuit. [related to *litigate]</p>",
      },
    ],
  },
  {
    word: "Litigate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litigate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 go to law. 2 contest (a point) at law. litigation <strong class='noun'> n. </strong> Litigator <strong class='noun'> n. </strong> [latin lis lit- lawsuit]</p>",
      },
    ],
  },
  {
    word: "Litigious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litigious <strong class='adj'> adj. </strong> 1 fond of litigation. 2 contentious. [latin: related to *litigate]</p>",
      },
    ],
  },
  {
    word: "Litmus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litmus <strong class='noun'> n. </strong> Dye from lichens, turned red by acid and blue by alkali. [old norse, = dye-moss]</p>",
      },
    ],
  },
  {
    word: "Litmus paper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litmus paper <strong class='noun'> n. </strong> Paper stained with litmus, used to test for acids or alkalis.</p>",
      },
    ],
  },
  {
    word: "Litmus test",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litmus test <strong class='noun'> n. </strong> Colloq. Real or ultimate test.</p>",
      },
    ],
  },
  {
    word: "Litotes",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litotes <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> ironic understatement, esp. Using the negative (e.g. I shan't be sorry for i shall be glad). [greek litos plain, meagre]</p>",
      },
    ],
  },
  {
    word: "Litre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litre <strong class='noun'> n. </strong> (us liter) metric unit of capacity equal to 1 cubic decimetre (1.76 pints). [greek litra]</p>",
      },
    ],
  },
  {
    word: "Litt.d.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litt.d. <strong class='abbr'> Abbr. </strong> Doctor of letters. [latin litterarum doctor]</p>",
      },
    ],
  },
  {
    word: "Litter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litter <strong class='noun'> —n. </strong> 1 a refuse, esp. Paper, discarded in a public place. B odds and ends lying about. 2 young animals brought forth at one birth. 3 vehicle containing a couch and carried on men's shoulders or by animals. 4 a kind of stretcher for the sick and wounded. 5 straw etc., as bedding for animals. 6 granulated material for use as an animal's, esp. A cat's, toilet indoors. —v. 1 make (a place) untidy with refuse. 2 give birth to (whelps etc.). 3 a provide (a horse etc.) With litter as bedding. B spread straw etc. On (a stable-floor etc.). [latin lectus bed]</p>",
      },
    ],
  },
  {
    word: "Litterbug",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litterbug <strong class='noun'> n. </strong> Colloq. Person who drops litter in the street etc.</p>",
      },
    ],
  },
  {
    word: "Litter-lout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Litter-lout <strong class='noun'> n. </strong> Colloq. = *litterbug.</p>",
      },
    ],
  },
  {
    word: "Little",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Little <strong class='adj'> —adj.</strong> (littler, littlest; less or lesser, least) 1 small in size, amount, degree, etc.; often used affectionately or condescendingly (friendly little chap; silly little fool). 2 a short in stature. B of short distance or duration. 3 (prec. By a) a certain though small amount of (give me a little butter). 4 trivial (questions every little thing).</p>",
      },
    ],
  },
  {
    word: "Little bear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Little bear <strong class='see'> see *</strong> bear2.</p>",
      },
    ],
  },
  {
    word: "Little by little",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Little by little <strong class='adv'> adv. </strong> By degrees; gradually.</p>",
      },
    ],
  },
  {
    word: "Little end",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Little end <strong class='noun'> n. </strong> The smaller end of a connecting-rod, attached to the piston.</p>",
      },
    ],
  },
  {
    word: "Little grebe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Little grebe <strong class='noun'> n. </strong> Small water-bird of the grebe family.</p>",
      },
    ],
  },
  {
    word: "Little people",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Little people <strong class='n_pl'> n.pl. </strong> (prec. By the) fairies.</p>",
      },
    ],
  },
  {
    word: "Little woman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Little woman <strong class='noun'> n. </strong> (prec. By the) <strong class='colloq'> colloq. </strong> Often derog. One's wife.</p>",
      },
    ],
  },
  {
    word: "Littoral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Littoral <strong class='adj'> —adj.</strong> Of or on the shore. <strong class='noun'> —n. </strong> Region lying along a shore. [latin litus litor- shore]</p>",
      },
    ],
  },
  {
    word: "Liturgy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liturgy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 prescribed form of public worship. 2 (the liturgy) the book of common prayer. liturgical adj. Liturgically <strong class='adv'> adv. </strong> [greek leitourgia public worship]</p>",
      },
    ],
  },
  {
    word: "Livable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Livable <strong class='var'> var. </strong> Of *liveable.</p>",
      },
    ],
  },
  {
    word: "Live1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Live1 <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> 1 have life; be or remain alive. 2 have one's home (lives up the road). 3 (foll. By on) subsist or feed (lives on fruit). 4 (foll. By on, off) depend for subsistence (lives off the state; lives on a pension). 5 (foll. By on, by) sustain one's position (live on their reputation; lives by his wits). 6 a spend or pass (lived a full life). B express in one's life (lives his faith). 7 conduct oneself, arrange one's habits, etc., in a specified way (live quietly). 8 (often foll. By on) (of a person or thing) survive; remain (memory lived on). 9 enjoy life to the full (not really living). live and let live condone others' failings so as to be similarly tolerated. Live down cause (past guilt, a scandal, etc.) To be forgotten by blameless conduct thereafter. Live for regard as one's life's purpose (lives for her music). Live in (or out) reside on (or off) the premises of one's work. Live it up <strong class='colloq'> colloq. </strong> Live gaily and extravagantly. Live a lie keep up a pretence. Live together (esp. Of a couple not married to each other) share a home and have a sexual relationship. Live up to fulfil. Live with 1 share a home with. 2 tolerate. [old english]</p>",
      },
    ],
  },
  {
    word: "Live2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Live2 <strong class='adj'> —adj.</strong> 1 (attrib.) That is alive; living. 2 (of a broadcast, performance, etc.) Heard or seen at the time of its performance or with an audience present. 3 of current interest or importance (a live issue). 4 glowing, burning (live coals). 5 (of a match, bomb, etc.) Not yet kindled or exploded. 6 (of a wire etc.) Charged with or carrying electricity. <strong class='adv'> —adv. </strong> 1 in order to make a live broadcast (going live now to the house of commons). 2 as a live performance etc. (show went out live). [from *alive]</p>",
      },
    ],
  },
  {
    word: "Liveable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liveable <strong class='adj'> adj. </strong> (also livable) 1 <strong class='colloq'> colloq. </strong> (usu. Liveable-in) (of a house etc.) Fit to live in. 2 (of a life) worth living. 3 <strong class='colloq'> colloq. </strong> (usu. Liveable-with) (of a person) easy to live with.</p>",
      },
    ],
  },
  {
    word: "Lived-in",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lived-in <strong class='adj'> adj. </strong> 1 (of a room etc.) Showing signs of habitation. 2 <strong class='colloq'> colloq. </strong> (of a face) marked by experience.</p>",
      },
    ],
  },
  {
    word: "Live-in",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Live-in <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of a sexual partner, employee, etc.) Cohabiting; resident.</p>",
      },
    ],
  },
  {
    word: "Livelihood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Livelihood <strong class='noun'> n. </strong> Means of living; job, income. [old english: related to *life]</p>",
      },
    ],
  },
  {
    word: "Livelong",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Livelong <strong class='adj'> adj. </strong> In its entire length (the livelong day). [from obsolete lief, assimilated to *live1]</p>",
      },
    ],
  },
  {
    word: "Lively",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lively <strong class='adj'> adj. </strong> (-ier, -iest) 1 full of life; vigorous, energetic. 2 vivid (lively imagination). 3 cheerful. 4 joc. Exciting, dangerous (made things lively for him). liveliness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Liven",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liven <strong class='verb'> v. </strong> (often foll. By up) <strong class='colloq'> colloq. </strong> Make or become lively, cheer up.</p>",
      },
    ],
  },
  {
    word: "Liver1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liver1 <strong class='noun'> n. </strong> 1 large glandular organ in the abdomen of vertebrates. 2 liver of some animals as food. [old english]</p>",
      },
    ],
  },
  {
    word: "Liver2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liver2 <strong class='noun'> n. </strong> Person who lives in a specified way (a fast liver).</p>",
      },
    ],
  },
  {
    word: "Liveried",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liveried <strong class='adj'> adj. </strong> Wearing livery.</p>",
      },
    ],
  },
  {
    word: "Liverish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liverish <strong class='adj'> adj. </strong> 1 suffering from a liver disorder. 2 peevish, glum.</p>",
      },
    ],
  },
  {
    word: "Liverpudlian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liverpudlian <strong class='noun'> —n. </strong> Native of liverpool. <strong class='adj'> —adj.</strong> Of liverpool. [liverpool in nw england]</p>",
      },
    ],
  },
  {
    word: "Liver sausage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liver sausage <strong class='noun'> n. </strong> Sausage of cooked liver etc.</p>",
      },
    ],
  },
  {
    word: "Liverwort",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Liverwort <strong class='noun'> n. </strong> Small mosslike or leafless plant sometimes lobed like a liver.</p>",
      },
    ],
  },
  {
    word: "Livery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Livery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 distinctive uniform of a member of a city company or of a servant. 2 distinctive guise or marking (birds in their winter livery). 3 distinctive colour scheme in which a company's vehicles etc. Are painted. at livery (of a horse) kept for the owner for a fixed charge. [anglo-french liveré,  <strong class='archaic_it_was'>past part.</strong> Of livrer *deliver]</p>",
      },
    ],
  },
  {
    word: "Livery stable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Livery stable <strong class='noun'> n. </strong> Stable where horses are kept at livery or let out for hire.</p>",
      },
    ],
  },
  {
    word: "Lives",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lives <strong class='pl'> pl. </strong> Of *life.</p>",
      },
    ],
  },
  {
    word: "Livestock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Livestock <strong class='noun'> n. </strong> (usu. Treated as pl.) Animals on a farm, kept for use or profit.</p>",
      },
    ],
  },
  {
    word: "Live wire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Live wire <strong class='noun'> n. </strong> Spirited person.</p>",
      },
    ],
  },
  {
    word: "Livid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Livid <strong class='adj'> adj. </strong> 1 <strong class='colloq'> colloq. </strong> Furious. 2 of a bluish leaden colour (livid bruise). [latin]</p>",
      },
    ],
  },
  {
    word: "Living",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Living <strong class='noun'> —n. </strong> 1 being alive (that's what living is all about). 2 livelihood. 3 position held by a clergyman, providing an income. <strong class='adj'> —adj.</strong> 1 contemporary; now alive. 2 (of a likeness) exact, lifelike. 3 (of a language) still in vernacular use. within living memory within the memory of people still alive.</p>",
      },
    ],
  },
  {
    word: "Living-room",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Living-room <strong class='noun'> n. </strong> Room for general day use.</p>",
      },
    ],
  },
  {
    word: "Living wage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Living wage <strong class='noun'> n. </strong> Wage on which one can live without privation.</p>",
      },
    ],
  },
  {
    word: "Lizard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lizard <strong class='noun'> n. </strong> Reptile with usu. A long body and tail, four legs, and a rough or scaly hide. [latin lacertus]</p>",
      },
    ],
  },
  {
    word: "Lj",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lj <strong class='sing'> abbr. </strong> (pl. L jj) lord justice.</p>",
      },
    ],
  },
  {
    word: "'Ll",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>'Ll <strong class='verb'> v. </strong> (usu. After pronouns) shall, will (i'll; that'll). [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Llama",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Llama <strong class='noun'> n. </strong> S. American ruminant kept as a beast of burden and for its soft woolly fleece. [spanish from quechua]</p>",
      },
    ],
  },
  {
    word: "Ll b",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ll b <strong class='sing'> abbr. </strong> Bachelor of laws. [latin legum baccalaureus]</p>",
      },
    ],
  },
  {
    word: "Ll d",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ll d <strong class='sing'> abbr. </strong> Doctor of laws. [latin legum doctor]</p>",
      },
    ],
  },
  {
    word: "Ll m",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ll m <strong class='sing'> abbr. </strong> Master of laws. [latin legum magister]</p>",
      },
    ],
  },
  {
    word: "Lloyd's",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lloyd's <strong class='noun'> n. </strong> Incorporated society of underwriters in london. [lloyd, proprietor of the coffee-house where the society originally met]</p>",
      },
    ],
  },
  {
    word: "Lloyd's list",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lloyd's list <strong class='noun'> n. </strong> Daily publication devoted to shipping news.</p>",
      },
    ],
  },
  {
    word: "Lloyd's register",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lloyd's register <strong class='noun'> n. </strong> Annual classified list of all ships.</p>",
      },
    ],
  },
  {
    word: "Ln",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ln <strong class='sing'> abbr. </strong> Natural logarithm.</p>",
      },
    ],
  },
  {
    word: "Lo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lo <strong class='n_pl'> int. </strong> Archaic look. lo and behold joc. Formula introducing mention of a surprising fact. [old english]</p>",
      },
    ],
  },
  {
    word: "Loach",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loach <strong class='noun'> n. </strong> (pl. Same or -es) small freshwater fish. [french]</p>",
      },
    ],
  },
  {
    word: "Load",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Load <strong class='noun'> —n. </strong> 1 a what is carried or to be carried. B amount usu. Or actually carried (often in <strong class='n_pl'> comb. </strong> : lorry-load of bricks). 2 burden or commitment of work, responsibility, care, etc. 3 <strong class='colloq'> colloq. </strong> A (in pl.; often foll. By of) plenty, a lot (loads of money, people). B (a load of) a quantity (a load of nonsense). 4 amount of power carried by an electric circuit or supplied by a generating station. —v. 1 a put a load on or aboard. B place (a load) aboard a ship, on a vehicle, etc. 2 (often foll. By up) (of a vehicle or person) take a load aboard. 3 (often foll. By with) burden, strain (loaded with food). 4 (also load up) (foll. By with) overburden, overwhelm (loaded us with work, with abuse). 5 a put ammunition in (a gun), film in (a camera), a cassette in (a tape recorder), a program in (a computer), etc. B put (a film, cassette, etc.) Into a device. 6 give a bias to. get a load of slang take note of. [old english, = way]</p>",
      },
    ],
  },
  {
    word: "Loaded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loaded <strong class='adj'> adj. </strong> 1 slang a rich. B drunk. C us drugged. 2 (of dice etc.) Weighted. 3 (of a question or statement) carrying some hidden implication.</p>",
      },
    ],
  },
  {
    word: "Loader",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loader <strong class='noun'> n. </strong> 1 loading-machine. 2 (in <strong class='n_pl'> comb. </strong> ) Gun, machine, lorry, etc., loaded in a specified way (breech-loader; front-loader). -loading adj. (in <strong class='n_pl'> comb. </strong> ) (in sense 2).</p>",
      },
    ],
  },
  {
    word: "Load line",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Load line <strong class='noun'> n. </strong> = *plimsoll line.</p>",
      },
    ],
  },
  {
    word: "Loadstone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loadstone <strong class='var'> var. </strong> Of *lodestone.</p>",
      },
    ],
  },
  {
    word: "Loaf1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loaf1 <strong class='noun'> n. </strong> (pl. Loaves) 1 unit of baked bread, usu. Of a standard size or shape. 2 other food made in the shape of a loaf and cooked. 3 slang head as the seat of common sense. [old english]</p>",
      },
    ],
  },
  {
    word: "Loaf2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loaf2 <strong class='verb'> v. </strong> (often foll. By about, around) spend time idly; hang about. [back-formation from *loafer]</p>",
      },
    ],
  },
  {
    word: "Loafer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loafer <strong class='noun'> n. </strong> 1 idle person. 2 (loafer) propr. Flat soft-soled leather shoe. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Loam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loam <strong class='noun'> n. </strong> Rich soil of clay, sand, and humus. loamy adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Loan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loan <strong class='noun'> —n. </strong> 1 thing lent, esp. A sum of money. 2 lending or being lent. —v. Lend (money, works of art, etc.). on loan being lent. [old english]</p>",
      },
    ],
  },
  {
    word: "Loan shark",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loan shark <strong class='noun'> n. </strong> Colloq. Person who lends money at exorbitant rates of interest.</p>",
      },
    ],
  },
  {
    word: "Loath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loath <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> (also loth) disinclined, reluctant (loath to admit it). [old english]</p>",
      },
    ],
  },
  {
    word: "Loathe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loathe <strong class='verb'> v. </strong> (-thing) detest, hate. loathing <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Loathsome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loathsome <strong class='adj'> adj. </strong> Arousing hatred or disgust; repulsive.</p>",
      },
    ],
  },
  {
    word: "Loaves",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Loaves <strong class='pl'> pl. </strong> Of *loaf1.</p>",
      },
    ],
  },
  {
    word: "Lob",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lob <strong class='noun'> —v. </strong> (-bb-) hit or throw (a ball etc.) Slowly or in a high arc. <strong class='noun'> —n. </strong> Such a ball. [probably low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Lobar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobar <strong class='adj'> adj. </strong> Of a lobe, esp. Of the lung (lobar pneumonia).</p>",
      },
    ],
  },
  {
    word: "Lobate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobate <strong class='adj'> adj. </strong> Having a lobe or lobes.</p>",
      },
    ],
  },
  {
    word: "Lobby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobby <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 porch, ante-room, entrance-hall, or corridor. 2 a (in the house of commons) large hall used esp. For interviews between mps and the public. B (also division lobby) each of two corridors to which mps retire to vote. 3 a body of lobbyists (anti-abortion lobby). B organized rally of lobbying members of the public. 4 (prec. By the) group of journalists who receive unattributable briefings from the government (lobby correspondent). —v. (-ies, -ied) 1 solicit the support of (an influential person). 2 (of members of the public) inform in order to influence (legislators, an mp, etc.). 3 frequent a parliamentary lobby. [latin lobia lodge]</p>",
      },
    ],
  },
  {
    word: "Lobbyist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobbyist <strong class='noun'> n. </strong> Person who lobbies an mp etc., esp. Professionally.</p>",
      },
    ],
  },
  {
    word: "Lobe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobe <strong class='noun'> n. </strong> 1 lower soft pendulous part of the outer ear. 2 similar part of other organs, esp. The brain, liver, and lung. lobed adj. [greek lobos lobe, pod]</p>",
      },
    ],
  },
  {
    word: "Lobelia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobelia <strong class='noun'> n. </strong> Plant with bright, esp. Blue, flowers. [lobel, name of a botanist]</p>",
      },
    ],
  },
  {
    word: "Lobotomy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobotomy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> incision into the frontal lobe of the brain, formerly used in some cases of mental disorder. [from *lobe]</p>",
      },
    ],
  },
  {
    word: "Lobscouse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobscouse <strong class='noun'> n. </strong> Sailor's dish of meat stewed with vegetables and ship's biscuit. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Lobster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobster <strong class='noun'> n. </strong> 1 marine crustacean with two pincer-like claws. 2 its flesh as food. [latin locusta lobster, *locust]</p>",
      },
    ],
  },
  {
    word: "Lobster-pot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobster-pot <strong class='noun'> n. </strong> Basket for trapping lobsters.</p>",
      },
    ],
  },
  {
    word: "Lobworm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lobworm <strong class='noun'> n. </strong> Large earthworm used as fishing-bait. [from *lob in obsolete sense ‘pendulous object’]</p>",
      },
    ],
  },
  {
    word: "Local",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Local <strong class='adj'> —adj.</strong> 1 belonging to, existing in, or peculiar to a particular place (local history). 2 of the neighbourhood (local paper). 3 of or affecting a part and not the whole (local anaesthetic). 4 (of a telephone call) to a nearby place and charged at a lower rate. <strong class='noun'> —n. </strong> 1 inhabitant of a particular place. 2 (often prec. By the) <strong class='colloq'> colloq. </strong> Local public house. 3 local anaesthetic. locally <strong class='adv'> adv. </strong> [latin locus place]</p>",
      },
    ],
  },
  {
    word: "Local authority",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Local authority <strong class='noun'> n. </strong> Administrative body in local government.</p>",
      },
    ],
  },
  {
    word: "Local colour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Local colour <strong class='noun'> n. </strong> Touches of detail in a story etc. Designed to provide a realistic background.</p>",
      },
    ],
  },
  {
    word: "Locale",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locale <strong class='noun'> n. </strong> Scene or locality of an event or occurrence. [french local]</p>",
      },
    ],
  },
  {
    word: "Local government",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Local government <strong class='noun'> n. </strong> System of administration of a county, district, parish, etc., by the elected representatives of those who live there.</p>",
      },
    ],
  },
  {
    word: "Locality",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 district. 2 site or scene of a thing. 3 thing's position. [latin: related to *local]</p>",
      },
    ],
  },
  {
    word: "Localize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Localize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 restrict or assign to a particular place. 2 invest with the characteristics of a particular place. 3 decentralize.</p>",
      },
    ],
  },
  {
    word: "Local time",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Local time <strong class='noun'> n. </strong> Time in a particular place.</p>",
      },
    ],
  },
  {
    word: "Local train",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Local train <strong class='noun'> n. </strong> Train stopping at all the stations on its route.</p>",
      },
    ],
  },
  {
    word: "Locate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 discover the exact place of. 2 establish in a place; situate. 3 state the locality of. [latin: related to *local] <strong class='usage'> Usage:- </strong>in standard english, it is not acceptable to use locate to mean merely ‘find’ as in can't locate my key.</p>",
      },
    ],
  },
  {
    word: "Location",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Location <strong class='noun'> n. </strong> 1 particular place. 2 locating. 3 natural, not studio, setting for a film etc. (filmed on location).</p>",
      },
    ],
  },
  {
    word: "Loc. Cit.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loc. Cit. <strong class='abbr'> Abbr. </strong> In the passage cited. [latin loco citato]</p>",
      },
    ],
  },
  {
    word: "Loch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loch <strong class='noun'> n. </strong> Scot. Lake or narrow inlet of the sea. [gaelic]</p>",
      },
    ],
  },
  {
    word: "Loci",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Loci <strong class='pl'> pl. </strong> Of *locus.</p>",
      },
    ],
  },
  {
    word: "Lock1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lock1 <strong class='noun'> —n. </strong> 1 mechanism for fastening a door etc., with a bolt that requires a key of a particular shape to work it. 2 confined section of a canal or river within sluice-gates, for moving boats from one level to another. 3 a turning of a vehicle's front wheels. B (in full full lock) maximum extent of this. 4 interlocked or jammed state. 5 wrestling-hold that keeps an opponent's limb fixed. 6 (in full lock forward) player in the second row of a rugby scrum. 7 mechanism for exploding the charge of a gun. —v. 1 a fasten with a lock. B (foll. By up) shut (a house etc.) Thus. C (of a door etc.) Be lockable. 2 a (foll. By up, in, into) enclose (a person or thing) by locking. B (foll. By up) <strong class='colloq'> colloq. </strong> Imprison (a person). 3 (often foll. By up, away) store inaccessibly (capital locked up in land). 4 (foll. By in) hold fast (in sleep, an embrace, a struggle, etc.). 5 (usu. In passive) (of land, hills, etc.) Enclose. 6 make or become rigidly fixed. 7 (cause to) jam or catch. lock on to (of a missile etc.) Automatically find and then track (a target). Lock out 1 keep out by locking the door. 2 (of an employer) subject (employees) to a lockout. Under lock and key locked up. lockable adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Lock2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lock2 <strong class='noun'> n. </strong> 1 portion of hair that hangs together. 2 (in pl.) The hair of the head (golden locks). [old english]</p>",
      },
    ],
  },
  {
    word: "Locker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locker <strong class='noun'> n. </strong> (usu. Lockable) cupboard or compartment, esp. For public use.</p>",
      },
    ],
  },
  {
    word: "Locket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locket <strong class='noun'> n. </strong> Small ornamental case for a portrait or lock of hair, worn on a chain round the neck. [french diminutive of loc latch, *lock1]</p>",
      },
    ],
  },
  {
    word: "Lockjaw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lockjaw <strong class='noun'> n. </strong> Form of tetanus in which the jaws become rigidly closed.</p>",
      },
    ],
  },
  {
    word: "Lock-keeper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lock-keeper <strong class='noun'> n. </strong> Person in charge of a river or canal lock.</p>",
      },
    ],
  },
  {
    word: "Lockout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lockout <strong class='noun'> n. </strong> Employer's exclusion of employees from the workplace until certain terms are agreed to.</p>",
      },
    ],
  },
  {
    word: "Locksmith",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locksmith <strong class='noun'> n. </strong> Maker and mender of locks.</p>",
      },
    ],
  },
  {
    word: "Lock, stock, and barrel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lock, stock, and barrel <strong class='adv'> adv. </strong> Completely.</p>",
      },
    ],
  },
  {
    word: "Lock-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lock-up <strong class='noun'> —n. </strong> 1 house or room for the temporary detention of prisoners. 2 premises that can be locked up, esp. A small shop. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> That can be locked up (lock-up garage).</p>",
      },
    ],
  },
  {
    word: "Loco1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loco1 <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='colloq'> colloq. </strong> Locomotive engine. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Loco2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loco2 <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> Slang crazy. [spanish]</p>",
      },
    ],
  },
  {
    word: "Locomotion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locomotion <strong class='noun'> n. </strong> Motion or the power of motion from place to place. [latin *locus, *motion]</p>",
      },
    ],
  },
  {
    word: "Locomotive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locomotive <strong class='noun'> —n. </strong> Engine for pulling trains. <strong class='adj'> —adj.</strong> Of, having, or effecting locomotion.</p>",
      },
    ],
  },
  {
    word: "Locum tenens",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locum tenens <strong class='noun'> n. </strong> (pl. Locum tenentes) (also <strong class='colloq'> colloq. </strong> Locum) deputy acting esp. For a doctor or clergyman. [latin, = (one) holding a place]</p>",
      },
    ],
  },
  {
    word: "Locus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locus <strong class='noun'> n. </strong> (pl. Loci) 1 position or locality. 2 line or curve etc. Formed by all the points satisfying certain conditions, or by the defined motion of a point, line, or surface. [latin, = place]</p>",
      },
    ],
  },
  {
    word: "Locus classicus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locus classicus <strong class='noun'> n. </strong> (pl. Loci classici) best known or most authoritative passage on a subject. [latin: related to *locus]</p>",
      },
    ],
  },
  {
    word: "Locust",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locust <strong class='noun'> n. </strong> African or asian grasshopper migrating in swarms and consuming all vegetation. [latin locusta locust, *lobster]</p>",
      },
    ],
  },
  {
    word: "Locution",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Locution <strong class='noun'> n. </strong> 1 word, phrase, or idiom. 2 style of speech. [latin loquor locut- speak]</p>",
      },
    ],
  },
  {
    word: "Lode",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lode <strong class='noun'> n. </strong> Vein of metal ore. [var. Of *load]</p>",
      },
    ],
  },
  {
    word: "Lodestar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lodestar <strong class='noun'> n. </strong> 1 star used as a guide in navigation, esp. The pole star. 2 a guiding principle. B object of pursuit. [from *lode in obsolete sense ‘way, journey’]</p>",
      },
    ],
  },
  {
    word: "Lodestone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lodestone <strong class='noun'> n. </strong> (also loadstone) 1 magnetic oxide of iron. 2 a piece of this used as a magnet. B thing that attracts.</p>",
      },
    ],
  },
  {
    word: "Lodge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lodge <strong class='noun'> —n. </strong> 1 small house at the entrance to a park or grounds of a large house, occupied by a gatekeeper etc. 2 small house used in the sporting seasons (hunting lodge). 3 porter's room at the gate of a college, factory, etc. 4 members or meeting-place of a branch of a society such as the freemasons. 5 beaver's or otter's lair. —v. (-ging) 1 a reside or live, esp. As a lodger. B provide with temporary accommodation. 2 submit or present (a complaint etc.) For attention. 3 become fixed or caught; stick. 4 deposit (money etc.) For security. 5 (foll. By in, with) place (power etc.) In a person. [french loge: related to *leaf]</p>",
      },
    ],
  },
  {
    word: "Lodger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lodger <strong class='noun'> n. </strong> Person paying for accommodation in another's house.</p>",
      },
    ],
  },
  {
    word: "Lodging",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lodging <strong class='noun'> n. </strong> 1 temporary accommodation (a lodging for the night). 2 (in pl.) Room or rooms rented for lodging in.</p>",
      },
    ],
  },
  {
    word: "Loess",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loess <strong class='noun'> n. </strong> Deposit of fine wind-blown soil, esp. In the basins of large rivers. [swiss german, = loose]</p>",
      },
    ],
  },
  {
    word: "Loft",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loft <strong class='noun'> —n. </strong> 1 attic. 2 room over a stable. 3 gallery in a church or hall. 4 pigeon-house. 5 backward slope on the face of a golf-club. 6 lofting stroke. —v. Send (a ball etc.) High up. [old english, = air, upper room]</p>",
      },
    ],
  },
  {
    word: "Lofty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lofty <strong class='adj'> adj. </strong> (-ier, -iest) 1 (of things) of imposing height. 2 haughty, aloof. 3 exalted, noble (lofty ideals). loftily <strong class='adv'> adv. </strong> Loftiness n.</p>",
      },
    ],
  },
  {
    word: "Log1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Log1 <strong class='noun'> —n. </strong> 1 unhewn piece of a felled tree; any large rough piece of wood, esp. Cut for firewood. 2 hist. Floating device for gauging a ship's speed. 3 record of events occurring during the voyage of a ship or aircraft. 4 any systematic record of deeds, experiences, etc. 5 = *logbook. —v. <strong class='gg'> (-gg-) </strong> 1 a enter (a ship's speed, or other transport details) in a logbook. B enter (data etc.) In a regular record. 2 attain (a distance, speed, etc., thus recorded) (had logged over 600 miles). 3 cut into logs. log in = log on. Log on (or off) open (or close) one's online access to a computer system. Sleep like a log sleep soundly. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Log2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Log2 <strong class='noun'> n. </strong> Logarithm. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Logan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Logan <strong class='noun'> n. </strong> (in full logan-stone) poised heavy stone rocking at a touch. [= (dial.) Logging, = rocking]</p>",
      },
    ],
  },
  {
    word: "Loganberry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loganberry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> dark red fruit, hybrid of a blackberry and a raspberry. [logan, name of a horticulturalist]</p>",
      },
    ],
  },
  {
    word: "Logarithm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Logarithm <strong class='noun'> n. </strong> One of a series of arithmetic exponents tabulated to simplify computation by making it possible to use addition and subtraction instead of multiplication and division. logarithmic adj. Logarithmically <strong class='adv'> adv. </strong> [greek logos reckoning, arithmos number]</p>",
      },
    ],
  },
  {
    word: "Logbook",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Logbook <strong class='noun'> n. </strong> 1 book containing a detailed record or log. 2 vehicle registration document.</p>",
      },
    ],
  },
  {
    word: "Log cabin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Log cabin <strong class='noun'> n. </strong> Hut built of logs.</p>",
      },
    ],
  },
  {
    word: "Logger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Logger <strong class='noun'> n. </strong> Us lumberjack.</p>",
      },
    ],
  },
  {
    word: "Loggerhead",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loggerhead <strong class='noun'> n. </strong> at loggerheads (often foll. By with) disagreeing or disputing. [probably dial. From logger wooden block]</p>",
      },
    ],
  },
  {
    word: "Loggia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loggia <strong class='noun'> n. </strong> Open-sided gallery or arcade. [italian, = *lodge]</p>",
      },
    ],
  },
  {
    word: "Logging",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Logging <strong class='noun'> n. </strong> Work of cutting and preparing forest timber.</p>",
      },
    ],
  },
  {
    word: "Logic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Logic <strong class='noun'> n. </strong> 1 a science of reasoning. B particular system or method of reasoning. 2 a chain of reasoning (regarded as sound or unsound). B use of or ability in argument. 3 inexorable force, compulsion, or consequence (the logic of events). 4 a principles used in designing a computer etc. B circuits using this. logician <strong class='noun'> n. </strong> [related to *-logic]</p>",
      },
    ],
  },
  {
    word: "-logic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-logic <strong class='n_pl'> comb. </strong> Form (also -logical) forming adjectives corresponding esp. To nouns in -logy (pathological; zoological). [greek -logikos]</p>",
      },
    ],
  },
  {
    word: "Logical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Logical <strong class='adj'> adj. </strong> 1 of or according to logic (the logical conclusion). 2 correctly reasoned. 3 defensible or explicable on the ground of consistency. 4 capable of correct reasoning. logicality <strong class='noun'> n. </strong> Logically <strong class='adv'> adv. </strong> [greek logos word, reason]</p>",
      },
    ],
  },
  {
    word: "-logist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-logist <strong class='n_pl'> comb. </strong> Form forming nouns meaning ‘person skilled in -logy’ (geologist).</p>",
      },
    ],
  },
  {
    word: "Logistics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Logistics <strong class='n_pl'> n.pl. </strong> 1 organization of (orig. Military) services and supplies. 2 organization of any complex operation. logistic adj. Logistical adj. Logistically <strong class='adv'> adv. </strong> [french loger lodge]</p>",
      },
    ],
  },
  {
    word: "Log-jam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Log-jam <strong class='noun'> n. </strong> Deadlock.</p>",
      },
    ],
  },
  {
    word: "Logo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Logo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> emblem of an organization used in its display material etc. [abbreviation of logotype from greek logos word]</p>",
      },
    ],
  },
  {
    word: "-logy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-logy <strong class='n_pl'> comb. </strong> Form forming nouns denoting: 1 a subject of study (biology). 2 speech or discourse or a characteristic of this (trilogy; tautology; phraseology). [greek -logia from logos word]</p>",
      },
    ],
  },
  {
    word: "Loin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loin <strong class='noun'> n. </strong> 1 (in pl.) Side and back of the body between the ribs and the hip-bones. 2 joint of meat from this part of an animal. [french loigne from latin lumbus]</p>",
      },
    ],
  },
  {
    word: "Loincloth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loincloth <strong class='noun'> n. </strong> Cloth worn round the hips, esp. As a sole garment.</p>",
      },
    ],
  },
  {
    word: "Loiter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loiter <strong class='verb'> v. </strong> 1 stand about idly; linger. 2 go slowly with frequent stops. loiter with intent linger in order to commit a felony. loiterer <strong class='noun'> n. </strong> [dutch]</p>",
      },
    ],
  },
  {
    word: "Loll",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loll <strong class='verb'> v. </strong> 1 stand, sit, or recline in a lazy attitude. 2 hang loosely. [imitative]</p>",
      },
    ],
  },
  {
    word: "Lollipop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lollipop <strong class='noun'> n. </strong> Hard sweet on a stick. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Lollipop man",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lollipop man <strong class='noun'> n. </strong> (also lollipop lady) <strong class='colloq'> colloq. </strong> Warden using a circular sign on a pole to stop traffic for children to cross the road.</p>",
      },
    ],
  },
  {
    word: "Lollop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lollop <strong class='verb'> v. </strong> (-p-) <strong class='colloq'> colloq. </strong> 1 flop about. 2 move in ungainly bounds. [probably from *loll, *trollop]</p>",
      },
    ],
  },
  {
    word: "Lolly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lolly <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 <strong class='colloq'> colloq. </strong> Lollipop. 2 = *ice lolly. 3 slang money. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Londoner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Londoner <strong class='noun'> n. </strong> Native or inhabitant of london.</p>",
      },
    ],
  },
  {
    word: "London pride",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>London pride <strong class='noun'> n. </strong> Pink-flowered saxifrage.</p>",
      },
    ],
  },
  {
    word: "Lone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lone <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> 1 solitary; without companions. 2 isolated. 3 unmarried, single (lone parent). [from *alone]</p>",
      },
    ],
  },
  {
    word: "Lone hand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lone hand <strong class='noun'> n. </strong> 1 hand played or player playing against the rest at cards. 2 person or action without allies.</p>",
      },
    ],
  },
  {
    word: "Lonely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lonely <strong class='adj'> adj. </strong> (-ier, -iest) 1 without companions (lonely existence). 2 sad because of this. 3 unfrequented, isolated, uninhabited. loneliness n.</p>",
      },
    ],
  },
  {
    word: "Lonely hearts",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lonely hearts <strong class='n_pl'> n.pl. </strong> People seeking friendship or marriage through a newspaper column, club, etc.</p>",
      },
    ],
  },
  {
    word: "Loner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loner <strong class='noun'> n. </strong> Person or animal that prefers to be alone.</p>",
      },
    ],
  },
  {
    word: "Lonesome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lonesome <strong class='adj'> adj. </strong> Esp. Us 1 lonely. 2 making one feel forlorn (a lonesome song).</p>",
      },
    ],
  },
  {
    word: "Lone wolf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lone wolf <strong class='noun'> n. </strong> Loner.</p>",
      },
    ],
  },
  {
    word: "Long1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long1 <strong class='adj'> —adj.</strong> (longer; longest) 1 measuring much from end to end in space or time. 2 (following a measurement) in length or duration (2 metres long; two months long). 3 a consisting of many items (a long list). B seemingly more than the stated amount; tedious (ten long miles). 4 of elongated shape. 5 lasting or reaching far back or forward in time (long friendship). 6 far-reaching; acting at a distance; involving a great interval or difference. 7 (of a vowel or syllable) having the greater of the two recognized durations. 8 (of odds or a chance) reflecting a low level of probability. 9 (of stocks) bought in large quantities in advance, with the expectation of a rise in price. 10 (foll. By on) <strong class='colloq'> colloq. </strong> Well supplied with. <strong class='noun'> —n. </strong> Long interval or period (will not take long; won't be long). <strong class='adv'> —adv. </strong> (longer; longest) 1 by or for a long time (long before; long ago). 2 (following nouns of duration) throughout a specified time (all day long). 3 (in compar.) After an implied point of time (shall not wait any longer). as (or so) long as provided that. Before long soon. In the long run (or term) eventually, ultimately. The long and the short of it 1 all that need be said. 2 the eventual outcome. Not by a long shot (or chalk) by no means. longish adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Long2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long2 <strong class='verb'> v. </strong> (foll. By for or to + infin.) Have a strong wish or desire for. [old english, = seem *long1 to]</p>",
      },
    ],
  },
  {
    word: "Long.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long. <strong class='abbr'> Abbr. </strong> Longitude.</p>",
      },
    ],
  },
  {
    word: "Longboat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longboat <strong class='noun'> n. </strong> Sailing-ship's largest boat.</p>",
      },
    ],
  },
  {
    word: "Longbow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longbow <strong class='noun'> n. </strong> Bow drawn by hand and shooting a long feathered arrow.</p>",
      },
    ],
  },
  {
    word: "Long-distance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-distance <strong class='attrib'> attrib. </strong> . Adj.travelling or operating between distant places. <strong class='adv'> —adv. </strong> Between distant places (phone long-distance).</p>",
      },
    ],
  },
  {
    word: "Long division",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long division <strong class='noun'> n. </strong> Division of numbers with details of the calculations written down.</p>",
      },
    ],
  },
  {
    word: "Long-drawn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-drawn <strong class='adj'> adj. </strong> (also long-drawn-out) prolonged.</p>",
      },
    ],
  },
  {
    word: "Longeron",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longeron <strong class='noun'> n. </strong> Longitudinal member of a plane's fuselage. [french]</p>",
      },
    ],
  },
  {
    word: "Longevity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longevity <strong class='noun'> n. </strong> Formal long life. [latin longus long, aevum age]</p>",
      },
    ],
  },
  {
    word: "Long face",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long face <strong class='noun'> n. </strong> Dismal expression.</p>",
      },
    ],
  },
  {
    word: "Longhand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longhand <strong class='noun'> n. </strong> Ordinary handwriting.</p>",
      },
    ],
  },
  {
    word: "Long haul",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long haul <strong class='noun'> n. </strong> 1 transport over a long distance. 2 prolonged effort or task.</p>",
      },
    ],
  },
  {
    word: "Longing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longing <strong class='noun'> —n. </strong> Intense desire. <strong class='adj'> —adj.</strong> Having or showing this. longingly adv.</p>",
      },
    ],
  },
  {
    word: "Long in the tooth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long in the tooth <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> Colloq. Old.</p>",
      },
    ],
  },
  {
    word: "Longitude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longitude <strong class='noun'> n. </strong> 1 angular distance east or west from a standard meridian such as greenwich to the meridian of any place. 2 angular distance of a celestial body, esp. Along the ecliptic. [latin longitudo length, from longus long]</p>",
      },
    ],
  },
  {
    word: "Longitudinal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longitudinal <strong class='adj'> adj. </strong> 1 of or in length. 2 running lengthwise. 3 of longitude. longitudinally adv.</p>",
      },
    ],
  },
  {
    word: "Long johns",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long johns <strong class='n_pl'> n.pl. </strong> Colloq. Long underpants.</p>",
      },
    ],
  },
  {
    word: "Long jump",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long jump <strong class='noun'> n. </strong> Athletic contest of jumping as far as possible along the ground in one leap.</p>",
      },
    ],
  },
  {
    word: "Long-life",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-life <strong class='adj'> adj. </strong> (of milk etc.) Treated to prolong its period of usability.</p>",
      },
    ],
  },
  {
    word: "Long-lived",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-lived <strong class='adj'> adj. </strong> Having a long life; durable.</p>",
      },
    ],
  },
  {
    word: "Long-lost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-lost <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> That has been lost for a long time.</p>",
      },
    ],
  },
  {
    word: "Long-playing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-playing <strong class='adj'> adj. </strong> (of a gramophone record) playing for about 20–30 minutes on each side.</p>",
      },
    ],
  },
  {
    word: "Long-range",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-range <strong class='adj'> adj. </strong> 1 having a long range. 2 relating to a period of time far into the future (long-range weather forecast).</p>",
      },
    ],
  },
  {
    word: "Long-running",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-running <strong class='adj'> adj. </strong> Continuing for a long time (a long-running musical).</p>",
      },
    ],
  },
  {
    word: "Longshore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longshore <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> 1 existing on or frequenting the shore. 2 directed along the shore. [from along shore]</p>",
      },
    ],
  },
  {
    word: "Longshoreman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longshoreman <strong class='noun'> n. </strong> Us docker.</p>",
      },
    ],
  },
  {
    word: "Long shot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long shot <strong class='noun'> n. </strong> 1 wild guess or venture. 2 bet at long odds.</p>",
      },
    ],
  },
  {
    word: "Long sight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long sight <strong class='noun'> n. </strong> Ability to see clearly only what is comparatively distant.</p>",
      },
    ],
  },
  {
    word: "Long-sighted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-sighted <strong class='adj'> adj. </strong> 1 having long sight. 2 far-sighted. long-sightedness n.</p>",
      },
    ],
  },
  {
    word: "Long-standing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-standing <strong class='adj'> adj. </strong> That has long existed.</p>",
      },
    ],
  },
  {
    word: "Long-suffering",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-suffering <strong class='adj'> adj. </strong> Bearing provocation patiently.</p>",
      },
    ],
  },
  {
    word: "Long-term",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-term <strong class='adj'> adj. </strong> Of or for a long period of time (long-term plans).</p>",
      },
    ],
  },
  {
    word: "Long wave",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long wave <strong class='noun'> n. </strong> Radio wave of frequency less than 300 khz.</p>",
      },
    ],
  },
  {
    word: "Longways",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Longways <strong class='adv'> adv. </strong> (also longwise) = *lengthways.</p>",
      },
    ],
  },
  {
    word: "Long-winded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Long-winded <strong class='adj'> adj. </strong> (of a speech or writing) tediously lengthy.</p>",
      },
    ],
  },
  {
    word: "Loo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loo <strong class='noun'> n. </strong> Colloq. Lavatory. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Loofah",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loofah <strong class='noun'> n. </strong> Rough bath-sponge made from the dried pod of a type of gourd. [arabic]</p>",
      },
    ],
  },
  {
    word: "Look",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Look <strong class='noun'> —v. </strong> 1 a (often foll. By at, down, up, etc.) Use one's sight; turn one's eyes in some direction. B turn one's eyes on; examine (looked me in the eyes; looked us up and down). 2 a make a visual or mental search (i'll look in the morning). B (foll. By at) consider, examine (must look at the facts). 3 (foll. By for) search for, seek, be on the watch for. 4 inquire (when one looks deeper). 5 have a specified appearance; seem (look a fool; future looks bleak). 6 (foll. By to) a consider; be concerned about (look to the future). B rely on (look to me for support). 7 (foll. By into) investigate. 8 (foll. By what, where, whether, etc.) Ascertain or observe by sight. 9 (of a thing) face some direction. 10 indicate (emotion etc.) By one's looks. 11 (foll. By that) take care; make sure. 12 (foll. By to + infin.) Aim (am looking to finish it soon). <strong class='noun'> —n. </strong> 1 act of looking; gaze, glance. 2 (in sing. Or pl.) Appearance of a face; expression. 3 appearance of a thing (by the look of it). 4 style, fashion (this year's look; the wet look). <strong class='pl'> —int. </strong> (also look here!) Calling attention, expressing a protest, etc. look after attend to; take care of. Look one's age appear as old as one really is. Look back 1 (foll. By on, to) turn one's thoughts to (something past). 2 (usu. With neg.) Cease to progress (he's never looked back). Look down on (or look down one's nose at) regard with contempt or superiority.</p>",
      },
    ],
  },
  {
    word: "Look-alike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Look-alike <strong class='noun'> n. </strong> Person or thing closely resembling another.</p>",
      },
    ],
  },
  {
    word: "Looker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Looker <strong class='noun'> n. </strong> 1 person of a specified appearance (good-looker). 2 <strong class='colloq'> colloq. </strong> Attractive woman.</p>",
      },
    ],
  },
  {
    word: "Looker-on",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Looker-on <strong class='noun'> n. </strong> (pl. Lookers-on) spectator.</p>",
      },
    ],
  },
  {
    word: "Look-in",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Look-in <strong class='noun'> n. </strong> Colloq. Chance of participation or success (never gets a look-in).</p>",
      },
    ],
  },
  {
    word: "Looking-glass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Looking-glass <strong class='noun'> n. </strong> Mirror.</p>",
      },
    ],
  },
  {
    word: "Lookout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lookout <strong class='noun'> n. </strong> 1 watch or looking out (on the lookout). 2 a observation-post. B person etc. Stationed to keep watch. 3 prospect (it's a bad lookout). 4 <strong class='colloq'> colloq. </strong> Person's own concern (that's your lookout).</p>",
      },
    ],
  },
  {
    word: "Loom1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loom1 <strong class='noun'> n. </strong> Apparatus for weaving. [old english]</p>",
      },
    ],
  },
  {
    word: "Loom2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loom2 <strong class='verb'> v. </strong> 1 appear dimly, esp. As a vague and often threatening shape. 2 (of an event) be ominously close. [probably low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Loon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loon <strong class='noun'> n. </strong> 1 a kind of diving bird. 2 <strong class='colloq'> colloq. </strong> Crazy person (cf. *loony). [old norse]</p>",
      },
    ],
  },
  {
    word: "Loony  slang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loony slang <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> lunatic. <strong class='adj'> —adj.</strong> (-ier, -iest) crazy. looniness <strong class='noun'> n. </strong> [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Loony-bin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loony-bin <strong class='noun'> n. </strong> Slang offens. Mental home or hospital.</p>",
      },
    ],
  },
  {
    word: "Loop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loop <strong class='noun'> —n. </strong> 1 a figure produced by a curve, or a doubled thread etc., that crosses itself. B thing, path, etc., forming this figure. 2 similarly shaped attachment used as a fastening. 3 ring etc. As a handle etc. 4 contraceptive coil. 5 (in full loop-line) railway or telegraph line that diverges from a main line and joins it again. 6 skating or aerobatic manoeuvre describing a loop. 7 complete circuit for an electric current. 8 endless band of tape or film allowing continuous repetition. 9 sequence of computer operations repeated until some condition is satisfied. —v. 1 form or bend into a loop. 2 fasten with a loop or loops. 3 form a loop. 4 (also loop the loop) fly in a circle vertically. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Loophole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loophole <strong class='noun'> n. </strong> 1 means of evading a rule etc. Without infringing it. 2 narrow vertical slit in the wall of a fort etc.</p>",
      },
    ],
  },
  {
    word: "Loopy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loopy <strong class='adj'> adj. </strong> (-ier, -iest) slang crazy, daft.</p>",
      },
    ],
  },
  {
    word: "Loose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loose <strong class='adj'> —adj.</strong> 1 not tightly held, fixed, etc. (loose handle; loose stones). 2 free from bonds or restraint. 3 not held together (loose papers). 4 not compact or dense (loose soil). 5 inexact (loose translation). 6 morally lax. 7 (of the tongue) indiscreet. 8 tending to diarrhoea. 9 (in <strong class='n_pl'> comb. </strong> ) Loosely (loose-fitting). —v. <strong class='sing'>(-sing)</strong> 1 free; untie or detach; release. 2 relax (loosed my hold). 3 discharge (a missile). at a loose end unoccupied. On the loose 1 escaped from captivity. 2 enjoying oneself freely. loosely <strong class='adv'> adv. </strong> Looseness <strong class='noun'> n. </strong> Loosish adj. [old norse]</p>",
      },
    ],
  },
  {
    word: "Loose cover",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loose cover <strong class='noun'> n. </strong> Removable cover for an armchair etc.</p>",
      },
    ],
  },
  {
    word: "Loose-leaf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loose-leaf <strong class='adj'> adj. </strong> (of a notebook etc.) With pages that can be removed and replaced.</p>",
      },
    ],
  },
  {
    word: "Loosen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loosen <strong class='verb'> v. </strong> Make or become loose or looser. loosen a person's tongue make a person talk freely. Loosen up 1 relax. 2 limber up.</p>",
      },
    ],
  },
  {
    word: "Loot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loot <strong class='noun'> —n. </strong> 1 spoil, booty. 2 slang money. —v. 1 rob or steal, esp. After rioting etc. 2 plunder. looter <strong class='noun'> n. </strong> [hindi]</p>",
      },
    ],
  },
  {
    word: "Lop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lop <strong class='verb'> v. </strong> (-pp-) 1 a (often foll. By off, away) cut or remove (a part or parts) from a whole, esp. Branches from a tree. B remove branches from (a tree). 2 (often foll. By off) remove (items) as superfluous. [old english]</p>",
      },
    ],
  },
  {
    word: "Lope",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lope <strong class='noun'> —v. </strong> (-ping) run with a long bounding stride. <strong class='noun'> —n. </strong> Long bounding stride. [old norse: related to *leap]</p>",
      },
    ],
  },
  {
    word: "Lop-eared",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lop-eared <strong class='adj'> adj. </strong> Having drooping ears. [related to *lob]</p>",
      },
    ],
  },
  {
    word: "Lopsided",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lopsided <strong class='adj'> adj. </strong> Unevenly balanced. lopsidedness <strong class='noun'> n. </strong> [related to *lob]</p>",
      },
    ],
  },
  {
    word: "Loquacious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loquacious <strong class='adj'> adj. </strong> Talkative. loquacity <strong class='noun'> n. </strong> [latin loquor speak]</p>",
      },
    ],
  },
  {
    word: "Loquat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loquat <strong class='noun'> n. </strong> 1 small yellow egg-shaped fruit. 2 tree bearing it. [chinese]</p>",
      },
    ],
  },
  {
    word: "Lord",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lord <strong class='noun'> —n. </strong> 1 master or ruler. 2 hist. Feudal superior, esp. Of a manor. 3 peer of the realm or person with the title lord. 4 (lord) (often prec. By the) god or christ. 5 (lord) a prefixed as the designation of a marquis, earl, viscount, or baron, or (to the christian name) of the younger son of a duke or marquis. B (the lords) = *house of lords. <strong class='pl'> —int. </strong> (lord, good lord, etc.) Expressing surprise, dismay, etc. lord it over domineer. [old english, = bread-keeper: related to *loaf1, *ward]</p>",
      },
    ],
  },
  {
    word: "Lord chamberlain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lord chamberlain <strong class='noun'> n. </strong> Official in charge of the royal household.</p>",
      },
    ],
  },
  {
    word: "Lord chancellor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lord chancellor <strong class='noun'> n. </strong> (also lord high chancellor) highest officer of the crown, presiding in the house of lords etc.</p>",
      },
    ],
  },
  {
    word: "Lord chief justice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lord chief justice <strong class='noun'> n. </strong> President of the queen's bench division.</p>",
      },
    ],
  },
  {
    word: "Lord lieutenant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lord lieutenant <strong class='noun'> n. </strong> 1 chief executive authority and head of magistrates in each county. 2 hist. Viceroy of ireland.</p>",
      },
    ],
  },
  {
    word: "Lordly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lordly <strong class='adj'> adj. </strong> (-ier, -iest) 1 haughty, imperious. 2 suitable for a lord. lordliness n.</p>",
      },
    ],
  },
  {
    word: "Lord mayor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lord mayor <strong class='noun'> n. </strong> Title of the mayor in some large cities.</p>",
      },
    ],
  },
  {
    word: "Lord privy seal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lord privy seal <strong class='noun'> n. </strong> Senior cabinet minister without official duties.</p>",
      },
    ],
  },
  {
    word: "Lords and ladies",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lords and ladies <strong class='noun'> n. </strong> Wild arum.</p>",
      },
    ],
  },
  {
    word: "Lord's day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lord's day <strong class='noun'> n. </strong> Sunday.</p>",
      },
    ],
  },
  {
    word: "Lordship",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lordship <strong class='noun'> n. </strong> 1 (usu. Lordship) title used in addressing or referring to a man with the rank of lord (your lordship; his lordship). 2 (foll. By over) dominion, rule.</p>",
      },
    ],
  },
  {
    word: "Lord's prayer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lord's prayer <strong class='noun'> n. </strong> The our father.</p>",
      },
    ],
  },
  {
    word: "Lords spiritual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lords spiritual <strong class='n_pl'> n.pl. </strong> Bishops in the house of lords.</p>",
      },
    ],
  },
  {
    word: "Lord's supper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lord's supper <strong class='noun'> n. </strong> Eucharist.</p>",
      },
    ],
  },
  {
    word: "Lords temporal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lords temporal <strong class='n_pl'> n.pl. </strong> Members of the house of lords other than bishops.</p>",
      },
    ],
  },
  {
    word: "Lore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lore <strong class='noun'> n. </strong> Body of traditions and knowledge on a subject or held by a particular group (bird lore; gypsy lore). [old english: related to *learn]</p>",
      },
    ],
  },
  {
    word: "Lorgnette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lorgnette <strong class='noun'> n. </strong> Pair of eyeglasses or opera-glasses on a long handle. [french lorgner to squint]</p>",
      },
    ],
  },
  {
    word: "Lorn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lorn <strong class='adj'> adj. </strong> Archaic desolate, forlorn. [old english, <strong class='archaic_it_was'>past part. Of *</strong> lose]</p>",
      },
    ],
  },
  {
    word: "Lorry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lorry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> large vehicle for transporting goods etc. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Lose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lose <strong class='verb'> v. </strong> (-sing; past and  <strong class='archaic_it_was'>past part.</strong> Lost) 1 be deprived of or cease to have, esp. By negligence. 2 be deprived of (a person) by death. 3 become unable to find, follow, or understand (lose one's way). 4 let or have pass from one's control or reach (lost my chance; lost his composure). 5 be defeated in (a game, lawsuit, battle, etc.). 6 get rid of (lost our pursuers; lose weight). 7 forfeit (a right to a thing). 8 spend (time, efforts, etc.) To no purpose.</p>",
      },
    ],
  },
  {
    word: "Loser",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loser <strong class='noun'> n. </strong> 1 person or thing that loses, esp. A contest (is a bad loser). 2 <strong class='colloq'> colloq. </strong> Person who regularly fails.</p>",
      },
    ],
  },
  {
    word: "Loss",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loss <strong class='noun'> n. </strong> 1 losing or being lost. 2 thing or amount lost. 3 detriment resulting from losing. at a loss (sold etc.) For less than was paid for it. Be at a loss be puzzled or uncertain. [probably back-formation from *lost]</p>",
      },
    ],
  },
  {
    word: "Loss-leader",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loss-leader <strong class='noun'> n. </strong> Item sold at a loss to attract customers.</p>",
      },
    ],
  },
  {
    word: "Lost  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lost  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>lose.</p>",
      },
    ],
  },
  {
    word: "Lost cause",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lost cause <strong class='noun'> n. </strong> Hopeless undertaking.</p>",
      },
    ],
  },
  {
    word: "Lot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lot <strong class='noun'> n. </strong> 1 <strong class='colloq'> colloq. </strong> (prec. By a or in pl.) A a large number or amount (a lot of people; lots of milk). B <strong class='colloq'> colloq. </strong> Much (a lot warmer; smiles a lot). 2 a each of a set of objects used to make a chance selection. B this method of deciding (chosen by lot). 3 share or responsibility resulting from it. 4 person's destiny, fortune, or condition. 5 (esp. Us) plot; allotment of land (parking lot). 6 article or set of articles for sale at an auction etc. 7 group of associated persons or things. cast (or draw) lots decide by lots. Throw in one's lot with decide to share the fortunes of. The (or the whole) lot the total number or quantity. A whole lot <strong class='colloq'> colloq. </strong> Very much (is a whole lot better). [old english] <strong class='usage'> Usage:- </strong>in sense 1a, a lot of is somewhat informal, but acceptable in serious writing, whereas lots of is not acceptable.</p>",
      },
    ],
  },
  {
    word: "Loth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Loth <strong class='var'> var. </strong> Of *loath.</p>",
      },
    ],
  },
  {
    word: "Lothario",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lothario <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> libertine. [name of a character in a play]</p>",
      },
    ],
  },
  {
    word: "Lotion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lotion <strong class='noun'> n. </strong> Medicinal or cosmetic liquid preparation applied externally. [latin lavo lot- wash]</p>",
      },
    ],
  },
  {
    word: "Lottery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lottery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 means of raising money by selling numbered tickets and giving prizes to the holders of numbers drawn at random. 2 thing whose success is governed by chance. [dutch: related to *lot]</p>",
      },
    ],
  },
  {
    word: "Lotto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lotto <strong class='noun'> n. </strong> Game of chance like bingo, but with numbers drawn by players instead of called. [italian]</p>",
      },
    ],
  },
  {
    word: "Lotus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lotus <strong class='noun'> n. </strong> 1 legendary plant inducing luxurious languor when eaten. 2 a kind of water lily etc., esp. Used symbolically in hinduism and buddhism. [greek lotos]</p>",
      },
    ],
  },
  {
    word: "Lotus-eater",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lotus-eater <strong class='noun'> n. </strong> Person given to indolent enjoyment.</p>",
      },
    ],
  },
  {
    word: "Lotus position",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lotus position <strong class='noun'> n. </strong> Cross-legged position of meditation with the feet resting on the thighs.</p>",
      },
    ],
  },
  {
    word: "Loud",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loud <strong class='adj'> —adj.</strong> 1 strongly audible, noisy. 2 (of colours etc.) Gaudy, obtrusive. <strong class='adv'> —adv. </strong> Loudly. out loud aloud. loudish adj. Loudly <strong class='adv'> adv. </strong> Loudness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Loud hailer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loud hailer <strong class='noun'> n. </strong> Electronic device for amplifying the voice.</p>",
      },
    ],
  },
  {
    word: "Loudspeaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loudspeaker <strong class='noun'> n. </strong> Apparatus that converts electrical signals into sound.</p>",
      },
    ],
  },
  {
    word: "Lough",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lough <strong class='noun'> n. </strong> Ir. Lake, arm of the sea. [irish: related to *loch]</p>",
      },
    ],
  },
  {
    word: "Lounge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lounge <strong class='noun'> —v. </strong> (-ging) 1 recline comfortably; loll. 2 stand or move about idly. <strong class='noun'> —n. </strong> 1 place for lounging, esp.: a a sitting-room in a house. B a public room (e.g. In a hotel). C a place in an airport etc. With seats for waiting passengers. 2 spell of lounging. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Lounge bar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lounge bar <strong class='noun'> n. </strong> More comfortable bar in a pub etc.</p>",
      },
    ],
  },
  {
    word: "Lounge suit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lounge suit <strong class='noun'> n. </strong> Man's suit for ordinary day (esp. Business) wear.</p>",
      },
    ],
  },
  {
    word: "Lour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lour <strong class='verb'> v. </strong> (also lower) 1 frown; look sullen. 2 (of the sky etc.) Look dark and threatening. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Louse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Louse <strong class='noun'> —n. </strong> 1 (pl. Lice) parasitic insect. 2 (pl. Louses) slang contemptible person. —v. <strong class='sing'>(-sing)</strong> delouse. louse up slang make a mess of. [old english]</p>",
      },
    ],
  },
  {
    word: "Lousy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lousy <strong class='adj'> adj. </strong> (-ier, -iest) 1 <strong class='colloq'> colloq. </strong> Very bad; disgusting; ill (feel lousy). 2 (often foll. By with) <strong class='colloq'> colloq. </strong> Well supplied, teeming. 3 infested with lice. lousily <strong class='adv'> adv. </strong> Lousiness n.</p>",
      },
    ],
  },
  {
    word: "Lout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lout <strong class='noun'> n. </strong> Rough-mannered person. loutish adj. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Louvre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Louvre <strong class='noun'> n. </strong> (also louver) 1 each of a set of overlapping slats designed to admit air and some light and exclude rain. 2 domed structure on a roof with side openings for ventilation etc. louvred adj. [french lover skylight]</p>",
      },
    ],
  },
  {
    word: "Lovable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lovable <strong class='adj'> adj. </strong> (also loveable) inspiring love or affection.</p>",
      },
    ],
  },
  {
    word: "Lovage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lovage <strong class='noun'> n. </strong> Herb used for flavouring etc. [french levesche from latin ligusticum ligurian]</p>",
      },
    ],
  },
  {
    word: "Lovat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lovat <strong class='noun'> n. </strong> & adj. Muted green. [lovat in scotland]</p>",
      },
    ],
  },
  {
    word: "Love",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love <strong class='noun'> —n. </strong> 1 deep affection or fondness. 2 sexual passion. 3 sexual relations. 4 a beloved one; sweetheart (often as a form of address). B <strong class='colloq'> colloq. </strong> Form of address regardless of affection. 5 <strong class='colloq'> colloq. </strong> Person of whom one is fond. 6 affectionate greetings (give him my love). 7 (in games) no score; nil. —v. <strong class='ving'> (-ving) </strong> 1 feel love or a deep fondness for. 2 delight in; admire; greatly cherish. 3 <strong class='colloq'> colloq. </strong> Like very much (loves books). 4 (foll. By verbal noun, or to + infin.) Be inclined, esp. As a habit; greatly enjoy (children love dressing up; loves to run). fall in love (often foll. By with) suddenly begin to love. For love for pleasure not profit. For the love of for the sake of. In love (often foll. By with) enamoured (of). Make love <strong class='adj'> (often foll. By to) </strong> 1 have sexual intercourse (with). 2 archaic pay amorous attention (to). Not for love or money <strong class='colloq'> colloq. </strong> Not in any circumstances. [old english]</p>",
      },
    ],
  },
  {
    word: "Loveable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loveable <strong class='var'> var. </strong> Of *lovable.</p>",
      },
    ],
  },
  {
    word: "Love affair",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love affair <strong class='noun'> n. </strong> Romantic or sexual relationship between two people.</p>",
      },
    ],
  },
  {
    word: "Love-bird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love-bird <strong class='noun'> n. </strong> Parrot, esp. One seeming to show great affection for its mate.</p>",
      },
    ],
  },
  {
    word: "Love bite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love bite <strong class='noun'> n. </strong> Bruise made by a partner's biting etc. During lovemaking.</p>",
      },
    ],
  },
  {
    word: "Love-child",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love-child <strong class='noun'> n. </strong> Child of unmarried parents.</p>",
      },
    ],
  },
  {
    word: "Love-hate relationship",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love-hate relationship <strong class='noun'> n. </strong> Intense relationship involving ambivalent emotions.</p>",
      },
    ],
  },
  {
    word: "Love-in-a-mist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love-in-a-mist <strong class='noun'> n. </strong> Blue-flowered cultivated plant.</p>",
      },
    ],
  },
  {
    word: "Loveless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loveless <strong class='adj'> adj. </strong> Unloving or unloved or both.</p>",
      },
    ],
  },
  {
    word: "Love-lies-bleeding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love-lies-bleeding <strong class='noun'> n. </strong> Cultivated plant with drooping spikes of purple-red blooms.</p>",
      },
    ],
  },
  {
    word: "Lovelorn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lovelorn <strong class='adj'> adj. </strong> Pining from unrequited love.</p>",
      },
    ],
  },
  {
    word: "Lovely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lovely <strong class='adj'> —adj.</strong> (-ier, -iest) 1 <strong class='colloq'> colloq. </strong> Pleasing, delightful. 2 beautiful. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> <strong class='colloq'> colloq. </strong> Pretty woman. lovely and <strong class='colloq'> colloq. </strong> Delightfully (lovely and warm). loveliness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Lovemaking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lovemaking <strong class='noun'> n. </strong> 1 sexual play, esp. Intercourse. 2 archaic courtship.</p>",
      },
    ],
  },
  {
    word: "Love-nest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love-nest <strong class='noun'> n. </strong> Colloq. Secluded retreat for (esp. Illicit) lovers.</p>",
      },
    ],
  },
  {
    word: "Lover",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lover <strong class='noun'> n. </strong> 1 person in love with another. 2 person with whom another is having sexual relations. 3 (in pl.) Unmarried couple in love or having sexual relations. 4 person who likes or enjoys a specified thing (music lover).</p>",
      },
    ],
  },
  {
    word: "Love-seat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Love-seat <strong class='noun'> n. </strong> Small sofa in the shape of an s, with two seats facing in opposite directions.</p>",
      },
    ],
  },
  {
    word: "Lovesick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lovesick <strong class='adj'> adj. </strong> Languishing with love.</p>",
      },
    ],
  },
  {
    word: "Lovey-dovey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lovey-dovey <strong class='adj'> adj. </strong> Colloq. Fondly affectionate, sentimental.</p>",
      },
    ],
  },
  {
    word: "Loving",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loving <strong class='adj'> —adj.</strong> Feeling or showing love; affectionate. <strong class='noun'> —n. </strong> Affection; love. lovingly adv.</p>",
      },
    ],
  },
  {
    word: "Loving-cup",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loving-cup <strong class='noun'> n. </strong> Two-handled drinking-cup.</p>",
      },
    ],
  },
  {
    word: "Low1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low1 <strong class='adj'> —adj.</strong> 1 not high or tall (low wall). 2 a not elevated in position (low altitude). B (of the sun) near the horizon. 3 of or in humble rank or position (of low birth).</p>",
      },
    ],
  },
  {
    word: "Low2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low2 <strong class='noun'> —n. </strong> Sound made by cattle; moo. —v. Make this sound. [old english]</p>",
      },
    ],
  },
  {
    word: "Low-born",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low-born <strong class='adj'> adj. </strong> Of humble birth.</p>",
      },
    ],
  },
  {
    word: "Lowbrow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lowbrow <strong class='adj'> —adj.</strong> Not intellectual or cultured. <strong class='noun'> —n. </strong> Lowbrow person.</p>",
      },
    ],
  },
  {
    word: "Low church",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low church <strong class='noun'> n. </strong> Section of the church of england attaching little importance to ritual, priestly authority, and the sacraments.</p>",
      },
    ],
  },
  {
    word: "Low-class",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low-class <strong class='adj'> adj. </strong> Of low quality or social class.</p>",
      },
    ],
  },
  {
    word: "Low comedy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low comedy <strong class='noun'> n. </strong> Comedy bordering on farce.</p>",
      },
    ],
  },
  {
    word: "Low countries",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low countries <strong class='n_pl'> n.pl. </strong> The netherlands, belgium, and luxembourg.</p>",
      },
    ],
  },
  {
    word: "Low-down",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low-down <strong class='adj'> —adj.</strong> Mean, dishonourable. <strong class='noun'> —n. </strong> Colloq. (prec. By the; usu. Foll. By on) relevant information.</p>",
      },
    ],
  },
  {
    word: "Lower1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lower1 <strong class='adj'> —adj.</strong> (compar. Of *low1). 1 less high in position or status. 2 situated below another part (lower lip). 3 a situated on less high land (lower egypt). B situated to the south (lower california). 4 (of a mammal, plant, etc.) Evolved to only a slight degree. <strong class='adv'> —adv. </strong> In or to a lower position, status, etc. lowermost adj.</p>",
      },
    ],
  },
  {
    word: "Lower2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lower2 <strong class='verb'> v. </strong> 1 let or haul down. 2 make or become lower. 3 degrade.</p>",
      },
    ],
  },
  {
    word: "Lower3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lower3 <strong class='var'> var. </strong> Of *lour.</p>",
      },
    ],
  },
  {
    word: "Lower case",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lower case <strong class='noun'> n. </strong> Small letters.</p>",
      },
    ],
  },
  {
    word: "Lower class",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lower class <strong class='noun'> n. </strong> Working class.</p>",
      },
    ],
  },
  {
    word: "Lower house",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lower house <strong class='noun'> n. </strong> Larger and usu. Elected body in a legislature, esp. The house of commons.</p>",
      },
    ],
  },
  {
    word: "Lowest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lowest <strong class='adj'> adj. </strong> (superl. Of *low1) least high in position or status.</p>",
      },
    ],
  },
  {
    word: "Lowest common denominator",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lowest common denominator <strong class='noun'> n. </strong> 1 math. Lowest common multiple of the denominators of several fractions. 2 the worst or most vulgar common feature of members of a group.</p>",
      },
    ],
  },
  {
    word: "Lowest common multiple",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lowest common multiple <strong class='noun'> n. </strong> Math. Least quantity that is a multiple of two or more given quantities.</p>",
      },
    ],
  },
  {
    word: "Low frequency",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low frequency <strong class='noun'> n. </strong> Frequency, esp. In radio, 30 to 300 kilohertz.</p>",
      },
    ],
  },
  {
    word: "Low gear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low gear <strong class='noun'> n. </strong> Gear such that the driven end of a transmission revolves slower than the driving end.</p>",
      },
    ],
  },
  {
    word: "Low-grade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low-grade <strong class='adj'> adj. </strong> Of low quality.</p>",
      },
    ],
  },
  {
    word: "Low-key",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low-key <strong class='adj'> adj. </strong> Lacking intensity, restrained.</p>",
      },
    ],
  },
  {
    word: "Lowland",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lowland <strong class='noun'> —n. </strong> (usu. In pl.) Low-lying country. <strong class='adj'> —adj.</strong> Of or in lowland. lowlander n.</p>",
      },
    ],
  },
  {
    word: "Low-level",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low-level <strong class='adj'> adj. </strong> (of a computer language) close in form to machine code.</p>",
      },
    ],
  },
  {
    word: "Lowly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lowly <strong class='adj'> adj. </strong> (-ier, -iest) humble; unpretentious. lowliness n.</p>",
      },
    ],
  },
  {
    word: "Low-lying",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low-lying <strong class='adj'> adj. </strong> Near to the ground or sea level.</p>",
      },
    ],
  },
  {
    word: "Low-pitched",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low-pitched <strong class='adj'> adj. </strong> 1 (of a sound) low. 2 (of a roof) having only a slight slope.</p>",
      },
    ],
  },
  {
    word: "Low pressure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low pressure <strong class='noun'> n. </strong> 1 low degree of activity or exertion. 2 atmospheric condition with the pressure below average.</p>",
      },
    ],
  },
  {
    word: "Low-rise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low-rise <strong class='adj'> —adj.</strong> (of a building) having few storeys. <strong class='noun'> —n. </strong> Such a building.</p>",
      },
    ],
  },
  {
    word: "Low season",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low season <strong class='noun'> n. </strong> Period of fewest visitors at a resort etc.</p>",
      },
    ],
  },
  {
    word: "Low sunday",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low sunday <strong class='noun'> n. </strong> Sunday after easter.</p>",
      },
    ],
  },
  {
    word: "Low tide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Low tide <strong class='noun'> n. </strong> (also low water) time or level of the tide at its ebb.</p>",
      },
    ],
  },
  {
    word: "Loyal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loyal <strong class='adj'> adj. </strong> 1 <strong class='adj'> (often foll. By to) </strong> faithful. 2 steadfast in allegiance etc. loyally <strong class='adv'> adv. </strong> Loyalty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. [latin: related to *legal]</p>",
      },
    ],
  },
  {
    word: "Loyalist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loyalist <strong class='noun'> n. </strong> 1 person who remains loyal to the legitimate sovereign etc. 2 (loyalist) (esp. Extremist) supporter of union between great britain and northern ireland. loyalism n.</p>",
      },
    ],
  },
  {
    word: "Loyal toast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Loyal toast <strong class='noun'> n. </strong> Toast to the sovereign.</p>",
      },
    ],
  },
  {
    word: "Lozenge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lozenge <strong class='noun'> n. </strong> 1 rhombus. 2 small sweet or medicinal tablet to be dissolved in the mouth. 3 lozenge-shaped object. [french]</p>",
      },
    ],
  },
  {
    word: "Lp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lp <strong class='sing'> abbr. </strong> Long-playing (record).</p>",
      },
    ],
  },
  {
    word: "L-plate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L-plate <strong class='noun'> n. </strong> Sign bearing the letter l, attached to a vehicle to show that it is being driven by a learner. [from *plate]</p>",
      },
    ],
  },
  {
    word: "Lpo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lpo <strong class='sing'> abbr. </strong> London philharmonic orchestra.</p>",
      },
    ],
  },
  {
    word: "Lsd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lsd <strong class='sing'> abbr. </strong> Lysergic acid diethylamide, a powerful hallucinogenic drug.</p>",
      },
    ],
  },
  {
    word: "L.s.d.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>L.s.d. <strong class='noun'> n. </strong> (also £.s.d.) 1 hist. Pounds, shillings, and pence (in former british currency). 2 money, riches. [latin librae, solidi, denarii]</p>",
      },
    ],
  },
  {
    word: "Lse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lse <strong class='sing'> abbr. </strong> London school of economics.</p>",
      },
    ],
  },
  {
    word: "Lso",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lso <strong class='sing'> abbr. </strong> London symphony orchestra.</p>",
      },
    ],
  },
  {
    word: "Lt.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lt. <strong class='abbr'> Abbr. </strong> 1 lieutenant. 2 light.</p>",
      },
    ],
  },
  {
    word: "Ltd.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ltd. <strong class='abbr'> Abbr. </strong> Limited.</p>",
      },
    ],
  },
  {
    word: "Lu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lu <strong class='prep'> symb. </strong> Lutetium.</p>",
      },
    ],
  },
  {
    word: "Lubber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lubber <strong class='noun'> n. </strong> Clumsy fellow, lout. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Lubricant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lubricant <strong class='noun'> n. </strong> Substance used to reduce friction.</p>",
      },
    ],
  },
  {
    word: "Lubricate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lubricate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 apply oil or grease etc. To. 2 make slippery. lubrication <strong class='noun'> n. </strong> Lubricator <strong class='noun'> n. </strong> [latin lubricus slippery]</p>",
      },
    ],
  },
  {
    word: "Lubricious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lubricious <strong class='adj'> adj. </strong> 1 slippery, evasive. 2 lewd. lubricity <strong class='noun'> n. </strong> [latin: related to *lubricate]</p>",
      },
    ],
  },
  {
    word: "Lucerne",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lucerne <strong class='noun'> n. </strong> = *alfalfa. [provençal, = glow-worm, referring to its shiny seeds]</p>",
      },
    ],
  },
  {
    word: "Lucid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lucid <strong class='adj'> adj. </strong> 1 expressing or expressed clearly. 2 sane. lucidity <strong class='noun'> n. </strong> Lucidly <strong class='adv'> adv. </strong> Lucidness <strong class='noun'> n. </strong> [latin lux luc- light]</p>",
      },
    ],
  },
  {
    word: "Lucifer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lucifer <strong class='noun'> n. </strong> Satan. [latin: related to *lucid, fero bring]</p>",
      },
    ],
  },
  {
    word: "Luck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luck <strong class='noun'> n. </strong> 1 good or bad fortune. 2 circumstances of life (beneficial or not) brought by this. 3 good fortune; success due to chance (in luck; out of luck). no such luck <strong class='colloq'> colloq. </strong> Unfortunately not. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Luckless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luckless <strong class='adj'> adj. </strong> Unlucky; ending in failure.</p>",
      },
    ],
  },
  {
    word: "Lucky",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lucky <strong class='adj'> adj. </strong> (-ier, -iest) 1 having or resulting from good luck. 2 bringing good luck (lucky charm). luckily adv.</p>",
      },
    ],
  },
  {
    word: "Lucky dip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lucky dip <strong class='noun'> n. </strong> Tub containing articles varying in value and chosen at random.</p>",
      },
    ],
  },
  {
    word: "Lucrative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lucrative <strong class='adj'> adj. </strong> Profitable. lucratively <strong class='adv'> adv. </strong> Lucrativeness <strong class='noun'> n. </strong> [latin: related to *lucre]</p>",
      },
    ],
  },
  {
    word: "Lucre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lucre <strong class='noun'> n. </strong> Derog. Financial gain. [latin lucrum gain]</p>",
      },
    ],
  },
  {
    word: "Luddite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luddite <strong class='noun'> —n. </strong> 1 person opposed to industrial progress or new technology. 2 hist. Member of a band of english artisans who destroyed machinery (1811–16). <strong class='adj'> —adj.</strong> Of the luddites. ludditism <strong class='noun'> n. </strong> [ned lud, destroyer of machinery]</p>",
      },
    ],
  },
  {
    word: "Ludicrous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ludicrous <strong class='adj'> adj. </strong> Absurd, ridiculous, laughable. ludicrously <strong class='adv'> adv. </strong> Ludicrousness <strong class='noun'> n. </strong> [latin ludicrum stage play]</p>",
      },
    ],
  },
  {
    word: "Ludo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ludo <strong class='noun'> n. </strong> Simple board-game played with dice and counters. [latin, = i play]</p>",
      },
    ],
  },
  {
    word: "Luff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luff <strong class='verb'> v. </strong> (also absol.) 1 steer (a ship) nearer the wind. 2 raise or lower (a crane's jib). [french, probably from low german]</p>",
      },
    ],
  },
  {
    word: "Lug",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lug <strong class='noun'> —v. </strong> <strong class='gg'> (-gg-) </strong> 1 drag or carry with effort. 2 pull hard. <strong class='noun'> —n. </strong> 1 hard or rough pull. 2 <strong class='colloq'> colloq. </strong> Ear. 3 projection on an object by which it may be carried, fixed in place, etc. [probably scandinavian]</p>",
      },
    ],
  },
  {
    word: "Luggage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luggage <strong class='noun'> n. </strong> Suitcases, bags, etc., for a traveller's belongings. [from *lug]</p>",
      },
    ],
  },
  {
    word: "Lugger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lugger <strong class='noun'> n. </strong> Small ship with four-cornered sails. [from *lugsail]</p>",
      },
    ],
  },
  {
    word: "Lughole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lughole <strong class='noun'> n. </strong> Slang ear.</p>",
      },
    ],
  },
  {
    word: "Lugsail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lugsail <strong class='noun'> n. </strong> Four-cornered sail on a yard. [probably from *lug]</p>",
      },
    ],
  },
  {
    word: "Lugubrious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lugubrious <strong class='adj'> adj. </strong> Doleful. lugubriously <strong class='adv'> adv. </strong> Lugubriousness <strong class='noun'> n. </strong> [latin lugeo mourn]</p>",
      },
    ],
  },
  {
    word: "Lugworm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lugworm <strong class='noun'> n. </strong> Large marine worm used as bait. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Lukewarm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lukewarm <strong class='adj'> adj. </strong> 1 moderately warm; tepid. 2 unenthusiastic, indifferent. [old english (now dial.) Luke warm, *warm]</p>",
      },
    ],
  },
  {
    word: "Lull",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lull <strong class='noun'> —v. </strong> 1 soothe or send to sleep. 2 (usu. Foll. By into) deceive (a person) into undue confidence (lulled into a false sense of security). 3 allay (suspicions etc.), usu. By deception. 4 (of noise, a storm, etc.) Abate or fall quiet. <strong class='noun'> —n. </strong> Temporary quiet period. [imitative]</p>",
      },
    ],
  },
  {
    word: "Lullaby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lullaby <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> soothing song to send a child to sleep. [related to *lull]</p>",
      },
    ],
  },
  {
    word: "Lumbago",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumbago <strong class='noun'> n. </strong> Rheumatic pain in the muscles of the lower back. [latin lumbus loin]</p>",
      },
    ],
  },
  {
    word: "Lumbar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumbar <strong class='adj'> adj. </strong> Of the lower back area. [as *lumbago]</p>",
      },
    ],
  },
  {
    word: "Lumbar puncture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumbar puncture <strong class='noun'> n. </strong> Withdrawal of spinal fluid from the lower back for diagnosis.</p>",
      },
    ],
  },
  {
    word: "Lumber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumber <strong class='noun'> —n. </strong> 1 disused and cumbersome articles. 2 partly prepared timber. —v. 1 (usu. Foll. By with) leave (a person etc.) With something unwanted or unpleasant. 2 (usu. Foll. By up) obstruct, fill inconveniently. 3 cut and prepare forest timber. 4 move in a slow clumsy way. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Lumberjack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumberjack <strong class='noun'> n. </strong> Person who fells and transports lumber.</p>",
      },
    ],
  },
  {
    word: "Lumber-jacket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumber-jacket <strong class='noun'> n. </strong> Jacket of the kind worn by lumberjacks.</p>",
      },
    ],
  },
  {
    word: "Lumber-room",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumber-room <strong class='noun'> n. </strong> Room where disused things are kept.</p>",
      },
    ],
  },
  {
    word: "Luminary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luminary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 literary natural light-giving body. 2 wise or inspiring person. 3 celebrated member of a group (show-business luminaries). [latin lumen lumin- light]</p>",
      },
    ],
  },
  {
    word: "Luminescence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luminescence <strong class='noun'> n. </strong> Emission of light without heat. luminescent adj.</p>",
      },
    ],
  },
  {
    word: "Luminous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luminous <strong class='adj'> adj. </strong> 1 shedding light. 2 phosphorescent, visible in darkness (luminous paint). luminosity n.</p>",
      },
    ],
  },
  {
    word: "Lump1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lump1 <strong class='noun'> —n. </strong> 1 compact shapeless mass. 2 tumour; swelling, bruise. 3 heavy, dull, or ungainly person. 4 (prec. By the) slang casual workers in the building trade. —v. 1 (usu. Foll. By together etc.) Treat as all alike; put together in a lump. 2 (of sauce etc.) Become lumpy. lump in the throat feeling of pressure there, caused by emotion. [scandinavian]</p>",
      },
    ],
  },
  {
    word: "Lump2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lump2 <strong class='verb'> v. </strong> Colloq. Put up with ungraciously (like it or lump it). [imitative]</p>",
      },
    ],
  },
  {
    word: "Lumpectomy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumpectomy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> surgical removal of a lump from the breast.</p>",
      },
    ],
  },
  {
    word: "Lumpish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumpish <strong class='adj'> adj. </strong> 1 heavy and clumsy. 2 stupid, lethargic.</p>",
      },
    ],
  },
  {
    word: "Lump sugar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lump sugar <strong class='noun'> n. </strong> Sugar in cubes.</p>",
      },
    ],
  },
  {
    word: "Lump sum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lump sum <strong class='noun'> n. </strong> 1 sum covering a number of items. 2 money paid down at once.</p>",
      },
    ],
  },
  {
    word: "Lumpy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lumpy <strong class='adj'> adj. </strong> (-ier, -iest) full of or covered with lumps. lumpily <strong class='adv'> adv. </strong> Lumpiness n.</p>",
      },
    ],
  },
  {
    word: "Lunacy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunacy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 insanity. 2 mental unsoundness. 3 great folly. [latin: related to *lunar]</p>",
      },
    ],
  },
  {
    word: "Lunar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunar <strong class='adj'> adj. </strong> Of, like, concerned with, or determined by the moon. [latin luna moon]</p>",
      },
    ],
  },
  {
    word: "Lunar module",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunar module <strong class='noun'> n. </strong> Small craft for travelling between the moon and a spacecraft in orbit around it.</p>",
      },
    ],
  },
  {
    word: "Lunar month",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunar month <strong class='noun'> n. </strong> 1 period of the moon's revolution, esp. The interval between new moons (about 291/2 days). 2 (in general use) four weeks.</p>",
      },
    ],
  },
  {
    word: "Lunate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunate <strong class='adj'> adj. </strong> Crescent-shaped.</p>",
      },
    ],
  },
  {
    word: "Lunatic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunatic <strong class='noun'> —n. </strong> 1 insane person. 2 wildly foolish person. <strong class='adj'> —adj.</strong> Insane; extremely reckless or foolish. [related to *lunacy]</p>",
      },
    ],
  },
  {
    word: "Lunatic asylum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunatic asylum <strong class='noun'> n. </strong> Hist. Mental home or hospital.</p>",
      },
    ],
  },
  {
    word: "Lunatic fringe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunatic fringe <strong class='noun'> n. </strong> Extreme or eccentric minority group.</p>",
      },
    ],
  },
  {
    word: "Lunation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunation <strong class='noun'> n. </strong> Interval between new moons, about 291/2 days. [medieval latin: related to *lunar]</p>",
      },
    ],
  },
  {
    word: "Lunch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunch <strong class='noun'> —n. </strong> Midday meal. —v. 1 take lunch. 2 entertain to lunch. [shortening of *luncheon]</p>",
      },
    ],
  },
  {
    word: "Luncheon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luncheon <strong class='noun'> n. </strong> Formal lunch. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Luncheon meat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luncheon meat <strong class='noun'> n. </strong> Tinned meat loaf of pork etc.</p>",
      },
    ],
  },
  {
    word: "Luncheon voucher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luncheon voucher <strong class='noun'> n. </strong> Voucher issued to employees and exchangeable for food at many restaurants and shops.</p>",
      },
    ],
  },
  {
    word: "Lung",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lung <strong class='noun'> n. </strong> Either of the pair of respiratory organs in humans and many other vertebrates. [old english: related to *light2]</p>",
      },
    ],
  },
  {
    word: "Lunge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lunge <strong class='noun'> —n. </strong> 1 sudden movement forward. 2 the basic attacking move in fencing. 3 long rope on which a horse is held and made to circle round its trainer. —v. (-ging) (usu. Foll. By at, out) deliver or make a lunge. [french allonger from long *long1]</p>",
      },
    ],
  },
  {
    word: "Lupin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lupin <strong class='noun'> n. </strong> Cultivated plant with long tapering spikes of flowers. [related to *lupine]</p>",
      },
    ],
  },
  {
    word: "Lupine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lupine <strong class='adj'> adj. </strong> Of or like wolves. [latin lupinus from lupus wolf]</p>",
      },
    ],
  },
  {
    word: "Lupus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lupus <strong class='noun'> n. </strong> Autoimmune inflammatory skin disease. [latin, = wolf]</p>",
      },
    ],
  },
  {
    word: "Lurch1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lurch1 <strong class='noun'> —n. </strong> Stagger; sudden unsteady movement or leaning. —v. Stagger; move or progress unsteadily. [originally naut., of uncertain origin]</p>",
      },
    ],
  },
  {
    word: "Lurch2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lurch2 <strong class='noun'> n. </strong> leave in the lurch desert (a friend etc.) In difficulties. [obsolete french lourche a kind of backgammon]</p>",
      },
    ],
  },
  {
    word: "Lurcher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lurcher <strong class='noun'> n. </strong> Crossbred dog, usu. A working dog crossed with a greyhound. [related to *lurk]</p>",
      },
    ],
  },
  {
    word: "Lure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lure <strong class='noun'> —v. </strong> <strong class='adj'> (-ring) </strong> 1 (usu. Foll. By away, into) entice. 2 recall with a lure. <strong class='noun'> —n. </strong> 1 thing used to entice. 2 (usu. Foll. By of) enticing quality (of a pursuit etc.). 3 falconer's apparatus for recalling a hawk. [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Lurex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lurex <strong class='noun'> n. </strong> Propr. 1 type of yarn incorporating a glittering metallic thread. 2 fabric made from this.</p>",
      },
    ],
  },
  {
    word: "Lurid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lurid <strong class='adj'> adj. </strong> 1 bright and glaring in colour. 2 sensational, shocking (lurid details). 3 ghastly, wan (lurid complexion). luridly <strong class='adv'> adv. </strong> [latin]</p>",
      },
    ],
  },
  {
    word: "Lurk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lurk <strong class='verb'> v. </strong> 1 linger furtively. 2 a lie in ambush. B (usu. Foll. By in, under, about, etc.) Hide, esp. For sinister purposes. 3 (as lurking adj.) Dormant (a lurking suspicion). [perhaps from *lour]</p>",
      },
    ],
  },
  {
    word: "Luscious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luscious <strong class='adj'> adj. </strong> 1 richly sweet in taste or smell. 2 (of style) over-rich. 3 voluptuously attractive. [perhaps related to *delicious]</p>",
      },
    ],
  },
  {
    word: "Lush1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lush1 <strong class='adj'> adj. </strong> 1 (of vegetation) luxuriant and succulent. 2 luxurious. 3 slang excellent. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Lush2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lush2 <strong class='noun'> n. </strong> Slang alcoholic, drunkard. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Lust",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lust <strong class='noun'> —n. </strong> 1 strong sexual desire. 2 (usu. Foll. By for, of) passionate desire for or enjoyment of (lust for power; lust of battle). 3 sensuous appetite regarded as sinful (lusts of the flesh). —v. (usu. Foll. By after, for) have a strong or excessive (esp. Sexual) desire. lustful adj. Lustfully <strong class='adv'> adv. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Luster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luster <strong class='noun'> n. </strong> (brit. Lustre) 1 gloss, shining surface. 2 brilliance, splendour. 3 iridescent glaze on pottery and porcelain. lustrous adj. [latin lustro illumine]</p>",
      },
    ],
  },
  {
    word: "Lustre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lustre <strong class='noun'> n. </strong> (us luster) 1 gloss, shining surface. 2 brilliance, splendour. 3 iridescent glaze on pottery and porcelain. lustrous adj. [latin lustro illumine]</p>",
      },
    ],
  },
  {
    word: "Lusty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lusty <strong class='adj'> adj. </strong> (-ier, -iest) 1 healthy and strong. 2 vigorous, lively. lustily <strong class='adv'> adv. </strong> Lustiness <strong class='noun'> n. </strong> [from *lust]</p>",
      },
    ],
  },
  {
    word: "Lutanist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lutanist <strong class='var'> var. </strong> Of *lutenist.</p>",
      },
    ],
  },
  {
    word: "Lute1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lute1 <strong class='noun'> n. </strong> Guitar-like instrument with a long neck and a pear-shaped body. [arabic]</p>",
      },
    ],
  },
  {
    word: "Lute2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lute2 <strong class='noun'> —n. </strong> Clay or cement for making joints airtight etc. —v. <strong class='ting'> (-ting) </strong> apply lute to. [latin lutum mud]</p>",
      },
    ],
  },
  {
    word: "Lutenist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lutenist <strong class='noun'> n. </strong> (also lutanist) lute-player. [related to *lute1]</p>",
      },
    ],
  },
  {
    word: "Lutetium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lutetium <strong class='noun'> n. </strong> Silvery metallic element, the heaviest of the lanthanide series. [lutetia, ancient name of paris]</p>",
      },
    ],
  },
  {
    word: "Lutheran",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lutheran <strong class='noun'> —n. </strong> 1 follower of luther. 2 member of the lutheran church. <strong class='adj'> —adj.</strong> Of luther, or the protestant reformation and the doctrines associated with him. lutheranism <strong class='noun'> n. </strong> [martin luther, religious reformer]</p>",
      },
    ],
  },
  {
    word: "Lux",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lux <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> the si unit of illumination. [latin]</p>",
      },
    ],
  },
  {
    word: "Luxuriant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luxuriant <strong class='adj'> adj. </strong> 1 growing profusely. 2 exuberant. 3 florid. luxuriance <strong class='noun'> n. </strong> Luxuriantly <strong class='adv'> adv. </strong> [latin: related to *luxury] <strong class='usage'> Usage:- </strong>luxuriant is sometimes confused with luxurious.</p>",
      },
    ],
  },
  {
    word: "Luxuriate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luxuriate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 (foll. By in) take self-indulgent delight in, enjoy as a luxury. 2 relax in comfort.</p>",
      },
    ],
  },
  {
    word: "Luxurious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luxurious <strong class='adj'> adj. </strong> 1 supplied with luxuries. 2 extremely comfortable. 3 fond of luxury. luxuriously <strong class='adv'> adv. </strong> [latin: related to *luxury] <strong class='usage'> Usage:- </strong>luxurious is sometimes confused with luxuriant.</p>",
      },
    ],
  },
  {
    word: "Luxury",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Luxury <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 choice or costly surroundings, possessions, etc. 2 thing giving comfort or enjoyment but not essential. 3 (attrib.) Comfortable and expensive (luxury flat). [latin luxus abundance]</p>",
      },
    ],
  },
  {
    word: "Lv",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lv <strong class='sing'> abbr. </strong> Luncheon voucher.</p>",
      },
    ],
  },
  {
    word: "Lw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Lw <strong class='prep'> symb. </strong> Lawrencium.</p>",
      },
    ],
  },
  {
    word: "-ly1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ly1 <strong class='slang_v'> suffix </strong>forming adjectives, esp. From nouns, meaning: 1 having the qualities of (princely). 2 recurring at intervals of (daily). [old english]</p>",
      },
    ],
  },
  {
    word: "-ly2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ly2 <strong class='slang_v'> suffix </strong>forming adverbs from adjectives (boldly; happily). [old english]</p>",
      },
    ],
  },
  {
    word: "Lychee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lychee <strong class='noun'> n. </strong> (also litchi, lichee) 1 sweet white juicy fruit in a brown skin. 2 tree, <strong class='archaic_it_was'> orig. </strong> From china, bearing this. [chinese]</p>",
      },
    ],
  },
  {
    word: "Lych-gate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lych-gate <strong class='var'> var. </strong> Of *lich-gate.</p>",
      },
    ],
  },
  {
    word: "Lycra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lycra <strong class='noun'> n. </strong> Propr. Elastic polyurethane fabric used esp. For sportswear.</p>",
      },
    ],
  },
  {
    word: "Lye",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lye <strong class='noun'> n. </strong> 1 water made alkaline with wood ashes. 2 any alkaline solution for washing. [old english]</p>",
      },
    ],
  },
  {
    word: "Lying  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lying  <strong class='see'>pres. Part. Of *</strong>lie1, *lie2.</p>",
      },
    ],
  },
  {
    word: "Lymph",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lymph <strong class='noun'> n. </strong> 1 colourless fluid from the tissues of the body, containing white blood cells. 2 this fluid used as a vaccine. [latin lympha]</p>",
      },
    ],
  },
  {
    word: "Lymphatic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lymphatic <strong class='adj'> adj. </strong> 1 of, secreting, or conveying lymph. 2 (of a person) pale, flabby, or sluggish.</p>",
      },
    ],
  },
  {
    word: "Lymphatic system",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lymphatic system <strong class='noun'> n. </strong> Network of vessels conveying lymph.</p>",
      },
    ],
  },
  {
    word: "Lymph gland",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lymph gland <strong class='noun'> n. </strong> (also lymph node) small mass of tissue in the lymphatic system.</p>",
      },
    ],
  },
  {
    word: "Lymphoma",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lymphoma <strong class='noun'> n. </strong> (pl. -s or -mata) tumour of the lymph nodes.</p>",
      },
    ],
  },
  {
    word: "Lynch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lynch <strong class='verb'> v. </strong> (of a mob) put (a person) to death without a legal trial. lynching <strong class='noun'> n. </strong> [originally us, after lynch, 18th-c. Justice of the peace in virginia]</p>",
      },
    ],
  },
  {
    word: "Lynch law",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lynch law <strong class='noun'> n. </strong> Procedure followed when a person is lynched.</p>",
      },
    ],
  },
  {
    word: "Lynx",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lynx <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> wild cat with a short tail and spotted fur. [greek lugx]</p>",
      },
    ],
  },
  {
    word: "Lynx-eyed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lynx-eyed <strong class='adj'> adj. </strong> Keen-sighted.</p>",
      },
    ],
  },
  {
    word: "Lyre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lyre <strong class='noun'> n. </strong> Ancient u-shaped stringed instrument. [greek lura]</p>",
      },
    ],
  },
  {
    word: "Lyre-bird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lyre-bird <strong class='noun'> n. </strong> Australian bird, the male of which has a lyre-shaped tail display.</p>",
      },
    ],
  },
  {
    word: "Lyric",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lyric <strong class='adj'> —adj.</strong> 1 (of poetry) expressing the writer's emotions, usu. Briefly and in stanzas. 2 (of a poet) writing in this manner. 3 meant or fit to be sung, songlike. <strong class='noun'> —n. </strong> 1 lyric poem. 2 (in pl.) Words of a song. [latin: related to *lyre]</p>",
      },
    ],
  },
  {
    word: "Lyrical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lyrical <strong class='adj'> adj. </strong> 1 = *lyric. 2 resembling, or using language appropriate to, lyric poetry. 3 <strong class='colloq'> colloq. </strong> Highly enthusiastic (wax lyrical about). lyrically adv.</p>",
      },
    ],
  },
  {
    word: "Lyricism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lyricism <strong class='noun'> n. </strong> Quality of being lyric.</p>",
      },
    ],
  },
  {
    word: "Lyricist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lyricist <strong class='noun'> n. </strong> Writer of (esp. Popular) lyrics.</p>",
      },
    ],
  },
  {
    word: "Lysergic acid diethylamide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Lysergic acid diethylamide <strong class='noun'> n. </strong> = *lsd. [from hydrolysis, ergot, *-ic]</p>",
      },
    ],
  },
  {
    word: "-lysis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-lysis <strong class='n_pl'> comb. </strong> Form forming nouns denoting disintegration or decomposition (electrolysis). [greek lusis loosening]</p>",
      },
    ],
  },
  {
    word: "-lyte",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-lyte <strong class='slang_v'> suffix </strong>forming nouns denoting substances that can be decomposed (electrolyte). [greek lutos loosened]</p>",
      },
    ],
  },
];

export const alphabet_L_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एल 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एल 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>£</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैब।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेबल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेबियाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेबियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्रम आदि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रयोगशाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मजदूर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्रमसाध्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्रम </p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्रम शिविर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मजदूर दिवस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मजदूर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्रम विनिमय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्रमिकों का दल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्रम-बचत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैब्राडोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लबर्नम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूलभुलैया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फीता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेस बांध लो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lachrymal</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lachrymose</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैकोनिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैक्रिमल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैक्रोस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lactate1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैक्टेट 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैक्टेशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैक्टियल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैक्टिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुग्धाम्ल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैक्टोज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैकुना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बालक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीढ़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीढ़ी-पीठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>La-di-da</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महिलाओं का चहेता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महिला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेडीबर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेडी चैपल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेडी डे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेडी-इन-वेटिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हत्यारी महिला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाडली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेडीशिप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेडी का चप्पल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैग 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैग 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैग 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगर लाउट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैगार्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैगिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैगून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिटा देना  </p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शांत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कागज रखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lain</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेयर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Laissez-faire</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Laity</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lake1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेक 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झील ज़िला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लामा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लामसरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भेड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाम्बा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैम्बास्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैम्ब्डा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मेमने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैम्ब्सवूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झूठा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झूठा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंगड़ा बत्तख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विलाप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विलाप करने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विलाप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लामिना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टुकड़े टुकड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लम्मा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिराग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंपब्लैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lamplight</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लामप्लाइटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैम्पून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंप पोस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैम्प्रे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंपशेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंकेस्ट्रियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लांस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिटिश सेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लांसोलेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लांसर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंसेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंसेट आर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूमि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूमि एजेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंडौ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उतर ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंडफॉल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंडफिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूमि-लड़की</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंडिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उतराई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंडिंग सामग्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घाट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मकान मालकिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंड लाइन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंडलॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मकान मालिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंडलुबर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमाचिह्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूमि द्रव्यमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूमि-खान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूस्वामी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिदृश्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंडस्केप बागवानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूस्खलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूस्खलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाषा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाषा प्रयोगशाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैनोलिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लालटेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लालटेन जबड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंथेनाइड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंथेनाइड श्रृंखला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैंथेनम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डोरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाओडिकियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lap1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैप 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैप 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गोद-कुत्ते</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैपेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैपिडरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लापीस लाजुली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैपलैंडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सम्मान की गोद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lappet</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैपटॉप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैपविंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लारबोर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लार्ने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लार्च</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लार्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Larder</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lardy</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लार्डी-केक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विशाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन के रूप में बड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़े पैमाने पर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़े पैमाने पर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लार्गेसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लार्गो मूस।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लारियात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lark1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lark2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लार्कसपुर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लार्वा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Laryngeal</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैरींगाइटिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Larynx</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lasagne</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कामुकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेजर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैशिंग्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लासा बुखार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लासो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Last1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Last2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Last3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतिम-खाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थायी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंततः</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आखरी मिनट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपनाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतिम पोस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतिम संस्कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिछले भूसे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतिम ट्रम्प</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतिम शब्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेट।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुंडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेचकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वर्गीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैटेकोमर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटेन पाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हाल तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यवस्थित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यक्त गर्मी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पार्श्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पार्श्व सोच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटेक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खराद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lather</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैटिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैटिन अमेरिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटिनेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैटिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लतीश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अक्षांश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटिट्यूडिनियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शौचालय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाद वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैटर-डे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीते दिनों वाला साधु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाद में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाली खिड़की</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लातवियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाउड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रशंसनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉडनम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रशंसा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हंसना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हंसने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हँसना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हंसाने वाली गैस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हंसते हुए जैकस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जगहंसाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हँसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्च 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्च 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्चर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लांच पैड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्डर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्ड्रेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्ड्रेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धोने लायक कपड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉरेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉरेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लावा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लावेटोरियल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शौचालय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शौचालय कागज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lave</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैवेंडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लैवेंडर-पानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लविश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानून का पालन करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉब्रेकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदालत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधिवृषण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सांसद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन की घास काटने वाली मशीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लान टेनिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉरेंसियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुकदमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वकील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रेचक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lay1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lay2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ले3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Leas4</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेबाउट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ले-बाय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फिगर रखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेमैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छंटनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विन्यास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पाठक लेटो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आलसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आलसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lb</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलबीसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>L.B.W.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एल.सी.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलसीडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>LCM</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एल/सीपीएल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ld।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीड 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीड 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीसा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीसा मुक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>में नेतृत्व करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अग्रणी 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अग्रणी 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अग्रणी विमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रमुख लेख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रास्ता दिखाने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अग्रणी नोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहम सवाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीड पेंसिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीसा विषाक्तता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्ती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्रक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्ती-मोल्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्ती-डंठल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीग 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीग 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीग तालिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रिसना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रिसाव के</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lean1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lean2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झुकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झुकाव से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुबला साल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छलांग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीप-फ्रॉग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधिवर्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सिखाने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पट्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पट्टे पर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पट्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम से कम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्प सामान्य विभाजक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आम एकाधिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेदरबैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमड़े से बाउंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमड़े का जैकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेथी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीव 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छुट्टी 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Leved</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीवेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्तियाँ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छूटी लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Leavings</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेबनानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेचर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lechour</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lechery</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्याख्यान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाषण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्याख्याता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलईडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलईडी  </p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेडरहोसन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कसाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खाता बही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हरा प्याज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीवर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीवे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेफ्ट 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाम 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंक छोड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बायां हाथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाएं हाथ से काम करने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाएं हाथ के व्यक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वामपंथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामान छोड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाईं ओर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बचा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाईं ओर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वामपंथी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेफ्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टांग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विरासत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानूनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानूनी सहायता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानूनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानूनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैधता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैध करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानूनी अलगाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानूनी निविदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विरासत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगाटो मूस।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहले पैर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेग-बाय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दंतकथा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिग्गज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगरडेमैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगर लाइन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेग्गी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुपाठ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीजनरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगिनेयर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगोनायर रोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानून बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विधान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विधायी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विधान - सभा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैध करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैध करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगलेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेग-ऑफ-मटन आस्तीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पैर-पुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेग-रूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेग्यूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेगुमिनस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पैर ऊपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पैर गर्म करने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फुर्सत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आराम केन्द्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संलग्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इत्मीनान से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवकाश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटमोटिफ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेमिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नींबू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नींबू पानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीबू बाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नींबू वाला दही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नींबू गेरियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नींबू एकमात्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेमूर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उधार देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेंटो मुस।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लियो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लियोनिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तेंदुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेओटर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोढ़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेपिडोप्टेरस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेप्रचून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुष्ठ रोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेस्बियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेस-मेजस्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-कम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पट्टेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पाठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पट्टेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नफरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेट 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lb</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलबीसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>L.B.W.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एल.सी.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलसीडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>LCM</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एल/सीपीएल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ld।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Let2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-होने देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निराशा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घातक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाहर निकालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्र-बम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्र पात्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्र दिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटरहेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साख पत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटरप्रेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सलाद पत्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूको-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूकोसाइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूकेमिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूकेमिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेवंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेवेंटाइन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तर पार करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेवलर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तरीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेवलर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तरीय पेगिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फ़ायदा उठाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेवेरजेड बायआउट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेवरेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेविथान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेविस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेविटेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेविटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भद्दी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाब्दिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेक्सोग्राफी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेक्सिकॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेडेन जार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलएफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देयता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तरदायी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Liaise</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मेल जोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लियाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झूठा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lib।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lib</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदारवादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिबरल डेमोक्रेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदार बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिबर्टाइन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वतंत्रता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कामनात्मक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कामेच्छा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुस्तकालय अध्यक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुस्तकालय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिब्रेटो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूँ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइसेंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइसेंस 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइसेंस 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइसेंसधारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Licentiate</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइसेंसपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिचेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिच-गेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइसेंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चाटना और एक वादा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नद्यपान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढक्कन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिडो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lie1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lie2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झूठ बोला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाई डिटेक्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेट जाएं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेग usu। हिस्ट।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रिहायश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूमि का झूठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झूठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेफ्ट।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेफ्टिनेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेफ्टेनंट कर्नल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिंदगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन बीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>LifeBelt</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>LifeBlood</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइफबोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>LifeBuoy</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन चक्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवनरक्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन रक्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन बीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन जाकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेजान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आजीवन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन रेखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिंदगी भर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन साथी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन रक्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइफर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन विज्ञान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आजीवन कारावास की सजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन-आकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन शैली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन-समर्थन मशीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवन काल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उठाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिफ्ट बंद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिगामेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ligature</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Light1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Light2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइट बल्ब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हल्का 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lighten2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lighter1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइटर 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हल्का-से-हवा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकाश उंगली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइट फ्लाईवेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हल्के पैरों वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छिछोरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हल्का-फुल्का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दस स्टोन से कम तोल का मुक्केबाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइटहाउस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकाश उद्योग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकाश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकाश-अप समय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हल्का मीटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हल्का मिडलवेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिजली चमकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिजली का चालक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोशनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइटशिप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हल्का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइट वेल्टरवेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकाश वर्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lignous</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिग्नाइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिग्नम विटे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पसंद है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैसे 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैसे 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैसे 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-पसंद करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पसंद करने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संभावना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संभावित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समान विचारधारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>की तरह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समानता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैसे ही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पसंद करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकाइन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिलियासस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिलिपुटियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिलो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lilt</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिली-लिवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कामुदिनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नरगिसी सफेद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Limb1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Limb2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Limber1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Limber2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Limber2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Limbo1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिम्बो 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूना 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइम 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइम 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूना भट्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइमलाइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिमरिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूना पत्थर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिमिटेड कंपनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिमन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिमो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिमोसिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Limp1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Limp2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिमपेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिमपेट मेरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिम्पिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वंशावली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंचपिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिनकस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंडेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइन 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइन 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वंश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वंशावली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वंशावली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रैखिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रैखिक बी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वंशावली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रेखा चित्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिनन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिनन टोकरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आग की रेखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दृष्टि की रेखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कतार में लगाओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइन प्रिंटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइनर 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइनर 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइन्समैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पंक्ति बनायें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ling1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ling2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-लिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दीर्घ काल तक रहना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधोवस्त्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंगो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामान्य भाषा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंगीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाषाविद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाषाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाषाविज्ञान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संपर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंकेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंकमैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंक करें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिननियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिनेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिनो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिनोकुट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिनोलियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलसी का बीज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलसी का तेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिन्सी-वूल्सी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंटेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शेर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शेर दिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शेर करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा हिस्सा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>होंठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिपिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिपोसक्शन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>होंठ पढ़ें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिखावटी प्रेम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिपस्टिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्रव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिसमापन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तरल स्फ़टिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिक्विड क्रिस्टल डिस्प्ले</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तरलता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिसमापक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिस्ले</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिस्प</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिसोम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>List1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>List2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बात सुनो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्रोता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिस्टेरिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचीहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूची मूल्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिटनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साक्षरता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाब्दिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साहित्यवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साहित्यिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साक्षर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साहित्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साहित्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिथे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिथियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिथो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिथोग्राफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिथोग्राफी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिथुआनियाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुकदमेबाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुकदमेबाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुकदमेबाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिटमस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिटमस पेपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिट्मस परीक्षण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Litotes</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Litt.D.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कूड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिटरबग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कूड़े-कम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटा भालू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोरा थोरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोड़ा अंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोड़ा ग्रेबे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोड़े लोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटी महिला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Littoral</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुकदमेबाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रहने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Live1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Live2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रहने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>में रहते थे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>में रहते हैं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आजीविका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खूब जियो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Liven</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Liver1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिवर 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिवरिश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिवरपुडलियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिवर सॉसेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिवरवॉर्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झूठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्तबल जहां घोड़े और वाहन किराए पर दिए जाते हैं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़िंदगियाँ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पशुधन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ताल्लुक़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज्वलंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीविका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैठक कक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्वाह म़ज़दूरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छिपकली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलजे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ll</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लामा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ll b</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ll d</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ll m</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉयड्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉयड की सूची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉयड का रजिस्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलएन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लदा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घाट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोडस्टोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोफ 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोफ 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोफर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोमट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऋृण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऋण शार्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घृणा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घृणा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घृणा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोटियों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोबार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पैरवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉबी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉबिस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोबेलिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोबोटॉमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lobscouse</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झींगा मछली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉबस्टर-पॉट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोबवर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थानीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थानीय प्राधिकारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थानीय रंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थानीय सरकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इलाका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थानीयकरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थानीय समय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थानीय रेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पता लगाओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Loc। सिट।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Loch</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉक 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉक 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉकजॉ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉक-कीपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तालाबंदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ताला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉक स्टॉक और बैरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हवालात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोको 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोको 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोकोमोशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोकोमोटिव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोकोम टेनेंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोकोस क्लासिकस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टिड्डी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lodestar</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉजस्टोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉगर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Loess</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मचान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदात्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Log1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Log2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोगन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोगनबेरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लघुगणक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉगबुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लकड़ी का घर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लकड़हारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लकड़हारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉजिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉगिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-तर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तार्किक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-लोगिस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संभार तंत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉग-जाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतीक चिन्ह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-लॉजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Loin</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Loincloth</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टाल - मटोल करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉलीपॉप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉलीपॉप आदमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोलप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लल्ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंदनर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंदन गर्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकेला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकेला हाथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकेला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तन्हा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुंवारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकेला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकेला भेड़िया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Long1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Long2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबा।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्गबोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्गबो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लम्बी दूरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लम्बा विभाजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय से तैयार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दीर्घायु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबा चेहरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोंगहंद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबी दौड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लालसा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दांत में लंबे समय तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देशांतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुदैर्ध्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुटन्ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लम्बी कूद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबा जीवन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय तक जीवित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय से खो दिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय तक खेलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबी दूरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय तक चल रहा है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्गशोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Longshoreman</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबा शॉट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबी दृष्टि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय से पीड़ित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दीर्घकालिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबी लहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉन्गवेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबे समय से घुमावदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोफाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नज़र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक जैसे दिखते हैं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नज़र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुकर-ऑन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहां देखो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शीशा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाहर देखो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>LOOM1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>LOOM2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोनी स्लैंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पागल खाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खामियों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Loopy</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढीला कवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गिरा हुआ पत्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lop-ered</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lopsed</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिक्वियस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Loquat</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भगवान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉर्ड चैंबरलेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉर्ड चांसलर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भगवान मुख्य न्यायाधीश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भगवान लेफ्टिनेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभुली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महापौर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉर्ड प्रिवी सील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉर्ड्स एंड लेडीज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भगवान का दिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभुत्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भगवान की प्रार्थना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभु आध्यात्मिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभु भोज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉर्ड्स टेम्पोरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विद्या</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोरगनेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लर्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परास्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नुकसान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हार हुआ नेता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोया  </p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हारा हुया मुकद्दमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Loth</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोथारियो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लॉटरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोट्टो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमल फूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमल-ईटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमल की स्थिति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊँचा स्वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाउड हेलर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाउडस्पीकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विश्राम कक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाउंज बार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाउंज सूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जला हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घटिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lout</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लौवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लवेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लवत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रिम प्यर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लव-बर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यार काटो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यार बच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रेम - नफरत संबंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रेम-इन-द-मिस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लवलेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झूठे प्यार में खून बहता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लवेलोर्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रणयक्रीड़ा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यार का घोंसला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रेमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आरामदायक कुर्सी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लवी डवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्यार-कप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लो 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लो 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम-जन्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोब्रो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम चर्च</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निम्न वर्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम कॉमेडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निचले देश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम-नीचे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोअर 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोअर 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोअर 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निचला मामला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निम्न वर्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निचला सदन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सबसे कम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूनतम सार्व भाजक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सबसे कम आम कई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम आवृत्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निचला गियर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निम्न श्रेणी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम महत्वपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तराई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम स्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम-झूठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम पिच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम दबाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम वृद्धि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम मौसम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम रविवार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम ज्वार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्ठावान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वफादार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वफादार टोस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lozenge</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एल-प्लेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलपीओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एलएसडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>L.S.D.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lse</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lso</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेफ्टिनेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिमिटेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lubber</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्नेहक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिकनाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिकनाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूसर्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूसिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लूसिफ़ेर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सौभाग्य से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाग्यशाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भागयशाली स्नान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाभप्रद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुडाइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुडिक्रस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुडो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लूग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लूगर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lughole</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लूगसेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुगुब्रियस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लूगवर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुनगुनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुल्ल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाला लल्ला लोरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लम्बागो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लकड़ी का पंचर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लकड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबरजैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंबर-जैकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लकड़ी कमरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुमनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Luminescence</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमकदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lump1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lump2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lumpectomy</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकमुश्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चीनी की डली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकमुश्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गांठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुंहतोड़ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चंद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुनार मॉड्युल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चंद्र मास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lunate</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lunatic</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पागलख़ाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पागल किनारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूनेशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिन का खाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंच मटन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंच वाउचर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फेफड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lunge</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूपिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूपिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ल्यूपस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lurch1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lurch2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lucher</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लालच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lurex</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुरिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुस्वाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lush1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lush2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हवस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लस्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुटानिस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lute1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lute2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुटेनिस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुटेटियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लूथरन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शानदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lixuriate</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शान शौकत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विलासिता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>LV</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>LW</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-ly1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-ly2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lychee</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइच-गेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइक्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेटा हुआ  </p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिम्फ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लसीका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लसीका प्रणाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिम्फ ग्रंथि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिम्फोमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंच कानून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिंक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lynx-eyed</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Lyre</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लायर-बर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गीतात्मक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गीतकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गीतकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीसर्जिक एसिड डैथ्यलामैड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-लिसिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-lyte</p>",
  },
];