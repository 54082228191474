export const alphabet_I = [
  {
    word: "I",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>I </p>",
      },
    ],
  },
  {
    word: "I1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>I1 <strong class='noun'> n. </strong> (also i) (pl. Is or i's) 1 ninth letter of the alphabet. 2 (as a roman numeral) 1.</p>",
      },
    ],
  },
  {
    word: "I2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>I2 <strong class='pron'> pron. </strong> (obj. Me; <strong class='poss'> poss. </strong> My, mine; <strong class='pl'> pl. </strong> We) used by a speaker or writer to refer to himself or herself. [old english]</p>",
      },
    ],
  },
  {
    word: "I3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>I3 <strong class='prep'> symb. </strong> Iodine.</p>",
      },
    ],
  },
  {
    word: "I4",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>I4 <strong class='sing'> abbr. </strong> (also i.) 1 island(s). 2 isle(s).</p>",
      },
    ],
  },
  {
    word: "-ial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>-ial <strong class='var'> var. </strong> Of *-al.</p>",
      },
    ],
  },
  {
    word: "Iambic  prosody",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iambic prosody <strong class='adj'> —adj.</strong> Of or using iambuses. <strong class='noun'> —n. </strong> (usu. In pl.) Iambic verse.</p>",
      },
    ],
  },
  {
    word: "Iambus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iambus <strong class='noun'> n. </strong> (pl. -buses or -bi) metrical foot consisting of one short followed by one long syllable (&iambus.). [greek, = lampoon]</p>",
      },
    ],
  },
  {
    word: "-ian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>-ian <strong class='var'> var. </strong> Of *-an.</p>",
      },
    ],
  },
  {
    word: "Iba",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iba <strong class='sing'> abbr. </strong> Independent broadcasting authority.</p>",
      },
    ],
  },
  {
    word: "Iberian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iberian <strong class='adj'> —adj.</strong> Of iberia, the peninsula comprising spain and portugal; of spain and portugal. <strong class='noun'> —n. </strong> Native or language of iberia. [latin iberia]</p>",
      },
    ],
  },
  {
    word: "Ibex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ibex <strong class='noun'> n. </strong> (pl. -es) wild mountain goat with thick curved ridged horns. [latin]</p>",
      },
    ],
  },
  {
    word: "Ibid.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ibid. <strong class='abbr'> Abbr. </strong> In the same book or passage etc. [latin ibidem in the same place]</p>",
      },
    ],
  },
  {
    word: "-ibility",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ibility <strong class='slang_v'> suffix </strong>forming nouns from, or corresponding to, adjectives in -ible.</p>",
      },
    ],
  },
  {
    word: "Ibis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ibis <strong class='noun'> n. </strong> (pl. -es) wading bird with a curved bill, long neck, and long legs. [greek, from egyptian]</p>",
      },
    ],
  },
  {
    word: "-ible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ible <strong class='slang_v'> suffix </strong>forming adjectives meaning ‘that may or may be’ (forcible; possible). [latin]</p>",
      },
    ],
  },
  {
    word: "-ible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ible <strong class='slang_v'> suffix </strong>forming adjectives meaning ‘that may or may be’ (forcible; possible). [latin]</p>",
      },
    ],
  },
  {
    word: "-ibly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ibly <strong class='slang_v'> suffix </strong>forming adverbs corresponding to adjectives in -ible.</p>",
      },
    ],
  },
  {
    word: "Ibo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ibo <strong class='noun'> n. </strong> (also igbo) <strong class='pl_same_or_s'> (pl. Same or -s) </strong> 1 member of a black people of se nigeria. 2 their language. [native name]</p>",
      },
    ],
  },
  {
    word: "-ic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ic <strong class='slang_v'> suffix </strong>1 forming adjectives (arabic; classic; public) and nouns (critic; epic; mechanic; music). 2 combined in higher valence or degree of oxidation (ferric; sulphuric). [latin -icus, greek -ikos]</p>",
      },
    ],
  },
  {
    word: "-ical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ical <strong class='slang_v'> suffix </strong>forming adjectives corresponding to nouns or adjectives in -ic or -y (classical; historical).</p>",
      },
    ],
  },
  {
    word: "Ice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice <strong class='noun'> —n. </strong> 1 a frozen water. B sheet of this on water. 2 ice-cream or water-ice (ate an ice). —v. (icing) 1 mix with or cool in ice (iced drinks). 2 (often foll. By over, up) a cover or become covered with ice. B freeze. 3 cover (a cake etc.) With icing. on ice 1 performed by skaters. 2 <strong class='colloq'> colloq. </strong> In reserve. On thin ice in a risky situation. [old english]</p>",
      },
    ],
  },
  {
    word: "Ice age",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice age <strong class='noun'> n. </strong> Glacial period.</p>",
      },
    ],
  },
  {
    word: "Ice-axe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-axe <strong class='noun'> n. </strong> Cutting tool used by mountaineers.</p>",
      },
    ],
  },
  {
    word: "Iceberg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iceberg <strong class='noun'> n. </strong> Large floating mass of ice. the tip of the iceberg small perceptible part of something very large or complex. [dutch]</p>",
      },
    ],
  },
  {
    word: "Iceberg lettuce",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iceberg lettuce <strong class='noun'> n. </strong> Crisp type of round lettuce.</p>",
      },
    ],
  },
  {
    word: "Ice blue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice blue <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> (as adj. Often hyphenated) very pale blue.</p>",
      },
    ],
  },
  {
    word: "Icebox",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Icebox <strong class='noun'> n. </strong> 1 compartment in a refrigerator for making or storing ice. 2 us refrigerator.</p>",
      },
    ],
  },
  {
    word: "Ice-breaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-breaker <strong class='noun'> n. </strong> 1 ship designed to break through ice. 2 joke, incident, etc. That breaks the ice.</p>",
      },
    ],
  },
  {
    word: "Ice bucket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice bucket <strong class='noun'> n. </strong> Bucket holding ice, used to chill wine.</p>",
      },
    ],
  },
  {
    word: "Ice cap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice cap <strong class='noun'> n. </strong> Permanent covering of ice, esp. In polar regions.</p>",
      },
    ],
  },
  {
    word: "Ice-cream",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-cream <strong class='noun'> n. </strong> Sweet creamy frozen food, usu. Flavoured.</p>",
      },
    ],
  },
  {
    word: "Ice-cube",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-cube <strong class='noun'> n. </strong> Small block of ice for drinks etc.</p>",
      },
    ],
  },
  {
    word: "Ice-field",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-field <strong class='noun'> n. </strong> Expanse of ice, esp. In polar regions.</p>",
      },
    ],
  },
  {
    word: "Ice hockey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice hockey <strong class='noun'> n. </strong> Form of hockey played on ice.</p>",
      },
    ],
  },
  {
    word: "Icelander",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Icelander <strong class='noun'> n. </strong> 1 native or national of iceland. 2 person of icelandic descent.</p>",
      },
    ],
  },
  {
    word: "Icelandic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Icelandic <strong class='adj'> —adj.</strong> Of iceland. <strong class='noun'> —n. </strong> Language of iceland.</p>",
      },
    ],
  },
  {
    word: "Ice lolly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice lolly <strong class='noun'> n. </strong> (also iced lolly) flavoured ice on a stick.</p>",
      },
    ],
  },
  {
    word: "Ice-pack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-pack <strong class='noun'> n. </strong> 1 = *pack ice. 2 ice applied to the body for medical purposes.</p>",
      },
    ],
  },
  {
    word: "Ice-pick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-pick <strong class='noun'> n. </strong> Tool with a spike for splitting up ice.</p>",
      },
    ],
  },
  {
    word: "Ice-plant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-plant <strong class='noun'> n. </strong> Plant with speckled leaves.</p>",
      },
    ],
  },
  {
    word: "Ice-rink",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-rink <strong class='noun'> n. </strong> = *rink <strong class='noun'> n. </strong> 1.</p>",
      },
    ],
  },
  {
    word: "Ice-skate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ice-skate <strong class='noun'> —n. </strong> Boot with a blade beneath, for skating on ice. —v. Skate on ice. ice-skater n.</p>",
      },
    ],
  },
  {
    word: "Ichneumon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ichneumon <strong class='noun'> n. </strong> 1 (in full ichneumon fly) small wasp depositing eggs in or on the larva of another as food for its own larva. 2 mongoose noted for destroying crocodile eggs. [greek from ikhnos footstep]</p>",
      },
    ],
  },
  {
    word: "Ichthyology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ichthyology <strong class='noun'> n. </strong> The study of fishes. ichthyological adj. Ichthyologist <strong class='noun'> n. </strong> [greek ikhthus fish]</p>",
      },
    ],
  },
  {
    word: "Ichthyosaurus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ichthyosaurus <strong class='noun'> n. </strong> (also ichthyosaur) (pl. -sauruses or -saurs) extinct marine reptile with four flippers and usu. A large tail. [greek ikhthus fish, sauros lizard]</p>",
      },
    ],
  },
  {
    word: "-ician",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ician <strong class='slang_v'> suffix </strong>forming nouns denoting persons skilled in subjects having nouns usu. Ending in -ic or -ics (magician; politician). [french -icien]</p>",
      },
    ],
  },
  {
    word: "Icicle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Icicle <strong class='noun'> n. </strong> Hanging tapering piece of ice, formed from dripping water. [from *ice, obsolete ickle icicle]</p>",
      },
    ],
  },
  {
    word: "Icing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Icing <strong class='noun'> n. </strong> 1 coating of sugar etc. On a cake or biscuit. 2 formation of ice on a ship or aircraft. icing on the cake inessential though attractive addition or enhancement.</p>",
      },
    ],
  },
  {
    word: "Icing sugar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Icing sugar <strong class='noun'> n. </strong> Finely powdered sugar.</p>",
      },
    ],
  },
  {
    word: "Icon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Icon <strong class='noun'> n. </strong> (also ikon) 1 painting of christ etc., esp. In the eastern church. 2 image or statue. 3 symbol on a vdu screen of a program, option, or window, esp. For selection. iconic adj. [greek eikon image]</p>",
      },
    ],
  },
  {
    word: "Iconoclast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iconoclast <strong class='noun'> n. </strong> 1 person who attacks cherished beliefs. 2 hist. Person destroying religious images. iconoclasm <strong class='noun'> n. </strong> Iconoclastic adj. [greek: related to *icon, klao break]</p>",
      },
    ],
  },
  {
    word: "Iconography",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iconography <strong class='noun'> n. </strong> 1 the illustration of a subject by drawings or figures. 2 the study of portraits, esp. Of an individual, or of artistic images or symbols. [greek: related to *icon]</p>",
      },
    ],
  },
  {
    word: "Iconostasis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iconostasis <strong class='noun'> n. </strong> (pl. -stases) (in the eastern church) screen bearing icons. [greek: related to *icon]</p>",
      },
    ],
  },
  {
    word: "Icosahedron",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Icosahedron <strong class='noun'> n. </strong> Solid figure with twenty faces. [greek eikosi twenty, hedra base]</p>",
      },
    ],
  },
  {
    word: "-ics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ics <strong class='slang_v'> suffix </strong>(treated as sing. Or pl.) Forming nouns denoting arts, sciences, etc. (athletics; politics).</p>",
      },
    ],
  },
  {
    word: "Icy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Icy <strong class='adj'> adj. </strong> (-ier, -iest) 1 very cold. 2 covered with or abounding in ice. 3 (of a tone or manner) unfriendly, hostile. icily <strong class='adv'> adv. </strong> Iciness n.</p>",
      },
    ],
  },
  {
    word: "Id",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Id <strong class='sing'> abbr. </strong> Identification, identity (id card).</p>",
      },
    ],
  },
  {
    word: "Id",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Id <strong class='noun'> n. </strong> Person's inherited unconscious psychological impulses. [latin, = that]</p>",
      },
    ],
  },
  {
    word: "I'd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>I'd <strong class='contr'> contr. </strong> 1 i had. 2 i should; i would.</p>",
      },
    ],
  },
  {
    word: "-ide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ide <strong class='slang_v'> suffix </strong>chem. Forming nouns denoting binary compounds of an element (sodium chloride; lead sulphide; calcium carbide). [extended from *oxide]</p>",
      },
    ],
  },
  {
    word: "Idea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idea <strong class='noun'> n. </strong> 1 plan etc. Formed by mental effort (an idea for a book). 2 a mental impression or concept. B vague belief or fancy (had an idea you were married). 3 intention or purpose (the idea is to make money). 4 archetype or pattern. 5 ambition or aspiration (have ideas; put ideas into a person's head). have no idea <strong class='colloq'> colloq. </strong> 1 not know at all. 2 be completely incompetent. Not one's idea of <strong class='colloq'> colloq. </strong> Not what one regards as (not my idea of a holiday). [greek, = form, kind]</p>",
      },
    ],
  },
  {
    word: "Ideal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ideal <strong class='adj'> —adj.</strong> 1 answering to one's highest conception; perfect. 2 existing only in idea; visionary. <strong class='noun'> —n. </strong> Perfect type, thing, concept, principle, etc., esp. As a standard to emulate. [french: related to *idea]</p>",
      },
    ],
  },
  {
    word: "Idealism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idealism <strong class='noun'> n. </strong> 1 forming or pursuing ideals, esp. Unrealistically. 2 representation of things in ideal form. 3 system of thought in which objects are held to be in some way dependent on the mind. idealist <strong class='noun'> n. </strong> Idealistic adj. Idealistically adv.</p>",
      },
    ],
  },
  {
    word: "Idealize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idealize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) regard or represent as ideal or perfect. idealization n.</p>",
      },
    ],
  },
  {
    word: "Ideally",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ideally <strong class='adv'> adv. </strong> 1 in ideal circumstances. 2 according to an ideal.</p>",
      },
    ],
  },
  {
    word: "Idée fixe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idée fixe <strong class='noun'> n. </strong> (pl. Idées fixes pronunc. Same) dominating idea; obsession. [french, = fixed idea]</p>",
      },
    ],
  },
  {
    word: "Identical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Identical <strong class='adj'> adj. </strong> 1 (often foll. By with) (of different things) absolutely alike. 2 one and the same. 3 (of twins) developed from a single ovum. identically <strong class='adv'> adv. </strong> [latin identicus: related to *identity]</p>",
      },
    ],
  },
  {
    word: "Identification",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Identification <strong class='noun'> n. </strong> 1 identifying. 2 means of identifying (also attrib.: identification card).</p>",
      },
    ],
  },
  {
    word: "Identification parade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Identification parade <strong class='noun'> n. </strong> Group of people from whom a suspect is to be identified.</p>",
      },
    ],
  },
  {
    word: "Identify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Identify <strong class='verb'> v. </strong> (-ies, -ied) 1 establish the identity of; recognize. 2 select or discover (identify the best solution). 3 (also refl.; foll. By with) associate inseparably or very closely (with a party, policy, etc.). 4 (often foll. By with) treat as identical. 5 (foll. By with) put oneself in the place of (another person). identifiable adj. [medieval latin identifico: related to *identity]</p>",
      },
    ],
  },
  {
    word: "Identikit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Identikit <strong class='noun'> n. </strong> (often attrib.) Propr. Picture of esp. A wanted suspect assembled from standard components using witnesses' descriptions. [from *identity, *kit]</p>",
      },
    ],
  },
  {
    word: "Identity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Identity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a condition of being a specified person or thing. B individuality, personality (felt he had lost his identity). 2 identification or the result of it (mistaken identity; identity card). 3 absolute sameness (identity of interests). 4 algebra a equality of two expressions for all values of the quantities. B equation expressing this. [latin identitas from idem same]</p>",
      },
    ],
  },
  {
    word: "Ideogram",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ideogram <strong class='noun'> n. </strong> Character symbolizing a thing without indicating the sounds in its name (e.g. A numeral, chinese characters). [greek idea form, *-gram]</p>",
      },
    ],
  },
  {
    word: "Ideograph",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ideograph <strong class='noun'> n. </strong> = *ideo <strong class='gram'>gram.</strong>  ideographic adj. Ideography n.</p>",
      },
    ],
  },
  {
    word: "Ideologue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ideologue <strong class='noun'> n. </strong> Often derog. Adherent of an ideology. [french: related to *idea]</p>",
      },
    ],
  },
  {
    word: "Ideology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ideology <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 ideas at the basis of an economic or political theory (marxist ideology). 2 characteristic thinking of a class etc. (bourgeois ideology). ideological adj. Ideologically <strong class='adv'> adv. </strong> Ideologist <strong class='noun'> n. </strong> [french: related to *idea, *-logy]</p>",
      },
    ],
  },
  {
    word: "Ides",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ides <strong class='n_pl'> n.pl. </strong> Day of the ancient roman month (the 15th day of march, may, july, and october, the 13th of other months). [latin idus]</p>",
      },
    ],
  },
  {
    word: "Idiocy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idiocy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 foolishness; foolish act. 2 extreme mental imbecility.</p>",
      },
    ],
  },
  {
    word: "Idiom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idiom <strong class='noun'> n. </strong> 1 phrase etc. Established by usage and not immediately comprehensible from the words used (e.g. Over the moon, see the light). 2 form of expression peculiar to a language etc. 3 language of a people or country. 4 characteristic mode of expression in art etc. [greek idios own]</p>",
      },
    ],
  },
  {
    word: "Idiomatic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idiomatic <strong class='adj'> adj. </strong> 1 relating or conforming to idiom. 2 characteristic of a particular language. idiomatically adv.</p>",
      },
    ],
  },
  {
    word: "Idiosyncrasy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idiosyncrasy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> attitude, behaviour, or opinion peculiar to a person; anything highly individual or eccentric. idiosyncratic adj. Idiosyncratically <strong class='adv'> adv. </strong> [greek idios private, sun with, krasis mixture]</p>",
      },
    ],
  },
  {
    word: "Idiot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idiot <strong class='noun'> n. </strong> 1 stupid person. 2 mentally deficient person incapable of rational conduct. idiotic adj. Idiotically <strong class='adv'> adv. </strong> [greek idiotes, = private citizen, ignorant person]</p>",
      },
    ],
  },
  {
    word: "Idle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idle <strong class='adj'> —adj.</strong> (idler, idlest) 1 lazy, indolent. 2 not in use; not working. 3 (of time etc.) Unoccupied. 4 purposeless; groundless (idle rumour). 5 useless, ineffective (idle protest). —v. (-ling) 1 be idle. 2 run (an engine) or (of an engine) be run slowly without doing any work. 3 (foll. By away) pass (time etc.) In idleness. idleness <strong class='noun'> n. </strong> Idler <strong class='noun'> n. </strong> Idly <strong class='adv'> adv. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Idol",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idol <strong class='noun'> n. </strong> 1 image of a deity etc. As an object of worship. 2 object of excessive or supreme adulation. [greek eidolon image, phantom]</p>",
      },
    ],
  },
  {
    word: "Idolater",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idolater <strong class='noun'> n. </strong> 1 worshipper of idols. 2 devoted admirer. idolatrous adj. Idolatry <strong class='noun'> n. </strong> [related to *idol, greek latreuo worship]</p>",
      },
    ],
  },
  {
    word: "Idolize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idolize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 venerate or love excessively. 2 make an idol of. idolization n.</p>",
      },
    ],
  },
  {
    word: "Idyll",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idyll <strong class='noun'> n. </strong> 1 short description, esp. In verse, of a peaceful or romantic, esp. Rural, scene or incident. 2 such a scene or incident. [greek eidullion]</p>",
      },
    ],
  },
  {
    word: "Idyllic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Idyllic <strong class='adj'> adj. </strong> 1 blissfully peaceful and happy. 2 of or like an idyll. idyllically adv.</p>",
      },
    ],
  },
  {
    word: "I.e.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>I.e. <strong class='abbr'> Abbr. </strong> That is to say. [latin id est]</p>",
      },
    ],
  },
  {
    word: "-ie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>-ie <strong class='see'> see *</strong> -y2.</p>",
      },
    ],
  },
  {
    word: "If  —",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>If — <strong class='conj'> conj. </strong> 1 introducing a conditional clause: a on the condition or supposition that; in the event that (if he comes i will tell him; if you are tired we can rest). B (with past tense) implying that the condition is not fulfilled (if i knew i would say). 2 even though (i'll finish it, if it takes me all day). 3 whenever (if i am not sure i ask). 4 whether (see if you can find it). 5 expressing a wish, surprise, or request (if i could just try!; if it isn't my old hat!; if you wouldn't mind?). <strong class='noun'> —n. </strong> Condition, supposition (too many ifs about it). if only 1 even if for no other reason than (i'll come if only to see her). 2 (often ellipt.) Expression of regret; i wish that (if only i had thought of it). [old english]</p>",
      },
    ],
  },
  {
    word: "Iffy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iffy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Uncertain; dubious.</p>",
      },
    ],
  },
  {
    word: "Igbo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Igbo <strong class='var'> var. </strong> Of *ibo.</p>",
      },
    ],
  },
  {
    word: "Igloo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Igloo <strong class='noun'> n. </strong> Eskimo dome-shaped dwelling, esp. Of snow. [eskimo, = house]</p>",
      },
    ],
  },
  {
    word: "Igneous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Igneous <strong class='adj'> adj. </strong> 1 of fire; fiery. 2 (esp. Of rocks) volcanic. [latin ignis fire]</p>",
      },
    ],
  },
  {
    word: "Ignite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ignite <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 set fire to. 2 catch fire. 3 provoke or excite (feelings etc.). [latin ignio ignit- set on fire]</p>",
      },
    ],
  },
  {
    word: "Ignition",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ignition <strong class='noun'> n. </strong> 1 mechanism for, or the action of, starting combustion in an internal-combustion engine. 2 igniting or being ignited.</p>",
      },
    ],
  },
  {
    word: "Ignoble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ignoble <strong class='adj'> adj. </strong> (-bler, -blest) 1 dishonourable. 2 of low birth, position, or reputation. ignobly <strong class='adv'> adv. </strong> [latin: related to *in-1, *noble]</p>",
      },
    ],
  },
  {
    word: "Ignominious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ignominious <strong class='adj'> adj. </strong> Shameful, humiliating. ignominiously <strong class='adv'> adv. </strong> [latin: related to *ignominy]</p>",
      },
    ],
  },
  {
    word: "Ignominy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ignominy <strong class='noun'> n. </strong> Dishonour, infamy. [latin: related to *in-1, latin (g)nomen name]</p>",
      },
    ],
  },
  {
    word: "Ignoramus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ignoramus <strong class='noun'> n. </strong> (pl. -muses) ignorant person. [latin, = we do not know: related to *ignore]</p>",
      },
    ],
  },
  {
    word: "Ignorance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ignorance <strong class='noun'> n. </strong> Lack of knowledge. [french from latin: related to *ignore]</p>",
      },
    ],
  },
  {
    word: "Ignorant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ignorant <strong class='adj'> adj. </strong> 1 (often foll. By of, in) lacking knowledge (esp. Of a fact or subject). 2 <strong class='colloq'> colloq. </strong> Uncouth. ignorantly adv.</p>",
      },
    ],
  },
  {
    word: "Ignore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ignore <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> refuse to take notice of; intentionally disregard. [latin ignoro not know]</p>",
      },
    ],
  },
  {
    word: "Iguana",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iguana <strong class='noun'> n. </strong> Large american, w. Indian, or pacific lizard with a dorsal crest. [spanish from carib iwana]</p>",
      },
    ],
  },
  {
    word: "Iguanodon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iguanodon <strong class='noun'> n. </strong> Large plant-eating dinosaur with small forelimbs. [from *iguana, which it resembles, after mastodon etc.]</p>",
      },
    ],
  },
  {
    word: "Ikebana",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ikebana <strong class='noun'> n. </strong> Art of japanese flower arrangement. [japanese, = living flowers]</p>",
      },
    ],
  },
  {
    word: "Ikon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ikon <strong class='var'> var. </strong> Of *icon.</p>",
      },
    ],
  },
  {
    word: "Il-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Il- <strong class='prefix '> prefix </strong> <strong class='assim'> assim. </strong> Form of *in-1, *in-2 before l.</p>",
      },
    ],
  },
  {
    word: "Ileum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ileum <strong class='noun'> n. </strong> (pl. Ilea) third and last portion of the small intestine. [latin ilium]</p>",
      },
    ],
  },
  {
    word: "Ilex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ilex <strong class='noun'> n. </strong> (pl. -es) 1 tree or shrub of the genus including the common holly. 2 holm-oak. [latin]</p>",
      },
    ],
  },
  {
    word: "Iliac",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iliac <strong class='adj'> adj. </strong> Of the lower body (iliac artery). [latin ilia flanks]</p>",
      },
    ],
  },
  {
    word: "Ilk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ilk <strong class='noun'> n. </strong> 1 colloq., usu. Derog. Sort, family, class, etc. 2 (in of that ilk) scot. Of the ancestral estate with the same name as the family (guthrie of that ilk). [old english]</p>",
      },
    ],
  },
  {
    word: "Ill",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill <strong class='adj'> —adj.</strong> (attrib. Except in sense 1) 1 (usu. Predic.) Not in good health; unwell. 2 wretched, unfavourable (ill fortune; ill luck). 3 harmful (ill effects). 4 hostile, unkind (ill feeling). 5 faulty, unskilful (ill management). 6 (of manners or conduct) improper. <strong class='adv'> —adv. </strong> 1 badly, wrongly, imperfectly (ill-matched; ill-provided). 2 scarcely (can ill afford it). 3 unfavourably (spoke ill of them). <strong class='noun'> —n. </strong> 1 injury, harm. 2 evil. ill at ease embarrassed, uneasy. [old norse]</p>",
      },
    ],
  },
  {
    word: "I'll",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>I'll <strong class='contr'> contr. </strong> I shall; i will.</p>",
      },
    ],
  },
  {
    word: "Ill-advised",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-advised <strong class='adj'> adj. </strong> Foolish; imprudent.</p>",
      },
    ],
  },
  {
    word: "Ill-assorted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-assorted <strong class='adj'> adj. </strong> Badly matched; mixed.</p>",
      },
    ],
  },
  {
    word: "Ill-bred",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-bred <strong class='adj'> adj. </strong> Badly brought up; rude.</p>",
      },
    ],
  },
  {
    word: "Ill-defined",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-defined <strong class='adj'> adj. </strong> Not clearly defined.</p>",
      },
    ],
  },
  {
    word: "Ill-disposed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-disposed <strong class='adj'> adj. </strong> 1 (often foll. By towards) unfavourably disposed. 2 malevolent.</p>",
      },
    ],
  },
  {
    word: "Illegal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illegal <strong class='adj'> adj. </strong> 1 not legal. 2 criminal. illegality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Illegally adv.</p>",
      },
    ],
  },
  {
    word: "Illegible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illegible <strong class='adj'> adj. </strong> Not legible. illegibility <strong class='noun'> n. </strong> Illegibly adv.</p>",
      },
    ],
  },
  {
    word: "Illegitimate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illegitimate <strong class='adj'> adj. </strong> 1 born of parents not married to each other. 2 unlawful. 3 improper. 4 wrongly inferred. illegitimacy <strong class='noun'> n. </strong> Illegitimately adv.</p>",
      },
    ],
  },
  {
    word: "Ill-fated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-fated <strong class='adj'> adj. </strong> Destined to or bringing bad fortune.</p>",
      },
    ],
  },
  {
    word: "Ill-favoured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-favoured <strong class='adj'> adj. </strong> Unattractive.</p>",
      },
    ],
  },
  {
    word: "Ill-founded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-founded <strong class='adj'> adj. </strong> (of an idea etc.) Baseless.</p>",
      },
    ],
  },
  {
    word: "Ill-gotten",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-gotten <strong class='adj'> adj. </strong> Gained unlawfully or wickedly.</p>",
      },
    ],
  },
  {
    word: "Ill health",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill health <strong class='noun'> n. </strong> Poor physical or mental condition.</p>",
      },
    ],
  },
  {
    word: "Ill humour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill humour <strong class='noun'> n. </strong> Irritability.</p>",
      },
    ],
  },
  {
    word: "Illiberal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illiberal <strong class='adj'> adj. </strong> 1 intolerant, narrow-minded. 2 without liberal culture; vulgar. 3 stingy; mean. illiberality <strong class='noun'> n. </strong> Illiberally adv.</p>",
      },
    ],
  },
  {
    word: "Illicit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illicit <strong class='adj'> adj. </strong> Unlawful, forbidden. illicitly adv.</p>",
      },
    ],
  },
  {
    word: "Illiterate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illiterate <strong class='adj'> —adj.</strong> 1 unable to read. 2 uneducated. <strong class='noun'> —n. </strong> Illiterate person. illiteracy <strong class='noun'> n. </strong> Illiterately adv.</p>",
      },
    ],
  },
  {
    word: "Ill-mannered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-mannered <strong class='adj'> adj. </strong> Having bad manners; rude.</p>",
      },
    ],
  },
  {
    word: "Ill-natured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-natured <strong class='adj'> adj. </strong> Churlish, unkind.</p>",
      },
    ],
  },
  {
    word: "Illness",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illness <strong class='noun'> n. </strong> 1 disease. 2 being ill.</p>",
      },
    ],
  },
  {
    word: "Illogical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illogical <strong class='adj'> adj. </strong> Devoid of or contrary to logic. illogicality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Illogically adv.</p>",
      },
    ],
  },
  {
    word: "Ill-omened",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-omened <strong class='adj'> adj. </strong> Doomed.</p>",
      },
    ],
  },
  {
    word: "Ill-tempered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-tempered <strong class='adj'> adj. </strong> Morose, irritable.</p>",
      },
    ],
  },
  {
    word: "Ill-timed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-timed <strong class='adj'> adj. </strong> Done or occurring at an inappropriate time.</p>",
      },
    ],
  },
  {
    word: "Ill-treat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-treat <strong class='verb'> v. </strong> Treat badly; abuse.</p>",
      },
    ],
  },
  {
    word: "Illuminate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illuminate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 light up; make bright. 2 decorate (buildings etc.) With lights. 3 decorate (a manuscript etc.) With gold, colour, etc. 4 help to explain (a subject etc.). 5 enlighten spiritually or intellectually. 6 shed lustre on. illuminating adj. Illumination <strong class='noun'> n. </strong> Illuminative adj. [latin lumen light]</p>",
      },
    ],
  },
  {
    word: "Illumine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illumine <strong class='verb'> v. </strong> (-ning) literary 1 light up; make bright. 2 enlighten.</p>",
      },
    ],
  },
  {
    word: "Ill-use",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill-use <strong class='verb'> v. </strong> = *ill-treat.</p>",
      },
    ],
  },
  {
    word: "Illusion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illusion <strong class='noun'> n. </strong> 1 false impression or belief. 2 state of being deceived by appearances. 3 figment of the imagination. be under the illusion (foll. By that) believe mistakenly. illusive adj. Illusory adj. [latin illudo mock]</p>",
      },
    ],
  },
  {
    word: "Illusionist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illusionist <strong class='noun'> n. </strong> Conjuror.</p>",
      },
    ],
  },
  {
    word: "Illustrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illustrate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a provide (a book etc.) With pictures. B elucidate by drawings, pictures, examples, etc. 2 serve as an example of. illustrator <strong class='noun'> n. </strong> [latin lustro light up]</p>",
      },
    ],
  },
  {
    word: "Illustration",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illustration <strong class='noun'> n. </strong> 1 drawing or picture in a book, magazine, etc. 2 explanatory example. 3 illustrating.</p>",
      },
    ],
  },
  {
    word: "Illustrative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illustrative <strong class='adj'> adj. </strong> (often foll. By of) explanatory; exemplary.</p>",
      },
    ],
  },
  {
    word: "Illustrious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Illustrious <strong class='adj'> adj. </strong> Distinguished, renowned. [latin illustris: related to *illustrate]</p>",
      },
    ],
  },
  {
    word: "Ill will",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ill will <strong class='noun'> n. </strong> Bad feeling; animosity.</p>",
      },
    ],
  },
  {
    word: "Im-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Im- <strong class='prefix '> prefix </strong> <strong class='assim'> assim. </strong> Form of *in-1, *in-2 before b, m, or p.</p>",
      },
    ],
  },
  {
    word: "I'm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>I'm <strong class='contr'> contr. </strong> I am.</p>",
      },
    ],
  },
  {
    word: "Image",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Image <strong class='noun'> —n. </strong> 1 representation of an object, e.g. A statue. 2 reputation or persona of a person, company, etc. 3 appearance as seen in a mirror or through a lens. 4 mental picture or idea. 5 simile or metaphor. —v. (-ging) 1 make an image of; portray. 2 reflect, mirror. 3 describe or imagine vividly. be the image of be or look exactly like. [latin imago imagin-]</p>",
      },
    ],
  },
  {
    word: "Imagery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imagery <strong class='noun'> n. </strong> 1 figurative illustration, esp. In literature. 2 images; statuary, carving. 3 mental images collectively.</p>",
      },
    ],
  },
  {
    word: "Imaginary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imaginary <strong class='adj'> adj. </strong> 1 existing only in the imagination. 2 math. Being the square root of a negative quantity. [latin: related to *image]</p>",
      },
    ],
  },
  {
    word: "Imagination",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imagination <strong class='noun'> n. </strong> 1 mental faculty of forming images or concepts of objects or situations not existent or not directly experienced. 2 mental creativity or resourcefulness.</p>",
      },
    ],
  },
  {
    word: "Imaginative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imaginative <strong class='adj'> adj. </strong> Having or showing imagination. imaginatively <strong class='adv'> adv. </strong> Imaginativeness n.</p>",
      },
    ],
  },
  {
    word: "Imagine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imagine <strong class='verb'> v. </strong> (-ning) 1 a form a mental image or concept of. B picture to oneself. 2 think of as probable (can't imagine he'd be so stupid). 3 guess (can't imagine what he is doing). 4 suppose (i imagine you'll need help). imaginable adj. [latin imaginor] </p>",
      },
    ],
  },
  {
    word: "Imago",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imago <strong class='noun'> n. </strong> (pl. -s or imagines) fully developed stage of an insect, e.g. A butterfly. [latin: see *image]</p>",
      },
    ],
  },
  {
    word: "Imam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imam <strong class='noun'> n. </strong> 1 leader of prayers in a mosque. 2 title of various muslim leaders. [arabic]</p>",
      },
    ],
  },
  {
    word: "Imbalance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imbalance <strong class='noun'> n. </strong> 1 lack of balance. 2 disproportion.</p>",
      },
    ],
  },
  {
    word: "Imbecile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imbecile <strong class='noun'> —n. </strong> 1 <strong class='colloq'> colloq. </strong> Stupid person. 2 person with a mental age of about five. <strong class='adj'> —adj.</strong> Mentally weak; stupid, idiotic. imbecilic adj. Imbecility <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. [french from latin]</p>",
      },
    ],
  },
  {
    word: "Imbed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Imbed <strong class='var'> var. </strong> Of *embed.</p>",
      },
    ],
  },
  {
    word: "Imbibe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imbibe <strong class='verb'> v. </strong> (-bing) 1 drink (esp. Alcohol). 2 a assimilate (ideas etc.). B absorb (moisture etc.). 3 inhale (air etc.). [latin bibo drink]</p>",
      },
    ],
  },
  {
    word: "Imbroglio",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imbroglio <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 confused or complicated situation. 2 confused heap. [italian: related to *in-2, *broil]</p>",
      },
    ],
  },
  {
    word: "Imbue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imbue <strong class='verb'> v. </strong> (-bues, -bued, -buing) (often foll. By with) 1 inspire or permeate (with feelings, opinions, or qualities). 2 saturate. 3 dye. [latin imbuo]</p>",
      },
    ],
  },
  {
    word: "Imf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imf <strong class='sing'> abbr. </strong> International monetary fund.</p>",
      },
    ],
  },
  {
    word: "Imitate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imitate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 follow the example of; copy. 2 mimic. 3 make a copy of. 4 be like. imitable adj. Imitator <strong class='noun'> n. </strong> [latin imitor -tat-]</p>",
      },
    ],
  },
  {
    word: "Imitation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imitation <strong class='noun'> n. </strong> 1 imitating or being imitated. 2 copy. 3 counterfeit (often attrib.: imitation leather).</p>",
      },
    ],
  },
  {
    word: "Imitative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imitative <strong class='adj'> adj. </strong> 1 (often foll. By of) imitating; following a model or example. 2 (of a word) reproducing a natural sound (e.g. Fizz), or otherwise suggestive (e.g. Blob).</p>",
      },
    ],
  },
  {
    word: "Immaculate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immaculate <strong class='adj'> adj. </strong> 1 perfectly clean and tidy. 2 perfect (immaculate timing). 3 innocent, faultless. immaculately <strong class='adv'> adv. </strong> Immaculateness <strong class='noun'> n. </strong> [latin: related to *in-1, macula spot]</p>",
      },
    ],
  },
  {
    word: "Immaculate conception",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immaculate conception <strong class='noun'> n. </strong> Rc ch. Doctrine that the virgin mary was without original sin from conception.</p>",
      },
    ],
  },
  {
    word: "Immanent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immanent <strong class='adj'> adj. </strong> 1 (often foll. By in) naturally present, inherent. 2 (of god) omnipresent. immanence <strong class='noun'> n. </strong> [latin: related to *in-2, maneo remain]</p>",
      },
    ],
  },
  {
    word: "Immaterial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immaterial <strong class='adj'> adj. </strong> 1 unimportant; irrelevant. 2 not material; incorporeal. immateriality n.</p>",
      },
    ],
  },
  {
    word: "Immature",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immature <strong class='adj'> adj. </strong> 1 not mature. 2 undeveloped, esp. Emotionally. 3 unripe. immaturely <strong class='adv'> adv. </strong> Immaturity n.</p>",
      },
    ],
  },
  {
    word: "Immeasurable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immeasurable <strong class='adj'> adj. </strong> Not measurable; immense. immeasurably adv.</p>",
      },
    ],
  },
  {
    word: "Immediate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immediate <strong class='adj'> adj. </strong> 1 occurring or done at once (immediate reply). 2 nearest, next; direct (immediate vicinity; immediate future; immediate cause of death). 3 most pressing or urgent (our immediate concern). immediacy <strong class='noun'> n. </strong> Immediateness <strong class='noun'> n. </strong> [latin: related to *in-1, *mediate]</p>",
      },
    ],
  },
  {
    word: "Immediately",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immediately <strong class='adv'> —adv. </strong> 1 without pause or delay. 2 without intermediary. — <strong class='conj'> conj. </strong> As soon as.</p>",
      },
    ],
  },
  {
    word: "Immemorial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immemorial <strong class='adj'> adj. </strong> Ancient beyond memory or record (from time immemorial).</p>",
      },
    ],
  },
  {
    word: "Immense",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immense <strong class='adj'> adj. </strong> 1 extremely large; huge. 2 considerable (immense difference). immenseness <strong class='noun'> n. </strong> Immensity <strong class='noun'> n. </strong> [latin metior mens- measure]</p>",
      },
    ],
  },
  {
    word: "Immensely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immensely <strong class='adv'> adv. </strong> 1 <strong class='colloq'> colloq. </strong> Very much (enjoyed myself immensely). 2 to an immense degree (immensely rich).</p>",
      },
    ],
  },
  {
    word: "Immerse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immerse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 a (often foll. By in) dip, plunge. B submerge (a person). 2 (often refl. Or in passive; often foll. By in) absorb or involve deeply. 3 (often foll. By in) bury, embed. [latin mergo mers- dip]</p>",
      },
    ],
  },
  {
    word: "Immersion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immersion <strong class='noun'> n. </strong> 1 immersing or being immersed. 2 baptism by total bodily immersion. 3 mental absorption.</p>",
      },
    ],
  },
  {
    word: "Immersion heater",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immersion heater <strong class='noun'> n. </strong> Electric device immersed in a liquid to heat it, esp. In a hot-water tank.</p>",
      },
    ],
  },
  {
    word: "Immigrant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immigrant <strong class='noun'> —n. </strong> Person who immigrates. <strong class='adj'> —adj.</strong> 1 immigrating. 2 of immigrants.</p>",
      },
    ],
  },
  {
    word: "Immigrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immigrate <strong class='verb'> v. </strong> Come into a country and settle. immigration <strong class='noun'> n. </strong> [related to *in-2, *migrate]</p>",
      },
    ],
  },
  {
    word: "Imminent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imminent <strong class='adj'> adj. </strong> Impending; about to happen (war is imminent). imminence <strong class='noun'> n. </strong> Imminently <strong class='adv'> adv. </strong> [latin immineo be impending]</p>",
      },
    ],
  },
  {
    word: "Immiscible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immiscible <strong class='adj'> adj. </strong> (often foll. By with) not able to be mixed. immiscibility n.</p>",
      },
    ],
  },
  {
    word: "Immobile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immobile <strong class='adj'> adj. </strong> 1 not moving. 2 unable to move or be moved. immobility n.</p>",
      },
    ],
  },
  {
    word: "Immobilize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immobilize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 make or keep immobile. 2 keep (a limb or patient) still for healing purposes. immobilization n.</p>",
      },
    ],
  },
  {
    word: "Immoderate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immoderate <strong class='adj'> adj. </strong> Excessive; lacking moderation. immoderately adv.</p>",
      },
    ],
  },
  {
    word: "Immodest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immodest <strong class='adj'> adj. </strong> 1 lacking modesty; conceited. 2 shameless, indecent. immodestly <strong class='adv'> adv. </strong> Immodesty n.</p>",
      },
    ],
  },
  {
    word: "Immolate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immolate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> kill or offer as a sacrifice. immolation <strong class='noun'> n. </strong> [latin, = sprinkle with meal]</p>",
      },
    ],
  },
  {
    word: "Immoral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immoral <strong class='adj'> adj. </strong> 1 not conforming to accepted morality; morally wrong. 2 sexually promiscuous or deviant. immorality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Immorally adv.</p>",
      },
    ],
  },
  {
    word: "Immortal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immortal <strong class='adj'> —adj.</strong> 1 a living for ever; not mortal. B divine. 2 unfading. 3 famous for all time. <strong class='noun'> —n. </strong> 1 a immortal being. B (in pl.) Gods of antiquity. 2 person, esp. An author, remembered long after death. immortality <strong class='noun'> n. </strong> Immortalize v. (also -ise) (-zing or -sing). Immortally adv.</p>",
      },
    ],
  },
  {
    word: "Immovable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immovable <strong class='adj'> adj. </strong> (also immoveable) 1 not able to be moved. 2 steadfast, unyielding. 3 emotionless. 4 not subject to change (immovable law). 5 motionless. 6 (of property) consisting of land, houses, etc. immovability <strong class='noun'> n. </strong> Immovably adv.</p>",
      },
    ],
  },
  {
    word: "Immune",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immune <strong class='adj'> adj. </strong> 1 a (often foll. By against, from, to) protected against infection through inoculation etc. B relating to immunity (immune system). 2 (foll. By from, to) exempt from or proof against a charge, duty, criticism, etc. [latin immunis exempt]</p>",
      },
    ],
  },
  {
    word: "Immunity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immunity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 ability of an organism to resist infection by means of antibodies and white blood cells. 2 (often foll. By from) freedom or exemption.</p>",
      },
    ],
  },
  {
    word: "Immunize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immunize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) make immune, usu. By inoculation. immunization n.</p>",
      },
    ],
  },
  {
    word: "Immunodeficiency",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immunodeficiency <strong class='noun'> n. </strong> Reduction in normal immune defences.</p>",
      },
    ],
  },
  {
    word: "Immunoglobulin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immunoglobulin <strong class='noun'> n. </strong> Any of a group of related proteins functioning as antibodies.</p>",
      },
    ],
  },
  {
    word: "Immunology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immunology <strong class='noun'> n. </strong> The study of immunity. immunological adj. Immunologist n.</p>",
      },
    ],
  },
  {
    word: "Immunotherapy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immunotherapy <strong class='noun'> n. </strong> Prevention or treatment of disease with substances that stimulate the immune response.</p>",
      },
    ],
  },
  {
    word: "Immure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immure <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 imprison. 2 refl. Shut oneself away. [latin murus wall]</p>",
      },
    ],
  },
  {
    word: "Immutable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Immutable <strong class='adj'> adj. </strong> Unchangeable. immutability <strong class='noun'> n. </strong> Immutably adv.</p>",
      },
    ],
  },
  {
    word: "Imp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imp <strong class='noun'> n. </strong> 1 mischievous child. 2 small devil or sprite. [old english, = young shoot]</p>",
      },
    ],
  },
  {
    word: "Impact",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impact <strong class='noun'> —n. </strong> 1 effect of sudden forcible contact between two solid bodies etc.; collision. 2 strong effect or impression. —v. 1 press or fix firmly. 2 (as impacted adj.) (of a tooth) wedged between another tooth and the jaw. 3 (often foll. By on) have an impact on. impaction <strong class='noun'> n. </strong> [latin: related to *impinge]</p>",
      },
    ],
  },
  {
    word: "Impair",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impair <strong class='verb'> v. </strong> Damage, weaken. impairment <strong class='noun'> n. </strong> [latin, = make worse, from pejor]</p>",
      },
    ],
  },
  {
    word: "Impala",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impala <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> small african antelope. [zulu]</p>",
      },
    ],
  },
  {
    word: "Impale",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impale <strong class='verb'> v. </strong> (-ling) transfix or pierce with a sharp stake etc. impalement <strong class='noun'> n. </strong> [latin palus *pale2]</p>",
      },
    ],
  },
  {
    word: "Impalpable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impalpable <strong class='adj'> adj. </strong> 1 not easily grasped by the mind; intangible. 2 imperceptible to the touch. 3 (of powder) very fine. impalpability <strong class='noun'> n. </strong> Impalpably adv.</p>",
      },
    ],
  },
  {
    word: "Impanel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impanel <strong class='var'> var. </strong> Of *empanel.</p>",
      },
    ],
  },
  {
    word: "Impart",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impart <strong class='verb'> v. </strong> <strong class='adj'> (often foll. By to) </strong> 1 communicate (news etc.). 2 give a share of (a thing). [latin: related to *part]</p>",
      },
    ],
  },
  {
    word: "Impartial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impartial <strong class='adj'> adj. </strong> Treating all alike; unprejudiced, fair. impartiality <strong class='noun'> n. </strong> Impartially adv.</p>",
      },
    ],
  },
  {
    word: "Impassable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impassable <strong class='adj'> adj. </strong> Not able to be traversed. impassability <strong class='noun'> n. </strong> Impassableness <strong class='noun'> n. </strong> Impassably adv.</p>",
      },
    ],
  },
  {
    word: "Impasse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impasse <strong class='noun'> n. </strong> Deadlock. [french: related to *pass1]</p>",
      },
    ],
  },
  {
    word: "Impassible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impassible <strong class='adj'> adj. </strong> 1 impassive. 2 incapable of feeling, emotion, or injury. impassibility <strong class='noun'> n. </strong> Impassibly <strong class='adv'> adv. </strong> [latin patior pass- suffer]</p>",
      },
    ],
  },
  {
    word: "Impassioned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impassioned <strong class='adj'> adj. </strong> Filled with passion; ardent. [italian impassionato: related to *passion]</p>",
      },
    ],
  },
  {
    word: "Impassive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impassive <strong class='adj'> adj. </strong> Incapable of or not showing emotion; serene. impassively <strong class='adv'> adv. </strong> Impassiveness <strong class='noun'> n. </strong> Impassivity n.</p>",
      },
    ],
  },
  {
    word: "Impasto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impasto <strong class='noun'> n. </strong> Art technique of laying on paint thickly. [italian]</p>",
      },
    ],
  },
  {
    word: "Impatiens",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impatiens <strong class='noun'> n. </strong> Any of several plants including the busy lizzie. [latin: related to *impatient]</p>",
      },
    ],
  },
  {
    word: "Impatient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impatient <strong class='adj'> adj. </strong> 1 lacking or showing a lack of patience or tolerance. 2 restlessly eager. 3 (foll. By of) intolerant of. impatience <strong class='noun'> n. </strong> Impatiently adv.</p>",
      },
    ],
  },
  {
    word: "Impeach",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impeach <strong class='verb'> v. </strong> 1 charge with a crime against the state, esp. Treason. 2 us charge (a public official) with misconduct. 3 call in question, disparage. impeachable adj. Impeachment <strong class='noun'> n. </strong> [french empecher from latin pedica fetter]</p>",
      },
    ],
  },
  {
    word: "Impeccable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impeccable <strong class='adj'> adj. </strong> Faultless, exemplary. impeccability <strong class='noun'> n. </strong> Impeccably <strong class='adv'> adv. </strong> [related to *in-1, latin pecco sin]</p>",
      },
    ],
  },
  {
    word: "Impecunious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impecunious <strong class='adj'> adj. </strong> Having little or no money. impecuniosity <strong class='noun'> n. </strong> Impecuniousness <strong class='noun'> n. </strong> [related to *pecuniary]</p>",
      },
    ],
  },
  {
    word: "Impedance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impedance <strong class='noun'> n. </strong> Total effective resistance of an electric circuit etc. To an alternating current. [from *impede] <strong class='usage'> Usage:- </strong>impedance is sometimes confused with impediment, which means ‘a hindrance’ or ‘a speech defect’.</p>",
      },
    ],
  },
  {
    word: "Impede",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impede <strong class='verb'> v. </strong> (-ding) obstruct; hinder. [latin impedio from pes ped- foot]</p>",
      },
    ],
  },
  {
    word: "Impediment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impediment <strong class='noun'> n. </strong> 1 hindrance or obstruction. 2 speech defect, e.g. A stammer. [latin: related to *impede] <strong class='usage'> Usage:- </strong>see note at impedance.</p>",
      },
    ],
  },
  {
    word: "Impedimenta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impedimenta <strong class='n_pl'> n.pl. </strong> 1 encumbrances. 2 baggage, esp. Of an army.</p>",
      },
    ],
  },
  {
    word: "Impel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impel <strong class='verb'> v. </strong> (-ll-) 1 drive, force, or urge. 2 propel. [latin pello drive]</p>",
      },
    ],
  },
  {
    word: "Impend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impend <strong class='verb'> v. </strong> (often foll. By over) 1 (of a danger, event, etc.) Be threatening or imminent. 2 hang. impending adj. [latin pendeo hang]</p>",
      },
    ],
  },
  {
    word: "Impenetrable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impenetrable <strong class='adj'> adj. </strong> 1 not able to be penetrated. 2 inscrutable. 3 inaccessible to ideas, influences, etc. impenetrability <strong class='noun'> n. </strong> Impenetrableness <strong class='noun'> n. </strong> Impenetrably adv.</p>",
      },
    ],
  },
  {
    word: "Impenitent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impenitent <strong class='adj'> adj. </strong> Not sorry, unrepentant. impenitence n.</p>",
      },
    ],
  },
  {
    word: "Imperative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperative <strong class='adj'> —adj.</strong> 1 urgent; obligatory. 2 commanding, peremptory. 3 <strong class='gram'>gram.</strong> (of a mood) expressing a command (e.g. Come here!). <strong class='noun'> —n. </strong> 1 <strong class='gram'>gram.</strong> Imperative mood. 2 command. 3 essential or urgent thing. [latin impero command]</p>",
      },
    ],
  },
  {
    word: "Imperceptible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperceptible <strong class='adj'> adj. </strong> 1 not perceptible. 2 very slight, gradual, or subtle. imperceptibility <strong class='noun'> n. </strong> Imperceptibly adv.</p>",
      },
    ],
  },
  {
    word: "Imperfect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperfect <strong class='adj'> —adj.</strong> 1 not perfect; faulty, incomplete. 2 <strong class='gram'>gram.</strong> (of a tense) denoting action in progress but not completed (e.g. They were singing). <strong class='noun'> —n. </strong> Imperfect tense. imperfectly adv.</p>",
      },
    ],
  },
  {
    word: "Imperfection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperfection <strong class='noun'> n. </strong> 1 state of being imperfect. 2 fault, blemish.</p>",
      },
    ],
  },
  {
    word: "Imperial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperial <strong class='adj'> adj. </strong> 1 of or characteristic of an empire or similar sovereign state. 2 a of an emperor. B majestic, august; authoritative. 3 (of non-metric weights and measures) statutory in the uk, esp. Formerly (imperial gallon). imperially <strong class='adv'> adv. </strong> [latin imperium dominion]</p>",
      },
    ],
  },
  {
    word: "Imperialism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperialism <strong class='noun'> n. </strong> 1 imperial rule or system. 2 usu. Derog. Policy of dominating other nations by acquiring dependencies etc. imperialist <strong class='noun'> n. </strong> & adj. Imperialistic adj.</p>",
      },
    ],
  },
  {
    word: "Imperil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperil <strong class='verb'> v. </strong> (-ll-; us -l-) endanger.</p>",
      },
    ],
  },
  {
    word: "Imperious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperious <strong class='adj'> adj. </strong> Overbearing, domineering. imperiously <strong class='adv'> adv. </strong> Imperiousness n.</p>",
      },
    ],
  },
  {
    word: "Imperishable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperishable <strong class='adj'> adj. </strong> Not able to perish, indestructible.</p>",
      },
    ],
  },
  {
    word: "Impermanent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impermanent <strong class='adj'> adj. </strong> Not permanent. impermanence <strong class='noun'> n. </strong> Impermanency n.</p>",
      },
    ],
  },
  {
    word: "Impermeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impermeable <strong class='adj'> adj. </strong> Not permeable, not allowing fluids to pass through. impermeability n.</p>",
      },
    ],
  },
  {
    word: "Impermissible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impermissible <strong class='adj'> adj. </strong> Not allowable.</p>",
      },
    ],
  },
  {
    word: "Impersonal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impersonal <strong class='adj'> adj. </strong> 1 without personal reference; objective, impartial. 2 without human attributes; cold, unfeeling. 3 <strong class='gram'>gram.</strong> A (of a verb) used esp. With it as a subject (e.g. It is snowing). B (of a pronoun) = *indefinite. impersonality <strong class='noun'> n. </strong> Impersonally adv.</p>",
      },
    ],
  },
  {
    word: "Impersonate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impersonate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 pretend to be (another person), esp. As entertainment or fraud. 2 act (a character). impersonation <strong class='noun'> n. </strong> Impersonator <strong class='noun'> n. </strong> [from *in-2, latin *persona]</p>",
      },
    ],
  },
  {
    word: "Impertinent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impertinent <strong class='adj'> adj. </strong> 1 insolent, disrespectful. 2 esp. Law irrelevant. impertinence <strong class='noun'> n. </strong> Impertinently adv.</p>",
      },
    ],
  },
  {
    word: "Imperturbable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imperturbable <strong class='adj'> adj. </strong> Not excitable; calm. imperturbability <strong class='noun'> n. </strong> Imperturbably adv.</p>",
      },
    ],
  },
  {
    word: "Impervious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impervious <strong class='adj'> adj. </strong> (usu. Foll. By to) 1 impermeable. 2 not responsive (to argument etc.).</p>",
      },
    ],
  },
  {
    word: "Impetigo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impetigo <strong class='noun'> n. </strong> Contagious skin infection forming pimples and sores. [latin impeto assail]</p>",
      },
    ],
  },
  {
    word: "Impetuous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impetuous <strong class='adj'> adj. </strong> 1 acting or done rashly or with sudden energy. 2 moving forcefully or rapidly. impetuosity <strong class='noun'> n. </strong> Impetuously <strong class='adv'> adv. </strong> Impetuousness <strong class='noun'> n. </strong> [latin: related to *impetus]</p>",
      },
    ],
  },
  {
    word: "Impetus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impetus <strong class='noun'> n. </strong> 1 force with which a body moves. 2 driving force or impulse. [latin impeto assail]</p>",
      },
    ],
  },
  {
    word: "Impiety",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impiety <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 lack of piety or reverence. 2 act etc. Showing this.</p>",
      },
    ],
  },
  {
    word: "Impinge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impinge <strong class='verb'> v. </strong> (-ging) (usu. Foll. By on, upon) 1 make an impact or effect. 2 encroach. impingement <strong class='noun'> n. </strong> [latin pango pact- fix]</p>",
      },
    ],
  },
  {
    word: "Impious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impious <strong class='adj'> adj. </strong> 1 not pious. 2 wicked, profane.</p>",
      },
    ],
  },
  {
    word: "Impish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impish <strong class='adj'> adj. </strong> Of or like an imp; mischievous. impishly <strong class='adv'> adv. </strong> Impishness n.</p>",
      },
    ],
  },
  {
    word: "Implacable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Implacable <strong class='adj'> adj. </strong> Unable to be appeased. implacability <strong class='noun'> n. </strong> Implacably adv.</p>",
      },
    ],
  },
  {
    word: "Implant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Implant <strong class='noun'> —v. </strong> 1 (often foll. By in) insert or fix. 2 (often foll. By in) instil (an idea etc.) In a person's mind. 3 plant. 4 a insert (tissue etc.) In a living body. B (in passive) (of a fertilized ovum) become attached to the wall of the womb. <strong class='noun'> —n. </strong> Thing implanted, esp. A piece of tissue. implantation <strong class='noun'> n. </strong> [latin: related to *plant]</p>",
      },
    ],
  },
  {
    word: "Implausible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Implausible <strong class='adj'> adj. </strong> Not plausible. implausibility <strong class='noun'> n. </strong> Implausibly adv.</p>",
      },
    ],
  },
  {
    word: "Implement",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Implement <strong class='noun'> —n. </strong> Tool, instrument, utensil. —v. Put (a decision, plan, contract, etc.) Into effect. implementation <strong class='noun'> n. </strong> [latin impleo fulfil]</p>",
      },
    ],
  },
  {
    word: "Implicate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Implicate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 (often foll. By in) show (a person) to be involved (in a crime etc.). 2 imply. [latin plico fold]</p>",
      },
    ],
  },
  {
    word: "Implication",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Implication <strong class='noun'> n. </strong> 1 thing implied. 2 implicating or implying.</p>",
      },
    ],
  },
  {
    word: "Implicit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Implicit <strong class='adj'> adj. </strong> 1 implied though not plainly expressed. 2 absolute, unquestioning (implicit belief). implicitly <strong class='adv'> adv. </strong> [latin: related to *implicate]</p>",
      },
    ],
  },
  {
    word: "Implode",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Implode <strong class='verb'> v. </strong> (-ding) (cause to) burst inwards. implosion <strong class='noun'> n. </strong> [from *in-2: cf. *explode]</p>",
      },
    ],
  },
  {
    word: "Implore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Implore <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 (often foll. By to + infin.) Entreat (a person). 2 beg earnestly for. [latin ploro weep]</p>",
      },
    ],
  },
  {
    word: "Imply",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imply <strong class='verb'> v. </strong> (-ies, -ied) 1 (often foll. By that) strongly suggest or insinuate without directly stating (what are you implying?). 2 signify, esp. As a consequence (silence implies guilt). [latin: related to *implicate]</p>",
      },
    ],
  },
  {
    word: "Impolite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impolite <strong class='adj'> adj. </strong> (impolitest) ill-mannered, uncivil, rude. impolitely <strong class='adv'> adv. </strong> Impoliteness n.</p>",
      },
    ],
  },
  {
    word: "Impolitic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impolitic <strong class='adj'> adj. </strong> Inexpedient, unwise. impoliticly adv.</p>",
      },
    ],
  },
  {
    word: "Imponderable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imponderable <strong class='adj'> —adj.</strong> 1 not able to be estimated. 2 very light; weightless. <strong class='noun'> —n. </strong> (usu. In pl.) Imponderable thing. imponderability <strong class='noun'> n. </strong> Imponderably adv.</p>",
      },
    ],
  },
  {
    word: "Import",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Import <strong class='noun'> —v. </strong> 1 bring in (esp. Foreign goods or services) to a country. 2 imply, indicate, signify. <strong class='noun'> —n. </strong> 1 (esp. In pl.) Imported article or service. 2 importing. 3 what is implied; meaning. 4 importance. importation <strong class='noun'> n. </strong> Importer <strong class='noun'> n. </strong> [latin importo carry in] </p>",
      },
    ],
  },
  {
    word: "Important",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Important <strong class='adj'> adj. </strong> 1 <strong class='adj'> (often foll. By to) </strong> of great effect or consequence; momentous. 2 (of a person) having high rank or authority. 3 pompous. importance <strong class='noun'> n. </strong> Importantly <strong class='adv'> adv. </strong> [latin importo carry in, signify]</p>",
      },
    ],
  },
  {
    word: "Importunate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Importunate <strong class='adj'> adj. </strong> Making persistent or pressing requests. importunity <strong class='noun'> n. </strong> [latin importunus inconvenient]</p>",
      },
    ],
  },
  {
    word: "Importune",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Importune <strong class='verb'> v. </strong> (-ning) 1 pester (a person) with requests. 2 solicit as a prostitute.</p>",
      },
    ],
  },
  {
    word: "Impose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impose <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 (often foll. By on, upon) lay (a tax, duty, charge, or obligation) on. 2 enforce compliance with. 3 also refl. (foll. By on, upon, or absol.) Take advantage of (will not impose on you any longer). 4 (often foll. By on, upon) inflict (a thing) on. [latin impono]</p>",
      },
    ],
  },
  {
    word: "Imposing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imposing <strong class='adj'> adj. </strong> Impressive, formidable, esp. In appearance.</p>",
      },
    ],
  },
  {
    word: "Imposition",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imposition <strong class='noun'> n. </strong> 1 imposing or being imposed. 2 unfair demand or burden. 3 tax, duty.</p>",
      },
    ],
  },
  {
    word: "Impossible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impossible <strong class='adj'> adj. </strong> 1 not possible. 2 <strong class='colloq'> colloq. </strong> Not easy, convenient, or believable. 3 <strong class='colloq'> colloq. </strong> (esp. Of a person) outrageous, intolerable. impossibility <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Impossibly adv.</p>",
      },
    ],
  },
  {
    word: "Impost1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impost1 <strong class='noun'> n. </strong> Tax, duty, or tribute. [latin impono impost- impose]</p>",
      },
    ],
  },
  {
    word: "Impost2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impost2 <strong class='noun'> n. </strong> Upper course of a pillar, carrying an arch.</p>",
      },
    ],
  },
  {
    word: "Impostor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impostor <strong class='noun'> n. </strong> (also imposter) 1 person who assumes a false character or pretends to be someone else. 2 swindler.</p>",
      },
    ],
  },
  {
    word: "Imposture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imposture <strong class='noun'> n. </strong> Fraudulent deception.</p>",
      },
    ],
  },
  {
    word: "Impotent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impotent <strong class='adj'> adj. </strong> 1 powerless, ineffective. 2 (of a male) unable to achieve an erection or orgasm. impotence n.</p>",
      },
    ],
  },
  {
    word: "Impound",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impound <strong class='verb'> v. </strong> 1 confiscate. 2 take legal possession of. 3 shut up (animals) in a pound.</p>",
      },
    ],
  },
  {
    word: "Impoverish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impoverish <strong class='verb'> v. </strong> Make poor. impoverishment <strong class='noun'> n. </strong> [french: related to *poverty]</p>",
      },
    ],
  },
  {
    word: "Impracticable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impracticable <strong class='adj'> adj. </strong> Not practicable. impracticability <strong class='noun'> n. </strong> Impracticably adv.</p>",
      },
    ],
  },
  {
    word: "Impractical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impractical <strong class='adj'> adj. </strong> 1 not practical. 2 esp. Us not practicable. impracticality n.</p>",
      },
    ],
  },
  {
    word: "Imprecation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imprecation <strong class='noun'> n. </strong> Formal oath, curse. [latin precor pray]</p>",
      },
    ],
  },
  {
    word: "Imprecise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imprecise <strong class='adj'> adj. </strong> Not precise. imprecisely <strong class='adv'> adv. </strong> Impreciseness <strong class='noun'> n. </strong> Imprecision n.</p>",
      },
    ],
  },
  {
    word: "Impregnable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impregnable <strong class='adj'> adj. </strong> Strong enough to be secure against attack. impregnability <strong class='noun'> n. </strong> Impregnably <strong class='adv'> adv. </strong> [french: related to *in-1, latin prehendo take]</p>",
      },
    ],
  },
  {
    word: "Impregnate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impregnate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 (often foll. By with) fill or saturate. 2 (often foll. By with) imbue (with feelings etc.). 3 a make (a female) pregnant. B fertilize (an ovum). impregnation <strong class='noun'> n. </strong> [latin: related to *pregnant]</p>",
      },
    ],
  },
  {
    word: "Impresario",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impresario <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> organizer of public entertainment, esp. A theatrical etc. Manager. [italian]</p>",
      },
    ],
  },
  {
    word: "Impress",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impress <strong class='noun'> —v. </strong> 1 (often foll. By with) a affect or influence deeply. B affect (a person) favourably (was most impressed). 2 (often foll. By on) emphasize (an idea etc.) (must impress on you the need to be prompt). 3 a (often foll. By on) imprint or make (a mark). B mark (a thing) with a stamp, seal, etc. <strong class='noun'> —n. </strong> 1 mark made by a seal, stamp, etc. 2 characteristic mark or quality. impressible adj. [french: related to *press1]</p>",
      },
    ],
  },
  {
    word: "Impression",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impression <strong class='noun'> n. </strong> 1 effect (esp. On the mind or feelings). 2 notion or belief (esp. Vague or mistaken). 3 imitation of a person or sound, esp. As entertainment. 4 a impressing. B mark impressed.</p>",
      },
    ],
  },
  {
    word: "Impressionable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impressionable <strong class='adj'> adj. </strong> Easily influenced. impressionability <strong class='noun'> n. </strong> Impressionably adv.</p>",
      },
    ],
  },
  {
    word: "Impressionism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impressionism <strong class='noun'> n. </strong> 1 style or movement in art concerned with conveying the effect of natural light on objects. 2 style of music or writing seeking to convey esp. Fleeting feelings or experience. impressionist <strong class='noun'> n. </strong> Impressionistic adj.</p>",
      },
    ],
  },
  {
    word: "Impressive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impressive <strong class='adj'> adj. </strong> Arousing respect, approval, or admiration. impressively <strong class='adv'> adv. </strong> Impressiveness n.</p>",
      },
    ],
  },
  {
    word: "Imprimatur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imprimatur <strong class='noun'> n. </strong> 1 rc ch. Licence to print (a religious book etc.). 2 official approval. [latin, = let it be printed] <strong class='usage'> Usage:- </strong>imprimatur is sometimes confused with sense 2 of imprint.</p>",
      },
    ],
  },
  {
    word: "Imprint",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imprint <strong class='noun'> —v. </strong> 1 (often foll. By on) impress firmly, esp. On the mind. 2 a (often foll. By on) make a stamp or impression of (a figure etc.) On a thing. B make an impression on (a thing) with a stamp etc. <strong class='noun'> —n. </strong> 1 impression, stamp. 2 printer's or publisher's name etc. Printed in a book. <strong class='usage'> Usage:- </strong>see note at imprimatur.</p>",
      },
    ],
  },
  {
    word: "Imprison",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imprison <strong class='verb'> v. </strong> 1 put in prison. 2 confine. imprisonment n.</p>",
      },
    ],
  },
  {
    word: "Improbable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Improbable <strong class='adj'> adj. </strong> 1 unlikely. 2 difficult to believe. improbability <strong class='noun'> n. </strong> Improbably adv.</p>",
      },
    ],
  },
  {
    word: "Improbity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Improbity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 wickedness; dishonesty. 2 wicked or dishonest act.</p>",
      },
    ],
  },
  {
    word: "Impromptu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impromptu <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> Extempore, unrehearsed. <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 extempore performance or speech. 2 short, usu. Solo, instrumental composition, often improvisatory in style. [french from latin in promptu in readiness]</p>",
      },
    ],
  },
  {
    word: "Improper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Improper <strong class='adj'> adj. </strong> 1 unseemly; indecent. 2 inaccurate, wrong. improperly adv.</p>",
      },
    ],
  },
  {
    word: "Improper fraction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Improper fraction <strong class='noun'> n. </strong> Fraction in which the numerator is greater than or equal to the denominator.</p>",
      },
    ],
  },
  {
    word: "Impropriety",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impropriety <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 lack of propriety; indecency. 2 instance of this. 3 incorrectness.</p>",
      },
    ],
  },
  {
    word: "Improve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Improve <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> 1 a make or become better. B (foll. By on, upon) produce something better than. 2 (as improving adj.) Giving moral benefit (improving literature). improvable adj. Improvement <strong class='noun'> n. </strong> [anglo-french emprower from french prou profit]</p>",
      },
    ],
  },
  {
    word: "Improvident",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Improvident <strong class='adj'> adj. </strong> 1 lacking foresight. 2 profligate; wasteful. 3 incautious. improvidence <strong class='noun'> n. </strong> Improvidently adv.</p>",
      },
    ],
  },
  {
    word: "Improvise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Improvise <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> (also absol.) 1 compose or perform (music, verse, etc.) Extempore. 2 provide or construct from materials not intended for the purpose. improvisation <strong class='noun'> n. </strong> Improvisational adj. Improvisatory adj. [latin improvisus unforeseen]</p>",
      },
    ],
  },
  {
    word: "Imprudent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Imprudent <strong class='adj'> adj. </strong> Unwise, indiscreet. imprudence <strong class='noun'> n. </strong> Imprudently adv.</p>",
      },
    ],
  },
  {
    word: "Impudent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impudent <strong class='adj'> adj. </strong> Impertinent. impudence <strong class='noun'> n. </strong> Impudently <strong class='adv'> adv. </strong> [latin pudeo be ashamed]</p>",
      },
    ],
  },
  {
    word: "Impugn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impugn <strong class='verb'> v. </strong> Challenge or call in question. impugnment <strong class='noun'> n. </strong> [latin pugno fight]</p>",
      },
    ],
  },
  {
    word: "Impulse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impulse <strong class='noun'> n. </strong> 1 sudden urge (felt an impulse to laugh). 2 tendency to follow such urges (man of impulse). 3 impelling; a push. 4 impetus. 5 physics a large temporary force producing a change of momentum (e.g. A hammer-blow). B change of momentum so produced. 6 wave of excitation in a nerve. [latin: related to *pulse1]</p>",
      },
    ],
  },
  {
    word: "Impulse buying",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impulse buying <strong class='noun'> n. </strong> Purchasing goods on impulse.</p>",
      },
    ],
  },
  {
    word: "Impulsion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impulsion <strong class='noun'> n. </strong> 1 impelling. 2 mental impulse. 3 impetus.</p>",
      },
    ],
  },
  {
    word: "Impulsive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impulsive <strong class='adj'> adj. </strong> 1 tending to act on impulse. 2 done on impulse. 3 tending to impel. impulsively <strong class='adv'> adv. </strong> Impulsiveness n.</p>",
      },
    ],
  },
  {
    word: "Impunity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impunity <strong class='noun'> n. </strong> Exemption from punishment, bad consequences, etc. with impunity without punishment etc. [latin poena penalty]</p>",
      },
    ],
  },
  {
    word: "Impure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impure <strong class='adj'> adj. </strong> 1 adulterated. 2 dirty. 3 unchaste.</p>",
      },
    ],
  },
  {
    word: "Impurity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impurity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 being impure. 2 impure thing or part.</p>",
      },
    ],
  },
  {
    word: "Impute",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Impute <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (foll. By to) attribute (a fault etc.) To. imputation <strong class='noun'> n. </strong> [latin puto reckon]</p>",
      },
    ],
  },
  {
    word: "In",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In <strong class='prep'> —prep. </strong> 1 expressing inclusion or position within limits of space, time, circumstance, etc. (in england; in bed; in 1989; in the rain). 2 a within (a certain time) (finished it in two hours).</p>",
      },
    ],
  },
  {
    word: "In.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>In. <strong class='abbr'> Abbr. </strong> Inch(es).</p>",
      },
    ],
  },
  {
    word: "In-1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In-1 <strong class='prefix '> prefix </strong> (also il-, im-, ir-) added to: 1 adjectives, meaning ‘not’ (inedible; insane). 2 nouns, meaning ‘without, lacking’ (inaction). [latin]</p>",
      },
    ],
  },
  {
    word: "In-2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In-2 <strong class='prefix '> prefix </strong> (also il-, im-, ir-) in, on, into, towards, within (induce; influx; insight; intrude). [from *in, or from latin in (prep.)]</p>",
      },
    ],
  },
  {
    word: "Inability",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inability <strong class='noun'> n. </strong> 1 being unable. 2 lack of power or means.</p>",
      },
    ],
  },
  {
    word: "In absentia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In absentia <strong class='adv'> adv. </strong> In (his, her, or their) absence. [latin]</p>",
      },
    ],
  },
  {
    word: "Inaccessible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inaccessible <strong class='adj'> adj. </strong> 1 not accessible. 2 (of a person) unapproachable. inaccessibility n.</p>",
      },
    ],
  },
  {
    word: "Inaccurate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inaccurate <strong class='adj'> adj. </strong> Not accurate. inaccuracy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Inaccurately adv.</p>",
      },
    ],
  },
  {
    word: "Inaction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inaction <strong class='noun'> n. </strong> Lack of action.</p>",
      },
    ],
  },
  {
    word: "Inactive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inactive <strong class='adj'> adj. </strong> 1 not active. 2 not operating. 3 indolent. inactivity n.</p>",
      },
    ],
  },
  {
    word: "Inadequate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inadequate <strong class='adj'> adj. </strong> 1 not adequate; insufficient. 2 (of a person) incompetent; weak. inadequacy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Inadequately adv.</p>",
      },
    ],
  },
  {
    word: "Inadmissible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inadmissible <strong class='adj'> adj. </strong> That cannot be admitted or allowed. inadmissibility <strong class='noun'> n. </strong> Inadmissibly adv.</p>",
      },
    ],
  },
  {
    word: "Inadvertent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inadvertent <strong class='adj'> adj. </strong> 1 unintentional. 2 negligent, inattentive. inadvertence <strong class='noun'> n. </strong> Inadvertently <strong class='adv'> adv. </strong> [from *in-1, *advert]</p>",
      },
    ],
  },
  {
    word: "Inadvisable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inadvisable <strong class='adj'> adj. </strong> Not advisable. inadvisability n.</p>",
      },
    ],
  },
  {
    word: "Inalienable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inalienable <strong class='adj'> adj. </strong> That cannot be transferred to another or taken away (inalienable rights).</p>",
      },
    ],
  },
  {
    word: "Inamorato",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inamorato <strong class='noun'> n. </strong> (fem. Inamorata) <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> literary lover. [italian inamorato: related to *in-2, latin amor love]</p>",
      },
    ],
  },
  {
    word: "Inane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inane <strong class='adj'> adj. </strong> 1 silly, senseless. 2 empty, void. inanely <strong class='adv'> adv. </strong> Inanity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. [latin inanis]</p>",
      },
    ],
  },
  {
    word: "Inanimate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inanimate <strong class='adj'> adj. </strong> 1 not endowed with, or deprived of, animal life (an inanimate object). 2 spiritless, dull.</p>",
      },
    ],
  },
  {
    word: "Inapplicable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inapplicable <strong class='adj'> adj. </strong> <strong class='adj'> (often foll. By to) </strong> not applicable or relevant. inapplicability n.</p>",
      },
    ],
  },
  {
    word: "Inapposite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inapposite <strong class='adj'> adj. </strong> Not apposite.</p>",
      },
    ],
  },
  {
    word: "Inappropriate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inappropriate <strong class='adj'> adj. </strong> Not appropriate. inappropriately <strong class='adv'> adv. </strong> Inappropriateness n.</p>",
      },
    ],
  },
  {
    word: "Inapt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inapt <strong class='adj'> adj. </strong> 1 not apt or suitable. 2 unskilful. inaptitude n.</p>",
      },
    ],
  },
  {
    word: "Inarticulate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inarticulate <strong class='adj'> adj. </strong> 1 unable to express oneself clearly. 2 (of speech) not articulate; indistinct. 3 dumb. 4 esp. Anat. Not jointed. inarticulately adv.</p>",
      },
    ],
  },
  {
    word: "Inasmuch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inasmuch <strong class='adv'> adv. </strong> (foll. By as) 1 since, because. 2 to the extent that. [from in as much]</p>",
      },
    ],
  },
  {
    word: "Inattentive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inattentive <strong class='adj'> adj. </strong> 1 not paying attention. 2 neglecting to show courtesy. inattention <strong class='noun'> n. </strong> Inattentively adv.</p>",
      },
    ],
  },
  {
    word: "Inaudible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inaudible <strong class='adj'> adj. </strong> Unable to be heard. inaudibly adv.</p>",
      },
    ],
  },
  {
    word: "Inaugural",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inaugural <strong class='adj'> —adj.</strong> Of or for an inauguration. <strong class='noun'> —n. </strong> Inaugural speech, lecture, etc. [french from latin auguro take omens: related to *augur]</p>",
      },
    ],
  },
  {
    word: "Inaugurate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inaugurate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 admit formally to office. 2 begin (an undertaking) or initiate the public use of (a building etc.), with a ceremony. 3 begin, introduce. inauguration <strong class='noun'> n. </strong> Inaugurator n.</p>",
      },
    ],
  },
  {
    word: "Inauspicious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inauspicious <strong class='adj'> adj. </strong> 1 ill-omened, not favourable. 2 unlucky. inauspiciously <strong class='adv'> adv. </strong> Inauspiciousness n.</p>",
      },
    ],
  },
  {
    word: "In-between",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In-between <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Colloq. Intermediate.</p>",
      },
    ],
  },
  {
    word: "Inboard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inboard <strong class='adv'> —adv. </strong> Within the sides or towards the centre of a ship, aircraft, or vehicle. <strong class='adj'> —adj.</strong> Situated inboard.</p>",
      },
    ],
  },
  {
    word: "Inborn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inborn <strong class='adj'> adj. </strong> Existing from birth; natural, innate.</p>",
      },
    ],
  },
  {
    word: "Inbred",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inbred <strong class='adj'> adj. </strong> 1 inborn. 2 produced by inbreeding.</p>",
      },
    ],
  },
  {
    word: "Inbreeding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inbreeding <strong class='noun'> n. </strong> Breeding from closely related animals or persons. inbreed v. (past and  <strong class='archaic_it_was'>past part.</strong> -bred).</p>",
      },
    ],
  },
  {
    word: "Inbuilt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Inbuilt <strong class='adj'> adj. </strong> Built-in.</p>",
      },
    ],
  },
  {
    word: "Inc.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inc. <strong class='abbr'> Abbr. </strong> Us incorporated.</p>",
      },
    ],
  },
  {
    word: "Inca",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inca <strong class='noun'> n. </strong> Member of a people of peru before the spanish conquest. [quechua, = lord]</p>",
      },
    ],
  },
  {
    word: "Incalculable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incalculable <strong class='adj'> adj. </strong> 1 too great for calculation. 2 not calculable beforehand. 3 uncertain, unpredictable. incalculability <strong class='noun'> n. </strong> Incalculably adv.</p>",
      },
    ],
  },
  {
    word: "Incandesce",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incandesce <strong class='verb'> v. </strong> (-cing) (cause to) glow with heat.</p>",
      },
    ],
  },
  {
    word: "Incandescent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incandescent <strong class='adj'> adj. </strong> 1 glowing with heat. 2 shining. 3 (of artificial light) produced by a glowing filament etc. incandescence <strong class='noun'> n. </strong> [latin candeo be white]</p>",
      },
    ],
  },
  {
    word: "Incantation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incantation <strong class='noun'> n. </strong> Magical formula; spell, charm. incantational adj. [latin canto sing]</p>",
      },
    ],
  },
  {
    word: "Incapable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incapable <strong class='adj'> adj. </strong> 1 a not capable. B too honest, kind, etc., to do something (incapable of hurting anyone). 2 not capable of rational conduct (drunk and incapable). incapability <strong class='noun'> n. </strong> Incapably adv.</p>",
      },
    ],
  },
  {
    word: "Incapacitate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incapacitate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> make incapable or unfit.</p>",
      },
    ],
  },
  {
    word: "Incapacity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incapacity <strong class='noun'> n. </strong> 1 inability; lack of power. 2 legal disqualification.</p>",
      },
    ],
  },
  {
    word: "Incarcerate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incarcerate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> imprison. incarceration <strong class='noun'> n. </strong> [medieval latin carcer prison]</p>",
      },
    ],
  },
  {
    word: "Incarnate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incarnate <strong class='adj'> —adj.</strong> Embodied in flesh, esp. In human form (is the devil incarnate). —v. <strong class='ting'> (-ting) </strong> 1 embody in flesh. 2 put (an idea etc.) Into concrete form. 3 be the living embodiment of (a quality). [latin incarnor be made flesh: related to *carnage]</p>",
      },
    ],
  },
  {
    word: "Incarnation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incarnation <strong class='noun'> n. </strong> 1 a embodiment in (esp. Human) flesh. B (the incarnation) the embodiment of god in christ. 2 (often foll. By of) living type (of a quality etc.).</p>",
      },
    ],
  },
  {
    word: "Incautious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incautious <strong class='adj'> adj. </strong> Heedless, rash. incautiously adv.</p>",
      },
    ],
  },
  {
    word: "Incendiary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incendiary <strong class='adj'> —adj.</strong> 1 (of a bomb) designed to cause fires. 2 a of arson. B guilty of arson. 3 inflammatory. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 incendiary bomb. 2 arsonist. incendiarism <strong class='noun'> n. </strong> [latin incendo -cens- set fire to]</p>",
      },
    ],
  },
  {
    word: "Incense1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incense1 <strong class='noun'> n. </strong> 1 gum or spice producing a sweet smell when burned. 2 smoke of this, esp. In religious ceremonial. [church latin incensum]</p>",
      },
    ],
  },
  {
    word: "Incense2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incense2 <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> make angry. [latin: related to *incendiary]</p>",
      },
    ],
  },
  {
    word: "Incense2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incense2 <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> make angry. [latin: related to *incendiary]</p>",
      },
    ],
  },
  {
    word: "Incentive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incentive <strong class='noun'> —n. </strong> 1 motive or incitement. 2 payment or concession encouraging effort in work. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Serving to motivate or incite (incentive scheme). [latin incentivus that sets the tune]</p>",
      },
    ],
  },
  {
    word: "Inception",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inception <strong class='noun'> n. </strong> Beginning. [latin incipio -cept- begin]</p>",
      },
    ],
  },
  {
    word: "Inceptive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inceptive <strong class='adj'> adj. </strong> 1 a beginning. B initial. 2 (of a verb) denoting the beginning of an action.</p>",
      },
    ],
  },
  {
    word: "Incessant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incessant <strong class='adj'> adj. </strong> Unceasing, continual, repeated. incessantly <strong class='adv'> adv. </strong> [latin cesso cease]</p>",
      },
    ],
  },
  {
    word: "Incest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incest <strong class='noun'> n. </strong> Sexual intercourse between persons too closely related to marry. [latin castus chaste]</p>",
      },
    ],
  },
  {
    word: "Incestuous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incestuous <strong class='adj'> adj. </strong> 1 of or guilty of incest. 2 having relationships restricted to a particular group or organization. incestuously adv.</p>",
      },
    ],
  },
  {
    word: "Inch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inch <strong class='noun'> —n. </strong> 1 linear measure of 1/12 of a foot (2.54 cm). 2 (as a unit of rainfall) 1 inch depth of water. 3 (as a unit of map-scale) so many inches representing 1 mile. 4 small amount (usu. With neg.: would not yield an inch). —v. Move gradually. every inch entirely (looked every inch a queen). Within an inch of almost to the point of. [old english from latin uncia *ounce]</p>",
      },
    ],
  },
  {
    word: "Inchoate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inchoate <strong class='adj'> adj. </strong> 1 just begun. 2 undeveloped. inchoation <strong class='noun'> n. </strong> [latin inchoo, incoho begin] <strong class='usage'> Usage:- </strong>inchoate is sometimes used incorrectly to mean ‘chaotic’ or ‘incoherent’.</p>",
      },
    ],
  },
  {
    word: "Incidence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incidence <strong class='noun'> n. </strong> 1 (often foll. By of) range, scope, extent, or rate of occurrence or influence (of disease, tax, etc.). 2 falling of a line, ray, particles, etc., on a surface. 3 coming into contact with a thing. [latin cado fall]</p>",
      },
    ],
  },
  {
    word: "Incident",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incident <strong class='noun'> —n. </strong> 1 occurrence, esp. A minor one. 2 public disturbance (the march took place without incident). 3 clash of armed forces (frontier incident). 4 distinct piece of action in a play, film, etc. <strong class='adj'> —adj.</strong> 1 <strong class='adj'> (often foll. By to) </strong> apt to occur; naturally attaching. 2 (often foll. By on, upon) (of light etc.) Falling. [latin cado fall]</p>",
      },
    ],
  },
  {
    word: "Incidental",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incidental <strong class='adj'> —adj.</strong> <strong class='adj'> (often foll. By to) </strong> 1 small and relatively unimportant, minor; supplementary. 2 not essential. <strong class='noun'> —n. </strong> (usu. In pl.) Minor detail, expense, event, etc.</p>",
      },
    ],
  },
  {
    word: "Incidentally",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incidentally <strong class='adv'> adv. </strong> 1 by the way. 2 in an incidental way.</p>",
      },
    ],
  },
  {
    word: "Incidental music",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incidental music <strong class='noun'> n. </strong> Background music in a film, broadcast, etc.</p>",
      },
    ],
  },
  {
    word: "Incinerate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incinerate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> burn to ashes. incineration <strong class='noun'> n. </strong> [medieval latin cinis ciner- ashes]</p>",
      },
    ],
  },
  {
    word: "Incinerator",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incinerator <strong class='noun'> n. </strong> Furnace or device for incineration.</p>",
      },
    ],
  },
  {
    word: "Incipient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incipient <strong class='adj'> adj. </strong> 1 beginning. 2 in an early stage. [latin incipio begin]</p>",
      },
    ],
  },
  {
    word: "Incise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incise <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 make a cut in. 2 engrave. [latin caedo cut]</p>",
      },
    ],
  },
  {
    word: "Incision",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incision <strong class='noun'> n. </strong> 1 cutting, esp. By a surgeon. 2 cut made in this way.</p>",
      },
    ],
  },
  {
    word: "Incisive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incisive <strong class='adj'> adj. </strong> 1 sharp. 2 clear and effective.</p>",
      },
    ],
  },
  {
    word: "Incisor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incisor <strong class='noun'> n. </strong> Cutting-tooth, esp. At the front of the mouth.</p>",
      },
    ],
  },
  {
    word: "Incite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incite <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> <strong class='adj'> (often foll. By to) </strong> urge or stir up. incitement <strong class='noun'> n. </strong> [latin cito rouse]</p>",
      },
    ],
  },
  {
    word: "Incivility",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incivility <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 rudeness. 2 impolite act.</p>",
      },
    ],
  },
  {
    word: "Inclement",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inclement <strong class='adj'> adj. </strong> (of the weather) severe or stormy. inclemency n.</p>",
      },
    ],
  },
  {
    word: "Inclination",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inclination <strong class='noun'> n. </strong> 1 disposition or propensity. 2 liking, affection. 3 slope, slant. 4 angle between lines. 5 dip of a magnetic needle. 6 slow nod of the head. [latin: related to *incline]</p>",
      },
    ],
  },
  {
    word: "Incline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incline <strong class='noun'> —v. </strong> (-ning) 1 (usu. In passive) a dispose or influence (am inclined to think so; does not incline me to agree; don't feel inclined). B have a specified tendency (the door is inclined to bang). 2 a be disposed (i incline to think so). B (often foll. By to, towards) tend. 3 (cause to) lean, usu. From the vertical; slope. 4 bend forward or downward. <strong class='noun'> —n. </strong> Slope. incline one's ear listen favourably. [latin clino bend]</p>",
      },
    ],
  },
  {
    word: "Inclined plane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inclined plane <strong class='noun'> n. </strong> Sloping plane used e.g. To reduce work in raising a load.</p>",
      },
    ],
  },
  {
    word: "Include",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Include <strong class='verb'> v. </strong> (-ding) 1 comprise or reckon in as part of a whole. 2 (as including <strong class='n_pl'> comb. </strong> ) If we include (six, including me). 3 put in a certain category etc. inclusion <strong class='noun'> n. </strong> [latin includo -clus- enclose, from claudo shut]</p>",
      },
    ],
  },
  {
    word: "Inclusive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inclusive <strong class='adj'> adj. </strong> 1 (often foll. By of) including. 2 including the limits stated (pages 7 to 26 inclusive). 3 including all or much (inclusive terms). inclusively <strong class='adv'> adv. </strong> Inclusiveness n.</p>",
      },
    ],
  },
  {
    word: "Incognito",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incognito <strong class='predic'> —predic. </strong> <strong class='adj'> Adj. </strong> & <strong class='adv'> adv. </strong> With one's name or identity kept secret. <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 person who is incognito. 2 pretended identity. [italian, = unknown: related to *in-1, *cognition]</p>",
      },
    ],
  },
  {
    word: "Incognizant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incognizant <strong class='adj'> adj. </strong> Formal unaware. incognizance n.</p>",
      },
    ],
  },
  {
    word: "Incoherent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incoherent <strong class='adj'> adj. </strong> 1 unintelligible. 2 lacking logic or consistency; not clear. incoherence <strong class='noun'> n. </strong> Incoherently adv.</p>",
      },
    ],
  },
  {
    word: "Incombustible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incombustible <strong class='adj'> adj. </strong> That cannot be burnt.</p>",
      },
    ],
  },
  {
    word: "Income",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Income <strong class='noun'> n. </strong> Money received, esp. Periodically or in a year, from one's work, investments, etc. [from *in, *come]</p>",
      },
    ],
  },
  {
    word: "Income tax",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Income tax <strong class='noun'> n. </strong> Tax levied on income.</p>",
      },
    ],
  },
  {
    word: "Incoming",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incoming <strong class='adj'> —adj.</strong> 1 coming in (incoming telephone calls). 2 succeeding another (incoming tenant). <strong class='noun'> —n. </strong> (usu. In pl.) Revenue, income.</p>",
      },
    ],
  },
  {
    word: "Incommensurable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incommensurable <strong class='adj'> adj. </strong> (often foll. By with) 1 not commensurable. 2 having no common factor, integral or fractional. incommensurability n.</p>",
      },
    ],
  },
  {
    word: "Incommensurate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incommensurate <strong class='adj'> adj. </strong> 1 (often foll. By with, to) out of proportion; inadequate. 2 = *incommensurable.</p>",
      },
    ],
  },
  {
    word: "Incommode",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incommode <strong class='verb'> v. </strong> (-ding) formal 1 inconvenience. 2 trouble, annoy.</p>",
      },
    ],
  },
  {
    word: "Incommodious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incommodious <strong class='adj'> adj. </strong> Formal too small for comfort; inconvenient.</p>",
      },
    ],
  },
  {
    word: "Incommunicable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incommunicable <strong class='adj'> adj. </strong> That cannot be communicated.</p>",
      },
    ],
  },
  {
    word: "Incommunicado",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incommunicado <strong class='adj'> adj. </strong> 1 without means of communication. 2 (of a prisoner) in solitary confinement. [spanish incomunicado]</p>",
      },
    ],
  },
  {
    word: "Incommunicative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incommunicative <strong class='adj'> adj. </strong> Uncommunicative.</p>",
      },
    ],
  },
  {
    word: "Incomparable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incomparable <strong class='adj'> adj. </strong> Without an equal; matchless. incomparability <strong class='noun'> n. </strong> Incomparably adv.</p>",
      },
    ],
  },
  {
    word: "Incompatible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incompatible <strong class='adj'> adj. </strong> Not compatible. incompatibility n.</p>",
      },
    ],
  },
  {
    word: "Incompetent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incompetent <strong class='adj'> —adj.</strong> Lacking the necessary skill. <strong class='noun'> —n. </strong> Incompetent person. incompetence n.</p>",
      },
    ],
  },
  {
    word: "Incomplete",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incomplete <strong class='adj'> adj. </strong> Not complete.</p>",
      },
    ],
  },
  {
    word: "Incomprehensible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incomprehensible <strong class='adj'> adj. </strong> That cannot be understood.</p>",
      },
    ],
  },
  {
    word: "Incomprehension",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incomprehension <strong class='noun'> n. </strong> Failure to understand.</p>",
      },
    ],
  },
  {
    word: "Inconceivable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconceivable <strong class='adj'> adj. </strong> 1 that cannot be imagined. 2 <strong class='colloq'> colloq. </strong> Most unlikely. inconceivably adv.</p>",
      },
    ],
  },
  {
    word: "Inconclusive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconclusive <strong class='adj'> adj. </strong> (of an argument, evidence, or action) not decisive or convincing.</p>",
      },
    ],
  },
  {
    word: "Incongruous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incongruous <strong class='adj'> adj. </strong> 1 out of place; absurd. 2 (often foll. By with) out of keeping. incongruity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Incongruously adv.</p>",
      },
    ],
  },
  {
    word: "Inconsequent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconsequent <strong class='adj'> adj. </strong> 1 irrelevant. 2 lacking logical sequence. 3 disconnected. inconsequence n.</p>",
      },
    ],
  },
  {
    word: "Inconsequential",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconsequential <strong class='adj'> adj. </strong> 1 unimportant. 2 = *inconsequent. inconsequentially adv.</p>",
      },
    ],
  },
  {
    word: "Inconsiderable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconsiderable <strong class='adj'> adj. </strong> 1 of small size, value, etc. 2 not worth considering. inconsiderably adv.</p>",
      },
    ],
  },
  {
    word: "Inconsiderate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconsiderate <strong class='adj'> adj. </strong> (of a person or action) lacking regard for others; thoughtless. inconsiderately <strong class='adv'> adv. </strong> Inconsiderateness n.</p>",
      },
    ],
  },
  {
    word: "Inconsistent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconsistent <strong class='adj'> adj. </strong> Not consistent. inconsistency <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Inconsistently adv.</p>",
      },
    ],
  },
  {
    word: "Inconsolable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconsolable <strong class='adj'> adj. </strong> (of a person, grief, etc.) That cannot be consoled. inconsolably adv.</p>",
      },
    ],
  },
  {
    word: "Inconspicuous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconspicuous <strong class='adj'> adj. </strong> Not conspicuous; not easily noticed. inconspicuously <strong class='adv'> adv. </strong> Inconspicuousness n.</p>",
      },
    ],
  },
  {
    word: "Inconstant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconstant <strong class='adj'> adj. </strong> 1 fickle, changeable. 2 variable, not fixed. inconstancy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>.</p>",
      },
    ],
  },
  {
    word: "Incontestable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incontestable <strong class='adj'> adj. </strong> That cannot be disputed. incontestably adv.</p>",
      },
    ],
  },
  {
    word: "Incontinent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incontinent <strong class='adj'> adj. </strong> 1 unable to control the bowels or bladder. 2 lacking self-restraint (esp. In sexual matters). incontinence n.</p>",
      },
    ],
  },
  {
    word: "Incontrovertible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incontrovertible <strong class='adj'> adj. </strong> Indisputable, undeniable. incontrovertibly adv.</p>",
      },
    ],
  },
  {
    word: "Inconvenience",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconvenience <strong class='noun'> —n. </strong> 1 lack of ease or comfort; trouble. 2 cause or instance of this. —v. (-cing) cause inconvenience to.</p>",
      },
    ],
  },
  {
    word: "Inconvenient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inconvenient <strong class='adj'> adj. </strong> Causing trouble, difficulty, or discomfort; awkward. inconveniently adv.</p>",
      },
    ],
  },
  {
    word: "Incorporate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incorporate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> 1 include as a part or ingredient (incorporated all the latest features). 2 (often foll. By in, with) unite (in one body). 3 admit as a member of a company etc. 4 (esp. As incorporated adj.) Form into a legal corporation. <strong class='adj'> —adj.</strong> Incorporated. incorporation <strong class='noun'> n. </strong> [latin corpus body]</p>",
      },
    ],
  },
  {
    word: "Incorporeal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incorporeal <strong class='adj'> adj. </strong> Without physical or material existence. incorporeally <strong class='adv'> adv. </strong> Incorporeity n.</p>",
      },
    ],
  },
  {
    word: "Incorrect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incorrect <strong class='adj'> adj. </strong> 1 not correct or true. 2 improper, unsuitable. incorrectly adv.</p>",
      },
    ],
  },
  {
    word: "Incorrigible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incorrigible <strong class='adj'> adj. </strong> (of a person or habit) that cannot be corrected or improved. incorrigibility <strong class='noun'> n. </strong> Incorrigibly adv.</p>",
      },
    ],
  },
  {
    word: "Incorruptible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incorruptible <strong class='adj'> adj. </strong> 1 that cannot be corrupted, esp. By bribery. 2 that cannot decay. incorruptibility <strong class='noun'> n. </strong> Incorruptibly adv.</p>",
      },
    ],
  },
  {
    word: "Increase",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Increase <strong class='noun'> —v. </strong> <strong class='sing'>(-sing)</strong> make or become greater or more numerous. <strong class='noun'> —n. </strong> 1 growth, enlargement. 2 (of people, animals, or plants) multiplication. 3 amount or extent of an increase. on the increase increasing. [latin cresco grow]</p>",
      },
    ],
  },
  {
    word: "Increasingly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Increasingly <strong class='adv'> adv. </strong> More and more.</p>",
      },
    ],
  },
  {
    word: "Incredible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incredible <strong class='adj'> adj. </strong> 1 that cannot be believed. 2 <strong class='colloq'> colloq. </strong> Amazing, extremely good. incredibility <strong class='noun'> n. </strong> Incredibly adv.</p>",
      },
    ],
  },
  {
    word: "Incredulous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incredulous <strong class='adj'> adj. </strong> Unwilling to believe; showing disbelief. incredulity <strong class='noun'> n. </strong> Incredulously adv.</p>",
      },
    ],
  },
  {
    word: "Increment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Increment <strong class='noun'> n. </strong> Increase or added amount, esp. On a fixed salary scale. incremental adj. [latin cresco grow]</p>",
      },
    ],
  },
  {
    word: "Incriminate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incriminate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 make (a person) appear to be guilty. 2 charge with a crime. incrimination <strong class='noun'> n. </strong> Incriminatory adj. [latin: related to *crime]</p>",
      },
    ],
  },
  {
    word: "Incrustation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incrustation <strong class='noun'> n. </strong> 1 encrusting. 2 crust or hard coating. 3 deposit on a surface. [latin: related to *crust]</p>",
      },
    ],
  },
  {
    word: "Incubate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incubate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 hatch (eggs) by sitting on them or by artificial heat. 2 cause (micro-organisms) to develop. 3 develop slowly. [latin cubo lie]</p>",
      },
    ],
  },
  {
    word: "Incubation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incubation <strong class='noun'> n. </strong> 1 incubating. 2 period between infection and the appearance of the first symptoms.</p>",
      },
    ],
  },
  {
    word: "Incubator",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incubator <strong class='noun'> n. </strong> Apparatus providing artificial warmth for hatching eggs, rearing premature babies, or developing micro-organisms.</p>",
      },
    ],
  },
  {
    word: "Incubus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incubus <strong class='noun'> n. </strong> (pl. -buses or -bi) 1 demon formerly believed to have sexual intercourse with sleeping women. 2 nightmare. 3 oppressive person or thing. [latin: as *incubate]</p>",
      },
    ],
  },
  {
    word: "Inculcate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inculcate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (often foll. By upon, in) urge or impress (a habit or idea) persistently. inculcation <strong class='noun'> n. </strong> [latin calco tread]</p>",
      },
    ],
  },
  {
    word: "Incumbency",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incumbency <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> office or tenure of an incumbent.</p>",
      },
    ],
  },
  {
    word: "Incumbent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incumbent <strong class='adj'> —adj.</strong> 1 resting as a duty (it is incumbent on you to do it). 2 (often foll. By on) lying, pressing. 3 currently holding office (the incumbent president). <strong class='noun'> —n. </strong> Holder of an office or post, esp. A benefice. [latin incumbo lie upon]</p>",
      },
    ],
  },
  {
    word: "Incunabulum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incunabulum <strong class='noun'> n. </strong> (pl. -la) 1 early printed book, esp. From before 1501. 2 (in pl.) Early stages of a thing. [latin, (in pl.) = swaddling-clothes]</p>",
      },
    ],
  },
  {
    word: "Incur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incur <strong class='verb'> v. </strong> <strong class='adj'> (-rr-) </strong> bring on oneself (danger, blame, loss, etc.). [latin curro run]</p>",
      },
    ],
  },
  {
    word: "Incurable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incurable <strong class='adj'> —adj.</strong> That cannot be cured. <strong class='noun'> —n. </strong> Incurable person. incurability <strong class='noun'> n. </strong> Incurably adv.</p>",
      },
    ],
  },
  {
    word: "Incurious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incurious <strong class='adj'> adj. </strong> Lacking curiosity.</p>",
      },
    ],
  },
  {
    word: "Incursion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incursion <strong class='noun'> n. </strong> Invasion or attack, esp. Sudden or brief. incursive adj. [latin: related to *incur]</p>",
      },
    ],
  },
  {
    word: "Incurve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Incurve <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> 1 bend into a curve. 2 (as incurved adj.) Curved inwards. incurvation n.</p>",
      },
    ],
  },
  {
    word: "Indebted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indebted <strong class='adj'> adj. </strong> (usu. Foll. By to) owing gratitude or money. indebtedness <strong class='noun'> n. </strong> [french endetté: related to *debt]</p>",
      },
    ],
  },
  {
    word: "Indecent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indecent <strong class='adj'> adj. </strong> 1 offending against decency. 2 unbecoming; unsuitable (indecent haste). indecency <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Indecently adv.</p>",
      },
    ],
  },
  {
    word: "Indecent assault",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indecent assault <strong class='noun'> n. </strong> Sexual attack not involving rape.</p>",
      },
    ],
  },
  {
    word: "Indecent exposure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indecent exposure <strong class='noun'> n. </strong> Exposing one's genitals in public.</p>",
      },
    ],
  },
  {
    word: "Indecipherable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indecipherable <strong class='adj'> adj. </strong> That cannot be deciphered.</p>",
      },
    ],
  },
  {
    word: "Indecision",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indecision <strong class='noun'> n. </strong> Inability to decide; hesitation.</p>",
      },
    ],
  },
  {
    word: "Indecisive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indecisive <strong class='adj'> adj. </strong> 1 (of a person) not decisive; hesitating. 2 not conclusive (an indecisive battle). indecisively <strong class='adv'> adv. </strong> Indecisiveness n.</p>",
      },
    ],
  },
  {
    word: "Indeclinable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indeclinable <strong class='adj'> adj. </strong> Gram. That cannot be declined; having no inflections.</p>",
      },
    ],
  },
  {
    word: "Indecorous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indecorous <strong class='adj'> adj. </strong> 1 improper, undignified. 2 in bad taste. indecorously adv.</p>",
      },
    ],
  },
  {
    word: "Indeed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indeed <strong class='adv'> —adv. </strong> 1 in truth; really. 2 admittedly. <strong class='pl'> —int. </strong> Expressing irony, incredulity, etc.</p>",
      },
    ],
  },
  {
    word: "Indefatigable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indefatigable <strong class='adj'> adj. </strong> Unwearying, unremitting. indefatigably adv.</p>",
      },
    ],
  },
  {
    word: "Indefeasible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indefeasible <strong class='adj'> adj. </strong> Literary (esp. Of a claim, rights, etc.) That cannot be forfeited or annulled. indefeasibly adv.</p>",
      },
    ],
  },
  {
    word: "Indefensible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indefensible <strong class='adj'> adj. </strong> That cannot be defended or justified. indefensibility <strong class='noun'> n. </strong> Indefensibly adv.</p>",
      },
    ],
  },
  {
    word: "Indefinable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indefinable <strong class='adj'> adj. </strong> That cannot be defined; mysterious. indefinably adv.</p>",
      },
    ],
  },
  {
    word: "Indefinite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indefinite <strong class='adj'> adj. </strong> 1 vague, undefined. 2 unlimited. 3 (of adjectives, adverbs, and pronouns) not determining the person etc. Referred to (e.g. Some, someone, anyhow).</p>",
      },
    ],
  },
  {
    word: "Indefinite article",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indefinite article <strong class='noun'> n. </strong> Word (e.g. A, an in english) preceding a noun and implying ‘any of several’.</p>",
      },
    ],
  },
  {
    word: "Indefinitely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indefinitely <strong class='adv'> adv. </strong> 1 for an unlimited time (was postponed indefinitely). 2 in an indefinite manner.</p>",
      },
    ],
  },
  {
    word: "Indelible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indelible <strong class='adj'> adj. </strong> That cannot be rubbed out or removed. indelibly <strong class='adv'> adv. </strong> [latin deleo efface]</p>",
      },
    ],
  },
  {
    word: "Indelicate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indelicate <strong class='adj'> adj. </strong> 1 coarse, unrefined. 2 tactless. indelicacy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Indelicately adv.</p>",
      },
    ],
  },
  {
    word: "Indemnify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indemnify <strong class='verb'> v. </strong> (-ies, -ied) 1 (often foll. By from, against) secure (a person) in respect of harm, a loss, etc. 2 (often foll. By for) exempt from a penalty. 3 compensate. indemnification <strong class='noun'> n. </strong> [latin indemnis free from loss]</p>",
      },
    ],
  },
  {
    word: "Indemnity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indemnity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a compensation for damage. B sum exacted by a victor in war. 2 security against loss. 3 exemption from penalties.</p>",
      },
    ],
  },
  {
    word: "Indent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indent <strong class='noun'> —v. </strong> 1 make or impress marks, notches, dents, etc. In. 2 start (a line of print or writing) further from the margin than others. 3 draw up (a legal document) in duplicate. 4 a (often foll. By on, upon a person, for a thing) make a requisition. B order (goods) by requisition. <strong class='noun'> —n. </strong> 1 a order (esp. From abroad) for goods. B official requisition for stores. 2 indented line. 3 indentation. 4 indenture. [latin dens dentis tooth]</p>",
      },
    ],
  },
  {
    word: "Indentation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indentation <strong class='noun'> n. </strong> 1 indenting or being indented. 2 notch.</p>",
      },
    ],
  },
  {
    word: "Indention",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indention <strong class='noun'> n. </strong> 1 indenting, esp. In printing. 2 notch.</p>",
      },
    ],
  },
  {
    word: "Indenture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indenture <strong class='noun'> —n. </strong> 1 (usu. In pl.) Sealed agreement or contract. 2 formal list, certificate, etc. —v. <strong class='adj'> (-ring) </strong> hist. Bind by indentures, esp. As an apprentice. [anglo-french: related to *indent]</p>",
      },
    ],
  },
  {
    word: "Independent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Independent <strong class='adj'> —adj.</strong> 1 a (often foll. By of) not depending on authority or control. B self-governing. 2 a not depending on another person for one's opinions or livelihood. B (of income or resources) making it unnecessary to earn one's living. 3 unwilling to be under an obligation to others. 4 acting independently of any political party. 5 not depending on something else for its validity etc. (independent proof). 6 (of broadcasting, a school, etc.) Not supported by public funds. <strong class='noun'> —n. </strong> Person who is politically independent. independence <strong class='noun'> n. </strong> Independently adv.</p>",
      },
    ],
  },
  {
    word: "In-depth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In-depth <strong class='adj'> adj. </strong> Thorough.</p>",
      },
    ],
  },
  {
    word: "Indescribable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indescribable <strong class='adj'> adj. </strong> 1 too good or bad etc. To be described. 2 that cannot be described. indescribably adv.</p>",
      },
    ],
  },
  {
    word: "Indestructible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indestructible <strong class='adj'> adj. </strong> That cannot be destroyed. indestructibility <strong class='noun'> n. </strong> Indestructibly adv.</p>",
      },
    ],
  },
  {
    word: "Indeterminable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indeterminable <strong class='adj'> adj. </strong> That cannot be ascertained or settled. indeterminably adv.</p>",
      },
    ],
  },
  {
    word: "Indeterminate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indeterminate <strong class='adj'> adj. </strong> 1 not fixed in extent, character, etc. 2 left doubtful; vague. 3 math. Of no fixed value. indeterminacy n.</p>",
      },
    ],
  },
  {
    word: "Indeterminate vowel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indeterminate vowel <strong class='noun'> n. </strong> Vowel heard in ‘a moment ago’.</p>",
      },
    ],
  },
  {
    word: "Index",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Index <strong class='noun'> —n. </strong> (pl. Indexes or indices) 1 alphabetical list of subjects etc. With references, usu. At the end of a book. 2 = *card index. 3 measure of prices or wages compared with a previous month, year, etc. (retail price index). 4 math. Exponent of a number. 5 pointer, sign, or indicator. —v. 1 provide (a book etc.) With an index. 2 enter in an index. 3 relate (wages etc.) To a price index. indexation <strong class='noun'> n. </strong> (in sense 3 of v.). [latin]</p>",
      },
    ],
  },
  {
    word: "Index finger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Index finger <strong class='noun'> n. </strong> Forefinger.</p>",
      },
    ],
  },
  {
    word: "Index-linked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Index-linked <strong class='adj'> adj. </strong> Related to the value of a price index.</p>",
      },
    ],
  },
  {
    word: "Indiaman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indiaman <strong class='noun'> n. </strong> (pl. -men) hist. Ship engaged in trade with india or the east indies.</p>",
      },
    ],
  },
  {
    word: "Indian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indian <strong class='noun'> —n. </strong> 1 a native or national of india. B person of indian descent. 2 (in full american indian) a original inhabitant of america. B any of the languages of the american indians. <strong class='adj'> —adj.</strong> 1 of india or the subcontinent comprising india, pakistan, and bangladesh. 2 of the original peoples of america.</p>",
      },
    ],
  },
  {
    word: "Indian corn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Indian corn <strong class='noun'> n. </strong> Maize.</p>",
      },
    ],
  },
  {
    word: "Indian elephant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indian elephant <strong class='noun'> n. </strong> The elephant of india, smaller than the african elephant.</p>",
      },
    ],
  },
  {
    word: "Indian file",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indian file <strong class='noun'> n. </strong> = *single file.</p>",
      },
    ],
  },
  {
    word: "Indian hemp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indian hemp <strong class='see'> see *</strong> hemp 1.</p>",
      },
    ],
  },
  {
    word: "Indian ink",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indian ink <strong class='noun'> n. </strong> 1 black pigment. 2 ink made from this.</p>",
      },
    ],
  },
  {
    word: "Indian summer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indian summer <strong class='noun'> n. </strong> 1 dry warm weather in late autumn. 2 late tranquil period of life.</p>",
      },
    ],
  },
  {
    word: "Indiarubber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indiarubber <strong class='noun'> n. </strong> Rubber for erasing pencil marks etc.</p>",
      },
    ],
  },
  {
    word: "Indicate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indicate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (often foll. By that) 1 point out; make known. 2 be a sign of; show the presence of. 3 call for; require (stronger measures are indicated). 4 state briefly. 5 give as a reading or measurement. 6 point by hand; use a vehicle's indicator (failed to indicate). indication <strong class='noun'> n. </strong> [latin dico make known]</p>",
      },
    ],
  },
  {
    word: "Indicative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indicative <strong class='adj'> —adj.</strong> 1 (foll. By of) suggestive; serving as an indication. 2 <strong class='gram'>gram.</strong> (of a mood) stating a fact. <strong class='noun'> —n. </strong> Gram. 1 indicative mood. 2 verb in this mood.</p>",
      },
    ],
  },
  {
    word: "Indicator",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indicator <strong class='noun'> n. </strong> 1 flashing light on a vehicle showing the direction in which it is about to turn. 2 person or thing that indicates. 3 device indicating the condition of a machine etc. 4 recording instrument. 5 board giving information, esp. Times of trains etc.</p>",
      },
    ],
  },
  {
    word: "Indicatory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indicatory <strong class='adj'> adj. </strong> (often foll. By of) indicative.</p>",
      },
    ],
  },
  {
    word: "Indices",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Indices <strong class='pl'> pl. </strong> Of *index.</p>",
      },
    ],
  },
  {
    word: "Indict",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indict <strong class='verb'> v. </strong> Accuse formally by legal process. [anglo-french: related to *in-2, *dictate]</p>",
      },
    ],
  },
  {
    word: "Indictable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indictable <strong class='adj'> adj. </strong> 1 (of an offence) making the doer liable to be charged with a crime. 2 (of a person) so liable.</p>",
      },
    ],
  },
  {
    word: "Indictment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indictment <strong class='noun'> n. </strong> 1 a indicting, accusation. B document containing this. 2 thing that serves to condemn or censure (an indictment of society).</p>",
      },
    ],
  },
  {
    word: "Indie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indie <strong class='colloq'> colloq. </strong> <strong class='adj'> —adj.</strong> (of a pop group or record label) independent, not belonging to one of the major companies. <strong class='noun'> —n. </strong> Such a group or label. [abbreviation of *independent]</p>",
      },
    ],
  },
  {
    word: "Indifference",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indifference <strong class='noun'> n. </strong> 1 lack of interest or attention. 2 unimportance.</p>",
      },
    ],
  },
  {
    word: "Indifferent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indifferent <strong class='adj'> adj. </strong> 1 (foll. By to) showing indifference or lack of interest. 2 neither good nor bad. 3 of poor quality or ability. indifferently adv.</p>",
      },
    ],
  },
  {
    word: "Indigenous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indigenous <strong class='adj'> adj. </strong> <strong class='adj'> (often foll. By to) </strong> native or belonging naturally to a place. [latin: from a root gen- be born]</p>",
      },
    ],
  },
  {
    word: "Indigent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indigent <strong class='adj'> adj. </strong> Formal needy, poor. indigence <strong class='noun'> n. </strong> [latin egeo need]</p>",
      },
    ],
  },
  {
    word: "Indigestible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indigestible <strong class='adj'> adj. </strong> 1 difficult or impossible to digest. 2 too complex to read or understand. indigestibility n.</p>",
      },
    ],
  },
  {
    word: "Indigestion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indigestion <strong class='noun'> n. </strong> 1 difficulty in digesting food. 2 pain caused by this.</p>",
      },
    ],
  },
  {
    word: "Indignant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indignant <strong class='adj'> adj. </strong> Feeling or showing indignation. indignantly <strong class='adv'> adv. </strong> [latin dignus worthy]</p>",
      },
    ],
  },
  {
    word: "Indignation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indignation <strong class='noun'> n. </strong> Anger at supposed injustice etc.</p>",
      },
    ],
  },
  {
    word: "Indignity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indignity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 humiliating treatment or quality. 2 insult.</p>",
      },
    ],
  },
  {
    word: "Indigo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indigo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 colour between blue and violet in the spectrum. 2 dye of this colour. [greek indikon indian dye]</p>",
      },
    ],
  },
  {
    word: "Indirect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indirect <strong class='adj'> adj. </strong> 1 not going straight to the point. 2 (of a route etc.) Not straight. 3 a not directly sought (indirect result). B not primary (indirect cause). indirectly adv.</p>",
      },
    ],
  },
  {
    word: "Indirect object",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indirect object <strong class='noun'> n. </strong> Gram. Person or thing affected by a verbal action but not primarily acted on (e.g. Him in give him the book).</p>",
      },
    ],
  },
  {
    word: "Indirect question",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indirect question <strong class='noun'> n. </strong> Gram. Question in indirect speech.</p>",
      },
    ],
  },
  {
    word: "Indirect speech",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indirect speech <strong class='noun'> n. </strong> = *reported speech.</p>",
      },
    ],
  },
  {
    word: "Indirect tax",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indirect tax <strong class='noun'> n. </strong> Tax on goods and services, not on income or profits.</p>",
      },
    ],
  },
  {
    word: "Indiscernible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indiscernible <strong class='adj'> adj. </strong> That cannot be discerned.</p>",
      },
    ],
  },
  {
    word: "Indiscipline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indiscipline <strong class='noun'> n. </strong> Lack of discipline.</p>",
      },
    ],
  },
  {
    word: "Indiscreet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indiscreet <strong class='adj'> adj. </strong> 1 not discreet. 2 injudicious, unwary. indiscreetly adv.</p>",
      },
    ],
  },
  {
    word: "Indiscretion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indiscretion <strong class='noun'> n. </strong> Indiscreet conduct or action.</p>",
      },
    ],
  },
  {
    word: "Indiscriminate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indiscriminate <strong class='adj'> adj. </strong> Making no distinctions; done or acting at random (indiscriminate shooting). indiscriminately adv.</p>",
      },
    ],
  },
  {
    word: "Indispensable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indispensable <strong class='adj'> adj. </strong> That cannot be dispensed with; necessary. indispensability <strong class='noun'> n. </strong> Indispensably adv.</p>",
      },
    ],
  },
  {
    word: "Indisposed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indisposed <strong class='adj'> adj. </strong> 1 slightly unwell. 2 averse or unwilling. indisposition n.</p>",
      },
    ],
  },
  {
    word: "Indisputable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indisputable <strong class='adj'> adj. </strong> That cannot be disputed. indisputably adv.</p>",
      },
    ],
  },
  {
    word: "Indissoluble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indissoluble <strong class='adj'> adj. </strong> 1 that cannot be dissolved or broken up. 2 firm and lasting. indissolubly adv.</p>",
      },
    ],
  },
  {
    word: "Indistinct",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indistinct <strong class='adj'> adj. </strong> 1 not distinct. 2 confused, obscure. indistinctly adv.</p>",
      },
    ],
  },
  {
    word: "Indistinguishable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indistinguishable <strong class='adj'> adj. </strong> (often foll. By from) not distinguishable.</p>",
      },
    ],
  },
  {
    word: "Indite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indite <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> formal or joc. 1 put (a speech etc.) Into words. 2 write (a letter etc.). [french: related to *indict]</p>",
      },
    ],
  },
  {
    word: "Indium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indium <strong class='noun'> n. </strong> Soft silvery-white metallic element occurring in zinc ores. [latin indicum *indigo]</p>",
      },
    ],
  },
  {
    word: "Individual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Individual <strong class='adj'> —adj.</strong> 1 of, for, or characteristic of, a single person etc. 2 a single (individual words). B particular; not general. 3 having a distinct character. 4 designed for use by one person. <strong class='noun'> —n. </strong> 1 single member of a class. 2 single human being. 3 <strong class='colloq'> colloq. </strong> Person (a tiresome individual). 4 distinctive person. [medieval latin: related to *divide]</p>",
      },
    ],
  },
  {
    word: "Individualism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Individualism <strong class='noun'> n. </strong> 1 social theory favouring free action by individuals. 2 being independent or different. individualist <strong class='noun'> n. </strong> Individualistic adj.</p>",
      },
    ],
  },
  {
    word: "Individuality",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Individuality <strong class='noun'> n. </strong> 1 individual character, esp. When strongly marked. 2 separate existence.</p>",
      },
    ],
  },
  {
    word: "Individualize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Individualize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 give an individual character to. 2 (esp. As individualized adj.) Personalize (individualized notepaper).</p>",
      },
    ],
  },
  {
    word: "Individually",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Individually <strong class='adv'> adv. </strong> 1 one by one. 2 personally. 3 distinctively.</p>",
      },
    ],
  },
  {
    word: "Indivisible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indivisible <strong class='adj'> adj. </strong> Not divisible.</p>",
      },
    ],
  },
  {
    word: "Indo-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indo- <strong class='n_pl'> comb. </strong> Form indian; indian and.</p>",
      },
    ],
  },
  {
    word: "Indoctrinate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indoctrinate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> teach to accept a particular belief uncritically. indoctrination n.</p>",
      },
    ],
  },
  {
    word: "Indo-european",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indo-european <strong class='adj'> —adj.</strong> 1 of the family of languages spoken over most of europe and asia as far as <strong class='noun'> n. </strong> India. 2 of the hypothetical parent language of this family. <strong class='noun'> —n. </strong> 1 indo-european family of languages. 2 hypothetical parent language of these.</p>",
      },
    ],
  },
  {
    word: "Indolent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indolent <strong class='adj'> adj. </strong> Lazy; averse to exertion. indolence <strong class='noun'> n. </strong> Indolently <strong class='adv'> adv. </strong> [latin doleo suffer pain]</p>",
      },
    ],
  },
  {
    word: "Indomitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indomitable <strong class='adj'> adj. </strong> 1 unconquerable. 2 unyielding. indomitably <strong class='adv'> adv. </strong> [latin: related to *in-1, domito tame]</p>",
      },
    ],
  },
  {
    word: "Indoor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indoor <strong class='adj'> adj. </strong> Of, done, or for use in a building or under cover.</p>",
      },
    ],
  },
  {
    word: "Indoors",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indoors <strong class='adv'> adv. </strong> Into or in a building.</p>",
      },
    ],
  },
  {
    word: "Indorse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indorse <strong class='var'> var. </strong> Of *endorse.</p>",
      },
    ],
  },
  {
    word: "Indrawn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indrawn <strong class='adj'> adj. </strong> (of breath etc.) Drawn in.</p>",
      },
    ],
  },
  {
    word: "Indubitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indubitable <strong class='adj'> adj. </strong> That cannot be doubted. indubitably <strong class='adv'> adv. </strong> [latin dubito doubt]</p>",
      },
    ],
  },
  {
    word: "Induce",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Induce <strong class='verb'> v. </strong> (-cing) 1 prevail on; persuade. 2 bring about. 3 a bring on (labour) artificially. B bring on labour in (a mother). C speed up the birth of (a baby). 4 produce (a current) by induction. 5 infer; deduce. inducible adj. [latin duco duct- lead]</p>",
      },
    ],
  },
  {
    word: "Inducement",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inducement <strong class='noun'> n. </strong> Attractive offer; incentive; bribe.</p>",
      },
    ],
  },
  {
    word: "Induct",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Induct <strong class='verb'> v. </strong> (often foll. By to, into) 1 introduce into office, install (into a benefice etc.). 2 archaic lead (to a seat, into a room, etc.); install. [related to *induce]</p>",
      },
    ],
  },
  {
    word: "Inductance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inductance <strong class='noun'> n. </strong> Property of an electric circuit generating an electromotive force by virtue of the current flowing through it.</p>",
      },
    ],
  },
  {
    word: "Induction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Induction <strong class='noun'> n. </strong> 1 act of inducting or inducing. 2 act of bringing on (esp. Labour) by artificial means. 3 inference of a general law from particular instances. 4 (often attrib.) Formal introduction to a new job etc. (induction course). 5 electr. A production of an electric or magnetic state by the proximity (without contact) of an electrified or magnetized body. B production of an electric current by a change of magnetic field. 6 drawing of the fuel mixture into the cylinders of an internal-combustion engine.</p>",
      },
    ],
  },
  {
    word: "Inductive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inductive <strong class='adj'> adj. </strong> 1 (of reasoning etc.) Based on induction. 2 of electric or magnetic induction.</p>",
      },
    ],
  },
  {
    word: "Inductor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inductor <strong class='noun'> n. </strong> Component (in an electric circuit) having inductance.</p>",
      },
    ],
  },
  {
    word: "Indue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Indue <strong class='var'> var. </strong> Of *endue.</p>",
      },
    ],
  },
  {
    word: "Indulge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indulge <strong class='verb'> v. </strong> (-ging) 1 (often foll. By in) take pleasure freely. 2 yield freely to (a desire etc.). 3 (also refl.) Gratify the wishes of. 4 <strong class='colloq'> colloq. </strong> Take alcoholic liquor. [latin indulgeo give free rein to]</p>",
      },
    ],
  },
  {
    word: "Indulgence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indulgence <strong class='noun'> n. </strong> 1 indulging or being indulgent. 2 thing indulged in. 3 rc ch. Remission of punishment still due after absolution. 4 privilege granted.</p>",
      },
    ],
  },
  {
    word: "Indulgent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Indulgent <strong class='adj'> adj. </strong> 1 lenient; ready to overlook faults etc. 2 indulging. indulgently adv.</p>",
      },
    ],
  },
  {
    word: "Industrial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Industrial <strong class='adj'> adj. </strong> 1 of, engaged in, or for use in or serving the needs of industries. 2 (of a nation etc.) Having developed industries. industrially adv.</p>",
      },
    ],
  },
  {
    word: "Industrial action",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Industrial action <strong class='noun'> n. </strong> Strike or other disruptive action by workers as a protest.</p>",
      },
    ],
  },
  {
    word: "Industrial estate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Industrial estate <strong class='noun'> n. </strong> Area of land zoned for factories etc.</p>",
      },
    ],
  },
  {
    word: "Industrialism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Industrialism <strong class='noun'> n. </strong> System in which manufacturing industries are prevalent.</p>",
      },
    ],
  },
  {
    word: "Industrialist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Industrialist <strong class='noun'> n. </strong> Owner or manager in industry.</p>",
      },
    ],
  },
  {
    word: "Industrialize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Industrialize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) make (a nation etc.) Industrial. industrialization n.</p>",
      },
    ],
  },
  {
    word: "Industrial relations",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Industrial relations <strong class='n_pl'> n.pl. </strong> Relations between management and workers.</p>",
      },
    ],
  },
  {
    word: "Industrious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Industrious <strong class='adj'> adj. </strong> Hard-working. industriously adv.</p>",
      },
    ],
  },
  {
    word: "Industry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Industry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a branch of production or manufacture; commercial enterprise. B these collectively. 2 concerted activity (a hive of industry). 3 diligence. [latin industria]</p>",
      },
    ],
  },
  {
    word: "-ine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ine <strong class='slang_v'> suffix </strong>1 forming adjectives, meaning ‘belonging to, of the nature of’ (alpine; asinine). 2 forming feminine nouns (heroine). [latin -inus]</p>",
      },
    ],
  },
  {
    word: "Inebriate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inebriate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> 1 make drunk. 2 excite. <strong class='adj'> —adj.</strong> Drunken. <strong class='noun'> —n. </strong> Drunkard. inebriation <strong class='noun'> n. </strong> Inebriety <strong class='noun'> n. </strong> [latin ebrius drunk]</p>",
      },
    ],
  },
  {
    word: "Inedible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inedible <strong class='adj'> adj. </strong> Not suitable for eating.</p>",
      },
    ],
  },
  {
    word: "Ineducable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ineducable <strong class='adj'> adj. </strong> Incapable of being educated.</p>",
      },
    ],
  },
  {
    word: "Ineffable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ineffable <strong class='adj'> adj. </strong> 1 too great for description in words. 2 that must not be uttered. ineffability <strong class='noun'> n. </strong> Ineffably <strong class='adv'> adv. </strong> [latin effor speak out]</p>",
      },
    ],
  },
  {
    word: "Ineffective",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ineffective <strong class='adj'> adj. </strong> Not achieving the desired effect or results. ineffectively <strong class='adv'> adv. </strong> Ineffectiveness n.</p>",
      },
    ],
  },
  {
    word: "Ineffectual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ineffectual <strong class='adj'> adj. </strong> Ineffective, feeble. ineffectually <strong class='adv'> adv. </strong> Ineffectualness n.</p>",
      },
    ],
  },
  {
    word: "Inefficient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inefficient <strong class='adj'> adj. </strong> 1 not efficient or fully capable. 2 (of a machine etc.) Wasteful. inefficiency <strong class='noun'> n. </strong> Inefficiently adv.</p>",
      },
    ],
  },
  {
    word: "Inelegant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inelegant <strong class='adj'> adj. </strong> 1 ungraceful. 2 unrefined. inelegance <strong class='noun'> n. </strong> Inelegantly adv.</p>",
      },
    ],
  },
  {
    word: "Ineligible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ineligible <strong class='adj'> adj. </strong> Not eligible or qualified. ineligibility n.</p>",
      },
    ],
  },
  {
    word: "Ineluctable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ineluctable <strong class='adj'> adj. </strong> Inescapable, unavoidable. [latin luctor strive]</p>",
      },
    ],
  },
  {
    word: "Inept",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inept <strong class='adj'> adj. </strong> 1 unskilful. 2 absurd, silly. 3 out of place. ineptitude <strong class='noun'> n. </strong> Ineptly <strong class='adv'> adv. </strong> [latin: related to *apt]</p>",
      },
    ],
  },
  {
    word: "Inequable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inequable <strong class='adj'> adj. </strong> 1 unfair. 2 not uniform.</p>",
      },
    ],
  },
  {
    word: "Inequality",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inequality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 lack of equality. 2 variability. 3 unevenness.</p>",
      },
    ],
  },
  {
    word: "Inequitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inequitable <strong class='adj'> adj. </strong> Unfair, unjust.</p>",
      },
    ],
  },
  {
    word: "Inequity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inequity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> unfairness, injustice.</p>",
      },
    ],
  },
  {
    word: "Ineradicable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ineradicable <strong class='adj'> adj. </strong> That cannot be rooted out.</p>",
      },
    ],
  },
  {
    word: "Inert",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inert <strong class='adj'> adj. </strong> 1 without inherent power of action, motion, or resistance. 2 not reacting chemically with other substances (inert gas). 3 sluggish, slow; lifeless. [latin iners -ert-: related to *art]</p>",
      },
    ],
  },
  {
    word: "Inertia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inertia <strong class='noun'> n. </strong> 1 physics property of matter by which it continues in its existing state of rest or motion unless an external force is applied. 2 a inertness, lethargy. B tendency to remain unchanged (inertia of the system). inertial adj. [latin: related to *inert]</p>",
      },
    ],
  },
  {
    word: "Inertia reel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inertia reel <strong class='noun'> n. </strong> Reel allowing a seat-belt to unwind freely but locking on impact etc.</p>",
      },
    ],
  },
  {
    word: "Inertia selling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inertia selling <strong class='noun'> n. </strong> Sending of unsolicited goods in the hope of making a sale.</p>",
      },
    ],
  },
  {
    word: "Inescapable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inescapable <strong class='adj'> adj. </strong> That cannot be escaped or avoided.</p>",
      },
    ],
  },
  {
    word: "Inessential",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inessential <strong class='adj'> —adj.</strong> Not necessary; dispensable. <strong class='noun'> —n. </strong> Inessential thing.</p>",
      },
    ],
  },
  {
    word: "Inestimable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inestimable <strong class='adj'> adj. </strong> Too great, precious, etc., to be estimated. inestimably adv.</p>",
      },
    ],
  },
  {
    word: "Inevitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inevitable <strong class='adj'> —adj.</strong> 1 unavoidable; sure to happen. 2 <strong class='colloq'> colloq. </strong> Tiresomely familiar. <strong class='noun'> —n. </strong> (prec. By the) inevitable fact, event, etc. inevitability <strong class='noun'> n. </strong> Inevitably <strong class='adv'> adv. </strong> [latin evito avoid]</p>",
      },
    ],
  },
  {
    word: "Inexact",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexact <strong class='adj'> adj. </strong> Not exact. inexactitude <strong class='noun'> n. </strong> Inexactly adv.</p>",
      },
    ],
  },
  {
    word: "Inexcusable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexcusable <strong class='adj'> adj. </strong> That cannot be excused or justified. inexcusably adv.</p>",
      },
    ],
  },
  {
    word: "Inexhaustible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexhaustible <strong class='adj'> adj. </strong> That cannot be used up, endless.</p>",
      },
    ],
  },
  {
    word: "Inexorable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexorable <strong class='adj'> adj. </strong> Relentless; unstoppable. inexorably <strong class='adv'> adv. </strong> [latin exoro entreat]</p>",
      },
    ],
  },
  {
    word: "Inexpedient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexpedient <strong class='adj'> adj. </strong> Not expedient.</p>",
      },
    ],
  },
  {
    word: "Inexpensive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexpensive <strong class='adj'> adj. </strong> Not expensive.</p>",
      },
    ],
  },
  {
    word: "Inexperience",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexperience <strong class='noun'> n. </strong> Lack of experience, knowledge, or skill. inexperienced adj.</p>",
      },
    ],
  },
  {
    word: "Inexpert",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexpert <strong class='adj'> adj. </strong> Unskilful; lacking expertise.</p>",
      },
    ],
  },
  {
    word: "Inexpiable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexpiable <strong class='adj'> adj. </strong> That cannot be expiated or appeased.</p>",
      },
    ],
  },
  {
    word: "Inexplicable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexplicable <strong class='adj'> adj. </strong> That cannot be explained. inexplicably adv.</p>",
      },
    ],
  },
  {
    word: "Inexpressible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inexpressible <strong class='adj'> adj. </strong> That cannot be expressed. inexpressibly adv.</p>",
      },
    ],
  },
  {
    word: "Inextinguishable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inextinguishable <strong class='adj'> adj. </strong> That cannot be extinguished or destroyed.</p>",
      },
    ],
  },
  {
    word: "In extremis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In extremis <strong class='adj'> adj. </strong> 1 at the point of death. 2 in great difficulties; in an emergency. [latin]</p>",
      },
    ],
  },
  {
    word: "Inextricable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inextricable <strong class='adj'> adj. </strong> 1 inescapable. 2 that cannot be separated, loosened, or solved. inextricably adv.</p>",
      },
    ],
  },
  {
    word: "Inf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inf <strong class='sing'> abbr. </strong> Intermediate-range nuclear forces.</p>",
      },
    ],
  },
  {
    word: "Infallible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infallible <strong class='adj'> adj. </strong> 1 incapable of error. 2 unfailing; sure to succeed. 3 (of the pope) incapable of doctrinal error. infallibility <strong class='noun'> n. </strong> Infallibly adv.</p>",
      },
    ],
  },
  {
    word: "Infamous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infamous <strong class='adj'> adj. </strong> Notoriously bad. infamously <strong class='adv'> adv. </strong> Infamy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>.</p>",
      },
    ],
  },
  {
    word: "Infant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infant <strong class='noun'> n. </strong> 1 a child during the earliest period of its life. B schoolchild below the age of seven years. 2 (esp. Attrib.) Thing in an early stage of its development. 3 law person under 18. infancy <strong class='noun'> n. </strong> [latin infans unable to speak]</p>",
      },
    ],
  },
  {
    word: "Infanta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infanta <strong class='noun'> n. </strong> Hist. Daughter of a spanish or portuguese king. [spanish and portuguese: related to *infant]</p>",
      },
    ],
  },
  {
    word: "Infanticide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infanticide <strong class='noun'> n. </strong> 1 killing of an infant, esp. Soon after birth. 2 person who kills an infant.</p>",
      },
    ],
  },
  {
    word: "Infantile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infantile <strong class='adj'> adj. </strong> 1 of or like infants. 2 childish, immature. infantilism n.</p>",
      },
    ],
  },
  {
    word: "Infantile paralysis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infantile paralysis <strong class='noun'> n. </strong> Poliomyelitis.</p>",
      },
    ],
  },
  {
    word: "Infantry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infantry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> body of foot-soldiers; foot-soldiers collectively. [italian infante youth, foot-soldier]</p>",
      },
    ],
  },
  {
    word: "Infantryman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infantryman <strong class='noun'> n. </strong> Soldier of an infantry regiment.</p>",
      },
    ],
  },
  {
    word: "Infarct",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infarct <strong class='noun'> n. </strong> Small area of dead tissue caused by an inadequate blood supply. infarction <strong class='noun'> n. </strong> [latin farcio farct- stuff]</p>",
      },
    ],
  },
  {
    word: "Infatuate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infatuate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (usu. As infatuated adj.) 1 inspire with intense usu. Transitory fondness or admiration. 2 affect with extreme folly. infatuation <strong class='noun'> n. </strong> [latin: related to *fatuous]</p>",
      },
    ],
  },
  {
    word: "Infect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infect <strong class='verb'> v. </strong> 1 affect or contaminate with a germ, virus, or disease. 2 imbue, taint. [latin inficio -fect- taint]</p>",
      },
    ],
  },
  {
    word: "Infection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infection <strong class='noun'> n. </strong> 1 a infecting or being infected. B instance of this; disease. 2 communication of disease, esp. By air, water, etc.</p>",
      },
    ],
  },
  {
    word: "Infectious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infectious <strong class='adj'> adj. </strong> 1 infecting. 2 (of a disease) transmissible by infection. 3 (of emotions etc.) Quickly affecting or spreading to others. infectiously <strong class='adv'> adv. </strong> Infectiousness n.</p>",
      },
    ],
  },
  {
    word: "Infelicity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infelicity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 inapt expression etc. 2 unhappiness. infelicitous adj.</p>",
      },
    ],
  },
  {
    word: "Infer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infer <strong class='verb'> v. </strong> <strong class='adj'> (-rr-) </strong> 1 deduce or conclude. 2 imply. inferable adj. [latin fero bring] <strong class='usage'> Usage:- </strong>the use of infer in sense 2 is considered incorrect by some people.</p>",
      },
    ],
  },
  {
    word: "Inference",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inference <strong class='noun'> n. </strong> 1 act of inferring. 2 thing inferred. inferential adj.</p>",
      },
    ],
  },
  {
    word: "Inferior",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inferior <strong class='adj'> —adj.</strong> 1 <strong class='adj'> (often foll. By to) </strong> lower in rank, quality, etc. 2 of poor quality. 3 situated below. 4 written or printed below the line. <strong class='noun'> —n. </strong> Person inferior to another, esp. In rank. [latin, comparative of inferus]</p>",
      },
    ],
  },
  {
    word: "Inferiority",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inferiority <strong class='noun'> n. </strong> Being inferior.</p>",
      },
    ],
  },
  {
    word: "Inferiority complex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inferiority complex <strong class='noun'> n. </strong> Feeling of inadequacy, sometimes marked by compensating aggressive behaviour.</p>",
      },
    ],
  },
  {
    word: "Infernal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infernal <strong class='adj'> adj. </strong> 1 of hell; hellish. 2 <strong class='colloq'> colloq. </strong> Detestable, tiresome. infernally <strong class='adv'> adv. </strong> [latin infernus low]</p>",
      },
    ],
  },
  {
    word: "Inferno",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inferno <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 raging fire. 2 scene of horror or distress. 3 hell. [italian: related to *infernal]</p>",
      },
    ],
  },
  {
    word: "Infertile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infertile <strong class='adj'> adj. </strong> 1 not fertile. 2 unable to have offspring. infertility n.</p>",
      },
    ],
  },
  {
    word: "Infest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infest <strong class='verb'> v. </strong> (esp. Of vermin) overrun (a place). infestation <strong class='noun'> n. </strong> [latin infestus hostile]</p>",
      },
    ],
  },
  {
    word: "Infidel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infidel <strong class='noun'> —n. </strong> Unbeliever in esp. The supposed true religion. <strong class='adj'> —adj.</strong> 1 of infidels. 2 unbelieving. [latin fides faith]</p>",
      },
    ],
  },
  {
    word: "Infidelity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infidelity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> unfaithfulness, esp. Adultery. [latin: related to *infidel]</p>",
      },
    ],
  },
  {
    word: "Infield",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infield <strong class='noun'> n. </strong> Cricket the part of the ground near the wicket.</p>",
      },
    ],
  },
  {
    word: "Infighting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infighting <strong class='noun'> n. </strong> 1 conflict or competitiveness between colleagues. 2 boxing within arm's length.</p>",
      },
    ],
  },
  {
    word: "Infill",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infill <strong class='noun'> —n. </strong> 1 material used to fill a hole, gap, etc. 2 filling gaps (esp. In a row of buildings). —v. Fill in (a cavity etc.).</p>",
      },
    ],
  },
  {
    word: "Infilling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infilling <strong class='noun'> n. </strong> = *infill n.</p>",
      },
    ],
  },
  {
    word: "Infiltrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infiltrate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a enter (a territory, political party, etc.) Gradually and imperceptibly. B cause to do this. 2 permeate by filtration. 3 (often foll. By into, through) introduce (fluid) by filtration. infiltration <strong class='noun'> n. </strong> Infiltrator <strong class='noun'> n. </strong> [from *in-2, *filtrate]</p>",
      },
    ],
  },
  {
    word: "Infinite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infinite <strong class='adj'> —adj.</strong> 1 boundless, endless. 2 very great or many. <strong class='noun'> —n. </strong> 1 (the infinite) god. 2 (the infinite) infinite space. infinitely <strong class='adv'> adv. </strong> [latin: related to *in-1, *finite]</p>",
      },
    ],
  },
  {
    word: "Infinitesimal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infinitesimal <strong class='adj'> —adj.</strong> Infinitely or very small. <strong class='noun'> —n. </strong> Infinitesimal amount. infinitesimally adv.</p>",
      },
    ],
  },
  {
    word: "Infinitive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infinitive <strong class='noun'> —n. </strong> Form of a verb expressing the verbal notion without a particular subject, tense, etc. (e.g. See in we came to see, let him see). <strong class='adj'> —adj.</strong> Having this form.</p>",
      },
    ],
  },
  {
    word: "Infinitude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infinitude <strong class='noun'> n. </strong> Literary = *infinity 1, 2.</p>",
      },
    ],
  },
  {
    word: "Infinity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infinity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 being infinite; boundlessness. 2 infinite number or extent. 3 infinite distance (gaze into infinity). 4 math. Infinite quantity.</p>",
      },
    ],
  },
  {
    word: "Infirm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infirm <strong class='adj'> adj. </strong> Physically weak, esp. Through age.</p>",
      },
    ],
  },
  {
    word: "Infirmary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infirmary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 hospital. 2 sick-quarters in a school etc.</p>",
      },
    ],
  },
  {
    word: "Infirmity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infirmity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 being infirm. 2 particular physical weakness.</p>",
      },
    ],
  },
  {
    word: "Infix",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infix <strong class='verb'> v. </strong> Fasten or fix in.</p>",
      },
    ],
  },
  {
    word: "In flagrante delicto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In flagrante delicto <strong class='adv'> adv. </strong> In the very act of committing an offence. [latin, = in blazing crime]</p>",
      },
    ],
  },
  {
    word: "Inflame",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflame <strong class='verb'> v. </strong> (-ming) 1 provoke to strong feeling, esp. Anger. 2 cause inflammation in; make hot. 3 aggravate. 4 catch or set on fire. 5 light up with or as with flames.</p>",
      },
    ],
  },
  {
    word: "Inflammable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflammable <strong class='adj'> adj. </strong> Easily set on fire or excited. inflammability n. <strong class='usage'> Usage:- </strong>where there is a danger of inflammable being understood to mean the opposite, i.e. ‘not easily set on fire’, flammable can be used to avoid confusion.</p>",
      },
    ],
  },
  {
    word: "Inflammation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflammation <strong class='noun'> n. </strong> 1 inflaming. 2 bodily condition with heat, swelling, redness, and usu. Pain.</p>",
      },
    ],
  },
  {
    word: "Inflammatory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflammatory <strong class='adj'> adj. </strong> 1 tending to cause anger etc. 2 of inflammation.</p>",
      },
    ],
  },
  {
    word: "Inflatable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflatable <strong class='adj'> —adj.</strong> That can be inflated. <strong class='noun'> —n. </strong> Inflatable object.</p>",
      },
    ],
  },
  {
    word: "Inflate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 distend with air or gas. 2 (usu. Foll. By with; usu. In passive) puff up (with pride etc.). 3 a cause inflation of (the currency). B raise (prices) artificially. 4 (as inflated adj.) (esp. Of language, opinions, etc.) Bombastic, overblown, exaggerated. [latin inflo -flat-]</p>",
      },
    ],
  },
  {
    word: "Inflation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflation <strong class='noun'> n. </strong> 1 inflating. 2 econ. A general increase in prices. B increase in the supply of money regarded as causing this. inflationary adj.</p>",
      },
    ],
  },
  {
    word: "Inflect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflect <strong class='verb'> v. </strong> 1 change the pitch of (the voice). 2 a change the form of (a word) to express grammatical relation. B undergo such a change. 3 bend, curve. inflective adj. [latin flecto flex- bend]</p>",
      },
    ],
  },
  {
    word: "Inflection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflection <strong class='noun'> n. </strong> (also inflexion) 1 inflecting or being inflected. 2 a inflected word. B suffix etc. Used to inflect. 3 modulation of the voice. inflectional adj. [latin: related to *inflect]</p>",
      },
    ],
  },
  {
    word: "Inflexible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflexible <strong class='adj'> adj. </strong> 1 unbendable. 2 unbending. inflexibility <strong class='noun'> n. </strong> Inflexibly adv.</p>",
      },
    ],
  },
  {
    word: "Inflexion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflexion <strong class='var'> var. </strong> Of *inflection.</p>",
      },
    ],
  },
  {
    word: "Inflict",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflict <strong class='verb'> v. </strong> (usu. Foll. By on) 1 deal (a blow etc.). 2 often joc. Impose (suffering, oneself, etc.) On (shall not inflict myself on you any longer). infliction <strong class='noun'> n. </strong> Inflictor <strong class='noun'> n. </strong> [latin fligo flict- strike]</p>",
      },
    ],
  },
  {
    word: "Inflight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflight <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Occurring or provided during a flight.</p>",
      },
    ],
  },
  {
    word: "Inflorescence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflorescence <strong class='noun'> n. </strong> 1 a complete flower-head of a plant. B arrangement of this. 2 flowering. [latin: related to *in-2, *flourish]</p>",
      },
    ],
  },
  {
    word: "Inflow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inflow <strong class='noun'> n. </strong> 1 flowing in. 2 something that flows in.</p>",
      },
    ],
  },
  {
    word: "Influence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Influence <strong class='noun'> —n. </strong> 1 (usu. Foll. By on) effect a person or thing has on another. 2 (usu. Foll. By over, with) moral ascendancy or power. 3 thing or person exercising this. —v. (-cing) exert influence on; affect. under the influence <strong class='colloq'> colloq. </strong> Drunk. [latin influo flow in]</p>",
      },
    ],
  },
  {
    word: "Influential",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Influential <strong class='adj'> adj. </strong> Having great influence. influentially adv.</p>",
      },
    ],
  },
  {
    word: "Influenza",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Influenza <strong class='noun'> n. </strong> Virus infection causing fever, aches, and catarrh. [italian: related to *influence]</p>",
      },
    ],
  },
  {
    word: "Influx",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Influx <strong class='noun'> n. </strong> Flowing in, esp. Of people or things into a place. [latin: related to *flux]</p>",
      },
    ],
  },
  {
    word: "Info",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Info <strong class='noun'> n. </strong> Colloq. Information. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Inform",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inform <strong class='verb'> v. </strong> 1 tell (informed them of their rights). 2 (usu. Foll. By against, on) give incriminating information about a person to the authorities. [latin: related to *form]</p>",
      },
    ],
  },
  {
    word: "Informal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Informal <strong class='adj'> adj. </strong> 1 without formality. 2 not formal. informality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Informally adv.</p>",
      },
    ],
  },
  {
    word: "Informant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Informant <strong class='noun'> n. </strong> Giver of information.</p>",
      },
    ],
  },
  {
    word: "Information",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Information <strong class='noun'> n. </strong> 1 a something told; knowledge. B items of knowledge; news. 2 charge or complaint lodged with a court etc.</p>",
      },
    ],
  },
  {
    word: "Information retrieval",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Information retrieval <strong class='noun'> n. </strong> The tracing of information stored in books, computers, etc.</p>",
      },
    ],
  },
  {
    word: "Information technology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Information technology <strong class='noun'> n. </strong> The study or use of processes (esp. Computers, telecommunications, etc.) For storing, retrieving, and sending information.</p>",
      },
    ],
  },
  {
    word: "Informative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Informative <strong class='adj'> adj. </strong> Giving information; instructive.</p>",
      },
    ],
  },
  {
    word: "Informed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Informed <strong class='adj'> adj. </strong> 1 knowing the facts. 2 having some knowledge.</p>",
      },
    ],
  },
  {
    word: "Informer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Informer <strong class='noun'> n. </strong> Person who informs, esp. Against others.</p>",
      },
    ],
  },
  {
    word: "Infra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infra <strong class='adv'> adv. </strong> Below, further on (in a book etc.). [latin, = below]</p>",
      },
    ],
  },
  {
    word: "Infra-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infra- <strong class='n_pl'> comb. </strong> Form below.</p>",
      },
    ],
  },
  {
    word: "Infraction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infraction <strong class='noun'> n. </strong> Infringement. [latin: related to *infringe]</p>",
      },
    ],
  },
  {
    word: "Infra dig",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infra dig <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> Colloq. Beneath one's dignity. [latin infra dignitatem]</p>",
      },
    ],
  },
  {
    word: "Infrared",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infrared <strong class='adj'> adj. </strong> Of or using rays with a wavelength just longer than the red end of the visible spectrum.</p>",
      },
    ],
  },
  {
    word: "Infrastructure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infrastructure <strong class='noun'> n. </strong> 1 a basic structural foundations of a society or enterprise. B roads, bridges, sewers, etc., regarded as a country's economic foundation. 2 permanent installations as a basis for military etc. Operations.</p>",
      },
    ],
  },
  {
    word: "Infrequent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infrequent <strong class='adj'> adj. </strong> Not frequent. infrequently adv.</p>",
      },
    ],
  },
  {
    word: "Infringe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infringe <strong class='verb'> v. </strong> (-ging) 1 break or violate (a law, another's rights, etc.). 2 (usu. Foll. By on) encroach; trespass. infringement <strong class='noun'> n. </strong> [latin frango fract- break]</p>",
      },
    ],
  },
  {
    word: "Infuriate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infuriate <strong class='verb'> v. </strong> Make furious; irritate greatly. infuriating adj. Infuriatingly <strong class='adv'> adv. </strong> [medieval latin: related to *fury]</p>",
      },
    ],
  },
  {
    word: "Infuse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infuse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 (usu. Foll. By with) fill (with a quality). 2 steep (tea leaves etc.) In liquid to extract the content; be steeped thus. 3 (usu. Foll. By into) instil (life etc.). [latin infundo -fus-: related to *found3]</p>",
      },
    ],
  },
  {
    word: "Infusible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infusible <strong class='adj'> adj. </strong> That cannot be melted. infusibility n.</p>",
      },
    ],
  },
  {
    word: "Infusion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Infusion <strong class='noun'> n. </strong> 1 a infusing. B liquid extract obtained thus. 2 infused element.</p>",
      },
    ],
  },
  {
    word: "-ing1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ing1 <strong class='slang_v'> suffix </strong>forming nouns from verbs denoting: 1 verbal action or its result (asking). 2 material associated with a process etc. (piping; washing). 3 occupation or event (banking; wedding). [old english]</p>",
      },
    ],
  },
  {
    word: "-ing2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ing2 <strong class='slang_v'> suffix </strong>1 forming the present participle of verbs (asking; fighting), often as adjectives (charming; strapping). 2 forming adjectives from nouns (hulking) and verbs (balding). [old english]</p>",
      },
    ],
  },
  {
    word: "Ingenious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingenious <strong class='adj'> adj. </strong> 1 clever at inventing, organizing, etc. 2 cleverly contrived. ingeniously <strong class='adv'> adv. </strong> [latin ingenium cleverness] <strong class='usage'> Usage:- </strong>ingenious is sometimes confused with ingenuous.</p>",
      },
    ],
  },
  {
    word: "Ingénue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingénue <strong class='noun'> n. </strong> 1 unsophisticated young woman. 2 such a part in a play. [french: related to *ingenuous]</p>",
      },
    ],
  },
  {
    word: "Ingenuity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingenuity <strong class='noun'> n. </strong> Inventiveness, cleverness.</p>",
      },
    ],
  },
  {
    word: "Ingenuous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingenuous <strong class='adj'> adj. </strong> 1 artless. 2 frank. ingenuously <strong class='adv'> adv. </strong> [latin ingenuus free-born, frank] <strong class='usage'> Usage:- </strong>ingenuous is sometimes confused with ingenious.</p>",
      },
    ],
  },
  {
    word: "Ingest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingest <strong class='verb'> v. </strong> 1 take in (food etc.). 2 absorb (knowledge etc.). ingestion <strong class='noun'> n. </strong> [latin gero carry]</p>",
      },
    ],
  },
  {
    word: "Inglenook",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inglenook <strong class='noun'> n. </strong> Space within the opening on either side of a large fireplace. [perhaps gaelic aingeal fire, light]</p>",
      },
    ],
  },
  {
    word: "Inglorious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inglorious <strong class='adj'> adj. </strong> 1 shameful. 2 not famous.</p>",
      },
    ],
  },
  {
    word: "Ingoing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ingoing <strong class='adj'> adj. </strong> Going in.</p>",
      },
    ],
  },
  {
    word: "Ingot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingot <strong class='noun'> n. </strong> (usu. Oblong) piece of cast metal, esp. Gold. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Ingraft",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingraft <strong class='var'> var. </strong> Of *engraft.</p>",
      },
    ],
  },
  {
    word: "Ingrained",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingrained <strong class='adj'> adj. </strong> 1 deeply rooted; inveterate. 2 (of dirt etc.) Deeply embedded.</p>",
      },
    ],
  },
  {
    word: "Ingratiate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingratiate <strong class='v_refl'> v.refl. </strong> <strong class='ting'> (-ting) </strong> (usu. Foll. By with) bring oneself into favour. ingratiating adj. Ingratiatingly <strong class='adv'> adv. </strong> [latin in gratiam into favour]</p>",
      },
    ],
  },
  {
    word: "Ingratitude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingratitude <strong class='noun'> n. </strong> Lack of due gratitude.</p>",
      },
    ],
  },
  {
    word: "Ingredient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingredient <strong class='noun'> n. </strong> Component part in a mixture. [latin ingredior enter into]</p>",
      },
    ],
  },
  {
    word: "Ingress",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingress <strong class='noun'> n. </strong> Act or right of going in. [latin ingressus: related to *ingredient]</p>",
      },
    ],
  },
  {
    word: "Ingrowing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ingrowing <strong class='adj'> adj. </strong> (esp. Of a toenail) growing into the flesh. ingrown adj.</p>",
      },
    ],
  },
  {
    word: "Inguinal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inguinal <strong class='adj'> adj. </strong> Of the groin. [latin inguen groin]</p>",
      },
    ],
  },
  {
    word: "Inhabit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhabit <strong class='verb'> v. </strong> (-t-) dwell in; occupy. inhabitable adj. [latin: related to *habit]</p>",
      },
    ],
  },
  {
    word: "Inhabitant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhabitant <strong class='noun'> n. </strong> Person etc. Who inhabits a place.</p>",
      },
    ],
  },
  {
    word: "Inhalant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhalant <strong class='noun'> n. </strong> Medicinal substance for inhaling.</p>",
      },
    ],
  },
  {
    word: "Inhale",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhale <strong class='verb'> v. </strong> (-ling) (often absol.) Breathe in (air, gas, smoke, etc.). inhalation <strong class='noun'> n. </strong> [latin halo breathe]</p>",
      },
    ],
  },
  {
    word: "Inhaler",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhaler <strong class='noun'> n. </strong> Device for administering an inhalant, esp. To relieve asthma.</p>",
      },
    ],
  },
  {
    word: "Inhere",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhere <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> be inherent. [latin haereo haes- stick]</p>",
      },
    ],
  },
  {
    word: "Inherent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inherent <strong class='adj'> adj. </strong> (often foll. By in) existing in something as an essential or permanent attribute. inherence <strong class='noun'> n. </strong> Inherently adv.</p>",
      },
    ],
  },
  {
    word: "Inherit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inherit <strong class='verb'> v. </strong> (-t-) 1 receive (property, rank, title, etc.) By legal succession. 2 derive (a characteristic) from one's ancestors. 3 derive (a situation etc.) From a predecessor. inheritable adj. Inheritor <strong class='noun'> n. </strong> [latin heres heir]</p>",
      },
    ],
  },
  {
    word: "Inheritance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inheritance <strong class='noun'> n. </strong> 1 thing that is inherited. 2 inheriting.</p>",
      },
    ],
  },
  {
    word: "Inheritance tax",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inheritance tax <strong class='noun'> n. </strong> Tax levied on property acquired by gift or inheritance. <strong class='usage'> Usage:- </strong>this tax was introduced in 1986 to replace capital transfer tax.</p>",
      },
    ],
  },
  {
    word: "Inhibit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhibit <strong class='verb'> v. </strong> (-t-) 1 hinder, restrain, or prevent (action or progress). 2 (as inhibited adj.) Suffering from inhibition. 3 (usu. Foll. By from + verbal noun) prohibit (a person etc.). inhibitory adj. [latin inhibeo -hibit- hinder]</p>",
      },
    ],
  },
  {
    word: "Inhibition",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhibition <strong class='noun'> n. </strong> 1 psychol. Restraint on the direct expression of an instinct. 2 <strong class='colloq'> colloq. </strong> Emotional resistance to a thought, action, etc. 3 inhibiting or being inhibited.</p>",
      },
    ],
  },
  {
    word: "Inhospitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhospitable <strong class='adj'> adj. </strong> 1 not hospitable. 2 (of a region etc.) Not affording shelter, favourable conditions, etc. inhospitably adv.</p>",
      },
    ],
  },
  {
    word: "In-house",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In-house <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Within an institution, company, etc.</p>",
      },
    ],
  },
  {
    word: "Inhuman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhuman <strong class='adj'> adj. </strong> Brutal; unfeeling; barbarous. inhumanity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Inhumanly adv.</p>",
      },
    ],
  },
  {
    word: "Inhumane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inhumane <strong class='adj'> adj. </strong> = *inhuman. inhumanely adv.</p>",
      },
    ],
  },
  {
    word: "Inimical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inimical <strong class='adj'> adj. </strong> 1 hostile. 2 harmful. inimically <strong class='adv'> adv. </strong> [latin inimicus enemy]</p>",
      },
    ],
  },
  {
    word: "Inimitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inimitable <strong class='adj'> adj. </strong> Impossible to imitate. inimitably adv.</p>",
      },
    ],
  },
  {
    word: "Iniquity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iniquity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 wickedness. 2 gross injustice. iniquitous adj. [french from latin aequus just]</p>",
      },
    ],
  },
  {
    word: "Initial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Initial <strong class='adj'> —adj.</strong> Of or at the beginning. <strong class='noun'> —n. </strong> Initial letter, esp. (in pl.) Those of a person's names. —v. (-ll-; us -l-) mark or sign with one's initials. initially <strong class='adv'> adv. </strong> [latin initium beginning]</p>",
      },
    ],
  },
  {
    word: "Initial letter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Initial letter <strong class='noun'> n. </strong> First letter of a word.</p>",
      },
    ],
  },
  {
    word: "Initiate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Initiate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> 1 begin; set going; originate. 2 a admit (a person) into a society, office, etc., esp. With a ritual. B instruct (a person) in a subject. <strong class='noun'> —n. </strong> (esp. Newly) initiated person. initiation <strong class='noun'> n. </strong> Initiator <strong class='noun'> n. </strong> Initiatory adj. [latin initium beginning]</p>",
      },
    ],
  },
  {
    word: "Initiative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Initiative <strong class='noun'> n. </strong> 1 ability to initiate things; enterprise (lacks initiative). 2 first step. 3 (prec. By the) power or right to begin. have the initiative esp. Mil. Be able to control the enemy's movements. [french: related to *initiate]</p>",
      },
    ],
  },
  {
    word: "Inject",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inject <strong class='verb'> v. </strong> 1 a (usu. Foll. By into) drive (a solution, medicine, etc.) By or as if by a syringe. B (usu. Foll. By with) fill (a cavity etc.) By injecting. C administer medicine etc. To (a person) by injection. 2 place (a quality, money, etc.) Into something. injection <strong class='noun'> n. </strong> Injector <strong class='noun'> n. </strong> [latin injicere -ject- from jacio throw]</p>",
      },
    ],
  },
  {
    word: "Injudicious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Injudicious <strong class='adj'> adj. </strong> Unwise; ill-judged.</p>",
      },
    ],
  },
  {
    word: "Injunction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Injunction <strong class='noun'> n. </strong> 1 authoritative order. 2 judicial order restraining a person or body from an act, or compelling redress to an injured party. [latin: related to *enjoin]</p>",
      },
    ],
  },
  {
    word: "Injure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Injure <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 harm or damage. 2 do wrong to. [back-formation from *injury]</p>",
      },
    ],
  },
  {
    word: "Injured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Injured <strong class='adj'> adj. </strong> 1 harmed or hurt. 2 offended.</p>",
      },
    ],
  },
  {
    word: "Injurious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Injurious <strong class='adj'> adj. </strong> 1 hurtful. 2 (of language) insulting. 3 wrongful.</p>",
      },
    ],
  },
  {
    word: "Injury",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Injury <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 physical harm or damage. 2 offence to feelings etc. 3 esp. Law wrongful action or treatment. [latin injuria]</p>",
      },
    ],
  },
  {
    word: "Injury time",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Injury time <strong class='noun'> n. </strong> Extra playing-time at a football etc. Match to compensate for time lost in dealing with injuries.</p>",
      },
    ],
  },
  {
    word: "Injustice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Injustice <strong class='noun'> n. </strong> 1 lack of fairness. 2 unjust act. do a person an injustice judge a person unfairly. [french from latin: related to *in-1]</p>",
      },
    ],
  },
  {
    word: "Ink",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ink <strong class='noun'> —n. </strong> 1 coloured fluid or paste used for writing, printing, etc. 2 black liquid ejected by a cuttlefish etc. —v. 1 (usu. Foll. By in, over, etc.) Mark with ink. 2 cover (type etc.) With ink. [greek egkauston purple ink used by roman emperors]</p>",
      },
    ],
  },
  {
    word: "Inkling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inkling <strong class='noun'> n. </strong> (often foll. By of) slight knowledge or suspicion; hint. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Inkstand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inkstand <strong class='noun'> n. </strong> Stand for one or more ink bottles.</p>",
      },
    ],
  },
  {
    word: "Ink-well",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ink-well <strong class='noun'> n. </strong> Pot for ink, usu. Housed in a hole in a desk.</p>",
      },
    ],
  },
  {
    word: "Inky",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inky <strong class='adj'> adj. </strong> (-ier, -iest) of, as black as, or stained with ink. inkiness n.</p>",
      },
    ],
  },
  {
    word: "Inland",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inland <strong class='adj'> —adj.</strong> 1 in the interior of a country. 2 carried on within a country. <strong class='adv'> —adv. </strong> In or towards the interior of a country.</p>",
      },
    ],
  },
  {
    word: "Inland revenue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inland revenue <strong class='noun'> n. </strong> Government department assessing and collecting taxes.</p>",
      },
    ],
  },
  {
    word: "In-law",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In-law <strong class='noun'> n. </strong> (often in pl.) Relative by marriage.</p>",
      },
    ],
  },
  {
    word: "Inlay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inlay <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Inlaid) 1 embed (a thing in another) so that the surfaces are even. 2 decorate (a thing with inlaid work). <strong class='noun'> —n. </strong> 1 inlaid work. 2 material inlaid. 3 filling shaped to fit a tooth-cavity. [from *in-2, *lay1]</p>",
      },
    ],
  },
  {
    word: "Inlet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inlet <strong class='noun'> n. </strong> 1 small arm of the sea, a lake, or a river. 2 piece inserted. 3 way of entry. [from *in, *let1]</p>",
      },
    ],
  },
  {
    word: "In loco parentis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In loco parentis <strong class='adv'> adv. </strong> (acting) for or instead of a parent. [latin]</p>",
      },
    ],
  },
  {
    word: "Inmate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inmate <strong class='noun'> n. </strong> Occupant of a hospital, prison, institution, etc. [probably from *inn, *mate1]</p>",
      },
    ],
  },
  {
    word: "In memoriam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In memoriam <strong class='n_pl'> comb. </strong> In memory of (a dead person). [latin]</p>",
      },
    ],
  },
  {
    word: "Inmost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inmost <strong class='adj'> adj. </strong> Most inward. [old english]</p>",
      },
    ],
  },
  {
    word: "Inn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inn <strong class='noun'> n. </strong> 1 pub, sometimes with accommodation. 2 hist. House providing accommodation, esp. For travellers. [old english: related to *in]</p>",
      },
    ],
  },
  {
    word: "Innards",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innards <strong class='n_pl'> n.pl. </strong> Colloq. Entrails. [special pronunciation of *inward]</p>",
      },
    ],
  },
  {
    word: "Innate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innate <strong class='adj'> adj. </strong> Inborn; natural. innately <strong class='adv'> adv. </strong> [latin natus born]</p>",
      },
    ],
  },
  {
    word: "Inner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inner <strong class='adj'> —adj.</strong> (usu. Attrib.) 1 inside; interior. 2 (of thoughts, feelings, etc.) Deeper. <strong class='noun'> —n. </strong> Archery 1 division of the target next to the bull's-eye. 2 shot striking this. innermost adj. [old english, comparative of *in]</p>",
      },
    ],
  },
  {
    word: "Inner city",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inner city <strong class='noun'> n. </strong> Central area of a city, esp. Regarded as having particular problems (also (with hyphen) attrib.: inner-city housing).</p>",
      },
    ],
  },
  {
    word: "Inner man",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inner man <strong class='noun'> n. </strong> (also inner woman) 1 soul or mind. 2 joc. Stomach.</p>",
      },
    ],
  },
  {
    word: "Inner tube",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inner tube <strong class='noun'> n. </strong> Separate inflatable tube inside a pneumatic tyre.</p>",
      },
    ],
  },
  {
    word: "Innings",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innings <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> 1 esp. Cricket part of a game during which a side is batting. 2 period during which a government, party, person, etc. Is in office or can achieve something. [obsolete in (verb) = go in]</p>",
      },
    ],
  },
  {
    word: "Innkeeper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innkeeper <strong class='noun'> n. </strong> Person who keeps an inn.</p>",
      },
    ],
  },
  {
    word: "Innocent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innocent <strong class='adj'> —adj.</strong> 1 free from moral wrong. 2 (usu. Foll. By of) not guilty (of a crime etc.). 3 simple; guileless. 4 harmless. <strong class='noun'> —n. </strong> Innocent person, esp. A young child. innocence <strong class='noun'> n. </strong> Innocently <strong class='adv'> adv. </strong> [latin noceo hurt]</p>",
      },
    ],
  },
  {
    word: "Innocuous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innocuous <strong class='adj'> adj. </strong> Harmless. [latin innocuus: related to *innocent]</p>",
      },
    ],
  },
  {
    word: "Inn of court",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inn of court <strong class='noun'> n. </strong> Each of the four legal societies admitting people to the english bar.</p>",
      },
    ],
  },
  {
    word: "Innovate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innovate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> bring in new methods, ideas, etc.; make changes. innovation <strong class='noun'> n. </strong> Innovative adj. Innovator <strong class='noun'> n. </strong> Innovatory adj. [latin novus new]</p>",
      },
    ],
  },
  {
    word: "Innuendo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innuendo <strong class='noun'> n. </strong> (pl. -es or -s) allusive remark or hint, usu. Disparaging or with a double meaning. [latin, = by nodding at: related to *in-2, nuo nod]</p>",
      },
    ],
  },
  {
    word: "Innuit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Innuit <strong class='var'> var. </strong> Of *inuit.</p>",
      },
    ],
  },
  {
    word: "Innumerable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innumerable <strong class='adj'> adj. </strong> Too many to be counted. innumerably adv.</p>",
      },
    ],
  },
  {
    word: "Innumerate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Innumerate <strong class='adj'> adj. </strong> Having no knowledge of basic mathematics. innumeracy n.</p>",
      },
    ],
  },
  {
    word: "Inoculate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inoculate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> treat (a person or animal) with vaccine or serum to promote immunity against a disease. inoculation <strong class='noun'> n. </strong> [latin oculus eye, bud]</p>",
      },
    ],
  },
  {
    word: "Inoffensive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inoffensive <strong class='adj'> adj. </strong> Not objectionable; harmless.</p>",
      },
    ],
  },
  {
    word: "Inoperable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inoperable <strong class='adj'> adj. </strong> Surgery that cannot successfully be operated on.</p>",
      },
    ],
  },
  {
    word: "Inoperative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inoperative <strong class='adj'> adj. </strong> Not working or taking effect.</p>",
      },
    ],
  },
  {
    word: "Inopportune",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inopportune <strong class='adj'> adj. </strong> Not appropriate, esp. Not timely.</p>",
      },
    ],
  },
  {
    word: "Inordinate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inordinate <strong class='adj'> adj. </strong> Excessive. inordinately <strong class='adv'> adv. </strong> [latin: related to *ordain]</p>",
      },
    ],
  },
  {
    word: "Inorganic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inorganic <strong class='adj'> adj. </strong> 1 chem. (of a compound) not organic, usu. Of mineral origin. 2 without organized physical structure. 3 extraneous.</p>",
      },
    ],
  },
  {
    word: "In-patient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In-patient <strong class='noun'> n. </strong> Patient who lives in hospital while under treatment.</p>",
      },
    ],
  },
  {
    word: "Input",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Input <strong class='noun'> —n. </strong> 1 what is put in or taken in. 2 place where energy, information, etc., enters a system. 3 action of putting in or feeding in. 4 contribution of information etc. —v. (inputting; past and  <strong class='archaic_it_was'>past part.</strong> Input or inputted) (often foll. By into) 1 put in. 2 supply (data, programs, etc., to a computer etc.).</p>",
      },
    ],
  },
  {
    word: "Inquest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inquest <strong class='noun'> n. </strong> 1 law inquiry by a coroner's court into the cause of a death. 2 <strong class='colloq'> colloq. </strong> Discussion analysing the outcome of a game, election, etc. [romanic: related to *inquire]</p>",
      },
    ],
  },
  {
    word: "Inquietude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inquietude <strong class='noun'> n. </strong> Uneasiness. [latin: related to *quiet]</p>",
      },
    ],
  },
  {
    word: "Inquire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inquire <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 seek information formally; make a formal investigation. 2 = *enquire. [latin quaero quisit- seek]</p>",
      },
    ],
  },
  {
    word: "Inquiry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inquiry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 investigation, esp. An official one. 2 = *enquiry.</p>",
      },
    ],
  },
  {
    word: "Inquisition",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inquisition <strong class='noun'> n. </strong> 1 intensive search or investigation. 2 judicial or official inquiry. 3 (the inquisition) rc ch. Hist. Ecclesiastical tribunal for the violent suppression of heresy, esp. In spain. inquisitional adj. [latin: related to *inquire]</p>",
      },
    ],
  },
  {
    word: "Inquisitive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inquisitive <strong class='adj'> adj. </strong> 1 unduly curious; prying. 2 seeking knowledge. inquisitively <strong class='adv'> adv. </strong> Inquisitiveness n.</p>",
      },
    ],
  },
  {
    word: "Inquisitor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inquisitor <strong class='noun'> n. </strong> 1 official investigator. 2 hist. Officer of the inquisition.</p>",
      },
    ],
  },
  {
    word: "Inquisitorial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inquisitorial <strong class='adj'> adj. </strong> 1 of or like an inquisitor. 2 prying. inquisitorially adv.</p>",
      },
    ],
  },
  {
    word: "Inquorate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inquorate <strong class='adj'> adj. </strong> Not constituting a quorum.</p>",
      },
    ],
  },
  {
    word: "In re",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In re <strong class='n_pl'> comb. </strong> = *re1. [latin]</p>",
      },
    ],
  },
  {
    word: "Inri",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inri <strong class='sing'> abbr. </strong> Jesus of nazareth, king of the jews. [latin iesus nazarenus rex iudaeorum]</p>",
      },
    ],
  },
  {
    word: "Inroad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inroad <strong class='noun'> n. </strong> 1 (often in pl.) Encroachment; using up of resources etc. 2 hostile attack.</p>",
      },
    ],
  },
  {
    word: "Inrush",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inrush <strong class='noun'> n. </strong> Rapid influx.</p>",
      },
    ],
  },
  {
    word: "Insalubrious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insalubrious <strong class='adj'> adj. </strong> (of a climate or place) unhealthy.</p>",
      },
    ],
  },
  {
    word: "Insane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insane <strong class='adj'> adj. </strong> 1 mad. 2 <strong class='colloq'> colloq. </strong> Extremely foolish. insanely <strong class='adv'> adv. </strong> Insanity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>.</p>",
      },
    ],
  },
  {
    word: "Insanitary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insanitary <strong class='adj'> adj. </strong> Not sanitary; dirty.</p>",
      },
    ],
  },
  {
    word: "Insatiable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insatiable <strong class='adj'> adj. </strong> 1 unable to be satisfied. 2 extremely greedy. insatiability <strong class='noun'> n. </strong> Insatiably adv.</p>",
      },
    ],
  },
  {
    word: "Insatiate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insatiate <strong class='adj'> adj. </strong> Never satisfied.</p>",
      },
    ],
  },
  {
    word: "Inscribe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inscribe <strong class='verb'> v. </strong> (-bing) 1 a (usu. Foll. By in, on) write or carve (words etc.) On a surface, page, etc. B (usu. Foll. By with) mark (a surface) with characters. 2 (usu. Foll. By to) write an informal dedication in or on (a book etc.). 3 enter the name of (a person) on a list or in a book. 4 geom. Draw (a figure) within another so that points of it lie on the boundary of the other. [latin scribo write]</p>",
      },
    ],
  },
  {
    word: "Inscription",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inscription <strong class='noun'> n. </strong> 1 words inscribed. 2 inscribing. inscriptional adj. [latin: related to *inscribe]</p>",
      },
    ],
  },
  {
    word: "Inscrutable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inscrutable <strong class='adj'> adj. </strong> Mysterious, impenetrable. inscrutability <strong class='noun'> n. </strong> Inscrutably <strong class='adv'> adv. </strong> [latin scrutor search]</p>",
      },
    ],
  },
  {
    word: "Insect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insect <strong class='noun'> n. </strong> Small invertebrate of a class characteristically having a head, thorax, abdomen, two antennae, three pairs of thoracic legs, and usu. One or two pairs of thoracic wings. [latin: related to *section]</p>",
      },
    ],
  },
  {
    word: "Insecticide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insecticide <strong class='noun'> n. </strong> Substance for killing insects.</p>",
      },
    ],
  },
  {
    word: "Insectivore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insectivore <strong class='noun'> n. </strong> 1 animal that feeds on insects. 2 plant which captures and absorbs insects. insectivorous adj. [from *insect, latin voro devour]</p>",
      },
    ],
  },
  {
    word: "Insecure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insecure <strong class='adj'> adj. </strong> 1 a unsafe; not firm. B (of a surface etc.) Liable to give way. 2 uncertain; lacking confidence. insecurity n.</p>",
      },
    ],
  },
  {
    word: "Inseminate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inseminate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 introduce semen into. 2 sow (seed etc.). insemination <strong class='noun'> n. </strong> [latin: related to *semen]</p>",
      },
    ],
  },
  {
    word: "Insensate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insensate <strong class='adj'> adj. </strong> 1 without physical sensation. 2 without sensibility. 3 stupid. [latin: related to *sense]</p>",
      },
    ],
  },
  {
    word: "Insensible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insensible <strong class='adj'> adj. </strong> 1 unconscious. 2 (usu. Foll. By of, to) unaware (insensible of her needs). 3 callous. 4 too small or gradual to be perceived. insensibility <strong class='noun'> n. </strong> Insensibly adv.</p>",
      },
    ],
  },
  {
    word: "Insensitive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insensitive <strong class='adj'> adj. </strong> <strong class='adj'> (often foll. By to) </strong> 1 unfeeling; boorish; crass. 2 not sensitive to physical stimuli. insensitively <strong class='adv'> adv. </strong> Insensitiveness <strong class='noun'> n. </strong> Insensitivity n.</p>",
      },
    ],
  },
  {
    word: "Insentient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insentient <strong class='adj'> adj. </strong> Not sentient; inanimate.</p>",
      },
    ],
  },
  {
    word: "Inseparable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inseparable <strong class='adj'> adj. </strong> (esp. Of friends) unable or unwilling to be separated. inseparability <strong class='noun'> n. </strong> Inseparably adv.</p>",
      },
    ],
  },
  {
    word: "Insert",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insert <strong class='noun'> —v. </strong> Place or put (a thing) into another. <strong class='noun'> —n. </strong> Something (esp. Pages) inserted. [latin sero sert- join]</p>",
      },
    ],
  },
  {
    word: "Insertion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insertion <strong class='noun'> n. </strong> 1 inserting. 2 thing inserted.</p>",
      },
    ],
  },
  {
    word: "In-service",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In-service <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of training) for those actively engaged in the profession or activity concerned.</p>",
      },
    ],
  },
  {
    word: "Inset",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inset <strong class='noun'> —n. </strong> 1 a extra section inserted in a book etc. B small map etc. Within the border of a larger one. 2 piece let into a dress etc. —v. (insetting; past and  <strong class='archaic_it_was'>past part.</strong> Inset or insetted) 1 put in as an inset. 2 decorate with an inset.</p>",
      },
    ],
  },
  {
    word: "Inshore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inshore <strong class='adv'> adv. </strong> & adj. At sea but close to the shore.</p>",
      },
    ],
  },
  {
    word: "Inside",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inside <strong class='noun'> —n. </strong> 1 a inner side. B inner part; interior. 2 side away from the road. 3 (usu. In pl.) Colloq. Stomach and bowels. <strong class='adj'> —adj.</strong> 1 situated on or in the inside. 2 football & hockey nearer to the centre of the field. <strong class='adv'> —adv. </strong> 1 on, in, or to the inside. 2 slang in prison. <strong class='prep'> —prep. </strong> 1 on the inner side of; within. 2 in less than (inside an hour). inside out 1 with the inner surface turned outwards. 2 thoroughly (knew his subject inside out).</p>",
      },
    ],
  },
  {
    word: "Inside information",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inside information <strong class='noun'> n. </strong> Information not normally accessible to outsiders.</p>",
      },
    ],
  },
  {
    word: "Inside job",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inside job <strong class='noun'> n. </strong> Colloq. Crime committed by a person living or working on the premises burgled etc.</p>",
      },
    ],
  },
  {
    word: "Insider",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insider <strong class='noun'> n. </strong> 1 person who is within an organization etc. 2 person privy to a secret.</p>",
      },
    ],
  },
  {
    word: "Insider dealing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insider dealing <strong class='noun'> n. </strong> Stock exch. Illegal practice of trading to one's own advantage through having access to confidential information.</p>",
      },
    ],
  },
  {
    word: "Insidious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insidious <strong class='adj'> adj. </strong> 1 proceeding inconspicuously but harmfully. 2 crafty. insidiously <strong class='adv'> adv. </strong> Insidiousness <strong class='noun'> n. </strong> [latin insidiae ambush]</p>",
      },
    ],
  },
  {
    word: "Insight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insight <strong class='noun'> n. </strong> (usu. Foll. By into) 1 capacity of understanding hidden truths etc. 2 instance of this.</p>",
      },
    ],
  },
  {
    word: "Insignia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insignia <strong class='noun'> n. </strong> (treated as sing. Or pl.) Badge. [latin signum sign]</p>",
      },
    ],
  },
  {
    word: "Insignificant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insignificant <strong class='adj'> adj. </strong> 1 unimportant. 2 meaningless. insignificance n.</p>",
      },
    ],
  },
  {
    word: "Insincere",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insincere <strong class='adj'> adj. </strong> Not sincere. insincerely <strong class='adv'> adv. </strong> Insincerity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>.</p>",
      },
    ],
  },
  {
    word: "Insinuate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insinuate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 hint obliquely, esp. Unpleasantly. 2 (often refl.; usu. Foll. By into) a introduce (a person etc.) Into favour etc., by subtle manipulation. B introduce (a thing, oneself, etc.) Deviously into a place. insinuation <strong class='noun'> n. </strong> [latin sinuo curve]</p>",
      },
    ],
  },
  {
    word: "Insipid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insipid <strong class='adj'> adj. </strong> 1 lacking vigour or character; dull. 2 tasteless. insipidity <strong class='noun'> n. </strong> Insipidly <strong class='adv'> adv. </strong> [latin sapio have savour]</p>",
      },
    ],
  },
  {
    word: "Insist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insist <strong class='verb'> v. </strong> (usu. Foll. By on or that; also absol.) Maintain or demand assertively (insisted on my going; insisted that he was innocent). [latin sisto stand]</p>",
      },
    ],
  },
  {
    word: "Insistent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insistent <strong class='adj'> adj. </strong> 1 (often foll. By on) insisting. 2 forcing itself on the attention. insistence <strong class='noun'> n. </strong> Insistently adv.</p>",
      },
    ],
  },
  {
    word: "In situ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In situ <strong class='adv'> adv. </strong> In its proper or original place. [latin]</p>",
      },
    ],
  },
  {
    word: "Insobriety",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insobriety <strong class='noun'> n. </strong> Intemperance, esp. In drinking.</p>",
      },
    ],
  },
  {
    word: "Insofar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insofar <strong class='adv'> adv. </strong> = in so far (see *far).</p>",
      },
    ],
  },
  {
    word: "Insole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insole <strong class='noun'> n. </strong> Fixed or removable inner sole of a boot or shoe.</p>",
      },
    ],
  },
  {
    word: "Insolent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insolent <strong class='adj'> adj. </strong> Impertinently insulting. insolence <strong class='noun'> n. </strong> Insolently <strong class='adv'> adv. </strong> [latin soleo be accustomed]</p>",
      },
    ],
  },
  {
    word: "Insoluble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insoluble <strong class='adj'> adj. </strong> 1 incapable of being solved. 2 incapable of being dissolved. insolubility <strong class='noun'> n. </strong> Insolubly adv.</p>",
      },
    ],
  },
  {
    word: "Insolvent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insolvent <strong class='adj'> —adj.</strong> Unable to pay one's debts; bankrupt. <strong class='noun'> —n. </strong> Insolvent person. insolvency n.</p>",
      },
    ],
  },
  {
    word: "Insomnia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insomnia <strong class='noun'> n. </strong> Sleeplessness, esp. Habitual. [latin somnus sleep]</p>",
      },
    ],
  },
  {
    word: "Insomniac",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insomniac <strong class='noun'> n. </strong> Person suffering from insomnia.</p>",
      },
    ],
  },
  {
    word: "Insomuch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insomuch <strong class='adv'> adv. </strong> 1 (foll. By that) to such an extent. 2 (foll. By as) inasmuch. [originally in so much]</p>",
      },
    ],
  },
  {
    word: "Insouciant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insouciant <strong class='adj'> adj. </strong> Carefree; unconcerned. insouciance <strong class='noun'> n. </strong> [french souci care]</p>",
      },
    ],
  },
  {
    word: "Inspect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inspect <strong class='verb'> v. </strong> 1 look closely at. 2 examine officially. inspection <strong class='noun'> n. </strong> [latin spicio spect- look]</p>",
      },
    ],
  },
  {
    word: "Inspector",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inspector <strong class='noun'> n. </strong> 1 person who inspects. 2 official employed to supervise. 3 police officer next above sergeant in rank. inspectorate n.</p>",
      },
    ],
  },
  {
    word: "Inspector of taxes",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inspector of taxes <strong class='noun'> n. </strong> Inland revenue official responsible for assessing taxes.</p>",
      },
    ],
  },
  {
    word: "Inspiration",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inspiration <strong class='noun'> n. </strong> 1 a creative force or influence. B person etc. Stimulating creativity etc. C divine influence, esp. On the writing of scripture etc. 2 sudden brilliant idea. inspirational adj.</p>",
      },
    ],
  },
  {
    word: "Inspire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inspire <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 stimulate (a person) to esp. Creative activity. 2 a (usu. Foll. By with) animate (a person) with a feeling. B create (a feeling) in a person (inspires confidence). 3 prompt; give rise to (a poem inspired by love). 4 (as inspired adj.) Characterized by inspiration. inspiring adj. [latin spiro breathe]</p>",
      },
    ],
  },
  {
    word: "Inspirit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inspirit <strong class='verb'> v. </strong> (-t-) 1 put life into; animate. 2 encourage.</p>",
      },
    ],
  },
  {
    word: "Inst.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inst. <strong class='abbr'> Abbr. </strong> = *instant adj. 4 (the 6th inst.).</p>",
      },
    ],
  },
  {
    word: "Instability",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instability <strong class='noun'> n. </strong> 1 lack of stability. 2 unpredictability in behaviour etc.</p>",
      },
    ],
  },
  {
    word: "Install",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Install <strong class='verb'> v. </strong> (also instal) (-ll-) 1 place (equipment etc.) In position ready for use. 2 place (a person) in an office or rank with ceremony. 3 establish (oneself, a person, etc.). installation <strong class='noun'> n. </strong> [latin: related to *stall1]</p>",
      },
    ],
  },
  {
    word: "Installment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Installment <strong class='noun'> n. </strong> (brit. Instalment) 1 any of several usu. Equal payments for something. 2 any of several parts, esp. Of a broadcast or published story. [anglo-french estaler fix]</p>",
      },
    ],
  },
  {
    word: "Instalment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instalment <strong class='noun'> n. </strong> (us installment) 1 any of several usu. Equal payments for something. 2 any of several parts, esp. Of a broadcast or published story. [anglo-french estaler fix]</p>",
      },
    ],
  },
  {
    word: "Instance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instance <strong class='noun'> —n. </strong> 1 example or illustration of. 2 particular case (that's not true in this instance). —v. (-cing) cite as an instance. for instance as an example. In the first (or second etc.) Instance in the first (or second etc.) Place; at the first (or second etc.) Stage (of a proceeding). [french from latin instantia contrary example]</p>",
      },
    ],
  },
  {
    word: "Instant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instant <strong class='adj'> —adj.</strong> 1 occurring immediately. 2 (of food etc.) Processed for quick preparation. 3 urgent; pressing. 4 commerce of the current month (the 6th instant). <strong class='noun'> —n. </strong> 1 precise moment (come here this instant). 2 short space of time (in an instant). [latin insto be urgent]</p>",
      },
    ],
  },
  {
    word: "Instantaneous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instantaneous <strong class='adj'> adj. </strong> Occurring or done in an instant. instantaneously adv.</p>",
      },
    ],
  },
  {
    word: "Instantly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instantly <strong class='adv'> adv. </strong> Immediately; at once.</p>",
      },
    ],
  },
  {
    word: "Instead",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instead <strong class='adv'> adv. </strong> 1 (foll. By of) in place of. 2 as an alternative.</p>",
      },
    ],
  },
  {
    word: "Instep",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instep <strong class='noun'> n. </strong> 1 inner arch of the foot between the toes and the ankle. 2 part of a shoe etc. Over or under this. [ultimately from *in-2, *step]</p>",
      },
    ],
  },
  {
    word: "Instigate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instigate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 bring about by incitement or persuasion. 2 urge on, incite. instigation <strong class='noun'> n. </strong> Instigator <strong class='noun'> n. </strong> [latin stigo prick]</p>",
      },
    ],
  },
  {
    word: "Instil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instil <strong class='verb'> v. </strong> (us instill) (-ll-) (often foll. By into) 1 introduce (a feeling, idea, etc.) Into a person's mind etc. Gradually. 2 put (a liquid) into something in drops. instillation <strong class='noun'> n. </strong> Instilment <strong class='noun'> n. </strong> [latin stillo drop]</p>",
      },
    ],
  },
  {
    word: "Instill",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instill <strong class='verb'> v. </strong> (brit. Instil) (-ll-) (often foll. By into) 1 introduce (a feeling, idea, etc.) Into a person's mind etc. Gradually. 2 put (a liquid) into something in drops. instillation <strong class='noun'> n. </strong> Instilment <strong class='noun'> n. </strong> [latin stillo drop]</p>",
      },
    ],
  },
  {
    word: "Instinct",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instinct <strong class='noun'> —n. </strong> 1 a innate pattern of behaviour, esp. In animals. B innate impulse. 2 intuition. <strong class='predic'> —predic. </strong> <strong class='adj'> Adj. </strong> (foll. By with) imbued, filled (with life, beauty, etc.). instinctive adj. Instinctively <strong class='adv'> adv. </strong> Instinctual adj. [latin stinguo prick]</p>",
      },
    ],
  },
  {
    word: "Institute",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Institute <strong class='noun'> —n. </strong> 1 society or organization for the promotion of science, education, etc. 2 its premises. —v. <strong class='ting'> (-ting) </strong> 1 establish; found. 2 initiate (an inquiry etc.). 3 (usu. Foll. By to, into) appoint (a person) as a cleric in a church etc. [latin statuo set up]</p>",
      },
    ],
  },
  {
    word: "Institution",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Institution <strong class='noun'> n. </strong> 1 organization or society founded for a particular purpose. 2 established law, practice, or custom. 3 <strong class='colloq'> colloq. </strong> (of a person etc.) Familiar object. 4 instituting or being instituted.</p>",
      },
    ],
  },
  {
    word: "Institutional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Institutional <strong class='adj'> adj. </strong> 1 of or like an institution. 2 typical of institutions. institutionally adv.</p>",
      },
    ],
  },
  {
    word: "Institutionalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Institutionalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 (as institutionalized adj.) Made dependent after a long period in an institution. 2 place or keep (a person) in an institution. 3 make institutional.</p>",
      },
    ],
  },
  {
    word: "Instruct",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instruct <strong class='verb'> v. </strong> 1 teach (a person) a subject etc.; train. 2 (usu. Foll. By to + infin.) Direct; command. 3 law a employ (a lawyer). B inform. instructor <strong class='noun'> n. </strong> [latin instruo -struct- build, teach]</p>",
      },
    ],
  },
  {
    word: "Instruction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instruction <strong class='noun'> n. </strong> 1 (often in pl.) A order. B direction (as to how a thing works etc.). 2 teaching (course of instruction). instructional adj.</p>",
      },
    ],
  },
  {
    word: "Instructive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instructive <strong class='adj'> adj. </strong> Tending to instruct; enlightening.</p>",
      },
    ],
  },
  {
    word: "Instrument",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instrument <strong class='noun'> n. </strong> 1 tool or implement, esp. For delicate or scientific work. 2 (in full musical instrument) device for producing musical sounds. 3 a thing used in performing an action. B person made use of. 4 measuring-device, esp. In an aeroplane. 5 formal, esp. Legal, document. [latin instrumentum: related to *instruct]</p>",
      },
    ],
  },
  {
    word: "Instrumental",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instrumental <strong class='adj'> adj. </strong> 1 serving as an instrument or means. 2 (of music) performed on instruments. 3 of, or arising from, an instrument (instrumental error).</p>",
      },
    ],
  },
  {
    word: "Instrumentalist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instrumentalist <strong class='noun'> n. </strong> Performer on a musical instrument.</p>",
      },
    ],
  },
  {
    word: "Instrumentality",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instrumentality <strong class='noun'> n. </strong> Agency or means.</p>",
      },
    ],
  },
  {
    word: "Instrumentation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Instrumentation <strong class='noun'> n. </strong> 1 a provision or use of instruments. B instruments collectively. 2 a arrangement of music for instruments. B the particular instruments used in a piece.</p>",
      },
    ],
  },
  {
    word: "Insubordinate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insubordinate <strong class='adj'> adj. </strong> Disobedient; rebellious. insubordination n.</p>",
      },
    ],
  },
  {
    word: "Insubstantial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insubstantial <strong class='adj'> adj. </strong> 1 lacking solidity or substance. 2 not real.</p>",
      },
    ],
  },
  {
    word: "Insufferable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insufferable <strong class='adj'> adj. </strong> 1 intolerable. 2 unbearably conceited etc. insufferably adv.</p>",
      },
    ],
  },
  {
    word: "Insufficient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insufficient <strong class='adj'> adj. </strong> Not sufficient; inadequate. insufficiency <strong class='noun'> n. </strong> Insufficiently adv.</p>",
      },
    ],
  },
  {
    word: "Insular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insular <strong class='adj'> adj. </strong> 1 a of or like an island. B separated or remote. 2 narrow-minded. insularity <strong class='noun'> n. </strong> [latin insula island]</p>",
      },
    ],
  },
  {
    word: "Insulate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insulate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 prevent the passage of electricity, heat, or sound from (a thing, room, etc.) By interposing non-conductors. 2 isolate. insulation <strong class='noun'> n. </strong> Insulator <strong class='noun'> n. </strong> [latin insula island]</p>",
      },
    ],
  },
  {
    word: "Insulin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insulin <strong class='noun'> n. </strong> Hormone regulating the amount of glucose in the blood, the lack of which causes diabetes. [latin insula island]</p>",
      },
    ],
  },
  {
    word: "Insult",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insult <strong class='noun'> —v. </strong> 1 speak to or treat with scornful abuse. 2 offend the self-respect or modesty of. <strong class='noun'> —n. </strong> Insulting remark or action. insulting adj. Insultingly <strong class='adv'> adv. </strong> [latin insulto leap on, assail]</p>",
      },
    ],
  },
  {
    word: "Insuperable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insuperable <strong class='adj'> adj. </strong> 1 (of a barrier) impossible to surmount. 2 (of a difficulty etc.) Impossible to overcome. insuperability <strong class='noun'> n. </strong> Insuperably <strong class='adv'> adv. </strong> [latin supero overcome]</p>",
      },
    ],
  },
  {
    word: "Insupportable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insupportable <strong class='adj'> adj. </strong> 1 unable to be endured. 2 unjustifiable.</p>",
      },
    ],
  },
  {
    word: "Insurance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insurance <strong class='noun'> n. </strong> 1 insuring. 2 a sum paid for this. B sum paid out as compensation for theft, damage, etc. [french: related to *ensure]</p>",
      },
    ],
  },
  {
    word: "Insure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insure <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> (often foll. By against; also absol.) Secure compensation in the event of loss or damage to (property, life, a person, etc.) By advance regular payments. [var. Of *ensure]</p>",
      },
    ],
  },
  {
    word: "Insured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insured <strong class='noun'> n. </strong> (usu. Prec. By the) person etc. Covered by insurance.</p>",
      },
    ],
  },
  {
    word: "Insurer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insurer <strong class='noun'> n. </strong> Person or company selling insurance policies.</p>",
      },
    ],
  },
  {
    word: "Insurgent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insurgent <strong class='adj'> —adj.</strong> In active revolt. <strong class='noun'> —n. </strong> Rebel. insurgence <strong class='noun'> n. </strong> [latin surgo surrect- rise]</p>",
      },
    ],
  },
  {
    word: "Insurmountable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insurmountable <strong class='adj'> adj. </strong> Unable to be surmounted or overcome.</p>",
      },
    ],
  },
  {
    word: "Insurrection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insurrection <strong class='noun'> n. </strong> Rebellion. insurrectionist <strong class='noun'> n. </strong> [latin: related to *insurgent]</p>",
      },
    ],
  },
  {
    word: "Insusceptible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Insusceptible <strong class='adj'> adj. </strong> Not susceptible.</p>",
      },
    ],
  },
  {
    word: "Intact",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intact <strong class='adj'> adj. </strong> 1 undamaged; entire. 2 untouched. intactness <strong class='noun'> n. </strong> [latin tango tact- touch]</p>",
      },
    ],
  },
  {
    word: "Intaglio",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intaglio <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 gem with an incised design. 2 engraved design. [italian: related to *in-2, *tail2]</p>",
      },
    ],
  },
  {
    word: "Intake",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intake <strong class='noun'> n. </strong> 1 action of taking in. 2 a number (of people etc.), or amount, taken in or received. B such people etc. (this year's intake). 3 place where water is taken into a pipe, or fuel or air enters an engine etc.</p>",
      },
    ],
  },
  {
    word: "Intangible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intangible <strong class='adj'> —adj.</strong> 1 unable to be touched. 2 unable to be grasped mentally. <strong class='noun'> —n. </strong> Thing that cannot be precisely assessed or defined. intangibility <strong class='noun'> n. </strong> Intangibly <strong class='adv'> adv. </strong> [latin: related to *intact]</p>",
      },
    ],
  },
  {
    word: "Integer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Integer <strong class='noun'> n. </strong> Whole number. [latin, = untouched, whole]</p>",
      },
    ],
  },
  {
    word: "Integral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Integral <strong class='adj'> —adj.</strong> Also 1 a of or necessary to a whole. B forming a whole. C complete. 2 of or denoted by an integer. <strong class='noun'> —n. </strong> Math. Quantity of which a given function is the derivative. integrally <strong class='adv'> adv. </strong> [latin: related to *integer] <strong class='usage'> Usage:- </strong>the alternative pronunciation given for the adjective, stressed on the second syllable, is considered incorrect by some people.</p>",
      },
    ],
  },
  {
    word: "Integral calculus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Integral calculus <strong class='noun'> n. </strong> Mathematics concerned with finding integrals, their properties and application, etc.</p>",
      },
    ],
  },
  {
    word: "Integrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Integrate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a combine (parts) into a whole. B complete by the addition of parts. 2 bring or come into equal membership of society, a school, etc. 3 desegregate, esp. Racially (a school etc.). 4 math. Find the integral of. integration n.</p>",
      },
    ],
  },
  {
    word: "Integrated circuit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Integrated circuit <strong class='noun'> n. </strong> Electronics small chip etc. Of material replacing several separate components in a conventional electronic circuit.</p>",
      },
    ],
  },
  {
    word: "Integrity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Integrity <strong class='noun'> n. </strong> 1 moral excellence; honesty. 2 wholeness; soundness. [latin: related to *integer]</p>",
      },
    ],
  },
  {
    word: "Integument",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Integument <strong class='noun'> n. </strong> Natural outer covering, as a skin, husk, rind, etc. [latin tego cover]</p>",
      },
    ],
  },
  {
    word: "Intellect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intellect <strong class='noun'> n. </strong> 1 a faculty of reasoning, knowing, and thinking. B understanding. 2 clever or knowledgeable person. [latin: related to *intelligent]</p>",
      },
    ],
  },
  {
    word: "Intellectual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intellectual <strong class='adj'> —adj.</strong> 1 of or appealing to the intellect. 2 possessing a highly developed intellect. 3 requiring the intellect. <strong class='noun'> —n. </strong> Intellectual person. intellectuality <strong class='noun'> n. </strong> Intellectualize v. (also -ise) (-zing or -sing). Intellectually adv.</p>",
      },
    ],
  },
  {
    word: "Intelligence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intelligence <strong class='noun'> n. </strong> 1 a intellect; understanding. B quickness of understanding. 2 a the collecting of information, esp. Of military or political value. B information so collected. C people employed in this.</p>",
      },
    ],
  },
  {
    word: "Intelligence quotient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intelligence quotient <strong class='noun'> n. </strong> Number denoting the ratio of a person's intelligence to the average.</p>",
      },
    ],
  },
  {
    word: "Intelligent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intelligent <strong class='adj'> adj. </strong> 1 having or showing intelligence, esp. Of a high level. 2 clever. intelligently <strong class='adv'> adv. </strong> [latin intelligo -lect- understand]</p>",
      },
    ],
  },
  {
    word: "Intelligentsia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intelligentsia <strong class='noun'> n. </strong> Class of intellectuals regarded as possessing culture and political initiative. [russian intelligentsiya]</p>",
      },
    ],
  },
  {
    word: "Intelligible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intelligible <strong class='adj'> adj. </strong> Able to be understood. intelligibility <strong class='noun'> n. </strong> Intelligibly adv.</p>",
      },
    ],
  },
  {
    word: "Intemperate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intemperate <strong class='adj'> adj. </strong> 1 immoderate. 2 a given to excessive drinking of alcohol. B excessively indulgent in one's appetites. intemperance n.</p>",
      },
    ],
  },
  {
    word: "Intend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intend <strong class='verb'> v. </strong> 1 have as one's purpose (we intend to go; we intend going). 2 (usu. Foll. By for, as) design or destine (a person or a thing) (i intend him to go; i intend it as a warning). [latin tendo stretch]</p>",
      },
    ],
  },
  {
    word: "Intended",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intended <strong class='adj'> —adj.</strong> Done on purpose. <strong class='noun'> —n. </strong> Colloq. One's fiancé or fiancée.</p>",
      },
    ],
  },
  {
    word: "Intense",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intense <strong class='adj'> adj. </strong> (intenser, intensest) 1 existing in a high degree; violent; forceful; extreme (intense joy; intense cold). 2 very emotional. intensely <strong class='adv'> adv. </strong> Intenseness <strong class='noun'> n. </strong> [latin intensus stretched] <strong class='usage'> Usage:- </strong>intense is sometimes confused with intensive, and wrongly used to describe a course of study etc.</p>",
      },
    ],
  },
  {
    word: "Intensifier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intensifier <strong class='noun'> n. </strong> 1 thing that makes something more intense. 2 word or <strong class='prefix '> prefix </strong> used to give force or emphasis, e.g. Thundering in a thundering nuisance.</p>",
      },
    ],
  },
  {
    word: "Intensify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intensify <strong class='verb'> v. </strong> (-ies, -ied) make or become intense or more intense. intensification n.</p>",
      },
    ],
  },
  {
    word: "Intensity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intensity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 intenseness. 2 amount of some quality, e.g. Force, brightness, etc.</p>",
      },
    ],
  },
  {
    word: "Intensive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intensive <strong class='adj'> adj. </strong> 1 thorough, vigorous; directed to a single point, area, or subject (intensive study; intensive bombardment). 2 of or relating to intensity. 3 serving to increase production in relation to costs (intensive farming). 4 (usu. In <strong class='n_pl'> comb. </strong> ) Econ. Making much use of (labour-intensive). 5 (of an adjective, adverb, etc.) Expressing intensity, e.g. Really in my feet are really cold. intensively <strong class='adv'> adv. </strong> Intensiveness n. <strong class='usage'> Usage:- </strong>see note at intense.</p>",
      },
    ],
  },
  {
    word: "Intensive care",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intensive care <strong class='noun'> n. </strong> 1 constant monitoring etc. Of a seriously ill patient. 2 part of a hospital devoted to this.</p>",
      },
    ],
  },
  {
    word: "Intent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intent <strong class='noun'> —n. </strong> Intention; purpose (with intent to defraud). <strong class='adj'> —adj.</strong> 1 (usu. Foll. By on) a resolved, determined. B attentively occupied. 2 (esp. Of a look) earnest; eager. to all intents and purposes practically; virtually. intently <strong class='adv'> adv. </strong> Intentness <strong class='noun'> n. </strong> [latin intentus]</p>",
      },
    ],
  },
  {
    word: "Intention",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intention <strong class='noun'> n. </strong> 1 thing intended; aim, purpose. 2 intending (done without intention).</p>",
      },
    ],
  },
  {
    word: "Intentional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intentional <strong class='adj'> adj. </strong> Done on purpose. intentionally adv.</p>",
      },
    ],
  },
  {
    word: "Inter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inter <strong class='verb'> v. </strong> <strong class='adj'> (-rr-) </strong> bury (a corpse etc.). [latin terra earth]</p>",
      },
    ],
  },
  {
    word: "Inter-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inter- <strong class='n_pl'> comb. </strong> Form 1 between, among (intercontinental). 2 mutually, reciprocally (interbreed). [latin inter between, among]</p>",
      },
    ],
  },
  {
    word: "Interact",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interact <strong class='verb'> v. </strong> Act on each other. interaction n.</p>",
      },
    ],
  },
  {
    word: "Interactive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interactive <strong class='adj'> adj. </strong> 1 reciprocally active. 2 (of a computer or other electronic device) allowing a two-way flow of information between it and a user. interactively adv.</p>",
      },
    ],
  },
  {
    word: "Inter alia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inter alia <strong class='adv'> adv. </strong> Among other things. [latin]</p>",
      },
    ],
  },
  {
    word: "Interbreed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interbreed <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> -bred) 1 (cause to) breed with members of a different race or species to produce a hybrid. 2 breed within one family etc.</p>",
      },
    ],
  },
  {
    word: "Intercalary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intercalary <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> 1 a (of a day or a month) inserted in the calendar to harmonize it with the solar year. B (of a year) having such an addition. 2 interpolated. [latin calo proclaim]</p>",
      },
    ],
  },
  {
    word: "Intercede",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intercede <strong class='verb'> v. </strong> (-ding) (usu. Foll. By with) intervene on behalf of another; plead. [latin: related to *cede]</p>",
      },
    ],
  },
  {
    word: "Intercept",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intercept <strong class='verb'> v. </strong> 1 seize, catch, or stop (a person or thing) going from one place to another. 2 (usu. Foll. By from) cut off (light etc.). interception <strong class='noun'> n. </strong> Interceptive adj. Interceptor <strong class='noun'> n. </strong> [latin intercipio -cept- from capio take]</p>",
      },
    ],
  },
  {
    word: "Intercession",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intercession <strong class='noun'> n. </strong> Interceding. intercessor <strong class='noun'> n. </strong> [latin: related to *intercede]</p>",
      },
    ],
  },
  {
    word: "Interchange",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interchange <strong class='noun'> —v. </strong> (-ging) 1 (of two people) exchange (things) with each other. 2 put each of (two things) in the other's place; alternate. <strong class='noun'> —n. </strong> 1 (often foll. By of) exchange between two people etc. 2 alternation. 3 road junction where traffic streams do not cross.</p>",
      },
    ],
  },
  {
    word: "Interchangeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interchangeable <strong class='adj'> adj. </strong> That can be interchanged, esp. Without affecting the way a thing works. interchangeably adv.</p>",
      },
    ],
  },
  {
    word: "Inter-city",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inter-city <strong class='adj'> adj. </strong> Existing or travelling between cities.</p>",
      },
    ],
  },
  {
    word: "Intercom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intercom <strong class='noun'> n. </strong> Colloq. 1 system of intercommunication by radio or telephone. 2 instrument used in this. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Intercommunicate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intercommunicate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 communicate reciprocally. 2 (of rooms etc.) Open into each other. intercommunication n.</p>",
      },
    ],
  },
  {
    word: "Intercommunion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intercommunion <strong class='noun'> n. </strong> 1 mutual communion. 2 mutual action or relationship, esp. Between christian denominations.</p>",
      },
    ],
  },
  {
    word: "Interconnect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interconnect <strong class='verb'> v. </strong> Connect with each other. interconnection n.</p>",
      },
    ],
  },
  {
    word: "Intercontinental",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intercontinental <strong class='adj'> adj. </strong> Connecting or travelling between continents.</p>",
      },
    ],
  },
  {
    word: "Intercourse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intercourse <strong class='noun'> n. </strong> 1 communication or dealings between individuals, nations, etc. 2 = *sexual intercourse. [latin: related to *course]</p>",
      },
    ],
  },
  {
    word: "Interdenominational",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interdenominational <strong class='adj'> adj. </strong> Concerning more than one (religious) denomination.</p>",
      },
    ],
  },
  {
    word: "Interdepartmental",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interdepartmental <strong class='adj'> adj. </strong> Concerning more than one department.</p>",
      },
    ],
  },
  {
    word: "Interdependent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interdependent <strong class='adj'> adj. </strong> Dependent on each other. interdependence n.</p>",
      },
    ],
  },
  {
    word: "Interdict",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interdict <strong class='noun'> —n. </strong> 1 authoritative prohibition. 2 rc ch. Sentence debarring a person, or esp. A place, from ecclesiastical functions and privileges. —v. 1 prohibit (an action). 2 forbid the use of. 3 (usu. Foll. By from + verbal noun) restrain (a person). 4 (usu. Foll. By to) forbid (a thing) to a person. interdiction <strong class='noun'> n. </strong> Interdictory adj. [latin dico say]</p>",
      },
    ],
  },
  {
    word: "Interdisciplinary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interdisciplinary <strong class='adj'> adj. </strong> Of or between more than one branch of learning.</p>",
      },
    ],
  },
  {
    word: "Interest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interest <strong class='noun'> —n. </strong> 1 a concern; curiosity (have no interest in fishing). B quality exciting curiosity etc. (this book lacks interest). 2 subject, hobby, etc., in which one is concerned. 3 advantage or profit (it is in my interest to go). 4 money paid for the use of money lent. 5 a thing in which one has a stake or concern (business interests). B financial stake (in an undertaking etc.). C legal concern, title, or right (in property). 6 a party or group with a common interest (the brewing interest). B principle or cause with which this is concerned. —v. 1 excite the curiosity or attention of. 2 (usu. Foll. By in) cause (a person) to take a personal interest. 3 (as interested adj.) Having a private interest; not impartial or disinterested. [latin, = it matters]</p>",
      },
    ],
  },
  {
    word: "Interesting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interesting <strong class='adj'> adj. </strong> Causing curiosity; holding the attention. interestingly adv.</p>",
      },
    ],
  },
  {
    word: "Interface",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interface <strong class='noun'> —n. </strong> 1 surface forming a boundary between two regions. 2 means or place of interaction between two systems etc.; interaction (the interface between psychology and education). 3 esp. Computing apparatus for connecting two pieces of equipment so that they can be operated jointly. —v. (-cing) (often foll. By with) 1 connect with (another piece of equipment etc.) By an interface. 2 interact. <strong class='usage'> Usage:- </strong>the use of the noun and verb in sense 2 is deplored by some people.</p>",
      },
    ],
  },
  {
    word: "Interfacing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interfacing <strong class='noun'> n. </strong> Stiffish material between two layers of fabric in collars etc.</p>",
      },
    ],
  },
  {
    word: "Interfere",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interfere <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 (usu. Foll. By with) a (of a person) meddle; obstruct a process etc. B (of a thing) be a hindrance. 2 (usu. Foll. By in) intervene, esp. Without invitation or necessity. 3 (foll. By with) euphem. Molest or assault sexually. 4 (of light or other waves) combine so as to cause interference. [latin ferio strike]</p>",
      },
    ],
  },
  {
    word: "Interference",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interference <strong class='noun'> n. </strong> 1 act of interfering. 2 fading or disturbance of received radio signals. 3 physics combination of two or more wave motions to form a resultant wave in which the displacement is reinforced or cancelled.</p>",
      },
    ],
  },
  {
    word: "Interferon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interferon <strong class='noun'> n. </strong> Any of various proteins inhibiting the development of a virus in a cell etc.</p>",
      },
    ],
  },
  {
    word: "Interfuse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interfuse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 a (usu. Foll. By with) mix (a thing) with; intersperse. B blend (things). 2 (of two things) blend with each other. interfusion <strong class='noun'> n. </strong> [latin: related to *fuse1]</p>",
      },
    ],
  },
  {
    word: "Intergalactic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intergalactic <strong class='adj'> adj. </strong> Of or situated between galaxies.</p>",
      },
    ],
  },
  {
    word: "Interim",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interim <strong class='noun'> —n. </strong> Intervening time. <strong class='adj'> —adj.</strong> Provisional, temporary. [latin, = in the interim]</p>",
      },
    ],
  },
  {
    word: "Interior",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interior <strong class='adj'> —adj.</strong> 1 inner. 2 inland. 3 internal; domestic. 4 (usu. Foll. By to) situated further in or within. 5 existing in the mind. 6 coming from inside. <strong class='noun'> —n. </strong> 1 interior part; inside. 2 interior part of a region. 3 home affairs of a country (minister of the interior). 4 representation of the inside of a room etc. [latin]</p>",
      },
    ],
  },
  {
    word: "Interior decoration",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interior decoration <strong class='noun'> n. </strong> Decoration of the interior of a building etc. interior decorator n.</p>",
      },
    ],
  },
  {
    word: "Interior design",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interior design <strong class='noun'> n. </strong> Design of the interior of a building. interior designer n.</p>",
      },
    ],
  },
  {
    word: "Interject",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interject <strong class='verb'> v. </strong> 1 utter (words) abruptly or parenthetically. 2 interrupt. [latin jacio throw]</p>",
      },
    ],
  },
  {
    word: "Interjection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interjection <strong class='noun'> n. </strong> Exclamation, esp. As a part of speech (e.g. Ah!, dear me!).</p>",
      },
    ],
  },
  {
    word: "Interlace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interlace <strong class='verb'> v. </strong> (-cing) 1 bind intricately together; interweave. 2 cross each other intricately. interlacement n.</p>",
      },
    ],
  },
  {
    word: "Interlard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interlard <strong class='verb'> v. </strong> (usu. Foll. By with) mix (writing or speech) with unusual words or phrases. [french]</p>",
      },
    ],
  },
  {
    word: "Interleave",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interleave <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> insert (usu. Blank) leaves between the leaves of (a book etc.).</p>",
      },
    ],
  },
  {
    word: "Interline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interline <strong class='verb'> v. </strong> (-ning) put an extra layer of material between the fabric of (a garment) and its lining.</p>",
      },
    ],
  },
  {
    word: "Interlink",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interlink <strong class='verb'> v. </strong> Link or be linked together.</p>",
      },
    ],
  },
  {
    word: "Interlock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interlock <strong class='noun'> —v. </strong> 1 engage with each other by overlapping. 2 lock or clasp within each other. <strong class='noun'> —n. </strong> 1 machine-knitted fabric with fine stitches. 2 mechanism for preventing a set of operations from being performed in any but the prescribed sequence.</p>",
      },
    ],
  },
  {
    word: "Interlocutor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interlocutor <strong class='noun'> n. </strong> Formal person who takes part in a conversation. [latin loquor speak]</p>",
      },
    ],
  },
  {
    word: "Interlocutory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interlocutory <strong class='adj'> adj. </strong> Formal 1 of dialogue. 2 (of a decree etc.) Given provisionally in a legal action.</p>",
      },
    ],
  },
  {
    word: "Interloper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interloper <strong class='noun'> n. </strong> 1 intruder. 2 person who interferes in others' affairs, esp. For profit. [after landloper vagabond, from dutch loopen run]</p>",
      },
    ],
  },
  {
    word: "Interlude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interlude <strong class='noun'> n. </strong> 1 a pause between the acts of a play. B something performed during this pause. 2 contrasting event, time, etc. In the middle of something (comic interlude). 3 piece of music played between other pieces etc. [medieval latin ludus play]</p>",
      },
    ],
  },
  {
    word: "Intermarry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intermarry <strong class='verb'> v. </strong> (-ies, -ied) (foll. By with) (of races, castes, families, etc.) Become connected by marriage. intermarriage n.</p>",
      },
    ],
  },
  {
    word: "Intermediary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intermediary <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> intermediate person or thing, esp. A mediator. <strong class='adj'> —adj.</strong> Acting as mediator; intermediate.</p>",
      },
    ],
  },
  {
    word: "Intermediate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intermediate <strong class='adj'> —adj.</strong> Coming between two things in time, place, order, character, etc. <strong class='noun'> —n. </strong> 1 intermediate thing. 2 chemical compound formed by one reaction and then used in another. [latin intermedius]</p>",
      },
    ],
  },
  {
    word: "Interment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interment <strong class='noun'> n. </strong> Burial. <strong class='usage'> Usage:- </strong>interment is sometimes confused with internment, which means ‘confinement’.</p>",
      },
    ],
  },
  {
    word: "Intermezzo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intermezzo <strong class='noun'> n. </strong> (pl. -mezzi or -s) 1 a short connecting instrumental movement in a musical work. B similar independent piece. 2 short light dramatic or other performance inserted between the acts of a play. [italian]</p>",
      },
    ],
  },
  {
    word: "Interminable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interminable <strong class='adj'> adj. </strong> 1 endless. 2 tediously long. interminably adv.</p>",
      },
    ],
  },
  {
    word: "Intermingle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intermingle <strong class='verb'> v. </strong> (-ling) mix together; mingle.</p>",
      },
    ],
  },
  {
    word: "Intermission",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intermission <strong class='noun'> n. </strong> 1 pause or cessation. 2 interval in a cinema etc. [latin: related to *intermittent]</p>",
      },
    ],
  },
  {
    word: "Intermittent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intermittent <strong class='adj'> adj. </strong> Occurring at intervals; not continuous. intermittently <strong class='adv'> adv. </strong> [latin mitto miss- let go]</p>",
      },
    ],
  },
  {
    word: "Intermix",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intermix <strong class='verb'> v. </strong> Mix together.</p>",
      },
    ],
  },
  {
    word: "Intern",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intern <strong class='noun'> —n. </strong> (also interne) esp. Us = *houseman. —v. Oblige (a prisoner, alien, etc.) To reside within prescribed limits. internment <strong class='noun'> n. </strong> [french: related to *internal] <strong class='usage'> Usage:- </strong>internment is sometimes confused with interment, which means ‘burial’.</p>",
      },
    ],
  },
  {
    word: "Internal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Internal <strong class='adj'> adj. </strong> 1 of or situated in the inside or invisible part. 2 of the inside of the body (internal injuries). 3 of a nation's domestic affairs. 4 (of a student) attending a university etc. As well as taking its examinations. 5 used or applying within an organization. 6 a intrinsic. B of the mind or soul. internality <strong class='noun'> n. </strong> Internally <strong class='adv'> adv. </strong> [medieval latin internus internal]</p>",
      },
    ],
  },
  {
    word: "Internal-combustion engine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Internal-combustion engine <strong class='noun'> n. </strong> Engine with its motive power generated by the explosion of gases or vapour with air in a cylinder.</p>",
      },
    ],
  },
  {
    word: "Internal evidence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Internal evidence <strong class='noun'> n. </strong> Evidence derived from the contents of the thing discussed.</p>",
      },
    ],
  },
  {
    word: "Internalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Internalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) psychol. Make (attitudes, behaviour, etc.) Part of one's nature by learning or unconscious assimilation. internalization n.</p>",
      },
    ],
  },
  {
    word: "International",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>International <strong class='adj'> —adj.</strong> 1 existing or carried on between nations. 2 agreed on or used by all or many nations. <strong class='noun'> —n. </strong> 1 a contest, esp. In sport, between teams representing different countries. B member of such a team. 2 (international) any of four successive associations for socialist or communist action. internationality <strong class='noun'> n. </strong> Internationally adv.</p>",
      },
    ],
  },
  {
    word: "Internationalism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Internationalism <strong class='noun'> n. </strong> Advocacy of a community of interests among nations. internationalist n.</p>",
      },
    ],
  },
  {
    word: "Internationalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Internationalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 make international. 2 bring under the protection or control of two or more nations.</p>",
      },
    ],
  },
  {
    word: "Interne",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interne <strong class='var'> var. </strong> Of *intern n.</p>",
      },
    ],
  },
  {
    word: "Internecine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Internecine <strong class='adj'> adj. </strong> Mutually destructive. [latin internecinus deadly]</p>",
      },
    ],
  },
  {
    word: "Internee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Internee <strong class='noun'> n. </strong> Person interned.</p>",
      },
    ],
  },
  {
    word: "Interpenetrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpenetrate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 penetrate each other. 2 pervade. interpenetration n.</p>",
      },
    ],
  },
  {
    word: "Interpersonal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpersonal <strong class='adj'> adj. </strong> Between persons, social (interpersonal skills).</p>",
      },
    ],
  },
  {
    word: "Interplanetary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interplanetary <strong class='adj'> adj. </strong> 1 between planets. 2 of travel between planets.</p>",
      },
    ],
  },
  {
    word: "Interplay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interplay <strong class='noun'> n. </strong> Reciprocal action.</p>",
      },
    ],
  },
  {
    word: "Interpol",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpol <strong class='noun'> n. </strong> International criminal police organization. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Interpolate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpolate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a insert (words) in a book etc., esp. Misleadingly. B make such insertions in (a book etc.). 2 interject (a remark) in a conversation. 3 estimate (values) between known ones in the same range. interpolation <strong class='noun'> n. </strong> Interpolator <strong class='noun'> n. </strong> [latin interpolo furbish]</p>",
      },
    ],
  },
  {
    word: "Interpose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpose <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 (often foll. By between) insert (a thing) between others. 2 say (words) as an interruption; interrupt. 3 exercise or advance (a veto or objection) so as to interfere. 4 (foll. By between) intervene (between parties). interposition <strong class='noun'> n. </strong> [latin pono put]</p>",
      },
    ],
  },
  {
    word: "Interpret",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpret <strong class='verb'> v. </strong> (-t-) 1 explain the meaning of (words, a dream, etc.). 2 make out or bring out the meaning of (creative work). 3 act as an interpreter. 4 explain or understand (behaviour etc.) In a specified manner. interpretation <strong class='noun'> n. </strong> Interpretative adj. Interpretive adj. [latin interpres -pretis explainer]</p>",
      },
    ],
  },
  {
    word: "Interpreter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpreter <strong class='noun'> n. </strong> Person who interprets, esp. One who translates foreign speech orally.</p>",
      },
    ],
  },
  {
    word: "Interracial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interracial <strong class='adj'> adj. </strong> Between or affecting different races.</p>",
      },
    ],
  },
  {
    word: "Interregnum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interregnum <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 interval when the normal government or leadership is suspended, esp. Between successive reigns or regimes. 2 interval, pause. [latin regnum reign]</p>",
      },
    ],
  },
  {
    word: "Interrelate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrelate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 relate (two or more things) to each other. 2 (of two or more things) relate to each other. interrelation <strong class='noun'> n. </strong> Interrelationship n.</p>",
      },
    ],
  },
  {
    word: "Interrogate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrogate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> question (a person), esp. Closely or formally. interrogation <strong class='noun'> n. </strong> Interrogator <strong class='noun'> n. </strong> [latin rogo ask]</p>",
      },
    ],
  },
  {
    word: "Interrogative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrogative <strong class='adj'> —adj.</strong> Of, like, or used in a question. <strong class='noun'> —n. </strong> Interrogative word (e.g. What?).</p>",
      },
    ],
  },
  {
    word: "Interrogatory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrogatory <strong class='adj'> —adj.</strong> Questioning (interrogatory tone). <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> formal set of questions.</p>",
      },
    ],
  },
  {
    word: "Interrupt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrupt <strong class='verb'> v. </strong> 1 break the continuous progress of (an action, speech, person speaking, etc.). 2 obstruct (a person's view etc.). interruption <strong class='noun'> n. </strong> [latin: related to *rupture]</p>",
      },
    ],
  },
  {
    word: "Interrupter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrupter <strong class='noun'> n. </strong> (also interruptor) 1 person or thing that interrupts. 2 device for interrupting, esp. An electric circuit.</p>",
      },
    ],
  },
  {
    word: "Intersect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intersect <strong class='verb'> v. </strong> 1 divide (a thing) by crossing it. 2 (of lines, roads, etc.) Cross each other. [latin: related to *section]</p>",
      },
    ],
  },
  {
    word: "Intersection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intersection <strong class='noun'> n. </strong> 1 intersecting. 2 place where two roads intersect. 3 point or line common to lines or planes that intersect.</p>",
      },
    ],
  },
  {
    word: "Intersperse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intersperse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 (often foll. By between, among) scatter. 2 (foll. By with) vary (a thing) by scattering other things among it. interspersion <strong class='noun'> n. </strong> [latin: related to *sparse]</p>",
      },
    ],
  },
  {
    word: "Interstate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interstate <strong class='adj'> adj. </strong> Existing or carried on between states, esp. Those of the us.</p>",
      },
    ],
  },
  {
    word: "Interstellar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interstellar <strong class='adj'> adj. </strong> Between stars.</p>",
      },
    ],
  },
  {
    word: "Interstice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interstice <strong class='noun'> n. </strong> 1 intervening space. 2 chink or crevice. [latin interstitium from sisto stand]</p>",
      },
    ],
  },
  {
    word: "Interstitial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interstitial <strong class='adj'> adj. </strong> Of, forming, or occupying interstices. interstitially adv.</p>",
      },
    ],
  },
  {
    word: "Intertwine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intertwine <strong class='verb'> v. </strong> (-ning) (often foll. By with) entwine (together).</p>",
      },
    ],
  },
  {
    word: "Interval",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interval <strong class='noun'> n. </strong> 1 intervening time or space. 2 pause or break, esp. Between the parts of a performance. 3 difference in pitch between two sounds. at intervals here and there; now and then. [latin intervallum space between ramparts]</p>",
      },
    ],
  },
  {
    word: "Intervene",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intervene <strong class='verb'> v. </strong> (-ning) 1 occur in time between events. 2 interfere; prevent or modify events. 3 be situated between things. 4 come in as an extraneous factor. [latin venio vent- come]</p>",
      },
    ],
  },
  {
    word: "Intervention",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intervention <strong class='noun'> n. </strong> 1 intervening. 2 interference, esp. By a state. 3 mediation.</p>",
      },
    ],
  },
  {
    word: "Interventionist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interventionist <strong class='noun'> n. </strong> Person who favours intervention.</p>",
      },
    ],
  },
  {
    word: "Interview",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interview <strong class='noun'> —n. </strong> 1 oral examination of an applicant. 2 conversation with a reporter, for a broadcast or publication. 3 meeting face to face, esp. For consultation. —v. Hold an interview with. interviewee <strong class='noun'> n. </strong> Interviewer <strong class='noun'> n. </strong> [french entrevue: related to *inter-, vue sight]</p>",
      },
    ],
  },
  {
    word: "Interwar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interwar <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Existing in the period between two wars.</p>",
      },
    ],
  },
  {
    word: "Interweave",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interweave <strong class='verb'> v. </strong> (-ving; past -wove;  <strong class='archaic_it_was'>past part.</strong> -woven) 1 weave together. 2 blend intimately.</p>",
      },
    ],
  },
  {
    word: "Interpolate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpolate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a insert (words) in a book etc., esp. Misleadingly. B make such insertions in (a book etc.). 2 interject (a remark) in a conversation. 3 estimate (values) between known ones in the same range. interpolation <strong class='noun'> n. </strong> Interpolator <strong class='noun'> n. </strong> [latin interpolo furbish]</p>",
      },
    ],
  },
  {
    word: "Interpose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpose <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 (often foll. By between) insert (a thing) between others. 2 say (words) as an interruption; interrupt. 3 exercise or advance (a veto or objection) so as to interfere. 4 (foll. By between) intervene (between parties). interposition <strong class='noun'> n. </strong> [latin pono put]</p>",
      },
    ],
  },
  {
    word: "Interpret",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpret <strong class='verb'> v. </strong> (-t-) 1 explain the meaning of (words, a dream, etc.). 2 make out or bring out the meaning of (creative work). 3 act as an interpreter. 4 explain or understand (behaviour etc.) In a specified manner. interpretation <strong class='noun'> n. </strong> Interpretative adj. Interpretive adj. [latin interpres -pretis explainer]</p>",
      },
    ],
  },
  {
    word: "Interpreter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interpreter <strong class='noun'> n. </strong> Person who interprets, esp. One who translates foreign speech orally.</p>",
      },
    ],
  },
  {
    word: "Interracial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interracial <strong class='adj'> adj. </strong> Between or affecting different races.</p>",
      },
    ],
  },
  {
    word: "Interregnum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interregnum <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 interval when the normal government or leadership is suspended, esp. Between successive reigns or regimes. 2 interval, pause. [latin regnum reign]</p>",
      },
    ],
  },
  {
    word: "Interrelate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrelate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 relate (two or more things) to each other. 2 (of two or more things) relate to each other. interrelation <strong class='noun'> n. </strong> Interrelationship n.</p>",
      },
    ],
  },
  {
    word: "Interrogate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrogate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> question (a person), esp. Closely or formally. interrogation <strong class='noun'> n. </strong> Interrogator <strong class='noun'> n. </strong> [latin rogo ask]</p>",
      },
    ],
  },
  {
    word: "Interrogative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrogative <strong class='adj'> —adj.</strong> Of, like, or used in a question. <strong class='noun'> —n. </strong> Interrogative word (e.g. What?).</p>",
      },
    ],
  },
  {
    word: "Interrogatory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrogatory <strong class='adj'> —adj.</strong> Questioning (interrogatory tone). <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> formal set of questions.</p>",
      },
    ],
  },
  {
    word: "Interrupt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrupt <strong class='verb'> v. </strong> 1 break the continuous progress of (an action, speech, person speaking, etc.). 2 obstruct (a person's view etc.). interruption <strong class='noun'> n. </strong> [latin: related to *rupture]</p>",
      },
    ],
  },
  {
    word: "Interrupter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interrupter <strong class='noun'> n. </strong> (also interruptor) 1 person or thing that interrupts. 2 device for interrupting, esp. An electric circuit.</p>",
      },
    ],
  },
  {
    word: "Intersect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intersect <strong class='verb'> v. </strong> 1 divide (a thing) by crossing it. 2 (of lines, roads, etc.) Cross each other. [latin: related to *section]</p>",
      },
    ],
  },
  {
    word: "Intersection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intersection <strong class='noun'> n. </strong> 1 intersecting. 2 place where two roads intersect. 3 point or line common to lines or planes that intersect.</p>",
      },
    ],
  },
  {
    word: "Intersperse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intersperse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 (often foll. By between, among) scatter. 2 (foll. By with) vary (a thing) by scattering other things among it. interspersion <strong class='noun'> n. </strong> [latin: related to *sparse]</p>",
      },
    ],
  },
  {
    word: "Interstate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interstate <strong class='adj'> adj. </strong> Existing or carried on between states, esp. Those of the us.</p>",
      },
    ],
  },
  {
    word: "Interstellar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interstellar <strong class='adj'> adj. </strong> Between stars.</p>",
      },
    ],
  },
  {
    word: "Interstice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interstice <strong class='noun'> n. </strong> 1 intervening space. 2 chink or crevice. [latin interstitium from sisto stand]</p>",
      },
    ],
  },
  {
    word: "Interstitial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interstitial <strong class='adj'> adj. </strong> Of, forming, or occupying interstices. interstitially adv.</p>",
      },
    ],
  },
  {
    word: "Intertwine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intertwine <strong class='verb'> v. </strong> (-ning) (often foll. By with) entwine (together).</p>",
      },
    ],
  },
  {
    word: "Interval",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interval <strong class='noun'> n. </strong> 1 intervening time or space. 2 pause or break, esp. Between the parts of a performance. 3 difference in pitch between two sounds. at intervals here and there; now and then. [latin intervallum space between ramparts]</p>",
      },
    ],
  },
  {
    word: "Intervene",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intervene <strong class='verb'> v. </strong> (-ning) 1 occur in time between events. 2 interfere; prevent or modify events. 3 be situated between things. 4 come in as an extraneous factor. [latin venio vent- come]</p>",
      },
    ],
  },
  {
    word: "Intervention",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intervention <strong class='noun'> n. </strong> 1 intervening. 2 interference, esp. By a state. 3 mediation.</p>",
      },
    ],
  },
  {
    word: "Interventionist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interventionist <strong class='noun'> n. </strong> Person who favours intervention.</p>",
      },
    ],
  },
  {
    word: "Interview",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interview <strong class='noun'> —n. </strong> 1 oral examination of an applicant. 2 conversation with a reporter, for a broadcast or publication. 3 meeting face to face, esp. For consultation. —v. Hold an interview with. interviewee <strong class='noun'> n. </strong> Interviewer <strong class='noun'> n. </strong> [french entrevue: related to *inter-, vue sight]</p>",
      },
    ],
  },
  {
    word: "Interwar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interwar <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Existing in the period between two wars.</p>",
      },
    ],
  },
  {
    word: "Interweave",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Interweave <strong class='verb'> v. </strong> (-ving; past -wove;  <strong class='archaic_it_was'>past part.</strong> -woven) 1 weave together. 2 blend intimately.</p>",
      },
    ],
  },
  {
    word: "Intestate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intestate <strong class='adj'> —adj.</strong> Not having made a will before death. <strong class='noun'> —n. </strong> Person who has died intestate. intestacy <strong class='noun'> n. </strong> [latin: related to *testament] <strong class='adj'> —adj.</strong> Not having made a will before death. <strong class='noun'> —n. </strong> Person who has died intestate. intestacy <strong class='noun'> n. </strong> [latin: related to *testament]</p>",
      },
    ],
  },
  {
    word: "Intestine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intestine <strong class='noun'> n. </strong> (in sing. Or pl.) Lower part of the alimentary canal. intestinal adj. [latin intus within]</p>",
      },
    ],
  },
  {
    word: "Intifada",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intifada <strong class='noun'> n. </strong> Arab uprising. [arabic]</p>",
      },
    ],
  },
  {
    word: "Intimacy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intimacy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 state of being intimate. 2 intimate remark or act; sexual intercourse.</p>",
      },
    ],
  },
  {
    word: "Intimate1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intimate1 <strong class='adj'> —adj.</strong> 1 closely acquainted; familiar (intimate friend). 2 private and personal. 3 (usu. Foll. By with) having sexual relations. 4 (of knowledge) detailed, thorough. 5 (of a relationship between things) close. <strong class='noun'> —n. </strong> Close friend. intimately <strong class='adv'> adv. </strong> [latin intimus inmost]</p>",
      },
    ],
  },
  {
    word: "Intimate2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intimate2 <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 (often foll. By that) state or make known. 2 imply, hint. intimation <strong class='noun'> n. </strong> [latin intimo announce: related to *intimate1]</p>",
      },
    ],
  },
  {
    word: "Intimidate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intimidate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> frighten or overawe, esp. To subdue or influence. intimidation <strong class='noun'> n. </strong> [medieval latin: related to *timid]</p>",
      },
    ],
  },
  {
    word: "Into",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Into <strong class='n_pl'> comb. </strong> 1 expressing motion or direction to a point on or within (walked into a tree; ran into the house). 2 expressing direction of attention etc. (will look into it). 3 expressing a change of state (turned into a dragon; separated into groups). 4 after the beginning of (five minutes into the game). 5 <strong class='colloq'> colloq. </strong> Interested in. [old english: related to *in, *to]</p>",
      },
    ],
  },
  {
    word: "Intolerable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intolerable <strong class='adj'> adj. </strong> That cannot be endured. intolerably adv.</p>",
      },
    ],
  },
  {
    word: "Intolerant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intolerant <strong class='adj'> adj. </strong> Not tolerant, esp. Of others' beliefs or behaviour. intolerance n.</p>",
      },
    ],
  },
  {
    word: "Intonation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intonation <strong class='noun'> n. </strong> 1 modulation of the voice; accent. 2 intoning. 3 accuracy of musical pitch. [medieval latin: related to *intone]</p>",
      },
    ],
  },
  {
    word: "Intone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intone <strong class='verb'> v. </strong> (-ning) 1 recite (prayers etc.) With prolonged sounds, esp. In a monotone. 2 utter with a particular tone. [medieval latin: related to *in-2]</p>",
      },
    ],
  },
  {
    word: "In toto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In toto <strong class='adv'> adv. </strong> Completely. [latin]</p>",
      },
    ],
  },
  {
    word: "Intoxicant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intoxicant <strong class='adj'> —adj.</strong> Intoxicating. <strong class='noun'> —n. </strong> Intoxicating substance.</p>",
      },
    ],
  },
  {
    word: "Intoxicate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intoxicate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 make drunk. 2 excite or elate beyond self-control. intoxication <strong class='noun'> n. </strong> [medieval latin: related to *toxic]</p>",
      },
    ],
  },
  {
    word: "Intra-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intra- <strong class='prefix '> prefix </strong> on the inside, within. [latin intra inside]</p>",
      },
    ],
  },
  {
    word: "Intractable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intractable <strong class='adj'> adj. </strong> 1 hard to control or deal with. 2 difficult, stubborn. intractability <strong class='noun'> n. </strong> Intractably adv.</p>",
      },
    ],
  },
  {
    word: "Intramural",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intramural <strong class='adj'> adj. </strong> 1 situated or done within the walls of an institution etc. 2 forming part of normal university etc. Studies. intramurally <strong class='adv'> adv. </strong> [latin murus wall]</p>",
      },
    ],
  },
  {
    word: "Intramuscular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intramuscular <strong class='adj'> adj. </strong> In or into muscle tissue.</p>",
      },
    ],
  },
  {
    word: "Intransigent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intransigent <strong class='adj'> —adj.</strong> Uncompromising, stubborn. <strong class='noun'> —n. </strong> Intransigent person. intransigence <strong class='noun'> n. </strong> [spanish los intransigentes extremists]</p>",
      },
    ],
  },
  {
    word: "Intransitive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intransitive <strong class='adj'> adj. </strong> (of a verb) not taking a direct object.</p>",
      },
    ],
  },
  {
    word: "Intrauterine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intrauterine <strong class='adj'> adj. </strong> Within the womb.</p>",
      },
    ],
  },
  {
    word: "Intravenous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intravenous <strong class='adj'> adj. </strong> In or into a vein or veins. intravenously adv.</p>",
      },
    ],
  },
  {
    word: "In-tray",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In-tray <strong class='noun'> n. </strong> Tray for incoming documents.</p>",
      },
    ],
  },
  {
    word: "Intrepid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intrepid <strong class='adj'> adj. </strong> Fearless; very brave. intrepidity <strong class='noun'> n. </strong> Intrepidly <strong class='adv'> adv. </strong> [latin trepidus alarmed]</p>",
      },
    ],
  },
  {
    word: "Intricate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intricate <strong class='adj'> adj. </strong> Very complicated; perplexingly detailed. intricacy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Intricately <strong class='adv'> adv. </strong> [latin: related to *in-2, tricae tricks]</p>",
      },
    ],
  },
  {
    word: "Intrigue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intrigue <strong class='noun'> —v. </strong> (-gues, -gued, -guing) 1 (foll. By with) a carry on an underhand plot. B use secret influence. 2 arouse the curiosity of. <strong class='noun'> —n. </strong> 1 underhand plot or plotting. 2 secret arrangement (amorous intrigues). intriguing adj. Esp. In sense 2 of v. Intriguingly <strong class='adv'> adv. </strong> [french from italian intrigo]</p>",
      },
    ],
  },
  {
    word: "Intrinsic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intrinsic <strong class='adj'> adj. </strong> Inherent, essential (intrinsic value). intrinsically <strong class='adv'> adv. </strong> [latin intrinsecus inwardly]</p>",
      },
    ],
  },
  {
    word: "Intro",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intro <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='colloq'> colloq. </strong> Introduction. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Intro-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intro- <strong class='n_pl'> comb. </strong> Form into. [latin]</p>",
      },
    ],
  },
  {
    word: "Introduce",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Introduce <strong class='verb'> v. </strong> (-cing) 1 (foll. By to) make (a person or oneself) known by name to another, esp. Formally. 2 announce or present to an audience. 3 bring (a custom etc.) Into use. 4 bring (legislation) before parliament etc. 5 (foll. By to) initiate (a person) in a subject. 6 insert. 7 bring in; usher in; bring forward. 8 occur just before the start of. 9 put on sale for the first time. introducible adj. [latin duco lead]</p>",
      },
    ],
  },
  {
    word: "Introduction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Introduction <strong class='noun'> n. </strong> 1 introducing or being introduced. 2 formal presentation of one person to another. 3 explanatory section at the beginning of a book etc. 4 introductory treatise. 5 thing introduced.</p>",
      },
    ],
  },
  {
    word: "Introductory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Introductory <strong class='adj'> adj. </strong> Serving as an introduction; preliminary.</p>",
      },
    ],
  },
  {
    word: "Introit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Introit <strong class='noun'> n. </strong> Psalm or antiphon sung or said as the priest approaches the altar for the eucharist. [latin introitus entrance]</p>",
      },
    ],
  },
  {
    word: "Introspection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Introspection <strong class='noun'> n. </strong> Examination of one's own thoughts. introspective adj. [latin specio spect- look]</p>",
      },
    ],
  },
  {
    word: "Introvert",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Introvert <strong class='noun'> —n. </strong> 1 person predominantly concerned with his or her own thoughts. 2 shy thoughtful person. <strong class='adj'> —adj.</strong> (also introverted) characteristic of an introvert. introversion n.</p>",
      },
    ],
  },
  {
    word: "Intrude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intrude <strong class='verb'> v. </strong> (-ding) (foll. By on, upon, into) 1 come uninvited or unwanted. 2 force on a person. [latin trudo trus- thrust]</p>",
      },
    ],
  },
  {
    word: "Intruder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intruder <strong class='noun'> n. </strong> Person who intrudes, esp. A trespasser.</p>",
      },
    ],
  },
  {
    word: "Intrusion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intrusion <strong class='noun'> n. </strong> 1 intruding. 2 influx of molten rock between existing strata etc. intrusive adj.</p>",
      },
    ],
  },
  {
    word: "Intrust",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intrust <strong class='var'> var. </strong> Of *entrust.</p>",
      },
    ],
  },
  {
    word: "Intuition",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intuition <strong class='noun'> n. </strong> Immediate insight or understanding without conscious reasoning. intuit v. Intuitional adj. [latin tueor tuit- look]</p>",
      },
    ],
  },
  {
    word: "Intuitive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Intuitive <strong class='adj'> adj. </strong> Of, possessing, or perceived by intuition. intuitively <strong class='adv'> adv. </strong> Intuitiveness <strong class='noun'> n. </strong> [medieval latin: related to *intuition]</p>",
      },
    ],
  },
  {
    word: "Inuit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inuit <strong class='noun'> n. </strong> (also innuit) <strong class='pl_same_or_s'> (pl. Same or -s) </strong> <strong class='noun'> n. </strong> American eskimo. [eskimo inuit people]</p>",
      },
    ],
  },
  {
    word: "Inundate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inundate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (often foll. By with) 1 flood. 2 overwhelm. inundation <strong class='noun'> n. </strong> [latin unda wave]</p>",
      },
    ],
  },
  {
    word: "Inure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inure <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 (often in passive; foll. By to) accustom (a person) to an esp. Unpleasant thing. 2 law take effect. inurement <strong class='noun'> n. </strong> [anglo-french: related to *in, eure work, from latin opera]</p>",
      },
    ],
  },
  {
    word: "Invade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invade <strong class='verb'> v. </strong> (-ding) (often absol.) 1 enter (a country etc.) Under arms to control or subdue it. 2 swarm into. 3 (of a disease) attack. 4 encroach upon (a person's rights, esp. Privacy). invader <strong class='noun'> n. </strong> [latin vado vas- go]</p>",
      },
    ],
  },
  {
    word: "Invalid1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invalid1 <strong class='noun'> —n. </strong> Person enfeebled or disabled by illness or injury. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> 1 of or for invalids. 2 sick, disabled. —v. (-d-) 1 (often foll. By out etc.) Remove (an invalid) from active service. 2 (usu. In passive) disable (a person) by illness. invalidism <strong class='noun'> n. </strong> Invalidityn. [latin: related to *in-1]</p>",
      },
    ],
  },
  {
    word: "Invalid2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invalid2 <strong class='adj'> adj. </strong> Not valid. invalidity n.</p>",
      },
    ],
  },
  {
    word: "Invalidate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invalidate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> make (a claim etc.) Invalid. invalidation n.</p>",
      },
    ],
  },
  {
    word: "Invaluable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invaluable <strong class='adj'> adj. </strong> Above valuation; very valuable. invaluably adv.</p>",
      },
    ],
  },
  {
    word: "Invariable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invariable <strong class='adj'> adj. </strong> 1 unchangeable. 2 always the same. 3 math. Constant. invariably adv.</p>",
      },
    ],
  },
  {
    word: "Invasion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invasion <strong class='noun'> n. </strong> Invading or being invaded.</p>",
      },
    ],
  },
  {
    word: "Invasive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invasive <strong class='adj'> adj. </strong> 1 (of weeds, cancer cells, etc.) Tending to spread. 2 (of surgery) involving large incisions etc. 3 tending to encroach.</p>",
      },
    ],
  },
  {
    word: "Invective",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invective <strong class='noun'> n. </strong> Strong verbal attack. [latin: related to *inveigh]</p>",
      },
    ],
  },
  {
    word: "Inveigh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inveigh <strong class='verb'> v. </strong> (foll. By against) speak or write with strong hostility. [latin invehor -vect- assail]</p>",
      },
    ],
  },
  {
    word: "Inveigle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inveigle <strong class='verb'> v. </strong> (-ling) (foll. By into, or to + infin.) Entice; persuade by guile. inveiglement <strong class='noun'> n. </strong> [anglo-french from french aveugler to blind]</p>",
      },
    ],
  },
  {
    word: "Invent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invent <strong class='verb'> v. </strong> 1 create by thought, originate (a method, device, etc.). 2 concoct (a false story etc.). inventor <strong class='noun'> n. </strong> [latin invenio -vent- find]</p>",
      },
    ],
  },
  {
    word: "Invention",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invention <strong class='noun'> n. </strong> 1 inventing or being invented. 2 thing invented. 3 fictitious story. 4 inventiveness.</p>",
      },
    ],
  },
  {
    word: "Inventive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inventive <strong class='adj'> adj. </strong> Able to invent; imaginative. inventively <strong class='adv'> adv. </strong> Inventiveness n.</p>",
      },
    ],
  },
  {
    word: "Inventory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inventory <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 complete list of goods etc. 2 goods listed in this. —v. (-ies, -ied) 1 make an inventory of. 2 enter (goods) in an inventory. [medieval latin: related to *invent]</p>",
      },
    ],
  },
  {
    word: "Inverse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inverse <strong class='adj'> —adj.</strong> Inverted in position, order, or relation. <strong class='noun'> —n. </strong> 1 inverted state. 2 (often foll. By of) the direct opposite. [latin: related to *invert]</p>",
      },
    ],
  },
  {
    word: "Inverse proportion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inverse proportion <strong class='noun'> n. </strong> (also inverse ratio) relation between two quantities such that one increases in proportion as the other decreases.</p>",
      },
    ],
  },
  {
    word: "Inversion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inversion <strong class='noun'> n. </strong> 1 turning upside down. 2 reversal of a normal order, position, or relation.</p>",
      },
    ],
  },
  {
    word: "Invert",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invert <strong class='verb'> v. </strong> 1 turn upside down. 2 reverse the position, order, or relation of. [latin verto vers- turn]</p>",
      },
    ],
  },
  {
    word: "Invertebrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invertebrate <strong class='adj'> —adj.</strong> (of an animal) not having a backbone. <strong class='noun'> —n. </strong> Invertebrate animal.</p>",
      },
    ],
  },
  {
    word: "Inverted commas",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inverted commas <strong class='n_pl'> n.pl. </strong> = *quotation marks.</p>",
      },
    ],
  },
  {
    word: "Invest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invest <strong class='verb'> v. </strong> 1 a (often foll. By in) apply or use (money), esp. For profit. B (foll. By in) put money for profit into (stocks etc.). 2 (often foll. By in) devote (time etc.) To an enterprise. 3 (foll. By in) <strong class='colloq'> colloq. </strong> Buy (something useful). 4 a (foll. By with) provide or credit (a person etc. With qualities) (invested her with magical importance; invested his tone with irony). B (foll. By in) attribute or entrust (qualities or feelings) to (a person etc.) (power invested in the doctor). 5 (often foll. By with, in) clothe with the insignia of office; install in an office. investor <strong class='noun'> n. </strong> [latin vestis clothing]</p>",
      },
    ],
  },
  {
    word: "Investigate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Investigate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 inquire into; examine. 2 make a systematic inquiry. investigation <strong class='noun'> n. </strong> Investigative adj. Investigator <strong class='noun'> n. </strong> Investigatory adj. [latin vestigo track]</p>",
      },
    ],
  },
  {
    word: "Investiture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Investiture <strong class='noun'> n. </strong> Formal investing of a person with honours or rank. [medieval latin: related to *invest]</p>",
      },
    ],
  },
  {
    word: "Investment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Investment <strong class='noun'> n. </strong> 1 investing. 2 money invested. 3 property etc. In which money is invested.</p>",
      },
    ],
  },
  {
    word: "Investment trust",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Investment trust <strong class='noun'> n. </strong> Trust that buys and sells shares in selected companies to make a profit for its members.</p>",
      },
    ],
  },
  {
    word: "Inveterate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inveterate <strong class='adj'> adj. </strong> 1 (of a person) confirmed in a habit etc. 2 (of a habit etc.) Long-established. inveteracy <strong class='noun'> n. </strong> [latin vetus old]</p>",
      },
    ],
  },
  {
    word: "Invidious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invidious <strong class='adj'> adj. </strong> Likely to cause resentment or anger (invidious position; invidious task). [latin invidiosus: related to *envy]</p>",
      },
    ],
  },
  {
    word: "Invigilate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invigilate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> supervise people taking an exam. invigilation <strong class='noun'> n. </strong> Invigilator <strong class='noun'> n. </strong> [latin: related to *vigil]</p>",
      },
    ],
  },
  {
    word: "Invigorate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invigorate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> give vigour or strength to. invigorating adj. [medieval latin: related to *vigour]</p>",
      },
    ],
  },
  {
    word: "Invincible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invincible <strong class='adj'> adj. </strong> Unconquerable. invincibility <strong class='noun'> n. </strong> Invincibly <strong class='adv'> adv. </strong> [latin vinco conquer]</p>",
      },
    ],
  },
  {
    word: "Inviolable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inviolable <strong class='adj'> adj. </strong> Not to be violated or dishonoured. inviolability <strong class='noun'> n. </strong> Inviolably adv.</p>",
      },
    ],
  },
  {
    word: "Inviolate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inviolate <strong class='adj'> adj. </strong> 1 not violated. 2 safe (from violation or harm). inviolacy n.</p>",
      },
    ],
  },
  {
    word: "Invisible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invisible <strong class='adj'> adj. </strong> Not visible to the eye. invisibility <strong class='noun'> n. </strong> Invisibly adv.</p>",
      },
    ],
  },
  {
    word: "Invisible exports",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invisible exports <strong class='n_pl'> n.pl. </strong> (also invisible imports etc.) Intangible commodities, esp. Services, involving payment between countries.</p>",
      },
    ],
  },
  {
    word: "Invitation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invitation <strong class='noun'> n. </strong> 1 inviting or being invited. 2 letter or card etc. Used to invite.</p>",
      },
    ],
  },
  {
    word: "Invite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invite <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> 1 (often foll. By to, or to + infin.) Ask (a person) courteously to come, or to do something. 2 make a formal courteous request for. 3 tend to call forth unintentionally. 4 a attract. B be attractive. <strong class='noun'> —n. </strong> Colloq. Invitation. [latin invito]</p>",
      },
    ],
  },
  {
    word: "Inviting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inviting <strong class='adj'> adj. </strong> 1 attractive. 2 tempting. invitingly adv.</p>",
      },
    ],
  },
  {
    word: "In vitro",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>In vitro <strong class='adv'> adv. </strong> (of biological processes) taking place in a test-tube or other laboratory environment. [latin, = in glass]</p>",
      },
    ],
  },
  {
    word: "Invocation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invocation <strong class='noun'> n. </strong> 1 invoking or being invoked, esp. In prayer. 2 summoning of supernatural beings, e.g. The muses, for inspiration. 3 eccl. The words ‘in the name of the father’ etc. Used to preface a sermon etc. invocatory adj. [latin: related to *invoke] </p>",
      },
    ],
  },
  {
    word: "Invoice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invoice <strong class='noun'> —n. </strong> Bill for usu. Itemized goods or services. —v. (-cing) 1 send an invoice to. 2 make an invoice of. [earlier invoyes <strong class='pl'> pl. </strong> Of invoy: related to *envoy]</p>",
      },
    ],
  },
  {
    word: "Invoke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invoke <strong class='verb'> v. </strong> (-king) 1 call on (a deity etc.) In prayer or as a witness. 2 appeal to (the law, a person's authority, etc.). 3 summon (a spirit) by charms etc. 4 ask earnestly for (vengeance etc.). [latin voco call]</p>",
      },
    ],
  },
  {
    word: "Involuntary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Involuntary <strong class='adj'> adj. </strong> 1 done without exercising the will; unintentional. 2 (of a muscle) not under the control of the will. involuntarily <strong class='adv'> adv. </strong> Involuntariness n.</p>",
      },
    ],
  },
  {
    word: "Involute",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Involute <strong class='adj'> adj. </strong> 1 involved, intricate. 2 curled spirally. [latin: related to *involve]</p>",
      },
    ],
  },
  {
    word: "Involuted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Involuted <strong class='adj'> adj. </strong> Complicated, abstruse.</p>",
      },
    ],
  },
  {
    word: "Involution",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Involution <strong class='noun'> n. </strong> 1 involving. 2 intricacy. 3 curling inwards. 4 part that curls inwards.</p>",
      },
    ],
  },
  {
    word: "Involve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Involve <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> 1 (often foll. By in) cause (a person or thing) to share the experience or effect (of a situation, activity, etc.). 2 imply, entail, make necessary. 3 (often foll. By in) implicate (a person) in a charge, crime, etc. 4 include or affect in its operations. 5 (as involved adj.) A (often foll. By in) concerned. B complicated in thought or form. C amorously associated. involvement <strong class='noun'> n. </strong> [latin volvo roll]</p>",
      },
    ],
  },
  {
    word: "Invulnerable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Invulnerable <strong class='adj'> adj. </strong> That cannot be wounded, damaged, or hurt, physically or mentally. invulnerability <strong class='noun'> n. </strong> Invulnerably adv.</p>",
      },
    ],
  },
  {
    word: "Inward",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inward <strong class='adj'> —adj.</strong> 1 directed towards the inside; going in. 2 situated within. 3 mental, spiritual. <strong class='adv'> —adv. </strong> (also inwards) 1 towards the inside. 2 in the mind or soul. [old english: related to *in, *-ward]</p>",
      },
    ],
  },
  {
    word: "Inwardly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inwardly <strong class='adv'> adv. </strong> 1 on the inside. 2 in the mind or soul. 3 not aloud.</p>",
      },
    ],
  },
  {
    word: "Inwrought",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Inwrought <strong class='adj'> adj. </strong> 1 (often foll. By with) (of a fabric) decorated (with a pattern). 2 (often foll. By in, on) (of a pattern) wrought (in or on a fabric).</p>",
      },
    ],
  },
  {
    word: "Iodide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iodide <strong class='noun'> n. </strong> Any compound of iodine with another element or group.</p>",
      },
    ],
  },
  {
    word: "Iodine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iodine <strong class='noun'> n. </strong> 1 black crystalline element forming a violet vapour. 2 solution of this as an antiseptic. [french iode from greek iodes violet-like]</p>",
      },
    ],
  },
  {
    word: "Iom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iom <strong class='sing'> abbr. </strong> Isle of man.</p>",
      },
    ],
  },
  {
    word: "Ion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ion <strong class='noun'> n. </strong> Atom or group of atoms that has lost one or more electrons (= *cation), or gained one or more electrons (= *anion). [greek, = going]</p>",
      },
    ],
  },
  {
    word: "-ion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ion <strong class='slang_v'> suffix </strong>(usu. As -sion, -tion, -xion) forming nouns denoting: 1 verbal action (excision). 2 instance of this (a suggestion). 3 resulting state or product (vexation; concoction). [latin -io]</p>",
      },
    ],
  },
  {
    word: "Ionic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ionic <strong class='adj'> adj. </strong> Of the order of greek architecture characterized by a column with scroll-shapes on either side of the capital. [from ionia in greek asia minor]</p>",
      },
    ],
  },
  {
    word: "Ionic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ionic <strong class='adj'> adj. </strong> Of or using ions. ionically adv.</p>",
      },
    ],
  },
  {
    word: "Ionize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ionize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) convert or be converted into an ion or ions. ionization n.</p>",
      },
    ],
  },
  {
    word: "Ionizer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ionizer <strong class='noun'> n. </strong> Device producing ions to improve the quality of the air.</p>",
      },
    ],
  },
  {
    word: "Ionosphere",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ionosphere <strong class='noun'> n. </strong> Ionized region of the atmosphere above the stratosphere, reflecting radio waves. ionospheric adj.</p>",
      },
    ],
  },
  {
    word: "Iota",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iota <strong class='noun'> n. </strong> 1 ninth letter of the greek alphabet (i, i). 2 (usu. With neg.) A jot. [greek iota]</p>",
      },
    ],
  },
  {
    word: "Iou",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iou <strong class='noun'> n. </strong> Signed document acknowledging a debt. [from i owe you]</p>",
      },
    ],
  },
  {
    word: "Iow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iow <strong class='sing'> abbr. </strong> Isle of wight.</p>",
      },
    ],
  },
  {
    word: "Ipa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ipa <strong class='sing'> abbr. </strong> International phonetic alphabet.</p>",
      },
    ],
  },
  {
    word: "Ipecacuanha",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ipecacuanha <strong class='noun'> n. </strong> Root of a s. American shrub, used as an emetic and purgative. [portuguese from s. American indian, = emetic creeper]</p>",
      },
    ],
  },
  {
    word: "Ipso facto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ipso facto <strong class='adv'> adv. </strong> By that very fact. [latin]</p>",
      },
    ],
  },
  {
    word: "Iq",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iq <strong class='sing'> abbr. </strong> Intelligence quotient.</p>",
      },
    ],
  },
  {
    word: "Ir-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ir- <strong class='prefix '> prefix </strong> <strong class='assim'> assim. </strong> Form of *in-1, *in-2 before r.</p>",
      },
    ],
  },
  {
    word: "Ira",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ira <strong class='sing'> abbr. </strong> Irish republican army.</p>",
      },
    ],
  },
  {
    word: "Iranian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iranian <strong class='adj'> —adj.</strong> 1 of iran (formerly persia). 2 of the group of languages including persian. <strong class='noun'> —n. </strong> 1 native or national of iran. 2 person of iranian descent.</p>",
      },
    ],
  },
  {
    word: "Iraqi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iraqi <strong class='adj'> —adj.</strong> Of iraq. <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 a native or national of iraq. B person of iraqi descent. 2 the form of arabic spoken in iraq.</p>",
      },
    ],
  },
  {
    word: "Irascible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irascible <strong class='adj'> adj. </strong> Irritable; hot-tempered. irascibility <strong class='noun'> n. </strong> Irascibly <strong class='adv'> adv. </strong> [latin irascor grow angry, from ira anger]</p>",
      },
    ],
  },
  {
    word: "Irate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irate <strong class='adj'> adj. </strong> Angry, enraged. irately <strong class='adv'> adv. </strong> Irateness <strong class='noun'> n. </strong> [latin iratus from ira anger]</p>",
      },
    ],
  },
  {
    word: "Ire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ire <strong class='noun'> n. </strong> Literary anger. [latin ira]</p>",
      },
    ],
  },
  {
    word: "Iridaceous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iridaceous <strong class='adj'> adj. </strong> Of the iris family of plants.</p>",
      },
    ],
  },
  {
    word: "Iridescent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iridescent <strong class='adj'> adj. </strong> 1 showing rainbow-like luminous colours. 2 changing colour with position. iridescence n.</p>",
      },
    ],
  },
  {
    word: "Iridium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iridium <strong class='noun'> n. </strong> Hard white metallic element of the platinum group.</p>",
      },
    ],
  },
  {
    word: "Iris",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iris <strong class='noun'> n. </strong> 1 circular coloured membrane behind the cornea of the eye, with a circular opening (pupil) in the centre. 2 plant of a family with bulbs or tuberous roots, sword-shaped leaves, and showy flowers. 3 adjustable diaphragm for regulating the size of a central hole, esp. For the admission of light to a lens. [greek iris iridos rainbow]</p>",
      },
    ],
  },
  {
    word: "Irish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irish <strong class='adj'> —adj.</strong> Of ireland or its people. <strong class='noun'> —n. </strong> 1 celtic language of ireland. 2 (prec. By the; treated as pl.) The people of ireland. [old english]</p>",
      },
    ],
  },
  {
    word: "Irish bull",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irish bull <strong class='noun'> n. </strong> = *bull3.</p>",
      },
    ],
  },
  {
    word: "Irish coffee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irish coffee <strong class='noun'> n. </strong> Coffee with a dash of whiskey and a little sugar, topped with cream.</p>",
      },
    ],
  },
  {
    word: "Irishman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irishman <strong class='noun'> n. </strong> Man who is irish by birth or descent.</p>",
      },
    ],
  },
  {
    word: "Irish stew",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irish stew <strong class='noun'> n. </strong> Stew of mutton, potato, and onion.</p>",
      },
    ],
  },
  {
    word: "Irishwoman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irishwoman <strong class='noun'> n. </strong> Woman who is irish by birth or descent.</p>",
      },
    ],
  },
  {
    word: "Irk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irk <strong class='verb'> v. </strong> Irritate, bore, annoy. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Irksome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irksome <strong class='adj'> adj. </strong> Annoying, tiresome. irksomely adv.</p>",
      },
    ],
  },
  {
    word: "Iron",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iron <strong class='noun'> —n. </strong> 1 grey metallic element used for tools and constructions and found in some foods, e.g. Spinach. 2 this as a symbol of strength or firmness (man of iron; iron will). 3 tool made of iron. 4 implement with a flat base which is heated to smooth clothes etc. 5 golf club with an iron or steel sloping face. 6 (usu. In pl.) Fetter. 7 (usu. In pl.) Stirrup. 8 (often in pl.) Iron support for a malformed leg. <strong class='adj'> —adj.</strong> 1 made of iron. 2 very robust. 3 unyielding, merciless. —v. Smooth (clothes etc.) With an iron. iron out remove (difficulties etc.). [old english]</p>",
      },
    ],
  },
  {
    word: "Iron age",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iron age <strong class='noun'> n. </strong> Period when iron replaced bronze in the making of tools and weapons.</p>",
      },
    ],
  },
  {
    word: "Ironclad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironclad <strong class='adj'> —adj.</strong> 1 clad or protected with iron. 2 impregnable. <strong class='noun'> —n. </strong> Hist. Warship protected by iron plates.</p>",
      },
    ],
  },
  {
    word: "Iron cross",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iron cross <strong class='noun'> n. </strong> German military decoration.</p>",
      },
    ],
  },
  {
    word: "Iron curtain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iron curtain <strong class='noun'> n. </strong> Hist. Former notional barrier to the passage of people and information between the soviet bloc and the west.</p>",
      },
    ],
  },
  {
    word: "Ironic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironic <strong class='adj'> adj. </strong> (also ironical) using or displaying irony. ironically adv.</p>",
      },
    ],
  },
  {
    word: "Ironing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironing <strong class='noun'> n. </strong> Clothes etc. For ironing or just ironed.</p>",
      },
    ],
  },
  {
    word: "Ironing-board",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironing-board <strong class='noun'> n. </strong> Narrow folding table on which clothes etc. Are ironed.</p>",
      },
    ],
  },
  {
    word: "Iron in the fire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iron in the fire <strong class='noun'> n. </strong> Undertaking, opportunity (usu. In pl.: too many irons in the fire).</p>",
      },
    ],
  },
  {
    word: "Iron lung",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iron lung <strong class='noun'> n. </strong> Rigid case fitted over a patient's body for administering prolonged artificial respiration.</p>",
      },
    ],
  },
  {
    word: "Ironmaster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironmaster <strong class='noun'> n. </strong> Manufacturer of iron.</p>",
      },
    ],
  },
  {
    word: "Ironmonger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironmonger <strong class='noun'> n. </strong> Dealer in hardware etc. ironmongery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>.</p>",
      },
    ],
  },
  {
    word: "Iron rations",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iron rations <strong class='n_pl'> n.pl. </strong> Small emergency supply of food.</p>",
      },
    ],
  },
  {
    word: "Ironstone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironstone <strong class='noun'> n. </strong> 1 rock containing much iron. 2 a kind of hard white pottery.</p>",
      },
    ],
  },
  {
    word: "Ironware",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironware <strong class='noun'> n. </strong> Articles made of iron.</p>",
      },
    ],
  },
  {
    word: "Ironwork",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironwork <strong class='noun'> n. </strong> 1 things made of iron. 2 work in iron.</p>",
      },
    ],
  },
  {
    word: "Ironworks",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ironworks <strong class='noun'> n. </strong> (as sing. Or pl.) Factory where iron is smelted or iron goods are made.</p>",
      },
    ],
  },
  {
    word: "Irony",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irony <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 expression of meaning, often humorous or sarcastic, using language of a different or opposite tendency. 2 apparent perversity of an event or circumstance in reversing human intentions. 3 theatr. Use of language with one meaning for a privileged audience and another for those addressed or concerned. [greek eironeia pretended ignorance]</p>",
      },
    ],
  },
  {
    word: "Irradiate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irradiate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 subject to radiation. 2 shine upon; light up. 3 throw light on (a subject). irradiation <strong class='noun'> n. </strong> [latin irradio shine on, from radius ray]</p>",
      },
    ],
  },
  {
    word: "Irrational",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irrational <strong class='adj'> adj. </strong> 1 illogical; unreasonable. 2 not endowed with reason. 3 math. Not commensurate with the natural numbers. irrationality <strong class='noun'> n. </strong> Irrationally adv.</p>",
      },
    ],
  },
  {
    word: "Irreconcilable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irreconcilable <strong class='adj'> adj. </strong> 1 implacably hostile. 2 (of ideas etc.) Incompatible. irreconcilability <strong class='noun'> n. </strong> Irreconcilably adv.</p>",
      },
    ],
  },
  {
    word: "Irrecoverable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irrecoverable <strong class='adj'> adj. </strong> Not able to be recovered or remedied. irrecoverably adv.</p>",
      },
    ],
  },
  {
    word: "Irredeemable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irredeemable <strong class='adj'> adj. </strong> 1 not able to be redeemed. 2 hopeless. irredeemably adv.</p>",
      },
    ],
  },
  {
    word: "Irredentist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irredentist <strong class='noun'> n. </strong> Person advocating the restoration to his or her country of any territory formerly belonging to it. irredentism <strong class='noun'> n. </strong> [italian irredenta unredeemed]</p>",
      },
    ],
  },
  {
    word: "Irreducible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irreducible <strong class='adj'> adj. </strong> Not able to be reduced or simplified. irreducibility <strong class='noun'> n. </strong> Irreducibly adv.</p>",
      },
    ],
  },
  {
    word: "Irrefutable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irrefutable <strong class='adj'> adj. </strong> That cannot be refuted. irrefutably adv.</p>",
      },
    ],
  },
  {
    word: "Irregular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irregular <strong class='adj'> —adj.</strong> 1 not regular; unsymmetrical, uneven; varying in form. 2 not occurring at regular intervals. 3 contrary to a rule, principle, or custom; abnormal. 4 (of troops) not belonging to the regular army. 5 (of a verb, noun, etc.) Not inflected according to the usual rules. 6 disorderly. <strong class='noun'> —n. </strong> (in pl.) Irregular troops. irregularity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Irregularly adv.</p>",
      },
    ],
  },
  {
    word: "Irrelevant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irrelevant <strong class='adj'> adj. </strong> <strong class='adj'> (often foll. By to) </strong> not relevant. irrelevance <strong class='noun'> n. </strong> Irrelevancy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>.</p>",
      },
    ],
  },
  {
    word: "Irreligious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irreligious <strong class='adj'> adj. </strong> Lacking or hostile to religion; irreverent.</p>",
      },
    ],
  },
  {
    word: "Irremediable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irremediable <strong class='adj'> adj. </strong> That cannot be remedied. irremediably adv.</p>",
      },
    ],
  },
  {
    word: "Irremovable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irremovable <strong class='adj'> adj. </strong> That cannot be removed. irremovably adv.</p>",
      },
    ],
  },
  {
    word: "Irreparable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irreparable <strong class='adj'> adj. </strong> (of an injury, loss, etc.) That cannot be rectified or made good. irreparably adv.</p>",
      },
    ],
  },
  {
    word: "Irreplaceable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irreplaceable <strong class='adj'> adj. </strong> That cannot be replaced.</p>",
      },
    ],
  },
  {
    word: "Irrepressible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irrepressible <strong class='adj'> adj. </strong> That cannot be repressed or restrained. irrepressibly adv.</p>",
      },
    ],
  },
  {
    word: "Irreproachable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irreproachable <strong class='adj'> adj. </strong> Faultless, blameless. irreproachably adv.</p>",
      },
    ],
  },
  {
    word: "Irresistible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irresistible <strong class='adj'> adj. </strong> Too strong, delightful, or convincing to be resisted. irresistibly adv.</p>",
      },
    ],
  },
  {
    word: "Irresolute",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irresolute <strong class='adj'> adj. </strong> 1 hesitant. 2 lacking in resoluteness. irresolutely <strong class='adv'> adv. </strong> Irresoluteness <strong class='noun'> n. </strong> Irresolution n.</p>",
      },
    ],
  },
  {
    word: "Irrespective",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irrespective <strong class='adj'> adj. </strong> (foll. By of) not taking into account; regardless of.</p>",
      },
    ],
  },
  {
    word: "Irresponsible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irresponsible <strong class='adj'> adj. </strong> 1 acting or done without due sense of responsibility. 2 not responsible for one's conduct. irresponsibility <strong class='noun'> n. </strong> Irresponsibly adv.</p>",
      },
    ],
  },
  {
    word: "Irretrievable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irretrievable <strong class='adj'> adj. </strong> That cannot be retrieved or restored. irretrievably adv.</p>",
      },
    ],
  },
  {
    word: "Irreverent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irreverent <strong class='adj'> adj. </strong> Lacking reverence. irreverence <strong class='noun'> n. </strong> Irreverently adv.</p>",
      },
    ],
  },
  {
    word: "Irreversible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irreversible <strong class='adj'> adj. </strong> Not reversible or alterable. irreversibly adv.</p>",
      },
    ],
  },
  {
    word: "Irrevocable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irrevocable <strong class='adj'> adj. </strong> 1 unalterable. 2 gone beyond recall. irrevocably adv.</p>",
      },
    ],
  },
  {
    word: "Irrigate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irrigate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a water (land) by means of channels etc. B (of a stream etc.) Supply (land) with water. 2 supply (a wound etc.) With a constant flow of liquid. irrigable adj. Irrigation <strong class='noun'> n. </strong> Irrigator <strong class='noun'> n. </strong> [latin rigo moisten]</p>",
      },
    ],
  },
  {
    word: "Irritable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irritable <strong class='adj'> adj. </strong> 1 easily annoyed. 2 (of an organ etc.) Very sensitive to contact. irritability <strong class='noun'> n. </strong> Irritably <strong class='adv'> adv. </strong> [latin: related to *irritate]</p>",
      },
    ],
  },
  {
    word: "Irritant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irritant <strong class='adj'> —adj.</strong> Causing irritation. <strong class='noun'> —n. </strong> Irritant substance.</p>",
      },
    ],
  },
  {
    word: "Irritate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irritate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 excite to anger; annoy. 2 stimulate discomfort in (a part of the body). 3 biol. Stimulate (an organ) to action. irritating adj. Irritation <strong class='noun'> n. </strong> Irritative adj. [latin irrito]</p>",
      },
    ],
  },
  {
    word: "Irrupt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Irrupt <strong class='verb'> v. </strong> (foll. By into) enter forcibly or violently. irruption <strong class='noun'> n. </strong> [latin: related to *rupture]</p>",
      },
    ],
  },
  {
    word: "Is",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Is <strong class='archaic_it_was'> 3rd sing. </strong>Present of *be.</p>",
      },
    ],
  },
  {
    word: "Isbn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isbn <strong class='sing'> abbr. </strong> International standard book number.</p>",
      },
    ],
  },
  {
    word: "-ise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ise <strong class='var'> var. </strong> Of *-ize. <strong class='usage'> Usage:- </strong>see note at -ize.</p>",
      },
    ],
  },
  {
    word: "-ish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ish <strong class='slang_v'> suffix </strong>forming adjectives: 1 from nouns, meaning: a having the qualities of (boyish). B of the nationality of (danish). 2 from adjectives, meaning ‘somewhat’ (thickish). 3 <strong class='colloq'> colloq. </strong> Denoting an approximate age or time of day (fortyish; six-thirtyish). [old english]</p>",
      },
    ],
  },
  {
    word: "Isinglass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isinglass <strong class='noun'> n. </strong> 1 gelatin obtained from fish, esp. Sturgeon, and used in making jellies, glue, etc. 2 mica. [dutch huisenblas sturgeon's bladder]</p>",
      },
    ],
  },
  {
    word: "Islam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Islam <strong class='noun'> n. </strong> 1 the religion of the muslims, proclaimed by muhammad. 2 the muslim world. islamic adj. [arabic, = submission (to god)]</p>",
      },
    ],
  },
  {
    word: "Island",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Island <strong class='noun'> n. </strong> 1 piece of land surrounded by water. 2 = *traffic island. 3 detached or isolated thing. [old english igland; first syllable influenced by *isle]</p>",
      },
    ],
  },
  {
    word: "Islander",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Islander <strong class='noun'> n. </strong> Native or inhabitant of an island.</p>",
      },
    ],
  },
  {
    word: "Isle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isle <strong class='noun'> n. </strong> Poet. (and in place-names) island, esp. A small one. [french ile from latin insula]</p>",
      },
    ],
  },
  {
    word: "Islet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Islet <strong class='noun'> n. </strong> 1 small island. 2 anat. Structurally distinct portion of tissue. [french diminutive of *isle]</p>",
      },
    ],
  },
  {
    word: "Ism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ism <strong class='noun'> n. </strong> Colloq. Usu. Derog. Any distinctive doctrine or practice. [from *-ism]</p>",
      },
    ],
  },
  {
    word: "-ism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ism <strong class='slang_v'> suffix </strong>forming nouns, esp. Denoting: 1 action or its result (baptism; organism). 2 system or principle (conservatism; jingoism). 3 state or quality (heroism; barbarism). 4 basis of prejudice or discrimination (racism; sexism). 5 peculiarity in language (americanism). [greek -ismos]</p>",
      },
    ],
  },
  {
    word: "Isn't",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Isn't <strong class='contr'> contr. </strong> Is not.</p>",
      },
    ],
  },
  {
    word: "Iso-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iso- <strong class='n_pl'> comb. </strong> Form equal. [greek isos equal]</p>",
      },
    ],
  },
  {
    word: "Isobar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isobar <strong class='noun'> n. </strong> Line on a map connecting places with the same atmospheric pressure. isobaric adj. [greek baros weight]</p>",
      },
    ],
  },
  {
    word: "Isochronous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isochronous <strong class='adj'> adj. </strong> 1 occurring at the same time. 2 occupying equal time.</p>",
      },
    ],
  },
  {
    word: "Isolate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isolate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a place apart or alone. B place (a contagious or infectious patient etc.) In quarantine. 2 separate (a substance) from a mixture. 3 insulate (electrical apparatus), esp. By a physical gap; disconnect. isolation <strong class='noun'> n. </strong> [latin insulatus made into an island]</p>",
      },
    ],
  },
  {
    word: "Isolationism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isolationism <strong class='noun'> n. </strong> Policy of holding aloof from the affairs of other countries or groups. isolationist n.</p>",
      },
    ],
  },
  {
    word: "Isomer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isomer <strong class='noun'> n. </strong> One of two or more compounds with the same molecular formula but a different arrangement of atoms. isomeric adj. Isomerism <strong class='noun'> n. </strong> [greek *iso-, meros share]</p>",
      },
    ],
  },
  {
    word: "Isometric",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isometric <strong class='adj'> adj. </strong> 1 of equal measure. 2 (of muscle action) developing tension while the muscle is prevented from contracting. 3 (of a drawing etc.) With the plane of projection at equal angles to the three principal axes of the object shown. [greek isometria equality of measure]</p>",
      },
    ],
  },
  {
    word: "Isomorphic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isomorphic <strong class='adj'> adj. </strong> (also isomorphous) exactly corresponding in form and relations. [from *iso-, greek morphe form]</p>",
      },
    ],
  },
  {
    word: "Isosceles",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isosceles <strong class='adj'> adj. </strong> (of a triangle) having two sides equal. [from *iso-, greek skelos leg]</p>",
      },
    ],
  },
  {
    word: "Isotherm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isotherm <strong class='noun'> n. </strong> Line on a map connecting places with the same temperature. isothermal adj. [from *iso-, greek therme heat]</p>",
      },
    ],
  },
  {
    word: "Isotope",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isotope <strong class='noun'> n. </strong> One of two or more forms of an element differing from each other in relative atomic mass, and in nuclear but not chemical properties. isotopic adj. [from *iso-, greek topos place]</p>",
      },
    ],
  },
  {
    word: "Isotropic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isotropic <strong class='adj'> adj. </strong> Having the same physical properties in all directions. isotropy <strong class='noun'> n. </strong> [from *iso-, greek tropos turn]</p>",
      },
    ],
  },
  {
    word: "Israeli",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Israeli <strong class='adj'> —adj.</strong> Of the modern state of israel. <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 native or national of israel. 2 person of israeli descent. [hebrew]</p>",
      },
    ],
  },
  {
    word: "Israelite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Israelite <strong class='noun'> n. </strong> Hist. Native of ancient israel; jew. [hebrew]</p>",
      },
    ],
  },
  {
    word: "Issue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Issue <strong class='noun'> —n. </strong> 1 a act of giving out or circulating shares, notes, stamps, etc. B quantity of coins, copies of a newspaper, etc., circulated at one time. C each of a regular series of a magazine etc. (the may issue). 2 a outgoing, outflow. B way out, outlet, esp. The place of the emergence of a stream etc. 3 point in question; important subject of debate or litigation. 4 result; outcome. 5 law children, progeny (without male issue). —v. (issues, issued, issuing) 1 literary go or come out. 2 a send forth; publish; put into circulation. B supply, esp. Officially or authoritatively (foll. By to, with: issued passports to them; issued them with passports). 3 a (often foll. By from) be derived or result. B (foll. By in) end, result. 4 (foll. By from) emerge from a condition. at issue under discussion; in dispute. Join (or take) issue (foll. By with a person etc., about, on, over a subject) disagree or argue. [latin exitus: related to *exit]</p>",
      },
    ],
  },
  {
    word: "-ist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ist <strong class='slang_v'> suffix </strong>forming personal nouns denoting: 1 adherent of a system etc. In -ism: (marxist; fatalist). 2 person pursuing, using, or concerned with something as an interest or profession (balloonist; tobacconist). 3 person who does something expressed by a verb in -ize (plagiarist). 4 person who subscribes to a prejudice or practises discrimination (racist; sexist). [greek -istes]</p>",
      },
    ],
  },
  {
    word: "Isthmus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Isthmus <strong class='noun'> n. </strong> (pl. -es) narrow piece of land connecting two larger bodies of land. [greek isthmos]</p>",
      },
    ],
  },
  {
    word: "It",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>It <strong class='sing'> abbr. </strong> Information technology.</p>",
      },
    ],
  },
  {
    word: "It",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>It <strong class='pron'> pron. </strong> ( <strong class='poss'> poss. </strong> Its; <strong class='pl'> pl. </strong> They) 1 thing (or occasionally an animal or child) previously named or in question (took a stone and threw it). 2 person in question (who is it? It is i). 3 as the subject of an impersonal verb (it is raining; it is winter; it is two miles to bath). 4 as a substitute for a deferred subject or object (it is silly to talk like that; i take it that you agree). 5 as a substitute for a vague object (brazen it out). 6 as the antecedent to a relative word or clause (it was an owl that i heard). 7 exactly what is needed. 8 extreme limit of achievement. 9 <strong class='colloq'> colloq. </strong> A sexual intercourse. B sex appeal. 10 (in children's games) player who has to perform a required feat. that's it <strong class='colloq'> colloq. </strong> That is: 1 what is required. 2 the difficulty. 3 the end, enough. [old english]</p>",
      },
    ],
  },
  {
    word: "Italian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Italian <strong class='noun'> —n. </strong> 1 a native or national of italy. B person of italian descent. 2 romance language of italy. <strong class='adj'> —adj.</strong> Of or relating to italy.</p>",
      },
    ],
  },
  {
    word: "Italianate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Italianate <strong class='adj'> adj. </strong> Of italian style or appearance.</p>",
      },
    ],
  },
  {
    word: "Italian vermouth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Italian vermouth <strong class='noun'> n. </strong> Sweet kind of vermouth.</p>",
      },
    ],
  },
  {
    word: "Italic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Italic <strong class='adj'> —adj.</strong> 1 a of the sloping kind of letters now used esp. For emphasis and in foreign words. B (of handwriting) compact and pointed like early italian handwriting. 2 (italic) of ancient italy. <strong class='noun'> —n. </strong> 1 letter in italic type. 2 this type. [latin italicus: related to *italian]</p>",
      },
    ],
  },
  {
    word: "Italicize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Italicize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) print in italics.</p>",
      },
    ],
  },
  {
    word: "Itch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Itch <strong class='noun'> —n. </strong> 1 irritation in the skin. 2 impatient desire. 3 (prec. By the) (in general use) scabies. —v. 1 feel an irritation in the skin. 2 feel a desire to do something (itching to tell you). [old english]</p>",
      },
    ],
  },
  {
    word: "Itching palm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Itching palm <strong class='noun'> n. </strong> Avarice.</p>",
      },
    ],
  },
  {
    word: "Itchy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Itchy <strong class='adj'> adj. </strong> (-ier, -iest) having or causing an itch. have itchy feet <strong class='colloq'> colloq. </strong> 1 be restless. 2 have a strong urge to travel. itchiness n.</p>",
      },
    ],
  },
  {
    word: "It'd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>It'd <strong class='contr'> contr. </strong> Colloq. 1 it had. 2 it would.</p>",
      },
    ],
  },
  {
    word: "-ite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ite <strong class='slang_v'> suffix </strong>forming nouns meaning ‘a person or thing connected with’ (israelite; trotskyite; graphite; dynamite). [greek -ites]</p>",
      },
    ],
  },
  {
    word: "Item",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Item <strong class='noun'> n. </strong> 1 any of a number of enumerated things. 2 separate or distinct piece of news etc. [latin, = in like manner]</p>",
      },
    ],
  },
  {
    word: "Itemize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Itemize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) state item by item. itemization n.</p>",
      },
    ],
  },
  {
    word: "Iterate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iterate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> repeat; state repeatedly. iteration <strong class='noun'> n. </strong> Iterative adj. [latin iterum again]</p>",
      },
    ],
  },
  {
    word: "-itic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-itic <strong class='slang_v'> suffix </strong>forming adjectives and nouns corresponding to nouns in -ite, -itis, etc. (semitic; arthritic). [latin -iticus, greek -itikos]</p>",
      },
    ],
  },
  {
    word: "Itinerant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Itinerant <strong class='adj'> —adj.</strong> Travelling from place to place. <strong class='noun'> —n. </strong> Itinerant person. [latin iter itiner- journey]</p>",
      },
    ],
  },
  {
    word: "Itinerary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Itinerary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 detailed route. 2 record of travel. 3 guidebook.</p>",
      },
    ],
  },
  {
    word: "-itis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-itis <strong class='slang_v'> suffix </strong>forming nouns, esp.: 1 names of inflammatory diseases (appendicitis). 2 <strong class='colloq'> colloq. </strong> With ref. To conditions compared to diseases (electionitis). [greek]</p>",
      },
    ],
  },
  {
    word: "It'll",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>It'll <strong class='contr'> contr. </strong> Colloq. It will; it shall.</p>",
      },
    ],
  },
  {
    word: "Its",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Its <strong class='poss'> poss. </strong> <strong class='pron'> Pron. </strong> Of it; of itself.</p>",
      },
    ],
  },
  {
    word: "It's",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>It's <strong class='contr'> contr. </strong> 1 it is. 2 it has.</p>",
      },
    ],
  },
  {
    word: "Itself",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Itself <strong class='pron'> pron. </strong> Emphatic and refl. Form of *it. be itself see *oneself. By itself see by oneself. In itself viewed in its essential qualities (not in itself a bad thing). [old english: related to *it, *self]</p>",
      },
    ],
  },
  {
    word: "Itv",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Itv <strong class='sing'> abbr. </strong> Independent television.</p>",
      },
    ],
  },
  {
    word: "-ity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ity <strong class='n_pl'>  <strong class='see'> <strong class='see'>suffix forming nouns denoting:</strong></strong> </strong> 1 quality or condition (humility; purity). 2 instance of this (monstrosity). [latin -itas]</p>",
      },
    ],
  },
  {
    word: "Iud",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Iud <strong class='sing'> abbr. </strong> Intrauterine (contraceptive) device.</p>",
      },
    ],
  },
  {
    word: "I've",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>I've <strong class='contr'> contr. </strong> I have.</p>",
      },
    ],
  },
  {
    word: "-ive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ive <strong class='slang_v'> suffix </strong>forming adjectives meaning ‘tending to’, and corresponding nouns (suggestive; corrosive; palliative). [latin -ivus]</p>",
      },
    ],
  },
  {
    word: "Ivf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ivf <strong class='sing'> abbr. </strong> In vitro fertilization.</p>",
      },
    ],
  },
  {
    word: "Ivory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ivory <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 hard substance of the tusks of an elephant etc. 2 creamy-white colour of this. 3 (usu. In pl.) A article made of ivory. B slang thing made of or resembling ivory, esp. A piano key or a tooth. [latin ebur]</p>",
      },
    ],
  },
  {
    word: "Ivory tower",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ivory tower <strong class='noun'> n. </strong> Seclusion or withdrawal from the harsh realities of life (often attrib.: ivory tower professors).</p>",
      },
    ],
  },
  {
    word: "Ivy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ivy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> climbing evergreen shrub with shiny five-angled leaves. [old english]</p>",
      },
    ],
  },
  {
    word: "-ize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ize <strong class='slang_v'> suffix </strong>(also -ise) forming verbs, meaning: 1 make or become such (americanize; realize). 2 treat in such a way (monopolize; pasteurize). 3 a follow a special practice (economize). B have a specified feeling (sympathize). -ization suffix forming nouns. [greek -izo] <strong class='usage'> Usage:- </strong>the form -ize has been in use in english since the 16th c.; it is widely used in american english, but is not an americanism. The alternative spelling -ise (reflecting a french influence) is in common use, esp. In british english, and is obligatory in certain cases: (a) where it forms part of a larger word-element, such as -mise (= sending) in compromise, and -prise (= taking) in surprise; and (b) in verbs corresponding to nouns with -s- in the stem, such as advertise and televise.</p>",
      },
    ],
  },
];

export const alphabet_I_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मैं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>I1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>I2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>I3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>I4</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-ल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इम्बिक प्रोसोडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इम्बस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-योन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईबीए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>औबेरियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>औबेक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ibid।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-बिलता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार की पक्षी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-तो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईबीओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-I C</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-संबंधी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिम युग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फ के लिए कुदाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिमशैल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिमशैल सलाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीला बर्फ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिमीकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइस ब्रेकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फ बाल्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फ की टोपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइसक्रीम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फ़ के छोटे टुकड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फ क्षेत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइस हॉकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइसलैंड वासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइसलैंड का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फ की रोटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइस पैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिमशैल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फ का पौधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइस स्केटिंग रिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फ पर स्केट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुड़सवार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इहतीओलोगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इहतीओजास्र्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-सियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिमलंब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टुकड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बारीक चीनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइकन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शास्त्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इकोनोस्टेसिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विंशतिफलक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-सि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ठंडा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-ड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आदर्श</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आदर्शवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आदर्श बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आदर्श रूप में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Idée fixe</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सदृश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचान परेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचानना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिनाख्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इदेओग्राम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इदेओग्राफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचारक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचारधारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इडस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्खता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुहावरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुहावरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेवकूफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निठल्ला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ति पूजक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देवता मानना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुखद जीवन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुखद जीवन का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अर्थात।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-अर्थात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यदि -</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Iffy</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईग्बो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इग्लू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आतशी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रज्वलित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इग्निशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदनामी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अज्ञान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनजान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नज़रअंदाज़ करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गोधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इगु़नोडोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इकेबाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइकॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Il-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लघ्वान्त्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इलेक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फुंफरे के नीचे का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैसे लोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमार मिश्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़राब ढंग से परिभाषित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैरकानूनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवैध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मनहूस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुरूप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेबुनियाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेईमानी से मिला हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमार स्वास्थ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्य हास्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवैध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरक्षर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विसंगत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभागा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिड़चिड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमार समय पर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमार का इलाज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्पष्ट करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरा व्यवहार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>माया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जादूगर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदाहरण देकर स्पष्ट करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चित्रण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदाहराणदर्शक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शानदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मैं हूँ-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मैं हूँ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छवि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कल्पना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काल्पनिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कल्पना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कल्पनाशील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कल्पना करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईमागौ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईमाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंतुलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैठाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पी लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उलझन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रंगना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्राष्ट्रीय मुद्रा कोष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नकल करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नकल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कृत्रिम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्मल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>त्रुटिहीन गर्भाधान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरंतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमूर्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिपक्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत बड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुरंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुरंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुरातन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अत्यधिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेहद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तल्लीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विसर्जन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निमज्जन तापक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आप्रवासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परदेश में बसना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>करीब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मिलाने के लिए योग्य नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अत्यंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्लज्ज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यज्ञ का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनैतिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिरक्षा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोग प्रतिरोधक क्षमता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छुटकारा देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इम्यूनो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इम्यूनोग्लोबुलिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इम्मुनोलोगि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>immunotherapy</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उछालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अडिग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भंग करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिगाड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इम्पाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोंचना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पृश्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धरना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्पक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गतिरोध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भावपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवेगहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इम्पैस्टो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इम्पैटेंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेताब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोषी ठहराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>त्रुटिहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेचारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुक़ाबला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाधा डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हकलाहट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्ररित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समीप आ जाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभेद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिवार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगोचर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपूर्णता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साम्राज्यवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संकट में डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविनाशी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनस्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभेद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाजायज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवैयक्तिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभिनय करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभेद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविचलित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रबल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवेकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रेरणा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नास्तिकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टकराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेईमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शरारती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संगदिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रत्यारोपण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकल्पनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमल में लाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फंसाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निहितार्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्निहित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रार्थना करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मतलब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हलका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महत्वपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हठी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिद करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोपना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभावशाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आरोपण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंभव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Impost1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Impost2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कपटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पाखंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नपुंसक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ब्त करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शक्तिहीन करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यवहार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यवहारिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिश्चित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभेद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्याप्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईम्प्रेस्सारिओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभावित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभाव जमाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभावित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभाववाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभावशाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इजाज़त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छाप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंदी बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंभव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना पहले सोचे हुए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित अंश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनौचित्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुधार करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उड़ाऊ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुधारने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढीठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिलेर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहस करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवेग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियोजित खरीदारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवेग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवेगशील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दण्ड मुक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशुद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपवित्रता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मढ़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>में।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहले में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मे २</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमर्थता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इसकी अनुपस्थिति में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पर पहुंच-योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ग़लत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्क्रियता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्क्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपर्याप्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वीकार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेपरवाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेअदब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविच्छेद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनमोराटो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेहूदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचेतन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुपयुक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धीरे से बोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यद्यपि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अश्राव्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्घाटन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>का उद्घाटन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>के बीच में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भीतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्मजात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्मजात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतरिक प्रजनन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्निर्मित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेहिसाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लहकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गरमागरम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जादू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अक्षम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशक्त करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अक्षमता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क़ैद कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवतार लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवतार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असावधान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपदेशक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धूप 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धूप 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धूप 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रोत्साहन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आरंभ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आरंभ का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लगातार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कौटुम्बिक व्यभिचार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईन्सेस्त का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इब्तिदाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आकस्मिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयोग से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रासंगिक संगीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जला देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रीमेटोरिअम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्पन्न होनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काटकर अलग कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चीरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काटा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छेनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तेजित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कटुता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कठोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झुकाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इच्छा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इच्छुक विमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शामिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सहित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुप्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भेस बदले हुए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेतुका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न जलने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आने वाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तारतम्यहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेडौल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यवस्था</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असुविधाजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बयान से बाहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सम्पर्क-वर्जित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकांतप्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेमिसाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंगत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अक्षम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधूरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समझ से बाहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अज्ञेयत्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समझ से बाहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुविधा में पड़ा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेमेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंगत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रमरहित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचारशून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवेकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंगत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गमगीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगोचर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्विवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंयमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुंहतोड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असुविधाजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असुविधाजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाविष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निराकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ग़लत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंशोधनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईमानदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बढ़ोतरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तेजी से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविश्वसनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विश्वास न होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेतन वृद्धि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोषी ठहराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईन्कस्र्स्तैषेण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेते</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इन्क्यूबेशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अण्डे सेने की मशीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईन्कुबुस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मन में बैठाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पदग्राही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्भर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपने ऊपर लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचारशून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चढ़ाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झुका देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऋणी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित प्रयास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभद्र प्रदर्शन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवेकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमंजस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुविधा में पड़ा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वास्तव में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अथक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविभाज्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमर्थनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिभाष्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिश्चितकालीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिश्चितकालीन लेख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिश्चित काल के लिए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमा कराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हानि से सुरक्षा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मांगपत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खरोज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दांतेदार बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ठीका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वतंत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>में गहराई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवर्णनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अक्षय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुविधा में पड़ा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुविधा में पड़ा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिश्चित स्वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुक्रमणिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तर्जनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचकांक से जुड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भारतीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंडियन मकई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भारतीय हाथी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भारतीय संचिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भारतीय गांजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भारतीय स्याही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भारत की गर्मीया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भारत रबड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संकेत देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचकांकों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभियोग लगाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभ्यारोप्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभियोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदासीनता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदासीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देशज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दरिद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपचनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खट्टी डकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रोधित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्यक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्यक्ष वस्तु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्यक्ष सवाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्यक्ष भाषण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्यक्ष कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्यक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुशासनहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असावधानीपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवेक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवेकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिहार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्विवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थायी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवेच्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सृजना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईण्डीयुम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यक्तिगत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यक्तिवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यक्तित्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकेला होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यक्तिगत रूप से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभाज्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भारत-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राज़ी करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भारोपीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकर्मण्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घर के अंदर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घर के अंदर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुष्टि करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खिन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ाहिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रेरित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रलोभन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिष्ठापित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधिष्ठापन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रवेश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधिष्ठापन का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रारंभ करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकाया में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिप्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आसक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कृपालु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>औद्योगिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>औद्योगिक कार्यकलाप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>औद्योगिक संपदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्योगवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्योगपति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>औद्योगिक करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>औद्योगिक संबंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मेहनती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्योग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-इन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मतवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखाद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपढ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रभावी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रभावी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रभावी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असजीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिवार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्यायी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमानता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्यायी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्याय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जड़ से न उखाड़ने येग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्क्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जड़ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जड़ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जड़ता विक्रेता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिवार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नगण्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विशाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिहार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशुद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अक्षम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अटूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्ठुर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेमतलब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सस्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुभव का हीनता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्याख्येय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकथनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकथनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सदा जलानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आखिर में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विकट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदनाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिशु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिशु हत्या</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिशु-संबंधी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिशु पक्षाघात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पैदल सेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पैदल सेनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोधगलितांश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तेजित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संक्रमित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संक्रमण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संक्रामक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंगति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तर्क करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हीनता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हीन भावना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राक्षसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नरक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुपजाऊ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हमला करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेवफ़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेवफ़ाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खेत मेँ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंदरूनी कलह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संक्रमित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुसपैठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुसपैठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत छोता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रिया के साधारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनंतता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमजोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुर्बलता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुर्बलता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इन्फ़िक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फ्लैग्रेन्टे डेलिक्टो में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भड़काना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज्वलनशील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूजन और जलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भड़काऊ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>inflatable</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बढ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुद्रा स्फ़ीति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बांका करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मोड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मोड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दण्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनफ्लाइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फूलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रभावशाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंफ्लुएंजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तांता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जानकारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बताना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनौपचारिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचना देनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जानकारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचना की पुनर्प्राप्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचान प्रौद्योगिकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जानकारीपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचित किया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचना देनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुनियादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुनियादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अतिक्रमण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इन्फ्रा डिग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवरक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आधारभूत संरचना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निराला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>का उल्लंघन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रुद्ध करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पानी में डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जान फूंकने का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आसव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-ing1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-ing2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सरलता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सरलता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सदाबहार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निगलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Inglenook</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लज्जाजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दाखिला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनगट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टीका लगा देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दीर्घस्थायी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुग्रहभाजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कृतघ्नता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घटक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रवेश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुदी तसवीर की छाप का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंघास का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निवास करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निवासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>inhalant</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साँस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साँस लेनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहाँ पर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्निहित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनहेरिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विरासत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वंशानुक्रम कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निषेध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असत्कारशील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>में-घर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमानवीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमानवीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विरोधी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनोखी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शुरुआती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रारंभिक पत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आरंभ करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंजेक्षन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचारशून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निषेधाज्ञा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घायल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोटिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हानिकारक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घायल के कारण दिया हुआ अधिक समय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्याय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्याही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आभास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दवात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्याही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोशनाई पोता हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनलैंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्देशीय राजस्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ससुराल वाले</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रवेश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोको पेरेंटिस में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कैदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक मृत व्यक्ति की स्मृति में लिखा मृत्युलेख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधिकांश में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सराय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धर्मशाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्मजात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भीतरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भीतरी शहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भीतरी आदमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भीतरी नली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सराय का मालिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मासूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहानिकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदालत का सराय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इन्युएन्दो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सराय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंख्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंग करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरापद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्क्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेवक़्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असामान्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकार्बनिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधैर्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनपुट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानूनी जांच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गड़बड़ाहट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूछताछ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाँच करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यायिक जांच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिज्ञासु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिज्ञासा दिखानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिज्ञासु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूछताछ करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इसके कानूनी मामले में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंट्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दखलंदाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आनन फानन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपध्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पागल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वास्थ्यकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लालची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लालची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिलालेख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गूढ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कीड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कीटनाशक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कीटनाशक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असुरक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेसुध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेसुध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेसुध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवियोज्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रविष्टि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेवा में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनसेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नदी के किनारे पर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंदर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंदर की जानकारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंदर का काम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंदरूनी सूत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंद्रूनी लेन - देन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कपटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्दृष्टि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिल्ला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुच्छ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्ठाहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इशारा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फीका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ोर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आग्रहपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बगल में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मद्यपान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जहां तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धूप में सुखाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदतमीज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अघुलनशील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिवालिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिद्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इन्सोम्नियाक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इस हद तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>insouciant</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरीक्षण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरीक्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कर -निरीक्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रेरणा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रेरित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्ररेरित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदाहरण।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थिरता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थापित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किश्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किश्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदाहरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुरंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तात्कालिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हाथों हाथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बजाय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इस आकार की वस्तु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भड़काना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उकसाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उछालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वाभाविक प्रवृत्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संस्था</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संस्थान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संस्थागत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संस्थागत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिदायत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुदेश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिक्षाप्रद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाद्य यंत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सहायक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वादक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साधन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपकरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्धत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असाध्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दम्भी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाकाफी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्वीपीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बचाने के</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंसुलिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपमान करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अजेय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असहनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ठीक कर लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमा कंपनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विद्रोही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुर्गम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विद्रोह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असुरक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुड़ा रहना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रवेश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमूर्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूर्णांक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभिन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाकलन गणित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकीकृत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकीकृत परिपथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखंडता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झिल्ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्धि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बौद्धिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्धिमत्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्धिलब्धि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्धिमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्धिजीवीवर्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुगम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंयमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इरादा रखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभीष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गहन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>intensifier</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तीव्रता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गहन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गहन देखभाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इरादा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इरादा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जान-बूझकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटरैक्ट करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटरैक्टिव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिवारों के बीच का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतरंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्षा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवरोधन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिमायत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेन-देन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनिमय करने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर-शहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इण्टरकॉम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्र-व्यवहार करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक दूसरे का संबंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संबंध रखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महाद्वीपीयों के बीच का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संभोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर -संबंधी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाखाओं के बीच का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्योन्याश्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पाबंदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतःविषय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रुचि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिलचस्प</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटरफेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटरफ़ेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हस्तक्षेप करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दखल अंदाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटरफेरॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मिलना-जुलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतरिक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्तरिम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतरिक भाग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भीतरी सजावट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतरिक सज्जा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकाएक बीच में बोल उठना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विस्मयादिबोधक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिल्द बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकसर इस्तेमाल करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुदा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लगाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आलिंगन करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वार्ताकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुलकात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुसपैठिये</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्तराल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोटी बेटी का व्यवहार करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मध्यस्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मध्यवर्ती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दफ़न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटेरमेस्सो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मिलाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विराम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रुक-रुक कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मिश्रित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नजरबंद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतरिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतरिक दहन इंजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतरिक साक्ष्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतरराष्ट्रीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्राष्ट्रीयवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्राष्ट्रीयकरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतरिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आपसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटर्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ग्रहों तक चलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पारस्परिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ग्रहों के बीच का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परस्पर क्रिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटरपोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैठाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्याख्या करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुभाषिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतरजातीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दो राजाए के भीतर समय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परस्पर संबंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूछताछ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रश्नवाचक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूछताछ करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रुकावट डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाधा डालनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटरसेक्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौराहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिखेरना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतरराज्यीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तारे के बीच का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्तराल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मध्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक दूसरे से लिपटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मध्यान्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हस्तक्षेप करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हस्तक्षेप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीच में आनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साक्षात्कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतरयुद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक दूसरे से लिपटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैठाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्याख्या करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुभाषिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतरजातीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दो राजाए के भीतर समय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परस्पर संबंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूछताछ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रश्नवाचक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूछताछ करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रुकावट डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाधा डालनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंटरसेक्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौराहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिखेरना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतरराज्यीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तारे के बीच का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्तराल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मध्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक दूसरे से लिपटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मध्यान्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हस्तक्षेप करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हस्तक्षेप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीच में आनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साक्षात्कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतरयुद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक दूसरे से लिपटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना वसीयतनामा मारा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंतिफादा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आत्मीयता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Intimate1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Intimate2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धमकाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न सहने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असहिष्णु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवाज़ का उतार-चढ़ाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ोर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नशा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नशा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंदर का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंट्रामस्क्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सैद्धांतिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकर्मक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्गर्भाशयी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नसों में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ट्रे में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जटिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साज़िश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतरिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंट्रो-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिचय देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिचय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिचयात्मक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुसपैठ करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आत्मनिरीक्षण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्मुखी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुसेड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुसेड़नेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अतिक्रमण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देख-रेख में रखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतर्ज्ञान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सहज ज्ञान युक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनुइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पाट देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तपाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आक्रमण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Invalid1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Invalid2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमान्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमूल्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आक्रमण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनवेसिव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फटकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आक्रमण करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुभाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आविष्कार करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आविष्कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आविष्कारशील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भंडार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्लोक में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उलटा अनुपात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उलट देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>औंधाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकशेरुकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवतरण चिन्ह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निवेश करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाँच करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संस्कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निवेश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निवेश मे भरोसा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कट्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपमानजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंग करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>का लाभ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अजेय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पवित्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखण्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदृश्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदृश्य निर्यात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आमंत्रण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आमंत्रित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आमंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कृत्रिम परिवेशीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मंगलाचरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनवॉइस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आह्वान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनैच्छिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उलझा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संलग्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पेचीदगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शामिल होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभेद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतरिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंदर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सजा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योडिद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयोडीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईओएम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईओण का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईओण का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योण बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ionizer</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योण क्षेत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>IOW</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईपीए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का घास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ipso facto</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्धि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईआर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईरानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इराक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रुद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रोध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इरीडेस से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इंद्रधनुषी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इरिडियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आँख की पुतली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयरिश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयरिश बुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयरिश कॉफी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयलैंडवासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइरिश खिचड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयलैंडवासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सताना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लौह युग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बख़्तरबंद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहे के पार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहे का परदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहे का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इस्त्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इस्त्री करने का बोर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आग में लोहे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहे का फेफड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आयरनमास्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दूकनदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहे की राशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहे का पत्थर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहे का बरतन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कच्चे लोहे का ढलाई खाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कच्चे लोहे का ढलाई खाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विडंबना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमकाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तर्कहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कट्टर विरोधी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रतिलभ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपूरणीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इरिडैंटिस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलघुकरणीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखंडनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियमित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रासंगिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधार्मिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाइलाज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपूरणीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सदा एकसां</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहुंच से बाहर का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अथक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डगमग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरपेक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर जिम्मेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविभाज्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेअदब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सिंचाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिड़चिड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तेजक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिढ़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईएसबीएन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-ish</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभ्रक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इसलाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्वीप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्वीप पर रहनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टापू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटा सा टाप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-इस्मे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नहीं है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईएसओ-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समताप-रेखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समकालिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पृथकतावाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइसोमर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सममितीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइसोमॉर्फिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समद्वाल्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इज़ोटेर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइसोटोप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समदैशिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इजरायल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इस्राएली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुद्दा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-वादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयोग भूमि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इतालवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इतालवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इटालियन वर्माउथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तिरछा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तिरछे अक्षर लिखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खुजली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खुजली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खुजलीदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यह होगा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-ते</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वस्तु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गिनना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोहराएं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चलनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यात्रा कार्यक्रम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-यह है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यह होगा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इसका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यह है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपने आप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईटीवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्पसंख्यक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईयूडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मैंने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-मैंने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आईवीएफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हाथी दांत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइवरी टॉवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइवी लता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-ज़</p>",
  },
];
