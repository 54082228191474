export const alphabet_Y = [
  {
    word: "Y",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p> </p>",
      },
    ],
  },
  {
    word: "Y1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Y1 <strong class='noun'> n. </strong> (also y) (pl. Ys or y's) 1 twenty-fifth letter of the alphabet. 2 (usu. Y) algebra second unknown quantity. 3 y-shaped thing.</p>",
      },
    ],
  },
  {
    word: "Y2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Y2 <strong class='prep'> symb. </strong> Yttrium.</p>",
      },
    ],
  },
  {
    word: "-y1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-y1 <strong class='slang_v'> suffix </strong>forming adjectives: 1 from nouns and adjectives, meaning: a full of; having the quality of (messy). B addicted to (boozy). 2 from verbs, meaning ‘inclined to’, ‘apt to’ (sticky). [old english]</p>",
      },
    ],
  },
  {
    word: "-y2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-y2 <strong class='slang_v'> suffix </strong>(also -ey, -ie) forming diminutive nouns, pet names, etc. (granny; sally; nightie). [originally scottish]</p>",
      },
    ],
  },
  {
    word: "-y3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-y3 <strong class='slang_v'> suffix </strong>forming nouns denoting state, condition, or quality (orthodoxy). [latin -ia, greek -eia]</p>",
      },
    ],
  },
  {
    word: "Yacht",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yacht <strong class='noun'> —n. </strong> 1 light sailing-vessel. 2 larger usu. Power-driven vessel for cruising. —v. Race or cruise in a yacht. [dutch jaghtschip, literally ‘pursuit-ship’]</p>",
      },
    ],
  },
  {
    word: "Yachtsman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yachtsman <strong class='noun'> n. </strong> (fem. Yachtswoman) person who sails yachts.</p>",
      },
    ],
  },
  {
    word: "Yack  slang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yack slang <strong class='noun'> —n. </strong> Trivial or unduly persistent conversation. —v. Engage in this. [imitative]</p>",
      },
    ],
  },
  {
    word: "Yah",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yah <strong class='n_pl'> int. </strong> (also yah boo) expressing derision or defiance. [imitative]</p>",
      },
    ],
  },
  {
    word: "Yahoo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yahoo <strong class='noun'> n. </strong> Bestial person. [name of a race of brutes in gulliver's travels]</p>",
      },
    ],
  },
  {
    word: "Yahweh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yahweh <strong class='noun'> n. </strong> (also yahveh) = *jehovah.</p>",
      },
    ],
  },
  {
    word: "Yak",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yak <strong class='noun'> n. </strong> Long-haired tibetan ox. [tibetan]</p>",
      },
    ],
  },
  {
    word: "Yale lock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yale lock <strong class='noun'> n. </strong> Propr. Type of lock with a revolving barrel, used for doors etc. [yale, name of its inventor]</p>",
      },
    ],
  },
  {
    word: "Yam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yam <strong class='noun'> n. </strong> 1 a tropical or subtropical climbing plant. B edible starchy tuber of this. 2 us sweet potato. [portuguese or spanish]</p>",
      },
    ],
  },
  {
    word: "Yammer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yammer <strong class='colloq'> colloq. </strong> Or dial. <strong class='noun'> —n. </strong> 1 lament, wail, grumble. 2 voluble talk. —v. Utter a yammer. [old english]</p>",
      },
    ],
  },
  {
    word: "Yang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yang <strong class='noun'> n. </strong> (in chinese philosophy) the active male principle of the universe (cf. *yin).</p>",
      },
    ],
  },
  {
    word: "Yank",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yank <strong class='noun'> n. </strong> Colloq. Often derog. American. [abbreviation of *yankee]</p>",
      },
    ],
  },
  {
    word: "Yank",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yank <strong class='verb'> v. </strong> & <strong class='noun'> n. </strong> Colloq. Pull with a jerk. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Yankee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yankee <strong class='noun'> n. </strong> Colloq. 1 often derog. = *yank. 2 us inhabitant of new england or of the northern states. [origin uncertain: perhaps from dutch janke, diminutive of jan john, as a nickname]</p>",
      },
    ],
  },
  {
    word: "Yap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yap <strong class='noun'> —v. </strong> (-pp-) 1 bark shrilly or fussily. 2 <strong class='colloq'> colloq. </strong> Talk noisily, foolishly, or complainingly. <strong class='noun'> —n. </strong> Sound of yapping. yappy adj. (-ier, -iest) in sense 1 of v. [imitative]</p>",
      },
    ],
  },
  {
    word: "Yarborough",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yarborough <strong class='noun'> n. </strong> Whist or bridge hand with no card above a 9. [earl of yarborough, said to have betted against it]</p>",
      },
    ],
  },
  {
    word: "Yard1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yard1 <strong class='noun'> n. </strong> 1 unit of linear measure (3 ft., 0.9144 metre). 2 this length of material. 3 square or cubic yard. 4 spar slung across a mast for a sail to hang from. 5 (in pl.; foll. By of) <strong class='colloq'> colloq. </strong> A great length. [old english, = stick]</p>",
      },
    ],
  },
  {
    word: "Yard2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yard2 <strong class='noun'> n. </strong> 1 piece of enclosed ground, esp. Attached to a building or used for a particular purpose. 2  <strong class='archaic_it_was'>Us & austral.</strong> Garden of a house. [old english, = enclosure]</p>",
      },
    ],
  },
  {
    word: "Yardage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yardage <strong class='noun'> n. </strong> Number of yards of material etc.</p>",
      },
    ],
  },
  {
    word: "Yard-arm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yard-arm <strong class='noun'> n. </strong> Either end of a ship's yard.</p>",
      },
    ],
  },
  {
    word: "Yardie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yardie <strong class='noun'> n. </strong> Slang member of a jamaican or w. Indian gang engaging in organized crime, esp. Drug-trafficking. [jamaican english, = house, home]</p>",
      },
    ],
  },
  {
    word: "Yardstick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yardstick <strong class='noun'> n. </strong> 1 standard of comparison. 2 measuring rod a yard long, usu. Divided into inches etc.</p>",
      },
    ],
  },
  {
    word: "Yarmulke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yarmulke <strong class='noun'> n. </strong> (also yarmulka) skullcap worn by jewish men. [yiddish]</p>",
      },
    ],
  },
  {
    word: "Yarn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yarn <strong class='noun'> —n. </strong> 1 spun thread, esp. For knitting, weaving, etc. 2 <strong class='colloq'> colloq. </strong> Story, traveller's tale, anecdote. —v. Colloq. Tell yarns. [old english]</p>",
      },
    ],
  },
  {
    word: "Yarrow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yarrow <strong class='noun'> n. </strong> Perennial plant, esp. Milfoil. [old english]</p>",
      },
    ],
  },
  {
    word: "Yashmak",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yashmak <strong class='noun'> n. </strong> Veil concealing the face except the eyes, worn by some muslim women. [arabic]</p>",
      },
    ],
  },
  {
    word: "Yaw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yaw <strong class='noun'> —v. </strong> (of a ship or aircraft etc.) Fail to hold a straight course; go unsteadily. <strong class='noun'> —n. </strong> Yawing of a ship etc. From its course. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Yawl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yawl <strong class='noun'> n. </strong> A kind of ship's boat or sailing- or fishing-boat. [low german jolle or dutch jol]</p>",
      },
    ],
  },
  {
    word: "Yawn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yawn <strong class='noun'> —v. </strong> 1 open the mouth wide and inhale, esp. When sleepy or bored. 2 gape, be wide open. <strong class='noun'> —n. </strong> 1 act of yawning. 2 <strong class='colloq'> colloq. </strong> Boring idea, activity, etc. [old english]</p>",
      },
    ],
  },
  {
    word: "Yaws",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yaws <strong class='n_pl'> n.pl. </strong> (usu. Treated as sing.) Contagious tropical skin-disease with large red swellings. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Yb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Yb <strong class='prep'> symb. </strong> Ytterbium.</p>",
      },
    ],
  },
  {
    word: "Y chromosome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Y chromosome <strong class='noun'> n. </strong> (in humans and some other mammals) sex chromosome occurring only in male cells. [y as an arbitrary label]</p>",
      },
    ],
  },
  {
    word: "Yd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yd <strong class='sing'> abbr. </strong> (pl. Yds) yard (measure).</p>",
      },
    ],
  },
  {
    word: "Ye1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ye1 <strong class='pron'> pron. </strong> Archaic <strong class='pl'> pl. </strong> Of *thou1 (ye gods!).</p>",
      },
    ],
  },
  {
    word: "Ye2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ye2 <strong class='adj'> adj. </strong> Pseudo-archaic = *the (ye olde tea-shoppe). [from the obsolete y-shaped letter for th]</p>",
      },
    ],
  },
  {
    word: "Yea  archaic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yea archaic <strong class='adv'> —adv. </strong> 1 yes. 2 indeed (ready, yea eager). <strong class='noun'> —n. </strong> Utterance of ‘yea’; ‘yes’ vote. [old english]</p>",
      },
    ],
  },
  {
    word: "Yeah",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yeah <strong class='adv'> adv. </strong> Colloq. Yes. [a casual pronunciation of *yes]</p>",
      },
    ],
  },
  {
    word: "Year",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Year <strong class='noun'> n. </strong> 1 time occupied by the earth in one revolution round the sun, approx. 3651/4 days. 2 = *calendar year. 3 period of twelve months, starting at any point (four years ago; tax year). 4 (in pl.) Age, time of life (young for his years). 5 (usu. In pl.) Colloq. Very long time. 6 group of students entering college etc. In the same academic year. [old english]</p>",
      },
    ],
  },
  {
    word: "Yearbook",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yearbook <strong class='noun'> n. </strong> Annual publication dealing with events or aspects of the (usu. Preceding) year.</p>",
      },
    ],
  },
  {
    word: "Yearling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yearling <strong class='noun'> n. </strong> Animal between one and two years old.</p>",
      },
    ],
  },
  {
    word: "Yearly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yearly <strong class='adj'> —adj.</strong> 1 done, produced, or occurring once a year. 2 of or lasting a year. <strong class='adv'> —adv. </strong> Once a year.</p>",
      },
    ],
  },
  {
    word: "Yearn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yearn <strong class='verb'> v. </strong> Be filled with longing, compassion, or tenderness. yearning <strong class='noun'> n. </strong> & adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Yeast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yeast <strong class='noun'> n. </strong> Greyish-yellow fungus obtained esp. From fermenting malt liquors and used as a fermenting agent, to raise bread, etc. [old english]</p>",
      },
    ],
  },
  {
    word: "Yeasty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yeasty <strong class='adj'> adj. </strong> (-ier, -iest) 1 of, like, or tasting of yeast; frothy. 2 in a ferment. 3 working like yeast. 4 (of talk etc.) Light and superficial.</p>",
      },
    ],
  },
  {
    word: "Yell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yell <strong class='noun'> —n. </strong> Loud sharp cry; shout. —v. Cry, shout. [old english]</p>",
      },
    ],
  },
  {
    word: "Yellow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellow <strong class='adj'> —adj.</strong> 1 of the colour of buttercups, lemons, egg-yolks, etc. 2 having a yellow skin or complexion. 3 <strong class='colloq'> colloq. </strong> Cowardly. <strong class='noun'> —n. </strong> 1 yellow colour or pigment. 2 yellow clothes or material. —v. Turn yellow. yellowish adj. Yellowness <strong class='noun'> n. </strong> Yellowy adj. [old english: related to *gold]</p>",
      },
    ],
  },
  {
    word: "Yellow-belly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellow-belly <strong class='noun'> n. </strong> Colloq. Coward.</p>",
      },
    ],
  },
  {
    word: "Yellow card",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellow card <strong class='noun'> n. </strong> Card shown by the referee to a football-player being cautioned.</p>",
      },
    ],
  },
  {
    word: "Yellow fever",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellow fever <strong class='noun'> n. </strong> Tropical virus disease with fever and jaundice.</p>",
      },
    ],
  },
  {
    word: "Yellow flag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellow flag <strong class='noun'> n. </strong> Flag displayed by a ship in quarantine.</p>",
      },
    ],
  },
  {
    word: "Yellowhammer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellowhammer <strong class='noun'> n. </strong> Bunting of which the male has a yellow head, neck, and breast.</p>",
      },
    ],
  },
  {
    word: "Yellow pages",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellow pages <strong class='n_pl'> n.pl. </strong> Propr. Telephone directory on yellow paper, listing and classifying business subscribers.</p>",
      },
    ],
  },
  {
    word: "Yellow pepper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellow pepper <strong class='noun'> n. </strong> Ripe yellow fruit of the capsicum plant.</p>",
      },
    ],
  },
  {
    word: "Yellow spot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellow spot <strong class='noun'> n. </strong> Point of acutest vision in the retina.</p>",
      },
    ],
  },
  {
    word: "Yellow streak",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yellow streak <strong class='noun'> n. </strong> Colloq. Trait of cowardice.</p>",
      },
    ],
  },
  {
    word: "Yelp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yelp <strong class='noun'> —n. </strong> Sharp shrill cry as of a dog in pain or excitement. —v. Utter a yelp. [old english]</p>",
      },
    ],
  },
  {
    word: "Yen1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yen1 <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> chief monetary unit of japan. [japanese from chinese]</p>",
      },
    ],
  },
  {
    word: "Yen2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yen2 <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Longing or yearning. —v. (-nn-) feel a longing. [chinese]</p>",
      },
    ],
  },
  {
    word: "Yeoman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yeoman <strong class='noun'> n. </strong> 1 esp. Hist. Man holding and cultivating a small landed estate. 2 member of the yeomanry force. yeomanly adj. [from earlier yoman, yeman, etc., probably = young man]</p>",
      },
    ],
  },
  {
    word: "Yeoman of the guard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yeoman of the guard <strong class='noun'> n. </strong> Member of the sovereign's bodyguard.</p>",
      },
    ],
  },
  {
    word: "Yeomanry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yeomanry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 body or class of yeomen. 2 hist. Volunteer cavalry force raised from the yeoman class.</p>",
      },
    ],
  },
  {
    word: "Yeoman warder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yeoman warder <strong class='noun'> n. </strong> (correct term for) a “beefeater” at the tower of london.</p>",
      },
    ],
  },
  {
    word: "Yep",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yep <strong class='adv'> adv. </strong> & <strong class='noun'> n. </strong> (also yup) us <strong class='colloq'> colloq. </strong> = *yes.</p>",
      },
    ],
  },
  {
    word: "Yes",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yes <strong class='adv'> —adv. </strong> 1 indicating that the answer to the question is affirmative, the statement etc. Made is correct, the request or command will be complied with, or the person summoned or addressed is present. 2 (yes?) A indeed? Is that so? B what do you want? <strong class='noun'> —n. </strong> 1 utterance of the word yes. 2 affirmation or assent. 3 ‘yes’ vote. say yes grant a request, confirm a statement. [old english, = yea let it be]</p>",
      },
    ],
  },
  {
    word: "Yes-man",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yes-man <strong class='noun'> n. </strong> Colloq. Weakly acquiescent person.</p>",
      },
    ],
  },
  {
    word: "Yesterday",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yesterday <strong class='adv'> —adv. </strong> 1 on the day before today. 2 in the recent past. <strong class='noun'> —n. </strong> 1 the day before today. 2 the recent past. [old english]</p>",
      },
    ],
  },
  {
    word: "Yesteryear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yesteryear <strong class='noun'> n. </strong> Archaic or rhet. 1 last year. 2 the recent past. [old english yester- that is last past, *year]</p>",
      },
    ],
  },
  {
    word: "Yet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yet <strong class='adv'> —adv. </strong> 1 as late as, or until, now or then (there is yet time; your best work yet). 2 (with neg. Or interrog.) So soon as, or by, now or then (it is not time yet; have you finished yet?). 3 again; in addition (more and yet more). 4 in the remaining time available (i will do it yet). 5 (foll. By compar.) Even (a yet more difficult task). 6 nevertheless; and or but in spite of that. — <strong class='conj'> conj. </strong> But at the same time; but nevertheless. [old english]</p>",
      },
    ],
  },
  {
    word: "Yeti",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yeti <strong class='noun'> n. </strong> = *abominable snowman. [tibetan]</p>",
      },
    ],
  },
  {
    word: "Yew",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yew <strong class='noun'> n. </strong> 1 dark-leaved evergreen tree bearing berry-like cones. 2 its wood. [old english]</p>",
      },
    ],
  },
  {
    word: "Y-fronts",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Y-fronts <strong class='noun'> n. </strong> Propr. Men's or boys' briefs with a y-shaped seam at the front.</p>",
      },
    ],
  },
  {
    word: "Yha",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yha <strong class='sing'> abbr. </strong> Youth hostels association.</p>",
      },
    ],
  },
  {
    word: "Yid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yid <strong class='noun'> n. </strong> Slang offens. Jew. [back-formation from *yiddish]</p>",
      },
    ],
  },
  {
    word: "Yiddish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yiddish <strong class='noun'> —n. </strong> Language used by jews in or from europe, <strong class='archaic_it_was'> orig. </strong> A german dialect with words from hebrew etc. <strong class='adj'> —adj.</strong> Of this language. [german jüdisch jewish]</p>",
      },
    ],
  },
  {
    word: "Yield",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yield <strong class='noun'> —v. </strong> 1 produce or return as a fruit, profit, or result. 2 give up; surrender, concede. 3 a <strong class='adj'> (often foll. By to) </strong> surrender; submit; defer to. B (as yielding adj.) Compliant; submissive; soft and pliable. 4 (foll. By to) give right of way to (other traffic). 5 (foll.</p>",
      },
    ],
  },
  {
    word: "Yin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yin <strong class='noun'> n. </strong> (in chinese philosophy) the passive female principle of the universe (cf. *yang).</p>",
      },
    ],
  },
  {
    word: "Yippee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yippee <strong class='n_pl'> int. </strong> Expressing delight or excitement. [natural exclamation]</p>",
      },
    ],
  },
  {
    word: "Ymca",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ymca <strong class='sing'> abbr. </strong> Young men's christian association.</p>",
      },
    ],
  },
  {
    word: "Yob",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yob <strong class='noun'> n. </strong> Slang lout, hooligan. yobbish adj. [back slang for *boy]</p>",
      },
    ],
  },
  {
    word: "Yobbo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yobbo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> slang = *yob.</p>",
      },
    ],
  },
  {
    word: "Yodel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yodel <strong class='noun'> —v. </strong> (-ll-; us -l-) sing with melodious inarticulate sounds and frequent changes between falsetto and normal voice in the manner of swiss mountain-dwellers. <strong class='noun'> —n. </strong> Yodelling cry. yodeller <strong class='noun'> n. </strong> [german]</p>",
      },
    ],
  },
  {
    word: "Yoga",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yoga <strong class='noun'> n. </strong> 1 hindu system of meditation and asceticism designed to effect reunion with the universal spirit. 2 system of physical exercises and breathing control used in yoga. [sanskrit, = union]</p>",
      },
    ],
  },
  {
    word: "Yoghurt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yoghurt <strong class='noun'> n. </strong> (also yogurt) semi-solid sourish food made from milk fermented by added bacteria. [turkish]</p>",
      },
    ],
  },
  {
    word: "Yogi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yogi <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> devotee of yoga. [hindustani: related to *yoga]</p>",
      },
    ],
  },
  {
    word: "Yoicks",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yoicks <strong class='n_pl'> int. </strong> Cry used by fox-hunters to urge on the hounds. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Yoke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yoke <strong class='noun'> —n. </strong> 1 wooden crosspiece fastened over the necks of two oxen etc. And attached to the plough or wagon to be pulled. 2 <strong class='pl_same_or_s'> (pl. Same or -s) </strong> pair (of oxen etc.). 3 object like a yoke in form or function, e.g. A wooden shoulder-piece for carrying a pair of pails, the top section of a garment from which the rest hangs. 4 sway, dominion, or servitude. 5 bond of union, esp. Of marriage. —v. (-king) 1 put a yoke on. 2 couple or unite (a pair). 3 (foll. By to) link (one thing) to (another). 4 match or work together. [old english]</p>",
      },
    ],
  },
  {
    word: "Yokel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yokel <strong class='noun'> n. </strong> Rustic; country bumpkin. [perhaps dial.]</p>",
      },
    ],
  },
  {
    word: "Yolk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yolk <strong class='noun'> n. </strong> Yellow inner part of an egg. [old english: related to *yellow]</p>",
      },
    ],
  },
  {
    word: "Yom kippur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yom kippur <strong class='noun'> n. </strong> Most solemn religious fast day of the jewish year, day of atonement. [hebrew]</p>",
      },
    ],
  },
  {
    word: "Yon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yon <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Literary & dial. Yonder. [old english]</p>",
      },
    ],
  },
  {
    word: "Yonder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yonder <strong class='adv'> —adv. </strong> Over there; at some distance in that direction; in the place indicated. <strong class='adj'> —adj.</strong> Situated yonder.</p>",
      },
    ],
  },
  {
    word: "Yonks",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yonks <strong class='n_pl'> n.pl. </strong> Slang a long time (yonks ago). [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Yoo-hoo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yoo-hoo <strong class='n_pl'> int. </strong> Used to attract a person's attention. [natural exclamation]</p>",
      },
    ],
  },
  {
    word: "Yore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yore <strong class='noun'> n. </strong> of yore a long time ago. [old english, = long ago]</p>",
      },
    ],
  },
  {
    word: "York",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>York <strong class='verb'> v. </strong> Cricket bowl out with a yorker. [back-formation from *yorker]</p>",
      },
    ],
  },
  {
    word: "Yorker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yorker <strong class='noun'> n. </strong> Cricket ball that pitches immediately under the bat. [probably with ref. To the practice of yorkshire cricketers]</p>",
      },
    ],
  },
  {
    word: "Yorkist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yorkist <strong class='noun'> —n. </strong> Hist. Follower of the house of york, esp. In the wars of the roses. <strong class='adj'> —adj.</strong> Of the house of york.</p>",
      },
    ],
  },
  {
    word: "Yorkshire pudding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yorkshire pudding <strong class='noun'> n. </strong> Baked batter eaten with roast beef.</p>",
      },
    ],
  },
  {
    word: "Yorkshire terrier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yorkshire terrier <strong class='noun'> n. </strong> Small long-haired blue and tan kind of terrier.</p>",
      },
    ],
  },
  {
    word: "You",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>You <strong class='pron'> pron. </strong> (obj. You; <strong class='poss'> poss. </strong> Your, yours) 1 the person or persons addressed. 2 (as int. With a noun) in an exclamatory statement (you fools!). 3 (in general statements) one, a person, people (you get used to it). you and yours you and your family, property, etc. [old english, originally  <strong class='slang_v'>objective case of *</strong> ye1]</p>",
      },
    ],
  },
  {
    word: "You'd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>You'd <strong class='contr'> contr. </strong> 1 you had. 2 you would.</p>",
      },
    ],
  },
  {
    word: "You'll",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>You'll <strong class='contr'> contr. </strong> You will; you shall.</p>",
      },
    ],
  },
  {
    word: "Young",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Young <strong class='adj'> —adj.</strong> (younger; youngest) 1 not far advanced in life, development, or existence; not yet old. 2 a immature, inexperienced. B youthful. 3 of or characteristic of youth (young love). 4 representing young people (young farmers). 5 a distinguishing a son from his father (young george). B as a familiar or condescending form of address (listen, young lady). 6 (younger) distinguishing one person from another of the same name (the younger pitt). <strong class='noun'> —n. </strong> (collect.) Offspring, esp. Of animals. youngish adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Young person",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Young person <strong class='noun'> n. </strong> Law person aged between 14 and 17 years.</p>",
      },
    ],
  },
  {
    word: "Youngster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Youngster <strong class='noun'> n. </strong> Child, young person.</p>",
      },
    ],
  },
  {
    word: "Your",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Your <strong class='poss'> poss. </strong> <strong class='pron'> Pron. </strong> 1 of or belonging to you. 2 <strong class='colloq'> colloq. </strong> Often derog. Much talked of; well known (your typical professor). [old english]</p>",
      },
    ],
  },
  {
    word: "You're",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>You're <strong class='contr'> contr. </strong> You are.</p>",
      },
    ],
  },
  {
    word: "Yours",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yours <strong class='poss'> poss. </strong> <strong class='pron'> Pron. </strong> 1 the one or ones belonging to you (it is yours; yours are over there). 2 your letter (yours of the 10th). 3 introducing a formula ending a letter (yours ever; yours truly). of yours of or belonging to you (friend of yours).</p>",
      },
    ],
  },
  {
    word: "Yourself",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yourself <strong class='pron'> pron. </strong> (pl. Yourselves) 1 a emphat. Form of *you. B refl. Form of *you. 2 in your normal state of body or mind (are quite yourself again). be yourself see *oneself.</p>",
      },
    ],
  },
  {
    word: "Youth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Youth <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 being young; period between childhood and adult age. 2 vigour, enthusiasm, inexperience, or other characteristic of this period. 3 young man. 4 (as pl.) Young people collectively (the youth of the country). [old english: related to *young]</p>",
      },
    ],
  },
  {
    word: "Youth club",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Youth club <strong class='noun'> n. </strong> Place for young people's leisure activities.</p>",
      },
    ],
  },
  {
    word: "Youthful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Youthful <strong class='adj'> adj. </strong> Young or still having the characteristics of youth. youthfully <strong class='adv'> adv. </strong> Youthfulness n.</p>",
      },
    ],
  },
  {
    word: "Youth hostel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Youth hostel <strong class='noun'> n. </strong> Any of a chain of cheap lodgings for holiday-makers, esp. Walkers and cyclists.</p>",
      },
    ],
  },
  {
    word: "You've",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>You've <strong class='contr'> contr. </strong> You have.</p>",
      },
    ],
  },
  {
    word: "Yowl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yowl <strong class='noun'> —n. </strong> Loud wailing cry of or as of a cat or dog in distress. —v. Utter a yowl. [imitative]</p>",
      },
    ],
  },
  {
    word: "Yo-yo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yo-yo <strong class='noun'> n. </strong> (pl. Yo-yos) 1 toy consisting of a pair of discs with a deep groove between them in which string is attached and wound, and which can be made to fall and rise. 2 thing that repeatedly falls and rises. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Yr.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yr. <strong class='abbr'> Abbr. </strong> 1 year(s). 2 younger. 3 your.</p>",
      },
    ],
  },
  {
    word: "Yrs.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yrs. <strong class='abbr'> Abbr. </strong> 1 years. 2 yours.</p>",
      },
    ],
  },
  {
    word: "Yts",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yts <strong class='sing'> abbr. </strong> Youth training scheme.</p>",
      },
    ],
  },
  {
    word: "Ytterbium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ytterbium <strong class='noun'> n. </strong> Metallic element of the lanthanide series. [ytterby in sweden]</p>",
      },
    ],
  },
  {
    word: "Yttrium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yttrium <strong class='noun'> n. </strong> Metallic element resembling the lanthanides. [related to *ytterbium]</p>",
      },
    ],
  },
  {
    word: "Yuan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yuan <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> chief monetary unit of china. [chinese]</p>",
      },
    ],
  },
  {
    word: "Yucca",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yucca <strong class='noun'> n. </strong> Subtropical white-flowered plant with swordlike leaves, often grown as a house-plant. [carib]</p>",
      },
    ],
  },
  {
    word: "Yuck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yuck <strong class='n_pl'> int. </strong> (also yuk) slang expression of strong distaste. [imitative]</p>",
      },
    ],
  },
  {
    word: "Yucky",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yucky <strong class='adj'> adj. </strong> (also yukky) (-ier, -iest) slang 1 messy, repellent. 2 sickly, sentimental.</p>",
      },
    ],
  },
  {
    word: "Yugoslav ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yugoslav  <strong class='slang_v'> (also jugoslav) </strong> <strong class='noun'> —n. </strong> 1 native or national of yugoslavia. 2 person of yugoslav descent. <strong class='adj'> —adj.</strong> Of yugoslavia. yugoslavian adj. & <strong class='noun'> n. </strong> [serbo-croat jug south: related to *slav]</p>",
      },
    ],
  },
  {
    word: "Yuk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Yuk <strong class='var'> var. </strong> Of *yuck.</p>",
      },
    ],
  },
  {
    word: "Yukky",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Yukky <strong class='var'> var. </strong> Of *yucky.</p>",
      },
    ],
  },
  {
    word: "Yule",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yule <strong class='noun'> n. </strong> (in full yule-tide) archaic the christmas festival. [old english]</p>",
      },
    ],
  },
  {
    word: "Yule-log",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yule-log <strong class='noun'> n. </strong> 1 large log traditionally burnt on christmas eve. 2 log-shaped chocolate cake eaten at christmas.</p>",
      },
    ],
  },
  {
    word: "Yummy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yummy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Tasty, delicious. [from *yum-yum]</p>",
      },
    ],
  },
  {
    word: "Yum-yum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yum-yum <strong class='n_pl'> int. </strong> Expressing pleasure from eating or the prospect of eating. [natural exclamation]</p>",
      },
    ],
  },
  {
    word: "Yup",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Yup <strong class='var'> var. </strong> Of *yep.</p>",
      },
    ],
  },
  {
    word: "Yuppie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Yuppie <strong class='noun'> n. </strong> (also yuppy) <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> (often attrib.) Colloq., usu. Derog. Young ambitious professional person working in a city. [from young urban professional]</p>",
      },
    ],
  },
  {
    word: "Ywca",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ywca <strong class='sing'> abbr. </strong> Young women's christian association.</p>",
      },
    ],
  },
];

export const alphabet_Y_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Y</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Y1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Y2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-y1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-y2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-y3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>याख़्टसमैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>याक स्लैंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हाँ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>याहू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहोवा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>याक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>येल लॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रतालू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिकायत करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यांग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यांकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भौंकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यरबोरो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यार्ड 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यार्ड 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यार्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यार्ड हाथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यार्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मापदंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यरमुल्के</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धागा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>येरो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुक़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रास्ते से हटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धरना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जम्हाई लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रास्ते से हटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाई बी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Y गुणसूत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यार्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ye1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ye2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हाँ पुरातन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हाँ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सालाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक बरस का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सालाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदास होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यीस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फेनिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चीखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीला पेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिला पत्रक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीला बुखार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीला झंडा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीले रंग का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीत पृष्ठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीली मिर्च</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीला स्थान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीले रंग की लकीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भौंकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Yen1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Yen2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फ़र्मर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गार्ड का योमन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्षेत्रपाल लोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योमन वार्डर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हां</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हाँ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हाँ आदमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहल साल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभी तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिममानव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>y-मोर्चों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Yha</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहूदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पैदावार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जय-जय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वायएमसीए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योबो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योडल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Yoicks</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घोड़े का अंसबंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गांवारू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जर्दी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Yom Kippur</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उधर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Yonks</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यू-हू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गया काल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यॉर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यॉर्कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यॉर्कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दूध, आटे और अंडे से बना हुआ गुलगुला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यॉर्कशायर टेरियर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आप चाहते</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युवा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युवा व्यक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौजवान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुम्हारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आपका अपना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खुद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युवा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युवा क्लब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युवा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूथ हॉस्टल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आपने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिल्लाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योयो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Yr।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Yrs।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>YTS</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ytterbium</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>yttrium</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युआन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युक्का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुश्ती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूगोस्लाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युक्की</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लट्ठे के आकार का केक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वादिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वादिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हां</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युप्पी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाईडब्ल्यूसीए</p>",
  },
];
