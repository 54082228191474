<template>
  <div class="main-container col-12">
    <div v-if="showLoader" class="show-loader position-fixed w-100 h-100">
      <Loader />
    </div>
    <div class="wrapper row">
      <div class="col-12">
        <!-- <h3>Multi Language Translator</h3> -->
        <!-- <h3>Multi tulkot(Translate - Latvian)</h3> -->
        <!-- <h3>Multi Versti(Translate - Lithuanian)</h3> -->
        <!-- <h3>Multi traduzir(Translate - Portuguese)</h3> -->
        <!-- <h3>Multi Prevedi(Translate - Slovenian)</h3> -->
        <!-- <h3>000-webhost-Sahastrabahu</h3> -->
        <!-- <h3>Multi Translate - đa dịch(Translate - Vietnamese)</h3> -->
        <div class="col-12 position-relative mt-5">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 mb-2">
              <multiselect
                class="col-12"
                v-model="primaryLanguage"
                :options="dropDownArry.text"
                placeholder="Select language"
                label="language"
                track-by="code"
                @select="onPrimaryLangSelect"
              >
              </multiselect>
            </div>
            <div
              class="col-12 col-md-6 col-lg-6 mb-2"
              title="Select any language(s)"
            >
              <multiselect
                class="col-12"
                v-model="multiSelectedLang"
                :options="userSelectedLang"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Select language"
                label="language"
                track-by="code"
                open-direction="bottom"
                :hide-selected="true"
                :allow-empty="false"
                ref="multiselect"
                @select="onSelect"
              >
              </multiselect>
            </div>
          </div>
          <div
            class="multiselect__clear"
            title="Clear all"
            v-if="multiSelectedLang.length"
            @click="clearAll()"
          ></div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6 col-md-6">
            <div class="mb-2 mt-2 position-relative w-100 sourceText-container">
              <textarea
                id="sourceText"
                v-model="inputText"
                class="col-12"
                :maxlength="InputTextMaxLength"
              ></textarea>
              <div class="set-btn position-absolute text-right">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6">
                    <button
                      type="submit"
                      class="btn audio-btn"
                      :disabled="inputText.length ? false : true"
                    >
                      <TextToSpeech
                        :languageObject="primaryLanguage"
                        :speechText="inputText"
                      />
                    </button>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6">
                    <button
                      type="submit"
                      class="btn translate-btn"
                      @click="translateHandler"
                      :disabled="inputText.length ? false : true"
                    >
                      Translate
                    </button>
                    <button
                      type="submit"
                      class="btn clear-btn"
                      @click="clearHandler"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-lg-6 col-md-6 position-relative mb-2 mt-2"
            v-for="val of multiLangTranslate"
            :key="val"
          >
            <code class="selected_lang">{{ val.language }}</code>
            <textarea
              id="resultText"
              v-model="val.meaning"
              class="col-12"
              readonly
              :data-language-code="val.code"
            ></textarea>
            <div class="set-btn position-relative text-right">
              <div class="col-12">
                <button
                  type="submit"
                  class="btn audio-btn"
                  :disabled="inputText.length ? false : true"
                >
                  <TextToSpeech
                    :languageObject="val"
                    :speechText="val.meaning"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tableRows.length && tableColumns.length">
          <div style="height: 500px">
            <div id="grid-wrapper" style="width: 100%; height: 100%">
              <EasyDataTable
                table-class-name="customize-table"
                :headers="tableColumns"
                :items="tableRows"
                :search-value="searchValue"
                border-cell
                :rows-per-page="10"
              >
                <!-- <template #item-meaning="{ meaning }">
                  <div class="meaning-wrapper">
                    <div v-html="meaning"></div>
                  </div>
                </template> -->
                <template #item="fieldData">
                  <div class="meaning-wrapper">
                    <!-- <p>{{ fieldData }}</p> -->
                    <div v-if="fieldData.column === 'meaning'">
                      <div
                        v-html="fieldData.item[fieldData.column]"
                        :id="fieldData.column + '_' + fieldData.item.id"
                      ></div>
                      <TextToSpeechMeaningColumn
                        :languageObject="getCellKeyName(fieldData)"
                        :speechText="fieldData.item[fieldData.column]"
                        :id="fieldData.column + '_' + fieldData.item.id"
                        class="text-speech position-absolute"
                      />
                      <!-- <p>--[ {{ fieldData.item }}</p> -->
                    </div>
                    <div v-else>
                      <div
                        v-html="fieldData.item[fieldData.column]"
                        :id="fieldData.column"
                      ></div>
                      <TextToSpeech
                        :languageObject="getCellKeyName(fieldData)"
                        :speechText="fieldData.item[fieldData.column]"
                        class="text-speech position-absolute"
                      />
                    </div>
                  </div>
                </template>
              </EasyDataTable>
              <btnCellRenderer v-if="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import allLng from "./allLang.js";
import { wordsArrayMeaningWithHindi } from "./alphabet-words-translate-files/export-array-based-first-latter.js";
import Multiselect from "vue-multiselect";
import Loader from "../loader/loader.vue";
// import { getUser, updateUser } from '@/firebase';
import BtnCellRenderer from "./cellRender.vue";
import TextToSpeech from "./text-to-speech.vue";
import TextToSpeechMeaningColumn from "./text-to-speech-meaning-column.vue";
import volume from "../../assets/volume.svg";
export default {
  name: "Googletranslate",
  components: {
    Multiselect,
    Loader,
    btnCellRenderer: BtnCellRenderer,
    TextToSpeech,
    TextToSpeechMeaningColumn,
  },
  props: {},
  data() {
    return {
      Volume: volume,
      onColumnResized: (params) => {
        console.log(params);
      },
      inputText: "Use my name.",
      InputTextMaxLength: 500,
      output: "",
      errors: [],
      primaryLanguage: [],
      sourceLang: "sq",
      dropDownArry: allLng,
      sourceTextArray: [],
      tableMeaningDataArray: [],
      inputTextFirstLatterIndex: [],
      multiSelectedLang: [],
      defaultSelectedLanguage: ["hi"],
      maxLanguage: 5,
      multiLangTranslate: [],
      userSelectedLang: [],
      tableColumns: [],
      tableRows: [],
      searchValue: "",
      showLoader: true,
    };
  },
  beforeMount() {
    this.frameworkComponents = {
      btnCellRenderer: BtnCellRenderer,
    };
  },
  mounted() {
    this.primaryLanguage.push({
      language: "English",
      code: "en",
    });
    // default selected language
    this.dropDownArry.text.forEach((option) => {
      for (let lang of this.defaultSelectedLanguage) {
        if (option.code === lang) {
          this.multiSelectedLang.push(option);
        }
      }
      return this.multiSelectedLang;
    });
    this.userSelectedLang = this.dropDownArry.text.filter((drop) => {
      return drop.language !== this.primaryLanguage.language;
    });
    this.showLoader = false;
  },
  methods: {
    getCellKeyName(data) {
      let allSelectedLangClone = [
        ...this.primaryLanguage,
        ...this.multiLangTranslate,
      ];
      for (let i = 0; i < allSelectedLangClone.length; i++) {
        if (
          allSelectedLangClone[i].language.toLowerCase() ===
          data.column.toLowerCase()
        ) {
          return allSelectedLangClone[i];
        } else if (data.column.toLowerCase() === "meaning") {
          return {
            language: "English",
            code: "en",
          };
        }
      }
      return {};
    },
    clearAll() {
      this.multiSelectedLang = [];
      this.userSelectedLang = this.dropDownArry.text.filter((drop) => {
        return drop.language !== this.primaryLanguage[0].language;
      });
    },
    // user can select max 5 language at a time
    onSelect() {
      if (this.multiSelectedLang.length >= this.maxLanguage) {
        alert("You can select max 5 language");
        this.multiSelectedLang.length = this.maxLanguage - 1;
      }
    },
    onPrimaryLangSelect(value) {
      this.userSelectedLang = this.dropDownArry.text.filter((drop) => {
        return drop.language !== value.language;
      });
      this.multiSelectedLang = [];
      this.primaryLanguage = [];
      this.tableColumns = [];
      this.tableRows = [];
      this.tableMeaningDataArray = [];
      this.multiLangTranslate = [];
      this.primaryLanguage.push(value);
    },
    // translate every single word here
    async findStringAndMeaning(userInputTextArray) {
      this.showLoader = true;
      this.tableRows = [];
      this.tableColumns = [];
      let userInputTextArraySort = JSON.parse(
        JSON.stringify(userInputTextArray)
      );
      if (this.multiSelectedLang.length) {
        let hindi = this.multiSelectedLang.find((lang) => {
          return lang.code === "hi";
        });
        this.sourceLang =
          this.primaryLanguage[0] && this.primaryLanguage[0].code
            ? this.primaryLanguage[0].code
            : this.primaryLanguage.code;
        let getTableData = await wordsArrayMeaningWithHindi(
          userInputTextArraySort,
          hindi && hindi.code ? hindi.code : ""
        );

        let fullTableData = await this.addNotFound(
          userInputTextArray,
          getTableData
        );
        this.tableMeaningDataArray = fullTableData;
        let primaryLanguageObj;
        let indivisualMeaning = [];
        if (this.primaryLanguage.length) {
          for (let code of this.primaryLanguage) {
            let obj = {
              text: code.language,
              value: code.language.toLowerCase(),
              sortable: true,
            };
            primaryLanguageObj = obj;
          }
        } else {
          let obj = {
            text: this.primaryLanguage.language,
            value: this.primaryLanguage.language.toLowerCase(),
            sortable: true,
          };
          primaryLanguageObj = obj;
        }
        if (this.multiSelectedLang.length) {
          for (let i = 0; i < userInputTextArraySort.length; i++) {
            let tableColumn = [];
            for (let lang of this.multiSelectedLang) {
              let obj = {
                text: lang.language,
                value: lang.language.toLowerCase(),
                sortable: true,
              };
              tableColumn.push(obj);
              // tableRows
              var url =
                "https://translate.googleapis.com/translate_a/single?client=gtx&sl=" +
                this.sourceLang +
                "&tl=" +
                lang.code +
                "&dt=t&q=" +
                encodeURI(userInputTextArraySort[i]);
              await axios
                .post(url)
                .then((response) => {
                  let sentence = "";
                  for (let string of response.data[0]) {
                    sentence = sentence + string[0];
                  }
                  let langName = lang.language.toLowerCase();
                  let sourcLang =
                    this.primaryLanguage && this.primaryLanguage.length
                      ? this.primaryLanguage[0].language.toLowerCase()
                      : this.primaryLanguage.language.toLowerCase();
                  if (indivisualMeaning.length) {
                    if (indivisualMeaning[i]) {
                      indivisualMeaning[i][sourcLang] =
                        userInputTextArraySort[i];
                      indivisualMeaning[i][langName] = sentence;
                    } else {
                      let obj = {
                        [sourcLang]: userInputTextArraySort[i],
                        [langName]: sentence,
                        id: (Math.random() + 1).toString(36).substring(7),
                      };
                      indivisualMeaning.push(obj);
                    }
                  } else {
                    let obj = {
                      [sourcLang]: userInputTextArraySort[i],
                      [langName]: sentence,
                      id: (Math.random() + 1).toString(36).substring(7),
                    };
                    indivisualMeaning.push(obj);
                  }
                })
                .catch((e) => {
                  this.errors.push(e);
                });
            }
            let tabCol = [];
            tabCol[0] = { ...primaryLanguageObj };
            for (let tCol of tableColumn) {
              tabCol.push(tCol);
            }
            this.tableColumns = [...tabCol];
          }
        } else {
          alert("Please select at least one language");
        }
        this.tableRows = indivisualMeaning;
        this.mergeTableData();
      }
      this.showLoader = false;
    },
    mergeTableData() {
      for (let word of this.tableRows) {
        if (word && word.english) {
          let data = this.tableMeaningDataArray.find((meaningObj) => {
            return meaningObj.word.toLowerCase() === word.english;
          });
          word.meaning = data.language[0].meaning;
        }
      }
      this.tableColumns.splice(1, 0, {
        value: "meaning",
        text: "Meaning",
        sortable: true,
        // maxWidth: 1500,
      });
    },
    translateHandler() {
      if (this.inputText.length) {
        // reset privious table data
        this.sourceTextArray = [];
        //   getting input value
        var sourceText = this.inputText;
        //   input value split and create unique array
        // replace special charactor
        let userInputTextArray = sourceText
          .toLowerCase()
          .replace(".", " ")
          .replace(/\W+/gm, " ")
          .split(" ");
        var unique = Array.from(new Set(userInputTextArray));
        userInputTextArray = unique;
        // console.log("=+++++++++++++", unique, userInputTextArray);
        this.findStringAndMeaning(userInputTextArray);
        //   API call for translate string
        this.getTranslateResult(sourceText);
      }
    },
    // API call for string translate and set in output value
    async getTranslateResult(sourceText) {
      this.multiLangTranslate = [];
      let primaryLanguageObj;
      if (this.primaryLanguage.language) {
        this.sourceLang = this.primaryLanguage.code;
        let obj = {
          text:
            this.primaryLanguage && this.primaryLanguage.length
              ? this.primaryLanguage[0].language
              : this.primaryLanguage.language,
          value:
            this.primaryLanguage && this.primaryLanguage.length
              ? this.primaryLanguage[0].language.toLowerCase()
              : this.primaryLanguage.language.toLowerCase(),
          sortable: true,
        };
        primaryLanguageObj = obj;
      } else {
        if (this.primaryLanguage.length) {
          for (let code of this.primaryLanguage) {
            let obj = {
              text: code.language,
              value: code.language.toLowerCase(),
              sortable: true,
            };
            primaryLanguageObj = obj;
            this.sourceLang = code.code;
          }
        } else {
          let obj = {
            text: this.primaryLanguage.language,
            value: this.primaryLanguage.language.toLowerCase(),
            sortable: true,
          };
          primaryLanguageObj = obj;
          this.sourceLang = this.primaryLanguage.code;
        }
      }
      if (this.multiSelectedLang.length) {
        let tableColumn = [];
        for (let lang of this.multiSelectedLang) {
          let obj = {
            text: lang.language,
            value: lang.language.toLowerCase(),
            sortable: true,
          };
          tableColumn.push(obj);
          var url =
            "https://translate.googleapis.com/translate_a/single?client=gtx&sl=" +
            this.sourceLang +
            "&tl=" +
            lang.code +
            "&dt=t&q=" +
            encodeURI(sourceText);
          await axios
            .post(url)
            .then((response) => {
              let sentence = "";
              for (let string of response.data[0]) {
                sentence = sentence + string[0];
              }
              let obj = {
                language: lang.language,
                meaning: sentence,
                code: lang.code,
              };
              this.multiLangTranslate.push(obj);
            })
            .catch((e) => {
              this.errors.push(e);
            });
        }
        let tabCol = [];
        tabCol[0] = { ...primaryLanguageObj };
        for (let tCol of tableColumn) {
          if (!tabCol.find((tColObj) => tColObj.field === tCol.field)) {
            tabCol.push(tCol);
          }
        }
        setTimeout(() => {
          this.tableColumns = tabCol;
        }, 10);
      } else {
        alert("Please select at least one language");
      }
    },
    clearHandler() {
      this.inputTextFirstLatterIndex = [];
      this.sourceTextArray = [];
      this.tableMeaningDataArray = [];
      this.inputText = "";
      this.output = "";
      this.multiLangTranslate = [];
    },
    addNotFound(userInputTextArray, getTableData) {
      for (let i = 0; i < userInputTextArray.length; i++) {
        if (
          !getTableData.find(
            (tData) =>
              userInputTextArray[i].toLowerCase() === tData.word.toLowerCase()
          ) &&
          userInputTextArray[i].length
        ) {
          let obj = {
            word: userInputTextArray[i],
            language: [
              {
                language: "en",
                title: "Engish",
                meaning: "Not found",
              },
              {
                language: "hi",
                title: "Hindi",
                meaning: "Not found",
              },
            ],
          };
          getTableData.splice(i, 0, obj);
        }
      }
      return getTableData;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.ag-cell-value,
.ag-group-value {
  white-space: pre-wrap;
  line-height: 1.6;
}

.show-loader {
  top: 0;
  left: 0;
  text-align: left;
  background: #afafaf80;
  z-index: 9;
}

.selected_lang {
  position: absolute;
  top: 1px;
  left: 13px;
  background: #e7e7e7;
  text-align: left;
  padding: 0 10px;
  box-sizing: border-box;
  display: block;
  color: #0d6efd;
}

textarea {
  height: 200px;
  border: 1px solid #e8e8e8;
  resize: none;
  overflow: auto;
  padding: 25px 15px 0;
}

#sourceText {
  padding: 10px 15px;
}

.sourceText-container {
  max-height: 200px;
  border: 1px solid #e8e8e8;
  padding: 0px 0px 45px;
  height: 250px;
  /* margin: 0 0 0 13px; */
  box-sizing: border-box;
  width: calc(50% - 25px);
  /* margin-right: 12px; */
}

.sourceText-container textarea {
  height: 100%;
  border: unset;
  resize: none;
  overflow: unset;
  padding: unset;
  outline: none;
}

button:disabled {
  cursor: not-allowed;
}

button {
  width: auto !important;
}

.text-left p {
  text-align: left;
  margin: 0;
}

.multiselect__clear {
  position: absolute;
  top: 0;
  right: 41px;
  height: 40px;
  width: 40px;
  display: block;
  cursor: pointer;
  z-index: 2;
}

.multiselect__tags {
  padding: 8px 55px 0 8px;
  border-radius: 0px;
}

.multiselect__clear:after,
.multiselect__clear:before {
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 16px;
  background: #aaa;
  top: 12px;
  right: 4px;
  cursor: pointer;
}

.multiselect__clear:before {
  transform: rotate(45deg);
}

.multiselect__clear:after {
  transform: rotate(-45deg);
}

.set-btn {
  bottom: 0;
  right: 0;
  padding-right: 15px;
  padding-bottom: 10px;
  width: 100%;
  display: block;
  text-align: right;
}

.translate-btn {
  margin: 0;
  border: 1px solid #e8e8e8;
  height: 25px;
  padding: 0px 5px !important;
  background-color: #083b66 !important;
  color: #fff !important;
  margin-top: 5px;
}

.clear-btn {
  margin: 0;
  border: 1px solid #e8e8e8;
  height: 25px;
  padding: 0px 5px !important;
  /* background: #083b66 !important; */
  /* color: #fff !important; */
  margin-top: 5px;
  margin-left: 5px;
}

.ag-ltr .ag-cell {
  text-align: left;
}

.ag-unselectable {
  -moz-user-select: auto;
  -webkit-user-select: auto;
  user-select: auto;
}

.audio-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px !important;
  border: none !important;
  outline: none !important;
}

.audio-btn img {
  width: 100;
}

[data-language-code="ar"],
[data-language-code="ur"] {
  text-align: right;
}

.set-btn.position-relative.text-right {
  bottom: 20px;
}

.customize-table table tr:hover {
  opacity: 0.9;
  color: unset;
  background-color: unset;
}

.customize-table table td:hover {
  opacity: 0.9;
  color: #c1cad4;
  background-color: #083b66;
}

.text-speech {
  bottom: 0;
  right: 0;
  width: 22px;
}

.customize-table {
  --easy-table-header-font-size: 16px;
  --easy-table-body-row-font-size: 16px;
  --easy-table-footer-font-size: 16px;

  /* --easy-table-border: 1px solid #445269;
  --easy-table-row-border: 1px solid #445269;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #083b66;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;

  --easy-table-body-row-font-color: #c0c7d2;
  --easy-table-body-row-background-color: #083b66;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-opacity: .9;
  --easy-table-body-row-hover-font-color: unset;
  --easy-table-body-row-hover-background-color: unset;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #083b66;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #083b66;
  --easy-table-scrollbar-color: #083b66;
  --easy-table-scrollbar-thumb-color: #4c5d7a;
  --easy-table-scrollbar-corner-color: #083b66;

  --easy-table-loading-mask-background-color: #083b66; */
}
</style>
