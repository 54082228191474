export const alphabet_Z = [
  {
    word: "Z",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Z </p>",
      },
    ],
  },
  {
    word: "Z",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Z <strong class='noun'> n. </strong> (also z) (pl. Zs or z's) 1 twenty-sixth letter of the alphabet. 2 (usu. Z) algebra third unknown quantity.</p>",
      },
    ],
  },
  {
    word: "Zabaglione",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zabaglione <strong class='noun'> n. </strong> Italian dessert of whipped and heated egg-yolks, sugar, and wine. [italian]</p>",
      },
    ],
  },
  {
    word: "Zany",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zany <strong class='adj'> adj. </strong> (-ier, -iest) comically idiotic; crazily ridiculous. [french or italian]</p>",
      },
    ],
  },
  {
    word: "Zap ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zap  <strong class='slang_v'> slang —v. </strong> (-pp-) 1 a kill or destroy; attack. B hit hard (zapped the ball over the net). 2 move quickly. 3 overwhelm emotionally. <strong class='pl'> —int. </strong> Expressing the sound or impact of a bullet, ray gun, etc., or any sudden event. [imitative]</p>",
      },
    ],
  },
  {
    word: "Zappy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zappy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Lively, energetic.</p>",
      },
    ],
  },
  {
    word: "Zarathustrian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zarathustrian <strong class='var'> var. </strong> Of *zoroastrian.</p>",
      },
    ],
  },
  {
    word: "Zeal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zeal <strong class='noun'> n. </strong> Earnestness or fervour; hearty persistent endeavour. [greek zelos]</p>",
      },
    ],
  },
  {
    word: "Zealot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zealot <strong class='noun'> n. </strong> Extreme partisan; fanatic. zealotry n.</p>",
      },
    ],
  },
  {
    word: "Zealous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zealous <strong class='adj'> adj. </strong> Full of zeal; enthusiastic. zealously adv.</p>",
      },
    ],
  },
  {
    word: "Zebra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zebra <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> black-and-white striped african animal of the family including the ass and horse. [italian or portuguese from congolese]</p>",
      },
    ],
  },
  {
    word: "Zebra crossing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zebra crossing <strong class='noun'> n. </strong> Striped street-crossing where pedestrians have precedence.</p>",
      },
    ],
  },
  {
    word: "Zebu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zebu <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> humped ox of asia and africa. [french]</p>",
      },
    ],
  },
  {
    word: "Zed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zed <strong class='noun'> n. </strong> Letter z. [greek *zeta]</p>",
      },
    ],
  },
  {
    word: "Zee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zee <strong class='noun'> n. </strong> Us letter z. [var. Of *zed]</p>",
      },
    ],
  },
  {
    word: "Zeitgeist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zeitgeist <strong class='noun'> n. </strong> The spirit of the times. [german]</p>",
      },
    ],
  },
  {
    word: "Zen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zen <strong class='noun'> n. </strong> Form of buddhism emphasizing meditation and intuition. [japanese, = meditation]</p>",
      },
    ],
  },
  {
    word: "Zend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zend <strong class='noun'> n. </strong> An interpretation of the avesta. [persian]</p>",
      },
    ],
  },
  {
    word: "Zend-avesta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zend-avesta <strong class='noun'> n. </strong> Zoroastrian sacred writings of the avesta (or text) and zend (or commentary).</p>",
      },
    ],
  },
  {
    word: "Zenith",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zenith <strong class='noun'> n. </strong> 1 point of the heavens directly above an observer. 2 highest point (of power or prosperity etc.). [latin from arabic]</p>",
      },
    ],
  },
  {
    word: "Zephyr",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zephyr <strong class='noun'> n. </strong> Literary mild gentle breeze. [greek, = west wind]</p>",
      },
    ],
  },
  {
    word: "Zeppelin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zeppelin <strong class='noun'> n. </strong> Large dirigible german airship of the early 20th c. [count f. Von zeppelin, name of an airman]</p>",
      },
    ],
  },
  {
    word: "Zero",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zero <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 figure 0; nought; nil. 2 point on the scale of a thermometer etc. From which a positive or negative quantity is reckoned. 3 (attrib.) No, not any (zero growth). 4 (in full zero-hour) a hour at which a planned, esp. Military, operation is timed to begin. B crucial moment. 5 lowest or earliest point (down to zero; the year zero). zero in on (-oes, -oed) 1 take aim at. 2 focus one's attention on. [arabic: related to *cipher]</p>",
      },
    ],
  },
  {
    word: "Zero option",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zero option <strong class='noun'> n. </strong> Disarmament proposal for the total removal of certain types of weapons on both sides.</p>",
      },
    ],
  },
  {
    word: "Zero-rated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zero-rated <strong class='adj'> adj. </strong> On which no vat is charged.</p>",
      },
    ],
  },
  {
    word: "Zest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zest <strong class='noun'> n. </strong> 1 piquancy; stimulating flavour or quality. 2 a keen enjoyment or interest. B (often foll. By for) relish. C gusto. 3 scraping of orange or lemon peel as flavouring. zestful adj. Zestfully <strong class='adv'> adv. </strong> [french]</p>",
      },
    ],
  },
  {
    word: "Zeta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zeta <strong class='noun'> n. </strong> Sixth letter of the greek alphabet (z, z). [greek]</p>",
      },
    ],
  },
  {
    word: "Zeugma",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zeugma <strong class='noun'> n. </strong> Figure of speech using a verb or adjective with two nouns, to one of which it is strictly applicable while the word appropriate to the other is not used (e.g. With weeping eyes and [sc. Grieving] hearts) (cf. *syllepsis). [greek, = a yoking, from zugon yoke]</p>",
      },
    ],
  },
  {
    word: "Ziggurat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ziggurat <strong class='noun'> n. </strong> Rectangular stepped tower in ancient mesopotamia, surmounted by a temple. [assyrian]</p>",
      },
    ],
  },
  {
    word: "Zigzag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zigzag <strong class='adj'> —adj.</strong> With abrupt alternate right and left turns (zigzag line). <strong class='noun'> —n. </strong> Zigzag line; thing having the form of a zigzag or having sharp turns. <strong class='adv'> —adv. </strong> With a zigzag course. —v. <strong class='gg'> (-gg-) </strong> move in a zigzag course. [french from german]</p>",
      },
    ],
  },
  {
    word: "Zilch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zilch <strong class='noun'> n. </strong> Esp. Us slang nothing. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Zillion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zillion <strong class='noun'> n. </strong> Colloq. Indefinite large number. [probably after million]</p>",
      },
    ],
  },
  {
    word: "Zimmer frame",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zimmer frame <strong class='noun'> n. </strong> Propr. A kind of walking-frame. [zimmer, name of the maker]</p>",
      },
    ],
  },
  {
    word: "Zinc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zinc <strong class='noun'> n. </strong> Greyish-white metallic element used as a component of brass and in galvanizing sheet iron. [german zink]</p>",
      },
    ],
  },
  {
    word: "Zing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zing <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Vigour, energy. —v. Move swiftly, esp. With a high-pitched ringing sound. [imitative]</p>",
      },
    ],
  },
  {
    word: "Zinnia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zinnia <strong class='noun'> n. </strong> Garden plant with showy flowers. [zinn, name of a physician and botanist]</p>",
      },
    ],
  },
  {
    word: "Zion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zion <strong class='noun'> n. </strong> 1 ancient jerusalem; its holy hill. 2 a the jewish people or religion. B the christian church. 3 the kingdom of heaven. [hebrew siyôn]</p>",
      },
    ],
  },
  {
    word: "Zionism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zionism <strong class='noun'> n. </strong> Movement for the re-establishment and development of a jewish nation in what is now israel. zionist <strong class='noun'> n. </strong> & adj.</p>",
      },
    ],
  },
  {
    word: "Zip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zip <strong class='noun'> —n. </strong> 1 light fast sound. 2 energy, vigour. 3 a (in full zip-fastener) fastening device of two flexible strips with interlocking projections, closed or opened by sliding a clip along them. B (attrib.) Having a zip-fastener (zip bag). —v. (-pp-) 1 (often foll. By up) fasten with a zip-fastener. 2 move with zip or at high speed. [imitative]</p>",
      },
    ],
  },
  {
    word: "Zip code",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zip code <strong class='noun'> n. </strong> Us postcode. [zone improvement plan]</p>",
      },
    ],
  },
  {
    word: "Zipper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zipper <strong class='noun'> n. </strong> Esp. Us = *zip 3a.</p>",
      },
    ],
  },
  {
    word: "Zippy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zippy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Lively, speedy.</p>",
      },
    ],
  },
  {
    word: "Zircon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zircon <strong class='noun'> n. </strong> Zirconium silicate of which some translucent varieties are cut into gems. [german zirkon]</p>",
      },
    ],
  },
  {
    word: "Zirconium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zirconium <strong class='noun'> n. </strong> Grey metallic element.</p>",
      },
    ],
  },
  {
    word: "Zit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zit <strong class='noun'> n. </strong> Esp. Us slang pimple. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Zit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zit <strong class='noun'> n. </strong> Esp. Us slang pimple. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Zither",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zither <strong class='noun'> n. </strong> Stringed instrument with a flat soundbox, placed horizontally and played with the fingers and a plectrum. [latin: related to *guitar]</p>",
      },
    ],
  },
  {
    word: "Zloty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zloty <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> chief monetary unit of poland. [polish]</p>",
      },
    ],
  },
  {
    word: "Zn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Zn <strong class='prep'> symb. </strong> Zinc.</p>",
      },
    ],
  },
  {
    word: "Zodiac",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zodiac <strong class='noun'> n. </strong> 1 belt of the heavens including all apparent positions of the sun, moon, and planets as known to ancient astronomers, and divided into twelve equal parts (signs of the zodiac). 2 diagram of these signs. zodiacal adj. [greek zoion animal]</p>",
      },
    ],
  },
  {
    word: "Zombie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zombie <strong class='noun'> n. </strong> 1 <strong class='colloq'> colloq. </strong> Person who acts mechanically or lifelessly. 2 corpse said to have been revived by witchcraft. [west african]</p>",
      },
    ],
  },
  {
    word: "Zone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zone <strong class='noun'> —n. </strong> 1 area having particular features, properties, purpose, or use (danger zone; smokeless zone). 2 well-defined region of more or less beltlike form. 3 area between two concentric circles. 4 encircling band of colour etc. 5 archaic belt, girdle. —v. (-ning) 1 encircle as or with a zone. 2 arrange or distribute by zones. 3 assign as or to a particular area. zonal adj. [greek zone girdle]</p>",
      },
    ],
  },
  {
    word: "Zonked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zonked <strong class='adj'> adj. </strong> Slang (often foll. By out) exhausted; intoxicated. [zonk hit]</p>",
      },
    ],
  },
  {
    word: "Zoo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zoo <strong class='noun'> n. </strong> Zoological garden. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Zoological",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zoological <strong class='adj'> adj. </strong> Of zoology. <strong class='usage'> Usage:- </strong>see note at zoology.</p>",
      },
    ],
  },
  {
    word: "Zoological garden",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zoological garden <strong class='noun'> n. </strong> (also zoological gardens n.pl.) Public garden or park with a collection of animals for exhibition and study.</p>",
      },
    ],
  },
  {
    word: "Zoology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zoology <strong class='noun'> n. </strong> The study of animals. zoologist <strong class='noun'> n. </strong> [greek zoion animal] <strong class='usage'> Usage:- </strong>the second pronunciation given for zoology, zoological, and zoologist, with the first syllable pronounced as in zoo, although extremely common, is considered incorrect by some people.</p>",
      },
    ],
  },
  {
    word: "Zoom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zoom <strong class='noun'> —v. </strong> 1 move quickly, esp. With a buzzing sound. 2 cause an aeroplane to mount at high speed and a steep angle. 3 (often foll. By in or in on) (of a camera) change rapidly from a long shot to a close-up (of). 4 (of prices etc.) Rise sharply. <strong class='noun'> —n. </strong> 1 aeroplane's steep climb. 2 zooming camera shot. [imitative]</p>",
      },
    ],
  },
  {
    word: "Zoom lens",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zoom lens <strong class='noun'> n. </strong> Lens allowing a camera to zoom by varying the focal length.</p>",
      },
    ],
  },
  {
    word: "Zoophyte",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zoophyte <strong class='noun'> n. </strong> Plantlike animal, esp. A coral, sea anemone, or sponge. [greek zoion animal, phuton plant]</p>",
      },
    ],
  },
  {
    word: "Zoroastrian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zoroastrian <strong class='general'>(also zarathustrian)</strong>  <strong class='adj'> —adj.</strong> Of zoroaster (or zarathustra) or the dualistic religious system taught by him. <strong class='noun'> —n. </strong> Follower of zoroaster. zoroastrianism <strong class='noun'> n. </strong> [zoroaster, persian founder of the religion]</p>",
      },
    ],
  },
  {
    word: "Zr",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Zr <strong class='prep'> symb. </strong> Zirconium.</p>",
      },
    ],
  },
  {
    word: "Zucchini",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zucchini <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> esp.  <strong class='archaic_it_was'>Us & austral.</strong> Courgette. [italian, <strong class='pl'> pl. </strong> Of zucchino, diminutive of zucca gourd]</p>",
      },
    ],
  },
  {
    word: "Zulu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Zulu <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 member of a s. African bantu people. 2 their language. <strong class='adj'> —adj.</strong> Of this people or language. [native name]</p>",
      },
    ],
  },
];

export const alphabet_Z_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़बाग्लियोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बौड़म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गाली मार देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झगड़ालू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़राथुस्ट्रियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्साह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कट्टरपंथी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्साही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ेबरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ेबरा क्रॉसिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ेबू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युगचेतना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जांचना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ेंड-अबेस्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शीर्षबिंदु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हलकी हवा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टसेपेल्लिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शून्य विकल्प</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ीरो-रेटेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तेजकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ुग्मा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़िगगुरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वक्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुछ भी नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंख्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिम्मेर फ्रेम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जस्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़िनिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सिय्योन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीयनीज़्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़िप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिन कोड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़िपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यवसायिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिक्रोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>zirconium</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़िट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़िट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिट्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़्लॉटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जांचा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राशि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ोंबी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्षेत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ोंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिड़ियाघर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूलॉजिकल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ूलॉजिकल गार्डन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राणि विज्ञान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ूम लेंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ोफ़ीट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पारसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जांचा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुरई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ुलु</p>",
  },
];
