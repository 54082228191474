export const alphabet_V = [
  {
    word: "V",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p> </p>",
      },
    ],
  },
  {
    word: "V1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>V1 <strong class='noun'> n. </strong> (also v) (pl. Vs or v's) 1 twenty-second letter of the alphabet. 2 v-shaped thing. 3 (as a roman numeral) 5.</p>",
      },
    ],
  },
  {
    word: "V2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>V2 <strong class='sing'> abbr. </strong> Volt(s).</p>",
      },
    ],
  },
  {
    word: "V3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>V3 <strong class='prep'> symb. </strong> Vanadium.</p>",
      },
    ],
  },
  {
    word: "V.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>V. <strong class='abbr'> Abbr. </strong> 1 verse. 2 versus. 3 very. 4 vide.</p>",
      },
    ],
  },
  {
    word: "Vac",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vac <strong class='noun'> n. </strong> Colloq. 1 vacation. 2 vacuum cleaner. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Vacancy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacancy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 being vacant. 2 unoccupied job. 3 available room in a hotel etc.</p>",
      },
    ],
  },
  {
    word: "Vacant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacant <strong class='adj'> adj. </strong> 1 not filled or occupied. 2 not mentally active; showing no interest. vacantly <strong class='adv'> adv. </strong> [latin: related to *vacate]</p>",
      },
    ],
  },
  {
    word: "Vacant possession",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacant possession <strong class='noun'> n. </strong> Ownership of an unoccupied house etc.</p>",
      },
    ],
  },
  {
    word: "Vacate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> leave vacant, cease to occupy (a house, post, etc.). [latin vaco be empty]</p>",
      },
    ],
  },
  {
    word: "Vacation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacation <strong class='noun'> —n. </strong> 1 fixed holiday period, esp. In universities and lawcourts. 2 us holiday. 3 vacating or being vacated. —v. Us take a holiday. [latin: related to *vacate]</p>",
      },
    ],
  },
  {
    word: "Vaccinate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vaccinate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> inoculate with a vaccine to immunize against a disease. vaccination <strong class='noun'> n. </strong> Vaccinator n.</p>",
      },
    ],
  },
  {
    word: "Vaccine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vaccine <strong class='noun'> n. </strong> Preparation, <strong class='archaic_it_was'> orig. </strong> Cowpox virus, used in vaccination. [latin vacca cow]</p>",
      },
    ],
  },
  {
    word: "Vacillate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacillate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> be irresolute; fluctuate. vacillation <strong class='noun'> n. </strong> Vacillator <strong class='noun'> n. </strong> [latin]</p>",
      },
    ],
  },
  {
    word: "Vacuole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacuole <strong class='noun'> n. </strong> Tiny space in an organ or cell, containing air, fluid, etc. [latin vacuus empty]</p>",
      },
    ],
  },
  {
    word: "Vacuous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacuous <strong class='adj'> adj. </strong> 1 expressionless. 2 showing absence of thought or intelligence, inane. vacuity <strong class='noun'> n. </strong> Vacuously <strong class='adv'> adv. </strong> [latin vacuus empty]</p>",
      },
    ],
  },
  {
    word: "Vacuum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacuum <strong class='noun'> —n. </strong> (pl. -s or -cua) 1 space entirely devoid of matter. 2 space or vessel from which all or some of the air has been pumped out. 3 absence of the normal or previous content, activities, etc. 4 <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='colloq'> colloq. </strong> Vacuum cleaner. —v. Colloq. Clean with a vacuum cleaner. [latin vacuus empty]</p>",
      },
    ],
  },
  {
    word: "Vacuum brake",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacuum brake <strong class='noun'> n. </strong> Brake worked by the exhaustion of air.</p>",
      },
    ],
  },
  {
    word: "Vacuum cleaner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacuum cleaner <strong class='noun'> n. </strong> Machine for removing dust etc. By suction. vacuum-clean v.</p>",
      },
    ],
  },
  {
    word: "Vacuum flask",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacuum flask <strong class='noun'> n. </strong> Vessel with a double wall enclosing a vacuum, ensuring that the contents remain hot or cold.</p>",
      },
    ],
  },
  {
    word: "Vacuum-packed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacuum-packed <strong class='adj'> adj. </strong> Sealed after the partial removal of air.</p>",
      },
    ],
  },
  {
    word: "Vacuum tube",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vacuum tube <strong class='noun'> n. </strong> Tube with a near-vacuum for the free passage of electric current.</p>",
      },
    ],
  },
  {
    word: "Vade-mecum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vade-mecum <strong class='noun'> n. </strong> Handbook etc. Used constantly. [latin, = go with me]</p>",
      },
    ],
  },
  {
    word: "Vagabond",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vagabond <strong class='noun'> —n. </strong> Wanderer, esp. An idle one. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Wandering, roving. vagabondage <strong class='noun'> n. </strong> [latin vagor wander]</p>",
      },
    ],
  },
  {
    word: "Vagary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vagary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> caprice, whim. [latin vagor wander]</p>",
      },
    ],
  },
  {
    word: "Vagina",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vagina <strong class='noun'> n. </strong> (pl. -s or -nae) canal from the uterus to the vulva in female mammals. vaginal adj. [latin, = sheath]</p>",
      },
    ],
  },
  {
    word: "Vagrant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vagrant <strong class='noun'> —n. </strong> Unemployed itinerant. <strong class='adj'> —adj.</strong> Wandering, roving. vagrancy <strong class='noun'> n. </strong> [anglo-french]</p>",
      },
    ],
  },
  {
    word: "Vague",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vague <strong class='adj'> adj. </strong> 1 uncertain or ill-defined. 2 (of a person or mind) imprecise; inexact in thought, expression, or understanding. vaguely <strong class='adv'> adv. </strong> Vagueness <strong class='noun'> n. </strong> [latin vagus wandering]</p>",
      },
    ],
  },
  {
    word: "Vain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vain <strong class='adj'> adj. </strong> 1 having too high an opinion of one's looks, abilities, etc. 2 empty, trivial (vain triumphs). 3 useless; futile (in the vain hope of finding it). in vain 1 without success. 2 lightly or profanely (take his name in vain). vainly <strong class='adv'> adv. </strong> [latin vanus]</p>",
      },
    ],
  },
  {
    word: "Vainglory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vainglory <strong class='noun'> n. </strong> Boastfulness; extreme vanity. vainglorious adj. [french vaine gloire]</p>",
      },
    ],
  },
  {
    word: "Valance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valance <strong class='noun'> n. </strong> (also valence) short curtain round the frame or canopy of a bedstead, above a window, etc. [anglo-french valer descend]</p>",
      },
    ],
  },
  {
    word: "Vale",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vale <strong class='noun'> n. </strong> (archaic except in place-names) valley. [latin vallis]</p>",
      },
    ],
  },
  {
    word: "Valediction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valediction <strong class='noun'> n. </strong> Formal 1 bidding farewell. 2 words used in this. valedictory adj. & <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. [latin vale farewell]</p>",
      },
    ],
  },
  {
    word: "Valence1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valence1 <strong class='noun'> n. </strong> = *valency.</p>",
      },
    ],
  },
  {
    word: "Valence2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valence2 <strong class='var'> var. </strong> Of *valance.</p>",
      },
    ],
  },
  {
    word: "Valency",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valency <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> combining power of an atom measured by the number of hydrogen atoms it can displace or combine with. [latin valentia power]</p>",
      },
    ],
  },
  {
    word: "Valentine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valentine <strong class='noun'> n. </strong> 1 card sent, often anonymously, as a mark of love on st valentine's day (14 feb.). 2 sweetheart chosen on this day. [valentine, name of two saints]</p>",
      },
    ],
  },
  {
    word: "Valerian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valerian <strong class='noun'> n. </strong> Any of various flowering herbs, esp. Used as a sedative. [french from medieval latin]</p>",
      },
    ],
  },
  {
    word: "Valet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valet <strong class='noun'> —n. </strong> Gentleman's personal servant. —v. (-t-) 1 work as a valet (for). 2 clean or clean out (a car). [french va(s)let, related to *varlet, *vassal]</p>",
      },
    ],
  },
  {
    word: "Valetudinarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valetudinarian <strong class='noun'> —n. </strong> Person of poor health or who is unduly anxious about health. <strong class='adj'> —adj.</strong> Of a valetudinarian. valetudinarianism <strong class='noun'> n. </strong> [latin valetudo health]</p>",
      },
    ],
  },
  {
    word: "Valiant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valiant <strong class='adj'> adj. </strong> Brave. valiantly <strong class='adv'> adv. </strong> [latin valeo be strong]</p>",
      },
    ],
  },
  {
    word: "Valid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valid <strong class='adj'> adj. </strong> 1 (of a reason, objection, etc.) Sound, defensible. 2 a executed with the proper formalities, legally acceptable (valid contract; valid passport). B not yet expired. validity <strong class='noun'> n. </strong> [latin validus strong: related to *valiant]</p>",
      },
    ],
  },
  {
    word: "Validate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Validate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> make valid; ratify. validation n.</p>",
      },
    ],
  },
  {
    word: "Valise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valise <strong class='noun'> n. </strong> Us small portmanteau. [french from italian]</p>",
      },
    ],
  },
  {
    word: "Valium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valium <strong class='noun'> n. </strong> Propr. Drug diazepam used as a tranquillizer. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Valley",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valley <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> low area between hills, usu. With a stream or river flowing through it. [french: related to *vale]</p>",
      },
    ],
  },
  {
    word: "Valor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valor <strong class='noun'> n. </strong> (brit. Valour) courage, esp. In battle. valorous adj. [latin valeo be strong]</p>",
      },
    ],
  },
  {
    word: "Valour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valour <strong class='noun'> n. </strong> (us valor) courage, esp. In battle. valorous adj. [latin valeo be strong]</p>",
      },
    ],
  },
  {
    word: "Valuable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valuable <strong class='adj'> —adj.</strong> Of great value, price, or worth. <strong class='noun'> —n. </strong> (usu. In pl.) Valuable thing. valuably adv.</p>",
      },
    ],
  },
  {
    word: "Valuation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valuation <strong class='noun'> n. </strong> 1 estimation (esp. Professional) of a thing's worth. 2 worth so estimated.</p>",
      },
    ],
  },
  {
    word: "Value",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Value <strong class='noun'> —n. </strong> 1 worth, desirability, or utility, or the qualities on which these depend. 2 worth as estimated (set a high value on my time). 3 amount for which a thing can be exchanged in the open market. 4 equivalent of a thing. 5 (in full value for money) something well worth the money spent. 6 effectiveness (news value). 7 (in pl.) One's principles, priorities, or standards. 8 <strong class='mus'> mus. </strong> Duration of a note. 9 math. Amount denoted by an algebraic term. —v. (-ues, -ued, -uing) 1 estimate the value of, esp. Professionally. 2 have a high or specified opinion of. valueless adj. Valuer <strong class='noun'> n. </strong> [french  <strong class='archaic_it_was'>past part.</strong> Of valoir be worth, from latin valeo]</p>",
      },
    ],
  },
  {
    word: "Value added tax",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Value added tax <strong class='noun'> n. </strong> Tax levied on the rise in value of services and goods at each stage of production.</p>",
      },
    ],
  },
  {
    word: "Value judgement",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Value judgement <strong class='noun'> n. </strong> Subjective estimate of worth etc.</p>",
      },
    ],
  },
  {
    word: "Valve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Valve <strong class='noun'> n. </strong> 1 device controlling flow through a pipe etc., esp. Allowing movement in one direction only. 2 structure in an organ etc. Allowing a flow of blood etc. In one direction only. 3 = *thermionic valve. 4 device to vary the effective length of the tube in a trumpet etc. 5 half-shell of an oyster, mussel, etc. valvular adj. [latin valva leaf of a folding door]</p>",
      },
    ],
  },
  {
    word: "Vamoose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vamoose <strong class='verb'> v. </strong> Us slang depart hurriedly. [spanish vamos let us go]</p>",
      },
    ],
  },
  {
    word: "Vamp1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vamp1 <strong class='noun'> —n. </strong> Upper front part of a boot or shoe. —v. 1 (often foll. By up) repair or furbish. 2 (foll. By up) make by patching or from odds and ends. 3 improvise a musical accompaniment. [french avantpié front of the foot]</p>",
      },
    ],
  },
  {
    word: "Vamp2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vamp2 <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Woman who uses sexual attraction to exploit men. —v. Allure and exploit (a man). [abbreviation of *vampire]</p>",
      },
    ],
  },
  {
    word: "Vampire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vampire <strong class='noun'> n. </strong> 1 supposed ghost or reanimated corpse sucking the blood of sleeping persons. 2 person who preys ruthlessly on others. 3 (in full vampire bat) tropical (esp. South american) bloodsucking bat. [french or german from magyar]</p>",
      },
    ],
  },
  {
    word: "Van1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Van1 <strong class='noun'> n. </strong> 1 small covered goods vehicle. 2 railway carriage for luggage and for the guard. [abbreviation of *caravan]</p>",
      },
    ],
  },
  {
    word: "Van2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Van2 <strong class='noun'> n. </strong> Vanguard, forefront. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Vanadium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanadium <strong class='noun'> n. </strong> Hard grey metallic element used to strengthen steel. [old norse vanadís name of the scandinavian goddess freyja]</p>",
      },
    ],
  },
  {
    word: "Vandal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vandal <strong class='noun'> n. </strong> Person who wilfully or maliciously damages property. vandalism <strong class='noun'> n. </strong> [vandals, name of a germanic people that sacked rome and destroyed works of art in the 5th c.: latin from germanic]</p>",
      },
    ],
  },
  {
    word: "Vandalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vandalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) wilfully or maliciously destroy or damage (esp. Public property).</p>",
      },
    ],
  },
  {
    word: "Vane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vane <strong class='noun'> n. </strong> 1 weather-vane. 2 blade of a screw propeller or windmill etc. [dial. Var. Of obsolete fane banner]</p>",
      },
    ],
  },
  {
    word: "Vanguard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanguard <strong class='noun'> n. </strong> 1 foremost part of an advancing army etc. 2 leaders of a movement etc. [french avan(t)garde from avant before: related to *guard]</p>",
      },
    ],
  },
  {
    word: "Vanilla",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanilla <strong class='noun'> n. </strong> 1 a tropical fragrant climbing orchid. B (in full vanilla-pod) fruit of this. 2 extract from the vanilla-pod, or a synthetic substance, used as flavouring. [spanish diminutive of vaina pod]</p>",
      },
    ],
  },
  {
    word: "Vanish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanish <strong class='verb'> v. </strong> 1 disappear. 2 cease to exist. [latin: related to *vain]</p>",
      },
    ],
  },
  {
    word: "Vanishing cream",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanishing cream <strong class='noun'> n. </strong> Skin ointment that leaves no visible trace.</p>",
      },
    ],
  },
  {
    word: "Vanishing-point",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanishing-point <strong class='noun'> n. </strong> 1 point at which receding parallel lines appear to meet. 2 stage of complete disappearance.</p>",
      },
    ],
  },
  {
    word: "Vanity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 conceit about one's appearance or attainments. 2 futility, unsubstantiality, unreal thing (the vanity of human achievement). 3 ostentatious display. [latin: related to *vain]</p>",
      },
    ],
  },
  {
    word: "Vanity bag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanity bag <strong class='noun'> n. </strong> (also vanity case) woman's make-up bag or case.</p>",
      },
    ],
  },
  {
    word: "Vanity unit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanity unit <strong class='noun'> n. </strong> Wash-basin set into a unit with cupboards beneath.</p>",
      },
    ],
  },
  {
    word: "Vanquish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vanquish <strong class='verb'> v. </strong> Literary conquer, overcome. [latin vinco]</p>",
      },
    ],
  },
  {
    word: "Vantage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vantage <strong class='noun'> n. </strong> 1 (also vantage point) place giving a good view. 2 tennis = *advantage. [french: related to *advantage]</p>",
      },
    ],
  },
  {
    word: "Vapid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vapid <strong class='adj'> adj. </strong> Insipid; dull; flat. vapidity <strong class='noun'> n. </strong> [latin vapidus]</p>",
      },
    ],
  },
  {
    word: "Vapor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vapor <strong class='noun'> n. </strong> (brit. Vapour) 1 moisture or other substance diffused or suspended in air, e.g. Mist, smoke. 2 gaseous form of a substance. 3 medicinal inhalant. vaporous adj. Vapoury adj. [latin vapor steam]</p>",
      },
    ],
  },
  {
    word: "Vaporize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vaporize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) change into vapour. vaporization n.</p>",
      },
    ],
  },
  {
    word: "Vapour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vapour <strong class='noun'> n. </strong> (us vapor) 1 moisture or other substance diffused or suspended in air, e.g. Mist, smoke. 2 gaseous form of a substance. 3 medicinal inhalant. vaporous adj. Vapoury adj. [latin vapor steam]</p>",
      },
    ],
  },
  {
    word: "Vapour trail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vapour trail <strong class='noun'> n. </strong> Trail of condensed water from an aircraft etc.</p>",
      },
    ],
  },
  {
    word: "Variable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Variable <strong class='adj'> —adj.</strong> 1 changeable, adaptable. 2 apt to vary; not constant. 3 math. (of a quantity) indeterminate; able to assume different numerical values. <strong class='noun'> —n. </strong> Variable thing or quantity. variability <strong class='noun'> n. </strong> Variably adv.</p>",
      },
    ],
  },
  {
    word: "Variance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Variance <strong class='noun'> n. </strong> 1 (usu. Prec. By at) difference of opinion; dispute (we were at variance). 2 discrepancy.</p>",
      },
    ],
  },
  {
    word: "Variant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Variant <strong class='adj'> —adj.</strong> 1 differing in form or details from a standard (variant spelling). 2 having different forms (forty variant types). <strong class='noun'> —n. </strong> Variant form, spelling, type, etc.</p>",
      },
    ],
  },
  {
    word: "Variation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Variation <strong class='noun'> n. </strong> 1 varying. 2 departure from the normal kind, amount, a standard, etc. (prices are subject to variation). 3 extent of this. 4 variant thing. 5 <strong class='mus'> mus. </strong> Theme in a changed or elaborated form.</p>",
      },
    ],
  },
  {
    word: "Varicolored",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Varicolored <strong class='adj'> adj. </strong> (brit. Varicoloured) 1 variegated in colour. 2 of various colours. [latin varius *various]</p>",
      },
    ],
  },
  {
    word: "Varicoloured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Varicoloured <strong class='adj'> adj. </strong> (us varicolored) 1 variegated in colour. 2 of various colours. [latin varius *various]</p>",
      },
    ],
  },
  {
    word: "Varicose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Varicose <strong class='adj'> adj. </strong> (esp. Of a vein etc.) Permanently and abnormally dilated. [latin varix varicose vein]</p>",
      },
    ],
  },
  {
    word: "Varied",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Varied <strong class='adj'> adj. </strong> Showing variety.</p>",
      },
    ],
  },
  {
    word: "Variegated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Variegated <strong class='adj'> adj. </strong> 1 with irregular patches of different colours. 2 having leaves of two or more colours. variegation <strong class='noun'> n. </strong> [latin: related to *various]</p>",
      },
    ],
  },
  {
    word: "Variety",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Variety <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 diversity; absence of uniformity; many-sidedness. 2 quantity or collection of different things (for a variety of reasons). 3 a class of things that differ from the rest in the same general class. B member of such a class. 4 (foll. By of) different form of a thing, quality, etc. 5 biol. Subdivision of a species. 6 series of dances, songs, comedy acts, etc. (variety show). [latin: related to *various]</p>",
      },
    ],
  },
  {
    word: "Various",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Various <strong class='adj'> adj. </strong> 1 different, diverse (from various backgrounds). 2 several (for various reasons). variously <strong class='adv'> adv. </strong> [latin varius] <strong class='usage'> Usage:- </strong>various (unlike several) cannot be used with of, as (wrongly) in various of the guests arrived late.</p>",
      },
    ],
  },
  {
    word: "Varlet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Varlet <strong class='noun'> n. </strong> Archaic menial; rascal. [french <strong class='var'> var. </strong> Of vaslet *valet]</p>",
      },
    ],
  },
  {
    word: "Varnish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Varnish <strong class='noun'> —n. </strong> 1 resinous solution used to give a hard shiny transparent coating. 2 similar preparation (nail varnish). 3 deceptive outward appearance or show. —v. 1 apply varnish to. 2 give a deceptively attractive appearance to. [french vernis, probably ultimately from berenice in cyrenaica]</p>",
      },
    ],
  },
  {
    word: "Varsity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Varsity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> <strong class='colloq'> colloq. </strong> (esp. With ref. To sports) university. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Vary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vary <strong class='verb'> v. </strong> (-ies, -ied) 1 be or become different; be of different kinds; change. 2 make different; modify. [latin vario: related to *various]</p>",
      },
    ],
  },
  {
    word: "Vas",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vas <strong class='noun'> n. </strong> (pl. Vasa) vessel or duct. [latin, = vessel]</p>",
      },
    ],
  },
  {
    word: "Vascular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vascular <strong class='adj'> adj. </strong> Of or containing vessels for conveying blood, sap, etc. [latin vasculum diminutive of *vas]</p>",
      },
    ],
  },
  {
    word: "Vas deferens",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vas deferens <strong class='noun'> n. </strong> (pl. Vasa deferentia) sperm duct of the testicle.</p>",
      },
    ],
  },
  {
    word: "Vase",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vase <strong class='noun'> n. </strong> Vessel used as an ornament or container for flowers. [latin: related to *vas]</p>",
      },
    ],
  },
  {
    word: "Vasectomy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vasectomy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> removal of part of each vas deferens, esp. For sterilization.</p>",
      },
    ],
  },
  {
    word: "Vaseline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vaseline <strong class='noun'> n. </strong> Propr. Type of petroleum jelly used as an ointment etc. [german wasser water, greek elaion oil]</p>",
      },
    ],
  },
  {
    word: "Vassal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vassal <strong class='noun'> n. </strong> 1 hist. Feudal tenant of land. 2 humble dependant. vassalage <strong class='noun'> n. </strong> [medieval latin vassallus retainer]</p>",
      },
    ],
  },
  {
    word: "Vast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vast <strong class='adj'> adj. </strong> Immense, huge. vastly <strong class='adv'> adv. </strong> Vastness <strong class='noun'> n. </strong> [latin]</p>",
      },
    ],
  },
  {
    word: "Vat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vat <strong class='sing'> abbr. </strong> Value added tax.</p>",
      },
    ],
  },
  {
    word: "Vat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vat <strong class='noun'> n. </strong> Tank, esp. For holding liquids in brewing, distilling, food manufacture, dyeing, and tanning. [dial. Var. Of fat, from old english]</p>",
      },
    ],
  },
  {
    word: "Vatican",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vatican <strong class='noun'> n. </strong> Palace or government of the pope in rome. [name of a hill in rome]</p>",
      },
    ],
  },
  {
    word: "Vaudeville",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vaudeville <strong class='noun'> n. </strong> Esp. Us 1 variety entertainment. 2 light stage play with interspersed songs. vaudevillian adj. & <strong class='noun'> n. </strong> [french]</p>",
      },
    ],
  },
  {
    word: "Vault",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vault <strong class='noun'> —n. </strong> 1 arched roof. 2 vaultlike covering (vault of heaven). 3 underground storage chamber or place of interment beneath a church or in a cemetery etc. 4 act of vaulting. —v. 1 leap, esp. Using the hands or a pole. 2 spring over in this way. 3 (esp. As vaulted) a make in the form of a vault. B provide with a vault or vaults. [latin volvo roll]</p>",
      },
    ],
  },
  {
    word: "Vaulting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vaulting <strong class='noun'> n. </strong> Arched work in a vaulted roof or ceiling.</p>",
      },
    ],
  },
  {
    word: "Vaulting-horse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vaulting-horse <strong class='noun'> n. </strong> Wooden box for vaulting over.</p>",
      },
    ],
  },
  {
    word: "Vaunt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vaunt <strong class='verb'> v. </strong> & <strong class='noun'> n. </strong> Literary boast. [latin: related to *vain]</p>",
      },
    ],
  },
  {
    word: "Vc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vc <strong class='sing'> abbr. </strong> Victoria cross.</p>",
      },
    ],
  },
  {
    word: "Vcr",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vcr <strong class='sing'> abbr. </strong> Video cassette recorder.</p>",
      },
    ],
  },
  {
    word: "Vd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vd <strong class='sing'> abbr. </strong> Venereal disease.</p>",
      },
    ],
  },
  {
    word: "Vdu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vdu <strong class='sing'> abbr. </strong> Visual display unit.</p>",
      },
    ],
  },
  {
    word: "Ve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ve <strong class='sing'> abbr. </strong> Victory in europe (in 1945).</p>",
      },
    ],
  },
  {
    word: "'Ve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>'Ve <strong class='sing'> abbr. </strong> (usu. After pronouns) have (i've; they've).</p>",
      },
    ],
  },
  {
    word: "Veal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veal <strong class='noun'> n. </strong> Calf's flesh as food. [french from latin vitulus calf]</p>",
      },
    ],
  },
  {
    word: "Vector",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vector <strong class='noun'> n. </strong> 1 math. & physics quantity having direction as well as magnitude. 2 carrier of disease. [latin veho vect- convey]</p>",
      },
    ],
  },
  {
    word: "Veda",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veda <strong class='noun'> n. </strong> (in sing. Or pl.) Oldest hindu scriptures. vedic adj. [sanskrit, = knowledge]</p>",
      },
    ],
  },
  {
    word: "Ve day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ve day <strong class='noun'> n. </strong> 8 may, the day marking victory in europe (in 1945).</p>",
      },
    ],
  },
  {
    word: "Veer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veer <strong class='noun'> —v. </strong> 1 change direction, esp. (of the wind) clockwise. 2 change in course or opinion etc. <strong class='noun'> —n. </strong> Change of direction. [french virer]</p>",
      },
    ],
  },
  {
    word: "Veg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veg <strong class='noun'> n. </strong> Colloq. Vegetable(s). [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Vegan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vegan <strong class='noun'> —n. </strong> Person who does not eat animals or animal products. <strong class='adj'> —adj.</strong> Using or containing no animal products. [shortening of *vegetarian]</p>",
      },
    ],
  },
  {
    word: "Vegeburger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vegeburger <strong class='var'> var. </strong> Of *veggie burger.</p>",
      },
    ],
  },
  {
    word: "Vegetable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vegetable <strong class='noun'> —n. </strong> 1 plant, esp. A herbaceous plant used for food, e.g. A cabbage, potato, or bean. 2 <strong class='colloq'> colloq. </strong> Derog. A offens. Person who is severely mentally incapacitated, esp. Through brain injury etc. B dull or inactive person. <strong class='adj'> —adj.</strong> Of, derived from, or relating to plant life or vegetables as food. [latin: related to *vegetate]</p>",
      },
    ],
  },
  {
    word: "Vegetable marrow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vegetable marrow <strong class='see'> see *</strong> marrow 1.</p>",
      },
    ],
  },
  {
    word: "Vegetal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vegetal <strong class='adj'> adj. </strong> Of or like plants. [medieval latin: related to *vegetate]</p>",
      },
    ],
  },
  {
    word: "Vegetarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vegetarian <strong class='noun'> —n. </strong> Person who does not eat meat or fish. <strong class='adj'> —adj.</strong> Excluding animal food, esp. Meat (vegetarian diet). vegetarianism <strong class='noun'> n. </strong> [from *vegetable]</p>",
      },
    ],
  },
  {
    word: "Vegetate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vegetate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 live an uneventful or monotonous life. 2 grow as plants do. [latin vegeto animate]</p>",
      },
    ],
  },
  {
    word: "Vegetation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vegetation <strong class='noun'> n. </strong> Plants collectively; plant life. [medieval latin: related to *vegetate]</p>",
      },
    ],
  },
  {
    word: "Vegetative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vegetative <strong class='adj'> adj. </strong> 1 concerned with growth and development as distinct from sexual reproduction. 2 of vegetation. [french or medieval latin: related to *vegetate]</p>",
      },
    ],
  },
  {
    word: "Veggie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veggie <strong class='noun'> n. </strong> (also vegie) <strong class='colloq'> colloq. </strong> Vegetarian. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Veggie burger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veggie burger <strong class='noun'> n. </strong> (also vegeburger) flat cake like a hamburger but containing vegetables or soya protein instead of meat.</p>",
      },
    ],
  },
  {
    word: "Vehement",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vehement <strong class='adj'> adj. </strong> Showing or caused by strong feeling; ardent (vehement protest). vehemence <strong class='noun'> n. </strong> Vehemently <strong class='adv'> adv. </strong> [latin]</p>",
      },
    ],
  },
  {
    word: "Vehicle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vehicle <strong class='noun'> n. </strong> 1 conveyance used on land or in space. 2 thing or person as a medium for expression or action. 3 liquid etc. As a medium for suspending pigments, drugs, etc. vehicular adj. [latin veho carry]</p>",
      },
    ],
  },
  {
    word: "Veil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veil <strong class='noun'> —n. </strong> 1 piece of usu. Transparent fabric attached to a woman's hat etc., esp. To conceal or protect the face. 2 piece of linen etc. As part of a nun's headdress. 3 thing that hides or disguises (a veil of silence). —v. 1 cover with a veil. 2 (esp. As veiled adj.) Partly conceal (veiled threats). beyond the veil in the unknown state of life after death. Draw a veil over avoid discussing; hush up. Take the veil become a nun. [latin velum]</p>",
      },
    ],
  },
  {
    word: "Vein",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vein <strong class='noun'> n. </strong> 1 a any of the tubes conveying blood to the heart. B (in general use) any blood-vessel. 2 rib of an insect's wing or leaf. 3 streak of a different colour in wood, marble, cheese, etc. 4 fissure in rock filled with ore. 5 specified character or tendency; mood (spoke in a sarcastic vein). veined adj. Veiny adj. (-ier, -iest). [latin vena]</p>",
      },
    ],
  },
  {
    word: "Velcro",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Velcro <strong class='noun'> n. </strong> Propr. Fastener consisting of two strips of fabric which cling when pressed together. [french velours croché hooked velvet]</p>",
      },
    ],
  },
  {
    word: "Veld",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veld <strong class='noun'> n. </strong> (also veldt) s.afr. Open country. [afrikaans: related to *field]</p>",
      },
    ],
  },
  {
    word: "Veleta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veleta <strong class='noun'> n. </strong> Ballroom dance in triple time. [spanish, = weather-vane]</p>",
      },
    ],
  },
  {
    word: "Vellum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vellum <strong class='noun'> n. </strong> 1 a fine parchment, <strong class='archaic_it_was'> orig. </strong> Calfskin. B manuscript on this. 2 smooth writing-paper imitating vellum. [french velin: related to *veal]</p>",
      },
    ],
  },
  {
    word: "Velocity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Velocity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> speed, esp. Of inanimate things (wind velocity; velocity of light). [latin velox swift]</p>",
      },
    ],
  },
  {
    word: "Velodrome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Velodrome <strong class='noun'> n. </strong> Place or building with a track for cycle-racing. [french vélo bicycle]</p>",
      },
    ],
  },
  {
    word: "Velour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Velour <strong class='noun'> n. </strong> (also velours pronunc. Same) plushlike fabric. [french]</p>",
      },
    ],
  },
  {
    word: "Velvet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Velvet <strong class='noun'> —n. </strong> 1 soft fabric with a thick short pile on one side. 2 furry skin on a growing antler. <strong class='adj'> —adj.</strong> Of, like, or soft as velvet. on velvet in an advantageous or prosperous position. velvety adj. [latin villus tuft, down]</p>",
      },
    ],
  },
  {
    word: "Velveteen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Velveteen <strong class='noun'> n. </strong> Cotton fabric with a pile like velvet.</p>",
      },
    ],
  },
  {
    word: "Velvet glove",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Velvet glove <strong class='noun'> n. </strong> Outward gentleness, esp. Cloaking firmness.</p>",
      },
    ],
  },
  {
    word: "Ven.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ven. <strong class='abbr'> Abbr. </strong> Venerable (as the title of an archdeacon).</p>",
      },
    ],
  },
  {
    word: "Venal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venal <strong class='adj'> adj. </strong> Corrupt; able to be bribed; involving bribery. venality <strong class='noun'> n. </strong> Venally <strong class='adv'> adv. </strong> [latin venum thing for sale] <strong class='usage'> Usage:- </strong>venal is sometimes confused with venial, which means ‘pardonable’.</p>",
      },
    ],
  },
  {
    word: "Vend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vend <strong class='verb'> v. </strong> Offer (small wares) for sale. vendible adj. [latin vendo sell]</p>",
      },
    ],
  },
  {
    word: "Vendetta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vendetta <strong class='noun'> n. </strong> 1 blood feud. 2 prolonged bitter quarrel. [latin: related to *vindictive]</p>",
      },
    ],
  },
  {
    word: "Vending-machine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vending-machine <strong class='noun'> n. </strong> Slot-machine selling small items.</p>",
      },
    ],
  },
  {
    word: "Vendor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vendor <strong class='noun'> n. </strong> Law seller, esp. Of property. [anglo-french: related to *vend]</p>",
      },
    ],
  },
  {
    word: "Veneer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veneer <strong class='noun'> —n. </strong> 1 thin covering of fine wood etc. 2 (often foll. By of) deceptively pleasing appearance. —v. 1 apply a veneer to (wood etc.). 2 disguise. [german furnieren to furnish]</p>",
      },
    ],
  },
  {
    word: "Venerable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venerable <strong class='adj'> adj. </strong> 1 entitled to deep respect on account of character, age, associations, etc. (venerable priest; venerable relics). 2 title of an archdeacon in the church of england. [latin: related to *venerate]</p>",
      },
    ],
  },
  {
    word: "Venerate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venerate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> respect deeply. veneration <strong class='noun'> n. </strong> Venerator <strong class='noun'> n. </strong> [latin veneror revere]</p>",
      },
    ],
  },
  {
    word: "Venereal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venereal <strong class='adj'> adj. </strong> 1 of sexual desire or intercourse. 2 of venereal disease. [latin venus veneris sexual love]</p>",
      },
    ],
  },
  {
    word: "Venereal disease",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venereal disease <strong class='noun'> n. </strong> Disease contracted by sexual intercourse with an infected person or congenitally.</p>",
      },
    ],
  },
  {
    word: "Venetian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venetian <strong class='noun'> —n. </strong> Native, citizen, or dialect of venice. <strong class='adj'> —adj.</strong> Of venice. [from french or medieval latin venetia venice]</p>",
      },
    ],
  },
  {
    word: "Venetian blind",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venetian blind <strong class='noun'> n. </strong> Window-blind of adjustable horizontal slats.</p>",
      },
    ],
  },
  {
    word: "Vengeance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vengeance <strong class='noun'> n. </strong> Punishment inflicted for wrong to oneself or one's cause. with a vengeance to a high or excessive degree (punctuality with a vengeance). [french venger from latin vindico avenge]</p>",
      },
    ],
  },
  {
    word: "Vengeful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vengeful <strong class='adj'> adj. </strong> Vindictive; seeking vengeance. vengefully <strong class='adv'> adv. </strong> [obsolete venge avenge: related to *vengeance]</p>",
      },
    ],
  },
  {
    word: "Venial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venial <strong class='adj'> adj. </strong> (of a sin or fault) pardonable; not mortal. veniality <strong class='noun'> n. </strong> Venially <strong class='adv'> adv. </strong> [latin venia forgiveness] <strong class='usage'> Usage:- </strong>venial is sometimes confused with venal, which means ‘corrupt’.</p>",
      },
    ],
  },
  {
    word: "Venison",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venison <strong class='noun'> n. </strong> Deer's flesh as food. [latin venatio hunting]</p>",
      },
    ],
  },
  {
    word: "Venn diagram",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venn diagram <strong class='noun'> n. </strong> Diagram using overlapping and intersecting circles etc. To show the relationships between mathematical sets. [venn, name of a logician]</p>",
      },
    ],
  },
  {
    word: "Venom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venom <strong class='noun'> n. </strong> 1 poisonous fluid of esp. Snakes. 2 malignity; virulence. venomous adj. Venomously <strong class='adv'> adv. </strong> [latin venenum]</p>",
      },
    ],
  },
  {
    word: "Venous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venous <strong class='adj'> adj. </strong> Of, full of, or contained in, veins. [latin: related to *vein]</p>",
      },
    ],
  },
  {
    word: "Vent1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vent1 <strong class='noun'> —n. </strong> 1 opening allowing the passage of air etc. 2 outlet; free expression (gave vent to my anger). 3 anus, esp. Of a lower animal. —v. 1 make a vent in (a cask etc.). 2 give free expression to. vent one's spleen on scold or ill-treat without cause. [latin ventus wind]</p>",
      },
    ],
  },
  {
    word: "Vent2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vent2 <strong class='noun'> n. </strong> Slit in a garment, esp. In the lower edge of the back of a jacket. [french fente from latin findo cleave]</p>",
      },
    ],
  },
  {
    word: "Ventilate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ventilate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 cause air to circulate freely in (a room etc.). 2 air (a question, grievance, etc.). 3 med. A oxygenate (the blood). B admit or force air into (the lungs). ventilation <strong class='noun'> n. </strong> [latin ventilo blow, winnow: related to *vent1]</p>",
      },
    ],
  },
  {
    word: "Ventilator",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ventilator <strong class='noun'> n. </strong> 1 appliance or aperture for ventilating a room etc. 2 med. = *respirator 2.</p>",
      },
    ],
  },
  {
    word: "Ventral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ventral <strong class='adj'> adj. </strong> Of or on the abdomen. [venter abdomen, from latin]</p>",
      },
    ],
  },
  {
    word: "Ventricle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ventricle <strong class='noun'> n. </strong> 1 cavity in the body. 2 hollow part of an organ, esp. The brain or heart. ventricular adj. [latin ventriculus diminutive of venter belly]</p>",
      },
    ],
  },
  {
    word: "Ventriloquism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ventriloquism <strong class='noun'> n. </strong> (also ventriloquy) skill of speaking without moving the lips, esp. As entertainment with a dummy. ventriloquist <strong class='noun'> n. </strong> [latin venter belly, loquor speak]</p>",
      },
    ],
  },
  {
    word: "Venture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venture <strong class='noun'> —n. </strong> 1 risky undertaking. 2 commercial speculation. —v. <strong class='adj'> (-ring) </strong> 1 dare; not be afraid. 2 dare to go, make, or put forward (venture out; venture an opinion). 3 a expose to risk; stake. B take risks. [from *adventure]</p>",
      },
    ],
  },
  {
    word: "Venture scout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venture scout <strong class='noun'> n. </strong> Senior scout.</p>",
      },
    ],
  },
  {
    word: "Venturesome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venturesome <strong class='adj'> adj. </strong> 1 disposed to take risks. 2 risky.</p>",
      },
    ],
  },
  {
    word: "Venue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venue <strong class='noun'> n. </strong> Place for a match, meeting, concert, etc. [french, from venir come]</p>",
      },
    ],
  },
  {
    word: "Venus fly-trap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Venus fly-trap <strong class='noun'> n. </strong> Insectivorous plant. [latin venus goddess of love]</p>",
      },
    ],
  },
  {
    word: "Veracious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veracious <strong class='adj'> adj. </strong> Formal 1 truthful by nature. 2 (of a statement etc.) True. veracity <strong class='noun'> n. </strong> [latin verax from verus true]</p>",
      },
    ],
  },
  {
    word: "Veranda",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veranda <strong class='noun'> n. </strong> (usu. Covered) platform along the side of a house. [hindi from portuguese varanda]</p>",
      },
    ],
  },
  {
    word: "Verb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verb <strong class='noun'> n. </strong> Word used to indicate action, a state, or an occurrence (e.g. Hear, be, happen). [latin verbum word]</p>",
      },
    ],
  },
  {
    word: "Verbal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verbal <strong class='adj'> —adj.</strong> 1 of words. 2 oral, not written. 3 of a verb. 4 (of a translation) literal. 5 talkative. <strong class='noun'> —n. </strong> 1 slang verbal statement to the police. 2 slang stream of abuse. verbally <strong class='adv'> adv. </strong> [latin: related to *verb] <strong class='usage'> Usage:- </strong>some people reject sense 2 of verbal as illogical, and prefer oral. However, verbal is the usual term in expressions such as verbal communication, verbal contract, and verbal evidence.</p>",
      },
    ],
  },
  {
    word: "Verbalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verbalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) put into words.</p>",
      },
    ],
  },
  {
    word: "Verbal noun",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verbal noun <strong class='noun'> n. </strong> Noun derived from a verb (e.g. Smoking in smoking is forbidden: see *-ing1).</p>",
      },
    ],
  },
  {
    word: "Verbatim",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verbatim <strong class='adv'> adv. </strong> & adj. In exactly the same words. [medieval latin: related to *verb]</p>",
      },
    ],
  },
  {
    word: "Verbena",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verbena <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> plant of a genus of usu. Annual or biennial plants with clusters of fragrant flowers. [latin]</p>",
      },
    ],
  },
  {
    word: "Verbiage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verbiage <strong class='noun'> n. </strong> Derog. Too many words or unnecessarily difficult words. [french: related to *verb]</p>",
      },
    ],
  },
  {
    word: "Verbose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verbose <strong class='adj'> adj. </strong> Using more words than are needed. verbosity <strong class='noun'> n. </strong> [latin verbosus from verbum word]</p>",
      },
    ],
  },
  {
    word: "Verdant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verdant <strong class='adj'> adj. </strong> 1 (of grass, a field, etc.) Green, lush. 2 (of a person) unsophisticated, green. verdancy <strong class='noun'> n. </strong> [perhaps from french verdeant from viridis green]</p>",
      },
    ],
  },
  {
    word: "Verdict",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verdict <strong class='noun'> n. </strong> 1 decision of a jury in a civil or criminal case. 2 decision; judgement. [anglo-french verdit from ver true, dit saying]</p>",
      },
    ],
  },
  {
    word: "Verdigris",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verdigris <strong class='noun'> n. </strong> Greenish-blue substance that forms on copper or brass. [french, = green of greece]</p>",
      },
    ],
  },
  {
    word: "Verdure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verdure <strong class='noun'> n. </strong> Literary green vegetation or its colour. [french verd green]</p>",
      },
    ],
  },
  {
    word: "Verge1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verge1 <strong class='noun'> n. </strong> 1 edge or border. 2 brink (on the verge of tears). 3 grass edging of a road etc. [latin virga rod]</p>",
      },
    ],
  },
  {
    word: "Verge2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verge2 <strong class='verb'> v. </strong> (-ging) 1 (foll. By on) border on. 2 incline downwards or in a specified direction. [latin vergo bend]</p>",
      },
    ],
  },
  {
    word: "Verger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verger <strong class='noun'> n. </strong> 1 church caretaker and attendant. 2 officer preceding a bishop etc. With a staff. [anglo-french: related to *verge1]</p>",
      },
    ],
  },
  {
    word: "Verify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verify <strong class='verb'> v. </strong> (-ies, -ied) 1 establish the truth, correctness, or validity of by examination etc. (verified my figures). 2 (of an event etc.) Bear out (a prediction or promise). verifiable adj. Verification <strong class='noun'> n. </strong> [medieval latin: related to *very]</p>",
      },
    ],
  },
  {
    word: "Verily",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verily <strong class='adv'> adv. </strong> Archaic really, truly. [from *very]</p>",
      },
    ],
  },
  {
    word: "Verisimilitude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verisimilitude <strong class='noun'> n. </strong> Appearance of being true or real. [latin verus true, similis like]</p>",
      },
    ],
  },
  {
    word: "Veritable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veritable <strong class='adj'> adj. </strong> Real; rightly so called (a veritable feast). veritably <strong class='adv'> adv. </strong> [french: related to *verity]</p>",
      },
    ],
  },
  {
    word: "Verity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a fundamental truth. 2 archaic truth. [latin veritas truth]</p>",
      },
    ],
  },
  {
    word: "Vermicelli",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vermicelli <strong class='noun'> n. </strong> 1 pasta in long slender threads. 2 shreds of chocolate as cake decoration etc. [latin vermis worm]</p>",
      },
    ],
  },
  {
    word: "Vermicide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vermicide <strong class='noun'> n. </strong> Drug that kills intestinal worms. [latin vermis worm]</p>",
      },
    ],
  },
  {
    word: "Vermiculite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vermiculite <strong class='noun'> n. </strong> A hydrous silicate mineral used esp. As a moisture-holding medium for plant growth. [latin vermiculatus worm-eaten, from vermis worm]</p>",
      },
    ],
  },
  {
    word: "Vermiform",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vermiform <strong class='adj'> adj. </strong> Worm-shaped. [medieval latin: related to *vermicide]</p>",
      },
    ],
  },
  {
    word: "Vermiform appendix",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vermiform appendix <strong class='noun'> n. </strong> Small blind tube extending from the caecum in man and some other mammals.</p>",
      },
    ],
  },
  {
    word: "Vermilion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vermilion <strong class='noun'> —n. </strong> 1 cinnabar. 2 a brilliant red pigment made esp. From this. B colour of this. <strong class='adj'> —adj.</strong> Of this colour. [latin vermiculus diminutive of vermis worm]</p>",
      },
    ],
  },
  {
    word: "Vermin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vermin <strong class='noun'> n. </strong> (usu. Treated as pl.) 1 mammals and birds harmful to game, crops, etc., e.g. Foxes and rats. 2 parasitic worms or insects. 3 vile people. verminous adj. [latin vermis worm]</p>",
      },
    ],
  },
  {
    word: "Vermouth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vermouth <strong class='noun'> n. </strong> Wine flavoured with aromatic herbs. [german: related to *wormwood]</p>",
      },
    ],
  },
  {
    word: "Vernacular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vernacular <strong class='noun'> —n. </strong> 1 language or dialect of a particular country. 2 language of a particular class or group. 3 homely speech. <strong class='adj'> —adj.</strong> (of language) native; not foreign or formal. [latin vernaculus native]</p>",
      },
    ],
  },
  {
    word: "Vernal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vernal <strong class='adj'> adj. </strong> Of or in spring. [latin ver spring]</p>",
      },
    ],
  },
  {
    word: "Vernal equinox",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vernal equinox <strong class='var'> var. </strong> Of *spring equinox.</p>",
      },
    ],
  },
  {
    word: "Vernier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vernier <strong class='noun'> n. </strong> Small movable graduated scale for obtaining fractional parts of subdivisions on a fixed scale. [vernier, name of a mathematician]</p>",
      },
    ],
  },
  {
    word: "Veronal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veronal <strong class='noun'> n. </strong> Sedative drug. [german from verona in italy]</p>",
      },
    ],
  },
  {
    word: "Veronica",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veronica <strong class='noun'> n. </strong> Speedwell. [medieval latin, probably from st veronica]</p>",
      },
    ],
  },
  {
    word: "Verruca",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verruca <strong class='noun'> n. </strong> (pl. Verrucae or -s) wart or similar growth, esp. On the foot. [latin]</p>",
      },
    ],
  },
  {
    word: "Versatile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Versatile <strong class='adj'> adj. </strong> 1 adapting easily to different subjects or occupations; skilled in many subjects or occupations. 2 having many uses. versatility <strong class='noun'> n. </strong> [latin verto vers- turn]</p>",
      },
    ],
  },
  {
    word: "Verse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verse <strong class='noun'> n. </strong> 1 poetry. 2 stanza of a poem or song. 3 each of the short numbered divisions of the bible. 4 poem. [latin versus: related to *versatile]</p>",
      },
    ],
  },
  {
    word: "Versed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Versed <strong class='adj'> adj. </strong> (foll. By in) experienced or skilled in. [latin versor be engaged in]</p>",
      },
    ],
  },
  {
    word: "Versicle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Versicle <strong class='noun'> n. </strong> Each of a priest's short sentences in a liturgy, answered by the congregation. [latin diminutive: related to *verse]</p>",
      },
    ],
  },
  {
    word: "Versify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Versify <strong class='verb'> v. </strong> (-ies, -ied) 1 turn into or express in verse. 2 compose verses. versification <strong class='noun'> n. </strong> Versifier n.</p>",
      },
    ],
  },
  {
    word: "Version",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Version <strong class='noun'> n. </strong> 1 account of a matter from a particular point of view. 2 book etc. In a particular edition or translation (authorized version). 3 form or variant. [latin verto vers- turn]</p>",
      },
    ],
  },
  {
    word: "Verso",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verso <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 left-hand page of an open book. 2 back of a printed leaf. [latin verso (folio) on the turned (leaf)]</p>",
      },
    ],
  },
  {
    word: "Versus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Versus <strong class='n_pl'> comb. </strong> Against (esp. In law and sport). [latin: related to *verse]</p>",
      },
    ],
  },
  {
    word: "Vertebra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vertebra <strong class='noun'> n. </strong> (pl. -brae) each segment of a backbone. vertebral adj. [latin verto turn]</p>",
      },
    ],
  },
  {
    word: "Vertebrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vertebrate <strong class='adj'> —adj.</strong> (of an animal) having a backbone. <strong class='noun'> —n. </strong> Vertebrate animal. [latin vertebratus jointed: related to *vertebra]</p>",
      },
    ],
  },
  {
    word: "Vertex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vertex <strong class='noun'> n. </strong> (pl. -tices or -texes) 1 highest point; top, apex. 2 a each angular point of a triangle, polygon, etc. B meeting-point of lines that form an angle. [latin, = whirlpool, crown of a head, from verto turn]</p>",
      },
    ],
  },
  {
    word: "Vertical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vertical <strong class='adj'> —adj.</strong> 1 at right angles to a horizontal plane. 2 in a direction from top to bottom of a picture etc. 3 of or at the vertex. <strong class='noun'> —n. </strong> Vertical line or plane. vertically <strong class='adv'> adv. </strong> [latin: related to *vertex]</p>",
      },
    ],
  },
  {
    word: "Vertical take-off",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vertical take-off <strong class='noun'> n. </strong> Take-off of an aircraft directly upwards.</p>",
      },
    ],
  },
  {
    word: "Vertiginous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vertiginous <strong class='adj'> adj. </strong> Of or causing vertigo. [latin: related to *vertigo]</p>",
      },
    ],
  },
  {
    word: "Vertigo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vertigo <strong class='noun'> n. </strong> Dizziness caused esp. By heights. [latin, = whirling, from verto turn]</p>",
      },
    ],
  },
  {
    word: "Vervain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vervain <strong class='noun'> n. </strong> Any of several verbenas, esp. One with small blue, white, or purple flowers. [latin: related to *verbena]</p>",
      },
    ],
  },
  {
    word: "Verve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Verve <strong class='noun'> n. </strong> Enthusiasm, vigour, spirit. [french]</p>",
      },
    ],
  },
  {
    word: "Very",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Very <strong class='adv'> —adv. </strong> 1 in a high degree (did it very easily). 2 in the fullest sense (foll. By own or superl. Adj.: do your very best; my very own room). <strong class='adj'> —adj.</strong> Actual; truly such (the very thing we need; his very words; the very same). not very in a low degree, far from being. Very good (or well) formula of consent or approval. Very high frequency (in radio) 30-300 megahertz. Very reverend title of a dean. [latin verus true]</p>",
      },
    ],
  },
  {
    word: "Very light",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Very light <strong class='noun'> n. </strong> Flare projected from a pistol for signalling or illuminating part of a battlefield etc. [very, name of its inventor]</p>",
      },
    ],
  },
  {
    word: "Vesicle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vesicle <strong class='noun'> n. </strong> Small bladder, bubble, or blister. [latin]</p>",
      },
    ],
  },
  {
    word: "Vespers",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vespers <strong class='n_pl'> n.pl. </strong> Evensong. [latin vesper evening]</p>",
      },
    ],
  },
  {
    word: "Vessel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vessel <strong class='noun'> n. </strong> 1 hollow receptacle, esp. For liquid. 2 ship or boat, esp. A large one. 3 duct or canal etc. Holding or conveying blood or sap, etc., esp. = *blood-vessel. [latin diminutive: related to *vas]</p>",
      },
    ],
  },
  {
    word: "Vest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vest <strong class='noun'> —n. </strong> 1 undergarment worn on the trunk. 2  <strong class='archaic_it_was'>Us & austral.</strong> Waistcoat. —v. 1 (foll. By with) bestow (powers, authority, etc.) On. 2 (foll. By in) confer (property or power) on (a person) with an immediate fixed right of future possession. 3 clothe (oneself), esp. In vestments. [latin vestis garment]</p>",
      },
    ],
  },
  {
    word: "Vestal virgin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vestal virgin <strong class='noun'> n. </strong> Rom. Antiq. Virgin consecrated to vesta and vowed to chastity. [vesta, roman goddess of the hearth and home]</p>",
      },
    ],
  },
  {
    word: "Vested interest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vested interest <strong class='noun'> n. </strong> 1 personal interest in a state of affairs, usu. With an expectation of gain. 2 law interest (usu. In land or money held in trust) recognized as belonging to a person.</p>",
      },
    ],
  },
  {
    word: "Vestibule",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vestibule <strong class='noun'> n. </strong> 1 hall or lobby of a building. 2 us enclosed space between railway-carriages. [latin]</p>",
      },
    ],
  },
  {
    word: "Vestige",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vestige <strong class='noun'> n. </strong> 1 trace; sign. 2 slight amount; particle. 3 atrophied part or organ of an animal or plant that was well developed in ancestors. vestigial adj. [latin vestigium footprint]</p>",
      },
    ],
  },
  {
    word: "Vestment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vestment <strong class='noun'> n. </strong> Ceremonial garment, esp. A chasuble. [latin: related to *vest]</p>",
      },
    ],
  },
  {
    word: "Vestry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vestry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> church room or building for keeping vestments etc. In.</p>",
      },
    ],
  },
  {
    word: "Vet1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vet1 <strong class='noun'> —n. </strong> Colloq. Veterinary surgeon. —v. <strong class='adj'> (-tt-) </strong> make a careful and critical examination of (a scheme, work, candidate, etc.). [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Vet2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vet2 <strong class='noun'> n. </strong> Us veteran. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Vetch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vetch <strong class='noun'> n. </strong> Plant of the pea family used largely for fodder. [latin vicia]</p>",
      },
    ],
  },
  {
    word: "Veteran",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veteran <strong class='noun'> n. </strong> 1 (often attrib.) Old soldier or long-serving member of any group (war veteran; veteran actor). 2 us ex-serviceman or servicewoman. [latin vetus -er- old]</p>",
      },
    ],
  },
  {
    word: "Veteran car",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veteran car <strong class='noun'> n. </strong> Car made before 1916, or (strictly) before 1905.</p>",
      },
    ],
  },
  {
    word: "Veterinarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veterinarian <strong class='noun'> n. </strong> Formal veterinary surgeon.</p>",
      },
    ],
  },
  {
    word: "Veterinary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veterinary <strong class='adj'> —adj.</strong> Of or for the diseases and injuries of animals. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> veterinary surgeon. [latin veterinae cattle]</p>",
      },
    ],
  },
  {
    word: "Veterinary surgeon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veterinary surgeon <strong class='noun'> n. </strong> Person qualified to treat animals.</p>",
      },
    ],
  },
  {
    word: "Veto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Veto <strong class='noun'> —n. </strong> (pl. -es) 1 right to reject a measure, resolution, etc. Unilaterally. 2 rejection, prohibition. —v. (-oes, -oed) 1 reject (a measure etc.). 2 forbid, prohibit. [latin, = i forbid]</p>",
      },
    ],
  },
  {
    word: "Vex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vex <strong class='verb'> v. </strong> 1 anger, irritate. 2 archaic grieve, afflict. [latin vexo afflict]</p>",
      },
    ],
  },
  {
    word: "Vexation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vexation <strong class='noun'> n. </strong> 1 vexing or being vexed. 2 annoying or distressing thing.</p>",
      },
    ],
  },
  {
    word: "Vexatious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vexatious <strong class='adj'> adj. </strong> 1 causing vexation. 2 law (of litigation) lacking sufficient grounds and seeking only to annoy the defendant.</p>",
      },
    ],
  },
  {
    word: "Vexed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vexed <strong class='adj'> adj. </strong> (of a question) much discussed; problematic.</p>",
      },
    ],
  },
  {
    word: "V.g.c.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>V.g.c. <strong class='abbr'> Abbr. </strong> Very good condition.</p>",
      },
    ],
  },
  {
    word: "Vhf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vhf <strong class='sing'> abbr. </strong> Very high frequency.</p>",
      },
    ],
  },
  {
    word: "Via",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Via <strong class='n_pl'> comb. </strong> Through (london to rome via paris; send it via your son). [latin, ablative of via way]</p>",
      },
    ],
  },
  {
    word: "Viable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viable <strong class='adj'> adj. </strong> 1 (of a plan etc.) Feasible, esp. Economically. 2 (esp. Of a foetus) capable of developing and surviving independently. viability <strong class='noun'> n. </strong> [french vie life]</p>",
      },
    ],
  },
  {
    word: "Viaduct",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viaduct <strong class='noun'> n. </strong> Long bridge, esp. A series of arches, carrying a road or railway across a valley or hollow. [latin via way, after *aqueduct]</p>",
      },
    ],
  },
  {
    word: "Vial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vial <strong class='noun'> n. </strong> Small (usu. Cylindrical glass) vessel, esp. For holding medicines. [related to *phial]</p>",
      },
    ],
  },
  {
    word: "Viand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viand <strong class='noun'> n. </strong> Formal (usu. In pl.) Article of food. [latin vivo live]</p>",
      },
    ],
  },
  {
    word: "Viaticum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viaticum <strong class='noun'> n. </strong> (pl. -ca) eucharist given to a dying person. [latin via road]</p>",
      },
    ],
  },
  {
    word: "Vibes",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vibes <strong class='n_pl'> n.pl. </strong> Colloq. 1 vibrations, esp. Feelings communicated. 2 = *vibraphone. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Vibrant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vibrant <strong class='adj'> adj. </strong> 1 vibrating. 2 (often foll. By with) thrilling, lively. 3 (of sound) resonant. 4 (of colours) bright and striking. vibrancy <strong class='noun'> n. </strong> Vibrantly <strong class='adv'> adv. </strong> [latin: related to *vibrate]</p>",
      },
    ],
  },
  {
    word: "Vibraphone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vibraphone <strong class='noun'> n. </strong> Instrument like a xylophone but with motor-driven resonators under the metal bars giving a vibrato effect. [from *vibrato]</p>",
      },
    ],
  },
  {
    word: "Vibrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vibrate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 move rapidly to and fro. 2 (of a sound) throb; resonate. 3 (foll. By with) quiver, thrill. 4 swing to and fro, oscillate. [latin vibro shake]</p>",
      },
    ],
  },
  {
    word: "Vibration",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vibration <strong class='noun'> n. </strong> 1 vibrating. 2 (in pl.) A mental, esp. Occult, influence. B atmosphere or feeling communicated.</p>",
      },
    ],
  },
  {
    word: "Vibrato",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vibrato <strong class='noun'> n. </strong> Rapid slight variation in musical pitch producing a tremulous effect. [italian: related to *vibrate]</p>",
      },
    ],
  },
  {
    word: "Vibrator",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vibrator <strong class='noun'> n. </strong> Device that vibrates, esp. An instrument for massage or sexual stimulation. vibratory adj.</p>",
      },
    ],
  },
  {
    word: "Viburnum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viburnum <strong class='noun'> n. </strong> A shrub, usu. With white flowers. [latin, = wayfaring-tree]</p>",
      },
    ],
  },
  {
    word: "Vicar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicar <strong class='noun'> n. </strong> Clergyman of a church of england parish where he formerly received a stipend rather than tithes: cf. *rector 1. [latin vicarius substitute: related to *vice3]</p>",
      },
    ],
  },
  {
    word: "Vicarage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicarage <strong class='noun'> n. </strong> Vicar's house.</p>",
      },
    ],
  },
  {
    word: "Vicarious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicarious <strong class='adj'> adj. </strong> 1 experienced indirectly or second-hand. 2 acting or done for another. 3 deputed, delegated. vicariously <strong class='adv'> adv. </strong> [latin: related to *vicar]</p>",
      },
    ],
  },
  {
    word: "Vice1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vice1 <strong class='noun'> n. </strong> 1 immoral conduct. 2 form of this (the vice of gluttony). 3 weakness; indulgence (brandy is my one vice). [latin vitium]</p>",
      },
    ],
  },
  {
    word: "Vice2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vice2 <strong class='noun'> n. </strong> (us vise) clamp with two jaws holding an object so as to leave the hands free to work on it. [vis screw, from latin vitis vine]</p>",
      },
    ],
  },
  {
    word: "Vice3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vice3 <strong class='n_pl'> comb. </strong> In the place of; succeeding. [latin, ablative of (vix) vicis change]</p>",
      },
    ],
  },
  {
    word: "Vice-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vice- <strong class='n_pl'> comb. </strong> Form forming nouns meaning: 1 substitute, deputy (vice-president). 2 next in rank to (vice admiral). [related to *vice3]</p>",
      },
    ],
  },
  {
    word: "Vice-chancellor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vice-chancellor <strong class='noun'> n. </strong> Deputy chancellor (esp. Administrator of a university).</p>",
      },
    ],
  },
  {
    word: "Vice-president",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vice-president <strong class='noun'> n. </strong> Official ranking below and deputizing for a president. vice-presidency <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Vice-presidential adj.</p>",
      },
    ],
  },
  {
    word: "Viceregal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viceregal <strong class='adj'> adj. </strong> Of a viceroy.</p>",
      },
    ],
  },
  {
    word: "Vicereine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicereine <strong class='noun'> n. </strong> 1 viceroy's wife. 2 woman viceroy. [french: related to *vice-, reine queen]</p>",
      },
    ],
  },
  {
    word: "Vice ring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vice ring <strong class='noun'> n. </strong> Group of criminals organizing prostitution.</p>",
      },
    ],
  },
  {
    word: "Viceroy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viceroy <strong class='noun'> n. </strong> Sovereign's deputy ruler in a colony, province, etc. [french: related to *vice-, roy king]</p>",
      },
    ],
  },
  {
    word: "Vice squad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vice squad <strong class='noun'> n. </strong> Police department concerned with prostitution etc.</p>",
      },
    ],
  },
  {
    word: "Vice versa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vice versa <strong class='adj'> adj. </strong> With the order of the terms changed; the other way round. [latin, = the position being reversed]</p>",
      },
    ],
  },
  {
    word: "Vichyssoise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vichyssoise <strong class='noun'> n. </strong> (usu. Chilled) creamy soup of leeks and potatoes. [french, = of vichy]</p>",
      },
    ],
  },
  {
    word: "Vichy water",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vichy water <strong class='noun'> n. </strong> Effervescent mineral water from vichy in france.</p>",
      },
    ],
  },
  {
    word: "Vicinity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicinity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 surrounding district. 2 (foll. By to) nearness. in the vicinity (often foll. By of) near (to). [latin vicinus neighbour]</p>",
      },
    ],
  },
  {
    word: "Vicious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicious <strong class='adj'> adj. </strong> 1 bad-tempered, spiteful (vicious dog, remark). 2 violent (vicious attack). 3 corrupt, depraved. 4 (of reasoning etc.) Faulty, unsound. viciously <strong class='adv'> adv. </strong> Viciousness <strong class='noun'> n. </strong> [latin: related to *vice1]</p>",
      },
    ],
  },
  {
    word: "Vicious circle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicious circle <strong class='noun'> n. </strong> Self-perpetuating, harmful sequence of cause and effect.</p>",
      },
    ],
  },
  {
    word: "Vicious spiral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicious spiral <strong class='noun'> n. </strong> Vicious circle, esp. As causing inflation.</p>",
      },
    ],
  },
  {
    word: "Vicissitude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicissitude <strong class='noun'> n. </strong> Literary change, esp. Of fortune. [latin: related to *vice3]</p>",
      },
    ],
  },
  {
    word: "Victim",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victim <strong class='noun'> n. </strong> 1 person or thing injured or destroyed (road victim; victim of greed). 2 prey; dupe (fell victim to his charm). 3 creature sacrificed to a deity or in a religious rite. [latin]</p>",
      },
    ],
  },
  {
    word: "Victimize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victimize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 single out for punishment or discrimination. 2 make (a person etc.) A victim. victimization n.</p>",
      },
    ],
  },
  {
    word: "Victor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victor <strong class='noun'> n. </strong> Winner in a battle or contest. [latin vinco vict- conquer]</p>",
      },
    ],
  },
  {
    word: "Victoria cross",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victoria cross <strong class='noun'> n. </strong> Highest decoration for conspicuous bravery in the armed services. [queen victoria]</p>",
      },
    ],
  },
  {
    word: "Victorian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victorian <strong class='adj'> —adj.</strong> 1 of the time of queen victoria. 2 prudish; strict. <strong class='noun'> —n. </strong> Person of this time.</p>",
      },
    ],
  },
  {
    word: "Victoriana",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victoriana <strong class='n_pl'> n.pl. </strong> Articles, esp. Collectors' items, of the victorian period.</p>",
      },
    ],
  },
  {
    word: "Victoria sponge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victoria sponge <strong class='noun'> n. </strong> Sandwich sponge cake with a jam filling.</p>",
      },
    ],
  },
  {
    word: "Victorious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victorious <strong class='adj'> adj. </strong> 1 conquering, triumphant. 2 marked by victory. victoriously <strong class='adv'> adv. </strong> [latin: related to *victor]</p>",
      },
    ],
  },
  {
    word: "Victory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victory <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> defeat of an enemy or opponent.</p>",
      },
    ],
  },
  {
    word: "Victual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victual <strong class='noun'> —n. </strong> (usu. In pl.) Food, provisions. —v. (-ll-; us -l-) 1 supply with victuals. 2 obtain stores. 3 eat victuals. [latin victus food]</p>",
      },
    ],
  },
  {
    word: "Victualer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victualer <strong class='noun'> n. </strong> (brit. Victualler) 1 person etc. Who supplies victuals. 2 (in full licensed victualler) publican etc. Licensed to sell alcohol.</p>",
      },
    ],
  },
  {
    word: "Victualler",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Victualler <strong class='noun'> n. </strong> (us victualer) 1 person etc. Who supplies victuals. 2 (in full licensed victualler) publican etc. Licensed to sell alcohol.</p>",
      },
    ],
  },
  {
    word: "Vicuña",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vicuña <strong class='noun'> n. </strong> 1 s. American mammal like a llama, with fine silky wool. 2 a cloth from its wool. B imitation of this. [spanish from quechua]</p>",
      },
    ],
  },
  {
    word: "Vide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vide <strong class='verb'> v. </strong> (in imper.) See, consult (a passage in a book etc.). [latin video see]</p>",
      },
    ],
  },
  {
    word: "Videlicet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Videlicet <strong class='adv'> adv. </strong> = *viz. [latin video see, licet allowed]</p>",
      },
    ],
  },
  {
    word: "Video",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Video <strong class='adj'> —adj.</strong> 1 of the recording (or reproduction) of moving pictures on magnetic tape. 2 of the broadcasting of television pictures. <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 such recording or broadcasting. 2 <strong class='colloq'> colloq. </strong> = *video recorder. 3 <strong class='colloq'> colloq. </strong> A film on videotape. —v. (-oes, -oed) record on videotape. [latin, = i see]</p>",
      },
    ],
  },
  {
    word: "Video cassette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Video cassette <strong class='noun'> n. </strong> Cassette of videotape.</p>",
      },
    ],
  },
  {
    word: "Videodisc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Videodisc <strong class='noun'> n. </strong> Disc for recording moving pictures and sound.</p>",
      },
    ],
  },
  {
    word: "Video game",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Video game <strong class='noun'> n. </strong> Computer game played on a television screen.</p>",
      },
    ],
  },
  {
    word: "Video nasty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Video nasty <strong class='noun'> n. </strong> Colloq. Horrific or pornographic video film.</p>",
      },
    ],
  },
  {
    word: "Video recorder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Video recorder <strong class='noun'> n. </strong> (also video cassette recorder) apparatus for recording and playing videotapes.</p>",
      },
    ],
  },
  {
    word: "Video shop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Video shop <strong class='noun'> n. </strong> Shop hiring out or selling video films etc.</p>",
      },
    ],
  },
  {
    word: "Videotape",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Videotape <strong class='noun'> —n. </strong> Magnetic tape for recording moving pictures and sound. —v. (-ping) record on this.</p>",
      },
    ],
  },
  {
    word: "Videotape recorder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Videotape recorder <strong class='noun'> n. </strong> = *video recorder.</p>",
      },
    ],
  },
  {
    word: "Videotex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Videotex <strong class='noun'> n. </strong> (also videotext) any electronic information system, esp. Teletext or viewdata.</p>",
      },
    ],
  },
  {
    word: "Vie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vie <strong class='verb'> v. </strong> (vies; vied; vying) (often foll. By with) compete; strive for superiority. [probably french: related to *envy]</p>",
      },
    ],
  },
  {
    word: "Vietnamese",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vietnamese <strong class='adj'> —adj.</strong> Of vietnam. <strong class='noun'> —n. </strong> <strong class='pl_same'> (pl. Same) </strong> native or language of vietnam.</p>",
      },
    ],
  },
  {
    word: "View",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>View <strong class='noun'> —n. </strong> 1 range of vision (came into view). 2 a what is seen; prospect, scene, etc. B picture etc. Of this. 3 a opinion. B manner of considering a thing (took a long-term view). 4 inspection by the eye or mind (private view). —v. 1 look at; inspect with the idea of purchasing; survey visually or mentally. 2 form a mental impression or opinion of; consider. 3 watch television. have in view 1 have as one's object. 2 bear (a circumstance) in mind. In view of considering. On view being shown or exhibited. With a view to with the hope or intention of. [latin video see]</p>",
      },
    ],
  },
  {
    word: "Viewdata",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viewdata <strong class='noun'> n. </strong> News and information service from a computer source, connected to a television screen by a telephone link.</p>",
      },
    ],
  },
  {
    word: "Viewer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viewer <strong class='noun'> n. </strong> 1 person who views, esp. Television. 2 device for looking at film transparencies etc.</p>",
      },
    ],
  },
  {
    word: "Viewfinder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viewfinder <strong class='noun'> n. </strong> Device on a camera showing the borders of the proposed photograph.</p>",
      },
    ],
  },
  {
    word: "Viewpoint",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viewpoint <strong class='noun'> n. </strong> Point of view.</p>",
      },
    ],
  },
  {
    word: "Vigil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vigil <strong class='noun'> n. </strong> 1 keeping awake during the night etc., esp. To keep watch or pray. 2 eve of a festival or holy day. [latin vigilia]</p>",
      },
    ],
  },
  {
    word: "Vigilance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vigilance <strong class='noun'> n. </strong> Watchfulness, caution. vigilant adj. [latin: related to *vigil]</p>",
      },
    ],
  },
  {
    word: "Vigilante",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vigilante <strong class='noun'> n. </strong> Member of a self-appointed group maintaining order etc. [spanish, = vigilant]</p>",
      },
    ],
  },
  {
    word: "Vignette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vignette <strong class='noun'> n. </strong> 1 short description, character sketch. 2 book illustration not in a definite border. 3 photograph etc. With the background shaded off. [french, diminutive: related to *vine]</p>",
      },
    ],
  },
  {
    word: "Vigor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vigor <strong class='noun'> n. </strong> (brit. Vigour) 1 physical or mental strength or energy. 2 healthy growth. 3 forcefulness; trenchancy, animation. vigorous adj. Vigorously <strong class='adv'> adv. </strong> [french from latin vigeo be lively]</p>",
      },
    ],
  },
  {
    word: "Vigour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vigour <strong class='noun'> n. </strong> (us vigor) 1 physical or mental strength or energy. 2 healthy growth. 3 forcefulness; trenchancy, animation. vigorous adj. Vigorously <strong class='adv'> adv. </strong> [french from latin vigeo be lively]</p>",
      },
    ],
  },
  {
    word: "Viking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viking <strong class='noun'> n. </strong> Scandinavian pirate and raider of the 8th–11th c. [old norse]</p>",
      },
    ],
  },
  {
    word: "Vile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vile <strong class='adj'> adj. </strong> 1 disgusting. 2 depraved. 3 <strong class='colloq'> colloq. </strong> Abominable (vile weather). vilely <strong class='adv'> adv. </strong> Vileness <strong class='noun'> n. </strong> [latin vilis cheap, base]</p>",
      },
    ],
  },
  {
    word: "Vilify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vilify <strong class='verb'> v. </strong> (-ies, -ied) defame; malign. vilification <strong class='noun'> n. </strong> [latin: related to *vile]</p>",
      },
    ],
  },
  {
    word: "Villa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Villa <strong class='noun'> n. </strong> 1 country house; mansion. 2 rented holiday home, esp. Abroad. 3 (usu. As part of an address) detached or semi-detached house in a residential district. [italian and latin]</p>",
      },
    ],
  },
  {
    word: "Village",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Village <strong class='noun'> n. </strong> 1 country settlement, larger than a hamlet and smaller than a town. 2 self-contained village-like community within a city etc. (greenwich village; olympic village). villager <strong class='noun'> n. </strong> [latin: related to *villa]</p>",
      },
    ],
  },
  {
    word: "Villain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Villain <strong class='noun'> n. </strong> 1 wicked person. 2 chief evil character in a play, story, etc. 3 <strong class='colloq'> colloq. </strong> Professional criminal. 4 <strong class='colloq'> colloq. </strong> Rascal. [latin: related to *villa]</p>",
      },
    ],
  },
  {
    word: "Villainous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Villainous <strong class='adj'> adj. </strong> Wicked.</p>",
      },
    ],
  },
  {
    word: "Villainy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Villainy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> wicked behaviour or act. [french: related to *villain]</p>",
      },
    ],
  },
  {
    word: "Villein",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Villein <strong class='noun'> n. </strong> Hist. Feudal tenant entirely subject to a lord or attached to a manor. villeinage <strong class='noun'> n. </strong> [var. Of *villain]</p>",
      },
    ],
  },
  {
    word: "Vim",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vim <strong class='noun'> n. </strong> Colloq. Vigour. [perhaps from latin, accusative of vis energy]</p>",
      },
    ],
  },
  {
    word: "Vinaigrette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vinaigrette <strong class='noun'> n. </strong> 1 salad dressing of oil, wine vinegar, and seasoning. 2 small bottle for smelling-salts. [french, diminutive: related to *vinegar]</p>",
      },
    ],
  },
  {
    word: "Vindicate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vindicate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 clear of blame or suspicion. 2 establish the existence, merits, or justice of (something disputed etc.). 3 justify by evidence or argument. vindication <strong class='noun'> n. </strong> Vindicator <strong class='noun'> n. </strong> Vindicatory adj. [latin vindico claim]</p>",
      },
    ],
  },
  {
    word: "Vindictive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vindictive <strong class='adj'> adj. </strong> Vengeful. vindictively <strong class='adv'> adv. </strong> Vindictiveness <strong class='noun'> n. </strong> [latin vindicta vengeance: related to *vindicate]</p>",
      },
    ],
  },
  {
    word: "Vine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vine <strong class='noun'> n. </strong> 1 climbing or trailing plant with a woody stem, esp. Bearing grapes. 2 stem of this. [latin vinea vineyard]</p>",
      },
    ],
  },
  {
    word: "Vinegar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vinegar <strong class='noun'> n. </strong> Sour liquid got from malt, wine, cider, etc., by fermentation and used as a condiment or for pickling. vinegary adj. [french, = sour wine: related to *eager]</p>",
      },
    ],
  },
  {
    word: "Vineyard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vineyard <strong class='noun'> n. </strong> Plantation of grapevines, esp. For wine-making.</p>",
      },
    ],
  },
  {
    word: "Vingt-et-un",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vingt-et-un <strong class='noun'> n. </strong> = *pontoon1. [french, = twenty-one]</p>",
      },
    ],
  },
  {
    word: "Vino",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vino <strong class='noun'> n. </strong> Slang wine, esp. Of an inferior kind. [italian, = wine]</p>",
      },
    ],
  },
  {
    word: "Vinous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vinous <strong class='adj'> adj. </strong> 1 of, like, or due to wine. 2 addicted to wine. [latin vinum wine]</p>",
      },
    ],
  },
  {
    word: "Vintage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vintage <strong class='noun'> —n. </strong> 1 a season's produce of grapes. B wine from this. 2 a gathering of grapes for wine-making. B season of this. 3 wine of high quality from a particular year and district. 4 a year etc. When a thing was made etc. B thing made etc. In a particular year etc. <strong class='adj'> —adj.</strong> 1 of high or peak quality. 2 of a past season. [latin vinum wine]</p>",
      },
    ],
  },
  {
    word: "Vintage car",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vintage car <strong class='noun'> n. </strong> Car made 1917–1930.</p>",
      },
    ],
  },
  {
    word: "Vintner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vintner <strong class='noun'> n. </strong> Wine-merchant. [anglo-latin from french, ultimately from latin vinetum vineyard, from vinum wine]</p>",
      },
    ],
  },
  {
    word: "Vinyl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vinyl <strong class='noun'> n. </strong> Plastic made by polymerization, esp. Polyvinyl chloride. [latin vinum wine]</p>",
      },
    ],
  },
  {
    word: "Viol",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viol <strong class='noun'> n. </strong> Medieval stringed instrument of various sizes, like a violin but held vertically. [french from provençal]</p>",
      },
    ],
  },
  {
    word: "Viola1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viola1 <strong class='noun'> n. </strong> Instrument larger than the violin and of lower pitch. [italian and spanish: related to *viol]</p>",
      },
    ],
  },
  {
    word: "Viola2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viola2 <strong class='noun'> n. </strong> Any plant of the genus including the pansy and violet, esp. A cultivated hybrid. [latin, = violet]</p>",
      },
    ],
  },
  {
    word: "Viola da gamba",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viola da gamba <strong class='noun'> n. </strong> Viol held between the player's legs.</p>",
      },
    ],
  },
  {
    word: "Violate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Violate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 disregard; break (an oath, treaty, law, etc.). 2 treat (a sanctuary etc.) Profanely; disrespect. 3 disturb (a person's privacy etc.). 4 rape. violable adj. Violation <strong class='noun'> n. </strong> Violator <strong class='noun'> n. </strong> [latin violo]</p>",
      },
    ],
  },
  {
    word: "Violence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Violence <strong class='noun'> n. </strong> 1 being violent. 2 violent conduct or treatment. 3 unlawful use of force. do violence to act contrary to; outrage. [latin: related to *violent]</p>",
      },
    ],
  },
  {
    word: "Violent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Violent <strong class='adj'> adj. </strong> 1 involving or using great physical force (violent person; violent storm). 2 a intense, vehement (violent pain; violent dislike). B lurid (violent colours). 3 (of death) resulting from violence or poison. violently <strong class='adv'> adv. </strong> [french from latin] </p>",
      },
    ],
  },
  {
    word: "Violet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Violet <strong class='noun'> —n. </strong> 1 sweet-scented plant with usu. Purple, blue, or white flowers. 2 bluish-purple colour at the end of the spectrum opposite red. 3 pigment or clothes or material of this colour. <strong class='adj'> —adj.</strong> Of this colour. [french diminutive of viole *viola2]</p>",
      },
    ],
  },
  {
    word: "Violin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Violin <strong class='noun'> n. </strong> High-pitched stringed instrument played with a bow. violinist <strong class='noun'> n. </strong> [italian diminutive of *viola1]</p>",
      },
    ],
  },
  {
    word: "Violist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Violist <strong class='noun'> n. </strong> Viol- or viola-player.</p>",
      },
    ],
  },
  {
    word: "Violoncello",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Violoncello <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> formal = *cello. [italian, diminutive of violone bass viol]</p>",
      },
    ],
  },
  {
    word: "Vip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vip <strong class='sing'> abbr. </strong> Very important person.</p>",
      },
    ],
  },
  {
    word: "Viper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viper <strong class='noun'> n. </strong> 1 small venomous snake. 2 malignant or treacherous person. [latin]</p>",
      },
    ],
  },
  {
    word: "Virago",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virago <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> fierce or abusive woman. [latin, = female warrior]</p>",
      },
    ],
  },
  {
    word: "Viral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viral <strong class='adj'> adj. </strong> Of or caused by a virus.</p>",
      },
    ],
  },
  {
    word: "Virgin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virgin <strong class='noun'> —n. </strong> 1 person who has never had sexual intercourse. 2 (the virgin) christ's mother mary. 3 (the virgin) sign or constellation virgo. <strong class='adj'> —adj.</strong> 1 not yet used etc. 2 virginal. [latin virgo -gin-]</p>",
      },
    ],
  },
  {
    word: "Virginal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virginal <strong class='adj'> —adj.</strong> Of or befitting a virgin. <strong class='noun'> —n. </strong> (usu. In pl.) Mus. Legless spinet in a box. [latin: related to *virgin]</p>",
      },
    ],
  },
  {
    word: "Virgin birth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virgin birth <strong class='noun'> n. </strong> 1 (usu. Preceded by the) doctrine of christ's birth from a virgin mother. 2 parthenogenesis.</p>",
      },
    ],
  },
  {
    word: "Virginia creeper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virginia creeper <strong class='noun'> n. </strong> Ornamental vine. [virginia in us]</p>",
      },
    ],
  },
  {
    word: "Virginity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virginity <strong class='noun'> n. </strong> State of being a virgin.</p>",
      },
    ],
  },
  {
    word: "Virgo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virgo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 constellation and sixth sign of the zodiac (the virgin). 2 person born when the sun is in this sign. [latin: related to *virgin]</p>",
      },
    ],
  },
  {
    word: "Virile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virile <strong class='adj'> adj. </strong> 1 (of a man) vigorous or strong. 2 sexually potent. 3 of a man as distinct from a woman or child. virility <strong class='noun'> n. </strong> [latin vir man]</p>",
      },
    ],
  },
  {
    word: "Virology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virology <strong class='noun'> n. </strong> The study of viruses. virologist n.</p>",
      },
    ],
  },
  {
    word: "Virtual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virtual <strong class='adj'> adj. </strong> Being so in practice though not strictly or in name (the virtual manager; a virtual promise). [medieval latin: related to *virtue]</p>",
      },
    ],
  },
  {
    word: "Virtually",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virtually <strong class='adv'> adv. </strong> In effect, nearly, almost.</p>",
      },
    ],
  },
  {
    word: "Virtual reality",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virtual reality <strong class='noun'> n. </strong> Simulation of the real world by a computer.</p>",
      },
    ],
  },
  {
    word: "Virtue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virtue <strong class='noun'> n. </strong> 1 moral excellence; goodness. 2 particular form of this. 3 (esp. Female) chastity. 4 good quality (has the virtue of speed). 5 efficacy (no virtue in such drugs). by (or in) virtue of on account of, because of. [latin: related to *virile]</p>",
      },
    ],
  },
  {
    word: "Virtuoso",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virtuoso <strong class='noun'> n. </strong> (pl. -si or -s) (often attrib.) Highly skilled artist, esp. A musician (virtuoso performance). virtuosic adj. Virtuosity <strong class='noun'> n. </strong> [italian: related to *virtuous]</p>",
      },
    ],
  },
  {
    word: "Virtuous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virtuous <strong class='adj'> adj. </strong> 1 morally good. 2 archaic chaste. virtuously <strong class='adv'> adv. </strong> [latin: related to *virtue]</p>",
      },
    ],
  },
  {
    word: "Virulent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virulent <strong class='adj'> adj. </strong> 1 strongly poisonous. 2 (of a disease) violent. 3 bitterly hostile. virulence <strong class='noun'> n. </strong> Virulently <strong class='adv'> adv. </strong> [latin: related to *virus]</p>",
      },
    ],
  },
  {
    word: "Virus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Virus <strong class='noun'> n. </strong> 1 microscopic organism often causing diseases. 2 = *computer virus. [latin, = poison]</p>",
      },
    ],
  },
  {
    word: "Visa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visa <strong class='noun'> n. </strong> Endorsement on a passport etc., esp. Allowing entrance to or exit from a country. [latin, = seen]</p>",
      },
    ],
  },
  {
    word: "Visage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visage <strong class='noun'> n. </strong> Literary face. [latin visus sight]</p>",
      },
    ],
  },
  {
    word: "Vis-à-vis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vis-à-vis <strong class='prep'> —prep. </strong> 1 in relation to. 2 in comparison with. <strong class='adv'> —adv. </strong> Opposite. [french, = face to face: related to *visage]</p>",
      },
    ],
  },
  {
    word: "Viscera",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viscera <strong class='n_pl'> n.pl. </strong> Internal organs of the body. [latin]</p>",
      },
    ],
  },
  {
    word: "Visceral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visceral <strong class='adj'> adj. </strong> 1 of the viscera. 2 of feelings rather than reason.</p>",
      },
    ],
  },
  {
    word: "Viscid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viscid <strong class='adj'> adj. </strong> Glutinous, sticky. [latin: related to *viscous]</p>",
      },
    ],
  },
  {
    word: "Viscose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viscose <strong class='noun'> n. </strong> 1 cellulose in a highly viscous state, used for making rayon etc. 2 fabric made from this. [latin: related to *viscous]</p>",
      },
    ],
  },
  {
    word: "Viscount",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viscount <strong class='noun'> n. </strong> British nobleman ranking between an earl and a baron. viscountcy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. [anglo-french: related to *vice-, *count2]</p>",
      },
    ],
  },
  {
    word: "Viscountess",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viscountess <strong class='noun'> n. </strong> 1 viscount's wife or widow. 2 woman holding the rank of viscount.</p>",
      },
    ],
  },
  {
    word: "Viscous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viscous <strong class='adj'> adj. </strong> 1 glutinous, sticky. 2 semifluid. 3 not flowing freely. viscosity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. [latin viscum birdlime]</p>",
      },
    ],
  },
  {
    word: "Vise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vise <strong class='noun'> n. </strong> (brit. Vice) clamp with two jaws holding an object so as to leave the hands free to work on it. [vis screw, from latin vitis vine]</p>",
      },
    ],
  },
  {
    word: "Visibility",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visibility <strong class='noun'> n. </strong> 1 being visible. 2 range or possibility of vision as determined by the light and weather.</p>",
      },
    ],
  },
  {
    word: "Visible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visible <strong class='adj'> adj. </strong> 1 able to be seen, perceived, or ascertained. 2 (of exports etc.) Consisting of actual goods. visibly <strong class='adv'> adv. </strong> [latin: related to *vision]</p>",
      },
    ],
  },
  {
    word: "Vision",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vision <strong class='noun'> n. </strong> 1 act or faculty of seeing, sight. 2 thing or person seen in a dream or trance. 3 mental picture (visions of hot toast). 4 imaginative insight. 5 statesmanlike foresight. 6 beautiful person etc. 7 television or cinema picture, esp. Of specified quality (poor vision). [latin video vis- see]</p>",
      },
    ],
  },
  {
    word: "Visionary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visionary <strong class='adj'> —adj.</strong> 1 given to seeing visions or to fanciful theories. 2 having vision or foresight. 3 not real, imaginary. 4 not practicable. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> visionary person.</p>",
      },
    ],
  },
  {
    word: "Visit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visit <strong class='noun'> —v. </strong> (-t-) 1 (also absol.) Go or come to see or inspect (a person, place, etc.). 2 stay temporarily with (a person) or at (a place). 3 (of a disease, calamity, etc.) Attack. 4 a (foll. By with) punish (a person). B (often foll. By upon) inflict punishment for (a sin). <strong class='noun'> —n. </strong> 1 a act of visiting. B temporary stay, esp. As a guest. 2 (foll. By to) occasion of going to a doctor etc. 3 formal or official call. [latin: related to *vision]</p>",
      },
    ],
  },
  {
    word: "Visitant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visitant <strong class='noun'> n. </strong> 1 visitor, esp. A ghost etc. 2 migratory bird resting temporarily in an area.</p>",
      },
    ],
  },
  {
    word: "Visitation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visitation <strong class='noun'> n. </strong> 1 official visit of inspection. 2 trouble etc. Seen as divine punishment. 3 (visitation) a visit of the virgin mary to elizabeth. B festival of this.</p>",
      },
    ],
  },
  {
    word: "Visitor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visitor <strong class='noun'> n. </strong> 1 person who visits. 2 migrant bird staying for part of the year.</p>",
      },
    ],
  },
  {
    word: "Visitors' book",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visitors' book <strong class='noun'> n. </strong> Book for visitors to a hotel, church, etc., to sign, make remarks in, etc.</p>",
      },
    ],
  },
  {
    word: "Visor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visor <strong class='noun'> n. </strong> (also vizor) 1 movable part of a helmet covering the face. 2 shield for the eyes, esp. One at the top of a vehicle windscreen. [anglo-french viser: related to *visage]</p>",
      },
    ],
  },
  {
    word: "Vista",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vista <strong class='noun'> n. </strong> 1 long narrow view as between rows of trees. 2 mental view of a long series of events. [italian]</p>",
      },
    ],
  },
  {
    word: "Visual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visual <strong class='adj'> adj. </strong> Of or used in seeing. visually <strong class='adv'> adv. </strong> [latin visus sight]</p>",
      },
    ],
  },
  {
    word: "Visual aid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visual aid <strong class='noun'> n. </strong> Film etc. As a teaching aid.</p>",
      },
    ],
  },
  {
    word: "Visual display unit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visual display unit <strong class='noun'> n. </strong> Computing device displaying data on a screen.</p>",
      },
    ],
  },
  {
    word: "Visualize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Visualize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) imagine visually. visualization n.</p>",
      },
    ],
  },
  {
    word: "Vital",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vital <strong class='adj'> —adj.</strong> 1 of or essential to organic life (vital functions). 2 essential, indispensable (of vital importance). 3 full of life or activity. 4 fatal (vital error). <strong class='noun'> —n. </strong> (in pl.) The body's vital organs, e.g. The heart and brain. vitally <strong class='adv'> adv. </strong> [latin vita life]</p>",
      },
    ],
  },
  {
    word: "Vitality",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitality <strong class='noun'> n. </strong> 1 liveliness, animation. 2 ability to survive or endure. [latin: related to *vital]</p>",
      },
    ],
  },
  {
    word: "Vitalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 endow with life. 2 make lively or vigorous. vitalization n.</p>",
      },
    ],
  },
  {
    word: "Vital statistics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vital statistics <strong class='n_pl'> n.pl. </strong> 1 joc. Measurements of a woman's bust, waist, and hips. 2 the number of births, marriages, deaths, etc.</p>",
      },
    ],
  },
  {
    word: "Vitamin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitamin <strong class='noun'> n. </strong> Any of various substances present in many foods and essential to health and growth (vitamin a, b, c, etc.). [latin vita life, *amine]</p>",
      },
    ],
  },
  {
    word: "Vitamin b complex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitamin b complex <strong class='noun'> n. </strong> Any of a group of vitamins often found together in foods.</p>",
      },
    ],
  },
  {
    word: "Vitaminize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitaminize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) add vitamins to.</p>",
      },
    ],
  },
  {
    word: "Vitiate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitiate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 impair, debase. 2 make invalid or ineffectual. vitiation <strong class='noun'> n. </strong> [latin: related to *vice1]</p>",
      },
    ],
  },
  {
    word: "Viticulture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viticulture <strong class='noun'> n. </strong> Cultivation of grapes. [latin vitis vine]</p>",
      },
    ],
  },
  {
    word: "Vitreous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitreous <strong class='adj'> adj. </strong> Of or like glass. [latin vitrum glass]</p>",
      },
    ],
  },
  {
    word: "Vitreous humour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitreous humour <strong class='noun'> n. </strong> Clear fluid in the eye between the lens and the retina.</p>",
      },
    ],
  },
  {
    word: "Vitrify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitrify <strong class='verb'> v. </strong> (-ies, -ied) change into glass or a glasslike substance, esp. By heat. vitrifaction <strong class='noun'> n. </strong> Vitrification <strong class='noun'> n. </strong> [french or medieval latin: related to *vitreous]</p>",
      },
    ],
  },
  {
    word: "Vitriol",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitriol <strong class='noun'> n. </strong> 1 sulphuric acid or a sulphate. 2 caustic or hostile speech or criticism. [latin vitrum]</p>",
      },
    ],
  },
  {
    word: "Vitriolic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vitriolic <strong class='adj'> adj. </strong> Caustic, hostile.</p>",
      },
    ],
  },
  {
    word: "Vituperate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vituperate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> criticize abusively. vituperation <strong class='noun'> n. </strong> Vituperative adj. [latin]</p>",
      },
    ],
  },
  {
    word: "Viva1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viva1 <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> = *viva voce. —v. (vivas, vivaed, vivaing) = viva-voce. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Viva2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viva2 <strong class='pl'> —int. </strong> Long live. <strong class='noun'> —n. </strong> Cry of this as a salute etc. [italian, = let live]</p>",
      },
    ],
  },
  {
    word: "Vivace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vivace <strong class='adv'> adv. </strong> Mus. In a lively manner. [latin: related to *vivacious]</p>",
      },
    ],
  },
  {
    word: "Vivacious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vivacious <strong class='adj'> adj. </strong> Lively, animated. vivacity <strong class='noun'> n. </strong> [latin vivax from vivo live]</p>",
      },
    ],
  },
  {
    word: "Vivarium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vivarium <strong class='noun'> n. </strong> (pl. -ria or -s) 1 glass bowl etc. For keeping animals for scientific study. 2 enclosure for keeping animals in (nearly) their natural state. [latin]</p>",
      },
    ],
  },
  {
    word: "Viva voce",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viva voce <strong class='adj'> —adj.</strong> Oral. <strong class='adv'> —adv. </strong> Orally. <strong class='noun'> —n. </strong> Oral examination. —v. (viva-voce) (-vocees, -voceed, -voceing) examine orally. [medieval latin, = with the living voice]</p>",
      },
    ],
  },
  {
    word: "Vivid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vivid <strong class='adj'> adj. </strong> 1 (of light or colour) strong, intense. 2 (of a memory, description, the imagination, etc.) Clear, lively, graphic. vividly <strong class='adv'> adv. </strong> Vividness <strong class='noun'> n. </strong> [latin]</p>",
      },
    ],
  },
  {
    word: "Vivify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vivify <strong class='verb'> v. </strong> (-ies, -ied) enliven, animate, give life to. [french from latin]</p>",
      },
    ],
  },
  {
    word: "Viviparous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viviparous <strong class='adj'> adj. </strong> Zool. Bringing forth young alive. [latin vivus alive, pario produce]</p>",
      },
    ],
  },
  {
    word: "Vivisect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vivisect <strong class='verb'> v. </strong> Perform vivisection on.</p>",
      },
    ],
  },
  {
    word: "Vivisection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vivisection <strong class='noun'> n. </strong> Surgical experimentation on living animals for scientific research. vivisectional adj. Vivisectionist <strong class='noun'> n. </strong> & adj. Vivisector <strong class='noun'> n. </strong> [latin vivus living, dissection (*dissect)]</p>",
      },
    ],
  },
  {
    word: "Vixen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vixen <strong class='noun'> n. </strong> 1 female fox. 2 spiteful woman. [old english: related to *fox]</p>",
      },
    ],
  },
  {
    word: "Viz.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Viz. <strong class='adv'> adv. </strong> Namely; that is to say; in other words. [abbreviation of *videlicet, z = medieval latin symbol for abbreviation of -et]</p>",
      },
    ],
  },
  {
    word: "Vizier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vizier <strong class='noun'> n. </strong> Hist. High official in some muslim countries. [ultimately from arabic]</p>",
      },
    ],
  },
  {
    word: "Vizor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Vizor <strong class='var'> var. </strong> Of *visor.</p>",
      },
    ],
  },
  {
    word: "V-neck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>V-neck <strong class='noun'> n. </strong> (often attrib.) V-shaped neckline on a pullover etc.</p>",
      },
    ],
  },
  {
    word: "Vocable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vocable <strong class='noun'> n. </strong> Word, esp. With reference to form not meaning. [latin voco call]</p>",
      },
    ],
  },
  {
    word: "Vocabulary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vocabulary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 words used by a particular language, book, branch of science, or author. 2 list of these, in alphabetical order with definitions or translations. 3 individual's stock of words (limited vocabulary). 4 set of artistic or stylistic forms or techniques. [medieval latin: related to *vocable]</p>",
      },
    ],
  },
  {
    word: "Vocal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vocal <strong class='adj'> —adj.</strong> 1 of or uttered by the voice. 2 outspoken (very vocal about his rights). <strong class='noun'> —n. </strong> (in sing. Or pl.) Sung part or piece of music. vocally <strong class='adv'> adv. </strong> [latin: related to *voice]</p>",
      },
    ],
  },
  {
    word: "Vocal cords",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vocal cords <strong class='noun'> n. </strong> Voice-producing part of the larynx.</p>",
      },
    ],
  },
  {
    word: "Vocalist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Vocalist <strong class='noun'> n. </strong> Singer.</p>",
      },
    ],
  },
  {
    word: "Vocalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vocalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 form (a sound) or utter (a word) with the voice. 2 articulate, express. vocalization n.</p>",
      },
    ],
  },
  {
    word: "Vocation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vocation <strong class='noun'> n. </strong> 1 a strong feeling of suitability for a particular career. B this regarded as a divine call to a career in the church. 2 employment, trade, profession. vocational adj. [latin voco call]</p>",
      },
    ],
  },
  {
    word: "Vocative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vocative <strong class='gram'>gram.</strong> <strong class='noun'> —n. </strong> Case of a noun used in addressing a person or thing. <strong class='adj'> —adj.</strong> Of or in this case.</p>",
      },
    ],
  },
  {
    word: "Vociferate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vociferate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 utter noisily. 2 shout, bawl. vociferation <strong class='noun'> n. </strong> Vociferator <strong class='noun'> n. </strong> [latin: related to *voice, fero bear]</p>",
      },
    ],
  },
  {
    word: "Vociferous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vociferous <strong class='adj'> adj. </strong> 1 noisy, clamorous. 2 insistently and forcibly outspoken. vociferously adv.</p>",
      },
    ],
  },
  {
    word: "Vodka",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vodka <strong class='noun'> n. </strong> Alcoholic spirit distilled esp. In russia from rye etc. [russian]</p>",
      },
    ],
  },
  {
    word: "Vogue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vogue <strong class='noun'> n. </strong> 1 (prec. By the) prevailing fashion. 2 (often attrib.) Popular use (had a great vogue). in vogue in fashion. voguish adj. [french from italian]</p>",
      },
    ],
  },
  {
    word: "Voice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voice <strong class='noun'> —n. </strong> 1 a sound formed in the larynx and uttered by the mouth, esp. By a person speaking, singing, etc. B power of this (lost her voice). 2 a use of the voice; spoken or written expression (esp. Give voice). B opinion so expressed. C right to express an opinion. D medium for expression. 3 <strong class='gram'>gram.</strong> Set of verbal forms showing whether a verb is active or passive. —v. (-cing) 1 express. 2 (esp. As voiced adj.) Utter with vibration of the vocal cords (e.g. B, d). in good voice singing or speaking well or easily. With one voice unanimously. [latin vox voc-]</p>",
      },
    ],
  },
  {
    word: "Voice-box",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Voice-box <strong class='noun'> n. </strong> Larynx.</p>",
      },
    ],
  },
  {
    word: "Voice in the wilderness",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voice in the wilderness <strong class='noun'> n. </strong> Unheeded advocate of reform.</p>",
      },
    ],
  },
  {
    word: "Voiceless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voiceless <strong class='adj'> adj. </strong> 1 dumb, speechless. 2 uttered without vibration of the vocal cords (e.g. F, p).</p>",
      },
    ],
  },
  {
    word: "Voice-over",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voice-over <strong class='noun'> n. </strong> Commentary in a film etc. By an unseen narrator.</p>",
      },
    ],
  },
  {
    word: "Void",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Void <strong class='adj'> —adj.</strong> 1 empty, vacant. 2 (of a contract etc.) Invalid, not legally binding (null and void). <strong class='noun'> —n. </strong> Empty space, vacuum. —v. 1 render void. 2 excrete; empty (the bowels etc.). void of lacking, free from. [french]</p>",
      },
    ],
  },
  {
    word: "Voile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voile <strong class='noun'> n. </strong> Fine semi-transparent fabric. [french, = *veil]</p>",
      },
    ],
  },
  {
    word: "Vol.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Vol. <strong class='abbr'> Abbr. </strong> Volume.</p>",
      },
    ],
  },
  {
    word: "Volatile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volatile <strong class='adj'> adj. </strong> 1 changeable in mood; fickle. 2 (of trading conditions etc.) Unstable. 3 (of a political situation etc.) Likely to erupt in violence. 4 chem. Evaporating rapidly. volatility <strong class='noun'> n. </strong> [latin volo fly]</p>",
      },
    ],
  },
  {
    word: "Volatilize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volatilize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) turn into vapour. volatilization n.</p>",
      },
    ],
  },
  {
    word: "Vol-au-vent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vol-au-vent <strong class='noun'> n. </strong> Small puff pastry case with a savoury filling. [french, literally ‘flight in the wind’]</p>",
      },
    ],
  },
  {
    word: "Volcanic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volcanic <strong class='adj'> adj. </strong> Of, like, or from a volcano. volcanically adv.</p>",
      },
    ],
  },
  {
    word: "Volcano",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volcano <strong class='noun'> n. </strong> (pl. -es) 1 mountain or hill from which lava, steam, etc. Escape through openings in the earth's crust. 2 volatile situation. [latin volcanus vulcan, roman god of fire]</p>",
      },
    ],
  },
  {
    word: "Vole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vole <strong class='noun'> n. </strong> Small plant-eating rodent. [originally vole-mouse from norwegian voll field]</p>",
      },
    ],
  },
  {
    word: "Volition",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volition <strong class='noun'> n. </strong> Act or power of willing. of one's own volition voluntarily. volitional adj. [latin volo wish]</p>",
      },
    ],
  },
  {
    word: "Volley",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volley <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 a simultaneous firing of a number of weapons. B bullets etc. So fired. 2 (usu. Foll. By of) torrent (of abuse etc.). 3 playing of a ball in tennis, football, etc., before it touches the ground. —v. (-eys, -eyed) return or send by or in a volley. [french volée from latin volo fly]</p>",
      },
    ],
  },
  {
    word: "Volleyball",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volleyball <strong class='noun'> n. </strong> Game for two teams of six hitting a large ball by hand over a high net.</p>",
      },
    ],
  },
  {
    word: "Volt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volt <strong class='noun'> n. </strong> Si unit of electromotive force, the difference of potential that would carry one ampere of current against one ohm resistance. [volta, name of a physicist]</p>",
      },
    ],
  },
  {
    word: "Voltage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voltage <strong class='noun'> n. </strong> Electromotive force expressed in volts.</p>",
      },
    ],
  },
  {
    word: "Volte-face",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volte-face <strong class='noun'> n. </strong> Sudden reversal of one's attitude or opinion. [french from italian]</p>",
      },
    ],
  },
  {
    word: "Voltmeter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voltmeter <strong class='noun'> n. </strong> Instrument measuring electric potential in volts.</p>",
      },
    ],
  },
  {
    word: "Voluble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voluble <strong class='adj'> adj. </strong> Speaking or spoken fluently or at length. volubility <strong class='noun'> n. </strong> Volubly <strong class='adv'> adv. </strong> [latin volvo roll]</p>",
      },
    ],
  },
  {
    word: "Volume",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volume <strong class='noun'> n. </strong> 1 single book forming part or all of a work. 2 a solid content, bulk. B space occupied by a gas or liquid. C (foll. By of) amount or quantity. 3 strength of sound, loudness. 4 (foll. By of) a moving mass of water etc. B (usu. In pl.) Mass of smoke etc. [latin volumen: related to *voluble, ancient books being in roll form]</p>",
      },
    ],
  },
  {
    word: "Volumetric",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volumetric <strong class='adj'> adj. </strong> Of measurement by volume. volumetrically <strong class='adv'> adv. </strong> [from *volume, *metric]</p>",
      },
    ],
  },
  {
    word: "Voluminous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voluminous <strong class='adj'> adj. </strong> 1 (of drapery etc.) Loose and ample. 2 written or writing at great length. [latin: related to *volume]</p>",
      },
    ],
  },
  {
    word: "Voluntary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voluntary <strong class='adj'> —adj.</strong> 1 acting, done, or given willingly; not compulsory; intentional. 2 unpaid (voluntary work). 3 (of an institution) supported by charity. 4 (of a school) built by a charity but maintained by a local education authority. 5 brought about by voluntary action. 6 (of a movement, muscle, or limb) controlled by the will. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> organ solo played before or after a church service. voluntarily <strong class='adv'> adv. </strong> Voluntariness <strong class='noun'> n. </strong> [latin voluntas will]</p>",
      },
    ],
  },
  {
    word: "Volunteer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volunteer <strong class='noun'> —n. </strong> Person who voluntarily undertakes a task or enters military etc. Service. —v. 1 (often foll. By to + infin.) Undertake or offer (one's services, a remark, etc.) Voluntarily. 2 (often foll. By for) be a volunteer. [french: related to *voluntary]</p>",
      },
    ],
  },
  {
    word: "Voluptuary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voluptuary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> person who seeks luxury and sensual pleasure. [latin: related to *voluptuous]</p>",
      },
    ],
  },
  {
    word: "Voluptuous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voluptuous <strong class='adj'> adj. </strong> 1 of, tending to, occupied with, or derived from, sensuous or sensual pleasure. 2 (of a woman) curvaceous and sexually desirable. voluptuously <strong class='adv'> adv. </strong> [latin voluptas pleasure]</p>",
      },
    ],
  },
  {
    word: "Volute",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Volute <strong class='noun'> n. </strong> Spiral stonework scroll as an ornament of esp. Ionic capitals. [latin volvo -ut- roll]</p>",
      },
    ],
  },
  {
    word: "Vomit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vomit <strong class='noun'> —v. </strong> (-t-) 1 eject (contents of the stomach) through the mouth; be sick. 2 (of a volcano, chimney, etc.) Eject violently, belch forth. <strong class='noun'> —n. </strong> Matter vomited from the stomach. [latin]</p>",
      },
    ],
  },
  {
    word: "Voodoo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voodoo <strong class='noun'> —n. </strong> Religious witchcraft as practised esp. In the w. Indies. —v. (-doos, -dooed) affect by voodoo; bewitch. [dahomey]</p>",
      },
    ],
  },
  {
    word: "Voracious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voracious <strong class='adj'> adj. </strong> 1 gluttonous, ravenous. 2 very eager (voracious reader). voraciously <strong class='adv'> adv. </strong> Voracity <strong class='noun'> n. </strong> [latin vorax from voro devour]</p>",
      },
    ],
  },
  {
    word: "Vortex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vortex <strong class='noun'> n. </strong> (pl. -texes or -tices) 1 whirlpool, whirlwind. 2 whirling motion or mass. 3 thing viewed as destructive or devouring (the vortex of society). vortical adj. [latin: related to *vertex]</p>",
      },
    ],
  },
  {
    word: "Vorticist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vorticist <strong class='noun'> n. </strong> Futuristic painter, writer, etc., of a school based on the so-called ‘vortices’ of modern civilization. vorticism n.</p>",
      },
    ],
  },
  {
    word: "Votary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Votary <strong class='noun'> n. </strong> (pl. -ies; fem. Votaress) (usu. Foll. By of) 1 person dedicated to the service of a god or cult. 2 devotee of a person, occupation, etc. [latin: related to *vote]</p>",
      },
    ],
  },
  {
    word: "Vote",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vote <strong class='noun'> —n. </strong> 1 formal expression of choice or opinion by a ballot, show of hands, etc., in an election etc. 2 (usu. Prec. By the) right to vote, esp. In a state election. 3 opinion expressed by a vote (vote of no confidence). 4 votes given by or for a particular group (the welsh vote; the labour vote). —v. <strong class='ting'> (-ting) </strong> 1 (often foll. By for, against) give a vote. 2 a enact or resolve by a majority of votes. B grant (a sum of money) by vote. 3 <strong class='colloq'> colloq. </strong> Pronounce by general consent. 4 (often foll. By that) suggest, urge. vote down defeat (a proposal etc.) In a vote. Vote in elect by voting. Vote off dismiss from (a committee etc.) By voting. Vote out dismiss from office etc. By voting. Vote with one's feet <strong class='colloq'> colloq. </strong> Indicate an opinion by one's presence or absence.</p>",
      },
    ],
  },
  {
    word: "Voter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voter <strong class='noun'> n. </strong> Person voting or with the right to vote at an election.</p>",
      },
    ],
  },
  {
    word: "Votive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Votive <strong class='adj'> adj. </strong> Offered or consecrated in fulfilment of a vow (votive offering). [latin: related to *vote]</p>",
      },
    ],
  },
  {
    word: "Vouch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vouch <strong class='verb'> v. </strong> (foll. By for) answer for, be surety for (will vouch for the truth of this; can vouch for him). [french vo(u)cher summon, invoke]</p>",
      },
    ],
  },
  {
    word: "Voucher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voucher <strong class='noun'> n. </strong> 1 document exchangeable for goods or services. 2 receipt. [from anglo-french, or from *vouch]</p>",
      },
    ],
  },
  {
    word: "Vouchsafe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vouchsafe <strong class='verb'> v. </strong> (-fing) formal 1 condescend to grant. 2 (foll. By to + infin.) Condescend.</p>",
      },
    ],
  },
  {
    word: "Vow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vow <strong class='noun'> —n. </strong> Solemn, esp. Religious, promise (monastic vows; marriage vows). —v. 1 promise solemnly. 2 archaic declare solemnly. [french vou(er): related to *vote]</p>",
      },
    ],
  },
  {
    word: "Vowel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vowel <strong class='noun'> n. </strong> 1 speech-sound made with vibration of the vocal cords but without audible friction. 2 letter(s) representing this, as a, e, i, o, u, aw, ah. [latin: related to *vocal]</p>",
      },
    ],
  },
  {
    word: "Vox pop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vox pop <strong class='noun'> n. </strong> (often attrib.) Colloq. Popular opinion as represented by informal comments from the public. [abbreviation of *vox populi]</p>",
      },
    ],
  },
  {
    word: "Vox populi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vox populi <strong class='noun'> n. </strong> Public opinion, popular belief. [latin, = the people's voice]</p>",
      },
    ],
  },
  {
    word: "Voyage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voyage <strong class='noun'> —n. </strong> Journey, esp. A long one by sea or in space. —v. (-ging) make a voyage. voyager <strong class='noun'> n. </strong> [latin *viaticum]</p>",
      },
    ],
  },
  {
    word: "Voyeur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Voyeur <strong class='noun'> n. </strong> 1 person who derives sexual pleasure from secretly observing others' sexual activity or organs. 2 (esp. Covert) spectator. voyeurism <strong class='noun'> n. </strong> Voyeuristic adj. [french voir see]</p>",
      },
    ],
  },
  {
    word: "Vs.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Vs. <strong class='abbr'> Abbr. </strong> Versus.</p>",
      },
    ],
  },
  {
    word: "V-sign",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>V-sign <strong class='noun'> n. </strong> 1 sign of the letter v made with the first two fingers pointing up and the back of the hand facing outwards, as a gesture of abuse etc. 2 similar sign made with the palm of the hand facing outwards, as a symbol of victory.</p>",
      },
    ],
  },
  {
    word: "Vso",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vso <strong class='sing'> abbr. </strong> Voluntary service overseas.</p>",
      },
    ],
  },
  {
    word: "Vsop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vsop <strong class='sing'> abbr. </strong> Very special old pale (brandy).</p>",
      },
    ],
  },
  {
    word: "Vto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vto <strong class='sing'> abbr. </strong> Vertical take-off.</p>",
      },
    ],
  },
  {
    word: "Vtol",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vtol <strong class='sing'> abbr. </strong> Vertical take-off and landing.</p>",
      },
    ],
  },
  {
    word: "Vtr",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vtr <strong class='sing'> abbr. </strong> Videotape recorder.</p>",
      },
    ],
  },
  {
    word: "Vulcanite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulcanite <strong class='noun'> n. </strong> Hard black vulcanized rubber. [related to *vulcanize]</p>",
      },
    ],
  },
  {
    word: "Vulcanize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulcanize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) treat (rubber etc.) With sulphur at a high temperature to strengthen it. vulcanization <strong class='noun'> n. </strong> [vulcan: related to *volcano]</p>",
      },
    ],
  },
  {
    word: "Vulcanology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulcanology <strong class='noun'> n. </strong> The study of volcanoes. vulcanological adj. Vulcanologist n.</p>",
      },
    ],
  },
  {
    word: "Vulgar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulgar <strong class='adj'> adj. </strong> 1 a coarse; indecent; tasteless. B of or characteristic of the common people. 2 common; prevalent (vulgar errors). vulgarly <strong class='adv'> adv. </strong> [latin vulgus common people]</p>",
      },
    ],
  },
  {
    word: "Vulgar fraction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulgar fraction <strong class='noun'> n. </strong> Fraction expressed by numerator and denominator, not decimally.</p>",
      },
    ],
  },
  {
    word: "Vulgarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulgarian <strong class='noun'> n. </strong> Vulgar (esp. Rich) person.</p>",
      },
    ],
  },
  {
    word: "Vulgarism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulgarism <strong class='noun'> n. </strong> Vulgar word, expression, action, or habit.</p>",
      },
    ],
  },
  {
    word: "Vulgarity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulgarity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> vulgar act, expression, or state.</p>",
      },
    ],
  },
  {
    word: "Vulgarize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulgarize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 make vulgar. 2 spoil by popularizing. vulgarization n.</p>",
      },
    ],
  },
  {
    word: "Vulgar tongue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulgar tongue <strong class='noun'> n. </strong> (prec. By the) national or vernacular language.</p>",
      },
    ],
  },
  {
    word: "Vulgate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulgate <strong class='noun'> n. </strong> 4th-c. Latin version of the bible. [latin: related to *vulgar]</p>",
      },
    ],
  },
  {
    word: "Vulnerable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulnerable <strong class='adj'> adj. </strong> 1 easily wounded or harmed. 2 (foll. By to) exposed to damage, temptation, etc. vulnerability <strong class='noun'> n. </strong> Vulnerably <strong class='adv'> adv. </strong> [latin vulnus -er- wound]</p>",
      },
    ],
  },
  {
    word: "Vulpine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulpine <strong class='adj'> adj. </strong> 1 of or like a fox. 2 crafty, cunning. [latin vulpes fox]</p>",
      },
    ],
  },
  {
    word: "Vulture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulture <strong class='noun'> n. </strong> 1 large carrion-eating bird of prey, reputed to gather with others in anticipation of a death. 2 rapacious person. [anglo-french from latin]</p>",
      },
    ],
  },
  {
    word: "Vulva",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vulva <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> external female genitals. [latin]</p>",
      },
    ],
  },
  {
    word: "Vv.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vv. <strong class='abbr'> Abbr. </strong> 1 verses. 2 volumes.</p>",
      },
    ],
  },
  {
    word: "Vying  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Vying  <strong class='see'>pres. Part. Of *</strong>vie.</p>",
      },
    ],
  },
];

export const alphabet_V_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वी 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वी 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वी 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रुकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रिक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खाली कब्जे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छुट्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टीकाकरण करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टीका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संदेह करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रिक्तिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खालीपन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैक्यूम ब्रेक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैक्यूम क्लीनर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैक्यूम फ्लास्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैक्यूम पैक्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेक्यूम - ट्यूब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vade mecum</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मौज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योनि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यर्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मैजपोश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घाटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिदाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैलेंस 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैलेंस 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयोजकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रेमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेलेरियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेवक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वात -संबंधी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहादुर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मान्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हैंड बैग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैलियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घाटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीरता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीरता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कीमती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूल्यांकन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूल्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूल्य वर्धित कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूल्य निर्णय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाल्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वामोज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vamp1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vamp2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिशाच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>VAN1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>VAN2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैनेडियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्बर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपद्रव मचाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फलक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हरावल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वनीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गायब होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साफ करने वाला क्रीम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोपी बिन्दु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घमंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैनिटी बैग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घमंड इकाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vanquish</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सहूलियत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीरस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाप बनकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाप-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाष्प ट्रेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झगड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उतार-चढ़ाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रंग-बिरंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रंग-बिरंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैरिकाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विभिन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तरह तरह का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विविधता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विविध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वार्लिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वार्निश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विश्वविद्यालय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलग होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीएएस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संवहनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वास डेफरेंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुलदान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुरुष नसबंदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेसिलीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जागीरदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत बड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेटिकन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाडेविल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मेहराब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वॉल्टिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मेहराब घोड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डींग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुलपति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीसीआर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडीयू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बछड़े का मांस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेक्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वी -दिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाकाहारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाकाहारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेजबर्गर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सबजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सब्जी का कुम्हाड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वनस्पतिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाकाहारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उगना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वनस्पति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वनस्पतिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाकाहारी बर्गर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लवलीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाहन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेल्क्रो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेलेटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चर्मपत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेलोड्रम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>velor</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मख़मली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नकली मखमली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मखमली दस्ताने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेन।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़रख़ीद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेच देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिशोध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यापारिक मशीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेंडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पोशिश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सम्मानित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सम्मानित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विषयी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुप्त रोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनीशियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खिड़की की झिलमिली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिशोध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तामसिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्षम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिरन का मांस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेन आरेख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़हर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिरापरक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vent1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेंट 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हवादार करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पंखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निलय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेंट्रिलोक्विज़्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोखिम उठाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेंचर स्काउट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साहसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीनस फ्लाई ट्रैप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सत्यप्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरामदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मौखिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रिया बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मौखिक संज्ञा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिशब्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Verbena</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शब्दाडंबर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाचाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सब्ज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्णय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर्डिग्रिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Verge1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Verge2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धरना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सत्यापित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वास्तव में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संभाव्यता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सचाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेवई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर्मा -संबंधी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>vermiculite</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कीड़ा के रूप का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर्मीफॉर्म एपेंडिक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर्मिलियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दरिंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वरमाउथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मातृभाषा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वसंती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वसंत विषुव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर्नियर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेरोनल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेरोनिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मस्सा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुमुखी प्रतिभा संपन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कविता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तजुर्बेकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटा पद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काविता लिखना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संस्करण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बनाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बांस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हड्डीवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिखर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊर्ध्वाधर टेक-ऑफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सिर घुमानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सिर का चक्कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्रह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चुस्ती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत हल्का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुटिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेस्पर्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जहाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बनियान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेस्टल वर्जिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निहित स्वार्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरोठा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निशान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वस्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेस्ट्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vet1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vet2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुभवी व्यक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुभवी कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पशुचिकित्सा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पशुचिकित्सा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पशु शल्यचिकित्सक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीटो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंग करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुब्बार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अफ़सोसनाक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झगड़े का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वी.जी.सी.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीएचएफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़रिये</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यवहार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शीशी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वियांड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वियाटिकम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुभूति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइब्राफोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कंपन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कंपन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकंपन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थरथानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Viburnum</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पादरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निवास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विकैरियस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइस 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइस 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइस 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुलपति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपाध्यक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विक्रेगल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विकरिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइस रिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइस-रोय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइस स्क्वाड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विपरीतता से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vichyssoise</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विची वाटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आस-पास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुष्चक्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शातिर सर्पिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्याय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीड़ित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यातनाऐं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विजेता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विक्टोरिया क्रॉस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विक्टोरियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विक्टोरियाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विक्टोरिया स्पंज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विजयी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चरना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचित्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विकलांग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विकग्ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़बरदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडियो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडियो कैसेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडियोडिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडियो गेम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडियो गंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडियो रिकॉर्डर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडियो की दुकान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडियोटेप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीडियोटेप रिकॉर्डर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>VideoTex</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झपकी लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वियतनामी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ViewData</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दर्शक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दृश्यदर्शी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दृष्टिकोण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जलूस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जागरूकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सजग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ताक़त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ताक़त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइकिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गाली देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गांव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खलनायक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शरारतपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विलीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनाईग्रेटे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साबित कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिशोधी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सिरका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनयार्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vingt-एट-अन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सस्ता शराब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मदिरा का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बढ़िया शराब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विंटेज कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विंटनर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनाइल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Viola1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वायोला 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वियोला दा गाम्बा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>का उल्लंघन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिंसा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिंसक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंगनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वायोलिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वायोलिन बजानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वायलनचेलो बजानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीआईपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विरागो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वायरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुंआरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अक्षत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुंवारी जन्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर्जीनिया क्रीपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कौमार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कन्या</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मर्द का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइरालजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आभासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आभासी रूप से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आभासी वास्तविकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कलाप्रवीण व्यक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धार्मिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विषैला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाइरस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीसा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुंह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>के रू-बरू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंत का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिपचिपा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आज़ाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विकांट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विकांटेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिपचिपा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिकंजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दृश्यता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दृश्यमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नज़र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काल्पनिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुलाकात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आगंतुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुलाक़ात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आगंतुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आगंतुकों की पुस्तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टोपी का छज्जा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विस्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तस्वीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दृश्य सहायता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दृश्य प्रदर्शन इकाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कल्पना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महत्वपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फिर जीवित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महत्वपूर्ण आँकड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विटामिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विटामिन बी जटिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विटामिन बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भ्रष्ट करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंगूर की खेती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कांच का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्कंच के समान पदार्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विट्रिट करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यंग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कटु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विटुपेरिट करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Viva1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Viva2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विवेक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गरमागरम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मछली पालने का बाड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मौखिक परीक्षा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फिर जीवित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विविपेरस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विवेक करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विविसेकशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोमड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अर्थात।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विज़ीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टोपी का छज्जा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वि रूप में बना हुआ गले की काट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उच्चारण किया हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शब्दावली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वर रज्जु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गायक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुखर करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पेशा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सम्बोधन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गला फाड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुखर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वोदका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रचलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवाज़ बॉक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंगल में आवाज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मौन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पार्श्व स्वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गूंज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वॉल्यूम।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिवर्तनशील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाप बनकर उड़ जाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वॉल-ए-वेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज्वालामुखी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज्वालामुखी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इच्छाशक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फ़ायर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वालीबाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाल्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वोल्टेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाल्ट वाली चेहरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाल्टमीटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बातूनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मात्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मोटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वैच्छिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वयंसेवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वंशावली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कामुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुंडलित वक्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उल्टी करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जादू का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पेटू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भंवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वोर्टिसिस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तरफ़दार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मतदाता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मन्नत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़मानत देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाउचर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विभूषित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्रत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आम विचार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन संवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जलयात्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वोयर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बनाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वि हस्ताक्षर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीएसओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीएसओपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीटीओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vtol</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वीटीआर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वल्केनाइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>vulcanize</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वल्कनोलॉजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अश्लील अंश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्यता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्यता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अश्लील करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अश्लील जीभ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वुल्गेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भेद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मक्कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गिद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Vv।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक दूसरे को टोकनेवाला</p>",
  },
];