<template>
  <div class="col-12" style="">
    <img style="width: 20px; height: 20px" :src="Volume" @click="greet(textToSpeech)" :disabled="disabledToggel" />
    <!-- <p v-for="list of audioList" :key="list">{{ list.lang }}</p> -->
    <!-- <div v-if="voiceList">
      <p v-for="list of voiceList" :key="list">
        {{ list.name }} - {{ list.lang }}
      </p>
    </div> -->
  </div>
</template>

<script>
import volume from "../../assets/volume.svg";
import AllLang from "./allLang.js";
export default {
  name: "speech",
  props: {
    languageObject: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    speechText: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      Volume: volume,
      allLang: AllLang,
      name: "",
      selectedVoice: 0,
      synth: window.speechSynthesis,
      voiceList: [],
      greetingSpeech: new window.SpeechSynthesisUtterance(),
      voiceText: this.speechText,
      disabledToggel: false,
    };
  },
  components: {
    // PulseLoader
  },
  mounted() {
    // wait for voices to load
    // I can't get FF to work without calling this first
    // Chrome works on the onvoiceschanged function
    // this.voiceList = this.getAllSystemLangAudioList();
    this.voiceList = this.synth.getVoices();
    // console.log("this.voiceList", this.voiceList);
    // if (this.voiceList.length) {
    // this.isLoading = false;
    // }
    this.synth.onvoiceschanged = () => {
      this.voiceList = this.synth.getVoices();
      // give a bit of delay to show loading screen
      // just for the sake of it, I suppose. Not the best reason
      // setTimeout(() => {
      //   this.isLoading = false;
      // }, 800);
    };
    // this.listenForSpeechEvents();
  },
  methods: {
    // async getAllSystemLangAudioList() {
    //   // let list = this.synth.getVoices();
    //   // console.log("listttttttt", list);
    //   return await this.synth.getVoices();
    // },
    /**
     * React to speech events
     */
    // listenForSpeechEvents() {
    //   this.greetingSpeech.onstart = () => {
    //     this.isLoading = true;
    //   };
    //   this.greetingSpeech.onend = () => {
    //     this.isLoading = false;
    //   };
    // },
    /**
     * Shout at the user
     */
    greet(textToSpeech) {
      textToSpeech(this.voiceList);
    },
    textToSpeech(audioList) {
      this.disabledToggel = true;
      let selectedLangObj;
      if (audioList.length) {
        for (let list of audioList) {
          let langLatter = list.lang.slice(0, 2);
          // console.log("listttttttttt", list.lang, list);
          // console.log("languageObject++++", this.languageObject[0]);
          if (
            langLatter === this.languageObject.code ||
            (this.languageObject.length &&
              langLatter === this.languageObject[0].code)
          ) {
            // console.log("listttttttttt", list.lang);
            // console.log("languageObject++++", this.languageObject.code);
            // let obj = this.allLang.tts.find((lang) => {
            //   // console.log("langgggggggg", lang.code);
            //   // console.log("this.languageObject.code", list.lang);
            //   return lang.code === list.lang;
            // });
            // console.log("objjjjjjjjjjjjjjjjj", obj);
            // console.log("listttttttt", list);
            selectedLangObj = list;
          }
        }
      }
      if (selectedLangObj) {
        // it should be 'craic', but it doesn't sound right
        this.greetingSpeech.text = this.voiceText;
        // this.greetingSpeech.voice = this.voiceList[2];
        this.greetingSpeech.voice = selectedLangObj;
        this.synth.speak(this.greetingSpeech);
      } else {
        alert("This voice is not available in your browser!");
      }
      setTimeout(() => {
        this.disabledToggel = false;
      }, 2000);
    },
  },
};
</script>

<style scoped>
.quiz-container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.1);
}

.v-spinner {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity ease 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.btn-success {
  background: #43c6ac;
  border-color: #43c6ac;
  cursor: pointer;
}

h1 {
  margin-bottom: 25px;
}

img[disabled="true"] {
  opacity: .5;
}
</style>
