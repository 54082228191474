export const alphabet_U = [
  {
    alpha: "<!-- U <p> start here </p> -->",
  },
  {
    word: "U",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p> </p>",
      },
    ],
  },
  {
    word: "U1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>U1 <strong class='noun'> n. </strong> (also u) (pl. Us or u's) 1 twenty-first letter of the alphabet. 2 u-shaped object or curve.</p>",
      },
    ],
  },
  {
    word: "U2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>U2 <strong class='adj'> adj. </strong> Colloq. Upper class or supposedly upper class. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "U3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>U3 <strong class='sing'> abbr. </strong> (also u.) Universal (of films classified as suitable for all).</p>",
      },
    ],
  },
  {
    word: "U4",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>U4 <strong class='prep'> symb. </strong> Uranium.</p>",
      },
    ],
  },
  {
    word: "Ub40",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ub40 <strong class='sing'> abbr. </strong> 1 card issued to people claiming unemployment benefit. 2 <strong class='colloq'> colloq. </strong> Unemployed person. [unemployment benefit]</p>",
      },
    ],
  },
  {
    word: "Ubiquitous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ubiquitous <strong class='adj'> adj. </strong> 1 (seemingly) present everywhere simultaneously. 2 often encountered. ubiquity <strong class='noun'> n. </strong> [latin ubique everywhere]</p>",
      },
    ],
  },
  {
    word: "U-boat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>U-boat <strong class='noun'> n. </strong> Hist. German submarine. [german untersee undersea]</p>",
      },
    ],
  },
  {
    word: "U.c.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>U.c. <strong class='abbr'> Abbr. </strong> Upper case.</p>",
      },
    ],
  },
  {
    word: "Ucca",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ucca <strong class='sing'> abbr. </strong> Universities central council on admissions.</p>",
      },
    ],
  },
  {
    word: "Uda",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uda <strong class='sing'> abbr. </strong> Ulster defence association (a loyalist paramilitary organization).</p>",
      },
    ],
  },
  {
    word: "Udder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Udder <strong class='noun'> n. </strong> Baglike mammary organ of cattle etc., with several teats. [old english]</p>",
      },
    ],
  },
  {
    word: "Udi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Udi <strong class='sing'> abbr. </strong> Unilateral declaration of independence.</p>",
      },
    ],
  },
  {
    word: "Udr",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Udr <strong class='sing'> abbr. </strong> Ulster defence regiment.</p>",
      },
    ],
  },
  {
    word: "Uefa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uefa <strong class='sing'> abbr. </strong> Union of european football associations.</p>",
      },
    ],
  },
  {
    word: "Ufo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ufo <strong class='noun'> n. </strong> (also ufo) <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> unidentified flying object.</p>",
      },
    ],
  },
  {
    word: "Ugh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ugh <strong class='n_pl'> int. </strong> 1 expressing disgust etc. 2 sound of a cough or grunt. [imitative]</p>",
      },
    ],
  },
  {
    word: "Ugli",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ugli <strong class='noun'> n. </strong> (pl. -lis or -lies) propr. Mottled green and yellow hybrid of a grapefruit and a tangerine. [from *ugly]</p>",
      },
    ],
  },
  {
    word: "Uglify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uglify <strong class='verb'> v. </strong> (-ies, -ied) make ugly.</p>",
      },
    ],
  },
  {
    word: "Ugly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ugly <strong class='adj'> adj. </strong> (-lier, -liest) 1 unpleasant to the eye, ear, or mind etc. (ugly scar; ugly snarl). 2 unpleasantly suggestive; discreditable (ugly rumours). 3 threatening, dangerous (an ugly look). 4 morally repulsive (ugly vices). ugliness <strong class='noun'> n. </strong> [old norse]</p>",
      },
    ],
  },
  {
    word: "Ugly customer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ugly customer <strong class='noun'> n. </strong> Threatening or violent person.</p>",
      },
    ],
  },
  {
    word: "Ugly duckling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ugly duckling <strong class='noun'> n. </strong> Person lacking early promise but blossoming later.</p>",
      },
    ],
  },
  {
    word: "Uhf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uhf <strong class='sing'> abbr. </strong> Ultrahigh frequency.</p>",
      },
    ],
  },
  {
    word: "Uh-huh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uh-huh <strong class='n_pl'> int. </strong> Colloq. Yes; indeed. [imitative]</p>",
      },
    ],
  },
  {
    word: "Uht",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uht <strong class='sing'> abbr. </strong> Ultra heat treated (esp. Of milk, for long keeping).</p>",
      },
    ],
  },
  {
    word: "Uk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uk <strong class='sing'> abbr. </strong> United kingdom.</p>",
      },
    ],
  },
  {
    word: "Ukrainian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ukrainian <strong class='noun'> —n. </strong> 1 native or language of ukraine. 2 person of ukrainian descent. <strong class='adj'> —adj.</strong> Of ukraine, its people, or language. [ukraine in eastern europe]</p>",
      },
    ],
  },
  {
    word: "Ukulele",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ukulele <strong class='noun'> n. </strong> Small four-stringed hawaiian guitar. [hawaiian]</p>",
      },
    ],
  },
  {
    word: "Ulcer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ulcer <strong class='noun'> n. </strong> 1 open sore on or in the body, often forming pus. 2 corrupting influence etc. ulcerous adj. [latin ulcus -cer-]</p>",
      },
    ],
  },
  {
    word: "Ulcerate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ulcerate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> form into or affect with an ulcer. ulceration n.</p>",
      },
    ],
  },
  {
    word: "-ule",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ule <strong class='slang_v'> suffix </strong>forming diminutive nouns (globule). [latin -ulus]</p>",
      },
    ],
  },
  {
    word: "Ullage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ullage <strong class='noun'> n. </strong> 1 amount by which a cask etc. Falls short of being full. 2 loss by evaporation or leakage. [french from latin]</p>",
      },
    ],
  },
  {
    word: "Ulna",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ulna <strong class='noun'> n. </strong> (pl. Ulnae) 1 thinner and longer bone in the forearm, opposite to the thumb. 2 corresponding bone in an animal's foreleg or a bird's wing. ulnar adj. [latin]</p>",
      },
    ],
  },
  {
    word: "Ulster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ulster <strong class='noun'> n. </strong> Long loose overcoat of rough cloth. [ulster in ireland]</p>",
      },
    ],
  },
  {
    word: "Ulsterman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ulsterman <strong class='noun'> n. </strong> (fem. Ulsterwoman) native of ulster.</p>",
      },
    ],
  },
  {
    word: "Ult.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ult. <strong class='abbr'> Abbr. </strong> Ultimo.</p>",
      },
    ],
  },
  {
    word: "Ulterior",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ulterior <strong class='adj'> adj. </strong> Not evident or admitted; hidden, secret (esp. Ulterior motive). [latin, = further]</p>",
      },
    ],
  },
  {
    word: "Ultimate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultimate <strong class='adj'> —adj.</strong> 1 last or last possible, final. 2 fundamental, primary, basic (ultimate truths). <strong class='noun'> —n. </strong> 1 (prec. By the) best achievable or imaginable. 2 final or fundamental fact or principle. ultimately <strong class='adv'> adv. </strong> [latin ultimus last]</p>",
      },
    ],
  },
  {
    word: "Ultimatum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultimatum <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> final statement of terms, the rejection of which could cause hostility etc. [latin: related to *ultimate]</p>",
      },
    ],
  },
  {
    word: "Ultimo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultimo <strong class='adj'> adj. </strong> Commerce of last month (the 28th ultimo). [latin, = in the last (mense month)]</p>",
      },
    ],
  },
  {
    word: "Ultra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultra <strong class='adj'> —adj.</strong> Extreme, esp. In religion or politics. <strong class='noun'> —n. </strong> Extremist. [see *ultra-]</p>",
      },
    ],
  },
  {
    word: "Ultra-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultra- <strong class='n_pl'> comb. </strong> Form 1 extreme(ly), excessive(ly) (ultra-modern). 2 beyond. [latin ultra beyond]</p>",
      },
    ],
  },
  {
    word: "Ultra-high",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultra-high <strong class='adj'> adj. </strong> (of a frequency) in the range 300 to 3000 megahertz.</p>",
      },
    ],
  },
  {
    word: "Ultramarine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultramarine <strong class='noun'> —n. </strong> 1 brilliant blue pigment <strong class='archaic_it_was'> orig. </strong> From lapis lazuli. 2 colour of this. <strong class='adj'> —adj.</strong> Of this colour. [italian and medieval latin, = beyond the sea, from where lapis lazuli was brought]</p>",
      },
    ],
  },
  {
    word: "Ultramicroscopic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultramicroscopic <strong class='adj'> adj. </strong> Too small to be seen by an ordinary optical microscope.</p>",
      },
    ],
  },
  {
    word: "Ultramontane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultramontane <strong class='adj'> —adj.</strong> 1 situated beyond the alps. 2 advocating supreme papal authority. <strong class='noun'> —n. </strong> 1 person living beyond the alps. 2 advocate of supreme papal authority. [medieval latin: related to *mountain]</p>",
      },
    ],
  },
  {
    word: "Ultrasonic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultrasonic <strong class='adj'> adj. </strong> Of or using sound waves pitched above the range of human hearing. ultrasonically adv.</p>",
      },
    ],
  },
  {
    word: "Ultrasonics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultrasonics <strong class='n_pl'> n.pl. </strong> (usu. Treated as sing.) Science of ultrasonic waves.</p>",
      },
    ],
  },
  {
    word: "Ultrasound",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultrasound <strong class='noun'> n. </strong> Ultrasonic waves.</p>",
      },
    ],
  },
  {
    word: "Ultraviolet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultraviolet <strong class='adj'> adj. </strong> Of or using radiation with a frequency just beyond that of the violet end of the visible spectrum.</p>",
      },
    ],
  },
  {
    word: "Ultra vires",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ultra vires <strong class='adv'> adv. </strong> & predic.adj. Beyond one's legal power or authority. [latin]</p>",
      },
    ],
  },
  {
    word: "Ululate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ululate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> howl, wail. ululation <strong class='noun'> n. </strong> [latin]</p>",
      },
    ],
  },
  {
    word: "Um",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Um <strong class='n_pl'> int. </strong> Expressing hesitation or a pause in speech. [imitative]</p>",
      },
    ],
  },
  {
    word: "Umbel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umbel <strong class='noun'> n. </strong> Flower-cluster with stalks springing from a common centre and forming a flat or curved surface. umbellate adj. [latin umbella sunshade]</p>",
      },
    ],
  },
  {
    word: "Umbelliferous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umbelliferous <strong class='adj'> adj. </strong> (of a plant) bearing umbels, such as parsley and carrot.</p>",
      },
    ],
  },
  {
    word: "Umber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umber <strong class='noun'> —n. </strong> 1 natural pigment like ochre but darker and browner. 2 colour of this. <strong class='adj'> —adj.</strong> Of this colour. [latin umbra shadow]</p>",
      },
    ],
  },
  {
    word: "Umbilical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umbilical <strong class='adj'> adj. </strong> Of the navel. [from *umbilicus]</p>",
      },
    ],
  },
  {
    word: "Umbilical cord",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umbilical cord <strong class='noun'> n. </strong> Cordlike structure attaching a foetus to the placenta.</p>",
      },
    ],
  },
  {
    word: "Umbilicus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umbilicus <strong class='noun'> n. </strong> (pl. -ci or -cuses) navel. [latin]</p>",
      },
    ],
  },
  {
    word: "Umbra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umbra <strong class='noun'> n. </strong> (pl. -s or -brae) total shadow, esp. That cast on the earth by the moon during a solar eclipse. [latin, = shadow]</p>",
      },
    ],
  },
  {
    word: "Umbrage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umbrage <strong class='noun'> n. </strong> Offence taken (esp. Take umbrage at). [latin: related to *umbra]</p>",
      },
    ],
  },
  {
    word: "Umbrella",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umbrella <strong class='noun'> n. </strong> 1 collapsible cloth canopy on a central stick, used against rain, strong sun, etc. 2 protection, patronage. 3 (often attrib.) Coordinating agency (umbrella organization). [italian diminutive: related to *umbra]</p>",
      },
    ],
  },
  {
    word: "Umlaut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umlaut <strong class='noun'> n. </strong> 1 mark (¨) used over a vowel, esp. In germanic languages, to indicate a vowel change. 2 such a vowel change, e.g. German mann, männer, english man, men. [german]</p>",
      },
    ],
  },
  {
    word: "Umpire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umpire <strong class='noun'> —n. </strong> Person enforcing rules and settling disputes in esp. Cricket or between disputants. —v. <strong class='adj'> (-ring) </strong> (often foll. By for, in, etc.) Act as umpire (in). [french nonper not equal: related to *peer2]</p>",
      },
    ],
  },
  {
    word: "Umpteen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Umpteen <strong class='colloq'> colloq. </strong> <strong class='adj'> —adj.</strong> Indefinitely many; a lot of. <strong class='pron'> — <strong class='pron'> pron. </strong> </strong> Indefinitely many. umpteenth adj. [jocular formation on *-teen]</p>",
      },
    ],
  },
  {
    word: "Un",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Un <strong class='sing'> abbr. </strong> United nations.</p>",
      },
    ],
  },
  {
    word: "Un-1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Un-1 <strong class='prefix '> prefix </strong> 1 added to adjectives and participles and their derivative nouns and adverbs, meaning: a not (unusable). B reverse of (esp. With implied approval etc.) (unselfish; unsociable). 2 (less often) added to nouns, meaning ‘a lack of’, ‘the reverse of’ (unrest; untruth). [old english] <strong class='usage'> Usage:- </strong>the number of words that can be formed with this <strong class='prefix '> prefix </strong> (and with un-2) is virtually unlimited; consequently only a selection can be given here.</p>",
      },
    ],
  },
  {
    word: "Un-2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Un-2 <strong class='prefix '> prefix </strong> added to verbs and (less often) nouns, forming verbs denoting: 1 reversal (undress; unsettle). 2 deprivation (unmask). 3 release from (unburden; uncage). 4 causing to be no longer (unman). [old english] <strong class='usage'> Usage:- </strong>see note at un-1.</p>",
      },
    ],
  },
  {
    word: "Un-3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Un-3 <strong class='prefix '> prefix </strong> chem. Denoting ‘one’, combined with other numerical roots nil (= 0), un (= 1), bi (= 2), etc., to form the names of elements based on the atomic number, and terminated with -ium, e.g. Unnilquadium = 104, ununbium = 112. [latin unus one]</p>",
      },
    ],
  },
  {
    word: "Unabashed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unabashed <strong class='adj'> adj. </strong> Not abashed.</p>",
      },
    ],
  },
  {
    word: "Unabated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unabated <strong class='adj'> adj. </strong> Not abated; undiminished.</p>",
      },
    ],
  },
  {
    word: "Unable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unable <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> (usu. Foll. By to + infin.) Not able.</p>",
      },
    ],
  },
  {
    word: "Unabridged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unabridged <strong class='adj'> adj. </strong> Complete; not abridged.</p>",
      },
    ],
  },
  {
    word: "Unacademic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unacademic <strong class='adj'> adj. </strong> (of a person, book, etc.) Not academic.</p>",
      },
    ],
  },
  {
    word: "Unacceptable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unacceptable <strong class='adj'> adj. </strong> Not acceptable. unacceptably adv.</p>",
      },
    ],
  },
  {
    word: "Unaccompanied",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaccompanied <strong class='adj'> adj. </strong> 1 not accompanied. 2 <strong class='mus'> mus. </strong> Without accompaniment.</p>",
      },
    ],
  },
  {
    word: "Unaccomplished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaccomplished <strong class='adj'> adj. </strong> 1 uncompleted. 2 lacking accomplishments.</p>",
      },
    ],
  },
  {
    word: "Unaccountable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaccountable <strong class='adj'> adj. </strong> 1 without explanation; strange. 2 not answerable for one's actions. unaccountably adv.</p>",
      },
    ],
  },
  {
    word: "Unaccounted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaccounted <strong class='adj'> adj. </strong> (often foll. By for) unexplained; excluded.</p>",
      },
    ],
  },
  {
    word: "Unaccustomed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaccustomed <strong class='adj'> adj. </strong> 1 (usu. Foll. By to) not accustomed. 2 unusual (unaccustomed silence).</p>",
      },
    ],
  },
  {
    word: "Unacknowledged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unacknowledged <strong class='adj'> adj. </strong> Not acknowledged.</p>",
      },
    ],
  },
  {
    word: "Unacquainted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unacquainted <strong class='adj'> adj. </strong> (usu. Foll. By with) not acquainted.</p>",
      },
    ],
  },
  {
    word: "Unadopted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unadopted <strong class='adj'> adj. </strong> (of a road) not maintained by a local authority.</p>",
      },
    ],
  },
  {
    word: "Unadorned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Unadorned <strong class='adj'> adj. </strong> Plain.</p>",
      },
    ],
  },
  {
    word: "Unadulterated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unadulterated <strong class='adj'> adj. </strong> 1 pure. 2 complete, utter.</p>",
      },
    ],
  },
  {
    word: "Unadventurous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unadventurous <strong class='adj'> adj. </strong> Not adventurous.</p>",
      },
    ],
  },
  {
    word: "Unadvised",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unadvised <strong class='adj'> adj. </strong> 1 indiscreet; rash. 2 without advice. unadvisedly adv.</p>",
      },
    ],
  },
  {
    word: "Unaffected",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaffected <strong class='adj'> adj. </strong> 1 (usu. Foll. By by) not affected. 2 free from affectation. unaffectedly adv.</p>",
      },
    ],
  },
  {
    word: "Unaffiliated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaffiliated <strong class='adj'> adj. </strong> Not affiliated.</p>",
      },
    ],
  },
  {
    word: "Unafraid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unafraid <strong class='adj'> adj. </strong> Not afraid.</p>",
      },
    ],
  },
  {
    word: "Unaided",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaided <strong class='adj'> adj. </strong> Without help.</p>",
      },
    ],
  },
  {
    word: "Unalike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unalike <strong class='adj'> adj. </strong> Not alike; different.</p>",
      },
    ],
  },
  {
    word: "Unalloyed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unalloyed <strong class='adj'> adj. </strong> 1 complete; utter (unalloyed joy). 2 pure.</p>",
      },
    ],
  },
  {
    word: "Unalterable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unalterable <strong class='adj'> adj. </strong> Not alterable.</p>",
      },
    ],
  },
  {
    word: "Unaltered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaltered <strong class='adj'> adj. </strong> Not altered; remaining the same.</p>",
      },
    ],
  },
  {
    word: "Unambiguous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unambiguous <strong class='adj'> adj. </strong> Not ambiguous; clear or definite in meaning. unambiguously adv.</p>",
      },
    ],
  },
  {
    word: "Unambitious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unambitious <strong class='adj'> adj. </strong> Not ambitious.</p>",
      },
    ],
  },
  {
    word: "Un-american",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Un-american <strong class='adj'> adj. </strong> 1 uncharacteristic of americans. 2 contrary to us interests, treasonable.</p>",
      },
    ],
  },
  {
    word: "Unamused",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unamused <strong class='adj'> adj. </strong> Not amused.</p>",
      },
    ],
  },
  {
    word: "Unanimous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unanimous <strong class='adj'> adj. </strong> 1 all in agreement (committee was unanimous). 2 (of an opinion, vote, etc.) By all without exception (unanimous choice). unanimity <strong class='noun'> n. </strong> Unanimously <strong class='adv'> adv. </strong> [latin unus one, animus mind]</p>",
      },
    ],
  },
  {
    word: "Unannounced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unannounced <strong class='adj'> adj. </strong> Not announced; without warning (of arrival etc.).</p>",
      },
    ],
  },
  {
    word: "Unanswerable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unanswerable <strong class='adj'> adj. </strong> 1 irrefutable (unanswerable case). 2 unable to be answered (unanswerable question).</p>",
      },
    ],
  },
  {
    word: "Unanswered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unanswered <strong class='adj'> adj. </strong> Not answered.</p>",
      },
    ],
  },
  {
    word: "Unanticipated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unanticipated <strong class='adj'> adj. </strong> Not anticipated.</p>",
      },
    ],
  },
  {
    word: "Unappealing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unappealing <strong class='adj'> adj. </strong> Unattractive.</p>",
      },
    ],
  },
  {
    word: "Unappetizing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unappetizing <strong class='adj'> adj. </strong> Not appetizing.</p>",
      },
    ],
  },
  {
    word: "Unappreciated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unappreciated <strong class='adj'> adj. </strong> Not appreciated.</p>",
      },
    ],
  },
  {
    word: "Unappreciative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unappreciative <strong class='adj'> adj. </strong> Not appreciative.</p>",
      },
    ],
  },
  {
    word: "Unapproachable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unapproachable <strong class='adj'> adj. </strong> 1 inaccessible. 2 (of a person) unfriendly.</p>",
      },
    ],
  },
  {
    word: "Unarmed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unarmed <strong class='adj'> adj. </strong> Not armed; without weapons.</p>",
      },
    ],
  },
  {
    word: "Unashamed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unashamed <strong class='adj'> adj. </strong> 1 feeling no guilt. 2 blatant; bold. unashamedly adv.</p>",
      },
    ],
  },
  {
    word: "Unassailable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unassailable <strong class='adj'> adj. </strong> Unable to be attacked; impregnable.</p>",
      },
    ],
  },
  {
    word: "Unassuming",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unassuming <strong class='adj'> adj. </strong> Not pretentious; modest.</p>",
      },
    ],
  },
  {
    word: "Unattached",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unattached <strong class='adj'> adj. </strong> 1 not engaged, married, etc. 2 <strong class='adj'> (often foll. By to) </strong> not attached, esp. To a particular organization etc.</p>",
      },
    ],
  },
  {
    word: "Unattainable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unattainable <strong class='adj'> adj. </strong> Not attainable.</p>",
      },
    ],
  },
  {
    word: "Unattended",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unattended <strong class='adj'> adj. </strong> 1 (usu. Foll. By to) not attended. 2 (of a person, vehicle, etc.) Alone.</p>",
      },
    ],
  },
  {
    word: "Unattractive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unattractive <strong class='adj'> adj. </strong> Not attractive. unattractively adv.</p>",
      },
    ],
  },
  {
    word: "Unattributable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unattributable <strong class='adj'> adj. </strong> (esp. Of published information) not attributed to a source etc.</p>",
      },
    ],
  },
  {
    word: "Unauthorized",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unauthorized <strong class='adj'> adj. </strong> (also -ised) not authorized.</p>",
      },
    ],
  },
  {
    word: "Unavailable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unavailable <strong class='adj'> adj. </strong> Not available. unavailability n.</p>",
      },
    ],
  },
  {
    word: "Unavailing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unavailing <strong class='adj'> adj. </strong> Achieving nothing. unavailingly adv.</p>",
      },
    ],
  },
  {
    word: "Unavoidable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unavoidable <strong class='adj'> adj. </strong> Inevitable. unavoidably adv.</p>",
      },
    ],
  },
  {
    word: "Unaware",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unaware <strong class='adj'> —adj.</strong> 1 (usu. Foll. By of or that) not aware. 2 unperceptive. <strong class='adv'> —adv. </strong> = *unawares. unawareness n.</p>",
      },
    ],
  },
  {
    word: "Unawares",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unawares <strong class='adv'> adv. </strong> 1 unexpectedly. 2 inadvertently.</p>",
      },
    ],
  },
  {
    word: "Unbalanced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbalanced <strong class='adj'> adj. </strong> 1 emotionally unstable. 2 biased (unbalanced report).</p>",
      },
    ],
  },
  {
    word: "Unban",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unban <strong class='verb'> v. </strong> (-nn-) remove prohibited status from; allow.</p>",
      },
    ],
  },
  {
    word: "Unbar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbar <strong class='verb'> v. </strong> <strong class='adj'> (-rr-) </strong> 1 unlock, open. 2 remove a bar from (a gate etc.).</p>",
      },
    ],
  },
  {
    word: "Unbearable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbearable <strong class='adj'> adj. </strong> Unendurable. unbearably adv.</p>",
      },
    ],
  },
  {
    word: "Unbeatable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbeatable <strong class='adj'> adj. </strong> Not beatable; excelling.</p>",
      },
    ],
  },
  {
    word: "Unbeaten",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbeaten <strong class='adj'> adj. </strong> 1 not beaten. 2 (of a record etc.) Not surpassed.</p>",
      },
    ],
  },
  {
    word: "Unbecoming",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbecoming <strong class='adj'> adj. </strong> 1 unflattering (unbecoming hat). 2 (usu. Foll. By to, for) not fitting; indecorous. unbecomingly adv.</p>",
      },
    ],
  },
  {
    word: "Unbeknown",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbeknown <strong class='adj'> adj. </strong> (also unbeknownst) (foll. By to) without the knowledge of (unbeknown to us).</p>",
      },
    ],
  },
  {
    word: "Unbelief",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbelief <strong class='noun'> n. </strong> Lack of esp. Religious belief. unbeliever <strong class='noun'> n. </strong> Unbelieving adj.</p>",
      },
    ],
  },
  {
    word: "Unbelievable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbelievable <strong class='adj'> adj. </strong> Not believable; incredible. unbelievably adv.</p>",
      },
    ],
  },
  {
    word: "Unbend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbend <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Unbent) 1 straighten. 2 relax; become affable.</p>",
      },
    ],
  },
  {
    word: "Unbending",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbending <strong class='adj'> adj. </strong> 1 inflexible. 2 firm; austere.</p>",
      },
    ],
  },
  {
    word: "Unbiased",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbiased <strong class='adj'> adj. </strong> (also unbiassed) impartial.</p>",
      },
    ],
  },
  {
    word: "Unbidden",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbidden <strong class='adj'> adj. </strong> Not commanded or invited (arrived unbidden).</p>",
      },
    ],
  },
  {
    word: "Unbind",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbind <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Unbound) release; unfasten, untie.</p>",
      },
    ],
  },
  {
    word: "Unbleached",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbleached <strong class='adj'> adj. </strong> Not bleached.</p>",
      },
    ],
  },
  {
    word: "Unblemished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unblemished <strong class='adj'> adj. </strong> Not blemished.</p>",
      },
    ],
  },
  {
    word: "Unblinking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unblinking <strong class='adj'> adj. </strong> 1 not blinking. 2 steadfast; stolid.</p>",
      },
    ],
  },
  {
    word: "Unblock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unblock <strong class='verb'> v. </strong> Remove an obstruction from.</p>",
      },
    ],
  },
  {
    word: "Unblushing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unblushing <strong class='adj'> adj. </strong> 1 shameless. 2 frank.</p>",
      },
    ],
  },
  {
    word: "Unbolt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbolt <strong class='verb'> v. </strong> Release the bolt of (a door etc.).</p>",
      },
    ],
  },
  {
    word: "Unborn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unborn <strong class='adj'> adj. </strong> Not yet, or never to be, born (unborn child; unborn hopes).</p>",
      },
    ],
  },
  {
    word: "Unbosom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbosom <strong class='verb'> v. </strong> (often refl.) Disclose (thoughts etc.); unburden oneself.</p>",
      },
    ],
  },
  {
    word: "Unbothered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbothered <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> Not bothered; unconcerned.</p>",
      },
    ],
  },
  {
    word: "Unbound1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbound1 <strong class='adj'> adj. </strong> 1 not bound. 2 unconstrained. 3 a (of a book) without a binding. B having paper covers.</p>",
      },
    ],
  },
  {
    word: "Unbound2  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbound2  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>unbind.</p>",
      },
    ],
  },
  {
    word: "Unbounded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbounded <strong class='adj'> adj. </strong> Infinite (unbounded optimism).</p>",
      },
    ],
  },
  {
    word: "Unbreakable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbreakable <strong class='adj'> adj. </strong> Not breakable.</p>",
      },
    ],
  },
  {
    word: "Unbridgeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbridgeable <strong class='adj'> adj. </strong> Unable to be bridged.</p>",
      },
    ],
  },
  {
    word: "Unbridle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbridle <strong class='verb'> v. </strong> (-ling) remove a bridle, constraints, etc., from (a horse, one's tongue, etc.) (unbridled insolence).</p>",
      },
    ],
  },
  {
    word: "Unbroken",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbroken <strong class='adj'> adj. </strong> 1 not broken. 2 untamed (unbroken horse). 3 uninterrupted (unbroken sleep). 4 unsurpassed (unbroken record).</p>",
      },
    ],
  },
  {
    word: "Unbuckle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbuckle <strong class='verb'> v. </strong> (-ling) release the buckle of (a strap, shoe, etc.).</p>",
      },
    ],
  },
  {
    word: "Unburden",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unburden <strong class='verb'> v. </strong> (often refl.; often followed by to) relieve (oneself, one's conscience, etc.) By confession etc.</p>",
      },
    ],
  },
  {
    word: "Unbusinesslike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbusinesslike <strong class='adj'> adj. </strong> Not businesslike.</p>",
      },
    ],
  },
  {
    word: "Unbutton",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unbutton <strong class='verb'> v. </strong> 1 unfasten the buttons of (a garment, person, etc.). 2 (absol.) Colloq. Relax.</p>",
      },
    ],
  },
  {
    word: "Uncalled-for",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncalled-for <strong class='adj'> adj. </strong> (of a remark, action, etc.) Rude, unnecessary.</p>",
      },
    ],
  },
  {
    word: "Uncanny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncanny <strong class='adj'> adj. </strong> (-ier, -iest) seemingly supernatural; mysterious. uncannily <strong class='adv'> adv. </strong> Uncanniness n.</p>",
      },
    ],
  },
  {
    word: "Uncapped",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncapped <strong class='adj'> adj. </strong> Sport (of a player) not yet awarded his cap or never having been selected to represent his country.</p>",
      },
    ],
  },
  {
    word: "Uncared-for",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncared-for <strong class='adj'> adj. </strong> Disregarded; neglected.</p>",
      },
    ],
  },
  {
    word: "Uncaring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncaring <strong class='adj'> adj. </strong> Neglectful, lacking compassion.</p>",
      },
    ],
  },
  {
    word: "Unceasing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unceasing <strong class='adj'> adj. </strong> Not ceasing; continuous (unceasing effort).</p>",
      },
    ],
  },
  {
    word: "Uncensored",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncensored <strong class='adj'> adj. </strong> Not censored.</p>",
      },
    ],
  },
  {
    word: "Unceremonious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unceremonious <strong class='adj'> adj. </strong> 1 abrupt; discourteous. 2 informal. unceremoniously adv.</p>",
      },
    ],
  },
  {
    word: "Uncertain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncertain <strong class='adj'> adj. </strong> 1 not certainly knowing or known (result is uncertain). 2 unreliable. 3 changeable, erratic (uncertain weather). in no uncertain terms clearly and forcefully. uncertainly <strong class='adv'> adv. </strong> Uncertainty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>.</p>",
      },
    ],
  },
  {
    word: "Unchain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchain <strong class='verb'> v. </strong> Remove the chain(s) from; release.</p>",
      },
    ],
  },
  {
    word: "Unchallengeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchallengeable <strong class='adj'> adj. </strong> Not challengeable; unassailable.</p>",
      },
    ],
  },
  {
    word: "Unchallenged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchallenged <strong class='adj'> adj. </strong> Not challenged.</p>",
      },
    ],
  },
  {
    word: "Unchangeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchangeable <strong class='adj'> adj. </strong> Unable to be changed.</p>",
      },
    ],
  },
  {
    word: "Unchanged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchanged <strong class='adj'> adj. </strong> Not changed; unaltered.</p>",
      },
    ],
  },
  {
    word: "Unchanging",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchanging <strong class='adj'> adj. </strong> Not changing; remaining the same.</p>",
      },
    ],
  },
  {
    word: "Unchaperoned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchaperoned <strong class='adj'> adj. </strong> Without a chaperone.</p>",
      },
    ],
  },
  {
    word: "Uncharacteristic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncharacteristic <strong class='adj'> adj. </strong> Not characteristic. uncharacteristically adv.</p>",
      },
    ],
  },
  {
    word: "Uncharitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncharitable <strong class='adj'> adj. </strong> Censorious, severe in judgement. uncharitably adv.</p>",
      },
    ],
  },
  {
    word: "Uncharted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncharted <strong class='adj'> adj. </strong> Not mapped or surveyed.</p>",
      },
    ],
  },
  {
    word: "Unchecked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchecked <strong class='adj'> adj. </strong> 1 not checked. 2 unrestrained (unchecked violence).</p>",
      },
    ],
  },
  {
    word: "Unchivalrous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchivalrous <strong class='adj'> adj. </strong> Not chivalrous. unchivalrously adv.</p>",
      },
    ],
  },
  {
    word: "Unchristian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unchristian <strong class='adj'> adj. </strong> Contrary to christian principles, esp. Uncaring or selfish.</p>",
      },
    ],
  },
  {
    word: "Uncial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncial <strong class='adj'> —adj.</strong> Of or written in rounded unjoined letters similar to capitals, found in manuscripts of the 4th–8th c. <strong class='noun'> —n. </strong> Uncial letter, style, or ms. [latin uncia inch]</p>",
      },
    ],
  },
  {
    word: "Uncircumcised",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncircumcised <strong class='adj'> adj. </strong> Not circumcised.</p>",
      },
    ],
  },
  {
    word: "Uncivil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncivil <strong class='adj'> adj. </strong> Ill-mannered; impolite. uncivilly adv.</p>",
      },
    ],
  },
  {
    word: "Uncivilized",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncivilized <strong class='adj'> adj. </strong> (also -ised) 1 not civilized. 2 rough; uncultured.</p>",
      },
    ],
  },
  {
    word: "Unclaimed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unclaimed <strong class='adj'> adj. </strong> Not claimed.</p>",
      },
    ],
  },
  {
    word: "Unclasp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unclasp <strong class='verb'> v. </strong> 1 loosen the clasp(s) of. 2 release the grip of (a hand etc.).</p>",
      },
    ],
  },
  {
    word: "Unclassified",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unclassified <strong class='adj'> adj. </strong> 1 not classified. 2 (of state information) not secret.</p>",
      },
    ],
  },
  {
    word: "Uncle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncle <strong class='noun'> n. </strong> 1 a brother of one's father or mother. B aunt's husband. 2 <strong class='colloq'> colloq. </strong> (form of address by a child to) parent's male friend. 3 slang esp. Hist. Pawnbroker. [latin avunculus]</p>",
      },
    ],
  },
  {
    word: "-uncle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-uncle <strong class='slang_v'> suffix </strong>forming nouns, usu. Diminutives (carbuncle). [latin -unculus]</p>",
      },
    ],
  },
  {
    word: "Unclean",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unclean <strong class='adj'> adj. </strong> 1 not clean. 2 unchaste. 3 religiously impure; forbidden.</p>",
      },
    ],
  },
  {
    word: "Unclear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unclear <strong class='adj'> adj. </strong> 1 not clear or easy to understand. 2 (of a person) uncertain (i'm unclear as to what you mean).</p>",
      },
    ],
  },
  {
    word: "Unclench",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unclench <strong class='verb'> v. </strong> 1 release (clenched hands etc.). 2 (of hands etc.) Become relaxed or open.</p>",
      },
    ],
  },
  {
    word: "Uncle sam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncle sam <strong class='noun'> n. </strong> Colloq. Us government.</p>",
      },
    ],
  },
  {
    word: "Unclothe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unclothe <strong class='verb'> v. </strong> (-thing) 1 remove clothes, leaves, etc. From. 2 expose, reveal.</p>",
      },
    ],
  },
  {
    word: "Unclouded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unclouded <strong class='adj'> adj. </strong> 1 clear; bright. 2 untroubled (unclouded serenity).</p>",
      },
    ],
  },
  {
    word: "Uncluttered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncluttered <strong class='adj'> adj. </strong> Not cluttered; austere, simple.</p>",
      },
    ],
  },
  {
    word: "Uncoil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Uncoil <strong class='verb'> v. </strong> Unwind.</p>",
      },
    ],
  },
  {
    word: "Uncoloured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncoloured <strong class='adj'> adj. </strong> 1 having no colour. 2 not influenced; impartial.</p>",
      },
    ],
  },
  {
    word: "Uncombed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncombed <strong class='adj'> adj. </strong> (of hair or a person) not combed.</p>",
      },
    ],
  },
  {
    word: "Uncomfortable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncomfortable <strong class='adj'> adj. </strong> 1 not comfortable. 2 uneasy; disquieting (uncomfortable silence). uncomfortably adv.</p>",
      },
    ],
  },
  {
    word: "Uncommercial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncommercial <strong class='adj'> adj. </strong> Not commercial.</p>",
      },
    ],
  },
  {
    word: "Uncommitted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncommitted <strong class='adj'> adj. </strong> 1 not committed. 2 not politically attached.</p>",
      },
    ],
  },
  {
    word: "Uncommon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncommon <strong class='adj'> adj. </strong> 1 unusual. 2 remarkably great etc. (uncommon appetite). uncommonly <strong class='adv'> adv. </strong> Uncommonness n.</p>",
      },
    ],
  },
  {
    word: "Uncommunicative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncommunicative <strong class='adj'> adj. </strong> Taciturn.</p>",
      },
    ],
  },
  {
    word: "Uncompetitive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncompetitive <strong class='adj'> adj. </strong> Not competitive.</p>",
      },
    ],
  },
  {
    word: "Uncomplaining",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncomplaining <strong class='adj'> adj. </strong> Not complaining; resigned. uncomplainingly adv.</p>",
      },
    ],
  },
  {
    word: "Uncompleted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncompleted <strong class='adj'> adj. </strong> Not completed; incomplete.</p>",
      },
    ],
  },
  {
    word: "Uncomplicated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncomplicated <strong class='adj'> adj. </strong> Simple; straightforward.</p>",
      },
    ],
  },
  {
    word: "Uncomplimentary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncomplimentary <strong class='adj'> adj. </strong> Insulting.</p>",
      },
    ],
  },
  {
    word: "Uncomprehending",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncomprehending <strong class='adj'> adj. </strong> Not comprehending.</p>",
      },
    ],
  },
  {
    word: "Uncompromising",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncompromising <strong class='adj'> adj. </strong> Stubborn; unyielding. uncompromisingly adv.</p>",
      },
    ],
  },
  {
    word: "Unconcealed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconcealed <strong class='adj'> adj. </strong> Not concealed; obvious.</p>",
      },
    ],
  },
  {
    word: "Unconcern",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconcern <strong class='noun'> n. </strong> Calmness; indifference; apathy. unconcerned adj. Unconcernedly adv.</p>",
      },
    ],
  },
  {
    word: "Unconditional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconditional <strong class='adj'> adj. </strong> Not subject to conditions; complete (unconditional surrender). unconditionally adv.</p>",
      },
    ],
  },
  {
    word: "Unconditioned reflex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconditioned reflex <strong class='noun'> n. </strong> Instinctive response to a stimulus.</p>",
      },
    ],
  },
  {
    word: "Unconfined",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconfined <strong class='adj'> adj. </strong> Not confined; boundless.</p>",
      },
    ],
  },
  {
    word: "Unconfirmed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconfirmed <strong class='adj'> adj. </strong> Not confirmed.</p>",
      },
    ],
  },
  {
    word: "Uncongenial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncongenial <strong class='adj'> adj. </strong> Not congenial.</p>",
      },
    ],
  },
  {
    word: "Unconnected",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconnected <strong class='adj'> adj. </strong> 1 not physically joined. 2 not connected or associated. 3 disconnected (unconnected ideas).</p>",
      },
    ],
  },
  {
    word: "Unconquerable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconquerable <strong class='adj'> adj. </strong> Not conquerable.</p>",
      },
    ],
  },
  {
    word: "Unconscionable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconscionable <strong class='adj'> adj. </strong> 1 without or contrary to conscience. 2 excessive (unconscionable waste). unconscionably <strong class='adv'> adv. </strong> [from *un-1, *conscience]</p>",
      },
    ],
  },
  {
    word: "Unconscious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconscious <strong class='adj'> —adj.</strong> Not conscious (fell unconscious; unconscious prejudice). <strong class='noun'> —n. </strong> Normally inaccessible part of the mind affecting the emotions etc. unconsciously <strong class='adv'> adv. </strong> Unconsciousness n.</p>",
      },
    ],
  },
  {
    word: "Unconsidered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconsidered <strong class='adj'> adj. </strong> 1 not considered; disregarded. 2 not premeditated.</p>",
      },
    ],
  },
  {
    word: "Unconstitutional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconstitutional <strong class='adj'> adj. </strong> In breach of a political constitution or procedural rules. unconstitutionally adv.</p>",
      },
    ],
  },
  {
    word: "Unconstrained",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconstrained <strong class='adj'> adj. </strong> Not constrained or compelled.</p>",
      },
    ],
  },
  {
    word: "Uncontaminated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncontaminated <strong class='adj'> adj. </strong> Not contaminated.</p>",
      },
    ],
  },
  {
    word: "Uncontested",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncontested <strong class='adj'> adj. </strong> Not contested.</p>",
      },
    ],
  },
  {
    word: "Uncontrollable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncontrollable <strong class='adj'> adj. </strong> Not controllable. uncontrollably adv.</p>",
      },
    ],
  },
  {
    word: "Uncontrolled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncontrolled <strong class='adj'> adj. </strong> Not controlled; unrestrained.</p>",
      },
    ],
  },
  {
    word: "Uncontroversial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncontroversial <strong class='adj'> adj. </strong> Not controversial.</p>",
      },
    ],
  },
  {
    word: "Unconventional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconventional <strong class='adj'> adj. </strong> Unusual; unorthodox. unconventionality <strong class='noun'> n. </strong> Unconventionally adv.</p>",
      },
    ],
  },
  {
    word: "Unconvinced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconvinced <strong class='adj'> adj. </strong> Not convinced.</p>",
      },
    ],
  },
  {
    word: "Unconvincing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unconvincing <strong class='adj'> adj. </strong> Not convincing. unconvincingly adv.</p>",
      },
    ],
  },
  {
    word: "Uncooked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncooked <strong class='adj'> adj. </strong> Not cooked; raw.</p>",
      },
    ],
  },
  {
    word: "Uncooperative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncooperative <strong class='adj'> adj. </strong> Not cooperative.</p>",
      },
    ],
  },
  {
    word: "Uncoordinated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncoordinated <strong class='adj'> adj. </strong> 1 not coordinated. 2 clumsy.</p>",
      },
    ],
  },
  {
    word: "Uncork",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncork <strong class='verb'> v. </strong> 1 draw the cork from (a bottle). 2 vent (feelings etc.).</p>",
      },
    ],
  },
  {
    word: "Uncorroborated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncorroborated <strong class='adj'> adj. </strong> (esp. Of evidence etc.) Not corroborated.</p>",
      },
    ],
  },
  {
    word: "Uncountable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncountable <strong class='adj'> adj. </strong> 1 inestimable, immense (uncountable wealth). 2 (of a noun) not used in the plural or with the indefinite article (e.g. Happiness, milk).</p>",
      },
    ],
  },
  {
    word: "Uncouple",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncouple <strong class='verb'> v. </strong> (-ling) release from couplings or couples.</p>",
      },
    ],
  },
  {
    word: "Uncouth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncouth <strong class='adj'> adj. </strong> Uncultured, rough. [old english, = unknown]</p>",
      },
    ],
  },
  {
    word: "Uncover",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncover <strong class='verb'> v. </strong> 1 remove a cover or covering from. 2 disclose (uncovered the truth).</p>",
      },
    ],
  },
  {
    word: "Uncritical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncritical <strong class='adj'> adj. </strong> 1 not critical; complacently accepting. 2 not in accordance with the principles of criticism. uncritically adv.</p>",
      },
    ],
  },
  {
    word: "Uncross",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncross <strong class='verb'> v. </strong> 1 remove from a crossed position. 2 (as uncrossed adj.) (of a cheque) not crossed.</p>",
      },
    ],
  },
  {
    word: "Uncrown",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncrown <strong class='verb'> v. </strong> 1 deprive of a crown, a position, etc. 2 (as uncrowned adj.) A not crowned. B having the status but not the name of (uncrowned king of boxing).</p>",
      },
    ],
  },
  {
    word: "Unction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unction <strong class='noun'> n. </strong> 1 a anointing with oil etc. As a religious rite or medical treatment. B oil, ointment, etc. So used. 2 a soothing words or thought. B excessive or insincere flattery. 3 a emotional fervency. B pretence of this. [latin ungo unct- anoint]</p>",
      },
    ],
  },
  {
    word: "Unctuous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unctuous <strong class='adj'> adj. </strong> 1 unpleasantly flattering; oily. 2 greasy or soapy. unctuously <strong class='adv'> adv. </strong> [medieval latin: related to *unction]</p>",
      },
    ],
  },
  {
    word: "Uncultivated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncultivated <strong class='adj'> adj. </strong> Not cultivated.</p>",
      },
    ],
  },
  {
    word: "Uncured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncured <strong class='adj'> adj. </strong> 1 not cured. 2 (of pork etc.) Not salted or smoked.</p>",
      },
    ],
  },
  {
    word: "Uncurl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncurl <strong class='verb'> v. </strong> Straighten out, untwist.</p>",
      },
    ],
  },
  {
    word: "Uncut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uncut <strong class='adj'> adj. </strong> 1 not cut. 2 (of a book) with the pages sealed or untrimmed. 3 (of a book, film, etc.) Complete; uncensored. 4 (of esp. A diamond) not shaped. 5 (of fabric) with a looped pile.</p>",
      },
    ],
  },
  {
    word: "Undamaged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Undamaged <strong class='adj'> adj. </strong> Intact.</p>",
      },
    ],
  },
  {
    word: "Undated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undated <strong class='adj'> adj. </strong> Without a date.</p>",
      },
    ],
  },
  {
    word: "Undaunted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undaunted <strong class='adj'> adj. </strong> Not daunted.</p>",
      },
    ],
  },
  {
    word: "Undeceive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undeceive <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> (often foll. By of) free (a person) from a misconception, deception, or error.</p>",
      },
    ],
  },
  {
    word: "Undecided",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undecided <strong class='adj'> adj. </strong> 1 not settled. 2 irresolute.</p>",
      },
    ],
  },
  {
    word: "Undeclared",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undeclared <strong class='adj'> adj. </strong> Not declared.</p>",
      },
    ],
  },
  {
    word: "Undefeated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undefeated <strong class='adj'> adj. </strong> Not defeated.</p>",
      },
    ],
  },
  {
    word: "Undefended",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undefended <strong class='adj'> adj. </strong> Not defended.</p>",
      },
    ],
  },
  {
    word: "Undefined",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undefined <strong class='adj'> adj. </strong> Not defined; vague, indefinite.</p>",
      },
    ],
  },
  {
    word: "Undemanding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undemanding <strong class='adj'> adj. </strong> Not demanding; easily done or satisfied (undemanding reading).</p>",
      },
    ],
  },
  {
    word: "Undemocratic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undemocratic <strong class='adj'> adj. </strong> Not democratic.</p>",
      },
    ],
  },
  {
    word: "Undemonstrative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undemonstrative <strong class='adj'> adj. </strong> Not emotionally expressive; reserved.</p>",
      },
    ],
  },
  {
    word: "Undeniable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undeniable <strong class='adj'> adj. </strong> Indisputable; certain. undeniably adv.</p>",
      },
    ],
  },
  {
    word: "Under",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Under <strong class='prep'> —prep. </strong> 1 a in or to a position lower than; below; beneath (under the table). B on the inside of (vest under his shirt). 2 inferior to; less than (no-one under a major; is under 18; was under £20). 3 a subject to; controlled by (under constraint; born under saturn; prospered under him). B undergoing (is under repair). C classified or subsumed in (under two headings). 4 at the foot of or sheltered by (under the cliff). 5 planted with (a crop). 6 powered by (sail, steam, etc.). <strong class='adv'> —adv. </strong> 1 in or to a lower position or condition (kept him under). 2 <strong class='colloq'> colloq. </strong> In or into unconsciousness (put him under). —adj.</p>",
      },
    ],
  },
  {
    word: "Under-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Under- <strong class='prefix '> prefix </strong> in senses of *under: 1 below, beneath (underground). 2 lower; subordinate (under-secretary). 3 insufficiently, incompletely (undercook; underdeveloped).</p>",
      },
    ],
  },
  {
    word: "Underachieve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underachieve <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> do less well than might be expected (esp. Academically). underachiever n.</p>",
      },
    ],
  },
  {
    word: "Underact",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underact <strong class='verb'> v. </strong> Theatr. Act with insufficient force.</p>",
      },
    ],
  },
  {
    word: "Under-age",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Under-age <strong class='adj'> adj. </strong> (also under age) not old enough.</p>",
      },
    ],
  },
  {
    word: "Underarm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underarm <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> Sport, esp. Cricket with the arm below shoulder-level. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> 1 under the arm (underarm seam). 2 in the armpit.</p>",
      },
    ],
  },
  {
    word: "Underbelly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underbelly <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> undersurface of an animal, vehicle, etc., esp. As vulnerable to attack.</p>",
      },
    ],
  },
  {
    word: "Underbid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underbid <strong class='noun'> —v. </strong> (-dd-; past and  <strong class='archaic_it_was'>past part.</strong> -bid) 1 make a lower bid than. 2 (also absol.) Bridge etc. Bid less on (one's hand) than warranted. <strong class='noun'> —n. </strong> Such a bid.</p>",
      },
    ],
  },
  {
    word: "Undercarriage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undercarriage <strong class='noun'> n. </strong> 1 wheeled retractable structure beneath an aircraft, used for landing etc. 2 supporting frame of a vehicle.</p>",
      },
    ],
  },
  {
    word: "Undercharge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undercharge <strong class='verb'> v. </strong> (-ging) 1 charge too little to (a person). 2 give too little charge to (a gun, electric battery, etc.).</p>",
      },
    ],
  },
  {
    word: "Underclothes",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underclothes <strong class='n_pl'> n.pl. </strong> Clothes worn under others, esp. Next to the skin.</p>",
      },
    ],
  },
  {
    word: "Underclothing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underclothing <strong class='noun'> n. </strong> Underclothes collectively.</p>",
      },
    ],
  },
  {
    word: "Undercoat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undercoat <strong class='noun'> n. </strong> 1 a layer of paint under a topcoat. B paint for this. 2 animal's under layer of hair etc.</p>",
      },
    ],
  },
  {
    word: "Undercook",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undercook <strong class='verb'> v. </strong> Cook insufficiently.</p>",
      },
    ],
  },
  {
    word: "Undercover",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undercover <strong class='adj'> adj. </strong> (usu. Attrib.) 1 surreptitious. 2 spying incognito, esp. By infiltration (undercover agent).</p>",
      },
    ],
  },
  {
    word: "Undercroft",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undercroft <strong class='noun'> n. </strong> Crypt. [obsolete croft from latin]</p>",
      },
    ],
  },
  {
    word: "Undercurrent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undercurrent <strong class='noun'> n. </strong> 1 current below the surface. 2 underlying often contrary feeling, influence, etc. (undercurrent of protest).</p>",
      },
    ],
  },
  {
    word: "Undercut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undercut <strong class='noun'> —v. </strong> (-tt-; past and  <strong class='archaic_it_was'>past part.</strong> -cut) 1 sell or work at a lower price than. 2 strike (a ball) to make it rise high. 3 cut away the part below. 4 undermine. <strong class='noun'> —n. </strong> Underside of sirloin.</p>",
      },
    ],
  },
  {
    word: "Underdeveloped",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underdeveloped <strong class='adj'> adj. </strong> 1 not fully developed; immature. 2 (of a country etc.) With unexploited potential. underdevelopment n.</p>",
      },
    ],
  },
  {
    word: "Underdog",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underdog <strong class='noun'> n. </strong> 1 oppressed person. 2 loser in a fight.</p>",
      },
    ],
  },
  {
    word: "Underdone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underdone <strong class='adj'> adj. </strong> Undercooked.</p>",
      },
    ],
  },
  {
    word: "Underemployed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underemployed <strong class='adj'> adj. </strong> Not fully occupied. underemployment n.</p>",
      },
    ],
  },
  {
    word: "Underestimate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underestimate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> form too low an estimate of. <strong class='noun'> —n. </strong> Estimate that is too low. underestimation n.</p>",
      },
    ],
  },
  {
    word: "Underexpose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underexpose <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> expose (film) for too short a time etc. underexposure n.</p>",
      },
    ],
  },
  {
    word: "Underfed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underfed <strong class='adj'> adj. </strong> Malnourished.</p>",
      },
    ],
  },
  {
    word: "Underfelt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underfelt <strong class='noun'> n. </strong> Felt laid under a carpet.</p>",
      },
    ],
  },
  {
    word: "Underfloor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underfloor <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Beneath the floor (underfloor heating).</p>",
      },
    ],
  },
  {
    word: "Underfoot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underfoot <strong class='adv'> adv. </strong> (also under foot) 1 under one's feet. 2 on the ground.</p>",
      },
    ],
  },
  {
    word: "Underfunded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underfunded <strong class='adj'> adj. </strong> Provided with insufficient money.</p>",
      },
    ],
  },
  {
    word: "Undergarment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undergarment <strong class='noun'> n. </strong> Piece of underclothing.</p>",
      },
    ],
  },
  {
    word: "Undergo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undergo <strong class='verb'> v. </strong> (3rd sing. Present -goes; past -went;  <strong class='archaic_it_was'>past part.</strong> -gone) be subjected to; suffer; endure.</p>",
      },
    ],
  },
  {
    word: "Undergraduate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undergraduate <strong class='noun'> n. </strong> Person studying for a first degree.</p>",
      },
    ],
  },
  {
    word: "Underground",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underground <strong class='adv'> —adv. </strong> 1 beneath the ground. 2 in or into secrecy or hiding. <strong class='adj'> —adj.</strong> 1 situated underground. 2 secret, subversive. 3 unconventional (underground literature). <strong class='noun'> —n. </strong> 1 underground railway. 2 secret subversive group or activity.</p>",
      },
    ],
  },
  {
    word: "Undergrowth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undergrowth <strong class='noun'> n. </strong> Dense shrubs etc., esp. In a wood.</p>",
      },
    ],
  },
  {
    word: "Underhand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underhand <strong class='adj'> adj. </strong> 1 deceitful; crafty; secret. 2 sport, esp. Cricket underarm.</p>",
      },
    ],
  },
  {
    word: "Underlay1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underlay1 <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> -laid) lay something under (a thing) to support or raise it. <strong class='noun'> —n. </strong> Thing so laid (esp. Under a carpet).</p>",
      },
    ],
  },
  {
    word: "Underlay2  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underlay2  <strong class='slang_v'>past of *</strong>underlie.</p>",
      },
    ],
  },
  {
    word: "Underlie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underlie <strong class='verb'> v. </strong> (-lying; past -lay;  <strong class='archaic_it_was'>past part.</strong> -lain) 1 (also absol.) Lie under (a stratum etc.). 2 (also absol.) (esp. As underlying adj.) Be the basis of (a doctrine, conduct, etc.). 3 exist beneath the superficial aspect of.</p>",
      },
    ],
  },
  {
    word: "Underline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underline <strong class='verb'> v. </strong> (-ning) 1 draw a line under (a word etc.) To give emphasis, indicate italic type, etc. 2 emphasize, stress.</p>",
      },
    ],
  },
  {
    word: "Underling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underling <strong class='noun'> n. </strong> Usu. Derog. Subordinate.</p>",
      },
    ],
  },
  {
    word: "Underlying  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underlying  <strong class='see'>pres. Part. Of *</strong>underlie.</p>",
      },
    ],
  },
  {
    word: "Undermanned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undermanned <strong class='adj'> adj. </strong> Having an insufficient crew or staff.</p>",
      },
    ],
  },
  {
    word: "Undermentioned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undermentioned <strong class='adj'> adj. </strong> Mentioned later in a book etc.</p>",
      },
    ],
  },
  {
    word: "Undermine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undermine <strong class='verb'> v. </strong> (-ning) 1 injure (a person, reputation, health, etc.) Secretly or insidiously. 2 wear away the base of (banks were undermined). 3 make an excavation under.</p>",
      },
    ],
  },
  {
    word: "Underneath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underneath <strong class='prep'> —prep. </strong> 1 at or to a lower place than, below. 2 on the inside of. <strong class='adv'> —adv. </strong> 1 at or to a lower place. 2 inside. <strong class='noun'> —n. </strong> Lower surface or part. <strong class='adj'> —adj.</strong> Lower. [old english: related to *nether]</p>",
      },
    ],
  },
  {
    word: "Undernourished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undernourished <strong class='adj'> adj. </strong> Insufficiently nourished. undernourishment n.</p>",
      },
    ],
  },
  {
    word: "Underpaid  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underpaid  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>underpay.</p>",
      },
    ],
  },
  {
    word: "Underpants",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underpants <strong class='n_pl'> n.pl. </strong> Undergarment, esp. Men's, covering the genitals and buttocks.</p>",
      },
    ],
  },
  {
    word: "Underpart",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underpart <strong class='noun'> n. </strong> Lower or subordinate part.</p>",
      },
    ],
  },
  {
    word: "Underpass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underpass <strong class='noun'> n. </strong> 1 road etc. Passing under another. 2 subway.</p>",
      },
    ],
  },
  {
    word: "Underpay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underpay <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> -paid) pay too little to (a person) or for (a thing). underpayment n.</p>",
      },
    ],
  },
  {
    word: "Underpin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underpin <strong class='verb'> v. </strong> (-nn-) 1 support from below with masonry etc. 2 support, strengthen.</p>",
      },
    ],
  },
  {
    word: "Underplay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underplay <strong class='verb'> v. </strong> 1 make little of. 2 theatr. Underact.</p>",
      },
    ],
  },
  {
    word: "Underpopulated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underpopulated <strong class='adj'> adj. </strong> Having an insufficient or very small population.</p>",
      },
    ],
  },
  {
    word: "Underprice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underprice <strong class='verb'> v. </strong> (-cing) price lower than what is usual or appropriate.</p>",
      },
    ],
  },
  {
    word: "Underprivileged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underprivileged <strong class='adj'> adj. </strong> Less privileged than others; having below average income, rights, etc.</p>",
      },
    ],
  },
  {
    word: "Underrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underrate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> have too low an opinion of.</p>",
      },
    ],
  },
  {
    word: "Underscore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underscore <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> = *underline.</p>",
      },
    ],
  },
  {
    word: "Undersea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undersea <strong class='adj'> adj. </strong> Below the sea or its surface.</p>",
      },
    ],
  },
  {
    word: "Underseal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underseal <strong class='noun'> —v. </strong> Seal the underpart of (esp. A vehicle against rust etc.). <strong class='noun'> —n. </strong> Protective coating for this.</p>",
      },
    ],
  },
  {
    word: "Under-secretary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Under-secretary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> subordinate official, esp. A junior minister or senior civil servant.</p>",
      },
    ],
  },
  {
    word: "Undersell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undersell <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> -sold) sell at a lower price than (another seller).</p>",
      },
    ],
  },
  {
    word: "Undersexed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undersexed <strong class='adj'> adj. </strong> Having unusually weak sexual desires.</p>",
      },
    ],
  },
  {
    word: "Undershirt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undershirt <strong class='noun'> n. </strong> Esp. Us man's or boy's vest.</p>",
      },
    ],
  },
  {
    word: "Undershoot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undershoot <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> -shot) land short of (a runway etc.).</p>",
      },
    ],
  },
  {
    word: "Undershot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undershot <strong class='adj'> adj. </strong> 1 (of a water-wheel) turned by water flowing under it. 2 (of a lower jaw) projecting beyond the upper jaw.</p>",
      },
    ],
  },
  {
    word: "Underside",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underside <strong class='noun'> n. </strong> Lower or under side or surface.</p>",
      },
    ],
  },
  {
    word: "Undersigned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undersigned <strong class='adj'> adj. </strong> (usu. Absol.) Whose signature is appended (we, the undersigned).</p>",
      },
    ],
  },
  {
    word: "Undersized",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undersized <strong class='adj'> adj. </strong> Smaller than average.</p>",
      },
    ],
  },
  {
    word: "Underskirt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underskirt <strong class='noun'> n. </strong> Petticoat.</p>",
      },
    ],
  },
  {
    word: "Underslung",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underslung <strong class='adj'> adj. </strong> Supported from above.</p>",
      },
    ],
  },
  {
    word: "Undersold  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undersold  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>undersell.</p>",
      },
    ],
  },
  {
    word: "Underspend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underspend <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> -spent) (usu. Absol.) Spend less than (the expected amount), or too little.</p>",
      },
    ],
  },
  {
    word: "Understaffed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Understaffed <strong class='adj'> adj. </strong> Having too few staff.</p>",
      },
    ],
  },
  {
    word: "Understand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Understand <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> -stood) 1 perceive the meaning of (words, a person, a language, a subject, etc.) (understood you perfectly; cannot understand algebra). 2 perceive the significance or cause of (do not understand why he came). 3 (often absol.) Sympathize with, know how to deal with (quite understand your difficulty; ask her, she understands). 4 (often foll. By that or absol.) Infer, take as implied (am i to understand that you refuse?; he is old, i understand).</p>",
      },
    ],
  },
  {
    word: "Understanding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Understanding <strong class='noun'> —n. </strong> 1 ability to understand or think; intelligence. 2 individual's perception of a situation etc. 3 agreement, esp. Informal (had an understanding). 4 sympathy; tolerance. <strong class='adj'> —adj.</strong> 1 having understanding or insight. 2 sympathetic. understandingly adv.</p>",
      },
    ],
  },
  {
    word: "Understate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Understate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 express mildly or in a restrained way. 2 represent as less than it actually is. understatement n.</p>",
      },
    ],
  },
  {
    word: "Understeer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Understeer <strong class='noun'> n. </strong> Tendency of a vehicle not to turn sharply enough.</p>",
      },
    ],
  },
  {
    word: "Understood  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Understood  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>understand.</p>",
      },
    ],
  },
  {
    word: "Understudy  esp. Theatr.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Understudy esp. Theatr. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> person ready to take on another's role etc. When required. —v. (-ies, -ied) 1 study (a role etc.) Thus. 2 act as an understudy to.</p>",
      },
    ],
  },
  {
    word: "Undersubscribed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undersubscribed <strong class='adj'> adj. </strong> Without sufficient subscribers, participants, etc.</p>",
      },
    ],
  },
  {
    word: "Undersurface",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undersurface <strong class='noun'> n. </strong> Lower or under surface.</p>",
      },
    ],
  },
  {
    word: "Undertake",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undertake <strong class='verb'> v. </strong> (-king; past -took;  <strong class='archaic_it_was'>past part.</strong> -taken) 1 agree to perform or be responsible for; engage in, enter upon (work, a responsibility, etc.). 2 (usu. Foll. By to + infin.) Promise. 3 guarantee (undertake that he is innocent).</p>",
      },
    ],
  },
  {
    word: "Undertaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undertaker <strong class='noun'> n. </strong> Professional funeral organizer.</p>",
      },
    ],
  },
  {
    word: "Undertaking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undertaking <strong class='noun'> n. </strong> 1 work etc. Undertaken, enterprise (serious undertaking). 2 promise. 3 professional funeral management.</p>",
      },
    ],
  },
  {
    word: "Underthings",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underthings <strong class='n_pl'> n.pl. </strong> Underclothes.</p>",
      },
    ],
  },
  {
    word: "Undertone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undertone <strong class='noun'> n. </strong> 1 subdued tone or colour. 2 underlying quality or feeling.</p>",
      },
    ],
  },
  {
    word: "Undertook  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undertook  <strong class='slang_v'>past of *</strong> undertake.</p>",
      },
    ],
  },
  {
    word: "Undertow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undertow <strong class='noun'> n. </strong> Current below the surface of the sea contrary to the surface current.</p>",
      },
    ],
  },
  {
    word: "Underused",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underused <strong class='adj'> adj. </strong> Not used to capacity.</p>",
      },
    ],
  },
  {
    word: "Undervalue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undervalue <strong class='verb'> v. </strong> (-ues, -ued, -uing) 1 value insufficiently. 2 underestimate.</p>",
      },
    ],
  },
  {
    word: "Undervest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Undervest <strong class='noun'> n. </strong> Vest.</p>",
      },
    ],
  },
  {
    word: "Underwater",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underwater <strong class='adj'> —adj.</strong> Situated or done under water. <strong class='adv'> —adv. </strong> Under water.</p>",
      },
    ],
  },
  {
    word: "Underwear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underwear <strong class='noun'> n. </strong> Underclothes.</p>",
      },
    ],
  },
  {
    word: "Underweight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underweight <strong class='adj'> —adj.</strong> Below normal weight. <strong class='noun'> —n. </strong> Insufficient weight.</p>",
      },
    ],
  },
  {
    word: "Underwent  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underwent  <strong class='slang_v'>past of *</strong> undergo.</p>",
      },
    ],
  },
  {
    word: "Underwhelm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underwhelm <strong class='verb'> v. </strong> Joc. Fail to impress. [alteration of *overwhelm]</p>",
      },
    ],
  },
  {
    word: "Underworld",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underworld <strong class='noun'> n. </strong> 1 those who live by organized crime and vice. 2 mythical abode of the dead under the earth.</p>",
      },
    ],
  },
  {
    word: "Underwrite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Underwrite <strong class='verb'> v. </strong> (-ting; past -wrote;  <strong class='archaic_it_was'>past part.</strong> -written) 1 a sign and accept liability under (an insurance policy, esp. On shipping etc.). B accept (liability) in this way. 2 undertake to finance or support. 3 engage to buy all the unsold stock in (a company etc.). underwriter n.</p>",
      },
    ],
  },
  {
    word: "Undescended",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undescended <strong class='adj'> adj. </strong> (of a testicle) not descending normally into the scrotum.</p>",
      },
    ],
  },
  {
    word: "Undeserved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undeserved <strong class='adj'> adj. </strong> Not deserved. undeservedly adv.</p>",
      },
    ],
  },
  {
    word: "Undeserving",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undeserving <strong class='adj'> adj. </strong> Not deserving.</p>",
      },
    ],
  },
  {
    word: "Undesigned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undesigned <strong class='adj'> adj. </strong> Unintentional.</p>",
      },
    ],
  },
  {
    word: "Undesirable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undesirable <strong class='adj'> —adj.</strong> Objectionable, unpleasant. <strong class='noun'> —n. </strong> Undesirable person. undesirability n.</p>",
      },
    ],
  },
  {
    word: "Undetectable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undetectable <strong class='adj'> adj. </strong> Not detectable.</p>",
      },
    ],
  },
  {
    word: "Undetected",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undetected <strong class='adj'> adj. </strong> Not detected.</p>",
      },
    ],
  },
  {
    word: "Undetermined",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undetermined <strong class='adj'> adj. </strong> = *undecided.</p>",
      },
    ],
  },
  {
    word: "Undeterred",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undeterred <strong class='adj'> adj. </strong> Not deterred.</p>",
      },
    ],
  },
  {
    word: "Undeveloped",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undeveloped <strong class='adj'> adj. </strong> Not developed.</p>",
      },
    ],
  },
  {
    word: "Undid  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undid  <strong class='slang_v'>past of *</strong> undo.</p>",
      },
    ],
  },
  {
    word: "Undies",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undies <strong class='n_pl'> n.pl. </strong> Colloq. (esp. Women's) underclothes. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Undifferentiated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undifferentiated <strong class='adj'> adj. </strong> Not differentiated; amorphous.</p>",
      },
    ],
  },
  {
    word: "Undigested",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undigested <strong class='adj'> adj. </strong> 1 not digested. 2 (of facts etc.) Not properly arranged or considered.</p>",
      },
    ],
  },
  {
    word: "Undignified",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undignified <strong class='adj'> adj. </strong> Lacking dignity.</p>",
      },
    ],
  },
  {
    word: "Undiluted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undiluted <strong class='adj'> adj. </strong> 1 not diluted. 2 complete, utter.</p>",
      },
    ],
  },
  {
    word: "Undiminished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undiminished <strong class='adj'> adj. </strong> Not diminished or lessened.</p>",
      },
    ],
  },
  {
    word: "Undine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undine <strong class='noun'> n. </strong> Female water-spirit. [latin unda wave]</p>",
      },
    ],
  },
  {
    word: "Undiplomatic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undiplomatic <strong class='adj'> adj. </strong> Tactless.</p>",
      },
    ],
  },
  {
    word: "Undisciplined",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undisciplined <strong class='adj'> adj. </strong> Lacking discipline; not disciplined.</p>",
      },
    ],
  },
  {
    word: "Undisclosed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undisclosed <strong class='adj'> adj. </strong> Not revealed or made known.</p>",
      },
    ],
  },
  {
    word: "Undiscovered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undiscovered <strong class='adj'> adj. </strong> Not discovered.</p>",
      },
    ],
  },
  {
    word: "Undiscriminating",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undiscriminating <strong class='adj'> adj. </strong> Lacking good judgement.</p>",
      },
    ],
  },
  {
    word: "Undisguised",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undisguised <strong class='adj'> adj. </strong> Not disguised; open.</p>",
      },
    ],
  },
  {
    word: "Undismayed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undismayed <strong class='adj'> adj. </strong> Not dismayed.</p>",
      },
    ],
  },
  {
    word: "Undisputed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undisputed <strong class='adj'> adj. </strong> Not disputed or called in question.</p>",
      },
    ],
  },
  {
    word: "Undistinguished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undistinguished <strong class='adj'> adj. </strong> Not distinguished; mediocre.</p>",
      },
    ],
  },
  {
    word: "Undisturbed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undisturbed <strong class='adj'> adj. </strong> Not disturbed or interfered with.</p>",
      },
    ],
  },
  {
    word: "Undivided",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undivided <strong class='adj'> adj. </strong> Not divided or shared; whole, entire (undivided attention).</p>",
      },
    ],
  },
  {
    word: "Undo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undo <strong class='verb'> v. </strong> (3rd sing. Present -does; past -did;  <strong class='archaic_it_was'>past part.</strong> -done; pres. Part. -doing) 1 unfasten (a coat, button, parcel, etc.), or the clothing of (a person). 2 annul, cancel (cannot undo the past). 3 ruin the prospects, reputation, or morals of.</p>",
      },
    ],
  },
  {
    word: "Undoing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undoing <strong class='noun'> n. </strong> 1 ruin or cause of ruin. 2 reversing of an action etc. 3 opening or unfastening.</p>",
      },
    ],
  },
  {
    word: "Undone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undone <strong class='adj'> adj. </strong> 1 not done. 2 not fastened. 3 archaic ruined.</p>",
      },
    ],
  },
  {
    word: "Undoubted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undoubted <strong class='adj'> adj. </strong> Certain, not questioned. undoubtedly adv.</p>",
      },
    ],
  },
  {
    word: "Undreamed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undreamed <strong class='adj'> adj. </strong> (also undreamt) (often foll. By of) not dreamed, thought, or imagined.</p>",
      },
    ],
  },
  {
    word: "Undress",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undress <strong class='noun'> —v. </strong> 1 take off one's clothes. 2 take the clothes off (a person). <strong class='noun'> —n. </strong> 1 ordinary or casual dress, esp. As opposed to full dress or uniform. 2 naked or scantily clad state.</p>",
      },
    ],
  },
  {
    word: "Undressed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undressed <strong class='adj'> adj. </strong> 1 not, or no longer, dressed. 2 (of food) without a dressing. 3 (of leather etc.) Not treated.</p>",
      },
    ],
  },
  {
    word: "Undrinkable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undrinkable <strong class='adj'> adj. </strong> Unfit for drinking.</p>",
      },
    ],
  },
  {
    word: "Undue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undue <strong class='adj'> adj. </strong> Excessive, disproportionate. unduly adv.</p>",
      },
    ],
  },
  {
    word: "Undulate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undulate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (cause to) have a wavy motion or look. undulation <strong class='noun'> n. </strong> [latin unda wave]</p>",
      },
    ],
  },
  {
    word: "Undying",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Undying <strong class='adj'> adj. </strong> Immortal; never-ending (undying love).</p>",
      },
    ],
  },
  {
    word: "Unearned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unearned <strong class='adj'> adj. </strong> Not earned.</p>",
      },
    ],
  },
  {
    word: "Unearned income",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unearned income <strong class='noun'> n. </strong> Income from investments etc. Rather than from working.</p>",
      },
    ],
  },
  {
    word: "Unearth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unearth <strong class='verb'> v. </strong> Discover by searching, digging, or rummaging.</p>",
      },
    ],
  },
  {
    word: "Unearthly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unearthly <strong class='adj'> adj. </strong> 1 supernatural, mysterious. 2 <strong class='colloq'> colloq. </strong> Absurdly early or inconvenient (unearthly hour). unearthliness n.</p>",
      },
    ],
  },
  {
    word: "Unease",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unease <strong class='noun'> n. </strong> Nervousness, anxiety.</p>",
      },
    ],
  },
  {
    word: "Uneasy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uneasy <strong class='adj'> adj. </strong> (-ier, -iest) 1 nervous, anxious. 2 disturbing (uneasy suspicion). uneasily <strong class='adv'> adv. </strong> Uneasiness n.</p>",
      },
    ],
  },
  {
    word: "Uneatable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uneatable <strong class='adj'> adj. </strong> Not able to be eaten (cf. *inedible).</p>",
      },
    ],
  },
  {
    word: "Uneaten",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uneaten <strong class='adj'> adj. </strong> Left not eaten.</p>",
      },
    ],
  },
  {
    word: "Uneconomic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uneconomic <strong class='adj'> adj. </strong> Not economic; unprofitable.</p>",
      },
    ],
  },
  {
    word: "Uneconomical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uneconomical <strong class='adj'> adj. </strong> Not economical; wasteful.</p>",
      },
    ],
  },
  {
    word: "Unedifying",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unedifying <strong class='adj'> adj. </strong> Distasteful, degrading.</p>",
      },
    ],
  },
  {
    word: "Unedited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unedited <strong class='adj'> adj. </strong> Not edited.</p>",
      },
    ],
  },
  {
    word: "Uneducated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uneducated <strong class='adj'> adj. </strong> Not educated.</p>",
      },
    ],
  },
  {
    word: "Unembarrassed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unembarrassed <strong class='adj'> adj. </strong> Not embarrassed.</p>",
      },
    ],
  },
  {
    word: "Unemotional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unemotional <strong class='adj'> adj. </strong> Not emotional; lacking emotion.</p>",
      },
    ],
  },
  {
    word: "Unemphatic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unemphatic <strong class='adj'> adj. </strong> Not emphatic.</p>",
      },
    ],
  },
  {
    word: "Unemployable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unemployable <strong class='adj'> adj. </strong> Unfit for paid employment. unemployability n.</p>",
      },
    ],
  },
  {
    word: "Unemployed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unemployed <strong class='adj'> adj. </strong> 1 out of work. 2 not in use.</p>",
      },
    ],
  },
  {
    word: "Unemployment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unemployment <strong class='noun'> n. </strong> 1 being unemployed. 2 lack of employment in a country etc.</p>",
      },
    ],
  },
  {
    word: "Unemployment benefit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unemployment benefit <strong class='noun'> n. </strong> State payment made to an unemployed person.</p>",
      },
    ],
  },
  {
    word: "Unencumbered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unencumbered <strong class='adj'> adj. </strong> 1 (of an estate) not having liabilities (e.g. A mortgage). 2 free; not burdened.</p>",
      },
    ],
  },
  {
    word: "Unending",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unending <strong class='adj'> adj. </strong> Endless or seemingly endless.</p>",
      },
    ],
  },
  {
    word: "Unendurable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unendurable <strong class='adj'> adj. </strong> Too bad to be borne.</p>",
      },
    ],
  },
  {
    word: "Unenlightened",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unenlightened <strong class='adj'> adj. </strong> Not enlightened.</p>",
      },
    ],
  },
  {
    word: "Unenterprising",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unenterprising <strong class='adj'> adj. </strong> Not enterprising.</p>",
      },
    ],
  },
  {
    word: "Unenthusiastic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unenthusiastic <strong class='adj'> adj. </strong> Not enthusiastic. unenthusiastically adv.</p>",
      },
    ],
  },
  {
    word: "Unenviable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unenviable <strong class='adj'> adj. </strong> Not enviable.</p>",
      },
    ],
  },
  {
    word: "Unequal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unequal <strong class='adj'> adj. </strong> 1 <strong class='adj'> (often foll. By to) </strong> not equal. 2 of varying quality. 3 unfair (unequal contest). unequally adv.</p>",
      },
    ],
  },
  {
    word: "Unequaled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unequaled <strong class='adj'> adj. </strong> (brit. -alled) superior to all others.</p>",
      },
    ],
  },
  {
    word: "Unequalled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unequalled <strong class='adj'> adj. </strong> (us -aled) superior to all others.</p>",
      },
    ],
  },
  {
    word: "Unequivocal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unequivocal <strong class='adj'> adj. </strong> Not ambiguous, plain, unmistakable. unequivocally adv.</p>",
      },
    ],
  },
  {
    word: "Unerring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unerring <strong class='adj'> adj. </strong> Not erring; true, certain. unerringly adv.</p>",
      },
    ],
  },
  {
    word: "Unesco",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unesco <strong class='sing'> abbr. </strong> (also unesco) united nations educational, scientific, and cultural organization.</p>",
      },
    ],
  },
  {
    word: "Unethical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unethical <strong class='adj'> adj. </strong> Not ethical, esp. Unscrupulous or unprofessional. unethically adv.</p>",
      },
    ],
  },
  {
    word: "Uneven",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uneven <strong class='adj'> adj. </strong> 1 not level or smooth. 2 of variable quality etc. 3 (of a contest) unequal. unevenly <strong class='adv'> adv. </strong> Unevenness n.</p>",
      },
    ],
  },
  {
    word: "Uneventful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uneventful <strong class='adj'> adj. </strong> Not eventful. uneventfully adv.</p>",
      },
    ],
  },
  {
    word: "Unexampled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexampled <strong class='adj'> adj. </strong> Without precedent.</p>",
      },
    ],
  },
  {
    word: "Unexceptionable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexceptionable <strong class='adj'> adj. </strong> Entirely satisfactory. <strong class='usage'> Usage:- </strong>see note at exceptionable.</p>",
      },
    ],
  },
  {
    word: "Unexceptional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexceptional <strong class='adj'> adj. </strong> Usual, normal, ordinary. <strong class='usage'> Usage:- </strong>see note at exceptionable.</p>",
      },
    ],
  },
  {
    word: "Unexceptional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexceptional <strong class='adj'> adj. </strong> Usual, normal, ordinary.</p>",
      },
    ],
  },
  {
    word: "Unexciting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexciting <strong class='adj'> adj. </strong> Not exciting; dull.</p>",
      },
    ],
  },
  {
    word: "Unexpected",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexpected <strong class='adj'> adj. </strong> Not expected; surprising. unexpectedly <strong class='adv'> adv. </strong> Unexpectedness n.</p>",
      },
    ],
  },
  {
    word: "Unexplained",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexplained <strong class='adj'> adj. </strong> Not explained.</p>",
      },
    ],
  },
  {
    word: "Unexplored",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexplored <strong class='adj'> adj. </strong> Not explored.</p>",
      },
    ],
  },
  {
    word: "Unexposed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexposed <strong class='adj'> adj. </strong> Not exposed.</p>",
      },
    ],
  },
  {
    word: "Unexpressed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexpressed <strong class='adj'> adj. </strong> Not expressed or made known (unexpressed fears).</p>",
      },
    ],
  },
  {
    word: "Unexpurgated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unexpurgated <strong class='adj'> adj. </strong> (esp. Of a text etc.) Complete.</p>",
      },
    ],
  },
  {
    word: "Unfading",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfading <strong class='adj'> adj. </strong> Never fading.</p>",
      },
    ],
  },
  {
    word: "Unfailing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfailing <strong class='adj'> adj. </strong> Not failing or dwindling; constant; reliable. unfailingly adv.</p>",
      },
    ],
  },
  {
    word: "Unfair",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfair <strong class='adj'> adj. </strong> Not fair, just, or impartial. unfairly <strong class='adv'> adv. </strong> Unfairness n.</p>",
      },
    ],
  },
  {
    word: "Unfaithful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfaithful <strong class='adj'> adj. </strong> 1 not faithful, esp. Adulterous. 2 treacherous; disloyal. unfaithfully <strong class='adv'> adv. </strong> Unfaithfulness n.</p>",
      },
    ],
  },
  {
    word: "Unfamiliar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfamiliar <strong class='adj'> adj. </strong> Not familiar. unfamiliarity n.</p>",
      },
    ],
  },
  {
    word: "Unfashionable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfashionable <strong class='adj'> adj. </strong> Not fashionable. unfashionably adv.</p>",
      },
    ],
  },
  {
    word: "Unfasten",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfasten <strong class='verb'> v. </strong> 1 make or become loose. 2 open the fastening(s) of. 3 detach.</p>",
      },
    ],
  },
  {
    word: "Unfathomable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfathomable <strong class='adj'> adj. </strong> Incapable of being fathomed.</p>",
      },
    ],
  },
  {
    word: "Unfavorable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfavorable <strong class='adj'> adj. </strong> (brit. Unfavourable) not favourable; adverse, hostile. unfavourably adv.</p>",
      },
    ],
  },
  {
    word: "Unfavourable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfavourable <strong class='adj'> adj. </strong> (us unfavorable) not favourable; adverse, hostile. unfavourably adv.</p>",
      },
    ],
  },
  {
    word: "Unfeasible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfeasible <strong class='adj'> adj. </strong> Not feasible; impractical.</p>",
      },
    ],
  },
  {
    word: "Unfeeling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfeeling <strong class='adj'> adj. </strong> Unsympathetic, harsh.</p>",
      },
    ],
  },
  {
    word: "Unfeigned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfeigned <strong class='adj'> adj. </strong> Genuine, sincere.</p>",
      },
    ],
  },
  {
    word: "Unfertilized",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfertilized <strong class='adj'> adj. </strong> (also -ised) not fertilized.</p>",
      },
    ],
  },
  {
    word: "Unfetter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfetter <strong class='verb'> v. </strong> Release from fetters.</p>",
      },
    ],
  },
  {
    word: "Unfilled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfilled <strong class='adj'> adj. </strong> Not filled.</p>",
      },
    ],
  },
  {
    word: "Unfinished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfinished <strong class='adj'> adj. </strong> Not finished; incomplete.</p>",
      },
    ],
  },
  {
    word: "Unfit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfit <strong class='adj'> adj. </strong> (often foll. By for, or to + infin.) Not fit.</p>",
      },
    ],
  },
  {
    word: "Unfitted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfitted <strong class='adj'> adj. </strong> 1 not fit. 2 not fitted or suited. 3 having no fittings.</p>",
      },
    ],
  },
  {
    word: "Unfitting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfitting <strong class='adj'> adj. </strong> Not suitable, unbecoming.</p>",
      },
    ],
  },
  {
    word: "Unfix",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfix <strong class='verb'> v. </strong> Release, loosen, or detach.</p>",
      },
    ],
  },
  {
    word: "Unflagging",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unflagging <strong class='adj'> adj. </strong> Tireless, persistent.</p>",
      },
    ],
  },
  {
    word: "Unflappable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unflappable <strong class='adj'> adj. </strong> Colloq. Imperturbable; calm. unflappability n.</p>",
      },
    ],
  },
  {
    word: "Unflattering",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unflattering <strong class='adj'> adj. </strong> Not flattering. unflatteringly adv.</p>",
      },
    ],
  },
  {
    word: "Unfledged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfledged <strong class='adj'> adj. </strong> 1 (of a person) inexperienced. 2 (of a bird) not yet fledged.</p>",
      },
    ],
  },
  {
    word: "Unflinching",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unflinching <strong class='adj'> adj. </strong> Not flinching. unflinchingly adv.</p>",
      },
    ],
  },
  {
    word: "Unfold",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfold <strong class='verb'> v. </strong> 1 open the fold or folds of, spread out. 2 reveal (thoughts etc.). 3 become opened out. 4 develop.</p>",
      },
    ],
  },
  {
    word: "Unforced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unforced <strong class='adj'> adj. </strong> 1 easy, natural. 2 not compelled or constrained.</p>",
      },
    ],
  },
  {
    word: "Unforeseeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unforeseeable <strong class='adj'> adj. </strong> Not foreseeable.</p>",
      },
    ],
  },
  {
    word: "Unforeseen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unforeseen <strong class='adj'> adj. </strong> Not foreseen.</p>",
      },
    ],
  },
  {
    word: "Unforgettable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unforgettable <strong class='adj'> adj. </strong> That cannot be forgotten; memorable, wonderful.</p>",
      },
    ],
  },
  {
    word: "Unforgivable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unforgivable <strong class='adj'> adj. </strong> That cannot be forgiven.</p>",
      },
    ],
  },
  {
    word: "Unforgiving",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unforgiving <strong class='adj'> adj. </strong> Not forgiving.</p>",
      },
    ],
  },
  {
    word: "Unformed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unformed <strong class='adj'> adj. </strong> 1 not formed; undeveloped. 2 shapeless.</p>",
      },
    ],
  },
  {
    word: "Unforthcoming",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unforthcoming <strong class='adj'> adj. </strong> Not forthcoming.</p>",
      },
    ],
  },
  {
    word: "Unfortunate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfortunate <strong class='adj'> —adj.</strong> 1 unlucky. 2 unhappy. 3 regrettable. <strong class='noun'> —n. </strong> Unfortunate person.</p>",
      },
    ],
  },
  {
    word: "Unfortunately",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfortunately <strong class='adv'> adv. </strong> 1 (qualifying a sentence) it is unfortunate that. 2 in an unfortunate manner.</p>",
      },
    ],
  },
  {
    word: "Unfounded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfounded <strong class='adj'> adj. </strong> Without foundation (unfounded rumour).</p>",
      },
    ],
  },
  {
    word: "Unfreeze",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfreeze <strong class='verb'> v. </strong> (-zing; past unfroze;  <strong class='archaic_it_was'>past part.</strong> Unfrozen) 1 (cause to) thaw. 2 derestrict (assets, credits, etc.).</p>",
      },
    ],
  },
  {
    word: "Unfrequented",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfrequented <strong class='adj'> adj. </strong> Not frequented.</p>",
      },
    ],
  },
  {
    word: "Unfriendly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfriendly <strong class='adj'> adj. </strong> (-ier, -iest) not friendly; hostile.</p>",
      },
    ],
  },
  {
    word: "Unfrock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfrock <strong class='verb'> v. </strong> = *defrock.</p>",
      },
    ],
  },
  {
    word: "Unfroze  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfroze  <strong class='slang_v'>past of *</strong> unfreeze.</p>",
      },
    ],
  },
  {
    word: "Unfrozen  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfrozen  <strong class='see'> <strong class='archaic_it_was'>past part. Of *</strong> unfreeze. </strong> </p>",
      },
    ],
  },
  {
    word: "Unfulfilled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfulfilled <strong class='adj'> adj. </strong> Not fulfilled.</p>",
      },
    ],
  },
  {
    word: "Unfunny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfunny <strong class='adj'> adj. </strong> (-ier, -iest) failing to amuse.</p>",
      },
    ],
  },
  {
    word: "Unfurl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfurl <strong class='verb'> v. </strong> 1 unroll, spread out (a sail, umbrella, etc.). 2 become unrolled.</p>",
      },
    ],
  },
  {
    word: "Unfurnished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unfurnished <strong class='adj'> adj. </strong> 1 (usu. Foll. By with) not supplied. 2 without furniture.</p>",
      },
    ],
  },
  {
    word: "Ungainly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungainly <strong class='adj'> adj. </strong> Awkward, clumsy. ungainliness <strong class='noun'> n. </strong> [obsolete gain straight, from old norse]</p>",
      },
    ],
  },
  {
    word: "Ungenerous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungenerous <strong class='adj'> adj. </strong> Mean. ungenerously adv.</p>",
      },
    ],
  },
  {
    word: "Ungentlemanly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungentlemanly <strong class='adj'> adj. </strong> Not gentlemanly.</p>",
      },
    ],
  },
  {
    word: "Unget-at-able",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unget-at-able <strong class='adj'> adj. </strong> Colloq. Inaccessible.</p>",
      },
    ],
  },
  {
    word: "Ungird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungird <strong class='verb'> v. </strong> Release the girdle, belt, etc. Of.</p>",
      },
    ],
  },
  {
    word: "Ungodly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungodly <strong class='adj'> adj. </strong> 1 impious, wicked. 2 <strong class='colloq'> colloq. </strong> Outrageous (ungodly hour).</p>",
      },
    ],
  },
  {
    word: "Ungovernable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungovernable <strong class='adj'> adj. </strong> Uncontrollable, violent.</p>",
      },
    ],
  },
  {
    word: "Ungraceful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungraceful <strong class='adj'> adj. </strong> Lacking grace or elegance. ungracefully adv.</p>",
      },
    ],
  },
  {
    word: "Ungracious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungracious <strong class='adj'> adj. </strong> Discourteous; grudging. ungraciously adv.</p>",
      },
    ],
  },
  {
    word: "Ungrammatical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungrammatical <strong class='adj'> adj. </strong> Contrary to the rules of grammar. ungrammatically adv.</p>",
      },
    ],
  },
  {
    word: "Ungrateful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungrateful <strong class='adj'> adj. </strong> Not feeling or showing gratitude. ungratefully adv.</p>",
      },
    ],
  },
  {
    word: "Ungreen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungreen <strong class='adj'> adj. </strong> Not concerned with the protection of the environment; harmful to the environment.</p>",
      },
    ],
  },
  {
    word: "Ungrudging",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungrudging <strong class='adj'> adj. </strong> Not grudging.</p>",
      },
    ],
  },
  {
    word: "Unguarded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unguarded <strong class='adj'> adj. </strong> 1 incautious, thoughtless (unguarded remark). 2 not guarded.</p>",
      },
    ],
  },
  {
    word: "Unguent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unguent <strong class='noun'> n. </strong> Soft ointment or lubricant. [latin unguo anoint]</p>",
      },
    ],
  },
  {
    word: "Ungulate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ungulate <strong class='adj'> —adj.</strong> Hoofed. <strong class='noun'> —n. </strong> Hoofed mammal. [latin ungula hoof, claw]</p>",
      },
    ],
  },
  {
    word: "Unhallowed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhallowed <strong class='adj'> adj. </strong> 1 not consecrated. 2 not sacred, wicked.</p>",
      },
    ],
  },
  {
    word: "Unhampered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhampered <strong class='adj'> adj. </strong> Not hampered.</p>",
      },
    ],
  },
  {
    word: "Unhand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhand <strong class='verb'> v. </strong> Rhet. Or joc. Take one's hands off (a person); release.</p>",
      },
    ],
  },
  {
    word: "Unhappy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhappy <strong class='adj'> adj. </strong> (-ier, -iest) 1 miserable. 2 unfortunate. 3 disastrous. unhappily <strong class='adv'> adv. </strong> Unhappiness n.</p>",
      },
    ],
  },
  {
    word: "Unharmed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unharmed <strong class='adj'> adj. </strong> Not harmed.</p>",
      },
    ],
  },
  {
    word: "Unharness",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unharness <strong class='verb'> v. </strong> Remove a harness from.</p>",
      },
    ],
  },
  {
    word: "Unhealthy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhealthy <strong class='adj'> adj. </strong> (-ier, -iest) 1 in poor health. 2 a harmful to health. B unwholesome. C slang dangerous. unhealthily <strong class='adv'> adv. </strong> Unhealthiness n.</p>",
      },
    ],
  },
  {
    word: "Unheard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unheard <strong class='adj'> adj. </strong> 1 not heard. 2 (usu. Unheard-of) unprecedented.</p>",
      },
    ],
  },
  {
    word: "Unheeded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unheeded <strong class='adj'> adj. </strong> Disregarded.</p>",
      },
    ],
  },
  {
    word: "Unhelpful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhelpful <strong class='adj'> adj. </strong> Not helpful. unhelpfully adv.</p>",
      },
    ],
  },
  {
    word: "Unhesitating",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhesitating <strong class='adj'> adj. </strong> Without hesitation. unhesitatingly adv.</p>",
      },
    ],
  },
  {
    word: "Unhindered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhindered <strong class='adj'> adj. </strong> Not hindered.</p>",
      },
    ],
  },
  {
    word: "Unhinge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhinge <strong class='verb'> v. </strong> (-ging) 1 take (a door etc.) Off its hinges. 2 (esp. As unhinged adj.) Make mad or crazy.</p>",
      },
    ],
  },
  {
    word: "Unhistorical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhistorical <strong class='adj'> adj. </strong> Not historical.</p>",
      },
    ],
  },
  {
    word: "Unhitch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhitch <strong class='verb'> v. </strong> 1 release from a hitched state. 2 unhook, unfasten.</p>",
      },
    ],
  },
  {
    word: "Unholy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unholy <strong class='adj'> adj. </strong> (-ier, -iest) 1 impious, wicked. 2 <strong class='colloq'> colloq. </strong> Dreadful; outrageous (unholy row).</p>",
      },
    ],
  },
  {
    word: "Unhook",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhook <strong class='verb'> v. </strong> 1 remove from a hook or hooks. 2 unfasten the hook(s) of.</p>",
      },
    ],
  },
  {
    word: "Unhoped-for",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhoped-for <strong class='adj'> adj. </strong> Not hoped for or expected.</p>",
      },
    ],
  },
  {
    word: "Unhorse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhorse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> throw (a rider) from a horse.</p>",
      },
    ],
  },
  {
    word: "Unhurried",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhurried <strong class='adj'> adj. </strong> Not hurried.</p>",
      },
    ],
  },
  {
    word: "Unhurt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Unhurt <strong class='adj'> adj. </strong> Not hurt.</p>",
      },
    ],
  },
  {
    word: "Unhygienic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unhygienic <strong class='adj'> adj. </strong> Not hygienic.</p>",
      },
    ],
  },
  {
    word: "Uni",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uni <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> esp. Austral. & nz <strong class='colloq'> colloq. </strong> University. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Uni-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uni- <strong class='n_pl'> comb. </strong> Form one; having or consisting of one. [latin unus one]</p>",
      },
    ],
  },
  {
    word: "Uniat  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uniat  <strong class='see'> (also uniate) </strong>  <strong class='adj'> —adj.</strong> Of the church in e. Europe or the near east, acknowledging papal supremacy but retaining its own liturgy etc. <strong class='noun'> —n. </strong> Member of such a church. [latin unio *union]</p>",
      },
    ],
  },
  {
    word: "Unicameral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unicameral <strong class='adj'> adj. </strong> Having a single legislative chamber. [related to *chamber]</p>",
      },
    ],
  },
  {
    word: "Unicef",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unicef <strong class='sing'> abbr. </strong> United nations children's (orig. International children's emergency) fund.</p>",
      },
    ],
  },
  {
    word: "Unicellular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unicellular <strong class='adj'> adj. </strong> (of an organism etc.) Consisting of a single cell.</p>",
      },
    ],
  },
  {
    word: "Unicorn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unicorn <strong class='noun'> n. </strong> Mythical horse with a single straight horn. [latin cornu horn]</p>",
      },
    ],
  },
  {
    word: "Unicycle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unicycle <strong class='noun'> n. </strong> Single-wheeled cycle, esp. As used by acrobats. unicyclist n.</p>",
      },
    ],
  },
  {
    word: "Unidentified",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unidentified <strong class='adj'> adj. </strong> Not identified.</p>",
      },
    ],
  },
  {
    word: "Unification",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unification <strong class='noun'> n. </strong> Unifying or being unified. unificatory adj.</p>",
      },
    ],
  },
  {
    word: "Unification church",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unification church <strong class='noun'> n. </strong> Religious organization founded by sun myung moon.</p>",
      },
    ],
  },
  {
    word: "Uniform",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uniform <strong class='adj'> —adj.</strong> 1 unvarying (uniform appearance). 2 conforming to the same standard, rules, etc. 3 constant over a period (uniform acceleration). <strong class='noun'> —n. </strong> Distinctive clothing worn by soldiers, police, schoolchildren, etc. uniformed adj. Uniformity <strong class='noun'> n. </strong> Uniformly <strong class='adv'> adv. </strong> [latin: related to *form]</p>",
      },
    ],
  },
  {
    word: "Unify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unify <strong class='verb'> v. </strong> (-ies, -ied) make or become united or uniform. [latin: related to *uni-]</p>",
      },
    ],
  },
  {
    word: "Unilateral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unilateral <strong class='adj'> adj. </strong> Done by or affecting only one person or party (unilateral disarmament). unilaterally adv.</p>",
      },
    ],
  },
  {
    word: "Unilateralism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unilateralism <strong class='noun'> n. </strong> Unilateral disarmament. unilateralist <strong class='noun'> n. </strong> & adj.</p>",
      },
    ],
  },
  {
    word: "Unimaginable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unimaginable <strong class='adj'> adj. </strong> Impossible to imagine.</p>",
      },
    ],
  },
  {
    word: "Unimaginative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unimaginative <strong class='adj'> adj. </strong> Lacking imagination; stolid, dull. unimaginatively adv.</p>",
      },
    ],
  },
  {
    word: "Unimpaired",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unimpaired <strong class='adj'> adj. </strong> Not impaired.</p>",
      },
    ],
  },
  {
    word: "Unimpeachable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unimpeachable <strong class='adj'> adj. </strong> Beyond reproach or question.</p>",
      },
    ],
  },
  {
    word: "Unimpeded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unimpeded <strong class='adj'> adj. </strong> Not impeded.</p>",
      },
    ],
  },
  {
    word: "Unimportant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unimportant <strong class='adj'> adj. </strong> Not important.</p>",
      },
    ],
  },
  {
    word: "Unimpressed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unimpressed <strong class='adj'> adj. </strong> Not impressed.</p>",
      },
    ],
  },
  {
    word: "Unimpressive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unimpressive <strong class='adj'> adj. </strong> Not impressive.</p>",
      },
    ],
  },
  {
    word: "Uninformed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninformed <strong class='adj'> adj. </strong> Not informed; ignorant.</p>",
      },
    ],
  },
  {
    word: "Uninhabitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninhabitable <strong class='adj'> adj. </strong> Unfit for habitation.</p>",
      },
    ],
  },
  {
    word: "Uninhabited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninhabited <strong class='adj'> adj. </strong> Not inhabited.</p>",
      },
    ],
  },
  {
    word: "Uninhibited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninhibited <strong class='adj'> adj. </strong> Not inhibited.</p>",
      },
    ],
  },
  {
    word: "Uninitiated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninitiated <strong class='adj'> adj. </strong> Not initiated, admitted, or instructed.</p>",
      },
    ],
  },
  {
    word: "Uninjured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninjured <strong class='adj'> adj. </strong> Not injured.</p>",
      },
    ],
  },
  {
    word: "Uninspired",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninspired <strong class='adj'> adj. </strong> Not inspired; commonplace, pedestrian.</p>",
      },
    ],
  },
  {
    word: "Uninspiring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninspiring <strong class='adj'> adj. </strong> Not inspiring.</p>",
      },
    ],
  },
  {
    word: "Unintelligent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unintelligent <strong class='adj'> adj. </strong> Not intelligent.</p>",
      },
    ],
  },
  {
    word: "Unintelligible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unintelligible <strong class='adj'> adj. </strong> Not intelligible.</p>",
      },
    ],
  },
  {
    word: "Unintended",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unintended <strong class='adj'> adj. </strong> Not intended.</p>",
      },
    ],
  },
  {
    word: "Unintentional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unintentional <strong class='adj'> adj. </strong> Not intentional. unintentionally adv.</p>",
      },
    ],
  },
  {
    word: "Uninterested",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninterested <strong class='adj'> adj. </strong> Not interested; indifferent.</p>",
      },
    ],
  },
  {
    word: "Uninteresting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninteresting <strong class='adj'> adj. </strong> Not interesting.</p>",
      },
    ],
  },
  {
    word: "Uninterrupted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninterrupted <strong class='adj'> adj. </strong> Not interrupted.</p>",
      },
    ],
  },
  {
    word: "Uninvited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninvited <strong class='adj'> adj. </strong> Not invited.</p>",
      },
    ],
  },
  {
    word: "Uninviting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uninviting <strong class='adj'> adj. </strong> Unattractive, repellent.</p>",
      },
    ],
  },
  {
    word: "Union",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Union <strong class='noun'> n. </strong> 1 uniting or being united. 2 a whole formed from parts or members. B political unit so formed. 3 = *trade union. 4 marriage. 5 concord (perfect union). 6 (union) a university social club and (at oxbridge) debating society. B buildings of this. 7 math. Totality of the members of two or more sets. 8 mixed fabric, e.g. Cotton with linen or silk. [latin unus one]</p>",
      },
    ],
  },
  {
    word: "Union-bashing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Union-bashing <strong class='noun'> n. </strong> Colloq. Media or government campaign against trade unions.</p>",
      },
    ],
  },
  {
    word: "Unionist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unionist <strong class='noun'> n. </strong> 1 a member of a trade union. B advocate of trade unions. 2 (usu. Unionist) member of a party advocating continued union between great britain and northern ireland. unionism n.</p>",
      },
    ],
  },
  {
    word: "Unionize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unionize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) organize in or into a trade union. unionization n.</p>",
      },
    ],
  },
  {
    word: "Union jack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Union jack <strong class='noun'> n. </strong> (also union flag) national ensign of the united kingdom.</p>",
      },
    ],
  },
  {
    word: "Unique",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unique <strong class='adj'> adj. </strong> 1 being the only one of its kind; having no like, equal, or parallel. 2 remarkable (unique opportunity). uniquely <strong class='adv'> adv. </strong> [latin unicus from unus one] <strong class='usage'> Usage:- </strong>in sense 1, unique cannot be qualified by adverbs such as absolutely, most, and quite. The use of unique in sense 2 is regarded as incorrect by some people.</p>",
      },
    ],
  },
  {
    word: "Unisex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unisex <strong class='adj'> adj. </strong> (of clothing, hairstyles, etc.) Designed for both sexes.</p>",
      },
    ],
  },
  {
    word: "Unison",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unison <strong class='noun'> n. </strong> 1 concord (acted in perfect unison). 2 coincidence in pitch of sounds or notes (sung in unison). [latin sonus *sound1]</p>",
      },
    ],
  },
  {
    word: "Unit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unit <strong class='noun'> n. </strong> 1 a individual thing, person, or group, esp. For calculation. B smallest component of a complex whole. 2 quantity as a standard of measurement (unit of heat; si unit). 3 smallest share in a unit trust. 4 part of a mechanism with a specified function. 5 fitted item of furniture, esp. As part of a set. 6 subgroup with a special function. 7 group of buildings, wards, etc., in a hospital. 8 a single-digit number. B the number ‘one’. [latin unus one]</p>",
      },
    ],
  },
  {
    word: "Unitarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unitarian <strong class='noun'> —n. </strong> 1 person who believes that god is one, not a trinity. 2 member of a religious body so believing. <strong class='adj'> —adj.</strong> Of unitarians. unitarianism <strong class='noun'> n. </strong> [latin unitas *unity]</p>",
      },
    ],
  },
  {
    word: "Unitary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unitary <strong class='adj'> adj. </strong> 1 of a unit or units. 2 marked by unity or uniformity. [from *unit or *unity]</p>",
      },
    ],
  },
  {
    word: "Unit cost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unit cost <strong class='noun'> n. </strong> Cost of producing one item.</p>",
      },
    ],
  },
  {
    word: "Unite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unite <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 join together; combine, esp. For a common purpose or action (united in their struggle). 2 join in marriage. 3 (cause to) form a physical or chemical whole (oil will not unite with water). [latin unio -it- from unus one]</p>",
      },
    ],
  },
  {
    word: "United kingdom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>United kingdom <strong class='noun'> n. </strong> Great britain and northern ireland.</p>",
      },
    ],
  },
  {
    word: "United nations",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>United nations <strong class='n_pl'> n.pl. </strong> (as sing. Or pl.) Supranational peace-seeking organization.</p>",
      },
    ],
  },
  {
    word: "United reformed church",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>United reformed church <strong class='noun'> n. </strong> Church formed in 1972 from the english presbyterian and congregational churches.</p>",
      },
    ],
  },
  {
    word: "United states",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>United states <strong class='noun'> n. </strong> (in full united states of america) federal republic of 50 states, mostly in <strong class='noun'> n. </strong> America and including alaska and hawaii.</p>",
      },
    ],
  },
  {
    word: "Unit price",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unit price <strong class='noun'> n. </strong> Price charged for each unit of goods supplied.</p>",
      },
    ],
  },
  {
    word: "Unit trust",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unit trust <strong class='noun'> n. </strong> Company investing contributions from many persons in various securities and paying proportional dividends.</p>",
      },
    ],
  },
  {
    word: "Unity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a oneness; being one; interconnected parts constituting a whole (national unity). B such a complex whole (person regarded as a unity). 2 a being united; solidarity. B harmony (lived together in unity). 3 the number ‘one’. [latin unus one]</p>",
      },
    ],
  },
  {
    word: "Univalent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Univalent <strong class='adj'> adj. </strong> Having a valency of one. [from *uni-, *valence1]</p>",
      },
    ],
  },
  {
    word: "Univalve  zool.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Univalve zool. <strong class='adj'> —adj.</strong> Having one valve. <strong class='noun'> —n. </strong> Univalve mollusc.</p>",
      },
    ],
  },
  {
    word: "Universal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Universal <strong class='adj'> —adj.</strong> Of, belonging to, or done etc. By all; applicable to all cases. <strong class='noun'> —n. </strong> Term, characteristic, or concept of general application. universality <strong class='noun'> n. </strong> Universally <strong class='adv'> adv. </strong> [latin: related to *universe]</p>",
      },
    ],
  },
  {
    word: "Universal coupling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Universal coupling <strong class='noun'> n. </strong> (also universal joint) coupling or joint which can transmit rotary power by a shaft at any angle.</p>",
      },
    ],
  },
  {
    word: "Universal time",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Universal time <strong class='noun'> n. </strong> = *greenwich mean time.</p>",
      },
    ],
  },
  {
    word: "Universe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Universe <strong class='noun'> n. </strong> 1 all existing things; creation. 2 all mankind. 3 statistics & logic all the objects under consideration. [latin universus combined into one]</p>",
      },
    ],
  },
  {
    word: "University",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>University <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 educational institution of advanced learning and research conferring degrees. 2 members of this. [latin: related to *universe]</p>",
      },
    ],
  },
  {
    word: "Unjust",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unjust <strong class='adj'> adj. </strong> Not just, not fair. unjustly <strong class='adv'> adv. </strong> Unjustness n.</p>",
      },
    ],
  },
  {
    word: "Unjustifiable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unjustifiable <strong class='adj'> adj. </strong> Not justifiable. unjustifiably adv.</p>",
      },
    ],
  },
  {
    word: "Unjustified",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unjustified <strong class='adj'> adj. </strong> Not justified.</p>",
      },
    ],
  },
  {
    word: "Unkempt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unkempt <strong class='adj'> adj. </strong> Untidy, dishevelled. [= uncombed]</p>",
      },
    ],
  },
  {
    word: "Unkind",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unkind <strong class='adj'> adj. </strong> Not kind; harsh, cruel. unkindly <strong class='adv'> adv. </strong> Unkindness n.</p>",
      },
    ],
  },
  {
    word: "Unknot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unknot <strong class='verb'> v. </strong> <strong class='adj'> (-tt-) </strong> release the knot(s) of, untie.</p>",
      },
    ],
  },
  {
    word: "Unknowable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unknowable <strong class='adj'> —adj.</strong> That cannot be known. <strong class='noun'> —n. </strong> 1 unknowable thing. 2 (the unknowable) the postulated absolute or ultimate reality.</p>",
      },
    ],
  },
  {
    word: "Unknowing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unknowing <strong class='adj'> adj. </strong> (often foll. By of) not knowing; ignorant, unconscious. unknowingly adv.</p>",
      },
    ],
  },
  {
    word: "Unknown",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unknown <strong class='adj'> —adj.</strong> <strong class='adj'> (often foll. By to) </strong> not known, unfamiliar. <strong class='noun'> —n. </strong> Unknown thing, person, or quantity. unknown to without the knowledge of (did it unknown to me).</p>",
      },
    ],
  },
  {
    word: "Unknown quantity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unknown quantity <strong class='noun'> n. </strong> Mysterious or obscure person or thing.</p>",
      },
    ],
  },
  {
    word: "Unknown soldier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unknown soldier <strong class='noun'> n. </strong> Unidentified soldier etc. Symbolizing a nation's dead in war.</p>",
      },
    ],
  },
  {
    word: "Unknown warrior",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unknown warrior <strong class='noun'> n. </strong> = *unknown soldier.</p>",
      },
    ],
  },
  {
    word: "Unlabeled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlabeled <strong class='adj'> adj. </strong> (brit. Unlabelled) not labelled; without a label.</p>",
      },
    ],
  },
  {
    word: "Unlabelled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlabelled <strong class='adj'> adj. </strong> (us unlabeled) not labelled; without a label.</p>",
      },
    ],
  },
  {
    word: "Unlace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlace <strong class='verb'> v. </strong> (-cing) 1 undo the lace(s) of. 2 unfasten or loosen in this way.</p>",
      },
    ],
  },
  {
    word: "Unladen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unladen <strong class='adj'> adj. </strong> Not laden.</p>",
      },
    ],
  },
  {
    word: "Unladen weight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unladen weight <strong class='noun'> n. </strong> Weight of a vehicle etc. When not loaded.</p>",
      },
    ],
  },
  {
    word: "Unladylike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unladylike <strong class='adj'> adj. </strong> Not ladylike.</p>",
      },
    ],
  },
  {
    word: "Unlatch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlatch <strong class='verb'> v. </strong> 1 release the latch of. 2 open in this way.</p>",
      },
    ],
  },
  {
    word: "Unlawful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlawful <strong class='adj'> adj. </strong> Illegal, not permissible. unlawfully adv.</p>",
      },
    ],
  },
  {
    word: "Unleaded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unleaded <strong class='adj'> adj. </strong> (of petrol etc.) Without added lead.</p>",
      },
    ],
  },
  {
    word: "Unlearn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlearn <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Unlearned or unlearnt) 1 forget deliberately. 2 rid oneself of (a habit, false information, etc.).</p>",
      },
    ],
  },
  {
    word: "Unlearned1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlearned1 <strong class='adj'> adj. </strong> Not well educated; ignorant.</p>",
      },
    ],
  },
  {
    word: "Unlearned2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlearned2 <strong class='adj'> adj. </strong> (also unlearnt) not learnt.</p>",
      },
    ],
  },
  {
    word: "Unleash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unleash <strong class='verb'> v. </strong> 1 release from a leash or restraint. 2 set free to engage in pursuit or attack.</p>",
      },
    ],
  },
  {
    word: "Unleavened",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unleavened <strong class='adj'> adj. </strong> Not leavened; made without yeast etc.</p>",
      },
    ],
  },
  {
    word: "Unless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unless <strong class='conj'> conj. </strong> If not; except when (shall go unless i hear from you). [= on less]</p>",
      },
    ],
  },
  {
    word: "Unlettered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlettered <strong class='adj'> adj. </strong> Illiterate; not well educated.</p>",
      },
    ],
  },
  {
    word: "Unlicensed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlicensed <strong class='adj'> adj. </strong> Not licensed, esp. To sell alcohol.</p>",
      },
    ],
  },
  {
    word: "Unlike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlike <strong class='adj'> —adj.</strong> 1 not like; different from. 2 uncharacteristic of (greed is unlike her). 3 dissimilar, different. <strong class='prep'> —prep. </strong> Differently from (acts quite unlike anyone else).</p>",
      },
    ],
  },
  {
    word: "Unlikely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlikely <strong class='adj'> adj. </strong> (-ier, -iest) 1 improbable (unlikely tale). 2 (foll. By to + infin.) Not expected (unlikely to die). 3 unpromising (unlikely candidate). unlikeliness n.</p>",
      },
    ],
  },
  {
    word: "Unlike signs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlike signs <strong class='n_pl'> n.pl. </strong> Math. Plus and minus.</p>",
      },
    ],
  },
  {
    word: "Unlimited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlimited <strong class='adj'> adj. </strong> Unrestricted; enormous (unlimited expanse).</p>",
      },
    ],
  },
  {
    word: "Unlined1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlined1 <strong class='adj'> adj. </strong> Without lines or wrinkles.</p>",
      },
    ],
  },
  {
    word: "Unlined2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlined2 <strong class='adj'> adj. </strong> Without a lining.</p>",
      },
    ],
  },
  {
    word: "Unlisted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlisted <strong class='adj'> adj. </strong> Not in a published list, esp. Of stock exchange prices or telephone numbers.</p>",
      },
    ],
  },
  {
    word: "Unlit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Unlit <strong class='adj'> adj. </strong> Not lit.</p>",
      },
    ],
  },
  {
    word: "Unload",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unload <strong class='verb'> v. </strong> 1 (also absol.) Remove a load from (a vehicle etc.). 2 remove (a load) from a vehicle etc. 3 remove the ammunition from (a gun etc.). 4 <strong class='colloq'> colloq. </strong> Get rid of.</p>",
      },
    ],
  },
  {
    word: "Unlock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlock <strong class='verb'> v. </strong> 1 a release the lock of (a door, box, etc.). B release or disclose by unlocking. 2 release thoughts, feelings, etc. From (one's mind etc.).</p>",
      },
    ],
  },
  {
    word: "Unlooked-for",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlooked-for <strong class='adj'> adj. </strong> Unexpected.</p>",
      },
    ],
  },
  {
    word: "Unloose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unloose <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> (also unloosen) unfasten, loose; set free.</p>",
      },
    ],
  },
  {
    word: "Unlovable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlovable <strong class='adj'> adj. </strong> Not lovable.</p>",
      },
    ],
  },
  {
    word: "Unloved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unloved <strong class='adj'> adj. </strong> Not loved.</p>",
      },
    ],
  },
  {
    word: "Unlovely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlovely <strong class='adj'> adj. </strong> Not attractive; unpleasant.</p>",
      },
    ],
  },
  {
    word: "Unloving",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unloving <strong class='adj'> adj. </strong> Not loving.</p>",
      },
    ],
  },
  {
    word: "Unlucky",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unlucky <strong class='adj'> adj. </strong> (-ier, -iest) 1 not fortunate or successful. 2 wretched. 3 bringing bad luck. 4 ill-judged. unluckily adv.</p>",
      },
    ],
  },
  {
    word: "Unmade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmade <strong class='adj'> adj. </strong> (esp. Of a bed) not made.</p>",
      },
    ],
  },
  {
    word: "Unmake",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmake <strong class='verb'> v. </strong> (-king; past and  <strong class='archaic_it_was'>past part.</strong> Unmade) undo; destroy, depose, annul.</p>",
      },
    ],
  },
  {
    word: "Unman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unman <strong class='verb'> v. </strong> (-nn-) make weak, cowardly, etc.; cause to weep etc.</p>",
      },
    ],
  },
  {
    word: "Unmanageable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmanageable <strong class='adj'> adj. </strong> Not easily managed or controlled.</p>",
      },
    ],
  },
  {
    word: "Unmanly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmanly <strong class='adj'> adj. </strong> Not manly.</p>",
      },
    ],
  },
  {
    word: "Unmanned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmanned <strong class='adj'> adj. </strong> 1 not manned. 2 overcome by emotion etc.</p>",
      },
    ],
  },
  {
    word: "Unmannerly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmannerly <strong class='adj'> adj. </strong> Ill-mannered. unmannerliness n.</p>",
      },
    ],
  },
  {
    word: "Unmarked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmarked <strong class='adj'> adj. </strong> 1 not marked. 2 not noticed.</p>",
      },
    ],
  },
  {
    word: "Unmarried",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmarried <strong class='adj'> adj. </strong> Not married, single.</p>",
      },
    ],
  },
  {
    word: "Unmask",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmask <strong class='verb'> v. </strong> 1 a remove the mask from. B expose the true character of. 2 remove one's mask.</p>",
      },
    ],
  },
  {
    word: "Unmatched",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmatched <strong class='adj'> adj. </strong> Not matched or equalled.</p>",
      },
    ],
  },
  {
    word: "Unmentionable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmentionable <strong class='adj'> —adj.</strong> Unsuitable for polite conversation. <strong class='noun'> —n. </strong> (in pl.) Joc. Undergarments.</p>",
      },
    ],
  },
  {
    word: "Unmerciful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmerciful <strong class='adj'> adj. </strong> Merciless. unmercifully adv.</p>",
      },
    ],
  },
  {
    word: "Unmerited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmerited <strong class='adj'> adj. </strong> Not merited.</p>",
      },
    ],
  },
  {
    word: "Unmet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmet <strong class='adj'> adj. </strong> (of a demand, goal, etc.) Not achieved or fulfilled.</p>",
      },
    ],
  },
  {
    word: "Unmethodical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmethodical <strong class='adj'> adj. </strong> Not methodical.</p>",
      },
    ],
  },
  {
    word: "Unmindful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmindful <strong class='adj'> adj. </strong> (often foll. By of) not mindful.</p>",
      },
    ],
  },
  {
    word: "Unmissable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmissable <strong class='adj'> adj. </strong> That cannot or should not be missed.</p>",
      },
    ],
  },
  {
    word: "Unmistakable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmistakable <strong class='adj'> adj. </strong> Clear, obvious, plain. unmistakably adv.</p>",
      },
    ],
  },
  {
    word: "Unmitigated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmitigated <strong class='adj'> adj. </strong> Not mitigated; absolute (unmitigated disaster).</p>",
      },
    ],
  },
  {
    word: "Unmixed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmixed <strong class='adj'> adj. </strong> Not mixed.</p>",
      },
    ],
  },
  {
    word: "Unmodified",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmodified <strong class='adj'> adj. </strong> Not modified.</p>",
      },
    ],
  },
  {
    word: "Unmoral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmoral <strong class='adj'> adj. </strong> Not concerned with morality (cf. *immoral). unmorality n.</p>",
      },
    ],
  },
  {
    word: "Unmoved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmoved <strong class='adj'> adj. </strong> 1 not moved. 2 constant in purpose. 3 unemotional.</p>",
      },
    ],
  },
  {
    word: "Unmusical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unmusical <strong class='adj'> adj. </strong> 1 discordant. 2 unskilled in or indifferent to music.</p>",
      },
    ],
  },
  {
    word: "Unnameable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unnameable <strong class='adj'> adj. </strong> Too bad to be named or mentioned.</p>",
      },
    ],
  },
  {
    word: "Unnamed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unnamed <strong class='adj'> adj. </strong> Not named.</p>",
      },
    ],
  },
  {
    word: "Unnatural",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unnatural <strong class='adj'> adj. </strong> 1 contrary to nature; not normal. 2 lacking natural feelings, esp. Cruel or wicked. 3 artificial. 4 affected. unnaturally adv.</p>",
      },
    ],
  },
  {
    word: "Unnecessary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unnecessary <strong class='adj'> adj. </strong> 1 not necessary. 2 superfluous. unnecessarily adv.</p>",
      },
    ],
  },
  {
    word: "Unneeded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unneeded <strong class='adj'> adj. </strong> Not needed.</p>",
      },
    ],
  },
  {
    word: "Unnerve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unnerve <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> deprive of confidence etc.</p>",
      },
    ],
  },
  {
    word: "Unnoticeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unnoticeable <strong class='adj'> adj. </strong> Not easily seen or noticed.</p>",
      },
    ],
  },
  {
    word: "Unnoticed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unnoticed <strong class='adj'> adj. </strong> Not noticed.</p>",
      },
    ],
  },
  {
    word: "Unnumbered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unnumbered <strong class='adj'> adj. </strong> 1 without a number. 2 not counted. 3 countless.</p>",
      },
    ],
  },
  {
    word: "Unobjectionable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unobjectionable <strong class='adj'> adj. </strong> Not objectionable; acceptable.</p>",
      },
    ],
  },
  {
    word: "Unobservant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unobservant <strong class='adj'> adj. </strong> Not observant.</p>",
      },
    ],
  },
  {
    word: "Unobserved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unobserved <strong class='adj'> adj. </strong> Not observed.</p>",
      },
    ],
  },
  {
    word: "Unobtainable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unobtainable <strong class='adj'> adj. </strong> That cannot be obtained.</p>",
      },
    ],
  },
  {
    word: "Unobtrusive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unobtrusive <strong class='adj'> adj. </strong> Not making oneself or itself noticed. unobtrusively adv.</p>",
      },
    ],
  },
  {
    word: "Unoccupied",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unoccupied <strong class='adj'> adj. </strong> Not occupied.</p>",
      },
    ],
  },
  {
    word: "Unofficial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unofficial <strong class='adj'> adj. </strong> Not officially authorized or confirmed. unofficially adv.</p>",
      },
    ],
  },
  {
    word: "Unofficial strike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unofficial strike <strong class='noun'> n. </strong> Strike not ratified by the strikers' trade union.</p>",
      },
    ],
  },
  {
    word: "Unopened",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unopened <strong class='adj'> adj. </strong> Not opened.</p>",
      },
    ],
  },
  {
    word: "Unopposed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unopposed <strong class='adj'> adj. </strong> Not opposed.</p>",
      },
    ],
  },
  {
    word: "Unorganized",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unorganized <strong class='adj'> adj. </strong> (also -ised) not organized.</p>",
      },
    ],
  },
  {
    word: "Unoriginal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unoriginal <strong class='adj'> adj. </strong> Lacking originality; derivative.</p>",
      },
    ],
  },
  {
    word: "Unorthodox",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unorthodox <strong class='adj'> adj. </strong> Not orthodox.</p>",
      },
    ],
  },
  {
    word: "Unpack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpack <strong class='verb'> v. </strong> 1 (also absol.) Open and empty (a package, luggage, etc.). 2 take (a thing) from a package etc.</p>",
      },
    ],
  },
  {
    word: "Unpaid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpaid <strong class='adj'> adj. </strong> (of a debt or a person) not paid.</p>",
      },
    ],
  },
  {
    word: "Unpainted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpainted <strong class='adj'> adj. </strong> Not painted.</p>",
      },
    ],
  },
  {
    word: "Unpaired",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpaired <strong class='adj'> adj. </strong> 1 not being one of a pair. 2 not united or arranged in pairs.</p>",
      },
    ],
  },
  {
    word: "Unpalatable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpalatable <strong class='adj'> adj. </strong> (of food, an idea, suggestion, etc.) Disagreeable, distasteful.</p>",
      },
    ],
  },
  {
    word: "Unparalleled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unparalleled <strong class='adj'> adj. </strong> Unequalled.</p>",
      },
    ],
  },
  {
    word: "Unpardonable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpardonable <strong class='adj'> adj. </strong> That cannot be pardoned. unpardonably adv.</p>",
      },
    ],
  },
  {
    word: "Unparliamentary language",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unparliamentary language <strong class='noun'> n. </strong> Oaths or abuse.</p>",
      },
    ],
  },
  {
    word: "Unparliamentary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unparliamentary <strong class='adj'> adj. </strong> Contrary to proper parliamentary usage.</p>",
      },
    ],
  },
  {
    word: "Unpasteurized",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpasteurized <strong class='adj'> adj. </strong> (also -ised) not pasteurized.</p>",
      },
    ],
  },
  {
    word: "Unpatriotic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpatriotic <strong class='adj'> adj. </strong> Not patriotic.</p>",
      },
    ],
  },
  {
    word: "Unperson",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unperson <strong class='noun'> n. </strong> Person said not to exist, esp. By the state.</p>",
      },
    ],
  },
  {
    word: "Unperturbed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unperturbed <strong class='adj'> adj. </strong> Not perturbed.</p>",
      },
    ],
  },
  {
    word: "Unpick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpick <strong class='verb'> v. </strong> Undo the sewing of (stitches, a garment, etc.).</p>",
      },
    ],
  },
  {
    word: "Unpin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpin <strong class='verb'> v. </strong> (-nn-) unfasten or detach by removing or opening a pin or pins.</p>",
      },
    ],
  },
  {
    word: "Unplaced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unplaced <strong class='adj'> adj. </strong> Not placed, esp. Not one of the first three in a race etc.</p>",
      },
    ],
  },
  {
    word: "Unplanned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unplanned <strong class='adj'> adj. </strong> Not planned.</p>",
      },
    ],
  },
  {
    word: "Unplayable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unplayable <strong class='adj'> adj. </strong> 1 sport (of a ball) too fast etc. To be returned. 2 that cannot be played.</p>",
      },
    ],
  },
  {
    word: "Unpleasant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpleasant <strong class='adj'> adj. </strong> Not pleasant, disagreeable. unpleasantly <strong class='adv'> adv. </strong> Unpleasantness n.</p>",
      },
    ],
  },
  {
    word: "Unpleasing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpleasing <strong class='adj'> adj. </strong> Not pleasing.</p>",
      },
    ],
  },
  {
    word: "Unplug",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unplug <strong class='verb'> v. </strong> <strong class='gg'> (-gg-) </strong> 1 disconnect (an electrical device) by removing its plug from the socket. 2 unstop.</p>",
      },
    ],
  },
  {
    word: "Unplumbed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unplumbed <strong class='adj'> adj. </strong> 1 not plumbed. 2 not fully explored or understood.</p>",
      },
    ],
  },
  {
    word: "Unpointed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpointed <strong class='adj'> adj. </strong> 1 having no point or points. 2 not punctuated. 3 (of brickwork etc.) Not pointed.</p>",
      },
    ],
  },
  {
    word: "Unpolished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpolished <strong class='adj'> adj. </strong> Not polished or refined; rough.</p>",
      },
    ],
  },
  {
    word: "Unpolitical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpolitical <strong class='adj'> adj. </strong> Not concerned with politics.</p>",
      },
    ],
  },
  {
    word: "Unpopular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpopular <strong class='adj'> adj. </strong> Not popular; disliked. unpopularity n.</p>",
      },
    ],
  },
  {
    word: "Unpopulated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpopulated <strong class='adj'> adj. </strong> Not populated.</p>",
      },
    ],
  },
  {
    word: "Unpractical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpractical <strong class='adj'> adj. </strong> 1 not practical. 2 (of a person) without practical skill.</p>",
      },
    ],
  },
  {
    word: "Unpracticed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpracticed <strong class='adj'> adj. </strong> (brit. Unpractised) 1 not experienced or skilled. 2 not put into practice.</p>",
      },
    ],
  },
  {
    word: "Unpractised",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpractised <strong class='adj'> adj. </strong> (us unpracticed) 1 not experienced or skilled. 2 not put into practice.</p>",
      },
    ],
  },
  {
    word: "Unprecedented",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprecedented <strong class='adj'> adj. </strong> Having no precedent; unparalleled. unprecedentedly adv.</p>",
      },
    ],
  },
  {
    word: "Unpredictable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpredictable <strong class='adj'> adj. </strong> That cannot be predicted. unpredictability <strong class='noun'> n. </strong> Unpredictably adv.</p>",
      },
    ],
  },
  {
    word: "Unprejudiced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprejudiced <strong class='adj'> adj. </strong> Not prejudiced.</p>",
      },
    ],
  },
  {
    word: "Unpremeditated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpremeditated <strong class='adj'> adj. </strong> Not deliberately planned, unintentional.</p>",
      },
    ],
  },
  {
    word: "Unprepared",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprepared <strong class='adj'> adj. </strong> Not prepared; not ready.</p>",
      },
    ],
  },
  {
    word: "Unprepossessing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprepossessing <strong class='adj'> adj. </strong> Unattractive.</p>",
      },
    ],
  },
  {
    word: "Unpretentious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpretentious <strong class='adj'> adj. </strong> Simple, modest, unassuming.</p>",
      },
    ],
  },
  {
    word: "Unpriced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpriced <strong class='adj'> adj. </strong> Not having a price fixed, marked, or stated.</p>",
      },
    ],
  },
  {
    word: "Unprincipled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprincipled <strong class='adj'> adj. </strong> Lacking or not based on moral principles.</p>",
      },
    ],
  },
  {
    word: "Unprintable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprintable <strong class='adj'> adj. </strong> Too offensive or indecent to be printed.</p>",
      },
    ],
  },
  {
    word: "Unproductive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unproductive <strong class='adj'> adj. </strong> Not productive.</p>",
      },
    ],
  },
  {
    word: "Unprofessional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprofessional <strong class='adj'> adj. </strong> 1 contrary to professional standards. 2 unskilled, amateurish. unprofessionally adv.</p>",
      },
    ],
  },
  {
    word: "Unprofitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprofitable <strong class='adj'> adj. </strong> Not profitable.</p>",
      },
    ],
  },
  {
    word: "Unprogressive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprogressive <strong class='adj'> adj. </strong> Not progressive, old-fashioned.</p>",
      },
    ],
  },
  {
    word: "Unpromising",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpromising <strong class='adj'> adj. </strong> Not likely to turn out well.</p>",
      },
    ],
  },
  {
    word: "Unprompted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprompted <strong class='adj'> adj. </strong> Spontaneous.</p>",
      },
    ],
  },
  {
    word: "Unpronounceable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpronounceable <strong class='adj'> adj. </strong> That cannot be pronounced.</p>",
      },
    ],
  },
  {
    word: "Unpropitious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpropitious <strong class='adj'> adj. </strong> Not propitious.</p>",
      },
    ],
  },
  {
    word: "Unprotected",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprotected <strong class='adj'> adj. </strong> Not protected.</p>",
      },
    ],
  },
  {
    word: "Unprovable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprovable <strong class='adj'> adj. </strong> That cannot be proved.</p>",
      },
    ],
  },
  {
    word: "Unproved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unproved <strong class='adj'> adj. </strong> (also unproven) not proved.</p>",
      },
    ],
  },
  {
    word: "Unprovoked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unprovoked <strong class='adj'> adj. </strong> Without provocation.</p>",
      },
    ],
  },
  {
    word: "Unpublished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpublished <strong class='adj'> adj. </strong> Not published.</p>",
      },
    ],
  },
  {
    word: "Unpunctual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpunctual <strong class='adj'> adj. </strong> Not punctual.</p>",
      },
    ],
  },
  {
    word: "Unpunished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unpunished <strong class='adj'> adj. </strong> Not punished.</p>",
      },
    ],
  },
  {
    word: "Unputdownable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unputdownable <strong class='adj'> adj. </strong> Colloq. (of a book) compulsively readable.</p>",
      },
    ],
  },
  {
    word: "Unqualified",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unqualified <strong class='adj'> adj. </strong> 1 not legally or officially qualified. 2 complete (unqualified success). 3 not competent (unqualified to say).</p>",
      },
    ],
  },
  {
    word: "Unquenchable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unquenchable <strong class='adj'> adj. </strong> That cannot be quenched.</p>",
      },
    ],
  },
  {
    word: "Unquestionable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unquestionable <strong class='adj'> adj. </strong> That cannot be disputed or doubted. unquestionably adv.</p>",
      },
    ],
  },
  {
    word: "Unquestioned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unquestioned <strong class='adj'> adj. </strong> Not disputed or doubted; definite, certain.</p>",
      },
    ],
  },
  {
    word: "Unquestioning",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unquestioning <strong class='adj'> adj. </strong> 1 asking no questions. 2 (of obedience etc.) Absolute. unquestioningly adv.</p>",
      },
    ],
  },
  {
    word: "Unquiet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unquiet <strong class='adj'> adj. </strong> 1 restless, agitated. 2 anxious.</p>",
      },
    ],
  },
  {
    word: "Unquote",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unquote <strong class='verb'> v. </strong> (as int.) Verbal formula indicating closing quotation marks.</p>",
      },
    ],
  },
  {
    word: "Unravel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unravel <strong class='verb'> v. </strong> (-ll-; us -l-) 1 make or become disentangled, unknitted, unknotted, etc. 2 probe and solve (a mystery etc.). 3 undo (esp. Knitted fabric).</p>",
      },
    ],
  },
  {
    word: "Unread",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unread <strong class='adj'> adj. </strong> 1 (of a book etc.) Not read. 2 (of a person) not well-read.</p>",
      },
    ],
  },
  {
    word: "Unreadable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreadable <strong class='adj'> adj. </strong> Too dull, bad, or difficult to read.</p>",
      },
    ],
  },
  {
    word: "Unready",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unready <strong class='adj'> adj. </strong> 1 not ready. 2 hesitant.</p>",
      },
    ],
  },
  {
    word: "Unreal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreal <strong class='adj'> adj. </strong> 1 not real. 2 imaginary. 3 slang incredible. unreality n.</p>",
      },
    ],
  },
  {
    word: "Unrealistic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrealistic <strong class='adj'> adj. </strong> Not realistic. unrealistically adv.</p>",
      },
    ],
  },
  {
    word: "Unrealizable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrealizable <strong class='adj'> adj. </strong> (also -isable) that cannot be realized.</p>",
      },
    ],
  },
  {
    word: "Unrealized",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrealized <strong class='adj'> adj. </strong> (also -ised) not realized.</p>",
      },
    ],
  },
  {
    word: "Unreason",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreason <strong class='noun'> n. </strong> Madness; chaos; disorder.</p>",
      },
    ],
  },
  {
    word: "Unreasonable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreasonable <strong class='adj'> adj. </strong> 1 excessive (unreasonable demands). 2 not heeding reason. unreasonably adv.</p>",
      },
    ],
  },
  {
    word: "Unreasoning",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreasoning <strong class='adj'> adj. </strong> Not reasoning.</p>",
      },
    ],
  },
  {
    word: "Unrecognizable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrecognizable <strong class='adj'> adj. </strong> (also -isable) that cannot be recognized.</p>",
      },
    ],
  },
  {
    word: "Unrecognized",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrecognized <strong class='adj'> adj. </strong> (also -ised) not acknowledged.</p>",
      },
    ],
  },
  {
    word: "Unrecorded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrecorded <strong class='adj'> adj. </strong> Not recorded.</p>",
      },
    ],
  },
  {
    word: "Unredeemed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unredeemed <strong class='adj'> adj. </strong> Not redeemed.</p>",
      },
    ],
  },
  {
    word: "Unreel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreel <strong class='verb'> v. </strong> Unwind from a reel.</p>",
      },
    ],
  },
  {
    word: "Unrefined",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrefined <strong class='adj'> adj. </strong> Not refined.</p>",
      },
    ],
  },
  {
    word: "Unreflecting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreflecting <strong class='adj'> adj. </strong> Not thoughtful.</p>",
      },
    ],
  },
  {
    word: "Unreformed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreformed <strong class='adj'> adj. </strong> Not reformed.</p>",
      },
    ],
  },
  {
    word: "Unregenerate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unregenerate <strong class='adj'> adj. </strong> Obstinately wrong or bad.</p>",
      },
    ],
  },
  {
    word: "Unregistered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unregistered <strong class='adj'> adj. </strong> Not registered.</p>",
      },
    ],
  },
  {
    word: "Unregulated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unregulated <strong class='adj'> adj. </strong> Not regulated.</p>",
      },
    ],
  },
  {
    word: "Unrehearsed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrehearsed <strong class='adj'> adj. </strong> Not rehearsed.</p>",
      },
    ],
  },
  {
    word: "Unrelated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrelated <strong class='adj'> adj. </strong> Not related.</p>",
      },
    ],
  },
  {
    word: "Unrelenting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrelenting <strong class='adj'> adj. </strong> Not abating, yielding, or relaxing; unmerciful. unrelentingly adv.</p>",
      },
    ],
  },
  {
    word: "Unreliable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreliable <strong class='adj'> adj. </strong> Not reliable; erratic. unreliability n.</p>",
      },
    ],
  },
  {
    word: "Unrelieved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrelieved <strong class='adj'> adj. </strong> Not relieved; monotonously uniform.</p>",
      },
    ],
  },
  {
    word: "Unremarkable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unremarkable <strong class='adj'> adj. </strong> Not remarkable; uninteresting, ordinary.</p>",
      },
    ],
  },
  {
    word: "Unremarked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unremarked <strong class='adj'> adj. </strong> Not mentioned or remarked upon.</p>",
      },
    ],
  },
  {
    word: "Unremitting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unremitting <strong class='adj'> adj. </strong> Incessant. unremittingly adv.</p>",
      },
    ],
  },
  {
    word: "Unremunerative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unremunerative <strong class='adj'> adj. </strong> Not, or not very, profitable.</p>",
      },
    ],
  },
  {
    word: "Unrepeatable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrepeatable <strong class='adj'> adj. </strong> 1 that cannot be done, made, or said again. 2 too indecent to repeat.</p>",
      },
    ],
  },
  {
    word: "Unrepentant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrepentant <strong class='adj'> adj. </strong> Not repentant, impenitent. unrepentantly adv.</p>",
      },
    ],
  },
  {
    word: "Unrepresentative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrepresentative <strong class='adj'> adj. </strong> Not representative.</p>",
      },
    ],
  },
  {
    word: "Unrepresented",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrepresented <strong class='adj'> adj. </strong> Not represented.</p>",
      },
    ],
  },
  {
    word: "Unrequited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrequited <strong class='adj'> adj. </strong> (of love etc.) Not returned.</p>",
      },
    ],
  },
  {
    word: "Unreserved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unreserved <strong class='adj'> adj. </strong> 1 not reserved. 2 total; without reservation. unreservedly adv.</p>",
      },
    ],
  },
  {
    word: "Unresisting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unresisting <strong class='adj'> adj. </strong> Not resisting.</p>",
      },
    ],
  },
  {
    word: "Unresolved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unresolved <strong class='adj'> adj. </strong> 1 irresolute, undecided. 2 (of questions etc.) Undetermined.</p>",
      },
    ],
  },
  {
    word: "Unresponsive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unresponsive <strong class='adj'> adj. </strong> Not responsive.</p>",
      },
    ],
  },
  {
    word: "Unrest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrest <strong class='noun'> n. </strong> Disturbed or dissatisfied state (industrial unrest).</p>",
      },
    ],
  },
  {
    word: "Unrestrained",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrestrained <strong class='adj'> adj. </strong> Not restrained.</p>",
      },
    ],
  },
  {
    word: "Unrestricted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrestricted <strong class='adj'> adj. </strong> Not restricted.</p>",
      },
    ],
  },
  {
    word: "Unrewarded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrewarded <strong class='adj'> adj. </strong> Not rewarded.</p>",
      },
    ],
  },
  {
    word: "Unrewarding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrewarding <strong class='adj'> adj. </strong> Not rewarding or satisfying.</p>",
      },
    ],
  },
  {
    word: "Unrighteous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrighteous <strong class='adj'> adj. </strong> Wicked.</p>",
      },
    ],
  },
  {
    word: "Unripe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Unripe <strong class='adj'> adj. </strong> Not ripe.</p>",
      },
    ],
  },
  {
    word: "Unrivaled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrivaled <strong class='adj'> adj. </strong> (brit. Unrivalled) having no equal.</p>",
      },
    ],
  },
  {
    word: "Unrivalled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unrivalled <strong class='adj'> adj. </strong> (us unrivaled) having no equal.</p>",
      },
    ],
  },
  {
    word: "Unroll",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unroll <strong class='verb'> v. </strong> 1 open out from a rolled-up state. 2 display or be displayed like this.</p>",
      },
    ],
  },
  {
    word: "Unromantic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unromantic <strong class='adj'> adj. </strong> Not romantic.</p>",
      },
    ],
  },
  {
    word: "Unruffled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Unruffled <strong class='adj'> adj. </strong> Calm.</p>",
      },
    ],
  },
  {
    word: "Unruly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unruly <strong class='adj'> adj. </strong> (-ier, -iest) undisciplined, disorderly. unruliness <strong class='noun'> n. </strong> [related to *rule]</p>",
      },
    ],
  },
  {
    word: "Unsaddle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsaddle <strong class='verb'> v. </strong> (-ling) 1 remove the saddle from. 2 unhorse.</p>",
      },
    ],
  },
  {
    word: "Unsafe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Unsafe <strong class='adj'> adj. </strong> Not safe.</p>",
      },
    ],
  },
  {
    word: "Unsaid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsaid <strong class='adj'> adj. </strong> Not uttered or expressed (left it unsaid).</p>",
      },
    ],
  },
  {
    word: "Unsaleable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsaleable <strong class='adj'> adj. </strong> Not saleable.</p>",
      },
    ],
  },
  {
    word: "Unsalted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsalted <strong class='adj'> adj. </strong> Not salted.</p>",
      },
    ],
  },
  {
    word: "Unsatisfactory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsatisfactory <strong class='adj'> adj. </strong> Poor, unacceptable.</p>",
      },
    ],
  },
  {
    word: "Unsatisfied",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsatisfied <strong class='adj'> adj. </strong> Not satisfied.</p>",
      },
    ],
  },
  {
    word: "Unsatisfying",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsatisfying <strong class='adj'> adj. </strong> Not satisfying.</p>",
      },
    ],
  },
  {
    word: "Unsaturated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsaturated <strong class='adj'> adj. </strong> Chem. (of esp. A fat or oil) having double or triple bonds in its molecule and therefore capable of further reaction.</p>",
      },
    ],
  },
  {
    word: "Unsavory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsavory <strong class='adj'> adj. </strong> (brit. Unsavoury) 1 disgusting, unpleasant. 2 morally offensive.</p>",
      },
    ],
  },
  {
    word: "Unsavoury",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsavoury <strong class='adj'> adj. </strong> (us unsavory) 1 disgusting, unpleasant. 2 morally offensive.</p>",
      },
    ],
  },
  {
    word: "Unsay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsay <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Unsaid) retract (a statement).</p>",
      },
    ],
  },
  {
    word: "Unscalable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscalable <strong class='adj'> adj. </strong> That cannot be scaled.</p>",
      },
    ],
  },
  {
    word: "Unscarred",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscarred <strong class='adj'> adj. </strong> Not scarred or damaged.</p>",
      },
    ],
  },
  {
    word: "Unscathed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscathed <strong class='adj'> adj. </strong> Without injury.</p>",
      },
    ],
  },
  {
    word: "Unscheduled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscheduled <strong class='adj'> adj. </strong> Not scheduled.</p>",
      },
    ],
  },
  {
    word: "Unschooled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unschooled <strong class='adj'> adj. </strong> Uneducated, untrained.</p>",
      },
    ],
  },
  {
    word: "Unscientific",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscientific <strong class='adj'> adj. </strong> Not scientific in method etc. unscientifically adv.</p>",
      },
    ],
  },
  {
    word: "Unscramble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscramble <strong class='verb'> v. </strong> (-ling) make plain, decode, interpret (a scrambled transmission etc.).</p>",
      },
    ],
  },
  {
    word: "Unscreened",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscreened <strong class='adj'> adj. </strong> 1 a (esp. Of coal) not passed through a screen or sieve. B not checked, esp. For security or medical problems. 2 not having a screen. 3 not shown on a screen.</p>",
      },
    ],
  },
  {
    word: "Unscrew",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscrew <strong class='verb'> v. </strong> 1 unfasten by removing a screw or screws. 2 loosen (a screw or screw-top).</p>",
      },
    ],
  },
  {
    word: "Unscripted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscripted <strong class='adj'> adj. </strong> (of a speech etc.) Delivered impromptu.</p>",
      },
    ],
  },
  {
    word: "Unscrupulous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unscrupulous <strong class='adj'> adj. </strong> Having no scruples, unprincipled. unscrupulously <strong class='adv'> adv. </strong> Unscrupulousness n.</p>",
      },
    ],
  },
  {
    word: "Unseal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unseal <strong class='verb'> v. </strong> Break the seal of; open (a letter, receptacle, etc.).</p>",
      },
    ],
  },
  {
    word: "Unseasonable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unseasonable <strong class='adj'> adj. </strong> 1 not seasonable. 2 untimely, inopportune. unseasonably adv.</p>",
      },
    ],
  },
  {
    word: "Unseasonal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unseasonal <strong class='adj'> adj. </strong> Not typical of, or appropriate to, the time or season. unseasonally adv.</p>",
      },
    ],
  },
  {
    word: "Unseat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unseat <strong class='verb'> v. </strong> 1 remove from (esp. A parliamentary) seat. 2 dislodge from a seat, esp. On horseback.</p>",
      },
    ],
  },
  {
    word: "Unseeded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unseeded <strong class='adj'> adj. </strong> Sport (of a player) not seeded.</p>",
      },
    ],
  },
  {
    word: "Unseeing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unseeing <strong class='adj'> adj. </strong> 1 unobservant. 2 blind. unseeingly adv.</p>",
      },
    ],
  },
  {
    word: "Unseemly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unseemly <strong class='adj'> adj. </strong> (-ier, -iest) 1 indecent. 2 unbecoming. unseemliness n.</p>",
      },
    ],
  },
  {
    word: "Unseen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unseen <strong class='adj'> —adj.</strong> 1 not seen. 2 invisible. 3 (of a translation) to be done without preparation. <strong class='noun'> —n. </strong> Unseen translation.</p>",
      },
    ],
  },
  {
    word: "Unselfconscious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unselfconscious <strong class='adj'> adj. </strong> Not self-conscious. unselfconsciously <strong class='adv'> adv. </strong> Unselfconsciousness n.</p>",
      },
    ],
  },
  {
    word: "Unselfish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unselfish <strong class='adj'> adj. </strong> Concerned about others; sharing. unselfishly <strong class='adv'> adv. </strong> Unselfishness n.</p>",
      },
    ],
  },
  {
    word: "Unsentimental",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsentimental <strong class='adj'> adj. </strong> Not sentimental.</p>",
      },
    ],
  },
  {
    word: "Unsettle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsettle <strong class='verb'> v. </strong> (-ling) 1 disturb; discompose. 2 derange.</p>",
      },
    ],
  },
  {
    word: "Unsettled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsettled <strong class='adj'> adj. </strong> 1 restless, disturbed; unpredictable, changeable. 2 open to change or further discussion. 3 (of a bill etc.) Unpaid.</p>",
      },
    ],
  },
  {
    word: "Unsex",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsex <strong class='verb'> v. </strong> Deprive (a person, esp. A woman) of the qualities of her or his sex.</p>",
      },
    ],
  },
  {
    word: "Unshackle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unshackle <strong class='verb'> v. </strong> (-ling) 1 release from shackles. 2 set free.</p>",
      },
    ],
  },
  {
    word: "Unshakeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unshakeable <strong class='adj'> adj. </strong> Firm; obstinate. unshakeably adv.</p>",
      },
    ],
  },
  {
    word: "Unshaken",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unshaken <strong class='adj'> adj. </strong> Not shaken.</p>",
      },
    ],
  },
  {
    word: "Unshaven",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unshaven <strong class='adj'> adj. </strong> Not shaved.</p>",
      },
    ],
  },
  {
    word: "Unsheathe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsheathe <strong class='verb'> v. </strong> (-thing) remove (a knife etc.) From a sheath.</p>",
      },
    ],
  },
  {
    word: "Unshockable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unshockable <strong class='adj'> adj. </strong> Unable to be shocked.</p>",
      },
    ],
  },
  {
    word: "Unshrinking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unshrinking <strong class='adj'> adj. </strong> Unhesitating, fearless.</p>",
      },
    ],
  },
  {
    word: "Unsighted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsighted <strong class='adj'> adj. </strong> 1 not sighted or seen. 2 prevented from seeing.</p>",
      },
    ],
  },
  {
    word: "Unsightly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsightly <strong class='adj'> adj. </strong> Ugly. unsightliness n.</p>",
      },
    ],
  },
  {
    word: "Unsigned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsigned <strong class='adj'> adj. </strong> Not signed.</p>",
      },
    ],
  },
  {
    word: "Unsinkable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsinkable <strong class='adj'> adj. </strong> Unable to be sunk.</p>",
      },
    ],
  },
  {
    word: "Unskilful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unskilful <strong class='adj'> adj. </strong> (us unskillful) not skilful.</p>",
      },
    ],
  },
  {
    word: "Unskilled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unskilled <strong class='adj'> adj. </strong> Lacking, or (of work) not needing, special skill or training.</p>",
      },
    ],
  },
  {
    word: "Unskillful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unskillful <strong class='adj'> adj. </strong> (brit. Unskilful) not skilful.</p>",
      },
    ],
  },
  {
    word: "Unsliced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsliced <strong class='adj'> adj. </strong> (esp. Of a loaf of bread) not sliced.</p>",
      },
    ],
  },
  {
    word: "Unsmiling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsmiling <strong class='adj'> adj. </strong> Not smiling.</p>",
      },
    ],
  },
  {
    word: "Unsmoked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsmoked <strong class='adj'> adj. </strong> Not cured by smoking (unsmoked bacon).</p>",
      },
    ],
  },
  {
    word: "Unsociable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsociable <strong class='adj'> adj. </strong> Not sociable, disliking company. <strong class='usage'> Usage:- </strong>see note at unsocial.</p>",
      },
    ],
  },
  {
    word: "Unsocial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsocial <strong class='adj'> adj. </strong> 1 not social; not suitable for or seeking society. 2 outside the normal working day (unsocial hours). 3 antisocial. <strong class='usage'> Usage:- </strong>unsocial is sometimes confused with unsociable.</p>",
      },
    ],
  },
  {
    word: "Unsoiled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsoiled <strong class='adj'> adj. </strong> Not soiled or dirtied.</p>",
      },
    ],
  },
  {
    word: "Unsold",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Unsold <strong class='adj'> adj. </strong> Not sold.</p>",
      },
    ],
  },
  {
    word: "Unsolicited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsolicited <strong class='adj'> adj. </strong> Not asked for; voluntary.</p>",
      },
    ],
  },
  {
    word: "Unsolved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsolved <strong class='adj'> adj. </strong> Not solved.</p>",
      },
    ],
  },
  {
    word: "Unsophisticated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsophisticated <strong class='adj'> adj. </strong> Artless, simple, natural.</p>",
      },
    ],
  },
  {
    word: "Unsorted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsorted <strong class='adj'> adj. </strong> Not sorted.</p>",
      },
    ],
  },
  {
    word: "Unsought",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsought <strong class='adj'> adj. </strong> 1 not sought for. 2 without being requested.</p>",
      },
    ],
  },
  {
    word: "Unsound",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsound <strong class='adj'> adj. </strong> 1 unhealthy, not sound. 2 rotten, weak; unreliable. 3 ill-founded. of unsound mind insane. unsoundness n.</p>",
      },
    ],
  },
  {
    word: "Unsparing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsparing <strong class='adj'> adj. </strong> 1 lavish. 2 merciless.</p>",
      },
    ],
  },
  {
    word: "Unspeakable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unspeakable <strong class='adj'> adj. </strong> 1 that cannot be expressed in words. 2 indescribably bad. unspeakably adv.</p>",
      },
    ],
  },
  {
    word: "Unspecific",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unspecific <strong class='adj'> adj. </strong> Not specific; general, inexact.</p>",
      },
    ],
  },
  {
    word: "Unspecified",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unspecified <strong class='adj'> adj. </strong> Not specified.</p>",
      },
    ],
  },
  {
    word: "Unspectacular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unspectacular <strong class='adj'> adj. </strong> Not spectacular; dull.</p>",
      },
    ],
  },
  {
    word: "Unspoiled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unspoiled <strong class='adj'> adj. </strong> (also unspoilt) not spoilt.</p>",
      },
    ],
  },
  {
    word: "Unspoken",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unspoken <strong class='adj'> adj. </strong> 1 not expressed in speech. 2 not uttered as speech.</p>",
      },
    ],
  },
  {
    word: "Unsporting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsporting <strong class='adj'> adj. </strong> Not fair or generous.</p>",
      },
    ],
  },
  {
    word: "Unsportsmanlike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsportsmanlike <strong class='adj'> adj. </strong> Unsporting.</p>",
      },
    ],
  },
  {
    word: "Unstable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstable <strong class='adj'> adj. </strong> (unstabler, unstablest) 1 not stable; likely to fall. 2 not stable emotionally. 3 changeable. unstably adv.</p>",
      },
    ],
  },
  {
    word: "Unstained",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstained <strong class='adj'> adj. </strong> Not stained.</p>",
      },
    ],
  },
  {
    word: "Unstated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstated <strong class='adj'> adj. </strong> Not stated or declared.</p>",
      },
    ],
  },
  {
    word: "Unsteady",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsteady <strong class='adj'> adj. </strong> (-ier, -iest) 1 not steady or firm. 2 changeable. 3 not uniform or regular. unsteadily <strong class='adv'> adv. </strong> Unsteadiness n.</p>",
      },
    ],
  },
  {
    word: "Unstick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstick <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Unstuck) separate (a thing stuck to another). come unstuck <strong class='colloq'> colloq. </strong> Come to grief, fail.</p>",
      },
    ],
  },
  {
    word: "Unstinted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstinted <strong class='adj'> adj. </strong> Not stinted.</p>",
      },
    ],
  },
  {
    word: "Unstinting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstinting <strong class='adj'> adj. </strong> Lavish; limitless. unstintingly adv.</p>",
      },
    ],
  },
  {
    word: "Unstitch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstitch <strong class='verb'> v. </strong> Undo the stitches of.</p>",
      },
    ],
  },
  {
    word: "Unstop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstop <strong class='verb'> v. </strong> (-pp-) 1 unblock. 2 remove the stopper from.</p>",
      },
    ],
  },
  {
    word: "Unstoppable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstoppable <strong class='adj'> adj. </strong> That cannot be stopped or prevented.</p>",
      },
    ],
  },
  {
    word: "Unstrap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstrap <strong class='verb'> v. </strong> (-pp-) undo the strap(s) of.</p>",
      },
    ],
  },
  {
    word: "Unstressed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstressed <strong class='adj'> adj. </strong> Not pronounced with stress.</p>",
      },
    ],
  },
  {
    word: "Unstring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstring <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Unstrung) 1 remove or relax the string(s) of (a bow, harp, etc.). 2 remove (beads etc.) From a string. 3 (esp. As unstrung adj.) Unnerve.</p>",
      },
    ],
  },
  {
    word: "Unstructured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstructured <strong class='adj'> adj. </strong> 1 not structured. 2 informal.</p>",
      },
    ],
  },
  {
    word: "Unstuck  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstuck  <strong class='see'>  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>unstick </strong> .</p>",
      },
    ],
  },
  {
    word: "Unstudied",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unstudied <strong class='adj'> adj. </strong> Easy, natural, spontaneous.</p>",
      },
    ],
  },
  {
    word: "Unsubstantial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsubstantial <strong class='adj'> adj. </strong> = *insubstantial.</p>",
      },
    ],
  },
  {
    word: "Unsubstantiated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsubstantiated <strong class='adj'> adj. </strong> Not substantiated.</p>",
      },
    ],
  },
  {
    word: "Unsubtle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsubtle <strong class='adj'> adj. </strong> Not subtle; obvious; clumsy.</p>",
      },
    ],
  },
  {
    word: "Unsuccessful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsuccessful <strong class='adj'> adj. </strong> Not successful. unsuccessfully adv.</p>",
      },
    ],
  },
  {
    word: "Unsuitable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsuitable <strong class='adj'> adj. </strong> Not suitable. unsuitability <strong class='noun'> n. </strong> Unsuitably adv.</p>",
      },
    ],
  },
  {
    word: "Unsuited",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsuited <strong class='adj'> adj. </strong> 1 (usu. Foll. By for) not fit. 2 (usu. Foll. By to) not adapted.</p>",
      },
    ],
  },
  {
    word: "Unsullied",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsullied <strong class='adj'> adj. </strong> Not sullied.</p>",
      },
    ],
  },
  {
    word: "Unsung",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsung <strong class='adj'> adj. </strong> Not celebrated, unrecognized (unsung heroes).</p>",
      },
    ],
  },
  {
    word: "Unsupervised",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsupervised <strong class='adj'> adj. </strong> Not supervised.</p>",
      },
    ],
  },
  {
    word: "Unsupported",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsupported <strong class='adj'> adj. </strong> Not supported.</p>",
      },
    ],
  },
  {
    word: "Unsure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Unsure <strong class='adj'> adj. </strong> Not sure.</p>",
      },
    ],
  },
  {
    word: "Unsurpassed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsurpassed <strong class='adj'> adj. </strong> Not surpassed.</p>",
      },
    ],
  },
  {
    word: "Unsurprised",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsurprised <strong class='adj'> adj. </strong> Not surprised.</p>",
      },
    ],
  },
  {
    word: "Unsurprising",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsurprising <strong class='adj'> adj. </strong> Not surprising. unsurprisingly adv.</p>",
      },
    ],
  },
  {
    word: "Unsuspecting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsuspecting <strong class='adj'> adj. </strong> Not suspecting. unsuspected adj.</p>",
      },
    ],
  },
  {
    word: "Unsustainable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsustainable <strong class='adj'> adj. </strong> That cannot be sustained.</p>",
      },
    ],
  },
  {
    word: "Unsweetened",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsweetened <strong class='adj'> adj. </strong> Not sweetened.</p>",
      },
    ],
  },
  {
    word: "Unswept",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unswept <strong class='adj'> adj. </strong> Not swept.</p>",
      },
    ],
  },
  {
    word: "Unswerving",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unswerving <strong class='adj'> adj. </strong> Steady, constant. unswervingly adv.</p>",
      },
    ],
  },
  {
    word: "Unsymmetrical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsymmetrical <strong class='adj'> adj. </strong> Not symmetrical.</p>",
      },
    ],
  },
  {
    word: "Unsympathetic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsympathetic <strong class='adj'> adj. </strong> Not sympathetic. unsympathetically adv.</p>",
      },
    ],
  },
  {
    word: "Unsystematic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unsystematic <strong class='adj'> adj. </strong> Not systematic. unsystematically adv.</p>",
      },
    ],
  },
  {
    word: "Untainted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untainted <strong class='adj'> adj. </strong> Not tainted.</p>",
      },
    ],
  },
  {
    word: "Untalented",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untalented <strong class='adj'> adj. </strong> Not talented.</p>",
      },
    ],
  },
  {
    word: "Untameable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untameable <strong class='adj'> adj. </strong> That cannot be tamed.</p>",
      },
    ],
  },
  {
    word: "Untamed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untamed <strong class='adj'> adj. </strong> Not tamed, wild.</p>",
      },
    ],
  },
  {
    word: "Untangle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untangle <strong class='verb'> v. </strong> (-ling) disentangle.</p>",
      },
    ],
  },
  {
    word: "Untapped",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untapped <strong class='adj'> adj. </strong> Not (yet) tapped or used (untapped resources).</p>",
      },
    ],
  },
  {
    word: "Untarnished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untarnished <strong class='adj'> adj. </strong> Not tarnished.</p>",
      },
    ],
  },
  {
    word: "Untaught",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untaught <strong class='adj'> adj. </strong> (of a person, knowledge, etc.) Not taught.</p>",
      },
    ],
  },
  {
    word: "Untaxed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untaxed <strong class='adj'> adj. </strong> (of a person, commodity, etc.) Not taxed.</p>",
      },
    ],
  },
  {
    word: "Unteachable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unteachable <strong class='adj'> adj. </strong> (of a person, subject, etc.) Incapable of being taught.</p>",
      },
    ],
  },
  {
    word: "Untenable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untenable <strong class='adj'> adj. </strong> (of a theory etc.) Not tenable.</p>",
      },
    ],
  },
  {
    word: "Untested",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untested <strong class='adj'> adj. </strong> Not tested or proved.</p>",
      },
    ],
  },
  {
    word: "Untether",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untether <strong class='verb'> v. </strong> Release (an animal) from a tether.</p>",
      },
    ],
  },
  {
    word: "Unthinkable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unthinkable <strong class='adj'> adj. </strong> 1 unimaginable, inconceivable. 2 <strong class='colloq'> colloq. </strong> Highly unlikely or undesirable. unthinkably adv.</p>",
      },
    ],
  },
  {
    word: "Unthinking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unthinking <strong class='adj'> adj. </strong> 1 thoughtless. 2 unintentional, inadvertent. unthinkingly adv.</p>",
      },
    ],
  },
  {
    word: "Unthread",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unthread <strong class='verb'> v. </strong> Take the thread out of (a needle etc.).</p>",
      },
    ],
  },
  {
    word: "Unthrone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unthrone <strong class='verb'> v. </strong> (-ning) dethrone.</p>",
      },
    ],
  },
  {
    word: "Untidy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untidy <strong class='adj'> adj. </strong> (-ier, -iest) not neat or orderly. untidily <strong class='adv'> adv. </strong> Untidiness n.</p>",
      },
    ],
  },
  {
    word: "Untie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untie <strong class='verb'> v. </strong> (untying) 1 undo (a knot, package, etc.). 2 release from bonds or attachment.</p>",
      },
    ],
  },
  {
    word: "Until",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Until <strong class='n_pl'> comb. </strong> & <strong class='conj'> conj. </strong> = *till1. [earlier untill: un from old norse und as far as] <strong class='usage'> Usage:- </strong>until, as opposed to till, is used esp. At the beginning of a sentence and in formal style, e.g. Until you told me, i had no idea; he resided there until his decease.</p>",
      },
    ],
  },
  {
    word: "Untimely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untimely <strong class='adj'> adj. </strong> 1 inopportune. 2 (of death) premature. untimeliness n.</p>",
      },
    ],
  },
  {
    word: "Untiring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untiring <strong class='adj'> adj. </strong> Tireless. untiringly adv.</p>",
      },
    ],
  },
  {
    word: "Untitled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untitled <strong class='adj'> adj. </strong> Having no title.</p>",
      },
    ],
  },
  {
    word: "Unto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unto <strong class='n_pl'> comb. </strong> Archaic = *to (in all uses except signalling the infinitive). [from *until, with to replacing til]</p>",
      },
    ],
  },
  {
    word: "Untold",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untold <strong class='adj'> adj. </strong> 1 not told. 2 immeasurable (untold misery).</p>",
      },
    ],
  },
  {
    word: "Untouchable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untouchable <strong class='adj'> —adj.</strong> That may not be touched. <strong class='noun'> —n. </strong> Member of a hereditary hindu group held to defile members of higher castes on contact. untouchability n. <strong class='usage'> Usage:- </strong>the use of this term, and social restrictions accompanying it, were declared illegal under the indian constitution in 1949.</p>",
      },
    ],
  },
  {
    word: "Untouched",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untouched <strong class='adj'> adj. </strong> 1 not touched. 2 not affected physically, emotionally, etc. 3 not discussed.</p>",
      },
    ],
  },
  {
    word: "Untoward",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untoward <strong class='adj'> adj. </strong> 1 inconvenient, unlucky. 2 awkward. 3 perverse, refractory. 4 unseemly.</p>",
      },
    ],
  },
  {
    word: "Untraceable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untraceable <strong class='adj'> adj. </strong> That cannot be traced.</p>",
      },
    ],
  },
  {
    word: "Untrained",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untrained <strong class='adj'> adj. </strong> Not trained.</p>",
      },
    ],
  },
  {
    word: "Untrammelled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untrammelled <strong class='adj'> adj. </strong> Not trammelled, unhampered.</p>",
      },
    ],
  },
  {
    word: "Untranslatable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untranslatable <strong class='adj'> adj. </strong> That cannot be translated satisfactorily.</p>",
      },
    ],
  },
  {
    word: "Untreated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untreated <strong class='adj'> adj. </strong> Not treated.</p>",
      },
    ],
  },
  {
    word: "Untried",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untried <strong class='adj'> adj. </strong> 1 not tried or tested. 2 inexperienced.</p>",
      },
    ],
  },
  {
    word: "Untroubled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untroubled <strong class='adj'> adj. </strong> Calm, tranquil.</p>",
      },
    ],
  },
  {
    word: "Untrue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untrue <strong class='adj'> adj. </strong> 1 not true. 2 <strong class='adj'> (often foll. By to) </strong> not faithful or loyal. 3 deviating from an accepted standard.</p>",
      },
    ],
  },
  {
    word: "Untrustworthy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untrustworthy <strong class='adj'> adj. </strong> Not trustworthy. untrustworthiness n.</p>",
      },
    ],
  },
  {
    word: "Untruth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untruth <strong class='noun'> n. </strong> 1 being untrue. 2 lie.</p>",
      },
    ],
  },
  {
    word: "Untruthful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untruthful <strong class='adj'> adj. </strong> Not truthful. untruthfully adv.</p>",
      },
    ],
  },
  {
    word: "Untuck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untuck <strong class='verb'> v. </strong> Free (bedclothes etc.) From being tucked in or up.</p>",
      },
    ],
  },
  {
    word: "Unturned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unturned <strong class='adj'> adj. </strong> 1 not turned over, round, away, etc. 2 not shaped by turning.</p>",
      },
    ],
  },
  {
    word: "Untutored",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untutored <strong class='adj'> adj. </strong> Uneducated, untaught.</p>",
      },
    ],
  },
  {
    word: "Untwine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untwine <strong class='verb'> v. </strong> (-ning) untwist, unwind.</p>",
      },
    ],
  },
  {
    word: "Untwist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Untwist <strong class='verb'> v. </strong> Open from a twisted or spiralled state.</p>",
      },
    ],
  },
  {
    word: "Unusable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unusable <strong class='adj'> adj. </strong> Not usable.</p>",
      },
    ],
  },
  {
    word: "Unused",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unused <strong class='adj'> adj. </strong> 1 a not in use. B never having been used. 2 (foll. By to) not accustomed.</p>",
      },
    ],
  },
  {
    word: "Unusual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unusual <strong class='adj'> adj. </strong> 1 not usual. 2 remarkable. unusually adv.</p>",
      },
    ],
  },
  {
    word: "Unutterable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unutterable <strong class='adj'> adj. </strong> Inexpressible; beyond description. unutterably adv.</p>",
      },
    ],
  },
  {
    word: "Unvarnished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unvarnished <strong class='adj'> adj. </strong> 1 not varnished. 2 plain and straightforward (the unvarnished truth).</p>",
      },
    ],
  },
  {
    word: "Unvarying",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unvarying <strong class='adj'> adj. </strong> Not varying.</p>",
      },
    ],
  },
  {
    word: "Unveil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unveil <strong class='verb'> v. </strong> 1 uncover (a statue etc.) Ceremonially. 2 reveal. 3 remove a veil from; remove one's veil.</p>",
      },
    ],
  },
  {
    word: "Unverified",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unverified <strong class='adj'> adj. </strong> Not verified.</p>",
      },
    ],
  },
  {
    word: "Unversed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unversed <strong class='adj'> adj. </strong> (usu. Foll. By in) not experienced or skilled.</p>",
      },
    ],
  },
  {
    word: "Unviable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unviable <strong class='adj'> adj. </strong> Not viable.</p>",
      },
    ],
  },
  {
    word: "Unvoiced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unvoiced <strong class='adj'> adj. </strong> 1 not spoken. 2 (of a consonant etc.) Not voiced.</p>",
      },
    ],
  },
  {
    word: "Unwaged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwaged <strong class='adj'> adj. </strong> Not receiving a wage; unemployed.</p>",
      },
    ],
  },
  {
    word: "Unwanted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwanted <strong class='adj'> adj. </strong> Not wanted.</p>",
      },
    ],
  },
  {
    word: "Unwarrantable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwarrantable <strong class='adj'> adj. </strong> Unjustifiable. unwarrantably adv.</p>",
      },
    ],
  },
  {
    word: "Unwarranted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwarranted <strong class='adj'> adj. </strong> 1 unauthorized. 2 unjustified.</p>",
      },
    ],
  },
  {
    word: "Unwary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwary <strong class='adj'> adj. </strong> (often foll. By of) not cautious. unwarily <strong class='adv'> adv. </strong> Unwariness n.</p>",
      },
    ],
  },
  {
    word: "Unwashed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwashed <strong class='adj'> adj. </strong> Not washed or clean. the great unwashed <strong class='colloq'> colloq. </strong> The rabble.</p>",
      },
    ],
  },
  {
    word: "Unwavering",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwavering <strong class='adj'> adj. </strong> Not wavering. unwaveringly adv.</p>",
      },
    ],
  },
  {
    word: "Unweaned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unweaned <strong class='adj'> adj. </strong> Not yet weaned.</p>",
      },
    ],
  },
  {
    word: "Unwearying",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwearying <strong class='adj'> adj. </strong> Persistent.</p>",
      },
    ],
  },
  {
    word: "Unwelcome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwelcome <strong class='adj'> adj. </strong> Not welcome or acceptable.</p>",
      },
    ],
  },
  {
    word: "Unwell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Unwell <strong class='adj'> adj. </strong> Ill.</p>",
      },
    ],
  },
  {
    word: "Unwholesome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwholesome <strong class='adj'> adj. </strong> 1 detrimental to physical or moral health. 2 unhealthy-looking.</p>",
      },
    ],
  },
  {
    word: "Unwieldy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwieldy <strong class='adj'> adj. </strong> (-ier, -iest) cumbersome or hard to manage, owing to size, shape, etc. unwieldily <strong class='adv'> adv. </strong> Unwieldiness <strong class='noun'> n. </strong> [wieldy active, from *wield]</p>",
      },
    ],
  },
  {
    word: "Unwilling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwilling <strong class='adj'> adj. </strong> Not willing or inclined; reluctant. unwillingly <strong class='adv'> adv. </strong> Unwillingness n.</p>",
      },
    ],
  },
  {
    word: "Unwind",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwind <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Unwound) 1 draw out or become drawn out after having been wound. 2 <strong class='colloq'> colloq. </strong> Relax.</p>",
      },
    ],
  },
  {
    word: "Unwinking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwinking <strong class='adj'> adj. </strong> 1 not winking. 2 vigilant.</p>",
      },
    ],
  },
  {
    word: "Unwise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwise <strong class='adj'> adj. </strong> Foolish, imprudent. unwisely adv.</p>",
      },
    ],
  },
  {
    word: "Unwished",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwished <strong class='adj'> adj. </strong> (usu. Foll. By for) not wished for.</p>",
      },
    ],
  },
  {
    word: "Unwitting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwitting <strong class='adj'> adj. </strong> 1 not knowing or aware (an unwitting offender). 2 unintentional. unwittingly <strong class='adv'> adv. </strong> [old english: related to *wit]</p>",
      },
    ],
  },
  {
    word: "Unwonted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwonted <strong class='adj'> adj. </strong> Not customary or usual.</p>",
      },
    ],
  },
  {
    word: "Unworkable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unworkable <strong class='adj'> adj. </strong> Not workable; impracticable.</p>",
      },
    ],
  },
  {
    word: "Unworkmanlike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unworkmanlike <strong class='adj'> adj. </strong> Badly done or made.</p>",
      },
    ],
  },
  {
    word: "Unworldly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unworldly <strong class='adj'> adj. </strong> Spiritual; naïve. unworldliness n.</p>",
      },
    ],
  },
  {
    word: "Unworn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unworn <strong class='adj'> adj. </strong> Not worn or impaired by wear.</p>",
      },
    ],
  },
  {
    word: "Unworried",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unworried <strong class='adj'> adj. </strong> Not worried; calm.</p>",
      },
    ],
  },
  {
    word: "Unworthy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unworthy <strong class='adj'> adj. </strong> (-ier, -iest) 1 (often foll. By of) not worthy of or befitting a person etc. 2 discreditable, unseemly. unworthily <strong class='adv'> adv. </strong> Unworthiness n.</p>",
      },
    ],
  },
  {
    word: "Unwound  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwound  <strong class='see'>  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>unwind. </strong> </p>",
      },
    ],
  },
  {
    word: "Unwrap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwrap <strong class='verb'> v. </strong> (-pp-) 1 remove the wrapping from. 2 open, unfold. 3 become unwrapped.</p>",
      },
    ],
  },
  {
    word: "Unwritten",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unwritten <strong class='adj'> adj. </strong> 1 not written. 2 (of a law etc.) Based on custom or judicial decision, not on statute.</p>",
      },
    ],
  },
  {
    word: "Unyielding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unyielding <strong class='adj'> adj. </strong> 1 not yielding. 2 firm, obstinate.</p>",
      },
    ],
  },
  {
    word: "Unzip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Unzip <strong class='verb'> v. </strong> (-pp-) unfasten the zip of.</p>",
      },
    ],
  },
  {
    word: "Up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Up <strong class='adv'> —adv. </strong> 1 at, in, or towards a higher place or a place regarded as higher, e.g. The north, a capital or a university (up in the air; up in scotland; went up to london; came up in 1989). 2 a to or in an erect or required position or condition (stood it up; wound up the watch). B in or into an active condition (stirred up trouble; the hunt is up). 3 in a stronger or leading position (three goals up; am £10 up; is well up in class). 4 to a specified place, person, or time (a child came up to me; fine up till now). 5 higher in price or value (our costs are up; shares are up). 6 a completely (burn up; eat up). B more loudly or clearly (speak up). 7 completed (time is up). 8 into a compact, accumulated, or secure state (pack up; save up; tie up). 9 out of bed, having risen (are you up yet?; sun is up). 10 happening, esp. Unusually (something is up). 11 (usu. Foll. By before) appearing for trial etc. (up before the magistrate). 12 (of a road etc.) Being repaired. 13 (of a jockey) in the saddle. <strong class='prep'> —prep. </strong> 1 upwards and along, through, or into (climbed up the ladder; went up the road). 2 from the bottom to the top of. 3 a at or in a higher part of (is up the street). B towards the source of (a river). <strong class='adj'> —adj.</strong> 1 directed upwards (up stroke). 2 of travel towards a capital or centre (the up train). <strong class='noun'> —n. </strong> Spell of good fortune. —v. (-pp-) 1 <strong class='colloq'> colloq. </strong> Start, esp. Abruptly, to speak or act (upped and hit him). 2 raise (upped their prices). be all up with be hopeless for (a person). On the up (or up and up) <strong class='colloq'> colloq. </strong> Steadily improving. Up against 1 close to. 2 in or into contact with. 3 <strong class='colloq'> colloq. </strong> Confronted with (a problem etc.).</p>",
      },
    ],
  },
  {
    word: "Up-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Up- <strong class='prefix '> prefix </strong> in senses of *up, added: 1 as an adverb to verbs and verbal derivations, = ‘upwards’ (upcurved; update). 2 as a preposition to nouns forming adverbs and adjectives (up-country; uphill). 3 as an adjective to nouns (upland; up-stroke).</p>",
      },
    ],
  },
  {
    word: "Up-and-coming",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Up-and-coming <strong class='adj'> adj. </strong> Colloq. (of a person) promising; progressing.</p>",
      },
    ],
  },
  {
    word: "Up-and-over",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Up-and-over <strong class='adj'> adj. </strong> (of a door) opening by being raised and pushed back into a horizontal position.</p>",
      },
    ],
  },
  {
    word: "Upbeat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upbeat <strong class='noun'> —n. </strong> Unaccented beat in music. <strong class='adj'> —adj.</strong> Colloq. Optimistic, cheerful.</p>",
      },
    ],
  },
  {
    word: "Upbraid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upbraid <strong class='verb'> v. </strong> (often foll. By with, for) chide, reproach. [old english: related to *braid = brandish]</p>",
      },
    ],
  },
  {
    word: "Upbringing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upbringing <strong class='noun'> n. </strong> Rearing of a child. [obsolete upbring to rear]</p>",
      },
    ],
  },
  {
    word: "Up-country",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Up-country <strong class='adv'> adv. </strong> & adj. Inland.</p>",
      },
    ],
  },
  {
    word: "Upcurved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upcurved <strong class='adj'> adj. </strong> Curved upwards.</p>",
      },
    ],
  },
  {
    word: "Update",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Update <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> bring up to date. <strong class='noun'> —n. </strong> 1 updating. 2 updated information etc.</p>",
      },
    ],
  },
  {
    word: "Up-end",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Up-end <strong class='verb'> v. </strong> Set or rise up on end.</p>",
      },
    ],
  },
  {
    word: "Upfield",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upfield <strong class='adv'> adv. </strong> In or to a position nearer to the opponents' end of a field.</p>",
      },
    ],
  },
  {
    word: "Upfront",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upfront <strong class='colloq'> colloq. </strong> <strong class='adv'> —adv. </strong> (usu. Up front) 1 at the front; in front. 2 (of payments) in advance. <strong class='adj'> —adj.</strong> 1 honest, frank, direct. 2 (of payments) made in advance.</p>",
      },
    ],
  },
  {
    word: "Upgrade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upgrade <strong class='verb'> v. </strong> (-ding) 1 raise in rank etc. 2 improve (equipment etc.).</p>",
      },
    ],
  },
  {
    word: "Upheaval",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upheaval <strong class='noun'> n. </strong> Violent or sudden change or disruption. [from upheave, = heave or lift up]</p>",
      },
    ],
  },
  {
    word: "Uphill",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uphill <strong class='adv'> —adv. </strong> Up a slope. <strong class='adj'> —adj.</strong> 1 sloping up; ascending. 2 arduous.</p>",
      },
    ],
  },
  {
    word: "Uphold",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uphold <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Upheld) 1 confirm (a decision etc.). 2 support, maintain (a custom etc.). upholder n.</p>",
      },
    ],
  },
  {
    word: "Upholster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upholster <strong class='verb'> v. </strong> Provide (furniture) with upholstery. [back formation from *upholsterer]</p>",
      },
    ],
  },
  {
    word: "Upholsterer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upholsterer <strong class='noun'> n. </strong> Person who upholsters, esp. For a living. [obsolete upholster from *uphold in sense ‘keep in repair’]</p>",
      },
    ],
  },
  {
    word: "Upholstery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upholstery <strong class='noun'> n. </strong> 1 covering, padding, springs, etc. For furniture. 2 upholsterer's work.</p>",
      },
    ],
  },
  {
    word: "Upkeep",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upkeep <strong class='noun'> n. </strong> 1 maintenance in good condition. 2 cost or means of this.</p>",
      },
    ],
  },
  {
    word: "Upland",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upland <strong class='noun'> —n. </strong> (usu. In pl.) Higher or inland parts of a country. <strong class='adj'> —adj.</strong> Of these parts.</p>",
      },
    ],
  },
  {
    word: "Uplift",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uplift <strong class='noun'> —v. </strong> 1 raise. 2 (esp. As uplifting adj.) Elevate morally or emotionally. <strong class='noun'> —n. </strong> 1 <strong class='colloq'> colloq. </strong> Elevating influence. 2 support for the bust etc.</p>",
      },
    ],
  },
  {
    word: "Up-market",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Up-market <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Of or directed at the upper end of the market; classy.</p>",
      },
    ],
  },
  {
    word: "Upmost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upmost <strong class='var'> var. </strong> Of *uppermost.</p>",
      },
    ],
  },
  {
    word: "Upon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upon <strong class='n_pl'> comb. </strong> = *on. [from up on] <strong class='usage'> Usage:- </strong>upon is sometimes more formal than on, but is standard in once upon a time and upon my word.</p>",
      },
    ],
  },
  {
    word: "Upper1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upper1 <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> 1 higher in place; situated above another part. 2 higher in rank etc. (upper class). <strong class='noun'> —n. </strong> Part of a boot or shoe above the sole. on one's uppers <strong class='colloq'> colloq. </strong> Very short of money.</p>",
      },
    ],
  },
  {
    word: "Upper2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upper2 <strong class='noun'> n. </strong> Slang amphetamine or other stimulant.</p>",
      },
    ],
  },
  {
    word: "Upper case",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upper case <strong class='noun'> n. </strong> Capital letters.</p>",
      },
    ],
  },
  {
    word: "Upper crust",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upper crust <strong class='noun'> n. </strong> Colloq. (prec. By the) the aristocracy.</p>",
      },
    ],
  },
  {
    word: "Upper-cut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upper-cut <strong class='noun'> —n. </strong> Upwards blow delivered with the arm bent. —v. Hit upwards with the arm bent.</p>",
      },
    ],
  },
  {
    word: "Upper hand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upper hand <strong class='noun'> n. </strong> (prec. By the) dominance, control.</p>",
      },
    ],
  },
  {
    word: "Upper house",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upper house <strong class='noun'> n. </strong> Higher house in a legislature, esp. The house of lords.</p>",
      },
    ],
  },
  {
    word: "Uppermost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uppermost <strong class='adj'> —adj.</strong> (also upmost) 1 highest. 2 predominant. <strong class='adv'> —adv. </strong> At or to the uppermost position.</p>",
      },
    ],
  },
  {
    word: "Uppish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uppish <strong class='adj'> adj. </strong> Colloq. Uppity.</p>",
      },
    ],
  },
  {
    word: "Uppity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uppity <strong class='adj'> adj. </strong> Colloq. Self-assertive, arrogant.</p>",
      },
    ],
  },
  {
    word: "Upright",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upright <strong class='adj'> —adj.</strong> 1 erect, vertical. 2 (of a piano) with vertical strings. 3 honourable or honest. <strong class='noun'> —n. </strong> 1 upright post or rod, esp. As a structural support. 2 upright piano. [old english]</p>",
      },
    ],
  },
  {
    word: "Uprising",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uprising <strong class='noun'> n. </strong> Insurrection.</p>",
      },
    ],
  },
  {
    word: "Uproar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uproar <strong class='noun'> n. </strong> Tumult; violent disturbance. [dutch, = commotion]</p>",
      },
    ],
  },
  {
    word: "Uproarious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uproarious <strong class='adj'> adj. </strong> 1 very noisy. 2 provoking loud laughter; very funny. uproariously adv.</p>",
      },
    ],
  },
  {
    word: "Uproot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uproot <strong class='verb'> v. </strong> 1 pull (a plant etc.) Up from the ground. 2 displace (a person). 3 eradicate.</p>",
      },
    ],
  },
  {
    word: "Uprush",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uprush <strong class='noun'> n. </strong> Upward rush.</p>",
      },
    ],
  },
  {
    word: "Ups-a-daisy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ups-a-daisy <strong class='var'> var. </strong> Of *upsy-daisy.</p>",
      },
    ],
  },
  {
    word: "Ups and downs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ups and downs <strong class='n_pl'> n.pl. </strong> 1 rises and falls. 2 mixed fortune.</p>",
      },
    ],
  },
  {
    word: "Upset",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upset <strong class='noun'> —v. </strong> (-tt-; past and  <strong class='archaic_it_was'>past part.</strong> Upset) 1 overturn. 2 disturb the composure or digestion of. 3 disrupt. <strong class='noun'> —n. </strong> 1 emotional or physical disturbance. 2 surprising result. <strong class='adj'> —adj.</strong> Disturbed (upset stomach).</p>",
      },
    ],
  },
  {
    word: "Upshot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upshot <strong class='noun'> n. </strong> Outcome, conclusion.</p>",
      },
    ],
  },
  {
    word: "Upside down",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upside down <strong class='adv'> adv. </strong> & adj. 1 with the upper and lower parts reversed; inverted. 2 in or into total disorder. [from up so down, perhaps = ‘up as if down’]</p>",
      },
    ],
  },
  {
    word: "Upsilon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upsilon <strong class='noun'> n. </strong> Twentieth letter of the greek alphabet (u, u). [greek, = slender u, from psilos slender, with ref. To its later coincidence in sound with greek oi]</p>",
      },
    ],
  },
  {
    word: "Upstage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upstage <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> Nearer the back of a theatre stage. —v. (-ging) 1 move upstage to make (another actor) face away from the audience. 2 divert attention from (a person) to oneself.</p>",
      },
    ],
  },
  {
    word: "Upstairs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upstairs <strong class='adv'> —adv. </strong> To or on an upper floor. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Situated upstairs. <strong class='noun'> —n. </strong> Upper floor.</p>",
      },
    ],
  },
  {
    word: "Upstanding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upstanding <strong class='adj'> adj. </strong> 1 standing up. 2 strong and healthy. 3 honest.</p>",
      },
    ],
  },
  {
    word: "Upstart",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upstart <strong class='noun'> —n. </strong> Newly successful, esp. Arrogant, person. <strong class='adj'> —adj.</strong> 1 that is an upstart. 2 of upstarts.</p>",
      },
    ],
  },
  {
    word: "Upstate  us",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upstate us <strong class='noun'> —n. </strong> Provincial, esp. Northern, part of a state. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Of this part. <strong class='adv'> —adv. </strong> In or to this part.</p>",
      },
    ],
  },
  {
    word: "Upstream",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upstream <strong class='adv'> adv. </strong> & adj. In the direction contrary to the flow of a stream etc.</p>",
      },
    ],
  },
  {
    word: "Up-stroke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Up-stroke <strong class='noun'> n. </strong> Upwards stroke.</p>",
      },
    ],
  },
  {
    word: "Upsurge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upsurge <strong class='noun'> n. </strong> Upward surge.</p>",
      },
    ],
  },
  {
    word: "Upswept",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upswept <strong class='adj'> adj. </strong> (of hair) combed to the top of the head.</p>",
      },
    ],
  },
  {
    word: "Upswing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upswing <strong class='noun'> n. </strong> Upward movement or trend.</p>",
      },
    ],
  },
  {
    word: "Upsy-daisy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upsy-daisy <strong class='n_pl'> int. </strong> (also ups-a-daisy) expressing encouragement to a child who is being lifted or has fallen. [earlier up-a-daisy]</p>",
      },
    ],
  },
  {
    word: "Uptake",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uptake <strong class='noun'> n. </strong> 1 <strong class='colloq'> colloq. </strong> Understanding (esp. Quick or slow on the uptake). 2 taking up (of an offer etc.).</p>",
      },
    ],
  },
  {
    word: "Upthrust",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upthrust <strong class='noun'> n. </strong> 1 upward thrust. 2 upward displacement of part of the earth's crust.</p>",
      },
    ],
  },
  {
    word: "Uptight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uptight <strong class='adj'> adj. </strong> Colloq. 1 nervously tense or angry. 2 us rigidly conventional.</p>",
      },
    ],
  },
  {
    word: "Uptown  us",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uptown us <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Of the residential part of a town or city. <strong class='adv'> —adv. </strong> In or into this part. <strong class='noun'> —n. </strong> This part.</p>",
      },
    ],
  },
  {
    word: "Upturn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upturn <strong class='noun'> —n. </strong> Upward trend; improvement. —v. Turn up or upside down.</p>",
      },
    ],
  },
  {
    word: "Upward",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upward <strong class='adv'> —adv. </strong> (also upwards) towards what is higher, more important, etc. <strong class='adj'> —adj.</strong> Moving or extending upwards. upwards of more than (upwards of forty).</p>",
      },
    ],
  },
  {
    word: "Upwardly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upwardly <strong class='adv'> adv. </strong> In an upward direction.</p>",
      },
    ],
  },
  {
    word: "Upwardly mobile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upwardly mobile <strong class='adj'> adj. </strong> Aspiring to advance socially or professionally.</p>",
      },
    ],
  },
  {
    word: "Upwind",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Upwind <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> In the direction from which the wind is blowing.</p>",
      },
    ],
  },
  {
    word: "Uranium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uranium <strong class='noun'> n. </strong> Radioactive grey dense metallic element, capable of nuclear fission and used as a source of nuclear energy. [uranus, name of a planet]</p>",
      },
    ],
  },
  {
    word: "Urban",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urban <strong class='adj'> adj. </strong> Of, living in, or situated in a town or city. [latin urbs city]</p>",
      },
    ],
  },
  {
    word: "Urbane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urbane <strong class='adj'> adj. </strong> Suave; elegant. urbanity <strong class='noun'> n. </strong> [latin: related to *urban]</p>",
      },
    ],
  },
  {
    word: "Urban guerrilla",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urban guerrilla <strong class='noun'> n. </strong> Terrorist operating in an urban area.</p>",
      },
    ],
  },
  {
    word: "Urbanize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urbanize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) make urban, esp. By destroying the rural quality of (a district). urbanization n.</p>",
      },
    ],
  },
  {
    word: "Urchin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urchin <strong class='noun'> n. </strong> 1 mischievous, esp. Ragged, child. 2 = *sea urchin. [latin ericius hedgehog]</p>",
      },
    ],
  },
  {
    word: "Urdu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urdu <strong class='noun'> n. </strong> Language related to hindi but with many persian words, used esp. In pakistan. [hindustani]</p>",
      },
    ],
  },
  {
    word: "-ure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ure <strong class='slang_v'> suffix </strong>forming: 1 nouns of action (seizure). 2 nouns of result (creature). 3 collective nouns (nature). [latin -ura]</p>",
      },
    ],
  },
  {
    word: "Urea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urea <strong class='noun'> n. </strong> Soluble nitrogenous compound contained esp. In urine. [french urée from greek ouron urine]</p>",
      },
    ],
  },
  {
    word: "Ureter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ureter <strong class='noun'> n. </strong> Duct conveying urine from the kidney to the bladder. [greek oureo urinate]</p>",
      },
    ],
  },
  {
    word: "Urethra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urethra <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> duct conveying urine from the bladder. [greek: related to *ureter]</p>",
      },
    ],
  },
  {
    word: "Urge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urge <strong class='noun'> —v. </strong> (-ging) 1 (often foll. By on) drive forcibly; hasten. 2 encourage or entreat earnestly or persistently. 3 (often foll. By on, upon) advocate (an action or argument etc.) Emphatically (to a person). <strong class='noun'> —n. </strong> 1 urging impulse or tendency. 2 strong desire. [latin urgeo]</p>",
      },
    ],
  },
  {
    word: "Urgent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urgent <strong class='adj'> adj. </strong> 1 requiring immediate action or attention. 2 importunate. urgency <strong class='noun'> n. </strong> Urgently <strong class='adv'> adv. </strong> [french: related to *urge]</p>",
      },
    ],
  },
  {
    word: "Uric",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uric <strong class='adj'> adj. </strong> Of urine. [french urique: related to *urine]</p>",
      },
    ],
  },
  {
    word: "Uric acid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uric acid <strong class='noun'> n. </strong> Constituent of urine.</p>",
      },
    ],
  },
  {
    word: "Urinal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urinal <strong class='noun'> n. </strong> Place or receptacle for urination by men. [latin: related to *urine]</p>",
      },
    ],
  },
  {
    word: "Urinary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urinary <strong class='adj'> adj. </strong> Of or relating to urine.</p>",
      },
    ],
  },
  {
    word: "Urinate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urinate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> discharge urine. urination n.</p>",
      },
    ],
  },
  {
    word: "Urine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urine <strong class='noun'> n. </strong> Waste fluid secreted by the kidneys and discharged from the bladder. [latin urina]</p>",
      },
    ],
  },
  {
    word: "Urn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urn <strong class='noun'> n. </strong> 1 vase with a foot and usu. A rounded body, used esp. For the ashes of the dead. 2 large vessel with a tap, in which tea or coffee etc. Is made or kept hot. [latin urna]</p>",
      },
    ],
  },
  {
    word: "Urogenital",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urogenital <strong class='adj'> adj. </strong> Of the urinary and reproductive systems. [greek ouron urine]</p>",
      },
    ],
  },
  {
    word: "Urology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Urology <strong class='noun'> n. </strong> The study of the urinary system. urological adj.</p>",
      },
    ],
  },
  {
    word: "Ursa major",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ursa major <strong class='noun'> n. </strong> = great bear (see *bear2). [latin]</p>",
      },
    ],
  },
  {
    word: "Ursa minor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ursa minor <strong class='noun'> n. </strong> = little bear (see *bear2). [latin]</p>",
      },
    ],
  },
  {
    word: "Ursine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ursine <strong class='adj'> adj. </strong> Of or like a bear. [latin ursus bear]</p>",
      },
    ],
  },
  {
    word: "Us",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Us <strong class='sing'> abbr. </strong> United states.</p>",
      },
    ],
  },
  {
    word: "Us",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Us <strong class='pron'> pron. </strong> 1  <strong class='slang_v'>objective case of *</strong> we (they saw us). 2 <strong class='colloq'> colloq. </strong> = *we (it's us again). 3 <strong class='colloq'> colloq. </strong> = *me1 (give us a kiss). [old english]</p>",
      },
    ],
  },
  {
    word: "Usa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usa <strong class='sing'> abbr. </strong> United states of america.</p>",
      },
    ],
  },
  {
    word: "Usable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usable <strong class='adj'> adj. </strong> That can be used.</p>",
      },
    ],
  },
  {
    word: "Usaf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usaf <strong class='sing'> abbr. </strong> United states air force.</p>",
      },
    ],
  },
  {
    word: "Usage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usage <strong class='noun'> n. </strong> 1 use, treatment (damaged by rough usage). 2 customary practice, esp. In the use of a language or as creating a precedent in law.</p>",
      },
    ],
  },
  {
    word: "Use",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Use <strong class='noun'> —v. </strong> (using) 1 cause to act or serve for a purpose; bring into service. 2 treat in a specified manner (used him shamefully). 3 exploit for one's own ends. 4 did or had habitually (i used to drink; it used not (or did not use) to rain so often). 5 (as used adj.) Second-hand. 6 (as used predic. Adj.) (foll. By to) familiar by habit; accustomed (used to hard work). <strong class='noun'> —n. </strong> 1 using or being used. 2 right or power of using (lost the use of his legs). 3 benefit, advantage (a torch would be of use; it's no use talking). 4 custom or usage (established by long use). have no use for 1 not need. 2 dislike, be contemptuous of. In use being used. Make use of 1 use. 2 benefit from. Out of use not being used. Use up 1 consume completely. 2 find a use for (leftovers etc.). [french us, user, ultimately from latin utor us-]</p>",
      },
    ],
  },
  {
    word: "Useful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Useful <strong class='adj'> adj. </strong> 1 that can be used to advantage; helpful; beneficial. 2 <strong class='colloq'> colloq. </strong> Creditable, efficient (useful footballer). make oneself useful help. usefully <strong class='adv'> adv. </strong> Usefulness n.</p>",
      },
    ],
  },
  {
    word: "Useless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Useless <strong class='adj'> adj. </strong> 1 serving no purpose; unavailing. 2 <strong class='colloq'> colloq. </strong> Feeble or ineffectual (useless at swimming). uselessly <strong class='adv'> adv. </strong> Uselessness n.</p>",
      },
    ],
  },
  {
    word: "User",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>User <strong class='noun'> n. </strong> Person who uses a thing.</p>",
      },
    ],
  },
  {
    word: "User-friendly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>User-friendly <strong class='adj'> adj. </strong> (of a computer etc.) Easy to use.</p>",
      },
    ],
  },
  {
    word: "Usher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usher <strong class='noun'> —n. </strong> 1 person who shows people to their seats in a cinema, church, etc. 2 doorkeeper at a court etc. —v. 1 act as usher to. 2 (usu. Foll. By in) announce, herald, or show in. [latin ostium door]</p>",
      },
    ],
  },
  {
    word: "Usherette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usherette <strong class='noun'> n. </strong> Female usher, esp. In a cinema.</p>",
      },
    ],
  },
  {
    word: "Ussr",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ussr <strong class='sing'> abbr. </strong> Hist. Union of soviet socialist republics.</p>",
      },
    ],
  },
  {
    word: "Usual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usual <strong class='adj'> adj. </strong> 1 customary, habitual (the usual time). 2 (absol., prec. By the, my, etc.) Colloq. Person's usual drink etc. as usual as (or was) usual. usually <strong class='adv'> adv. </strong> [latin: related to *use]</p>",
      },
    ],
  },
  {
    word: "Usurer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usurer <strong class='noun'> n. </strong> Person who practises usury.</p>",
      },
    ],
  },
  {
    word: "Usurp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usurp <strong class='verb'> v. </strong> Seize (a throne or power etc.) Wrongfully. usurpation <strong class='noun'> n. </strong> Usurper <strong class='noun'> n. </strong> [french from latin]</p>",
      },
    ],
  },
  {
    word: "Usury",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Usury <strong class='noun'> n. </strong> 1 lending of money at interest, esp. At an exorbitant or illegal rate. 2 interest at this rate. usurious adj. [anglo-french or medieval latin: related to *use]</p>",
      },
    ],
  },
  {
    word: "Utensil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utensil <strong class='noun'> n. </strong> Implement or vessel, esp. For kitchen use. [medieval latin: related to *use]</p>",
      },
    ],
  },
  {
    word: "Uterine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uterine <strong class='adj'> adj. </strong> Of the uterus.</p>",
      },
    ],
  },
  {
    word: "Uterus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uterus <strong class='noun'> n. </strong> (pl. Uteri) womb. [latin]</p>",
      },
    ],
  },
  {
    word: "Utilitarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utilitarian <strong class='adj'> —adj.</strong> 1 designed to be useful rather than attractive; severely practical. 2 of utilitarianism. <strong class='noun'> —n. </strong> Adherent of utilitarianism.</p>",
      },
    ],
  },
  {
    word: "Utilitarianism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utilitarianism <strong class='noun'> n. </strong> Doctrine that actions are right if they are useful or benefit a majority.</p>",
      },
    ],
  },
  {
    word: "Utility",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utility <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 usefulness. 2 useful thing. 3 = *public utility. 4 (attrib.) Basic and standardized (utility furniture). [latin utilis useful: related to *use]</p>",
      },
    ],
  },
  {
    word: "Utility room",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utility room <strong class='noun'> n. </strong> Room for domestic appliances, e.g. A washing-machine, boiler, etc.</p>",
      },
    ],
  },
  {
    word: "Utility vehicle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utility vehicle <strong class='noun'> n. </strong> Vehicle serving various functions.</p>",
      },
    ],
  },
  {
    word: "Utilize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utilize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) use; turn to account. utilization <strong class='noun'> n. </strong> [italian: related to *utility]</p>",
      },
    ],
  },
  {
    word: "Utmost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utmost <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Furthest, extreme, greatest. <strong class='noun'> —n. </strong> Utmost point or degree etc. do one's utmost do all that one can. [old english, = outmost]</p>",
      },
    ],
  },
  {
    word: "Utopia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utopia <strong class='noun'> n. </strong> Imagined perfect place or state of things. utopian adj. (also utopian). [title of a book by thomas more, from greek ou not, topos place]</p>",
      },
    ],
  },
  {
    word: "Utter1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utter1 <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Complete, absolute. utterly <strong class='adv'> adv. </strong> [old english, comparative of *out]</p>",
      },
    ],
  },
  {
    word: "Utter2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utter2 <strong class='verb'> v. </strong> 1 emit audibly. 2 express in words. 3 law put (esp. Forged money) into circulation. [dutch]</p>",
      },
    ],
  },
  {
    word: "Utterance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Utterance <strong class='noun'> n. </strong> 1 act of uttering. 2 thing spoken. 3 power or manner of speaking.</p>",
      },
    ],
  },
  {
    word: "Uttermost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uttermost <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Utmost.</p>",
      },
    ],
  },
  {
    word: "U-turn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>U-turn <strong class='noun'> n. </strong> 1 u-shaped turn of a vehicle so as to face in the opposite direction. 2 abrupt reversal of policy.</p>",
      },
    ],
  },
  {
    word: "Uv",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Uv <strong class='sing'> abbr. </strong> Ultraviolet.</p>",
      },
    ],
  },
  {
    word: "Uvpc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uvpc <strong class='sing'> abbr. </strong> Unplasticized polyvinyl chloride.</p>",
      },
    ],
  },
  {
    word: "Uvula",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uvula <strong class='noun'> n. </strong> (pl. Uvulae) fleshy part of the soft palate hanging above the throat. uvular adj. [latin diminutive of uva grape]</p>",
      },
    ],
  },
  {
    word: "Uxorial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uxorial <strong class='adj'> adj. </strong> Of a wife. [latin uxor wife]</p>",
      },
    ],
  },
  {
    word: "Uxorious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Uxorious <strong class='adj'> adj. </strong> Greatly or excessively fond of one's wife.</p>",
      },
    ],
  },
];

export const alphabet_U_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>U1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यू 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>U3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>U4</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>UB40</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देशव्यापी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पनडुब्बी जहाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यू.सी.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूसीसीए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूडीआर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूएफा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उफौ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ओह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुंडली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुरूप कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुरूप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदसूरत ग्राहक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदसूरत बत्तख़ का बच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूएचएफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहां</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यू एच टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूके</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूक्रेनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गिटार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्रण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़राब कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-यूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रसाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुहनी की हड्डी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलस्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उल्स्टरमैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्ट।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुप्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंतिम चेतावनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिछले महीने का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अत्यंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्ट्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्ट्रा हाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीला सा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्ट्रैमैक्रोस्कोपिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्ट्रामोंटेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्ट्रासोनिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्ट्रासोनिक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्ट्रासाउंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पराबैंगनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधिकारातीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शोर मचाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुष्पछत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाभि -संबंधी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूरा रंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गर्भनाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाभि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिछाया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छाता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊमलायूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंपायर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनेक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयुक्त राष्ट्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>UN-1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयुक्त राष्ट्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>UN-3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अक्षीण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमर्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तमाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गवारा नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना साथी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधूरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकथनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेहिसाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनभ्यस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्षुद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनजान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनलंकृत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चापलूसी नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वीकरणीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रभावित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेबस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनलिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनमेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरंतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमरिक का नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाखुश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकमत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्याशित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना जवाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुत्तरित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्याशित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनाकर्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न मजेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगोचर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>unappreciative</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहुंच से बाहर का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निहत्था</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेशरम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभेद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नम्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वाधीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्राप्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहुंच से बाहर का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदसूरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वीकार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनधिकृत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुपलब्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यर्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिवार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनजान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनजाने में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंतुलित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रतिबंधित करें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरामद करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असहनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपराजेय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अजेय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नामालूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नास्तिकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविश्वसनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आराम किया हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खड़ा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्पक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेबुलाया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनबाइंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सख़्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुकम्मल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनब्लॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेशरम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जल्दी से ले लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आइंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनभिज्ञता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Unbound1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Unbound2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असीम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनब्रेकेबल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनभिज्ञ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेलगाम होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोझ उतारना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लापरवाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य के लिए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलौकिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनकैप्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपेक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेपरवाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अटूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेंसर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनौपचारिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढुलमुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुक्त कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असहनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चुनौती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिवर्तनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिवर्तनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वाभाविक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संगदिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न सुलझा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईसाई धर्म का नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पांडुलिपे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहले से न सोचा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लावारिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुक्त करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवर्गीकृत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंकल जी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-चाचा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशुद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोल देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंकल सैम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पोशाक उतार लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्मल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुव्यवस्थित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खुलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनसुना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असुविधाजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यवसायिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशुद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असामान्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकांतप्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रतिस्पर्धी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अडिग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साफ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदासीनता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना शर्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना शर्त रिफ्लेक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असीमित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपुष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्मजात नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अजेय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेशरम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचेत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपेक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंवैधानिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वेच्छापूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पवित्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्विरोध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवज्ञा का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवादास्पद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरंपरागत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असहमत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुविधा में पड़ा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असहयोगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेबुनियाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोल देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंपुष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेशुमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुदा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंवार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उजागर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हितैषी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुक होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राज्य-पद से हटाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गर्मजोशी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मरहम का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीहड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लहराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काटा हुआ नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविभाजित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तिथि नहीं डाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यवस्थित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुविधा में पड़ा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहले से न सोचा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपराजित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिभाषित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अजनतंत्रवादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नमूने का नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्विवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रौढ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरबेली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दाम कम कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हवाई जहाज के पहिये</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हलकी तरफ मुकदमा चलाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे पहनने के कपड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे पहनने के कपड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमज़ोर करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आड़ में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरक्रॉफ्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदेखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काटकर अलग कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविकसित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम क्षमता का व्यक्ती या समूह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भोगा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अर्द्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम आंकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपूर्ण फोटो बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आधे पेट खाया हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फर्श के तहत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धरती पर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमज़ोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे पहनने के कपड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुज़रना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूमिगत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटा सा जंगल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चालाकीपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरले 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरले 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कायम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रेखांकन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटा सा अफसर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आधारभूत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमज़ोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे लिखा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमजोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुपोषित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरपेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जांघिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमज़ोर करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुरंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोड़ा सा वेतन देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिन से लगाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्पसंख्यक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुरस्कार देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वंचितों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम आंकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बल देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पानी के नीचे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सील के अंतर्गत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सचिव के तहत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेचना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरसेक्नेटेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे का कपड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरशूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सब से छोटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधो हस्ताक्षर करता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़राब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे का कपड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिद्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे गिराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूरा सेट का नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समझना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समझ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परदा डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समझदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समझा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरस्टैंडिंग एस्प। थिएटर।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधूरा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आरंभ करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चालू करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपक्रम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरथिंग्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मंद स्वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चलाया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरटो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधूरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम आंकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वास्कट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पानी के नीचे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंडरवियर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधोलोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमा कराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविभाजित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाहक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाहक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवांछनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>undetectable</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चल पाता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनपेक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्याकुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविकसित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूर्ववत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविभाज्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पचाया नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नालायक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिर्दिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कम न हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊंदिना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकूटनैतिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुशासनहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रकाशित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनदेखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविभाज्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साफदिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविभाज्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साफ़-जाहिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न सुलझा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अबाधित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविभाज्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूर्ववत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नष्ट कर दिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंदिग्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आशातीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घर का कपड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कपड़ा उतार लिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविभाज्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तेजित होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काम न करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना मेहनत की कमाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पता लगाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पता लगाने का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असहजता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेचैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखाद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वीकार कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलाभकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमितव्ययी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वीकार्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रकाशित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरोज़गार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरोजगारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार का वेतन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभारग्रस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असीम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेहद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नामालूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्साहित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवांछनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रतिम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रतिम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनेस्को</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनैतिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमतल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊंचा नीचा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेमिसाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना अपवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साधारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साधारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्याशित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरोज़गार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनपेक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिर्दिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्धारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमोघ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनफेथफुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनजान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अफैशनवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विलंब करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिमेय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिकूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिकूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यावहारिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्मम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनफिट करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न भरे गए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधूरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लगातार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनाकर्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पंखहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेहिचक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उधेड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सहज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्याशित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनपेक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविस्मरणीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अक्षम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>माफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेडौल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुर्भाग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुर्भाग्य से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निराधार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनफ़्रीज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार बार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमित्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनन्य करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनफ़्रीज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फ़्रीज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अधूरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना असबाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुरूप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनभिज्ञ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनमोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वीकार करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धर्मभ्रष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नमकहराम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नमकहराम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्याकरणिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एहसान फरामोश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेपनाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मरहम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकत्रित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकरम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाने देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रसन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहानिकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुपयोगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्बाध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घबराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपवित्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घृणाजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आशातीत-के लिए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घोड़े पर से मिरा देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धीरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभेद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मैली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विश्वविद्यालय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊनि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक सदनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनिसेफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनेक जीवकोष का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक तंगावाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनीसाइकिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अज्ञात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकीकरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकीकरण चर्च</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर्दी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनिफाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक तरफा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकतरफा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकल्पनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़याल से बाहर का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अछूता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ाहिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खुश नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रभावी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेख़बर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्जन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्जन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेहिचक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रारंभ नहीं किए गए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रिहाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदासीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फीके</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनपेक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनैच्छिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रसहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रसहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरंतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिन बुलाये मेहमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आमंत्रण रद्द करने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संघ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संघ की आलोचना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संघवादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनियन बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनियक जैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अद्वितीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उभयलिंगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामंजस्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इकाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकजुट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इकाई लागत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनाईटेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनाइटेड किंगडम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयुक्त राष्ट्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनाइटेड रिफॉर्म्ड चर्च</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयुक्त राज्य अमेरिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनिट मूल्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खंड ट्रस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकतरफा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूनिवलव ज़ूल।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सार्वभौमिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सार्वभौमिक युग्मन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सार्वभौमिक काल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रह्मांड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विश्वविद्यालय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अन्यायपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मैला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्दयी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अज्ञात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>unknowing</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनजान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अज्ञात मात्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अज्ञात सिपाही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अज्ञात योद्धा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेबल नहीं किया गया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेबल नहीं किया गया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंद खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनलडेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भार उतारा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ग़ैरक़ानूनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अछूता हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पढ़ना नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Unlearned1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Unlearned2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिलाने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखमीरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जब तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपढ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना लाइसेंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भिन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संभावना नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संकेतों के विपरीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असीमित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Unlined1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Unlined2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर-सूचीबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तिमिराच्छन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनलोड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनलॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनन्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुक करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदसूरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रेम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदकिस्मत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिवर्तित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टुकड़े टुकड़े करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमानवीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वाभाविक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डरनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मानव रहित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगोचर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवाहित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पोल खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेजोड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेदर्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाहक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनैतिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पागल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत ही खास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरंतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमिश्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंशोधित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेसुरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अज्ञात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वाभाविक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनावश्यक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शांत लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>unnoticeable</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किसी का ध्यान नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेशुमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्विवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुच्छ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्यक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नायाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खुला हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनौपचारिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनौपचारिक हड़ताल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंगठित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नक़ल करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिमित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवैतनिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रंगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयुगल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अद्वितीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेहया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अद्वितीय भाषा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संसद का नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रीम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदेशभक्तिपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेफिक्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फैलाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनपिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छिन्न-भिन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>से भी मैं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनप्लग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहले न सोचा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सियासी नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रसिद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आबादी वाले</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रयोग में न लाने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्राप्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रयोग में न लाने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभूतपूर्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्याशित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहले ही से नहीं मन में स्थिर कर लिया हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहले न सोचा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्राप्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनमोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनैतिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुर्वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अव्यवसायिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाभहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंगत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निराशात्मक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धीरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>unpronounceable</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अरक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>unprovable</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असुरक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रकाशित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदंडित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिपक्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अतृप्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्विवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्विवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्विवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशांत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंदें शब्द बोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खंडित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपठित ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवास्तविक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवास्तविक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचेतन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचेतन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवेक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचानने अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहचानने अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रत्यक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धुंधला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उधेड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिष्कृत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुच्छ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपंजीकृत करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपंजीकृत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुर नहीं मिलाया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रिहर्सल नहीं किया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबंधित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेदर्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविश्वसनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरंतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साधारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निरंतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलाभकारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेजोड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरहम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रतिनिधिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहले से न सोचा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्कपट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनसुलझे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशांति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनर्गल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रतिबंधित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पदक मिला नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाभशून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हक से महरूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिपक्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेजोड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेजोड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उतारना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौरस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उतारना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असुरक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनकहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविक्रेय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनसाल्टेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंतोषजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंतुष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाकाफी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंतृप्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेस्वाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेस्वाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनसुना करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुच्छ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूरा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपढ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरीक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोल देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलिखित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेशरम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेमौसम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तोड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैरवरीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाचीज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगोचर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वेच्छा से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेगरज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभावुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थिर करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यौन विशेषताओ से वंचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अडिग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुंडा नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>म्यान से निकालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दबाने का अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा बदसूरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भद्दा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहस्ताक्षरित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनाड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकुशल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिर्णीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनसुना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनसुना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकांतप्रिय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नामिलनसार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेचने का नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनचाही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न सुलझा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरिष्कृत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवर्गीकृत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोज निकाला नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वस्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्मम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिर्दिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिर्दिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साधारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ठीक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनकहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनभिज्ञ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वास्थ्य के लिए ठीक नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनकहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनस्टिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सतत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकत्र करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पेटी उतारना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्बल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रतिस्थित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनभिज्ञता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिसमें कुछ तत्व न हो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निराधार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिर्णीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असफल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुपयुक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शुद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चुप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>के चलते किसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमर्थित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिश्चित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नायाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचूक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पहले से न सोचा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अरक्षणीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मीठा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनसुना कर देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अडिग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भोंडा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुरूप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेढ़ंगा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेदाग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंबद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुलझाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रयुक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निष्कलंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>करमुक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थिर, असमर्थनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरीक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असंभव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कल्पनातीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनियंत्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लापरवाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असामयिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अथक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शीर्षकहीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पर्यत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चुप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न छूने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अछूता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभागा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुसरणीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुवाद के लिए अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुपचारित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेपरवाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेईमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुटिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेईमानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेईमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोल देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेसुरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अछूता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोल देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुलझाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यर्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रयुक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असामान्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिर्वचनीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदलते</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काव्य का नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलाभकारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चुप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनजान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवांछित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाजायज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असावधान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मैला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अटूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिर्दिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अश्रांत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वीकृति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोझल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तैयार नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपलक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असामान्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असाध्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिच्छुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलौकिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निश्चिन्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खुला हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिखा नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अड़ जाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूपी-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाग्य के चढ़ाव उतार का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उतार-ओवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्साहित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फटकारना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पालना पोसना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर की ओर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उकसाया हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अद्यतन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर अंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपफील्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अग्रिम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उन्नत करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उथल-पुथल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर की ओर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बनाए रखने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असबाब वाला करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असबाब बनानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असबाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मरम्मत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊंचे-ऊंचे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्थान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर-बाजार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अत्यंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपर 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपर 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपरकेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उच्च वर्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मजबूत स्थिति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उच्च सदन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपरवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डींगवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उग्रता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईमानदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विद्रोह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोलाहल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपद्रवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जड़ से उखाड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उछालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूपीएस-ए-डेज़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उतार चढ़ाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परेशान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिणाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उल्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपसिलोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चुनौती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपरवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कल का नवाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हमें अपस्टेट करें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नदी के ऊपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर स्ट्रोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चढ़ाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उछलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चढ़ाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपसी डेज़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्क्षेप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर मजबूत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हमें ऊपर उठाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चढ़ाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर की ओर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर की ओर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपरिगामी गतिशीलता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हवा आने की दिशा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूरेनियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शहरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शहरी गुरिल्ला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शहरी बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नटखट लड़का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उर्दू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-यूआर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूरिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूत्रवाहिनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूत्रमार्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनती करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत ज़रूरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूरिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूरिक अम्ल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूत्राशय का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूत्र त्याग करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कलश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूत्रजननांगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उरोलोजि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सप्तर्षिमंडल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उरसा नाबालिग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उर्सीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमेरीका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रयोग करने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूएसएएफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रयोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रयोग करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपयोगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपयोगकर्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूजर फ्रेंडली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपशिक्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रवेश करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सोवियत संघ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामान्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुदृढ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हड़पना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूदखोरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्तन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गर्भाशय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गर्भाशय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपयोगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपयोगीता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपयोगिता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यावहारिक कक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपयोगिता वाहन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपयोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अत्यंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आदर्शलोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Utter1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Utter2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कथन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यू टर्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पराबैंगनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यूवीपीसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलिजिह्वा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उक्सोरियल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उकसाने वाला</p>",
  },
];
