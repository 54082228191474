export const alphabet_Q = [
  {
    word: "Q",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p> </p>",
      },
    ],
  },
  {
    word: "Q1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Q1 <strong class='noun'> n. </strong> (also q) (pl. Qs or q's) seventeenth letter of the alphabet.</p>",
      },
    ],
  },
  {
    word: "Q2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Q2 <strong class='sing'> abbr. </strong> (also q.) 1 queen('s). 2 question.</p>",
      },
    ],
  },
  {
    word: "Qc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Qc <strong class='sing'> abbr. </strong> Queen's counsel.</p>",
      },
    ],
  },
  {
    word: "Qed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Qed <strong class='sing'> abbr. </strong> Which was to be proved. [latin quod erat demonstrandum]</p>",
      },
    ],
  },
  {
    word: "Qm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Qm <strong class='sing'> abbr. </strong> Quartermaster.</p>",
      },
    ],
  },
  {
    word: "Qr.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Qr. <strong class='abbr'> Abbr. </strong> Quarter(s).</p>",
      },
    ],
  },
  {
    word: "Qt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Qt <strong class='sing'> abbr. </strong> Quart(s).</p>",
      },
    ],
  },
  {
    word: "Qua",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Qua <strong class='conj'> conj. </strong> In the capacity of. [latin, = in the way in which]</p>",
      },
    ],
  },
  {
    word: "Quack1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quack1 <strong class='noun'> —n. </strong> Harsh sound made by ducks. —v. Utter this sound. [imitative]</p>",
      },
    ],
  },
  {
    word: "Quack2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quack2 <strong class='noun'> n. </strong> 1 unqualified practitioner, esp. Of medicine; charlatan (often attrib.: quack cure). 2 slang any doctor. quackery <strong class='noun'> n. </strong> [abbreviation of quacksalver from dutch: probably related to *quack1, *salve1]</p>",
      },
    ],
  },
  {
    word: "Quad1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quad1 <strong class='noun'> n. </strong> Colloq. Quadrangle. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Quad2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quad2 <strong class='noun'> n. </strong> Colloq. Quadruplet. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Quad3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quad3 <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Quadraphonics. <strong class='adj'> —adj.</strong> Quadraphonic. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Quadragesima",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadragesima <strong class='noun'> n. </strong> First sunday in lent. [latin quadragesimus fortieth]</p>",
      },
    ],
  },
  {
    word: "Quadrangle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadrangle <strong class='noun'> n. </strong> 1 four-sided plane figure, esp. A square or rectangle. 2 four-sided court, esp. In colleges. quadrangular adj. [latin: related to *quadri-, *angle1]</p>",
      },
    ],
  },
  {
    word: "Quadrant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadrant <strong class='noun'> n. </strong> 1 quarter of a circle's circumference. 2 quarter of a circle enclosed by two radii at right angles. 3 quarter of a sphere etc. 4 any of four parts of a plane divided by two lines at right angles. 5 a graduated quarter-circular strip of metal etc. B instrument graduated (esp. Through an arc of 90°) for measuring angles. [latin quadrans -ant-]</p>",
      },
    ],
  },
  {
    word: "Quadraphonic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadraphonic <strong class='adj'> adj. </strong> (of sound reproduction) using four transmission channels. quadraphonically <strong class='adv'> adv. </strong> Quadraphonics <strong class='n_pl'> n.pl. </strong> [from *quadri-, *stereophonic]</p>",
      },
    ],
  },
  {
    word: "Quadrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadrate <strong class='adj'> —adj.</strong> Esp. Anat. & zool. Square or rectangular. <strong class='noun'> —n. </strong> Rectangular object. —v. <strong class='ting'> (-ting) </strong> make square. [latin quadro make square]</p>",
      },
    ],
  },
  {
    word: "Quadratic  math.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadratic math. <strong class='adj'> —adj.</strong> Involving the square (and no higher power) of an unknown quantity or variable (quadratic equation). <strong class='noun'> —n. </strong> Quadratic equation.</p>",
      },
    ],
  },
  {
    word: "Quadri-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadri- <strong class='n_pl'> comb. </strong> Form four. [latin quattuor four]</p>",
      },
    ],
  },
  {
    word: "Quadriceps",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadriceps <strong class='noun'> n. </strong> Four-headed muscle at the front of the thigh. [from *quadri-, *biceps]</p>",
      },
    ],
  },
  {
    word: "Quadrilateral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadrilateral <strong class='adj'> —adj.</strong> Having four sides. <strong class='noun'> —n. </strong> Four-sided figure.</p>",
      },
    ],
  },
  {
    word: "Quadrille",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadrille <strong class='noun'> n. </strong> 1 a kind of square dance. 2 music for this. [french]</p>",
      },
    ],
  },
  {
    word: "Quadriplegia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadriplegia <strong class='noun'> n. </strong> Paralysis of all four limbs. quadriplegic adj. & <strong class='noun'> n. </strong> [from *quadri-, greek plege a blow]</p>",
      },
    ],
  },
  {
    word: "Quadruped",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadruped <strong class='noun'> n. </strong> Four-footed animal, esp. A mammal. [latin: related to *quadri-, pes ped- foot]</p>",
      },
    ],
  },
  {
    word: "Quadruple",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadruple <strong class='adj'> —adj.</strong> 1 fourfold; having four parts. 2 (of time in music) having four beats in a bar. <strong class='noun'> —n. </strong> Fourfold number or amount. —v. (-ling) multiply by four. [latin: related to *quadri-]</p>",
      },
    ],
  },
  {
    word: "Quadruplet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadruplet <strong class='noun'> n. </strong> Each of four children born at one birth.</p>",
      },
    ],
  },
  {
    word: "Quadruplicate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quadruplicate <strong class='adj'> —adj.</strong> 1 fourfold. 2 of which four copies are made. —v. <strong class='ting'> (-ting) </strong> multiply by four.</p>",
      },
    ],
  },
  {
    word: "Quaff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quaff <strong class='verb'> v. </strong> Literary 1 drink deeply. 2 drain (a cup etc.) In long draughts. quaffable adj. [perhaps imitative]</p>",
      },
    ],
  },
  {
    word: "Quagmire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quagmire <strong class='noun'> n. </strong> 1 muddy or boggy area. 2 hazardous situation. [from quag bog, *mire]</p>",
      },
    ],
  },
  {
    word: "Quail1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quail1 <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> small game-bird related to the partridge. [french quaille]</p>",
      },
    ],
  },
  {
    word: "Quail2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quail2 <strong class='verb'> v. </strong> Flinch; show fear. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Quaint",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quaint <strong class='adj'> adj. </strong> Attractively odd or old-fashioned. quaintly <strong class='adv'> adv. </strong> Quaintness <strong class='noun'> n. </strong> [french cointe from latin cognosco ascertain]</p>",
      },
    ],
  },
  {
    word: "Quake",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quake <strong class='noun'> —v. </strong> (-king) shake, tremble. <strong class='noun'> —n. </strong> Colloq. Earthquake. [old english]</p>",
      },
    ],
  },
  {
    word: "Quaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quaker <strong class='noun'> n. </strong> Member of the society of friends. quakerism n.</p>",
      },
    ],
  },
  {
    word: "Qualification",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Qualification <strong class='noun'> n. </strong> 1 accomplishment fitting a person for a position or purpose. 2 thing that modifies or limits (statement had many qualifications). 3 qualifying or being qualified. qualificatory adj. [french or medieval latin: related to *qualify]</p>",
      },
    ],
  },
  {
    word: "Qualify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Qualify <strong class='verb'> v. </strong> (-ies, -ied) 1 (often as qualified adj.) Make competent or fit for a position or purpose. 2 make legally entitled. 3 (usu. Foll. By for) (of a person) satisfy conditions or requirements. 4 modify or limit (a statement etc.) (qualified approval). 5 <strong class='gram'>gram.</strong> (of a word) attribute a quality to esp. A noun. 6 moderate, mitigate; make less severe. 7 (foll. By as) be describable as, count as (a grunt hardly qualifies as conversation). qualifier <strong class='noun'> n. </strong> [latin qualis such as, of what kind]</p>",
      },
    ],
  },
  {
    word: "Qualitative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Qualitative <strong class='adj'> adj. </strong> Of quality as opposed to quantity. qualitatively <strong class='adv'> adv. </strong> [latin: related to *quality]</p>",
      },
    ],
  },
  {
    word: "Quality",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 degree of excellence. 2 a general excellence (has quality). B (attrib.) Of high quality (a quality product). 3 attribute, faculty (has many good qualities). 4 relative nature or character. 5 timbre of a voice or sound. 6 archaic high social standing (people of quality). [latin qualis such as, of what kind]</p>",
      },
    ],
  },
  {
    word: "Quality control",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quality control <strong class='noun'> n. </strong> Maintaining of standards in products or services by testing samples.</p>",
      },
    ],
  },
  {
    word: "Qualm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Qualm <strong class='noun'> n. </strong> 1 misgiving; uneasy doubt. 2 scruple of conscience. 3 momentary faint or sick feeling. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Quandary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quandary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 perplexed state. 2 practical dilemma. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Quango",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quango <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> semi-public body with financial support from and senior appointments made by the government. [abbreviation of quasi (or quasi-autonomous) non-government(al) organization]</p>",
      },
    ],
  },
  {
    word: "Quanta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Quanta <strong class='pl'> pl. </strong> Of *quantum.</p>",
      },
    ],
  },
  {
    word: "Quantify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quantify <strong class='verb'> v. </strong> (-ies, -ied) 1 determine the quantity of. 2 express as a quantity. quantifiable adj. Quantification <strong class='noun'> n. </strong> [medieval latin: related to *quantity]</p>",
      },
    ],
  },
  {
    word: "Quantitative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quantitative <strong class='adj'> adj. </strong> 1 of quantity as opposed to quality. 2 measured or measurable by quantity.</p>",
      },
    ],
  },
  {
    word: "Quantity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quantity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 property of things that is measurable. 2 size, extent, weight, amount, or number. 3 specified or considerable portion, number, or amount (buys in quantity; small quantity of food). 4 (in pl.) Large amounts or numbers; an abundance. 5 length or shortness of vowel sounds or syllables. 6 math. Value, component, etc. That may be expressed in numbers. [latin quantus how much]</p>",
      },
    ],
  },
  {
    word: "Quantity surveyor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quantity surveyor <strong class='noun'> n. </strong> Person who measures and prices building work.</p>",
      },
    ],
  },
  {
    word: "Quantum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quantum <strong class='noun'> n. </strong> (pl. Quanta) 1 physics discrete amount of energy proportional to the frequency of radiation it represents. 2 a required or allowed amount. [latin quantus how much]</p>",
      },
    ],
  },
  {
    word: "Quantum jump",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quantum jump <strong class='noun'> n. </strong> (also quantum leap) 1 sudden large increase or advance. 2 physics abrupt transition in an atom or molecule from one quantum state to another.</p>",
      },
    ],
  },
  {
    word: "Quantum mechanics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quantum mechanics <strong class='n_pl'> n.pl. </strong> (usu. Treated as sing.) (also quantum theory) physics theory assuming that energy exists in discrete units.</p>",
      },
    ],
  },
  {
    word: "Quarantine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarantine <strong class='noun'> —n. </strong> 1 isolation imposed on persons or animals to prevent infection or contagion. 2 period of this. —v. (-ning) put in quarantine. [italian quaranta forty]</p>",
      },
    ],
  },
  {
    word: "Quark1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quark1 <strong class='noun'> n. </strong> Physics component of elementary particles. [word used by joyce in finnegans wake (1939)]</p>",
      },
    ],
  },
  {
    word: "Quark2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quark2 <strong class='noun'> n. </strong> A kind of low-fat curd cheese. [german]</p>",
      },
    ],
  },
  {
    word: "Quarrel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarrel <strong class='noun'> —n. </strong> 1 severe or angry dispute or contention. 2 break in friendly relations. 3 cause of complaint (have no quarrel with him). —v. (-ll-; us -l-) 1 (often foll. By with) find fault. 2 dispute; break off friendly relations. [latin querela from queror complain]</p>",
      },
    ],
  },
  {
    word: "Quarrelsome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarrelsome <strong class='adj'> adj. </strong> Given to quarrelling.</p>",
      },
    ],
  },
  {
    word: "Quarry1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarry1 <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> place from which stone etc. May be extracted. —v. (-ies, -ied) extract (stone) from a quarry. [latin quadrum square]</p>",
      },
    ],
  },
  {
    word: "Quarry2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarry2 <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 intended victim or prey. 2 object of pursuit. [latin cor heart]</p>",
      },
    ],
  },
  {
    word: "Quarry tile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarry tile <strong class='noun'> n. </strong> Unglazed floor-tile.</p>",
      },
    ],
  },
  {
    word: "Quart",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quart <strong class='noun'> n. </strong> Liquid measure equal to a quarter of a gallon; two pints (0.946 litre). [latin quartus fourth]</p>",
      },
    ],
  },
  {
    word: "Quarter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarter <strong class='noun'> —n. </strong> 1 each of four equal parts into which a thing is divided. 2 period of three months. 3 point of time 15 minutes before or after any hour. 4 a 25 us or canadian cents. B coin for this. 5 part of a town, esp. As occupied by a particular class (residential quarter). 6 a point of the compass. B region at this. 7 direction, district, or source of supply (help from any quarter). 8 (in pl.) A lodgings. B accommodation of troops etc. 9 a one fourth of a lunar month. B moon's position between the first and second (first quarter) or third and fourth (last quarter) of these. 10 a each of the four parts into which a carcass is divided. B (in pl.) = *hindquarters. 11 mercy towards an enemy etc. On condition of surrender. 12 a grain measure equivalent to 8 bushels. B one-fourth of a hundredweight. C <strong class='colloq'> colloq. </strong> One-fourth of a pound weight. 13 each of four divisions on a shield. —v. 1 divide into quarters. 2 hist. Divide (the body of an executed person) in this way. 3 a put (troops etc.) Into quarters. B provide with lodgings. 4 heraldry place (coats of arms) on the four quarters of a shield. [latin quartarius: related to *quart]</p>",
      },
    ],
  },
  {
    word: "Quarterback",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarterback <strong class='noun'> n. </strong> Player in american football who directs attacking play.</p>",
      },
    ],
  },
  {
    word: "Quarter day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarter day <strong class='noun'> n. </strong> One of four days on which quarterly payments are due, tenancies begin and end, etc.</p>",
      },
    ],
  },
  {
    word: "Quarterdeck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarterdeck <strong class='noun'> n. </strong> Part of a ship's upper deck near the stern, usu. Reserved for officers.</p>",
      },
    ],
  },
  {
    word: "Quarter-final",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarter-final <strong class='noun'> n. </strong> Match or round preceding the semifinal.</p>",
      },
    ],
  },
  {
    word: "Quarter-hour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarter-hour <strong class='noun'> n. </strong> 1 period of 15 minutes. 2 = *quarter <strong class='noun'> n. </strong> 3.</p>",
      },
    ],
  },
  {
    word: "Quarter-light",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarter-light <strong class='noun'> n. </strong> Small pivoted window in the side of a car, carriage, etc.</p>",
      },
    ],
  },
  {
    word: "Quarterly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarterly <strong class='adj'> —adj.</strong> Produced or occurring once every quarter of a year. <strong class='adv'> —adv. </strong> Once every quarter of a year. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> quarterly journal.</p>",
      },
    ],
  },
  {
    word: "Quartermaster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quartermaster <strong class='noun'> n. </strong> 1 regimental officer in charge of quartering, rations, etc. 2 naval petty officer in charge of steering, signals, etc.</p>",
      },
    ],
  },
  {
    word: "Quarter sessions",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarter sessions <strong class='n_pl'> n.pl. </strong> Hist. Court of limited criminal and civil jurisdiction, usu. Held quarterly.</p>",
      },
    ],
  },
  {
    word: "Quarterstaff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarterstaff <strong class='noun'> n. </strong> Hist. Stout pole 6–8 feet long, formerly used as a weapon.</p>",
      },
    ],
  },
  {
    word: "Quartet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quartet <strong class='noun'> n. </strong> 1 <strong class='mus'> mus. </strong> A composition for four performers. B the performers. 2 any group of four. [latin quartus]</p>",
      },
    ],
  },
  {
    word: "Quarto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quarto <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 size of a book or page given by folding a sheet of standard size twice to form four leaves. 2 book or sheet of this size. [latin: related to *quart]</p>",
      },
    ],
  },
  {
    word: "Quartz",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quartz <strong class='noun'> n. </strong> Silica in various mineral forms. [german from slavonic]</p>",
      },
    ],
  },
  {
    word: "Quartz clock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quartz clock <strong class='noun'> n. </strong> (also quartz watch) clock or watch operated by vibrations of an electrically driven quartz crystal.</p>",
      },
    ],
  },
  {
    word: "Quasar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quasar <strong class='noun'> n. </strong> Astron. Starlike object with a large redshift. [from quasi-stellar]</p>",
      },
    ],
  },
  {
    word: "Quash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quash <strong class='verb'> v. </strong> 1 annul; reject as invalid, esp. By a legal procedure. 2 suppress, crush. [french quasser from latin]</p>",
      },
    ],
  },
  {
    word: "Quasi-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quasi- <strong class='n_pl'> comb. </strong> Form 1 seemingly, not really. 2 almost. [latin quasi as if]</p>",
      },
    ],
  },
  {
    word: "Quaternary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quaternary <strong class='adj'> —adj.</strong> 1 having four parts. 2 (quaternary) <strong class='geol'> geol. </strong> Of the most recent period in the cenozoic era. <strong class='noun'> —n. </strong> (quaternary) <strong class='geol'> geol. </strong> This period. [latin quaterni four each]</p>",
      },
    ],
  },
  {
    word: "Quatrain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quatrain <strong class='noun'> n. </strong> Four-line stanza. [french quatre four]</p>",
      },
    ],
  },
  {
    word: "Quatrefoil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quatrefoil <strong class='noun'> n. </strong> Four-pointed or -leafed figure, esp. As an architectural ornament. [anglo-french quatre four: related to *foil2]</p>",
      },
    ],
  },
  {
    word: "Quattrocento",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quattrocento <strong class='noun'> n. </strong> 15th-c. Italian art. [italian, = 400, used for the years 1400–99]</p>",
      },
    ],
  },
  {
    word: "Quaver",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quaver <strong class='noun'> —v. </strong> 1 (esp. Of a voice or sound) vibrate, shake, tremble. 2 sing or say with a quavering voice. <strong class='noun'> —n. </strong> 1 <strong class='mus'> mus. </strong> Note half as long as a crotchet. 2 trill in singing. 3 tremble in speech. quavery adj. [probably imitative]</p>",
      },
    ],
  },
  {
    word: "Quay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quay <strong class='noun'> n. </strong> Artificial landing-place for loading and unloading ships. [french]</p>",
      },
    ],
  },
  {
    word: "Quayside",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quayside <strong class='noun'> n. </strong> Land forming or near a quay.</p>",
      },
    ],
  },
  {
    word: "Queasy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queasy <strong class='adj'> adj. </strong> (-ier, -iest) 1 a (of a person) nauseous. B (of the stomach) easily upset, weak of digestion. 2 (of the conscience etc.) Overscrupulous. queasily <strong class='adv'> adv. </strong> Queasiness <strong class='noun'> n. </strong> [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Queen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen <strong class='noun'> —n. </strong> 1 (as a title usu. Queen) female sovereign. 2 (in full queen consort) king's wife. 3 woman, country, or thing pre-eminent of its kind. 4 fertile female among ants, bees, etc. 5 most powerful piece in chess. 6 court-card depicting a queen. 7 (the queen) national anthem when the sovereign is female. 8 slang offens. Male homosexual. 9 belle or mock sovereign for some event (queen of the may). —v. Chess convert (a pawn) into a queen when it reaches the opponent's side of the board. queenly adj. (-ier, -iest). Queenliness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Queen-anne",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen-anne <strong class='noun'> n. </strong> (often attrib.) Style of english architecture, furniture, etc., in the early 18th c.</p>",
      },
    ],
  },
  {
    word: "Queen bee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen bee <strong class='noun'> n. </strong> 1 fertile female bee. 2 woman who behaves as if she is the most important person in a group.</p>",
      },
    ],
  },
  {
    word: "Queen mother",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen mother <strong class='noun'> n. </strong> Dowager who is mother of the sovereign.</p>",
      },
    ],
  },
  {
    word: "Queen of the may",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen of the may <strong class='noun'> n. </strong> = *may queen.</p>",
      },
    ],
  },
  {
    word: "Queen-post",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen-post <strong class='noun'> n. </strong> Either of two upright timbers between the tie-beam and main rafters of a roof-truss.</p>",
      },
    ],
  },
  {
    word: "Queensberry rules",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queensberry rules <strong class='n_pl'> n.pl. </strong> Standard rules, esp. Of boxing. [from the name marquis of queensberry]</p>",
      },
    ],
  },
  {
    word: "Queen's counsel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen's counsel <strong class='noun'> n. </strong> Counsel to the crown, taking precedence over other barristers.</p>",
      },
    ],
  },
  {
    word: "Queen's english",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen's english <strong class='noun'> n. </strong> (prec. By the) english language correctly written or spoken.</p>",
      },
    ],
  },
  {
    word: "Queen's evidence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen's evidence <strong class='see'> see *</strong> evidence.</p>",
      },
    ],
  },
  {
    word: "Queen's guide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen's guide <strong class='noun'> n. </strong> Guide who has reached the highest rank of proficiency.</p>",
      },
    ],
  },
  {
    word: "Queen's highway",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen's highway <strong class='noun'> n. </strong> Public road, regarded as being under the sovereign's protection.</p>",
      },
    ],
  },
  {
    word: "Queen's proctor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen's proctor <strong class='noun'> n. </strong> Official who has the right to intervene in probate, divorce, and nullity cases when collusion or the suppression of facts is alleged.</p>",
      },
    ],
  },
  {
    word: "Queen's scout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queen's scout <strong class='noun'> n. </strong> Scout who has reached the highest standard of proficiency.</p>",
      },
    ],
  },
  {
    word: "Queer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queer <strong class='adj'> —adj.</strong> 1 strange, odd, eccentric. 2 shady, suspect, of questionable character. 3 slightly ill; faint. 4 slang offens. (esp. Of a man) homosexual. <strong class='noun'> —n. </strong> Slang offens. Homosexual. —v. Slang spoil, put out of order. in queer street slang in difficulty, esp. In debt. Queer a person's pitch <strong class='colloq'> colloq. </strong> Spoil a person's chances. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Quell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quell <strong class='verb'> v. </strong> 1 crush or put down (a rebellion etc.). 2 suppress (fear etc.). [old english]</p>",
      },
    ],
  },
  {
    word: "Quench",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quench <strong class='verb'> v. </strong> 1 satisfy (thirst) by drinking. 2 extinguish (a fire or light). 3 cool, esp. With water. 4 esp. Metallurgy cool (a hot substance) in cold water etc. 5 stifle or suppress (desire etc.). [old english]</p>",
      },
    ],
  },
  {
    word: "Quern",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quern <strong class='noun'> n. </strong> Hand-mill for grinding corn. [old english]</p>",
      },
    ],
  },
  {
    word: "Querulous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Querulous <strong class='adj'> adj. </strong> Complaining, peevish. querulously adj. [latin queror complain]</p>",
      },
    ],
  },
  {
    word: "Query",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Query <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 question. 2 question mark or the word query as a mark of interrogation. —v. (-ies, -ied) 1 ask or inquire. 2 call in question. 3 dispute the accuracy of. [latin quaere imperative of quaero inquire]</p>",
      },
    ],
  },
  {
    word: "Quest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quest <strong class='noun'> —n. </strong> 1 search or seeking. 2 thing sought, esp. By a medieval knight. —v. (often foll. By about) go about in search of something (esp. Of dogs seeking game). [latin quaero quaesit- seek]</p>",
      },
    ],
  },
  {
    word: "Question",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Question <strong class='noun'> —n. </strong> 1 sentence worded or expressed so as to seek information or an answer. 2 a doubt or dispute about a matter (no question that he is dead). B raising of such doubt etc. 3 matter to be discussed or decided. 4 problem requiring a solution. —v. 1 ask questions of; interrogate; subject (a person) to examination. 2 throw doubt upon; raise objections to. be just a question of time be certain to happen sooner or later. Be a question of be at issue, be a problem (it's a question of money). Call in (or into) question express doubts about.</p>",
      },
    ],
  },
  {
    word: "Questionable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Questionable <strong class='adj'> adj. </strong> Doubtful as regards truth, quality, honesty, wisdom, etc.</p>",
      },
    ],
  },
  {
    word: "Question mark",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Question mark <strong class='noun'> n. </strong> Punctuation mark (?) Indicating a question.</p>",
      },
    ],
  },
  {
    word: "Question-master",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Question-master <strong class='noun'> n. </strong> Person presiding over a quiz game etc.</p>",
      },
    ],
  },
  {
    word: "Questionnaire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Questionnaire <strong class='noun'> n. </strong> Formulated series of questions, esp. For statistical analysis. [french: related to *question]</p>",
      },
    ],
  },
  {
    word: "Question time",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Question time <strong class='noun'> n. </strong> Period in parliament when mps may question ministers.</p>",
      },
    ],
  },
  {
    word: "Queue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queue <strong class='noun'> —n. </strong> Line or sequence of persons, vehicles, etc. Waiting their turn. —v. (queues, queued, queuing or queueing) (often foll. By up) form or join a queue. [latin cauda tail]</p>",
      },
    ],
  },
  {
    word: "Queue-jump",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Queue-jump <strong class='verb'> v. </strong> Push forward out of turn in a queue.</p>",
      },
    ],
  },
  {
    word: "Quibble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quibble <strong class='noun'> —n. </strong> 1 petty objection; trivial point of criticism. 2 evasion; argument relying on ambiguity. 3 archaic pun. —v. (-ling) use quibbles. quibbling adj. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Quiche",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quiche <strong class='noun'> n. </strong> Savoury flan. [french]</p>",
      },
    ],
  },
  {
    word: "Quick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quick <strong class='adj'> —adj.</strong> 1 taking only a short time (quick worker). 2 arriving after a short time, prompt. 3 with only a short interval (in quick succession). 4 lively, intelligent, alert. 5 (of a temper) easily roused. 6 archaic alive (the quick and the dead). <strong class='adv'> —adv. </strong> (also as int.) Quickly. <strong class='noun'> —n. </strong> 1 soft sensitive flesh, esp. Below the nails. 2 seat of emotion (cut to the quick). quickly <strong class='adv'> adv. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Quicken",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quicken <strong class='verb'> v. </strong> 1 make or become quicker; accelerate. 2 give life or vigour to; rouse. 3 a (of a woman) reach a stage in pregnancy when movements of the foetus can be felt. B (of a foetus) begin to show signs of life.</p>",
      },
    ],
  },
  {
    word: "Quick-fire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quick-fire <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Rapid; in rapid succession.</p>",
      },
    ],
  },
  {
    word: "Quick-freeze",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quick-freeze <strong class='verb'> v. </strong> Freeze (food) rapidly so as to preserve its natural qualities.</p>",
      },
    ],
  },
  {
    word: "Quickie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quickie <strong class='noun'> n. </strong> Colloq. Thing done or made quickly.</p>",
      },
    ],
  },
  {
    word: "Quicklime",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quicklime <strong class='noun'> n. </strong> = *lime1.</p>",
      },
    ],
  },
  {
    word: "Quick one",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quick one <strong class='noun'> n. </strong> Colloq. Drink (usu. Alcoholic) taken quickly.</p>",
      },
    ],
  },
  {
    word: "Quicksand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quicksand <strong class='noun'> n. </strong> (often in pl.) 1 area of loose wet sand that sucks in anything placed on it. 2 treacherous situation etc.</p>",
      },
    ],
  },
  {
    word: "Quickset",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quickset <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> (of a hedge etc.) Formed of cuttings, esp. Hawthorn. <strong class='noun'> —n. </strong> Hedge formed in this way.</p>",
      },
    ],
  },
  {
    word: "Quicksilver",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quicksilver <strong class='noun'> n. </strong> Mercury.</p>",
      },
    ],
  },
  {
    word: "Quickstep",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quickstep <strong class='noun'> n. </strong> Fast foxtrot.</p>",
      },
    ],
  },
  {
    word: "Quick-tempered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quick-tempered <strong class='adj'> adj. </strong> Easily angered.</p>",
      },
    ],
  },
  {
    word: "Quick-witted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quick-witted <strong class='adj'> adj. </strong> Quick to grasp a situation, make repartee, etc. quick-wittedness n.</p>",
      },
    ],
  },
  {
    word: "Quid1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quid1 <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> slang one pound sterling. quids in slang in a position of profit. [probably from latin quid what]</p>",
      },
    ],
  },
  {
    word: "Quid2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quid2 <strong class='noun'> n. </strong> Lump of tobacco for chewing. [a dialect word, = *cud]</p>",
      },
    ],
  },
  {
    word: "Quiddity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quiddity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 philos. Essence of a thing. 2 quibble; trivial objection. [latin quidditas from quid what]</p>",
      },
    ],
  },
  {
    word: "Quid pro quo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quid pro quo <strong class='noun'> n. </strong> (pl. Quid pro quos) return made (for a gift, favour, etc.). [latin, = something for something]</p>",
      },
    ],
  },
  {
    word: "Quiescent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quiescent <strong class='adj'> adj. </strong> Inert, dormant. quiescence <strong class='noun'> n. </strong> [related to *quiet]</p>",
      },
    ],
  },
  {
    word: "Quiet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quiet <strong class='adj'> —adj.</strong> 1 with little or no sound or motion. 2 of gentle or peaceful disposition. 3 unobtrusive; not showy. 4 not overt; disguised. 5 undisturbed, uninterrupted; free or far from vigorous action. 6 informal (quiet wedding). 7 enjoyed in quiet (quiet smoke). 8 not anxious or remorseful. 9 not busy (it is very quiet at work). 10 peaceful (all quiet on the frontier). <strong class='noun'> —n. </strong> 1 silence; stillness. 2 undisturbed state; tranquillity. —v. (often foll. By down) make or become quiet or calm. be quiet (esp. In imper.) Cease talking etc.</p>",
      },
    ],
  },
  {
    word: "Quieten",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quieten <strong class='verb'> v. </strong> (often foll. By down) = *quiet v.</p>",
      },
    ],
  },
  {
    word: "Quietism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quietism <strong class='noun'> n. </strong> Passive contemplative attitude towards life, esp. As a form of mysticism. quietist <strong class='noun'> n. </strong> & adj. [italian: related to *quiet]</p>",
      },
    ],
  },
  {
    word: "Quietude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quietude <strong class='noun'> n. </strong> State of quiet.</p>",
      },
    ],
  },
  {
    word: "Quietus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quietus <strong class='noun'> n. </strong> Release from life; death, final riddance (will get its quietus). [medieval latin: related to *quiet]</p>",
      },
    ],
  },
  {
    word: "Quiff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quiff <strong class='noun'> n. </strong> 1 man's tuft of hair, brushed upward over the forehead. 2 curl plastered down on the forehead. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Quill",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quill <strong class='noun'> n. </strong> 1 (in full quill-feather) large feather in a wing or tail. 2 hollow stem of this. 3 (in full quill pen) pen made of a quill. 4 (usu. In pl.) Porcupine's spine. [probably low german quiele]</p>",
      },
    ],
  },
  {
    word: "Quilt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quilt <strong class='noun'> —n. </strong> Coverlet, esp. Of quilted material. —v. Line a coverlet or garment with padding enclosed between layers of cloth by lines of stitching. quilter <strong class='noun'> n. </strong> Quilting <strong class='noun'> n. </strong> [latin culcita cushion]</p>",
      },
    ],
  },
  {
    word: "Quim",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quim <strong class='noun'> n. </strong> Coarse slang female genitals. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Quin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quin <strong class='noun'> n. </strong> Colloq. Quintuplet. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Quince",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quince <strong class='noun'> n. </strong> 1 acid pear-shaped fruit used in jams etc. 2 tree bearing this. [originally a plural, from french cooin, from cydonia in crete]</p>",
      },
    ],
  },
  {
    word: "Quincentenary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quincentenary <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 500th anniversary; celebration of this. <strong class='adj'> —adj.</strong> Of this anniversary. [latin quinque five]</p>",
      },
    ],
  },
  {
    word: "Quincunx",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quincunx <strong class='noun'> n. </strong> Five objects, esp. Trees, at the corners and centre of a square or rectangle. [latin, = five-twelfths]</p>",
      },
    ],
  },
  {
    word: "Quinine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quinine <strong class='noun'> n. </strong> Bitter drug obtained from cinchona bark, used as a tonic and to reduce fever. [spanish quina cinchona bark, from quechua kina bark]</p>",
      },
    ],
  },
  {
    word: "Quinquagesima",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quinquagesima <strong class='noun'> n. </strong> Sunday before lent. [latin quinquagesimus fiftieth]</p>",
      },
    ],
  },
  {
    word: "Quinquennial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quinquennial <strong class='adj'> adj. </strong> 1 lasting five years. 2 recurring every five years. quinquennially <strong class='adv'> adv. </strong> [latin quinquennis from quinque five, annus year]</p>",
      },
    ],
  },
  {
    word: "Quinquereme",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quinquereme <strong class='noun'> n. </strong> Ancient roman galley with five files of oarsmen on each side. [latin quinque five, remus oar]</p>",
      },
    ],
  },
  {
    word: "Quintessence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quintessence <strong class='noun'> n. </strong> 1 (usu. Foll. By of) purest and most perfect form, manifestation, or embodiment of a quality etc. 2 highly refined extract. quintessential adj. Quintessentially <strong class='adv'> adv. </strong> [latin quinta essentia fifth substance (underlying the four elements)] </p>",
      },
    ],
  },
  {
    word: "Quintet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quintet <strong class='noun'> n. </strong> 1 <strong class='mus'> mus. </strong> A composition for five performers. B the performers. 2 any group of five. [latin quintus]</p>",
      },
    ],
  },
  {
    word: "Quintuple",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quintuple <strong class='adj'> —adj.</strong> Fivefold; having five parts. <strong class='noun'> —n. </strong> Fivefold number or amount. —v. (-ling) multiply by five. [latin quintus fifth]</p>",
      },
    ],
  },
  {
    word: "Quintuplet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quintuplet <strong class='noun'> n. </strong> Each of five children born at one birth.</p>",
      },
    ],
  },
  {
    word: "Quintuplicate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quintuplicate <strong class='adj'> —adj.</strong> 1 fivefold. 2 of which five copies are made. —v. <strong class='ting'> (-ting) </strong> multiply by five.</p>",
      },
    ],
  },
  {
    word: "Quip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quip <strong class='noun'> —n. </strong> Clever saying; epi <strong class='gram'>gram.</strong> —v. (-pp-) make quips. [perhaps from latin quippe forsooth]</p>",
      },
    ],
  },
  {
    word: "Quire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quire <strong class='noun'> n. </strong> 25 (formerly 24) sheets of paper. [latin: related to *quaternary]</p>",
      },
    ],
  },
  {
    word: "Quirk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quirk <strong class='noun'> n. </strong> 1 peculiar feature, peculiarity. 2 trick of fate. quirky adj. (-ier, -iest). [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Quisling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quisling <strong class='noun'> n. </strong> Traitor, collaborator. [quisling, name of a norwegian officer and collaborator with the nazis]</p>",
      },
    ],
  },
  {
    word: "Quit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quit <strong class='noun'> —v. </strong> (-tting; past and  <strong class='archaic_it_was'>past part.</strong> Quitted or quit) 1 (also absol.) Give up, let go, abandon (a task etc.). 2 us cease, stop (quit grumbling). 3 leave or depart from. <strong class='predic'> —predic. </strong> <strong class='adj'> Adj. </strong> (foll. By of) rid of (glad to be quit of the problem). [latin: related to *quiet]</p>",
      },
    ],
  },
  {
    word: "Quitch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quitch <strong class='noun'> n. </strong> (in full quitch-grass) = *couch2. [old english]</p>",
      },
    ],
  },
  {
    word: "Quite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quite <strong class='adv'> adv. </strong> 1 completely, entirely, wholly. 2 to some extent, rather. 3 (often foll. By so) said to indicate agreement. quite a (or some) remarkable or outstanding (thing). Quite a few <strong class='colloq'> colloq. </strong> A fairly large number of. Quite something <strong class='colloq'> colloq. </strong> Remarkable thing or person. [var. Of *quit]</p>",
      },
    ],
  },
  {
    word: "Quits",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quits <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> On even terms by retaliation or repayment. call it quits acknowledge that things are now even; agree to stop quarrelling. [probably related to *quit]</p>",
      },
    ],
  },
  {
    word: "Quitter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quitter <strong class='noun'> n. </strong> 1 person who gives up easily. 2 shirker.</p>",
      },
    ],
  },
  {
    word: "Quiver1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quiver1 <strong class='noun'> —v. </strong> Tremble or vibrate with a slight rapid motion. <strong class='noun'> —n. </strong> Quivering motion or sound. [obsolete quiver nimble]</p>",
      },
    ],
  },
  {
    word: "Quiver2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quiver2 <strong class='noun'> n. </strong> Case for arrows. [anglo-french from germanic]</p>",
      },
    ],
  },
  {
    word: "Quixotic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quixotic <strong class='adj'> adj. </strong> Extravagantly and romantically chivalrous. quixotically <strong class='adv'> adv. </strong> [don quixote, in cervantes' romance]</p>",
      },
    ],
  },
  {
    word: "Quiz",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quiz <strong class='noun'> —n. </strong> (pl. Quizzes) 1 test of knowledge, esp. As entertainment. 2 interrogation, examination. —v. (-zz-) examine by questioning. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Quizzical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quizzical <strong class='adj'> adj. </strong> Expressing or done with mild or amused perplexity. quizzically adv.</p>",
      },
    ],
  },
  {
    word: "Quod",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quod <strong class='noun'> n. </strong> Slang prison. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Quoin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quoin <strong class='noun'> n. </strong> 1 external angle of a building. 2 cornerstone. 3 wedge used in printing and gunnery. [var. Of *coin]</p>",
      },
    ],
  },
  {
    word: "Quoit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quoit <strong class='noun'> n. </strong> 1 ring thrown to encircle an iron peg. 2 (in pl.) Game using these. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Quondam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quondam <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> That once was, sometime, former. [latin adv., = formerly]</p>",
      },
    ],
  },
  {
    word: "Quorate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quorate <strong class='adj'> adj. </strong> Constituting or having a quorum. [from *quorum]</p>",
      },
    ],
  },
  {
    word: "Quorum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quorum <strong class='noun'> n. </strong> Minimum number of members that must be present to constitute a valid meeting. [latin, = of whom]</p>",
      },
    ],
  },
  {
    word: "Quota",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quota <strong class='noun'> n. </strong> 1 share to be contributed to, or received from, a total. 2 number of goods, people, etc., stipulated or permitted. [latin quotus from quot how many]</p>",
      },
    ],
  },
  {
    word: "Quotable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quotable <strong class='adj'> adj. </strong> Worth quoting.</p>",
      },
    ],
  },
  {
    word: "Quotation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quotation <strong class='noun'> n. </strong> 1 passage or remark quoted. 2 quoting or being quoted. 3 contractor's estimate. [medieval latin: related to *quote]</p>",
      },
    ],
  },
  {
    word: "Quotation marks",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quotation marks <strong class='n_pl'> n.pl. </strong> Inverted commas (‘’ or “ ”) used at the beginning and end of a quotation etc.</p>",
      },
    ],
  },
  {
    word: "Quote",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quote <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> 1 cite or appeal to (an author, book, etc.) In confirmation of some view. 2 a repeat or copy out a passage from. B (foll. By from) cite (an author, book, etc.). 3 (foll. By as) cite (an author etc.) As proof, evidence, etc. 4 a enclose (words) in quotation marks. B (as int.) Verbal formula indicating opening quotation marks (he said, quote, ‘i shall stay’). 5 (often foll. By at, also absol.) State the price of. <strong class='noun'> —n. </strong> Colloq. 1 passage quoted. 2 price quoted. 3 (usu. In pl.) Quotation marks. [latin quoto mark with numbers]</p>",
      },
    ],
  },
  {
    word: "Quoth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quoth <strong class='verb'> v. </strong> (only in 1st and 3rd person) archaic said. [old english]</p>",
      },
    ],
  },
  {
    word: "Quotidian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quotidian <strong class='adj'> adj. </strong> 1 occurring or recurring daily. 2 commonplace, trivial. [latin cotidie daily]</p>",
      },
    ],
  },
  {
    word: "Quotient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Quotient <strong class='noun'> n. </strong> Result of a division sum. [latin quotiens -ent- how many times]</p>",
      },
    ],
  },
  {
    word: "Q.v.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Q.v. <strong class='abbr'> Abbr. </strong> Which see (in references). [latin quod vide]</p>",
      },
    ],
  },
  {
    word: "Qwerty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Qwerty <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Denoting the standard keyboard on english-language typewriters etc., with q, w, e, r, t, and y as the first keys on the top row of letters.</p>",
      },
    ],
  },
];

export const alphabet_Q_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्यू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Q1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Q2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्यूसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>QED</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्यूएम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्यूआर।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्यूटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योग्यता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quack1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quack2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quad1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quad2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quad3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुर्थिमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुष्कोण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुर्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुष्कोण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुष्कोण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विघात गणित।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quadri-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वाड्रिसेप्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुर्भुज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुष्कोण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुर्थि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौगुनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौगुनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुर्भुज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुर्भुज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quaff</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quagmire</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quail1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quail2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचित्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूकंप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वेकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योग्यता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अर्हता प्राप्त करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुणात्मक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुणवत्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुणवत्ता नियंत्रण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वाल्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quandary</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वैंगो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वांटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिमाणित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मात्रात्मक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मात्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मात्रा सर्वेक्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वांटम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वांटम जंप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वांटम यांत्रिकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संगरोध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quark1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quark2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झगड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झगड़ालू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वारी 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वारी 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खदान टाइल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौथाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्टरबैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्टर डे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्टरडेक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्टर फाइनल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्टर-घंटे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्टर-लाइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>त्रैमासिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्टरमास्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तिमाही सत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्टरस्टाफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौकड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्टो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्ट्ज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वार्ट्ज घड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वासर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quash</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quasi-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुर्भुज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quatrain</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quatrefoil</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quattrocento</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quay</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quayside</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Queasy</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी-ऐनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी मधुमक्खी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राजमाता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मई की रानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी-पोस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वींसबेरी नियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी के वकील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी की अंग्रेजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी के सबूत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी का मार्गदर्शक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी का राजमार्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी का प्रॉक्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रानी का स्काउट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quell</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुझाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quern</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Querulous</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सवाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रश्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संदिग्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रश्न चिह्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रश्न-मास्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रश्नावली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रश्न समय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कतार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कतार-जंप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वक्रता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वीचे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटपट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>त्वरित-आग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>त्वरित हिमीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quickie</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quicklime</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जल्दी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quicksand</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जल्दी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विकसिल्वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>त्वरित कदम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>त्वरित स्वभाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>त्वरित-बुद्धि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quid1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quid2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quiddity</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुआवज़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quiescent</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चुप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शांत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शांतता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शांत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शांत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विफ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रज़ाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quim</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quince</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quincentenary</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quincunx</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विनिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विनक्वेज़िमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quinquennial</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विनक्वेरेम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quintessence</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विंटेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विंटुपल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quintuplet</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quintuplicate</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quip</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quire</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quirk</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quisling</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quitch</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूर्णतया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्विट्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचित्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>QUIVER1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>QUIVER2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quixotic</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रश्न पूछना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quizzical</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quod</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quoin</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quoit</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quondam</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quorate</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोरम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Quotable</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्धरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्धरण चिह्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्धरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्धरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लब्धि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Q.V.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Qwerty</p>",
  },
];
