export const alphabet_W = [
    {
        "word": "W",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p> </p>"
            }
        ]
    },
    {
        "word": "W1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>W1 <strong class='noun'> n. </strong> (also w) (pl. Ws or w's) twenty-third letter of the alphabet.</p>"
            }
        ]
    },
    {
        "word": "W2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>W2 <strong class='sing'> abbr. </strong> (also w.) 1 watt(s). 2 west; western.</p>"
            }
        ]
    },
    {
        "word": "W3",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>W3 <strong class='prep'> symb. </strong> Tungsten. [wolframium, latinized name]</p>"
            }
        ]
    },
    {
        "word": "W.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>W. <strong class='abbr'> Abbr. </strong> 1 wicket(s). 2 wide(s). 3 with.</p>"
            }
        ]
    },
    {
        "word": "Wa",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wa <strong class='sing'> abbr. </strong> Western australia.</p>"
            }
        ]
    },
    {
        "word": "Wacky",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wacky <strong class='adj'> adj. </strong> (-ier, -iest) slang crazy. [originally dial., = left-handed]</p>"
            }
        ]
    },
    {
        "word": "Wad",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wad <strong class='noun'> —n. </strong> 1 lump of soft material used esp. To keep things apart or in place or to block a hole. 2 bundle of banknotes or documents. —v. (-dd-) 1 stop up or keep in place with a wad. 2 line, stuff, or protect with wadding. [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Wadding",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wadding <strong class='noun'> n. </strong> Soft fibrous material used in quilt-making etc., or to pack fragile articles.</p>"
            }
        ]
    },
    {
        "word": "Waddle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waddle <strong class='noun'> —v. </strong> (-ling) walk with short steps and a swaying motion. <strong class='noun'> —n. </strong> Waddling gait. [from *wade]</p>"
            }
        ]
    },
    {
        "word": "Wade",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wade <strong class='noun'> —v. </strong> (-ding) 1 walk through water, mud, etc., esp. With difficulty. 2 (foll. By through) go through (a tedious task, book, etc.). 3 (foll. By into) <strong class='colloq'> colloq. </strong> Attack (a person or task) vigorously. <strong class='noun'> —n. </strong> Spell of wading. wade in <strong class='colloq'> colloq. </strong> Make a vigorous attack or intervention. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wader",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wader <strong class='noun'> n. </strong> 1 long-legged water-bird that wades. 2 (in pl.) High waterproof boots.</p>"
            }
        ]
    },
    {
        "word": "Wadi",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wadi <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> rocky watercourse in <strong class='noun'> n. </strong> Africa etc., dry except in the rainy season. [arabic]</p>"
            }
        ]
    },
    {
        "word": "Wafer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wafer <strong class='noun'> n. </strong> 1 very thin light crisp sweet biscuit. 2 disc of unleavened bread used in the eucharist. 3 disc of red paper stuck on a legal document instead of a seal. [anglo-french wafre from germanic]</p>"
            }
        ]
    },
    {
        "word": "Wafer-thin",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wafer-thin <strong class='adj'> adj. </strong> Very thin.</p>"
            }
        ]
    },
    {
        "word": "Waffle1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waffle1 <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Verbose but aimless or ignorant talk or writing. —v. (-ling) indulge in waffle. [dial., = yelp]</p>"
            }
        ]
    },
    {
        "word": "Waffle2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waffle2 <strong class='noun'> n. </strong> Small crisp batter cake. [dutch]</p>"
            }
        ]
    },
    {
        "word": "Waffle-iron",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waffle-iron <strong class='noun'> n. </strong> Utensil, usu. Of two shallow metal pans hinged together, for baking waffles.</p>"
            }
        ]
    },
    {
        "word": "Waft",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waft <strong class='noun'> —v. </strong> Convey or travel easily and smoothly as through air or over water. <strong class='noun'> —n. </strong> (usu. Foll. By of) whiff or scent. [originally ‘convoy (ship etc.)’ From dutch or low german wachter from wachten to guard]</p>"
            }
        ]
    },
    {
        "word": "Wag1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wag1 <strong class='noun'> —v. </strong> <strong class='gg'> (-gg-) </strong> shake or wave to and fro. <strong class='noun'> —n. </strong> Single wagging motion (with a wag of his tail). tongues wag there is talk. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wag2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wag2 <strong class='noun'> n. </strong> Facetious person. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wage",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wage <strong class='noun'> —n. </strong> (in sing. Or pl.) Fixed regular payment to an employee, esp. A manual worker. —v. (-ging) carry on (a war etc.). [anglo-french from germanic]</p>"
            }
        ]
    },
    {
        "word": "Waged",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waged <strong class='adj'> adj. </strong> In regular paid employment.</p>"
            }
        ]
    },
    {
        "word": "Wage-earner",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wage-earner <strong class='noun'> n. </strong> Person who works for wages.</p>"
            }
        ]
    },
    {
        "word": "Wager",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wager <strong class='noun'> n. </strong> & v. = *bet. [anglo-french: related to *wage]</p>"
            }
        ]
    },
    {
        "word": "Waggish",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waggish <strong class='adj'> adj. </strong> Playful, facetious. waggishly <strong class='adv'> adv. </strong> Waggishness n.</p>"
            }
        ]
    },
    {
        "word": "Waggle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waggle <strong class='verb'> v. </strong> (-ling) <strong class='colloq'> colloq. </strong> Wag.</p>"
            }
        ]
    },
    {
        "word": "Waggly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waggly <strong class='adj'> adj. </strong> Unsteady; waggling.</p>"
            }
        ]
    },
    {
        "word": "Wagon",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wagon <strong class='noun'> n. </strong> (also waggon) 1 four-wheeled vehicle for heavy loads. 2 railway vehicle, esp. An open truck. 3 tea trolley. on the wagon (or water-wagon) slang teetotal. [dutch: related to *wain]</p>"
            }
        ]
    },
    {
        "word": "Wagoner",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wagoner <strong class='noun'> n. </strong> (also waggoner) driver of a wagon.</p>"
            }
        ]
    },
    {
        "word": "Wagon-load",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wagon-load <strong class='noun'> n. </strong> As much as a wagon can carry.</p>"
            }
        ]
    },
    {
        "word": "Wagtail",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wagtail <strong class='noun'> n. </strong> Small bird with a long tail in frequent motion.</p>"
            }
        ]
    },
    {
        "word": "Waif",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waif <strong class='noun'> n. </strong> 1 homeless and helpless person, esp. An abandoned child. 2 ownerless object or animal. [anglo-french, probably from scandinavian]</p>"
            }
        ]
    },
    {
        "word": "Waifs and strays",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waifs and strays <strong class='n_pl'> n.pl. </strong> 1 homeless or neglected children. 2 odds and ends.</p>"
            }
        ]
    },
    {
        "word": "Wail",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wail <strong class='noun'> —n. </strong> 1 prolonged plaintive high-pitched cry of pain, grief, etc. 2 sound like this. —v. 1 utter a wail. 2 lament or complain persistently or bitterly. [old norse]</p>"
            }
        ]
    },
    {
        "word": "Wain",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wain <strong class='noun'> n. </strong> Archaic wagon. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wainscot",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wainscot <strong class='noun'> n. </strong> Boarding or wooden panelling on the lower part of a room-wall. [low german wagenschot from wagen *wagon]</p>"
            }
        ]
    },
    {
        "word": "Wainscoting",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wainscoting <strong class='noun'> n. </strong> 1 wainscot. 2 material for this.</p>"
            }
        ]
    },
    {
        "word": "Waist",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waist <strong class='noun'> n. </strong> 1 a part of the human body below the ribs and above the hips; narrower middle part of the normal human figure. B circumference of this. 2 narrow middle of a violin, wasp, etc. 3 a part of a garment encircling the waist. B us blouse, bodice. 4 part of a ship between the forecastle and the quarterdeck. waisted adj. (also in <strong class='n_pl'> comb. </strong> ). [old english: related to *wax2]</p>"
            }
        ]
    },
    {
        "word": "Waistband",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waistband <strong class='noun'> n. </strong> Strip of cloth forming the waist of a garment.</p>"
            }
        ]
    },
    {
        "word": "Waistcoat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waistcoat <strong class='noun'> n. </strong> Close-fitting waist-length garment without sleeves or collar, worn usu. Over a shirt and under a jacket.</p>"
            }
        ]
    },
    {
        "word": "Waist-deep",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waist-deep <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> (also waist-high) up to the waist.</p>"
            }
        ]
    },
    {
        "word": "Waistline",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waistline <strong class='noun'> n. </strong> Outline or size of a person's body at the waist.</p>"
            }
        ]
    },
    {
        "word": "Wait",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wait <strong class='noun'> —v. </strong> 1 a defer action or departure for a specified time or until some event occurs (wait a minute; wait till i come; wait for a fine day). B be expectant. 2 await (an opportunity, one's turn, etc.). 3 defer (a meal etc.) Until a person's arrival. 4 (usu. As waiting n.) Park a vehicle for a short time. 5 act as a waiter or attendant. 6 (foll. By on, upon) a await the convenience of. B serve as an attendant to. C pay a respectful visit to. <strong class='noun'> —n. </strong> 1 period of waiting. 2 (usu. Foll. By for) watching for an enemy (lie in wait). 3 (in pl.) Archaic street singers of christmas carols. wait and see await the progress of events. Wait up (often foll. By for) not go to bed until a person arrives or an event happens. You wait! Used to imply a threat, warning, etc. [germanic: related to *wake1]</p>"
            }
        ]
    },
    {
        "word": "Waiter",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waiter <strong class='noun'> n. </strong> Man who serves at table in a hotel or restaurant etc.</p>"
            }
        ]
    },
    {
        "word": "Waiting game",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waiting game <strong class='noun'> n. </strong> The delaying of action in order to have a greater effect later.</p>"
            }
        ]
    },
    {
        "word": "Waiting-list",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waiting-list <strong class='noun'> n. </strong> List of people waiting for a thing not immediately available.</p>"
            }
        ]
    },
    {
        "word": "Waiting-room",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waiting-room <strong class='noun'> n. </strong> Room for people to wait in, esp. To see a doctor etc. Or at a station.</p>"
            }
        ]
    },
    {
        "word": "Waitress",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waitress <strong class='noun'> n. </strong> Woman who serves at table in a hotel or restaurant etc.</p>"
            }
        ]
    },
    {
        "word": "Waive",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waive <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> refrain from insisting on or using (a right, claim, opportunity, etc.). [anglo-french weyver: related to *waif]</p>"
            }
        ]
    },
    {
        "word": "Waiver",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waiver <strong class='noun'> n. </strong> Law 1 waiving of a legal right etc. 2 document recording this.</p>"
            }
        ]
    },
    {
        "word": "Wake1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wake1 <strong class='noun'> —v. </strong> (-king; past woke or waked;  <strong class='archaic_it_was'>past part.</strong> Woken or waked) 1 (often foll. By up) (cause to) cease to sleep. 2 (often foll. By up) (cause to) become alert or attentive. 3 archaic (except as waking adj. & n.) Be awake (waking hours). 4 disturb with noise. 5 evoke (an echo). <strong class='noun'> —n. </strong> 1 watch beside a corpse before burial; attendant lamentation and (less often) merrymaking. 2 (usu. In pl.) Annual holiday in (industrial) northern england. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wake2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wake2 <strong class='noun'> n. </strong> 1 track left on the water's surface by a moving ship. 2 turbulent air left behind a moving aircraft etc. in the wake of following, as a result of. [low german from old norse]</p>"
            }
        ]
    },
    {
        "word": "Wakeful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wakeful <strong class='adj'> adj. </strong> 1 unable to sleep. 2 (of a night etc.) Sleepless. 3 vigilant. wakefully <strong class='adv'> adv. </strong> Wakefulness n.</p>"
            }
        ]
    },
    {
        "word": "Waken",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waken <strong class='verb'> v. </strong> Make or become awake. [old norse]</p>"
            }
        ]
    },
    {
        "word": "Wale",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wale <strong class='noun'> n. </strong> 1 = *weal1. 2 ridge on corduroy etc. 3 <strong class='naut'> naut. </strong> A broad thick timber along a ship's side. [old english]</p>"
            }
        ]
    },
    {
        "word": "Walk",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walk <strong class='noun'> —v. </strong> 1 a progress by lifting and setting down each foot in turn, never having both feet off the ground at once. B (of a quadruped) go with the slowest gait. 2 a travel or go on foot. B take exercise in this way. 3 traverse on foot at walking speed, tread the floor or surface of. 4 cause to walk with one (walk the dog). <strong class='noun'> —n. </strong> 1 a act of walking, the ordinary human gait. B slowest gait of an animal. C person's manner of walking. 2 a distance which can be walked in a (usu. Specified) time (ten minutes' walk from here). B excursion on foot. 3 place or track intended or suitable for walking. walk all over <strong class='colloq'> colloq. </strong> 1 defeat easily. 2 take advantage of. Walk away from 1 easily outdistance. 2 refuse to become involved with. Walk away with <strong class='colloq'> colloq. </strong> = walk off with. Walk into <strong class='colloq'> colloq. </strong> 1 encounter through unwariness. 2 get (a job) easily. Walk off with <strong class='colloq'> colloq. </strong> 1 steal. 2 win easily. Walk on air feel elated.</p>"
            }
        ]
    },
    {
        "word": "Walkabout",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walkabout <strong class='noun'> n. </strong> 1 informal stroll among a crowd by a visiting dignitary. 2 period of wandering in the bush by an australian aboriginal.</p>"
            }
        ]
    },
    {
        "word": "Walker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walker <strong class='noun'> n. </strong> 1 person or animal that walks. 2 a framework in which a baby can walk unaided. B = *walking frame.</p>"
            }
        ]
    },
    {
        "word": "Walkie-talkie",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walkie-talkie <strong class='noun'> n. </strong> Two-way radio carried on the person.</p>"
            }
        ]
    },
    {
        "word": "Walk-in",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walk-in <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of a storage area) large enough to walk into.</p>"
            }
        ]
    },
    {
        "word": "Walking",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walking <strong class='noun'> n. </strong> & adj. In senses of *walk n.</p>"
            }
        ]
    },
    {
        "word": "Walking frame",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walking frame <strong class='noun'> n. </strong> Tubular metal frame for disabled or old people to help them walk.</p>"
            }
        ]
    },
    {
        "word": "Walking-stick",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walking-stick <strong class='noun'> n. </strong> Stick carried for support when walking.</p>"
            }
        ]
    },
    {
        "word": "Walkman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walkman <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> propr. Type of personal stereo.</p>"
            }
        ]
    },
    {
        "word": "Walk of life",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walk of life <strong class='noun'> n. </strong> Occupation, profession.</p>"
            }
        ]
    },
    {
        "word": "Walk-on",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walk-on <strong class='noun'> n. </strong> 1 (in full walk-on part) non-speaking dramatic role. 2 player of this.</p>"
            }
        ]
    },
    {
        "word": "Walk-out",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walk-out <strong class='noun'> n. </strong> Sudden angry departure, esp. As a protest or strike.</p>"
            }
        ]
    },
    {
        "word": "Walk-over",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walk-over <strong class='noun'> n. </strong> Easy victory.</p>"
            }
        ]
    },
    {
        "word": "Walkway",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walkway <strong class='noun'> n. </strong> Passage or path (esp. Raised) for walking along.</p>"
            }
        ]
    },
    {
        "word": "Wall",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wall <strong class='noun'> —n. </strong> 1 continuous vertical narrow structure of usu. Brick or stone, esp. Enclosing or dividing a space or supporting a roof. 2 thing like a wall, esp.: a a steep side of a mountain. B anat. The outermost layer or enclosing membrane etc. Of an organ etc. —v. 1 (esp. As walled adj.) Surround with a wall. 2 a (usu. Foll. By up, off) block (a space etc.) With a wall. B (foll. By up) enclose within a sealed space. go to the wall be defeated or pushed aside. Off the wall us slang unorthodox. Up the wall <strong class='colloq'> colloq. </strong> Crazy or furious. Walls have ears beware of eavesdroppers. wall-less adj. [latin vallum rampart]</p>"
            }
        ]
    },
    {
        "word": "Wallaby",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wallaby <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> marsupial similar to but smaller than a kangaroo. [aboriginal]</p>"
            }
        ]
    },
    {
        "word": "Wallah",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wallah <strong class='noun'> n. </strong> Slang person concerned with or in charge of a usu. Specified thing, business, etc. [hindi]</p>"
            }
        ]
    },
    {
        "word": "Wall bar",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wall bar <strong class='noun'> n. </strong> One of a set of parallel bars attached to the wall of a gymnasium, on which exercises are performed.</p>"
            }
        ]
    },
    {
        "word": "Wallet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wallet <strong class='noun'> n. </strong> Small flat esp. Leather case for holding banknotes etc. [anglo-french]</p>"
            }
        ]
    },
    {
        "word": "Wall-eye",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wall-eye <strong class='noun'> n. </strong> 1 eye with a streaked or opaque white iris. 2 eye squinting outwards. wall-eyed adj. [old norse]</p>"
            }
        ]
    },
    {
        "word": "Wallflower",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wallflower <strong class='noun'> n. </strong> 1 fragrant spring garden plant. 2 <strong class='colloq'> colloq. </strong> Woman sitting out a dance for lack of partners.</p>"
            }
        ]
    },
    {
        "word": "Wall game",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wall game <strong class='noun'> n. </strong> Form of football played at eton.</p>"
            }
        ]
    },
    {
        "word": "Walloon",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walloon <strong class='noun'> —n. </strong> 1 member of a people inhabiting s. And e. Belgium and neighbouring france. 2 french dialect spoken by this people. <strong class='adj'> —adj.</strong> Of or concerning the walloons or their language. [medieval latin wallo -onis]</p>"
            }
        ]
    },
    {
        "word": "Wallop",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wallop <strong class='colloq'> colloq. </strong> —v. (-p-) 1 thrash; beat. 2 (as walloping adj.) Huge. <strong class='noun'> —n. </strong> 1 heavy blow. 2 beer. [earlier senses ‘gallop’, ‘boil’, from french waloper from germanic: cf. *gallop]</p>"
            }
        ]
    },
    {
        "word": "Wallow",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wallow <strong class='noun'> —v. </strong> 1 (esp. Of an animal) roll about in mud etc. 2 (usu. Foll. By in) indulge in unrestrained pleasure, misery, etc. <strong class='noun'> —n. </strong> 1 act of wallowing. 2 place used by buffalo etc. For wallowing. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wallpaper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wallpaper <strong class='noun'> —n. </strong> 1 paper for pasting on to interior walls as decoration. 2 usu. Derog. Trivial background noise, music, etc. —v. Decorate with wallpaper.</p>"
            }
        ]
    },
    {
        "word": "Wall-to-wall",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wall-to-wall <strong class='adj'> adj. </strong> 1 (of a carpet) fitted to cover a whole room etc. 2 <strong class='colloq'> colloq. </strong> Ubiquitous (wall-to-wall pop music).</p>"
            }
        ]
    },
    {
        "word": "Wally",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wally <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> slang foolish or inept person. [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Walnut",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walnut <strong class='noun'> n. </strong> 1 tree with aromatic leaves and drooping catkins. 2 nut of this tree. 3 its timber. [old english, = foreign nut]</p>"
            }
        ]
    },
    {
        "word": "Walrus",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walrus <strong class='noun'> n. </strong> (pl. Same or -es) large amphibious long-tusked arctic mammal. [dutch]</p>"
            }
        ]
    },
    {
        "word": "Walrus moustache",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Walrus moustache <strong class='noun'> n. </strong> Long thick drooping moustache.</p>"
            }
        ]
    },
    {
        "word": "Waltz",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waltz <strong class='noun'> —n. </strong> 1 ballroom dance in triple time performed by couples revolving with sliding steps. 2 music for this. —v. 1 dance a waltz. 2 (often foll. By in, out, round, etc.) Colloq. Move easily, lightly, casually, etc. waltz off with <strong class='colloq'> colloq. </strong> 1 steal. 2 win easily. [german walzer from walzen revolve]</p>"
            }
        ]
    },
    {
        "word": "Wampum",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wampum <strong class='noun'> n. </strong> Beads made from shells and strung together for use as money, decoration, etc. By <strong class='noun'> n. </strong> American indians. [algonquian]</p>"
            }
        ]
    },
    {
        "word": "Wan",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wan <strong class='adj'> adj. </strong> (wanner, wannest) pale; exhausted-looking. wanly <strong class='adv'> adv. </strong> Wanness <strong class='noun'> n. </strong> [old english, = dark]</p>"
            }
        ]
    },
    {
        "word": "Wand",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wand <strong class='noun'> n. </strong> 1 supposedly magic stick used by a fairy, magician, etc. 2 staff as a symbol of office. 3 <strong class='colloq'> colloq. </strong> Conductor's baton. [old norse]</p>"
            }
        ]
    },
    {
        "word": "Wander",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wander <strong class='verb'> v. </strong> 1 (often foll. By in, off, etc.) Go about from place to place aimlessly. 2 a wind about; meander. B stray from a path etc. 3 talk or think incoherently; be inattentive or delirious. wanderer <strong class='noun'> n. </strong> [old english: related to *wend]</p>"
            }
        ]
    },
    {
        "word": "Wandering jew",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wandering jew <strong class='noun'> n. </strong> Person who never settles down.</p>"
            }
        ]
    },
    {
        "word": "Wanderlust",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wanderlust <strong class='noun'> n. </strong> Eagerness for travelling or wandering; restlessness. [german]</p>"
            }
        ]
    },
    {
        "word": "Wane",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wane <strong class='noun'> —v. </strong> (-ning) 1 (of the moon) decrease in apparent size. 2 decrease in power, vigour, importance, size, etc. <strong class='noun'> —n. </strong> Process of waning. on the wane waning; declining. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wangle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wangle <strong class='colloq'> colloq. </strong> —v. (-ling) (often refl.) Contrive to obtain (a favour etc.). <strong class='noun'> —n. </strong> Act of wangling. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Wank  coarse",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wank coarse <strong class='slang_v'> slang —v. </strong> Masturbate. <strong class='noun'> —n. </strong> Act of masturbating. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Wankel engine",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wankel engine <strong class='noun'> n. </strong> Internal-combustion engine with a continuously rotated and eccentrically pivoted shaft. [wankel, name of an engineer]</p>"
            }
        ]
    },
    {
        "word": "Wanker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wanker <strong class='noun'> n. </strong> Coarse slang contemptible or ineffectual person.</p>"
            }
        ]
    },
    {
        "word": "Wannabe",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wannabe <strong class='noun'> n. </strong> Slang 1 avid fan who tries to emulate the person he or she admires. 2 anybody who would like to be someone else. [corruption of want to be]</p>"
            }
        ]
    },
    {
        "word": "Want",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Want <strong class='noun'> —v. </strong> 1 a (often foll. By to + infin.) Desire; wish for possession of; need (wants a drink; wants it done immediately). B require to be attended to; need (garden wants weeding). C (foll. By to + infin.) Colloq. Ought; should (you want to be careful). 2 (usu. Foll. By for) lack; be deficient. 3 be without or fall short by. 4 (as wanted adj.) (of a suspected criminal etc.) Sought by the police. <strong class='noun'> —n. </strong> 1 (often foll. By of) lack, absence, or deficiency (could not go for want of time). 2 poverty; need. [old norse]</p>"
            }
        ]
    },
    {
        "word": "Wanting",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wanting <strong class='adj'> adj. </strong> 1 lacking (in quality or quantity); not equal to requirements. 2 absent, not supplied.</p>"
            }
        ]
    },
    {
        "word": "Wanton",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wanton <strong class='adj'> —adj.</strong> 1 licentious; sexually promiscuous. 2 capricious; arbitrary; motiveless (wanton wind; wanton destruction). 3 luxuriant; unrestrained (wanton profusion). <strong class='noun'> —n. </strong> Literary licentious person. wantonly <strong class='adv'> adv. </strong> [from obsolete wantowen, = undisciplined]</p>"
            }
        ]
    },
    {
        "word": "Wapiti",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wapiti <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='noun'> n. </strong> American deer. [a cree word]</p>"
            }
        ]
    },
    {
        "word": "War",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>War <strong class='noun'> —n. </strong> 1 a armed hostilities between esp. Nations; conflict. B specific instance or period of this. C suspension of international law etc. During this. 2 hostility or contention between people, groups, etc. 3 (often foll. By on) sustained campaign against crime, poverty, etc. —v. <strong class='adj'> (-rr-) </strong> 1 (as warring adj.) Rival; fighting. 2 make war. at war (often foll. By with) engaged in a war. Go to war declare or begin a war.</p>"
            }
        ]
    },
    {
        "word": "Warble",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warble <strong class='noun'> —v. </strong> (-ling) 1 sing in a gentle trilling manner. 2 speak in a warbling manner. <strong class='noun'> —n. </strong> Warbled song or utterance. [french werble(r)]</p>"
            }
        ]
    },
    {
        "word": "Warbler",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warbler <strong class='noun'> n. </strong> Bird that warbles.</p>"
            }
        ]
    },
    {
        "word": "War crime",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>War crime <strong class='noun'> n. </strong> Crime violating the international laws of war. war criminal n.</p>"
            }
        ]
    },
    {
        "word": "War cry",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>War cry <strong class='noun'> n. </strong> 1 phrase or name shouted to rally one's troops. 2 party slogan etc.</p>"
            }
        ]
    },
    {
        "word": "Ward",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Ward <strong class='noun'> n. </strong> 1 separate part of a hospital or room for a particular group of patients. 2 administrative division of a constituency. 3 a minor under the care of a guardian or court. B (in full ward of court) minor or mentally deficient person placed under the protection of a court. 4 (in pl.) The corresponding notches and projections in a key and a lock. 5 archaic guardian's control. ward off 1 parry (a blow). 2 avert (danger etc.). [old english]</p>"
            }
        ]
    },
    {
        "word": "-ward",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>-ward <strong class='slang_v'> suffix </strong>(also -wards) added to nouns of place or destination and to adverbs of direction and forming: 1 adverbs (usu. -wards) meaning ‘towards’ (backwards; homewards). 2 adjectives (usu. -ward) meaning ‘turned or tending towards’ (downward; onward). 3 (less commonly) nouns meaning ‘the region towards or about’ (look to the eastward). [old english]</p>"
            }
        ]
    },
    {
        "word": "War dance",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>War dance <strong class='noun'> n. </strong> Dance performed by primitive peoples etc. Before a battle or to celebrate victory.</p>"
            }
        ]
    },
    {
        "word": "Warden",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warden <strong class='noun'> n. </strong> 1 (often in <strong class='n_pl'> comb. </strong> ) Supervising official (traffic warden). 2 president or governor of a college, hospital, etc. [anglo-french and french: related to *guardian]</p>"
            }
        ]
    },
    {
        "word": "Warder",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warder <strong class='noun'> n. </strong> (fem. Wardress) prison officer. [french: related to *guard]</p>"
            }
        ]
    },
    {
        "word": "Wardrobe",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wardrobe <strong class='noun'> n. </strong> 1 large cupboard for storing clothes. 2 person's stock of clothes. 3 costume department of a theatre etc. [french]</p>"
            }
        ]
    },
    {
        "word": "Wardrobe mistress",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wardrobe mistress <strong class='noun'> n. </strong> (masc. Wardrobe master) person in charge of a theatrical wardrobe.</p>"
            }
        ]
    },
    {
        "word": "Wardroom",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wardroom <strong class='noun'> n. </strong> Mess in a warship for commissioned officers.</p>"
            }
        ]
    },
    {
        "word": "-wards",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>-wards <strong class='var'> var. </strong> Of *-ward.</p>"
            }
        ]
    },
    {
        "word": "Wardship",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wardship <strong class='noun'> n. </strong> Tutelage.</p>"
            }
        ]
    },
    {
        "word": "Ware",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Ware <strong class='noun'> n. </strong> 1 (esp. In <strong class='n_pl'> comb. </strong> ) Things of a specified kind made usu. For sale (chinaware; hardware). 2 (usu. In pl.) Articles for sale. 3 ceramics etc. Of a specified kind (delft ware). [old english]</p>"
            }
        ]
    },
    {
        "word": "Warehouse",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warehouse <strong class='noun'> —n. </strong> 1 building in which goods are stored. 2 wholesale or large retail store. —v. <strong class='sing'>(-sing)</strong> store temporarily in a repository.</p>"
            }
        ]
    },
    {
        "word": "Warfare",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warfare <strong class='noun'> n. </strong> Waging war, campaigning.</p>"
            }
        ]
    },
    {
        "word": "War-game",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>War-game <strong class='noun'> n. </strong> 1 military training exercise. 2 battle etc. Conducted with toy soldiers.</p>"
            }
        ]
    },
    {
        "word": "Warhead",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warhead <strong class='noun'> n. </strong> Explosive head of a missile.</p>"
            }
        ]
    },
    {
        "word": "Warhorse",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warhorse <strong class='noun'> n. </strong> 1 hist. Trooper's powerful horse. 2 <strong class='colloq'> colloq. </strong> Veteran soldier, politician, etc.</p>"
            }
        ]
    },
    {
        "word": "Warlike",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warlike <strong class='adj'> adj. </strong> 1 hostile. 2 soldierly. 3 military.</p>"
            }
        ]
    },
    {
        "word": "Warlock",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warlock <strong class='noun'> n. </strong> Archaic sorcerer. [old english, = traitor]</p>"
            }
        ]
    },
    {
        "word": "Warlord",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warlord <strong class='noun'> n. </strong> Military commander or commander-in-chief.</p>"
            }
        ]
    },
    {
        "word": "Warm",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warm <strong class='adj'> —adj.</strong> 1 of or at a fairly high temperature. 2 (of clothes etc.) Affording warmth. 3 a sympathetic, friendly, loving. B hearty, enthusiastic. 4 <strong class='colloq'> colloq. </strong> Iron. Dangerous, difficult, hostile. 5 <strong class='colloq'> colloq. </strong> A (in a game) close to the object etc. Sought. B near to guessing. 6 (of a colour etc.) Reddish or yellowish; suggestive of warmth. 7 hunting (of a scent) fresh and strong. —v. 1 make warm. 2 a (often foll. By up) warm oneself. B <strong class='adj'> (often foll. By to) </strong> become animated or sympathetic. <strong class='noun'> —n. </strong> 1 act of warming. 2 warmth of the atmosphere etc. warm up 1 make or become warm. 2 prepare for a performance etc. By practising. 3 reach a temperature for efficient working. 4 reheat (food). warmly <strong class='adv'> adv. </strong> Warmth <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Warm-blooded",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warm-blooded <strong class='adj'> adj. </strong> 1 having blood temperature well above that of the environment. 2 ardent.</p>"
            }
        ]
    },
    {
        "word": "War memorial",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>War memorial <strong class='noun'> n. </strong> Monument to those killed in a war.</p>"
            }
        ]
    },
    {
        "word": "Warm-hearted",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warm-hearted <strong class='adj'> adj. </strong> Kind, friendly. warm-heartedness n.</p>"
            }
        ]
    },
    {
        "word": "Warming-pan",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warming-pan <strong class='noun'> n. </strong> Hist. Container for live coals with a flat body and a long handle, used for warming a bed.</p>"
            }
        ]
    },
    {
        "word": "Warmonger",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warmonger <strong class='noun'> n. </strong> Person who promotes war. warmongering <strong class='noun'> n. </strong> & adj.</p>"
            }
        ]
    },
    {
        "word": "Warm-up",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warm-up <strong class='noun'> n. </strong> Period of preparatory exercise.</p>"
            }
        ]
    },
    {
        "word": "Warm work",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warm work <strong class='noun'> n. </strong> 1 work etc. That makes one warm through exertion. 2 dangerous conflict etc.</p>"
            }
        ]
    },
    {
        "word": "Warn",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warn <strong class='verb'> v. </strong> 1 (also absol.) A (often foll. By of or that) inform of danger, unknown circumstances, etc. B (foll. By to + infin.) Advise (a person) to take certain action. C (often foll. By against) inform (a person etc.) About a specific danger. 2 (usu. With neg.) Admonish. warn off tell (a person) to keep away (from). [old english]</p>"
            }
        ]
    },
    {
        "word": "Warning",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warning <strong class='noun'> n. </strong> 1 in senses of *warn. 2 thing that warns. [old english]</p>"
            }
        ]
    },
    {
        "word": "War of nerves",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>War of nerves <strong class='noun'> n. </strong> Attempt to wear down an opponent psychologically.</p>"
            }
        ]
    },
    {
        "word": "Warp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warp <strong class='noun'> —v. </strong> 1 a make or become distorted, esp. Through heat, damp, etc. B make or become perverted or strange (warped sense of humour). 2 haul (a ship) by a rope attached to a fixed point. <strong class='noun'> —n. </strong> 1 a warped state, esp. Of timber. B perversion of the mind. 2 lengthwise threads in a loom. 3 rope used in warping a ship. [old english]</p>"
            }
        ]
    },
    {
        "word": "Warpaint",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warpaint <strong class='noun'> n. </strong> 1 paint used to adorn the body before battle, esp. By <strong class='noun'> n. </strong> American indians. 2 <strong class='colloq'> colloq. </strong> Make-up.</p>"
            }
        ]
    },
    {
        "word": "Warpath",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warpath <strong class='noun'> n. </strong> on the warpath 1 (of <strong class='noun'> n. </strong> American indians) going to war. 2 <strong class='colloq'> colloq. </strong> Seeking a confrontation.</p>"
            }
        ]
    },
    {
        "word": "Warrant",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warrant <strong class='noun'> —n. </strong> 1 thing that authorizes an action. 2 a written authorization, money voucher, etc. B written authorization allowing police to search premises, arrest a suspect, etc. 3 certificate of service rank held by a warrant-officer. —v. 1 serve as a warrant for; justify. 2 guarantee or attest to esp. The genuineness of. i (or i'll) warrant i am certain; no doubt. [french warant, from germanic]</p>"
            }
        ]
    },
    {
        "word": "Warrant-officer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warrant-officer <strong class='noun'> n. </strong> Officer ranking between commissioned officers and ncos.</p>"
            }
        ]
    },
    {
        "word": "Warranty",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warranty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 undertaking as to the ownership or quality of a thing sold etc., often accepting responsibility for defects or repairs over a specified period. 2 (usu. Foll. By for + verbal noun) authority or justification. [anglo-french warantie: related to *warrant]</p>"
            }
        ]
    },
    {
        "word": "Warren",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warren <strong class='noun'> n. </strong> 1 network of rabbit burrows. 2 densely populated or labyrinthine building or district. [anglo-french warenne from germanic]</p>"
            }
        ]
    },
    {
        "word": "Warring  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warring  <strong class='slang_v'>see *war v.</strong> </p>"
            }
        ]
    },
    {
        "word": "Warrior",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warrior <strong class='noun'> n. </strong> 1 person experienced or distinguished in fighting. 2 fighting man, esp. Of primitive peoples. 3 (attrib.) Martial (warrior nation). [french werreior: related to *war]</p>"
            }
        ]
    },
    {
        "word": "Warship",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Warship <strong class='noun'> n. </strong> Ship used in war.</p>"
            }
        ]
    },
    {
        "word": "Wart",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wart <strong class='noun'> n. </strong> 1 small hard round growth on the skin. 2 protuberance on the skin of an animal, surface of a plant, etc. warts and all <strong class='colloq'> colloq. </strong> With no attempt to conceal blemishes. warty adj. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wart-hog",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wart-hog <strong class='noun'> n. </strong> African wild pig.</p>"
            }
        ]
    },
    {
        "word": "Wartime",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wartime <strong class='noun'> n. </strong> Period during which a war is being waged.</p>"
            }
        ]
    },
    {
        "word": "Wary",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wary <strong class='adj'> adj. </strong> (-ier, -iest) 1 on one's guard; circumspect. 2 (foll. By of) cautious. 3 showing caution. warily <strong class='adv'> adv. </strong> Wariness <strong class='noun'> n. </strong> [ware look out for, avoid]</p>"
            }
        ]
    },
    {
        "word": "Was  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Was  <strong class='slang_v'>1st & <strong class='archaic_it_was'> 3rd sing. </strong>Past of *be.</strong> </p>"
            }
        ]
    },
    {
        "word": "Wash",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wash <strong class='noun'> —v. </strong> 1 cleanse with liquid, esp. Water. 2 (foll. By out, off, away, etc.) A remove (a stain) in this way. B (of a stain etc.) Be removed by washing. 3 wash oneself or one's hands and face. 4 wash clothes, dishes, etc. 5 (of fabric or dye) bear washing without damage. 6 (of an argument etc.) Stand scrutiny; be believed or acceptable. 7 (of a river, waters, etc.) Touch. 8 (of liquid) carry along in a specified direction (was washed overboard; washed up on the shore). 9 (foll. By over, along, etc.) Sweep, move, or splash. 10 (foll. By over) occur all around without greatly affecting (a person). 11 sift (ore) by the action of water. 12 brush watery paint or ink over. 13 poet. Moisten, water. <strong class='noun'> —n. </strong> 1 a washing or being washed. B (prec. By the) a laundry etc. (sent them to the wash). 2 clothes etc. For washing or just washed. 3 motion of agitated water or air, esp.</p>"
            }
        ]
    },
    {
        "word": "Wash-basin",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wash-basin <strong class='noun'> n. </strong> Plumbed-in basin for washing one's hands etc.</p>"
            }
        ]
    },
    {
        "word": "Washboard",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washboard <strong class='noun'> n. </strong> 1 ribbed board on which clothes are scrubbed. 2 this as a percussion instrument.</p>"
            }
        ]
    },
    {
        "word": "Washed out",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washed out <strong class='adj'> adj. </strong> (also washed-out) 1 faded; pale. 2 <strong class='colloq'> colloq. </strong> Pale, exhausted.</p>"
            }
        ]
    },
    {
        "word": "Washed up",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washed up <strong class='adj'> adj. </strong> (also washed-up) esp. Us slang defeated, having failed.</p>"
            }
        ]
    },
    {
        "word": "Washer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washer <strong class='noun'> n. </strong> 1 person or machine that washes. 2 flat ring inserted at a joint to tighten it and prevent leakage or under the head of a screw etc., or under a nut, to disperse its pressure.</p>"
            }
        ]
    },
    {
        "word": "Washer-up",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washer-up <strong class='noun'> n. </strong> (pl. Washers-up) (also washer-upper) person who washes up dishes etc.</p>"
            }
        ]
    },
    {
        "word": "Washerwoman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washerwoman <strong class='noun'> n. </strong> Laundress.</p>"
            }
        ]
    },
    {
        "word": "Washing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washing <strong class='noun'> n. </strong> Clothes etc. For washing or just washed.</p>"
            }
        ]
    },
    {
        "word": "Washing-machine",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washing-machine <strong class='noun'> n. </strong> Machine for washing clothes.</p>"
            }
        ]
    },
    {
        "word": "Washing-powder",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washing-powder <strong class='noun'> n. </strong> Soap powder or detergent for washing clothes.</p>"
            }
        ]
    },
    {
        "word": "Washing-soda",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washing-soda <strong class='noun'> n. </strong> Sodium carbonate, used dissolved in water for washing and cleaning.</p>"
            }
        ]
    },
    {
        "word": "Washing-up",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washing-up <strong class='noun'> n. </strong> 1 process of washing dishes etc. 2 used dishes etc. For washing.</p>"
            }
        ]
    },
    {
        "word": "Wash-out",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wash-out <strong class='noun'> n. </strong> Colloq. Complete failure, non-event.</p>"
            }
        ]
    },
    {
        "word": "Washroom",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washroom <strong class='noun'> n. </strong> Esp. Us public toilet.</p>"
            }
        ]
    },
    {
        "word": "Washstand",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washstand <strong class='noun'> n. </strong> Piece of furniture to hold a basin, jug, soap, etc.</p>"
            }
        ]
    },
    {
        "word": "Washy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Washy <strong class='adj'> adj. </strong> (-ier, -iest) 1 too watery or weak. 2 lacking vigour or intensity. washily <strong class='adv'> adv. </strong> Washiness n.</p>"
            }
        ]
    },
    {
        "word": "Wasn't",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wasn't <strong class='contr'> contr. </strong> Was not.</p>"
            }
        ]
    },
    {
        "word": "Wasp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wasp <strong class='noun'> n. </strong> (also wasp) us usu. Derog. Middle-class american white protestant. [white anglo-saxon protestant]</p>"
            }
        ]
    },
    {
        "word": "Wasp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wasp <strong class='noun'> n. </strong> Stinging insect with black and yellow stripes. [old english]</p>"
            }
        ]
    },
    {
        "word": "Waspish",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waspish <strong class='adj'> adj. </strong> Irritable, snappish.</p>"
            }
        ]
    },
    {
        "word": "Wasp-waist",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wasp-waist <strong class='noun'> n. </strong> Very slender waist.</p>"
            }
        ]
    },
    {
        "word": "Wassail  archaic",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wassail archaic <strong class='noun'> —n. </strong> Festive occasion; drinking-bout. —v. Make merry. [old norse ves heill be in health: related to *whole]</p>"
            }
        ]
    },
    {
        "word": "Wastage",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wastage <strong class='noun'> n. </strong> 1 amount wasted. 2 loss by use, wear, or leakage. 3 (also natural wastage) loss of employees other than by redundancy.</p>"
            }
        ]
    },
    {
        "word": "Waste",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waste <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> 1 use to no purpose or with inadequate result or extravagantly. 2 fail to use (esp. An opportunity). 3 (often foll. By on) a give (advice etc.) Without effect. B (often in passive) fail to be appreciated or used properly (she was wasted on him; feel wasted in this job). 4 wear gradually away; make or become weak. 5 devastate. <strong class='adj'> —adj.</strong> 1 superfluous; no longer needed. 2 not inhabited or cultivated. <strong class='noun'> —n. </strong> 1 act of wasting. 2 waste material. 3 waste region. 4 being used up; diminution by wear. 5 = *waste pipe.</p>"
            }
        ]
    },
    {
        "word": "Waste disposal unit",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waste disposal unit <strong class='noun'> n. </strong> Device fitted to a sink etc. For disposing of household waste.</p>"
            }
        ]
    },
    {
        "word": "Wasteful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wasteful <strong class='adj'> adj. </strong> 1 extravagant. 2 causing or showing waste. wastefully adv.</p>"
            }
        ]
    },
    {
        "word": "Wasteland",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wasteland <strong class='noun'> n. </strong> 1 unproductive or useless area of land. 2 place or time considered spiritually or intellectually barren.</p>"
            }
        ]
    },
    {
        "word": "Waste paper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waste paper <strong class='noun'> n. </strong> Used or valueless paper.</p>"
            }
        ]
    },
    {
        "word": "Waste-paper basket",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waste-paper basket <strong class='noun'> n. </strong> Receptacle for waste paper.</p>"
            }
        ]
    },
    {
        "word": "Waste pipe",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waste pipe <strong class='noun'> n. </strong> Pipe to carry off waste material.</p>"
            }
        ]
    },
    {
        "word": "Waste product",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waste product <strong class='noun'> n. </strong> Useless by-product of manufacture or of an organism.</p>"
            }
        ]
    },
    {
        "word": "Waster",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waster <strong class='noun'> n. </strong> 1 wasteful person. 2 <strong class='colloq'> colloq. </strong> Wastrel.</p>"
            }
        ]
    },
    {
        "word": "Wastrel",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wastrel <strong class='noun'> n. </strong> Good-for-nothing person.</p>"
            }
        ]
    },
    {
        "word": "Watch",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watch <strong class='noun'> —v. </strong> 1 keep the eyes fixed on. 2 keep under observation; follow observantly. 3 (often foll. By for) be in an alert state; be vigilant. 4 (foll. By over) look after; take care of. <strong class='noun'> —n. </strong> 1 small portable timepiece for carrying on the wrist or in a pocket. 2 state of alert or constant observation or attention. 3 <strong class='naut'> naut. </strong> A usu. Four-hour spell of duty. B (in full starboard or port watch) each of the halves into which a ship's crew is divided to take alternate watches. 4 hist. Watchman or watchmen. on the watch for waiting for (an anticipated occurrence). Watch it (or oneself) <strong class='colloq'> colloq. </strong> Be careful. Watch out (often foll. By for) be on one's guard. watcher <strong class='noun'> n. </strong> (also in <strong class='n_pl'> comb. </strong> ). [old english: related to *wake1]</p>"
            }
        ]
    },
    {
        "word": "Watchdog",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watchdog <strong class='noun'> n. </strong> 1 dog guarding property etc. 2 person or body monitoring others' rights etc.</p>"
            }
        ]
    },
    {
        "word": "Watchful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watchful <strong class='adj'> adj. </strong> 1 accustomed to watching, alert. 2 on the watch. watchfully <strong class='adv'> adv. </strong> Watchfulness n.</p>"
            }
        ]
    },
    {
        "word": "Watching brief",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watching brief <strong class='noun'> n. </strong> Brief of a barrister who follows a case for a client not directly concerned.</p>"
            }
        ]
    },
    {
        "word": "Watchmaker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watchmaker <strong class='noun'> n. </strong> Person who makes and repairs watches and clocks.</p>"
            }
        ]
    },
    {
        "word": "Watchman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watchman <strong class='noun'> n. </strong> Man employed to look after an empty building etc. At night.</p>"
            }
        ]
    },
    {
        "word": "Watch-night service",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watch-night service <strong class='noun'> n. </strong> Religious service held on the last night of the year.</p>"
            }
        ]
    },
    {
        "word": "Watch-tower",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watch-tower <strong class='noun'> n. </strong> Tower for keeping watch from.</p>"
            }
        ]
    },
    {
        "word": "Watchword",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watchword <strong class='noun'> n. </strong> Phrase summarizing a guiding principle.</p>"
            }
        ]
    },
    {
        "word": "Water",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water <strong class='noun'> —n. </strong> 1 colourless transparent liquid compound of oxygen and hydrogen. 2 liquid consisting chiefly of this and found in seas and rivers, in rain, and in secretions of organisms. 3 expanse of water; a sea, lake, river, etc. 4 (in pl.) Part of a sea or river. 5 (often as the waters) mineral water at a spa etc. 6 state of a tide. 7 solution of a specified substance in water (lavender-water). 8 transparency and brilliance of a gem. 9 (attrib.) A found in or near water. B of, for, or worked by water. C involving, using, or yielding water. 10 (usu. In pl.) Amniotic fluid, released during labour. —v. 1 sprinkle or soak with water. 2 supply (a plant) with water. 3 give water to (an animal). 4 secrete water. 5 (as watered adj.) (of silk etc.) Having irregular wavy glossy markings. 6 take in a supply of water. by water using a ship etc. For transport. Like water in great quantity, profusely. Make one's mouth water cause one's saliva to flow, stimulate one's appetite or anticipation. Of the first water of the finest quality or extreme degree. Water down 1 dilute. 2 make less forceful or horrifying. Water under the bridge past events accepted as irrevocable. [old english]</p>"
            }
        ]
    },
    {
        "word": "Water-bearer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-bearer <strong class='var'> var. </strong> Of *water-carrier.</p>"
            }
        ]
    },
    {
        "word": "Water-bed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-bed <strong class='noun'> n. </strong> Mattress filled with water.</p>"
            }
        ]
    },
    {
        "word": "Water-biscuit",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-biscuit <strong class='noun'> n. </strong> Thin crisp unsweetened biscuit.</p>"
            }
        ]
    },
    {
        "word": "Water-buffalo",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-buffalo <strong class='noun'> n. </strong> Common domestic indian buffalo.</p>"
            }
        ]
    },
    {
        "word": "Water bus",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water bus <strong class='noun'> n. </strong> Boat carrying passengers on a regular run on a river, lake, etc.</p>"
            }
        ]
    },
    {
        "word": "Water-cannon",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-cannon <strong class='noun'> n. </strong> Device using a jet of water to disperse a crowd etc.</p>"
            }
        ]
    },
    {
        "word": "Water-carrier",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-carrier <strong class='noun'> n. </strong> (also water-bearer) (prec. By the) zodiacal sign or constellation aquarius.</p>"
            }
        ]
    },
    {
        "word": "Water chestnut",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water chestnut <strong class='noun'> n. </strong> Corm from a sedge, used in chinese cookery.</p>"
            }
        ]
    },
    {
        "word": "Water-clock",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-clock <strong class='noun'> n. </strong> Clock measuring time by the flow of water.</p>"
            }
        ]
    },
    {
        "word": "Water-closet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-closet <strong class='noun'> n. </strong> Lavatory that can be flushed.</p>"
            }
        ]
    },
    {
        "word": "Water-color",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-color <strong class='noun'> n. </strong> (brit. Water-colour) 1 artists' paint made of pigment to be diluted with water and not oil. 2 picture painted with this. 3 art of painting with water-colours. water-colourist n.</p>"
            }
        ]
    },
    {
        "word": "Water-colour",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-colour <strong class='noun'> n. </strong> (us water-color) 1 artists' paint made of pigment to be diluted with water and not oil. 2 picture painted with this. 3 art of painting with water-colours. water-colourist n.</p>"
            }
        ]
    },
    {
        "word": "Water-cooled",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-cooled <strong class='adj'> adj. </strong> Cooled by the circulation of water.</p>"
            }
        ]
    },
    {
        "word": "Watercourse",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watercourse <strong class='noun'> n. </strong> 1 brook or stream. 2 bed of this.</p>"
            }
        ]
    },
    {
        "word": "Watercress",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watercress <strong class='noun'> n. </strong> Pungent cress growing in running water and used in salad.</p>"
            }
        ]
    },
    {
        "word": "Water-diviner",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-diviner <strong class='noun'> n. </strong> Dowser.</p>"
            }
        ]
    },
    {
        "word": "Waterfall",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterfall <strong class='noun'> n. </strong> Stream flowing over a precipice or down a steep hillside.</p>"
            }
        ]
    },
    {
        "word": "Waterfowl",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterfowl <strong class='noun'> n. </strong> (usu. Collect. As pl.) Birds frequenting water.</p>"
            }
        ]
    },
    {
        "word": "Waterfront",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterfront <strong class='noun'> n. </strong> Part of a town adjoining a river etc.</p>"
            }
        ]
    },
    {
        "word": "Water-glass",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-glass <strong class='noun'> n. </strong> Solution of sodium or potassium silicate used esp. For preserving eggs.</p>"
            }
        ]
    },
    {
        "word": "Water-hammer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-hammer <strong class='noun'> n. </strong> Knocking noise in a water-pipe when a tap is suddenly turned off.</p>"
            }
        ]
    },
    {
        "word": "Water-hole",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-hole <strong class='noun'> n. </strong> Shallow depression in which water collects.</p>"
            }
        ]
    },
    {
        "word": "Water-ice",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-ice <strong class='noun'> n. </strong> Flavoured and frozen water and sugar etc.</p>"
            }
        ]
    },
    {
        "word": "Watering-can",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watering-can <strong class='noun'> n. </strong> Portable container with a long spout, for watering plants.</p>"
            }
        ]
    },
    {
        "word": "Watering-hole",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watering-hole <strong class='noun'> n. </strong> 1 = *watering-place 1. 2 slang bar.</p>"
            }
        ]
    },
    {
        "word": "Watering-place",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watering-place <strong class='noun'> n. </strong> 1 pool from which animals regularly drink. 2 spa or seaside resort.</p>"
            }
        ]
    },
    {
        "word": "Water jump",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water jump <strong class='noun'> n. </strong> Jump over water in a steeplechase etc.</p>"
            }
        ]
    },
    {
        "word": "Water-level",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-level <strong class='noun'> n. </strong> 1 a surface of the water in a reservoir etc. B height of this. 2 level below which the ground is saturated with water. 3 level using water to determine the horizontal.</p>"
            }
        ]
    },
    {
        "word": "Water lily",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water lily <strong class='noun'> n. </strong> Aquatic plant with floating leaves and flowers.</p>"
            }
        ]
    },
    {
        "word": "Water-line",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-line <strong class='noun'> n. </strong> Line along which the surface of water touches a ship's side.</p>"
            }
        ]
    },
    {
        "word": "Waterlogged",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterlogged <strong class='adj'> adj. </strong> Saturated or filled with water.</p>"
            }
        ]
    },
    {
        "word": "Waterloo",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterloo <strong class='noun'> n. </strong> Decisive defeat or contest. [waterloo in belgium, where napoleon was defeated]</p>"
            }
        ]
    },
    {
        "word": "Water main",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water main <strong class='noun'> n. </strong> Main pipe in a water-supply system.</p>"
            }
        ]
    },
    {
        "word": "Waterman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterman <strong class='noun'> n. </strong> 1 boatman plying for hire. 2 oarsman as regards skill in keeping the boat balanced.</p>"
            }
        ]
    },
    {
        "word": "Watermark",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watermark <strong class='noun'> —n. </strong> Faint design in some paper identifying the maker etc. —v. Mark with this.</p>"
            }
        ]
    },
    {
        "word": "Water-meadow",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-meadow <strong class='noun'> n. </strong> Meadow periodically flooded by a stream.</p>"
            }
        ]
    },
    {
        "word": "Water melon",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water melon <strong class='noun'> n. </strong> Large dark-green melon with red pulp and watery juice.</p>"
            }
        ]
    },
    {
        "word": "Water-mill",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-mill <strong class='noun'> n. </strong> Mill worked by a water-wheel.</p>"
            }
        ]
    },
    {
        "word": "Water-pistol",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-pistol <strong class='noun'> n. </strong> Toy pistol shooting a jet of water.</p>"
            }
        ]
    },
    {
        "word": "Water polo",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water polo <strong class='noun'> n. </strong> Game played by swimmers, with a ball like a football.</p>"
            }
        ]
    },
    {
        "word": "Water-power",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-power <strong class='noun'> n. </strong> Mechanical force derived from the weight or motion of water.</p>"
            }
        ]
    },
    {
        "word": "Waterproof",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterproof <strong class='adj'> —adj.</strong> Impervious to water. <strong class='noun'> —n. </strong> Waterproof garment or material. —v. Make waterproof.</p>"
            }
        ]
    },
    {
        "word": "Water-rat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-rat <strong class='noun'> n. </strong> = *water-vole.</p>"
            }
        ]
    },
    {
        "word": "Water-rate",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-rate <strong class='noun'> n. </strong> Charge made for the use of the public water-supply.</p>"
            }
        ]
    },
    {
        "word": "Watershed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watershed <strong class='noun'> n. </strong> 1 line of separation between waters flowing to different rivers, basins, etc. 2 turning-point in affairs. [from shed ridge]</p>"
            }
        ]
    },
    {
        "word": "Waterside",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterside <strong class='noun'> n. </strong> Edge of a sea, lake, or river.</p>"
            }
        ]
    },
    {
        "word": "Water-ski",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-ski <strong class='noun'> —n. </strong> Each of a pair of skis for skimming the surface of the water when towed by a motor boat. —v. Travel on water-skis. water-skier n.</p>"
            }
        ]
    },
    {
        "word": "Water-softener",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-softener <strong class='noun'> n. </strong> Apparatus for softening hard water.</p>"
            }
        ]
    },
    {
        "word": "Waterspout",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterspout <strong class='noun'> n. </strong> Gyrating column of water and spray between sea and cloud.</p>"
            }
        ]
    },
    {
        "word": "Water table",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water table <strong class='noun'> n. </strong> = *water-level 2.</p>"
            }
        ]
    },
    {
        "word": "Watertight",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watertight <strong class='adj'> adj. </strong> 1 closely fastened or fitted so as to prevent the passage of water. 2 (of an argument etc.) Unassailable.</p>"
            }
        ]
    },
    {
        "word": "Water-tower",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-tower <strong class='noun'> n. </strong> Tower with an elevated tank to give pressure for distributing water.</p>"
            }
        ]
    },
    {
        "word": "Water-vole",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-vole <strong class='noun'> n. </strong> Aquatic vole.</p>"
            }
        ]
    },
    {
        "word": "Waterway",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterway <strong class='noun'> n. </strong> Navigable channel.</p>"
            }
        ]
    },
    {
        "word": "Water-wheel",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-wheel <strong class='noun'> n. </strong> Wheel driven by water to work machinery, or to raise water.</p>"
            }
        ]
    },
    {
        "word": "Water-wings",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Water-wings <strong class='n_pl'> n.pl. </strong> Inflated floats fixed on the arms of a person learning to swim.</p>"
            }
        ]
    },
    {
        "word": "Waterworks",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waterworks <strong class='noun'> n. </strong> 1 establishment for managing a water-supply. 2 <strong class='colloq'> colloq. </strong> Shedding of tears. 3 <strong class='colloq'> colloq. </strong> Urinary system.</p>"
            }
        ]
    },
    {
        "word": "Watery",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watery <strong class='adj'> adj. </strong> 1 containing too much water. 2 too thin in consistency. 3 of or consisting of water. 4 vapid, uninteresting. 5 (of colour) pale. 6 (of the sun, moon, or sky) rainy-looking. 7 (of eyes) moist; tearful. wateriness n.</p>"
            }
        ]
    },
    {
        "word": "Watt",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watt <strong class='noun'> n. </strong> Si unit of power, equivalent to one joule per second, corresponding to the rate of energy in an electric circuit where the potential difference is one volt and the current one ampere. [watt, name of an engineer]</p>"
            }
        ]
    },
    {
        "word": "Wattage",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wattage <strong class='noun'> n. </strong> Amount of electrical power expressed in watts.</p>"
            }
        ]
    },
    {
        "word": "Watt-hour",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Watt-hour <strong class='noun'> n. </strong> Energy used when one watt is applied for one hour.</p>"
            }
        ]
    },
    {
        "word": "Wattle1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wattle1 <strong class='noun'> n. </strong> 1 structure of interlaced rods and sticks used for fences etc. 2 australian acacia with pliant branches and golden flowers used as the national emblem. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wattle2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wattle2 <strong class='noun'> n. </strong> Fleshy appendage on the head or throat of a turkey or other birds. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Wattle and daub",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wattle and daub <strong class='noun'> n. </strong> Network of rods and twigs plastered with clay or mud as a building material.</p>"
            }
        ]
    },
    {
        "word": "Wave",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wave <strong class='noun'> —v. </strong> <strong class='ving'> (-ving) </strong> 1 a <strong class='adj'> (often foll. By to) </strong> move a hand etc. To and fro in greeting or as a signal. B move (a hand etc.) In this way. 2 a show a sinuous or sweeping motion as of a flag, tree, corn, etc. In the wind. B impart a waving motion to. 3 direct (a person) by waving (waved them away; waved them to follow). 4 express (a greeting etc.) By waving. 5 give an undulating form to (hair etc.). 6 (of hair etc.) Have such a form. <strong class='noun'> —n. </strong> 1 ridge of water between two depressions. 2 long body of water curling into an arch and breaking on the shore. 3 thing compared to this, e.g. A body of persons in one of successive advancing groups. 4 gesture of waving. 5 a process of waving the hair. B undulating form produced by this. 6 temporary occurrence or increase of a condition or influence (wave of enthusiasm; heat wave). 7 physics a disturbance of the particles of esp. A fluid medium for the propagation or direction of motion, heat, light, sound, etc. B single curve in this motion. 8 undulating line or outline. make waves <strong class='colloq'> colloq. </strong> Cause trouble. Wave aside dismiss as intrusive or irrelevant. Wave down wave to (a vehicle or driver) to stop. [old english]</p>"
            }
        ]
    },
    {
        "word": "Waveband",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waveband <strong class='noun'> n. </strong> Range of radio wavelengths between certain limits.</p>"
            }
        ]
    },
    {
        "word": "Wave-form",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wave-form <strong class='noun'> n. </strong> Physics curve showing the shape of a wave at a given time.</p>"
            }
        ]
    },
    {
        "word": "Wavelength",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wavelength <strong class='noun'> n. </strong> 1 distance between successive crests of a wave. 2 this as a distinctive feature of radio waves from a transmitter. 3 <strong class='colloq'> colloq. </strong> Particular mode or range of thought.</p>"
            }
        ]
    },
    {
        "word": "Wavelet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wavelet <strong class='noun'> n. </strong> Small wave.</p>"
            }
        ]
    },
    {
        "word": "Wave machine",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wave machine <strong class='noun'> n. </strong> Device at a swimming-pool producing waves.</p>"
            }
        ]
    },
    {
        "word": "Waver",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waver <strong class='verb'> v. </strong> 1 be or become unsteady; begin to give way. 2 be irresolute. 3 (of a light) flicker. [old norse: related to *wave]</p>"
            }
        ]
    },
    {
        "word": "Wavy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wavy <strong class='adj'> adj. </strong> (-ier, -iest) having waves or alternate contrary curves. waviness n.</p>"
            }
        ]
    },
    {
        "word": "Wax1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wax1 <strong class='noun'> —n. </strong> 1 sticky plastic yellowish substance secreted by bees as the material of honey <strong class='n_pl'> comb. </strong> 2 this bleached and purified, used for candles, modelling, etc. 3 any similar substance, e.g. The yellow substance secreted by the ear. —v. 1 cover or treat with wax. 2 remove unwanted hair from (legs etc.) Using wax. waxy adj. (-ier, -iest). [old english]</p>"
            }
        ]
    },
    {
        "word": "Wax2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wax2 <strong class='verb'> v. </strong> 1 (of the moon) increase in apparent size. 2 become larger or stronger. 3 pass into a specified state or mood (wax lyrical). wax and wane undergo alternate increases and decreases. [old english]</p>"
            }
        ]
    },
    {
        "word": "Waxen",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waxen <strong class='adj'> adj. </strong> 1 smooth or pale like wax. 2 archaic made of wax.</p>"
            }
        ]
    },
    {
        "word": "Waxwing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waxwing <strong class='noun'> n. </strong> Any of various birds with tips like red sealing-wax to some wing-feathers.</p>"
            }
        ]
    },
    {
        "word": "Waxwork",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waxwork <strong class='noun'> n. </strong> 1 object, esp. A lifelike dummy, modelled in wax. 2 (in pl.) Exhibition of wax dummies.</p>"
            }
        ]
    },
    {
        "word": "Way",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Way <strong class='noun'> —n. </strong> 1 road, track, path, etc., for passing along. 2 course or route for reaching a place (asked the way to london; the way out). 3 method or plan for attaining an object. 4 style, manner (i like the way you dress). 5 person's chosen or habitual course of action. 6 normal course of events (that is always the way). 7 travelling distance; length traversed or to be traversed. 8 unimpeded opportunity or space to advance (make way). 9 advance in some direction; impetus, progress (under way). 10 being engaged in movement from place to place; time spent in this (on the way home). 11 specified direction (step this way). 12 <strong class='colloq'> colloq. </strong> Scope or range. 13 line of occupation or business. 14 specified condition or state (things are in a bad way). 15 respect (is useful in some ways). 16 (in pl.) Part into which a thing is divided (split it three ways). 17 (in pl.) Structure of timber etc. Down which a new ship is launched. <strong class='adv'> —adv. </strong> Colloq. Far (way off). by the way incidentally. By way of 1 by means of. 2 as a form of. 3 passing through. Come one's way become available to one. Get out of the (or my etc.) Way stop obstructing a person. Go out of one's way make a special effort. In a way to some extent. In the (or one's) way forming an obstruction. Lead the way act as guide or leader. Look the other way ignore what one should notice. On the (or one's) way 1 in the course of a journey etc. 2 having progressed. 3 <strong class='colloq'> colloq. </strong> (of a child) conceived but not yet born. On the way out <strong class='colloq'> colloq. </strong> Going out of fashion or favour. Out of the way 1 no longer an obstacle. 2 disposed of. 3 unusual. 4 (of a place) remote. [old english]</p>"
            }
        ]
    },
    {
        "word": "Way back",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Way back <strong class='adv'> adv. </strong> Colloq. Long ago.</p>"
            }
        ]
    },
    {
        "word": "Waybill",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waybill <strong class='noun'> n. </strong> List of passengers or parcels on a vehicle.</p>"
            }
        ]
    },
    {
        "word": "Wayfarer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wayfarer <strong class='noun'> n. </strong> Traveller, esp. On foot. wayfaring <strong class='noun'> n. </strong> & adj.</p>"
            }
        ]
    },
    {
        "word": "Waylay",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Waylay <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Waylaid) 1 lie in wait for. 2 stop to talk to or rob.</p>"
            }
        ]
    },
    {
        "word": "Way of life",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Way of life <strong class='noun'> n. </strong> Principles or habits governing all one's actions etc.</p>"
            }
        ]
    },
    {
        "word": "Way-out",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Way-out <strong class='adj'> adj. </strong> Colloq. Unusual; eccentric.</p>"
            }
        ]
    },
    {
        "word": "-ways",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>-ways <strong class='slang_v'> suffix </strong>forming adjectives and adverbs of direction or manner (sideways).</p>"
            }
        ]
    },
    {
        "word": "Ways and means",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Ways and means <strong class='n_pl'> n.pl. </strong> 1 methods of achieving something. 2 methods of raising government revenue.</p>"
            }
        ]
    },
    {
        "word": "Wayside",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wayside <strong class='noun'> n. </strong> 1 side of a road. 2 land at the side of a road.</p>"
            }
        ]
    },
    {
        "word": "Wayward",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wayward <strong class='adj'> adj. </strong> Childishly self-willed; capricious. waywardness <strong class='noun'> n. </strong> [from *away, *-ward]</p>"
            }
        ]
    },
    {
        "word": "Wb",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wb <strong class='sing'> abbr. </strong> Weber(s).</p>"
            }
        ]
    },
    {
        "word": "Wc",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wc <strong class='sing'> abbr. </strong> 1 water-closet. 2 west central.</p>"
            }
        ]
    },
    {
        "word": "W/cdr.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>W/cdr. <strong class='abbr'> Abbr. </strong> Wing commander.</p>"
            }
        ]
    },
    {
        "word": "We",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>We <strong class='pron'> pron. </strong> (obj. Us; <strong class='poss'> poss. </strong> Our, ours) 1 <strong class='pl'> pl. </strong> Of *i2. 2 used for or by a royal person in a proclamation etc. Or by an editor etc. In a formal context. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wea",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wea <strong class='sing'> abbr. </strong> Workers' educational association.</p>"
            }
        ]
    },
    {
        "word": "Weak",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weak <strong class='adj'> adj. </strong> 1 deficient in strength, power, vigour, resolution, or number. 2 unconvincing. 3 <strong class='gram'>gram.</strong> (of a verb) forming inflections by the addition of a suffix to the stem. weakish adj. [old norse]</p>"
            }
        ]
    },
    {
        "word": "Weaken",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weaken <strong class='verb'> v. </strong> Make or become weak or weaker.</p>"
            }
        ]
    },
    {
        "word": "Weak-kneed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weak-kneed <strong class='adj'> adj. </strong> Colloq. Lacking resolution.</p>"
            }
        ]
    },
    {
        "word": "Weakling",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weakling <strong class='noun'> n. </strong> Feeble person or animal.</p>"
            }
        ]
    },
    {
        "word": "Weakly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weakly <strong class='adv'> —adv. </strong> In a weak manner. <strong class='adj'> —adj.</strong> (-ier, -iest) sickly, not robust.</p>"
            }
        ]
    },
    {
        "word": "Weak-minded",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weak-minded <strong class='adj'> adj. </strong> 1 mentally deficient. 2 lacking in resolution.</p>"
            }
        ]
    },
    {
        "word": "Weak moment",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weak moment <strong class='noun'> n. </strong> Time when one is unusually compliant or susceptible.</p>"
            }
        ]
    },
    {
        "word": "Weakness",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weakness <strong class='noun'> n. </strong> 1 being weak. 2 weak point. 3 (foll. By for) self-indulgent liking (weakness for chocolate).</p>"
            }
        ]
    },
    {
        "word": "Weak point",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weak point <strong class='noun'> n. </strong> (also weak spot) 1 place where defences are assailable. 2 flaw in an argument or character or in resistance to temptation.</p>"
            }
        ]
    },
    {
        "word": "Weal1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weal1 <strong class='noun'> —n. </strong> Ridge raised on the flesh by a stroke of a rod or whip. —v. Mark with a weal. [var. Of *wale]</p>"
            }
        ]
    },
    {
        "word": "Weal2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weal2 <strong class='noun'> n. </strong> Literary welfare. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wealth",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wealth <strong class='noun'> n. </strong> 1 riches. 2 being rich. 3 (foll. By of) abundance. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wealthy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wealthy <strong class='adj'> adj. </strong> (-ier, -iest) having an abundance, esp. Of money.</p>"
            }
        ]
    },
    {
        "word": "Wean",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wean <strong class='verb'> v. </strong> 1 accustom (an infant or other young mammal) to food other than (esp. Its mother's) milk. 2 (often foll. By from, away from) disengage (from a habit etc.) By enforced discontinuance. [old english, = accustom]</p>"
            }
        ]
    },
    {
        "word": "Weapon",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weapon <strong class='noun'> n. </strong> 1 thing designed, used, or usable for inflicting bodily harm. 2 means for gaining the advantage in a conflict. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weaponry",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weaponry <strong class='noun'> n. </strong> Weapons collectively.</p>"
            }
        ]
    },
    {
        "word": "Wear",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wear <strong class='noun'> —v. </strong> (past wore;  <strong class='archaic_it_was'>past part.</strong> Worn) 1 have on one's person as clothing or an ornament etc. 2 exhibit or present (a facial expression etc.) (wore a frown). 3 <strong class='colloq'> colloq. </strong> (usu. With neg.) Tolerate. 4 (often foll. By away, down) a injure the surface of, or partly obliterate or alter, by rubbing, stress, or use. B undergo such injury or change. 5 (foll. By off, away) rub or be rubbed off. 6 make (a hole etc.) By constant rubbing or dripping etc. 7 (often foll. By out) exhaust. 8 (foll. By down) overcome by persistence. 9 (foll. By well etc.) Endure continued use or life. 10 (of time) pass, esp. Tediously. 11 (of a ship) fly (a flag). <strong class='noun'> —n. </strong> 1 wearing or being worn. 2 things worn; fashionable or suitable clothing (sportswear; footwear). 3 (in full wear and tear) damage from continuous use. wear one's heart on one's sleeve show one's feelings openly. Wear off lose effectiveness or intensity. Wear out 1 use or be used until useless. 2 tire or be tired out. Wear thin (of patience, excuses, etc.) Begin to fail. Wear the trousers see *trousers. wearer <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wearisome",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wearisome <strong class='adj'> adj. </strong> Tedious; tiring by monotony or length.</p>"
            }
        ]
    },
    {
        "word": "Weary",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weary <strong class='adj'> —adj.</strong> (-ier, -iest) 1 very tired after exertion or endurance. 2 (foll. By of) no longer interested in, tired of. 3 tiring, tedious. —v. (-ies, -ied) make or grow weary. wearily <strong class='adv'> adv. </strong> Weariness <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Weasel",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weasel <strong class='noun'> n. </strong> Small flesh-eating mammal related to the stoat and ferret. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weasel word",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weasel word <strong class='noun'> n. </strong> (usu. In pl.) Word that is intentionally ambiguous or misleading.</p>"
            }
        ]
    },
    {
        "word": "Weather",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weather <strong class='noun'> —n. </strong> 1 state of the atmosphere at a place and time as regards heat, cloudiness, dryness, sunshine, wind, and rain etc. 2 (attrib.) Naut. Windward. —v. 1 expose to or affect by atmospheric changes; season (wood). 2 be discoloured or worn in this way. 3 a come safely through (a storm). B survive (a difficult period etc.). 4 get to the windward of (a cape etc.). keep a weather eye open be watchful. Make heavy weather of <strong class='colloq'> colloq. </strong> Exaggerate the difficulty presented by. Under the weather <strong class='colloq'> colloq. </strong> Indisposed. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weather-beaten",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weather-beaten <strong class='adj'> adj. </strong> Affected by exposure to the weather.</p>"
            }
        ]
    },
    {
        "word": "Weatherboard",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weatherboard <strong class='noun'> n. </strong> 1 sloping board attached to the bottom of an outside door to keep out the rain etc. 2 each of a series of overlapping horizontal boards on a wall. weatherboarding <strong class='noun'> n. </strong> (in sense 2 of n.).</p>"
            }
        ]
    },
    {
        "word": "Weathercock",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weathercock <strong class='noun'> n. </strong> 1 weather-vane in the form of a cock. 2 inconstant person.</p>"
            }
        ]
    },
    {
        "word": "Weather forecast",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weather forecast <strong class='noun'> n. </strong> Assessment of likely weather.</p>"
            }
        ]
    },
    {
        "word": "Weatherman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weatherman <strong class='noun'> n. </strong> Meteorologist, esp. One who broadcasts a weather forecast.</p>"
            }
        ]
    },
    {
        "word": "Weatherproof",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weatherproof <strong class='adj'> adj. </strong> Resistant to the effects of bad weather, esp. Rain.</p>"
            }
        ]
    },
    {
        "word": "Weather-vane",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weather-vane <strong class='noun'> n. </strong> 1 revolving pointer on a church spire etc. To show the direction of the wind. 2 inconstant person.</p>"
            }
        ]
    },
    {
        "word": "Weave1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weave1 <strong class='noun'> —v. </strong> (-ving; past wove;  <strong class='archaic_it_was'>past part.</strong> Woven or wove) 1 a form (fabric) by interlacing long threads in two directions. B form (thread) into fabric in this way. 2 make fabric in this way. 3 a (foll. By into) make (facts etc.) Into a story or connected whole. B make (a story) in this way. <strong class='noun'> —n. </strong> Style of weaving. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weave2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weave2 <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> move repeatedly from side to side; take an intricate course to avoid obstructions. get weaving slang begin action; hurry. [old norse: related to *wave]</p>"
            }
        ]
    },
    {
        "word": "Weaver",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weaver <strong class='noun'> n. </strong> 1 person who weaves fabric. 2 (in full weaver-bird) tropical bird building elaborately woven nests.</p>"
            }
        ]
    },
    {
        "word": "Web",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Web <strong class='noun'> n. </strong> 1 a woven fabric. B amount woven in one piece. 2 complex series (web of lies). 3 cobweb, gossamer, or a similar product of a spinning creature. 4 membrane between the toes of a swimming animal or bird. 5 large roll of paper used in printing. 6 thin flat connecting part in machinery etc. webbed adj. [old english]</p>"
            }
        ]
    },
    {
        "word": "Webbing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Webbing <strong class='noun'> n. </strong> Strong narrow closely-woven fabric used for belts etc.</p>"
            }
        ]
    },
    {
        "word": "Weber",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weber <strong class='noun'> n. </strong> The si unit of magnetic flux. [weber, name of a physicist]</p>"
            }
        ]
    },
    {
        "word": "Web-footed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Web-footed <strong class='adj'> adj. </strong> Having the toes connected by webs.</p>"
            }
        ]
    },
    {
        "word": "Wed.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wed. <strong class='abbr'> Abbr. </strong> (also weds.) Wednesday.</p>"
            }
        ]
    },
    {
        "word": "Wed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wed <strong class='verb'> v. </strong> (-dd-; past and  <strong class='archaic_it_was'>past part.</strong> Wedded or wed) 1 usu. Formal or literary marry. 2 unite. 3 (as wedded adj.) Of or in marriage (wedded bliss). 4 (as wedded adj.) (foll. By to) obstinately attached or devoted to (a pursuit etc.). [old english, = pledge]</p>"
            }
        ]
    },
    {
        "word": "We'd",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>We'd <strong class='contr'> contr. </strong> 1 we had. 2 we should; we would.</p>"
            }
        ]
    },
    {
        "word": "Wedding",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wedding <strong class='noun'> n. </strong> Marriage ceremony. [old english: related to *wed]</p>"
            }
        ]
    },
    {
        "word": "Wedding breakfast",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wedding breakfast <strong class='noun'> n. </strong> Meal etc. Between a wedding and departure for the honeymoon.</p>"
            }
        ]
    },
    {
        "word": "Wedding cake",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wedding cake <strong class='noun'> n. </strong> Rich iced cake served at a wedding reception.</p>"
            }
        ]
    },
    {
        "word": "Wedding ring",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wedding ring <strong class='noun'> n. </strong> Ring worn by a married person.</p>"
            }
        ]
    },
    {
        "word": "Wedge",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wedge <strong class='noun'> —n. </strong> 1 piece of tapering wood or metal etc. Driven between two objects or parts to secure or separate them. 2 anything resembling a wedge. 3 golf club with a wedge-shaped head. —v. (-ging) 1 secure or fasten with a wedge. 2 force open or apart with a wedge. 3 (foll. By in, into) pack or thrust (a thing or oneself) tightly in or into. thin end of the wedge <strong class='colloq'> colloq. </strong> Thing of little importance in itself, but likely to lead to more serious developments. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wedgwood",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wedgwood <strong class='noun'> n. </strong> Propr. 1 a kind of fine stoneware usu. With a white cameo design. 2 its characteristic blue colour. [wedgwood, name of a potter]</p>"
            }
        ]
    },
    {
        "word": "Wedlock",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wedlock <strong class='noun'> n. </strong> The married state. born in (or out of) wedlock born of married (or unmarried) parents. [old english, = marriage vow]</p>"
            }
        ]
    },
    {
        "word": "Wednesday",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wednesday <strong class='noun'> —n. </strong> Day of the week following tuesday. <strong class='adv'> —adv. </strong> Colloq. 1 on wednesday. 2 (wednesdays) on wednesdays; each wednesday. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weds.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weds. <strong class='abbr'> Abbr. </strong> Var. Of *wed.</p>"
            }
        ]
    },
    {
        "word": "Wee1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wee1 <strong class='adj'> adj. </strong> (weer; weest) 1 esp. Scot. Little. 2 <strong class='colloq'> colloq. </strong> Tiny. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wee2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wee2 <strong class='noun'> n. </strong> Colloq. = *wee-wee.</p>"
            }
        ]
    },
    {
        "word": "Weed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weed <strong class='noun'> —n. </strong> 1 wild plant growing where it is not wanted. 2 thin weak-looking person or horse. 3 (prec. By the) slang a marijuana. B tobacco. —v. 1 a clear (an area) of weeds. B remove unwanted parts from. 2 (foll. By out) a sort out and remove (inferior or unwanted parts etc.). B rid of inferior parts, unwanted members, etc. 3 cut off or uproot weeds. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weed-killer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weed-killer <strong class='noun'> n. </strong> Chemical used to destroy weeds.</p>"
            }
        ]
    },
    {
        "word": "Weeds",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weeds <strong class='n_pl'> n.pl. </strong> (in full widow's weeds) archaic deep mourning worn by a widow. [old english, = garment]</p>"
            }
        ]
    },
    {
        "word": "Weedy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weedy <strong class='adj'> adj. </strong> (-ier, -iest) 1 weak, feeble. 2 having many weeds.</p>"
            }
        ]
    },
    {
        "word": "Week",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Week <strong class='noun'> n. </strong> 1 period of seven days reckoned usu. From midnight on saturday. 2 any period of seven days. 3 the six days between sundays. 4 a the five days monday to friday. B time spent working in this period (35-hour week; three-day week). [old english]</p>"
            }
        ]
    },
    {
        "word": "Weekday",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weekday <strong class='noun'> n. </strong> Day other than sunday or saturday and sunday.</p>"
            }
        ]
    },
    {
        "word": "Weekend",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weekend <strong class='noun'> n. </strong> 1 sunday and saturday or part of saturday. 2 this period extended slightly esp. For a holiday or visit etc.</p>"
            }
        ]
    },
    {
        "word": "Weekender",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weekender <strong class='noun'> n. </strong> Person who spends the weekend away from home; weekend visitor.</p>"
            }
        ]
    },
    {
        "word": "Weekly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weekly <strong class='adj'> —adj.</strong> Done, produced, or occurring once a week. <strong class='adv'> —adv. </strong> Once a week. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> weekly newspaper or periodical.</p>"
            }
        ]
    },
    {
        "word": "Weeny",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weeny <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Tiny. [from *wee1]</p>"
            }
        ]
    },
    {
        "word": "Weep",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weep <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Wept) 1 shed tears. 2 (often foll. By for) bewail, lament over. 3 a be covered with or send forth drops. B come or send forth in drops; exude liquid. 4 (as weeping adj.) (of a tree) having drooping branches. <strong class='noun'> —n. </strong> Spell of weeping. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weepie",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weepie <strong class='noun'> n. </strong> Colloq. Sentimental or emotional film, play, etc.</p>"
            }
        ]
    },
    {
        "word": "Weepy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weepy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Inclined to weep; tearful.</p>"
            }
        ]
    },
    {
        "word": "Weevil",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weevil <strong class='noun'> n. </strong> Destructive beetle feeding esp. On grain. [low german]</p>"
            }
        ]
    },
    {
        "word": "Wee-wee",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wee-wee <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> 1 act of urinating. 2 urine. —v. (-wees, -weed) urinate. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Weft",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weft <strong class='noun'> n. </strong> 1 threads woven across a warp to make fabric. 2 yarn for these. 3 thing woven. [old english: related to *weave1]</p>"
            }
        ]
    },
    {
        "word": "Weigh",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weigh <strong class='verb'> v. </strong> 1 find the weight of. 2 balance in the hands to guess or as if to guess the weight of. 3 (often foll. By out) take a definite weight of (a substance); measure out (a specified weight) (weigh out the flour; weigh out 6 oz). 4 a estimate the relative value, importance, or desirability of. B (foll. By with, against) compare. 5 be equal to (a specified weight). 6 have (esp. A specified) importance; exert an influence. 7 (often foll. By on) be heavy or burdensome (to); be depressing (to). weigh down 1 bring down by exerting weight. 2 be oppressive to. Weigh in (of a boxer before a contest, or a jockey after a race) be weighed. Weigh in with <strong class='colloq'> colloq. </strong> Advance (an argument etc.) Boldly. Weigh out (of a jockey) be weighed before a race. Weigh up <strong class='colloq'> colloq. </strong> Form an estimate of. Weigh one's words carefully choose the way one expresses something. [old english, = carry]</p>"
            }
        ]
    },
    {
        "word": "Weighbridge",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weighbridge <strong class='noun'> n. </strong> Weighing-machine for vehicles.</p>"
            }
        ]
    },
    {
        "word": "Weigh-in",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weigh-in <strong class='noun'> n. </strong> Weighing of a boxer before a fight.</p>"
            }
        ]
    },
    {
        "word": "Weight",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weight <strong class='noun'> —n. </strong> 1 force experienced by a body as a result of the earth's gravitation. 2 heaviness of a body regarded as a property of it. 3 a quantitative expression of a body's weight. B scale of such weights (troy weight). 4 body of a known weight for use in weighing or weight training. 5 heavy body, esp. As used in a mechanism etc. 6 load or burden. 7 influence, importance. 8 athletics = *shot1 7. —v. 1 a attach a weight to. B hold down with a weight. 2 (foll. By with) impede or burden. throw one's weight about (or around) <strong class='colloq'> colloq. </strong> Be unpleasantly self-assertive. Worth one's weight in gold very useful or helpful. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weighting",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weighting <strong class='noun'> n. </strong> Extra allowance paid in special cases.</p>"
            }
        ]
    },
    {
        "word": "Weightless",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weightless <strong class='adj'> adj. </strong> (of a body, esp. In an orbiting spacecraft etc.) Not apparently acted on by gravity. weightlessness n.</p>"
            }
        ]
    },
    {
        "word": "Weightlifting",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weightlifting <strong class='noun'> n. </strong> Sport of lifting heavy weights. weightlifter n.</p>"
            }
        ]
    },
    {
        "word": "Weight training",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weight training <strong class='noun'> n. </strong> Physical training using weights.</p>"
            }
        ]
    },
    {
        "word": "Weighty",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weighty <strong class='adj'> adj. </strong> (-ier, -iest) 1 heavy. 2 momentous. 3 (of utterances etc.) Deserving consideration. 4 influential, authoritative. weightily <strong class='adv'> adv. </strong> Weightiness n.</p>"
            }
        ]
    },
    {
        "word": "Weir",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weir <strong class='noun'> n. </strong> Dam across a river to raise the level of water upstream or regulate its flow. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weird",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weird <strong class='adj'> adj. </strong> 1 uncanny, supernatural. 2 <strong class='colloq'> colloq. </strong> Queer, incomprehensible. weirdly <strong class='adv'> adv. </strong> Weirdness <strong class='noun'> n. </strong> [old english wyrd destiny]</p>"
            }
        ]
    },
    {
        "word": "Weirdo",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weirdo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='colloq'> colloq. </strong> Odd or eccentric person.</p>"
            }
        ]
    },
    {
        "word": "Welch",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welch <strong class='var'> var. </strong> Of *welsh.</p>"
            }
        ]
    },
    {
        "word": "Welcome",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welcome <strong class='noun'> —n. </strong> Act of greeting or receiving gladly; kind or glad reception. <strong class='pl'> —int. </strong> Expressing such a greeting. —v. (-ming) receive with a welcome. <strong class='adj'> —adj.</strong> 1 that one receives with pleasure (welcome guest; welcome news). 2 (foll. By to, or to + infin.) Cordially allowed or invited (you are welcome to use my car). make welcome receive hospitably. Outstay one's welcome stay too long as a visitor etc. You are welcome there is no need for thanks. [old english]</p>"
            }
        ]
    },
    {
        "word": "Weld",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weld <strong class='noun'> —v. </strong> 1 a hammer or press (pieces of iron or other metal usu. Heated but not melted) into one piece. B join by fusion with an electric arc etc. C form by welding into some article. 2 fashion into an effectual or homogeneous whole. <strong class='noun'> —n. </strong> Welded joint. welder <strong class='noun'> n. </strong> [alteration of *well2, probably influenced by the form welled]</p>"
            }
        ]
    },
    {
        "word": "Welfare",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welfare <strong class='noun'> n. </strong> 1 well-being, happiness; health and prosperity (of a person or community etc.). 2 (welfare) a welfare centre or office. B financial support given by the state. [from *well1, *fare]</p>"
            }
        ]
    },
    {
        "word": "Welfare state",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welfare state <strong class='noun'> n. </strong> 1 system whereby the state undertakes to protect the health and well-being of its citizens by means of grants, pensions, etc. 2 country practising this system.</p>"
            }
        ]
    },
    {
        "word": "Welfare work",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welfare work <strong class='noun'> n. </strong> Organized effort for the welfare of the poor, disabled, etc.</p>"
            }
        ]
    },
    {
        "word": "Welkin",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welkin <strong class='noun'> n. </strong> Poet. Sky. [old english, = cloud]</p>"
            }
        ]
    },
    {
        "word": "Well1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well1 <strong class='adv'> —adv. </strong> (better, best) 1 in a satisfactory way (works well). 2 with some distinction (plays the piano well). 3 in a kind way (treated me well). 4 thoroughly, carefully (polish it well). 5 with heartiness or approval (speak well of). 6 probably, reasonably (you may well be right). 7 to a considerable extent (is well over forty). <strong class='adj'> —adj.</strong> (better, best) 1 (usu. Predic.) In good health. 2 (predic.) A in a satisfactory state or position. B advisable (it would be well to enquire). <strong class='pl'> —int. </strong> Expressing surprise, resignation, etc., or used to introduce speech. leave well alone avoid needless change or disturbance. Well and truly decisively, completely. Well away 1 having made considerable progress. 2 <strong class='colloq'> colloq. </strong> Fast asleep or drunk. Well done! Expressing praise for something done. Well worth certainly worth. [old english]</p>"
            }
        ]
    },
    {
        "word": "Well2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well2 <strong class='noun'> —n. </strong> 1 shaft sunk into the ground to obtain water, oil, etc. 2 enclosed space like a well-shaft, e.g. In the middle of a building for stairs or a lift, or for light or ventilation. 3 (foll. By of) source. 4 (in pl.) Spa. 5 = *ink-well. 6 archaic water-spring. 7 railed space in a lawcourt. —v. (foll. By out, up) spring as from a fountain. [old english]</p>"
            }
        ]
    },
    {
        "word": "We'll",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>We'll <strong class='contr'> contr. </strong> We shall; we will.</p>"
            }
        ]
    },
    {
        "word": "Well-adjusted",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-adjusted <strong class='adj'> adj. </strong> 1 mentally and emotionally stable. 2 in a good state of adjustment.</p>"
            }
        ]
    },
    {
        "word": "Well-advised",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-advised <strong class='adj'> adj. </strong> (usu. Foll. By to + infin.) Prudent.</p>"
            }
        ]
    },
    {
        "word": "Well-appointed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-appointed <strong class='adj'> adj. </strong> Having all the necessary equipment.</p>"
            }
        ]
    },
    {
        "word": "Well-attended",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-attended <strong class='adj'> adj. </strong> Attended by a large number of people.</p>"
            }
        ]
    },
    {
        "word": "Well-balanced",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-balanced <strong class='adj'> adj. </strong> Sane, sensible.</p>"
            }
        ]
    },
    {
        "word": "Well-behaved",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-behaved <strong class='adj'> adj. </strong> Habitually behaving well.</p>"
            }
        ]
    },
    {
        "word": "Well-being",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-being <strong class='noun'> n. </strong> State of being contented, healthy, etc.</p>"
            }
        ]
    },
    {
        "word": "Well-born",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-born <strong class='adj'> adj. </strong> Of noble family.</p>"
            }
        ]
    },
    {
        "word": "Well-bred",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-bred <strong class='adj'> adj. </strong> Having or showing good breeding or manners.</p>"
            }
        ]
    },
    {
        "word": "Well-built",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-built <strong class='adj'> adj. </strong> Big, strong, and well-proportioned.</p>"
            }
        ]
    },
    {
        "word": "Well-connected",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-connected <strong class='adj'> adj. </strong> Associated, esp. By birth, with persons of good social position.</p>"
            }
        ]
    },
    {
        "word": "Well-disposed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-disposed <strong class='adj'> adj. </strong> (often foll. By towards) friendly or sympathetic.</p>"
            }
        ]
    },
    {
        "word": "Well-dressed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-dressed <strong class='adj'> adj. </strong> Fashionably smart.</p>"
            }
        ]
    },
    {
        "word": "Well-earned",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-earned <strong class='adj'> adj. </strong> Fully deserved.</p>"
            }
        ]
    },
    {
        "word": "Well-founded",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-founded <strong class='adj'> adj. </strong> (of suspicions etc.) Based on good evidence.</p>"
            }
        ]
    },
    {
        "word": "Well-groomed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-groomed <strong class='adj'> adj. </strong> With carefully tended hair, clothes, etc.</p>"
            }
        ]
    },
    {
        "word": "Well-head",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-head <strong class='noun'> n. </strong> Source.</p>"
            }
        ]
    },
    {
        "word": "Well-heeled",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-heeled <strong class='adj'> adj. </strong> Colloq. Wealthy.</p>"
            }
        ]
    },
    {
        "word": "Wellies",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wellies <strong class='n_pl'> n.pl. </strong> Colloq. Wellingtons. [abbreviation]</p>"
            }
        ]
    },
    {
        "word": "Well-informed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-informed <strong class='adj'> adj. </strong> Having much knowledge or information about a subject.</p>"
            }
        ]
    },
    {
        "word": "Wellington",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wellington <strong class='noun'> n. </strong> (in full wellington boot) waterproof boot usu. Reaching the knee. [duke of wellington]</p>"
            }
        ]
    },
    {
        "word": "Well-intentioned",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-intentioned <strong class='adj'> adj. </strong> Having or showing good intentions.</p>"
            }
        ]
    },
    {
        "word": "Well-judged",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-judged <strong class='adj'> adj. </strong> Opportunely, skilfully, or discreetly done.</p>"
            }
        ]
    },
    {
        "word": "Well-kept",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-kept <strong class='adj'> adj. </strong> Kept in good order or condition.</p>"
            }
        ]
    },
    {
        "word": "Well-known",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-known <strong class='adj'> adj. </strong> Known to many.</p>"
            }
        ]
    },
    {
        "word": "Well-made",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-made <strong class='adj'> adj. </strong> 1 strongly manufactured. 2 having a good build.</p>"
            }
        ]
    },
    {
        "word": "Well-mannered",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-mannered <strong class='adj'> adj. </strong> Having good manners.</p>"
            }
        ]
    },
    {
        "word": "Well-meaning",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-meaning <strong class='adj'> adj. </strong> (also well-meant) well-intentioned (but ineffective).</p>"
            }
        ]
    },
    {
        "word": "Wellnigh",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wellnigh <strong class='adv'> adv. </strong> Almost (wellnigh impossible).</p>"
            }
        ]
    },
    {
        "word": "Well off",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well off <strong class='adj'> adj. </strong> (also well-off) 1 having plenty of money. 2 in a fortunate situation.</p>"
            }
        ]
    },
    {
        "word": "Well-oiled",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-oiled <strong class='adj'> adj. </strong> Colloq. Very drunk.</p>"
            }
        ]
    },
    {
        "word": "Well-paid",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-paid <strong class='adj'> adj. </strong> 1 (of a job) that pays well. 2 (of a person) amply rewarded for a job.</p>"
            }
        ]
    },
    {
        "word": "Well-preserved",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-preserved <strong class='adj'> adj. </strong> 1 in good condition. 2 (of an old person) showing little sign of age.</p>"
            }
        ]
    },
    {
        "word": "Well-read",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-read <strong class='adj'> adj. </strong> Knowledgeable through much reading.</p>"
            }
        ]
    },
    {
        "word": "Well-received",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-received <strong class='adj'> adj. </strong> Welcomed; favourably received.</p>"
            }
        ]
    },
    {
        "word": "Well-rounded",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-rounded <strong class='adj'> adj. </strong> Complete and symmetrical.</p>"
            }
        ]
    },
    {
        "word": "Well-spoken",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-spoken <strong class='adj'> adj. </strong> Articulate or refined in speech.</p>"
            }
        ]
    },
    {
        "word": "Well-spring",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-spring <strong class='noun'> n. </strong> = *well-head.</p>"
            }
        ]
    },
    {
        "word": "Well-to-do",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-to-do <strong class='adj'> adj. </strong> Prosperous.</p>"
            }
        ]
    },
    {
        "word": "Well-tried",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-tried <strong class='adj'> adj. </strong> Often tested with good results.</p>"
            }
        ]
    },
    {
        "word": "Well-trodden",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-trodden <strong class='adj'> adj. </strong> Much frequented.</p>"
            }
        ]
    },
    {
        "word": "Well-wisher",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-wisher <strong class='noun'> n. </strong> Person who wishes one well.</p>"
            }
        ]
    },
    {
        "word": "Well-worn",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Well-worn <strong class='adj'> adj. </strong> 1 much worn by use. 2 (of a phrase etc.) Trite.</p>"
            }
        ]
    },
    {
        "word": "Welsh",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welsh <strong class='adj'> —adj.</strong> Of or relating to wales or its people or language. <strong class='noun'> —n. </strong> 1 the celtic language of wales. 2 (prec. By the; treated as pl.) The people of wales. [old english, ultimately from latin volcae, name of a celtic people]</p>"
            }
        ]
    },
    {
        "word": "Welsh",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welsh <strong class='verb'> v. </strong> (also welch) 1 (of a loser of a bet, esp. A bookmaker) decamp without paying. 2 evade an obligation. 3 (foll. By on) a fail to carry out a promise to (a person). B fail to honour (an obligation). [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Welshman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welshman <strong class='noun'> n. </strong> Man who is welsh by birth or descent.</p>"
            }
        ]
    },
    {
        "word": "Welsh rabbit",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welsh rabbit <strong class='noun'> n. </strong> (also, by folk etymology, welsh rarebit) dish of melted cheese etc. On toast.</p>"
            }
        ]
    },
    {
        "word": "Welshwoman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welshwoman <strong class='noun'> n. </strong> Woman who is welsh by birth or descent.</p>"
            }
        ]
    },
    {
        "word": "Welt",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welt <strong class='noun'> —n. </strong> 1 leather rim sewn round the edge of a shoe-upper for the sole to be attached to. 2 = *weal1. 3 ribbed or reinforced border of a garment. 4 heavy blow. —v. 1 provide with a welt. 2 raise weals on; thrash. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Welter1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welter1 <strong class='noun'> —v. </strong> 1 roll, wallow. 2 (foll. By in) lie prostrate or be soaked in. <strong class='noun'> —n. </strong> 1 general confusion. 2 (foll. By of) disorderly mixture or contrast. [low german or dutch]</p>"
            }
        ]
    },
    {
        "word": "Welter2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welter2 <strong class='noun'> n. </strong> Heavy rider or boxer. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Welterweight",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Welterweight <strong class='noun'> n. </strong> 1 weight in certain sports intermediate between lightweight and middleweight, in the amateur boxing scale 63.5–67 kg. 2 sportsman of this weight.</p>"
            }
        ]
    },
    {
        "word": "Wen",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wen <strong class='noun'> n. </strong> Benign tumour on the skin, esp. On the scalp. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wench",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wench <strong class='noun'> n. </strong> Joc. Girl or young woman. [abbreviation of wenchel, from old english, = child]</p>"
            }
        ]
    },
    {
        "word": "Wend",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wend <strong class='verb'> v. </strong> wend one's way make one's way. [old english, = turn]</p>"
            }
        ]
    },
    {
        "word": "Wendy house",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wendy house <strong class='noun'> n. </strong> Children's small houselike tent or structure for playing in. [wendy, name of a character in barrie's peter pan]</p>"
            }
        ]
    },
    {
        "word": "Went  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Went  <strong class='slang_v'>past of *</strong> go1.</p>"
            }
        ]
    },
    {
        "word": "Wept  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wept  <strong class='slang_v'>past of *</strong> weep.</p>"
            }
        ]
    },
    {
        "word": "Were  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Were  <strong class='slang_v'>past of *</strong>, <strong class='pl'> pl. </strong> Past, and past subjunctive of *be.</p>"
            }
        ]
    },
    {
        "word": "We're",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>We're <strong class='contr'> contr. </strong> We are.</p>"
            }
        ]
    },
    {
        "word": "Weren't",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Weren't <strong class='contr'> contr. </strong> Were not.</p>"
            }
        ]
    },
    {
        "word": "Werewolf",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Werewolf <strong class='noun'> n. </strong> (pl. -wolves) mythical being who at times changes from a person to a wolf. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wesleyan",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wesleyan <strong class='adj'> —adj.</strong> Of or relating to a protestant denomination founded by john wesley. <strong class='noun'> —n. </strong> Member of this denomination.</p>"
            }
        ]
    },
    {
        "word": "West",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>West <strong class='noun'> —n. </strong> 1 a point of the horizon where the sun sets at the equinoxes. B compass point corresponding to this. C direction in which this lies. 2 (usu. The west) a european civilization. B states of western europe and <strong class='noun'> n. </strong> America. C western part of a country, town, etc. <strong class='adj'> —adj.</strong> 1 towards, at, near, or facing the west. 2 from the west (west wind). <strong class='adv'> —adv. </strong> 1 towards, at, or near the west. 2 (foll. By of) further west than. go west slang be killed or destroyed etc. To the west (often followed by of) in a westerly direction. [old english]</p>"
            }
        ]
    },
    {
        "word": "Westbound",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Westbound <strong class='adj'> adj. </strong> Travelling or leading westwards.</p>"
            }
        ]
    },
    {
        "word": "West country",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>West country <strong class='noun'> n. </strong> South-western england.</p>"
            }
        ]
    },
    {
        "word": "West end",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>West end <strong class='noun'> n. </strong> Main entertainment and shopping area of london.</p>"
            }
        ]
    },
    {
        "word": "Westering",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Westering <strong class='adj'> adj. </strong> (of the sun) nearing the west.</p>"
            }
        ]
    },
    {
        "word": "Westerly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Westerly <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> 1 in a western position or direction. 2 (of a wind) from the west. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> such a wind.</p>"
            }
        ]
    },
    {
        "word": "Western",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Western <strong class='adj'> —adj.</strong> Of or in the west. <strong class='noun'> —n. </strong> Film or novel about cowboys in western north america. westernmost adj.</p>"
            }
        ]
    },
    {
        "word": "Westerner",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Westerner <strong class='noun'> n. </strong> Native or inhabitant of the west.</p>"
            }
        ]
    },
    {
        "word": "Westernize",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Westernize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) influence with, or convert to, the ideas and customs etc. Of the west.</p>"
            }
        ]
    },
    {
        "word": "West indian",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>West indian <strong class='noun'> n. </strong> 1 native or national of the west indies. 2 person of west indian descent.</p>"
            }
        ]
    },
    {
        "word": "West-north-west",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>West-north-west <strong class='noun'> n. </strong> Point or direction midway between west and north-west.</p>"
            }
        ]
    },
    {
        "word": "West side",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>West side <strong class='noun'> n. </strong> Us western part of manhattan.</p>"
            }
        ]
    },
    {
        "word": "West-south-west",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>West-south-west <strong class='noun'> n. </strong> Point or direction midway between west and south-west.</p>"
            }
        ]
    },
    {
        "word": "Westward",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Westward <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> (also westwards) towards the west. <strong class='noun'> —n. </strong> Westward direction or region.</p>"
            }
        ]
    },
    {
        "word": "Wet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wet <strong class='adj'> —adj.</strong> (wetter, wettest) 1 soaked or covered with water or other liquid. 2 (of the weather etc.) Rainy. 3 (of paint etc.) Not yet dried. 4 used with water (wet shampoo). 5 <strong class='colloq'> colloq. </strong> Feeble, inept. —v. (-tt-; past and  <strong class='archaic_it_was'>past part.</strong> Wet or wetted) 1 make wet. 2 a urinate in or on (wet the bed).</p>"
            }
        ]
    },
    {
        "word": "Wet blanket",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wet blanket <strong class='noun'> n. </strong> Colloq. Gloomy person hindering others' enjoyment.</p>"
            }
        ]
    },
    {
        "word": "Wet dream",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wet dream <strong class='noun'> n. </strong> Erotic dream with the involuntary ejaculation of semen.</p>"
            }
        ]
    },
    {
        "word": "Wether",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wether <strong class='noun'> n. </strong> Castrated ram. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wetland",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wetland <strong class='noun'> n. </strong> (often in pl.) Swamps and other damp areas of land.</p>"
            }
        ]
    },
    {
        "word": "Wet-nurse",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wet-nurse <strong class='noun'> —n. </strong> Woman employed to suckle another's child. —v. 1 act as a wet-nurse to. 2 <strong class='colloq'> colloq. </strong> Treat as if helpless.</p>"
            }
        ]
    },
    {
        "word": "Wet suit",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wet suit <strong class='noun'> n. </strong> Rubber garment worn by skin-divers etc. To keep warm.</p>"
            }
        ]
    },
    {
        "word": "We've",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>We've <strong class='contr'> contr. </strong> We have.</p>"
            }
        ]
    },
    {
        "word": "Wg. Cdr.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wg. Cdr. <strong class='abbr'> Abbr. </strong> Wing commander.</p>"
            }
        ]
    },
    {
        "word": "Whack",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whack <strong class='colloq'> colloq. </strong> —v. 1 strike or beat forcefully. 2 (as whacked adj.) Tired out. <strong class='noun'> —n. </strong> 1 sharp or resounding blow. 2 slang share. have a whack at slang attempt. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Whacking",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whacking <strong class='colloq'> colloq. </strong> <strong class='adj'> —adj.</strong> Very large. <strong class='adv'> —adv. </strong> Very.</p>"
            }
        ]
    },
    {
        "word": "Whale",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whale <strong class='noun'> —n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> very large marine mammal with a streamlined body and horizontal tail. —v. (-ling) hunt whales. a whale of a <strong class='colloq'> colloq. </strong> An exceedingly good or fine etc. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whalebone",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whalebone <strong class='noun'> n. </strong> Elastic horny substance in the upper jaw of some whales.</p>"
            }
        ]
    },
    {
        "word": "Whale-oil",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whale-oil <strong class='noun'> n. </strong> Oil from the blubber of whales.</p>"
            }
        ]
    },
    {
        "word": "Whaler",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whaler <strong class='noun'> n. </strong> Whaling ship or seaman.</p>"
            }
        ]
    },
    {
        "word": "Wham",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wham <strong class='n_pl'> int. </strong> Colloq. Expressing forcible impact. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Wharf",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wharf <strong class='noun'> —n. </strong> (pl. Wharves or -s) quayside area to which a ship may be moored to load and unload. —v. 1 moor (a ship) at a wharf. 2 store (goods) on a wharf. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wharfage",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wharfage <strong class='noun'> n. </strong> 1 accommodation at a wharf. 2 fee for this.</p>"
            }
        ]
    },
    {
        "word": "What  —interrog.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>What —interrog. <strong class='adj'> Adj. </strong> 1 asking for a choice from an indefinite number or for a statement of amount, number, or kind (what books have you read?). 2 <strong class='colloq'> colloq. </strong> = *which interrog.adj. (what book have you chosen?). <strong class='adj'> —adj.</strong> (usu. In an exclamation) how great or remarkable (what luck!). —rel. <strong class='adj'> Adj. </strong> The or any … that (will give you what help i can). <strong class='pron'> — <strong class='pron'> pron. </strong> </strong> (corresponding to the functions of the adj.) 1 what thing or things? (what is your name?; i don't know what you mean). 2 (asking for a remark to be repeated) = what did you say? 3 how much (what you must have suffered!). 4 (as rel. <strong class='pron'> Pron. </strong>) That or those which; a or the or any thing which (what followed was worse; tell me what you think). <strong class='adv'> —adv. </strong> To what extent (what does it matter?). what about what is the news or your opinion of. What-d'you-call-it <strong class='colloq'> colloq. </strong> Substitute for a name not recalled. What ever what at all or in any way (what ever do you mean?) (see also *whatever). What for <strong class='colloq'> colloq. </strong> 1 for what reason? 2 severe reprimand (esp. Give a person what for). What have you (prec. By or or and) <strong class='colloq'> colloq. </strong> Anything else similar. What not (prec. By and) other similar things. What's-his (or -her or -its) -name <strong class='colloq'> colloq. </strong> Substitute for a name not recalled. What's what <strong class='colloq'> colloq. </strong> What is useful or important etc. What with <strong class='colloq'> colloq. </strong> Because of (usu. Several things). [old english]</p>"
            }
        ]
    },
    {
        "word": "Whatever",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whatever <strong class='adj'> adj. </strong> & <strong class='pron'> pron. </strong> 1 = *what (in relative uses) with the emphasis on indefiniteness (lend me whatever you can; whatever money you have). 2 though anything (we are safe whatever happens). 3 (with neg. Or interrog.) At all; of any kind (there is no doubt whatever).</p>"
            }
        ]
    },
    {
        "word": "Whatnot",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whatnot <strong class='noun'> n. </strong> Colloq. Indefinite or trivial thing.</p>"
            }
        ]
    },
    {
        "word": "Whatsoever",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whatsoever <strong class='adj'> adj. </strong> & <strong class='pron'> pron. </strong> = *whatever.</p>"
            }
        ]
    },
    {
        "word": "Wheat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheat <strong class='noun'> n. </strong> 1 cereal plant bearing dense four-sided seed-spikes. 2 its grain, used in making flour etc. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wheatear",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheatear <strong class='noun'> n. </strong> Small migratory bird. [related to *white, *arse]</p>"
            }
        ]
    },
    {
        "word": "Wheaten",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheaten <strong class='adj'> adj. </strong> Made of wheat.</p>"
            }
        ]
    },
    {
        "word": "Wheat germ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheat germ <strong class='noun'> n. </strong> Embryo of the wheat grain, extracted as a source of vitamins.</p>"
            }
        ]
    },
    {
        "word": "Wheatmeal",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheatmeal <strong class='noun'> n. </strong> Flour made from wheat with some of the bran and germ removed.</p>"
            }
        ]
    },
    {
        "word": "Wheedle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheedle <strong class='verb'> v. </strong> (-ling) 1 coax by flattery or endearments. 2 (foll. By out) get (a thing) out of a person or cheat (a person) out of a thing by wheedling. [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Wheel",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheel <strong class='noun'> —n. </strong> 1 circular frame or disc which revolves on an axle and is used for vehicular or other mechanical motion. 2 wheel-like thing. 3 motion as of a wheel, esp. The movement of a line of soldiers with one end as a pivot. 4 (in pl.) Slang car. 5 = *steering-wheel. —v. 1 a turn on an axis or pivot. B swing round in line with one end as a pivot. 2 a (often foll. By about, round) change direction or face another way. B cause to do this. 3 push or pull (a wheeled thing, or its load or occupant). 4 go in circles or curves. at the wheel 1 driving a vehicle. 2 directing a ship. 3 in control. On wheels (or oiled wheels) smoothly. Wheel and deal engage in political or commercial scheming. Wheels within wheels 1 intricate machinery. 2 <strong class='colloq'> colloq. </strong> Indirect or secret agencies. wheeled adj. (also in <strong class='n_pl'> comb. </strong> ). [old english]</p>"
            }
        ]
    },
    {
        "word": "Wheelbarrow",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheelbarrow <strong class='noun'> n. </strong> Small handcart with one wheel and two shafts.</p>"
            }
        ]
    },
    {
        "word": "Wheelbase",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheelbase <strong class='noun'> n. </strong> Distance between the axles of a vehicle.</p>"
            }
        ]
    },
    {
        "word": "Wheelchair",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheelchair <strong class='noun'> n. </strong> Chair on wheels for an invalid or disabled person.</p>"
            }
        ]
    },
    {
        "word": "Wheel-clamp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheel-clamp <strong class='noun'> n. </strong> = *clamp1 <strong class='noun'> n. </strong> 2.</p>"
            }
        ]
    },
    {
        "word": "-wheeler",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>-wheeler <strong class='n_pl'> comb. </strong> Form vehicle with a specified number of wheels (three-wheeler).</p>"
            }
        ]
    },
    {
        "word": "Wheeler-dealer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheeler-dealer <strong class='noun'> n. </strong> Person who wheels and deals.</p>"
            }
        ]
    },
    {
        "word": "Wheel-house",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheel-house <strong class='noun'> n. </strong> Steersman's shelter.</p>"
            }
        ]
    },
    {
        "word": "Wheelie",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheelie <strong class='noun'> n. </strong> Slang stunt of riding a bicycle or motor cycle with the front wheel off the ground.</p>"
            }
        ]
    },
    {
        "word": "Wheel-spin",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheel-spin <strong class='noun'> n. </strong> Rotation of a vehicle's wheels without traction.</p>"
            }
        ]
    },
    {
        "word": "Wheelwright",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheelwright <strong class='noun'> n. </strong> Person who makes or repairs wheels.</p>"
            }
        ]
    },
    {
        "word": "Wheeze",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wheeze <strong class='noun'> —v. </strong> (-zing) 1 breathe with an audible whistling sound. 2 utter with this sound. <strong class='noun'> —n. </strong> 1 sound of wheezing. 2 <strong class='colloq'> colloq. </strong> Clever scheme. wheezy adj. (-ier, -iest). Wheezily <strong class='adv'> adv. </strong> Wheeziness <strong class='noun'> n. </strong> [probably from old norse, = hiss]</p>"
            }
        ]
    },
    {
        "word": "Whelk",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whelk <strong class='noun'> n. </strong> Marine mollusc with a spiral shell. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whelm",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whelm <strong class='verb'> v. </strong> Poet. 1 engulf. 2 crush with weight. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whelp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whelp <strong class='noun'> —n. </strong> 1 young dog; puppy. 2 archaic cub. 3 ill-mannered child or youth. —v. (also absol.) Give birth to (a whelp or whelps or (derog.) A child). [old english]</p>"
            }
        ]
    },
    {
        "word": "When  —interrog.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>When —interrog. <strong class='adv'> adv. </strong> 1 at what time? 2 on what occasion? 3 how soon? —rel. <strong class='adv'> adv. </strong> (prec. By time etc.) At or on which (there are times when i could cry). — <strong class='conj'> conj. </strong> 1 at the or any time that; as soon as (come when you like; come when ready). 2 although (why stand when you could sit?). 3 after which; and then; but just then (was nearly asleep when the bell rang). <strong class='pron'> — <strong class='pron'> pron. </strong> </strong> What time?; which time (till when can you stay?; since when it has improved). <strong class='noun'> —n. </strong> Time, occasion (fixed the where and when). [old english]</p>"
            }
        ]
    },
    {
        "word": "Whence  formal —interrog.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whence formal —interrog. <strong class='adv'> adv. </strong> From what place? — <strong class='conj'> conj. </strong> 1 to the place from which (return whence you came). 2 (often prec. By place etc.) From which. 3 and thence (whence it follows that). [old english: related to *when] <strong class='usage'> Usage:- </strong>the use of from whence rather than simply whence (as in the place from whence they came), though common, is generally considered incorrect.</p>"
            }
        ]
    },
    {
        "word": "Whenever",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whenever <strong class='conj'> conj. </strong> & <strong class='adv'> adv. </strong> 1 at whatever time; on whatever occasion. 2 every time that.</p>"
            }
        ]
    },
    {
        "word": "Whensoever",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whensoever <strong class='conj'> conj. </strong> & <strong class='adv'> adv. </strong> Formal = *whenever.</p>"
            }
        ]
    },
    {
        "word": "Where  —interrog.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Where —interrog. <strong class='adv'> adv. </strong> 1 in or to what place or position? 2 in what respect? (where does it concern us?). —rel. <strong class='adv'> adv. </strong> (prec. By place etc.) In or to which (places where they meet). — <strong class='conj'> conj. </strong> 1 in or to the or any place, direction, or respect in which (go where you like; tick where applicable). 2 and there (reached crewe, where the car broke down). <strong class='pron'> — <strong class='pron'> pron. </strong> </strong> What place? (where do you come from?). <strong class='noun'> —n. </strong> Place; scene of something (see *when n.). [old english]</p>"
            }
        ]
    },
    {
        "word": "Whereabouts  —interrog.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whereabouts —interrog. <strong class='adv'> adv. </strong> Approximately where? <strong class='noun'> —n. </strong> (as sing. Or pl.) Person's or thing's location.</p>"
            }
        ]
    },
    {
        "word": "Whereas",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whereas <strong class='conj'> conj. </strong> 1 in contrast or comparison with the fact that. 2 (esp. In legal preambles) taking into consideration the fact that.</p>"
            }
        ]
    },
    {
        "word": "Whereby",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whereby <strong class='conj'> conj. </strong> By what or which means.</p>"
            }
        ]
    },
    {
        "word": "Wherefore",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wherefore <strong class='adv'> —adv. </strong> Archaic 1 for what reason? 2 for which reason. <strong class='noun'> —n. </strong> See *why.</p>"
            }
        ]
    },
    {
        "word": "Wherein",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wherein <strong class='conj'> conj. </strong> Formal in what or which place or respect.</p>"
            }
        ]
    },
    {
        "word": "Whereof",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whereof <strong class='conj'> conj. </strong> Formal of what or which.</p>"
            }
        ]
    },
    {
        "word": "Whereupon",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whereupon <strong class='conj'> conj. </strong> Immediately after which.</p>"
            }
        ]
    },
    {
        "word": "Wherever",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wherever <strong class='adv'> —adv. </strong> In or to whatever place. — <strong class='conj'> conj. </strong> In every place that.</p>"
            }
        ]
    },
    {
        "word": "Wherewithal",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wherewithal <strong class='noun'> n. </strong> Colloq. Money etc. Needed for a purpose.</p>"
            }
        ]
    },
    {
        "word": "Wherry",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wherry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 light rowing-boat usu. For carrying passengers. 2 large light barge. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Whet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whet <strong class='verb'> v. </strong> <strong class='adj'> (-tt-) </strong> 1 sharpen (a tool). 2 stimulate (the appetite or a desire etc.). [old english]</p>"
            }
        ]
    },
    {
        "word": "Whether",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whether <strong class='conj'> conj. </strong> Introducing the first or both of alternative possibilities (i doubt whether it matters; i do not know whether they have arrived or not). whether or no whether it is so or not. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whetstone",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whetstone <strong class='noun'> n. </strong> Tapered stone used with water to sharpen tools.</p>"
            }
        ]
    },
    {
        "word": "Whew",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whew <strong class='n_pl'> int. </strong> Expressing surprise, consternation, or relief. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Whey",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whey <strong class='noun'> n. </strong> Watery liquid left when milk forms curds. [old english]</p>"
            }
        ]
    },
    {
        "word": "Which  —interrog.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Which —interrog. <strong class='adj'> Adj. </strong> Asking for choice from a definite set of alternatives (which john do you mean?; say which book you prefer). —rel. <strong class='adj'> Adj. </strong> Being the one just referred to; and this or these (ten years, during which time they admitted nothing). —interrog. <strong class='pron'> Pron. </strong> 1 which person or persons? (which of you is responsible?). 2 which thing or things? (say which you prefer). —rel. <strong class='pron'> Pron. </strong> ( <strong class='poss'> poss. </strong> Of which, whose) 1 which thing or things, usu. Introducing a clause not essential for identification (the house, which is empty, has been damaged). 2 used in place of that after in or that (there is the house in which i was born; that which you have just seen). [old english]</p>"
            }
        ]
    },
    {
        "word": "Whichever",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whichever <strong class='adj'> adj. </strong> & <strong class='pron'> pron. </strong> Any which (take whichever you like).</p>"
            }
        ]
    },
    {
        "word": "Whiff",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whiff <strong class='noun'> n. </strong> 1 puff or breath of air, smoke, etc. 2 smell. 3 (foll. By of) trace of scandal etc. 4 small cigar. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Whig",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whig <strong class='noun'> n. </strong> Hist. Member of the british reforming and constitutional party succeeded in the 19th c. By the liberal party. whiggery <strong class='noun'> n. </strong> Whiggish adj. Whiggism <strong class='noun'> n. </strong> [whiggamer, -more, nickname of 17th-c. Scots rebels]</p>"
            }
        ]
    },
    {
        "word": "While",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>While <strong class='noun'> —n. </strong> Period of time (a long while ago; waited a while; all this while). — <strong class='conj'> conj. </strong> 1 during the time that; for as long as; at the same time as (while i was away, the house was burgled; fell asleep while reading). 2 in spite of the fact that; whereas (while i want to believe it, i cannot). —v. (-ling) (foll. By away) pass (time etc.) In a leisurely or interesting way. —rel. <strong class='adv'> adv. </strong> (prec. By time etc.) During which (the summer while i was abroad). between whiles in the intervals. For a while for some time. In a while soon. The while in the meantime. Worth while (or worth one's while) worth the time or effort spent. [old english] <strong class='usage'> Usage:- </strong>worth while (two words) is used only predicatively, as in thought it worth while to ring the police, whereas worthwhile is used both predicatively and attributively.</p>"
            }
        ]
    },
    {
        "word": "Whilst",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whilst <strong class='adv'> adv. </strong> & <strong class='conj'> conj. </strong> While. [from *while]</p>"
            }
        ]
    },
    {
        "word": "Whim",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whim <strong class='noun'> n. </strong> 1 sudden fancy; caprice. 2 capriciousness. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Whimper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whimper <strong class='noun'> —v. </strong> Make feeble, querulous, or frightened sounds. <strong class='noun'> —n. </strong> Such a sound. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Whimsical",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whimsical <strong class='adj'> adj. </strong> Capricious, fantastic. whimsicality <strong class='noun'> n. </strong> Whimsically adv.</p>"
            }
        ]
    },
    {
        "word": "Whimsy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whimsy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> = *whim. [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Whin",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whin <strong class='noun'> n. </strong> (in sing. Or pl.) Gorse. [scandinavian]</p>"
            }
        ]
    },
    {
        "word": "Whinchat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whinchat <strong class='noun'> n. </strong> Small songbird.</p>"
            }
        ]
    },
    {
        "word": "Whine",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whine <strong class='noun'> —n. </strong> 1 complaining long-drawn wail as of a dog. 2 similar shrill prolonged sound. 3 querulous tone or complaint. —v. (-ning) emit or utter a whine; complain. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whinge",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whinge <strong class='verb'> v. </strong> (-geing or -ging) <strong class='colloq'> colloq. </strong> Whine; grumble peevishly. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whinny",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whinny <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> gentle or joyful neigh. —v. (-ies, -ied) give a whinny. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Whip",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whip <strong class='noun'> —n. </strong> 1 lash attached to a stick for urging on animals or punishing etc. 2 a member of a political party in parliament appointed to control its discipline and tactics. B whips' written notice requesting or requiring attendance for voting at a division etc., variously underlined according to the degree of urgency (three-line whip). C (prec. By the) party discipline and instructions (asked for the labour whip). 3 dessert made with whipped cream etc. 4 = *whipper-in. —v. (-pp-) 1 beat or urge on with a whip. 2 beat (cream or eggs etc.) Into a froth. 3 take or move suddenly, unexpectedly, or rapidly (whipped out a knife; whipped behind the door). 4 slang steal. 5 slang a excel. B defeat. 6 bind with spirally wound twine. 7 sew with overcast stitches. whip in bring (hounds) together. Whip on urge into action. Whip up excite or stir up. [low german or dutch]</p>"
            }
        ]
    },
    {
        "word": "Whipcord",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whipcord <strong class='noun'> n. </strong> Tightly twisted cord.</p>"
            }
        ]
    },
    {
        "word": "Whip hand",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whip hand <strong class='noun'> n. </strong> 1 hand that holds the whip (in riding etc.). 2 (usu. Prec. By the) advantage or control in a situation.</p>"
            }
        ]
    },
    {
        "word": "Whiplash",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whiplash <strong class='noun'> n. </strong> Flexible end of a whip.</p>"
            }
        ]
    },
    {
        "word": "Whiplash injury",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whiplash injury <strong class='noun'> n. </strong> Injury to the neck caused by a jerk of the head, esp. As in a motor accident.</p>"
            }
        ]
    },
    {
        "word": "Whipper-in",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whipper-in <strong class='noun'> n. </strong> (pl. Whippers-in) huntsman's assistant who manages the hounds.</p>"
            }
        ]
    },
    {
        "word": "Whippersnapper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whippersnapper <strong class='noun'> n. </strong> 1 small child. 2 insignificant but presumptuous person.</p>"
            }
        ]
    },
    {
        "word": "Whippet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whippet <strong class='noun'> n. </strong> Crossbred dog of the greyhound type used for racing. [probably from obsolete whippet move briskly, from whip it]</p>"
            }
        ]
    },
    {
        "word": "Whipping boy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whipping boy <strong class='noun'> n. </strong> Scapegoat.</p>"
            }
        ]
    },
    {
        "word": "Whipping-top",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whipping-top <strong class='noun'> n. </strong> Top kept spinning by blows of a lash.</p>"
            }
        ]
    },
    {
        "word": "Whippoorwill",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whippoorwill <strong class='noun'> n. </strong> American nightjar. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Whip-round",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whip-round <strong class='noun'> n. </strong> Colloq. Informal collection of money among a group of people.</p>"
            }
        ]
    },
    {
        "word": "Whipstock",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whipstock <strong class='noun'> n. </strong> Handle of a whip.</p>"
            }
        ]
    },
    {
        "word": "Whirl",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whirl <strong class='noun'> —v. </strong> 1 swing round and round; revolve rapidly. 2 (foll. By away) convey or go rapidly in a vehicle etc. 3 send or travel swiftly in an orbit or a curve. 4 (of the brain etc.) Seem to spin round. <strong class='noun'> —n. </strong> 1 whirling movement. 2 state of intense activity (the social whirl). 3 state of confusion (in a whirl). give it a whirl <strong class='colloq'> colloq. </strong> Attempt it. [old norse, and low german or dutch]</p>"
            }
        ]
    },
    {
        "word": "Whirligig",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whirligig <strong class='noun'> n. </strong> 1 spinning or whirling toy. 2 merry-go-round. 3 revolving motion.</p>"
            }
        ]
    },
    {
        "word": "Whirlpool",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whirlpool <strong class='noun'> n. </strong> Powerful circular eddy of water.</p>"
            }
        ]
    },
    {
        "word": "Whirlwind",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whirlwind <strong class='noun'> n. </strong> 1 rapidly whirling mass or column of air. 2 (attrib.) Very rapid.</p>"
            }
        ]
    },
    {
        "word": "Whirr",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whirr <strong class='noun'> —n. </strong> Continuous rapid buzz or soft clicking sound. —v. <strong class='adj'> (-rr-) </strong> make this sound. [scandinavian]</p>"
            }
        ]
    },
    {
        "word": "Whisk",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whisk <strong class='noun'> —v. </strong> 1 (foll. By away, off) a brush with a sweeping movement. B take suddenly. 2 whip (cream, eggs, etc.). 3 convey or go (esp. Out of sight) lightly or quickly. 4 wave or lightly brandish. <strong class='noun'> —n. </strong> 1 whisking action or motion. 2 utensil for whisking eggs or cream etc. 3 bunch of grass, twigs, bristles, etc., for removing dust or flies. [scandinavian]</p>"
            }
        ]
    },
    {
        "word": "Whisker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whisker <strong class='noun'> n. </strong> 1 (usu. In pl.) Hair growing on a man's face, esp. On the cheek. 2 each of the bristles on the face of a cat etc. 3 <strong class='colloq'> colloq. </strong> Small distance (within a whisker of). whiskered adj. Whiskery adj. [from *whisk]</p>"
            }
        ]
    },
    {
        "word": "Whiskey",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whiskey <strong class='noun'> n. </strong> (brit. Whisky) (pl. -eys) spirit distilled esp. From malted grain, esp. Barley or rye. [abbreviation of usquebaugh from gaelic, = water of life]</p>"
            }
        ]
    },
    {
        "word": "Whisky",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whisky <strong class='noun'> n. </strong> (ir. & us whiskey) (pl. -ies or -eys) spirit distilled esp. From malted grain, esp. Barley or rye. [abbreviation of usquebaugh from gaelic, = water of life]</p>"
            }
        ]
    },
    {
        "word": "Whisper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whisper <strong class='noun'> —v. </strong> 1 a speak very softly without vibration of the vocal cords. B talk or say in a barely audible tone or in a secret or confidential way. 2 rustle or murmur. <strong class='noun'> —n. </strong> 1 whispering speech or sound. 2 thing whispered. it is whispered there is a rumour. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whist",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whist <strong class='noun'> n. </strong> Card-game usu. For two pairs of players. [earlier whisk, perhaps from *whisk (with ref. To whisking away the tricks): perhaps associated with whist! (= silence)]</p>"
            }
        ]
    },
    {
        "word": "Whist drive",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whist drive <strong class='noun'> n. </strong> Social occasion with the playing of progressive whist.</p>"
            }
        ]
    },
    {
        "word": "Whistle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whistle <strong class='noun'> —n. </strong> 1 clear shrill sound made by forcing breath through a small hole between nearly closed lips. 2 similar sound made by a bird, the wind, a missile, etc. 3 instrument used to produce such a sound. —v. (-ling) 1 emit a whistle. 2 a give a signal or express surprise or derision by whistling. B (often foll. By up) summon or give a signal to (a dog etc.) By whistling. 3 (also absol.) Produce (a tune) by whistling. 4 (foll. By for) vainly seek or desire. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whistle-stop",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whistle-stop <strong class='noun'> n. </strong> 1 us small unimportant town on a railway. 2 politician's brief pause for an electioneering speech on tour.</p>"
            }
        ]
    },
    {
        "word": "Whit",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whit <strong class='noun'> —n. </strong> = *whitsuntide. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Of whitsuntide or whit sunday. [old english, = white]</p>"
            }
        ]
    },
    {
        "word": "Whit",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whit <strong class='noun'> n. </strong> Particle; least possible amount (not a whit better). [apparently = *wight]</p>"
            }
        ]
    },
    {
        "word": "White",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White <strong class='adj'> —adj.</strong> 1 resembling a surface reflecting sunlight without absorbing any of the visible rays; of the colour of milk or snow. 2 nearly this colour; pale, esp. In the face. 3 (white) a of the human group having light-coloured skin. B of or relating to white people. 4 albino (white mouse). 5 (of hair) having lost its colour, esp. In old age. 6 (of coffee) with milk or cream. <strong class='noun'> —n. </strong> 1 white colour or pigment. 2 a white clothes or material. B (in pl.) White garments as worn in cricket, tennis, etc. 3 a (in a game or sport) white piece, ball, etc. B player using these. 4 = *egg-white. 5 whitish part of the eyeball round the iris. 6 (white) member of a light-skinned race. bleed white drain of wealth etc. whiteness <strong class='noun'> n. </strong> Whitish adj. [old english]</p>"
            }
        ]
    },
    {
        "word": "White ant",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White ant <strong class='noun'> n. </strong> Termite.</p>"
            }
        ]
    },
    {
        "word": "Whitebait",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whitebait <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> (usu. In pl.) Small silvery-white young of herrings and sprats, esp. As food.</p>"
            }
        ]
    },
    {
        "word": "White cell",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White cell <strong class='noun'> n. </strong> Leucocyte.</p>"
            }
        ]
    },
    {
        "word": "White-collar",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White-collar <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of a worker or work) non-manual; clerical, professional.</p>"
            }
        ]
    },
    {
        "word": "White corpuscle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White corpuscle <strong class='noun'> n. </strong> = *white cell.</p>"
            }
        ]
    },
    {
        "word": "White elephant",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White elephant <strong class='noun'> n. </strong> Useless possession.</p>"
            }
        ]
    },
    {
        "word": "White feather",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White feather <strong class='noun'> n. </strong> Symbol of cowardice.</p>"
            }
        ]
    },
    {
        "word": "White flag",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White flag <strong class='noun'> n. </strong> Symbol of surrender.</p>"
            }
        ]
    },
    {
        "word": "White friar",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White friar <strong class='noun'> n. </strong> Carmelite.</p>"
            }
        ]
    },
    {
        "word": "White goods",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White goods <strong class='n_pl'> n.pl. </strong> Large domestic electrical equipment.</p>"
            }
        ]
    },
    {
        "word": "Whitehead",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whitehead <strong class='noun'> n. </strong> Colloq. White or white-topped skin-pustule.</p>"
            }
        ]
    },
    {
        "word": "White heat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White heat <strong class='noun'> n. </strong> 1 temperature at which metal emits white light. 2 state of intense passion or activity. white-hot adj.</p>"
            }
        ]
    },
    {
        "word": "White hope",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White hope <strong class='noun'> n. </strong> Person expected to achieve much.</p>"
            }
        ]
    },
    {
        "word": "White horses",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White horses <strong class='n_pl'> n.pl. </strong> White-crested waves at sea.</p>"
            }
        ]
    },
    {
        "word": "White lead",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White lead <strong class='noun'> n. </strong> Mixture of lead carbonate and hydrated lead oxide used as pigment.</p>"
            }
        ]
    },
    {
        "word": "White lie",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White lie <strong class='noun'> n. </strong> Harmless or trivial untruth.</p>"
            }
        ]
    },
    {
        "word": "White light",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White light <strong class='noun'> n. </strong> Apparently colourless light, e.g. Ordinary daylight.</p>"
            }
        ]
    },
    {
        "word": "White magic",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White magic <strong class='noun'> n. </strong> Magic used for beneficent purposes.</p>"
            }
        ]
    },
    {
        "word": "White meat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White meat <strong class='noun'> n. </strong> Poultry, veal, rabbit, and pork.</p>"
            }
        ]
    },
    {
        "word": "Whiten",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whiten <strong class='verb'> v. </strong> Make or become white. whitener n.</p>"
            }
        ]
    },
    {
        "word": "White noise",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White noise <strong class='noun'> n. </strong> Noise containing many frequencies with equal intensities.</p>"
            }
        ]
    },
    {
        "word": "White-out",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White-out <strong class='noun'> n. </strong> Dense blizzard, esp. In polar regions.</p>"
            }
        ]
    },
    {
        "word": "White paper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White paper <strong class='noun'> n. </strong> Government report giving information.</p>"
            }
        ]
    },
    {
        "word": "White pepper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White pepper <strong class='noun'> n. </strong> Pepper made by grinding a ripe or husked berry.</p>"
            }
        ]
    },
    {
        "word": "White russian",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White russian <strong class='noun'> n. </strong> = *byelorussian.</p>"
            }
        ]
    },
    {
        "word": "White sauce",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White sauce <strong class='noun'> n. </strong> Sauce of flour, melted butter, and milk or cream.</p>"
            }
        ]
    },
    {
        "word": "White slave",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White slave <strong class='noun'> n. </strong> Woman tricked or forced into prostitution.</p>"
            }
        ]
    },
    {
        "word": "White spirit",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White spirit <strong class='noun'> n. </strong> Light petroleum as a solvent.</p>"
            }
        ]
    },
    {
        "word": "White sugar",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White sugar <strong class='noun'> n. </strong> Purified sugar.</p>"
            }
        ]
    },
    {
        "word": "White tie",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White tie <strong class='noun'> n. </strong> Man's white bow-tie as part of full evening dress.</p>"
            }
        ]
    },
    {
        "word": "Whitewash",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whitewash <strong class='noun'> —n. </strong> 1 solution of quicklime or whiting for whitening walls etc. 2 means employed to conceal mistakes or faults. —v. 1 cover with whitewash. 2 attempt to clear the reputation of by concealing facts.</p>"
            }
        ]
    },
    {
        "word": "White wedding",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White wedding <strong class='noun'> n. </strong> Wedding at which the bride wears a formal white wedding dress.</p>"
            }
        ]
    },
    {
        "word": "White whale",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>White whale <strong class='noun'> n. </strong> Northern cetacean, white when adult.</p>"
            }
        ]
    },
    {
        "word": "Whitewood",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whitewood <strong class='noun'> n. </strong> Pale wood, esp. Prepared for staining etc.</p>"
            }
        ]
    },
    {
        "word": "Whither  archaic",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whither archaic <strong class='adv'> —adv. </strong> 1 to what place or state? 2 (prec. By place etc.) To which. — <strong class='conj'> conj. </strong> 1 to the or any place to which (go whither you will). 2 and thither. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whiting1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whiting1 <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> small white-fleshed fish used as food. [dutch: related to *white]</p>"
            }
        ]
    },
    {
        "word": "Whiting2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whiting2 <strong class='noun'> n. </strong> Ground chalk used in whitewashing etc.</p>"
            }
        ]
    },
    {
        "word": "Whitlow",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whitlow <strong class='noun'> n. </strong> Inflammation near a fingernail or toenail. [originally white *flaw1]</p>"
            }
        ]
    },
    {
        "word": "Whitsun",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whitsun <strong class='noun'> —n. </strong> = *whitsuntide. <strong class='adj'> —adj.</strong> = *whit. [whitsun day = whit sunday]</p>"
            }
        ]
    },
    {
        "word": "Whit sunday",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whit sunday <strong class='noun'> n. </strong> Seventh sunday after easter, commemorating pentecost.</p>"
            }
        ]
    },
    {
        "word": "Whitsuntide",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whitsuntide <strong class='noun'> n. </strong> Weekend or week including whit sunday.</p>"
            }
        ]
    },
    {
        "word": "Whittle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whittle <strong class='verb'> v. </strong> (-ling) 1 (often foll. By at) pare (wood etc.) With repeated slicing with a knife. 2 (often foll. By away, down) reduce by repeated subtractions. [dial. Thwittle]</p>"
            }
        ]
    },
    {
        "word": "Whiz  (also whizz)",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whiz (also whizz) <strong class='noun'> —n. </strong> Sound made by a body moving through the air at great speed. —v. (-zz-) move with or make a whiz. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Whiz-kid",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whiz-kid <strong class='noun'> n. </strong> Colloq. Brilliant or highly successful young person.</p>"
            }
        ]
    },
    {
        "word": "Who",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Who <strong class='sing'> abbr. </strong> World health organization.</p>"
            }
        ]
    },
    {
        "word": "Who",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Who <strong class='pron'> pron. </strong> (obj. Whom or <strong class='colloq'> colloq. </strong> Who; <strong class='poss'> poss. </strong> Whose) 1 a what or which person or persons? (who called?; you know who it was). B what sort of person or persons? (who am i to object?). 2 (a person) that (anyone who wishes can come; the woman whom you met; the man who you saw). 3 and or but he, they, etc. (gave it to tom, who sold it to jim). [old english] <strong class='usage'> Usage:- </strong>in the last two examples of sense 2 whom is correct, but who is common in less formal contexts.</p>"
            }
        ]
    },
    {
        "word": "Whoa",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whoa <strong class='n_pl'> int. </strong> Used to stop or slow a horse etc. [var. Of *ho]</p>"
            }
        ]
    },
    {
        "word": "Who'd",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Who'd <strong class='contr'> contr. </strong> 1 who had. 2 who would.</p>"
            }
        ]
    },
    {
        "word": "Whodunit",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whodunit <strong class='noun'> n. </strong> (also whodunnit) <strong class='colloq'> colloq. </strong> Detective story, play, or film. [= who done (illiterate for did) it?]</p>"
            }
        ]
    },
    {
        "word": "Whoever",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whoever <strong class='pron'> pron. </strong> (obj. Whoever or formal whomever; <strong class='poss'> poss. </strong> Whosever) 1 the or any person or persons who (whoever comes is welcome). 2 though anyone (whoever else objects, i do not).</p>"
            }
        ]
    },
    {
        "word": "Whole",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whole <strong class='adj'> —adj.</strong> 1 uninjured, unbroken, intact, or undiminished. 2 not less than; all there is of. 3 (of blood or milk etc.) With no part removed. <strong class='noun'> —n. </strong> 1 thing complete in itself. 2 all there is of a thing. 3 (foll. By of) all members etc. Of (the whole of london knows it). as a whole as a unity; not as separate parts. On the whole taking everything relevant into account. Whole lot see *lot. wholeness <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wholefood",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wholefood <strong class='noun'> n. </strong> Food which has not been unnecessarily processed or refined.</p>"
            }
        ]
    },
    {
        "word": "Wholegrain",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wholegrain <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Made with or containing whole grains (wholegrain rice).</p>"
            }
        ]
    },
    {
        "word": "Wholehearted",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wholehearted <strong class='adj'> adj. </strong> 1 completely devoted. 2 done with all possible effort or sincerity. wholeheartedly adv.</p>"
            }
        ]
    },
    {
        "word": "Wholemeal",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wholemeal <strong class='noun'> n. </strong> (usu. Attrib.) Meal or flour with none of the bran or germ removed.</p>"
            }
        ]
    },
    {
        "word": "Whole number",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whole number <strong class='noun'> n. </strong> Number without fractions; integer.</p>"
            }
        ]
    },
    {
        "word": "Wholesale",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wholesale <strong class='noun'> —n. </strong> Selling of goods in large quantities to be retailed by others. <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> 1 by wholesale. 2 on a large scale. —v. (-ling) sell wholesale. wholesaler <strong class='noun'> n. </strong> [by whole sale]</p>"
            }
        ]
    },
    {
        "word": "Wholesome",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wholesome <strong class='adj'> adj. </strong> 1 promoting physical, mental, or moral health. 2 prudent (wholesome respect). [old english: related to *whole]</p>"
            }
        ]
    },
    {
        "word": "Wholewheat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wholewheat <strong class='noun'> n. </strong> (usu. Attrib.) Wheat with none of the bran or germ removed.</p>"
            }
        ]
    },
    {
        "word": "Wholism",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wholism <strong class='var'> var. </strong> Of *holism.</p>"
            }
        ]
    },
    {
        "word": "Wholly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wholly <strong class='adv'> adv. </strong> 1 entirely; without limitation. 2 purely.</p>"
            }
        ]
    },
    {
        "word": "Whom  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whom  <strong class='slang_v'>objective case of *</strong> who.</p>"
            }
        ]
    },
    {
        "word": "Whomever  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whomever  <strong class='slang_v'>objective case of *</strong> whoever.</p>"
            }
        ]
    },
    {
        "word": "Whomsoever  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whomsoever  <strong class='slang_v'>objective case of *</strong> whosoever.</p>"
            }
        ]
    },
    {
        "word": "Whoop",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whoop <strong class='noun'> —n. </strong> 1 loud cry of or as of excitement etc. 2 long rasping indrawn breath in whooping cough. —v. Utter a whoop. whoop it up <strong class='colloq'> colloq. </strong> 1 engage in revelry. 2 us make a stir. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Whoopee",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whoopee <strong class='n_pl'> int. </strong> Expressing exuberant joy. make whoopee <strong class='colloq'> colloq. </strong> 1 have fun, make merry. 2 make love. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Whooping cough",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whooping cough <strong class='noun'> n. </strong> Infectious bacterial disease, esp. Of children, with a series of short violent coughs followed by a whoop.</p>"
            }
        ]
    },
    {
        "word": "Whoops",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whoops <strong class='n_pl'> int. </strong> Colloq. Expressing surprise or apology, esp. On losing balance or making an obvious mistake. [var. Of *oops]</p>"
            }
        ]
    },
    {
        "word": "Whop",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whop <strong class='verb'> v. </strong> (-pp-) slang 1 thrash. 2 defeat. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Whopper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whopper <strong class='noun'> n. </strong> Slang 1 something big of its kind. 2 great lie.</p>"
            }
        ]
    },
    {
        "word": "Whopping",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whopping <strong class='adj'> adj. </strong> Colloq. (esp. As an intensifier) huge (a whopping success; a whopping great lie).</p>"
            }
        ]
    },
    {
        "word": "Whore",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whore <strong class='noun'> n. </strong> 1 prostitute. 2 derog. Promiscuous woman. [old english]</p>"
            }
        ]
    },
    {
        "word": "Whore-house",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whore-house <strong class='noun'> n. </strong> Brothel.</p>"
            }
        ]
    },
    {
        "word": "Whorl",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whorl <strong class='noun'> n. </strong> 1 ring of leaves etc. Round a stem. 2 one turn of a spiral. [apparently <strong class='var'> var. </strong> Of *whirl]</p>"
            }
        ]
    },
    {
        "word": "Whortleberry",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whortleberry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> bilberry. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Whose  —interrog.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whose —interrog. <strong class='pron'> Pron. </strong> Of or belonging to which person (whose is this book?). —interrog. <strong class='adj'> Adj. </strong> Of whom or which (whose book is this?) —rel. <strong class='pron'> Pron. </strong> Of whom; of which (the man, whose name was tim; the house whose roof was damaged).</p>"
            }
        ]
    },
    {
        "word": "Whosoever",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Whosoever <strong class='pron'> pron. </strong> (obj. Whomsoever; <strong class='poss'> poss. </strong> Whosesoever) archaic = *whoever.</p>"
            }
        ]
    },
    {
        "word": "Who's who",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Who's who <strong class='noun'> n. </strong> 1 who or what each person is (know who's who). 2 list with facts about notable persons.</p>"
            }
        ]
    },
    {
        "word": "Why",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Why <strong class='adv'> —adv. </strong> 1 for what reason or purpose (why did you do it?; i do not know why you came). 2 (prec. By reason etc.) For which (the reasons why i did it). <strong class='pl'> —int. </strong> Expressing: 1 surprised discovery or recognition (why, it's you!). 2 impatience (why, of course i do!). 3 reflection (why, yes, i think so). 4 objection (why, what is wrong with it?). whys and wherefores reasons; explanation. [old english: related to *what]</p>"
            }
        ]
    },
    {
        "word": "Wi",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wi <strong class='sing'> abbr. </strong> 1 west indies. 2 women's institute.</p>"
            }
        ]
    },
    {
        "word": "Wick",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wick <strong class='noun'> n. </strong> Strip or thread feeding a flame with fuel. get on a person's wick <strong class='colloq'> colloq. </strong> Annoy a person. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wicked",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wicked <strong class='adj'> adj. </strong> (-er, -est) 1 sinful, iniquitous, immoral. 2 spiteful. 3 playfully malicious. 4 <strong class='colloq'> colloq. </strong> Very bad. 5 slang excellent. wickedly <strong class='adv'> adv. </strong> Wickedness <strong class='noun'> n. </strong> [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Wicker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wicker <strong class='noun'> n. </strong> Plaited osiers etc. As material for baskets etc. [scandinavian]</p>"
            }
        ]
    },
    {
        "word": "Wickerwork",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wickerwork <strong class='noun'> n. </strong> 1 wicker. 2 things made of wicker.</p>"
            }
        ]
    },
    {
        "word": "Wicket",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wicket <strong class='noun'> n. </strong> 1 cricket a three stumps with the bails in position defended by a batsman. B ground between two wickets. C state of this. D instance of a batsman being got out (bowler has taken four wickets). 2 (in full wicket-door or -gate) small door or gate, esp. Beside or in a larger one or closing the lower part only of a doorway. [anglo-french wiket = french guichet]</p>"
            }
        ]
    },
    {
        "word": "Wicket-keeper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wicket-keeper <strong class='noun'> n. </strong> Fieldsman stationed close behind a batsman's wicket.</p>"
            }
        ]
    },
    {
        "word": "Widdershins",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Widdershins <strong class='adv'> adv. </strong> (also withershins) esp. Scot. 1 in a direction contrary to the sun's course (considered unlucky). 2 anticlockwise. [german, = contrary]</p>"
            }
        ]
    },
    {
        "word": "Wide",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wide <strong class='adj'> —adj.</strong> 1 having sides far apart, broad, not narrow (wide river; wide sleeve; wide angle). 2 (following a measurement) in width (a metre wide). 3 a extending far (wide range; wide experience). B considerable (wide margin). 4 not restricted (a wide public). 5 a liberal; unprejudiced (takes wide views). B not specialized; general. 6 open to the full extent (wide eyes). 7 (foll. By of) not within a reasonable distance of, far from (wide shot; wide of the target). 8 (in <strong class='n_pl'> comb. </strong> ) Extending over the whole of (nationwide). <strong class='adv'> —adv. </strong> 1 widely. 2 to the full extent. 3 far from the target etc. (shooting wide). <strong class='noun'> —n. </strong> = *wide ball. give a wide berth to see *berth. Wide of the mark see *mark1. Wide open <strong class='adj'> (often foll. By to) </strong> exposed (to attack etc.). The wide world all the world, great as it is. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wide awake",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wide awake <strong class='adj'> adj. </strong> 1 fully awake. 2 <strong class='colloq'> colloq. </strong> Wary, knowing.</p>"
            }
        ]
    },
    {
        "word": "Wide ball",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wide ball <strong class='noun'> n. </strong> Cricket ball judged to be beyond the batsman's reach, so scoring a run.</p>"
            }
        ]
    },
    {
        "word": "Wide-eyed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wide-eyed <strong class='adj'> adj. </strong> Surprised; naïve.</p>"
            }
        ]
    },
    {
        "word": "Widely",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Widely <strong class='adv'> adv. </strong> 1 to a wide extent; far apart. 2 extensively. 3 by many people (it is widely thought that). 4 considerably; to a large degree (holds a widely different view).</p>"
            }
        ]
    },
    {
        "word": "Widen",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Widen <strong class='verb'> v. </strong> Make or become wider.</p>"
            }
        ]
    },
    {
        "word": "Widespread",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Widespread <strong class='adj'> adj. </strong> Widely distributed.</p>"
            }
        ]
    },
    {
        "word": "Widgeon",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Widgeon <strong class='noun'> n. </strong> (also wigeon) a kind of wild duck. [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Widow",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Widow <strong class='noun'> —n. </strong> 1 woman who has lost her husband by death and not married again. 2 woman whose husband is often away on a specified activity (golf widow). —v. 1 make into a widow or widower. 2 (as widowed adj.) Bereft by the death of a spouse. widowhood <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Widower",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Widower <strong class='noun'> n. </strong> Man who has lost his wife by death and not married again.</p>"
            }
        ]
    },
    {
        "word": "Widow's peak",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Widow's peak <strong class='noun'> n. </strong> V-shaped growth of hair towards the centre of the forehead.</p>"
            }
        ]
    },
    {
        "word": "Width",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Width <strong class='noun'> n. </strong> 1 measurement from side to side. 2 large extent. 3 liberality of views etc. 4 strip of material of full width. widthways <strong class='adv'> adv. </strong> [from *wide]</p>"
            }
        ]
    },
    {
        "word": "Wield",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wield <strong class='verb'> v. </strong> Hold and use; command, exert (a weapon, tool, power, etc.). [old english]</p>"
            }
        ]
    },
    {
        "word": "Wiener schnitzel",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wiener schnitzel <strong class='noun'> n. </strong> Veal cutlet breaded, fried, and garnished. [german]</p>"
            }
        ]
    },
    {
        "word": "Wife",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wife <strong class='noun'> n. </strong> (pl. Wives) 1 married woman, esp. In relation to her husband. 2 archaic woman. wifely adj. [old english, = woman]</p>"
            }
        ]
    },
    {
        "word": "Wig",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wig <strong class='noun'> n. </strong> Artificial head of hair. [abbreviation of *periwig]</p>"
            }
        ]
    },
    {
        "word": "Wigeon",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wigeon <strong class='var'> var. </strong> Of *widgeon.</p>"
            }
        ]
    },
    {
        "word": "Wigging",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wigging <strong class='noun'> n. </strong> Colloq. Reprimand. [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Wiggle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wiggle <strong class='colloq'> colloq. </strong> —v. (-ling) move from side to side etc. <strong class='noun'> —n. </strong> Act of wiggling; kink in a line etc. wiggly adj. (-ier, -iest). [low german or dutch wiggelen]</p>"
            }
        ]
    },
    {
        "word": "Wight",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wight <strong class='noun'> n. </strong> Archaic person. [old english, = thing, creature]</p>"
            }
        ]
    },
    {
        "word": "Wigwam",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wigwam <strong class='noun'> n. </strong> N. American indian's hut or tent. [ojibwa]</p>"
            }
        ]
    },
    {
        "word": "Wilco",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wilco <strong class='n_pl'> int. </strong> Colloq. Expressing compliance or agreement. [abbreviation of will comply]</p>"
            }
        ]
    },
    {
        "word": "Wild",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wild <strong class='adj'> —adj.</strong> 1 in its original natural state; not domesticated, cultivated, or civilized (wild cat; wild strawberry). 2 unrestrained, disorderly, uncontrolled (wild youth; wild hair). 3 tempestuous (wild night). 4 intensely eager, frantic (wild excitement; wild delight). 5 (foll. By about) <strong class='colloq'> colloq. </strong> Enthusiastically devoted to. 6 <strong class='colloq'> colloq. </strong> Infuriated. 7 haphazard, ill-aimed, rash (wild guess; wild venture). 8 <strong class='colloq'> colloq. </strong> Exciting, delightful. <strong class='adv'> —adv. </strong> In a wild manner. <strong class='noun'> —n. </strong> 1 wild tract of land. 2 desert. in the wild in an uncultivated etc. State. In the wilds <strong class='colloq'> colloq. </strong> Far from towns etc. Run wild grow or stray unchecked or undisciplined. wildly <strong class='adv'> adv. </strong> Wildness <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wild card",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wild card <strong class='noun'> n. </strong> 1 card having any rank chosen by the player holding it. 2 computing character that will match any character or combination of characters. 3 person or thing that can be used in several different ways.</p>"
            }
        ]
    },
    {
        "word": "Wildcat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wildcat <strong class='noun'> —n. </strong> 1 hot-tempered or violent person. 2 exploratory oil well. <strong class='adj'> —adj.</strong> (attrib.) 1 (of a strike) sudden and unofficial. 2 reckless; financially unsound.</p>"
            }
        ]
    },
    {
        "word": "Wildebeest",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wildebeest <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> = *gnu. [afrikaans: related to *wild, *beast]</p>"
            }
        ]
    },
    {
        "word": "Wilderness",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wilderness <strong class='noun'> n. </strong> 1 desert; uncultivated region or garden area. 2 (foll. By of) confused assemblage. [old english: related to *wild, *deer]</p>"
            }
        ]
    },
    {
        "word": "Wildfire",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wildfire <strong class='noun'> n. </strong> Hist. Combustible liquid used in war. spread like wildfire spread with great speed.</p>"
            }
        ]
    },
    {
        "word": "Wildfowl",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wildfowl <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> game-bird.</p>"
            }
        ]
    },
    {
        "word": "Wild-goose chase",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wild-goose chase <strong class='noun'> n. </strong> Foolish or hopeless quest.</p>"
            }
        ]
    },
    {
        "word": "Wild hyacinth",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wild hyacinth <strong class='noun'> n. </strong> = *bluebell.</p>"
            }
        ]
    },
    {
        "word": "Wildlife",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wildlife <strong class='noun'> n. </strong> Wild animals collectively.</p>"
            }
        ]
    },
    {
        "word": "Wild west",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wild west <strong class='noun'> n. </strong> Western us before the establishment of law and order.</p>"
            }
        ]
    },
    {
        "word": "Wile",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wile <strong class='noun'> —n. </strong> (usu. In pl.) Stratagem, trick. —v. (-ling) (foll. By away, into, etc.) Lure. [perhaps from scandinavian]</p>"
            }
        ]
    },
    {
        "word": "Wilful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wilful <strong class='adj'> adj. </strong> (us willful) 1 intentional, deliberate (wilful murder; wilful neglect). 2 obstinate. wilfully <strong class='adv'> adv. </strong> [from *will2]</p>"
            }
        ]
    },
    {
        "word": "Will1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Will1 <strong class='v_aux'> v.aux. </strong> (3rd sing. Present will; past would) 1 (strictly only in the 2nd and 3rd persons: see *shall) expressing a future statement, command, etc. (you will regret this; they will leave at once). 2 expressing the speaker's intention (i will return soon). 3 wish or desire (will you have a drink?; come when you will). 4 expressing a request as a question (will you please open the window?). 5 be able to (the jar will hold a kilo). 6 have a habit or tendency to (accidents will happen; will sit there for hours). 7 expressing probability or expectation (that will be my wife). [old english]</p>"
            }
        ]
    },
    {
        "word": "Will2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Will2 <strong class='noun'> —n. </strong> 1 faculty by which a person decides what to do. 2 strong desire or intention (will to live). 3 determination, will-power (has a strong will). 4 legal written directions for the disposal of one's property after death. 5 disposition towards others (good will). 6 archaic what one desires or ordains. —v. 1 try to cause by will-power (willed her to win). 2 intend; desire. 3 bequeath by a will. at will whenever one wishes. With a will energetically or resolutely. [old english]</p>"
            }
        ]
    },
    {
        "word": "Willful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willful <strong class='adj'> adj. </strong> (brit. Wilful) 1 intentional, deliberate (wilful murder; wilful neglect). 2 obstinate. wilfully <strong class='adv'> adv. </strong> [from *will2]</p>"
            }
        ]
    },
    {
        "word": "Willie",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willie <strong class='var'> var. </strong> Of *willy.</p>"
            }
        ]
    },
    {
        "word": "Willies",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willies <strong class='n_pl'> n.pl. </strong> Colloq. Nervous discomfort (gives me the willies). [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Willing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willing <strong class='adj'> adj. </strong> 1 ready to consent or undertake. 2 given or done etc. By a willing person. willingly <strong class='adv'> adv. </strong> Willingness n.</p>"
            }
        ]
    },
    {
        "word": "Will-o'-the-wisp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Will-o'-the-wisp <strong class='noun'> n. </strong> 1 phosphorescent light seen on marshy ground. 2 elusive person. [= william of the torch]</p>"
            }
        ]
    },
    {
        "word": "Willow",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willow <strong class='noun'> n. </strong> Tree with pliant branches yielding osiers and timber for cricket-bats etc., usu. Growing near water. [old english]</p>"
            }
        ]
    },
    {
        "word": "Willow-herb",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willow-herb <strong class='noun'> n. </strong> Plant with leaves like a willow.</p>"
            }
        ]
    },
    {
        "word": "Willow-pattern",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willow-pattern <strong class='noun'> n. </strong> Conventional chinese design of blue on white porcelain etc.</p>"
            }
        ]
    },
    {
        "word": "Willow-warbler",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willow-warbler <strong class='noun'> n. </strong> Small woodland bird with a tuneful song.</p>"
            }
        ]
    },
    {
        "word": "Willowy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willowy <strong class='adj'> adj. </strong> 1 lithe and slender. 2 having willows.</p>"
            }
        ]
    },
    {
        "word": "Will-power",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Will-power <strong class='noun'> n. </strong> Control by deliberate purpose over impulse.</p>"
            }
        ]
    },
    {
        "word": "Willy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willy <strong class='noun'> n. </strong> (also willie) <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> <strong class='colloq'> colloq. </strong> Penis. [diminutive of william]</p>"
            }
        ]
    },
    {
        "word": "Willy-nilly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Willy-nilly <strong class='adv'> adv. </strong> Whether one likes it or not. [later spelling of will i, nill i i am willing, i am unwilling]</p>"
            }
        ]
    },
    {
        "word": "Wilt",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wilt <strong class='noun'> —v. </strong> 1 wither, droop. 2 lose energy, flag. <strong class='noun'> —n. </strong> Plant-disease causing wilting. [originally dial.]</p>"
            }
        ]
    },
    {
        "word": "Wily",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wily <strong class='adj'> adj. </strong> (-ier, -iest) crafty, cunning. wiliness n.</p>"
            }
        ]
    },
    {
        "word": "Wimp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wimp <strong class='noun'> n. </strong> Colloq. Feeble or ineffectual person. wimpish adj. [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Wimple",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wimple <strong class='noun'> n. </strong> Headdress also covering the neck and the sides of the face, worn by some nuns. [old english]</p>"
            }
        ]
    },
    {
        "word": "Win",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Win <strong class='noun'> —v. </strong> (-nn-; past and  <strong class='archaic_it_was'>past part.</strong> Won) 1 secure as a result of a fight, contest, bet, effort, etc. 2 be the victor; be victorious in. <strong class='noun'> —n. </strong> Victory in a game etc. win the day be victorious in battle, argument, etc. Win over persuade, gain the support of. Win one's spurs <strong class='colloq'> colloq. </strong> Gain distinction or fame. Win through (or out) overcome obstacles. You can't win <strong class='colloq'> colloq. </strong> There is no way to succeed or to please. winnable adj. [old english, = toil]</p>"
            }
        ]
    },
    {
        "word": "Wince",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wince <strong class='noun'> —n. </strong> Start or involuntary shrinking movement of the face, showing pain or distress. —v. (-cing) give a wince. [germanic: related to *wink]</p>"
            }
        ]
    },
    {
        "word": "Wincey",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wincey <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> lightweight fabric of wool and cotton or linen. [apparently an alteration of woolsey in *linsey-woolsey]</p>"
            }
        ]
    },
    {
        "word": "Winceyette",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winceyette <strong class='noun'> n. </strong> Lightweight flannelette.</p>"
            }
        ]
    },
    {
        "word": "Winch",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winch <strong class='noun'> —n. </strong> 1 crank of a wheel or axle. 2 windlass. —v. Lift with a winch. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wind1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind1 <strong class='noun'> —n. </strong> 1 air in natural motion, esp. A current of this. 2 a breath, esp. As needed in exercise or playing a wind instrument. B power of breathing easily. 3 empty talk. 4 gas generated in the bowels etc. 5 wind instruments of an orchestra etc. 6 scent carried by the wind. —v. 1 cause to be out of breath by exertion or a blow. 2 make (a baby) bring up wind after feeding. 3 detect the presence of by a scent.</p>"
            }
        ]
    },
    {
        "word": "Wind2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind2 <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Wound) 1 (often as winding adj.) Go in a spiral, curved, or crooked course. 2 make (one's way) thus. 3 wrap closely; coil. 4 a provide with a coiled thread etc. B surround with or as with a coil. 5 wind up (a clock etc.). <strong class='noun'> —n. </strong> 1 bend or turn in a course. 2 single turn when winding. wind down 1 lower by winding. 2 unwind. 3 draw gradually to a close. Wind off unwind. Wind up 1 coil the whole of. 2 tighten the coiling or coiled spring of (esp. A clock). 3 <strong class='colloq'> colloq. </strong> A increase the intensity of (feelings etc.), excite. B provoke (a person) to anger etc. 4 bring to a conclusion; end. 5 a arrange the affairs of and dissolve (a company). B cease business and go into liquidation. 6 <strong class='colloq'> colloq. </strong> Arrive finally. [old english]</p>"
            }
        ]
    },
    {
        "word": "Windbag",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windbag <strong class='noun'> n. </strong> Colloq. Person who talks a lot but says little of any value.</p>"
            }
        ]
    },
    {
        "word": "Wind-break",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind-break <strong class='noun'> n. </strong> Thing serving to break the force of the wind.</p>"
            }
        ]
    },
    {
        "word": "Windburn",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windburn <strong class='noun'> n. </strong> Inflammation of the skin caused by exposure to the wind.</p>"
            }
        ]
    },
    {
        "word": "Windcheater",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windcheater <strong class='noun'> n. </strong> Wind-resistant jacket.</p>"
            }
        ]
    },
    {
        "word": "Wind-cone",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind-cone <strong class='noun'> n. </strong> = *wind-sock.</p>"
            }
        ]
    },
    {
        "word": "Wind-down",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind-down <strong class='noun'> n. </strong> Colloq. Gradual lessening of excitement or activity.</p>"
            }
        ]
    },
    {
        "word": "Winder",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winder <strong class='noun'> n. </strong> Winding mechanism, esp. Of a clock or watch.</p>"
            }
        ]
    },
    {
        "word": "Windfall",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windfall <strong class='noun'> n. </strong> 1 fruit, esp. An apple, blown to the ground by the wind. 2 unexpected good fortune, esp. A legacy.</p>"
            }
        ]
    },
    {
        "word": "Winding-sheet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winding-sheet <strong class='noun'> n. </strong> Sheet in which a corpse is wrapped for burial.</p>"
            }
        ]
    },
    {
        "word": "Wind instrument",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind instrument <strong class='noun'> n. </strong> Musical instrument sounded by an air-current, esp. The breath.</p>"
            }
        ]
    },
    {
        "word": "Wind-jammer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind-jammer <strong class='noun'> n. </strong> Merchant sailing-ship.</p>"
            }
        ]
    },
    {
        "word": "Windlass",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windlass <strong class='noun'> n. </strong> Machine with a horizontal axle for hauling or hoisting. [old norse, = winding-pole]</p>"
            }
        ]
    },
    {
        "word": "Windmill",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windmill <strong class='noun'> n. </strong> 1 mill worked by the wind acting on its sails. 2 toy consisting of a stick with curved vanes that revolve in a wind. tilt at windmills attack an imaginary enemy.</p>"
            }
        ]
    },
    {
        "word": "Window",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Window <strong class='noun'> n. </strong> 1 a opening in a wall etc., usu. With glass to admit light etc. B the glass itself. 2 space for display behind the window of a shop. 3 window-like opening. 4 opportunity to learn from observation. 5 transparent part in an envelope showing an address. 6 vdu display showing a particular part of the data. windowless adj. [old norse, = wind-eye]</p>"
            }
        ]
    },
    {
        "word": "Window-box",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Window-box <strong class='noun'> n. </strong> Box placed outside a window for growing flowers.</p>"
            }
        ]
    },
    {
        "word": "Window-dressing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Window-dressing <strong class='noun'> n. </strong> 1 art of arranging a display in a shop-window etc. 2 adroit presentation of facts etc. To give a deceptively favourable impression.</p>"
            }
        ]
    },
    {
        "word": "Window-pane",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Window-pane <strong class='noun'> n. </strong> Pane of glass in a window.</p>"
            }
        ]
    },
    {
        "word": "Window-seat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Window-seat <strong class='noun'> n. </strong> 1 seat below a window, esp. In an alcove. 2 seat next to a window in an aircraft, train, etc.</p>"
            }
        ]
    },
    {
        "word": "Window-shop",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Window-shop <strong class='verb'> v. </strong> Look at goods displayed in shop-windows, without buying anything.</p>"
            }
        ]
    },
    {
        "word": "Window-sill",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Window-sill <strong class='noun'> n. </strong> Sill below a window.</p>"
            }
        ]
    },
    {
        "word": "Windpipe",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windpipe <strong class='noun'> n. </strong> Air-passage from the throat to the lungs.</p>"
            }
        ]
    },
    {
        "word": "Windscreen",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windscreen <strong class='noun'> n. </strong> Screen of glass at the front of a motor vehicle.</p>"
            }
        ]
    },
    {
        "word": "Windscreen wiper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windscreen wiper <strong class='noun'> n. </strong> Blade moving in an arc to keep a windscreen clear of rain etc.</p>"
            }
        ]
    },
    {
        "word": "Windshield",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windshield <strong class='noun'> n. </strong> Us = *windscreen.</p>"
            }
        ]
    },
    {
        "word": "Wind-sock",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind-sock <strong class='noun'> n. </strong> Canvas cylinder or cone on a mast to show the direction of the wind at an airfield etc.</p>"
            }
        ]
    },
    {
        "word": "Windsurfing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windsurfing <strong class='noun'> n. </strong> Sport of riding on water on a sailboard. windsurf v. Windsurfer n.</p>"
            }
        ]
    },
    {
        "word": "Windswept",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windswept <strong class='adj'> adj. </strong> Exposed to or swept back by the wind.</p>"
            }
        ]
    },
    {
        "word": "Wind-tunnel",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind-tunnel <strong class='noun'> n. </strong> Tunnel-like device producing an air-stream past models of aircraft etc. For the study of aerodynamics.</p>"
            }
        ]
    },
    {
        "word": "Wind-up",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wind-up <strong class='noun'> —n. </strong> 1 conclusion; finish. 2 <strong class='colloq'> colloq. </strong> Attempt to provoke. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> (of a mechanism) operating by being wound up.</p>"
            }
        ]
    },
    {
        "word": "Windward",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windward <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> On the side from which the wind is blowing. <strong class='noun'> —n. </strong> Windward direction.</p>"
            }
        ]
    },
    {
        "word": "Windy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Windy <strong class='adj'> adj. </strong> (-ier, -iest) 1 stormy with or exposed to wind. 2 generating or characterized by flatulence. 3 <strong class='colloq'> colloq. </strong> Wordy. 4 <strong class='colloq'> colloq. </strong> Nervous, frightened.  windiness <strong class='noun'> n. </strong> [old english: related to *wind1]</p>"
            }
        ]
    },
    {
        "word": "Wine",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wine <strong class='noun'> —n. </strong> 1 fermented grape juice as an alcoholic drink. 2 fermented drink resembling this made from other fruits etc. 3 dark-red colour of red wine. —v. (-ning) (esp. In phr. Wine and dine) 1 drink wine. 2 entertain with wine. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wine bar",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wine bar <strong class='noun'> n. </strong> Bar or small restaurant where wine is the main drink available.</p>"
            }
        ]
    },
    {
        "word": "Winebibber",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winebibber <strong class='noun'> n. </strong> Tippler.</p>"
            }
        ]
    },
    {
        "word": "Wine cellar",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wine cellar <strong class='noun'> n. </strong> 1 cellar for storing wine. 2 its contents.</p>"
            }
        ]
    },
    {
        "word": "Wineglass",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wineglass <strong class='noun'> n. </strong> Glass for wine, usu. With a stem and foot.</p>"
            }
        ]
    },
    {
        "word": "Wine list",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wine list <strong class='noun'> n. </strong> List of wines available in a restaurant etc.</p>"
            }
        ]
    },
    {
        "word": "Winepress",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winepress <strong class='noun'> n. </strong> Press in which grapes are squeezed in making wine.</p>"
            }
        ]
    },
    {
        "word": "Wine vinegar",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wine vinegar <strong class='noun'> n. </strong> Vinegar made from wine as distinct from malt etc.</p>"
            }
        ]
    },
    {
        "word": "Wine waiter",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wine waiter <strong class='noun'> n. </strong> Waiter responsible for serving wine.</p>"
            }
        ]
    },
    {
        "word": "Wing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wing <strong class='noun'> —n. </strong> 1 each of the limbs or organs by which a bird etc. Is able to fly. 2 winglike structure supporting an aircraft. 3 part of a building etc. Extended in a certain direction. 4 a forward player at either end of a line in football, hockey, etc. B side part of a playing-area. 5 (in pl.) Sides of a theatre stage. 6 polarized section of a political party in terms of its views. 7 flank of a battle array. 8 the part of a vehicle over a wheel. 9 air-force unit of several squadrons or groups. —v. 1 travel or traverse on wings. 2 wound in a wing or an arm. 3 equip with wings. 4 enable to fly; send in flight. on the wing flying, in flight. Take under one's wing treat as a protégé. Take wing fly away. winged adj. Winglike adj. [old norse]</p>"
            }
        ]
    },
    {
        "word": "Wing-case",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wing-case <strong class='noun'> n. </strong> Horny cover of an insect's wing.</p>"
            }
        ]
    },
    {
        "word": "Wing-chair",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wing-chair <strong class='noun'> n. </strong> Chair with side-pieces at the top of a high back.</p>"
            }
        ]
    },
    {
        "word": "Wing-collar",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wing-collar <strong class='noun'> n. </strong> Man's high stiff collar with turned-down corners.</p>"
            }
        ]
    },
    {
        "word": "Wing commander",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wing commander <strong class='noun'> n. </strong> Raf officer next below group captain.</p>"
            }
        ]
    },
    {
        "word": "Winger",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winger <strong class='noun'> n. </strong> 1 (in football etc.) Wing player. 2 (in <strong class='n_pl'> comb. </strong> ) Member of a specified political wing.</p>"
            }
        ]
    },
    {
        "word": "Wing-nut",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wing-nut <strong class='noun'> n. </strong> Nut with projections for the fingers to turn it.</p>"
            }
        ]
    },
    {
        "word": "Wing-span",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wing-span <strong class='noun'> n. </strong> (also wing-spread) measurement right across the wings.</p>"
            }
        ]
    },
    {
        "word": "Wink",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wink <strong class='noun'> —v. </strong> 1 (often foll. By at) close and open one eye quickly, esp. As a signal. 2 close and open (one or both eyes) quickly. 3 (of a light etc.) Twinkle; (of an indicator) flash on and off. <strong class='noun'> —n. </strong> 1 act of winking. 2 <strong class='colloq'> colloq. </strong> Short sleep. in a wink very quickly. Wink at purposely avoid seeing; pretend not to notice. winker <strong class='noun'> n. </strong> (in sense 3 of v.). [old english]</p>"
            }
        ]
    },
    {
        "word": "Winkle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winkle <strong class='noun'> —n. </strong> Small edible sea snail. —v. (-ling) (foll. By out) extract with difficulty. [abbreviation of *periwinkle2]</p>"
            }
        ]
    },
    {
        "word": "Winkle-picker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winkle-picker <strong class='noun'> n. </strong> Slang long pointed shoe.</p>"
            }
        ]
    },
    {
        "word": "Winner",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winner <strong class='noun'> n. </strong> 1 person etc. That wins. 2 <strong class='colloq'> colloq. </strong> Successful or highly promising idea etc.</p>"
            }
        ]
    },
    {
        "word": "Winning",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winning <strong class='adj'> —adj.</strong> 1 having or bringing victory. 2 attractive (winning smile). <strong class='noun'> —n. </strong> (in pl.) Money won. winningly adv.</p>"
            }
        ]
    },
    {
        "word": "Winning-post",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winning-post <strong class='noun'> n. </strong> Post marking the end of a race.</p>"
            }
        ]
    },
    {
        "word": "Winnow",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winnow <strong class='verb'> v. </strong> 1 blow (grain) free of chaff etc. By an air-current. 2 (foll. By out, away, from, etc.) Get rid of (chaff etc.) From grain. 3 sift, examine (evidence etc.). [old english: related to *wind1]</p>"
            }
        ]
    },
    {
        "word": "Wino",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wino <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> slang alcoholic.</p>"
            }
        ]
    },
    {
        "word": "Winsome",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winsome <strong class='adj'> adj. </strong> Attractive, engaging. winsomely <strong class='adv'> adv. </strong> Winsomeness <strong class='noun'> n. </strong> [old english, = joyous]</p>"
            }
        ]
    },
    {
        "word": "Winter",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winter <strong class='noun'> —n. </strong> 1 coldest and last season of the year. 2 (attrib.) Characteristic of or fit for winter. —v. (usu. Foll. By at, in) pass the winter. [old english]</p>"
            }
        ]
    },
    {
        "word": "Winter garden",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winter garden <strong class='noun'> n. </strong> Garden or conservatory of plants flourishing in winter.</p>"
            }
        ]
    },
    {
        "word": "Wintergreen",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wintergreen <strong class='noun'> n. </strong> A kind of plant remaining green all winter.</p>"
            }
        ]
    },
    {
        "word": "Winter jasmine",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winter jasmine <strong class='noun'> n. </strong> Jasmine with yellow flowers in winter.</p>"
            }
        ]
    },
    {
        "word": "Winter solstice",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winter solstice <strong class='noun'> n. </strong> About 22 dec.</p>"
            }
        ]
    },
    {
        "word": "Winter sports",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winter sports <strong class='n_pl'> n.pl. </strong> Sports performed on snow or ice.</p>"
            }
        ]
    },
    {
        "word": "Wintertime",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wintertime <strong class='noun'> n. </strong> Season or period of winter.</p>"
            }
        ]
    },
    {
        "word": "Wintry",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wintry <strong class='adj'> adj. </strong> (-ier, -iest) 1 characteristic of winter. 2 lacking warmth; unfriendly. wintriness n.</p>"
            }
        ]
    },
    {
        "word": "Winy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Winy <strong class='adj'> adj. </strong> (-ier, -iest) wine-flavoured.</p>"
            }
        ]
    },
    {
        "word": "Wipe",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wipe <strong class='noun'> —v. </strong> (-ping) 1 clean or dry the surface of by rubbing. 2 rub (a cloth) over a surface. 3 spread (a liquid etc.) Over a surface by rubbing. 4 (often foll. By away, off, etc.) A clear or remove by wiping. B erase or eliminate completely. <strong class='noun'> —n. </strong> 1 act of wiping. 2 piece of specially treated material for wiping (antiseptic wipes). wipe down clean (a wall etc.) By wiping. Wipe the floor with <strong class='colloq'> colloq. </strong> Inflict a humiliating defeat on. Wipe off annul (a debt etc.). Wipe out 1 destroy, annihilate, obliterate. 2 clean the inside of. Wipe up 1 dry (dishes etc.). 2 take up (a liquid etc.) By wiping. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wiper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wiper <strong class='noun'> n. </strong> = *windscreen wiper.</p>"
            }
        ]
    },
    {
        "word": "Wire",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wire <strong class='noun'> —n. </strong> 1 a metal drawn out into a thread or thin flexible rod. B piece of this. C (attrib.) Made of wire. 2 length of this for fencing or to carry an electric current etc. 3 <strong class='colloq'> colloq. </strong> Tele <strong class='gram'>gram.</strong> —v. <strong class='adj'> (-ring) </strong> 1 provide, fasten, strengthen, etc., with wire. 2 (often foll. By up) install electrical circuits in (a building, equipment, etc.). 3 <strong class='colloq'> colloq. </strong> Telegraph. get one's wires crossed become confused and misunderstood. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wire-haired",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wire-haired <strong class='adj'> adj. </strong> (esp. Of a dog) with stiff or wiry hair.</p>"
            }
        ]
    },
    {
        "word": "Wireless",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wireless <strong class='noun'> n. </strong> Radio; radio receiving set.</p>"
            }
        ]
    },
    {
        "word": "Wire netting",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wire netting <strong class='noun'> n. </strong> Netting of meshed wire.</p>"
            }
        ]
    },
    {
        "word": "Wire-tapping",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wire-tapping <strong class='noun'> n. </strong> Tapping of telephone lines to eavesdrop.</p>"
            }
        ]
    },
    {
        "word": "Wire wool",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wire wool <strong class='noun'> n. </strong> Mass of fine wire for scouring or rubbing down.</p>"
            }
        ]
    },
    {
        "word": "Wireworm",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wireworm <strong class='noun'> n. </strong> Destructive larva of a kind of beetle.</p>"
            }
        ]
    },
    {
        "word": "Wiring",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wiring <strong class='noun'> n. </strong> System or installation of wires providing electrical circuits.</p>"
            }
        ]
    },
    {
        "word": "Wiry",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wiry <strong class='adj'> adj. </strong> (-ier, -iest) 1 sinewy, untiring. 2 like wire; tough, coarse. wiriness n.</p>"
            }
        ]
    },
    {
        "word": "Wisdom",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wisdom <strong class='noun'> n. </strong> 1 experience and knowledge together with the power of applying them. 2 prudence; common sense. 3 wise sayings. [old english: related to *wise1]</p>"
            }
        ]
    },
    {
        "word": "Wisdom tooth",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wisdom tooth <strong class='noun'> n. </strong> Hindmost molar usu. Cut at about 20 years of age.</p>"
            }
        ]
    },
    {
        "word": "Wise1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wise1 <strong class='adj'> adj. </strong> 1 having, showing, or dictated by wisdom. 2 prudent, sensible. 3 having knowledge (often in <strong class='n_pl'> comb. </strong> : streetwise; worldly-wise). 4 suggestive of wisdom. 5 us <strong class='colloq'> colloq. </strong> Alert, crafty. be (or get) wise to <strong class='colloq'> colloq. </strong> Be (or become) aware of. None the wiser knowing no more than before. Put wise <strong class='adj'> (often foll. By to) </strong> <strong class='colloq'> colloq. </strong> Inform (of). Wise up esp. Us <strong class='colloq'> colloq. </strong> Put or get wise. wisely <strong class='adv'> adv. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wise2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wise2 <strong class='noun'> n. </strong> Archaic way, manner, or degree. in no wise not at all. [old english]</p>"
            }
        ]
    },
    {
        "word": "-wise",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>-wise <strong class='slang_v'> suffix </strong>forming adjectives and adverbs of manner (clockwise; lengthwise) or respect (moneywise). <strong class='usage'> Usage:- </strong>more fanciful phrase-based combinations, such as employment-wise (= as regards employment) are restricted to informal contexts.</p>"
            }
        ]
    },
    {
        "word": "Wiseacre",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wiseacre <strong class='noun'> n. </strong> Person who affects a wise manner. [dutch wijsseggher soothsayer]</p>"
            }
        ]
    },
    {
        "word": "Wisecrack",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wisecrack <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Smart pithy remark. —v. Make a wisecrack.</p>"
            }
        ]
    },
    {
        "word": "Wise guy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wise guy <strong class='noun'> n. </strong> Colloq. Know-all.</p>"
            }
        ]
    },
    {
        "word": "Wise man",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wise man <strong class='noun'> n. </strong> Wizard, esp. One of the magi.</p>"
            }
        ]
    },
    {
        "word": "Wisent",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wisent <strong class='noun'> n. </strong> European bison. [german: cf. *bison]</p>"
            }
        ]
    },
    {
        "word": "Wish",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wish <strong class='noun'> —v. </strong> 1 (often foll. By for) have or express a desire or aspiration for (wish for happiness). 2 have as a desire or aspiration (i wish i could sing). 3 want or demand (i wish to go; i wish you to do it). 4 express one's hopes for (wish you success). 5 (foll. By on, upon) <strong class='colloq'> colloq. </strong> Foist on. <strong class='noun'> —n. </strong> 1 a desire, request. B expression of this. 2 thing desired. best (or good) wishes hopes felt or expressed for another's happiness etc. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wishbone",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wishbone <strong class='noun'> n. </strong> Forked bone between the neck and breast of a fowl often broken between two people, the longer portion entitling the holder to make a wish.</p>"
            }
        ]
    },
    {
        "word": "Wishful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wishful <strong class='adj'> adj. </strong> (often foll. By to + infin.) Desiring. wishfully adv.</p>"
            }
        ]
    },
    {
        "word": "Wish-fulfilment",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wish-fulfilment <strong class='noun'> n. </strong> Tendency for subconscious desire to be satisfied in fantasy.</p>"
            }
        ]
    },
    {
        "word": "Wishful thinking",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wishful thinking <strong class='noun'> n. </strong> Belief founded on wishes rather than facts.</p>"
            }
        ]
    },
    {
        "word": "Wishing-well",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wishing-well <strong class='noun'> n. </strong> Well into which coins are dropped and a wish is made.</p>"
            }
        ]
    },
    {
        "word": "Wishy-washy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wishy-washy <strong class='adj'> adj. </strong> Colloq. 1 feeble in quality or character. 2 weak, watery. [from *wash]</p>"
            }
        ]
    },
    {
        "word": "Wisp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wisp <strong class='noun'> n. </strong> 1 small bundle or twist of straw etc. 2 small separate quantity of smoke, hair, etc. 3 small thin person etc. wispy adj. (-ier, -iest). [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Wisteria",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wisteria <strong class='noun'> n. </strong> (also wistaria) climbing plant with blue, purple, or white hanging flowers. [wistar, name of an anatomist]</p>"
            }
        ]
    },
    {
        "word": "Wistful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wistful <strong class='adj'> adj. </strong> Yearning, mournfully expectant or wishful. wistfully <strong class='adv'> adv. </strong> Wistfulness <strong class='noun'> n. </strong> [apparently an assimilation of obsolete wistly ‘intently’ to wishful]</p>"
            }
        ]
    },
    {
        "word": "Wit",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wit <strong class='noun'> n. </strong> 1 (in sing. Or pl.) Intelligence; quick understanding. 2 a unexpected combining or contrasting of ideas or expressions. B power of giving pleasure by this. 3 person possessing such power. at one's wit's (or wits') end utterly at a loss or in despair. Have (or keep) one's wits about one be alert. Live by one's wits live by ingenious or crafty expedients, without a settled occupation. Out of one's wits mad. To wit that is to say, namely. [old english]</p>"
            }
        ]
    },
    {
        "word": "Witch",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witch <strong class='noun'> n. </strong> 1 sorceress, woman supposed to have dealings with the devil or evil spirits. 2 old hag. 3 fascinating girl or woman. [old english]</p>"
            }
        ]
    },
    {
        "word": "Witchcraft",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witchcraft <strong class='noun'> n. </strong> 1 use of magic. 2 bewitching charm.</p>"
            }
        ]
    },
    {
        "word": "Witch-doctor",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witch-doctor <strong class='noun'> n. </strong> Tribal magician of primitive people.</p>"
            }
        ]
    },
    {
        "word": "Witchery",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witchery <strong class='noun'> n. </strong> = *witchcraft.</p>"
            }
        ]
    },
    {
        "word": "Witches' sabbath",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witches' sabbath <strong class='noun'> n. </strong> Supposed midnight orgy of the devil and witches.</p>"
            }
        ]
    },
    {
        "word": "Witch-hazel",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witch-hazel <strong class='noun'> n. </strong> (also wych-hazel) 1 american shrub with bark yielding an astringent lotion. 2 this lotion.</p>"
            }
        ]
    },
    {
        "word": "Witch-hunt",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witch-hunt <strong class='noun'> n. </strong> Campaign against persons suspected of unpopular or unorthodox views, esp. Communists.</p>"
            }
        ]
    },
    {
        "word": "With",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>With <strong class='n_pl'> comb. </strong> Expressing: 1 instrument or means used (cut with a knife). 2 a association or company (lives with his mother; works with shell). B parting of company (dispense with). 3 cause (shiver with fear). 4 possession (man with dark hair; filled with water). 5 circumstances (sleep with the window open). 6 manner (handle with care). 7 agreement (sympathize with). 8 disagreement, antagonism (incompatible with; quarrel with). 9 understanding (are you with me?). 10 reference or regard (be patient with them; how are things with you?). away (or in or out etc.) With (as int.) Take, send, or put (a person or thing) away (or in or out etc.). With it <strong class='colloq'> colloq. </strong> 1 up to date. 2 alert and comprehending. With that thereupon. [old english]</p>"
            }
        ]
    },
    {
        "word": "Withdraw",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Withdraw <strong class='verb'> v. </strong> (past withdrew;  <strong class='archaic_it_was'>past part.</strong> Withdrawn) 1 pull or take aside or back. 2 discontinue, cancel, retract. 3 remove; take away. 4 take (money) out of an account. 5 retire or move apart. 6 (as withdrawn adj.) Abnormally shy and unsociable; mentally detached. [from *with = away]</p>"
            }
        ]
    },
    {
        "word": "Withdrawal",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Withdrawal <strong class='noun'> n. </strong> 1 withdrawing or being withdrawn. 2 process of ceasing to take an addictive drug etc., often with an unpleasant reaction (withdrawal symptoms). 3 = *coitus interruptus.</p>"
            }
        ]
    },
    {
        "word": "Withe",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Withe <strong class='noun'> n. </strong> (also withy) (pl. Withes or withies) tough flexible shoot, esp. Of willow, used for binding, basketwork, etc. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wither",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wither <strong class='verb'> v. </strong> 1 (often foll. By up) make or become dry and shrivelled. 2 (often foll. By away) deprive of or lose vigour or freshness. 3 (esp. As withering adj.) Blight with scorn etc. witheringly <strong class='adv'> adv. </strong> [apparently <strong class='var'> var. </strong> Of *weather]</p>"
            }
        ]
    },
    {
        "word": "Withers",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Withers <strong class='n_pl'> n.pl. </strong> Ridge between a horse's shoulder-blades. [obsolete wither against (the collar)]</p>"
            }
        ]
    },
    {
        "word": "Withershins",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Withershins <strong class='var'> var. </strong> Of *widdershins.</p>"
            }
        ]
    },
    {
        "word": "Withhold",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Withhold <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> -held) 1 hold back; restrain. 2 refuse to give, grant, or allow. [from *with = away]</p>"
            }
        ]
    },
    {
        "word": "Within",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Within <strong class='adv'> —adv. </strong> 1 inside. 2 indoors. 3 in spirit (pure within). <strong class='prep'> —prep. </strong> 1 inside. 2 a not beyond or out of. B not transgressing or exceeding. 3 not further off than (within three miles; within ten days). within one's grasp close enough to be obtained.</p>"
            }
        ]
    },
    {
        "word": "Without",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Without <strong class='prep'> —prep. </strong> 1 not having or feeling or showing. 2 with freedom from. 3 in the absence of. 4 with neglect or avoidance of. 5 archaic outside. <strong class='adv'> —adv. </strong> Archaic or literary 1 outside. 2 out of doors. [old english: related to *with, *out]</p>"
            }
        ]
    },
    {
        "word": "Withstand",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Withstand <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> -stood) oppose, hold out against. [old english: related to *with, *stand]</p>"
            }
        ]
    },
    {
        "word": "Withy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Withy <strong class='var'> var. </strong> Of *withe.</p>"
            }
        ]
    },
    {
        "word": "Witless",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witless <strong class='adj'> adj. </strong> Foolish, crazy. [old english: related to *wit]</p>"
            }
        ]
    },
    {
        "word": "Witness",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witness <strong class='noun'> —n. </strong> 1 = *eyewitness. 2 a person giving sworn testimony. B person attesting another's signature to a document. 3 (foll. By to, of) person or thing whose existence etc. Attests or proves something. 4 testimony, evidence, confirmation. —v. 1 be an eye-witness of. 2 be witness to the authenticity of (a signature etc.). 3 serve as evidence or an indication of. 4 (foll. By against, for, to) give or serve as evidence. bear witness to (or of) 1 attest the truth of. 2 state one's belief in. Call to witness appeal to for confirmation etc. [old english: related to *wit]</p>"
            }
        ]
    },
    {
        "word": "Witness-box",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witness-box <strong class='noun'> n. </strong> (us witness-stand) enclosure in a lawcourt from which witnesses give evidence.</p>"
            }
        ]
    },
    {
        "word": "Witter",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witter <strong class='verb'> v. </strong> (often foll. By on) <strong class='colloq'> colloq. </strong> Chatter annoyingly or on trivial matters. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Witticism",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witticism <strong class='noun'> n. </strong> Witty remark. [from *witty]</p>"
            }
        ]
    },
    {
        "word": "Wittingly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wittingly <strong class='adv'> adv. </strong> Aware of what one is doing; intentionally. [from *wit]</p>"
            }
        ]
    },
    {
        "word": "Witty",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Witty <strong class='adj'> adj. </strong> (-ier, -iest) showing esp. Verbal wit. wittily <strong class='adv'> adv. </strong> Wittiness <strong class='noun'> n. </strong> [old english: related to *wit]</p>"
            }
        ]
    },
    {
        "word": "Wives",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wives <strong class='pl'> pl. </strong> Of *wife.</p>"
            }
        ]
    },
    {
        "word": "Wizard",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wizard <strong class='noun'> —n. </strong> 1 sorcerer; magician. 2 person of remarkable powers, genius. <strong class='adj'> —adj.</strong> Slang wonderful. wizardry <strong class='noun'> n. </strong> [from *wise1]</p>"
            }
        ]
    },
    {
        "word": "Wizened",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wizened <strong class='adj'> adj. </strong> Shrivelled-looking. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wnw",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wnw <strong class='sing'> abbr. </strong> West-north-west.</p>"
            }
        ]
    },
    {
        "word": "Wo",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wo <strong class='sing'> abbr. </strong> Warrant-officer.</p>"
            }
        ]
    },
    {
        "word": "Woad",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woad <strong class='noun'> n. </strong> 1 plant yielding a blue dye. 2 dye from this. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wobble",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wobble <strong class='noun'> —v. </strong> (-ling) 1 sway from side to side. 2 stand or go unsteadily; stagger. 3 waver, vacillate. <strong class='noun'> —n. </strong> State or instance of wobbling. [cf. Low german wabbeln]</p>"
            }
        ]
    },
    {
        "word": "Wobbly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wobbly <strong class='adj'> adj. </strong> (-ier, -iest) 1 tending to wobble. 2 wavy (wobbly line). 3 weak after illness. 4 wavering, insecure (the economy was wobbly). throw a wobbly slang have a tantrum or fit of nerves.</p>"
            }
        ]
    },
    {
        "word": "Wodge",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wodge <strong class='noun'> n. </strong> Colloq. Chunk, lump. [alteration of *wedge]</p>"
            }
        ]
    },
    {
        "word": "Woe",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woe <strong class='noun'> n. </strong> 1 affliction; bitter grief. 2 (in pl.) Calamities. woe betide see *betide. Woe is me alas. [old english]</p>"
            }
        ]
    },
    {
        "word": "Woebegone",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woebegone <strong class='adj'> adj. </strong> Dismal-looking. [from *woe, begone = surrounded]</p>"
            }
        ]
    },
    {
        "word": "Woeful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woeful <strong class='adj'> adj. </strong> 1 sorrowful. 2 causing or feeling affliction. 3 very bad. woefully adv.</p>"
            }
        ]
    },
    {
        "word": "Wog",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wog <strong class='noun'> n. </strong> Slang offens. Foreigner, esp. A non-white one. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Woggle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woggle <strong class='noun'> n. </strong> Leather etc. Ring through which the ends of a scout's neckerchief are passed at the neck. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Wok",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wok <strong class='noun'> n. </strong> Bowl-shaped frying-pan used in esp. Chinese cookery. [chinese]</p>"
            }
        ]
    },
    {
        "word": "Woke  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woke  <strong class='slang_v'>past of *</strong> wake1.</p>"
            }
        ]
    },
    {
        "word": "Woken ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woken  <strong class='archaic_it_was'>past part. Of *</strong> wake1.</p>"
            }
        ]
    },
    {
        "word": "Wold",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wold <strong class='noun'> n. </strong> High open uncultivated land or moor. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wolf",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wolf <strong class='noun'> —n. </strong> (pl. Wolves) 1 wild animal related to the dog, usu. Hunting in packs. 2 slang man who seduces women. —v. (often foll. By down) devour greedily. cry wolf raise false alarms. Keep the wolf from the door avert starvation. wolfish adj. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wolfhound",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wolfhound <strong class='noun'> n. </strong> Dog of a kind used <strong class='archaic_it_was'> orig. </strong> To hunt wolves.</p>"
            }
        ]
    },
    {
        "word": "Wolf in sheep's clothing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wolf in sheep's clothing <strong class='noun'> n. </strong> Hostile person who pretends friendship.</p>"
            }
        ]
    },
    {
        "word": "Wolfram",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wolfram <strong class='noun'> n. </strong> 1 tungsten. 2 tungsten ore. [german]</p>"
            }
        ]
    },
    {
        "word": "Wolfsbane",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wolfsbane <strong class='noun'> n. </strong> Aconite.</p>"
            }
        ]
    },
    {
        "word": "Wolf-whistle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wolf-whistle <strong class='noun'> n. </strong> Whistle made by a man to a sexually attractive woman.</p>"
            }
        ]
    },
    {
        "word": "Wolverine",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wolverine <strong class='noun'> n. </strong> N. American animal of the weasel family. [related to *wolf]</p>"
            }
        ]
    },
    {
        "word": "Wolves",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wolves <strong class='pl'> pl. </strong> Of *wolf.</p>"
            }
        ]
    },
    {
        "word": "Wolves",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wolves <strong class='pl'> pl. </strong> Of *wolf.</p>"
            }
        ]
    },
    {
        "word": "Woman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woman <strong class='noun'> n. </strong> (pl. Women) 1 adult human female. 2 the female sex. 3 <strong class='colloq'> colloq. </strong> Wife or girlfriend. 4 (prec. By the) feminine characteristics (brought out the woman in him). 5 (attrib.) Female (woman doctor). 6 (in <strong class='n_pl'> comb. </strong> ) Woman of a specified nationality, skill, etc. (englishwoman; horsewoman). 7 <strong class='colloq'> colloq. </strong> Charwoman. [old english]</p>"
            }
        ]
    },
    {
        "word": "Womanhood",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Womanhood <strong class='noun'> n. </strong> 1 female maturity. 2 womanly instinct. 3 womankind.</p>"
            }
        ]
    },
    {
        "word": "Womanish",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Womanish <strong class='adj'> adj. </strong> Derog. Effeminate, unmanly.</p>"
            }
        ]
    },
    {
        "word": "Womanize",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Womanize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) chase after women; philander. womanizer n.</p>"
            }
        ]
    },
    {
        "word": "Womankind",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Womankind <strong class='noun'> n. </strong> (also womenkind) women in general.</p>"
            }
        ]
    },
    {
        "word": "Womanly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Womanly <strong class='adj'> adj. </strong> Having or showing qualities associated with women. womanliness n.</p>"
            }
        ]
    },
    {
        "word": "Womb",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Womb <strong class='noun'> n. </strong> Organ of conception and gestation in a woman and other female mammals. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wombat",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wombat <strong class='noun'> n. </strong> Burrowing plant-eating australian marsupial. [aboriginal]</p>"
            }
        ]
    },
    {
        "word": "Women",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Women <strong class='pl'> pl. </strong> Of *woman.</p>"
            }
        ]
    },
    {
        "word": "Womenfolk",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Womenfolk <strong class='noun'> n. </strong> 1 women in general. 2 the women in a family.</p>"
            }
        ]
    },
    {
        "word": "Womenkind",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Womenkind <strong class='var'> var. </strong> Of *womankind.</p>"
            }
        ]
    },
    {
        "word": "Women's libber",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Women's libber <strong class='noun'> n. </strong> Colloq. Supporter of women's liberation.</p>"
            }
        ]
    },
    {
        "word": "Women's liberation",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Women's liberation <strong class='noun'> n. </strong> (also women's lib) <strong class='colloq'> colloq. </strong> Movement urging the liberation of women from domestic duties and subservient status.</p>"
            }
        ]
    },
    {
        "word": "Women's rights",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Women's rights <strong class='n_pl'> n.pl. </strong> Position of legal and social equality with men.</p>"
            }
        ]
    },
    {
        "word": "Won  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Won  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>win.</p>"
            }
        ]
    },
    {
        "word": "Wonder",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wonder <strong class='noun'> —n. </strong> 1 emotion, esp. Admiration, excited by what is unexpected, unfamiliar, or inexplicable. 2 strange or remarkable thing, specimen, event, etc. 3 (attrib.) Having marvellous or amazing properties etc. (wonder drug; wonder woman). —v. 1 be filled with wonder or great surprise. 2 (foll. By that) be surprised to find. 3 desire or be curious to know (i wonder what the time is). i shouldn't wonder <strong class='colloq'> colloq. </strong> I think it likely. No (or small) wonder one cannot be surprised. Work (or do) wonders 1 do miracles. 2 be remarkably effective. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wonderful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wonderful <strong class='adj'> adj. </strong> Very remarkable or admirable. wonderfully <strong class='adv'> adv. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wonderland",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wonderland <strong class='noun'> n. </strong> 1 fairyland. 2 land of surprises or marvels.</p>"
            }
        ]
    },
    {
        "word": "Wonderment",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wonderment <strong class='noun'> n. </strong> Surprise, awe.</p>"
            }
        ]
    },
    {
        "word": "Wondrous  poet.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wondrous poet. <strong class='adj'> —adj.</strong> Wonderful. <strong class='adv'> —adv. </strong> Wonderfully (wondrous kind).</p>"
            }
        ]
    },
    {
        "word": "Wonky",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wonky <strong class='adj'> adj. </strong> (-ier, -iest) slang 1 crooked, askew. 2 loose, unsteady. 3 unreliable. [fanciful]</p>"
            }
        ]
    },
    {
        "word": "Wont",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wont <strong class='predic'> —predic. </strong> <strong class='adj'> Adj. </strong> Archaic or literary (foll. By to + infin.) Accustomed. <strong class='noun'> —n. </strong> Formal or joc. What is customary, one's habit. [old english]</p>"
            }
        ]
    },
    {
        "word": "Won't",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Won't <strong class='contr'> contr. </strong> Will not.</p>"
            }
        ]
    },
    {
        "word": "Wonted",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wonted <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Habitual, usual.</p>"
            }
        ]
    },
    {
        "word": "Woo",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woo <strong class='verb'> v. </strong> (woos, wooed) 1 court; seek the hand or love of. 2 try to win (fame, fortune, etc.). 3 seek the favour or support of. 4 coax or importune. wooer <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wood",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wood <strong class='noun'> n. </strong> 1 a hard fibrous substance of the trunk or branches of a tree or shrub. B this for timber or fuel. 2 (in sing. Or pl.) Growing trees densely occupying a tract of land. 3 wooden cask for wine etc. 4 wooden-headed golf club. 5 = *bowl2 <strong class='noun'> n. </strong> 1. not see the wood for the trees fail to grasp the main issue from over-attention to details. Out of the wood (or woods) out of danger or difficulty. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wood anemone",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wood anemone <strong class='noun'> n. </strong> A wild spring-flowering anemone.</p>"
            }
        ]
    },
    {
        "word": "Woodbine",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodbine <strong class='noun'> n. </strong> Honeysuckle.</p>"
            }
        ]
    },
    {
        "word": "Woodchuck",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodchuck <strong class='noun'> n. </strong> N. American marmot. [american indian name]</p>"
            }
        ]
    },
    {
        "word": "Woodcock",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodcock <strong class='noun'> n. </strong> Game-bird related to the snipe.</p>"
            }
        ]
    },
    {
        "word": "Woodcraft",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodcraft <strong class='noun'> n. </strong> 1 knowledge of woodland, esp. In camping etc. 2 skill in woodwork.</p>"
            }
        ]
    },
    {
        "word": "Woodcut",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodcut <strong class='noun'> n. </strong> 1 relief cut on wood. 2 print made from this.</p>"
            }
        ]
    },
    {
        "word": "Woodcutter",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodcutter <strong class='noun'> n. </strong> Person who cuts timber.</p>"
            }
        ]
    },
    {
        "word": "Wooded",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wooded <strong class='adj'> adj. </strong> Having woods or many trees.</p>"
            }
        ]
    },
    {
        "word": "Wooden",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wooden <strong class='adj'> adj. </strong> 1 made of wood. 2 like wood. 3 a stiff, clumsy. B expressionless. woodenly <strong class='adv'> adv. </strong> Woodenness n.</p>"
            }
        ]
    },
    {
        "word": "Woodland",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodland <strong class='noun'> n. </strong> (often attrib.) Wooded country, woods.</p>"
            }
        ]
    },
    {
        "word": "Woodlouse",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodlouse <strong class='noun'> n. </strong> (pl. -lice) small land crustacean with many legs.</p>"
            }
        ]
    },
    {
        "word": "Woodman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodman <strong class='noun'> n. </strong> Forester.</p>"
            }
        ]
    },
    {
        "word": "Woodpecker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodpecker <strong class='noun'> n. </strong> Bird that taps tree-trunks in search of insects.</p>"
            }
        ]
    },
    {
        "word": "Woodpigeon",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodpigeon <strong class='noun'> n. </strong> Dove with white patches like a ring round its neck.</p>"
            }
        ]
    },
    {
        "word": "Woodpile",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodpile <strong class='noun'> n. </strong> Pile of wood, esp. For fuel.</p>"
            }
        ]
    },
    {
        "word": "Wood pulp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wood pulp <strong class='noun'> n. </strong> Wood-fibre prepared for paper-making.</p>"
            }
        ]
    },
    {
        "word": "Woodruff",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodruff <strong class='noun'> n. </strong> White-flowered plant with fragrant leaves.</p>"
            }
        ]
    },
    {
        "word": "Woodshed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodshed <strong class='noun'> n. </strong> Shed where wood for fuel is stored. something nasty in the woodshed <strong class='colloq'> colloq. </strong> Shocking thing kept secret.</p>"
            }
        ]
    },
    {
        "word": "Woodwind",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodwind <strong class='noun'> n. </strong> 1 wind instruments that were (mostly) <strong class='archaic_it_was'> orig. </strong> Made of wood, e.g. The flute, clarinet, oboe, and saxophone. 2 one such instrument.</p>"
            }
        ]
    },
    {
        "word": "Woodwork",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodwork <strong class='noun'> n. </strong> 1 making of things in wood. 2 things made of wood. crawl out of the woodwork <strong class='colloq'> colloq. </strong> (of something distasteful) appear.</p>"
            }
        ]
    },
    {
        "word": "Woodworm",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodworm <strong class='noun'> n. </strong> 1 wood-boring larva of a kind of beetle. 2 condition of wood affected by this.</p>"
            }
        ]
    },
    {
        "word": "Woody",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woody <strong class='adj'> adj. </strong> (-ier, -iest) 1 wooded. 2 like or of wood. woodiness n.</p>"
            }
        ]
    },
    {
        "word": "Woodyard",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woodyard <strong class='noun'> n. </strong> Yard where wood is used or stored.</p>"
            }
        ]
    },
    {
        "word": "Woody nightshade",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woody nightshade <strong class='noun'> n. </strong> A kind of nightshade with poisonous red berries.</p>"
            }
        ]
    },
    {
        "word": "Woof1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woof1 <strong class='noun'> —n. </strong> Gruff bark of a dog. —v. Give a woof. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Woof2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woof2 <strong class='noun'> n. </strong> = *weft 1. [old english: related to *web]</p>"
            }
        ]
    },
    {
        "word": "Woofer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woofer <strong class='noun'> n. </strong> Loudspeaker for low frequencies. [from *woof1]</p>"
            }
        ]
    },
    {
        "word": "Wool",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wool <strong class='noun'> n. </strong> 1 fine soft wavy hair from the fleece of sheep etc. 2 woollen yarn or cloth or clothing. 3 wool-like substance (steel wool). pull the wool over a person's eyes deceive a person. [old english]</p>"
            }
        ]
    },
    {
        "word": "Woolen  (brit. Woollen)",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woolen (brit. Woollen) <strong class='adj'> —adj.</strong> Made wholly or partly of wool. <strong class='noun'> —n. </strong> 1 woollen fabric. 2 (in pl.) Woollen garments. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wool-gathering",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wool-gathering <strong class='noun'> n. </strong> Absent-mindedness.</p>"
            }
        ]
    },
    {
        "word": "Woollen  (us woolen)",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woollen (us woolen) <strong class='adj'> —adj.</strong> Made wholly or partly of wool. <strong class='noun'> —n. </strong> 1 woollen fabric. 2 (in pl.) Woollen garments. [old english]</p>"
            }
        ]
    },
    {
        "word": "Woolly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woolly <strong class='adj'> —adj.</strong> (-ier, -iest) 1 bearing wool. 2 like wool. 3 woollen (a woolly cardigan). 4 (of a sound) indistinct. 5 (of thought) vague or confused. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> <strong class='colloq'> colloq. </strong> Woollen garment, esp. A pullover. woolliness n.</p>"
            }
        ]
    },
    {
        "word": "Woolsack",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woolsack <strong class='noun'> n. </strong> 1 lord chancellor's wool-stuffed seat in the house of lords. 2 his position.</p>"
            }
        ]
    },
    {
        "word": "Woozy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woozy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> 1 dizzy or unsteady. 2 slightly drunk. woozily <strong class='adv'> adv. </strong> Wooziness <strong class='noun'> n. </strong> [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Wop",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wop <strong class='noun'> n. </strong> Slang offens. Italian or other s. European. [origin uncertain]</p>"
            }
        ]
    },
    {
        "word": "Worcester sauce",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worcester sauce <strong class='noun'> n. </strong> A pungent sauce. [worcester in england]</p>"
            }
        ]
    },
    {
        "word": "Word",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Word <strong class='noun'> —n. </strong> 1 meaningful element of speech, usu. Shown with a space on either side of it when written or printed. 2 speech, esp. As distinct from action. 3 one's promise or assurance. 4 (in sing. Or pl.) Thing said, remark, conversation. 5 (in pl.) Text of a song or an actor's part. 6 (in pl.) Angry talk (have words). 7 news, message (send word). 8 command (gave the word to begin). —v. Put into words; select words to express. in other words expressing the same thing differently. In so many words in those very words; explicitly. In a (or one) word briefly. My (or upon my) word exclamation of surprise etc. Take a person at his or her word interpret a person's words literally. Take a person's word for it believe a person's statement without investigation etc. The word (or word of god) the bible. Word for word in exactly the same or (of translation) corresponding words. wordless adj. [old english]</p>"
            }
        ]
    },
    {
        "word": "Word-blindness",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Word-blindness <strong class='noun'> n. </strong> = *dyslexia.</p>"
            }
        ]
    },
    {
        "word": "Word-game",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Word-game <strong class='noun'> n. </strong> Game involving the making or selection etc. Of words.</p>"
            }
        ]
    },
    {
        "word": "Wording",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wording <strong class='noun'> n. </strong> Form of words used.</p>"
            }
        ]
    },
    {
        "word": "Word of mouth",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Word of mouth <strong class='noun'> n. </strong> Speech (only).</p>"
            }
        ]
    },
    {
        "word": "Word-perfect",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Word-perfect <strong class='adj'> adj. </strong> Knowing one's part etc. By heart.</p>"
            }
        ]
    },
    {
        "word": "Wordplay",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wordplay <strong class='noun'> n. </strong> Witty use of words, esp. Punning.</p>"
            }
        ]
    },
    {
        "word": "Word processor",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Word processor <strong class='noun'> n. </strong> Computer program, or device incorporating a computer, used for storing text entered from a keyboard, making corrections, and providing a printout. word-process v. Word processing n.</p>"
            }
        ]
    },
    {
        "word": "Wordy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wordy <strong class='adj'> adj. </strong> (-ier, -iest) using or expressed in too many words. wordily <strong class='adv'> adv. </strong> Wordiness n.</p>"
            }
        ]
    },
    {
        "word": "Wore  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wore  <strong class='slang_v'>past of *</strong> wear.</p>"
            }
        ]
    },
    {
        "word": "Work",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Work <strong class='noun'> —n. </strong> 1 application of mental or physical effort to a purpose; use of energy. 2 task to be undertaken. 3 thing done or made by work; result of an action. 4 employment or occupation etc., esp. As a means of earning income. 5 literary or musical composition. 6 actions or experiences of a specified kind (nice work!). 7 (in <strong class='n_pl'> comb. </strong> ) Things made of a specified material or with specified tools etc. (ironwork; needlework).</p>"
            }
        ]
    },
    {
        "word": "Workable",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workable <strong class='adj'> adj. </strong> That can be worked, will work, or is worth working. workability n.</p>"
            }
        ]
    },
    {
        "word": "Workaday",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workaday <strong class='adj'> adj. </strong> Ordinary, everyday, practical.</p>"
            }
        ]
    },
    {
        "word": "Workaholic",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workaholic <strong class='noun'> n. </strong> Colloq. Person addicted to working.</p>"
            }
        ]
    },
    {
        "word": "Work-basket",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Work-basket <strong class='noun'> n. </strong> Basket for sewing materials.</p>"
            }
        ]
    },
    {
        "word": "Workbench",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workbench <strong class='noun'> n. </strong> Bench for manual work, esp. Carpentry.</p>"
            }
        ]
    },
    {
        "word": "Workbook",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workbook <strong class='noun'> n. </strong> Student's book with exercises.</p>"
            }
        ]
    },
    {
        "word": "Workbox",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workbox <strong class='noun'> n. </strong> Box for tools, needlework, etc.</p>"
            }
        ]
    },
    {
        "word": "Work camp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Work camp <strong class='noun'> n. </strong> Camp at which community work is done, esp. By young volunteers.</p>"
            }
        ]
    },
    {
        "word": "Workday",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workday <strong class='noun'> n. </strong> Day on which work is usually done.</p>"
            }
        ]
    },
    {
        "word": "Worker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worker <strong class='noun'> n. </strong> 1 person who works, esp. For an employer. 2 neuter bee or ant. 3 person who works hard.</p>"
            }
        ]
    },
    {
        "word": "Work experience",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Work experience <strong class='noun'> n. </strong> Scheme intended to give young people temporary experience of employment.</p>"
            }
        ]
    },
    {
        "word": "Workforce",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workforce <strong class='noun'> n. </strong> 1 workers engaged or available. 2 number of these.</p>"
            }
        ]
    },
    {
        "word": "Workhouse",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workhouse <strong class='noun'> n. </strong> Hist. Public institution for the poor of a parish.</p>"
            }
        ]
    },
    {
        "word": "Working",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Working <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> 1 a engaged in work (working mother; working man). B while so engaged (all his working life; in working hours). 2 functioning or able to function (working model). <strong class='noun'> —n. </strong> 1 activity of work. 2 functioning. 3 mine or quarry. 4 (usu. In pl.) Machinery, mechanism.</p>"
            }
        ]
    },
    {
        "word": "Working capital",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Working capital <strong class='noun'> n. </strong> Capital actually used in a business.</p>"
            }
        ]
    },
    {
        "word": "Working class",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Working class <strong class='noun'> n. </strong> Social class employed, esp. In manual or industrial work, for wages. working-class adj.</p>"
            }
        ]
    },
    {
        "word": "Working day",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Working day <strong class='noun'> n. </strong> 1 workday. 2 part of the day devoted to work.</p>"
            }
        ]
    },
    {
        "word": "Working hypothesis",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Working hypothesis <strong class='noun'> n. </strong> Hypothesis as a basis for action.</p>"
            }
        ]
    },
    {
        "word": "Working knowledge",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Working knowledge <strong class='noun'> n. </strong> Knowledge adequate to work with.</p>"
            }
        ]
    },
    {
        "word": "Working lunch",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Working lunch <strong class='noun'> n. </strong> Lunch at which business is conducted.</p>"
            }
        ]
    },
    {
        "word": "Working order",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Working order <strong class='noun'> n. </strong> Condition in which a machine works.</p>"
            }
        ]
    },
    {
        "word": "Working party",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Working party <strong class='noun'> n. </strong> Group of people appointed to study and advise on a particular problem.</p>"
            }
        ]
    },
    {
        "word": "Workload",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workload <strong class='noun'> n. </strong> Amount of work to be done.</p>"
            }
        ]
    },
    {
        "word": "Workman",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workman <strong class='noun'> n. </strong> 1 man employed to do manual labour. 2 person with regard to skill in a job (a good workman).</p>"
            }
        ]
    },
    {
        "word": "Workmanlike",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workmanlike <strong class='adj'> adj. </strong> Competent, showing practised skill.</p>"
            }
        ]
    },
    {
        "word": "Workmanship",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workmanship <strong class='noun'> n. </strong> Degree of skill in doing a task or of finish in the product made.</p>"
            }
        ]
    },
    {
        "word": "Workmate",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workmate <strong class='noun'> n. </strong> Person working alongside another.</p>"
            }
        ]
    },
    {
        "word": "Work of art",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Work of art <strong class='noun'> n. </strong> Fine picture, poem, building, etc.</p>"
            }
        ]
    },
    {
        "word": "Workout",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workout <strong class='noun'> n. </strong> Session of physical exercise or training.</p>"
            }
        ]
    },
    {
        "word": "Workpiece",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workpiece <strong class='noun'> n. </strong> Thing worked on with a tool or machine.</p>"
            }
        ]
    },
    {
        "word": "Workplace",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workplace <strong class='noun'> n. </strong> Place at which a person works.</p>"
            }
        ]
    },
    {
        "word": "Workroom",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workroom <strong class='noun'> n. </strong> Room for working in.</p>"
            }
        ]
    },
    {
        "word": "Worksheet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worksheet <strong class='noun'> n. </strong> 1 paper for recording work done or in progress. 2 paper listing questions or activities for students etc. To work through.</p>"
            }
        ]
    },
    {
        "word": "Workshop",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workshop <strong class='noun'> n. </strong> 1 room or building in which goods are manufactured. 2 place or meeting for concerted discussion or activity (dance workshop).</p>"
            }
        ]
    },
    {
        "word": "Work-shy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Work-shy <strong class='adj'> adj. </strong> Disinclined to work.</p>"
            }
        ]
    },
    {
        "word": "Workstation",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Workstation <strong class='noun'> n. </strong> 1 location of a stage in a manufacturing process. 2 computer terminal or the desk etc. Where this is located.</p>"
            }
        ]
    },
    {
        "word": "Work study",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Work study <strong class='noun'> n. </strong> Assessment of methods of working so as to achieve maximum productivity.</p>"
            }
        ]
    },
    {
        "word": "Work table",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Work table <strong class='noun'> n. </strong> Table for working at.</p>"
            }
        ]
    },
    {
        "word": "Worktop",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worktop <strong class='noun'> n. </strong> Flat surface for working on, esp. In a kitchen.</p>"
            }
        ]
    },
    {
        "word": "Work-to-rule",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Work-to-rule <strong class='noun'> n. </strong> Working to rule.</p>"
            }
        ]
    },
    {
        "word": "World",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>World <strong class='noun'> n. </strong> 1 a the earth, or a planetary body like it. B its countries and people. 2 the universe, all that exists. 3 a the time, state, or scene of human existence. B (prec. By the, this) mortal life. 4 secular interests and affairs. 5 human affairs; active life. 6 average, respectable, or fashionable people or their customs or opinions. 7 all that concerns or all who belong to a specified class or sphere of activity (the world of sport). 8 (foll. By of) vast amount. 9 (attrib.) Affecting many nations, of all nations (world politics; world champion). bring (or come) into the world give birth (or be born). For all the world (foll. By like, as if) precisely. In the world of all; at all (what in the world is it?). Man (or woman) of the world person experienced and practical in human affairs. Out of this world <strong class='colloq'> colloq. </strong> Extremely good etc. Think the world of have a very high regard for. [old english]</p>"
            }
        ]
    },
    {
        "word": "World-beater",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>World-beater <strong class='noun'> n. </strong> Person or thing surpassing all others.</p>"
            }
        ]
    },
    {
        "word": "World-class",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>World-class <strong class='adj'> adj. </strong> Of a quality or standard regarded as high throughout the world.</p>"
            }
        ]
    },
    {
        "word": "World cup",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>World cup <strong class='noun'> n. </strong> Competition between football teams from various countries.</p>"
            }
        ]
    },
    {
        "word": "World-famous",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>World-famous <strong class='adj'> adj. </strong> Known throughout the world.</p>"
            }
        ]
    },
    {
        "word": "Worldly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worldly <strong class='adj'> adj. </strong> (-ier, -iest) 1 of the affairs of the world, temporal, earthly (worldly goods). 2 experienced in life, sophisticated, practical. worldliness n.</p>"
            }
        ]
    },
    {
        "word": "Worldly-wise",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worldly-wise <strong class='adj'> adj. </strong> Prudent or shrewd in one's dealings with the world.</p>"
            }
        ]
    },
    {
        "word": "World music",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>World music <strong class='noun'> n. </strong> Pop music that incorporates local or ethnic elements (esp. From the developing world).</p>"
            }
        ]
    },
    {
        "word": "World war",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>World war <strong class='noun'> n. </strong> War involving many major nations.</p>"
            }
        ]
    },
    {
        "word": "World-weary",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>World-weary <strong class='adj'> adj. </strong> Bored with human affairs. world-weariness n.</p>"
            }
        ]
    },
    {
        "word": "Worldwide",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worldwide <strong class='adj'> —adj.</strong> Occurring in or known in all parts of the world. <strong class='adv'> —adv. </strong> Throughout the world.</p>"
            }
        ]
    },
    {
        "word": "Worm",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worm <strong class='noun'> —n. </strong> 1 any of various types of creeping invertebrate animals with long slender bodies and no limbs. 2 larva of an insect, esp. In fruit or wood. 3 (in pl.) Intestinal parasites. 4 insignificant or contemptible person. 5 spiral part of a screw. —v. 1 (often refl.) Move with a crawling motion. 2 refl. (foll. By into) insinuate oneself into favour etc. 3 (foll. By out) obtain (a secret etc.) By cunning persistence. 4 rid (a dog etc.) Of worms. [old english]</p>"
            }
        ]
    },
    {
        "word": "Worm-cast",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worm-cast <strong class='noun'> n. </strong> Convoluted mass of earth left on the surface by a burrowing earthworm.</p>"
            }
        ]
    },
    {
        "word": "Wormeaten",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wormeaten <strong class='adj'> adj. </strong> 1 eaten into by worms; decayed. 2 old and dilapidated.</p>"
            }
        ]
    },
    {
        "word": "Worm-hole",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worm-hole <strong class='noun'> n. </strong> Hole left by the passage of a worm.</p>"
            }
        ]
    },
    {
        "word": "Worm's-eye view",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worm's-eye view <strong class='noun'> n. </strong> View from below or from a humble position.</p>"
            }
        ]
    },
    {
        "word": "Wormwood",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wormwood <strong class='noun'> n. </strong> 1 plant with a bitter aromatic taste. 2 bitter mortification; source of this. [old english: cf. *vermouth]</p>"
            }
        ]
    },
    {
        "word": "Wormy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wormy <strong class='adj'> adj. </strong> (-ier, -iest) 1 full of worms. 2 wormeaten. worminess n.</p>"
            }
        ]
    },
    {
        "word": "Worn ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worn  <strong class='archaic_it_was'>past part. Of *</strong> wear. <strong class='adj'> —adj.</strong> 1 damaged by use or wear. 2 looking tired and exhausted.</p>"
            }
        ]
    },
    {
        "word": "Worrisome",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worrisome <strong class='adj'> adj. </strong> Causing worry.</p>"
            }
        ]
    },
    {
        "word": "Worry",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worry <strong class='noun'> —v. </strong> (-ies, -ied) 1 give way to anxiety. 2 harass, importune; be a trouble or anxiety to. 3 (of a dog etc.) Shake or pull about with the teeth. 4 (as worried adj.) Uneasy. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 thing that causes anxiety or disturbs tranquility. 2 disturbed state of mind; anxiety. worrier <strong class='noun'> n. </strong> [old english, = strangle]</p>"
            }
        ]
    },
    {
        "word": "Worry beads",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worry beads <strong class='n_pl'> n.pl. </strong> String of beads manipulated with the fingers to occupy or calm oneself.</p>"
            }
        ]
    },
    {
        "word": "Worse",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worse <strong class='adj'> —adj.</strong> 1 more bad. 2 (predic.) In or into worse health or a worse condition (is getting worse). <strong class='adv'> —adv. </strong> More badly; more ill. <strong class='noun'> —n. </strong> 1 worse thing or things (you might do worse than accept). 2 (prec. By the) worse condition (a change for the worse). none the worse (often foll. By for) not adversely affected (by). The worse for wear 1 damaged by use. 2 injured. Worse luck unfortunately. Worse off in a worse (esp. Financial) position. [old english]</p>"
            }
        ]
    },
    {
        "word": "Worsen",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worsen <strong class='verb'> v. </strong> Make or become worse.</p>"
            }
        ]
    },
    {
        "word": "Worship",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worship <strong class='noun'> —n. </strong> 1 a homage or service to a deity. B acts, rites, or ceremonies of this. 2 adoration, devotion. 3 (worship) (prec. By his, her, your) forms of description or address for a mayor, certain magistrates, etc. —v. (-pp-; us -p-) 1 adore as divine; honour with religious rites. 2 idolize or regard with adoration. 3 attend public worship. 4 be full of adoration. worshipper <strong class='noun'> n. </strong> [old english: related to *worth, *-ship]</p>"
            }
        ]
    },
    {
        "word": "Worshipful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worshipful <strong class='adj'> adj. </strong> (also worshipful) archaic (esp. In old titles of companies or officers) honourable, distinguished.</p>"
            }
        ]
    },
    {
        "word": "Worst",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worst <strong class='adj'> —adj.</strong> Most bad. <strong class='adv'> —adv. </strong> Most badly. <strong class='noun'> —n. </strong> Worst part or possibility (prepare for the worst). —v. Get the better of; defeat. at its etc. Worst in the worst state. At worst (or the worst) in the worst possible case. Do your worst expression of defiance. Get the worst of it be defeated. If the worst comes to the worst if the worst happens. [old english: related to *worse]</p>"
            }
        ]
    },
    {
        "word": "Worsted",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worsted <strong class='noun'> n. </strong> 1 fine woollen yarn. 2 fabric made from this. [worste(a)d in norfolk]</p>"
            }
        ]
    },
    {
        "word": "Wort",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wort <strong class='noun'> n. </strong> 1 archaic (except in names) plant (liverwort). 2 infusion of malt before it is fermented into beer. [old english]</p>"
            }
        ]
    },
    {
        "word": "Worth",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worth <strong class='predic'> —predic. </strong> <strong class='adj'> Adj. </strong> (used like a preposition) 1 of a value equivalent to (is worth £50; is worth very little). 2 such as to justify or repay (worth doing; not worth the trouble). 3 possessing or having property amounting to (is worth a million pounds). <strong class='noun'> —n. </strong> 1 what a person or thing is worth; the (usu. High) merit of (of great worth). 2 equivalent of money in a commodity (ten pounds' worth of petrol). for all one is worth <strong class='colloq'> colloq. </strong> With one's utmost efforts. For what it is worth without a guarantee of its truth or value. Worth it <strong class='colloq'> colloq. </strong> Worth while. Worth one's salt see *salt. Worth one's weight in gold see *weight. Worth while (or one's while) see *while. [old english]</p>"
            }
        ]
    },
    {
        "word": "Worthless",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worthless <strong class='adj'> adj. </strong> Without value or merit. worthlessness n.</p>"
            }
        ]
    },
    {
        "word": "Worthwhile",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worthwhile <strong class='adj'> adj. </strong> That is worth the time, effort, or money spent. <strong class='usage'> Usage:- </strong>see note at while.</p>"
            }
        ]
    },
    {
        "word": "Worthy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Worthy <strong class='adj'> —adj.</strong> (-ier, -iest) 1 deserving respect, estimable (lived a worthy life). 2 entitled to (esp. Condescending) recognition (a worthy old couple). 3 a (foll. By of or to + infin.) Deserving (worthy of a mention). B (foll. By of) adequate or suitable to the dignity etc. Of (words worthy of the occasion). <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 worthy person. 2 person of some distinction. worthily <strong class='adv'> adv. </strong> Worthiness n.</p>"
            }
        ]
    },
    {
        "word": "-worthy",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>-worthy <strong class='n_pl'> comb. </strong> Form forming adjectives meaning: 1 deserving of (noteworthy). 2 suitable for (roadworthy).</p>"
            }
        ]
    },
    {
        "word": "Would",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Would <strong class='v_aux'> v.aux. </strong> (3rd sing. Would)  <strong class='slang_v'>past of *</strong> will1, used esp.: 1 in reported speech (he said he would be home by evening). 2 to express a condition (they would have been killed if they had gone). 3 to express habitual action (would wait every evening). 4 to express a question or polite request (would they like it?; would you come in, please?). 5 to express probability (she would be over fifty by now). 6 to express consent (they would not help).</p>"
            }
        ]
    },
    {
        "word": "Would-be",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Would-be <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Desiring or aspiring to be.</p>"
            }
        ]
    },
    {
        "word": "Wouldn't",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wouldn't <strong class='contr'> contr. </strong> Would not.</p>"
            }
        ]
    },
    {
        "word": "Wound1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wound1 <strong class='noun'> —n. </strong> 1 injury done to living tissue by a deep cut or heavy blow etc. 2 pain inflicted on one's feelings; injury to one's reputation. —v. Inflict a wound on. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wound2  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wound2  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>wind2.</p>"
            }
        ]
    },
    {
        "word": "Wound up",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wound up <strong class='adj'> adj. </strong> Excited; tense; angry.</p>"
            }
        ]
    },
    {
        "word": "Wove  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wove  <strong class='slang_v'>past of *</strong> weave1.</p>"
            }
        ]
    },
    {
        "word": "Woven ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Woven  <strong class='archaic_it_was'>past part. Of *</strong> weave1.</p>"
            }
        ]
    },
    {
        "word": "Wow1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wow1 <strong class='pl'> —int. </strong> Expressing astonishment or admiration. <strong class='noun'> —n. </strong> Slang sensational success. —v. Slang impress greatly. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Wow2",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wow2 <strong class='noun'> n. </strong> Slow pitch-fluctuation in sound-reproduction, perceptible in long notes. [imitative]</p>"
            }
        ]
    },
    {
        "word": "Wp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wp <strong class='sing'> abbr. </strong> Word processor.</p>"
            }
        ]
    },
    {
        "word": "Wpc",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wpc <strong class='sing'> abbr. </strong> Woman police constable.</p>"
            }
        ]
    },
    {
        "word": "W.p.m.",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>W.p.m. <strong class='abbr'> Abbr. </strong> Words per minute.</p>"
            }
        ]
    },
    {
        "word": "Wrac",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrac <strong class='sing'> abbr. </strong> Women's royal army corps.</p>"
            }
        ]
    },
    {
        "word": "Wrack",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrack <strong class='noun'> n. </strong> 1 seaweed cast up or growing on the shore. 2 destruction. [low german or dutch wrak: cf. *wreck]</p>"
            }
        ]
    },
    {
        "word": "Wraf",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wraf <strong class='sing'> abbr. </strong> Women's royal air force.</p>"
            }
        ]
    },
    {
        "word": "Wraith",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wraith <strong class='noun'> n. </strong> 1 ghost. 2 spectral appearance of a living person supposed to portend that person's death. [origin unknown]</p>"
            }
        ]
    },
    {
        "word": "Wrangle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrangle <strong class='noun'> —n. </strong> Noisy argument or dispute. —v. (-ling) engage in a wrangle. [low german or dutch]</p>"
            }
        ]
    },
    {
        "word": "Wrap",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrap <strong class='noun'> —v. </strong> (-pp-) 1 (often foll. By up) envelop in folded or soft encircling material. 2 (foll. By round, about) arrange or draw (a pliant covering) round (a person). 3 (foll. By round) slang crash (a vehicle) into (a stationary object). <strong class='noun'> —n. </strong> 1 shawl, scarf, etc. 2 esp. Us wrapping material. take the wraps off disclose. Under wraps in secrecy. Wrapped up in engrossed or absorbed in. Wrap up 1 <strong class='colloq'> colloq. </strong> Finish off (a matter). 2 put on warm clothes (wrap up well). 3 (in imper.) Slang be quiet. [origin unknown] </p>"
            }
        ]
    },
    {
        "word": "Wraparound",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wraparound <strong class='adj'> adj. </strong> (also wrapround) 1 (esp. Of clothing) designed to wrap round. 2 curving or extending round at the edges.</p>"
            }
        ]
    },
    {
        "word": "Wrap-over",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrap-over <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> (of a garment) overlapping when worn. <strong class='noun'> —n. </strong> Such a garment.</p>"
            }
        ]
    },
    {
        "word": "Wrapper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrapper <strong class='noun'> n. </strong> 1 cover for a sweet, book, posted newspaper, etc. 2 loose enveloping robe or gown.</p>"
            }
        ]
    },
    {
        "word": "Wrapping",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrapping <strong class='noun'> n. </strong> (esp. In pl.) Material used to wrap; wraps, wrappers.</p>"
            }
        ]
    },
    {
        "word": "Wrapping paper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrapping paper <strong class='noun'> n. </strong> Strong or decorative paper for wrapping parcels.</p>"
            }
        ]
    },
    {
        "word": "Wrapround",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrapround <strong class='var'> var. </strong> Of *wraparound.</p>"
            }
        ]
    },
    {
        "word": "Wrasse",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrasse <strong class='noun'> n. </strong> Bright-coloured marine fish. [cornish wrach]</p>"
            }
        ]
    },
    {
        "word": "Wrath",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrath <strong class='noun'> n. </strong> Literary extreme anger. [old english: related to *wroth]</p>"
            }
        ]
    },
    {
        "word": "Wrathful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrathful <strong class='adj'> adj. </strong> Literary extremely angry. wrathfully adv.</p>"
            }
        ]
    },
    {
        "word": "Wreak",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wreak <strong class='verb'> v. </strong> 1 (usu. Foll. By upon) give play to (vengeance or one's anger etc.). 2 cause (damage etc.) (wreak havoc). [old english, = avenge]</p>"
            }
        ]
    },
    {
        "word": "Wreath",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wreath <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 flowers or leaves fastened in a ring, esp. As an ornament for the head or for laying on a grave etc. 2 curl or ring of smoke, cloud, or soft fabric. [old english: related to *writhe]</p>"
            }
        ]
    },
    {
        "word": "Wreathe",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wreathe <strong class='verb'> v. </strong> (-thing) 1 encircle or cover as, with, or like a wreath. 2 (foll. By round) wind (one's arms etc.) Round (a person etc.). 3 (of smoke etc.) Move in wreaths.</p>"
            }
        ]
    },
    {
        "word": "Wreck",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wreck <strong class='noun'> —n. </strong> 1 the sinking or running aground of a ship. 2 ship that has suffered a wreck. 3 greatly damaged building, thing, or person. 4 (foll. By of) wretched remnant. —v. 1 a seriously damage (a vehicle etc.). B ruin (hopes, a life, etc.). 2 cause the wreck of (a ship). [anglo-french wrec from germanic]</p>"
            }
        ]
    },
    {
        "word": "Wreckage",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wreckage <strong class='noun'> n. </strong> 1 wrecked material. 2 remnants of a wreck. 3 act of wrecking.</p>"
            }
        ]
    },
    {
        "word": "Wrecker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrecker <strong class='noun'> n. </strong> 1 person or thing that wrecks or destroys. 2 esp. Us person employed in demolition or breaking up damaged vehicles. 3 esp. Hist. Person on the shore who tries to bring about a shipwreck for plunder or profit.</p>"
            }
        ]
    },
    {
        "word": "Wren",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wren <strong class='noun'> n. </strong> Member of the women's royal naval service. [from the abbreviation wrns]</p>"
            }
        ]
    },
    {
        "word": "Wren",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wren <strong class='noun'> n. </strong> Small usu. Brown short-winged songbird with an erect tail. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wrench",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrench <strong class='noun'> —n. </strong> 1 violent twist or oblique pull or tearing off. 2 tool like a spanner for gripping and turning nuts etc. 3 painful uprooting or parting. —v. 1 twist or pull violently round or sideways. 2 (often foll. By off, away, etc.) Pull off with a wrench. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wrest",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrest <strong class='verb'> v. </strong> 1 wrench away from a person's grasp. 2 (foll. By from) obtain by effort or with difficulty. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wrestle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrestle <strong class='noun'> —n. </strong> 1 contest in which two opponents grapple and try to throw each other to the ground, esp. As an athletic sport. 2 hard struggle. —v. (-ling) 1 (often foll. By with) take part or fight in a wrestle. 2 a (foll. By with, against) struggle. B (foll. By with) do one's utmost to deal with (a task, difficulty, etc.). wrestler <strong class='noun'> n. </strong> Wrestling <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wretch",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wretch <strong class='noun'> n. </strong> 1 unfortunate or pitiable person. 2 (often as a playful term of depreciation) reprehensible person. [old english, = outcast]</p>"
            }
        ]
    },
    {
        "word": "Wretched",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wretched <strong class='adj'> adj. </strong> (wretcheder, wretchedest) 1 unhappy, miserable; unwell. 2 of bad quality; contemptible. 3 displeasing, hateful. wretchedly <strong class='adv'> adv. </strong> Wretchedness n.</p>"
            }
        ]
    },
    {
        "word": "Wrick",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrick <strong class='var'> var. </strong> Of *rick2.</p>"
            }
        ]
    },
    {
        "word": "Wriggle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wriggle <strong class='noun'> —v. </strong> (-ling) 1 (of a worm etc.) Twist or turn its body with short writhing movements. 2 make wriggling motions. 3 (foll. By along, through, etc.) Go thus (wriggled through the gap). 4 be evasive. <strong class='noun'> —n. </strong> Act of wriggling. wriggle out of <strong class='colloq'> colloq. </strong> Avoid on a pretext. wriggly adj. [low german wriggelen]</p>"
            }
        ]
    },
    {
        "word": "Wright",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wright <strong class='noun'> n. </strong> Maker or builder (usu. In <strong class='n_pl'> comb. </strong> : playwright; shipwright). [old english: related to *work]</p>"
            }
        ]
    },
    {
        "word": "Wring",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wring <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Wrung) 1 a squeeze tightly. B (often foll. By out) squeeze and twist, esp. To remove liquid. 2 break by twisting. 3 distress, torture. 4 extract by squeezing. 5 (foll. By out, from) obtain by pressure or importunity; extort. <strong class='noun'> —n. </strong> Act of wringing. wring one's hands clasp them as a gesture of distress. Wring the neck of kill (a chicken etc.) By twisting its neck. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wringer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wringer <strong class='noun'> n. </strong> Device for wringing water from washed clothes etc.</p>"
            }
        ]
    },
    {
        "word": "Wringing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wringing <strong class='adj'> adj. </strong> (in full wringing wet) so wet that water can be wrung out.</p>"
            }
        ]
    },
    {
        "word": "Wrinkle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrinkle <strong class='noun'> —n. </strong> 1 crease in the skin, esp. Caused by age. 2 similar mark in another flexible surface. 3 <strong class='colloq'> colloq. </strong> Useful tip or clever expedient. —v. (-ling) 1 make wrinkles in. 2 form wrinkles. [probably related to old english gewrinclod sinuous]</p>"
            }
        ]
    },
    {
        "word": "Wrinkly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrinkly <strong class='adj'> —adj.</strong> (-ier, -iest) having wrinkles. <strong class='noun'> —n. </strong> Slang offens. Old or middle-aged person.</p>"
            }
        ]
    },
    {
        "word": "Wrist",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrist <strong class='noun'> n. </strong> 1 joint connecting the hand with the arm. 2 part of a garment covering this. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wristlet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wristlet <strong class='noun'> n. </strong> Band or ring to strengthen, guard, or adorn the wrist.</p>"
            }
        ]
    },
    {
        "word": "Wrist-watch",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrist-watch <strong class='noun'> n. </strong> Small watch worn on a strap etc. Round the wrist.</p>"
            }
        ]
    },
    {
        "word": "Writ1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writ1 <strong class='noun'> n. </strong> Form of written command to act or not act in some way. [old english: related to *write]</p>"
            }
        ]
    },
    {
        "word": "Writ2  archaic",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writ2 archaic <strong class='archaic_it_was'>past part. Of *</strong> write. writ large in magnified or emphasized form.</p>"
            }
        ]
    },
    {
        "word": "C",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>C <strong class='verb'> v. </strong> (-ting; past wrote;  <strong class='archaic_it_was'>past part.</strong> Written) 1 mark paper or some other surface with symbols, letters, or words. 2 form or mark (such symbols etc.).</p>"
            }
        ]
    },
    {
        "word": "Wreckage",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wreckage <strong class='noun'> n. </strong> 1 wrecked material. 2 remnants of a wreck. 3 act of wrecking.</p>"
            }
        ]
    },
    {
        "word": "Wrecker",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrecker <strong class='noun'> n. </strong> 1 person or thing that wrecks or destroys. 2 esp. Us person employed in demolition or breaking up damaged vehicles. 3 esp. Hist. Person on the shore who tries to bring about a shipwreck for plunder or profit.</p>"
            }
        ]
    },
    {
        "word": "Wren",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wren <strong class='noun'> n. </strong> Member of the women's royal naval service. [from the abbreviation wrns]</p>"
            }
        ]
    },
    {
        "word": "Wren",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wren <strong class='noun'> n. </strong> Small usu. Brown short-winged songbird with an erect tail. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wrench",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrench <strong class='noun'> —n. </strong> 1 violent twist or oblique pull or tearing off. 2 tool like a spanner for gripping and turning nuts etc. 3 painful uprooting or parting. —v. 1 twist or pull violently round or sideways. 2 (often foll. By off, away, etc.) Pull off with a wrench. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wrest",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrest <strong class='verb'> v. </strong> 1 wrench away from a person's grasp. 2 (foll. By from) obtain by effort or with difficulty. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wrestle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrestle <strong class='noun'> —n. </strong> 1 contest in which two opponents grapple and try to throw each other to the ground, esp. As an athletic sport. 2 hard struggle. —v. (-ling) 1 (often foll. By with) take part or fight in a wrestle. 2 a (foll. By with, against) struggle. B (foll. By with) do one's utmost to deal with (a task, difficulty, etc.). wrestler <strong class='noun'> n. </strong> Wrestling <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wretch",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wretch <strong class='noun'> n. </strong> 1 unfortunate or pitiable person. 2 (often as a playful term of depreciation) reprehensible person. [old english, = outcast]</p>"
            }
        ]
    },
    {
        "word": "Wretched",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wretched <strong class='adj'> adj. </strong> (wretcheder, wretchedest) 1 unhappy, miserable; unwell. 2 of bad quality; contemptible. 3 displeasing, hateful. wretchedly <strong class='adv'> adv. </strong> Wretchedness n.</p>"
            }
        ]
    },
    {
        "word": "Wrick",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrick <strong class='var'> var. </strong> Of *rick2.</p>"
            }
        ]
    },
    {
        "word": "Wriggle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wriggle <strong class='noun'> —v. </strong> (-ling) 1 (of a worm etc.) Twist or turn its body with short writhing movements. 2 make wriggling motions. 3 (foll. By along, through, etc.) Go thus (wriggled through the gap). 4 be evasive. <strong class='noun'> —n. </strong> Act of wriggling. wriggle out of <strong class='colloq'> colloq. </strong> Avoid on a pretext. wriggly adj. [low german wriggelen]</p>"
            }
        ]
    },
    {
        "word": "Wright",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wright <strong class='noun'> n. </strong> Maker or builder (usu. In <strong class='n_pl'> comb. </strong> : playwright; shipwright). [old english: related to *work]</p>"
            }
        ]
    },
    {
        "word": "Wring",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wring <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Wrung) 1 a squeeze tightly. B (often foll. By out) squeeze and twist, esp. To remove liquid. 2 break by twisting. 3 distress, torture. 4 extract by squeezing. 5 (foll. By out, from) obtain by pressure or importunity; extort. <strong class='noun'> —n. </strong> Act of wringing. wring one's hands clasp them as a gesture of distress. Wring the neck of kill (a chicken etc.) By twisting its neck. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wringer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wringer <strong class='noun'> n. </strong> Device for wringing water from washed clothes etc.</p>"
            }
        ]
    },
    {
        "word": "Wringing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wringing <strong class='adj'> adj. </strong> (in full wringing wet) so wet that water can be wrung out.</p>"
            }
        ]
    },
    {
        "word": "Wrinkle",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrinkle <strong class='noun'> —n. </strong> 1 crease in the skin, esp. Caused by age. 2 similar mark in another flexible surface. 3 <strong class='colloq'> colloq. </strong> Useful tip or clever expedient. —v. (-ling) 1 make wrinkles in. 2 form wrinkles. [probably related to old english gewrinclod sinuous]</p>"
            }
        ]
    },
    {
        "word": "Wrinkly",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrinkly <strong class='adj'> —adj.</strong> (-ier, -iest) having wrinkles. <strong class='noun'> —n. </strong> Slang offens. Old or middle-aged person.</p>"
            }
        ]
    },
    {
        "word": "Wrist",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrist <strong class='noun'> n. </strong> 1 joint connecting the hand with the arm. 2 part of a garment covering this. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wristlet",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wristlet <strong class='noun'> n. </strong> Band or ring to strengthen, guard, or adorn the wrist.</p>"
            }
        ]
    },
    {
        "word": "Wrist-watch",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrist-watch <strong class='noun'> n. </strong> Small watch worn on a strap etc. Round the wrist.</p>"
            }
        ]
    },
    {
        "word": "Writ1",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writ1 <strong class='noun'> n. </strong> Form of written command to act or not act in some way. [old english: related to *write]</p>"
            }
        ]
    },
    {
        "word": "Writ2  archaic",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writ2 archaic <strong class='archaic_it_was'>past part. Of *</strong> write. writ large in magnified or emphasized form.</p>"
            }
        ]
    },
    {
        "word": "Write",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Write <strong class='verb'> v. </strong> (-ting; past wrote;  <strong class='archaic_it_was'>past part.</strong> Written) 1 mark paper or some other surface with symbols, letters, or words. 2 form or mark (such symbols etc.). 3 form or mark the symbols of (a word or sentence, or document etc.). 4 fill or complete (a sheet, cheque, etc.) With writing. 5 transfer (data) into a computer store. 6 (esp.</p>"
            }
        ]
    },
    {
        "word": "Write-off",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Write-off <strong class='noun'> n. </strong> Thing written off, esp. A vehicle too badly damaged to be repaired.</p>"
            }
        ]
    },
    {
        "word": "Writer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writer <strong class='noun'> n. </strong> 1 person who writes or has written something. 2 person who writes books, author.</p>"
            }
        ]
    },
    {
        "word": "Writer's cramp",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writer's cramp <strong class='noun'> n. </strong> Muscular spasm due to excessive writing.</p>"
            }
        ]
    },
    {
        "word": "Write-up",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Write-up <strong class='noun'> n. </strong> Written or published account, review.</p>"
            }
        ]
    },
    {
        "word": "Writhe",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writhe <strong class='verb'> v. </strong> (-thing) 1 twist or roll oneself about in or as in acute pain. 2 suffer mental torture or embarrassment (writhed with shame). [old english]</p>"
            }
        ]
    },
    {
        "word": "Writing",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writing <strong class='noun'> n. </strong> 1 written words etc. 2 handwriting. 3 (usu. In pl.) Author's works. in writing in written form. The writing on the wall ominously significant event etc.</p>"
            }
        ]
    },
    {
        "word": "Writing-desk",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writing-desk <strong class='noun'> n. </strong> Desk for writing at, esp. With compartments for papers etc.</p>"
            }
        ]
    },
    {
        "word": "Writing-paper",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Writing-paper <strong class='noun'> n. </strong> Paper for writing (esp. Letters) on.</p>"
            }
        ]
    },
    {
        "word": "Written ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Written  <strong class='archaic_it_was'>past part. Of *</strong> write.</p>"
            }
        ]
    },
    {
        "word": "Wrns",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrns <strong class='sing'> abbr. </strong> Women's royal naval service.</p>"
            }
        ]
    },
    {
        "word": "Wrong",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrong <strong class='adj'> —adj.</strong> 1 mistaken; not true; in error. 2 unsuitable; less or least desirable (the wrong road; a wrong decision). 3 contrary to law or morality (it is wrong to steal). 4 amiss; out of order, in a bad or abnormal condition (something wrong with my heart; has gone wrong). <strong class='adv'> —adv. </strong> (usually placed last) in a wrong manner or direction; with an incorrect result (guessed wrong). <strong class='noun'> —n. </strong> 1 what is morally wrong. 2 unjust action (suffer a wrong). —v. 1 treat unjustly. 2 mistakenly attribute bad motives to. do wrong sin. Do wrong to malign or mistreat (a person). Get wrong 1 misunderstand (a person etc.). 2 obtain an incorrect answer to. Get (or get hold of) the wrong end of the stick misunderstand completely. Go wrong 1 take the wrong path. 2 stop functioning properly. 3 depart from virtuous behaviour. In the wrong responsible for a quarrel, mistake, or offence. On the wrong side of 1 out of favour with (a person). 2 somewhat more than (a stated age). Wrong side out inside out. Wrong way round in the opposite or reverse of the normal or desirable orientation or sequence etc. wrongly <strong class='adv'> adv. </strong> Wrongness <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wrongdoer",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrongdoer <strong class='noun'> n. </strong> Person who behaves immorally or illegally. wrongdoing n.</p>"
            }
        ]
    },
    {
        "word": "Wrong-foot",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrong-foot <strong class='verb'> v. </strong> Colloq. 1 (in tennis, football, etc.) Catch (an opponent) off balance. 2 disconcert; catch unprepared.</p>"
            }
        ]
    },
    {
        "word": "Wrongful",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrongful <strong class='adj'> adj. </strong> Unwarranted, unjustified (wrongful arrest). wrongfully adv.</p>"
            }
        ]
    },
    {
        "word": "Wrong-headed",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrong-headed <strong class='adj'> adj. </strong> Perverse and obstinate.</p>"
            }
        ]
    },
    {
        "word": "Wrong side",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrong side <strong class='noun'> n. </strong> Worse or undesired or unusable side of esp. Fabric.</p>"
            }
        ]
    },
    {
        "word": "Wrote  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrote  <strong class='slang_v'>past of *</strong> write.</p>"
            }
        ]
    },
    {
        "word": "Wroth",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wroth <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> Archaic angry. [old english]</p>"
            }
        ]
    },
    {
        "word": "Wrought  archaic ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrought archaic  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>work. <strong class='adj'> —adj.</strong> (of metals) beaten out or shaped by hammering.</p>"
            }
        ]
    },
    {
        "word": "Wrought iron",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrought iron <strong class='noun'> n. </strong> Tough malleable form of iron suitable for forging or rolling, not cast.</p>"
            }
        ]
    },
    {
        "word": "Wrung  ",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrung  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>wring.</p>"
            }
        ]
    },
    {
        "word": "Wrvs",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wrvs <strong class='sing'> abbr. </strong> Women's royal voluntary service.</p>"
            }
        ]
    },
    {
        "word": "Wry",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wry <strong class='adj'> adj. </strong> (wryer, wryest or wrier, wriest) 1 distorted or turned to one side. 2 (of a face, smile, etc.) Contorted in disgust, disappointment, or mockery. 3 (of humour) dry and mocking. wryly <strong class='adv'> adv. </strong> Wryness <strong class='noun'> n. </strong> [old english]</p>"
            }
        ]
    },
    {
        "word": "Wryneck",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wryneck <strong class='noun'> n. </strong> Small woodpecker able to turn its head over its shoulder.</p>"
            }
        ]
    },
    {
        "word": "Wsw",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wsw <strong class='sing'> abbr. </strong> West-south-west.</p>"
            }
        ]
    },
    {
        "word": "Wt",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wt <strong class='sing'> abbr. </strong> Weight.</p>"
            }
        ]
    },
    {
        "word": "Wych-",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wych- <strong class='n_pl'> comb. </strong> Form in names of trees with pliant branches (wych-alder; wych-elm). [old english, = bending]</p>"
            }
        ]
    },
    {
        "word": "Wych-hazel",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wych-hazel <strong class='var'> var. </strong> Of *witch-hazel.</p>"
            }
        ]
    },
    {
        "word": "Wykehamist",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wykehamist <strong class='adj'> —adj.</strong> Of winchester college. <strong class='noun'> —n. </strong> Past or present member of winchester college. [william of wykeham, name of the founder]</p>"
            }
        ]
    },
    {
        "word": "Wysiwyg",
        "language": [
            {
                "language": "en",
                "title": "Engish",
                "meaning": "<p>Wysiwyg <strong class='adj'> adj. </strong> (also wysiwyg) computing denoting a form of text onscreen exactly corresponding to its printout. [acronym of what you see is what you get]</p>"
            }
        ]
    }
];

export const alphabet_W_Hindi = [
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यू</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यू 1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यू 2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यू 3</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यू</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निराला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गुच्छा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अस्तर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घिसना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उतारा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बगुला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाडी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वफ़र</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेफर पतली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Waffle1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Waffle2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वैफ़ल आयरन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गूंज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wag1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wag2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेतन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>छेड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मज़दूर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दांव</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लम्बी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दोलन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अदृढ़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गाड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ट्राम-द्राइवर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वैगन लोड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेग्टेल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>परित्यक्त</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Waifs और स्ट्रैस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विलाप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सप्तऋषि</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेनकोट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वान्सकोटिंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमरबंद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वास्कट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमर तक गहरा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रुकना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>परिचारक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इंतज़ार कर खेल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रतीक्षा सूची</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रतीक्षालय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेट्रेस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>माफ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>त्याग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wake1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>WAKE2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जागृत होनेवाला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जागना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>wale</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पैदल चलना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉकआउट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉकर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉकी टॉकी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिना नियोजित भेंट के चला आने वाला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>टहलना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घूमने वाला चौखटा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>छड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉकमेन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जिंदगी की सैर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चले चलो</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बाहर जाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाल्क-ओवर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रास्ता</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दीवार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आस्ट्रेलियन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वालाह</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉल -बार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बटुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दीवार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>wallflower</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉल -गेम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वलून</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रहार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लोट लगाते</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉलपेपर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लगातार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वैली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अखरोट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉलरस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉलरस मूंछें</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाल्ट्ज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाम्पम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़र्द</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>छड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घूमना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भटकते हुए यहूदी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफ़र का अनुराग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पतन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वंगल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घिसा -पिटा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वंकेल इंजन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वानर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डेप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चाहते हैं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चाहते हैं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रचंड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वैपिती</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गीत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गाने वाला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध अपराध</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध घोष</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बालक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-बालक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध नृत्य</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रबंधक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वार्डर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कपड़े की अलमारी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अलमारी मालकिन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाडररूम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-वर्ड्स</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वार्डशिप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेयर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गोदाम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध खेल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वारहेड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध अश्व</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जंगी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>करामाती</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सिपहसालार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गरम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जोशीला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध स्मारक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वार्म दिल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंगीठी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जंगबाज़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जोश में आना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गर्म काम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चेतावनी देना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चेतावनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वार ऑफ नर्वस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ताना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध पेंट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>टकराव</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वारंट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वारंट अधिकारी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गारंटी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ख़रगोश पालने का बाड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जीवन-मरण का</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>योद्धा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जंगी जहाज़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मस्सा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मसाला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युद्ध-काल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सावधान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>था</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धोना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धोने-बेसिन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धोने का तख़्ता</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमजोर करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धोया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉशर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाशर-अप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धोबिन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धुलाई</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉशिंग मशीन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कपड़े धोने का पाउडर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धुलाई का सोडा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धौना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धोने से बाहर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>टट्टी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुंह धोने की मेज़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़र्द</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नहीं था</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हड्डा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हड्डा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ताना मारता हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तत्कालीन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वासेल आर्कटिक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्षय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बरबाद करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अपशिष्ट निपटान एकक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अपव्ययी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बंजर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बेकार कागज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रद्दी कागज की टोकरी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नाली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यर्थ पदार्थ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नुक़सान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ख़राबी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निगरानी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चौकन्ना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संक्षिप्त देखना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घड़ीसाज़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चौकीदार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉच-नाइट सेवा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घड़ी टॉवर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नारा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी वाहक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी बिस्तर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल-बिस्किट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल भैंस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी की बस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी का गोला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल वाहक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सिंघाड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल घड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शौचघर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल रंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी के रंग का रंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी ठंडा हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल -सा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल-वचन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झरना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी की पक्षियां</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी का गिलास</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी के पाइप के अंदर आवाज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी छेद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी बर्फ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सींचने का कनस्तर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऐसा स्थान जहां पशुओं के पीने का पानी जमा होता है</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पशुओं को पानी पिलाने की जगाह</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी की कूद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी की सतह</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाटर लिली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी-लाइन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सैलाब किया हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाटरलू</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉटर मेन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नाविक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाटर-मार्क</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी का मैदान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तरबूज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल-कवच</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी की पिस्तौल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाटर पोलो</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल शक्ति</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जलरोधक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी का चूहा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी दर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जलविभाजन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉटर स्की</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल को निर्मल बनाने वाला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जलस्तंभ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी की मेज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निर्विवाद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल स्तंभ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी में रहने वाले मूस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जलमार्ग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल पहिया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रक्षा जैकेट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पानी के नल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आंसुओं से भरा हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाट्सहेज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाट घंटे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wattle1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wattle2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहुत गंदा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हिलाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेवबैंड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेव फार्म</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेवलेंथ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>छोटा लहर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तरंग मशीन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डगमगाने</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लहरदार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वैक्स 1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वैक्स 2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुलायम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वैक्सविंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मोम का काम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मार्ग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वापसी का रास्ता</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यात्री की सूची</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पथिक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ताक में रहना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जीवन शैली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>असामान्य</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-तरीके</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अर्थोपाय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सड़क के किनारे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्वच्छंद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पश्चिम बंगाल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्वागत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यू/सीडीआर।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>में wea</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमज़ोर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमजोर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अस्थिर मन का</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमज़ोर दिल का आदमी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दुर्बलता से</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मंदबुद्धि</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमजोर क्षण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमज़ोरी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमजोर बिंदु</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Weal1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Weal2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपत्ति</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धनवान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दूध छुड़ाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हथियार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हथियार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घिसाव</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>थकाऊ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>थका</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चालक आदमी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेसेल शब्द</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मौसम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फटा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मौसम का</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वियरेकॉक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मौसम पूर्वानुमान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंतरिक्षविज्ञानशास्री</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>weatherproof</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वात दिग्दर्शक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Weave1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Weave2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जुलाहा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेब</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बद्धी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेबर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झिल्लीदार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुध।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुध</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुध</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शादी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शादी का नाश्ता</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शादी का केक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शादी की अंगूठी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कील</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेजवूड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विवाह</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुधवार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Weds।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wee1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wee2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खर-पतवार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निराना हत्यारा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मातम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पतला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सप्ताह</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम करने के दिन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सप्ताहांत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वीकेंडर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साप्ताहिक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रोना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रोना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रोने वाले</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घुन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मूत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कपड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तौलना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तुलाचौकी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तौलना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वज़न</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भारहीन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भारोत्तोलन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वजन प्रशिक्षण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रभावपूर्ण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बांध</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अजीब</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निराला आदमी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्च</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्वागत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्ड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कल्याण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लोक हितकारी राज्य</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कल्याण कार्य</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आसमान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल 1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल 2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कुंआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी समायोजित</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>होशियार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से नियुक्त</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से भाग लिया है</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी संतुलित</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शिष्ट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हाल चाल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नामी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तमीज़दार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मज़बूत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से जुड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपकारक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से तैयार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लायक़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जानकार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह पाला-पोसा हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी सिर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दौलतमंद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेलिस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जानकार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेलिंग्टन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नेकनीयत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से न्याय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से रखा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रसिद्ध</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से बनाया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शिष्ट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नेकनीयत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेलनीग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह तेल दिया हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह अदा किया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से संरक्षित</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहुत पढ़ा हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ठीक ढंग से प्राप्त</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्ण-विकसित</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से बात</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सुगंधित</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ख़ुशहाल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आज़मूदा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अच्छी तरह से दलित</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शुभचिंतक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जर्जर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्श</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्श</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्शमैन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्श रैबिट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्शवूमन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पित्ती उछलना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्टर 1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्टर 2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेल्टरवेट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लड़की</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बीतना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेंडी हाउस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चला गया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रोया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>थे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>थे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नहीं थे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेयरवोल्फ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wesleyan</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पश्चिम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेस्टबाउंड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पश्चिम देश</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेस्ट एंड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेस्टिंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पच्छमी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेस्टर्न</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मग़रिबवासी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पश्चिमी बनाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पश्चिम भारतीय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पश्चिम-उत्तर-पश्चिम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पश्चिम की ओर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पश्चिमी-साउथ-वेस्ट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पश्चिम की ओर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भीगा हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गीला कम्बल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गीला सपना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मौसम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेटलैंड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नर्स</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गीला सूट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हमने</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यूजी। सीडीआर।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अजीब</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झालर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हेल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हेलबोन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हेल का तेल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हेलर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वैम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घाट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घाट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्या - intergrog।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जो कुछ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हाट्सएप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जो भी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गेहूँ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गेहूं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गेहूं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गेहूं के बीज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गेहूं भोजन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मान जाओ ना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चक्र</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ठेला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हीलबेस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हीलचेयर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हील क्लैंप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-wheeler</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हीलर डीलर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पहिया-घर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हीली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पहिया स्पिन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हीलराइट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हीज़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विल्क</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मटमैला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पिल्ला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जब - Intergrog।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>औपचारिक औपचारिक - Intergrog।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जब भी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जो भी हो</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कहाँ - intergrog।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Whereabouts --introg।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जबकि</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जिसके तहत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इस कारण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जिसमें</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जिसका</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जिस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जहां कहीं भी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>किस उपाय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फेरना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मट्ठा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>या</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेश्या</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाह</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मट्ठा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जो - इंट्रोग्रॉग।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जो भी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एहसास</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हिग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जबकि</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जबकि</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रिरियाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सनकी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हिम्सी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कड़ा करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कराहना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फुसफुसाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हिनाहिनाहट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कोड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हिपकॉर्ड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कोड़ा हाथ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मोच</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गर्दन की चोट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झपटने वाला में</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हिपर्सनापर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साल की उम्र</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बच्चे को कोड़ा लगाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चाबुक से टपका हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Whippoorwill</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कोड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कोड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चक्कर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चक्कर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हर्लपूल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बवंडर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धीरे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गलमुच्छा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हिस्की</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हिस्की</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फुसफुसाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ह्विस्ट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हिस ड्राइव</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सीटी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सीटी-स्टॉप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद चींटी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हाइटबैट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद कोशिका</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद कॉलर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद कॉरपस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद हाथी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद पंख</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद झंडा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद तपस्वी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद वस्तुओं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हाइटहेड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद गर्मी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद आशा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद घोड़े</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ह्वाइट लेड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद झूठ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफ़ेद रोशनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद जादू</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद मांस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निखारने में सहायक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>श्वेत रव</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद बाहर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद कागज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद काली मिर्च</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद रूसी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद सॉस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद दास</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद भावना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद चीनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद टाई</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धुलाई</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद रोशनी वाली शादी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद व्हेल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफेद लकड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जहां या तो पुरातन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Whiting1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Whiting2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिसहरी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाइट्सुन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>किस रविवार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाइट्सुंटाइड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हिटल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Whiz (whiz भी)</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>छोटी सी उम्र में कामयाबी प्राप्त करने वाला बच्चा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कौन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कौन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रुको</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कौन होगा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोड्यूनिट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कोई भी हो</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूरे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूरा भोजन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साबुत अनाज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हार्दिक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्ण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूरा नंबर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>थोक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पौष्टिक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चोकरयुक्त गेहूं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपूर्णता</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्ण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>किसको</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जिसे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जिस किसी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ललकार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>whoopee</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काली खांसी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ओह</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कांपना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हूपर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भारी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेश्या</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेश्या</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोर्ल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्हॉर्टलबेरी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जिसका -रिनरोग।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जो कोई भी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कौन कौन है</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्यों</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाई के</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विकलांग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शैतान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विकर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विकरवर्क</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गेंद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विकेट कीपर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Widdershins</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चौड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूरी तरह सचेत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चौड़ी गेंद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाइड आंखों</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यापक रूप से</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रसार किया गया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बड़े पैमाने पर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विजियन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विधवा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विदुर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विधवा का शिखर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चौड़ाई</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फिराना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विनर स्निजल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बीवी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विजॉन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विगिंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लचीलापन देता है</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लताड़ाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विगवाम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विल्को</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जंगली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाइल्ड कार्ड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wildcat</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हिरण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जंगल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जंगल की आग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाइल्डफॉल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>असंभव की खोज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जंगली हाइसिंथ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वन्यजीव</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जंगली पश्चिम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>छलबल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खुदराय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Will1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Will2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खुदराय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विल्स</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इच्छुक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विल-ओ'-द-वारप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विलो</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बेंत की तरह पतली लचकदार डाली वाला पेड़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विलो पैटर्न</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विलो-वर्कलर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमजोर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संकलप शक्ति</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चंट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिना सोचे समझे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विल्ट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चतुर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डरपोक समझा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वामपल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जीत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झिझक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Winceyette</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चरखी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wind1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>WIND2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडबैग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पवन-ब्रेक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडबर्न</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पवनचक्की</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हवा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम समाप्त करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाइन्डर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अप्रत्याशित</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडिंग शीट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हवा उपकरण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पवन-जामर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पवन -रंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडमिल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खिड़की</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडो बॉक्स</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडो ड्रेसिंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खिड़की फलक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडो सीट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फुटकर दुकान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खिड़की-चौखट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सांस की नली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडस्क्रीन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडस्क्रीन वाइपर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडशील्ड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हवा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडसर्फिंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हवा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हवा सुरंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ठप्प होना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंडवार्ड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तूफ़ानी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शराब</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शराब - घर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाइनबिबर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शराब के तहखाने</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शराब का गिलास</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शराब की सूची</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कुण्ड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाइन सिरका</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शराब बैरा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पंख</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पंखों की कुर्सी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंग-कॉलर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंग कमांडर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंगर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ढिबरी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विंग अवधि</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आँख मारना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झपकी लेना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झपकी लेने वाला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विजेता</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जीत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विजयी खंबा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फटकना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विनो</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मनोहर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सर्दी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सर्दियों का उद्यान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गन्धपूरा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सर्दियों का चमड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शीतकालीन अयनांत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शीतकालीन खेल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सर्दियों</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सर्दी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पोंछना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाइपर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तार बालों वाली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तार रहित</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तार के जाल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तार दोहन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वायर ऊन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वायरवॉर्म</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तारों</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कडा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुद्धि</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अक़ल ढ़ाड़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wise1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wise2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-ढंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुद्धिमानी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुद्धिमान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुद्धिमान आदमी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज्ञानी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विसेंट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तमन्ना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विशबोन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इच्छुक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कामचोर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वास्तविकता पर नहीं आशाओं के आधार पर कामना करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शुुकामनाएं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निरर्थक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पीला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्टेरिया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उदास</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुद्धि</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डायन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जादू टोने</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डायन चिकित्सक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Witchery</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चुड़ैलों का सब्त</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विच हैज़ल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संदिग्ध व्यक्तियों की खोज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साथ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वापस लेना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निकासी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुरझाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सूख</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विदर्स</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विदरशिन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रोक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंदर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सामना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>के साथ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विचित्र</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गवाह</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कटघरा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वीटर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विवेकीवाद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उग्रता से</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हाज़िर जवाब</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पत्नियों</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जादूगर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वज़नी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यूएनडब्ल्यू</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वो</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धरना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोबल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दिखता जारी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चकमा देना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हाय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोएबेगोन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खस्ताहाल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झगड़ालू करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कडाई</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उठा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जगाया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोल्ड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भेड़िया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोल्फहाउंड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भेड़ के कपड़ो में भेड़िया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wolfram</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोल्फ्स्बेन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भेड़िया सीटी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wolverine</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भेड़िया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भेड़िया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महिला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नारीत्व</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्रैण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्त्रैण करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वंशावली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्रैण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कोख</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोमब्रेट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>औरत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महिलाओं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महिलाओं की पनीर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महिलाओं का लिबर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महिला मुक्ति</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महिलाओं के अधिकार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जीत गया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आश्चर्य</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अद्भुत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वंडरलैंड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हैरत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चमत्कारिक कवि।</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जीर्ण -शीर्ण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नहीं होगा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नहीं होगा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अभिनय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वू</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लकड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुड एनीमोन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडबाइन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडचुक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडकॉक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडक्राफ्ट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडकट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लकड़हारा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जंगली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लकड़ी का</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडलैंड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडलूज़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडमैन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कठफोड़वा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लकड़ी का कबूतर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडपाइल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लकड़ी लुगदी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Woodruff</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लकड़े के सायबान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडविंड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लकड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडवॉर्म</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडयार्ड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वुडी नाइटशेड</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Woof1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Woof2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वूफर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊनी (ब्रिट। ऊनी)</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊन संग्रहण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊनी (यूएस ऊनी)</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊन की थैलियों</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वूज़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वोप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वॉर्सेस्टर चटनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शब्द</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शब्द-काल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शब्द का खेल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शब्दों</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुंह की बात</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शब्द-बिल्कुल सही</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शब्दों</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शब्द संसाधक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अधिक शब्दों वाले</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पहनी थी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यावहारिक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वर्कडे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम में डूबे रहने</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम-बास्केट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यक्षेत्र</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वर्कबुक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वर्कबॉक्स</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम का शिविर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यदिवस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मज़दूर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्य अनुभव</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कर्मचारियों की संख्या</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यशाला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यरत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यशील पूंजी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>श्रमिक वर्ग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्य दिवस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्य परिकल्पना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्य ज्ञान</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्य शुरू करने से पहले का भोजन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम के क्रम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यरत पार्टी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यभार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वर्कमैन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कारीगर जैसे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कारीगरी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सहकर्मी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कला का काम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कसरत करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>workpiece</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यस्थल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम करने वाला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यपत्रक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यशाला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम-शर्मीली</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्य केंद्र</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्य अध्ययन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्य तालिका</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>worktop</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्य के नियम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दुनिया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विश्व बीटर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विश्वस्तरीय</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विश्व कप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विश्व प्रसिद्ध</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सांसारिक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सांसारिक वार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विश्व संगीत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विश्व युध्द</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विश्व थके हुए</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दुनिया भर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कीड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कीड़ा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वर्मेटन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कीड़ा छेद</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वर्म की आंखों का दृश्य</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नागदौन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कीड़ों से भरा हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पहना हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चिंताजनक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चिंता</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चिंता मोतियों की चिंता</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़्यादा बुरा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खराब</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूजा करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपासनात्मक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहुत बुरा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सबसे खराब</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पौधा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कीमत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बेकार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सार्थक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>योग्य</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-योग्य</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चाहेंगे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>होगा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नहीं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घाव 1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घाव 2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खत्म करना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुनी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wow1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wow2</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यूपी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यूपीसी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यू.पी.एम.</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्रत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कूचा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्रफ़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्रेथ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लड़ाई</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लपेटना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चारों ओर लपेट दो</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लपेटें ओवर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आवरण</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रैपिंग</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लपेटने वाला कागज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लपेटना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लपेटना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्रोध</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रकोप</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बरपा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>माला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>माला</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जहाज के मलबे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मलबे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विध्वंसक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रेन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रेन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हथिया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कुश्ती</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नीच</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मनहूस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झपकी लेना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लोटना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>राइट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मरोड़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रिंगर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मरोड़ने</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शिकन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>wrinkly</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कलाई</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कंगन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कलाई घड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Writ1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>WRIT2 ARCHAIC</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मलबे</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विध्वंसक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रेन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रेन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पाना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हथिया</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कुश्ती</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नीच</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मनहूस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झपकी लेना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लोटना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>राइट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मरोड़</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रिंगर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मरोड़ने</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शिकन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>wrinkly</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कलाई</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कंगन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कलाई घड़ी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Writ1</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>WRIT2 ARCHAIC</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिखना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बट्टे खाते डालना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लेखक</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लेखक का ऐंठन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिखें</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उमेठना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिख रहे हैं</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिखने की मेज</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिखने का पेपर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिखा हुआ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>WRNS</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गलत</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गुनहगार</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गलत कदम</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गलत तरीके से</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गलत अध्यक्षता</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गलत पक्ष</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिखा था</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्रोधित होकर</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गढ़ा हुआ पुरातन</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लोहा</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गला घोंटना</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यूआरवीएस</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>राइ</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wryneck</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यूएसडब्ल्यू</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डब्ल्यूटी</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wych-</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विच-हेज़ेल</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वायकेमिस्ट</p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Wysiwyg</p>",
    },
];