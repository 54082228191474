<template>
  <div class="container-fluid p-0">
    <Header />
    <div class="px-3">
      <googletransalte />
    </div>
  </div>
</template>

<script>
import Googletransalte from "./components/Googletransalte/Googletransalte.vue";
import Header from "./components/Header/header.vue";

export default {
  name: "App",
  components: {
    Googletransalte,
    Header,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
[alt="www.000webhost.com"] {
    display: none;
}
</style>
