export const alphabet_E = [
    {
        word: "E",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>E </p>",
            },
        ],
    },
    {
        word: "E1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>E1 <strong class='noun'> n. </strong> (also e) (pl. Es or e's) 1 fifth letter of the alphabet. 2 <strong class='mus'> mus. </strong> Third note of the diatonic scale of c major.</p>",
            },
        ],
    },
    {
        word: "E2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>E2 <strong class='sing'> abbr. </strong> (also e.) 1 east, eastern. 2 see *e-number.</p>",
            },
        ],
    },
    {
        word: "E-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>E- <strong class='prefix '> prefix </strong> see *ex-1 before some consonants.</p>",
            },
        ],
    },
    {
        word: "Each",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Each <strong class='adj'> —adj.</strong> Every one of two or more persons or things, regarded separately (five in each class). <strong class='pron'> — <strong class='pron'> pron. </strong> </strong> Each person or thing (each of us). [old english]</p>",
            },
        ],
    },
    {
        word: "Each other",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Each other <strong class='pron'> pron. </strong> One another.</p>",
            },
        ],
    },
    {
        word: "Each way",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Each way <strong class='adj'> adj. </strong> (of a bet) backing a horse etc. To win or to come second or third.</p>",
            },
        ],
    },
    {
        word: "Eager",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eager <strong class='adj'> adj. </strong> Keen, enthusiastic (eager to learn; eager for news). eagerly <strong class='adv'> adv. </strong> Eagerness <strong class='noun'> n. </strong> [latin acer keen]</p>",
            },
        ],
    },
    {
        word: "Eager beaver",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eager beaver <strong class='noun'> n. </strong> Colloq. Very diligent person.</p>",
            },
        ],
    },
    {
        word: "Eagle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eagle <strong class='noun'> n. </strong> 1 a large bird of prey with keen vision and powerful flight. B this as a symbol, esp. Of the us. 2 score of two strokes under par at any hole in golf. [latin aquila]</p>",
            },
        ],
    },
    {
        word: "Eagle eye",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eagle eye <strong class='noun'> n. </strong> Keen sight, watchfulness. eagle-eyed adj.</p>",
            },
        ],
    },
    {
        word: "Eaglet",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eaglet <strong class='noun'> n. </strong> Young eagle.</p>",
            },
        ],
    },
    {
        word: "E. & o. E.",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>E. & o. E. <strong class='abbr'> Abbr. </strong> Errors and omissions excepted.</p>",
            },
        ],
    },
    {
        word: "Ear1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ear1 <strong class='noun'> n. </strong> 1 organ of hearing, esp. Its external part. 2 faculty for discriminating sounds (an ear for music). 3 attention, esp. Sympathetic (give ear to; have a person's ear). all ears listening attentively. Have (or keep) an ear to the ground be alert to rumours or trends. Up to one's ears (often foll. By in) <strong class='colloq'> colloq. </strong> Deeply involved or occupied. [old english]</p>",
            },
        ],
    },
    {
        word: "Ear2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ear2 <strong class='noun'> n. </strong> Seed-bearing head of a cereal plant. [old english]</p>",
            },
        ],
    },
    {
        word: "Earache",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earache <strong class='noun'> n. </strong> Pain in the inner ear.</p>",
            },
        ],
    },
    {
        word: "Eardrum",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eardrum <strong class='noun'> n. </strong> Membrane of the middle ear.</p>",
            },
        ],
    },
    {
        word: "Earful",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earful <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='colloq'> colloq. </strong> 1 prolonged amount of talking. 2 strong reprimand.</p>",
            },
        ],
    },
    {
        word: "Earl",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earl <strong class='noun'> n. </strong> British nobleman ranking between marquis and viscount. earldom <strong class='noun'> n. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Earl marshal",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earl marshal <strong class='noun'> n. </strong> President of the college of heralds, with ceremonial duties.</p>",
            },
        ],
    },
    {
        word: "Early",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Early <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> (-ier, -iest) 1 before the due, usual, or expected time. 2 a not far on in the day or night, or in time (early evening; at the earliest opportunity). B prompt (early payment appreciated). 3 not far on in a period, development, or process of evolution; being the first stage (early english architecture; early spring). 4 forward in flowering, ripening, etc. (early peaches). <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> (usu. In pl.) Early fruit or vegetable. earliness <strong class='noun'> n. </strong> [old english: related to *ere]</p>",
            },
        ],
    },
    {
        word: "Early bird",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Early bird <strong class='noun'> n. </strong> Colloq. Person who arrives, gets up, etc. Early.</p>",
            },
        ],
    },
    {
        word: "Early days",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Early days <strong class='n_pl'> n.pl. </strong> Too soon to expect results etc.</p>",
            },
        ],
    },
    {
        word: "Early on",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Early on <strong class='adv'> adv. </strong> At an early stage.</p>",
            },
        ],
    },
    {
        word: "Earmark",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earmark <strong class='noun'> —v. </strong> Set aside for a special purpose. <strong class='noun'> —n. </strong> Identifying mark.</p>",
            },
        ],
    },
    {
        word: "Earn",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earn <strong class='verb'> v. </strong> 1 bring in as income or interest. 2 be entitled to or obtain as the reward for work or merit. earner <strong class='noun'> n. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Earnest",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earnest <strong class='adj'> adj. </strong> Intensely serious. in earnest serious, seriously, with determination. earnestly <strong class='adv'> adv. </strong> Earnestness <strong class='noun'> n. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Earnings",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earnings <strong class='n_pl'> n.pl. </strong> Money earned.</p>",
            },
        ],
    },
    {
        word: "Earphone",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earphone <strong class='noun'> n. </strong> Device applied to the ear to receive a radio etc. Communication.</p>",
            },
        ],
    },
    {
        word: "Earpiece",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earpiece <strong class='noun'> n. </strong> Part of a telephone etc. Applied to the ear.</p>",
            },
        ],
    },
    {
        word: "Ear-piercing",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ear-piercing <strong class='adj'> —adj.</strong> Shrill. <strong class='noun'> —n. </strong> Piercing of the ears for wearing earrings.</p>",
            },
        ],
    },
    {
        word: "Earplug",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earplug <strong class='noun'> n. </strong> Piece of wax etc. Placed in the ear to protect against water, noise, etc.</p>",
            },
        ],
    },
    {
        word: "Earring",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earring <strong class='noun'> n. </strong> Jewellery worn on the ear.</p>",
            },
        ],
    },
    {
        word: "Earshot",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earshot <strong class='noun'> n. </strong> Hearing-range (within earshot).</p>",
            },
        ],
    },
    {
        word: "Ear-splitting",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ear-splitting <strong class='adj'> adj. </strong> Excessively loud.</p>",
            },
        ],
    },
    {
        word: "Earth",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earth <strong class='noun'> —n. </strong> 1 a (also earth) the planet on which we live. B land and sea, as distinct from sky. 2 a the ground (fell to earth). B soil, mould. 3 relig. This world, as distinct from heaven or hell. 4 connection to the earth as the completion of an electrical circuit. 5 hole of a fox etc. 6 (prec. By the) <strong class='colloq'> colloq. </strong> Huge sum; everything (cost the earth; want the earth). —v. 1 cover (plant-roots) with earth. 2 connect (an electrical circuit) to the earth. come back (or down) to earth return to realities. Gone to earth in hiding. On earth <strong class='colloq'> colloq. </strong> Existing anywhere; emphatically (the happiest man on earth; looked like nothing on earth; what on earth have you done?). Run to earth find after a long search. earthward adj. & <strong class='adv'> adv. </strong> Earthwards <strong class='adv'> adv. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Earthbound",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earthbound <strong class='adj'> adj. </strong> 1 attached to the earth or earthly things. 2 moving towards the earth.</p>",
            },
        ],
    },
    {
        word: "Earthen",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earthen <strong class='adj'> adj. </strong> Made of earth or baked clay.</p>",
            },
        ],
    },
    {
        word: "Earthenware",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earthenware <strong class='noun'> n. </strong> Pottery made of fired clay.</p>",
            },
        ],
    },
    {
        word: "Earthling",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earthling <strong class='noun'> n. </strong> Inhabitant of the earth, esp. In science fiction.</p>",
            },
        ],
    },
    {
        word: "Earthly",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earthly <strong class='adj'> adj. </strong> 1 of the earth or human life on it; terrestrial. 2 (usu. With neg.) Colloq. Remotely possible (is no earthly use; there wasn't an earthly reason). not an earthly <strong class='colloq'> colloq. </strong> No chance or idea whatever.</p>",
            },
        ],
    },
    {
        word: "Earth mother",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earth mother <strong class='noun'> n. </strong> Sensual and maternal woman.</p>",
            },
        ],
    },
    {
        word: "Earthquake",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earthquake <strong class='noun'> n. </strong> Convulsion of the earth's surface as a result of faults in strata or volcanic action.</p>",
            },
        ],
    },
    {
        word: "Earth sciences",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earth sciences <strong class='n_pl'> n.pl. </strong> Those concerned with the earth or part of it.</p>",
            },
        ],
    },
    {
        word: "Earth-shattering",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earth-shattering <strong class='adj'> adj. </strong> Colloq. Traumatic, devastating. earth-shatteringly adv.</p>",
            },
        ],
    },
    {
        word: "Earthwork",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earthwork <strong class='noun'> n. </strong> Artificial bank of earth in fortification or road-building etc.</p>",
            },
        ],
    },
    {
        word: "Earthworm",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earthworm <strong class='noun'> n. </strong> Common worm living in the ground.</p>",
            },
        ],
    },
    {
        word: "Earthy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earthy <strong class='adj'> adj. </strong> (-ier, -iest) 1 of or like earth or soil. 2 coarse, crude (earthy humour). earthiness n.</p>",
            },
        ],
    },
    {
        word: "Ear-trumpet",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ear-trumpet <strong class='noun'> n. </strong> Trumpet-shaped device formerly used as a hearing-aid.</p>",
            },
        ],
    },
    {
        word: "Earwig",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Earwig <strong class='noun'> n. </strong> Small insect with pincers at its rear end. [from *ear1, because they were once thought to enter the head through the ear]</p>",
            },
        ],
    },
    {
        word: "Ease",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ease <strong class='noun'> —n. </strong> 1 facility, effortlessness. 2 a freedom from pain or trouble. B freedom from constraint. —v. <strong class='sing'>(-sing)</strong> 1 relieve from pain or anxiety. 2 (often foll. By off, up) a become less burdensome or severe. B begin to take it easy. C slow down; moderate one's behaviour etc. 3 a relax; slacken; make a less tight fit. B move or be moved carefully into place (eased it into position). at ease 1 free from anxiety or constraint. 2 mil. In a relaxed attitude, with the feet apart. [latin: related to *adjacent]</p>",
            },
        ],
    },
    {
        word: "Easel",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easel <strong class='noun'> n. </strong> Stand for an artist's work, a blackboard, etc. [dutch ezel ass]</p>",
            },
        ],
    },
    {
        word: "Easement",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easement <strong class='noun'> n. </strong> Legal right of way or similar right over another's land. [french: related to *ease]</p>",
            },
        ],
    },
    {
        word: "Easily",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easily <strong class='adv'> adv. </strong> 1 without difficulty. 2 by far (easily the best). 3 very probably (it could easily snow).</p>",
            },
        ],
    },
    {
        word: "East",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>East <strong class='noun'> —n. </strong> 1 a point of the horizon where the sun rises at the equinoxes. B compass point corresponding to this. C direction in which this lies. 2 (usu. The east) a countries to the east of europe. B states of eastern europe. 3 eastern part of a country, town, etc. <strong class='adj'> —adj.</strong> 1 towards, at, near, or facing the east. 2 from the east (east wind). <strong class='adv'> —adv. </strong> 1 towards, at, or near the east. 2 (foll. By of) further east than. to the east (often foll. By of) in an easterly direction. [old english]</p>",
            },
        ],
    },
    {
        word: "Eastbound",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eastbound <strong class='adj'> adj. </strong> Travelling or leading eastwards.</p>",
            },
        ],
    },
    {
        word: "East end",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>East end <strong class='noun'> n. </strong> Part of london east of the city. east ender n.</p>",
            },
        ],
    },
    {
        word: "Easter",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easter <strong class='noun'> n. </strong> Festival (held on a variable sunday in march or april) commemorating christ's resurrection. [old english]</p>",
            },
        ],
    },
    {
        word: "Easter egg",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easter egg <strong class='noun'> n. </strong> Artificial usu. Chocolate egg given at easter.</p>",
            },
        ],
    },
    {
        word: "Easterly",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easterly <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> 1 in an eastern position or direction. 2 (of a wind) from the east. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> such a wind.</p>",
            },
        ],
    },
    {
        word: "Eastern",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eastern <strong class='adj'> adj. </strong> Of or in the east. easternmost adj.</p>",
            },
        ],
    },
    {
        word: "Eastern church",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eastern church <strong class='noun'> n. </strong> Orthodox church.</p>",
            },
        ],
    },
    {
        word: "Easterner",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easterner <strong class='noun'> n. </strong> Native or inhabitant of the east.</p>",
            },
        ],
    },
    {
        word: "East-north-east",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>East-north-east <strong class='noun'> n. </strong> Point or direction midway between east and north-east.</p>",
            },
        ],
    },
    {
        word: "East-south-east",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>East-south-east <strong class='noun'> n. </strong> Point or direction midway between east and south-east.</p>",
            },
        ],
    },
    {
        word: "Eastward",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eastward <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> (also eastwards) towards the east. <strong class='noun'> —n. </strong> Eastward direction or region.</p>",
            },
        ],
    },
    {
        word: "Easy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easy <strong class='adj'> —adj.</strong> (-ier, -iest) 1 not difficult; not requiring great effort. 2 free from pain, trouble, or anxiety. 3 free from constraint; relaxed and pleasant. 4 compliant. <strong class='adv'> —adv. </strong> With ease; in an effortless or relaxed manner. <strong class='pl'> —int. </strong> Go or move carefully. easy on the eye (or ear etc.) Colloq. Pleasant to look at (or listen to etc.). Go easy (foll. By with, on) be sparing or cautious. I'm easy <strong class='colloq'> colloq. </strong> I have no preference. Take it easy 1 proceed gently. 2 relax; work less. easiness <strong class='noun'> n. </strong> [french: related to *ease]</p>",
            },
        ],
    },
    {
        word: "Easy chair",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easy chair <strong class='noun'> n. </strong> Large comfortable armchair.</p>",
            },
        ],
    },
    {
        word: "Easygoing",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easygoing <strong class='adj'> adj. </strong> Placid and tolerant.</p>",
            },
        ],
    },
    {
        word: "Easy street",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Easy street <strong class='noun'> n. </strong> Colloq. Affluence.</p>",
            },
        ],
    },
    {
        word: "Eat",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eat <strong class='noun'> —v. </strong> (past ate;  <strong class='archaic_it_was'>past part.</strong> Eaten) 1 a take into the mouth, chew, and swallow (food). B consume food; take a meal. C devour (eaten by a lion). 2 (foll. By away, at, into) a destroy gradually, esp. By corrosion, disease, etc. B begin to consume or diminish (resources etc.). 3 <strong class='colloq'> colloq. </strong> Trouble, vex (what's eating you?). <strong class='noun'> —n. </strong> (in pl.) Colloq. Food. eat one's heart out suffer from excessive longing or envy. Eat out have a meal away from home, esp. In a restaurant. Eat up 1 eat completely.</p>",
            },
        ],
    },
    {
        word: "Eatable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eatable <strong class='adj'> —adj.</strong> Fit to be eaten. <strong class='noun'> —n. </strong> (usu. In pl.) Food.</p>",
            },
        ],
    },
    {
        word: "Eater",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eater <strong class='noun'> n. </strong> 1 person who eats (a big eater). 2 eating apple etc.</p>",
            },
        ],
    },
    {
        word: "Eating apple  etc.",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eating apple etc. <strong class='noun'> n. </strong> Apple etc. Suitable for eating raw.</p>",
            },
        ],
    },
    {
        word: "Eau-de-cologne",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eau-de-cologne <strong class='noun'> n. </strong> Toilet water <strong class='archaic_it_was'> orig. </strong> From cologne. [french, = water of cologne]</p>",
            },
        ],
    },
    {
        word: "Eaves",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eaves <strong class='n_pl'> n.pl. </strong> Underside of a projecting roof. [old english]</p>",
            },
        ],
    },
    {
        word: "Eavesdrop",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eavesdrop <strong class='verb'> v. </strong> (-pp-) listen to a private conversation. eavesdropper n.</p>",
            },
        ],
    },
    {
        word: "Ebb",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ebb <strong class='noun'> —n. </strong> Movement of the tide out to sea. —v. (often foll. By away) 1 flow out to sea; recede. 2 decline (life was ebbing away). [old english]</p>",
            },
        ],
    },
    {
        word: "Ebonite",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ebonite <strong class='noun'> n. </strong> Vulcanite. [from *ebony]</p>",
            },
        ],
    },
    {
        word: "Ebony",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ebony <strong class='noun'> —n. </strong> Heavy hard dark wood of a tropical tree. <strong class='adj'> —adj.</strong> 1 made of ebony. 2 black like ebony. [greek ebenos ebony tree]</p>",
            },
        ],
    },
    {
        word: "Ebullient",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ebullient <strong class='adj'> adj. </strong> Exuberant. ebullience <strong class='noun'> n. </strong> Ebulliency <strong class='noun'> n. </strong> Ebulliently <strong class='adv'> adv. </strong> [latin: related to *boil1]</p>",
            },
        ],
    },
    {
        word: "Ec",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ec <strong class='sing'> abbr. </strong> 1 east central. 2 european community.</p>",
            },
        ],
    },
    {
        word: "Eccentric",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eccentric <strong class='adj'> —adj.</strong> 1 odd or capricious in behaviour or appearance. 2 (also excentric) a not placed, not having its axis placed, centrally. B <strong class='adj'> (often foll. By to) </strong> (of a circle) not concentric (to another). C (of an orbit) not circular. <strong class='noun'> —n. </strong> 1 eccentric person. 2 disc at the end of a shaft for changing rotatory into backward-and-forward motion. eccentrically <strong class='adv'> adv. </strong> Eccentricity <strong class='noun'> n. </strong> [greek: related to *centre]</p>",
            },
        ],
    },
    {
        word: "Eccles cake",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eccles cake <strong class='noun'> n. </strong> Round cake of pastry filled with currants etc. [eccles in <strong class='noun'> n. </strong> England]</p>",
            },
        ],
    },
    {
        word: "Ecclesiastic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecclesiastic <strong class='noun'> —n. </strong> Clergyman. <strong class='adj'> —adj.</strong> = *ecclesiastical. [greek ekklesia church]</p>",
            },
        ],
    },
    {
        word: "Ecclesiastical",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecclesiastical <strong class='adj'> adj. </strong> Of the church or clergy.</p>",
            },
        ],
    },
    {
        word: "Ecg",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecg <strong class='sing'> abbr. </strong> Electrocardio <strong class='gram'>gram.</strong> </p>",
            },
        ],
    },
    {
        word: "Echelon",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Echelon <strong class='noun'> n. </strong> 1 level in an organization, in society, etc.; those occupying it (often in pl.: upper echelons). 2 wedge-shaped formation of troops, aircraft, etc. [french, = ladder, from latin scala]</p>",
            },
        ],
    },
    {
        word: "Echidna",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Echidna <strong class='noun'> n. </strong> Australian egg-laying spiny mammal. [greek, = viper]</p>",
            },
        ],
    },
    {
        word: "Echinoderm",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Echinoderm <strong class='noun'> n. </strong> (usu. Spiny) sea animal of the group including the starfish and sea urchin. [greek ekhinos sea-urchin, derma skin]</p>",
            },
        ],
    },
    {
        word: "Echo",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Echo <strong class='noun'> —n. </strong> (pl. -es) 1 a repetition of a sound by the reflection of sound waves. B sound so produced. 2 reflected radio or radar beam. 3 close imitation or imitator. 4 circumstance or event reminiscent of an earlier one. —v. (-es, -ed) 1 a (of a place) resound with an echo. B (of a sound) be repeated; resound. 2 repeat (a sound) thus. 3 a repeat (another's words). B imitate the opinions etc. Of. [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Echo chamber",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Echo chamber <strong class='noun'> n. </strong> Enclosure with sound-reflecting walls.</p>",
            },
        ],
    },
    {
        word: "Echoic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Echoic <strong class='adj'> adj. </strong> (of a word) onomatopoeic.</p>",
            },
        ],
    },
    {
        word: "Echolocation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Echolocation <strong class='noun'> n. </strong> Location of objects by reflected sound.</p>",
            },
        ],
    },
    {
        word: "Echo-sounder",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Echo-sounder <strong class='noun'> n. </strong> Depth-sounding device using timed echoes.</p>",
            },
        ],
    },
    {
        word: "Echt",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Echt <strong class='adj'> adj. </strong> Genuine. [german]</p>",
            },
        ],
    },
    {
        word: "Éclair",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Éclair <strong class='noun'> n. </strong> Small elongated iced cake of choux pastry filled with cream. [french, = lightning]</p>",
            },
        ],
    },
    {
        word: "Eclampsia",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eclampsia <strong class='noun'> n. </strong> Convulsive condition occurring esp. In pregnant women. [ultimately from greek]</p>",
            },
        ],
    },
    {
        word: "Éclat",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Éclat <strong class='noun'> n. </strong> 1 brilliant display. 2 social distinction; conspicuous success. [french]</p>",
            },
        ],
    },
    {
        word: "Eclectic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eclectic <strong class='adj'> —adj.</strong> Selecting ideas, style, etc., from various sources. <strong class='noun'> —n. </strong> Eclectic person or philosopher. eclectically <strong class='adv'> adv. </strong> Eclecticism <strong class='noun'> n. </strong> [greek eklego pick out]</p>",
            },
        ],
    },
    {
        word: "Eclipse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eclipse <strong class='noun'> —n. </strong> 1 obscuring of light from one heavenly body by another. 2 loss of light, importance, or prominence. —v. <strong class='sing'>(-sing)</strong> 1 (of a heavenly body) cause the eclipse of (another). 2 intercept (light). 3 outshine, surpass. [greek ekleipsis]</p>",
            },
        ],
    },
    {
        word: "Ecliptic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecliptic <strong class='noun'> n. </strong> Sun's apparent path among the stars during the year.</p>",
            },
        ],
    },
    {
        word: "Eclogue",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eclogue <strong class='noun'> n. </strong> Short pastoral poem. [greek: related to *eclectic]</p>",
            },
        ],
    },
    {
        word: "Eco-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eco- <strong class='n_pl'> comb. </strong> Form ecology, ecological (ecoclimate).</p>",
            },
        ],
    },
    {
        word: "Ecology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecology <strong class='noun'> n. </strong> 1 the study of the relations of organisms to one another and to their surroundings. 2 the study of the interaction of people with their environment. ecological adj. Ecologically <strong class='adv'> adv. </strong> Ecologist <strong class='noun'> n. </strong> [greek oikos house]</p>",
            },
        ],
    },
    {
        word: "Economic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Economic <strong class='adj'> adj. </strong> 1 of economics. 2 profitable (not economic to run buses on a sunday). 3 connected with trade and industry (economic geography). economically <strong class='adv'> adv. </strong> [greek: related to *economy]</p>",
            },
        ],
    },
    {
        word: "Economical",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Economical <strong class='adj'> adj. </strong> Sparing; avoiding waste. economically adv.</p>",
            },
        ],
    },
    {
        word: "Economics",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Economics <strong class='n_pl'> n.pl. </strong> (as sing.) 1 science of the production and distribution of wealth. 2 application of this to a particular subject (the economics of publishing).</p>",
            },
        ],
    },
    {
        word: "Economist",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Economist <strong class='noun'> n. </strong> Expert on or student of economics.</p>",
            },
        ],
    },
    {
        word: "Economize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Economize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 be economical; make economies; reduce expenditure. 2 (foll. By on) use sparingly.</p>",
            },
        ],
    },
    {
        word: "Economy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Economy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a community's system of wealth creation. B particular kind of this (a capitalist economy). C administration or condition of this. 2 a careful management of (esp. Financial) resources; frugality. B instance of this (made many economies). 3 sparing or careful use (economy of language). [greek oikonomia household management]</p>",
            },
        ],
    },
    {
        word: "Economy class",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Economy class <strong class='noun'> n. </strong> Cheapest class of air travel.</p>",
            },
        ],
    },
    {
        word: "Economy-size",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Economy-size <strong class='adj'> adj. </strong> (of goods) consisting of a larger quantity for a proportionally lower cost.</p>",
            },
        ],
    },
    {
        word: "Ecosystem",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecosystem <strong class='noun'> n. </strong> Biological community of interacting organisms and their physical environment.</p>",
            },
        ],
    },
    {
        word: "Ecstasy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecstasy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 overwhelming joy or rapture. 2 slang type of hallucinogenic drug. ecstatic adj. Ecstatically <strong class='adv'> adv. </strong> [greek ekstasis standing outside oneself]</p>",
            },
        ],
    },
    {
        word: "Ect",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ect <strong class='sing'> abbr. </strong> Electroconvulsive therapy.</p>",
            },
        ],
    },
    {
        word: "Ecto-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecto- <strong class='n_pl'> comb. </strong> Form outside. [greek ektos]</p>",
            },
        ],
    },
    {
        word: "Ectomorph",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ectomorph <strong class='noun'> n. </strong> Person with a lean body. [greek morphe form]</p>",
            },
        ],
    },
    {
        word: "-ectomy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ectomy <strong class='n_pl'> comb. </strong> Form denoting the surgical removal of part of the body (appendectomy). [greek ektome excision]</p>",
            },
        ],
    },
    {
        word: "Ectoplasm",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ectoplasm <strong class='noun'> n. </strong> Supposed viscous substance exuding from the body of a spiritualistic medium during a trance. [from *ecto-, *plasma]</p>",
            },
        ],
    },
    {
        word: "Ecu",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecu <strong class='noun'> n. </strong> (also ecu) <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> european currency unit. [abbreviation]</p>",
            },
        ],
    },
    {
        word: "Ecumenical",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ecumenical <strong class='adj'> adj. </strong> 1 of or representing the whole christian world. 2 seeking worldwide christian unity. ecumenically <strong class='adv'> adv. </strong> Ecumenism <strong class='noun'> n. </strong> [greek oikoumenikos of the inhabited earth]</p>",
            },
        ],
    },
    {
        word: "Eczema",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eczema <strong class='noun'> n. </strong> Inflammation of the skin, with itching and discharge. [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Ed.",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ed. <strong class='abbr'> Abbr. </strong> 1 edited by. 2 edition. 3 editor. 4 educated.</p>",
            },
        ],
    },
    {
        word: "-ed1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ed1 <strong class='slang_v'> suffix </strong>forming adjectives: 1 from nouns, meaning ‘having, wearing, etc.’ (talented; trousered). 2 from phrases of adjective and noun (good-humoured). [old english]</p>",
            },
        ],
    },
    {
        word: "-ed2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ed2 <strong class='slang_v'> suffix </strong>forming: 1 past tense and past participle of weak verbs (needed). 2 participial adjectives (escaped prisoner). [old english]</p>",
            },
        ],
    },
    {
        word: "Edam",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edam <strong class='noun'> n. </strong> Round dutch cheese with a red rind. [edam in holland]</p>",
            },
        ],
    },
    {
        word: "Eddy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eddy <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 circular movement of water causing a small whirlpool. 2 movement of wind, smoke, etc. Resembling this. —v. (-ies, -ied) whirl round in eddies. [old english ed- again, back]</p>",
            },
        ],
    },
    {
        word: "Edelweiss",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edelweiss <strong class='noun'> n. </strong> Alpine plant with white flowers. [german, = noble-white]</p>",
            },
        ],
    },
    {
        word: "Edema",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edema <strong class='noun'> n. </strong> (brit. Oedema) accumulation of excess fluid in body tissues, causing swelling. [greek oideo swell]</p>",
            },
        ],
    },
    {
        word: "Eden",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eden <strong class='noun'> n. </strong> Place or state of great happiness, with reference to the abode of adam and eve at the creation. [hebrew, originally = delight]</p>",
            },
        ],
    },
    {
        word: "Edentate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edentate <strong class='adj'> —adj.</strong> Having no or few teeth. <strong class='noun'> —n. </strong> Such a mammal. [latin dens dent- tooth]</p>",
            },
        ],
    },
    {
        word: "Edge",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edge <strong class='noun'> —n. </strong> 1 boundary-line or margin of an area or surface. 2 narrow surface of a thin object. 3 meeting-line of surfaces. 4 a sharpened side of a blade. B sharpness. 5 brink of a precipice. 6 edge-like thing, esp. The crest of a ridge. 7 effectiveness, incisiveness; excitement. —v. (-ging) 1 advance, esp. Gradually or furtively. 2 a provide with an edge or border. B form a border to. 3 sharpen (a tool etc.). have the edge on (or over) have a slight advantage over. On edge tense and irritable. Set a person's teeth on edge (of taste or sound) cause an unpleasant nervous sensation. Take the edge off make less intense. [old english]</p>",
            },
        ],
    },
    {
        word: "Edgeways",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edgeways <strong class='adv'> adv. </strong> (also edgewise) with edge uppermost or foremost. get a word in edgeways contribute to a conversation when the dominant speaker pauses.</p>",
            },
        ],
    },
    {
        word: "Edging",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edging <strong class='noun'> n. </strong> Thing forming an edge or border.</p>",
            },
        ],
    },
    {
        word: "Edgy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edgy <strong class='adj'> adj. </strong> (-ier, -iest) irritable; anxious. edgily <strong class='adv'> adv. </strong> Edginess n.</p>",
            },
        ],
    },
    {
        word: "Edible",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edible <strong class='adj'> adj. </strong> Fit to be eaten. edibility <strong class='noun'> n. </strong> [latin edo eat]</p>",
            },
        ],
    },
    {
        word: "Edict",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edict <strong class='noun'> n. </strong> Order proclaimed by authority. [latin edico proclaim]</p>",
            },
        ],
    },
    {
        word: "Edifice",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edifice <strong class='noun'> n. </strong> Building, esp. An imposing one. [latin aedis dwelling]</p>",
            },
        ],
    },
    {
        word: "Edify",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edify <strong class='verb'> v. </strong> (-ies, -ied) improve morally or intellectually. edification <strong class='noun'> n. </strong> [latin aedifico build]</p>",
            },
        ],
    },
    {
        word: "Edit",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edit <strong class='verb'> v. </strong> (-t-) 1 assemble, prepare, or modify (written material for publication). 2 be editor of (a newspaper etc.). 3 take extracts from and collate (a film etc.) To form a unified sequence. 4 a prepare (data) for processing by a computer. B alter (a text entered in a word processor etc.). 5 a reword in order to correct, or to alter the emphasis. B (foll. By out) remove (a part) from a text etc. [latin edo edit- give out]</p>",
            },
        ],
    },
    {
        word: "Edition",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edition <strong class='noun'> n. </strong> 1 edited or published form of a book etc. 2 copies of a book, newspaper, etc. Issued at one time. 3 instance of a regular broadcast. 4 person or thing similar to another (a miniature edition of her mother).</p>",
            },
        ],
    },
    {
        word: "Editor",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Editor <strong class='noun'> n. </strong> 1 person who edits. 2 person who directs the preparation of a newspaper or broadcast news programme or a particular section of one (sports editor). 3 person who selects or commissions material for publication. 4 computer program for entering and modifying textual data. editorship n.</p>",
            },
        ],
    },
    {
        word: "Editorial",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Editorial <strong class='adj'> —adj.</strong> 1 of editing or editors. 2 written or approved by an editor. <strong class='noun'> —n. </strong> Article giving a newspaper's views on a current topic. editorially adv.</p>",
            },
        ],
    },
    {
        word: "Edp",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edp <strong class='sing'> abbr. </strong> Electronic data processing.</p>",
            },
        ],
    },
    {
        word: "Educate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Educate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 give intellectual, moral, and social instruction to. 2 provide education for. educable adj. Educability <strong class='noun'> n. </strong> Educative adj. Educator <strong class='noun'> n. </strong> [latin educo -are rear]</p>",
            },
        ],
    },
    {
        word: "Educated",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Educated <strong class='adj'> adj. </strong> 1 having had an (esp. Good) education. 2 resulting from this (educated accent). 3 based on experience or study (educated guess).</p>",
            },
        ],
    },
    {
        word: "Education",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Education <strong class='noun'> n. </strong> 1 systematic instruction. 2 particular kind of or stage in education (a classical education; further education). 3 development of character or mental powers. educational adj. Educationally adv.</p>",
            },
        ],
    },
    {
        word: "Educationist",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Educationist <strong class='noun'> n. </strong> (also educationalist) expert in educational methods.</p>",
            },
        ],
    },
    {
        word: "Educe",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Educe <strong class='verb'> v. </strong> (-cing) literary bring out or develop from latency. eduction <strong class='noun'> n. </strong> [latin educo -ere draw out]</p>",
            },
        ],
    },
    {
        word: "Edwardian",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Edwardian <strong class='adj'> —adj.</strong> Of or characteristic of the reign of edward vii (1901–10). <strong class='noun'> —n. </strong> Person of this period.</p>",
            },
        ],
    },
    {
        word: "-ee",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ee <strong class='n_pl'>  <strong class='see'> <strong class='see'>suffix forming nouns denoting:</strong></strong> </strong> 1 person affected by the verbal action (employee; payee). 2 person concerned with or described as (absentee; refugee). 3 object of smaller size (bootee). [french -é in past part.]</p>",
            },
        ],
    },
    {
        word: "Eec",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eec <strong class='sing'> abbr. </strong> European economic community. <strong class='usage'> Usage:- </strong>ec is the more correct term.</p>",
            },
        ],
    },
    {
        word: "Eeg",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eeg <strong class='sing'> abbr. </strong> Electroencephalo <strong class='gram'>gram.</strong> </p>",
            },
        ],
    },
    {
        word: "Eel",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eel <strong class='noun'> n. </strong> Snakelike fish. [old english]</p>",
            },
        ],
    },
    {
        word: "-eer",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-eer <strong class='slang_v'> suffix </strong>forming: 1 nouns meaning ‘person concerned with’ (auctioneer). 2 verbs meaning ‘be concerned with’ (electioneer). [french -ier from latin -arius]</p>",
            },
        ],
    },
    {
        word: "Eerie",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eerie <strong class='adj'> adj. </strong> (eerier, eeriest) gloomy and strange; weird (eerie silence). eerily <strong class='adv'> adv. </strong> Eeriness <strong class='noun'> n. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Ef-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>Ef- <strong class='see'> see *</strong> ex-1.</p>",
            },
        ],
    },
    {
        word: "Efface",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Efface <strong class='verb'> v. </strong> (-cing) 1 rub or wipe out (a mark, recollection, etc.). 2 surpass, eclipse. 3 refl. (usu. As self-effacing adj.) Treat oneself as unimportant. effacement <strong class='noun'> n. </strong> [french: related to *face]</p>",
            },
        ],
    },
    {
        word: "Effect",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effect <strong class='noun'> —n. </strong> 1 result or consequence of an action etc. 2 efficacy (had little effect). 3 impression produced on a spectator, hearer, etc. (lights gave a pretty effect; said it just for effect). 4 (in pl.) Property. 5 (in pl.) Lighting, sound, etc., giving realism to a play, film, etc. 6 physical phenomenon (doppler effect; greenhouse effect). —v. Bring about (a change, cure, etc.). bring (or carry) into effect accomplish. Give effect to make operative. In effect for practical purposes. Take effect become operative. To the effect that the gist being that. To that effect having that result or implication. With effect from coming into operation at (a stated time). [latin: related to *fact] <strong class='usage'> Usage:- </strong>effect should not be confused with affect which, as a verb, has more meanings and is more common, but which does not exist as a noun.</p>",
            },
        ],
    },
    {
        word: "Effective",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effective <strong class='adj'> adj. </strong> 1 producing the intended result. 2 impressive, striking. 3 actual, existing. 4 operative. effectively <strong class='adv'> adv. </strong> Effectiveness n.</p>",
            },
        ],
    },
    {
        word: "Effectual",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effectual <strong class='adj'> adj. </strong> 1 producing the required effect. 2 valid. effectually adv.</p>",
            },
        ],
    },
    {
        word: "Effeminate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effeminate <strong class='adj'> adj. </strong> (of a man) womanish in appearance or manner. effeminacy <strong class='noun'> n. </strong> Effeminately <strong class='adv'> adv. </strong> [latin femina woman]</p>",
            },
        ],
    },
    {
        word: "Effervesce",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effervesce <strong class='verb'> v. </strong> (-cing) 1 give off bubbles of gas. 2 be lively. effervescence <strong class='noun'> n. </strong> Effervescent adj. [latin: related to *fervent]</p>",
            },
        ],
    },
    {
        word: "Effete",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effete <strong class='adj'> adj. </strong> Feeble, lanquid; effeminate. effeteness <strong class='noun'> n. </strong> [latin]</p>",
            },
        ],
    },
    {
        word: "Efficacious",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Efficacious <strong class='adj'> adj. </strong> Producing the desired effect. efficacy <strong class='noun'> n. </strong> [latin efficax: related to *efficient]</p>",
            },
        ],
    },
    {
        word: "Efficient",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Efficient <strong class='adj'> adj. </strong> 1 productive with minimum waste or effort. 2 (of a person) capable; acting effectively. efficiency <strong class='noun'> n. </strong> Efficiently <strong class='adv'> adv. </strong> [latin facio make]</p>",
            },
        ],
    },
    {
        word: "Effigy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effigy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> sculpture or model of a person. burn in effigy burn a model of a person. [latin effigies from fingo fashion]</p>",
            },
        ],
    },
    {
        word: "Effloresce",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effloresce <strong class='verb'> v. </strong> (-cing) 1 burst into flower. 2 a (of a substance) turn to a fine powder on exposure to air. B (of salts) come to the surface and crystallize. C (of a surface) become covered with salt particles. efflorescence <strong class='noun'> n. </strong> Efflorescent adj. [latin flos flor- *flower]</p>",
            },
        ],
    },
    {
        word: "Effluence",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effluence <strong class='noun'> n. </strong> 1 flowing out of light, electricity, etc. 2 that which flows out. [latin fluo flux- flow]</p>",
            },
        ],
    },
    {
        word: "Effluent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effluent <strong class='adj'> —adj.</strong> Flowing out. <strong class='noun'> —n. </strong> 1 sewage or industrial waste discharged into a river etc. 2 stream or lake flowing from a larger body of water.</p>",
            },
        ],
    },
    {
        word: "Effluvium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effluvium <strong class='noun'> n. </strong> (pl. -via) unpleasant or noxious outflow. [latin: related to *effluence]</p>",
            },
        ],
    },
    {
        word: "Effort",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effort <strong class='noun'> n. </strong> 1 use of physical or mental energy. 2 determined attempt. 3 force exerted. 4 <strong class='colloq'> colloq. </strong> Something accomplished. [latin fortis strong]</p>",
            },
        ],
    },
    {
        word: "Effortless",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effortless <strong class='adj'> adj. </strong> Easily done, requiring no effort. effortlessly <strong class='adv'> adv. </strong> Effortlessness n.</p>",
            },
        ],
    },
    {
        word: "Effrontery",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effrontery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> impudent audacity. [latin frons front- forehead]</p>",
            },
        ],
    },
    {
        word: "Effulgent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effulgent <strong class='adj'> adj. </strong> Literary radiant. effulgence <strong class='noun'> n. </strong> [latin fulgeo shine]</p>",
            },
        ],
    },
    {
        word: "Effuse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effuse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 pour forth (liquid, light, etc.). 2 give out (ideas etc.). [latin fundo fus- pour]</p>",
            },
        ],
    },
    {
        word: "Effusion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effusion <strong class='noun'> n. </strong> 1 outpouring. 2 derog. Unrestrained flow of words. [latin: related to *effuse]</p>",
            },
        ],
    },
    {
        word: "Effusive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Effusive <strong class='adj'> adj. </strong> Gushing, demonstrative. effusively <strong class='adv'> adv. </strong> Effusiveness n.</p>",
            },
        ],
    },
    {
        word: "Efl",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Efl <strong class='sing'> abbr. </strong> English as a foreign language.</p>",
            },
        ],
    },
    {
        word: "Eft",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eft <strong class='noun'> n. </strong> Newt. [old english]</p>",
            },
        ],
    },
    {
        word: "Efta",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Efta <strong class='noun'> n. </strong> (also efta) european free trade association. [abbreviation]</p>",
            },
        ],
    },
    {
        word: "E.g.",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>E.g. <strong class='abbr'> Abbr. </strong> For example. [latin exempli gratia]</p>",
            },
        ],
    },
    {
        word: "Egalitarian",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egalitarian <strong class='adj'> —adj.</strong> Of or advocating equal rights for all. <strong class='noun'> —n. </strong> Egalitarian person. egalitarianism <strong class='noun'> n. </strong> [french égal *equal]</p>",
            },
        ],
    },
    {
        word: "Egg1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egg1 <strong class='noun'> n. </strong> 1 a body produced by females of birds, insects, etc. And capable of developing into a new individual. B egg of the domestic hen, used for food. 2 biol. Ovum. 3 <strong class='colloq'> colloq. </strong> Person or thing of a specified kind (good egg). with egg on one's face colloq.</p>",
            },
        ],
    },
    {
        word: "Egg2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egg2 <strong class='verb'> v. </strong> (foll. By on) urge. [old norse: related to *edge]</p>",
            },
        ],
    },
    {
        word: "Eggcup",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eggcup <strong class='noun'> n. </strong> Cup for holding a boiled egg.</p>",
            },
        ],
    },
    {
        word: "Egg-flip",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egg-flip <strong class='noun'> n. </strong> (also egg-nog) drink of alcoholic spirit with beaten egg, milk, etc.</p>",
            },
        ],
    },
    {
        word: "Egghead",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egghead <strong class='noun'> n. </strong> Colloq. Intellectual; expert.</p>",
            },
        ],
    },
    {
        word: "Eggplant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eggplant <strong class='noun'> n. </strong> = *aubergine.</p>",
            },
        ],
    },
    {
        word: "Eggshell",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eggshell <strong class='noun'> —n. </strong> Shell of an egg. <strong class='adj'> —adj.</strong> 1 (of china) thin and fragile. 2 (of paint) with a slight gloss.</p>",
            },
        ],
    },
    {
        word: "Egg-white",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egg-white <strong class='noun'> n. </strong> White part round the yolk of an egg.</p>",
            },
        ],
    },
    {
        word: "Eglantine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eglantine <strong class='noun'> n. </strong> Sweet-brier. [latin acus needle]</p>",
            },
        ],
    },
    {
        word: "Ego",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ego <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 the self; the part of the mind that reacts to reality and has a sense of individuality. 2 self-esteem; self-conceit. [latin, = i]</p>",
            },
        ],
    },
    {
        word: "Egocentric",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egocentric <strong class='adj'> adj. </strong> Self-centred.</p>",
            },
        ],
    },
    {
        word: "Egoism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egoism <strong class='noun'> n. </strong> 1 self-interest as the moral basis of behaviour. 2 systematic selfishness. 3 = *egotism. egoist <strong class='noun'> n. </strong> Egoistic adj. Egoistical adj. Egoistically adv. <strong class='usage'> Usage:- </strong>the senses of egoism and egotism overlap, but egoism alone is used as a term in philosophy and psychology to mean self-interest (often contrasted with altruism).</p>",
            },
        ],
    },
    {
        word: "Egotism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egotism <strong class='noun'> n. </strong> 1 self-conceit. 2 selfishness. egotist <strong class='noun'> n. </strong> Egotistic adj. Egotistical adj. Egotistically adv. <strong class='usage'> Usage:- </strong>see note at egoism.</p>",
            },
        ],
    },
    {
        word: "Ego-trip",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ego-trip <strong class='noun'> n. </strong> Colloq. Activity to boost one's own self-esteem or self-conceit.</p>",
            },
        ],
    },
    {
        word: "Egregious",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egregious <strong class='adj'> adj. </strong> 1 extremely bad. 2 archaic remarkable. [latin grex greg- flock]</p>",
            },
        ],
    },
    {
        word: "Egress",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egress <strong class='noun'> n. </strong> Formal 1 exit. 2 right of going out. [latin egredior -gress- walk out]</p>",
            },
        ],
    },
    {
        word: "Egret",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egret <strong class='noun'> n. </strong> A kind of heron with long white feathers. [french aigrette]</p>",
            },
        ],
    },
    {
        word: "Egyptian",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egyptian <strong class='adj'> —adj.</strong> Of egypt. <strong class='noun'> —n. </strong> 1 native of egypt. 2 language of the ancient egyptians.</p>",
            },
        ],
    },
    {
        word: "Egyptology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Egyptology <strong class='noun'> n. </strong> The study of the language, history, and culture of ancient egypt. egyptologist n.</p>",
            },
        ],
    },
    {
        word: "Eh",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eh <strong class='n_pl'> int. </strong> Colloq. 1 expressing enquiry or surprise. 2 inviting assent. 3 asking for repetition or explanation. [instinctive exclamation]</p>",
            },
        ],
    },
    {
        word: "Eider",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eider <strong class='noun'> n. </strong> Any of various large northern ducks. [icelandic]</p>",
            },
        ],
    },
    {
        word: "Eiderdown",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eiderdown <strong class='noun'> n. </strong> Quilt stuffed with soft material, esp. Down.</p>",
            },
        ],
    },
    {
        word: "Eight",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eight <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 one more than seven. 2 symbol for this (8, viii, viii). 3 size etc. Denoted by eight. 4 eight-oared rowing-boat or its crew. 5 eight o'clock. [old english]</p>",
            },
        ],
    },
    {
        word: "Eighteen",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eighteen <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 one more than seventeen. 2 symbol for this (18, xviii, xviii). 3 size etc. Denoted by eighteen. 4 (18) (of films) suitable only for persons of 18 years and over. eighteenth adj. & <strong class='noun'> n. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Eightfold",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eightfold <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> 1 eight times as much or as many. 2 consisting of eight parts.</p>",
            },
        ],
    },
    {
        word: "Eighth",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eighth <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 next after seventh. 2 one of eight equal parts of a thing. eighthly adv.</p>",
            },
        ],
    },
    {
        word: "Eightsome",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eightsome <strong class='noun'> n. </strong> (in full eightsome reel) lively scottish dance for eight people.</p>",
            },
        ],
    },
    {
        word: "Eighty",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eighty <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 eight times ten. 2 symbol for this (80, lxxx, lxxx). 3 (in pl.) Numbers from 80 to 89, esp. The years of a century or of a person's life. eightieth adj. & <strong class='noun'> n. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Einsteinium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Einsteinium <strong class='noun'> n. </strong> Artificial radioactive metallic element. [einstein, name of a physicist]</p>",
            },
        ],
    },
    {
        word: "Eisteddfod",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eisteddfod <strong class='noun'> n. </strong> Congress of welsh poets and musicians; festival for musical competitions etc. [welsh]</p>",
            },
        ],
    },
    {
        word: "Either",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Either <strong class='adj'> —adj.</strong> & <strong class='pron'> pron. </strong> 1 one or the other of two (either of you can go; you may have either book). 2 each of two (houses on either side of the road). <strong class='adv'> —adv. </strong> & <strong class='conj'> conj. </strong> 1 as one possibility (is either right or wrong). 2 as one choice or alternative; which way you will (either come in or go out). 3 (with neg.) A any more than the other (if you do not go, i shall not either). B moreover (there is no time to lose, either). [old english]</p>",
            },
        ],
    },
    {
        word: "Ejaculate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ejaculate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (also absol.) 1 exclaim. 2 emit (semen) in orgasm. ejaculation <strong class='noun'> n. </strong> Ejaculatory adj. [latin ejaculor dart out]</p>",
            },
        ],
    },
    {
        word: "Eject",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eject <strong class='verb'> v. </strong> 1 expel, compel to leave. 2 (of a pilot etc.) Cause oneself to be propelled from an aircraft as an emergency measure. 3 cause to be removed, drop out, or pop up automatically from a gun, cassette-player, etc. 4 dispossess (a tenant). 5 emit, send out. ejection <strong class='noun'> n. </strong> [latin ejicio eject- throw out]</p>",
            },
        ],
    },
    {
        word: "Ejector",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ejector <strong class='noun'> n. </strong> Device for ejecting.</p>",
            },
        ],
    },
    {
        word: "Ejector seat",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ejector seat <strong class='noun'> n. </strong> Device in an aircraft for the emergency ejection of a pilot etc.</p>",
            },
        ],
    },
    {
        word: "Eke",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eke <strong class='verb'> v. </strong> (eking) eke out 1 supplement (income etc.). 2 make (a living) or support (an existence) with difficulty. [old english]</p>",
            },
        ],
    },
    {
        word: "Elaborate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elaborate <strong class='adj'> —adj.</strong> 1 minutely worked out. 2 complicated. —v. <strong class='ting'> (-ting) </strong> work out or explain in detail. elaborately <strong class='adv'> adv. </strong> Elaborateness <strong class='noun'> n. </strong> Elaboration <strong class='noun'> n. </strong> [latin: related to *labour]</p>",
            },
        ],
    },
    {
        word: "Élan",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Élan <strong class='noun'> n. </strong> Vivacity, dash. [french]</p>",
            },
        ],
    },
    {
        word: "Eland",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eland <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> large african antelope. [dutch]</p>",
            },
        ],
    },
    {
        word: "Elapse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elapse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> (of time) pass by. [latin elabor elaps- slip away]</p>",
            },
        ],
    },
    {
        word: "Elastic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elastic <strong class='adj'> —adj.</strong> 1 able to resume its normal bulk or shape after contraction, dilation, or distortion. 2 springy. 3 flexible, adaptable. <strong class='noun'> —n. </strong> Elastic cord or fabric, usu. Woven with strips of rubber. elastically <strong class='adv'> adv. </strong> Elasticity <strong class='noun'> n. </strong> [greek elastikos propulsive]</p>",
            },
        ],
    },
    {
        word: "Elasticated",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elasticated <strong class='adj'> adj. </strong> (of fabric) made elastic by weaving with rubber thread.</p>",
            },
        ],
    },
    {
        word: "Elastic band",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elastic band <strong class='noun'> n. </strong> = *rubber band.</p>",
            },
        ],
    },
    {
        word: "Elastomer",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elastomer <strong class='noun'> n. </strong> Natural or synthetic rubber or rubber-like plastic. [from *elastic, after isomer]</p>",
            },
        ],
    },
    {
        word: "Elate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (esp. As elated adj.) Make delighted or proud. elatedly <strong class='adv'> adv. </strong> Elation <strong class='noun'> n. </strong> [latin effero elat- raise]</p>",
            },
        ],
    },
    {
        word: "Elbow",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elbow <strong class='noun'> —n. </strong> 1 a joint between the forearm and the upper arm. B part of a sleeve covering the elbow. 2 elbow-shaped bend etc. —v. (foll. By in, out, aside, etc.) 1 jostle or thrust (a person or oneself). 2 make (one's way) thus. give a person the elbow <strong class='colloq'> colloq. </strong> Dismiss or reject a person. [old english: related to *ell, *bow1]</p>",
            },
        ],
    },
    {
        word: "Elbow-grease",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elbow-grease <strong class='noun'> n. </strong> Colloq. Vigorous polishing; hard work.</p>",
            },
        ],
    },
    {
        word: "Elbow-room",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elbow-room <strong class='noun'> n. </strong> Sufficient room to move or work in.</p>",
            },
        ],
    },
    {
        word: "Elder1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elder1 <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> (of persons, esp. When related) senior; of greater age. <strong class='noun'> —n. </strong> 1 older of two persons (is my elder by ten years). 2 (in pl.) Persons of greater age or venerable because of age. 3 official in the early christian church and some modern churches. [old english: related to *old]</p>",
            },
        ],
    },
    {
        word: "Elder2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elder2 <strong class='noun'> n. </strong> Tree with white flowers and dark berries. [old english]</p>",
            },
        ],
    },
    {
        word: "Elderberry",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elderberry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> berry of the elder tree.</p>",
            },
        ],
    },
    {
        word: "Elderly",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elderly <strong class='adj'> adj. </strong> Rather old; past middle age.</p>",
            },
        ],
    },
    {
        word: "Elder statesman",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elder statesman <strong class='noun'> n. </strong> Influential experienced older person, esp. A politician.</p>",
            },
        ],
    },
    {
        word: "Eldest",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eldest <strong class='adj'> adj. </strong> First-born; oldest surviving.</p>",
            },
        ],
    },
    {
        word: "Eldorado",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eldorado <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 imaginary land of great wealth. 2 place of abundance or opportunity. [spanish el dorado the gilded]</p>",
            },
        ],
    },
    {
        word: "Elecampane",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elecampane <strong class='noun'> n. </strong> Plant with bitter aromatic leaves and roots. [latin enula this plant, campana of the fields]</p>",
            },
        ],
    },
    {
        word: "Elect",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elect <strong class='noun'> —v. </strong> (usu. Foll. By to + infin.) 1 choose. 2 choose by voting. <strong class='adj'> —adj.</strong> 1 chosen. 2 select, choice. 3 (after the noun) chosen but not yet in office (president elect). [latin eligo elect- pick out]</p>",
            },
        ],
    },
    {
        word: "Election",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Election <strong class='noun'> n. </strong> 1 electing or being elected. 2 occasion of this.</p>",
            },
        ],
    },
    {
        word: "Electioneer",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electioneer <strong class='verb'> v. </strong> Take part in an election campaign.</p>",
            },
        ],
    },
    {
        word: "Elective",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elective <strong class='adj'> adj. </strong> 1 chosen by or derived from election. 2 (of a body) having the power to elect. 3 optional, not urgently necessary.</p>",
            },
        ],
    },
    {
        word: "Elector",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elector <strong class='noun'> n. </strong> 1 person who has the right to vote in an election. 2 (elector) hist. (in the holy roman empire) any of the german princes entitled to elect the emperor. electoral adj.</p>",
            },
        ],
    },
    {
        word: "Electorate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electorate <strong class='noun'> n. </strong> 1 body of all electors. 2 hist. Office or territories of a german elector.</p>",
            },
        ],
    },
    {
        word: "Electric",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electric <strong class='adj'> —adj.</strong> 1 of, worked by, or charged with electricity; producing or capable of generating electricity. 2 causing or charged with excitement. <strong class='noun'> —n. </strong> (in pl.) Colloq. Electrical equipment. [greek elektron amber]</p>",
            },
        ],
    },
    {
        word: "Electrical",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrical <strong class='adj'> adj. </strong> Of electricity. electrically adv.</p>",
            },
        ],
    },
    {
        word: "Electric blanket",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electric blanket <strong class='noun'> n. </strong> Blanket heated by an internal electric element.</p>",
            },
        ],
    },
    {
        word: "Electric chair",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electric chair <strong class='noun'> n. </strong> Electrified chair used for capital punishment.</p>",
            },
        ],
    },
    {
        word: "Electric eel",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electric eel <strong class='noun'> n. </strong> Eel-like fish able to give an electric shock.</p>",
            },
        ],
    },
    {
        word: "Electric eye",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electric eye <strong class='noun'> n. </strong> Colloq. Photoelectric cell operating a relay when a beam of light is broken.</p>",
            },
        ],
    },
    {
        word: "Electric fire",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electric fire <strong class='noun'> n. </strong> Electrically operated portable domestic heater.</p>",
            },
        ],
    },
    {
        word: "Electric guitar",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electric guitar <strong class='noun'> n. </strong> Guitar with a solid body and built-in pick-up rather than a soundbox.</p>",
            },
        ],
    },
    {
        word: "Electrician",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrician <strong class='noun'> n. </strong> Person who installs or maintains electrical equipment for a living.</p>",
            },
        ],
    },
    {
        word: "Electricity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electricity <strong class='noun'> n. </strong> 1 form of energy occurring in elementary particles (electrons, protons, etc.) And hence in larger bodies containing them. 2 science of electricity. 3 supply of electricity. 4 excitement.</p>",
            },
        ],
    },
    {
        word: "Electric shock",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electric shock <strong class='noun'> n. </strong> Effect of a sudden discharge of electricity through the body of a person etc.</p>",
            },
        ],
    },
    {
        word: "Electrify",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrify <strong class='verb'> v. </strong> (-ies, -ied) 1 charge with electricity. 2 convert to the use of electric power. 3 cause sudden excitement (news was electrifying). electrification n.</p>",
            },
        ],
    },
    {
        word: "Electro-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electro- <strong class='n_pl'> comb. </strong> Form of, by, or caused by electricity.</p>",
            },
        ],
    },
    {
        word: "Electrocardiogram",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrocardiogram <strong class='noun'> n. </strong> Record traced by an electrocardiograph. [german: related to *electro-]</p>",
            },
        ],
    },
    {
        word: "Electrocardiograph",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrocardiograph <strong class='noun'> n. </strong> Instrument recording the electric currents generated by a heartbeat.</p>",
            },
        ],
    },
    {
        word: "Electroconvulsive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electroconvulsive <strong class='adj'> adj. </strong> (of therapy) using convulsive response to electric shocks.</p>",
            },
        ],
    },
    {
        word: "Electrocute",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrocute <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> kill by electric shock. electrocution <strong class='noun'> n. </strong> [from *electro-, after execute]</p>",
            },
        ],
    },
    {
        word: "Electrode",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrode <strong class='noun'> n. </strong> Conductor through which electricity enters or leaves an electrolyte, gas, vacuum, etc. [from *electric, greek hodos way]</p>",
            },
        ],
    },
    {
        word: "Electrodynamics",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrodynamics <strong class='n_pl'> n.pl. </strong> (usu. Treated as sing.) The study of electricity in motion. electrodynamic adj.</p>",
            },
        ],
    },
    {
        word: "Electroencephalogram",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electroencephalogram <strong class='noun'> n. </strong> Record traced by an electroencephalograph. [german: related to *electro-]</p>",
            },
        ],
    },
    {
        word: "Electroencephalograph",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electroencephalograph <strong class='noun'> n. </strong> Instrument that records the electrical activity of the brain.</p>",
            },
        ],
    },
    {
        word: "Electrolyse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrolyse <strong class='verb'> v. </strong> (us -yze) (-sing, us -zing) subject to or treat by electrolysis.</p>",
            },
        ],
    },
    {
        word: "Electrolysis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrolysis <strong class='noun'> n. </strong> 1 chemical decomposition by electric action. 2 destruction of tumours, hair-roots, etc., by this process. electrolytic adj.</p>",
            },
        ],
    },
    {
        word: "Electrolyte",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrolyte <strong class='noun'> n. </strong> 1 solution able to conduct electricity, esp. In an electric cell or battery. 2 substance that can dissolve to produce this.</p>",
            },
        ],
    },
    {
        word: "Electrolyze",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrolyze <strong class='verb'> v. </strong> (brit. -yse) (-zing, brit. -sing) subject to or treat by electrolysis.</p>",
            },
        ],
    },
    {
        word: "Electromagnet",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electromagnet <strong class='noun'> n. </strong> Soft metal core made into a magnet by passing an electric current through a coil surrounding it.</p>",
            },
        ],
    },
    {
        word: "Electromagnetic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electromagnetic <strong class='adj'> adj. </strong> Having both electrical and magnetic properties. electromagnetically adv.</p>",
            },
        ],
    },
    {
        word: "Electromagnetism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electromagnetism <strong class='noun'> n. </strong> 1 magnetic forces produced by electricity. 2 the study of these.</p>",
            },
        ],
    },
    {
        word: "Electromotive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electromotive <strong class='adj'> adj. </strong> Producing or tending to produce an electric current.</p>",
            },
        ],
    },
    {
        word: "Electromotive force",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electromotive force <strong class='noun'> n. </strong> Force set up in an electric circuit by a difference in potential.</p>",
            },
        ],
    },
    {
        word: "Electron",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electron <strong class='noun'> n. </strong> Stable elementary particle with a charge of negative electricity, found in all atoms and acting as the primary carrier of electricity in solids.</p>",
            },
        ],
    },
    {
        word: "Electronic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electronic <strong class='adj'> adj. </strong> 1 a produced by or involving the flow of electrons. B of electrons or electronics. 2 (of music) produced by electronic means and usu. Recorded on tape. electronically adv.</p>",
            },
        ],
    },
    {
        word: "Electronic mail",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electronic mail <strong class='noun'> n. </strong> The sending of messages by a computer system; such messages.</p>",
            },
        ],
    },
    {
        word: "Electronics",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electronics <strong class='n_pl'> n.pl. </strong> (treated as sing.) Science of the movement of electrons in a vacuum, gas, semiconductor, etc., esp. In devices in which the flow is controlled and utilized.</p>",
            },
        ],
    },
    {
        word: "Electronic tagging",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electronic tagging <strong class='noun'> n. </strong> The attaching of electronic markers to people or goods, enabling them to be tracked down.</p>",
            },
        ],
    },
    {
        word: "Electron lens",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electron lens <strong class='noun'> n. </strong> Device for focusing a stream of electrons by means of electric or magnetic fields.</p>",
            },
        ],
    },
    {
        word: "Electron microscope",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electron microscope <strong class='noun'> n. </strong> Microscope with high magnification and resolution, using electron beams instead of light.</p>",
            },
        ],
    },
    {
        word: "Electronvolt",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electronvolt <strong class='noun'> n. </strong> A unit of energy, the amount gained by an electron when accelerated through a potential difference of one volt.</p>",
            },
        ],
    },
    {
        word: "Electroplate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electroplate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> coat with a thin layer of chromium, silver, etc., by electrolysis. <strong class='noun'> —n. </strong> Electroplated articles.</p>",
            },
        ],
    },
    {
        word: "Electroscope",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electroscope <strong class='noun'> n. </strong> Instrument for detecting and measuring electricity, esp. As an indication of the ionization of air by radioactivity. electroscopic adj.</p>",
            },
        ],
    },
    {
        word: "Electro-shock",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electro-shock <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of therapy) by means of electric shocks.</p>",
            },
        ],
    },
    {
        word: "Electrostatics",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrostatics <strong class='n_pl'> n.pl. </strong> (treated as sing.) The study of electricity at rest.</p>",
            },
        ],
    },
    {
        word: "Electrotechnology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrotechnology <strong class='noun'> n. </strong> Science of the application of electricity in technology.</p>",
            },
        ],
    },
    {
        word: "Electrotherapy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrotherapy <strong class='noun'> n. </strong> Treatment of diseases by use of electricity.</p>",
            },
        ],
    },
    {
        word: "Elegant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elegant <strong class='adj'> adj. </strong> 1 tasteful, refined, graceful. 2 ingeniously simple. elegance <strong class='noun'> n. </strong> Elegantly <strong class='adv'> adv. </strong> [latin: related to *elect]</p>",
            },
        ],
    },
    {
        word: "Elegiac",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elegiac <strong class='adj'> —adj.</strong> 1 used for elegies. 2 mournful. <strong class='noun'> —n. </strong> (in pl.) Elegiac verses. elegiacally adv.</p>",
            },
        ],
    },
    {
        word: "Elegy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elegy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 sorrowful poem or song, esp. For the dead. 2 poem in elegiac metre. [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Element",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Element <strong class='noun'> n. </strong> 1 component part; contributing factor. 2 any of the substances that cannot be resolved by chemical means into simpler substances. 3 a any of the four substances (earth, water, air, and fire) in ancient and medieval philosophy. B a being's natural abode or environment. 4 electr. Wire that heats up in an electric heater, kettle, etc. 5 (in pl.) Atmospheric agencies, esp. Wind and storm. 6 (in pl.) Rudiments of learning or of an art etc. 7 (in pl.) Bread and wine of the eucharist. in one's element in one's preferred situation, doing what one does well and enjoys. [french from latin]</p>",
            },
        ],
    },
    {
        word: "Elemental",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elemental <strong class='adj'> adj. </strong> 1 of or like the elements or the forces of nature; powerful. 2 essential, basic.</p>",
            },
        ],
    },
    {
        word: "Elementary",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elementary <strong class='adj'> adj. </strong> 1 dealing with the simplest facts of a subject. 2 unanalysable.</p>",
            },
        ],
    },
    {
        word: "Elementary particle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elementary particle <strong class='noun'> n. </strong> Physics subatomic particle, esp. One not known to consist of simpler ones.</p>",
            },
        ],
    },
    {
        word: "Elephant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elephant <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> largest living land animal, with a trunk and ivory tusks. [greek elephas]</p>",
            },
        ],
    },
    {
        word: "Elephantiasis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elephantiasis <strong class='noun'> n. </strong> Skin disease causing gross enlargement of limbs etc.</p>",
            },
        ],
    },
    {
        word: "Elephantine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elephantine <strong class='adj'> adj. </strong> 1 of elephants. 2 a huge. B clumsy.</p>",
            },
        ],
    },
    {
        word: "Elevate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 raise, lift up. 2 exalt in rank etc. 3 (usu. As elevated adj.) Raise morally or intellectually. [latin levo lift]</p>",
            },
        ],
    },
    {
        word: "Elevation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevation <strong class='noun'> n. </strong> 1 a elevating or being elevated. B angle with the horizontal. C height above sea level etc. D high position. 2 drawing or diagram showing one side of a building.</p>",
            },
        ],
    },
    {
        word: "Elevator",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevator <strong class='noun'> n. </strong> 1 us lift. 2 movable part of a tailplane for changing an aircraft's altitude. 3 hoisting machine.</p>",
            },
        ],
    },
    {
        word: "Eleven",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eleven <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 one more than ten. 2 symbol for this (11, xi, xi). 3 size etc. Denoted by eleven. 4 team of eleven players at cricket, football, etc. 5 eleven o'clock. [old english]</p>",
            },
        ],
    },
    {
        word: "Elevenfold",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevenfold <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> 1 eleven times as much or as many. 2 consisting of eleven parts.</p>",
            },
        ],
    },
    {
        word: "Eleven-plus",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eleven-plus <strong class='noun'> n. </strong> Esp. Hist. Examination taken at age 11–12 to determine the type of secondary school a child would enter.</p>",
            },
        ],
    },
    {
        word: "Elevenses",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevenses <strong class='noun'> n. </strong> Colloq. Light refreshment taken at about 11 a.m.</p>",
            },
        ],
    },
    {
        word: "Eleventh",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eleventh <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 next after tenth. 2 each of eleven equal parts of a thing. eleventh hour last possible moment.</p>",
            },
        ],
    },
    {
        word: "Elf",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elf <strong class='noun'> n. </strong> (pl. Elves) mythological being, esp. One that is small and mischievous. elfish adj. Elvish adj. [old english]</p>",
            },
        ],
    },
    {
        word: "Elfin",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elfin <strong class='adj'> adj. </strong> Of elves; elflike.</p>",
            },
        ],
    },
    {
        word: "Elicit",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elicit <strong class='verb'> v. </strong> (-t-) draw out (facts, a response, etc.), esp. With difficulty. [latin elicio]</p>",
            },
        ],
    },
    {
        word: "Elide",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elide <strong class='verb'> v. </strong> (-ding) omit (a vowel or syllable) in pronunciation. [latin elido elis- crush out]</p>",
            },
        ],
    },
    {
        word: "Eligible",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eligible <strong class='adj'> adj. </strong> 1 (often foll. By for) fit or entitled to be chosen (eligible for a rebate). 2 desirable or suitable, esp. For marriage. eligibility <strong class='noun'> n. </strong> [latin: related to *elect]</p>",
            },
        ],
    },
    {
        word: "Electroplate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electroplate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> coat with a thin layer of chromium, silver, etc., by electrolysis. <strong class='noun'> —n. </strong> Electroplated articles.</p>",
            },
        ],
    },
    {
        word: "Electroscope",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electroscope <strong class='noun'> n. </strong> Instrument for detecting and measuring electricity, esp. As an indication of the ionization of air by radioactivity. electroscopic adj.</p>",
            },
        ],
    },
    {
        word: "Electro-shock",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electro-shock <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of therapy) by means of electric shocks.</p>",
            },
        ],
    },
    {
        word: "Electrostatics",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrostatics <strong class='n_pl'> n.pl. </strong> (treated as sing.) The study of electricity at rest.</p>",
            },
        ],
    },
    {
        word: "Electrotechnology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrotechnology <strong class='noun'> n. </strong> Science of the application of electricity in technology.</p>",
            },
        ],
    },
    {
        word: "Electrotherapy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Electrotherapy <strong class='noun'> n. </strong> Treatment of diseases by use of electricity.</p>",
            },
        ],
    },
    {
        word: "Elegant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elegant <strong class='adj'> adj. </strong> 1 tasteful, refined, graceful. 2 ingeniously simple. elegance <strong class='noun'> n. </strong> Elegantly <strong class='adv'> adv. </strong> [latin: related to *elect]</p>",
            },
        ],
    },
    {
        word: "Elegiac",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elegiac <strong class='adj'> —adj.</strong> 1 used for elegies. 2 mournful. <strong class='noun'> —n. </strong> (in pl.) Elegiac verses. elegiacally adv.</p>",
            },
        ],
    },
    {
        word: "Elegy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elegy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 sorrowful poem or song, esp. For the dead. 2 poem in elegiac metre. [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Element",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Element <strong class='noun'> n. </strong> 1 component part; contributing factor. 2 any of the substances that cannot be resolved by chemical means into simpler substances. 3 a any of the four substances (earth, water, air, and fire) in ancient and medieval philosophy. B a being's natural abode or environment. 4 electr. Wire that heats up in an electric heater, kettle, etc. 5 (in pl.) Atmospheric agencies, esp. Wind and storm. 6 (in pl.) Rudiments of learning or of an art etc. 7 (in pl.) Bread and wine of the eucharist. in one's element in one's preferred situation, doing what one does well and enjoys. [french from latin]</p>",
            },
        ],
    },
    {
        word: "Elemental",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elemental <strong class='adj'> adj. </strong> 1 of or like the elements or the forces of nature; powerful. 2 essential, basic.</p>",
            },
        ],
    },
    {
        word: "Elementary",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elementary <strong class='adj'> adj. </strong> 1 dealing with the simplest facts of a subject. 2 unanalysable.</p>",
            },
        ],
    },
    {
        word: "Elementary particle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elementary particle <strong class='noun'> n. </strong> Physics subatomic particle, esp. One not known to consist of simpler ones.</p>",
            },
        ],
    },
    {
        word: "Elephant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elephant <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> largest living land animal, with a trunk and ivory tusks. [greek elephas]</p>",
            },
        ],
    },
    {
        word: "Elephantiasis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elephantiasis <strong class='noun'> n. </strong> Skin disease causing gross enlargement of limbs etc.</p>",
            },
        ],
    },
    {
        word: "Elephantine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elephantine <strong class='adj'> adj. </strong> 1 of elephants. 2 a huge. B clumsy.</p>",
            },
        ],
    },
    {
        word: "Elevate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 raise, lift up. 2 exalt in rank etc. 3 (usu. As elevated adj.) Raise morally or intellectually. [latin levo lift]</p>",
            },
        ],
    },
    {
        word: "Elevation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevation <strong class='noun'> n. </strong> 1 a elevating or being elevated. B angle with the horizontal. C height above sea level etc. D high position. 2 drawing or diagram showing one side of a building.</p>",
            },
        ],
    },
    {
        word: "Elevator",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevator <strong class='noun'> n. </strong> 1 us lift. 2 movable part of a tailplane for changing an aircraft's altitude. 3 hoisting machine.</p>",
            },
        ],
    },
    {
        word: "Eleven",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eleven <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 one more than ten. 2 symbol for this (11, xi, xi). 3 size etc. Denoted by eleven. 4 team of eleven players at cricket, football, etc. 5 eleven o'clock. [old english]</p>",
            },
        ],
    },
    {
        word: "Elevenfold",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevenfold <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> 1 eleven times as much or as many. 2 consisting of eleven parts.</p>",
            },
        ],
    },
    {
        word: "Eleven-plus",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eleven-plus <strong class='noun'> n. </strong> Esp. Hist. Examination taken at age 11–12 to determine the type of secondary school a child would enter.</p>",
            },
        ],
    },
    {
        word: "Elevenses",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elevenses <strong class='noun'> n. </strong> Colloq. Light refreshment taken at about 11 a.m.</p>",
            },
        ],
    },
    {
        word: "Eleventh",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eleventh <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 next after tenth. 2 each of eleven equal parts of a thing. eleventh hour last possible moment.</p>",
            },
        ],
    },
    {
        word: "Elf",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elf <strong class='noun'> n. </strong> (pl. Elves) mythological being, esp. One that is small and mischievous. elfish adj. Elvish adj. [old english]</p>",
            },
        ],
    },
    {
        word: "Elfin",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elfin <strong class='adj'> adj. </strong> Of elves; elflike.</p>",
            },
        ],
    },
    {
        word: "Elicit",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elicit <strong class='verb'> v. </strong> (-t-) draw out (facts, a response, etc.), esp. With difficulty. [latin elicio]</p>",
            },
        ],
    },
    {
        word: "Elide",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elide <strong class='verb'> v. </strong> (-ding) omit (a vowel or syllable) in pronunciation. [latin elido elis- crush out]</p>",
            },
        ],
    },
    {
        word: "Eligible",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eligible <strong class='adj'> adj. </strong> 1 (often foll. By for) fit or entitled to be chosen (eligible for a rebate). 2 desirable or suitable, esp. For marriage. eligibility <strong class='noun'> n. </strong> [latin: related to *elect]</p>",
            },
        ],
    },
    {
        word: "Eliminate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eliminate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 remove, get rid of. 2 exclude from consideration. 3 exclude from a further stage of a competition through defeat etc. elimination <strong class='noun'> n. </strong> Eliminator <strong class='noun'> n. </strong> [latin limen limin- threshold] <strong class='ting'> (-ting) </strong> 1 remove, get rid of. 2 exclude from consideration. 3 exclude from a further stage of a competition through defeat etc. elimination <strong class='noun'> n. </strong> Eliminator <strong class='noun'> n. </strong> [latin limen limin- threshold]</p>",
            },
        ],
    },
    {
        word: "Elision",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elision <strong class='noun'> n. </strong> Omission of a vowel or syllable in pronunciation (e.g. In we'll). [latin: related to *elide]</p>",
            },
        ],
    },
    {
        word: "Élite",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Élite <strong class='noun'> n. </strong> 1 (prec. By the) the best (of a group). 2 select group or class. 3 a size of letters in typewriting (12 per inch). [french: related to *elect]</p>",
            },
        ],
    },
    {
        word: "Élitism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Élitism <strong class='noun'> n. </strong> Recourse to or advocacy of leadership or dominance by a select group. élitist <strong class='noun'> n. </strong> & adj.</p>",
            },
        ],
    },
    {
        word: "Elixir",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elixir <strong class='noun'> n. </strong> 1 a alchemist's preparation supposedly able to change metals into gold or (in full elixir of life) to prolong life indefinitely. B remedy for all ills. 2 aromatic medicinal drug. [latin from arabic]</p>",
            },
        ],
    },
    {
        word: "Elizabethan",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elizabethan <strong class='adj'> —adj.</strong> Of the time of queen elizabeth i or ii. <strong class='noun'> —n. </strong> Person of this time.</p>",
            },
        ],
    },
    {
        word: "Elk",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elk <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> large deer of northern parts of europe, <strong class='noun'> n. </strong> America, and asia. [old english]</p>",
            },
        ],
    },
    {
        word: "Ell",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ell <strong class='noun'> n. </strong> Hist. Measure = 45 in. [old english, = forearm]</p>",
            },
        ],
    },
    {
        word: "Ellipse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ellipse <strong class='noun'> n. </strong> Regular oval, resulting when a cone is cut obliquely by a plane. [greek elleipsis deficit]</p>",
            },
        ],
    },
    {
        word: "Ellipsis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ellipsis <strong class='noun'> n. </strong> (pl. Ellipses) 1 omission of words needed to complete a construction or sense. 2 set of three dots etc. Indicating omission.</p>",
            },
        ],
    },
    {
        word: "Ellipsoid",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ellipsoid <strong class='noun'> n. </strong> Solid of which all the plane sections through one axis are circles and all the other plane sections are ellipses.</p>",
            },
        ],
    },
    {
        word: "Elliptic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elliptic <strong class='adj'> adj. </strong> (also elliptical) of or in the form of an ellipse. elliptically adv.</p>",
            },
        ],
    },
    {
        word: "Elm",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elm <strong class='noun'> n. </strong> 1 tree with rough serrated leaves. 2 its wood. [old english]</p>",
            },
        ],
    },
    {
        word: "Elocution",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elocution <strong class='noun'> n. </strong> Art of clear and expressive speech. [latin loquor speak]</p>",
            },
        ],
    },
    {
        word: "Elongate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elongate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> lengthen, extend. elongation <strong class='noun'> n. </strong> [latin longus long]</p>",
            },
        ],
    },
    {
        word: "Elope",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elope <strong class='verb'> v. </strong> (-ping) run away to marry secretly. elopement <strong class='noun'> n. </strong> [anglo-french]</p>",
            },
        ],
    },
    {
        word: "Eloquence",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eloquence <strong class='noun'> n. </strong> Fluent and effective use of language. [latin loquor speak]</p>",
            },
        ],
    },
    {
        word: "Eloquent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eloquent <strong class='adj'> adj. </strong> 1 having eloquence. 2 (often foll. By of) expressive. eloquently adv.</p>",
            },
        ],
    },
    {
        word: "Else",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Else <strong class='adv'> adv. </strong> 1 (prec. By indefinite or interrog. <strong class='pron'> Pron. </strong>) Besides (someone else; nowhere else; who else?). 2 instead (what else could i say?). 3 otherwise; if not (run, (or) else you will be late). or else see *or1. [old english]</p>",
            },
        ],
    },
    {
        word: "Elsewhere",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elsewhere <strong class='adv'> adv. </strong> In or to some other place.</p>",
            },
        ],
    },
    {
        word: "Elucidate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elucidate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> throw light on; explain. elucidation <strong class='noun'> n. </strong> Elucidatory adj. [latin: related to *lucid]</p>",
            },
        ],
    },
    {
        word: "Elude",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elude <strong class='verb'> v. </strong> (-ding) 1 escape adroitly from (danger, pursuit, etc.). 2 avoid compliance with (a law etc.) Or fulfilment of (an obligation). 3 baffle (a person or memory etc.). elusion <strong class='noun'> n. </strong> [latin ludo play]</p>",
            },
        ],
    },
    {
        word: "Elusive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elusive <strong class='adj'> adj. </strong> 1 difficult to find or catch. 2 difficult to remember. 3 avoiding the point raised. elusiveness n.</p>",
            },
        ],
    },
    {
        word: "Elver",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elver <strong class='noun'> n. </strong> Young eel. [from *eel, *fare]</p>",
            },
        ],
    },
    {
        word: "Elves",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>Elves <strong class='pl'> pl. </strong> Of *elf.</p>",
            },
        ],
    },
    {
        word: "Elvish",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>Elvish <strong class='see'> see *</strong> elf.</p>",
            },
        ],
    },
    {
        word: "Elysium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Elysium <strong class='noun'> n. </strong> 1 (also elysian fields) (in greek mythology) abode of the blessed after death. 2 place of ideal happiness. elysian adj. [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Em",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Em <strong class='noun'> n. </strong> Printing unit of measurement equal to the width of an m. [name of the letter m]</p>",
            },
        ],
    },
    {
        word: "Em-1 ,2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Em-1 ,2 <strong class='archaic_it_was'>see *en-1,2</strong>.</p>",
            },
        ],
    },
    {
        word: "'Em",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>'Em <strong class='pron'> pron. </strong> Colloq. Them.</p>",
            },
        ],
    },
    {
        word: "Emaciate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emaciate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (esp. As emaciated adj.) Make abnormally thin or feeble. emaciation <strong class='noun'> n. </strong> [latin macies leanness]</p>",
            },
        ],
    },
    {
        word: "Email",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Email <strong class='noun'> n. </strong> (also e-mail) = *electronic mail.</p>",
            },
        ],
    },
    {
        word: "Emanate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emanate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (usu. Foll. By from) issue or originate (from a source). emanation <strong class='noun'> n. </strong> [latin mano flow]</p>",
            },
        ],
    },
    {
        word: "Emancipate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emancipate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 free from social or political restraint. 2 (usu. As emancipated adj.) Free from the inhibitions of moral or social conventions. 3 free from slavery. emancipation <strong class='noun'> n. </strong> Emancipatory adj. [latin, = free from possession, from manus hand, capio take]</p>",
            },
        ],
    },
    {
        word: "Emasculate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emasculate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> 1 deprive of force or vigour. 2 castrate. <strong class='adj'> —adj.</strong> 1 deprived of force. 2 castrated. 3 effeminate. emasculation <strong class='noun'> n. </strong> [latin: related to *male]</p>",
            },
        ],
    },
    {
        word: "Embalm",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embalm <strong class='verb'> v. </strong> 1 preserve (a corpse) from decay. 2 preserve from oblivion. 3 make fragrant. embalmment <strong class='noun'> n. </strong> [french: related to *balm]</p>",
            },
        ],
    },
    {
        word: "Embankment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embankment <strong class='noun'> n. </strong> Bank constructed to keep back water or carry a road, railway, etc.</p>",
            },
        ],
    },
    {
        word: "Embargo",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embargo <strong class='noun'> —n. </strong> (pl. -es) 1 order forbidding foreign ships to enter, or any ships to leave, a country's ports. 2 official suspension of an activity. —v. (-es, -ed) place under embargo. [spanish: related to *bar1]</p>",
            },
        ],
    },
    {
        word: "Embark",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embark <strong class='verb'> v. </strong> 1 (often foll. By for) put or go on board a ship or aircraft (to a destination). 2 (foll. By on, in) begin an enterprise. embarkation <strong class='noun'> n. </strong> (in sense 1). [french: related to *barque]</p>",
            },
        ],
    },
    {
        word: "Embarrass",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embarrass <strong class='verb'> v. </strong> 1 make (a person) feel awkward or ashamed. 2 (as embarrassed adj.) Encumbered with debts. 3 encumber. embarrassment <strong class='noun'> n. </strong> [italian imbarrare bar in]</p>",
            },
        ],
    },
    {
        word: "Embassy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embassy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a residence or offices of an ambassador. B ambassador and staff. 2 deputation to a foreign government. [french: related to *ambassador]</p>",
            },
        ],
    },
    {
        word: "Embattled",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embattled <strong class='adj'> adj. </strong> 1 prepared or arrayed for battle. 2 fortified with battlements. 3 under heavy attack or in trying circumstances.</p>",
            },
        ],
    },
    {
        word: "Embed",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embed <strong class='verb'> v. </strong> (also imbed) (-dd-) (esp. As embedded adj.) Fix firmly in a surrounding mass.</p>",
            },
        ],
    },
    {
        word: "Embellish",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embellish <strong class='verb'> v. </strong> 1 beautify, adorn. 2 enhance with fictitious additions. embellishment <strong class='noun'> n. </strong> [french bel, *beau]</p>",
            },
        ],
    },
    {
        word: "Ember",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ember <strong class='noun'> n. </strong> (usu. In pl.) Small piece of glowing coal etc. In a dying fire. [old english]</p>",
            },
        ],
    },
    {
        word: "Ember days",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ember days <strong class='n_pl'> n.pl. </strong> Days of fasting and prayer in the christian church, associated with ordinations. [old english]</p>",
            },
        ],
    },
    {
        word: "Embezzle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embezzle <strong class='verb'> v. </strong> (-ling) divert (money etc.) Fraudulently to one's own use. embezzlement <strong class='noun'> n. </strong> Embezzler <strong class='noun'> n. </strong> [anglo-french]</p>",
            },
        ],
    },
    {
        word: "Embitter",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embitter <strong class='verb'> v. </strong> Arouse bitter feelings in. embitterment n.</p>",
            },
        ],
    },
    {
        word: "Emblazon",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emblazon <strong class='verb'> v. </strong> 1 portray or adorn conspicuously. 2 adorn (a heraldic shield). emblazonment n.</p>",
            },
        ],
    },
    {
        word: "Emblem",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emblem <strong class='noun'> n. </strong> 1 symbol. 2 (foll. By of) type, embodiment (the very emblem of courage). 3 heraldic or representative device. emblematic adj. [greek, = insertion]</p>",
            },
        ],
    },
    {
        word: "Embody",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embody <strong class='verb'> v. </strong> (-ies, -ied) 1 make (an idea etc.) Actual or discernible. 2 (of a thing) be a tangible expression of. 3 include, comprise. embodiment n.</p>",
            },
        ],
    },
    {
        word: "Embolden",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embolden <strong class='verb'> v. </strong> Make bold; encourage.</p>",
            },
        ],
    },
    {
        word: "Embolism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embolism <strong class='noun'> n. </strong> Obstruction of an artery by a clot, air-bubble, etc. [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Embolus",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embolus <strong class='noun'> n. </strong> (pl. -li) object causing an embolism.</p>",
            },
        ],
    },
    {
        word: "Emboss",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emboss <strong class='verb'> v. </strong> Carve or decorate with a design in relief. embossment <strong class='noun'> n. </strong> [related to *boss2]</p>",
            },
        ],
    },
    {
        word: "Embouchure",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embouchure <strong class='noun'> n. </strong> Way of applying the mouth to the mouthpiece of a musical instrument. [french: related to *en-1, bouche mouth]</p>",
            },
        ],
    },
    {
        word: "Embrace",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embrace <strong class='noun'> —v. </strong> (-cing) 1 a hold closely in the arms. B (absol., of two people) embrace each other. 2 clasp, enclose. 3 accept eagerly (an offer etc.). 4 adopt (a cause, idea, etc.). 5 include, comprise. 6 take in with the eye or mind. <strong class='noun'> —n. </strong> Act of embracing, clasp. embraceable adj. [latin: related to *brace]</p>",
            },
        ],
    },
    {
        word: "Embrasure",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embrasure <strong class='noun'> n. </strong> 1 bevelling of a wall at the sides of a window etc. 2 opening in a parapet for a gun etc. embrasured adj. [french embraser splay]</p>",
            },
        ],
    },
    {
        word: "Embrocation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embrocation <strong class='noun'> n. </strong> Liquid for rubbing on the body to relieve muscular pain. [greek embrokhe lotion]</p>",
            },
        ],
    },
    {
        word: "Embroider",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embroider <strong class='verb'> v. </strong> 1 decorate (cloth etc.) With needlework. 2 embellish (a narrative). embroiderer <strong class='noun'> n. </strong> [anglo-french from germanic]</p>",
            },
        ],
    },
    {
        word: "Embroidery",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embroidery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 art of embroidering. 2 embroidered work. 3 inessential ornament. 4 fictitious additions (to a story etc.).</p>",
            },
        ],
    },
    {
        word: "Embroil",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embroil <strong class='verb'> v. </strong> (often foll. By with) involve (a person etc.) In a conflict or difficulties. embroilment <strong class='noun'> n. </strong> [french brouiller mix]</p>",
            },
        ],
    },
    {
        word: "Embryo",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embryo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 a unborn or unhatched offspring. B human offspring in the first eight weeks from conception. 2 rudimentary plant in a seed. 3 thing in a rudimentary stage. 4 (attrib.) Undeveloped, immature. in embryo undeveloped. embryonic adj. [greek bruo grow]</p>",
            },
        ],
    },
    {
        word: "Embryology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Embryology <strong class='noun'> n. </strong> The study of embryos.</p>",
            },
        ],
    },
    {
        word: "Emend",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emend <strong class='verb'> v. </strong> Edit (a text etc.) To make corrections. emendation <strong class='noun'> n. </strong> [latin menda fault] <strong class='usage'> Usage:- </strong>see note at amend.</p>",
            },
        ],
    },
    {
        word: "Emerald",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emerald <strong class='noun'> —n. </strong> 1 bright-green gem. 2 colour of this. <strong class='adj'> —adj.</strong> Bright green. [greek smaragdos]</p>",
            },
        ],
    },
    {
        word: "Emerald green",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emerald green <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> (as adj. Often hyphenated) bright green.</p>",
            },
        ],
    },
    {
        word: "Emerald isle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emerald isle <strong class='noun'> n. </strong> Ireland.</p>",
            },
        ],
    },
    {
        word: "Emerge",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emerge <strong class='verb'> v. </strong> (-ging) 1 come up or out into view. 2 (of facts etc.) Become known, be revealed. 3 become recognized or prominent. 4 (of a question, difficulty, etc.) Become apparent. emergence <strong class='noun'> n. </strong> Emergent adj. [latin: related to *merge]</p>",
            },
        ],
    },
    {
        word: "Emergency",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emergency <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 sudden state of danger etc., requiring immediate action. 2 a condition requiring immediate treatment. B patient with this. 3 (attrib.) For use in an emergency. [medieval latin: related to *emerge]</p>",
            },
        ],
    },
    {
        word: "Emeritus",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emeritus <strong class='adj'> adj. </strong> Retired but retaining one's title as an honour (emeritus professor). [latin mereor earn]</p>",
            },
        ],
    },
    {
        word: "Emery",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emery <strong class='noun'> n. </strong> Coarse corundum for polishing metal etc. [greek smeris polishing powder]</p>",
            },
        ],
    },
    {
        word: "Emery-board",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emery-board <strong class='noun'> n. </strong> Emery-coated nail-file.</p>",
            },
        ],
    },
    {
        word: "Emetic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emetic <strong class='adj'> —adj.</strong> That causes vomiting. <strong class='noun'> —n. </strong> Emetic medicine. [greek emeo vomit]</p>",
            },
        ],
    },
    {
        word: "Emf",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emf <strong class='sing'> abbr. </strong> (also e.m.f.) Electromotive force.</p>",
            },
        ],
    },
    {
        word: "Emigrant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emigrant <strong class='noun'> —n. </strong> Person who emigrates. <strong class='adj'> —adj.</strong> Emigrating.</p>",
            },
        ],
    },
    {
        word: "Emigrate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emigrate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> leave one's own country to settle in another. emigration <strong class='noun'> n. </strong> [latin: related to *migrate]</p>",
            },
        ],
    },
    {
        word: "Émigré",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Émigré <strong class='noun'> n. </strong> Emigrant, esp. A political exile. [french]</p>",
            },
        ],
    },
    {
        word: "Eminence",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eminence <strong class='noun'> n. </strong> 1 distinction; recognized superiority. 2 piece of rising ground. 3 title used in addressing or referring to a cardinal (your eminence; his eminence). [latin: related to *eminent]</p>",
            },
        ],
    },
    {
        word: "Éminence grise",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Éminence grise <strong class='noun'> n. </strong> (pl. Éminences grises pronunc. Same) person who exercises power or influence without holding office. [french, = grey cardinal (orig. Of richelieu's secretary)]</p>",
            },
        ],
    },
    {
        word: "Eminent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eminent <strong class='adj'> adj. </strong> Distinguished, notable, outstanding. [latin emineo jut out]</p>",
            },
        ],
    },
    {
        word: "Emir",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emir <strong class='noun'> n. </strong> (also amir) title of various muslim rulers. [french from arabic 'amir]</p>",
            },
        ],
    },
    {
        word: "Emirate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emirate <strong class='noun'> n. </strong> Rank, domain, or reign of an emir.</p>",
            },
        ],
    },
    {
        word: "Emissary",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emissary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> person sent on a diplomatic mission. [latin: related to *emit]</p>",
            },
        ],
    },
    {
        word: "Emit",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emit <strong class='verb'> v. </strong> <strong class='adj'> (-tt-) </strong> give or send out (heat, light, a smell, sound, etc.); discharge. emission <strong class='noun'> n. </strong> [latin emitto emiss-]</p>",
            },
        ],
    },
    {
        word: "Emollient",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emollient <strong class='adj'> —adj.</strong> That softens or soothes the skin, feelings, etc. <strong class='noun'> —n. </strong> Emollient substance. [latin mollis soft]</p>",
            },
        ],
    },
    {
        word: "Emolument",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emolument <strong class='noun'> n. </strong> Fee from employment, salary. [latin]</p>",
            },
        ],
    },
    {
        word: "Emote",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emote <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> show excessive emotion.</p>",
            },
        ],
    },
    {
        word: "Emotion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emotion <strong class='noun'> n. </strong> 1 strong instinctive feeling such as love or fear. 2 emotional intensity or sensibility (spoke with emotion). [french: related to *motion]</p>",
            },
        ],
    },
    {
        word: "Emotional",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emotional <strong class='adj'> adj. </strong> 1 of or expressing emotions. 2 especially liable to emotion. 3 arousing emotion. emotionalism <strong class='noun'> n. </strong> Emotionally adv. <strong class='usage'> Usage:- </strong>see note at emotive.</p>",
            },
        ],
    },
    {
        word: "Emotive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emotive <strong class='adj'> adj. </strong> 1 arousing emotion. 2 of emotion. [latin: related to *motion] <strong class='usage'> Usage:- </strong>although the senses of emotive and emotional overlap, emotive is more common in the sense ‘arousing emotion’, as in an emotive issue, and is not used at all in sense 2 of emotional.</p>",
            },
        ],
    },
    {
        word: "Empanel",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empanel <strong class='verb'> v. </strong> (also impanel) (-ll-; us -l-) enter (a jury) on a panel.</p>",
            },
        ],
    },
    {
        word: "Empathize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empathize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) (usu. Foll. By with) exercise empathy.</p>",
            },
        ],
    },
    {
        word: "Empathy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empathy <strong class='noun'> n. </strong> Ability to identify with a person or object. empathetic adj. [as *pathos]</p>",
            },
        ],
    },
    {
        word: "Emperor",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emperor <strong class='noun'> n. </strong> Sovereign of an empire. [latin impero command]</p>",
            },
        ],
    },
    {
        word: "Emperor penguin",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emperor penguin <strong class='noun'> n. </strong> Largest known penguin.</p>",
            },
        ],
    },
    {
        word: "Emphasis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emphasis <strong class='noun'> n. </strong> (pl. Emphases) 1 importance or prominence attached to a thing (emphasis on economy). 2 stress laid on a word or syllable to make the meaning clear or show importance. 3 vigour or intensity of expression, feeling, etc. [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Emphasize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emphasize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) put emphasis on, stress.</p>",
            },
        ],
    },
    {
        word: "Emphatic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emphatic <strong class='adj'> adj. </strong> 1 forcibly expressive. 2 of words: a bearing the stress. B used to give emphasis. emphatically adv.</p>",
            },
        ],
    },
    {
        word: "Emphysema",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emphysema <strong class='noun'> n. </strong> Disease of the lungs causing breathlessness. [greek emphusao puff up]</p>",
            },
        ],
    },
    {
        word: "Empire",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empire <strong class='noun'> n. </strong> 1 large group of states or countries under a single authority. 2 supreme dominion. 3 large commercial organization etc. Owned or directed by one person. 4 (the empire) hist. The british empire. [latin imperium dominion]</p>",
            },
        ],
    },
    {
        word: "Empire-building",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empire-building <strong class='noun'> n. </strong> Purposeful accumulation of territory, authority, etc.</p>",
            },
        ],
    },
    {
        word: "Empirical",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empirical <strong class='adj'> adj. </strong> (also empiric) based on observation, experience, or experiment, not on theory. empirically <strong class='adv'> adv. </strong> Empiricism <strong class='noun'> n. </strong> Empiricist <strong class='noun'> n. </strong> [greek empeiria experience]</p>",
            },
        ],
    },
    {
        word: "Emplacement",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emplacement <strong class='noun'> n. </strong> 1 putting in position. 2 platform for guns. [french: related to *place]</p>",
            },
        ],
    },
    {
        word: "Employ",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Employ <strong class='noun'> —v. </strong> 1 use the services of (a person) in return for payment. 2 use (a thing, time, energy, etc.) To good effect. 3 keep (a person) occupied. <strong class='noun'> —n. </strong> (in phr. In the employ of) employed by. employable adj. Employer <strong class='noun'> n. </strong> [latin implicor be involved]</p>",
            },
        ],
    },
    {
        word: "Employee",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Employee <strong class='noun'> n. </strong> Person employed for wages.</p>",
            },
        ],
    },
    {
        word: "Employment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Employment <strong class='noun'> n. </strong> 1 employing or being employed. 2 person's trade or profession.</p>",
            },
        ],
    },
    {
        word: "Employment office",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Employment office <strong class='noun'> n. </strong> (formerly employment exchange) state-run employment agency.</p>",
            },
        ],
    },
    {
        word: "Emporium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emporium <strong class='noun'> n. </strong> (pl. -s or -ria) 1 large shop or store. 2 centre of commerce, market. [greek emporos merchant]</p>",
            },
        ],
    },
    {
        word: "Empower",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empower <strong class='verb'> v. </strong> Give authority to.</p>",
            },
        ],
    },
    {
        word: "Empress",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empress <strong class='noun'> n. </strong> 1 wife or widow of an emperor. 2 woman emperor. [french: related to *emperor]</p>",
            },
        ],
    },
    {
        word: "Empty",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empty <strong class='adj'> —adj.</strong> (-ier, -iest) 1 containing nothing. 2 (of a house etc.) Unoccupied or unfurnished. 3 (of a vehicle etc.) Without passengers etc. 4 a hollow, insincere (empty threats). B without purpose (an empty existence). C vacuous (an empty head). 5 <strong class='colloq'> colloq. </strong> Hungry. —v. (-ies, -ied) 1 remove the contents of. 2 (often foll. By into) transfer (contents). 3 become empty. 4 (of a river) discharge itself. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> <strong class='colloq'> colloq. </strong> Empty bottle etc. emptiness <strong class='noun'> n. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Empty-handed",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empty-handed <strong class='adj'> adj. </strong> (usu. Predic.) 1 bringing or taking nothing. 2 having achieved nothing.</p>",
            },
        ],
    },
    {
        word: "Empty-headed",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empty-headed <strong class='adj'> adj. </strong> Foolish; lacking sense.</p>",
            },
        ],
    },
    {
        word: "Empyrean",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Empyrean <strong class='noun'> —n. </strong> The highest heaven, as the sphere of fire or abode of god. <strong class='adj'> —adj.</strong> Of the empyrean. empyreal adj. [greek pur fire]</p>",
            },
        ],
    },
    {
        word: "Ems",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ems <strong class='sing'> abbr. </strong> European monetary system.</p>",
            },
        ],
    },
    {
        word: "Emu",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emu <strong class='sing'> abbr. </strong> Economic and monetary union; european monetary union.</p>",
            },
        ],
    },
    {
        word: "Emu",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emu <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> large flightless australian bird. [portuguese]</p>",
            },
        ],
    },
    {
        word: "Emulate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emulate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 try to equal or excel. 2 imitate. emulation <strong class='noun'> n. </strong> Emulative adj. Emulator <strong class='noun'> n. </strong> [latin aemulus rival]</p>",
            },
        ],
    },
    {
        word: "Emulsify",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emulsify <strong class='verb'> v. </strong> (-ies, -ied) convert into an emulsion. emulsification <strong class='noun'> n. </strong> Emulsifier n.</p>",
            },
        ],
    },
    {
        word: "Emulsion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emulsion <strong class='noun'> n. </strong> 1 fine dispersion of one liquid in another, esp. As paint, medicine, etc. 2 mixture of a silver compound in gelatin etc. For coating photographic plate or film. 3 emulsion paint. [latin mulgeo milk]</p>",
            },
        ],
    },
    {
        word: "Emulsion paint",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Emulsion paint <strong class='noun'> n. </strong> Water-thinned paint.</p>",
            },
        ],
    },
    {
        word: "En",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>En <strong class='noun'> n. </strong> Printing unit of measurement equal to half an em. [name of the letter n]</p>",
            },
        ],
    },
    {
        word: "En-1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>En-1 <strong class='prefix '> prefix </strong> (also em- before b, p) forming verbs, = *in-2: 1 from nouns, meaning ‘put into or on’ (engulf; entrust; embed). 2 from nouns or adjectives, meaning ‘bring into the condition of’ (enslave); often with the suffix -en (enlighten). 3 from verbs: a in the sense ‘in, into, on’ (enfold). B as an intensifier (entangle). [french en-, latin in-]</p>",
            },
        ],
    },
    {
        word: "En-2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>En-2 <strong class='prefix '> prefix </strong> (also em- before b, p) in, inside (energy; enthusiasm). [greek]</p>",
            },
        ],
    },
    {
        word: "-en",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-en <strong class='slang_v'> suffix </strong>forming verbs: 1 from adjectives, usu. Meaning ‘make or become so or more so’ (deepen; moisten). 2 from nouns (happen; strengthen). [old english]</p>",
            },
        ],
    },
    {
        word: "Enable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enable <strong class='verb'> v. </strong> (-ling) 1 (foll. By to + infin.) Give (a person etc.) The means or authority. 2 make possible. 3 esp. Computing make (a device) operational; switch on.</p>",
            },
        ],
    },
    {
        word: "Enact",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enact <strong class='verb'> v. </strong> 1 a ordain, decree. B make (a bill etc.) Law. 2 play (a part on stage or in life). enactive adj.</p>",
            },
        ],
    },
    {
        word: "Enactment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enactment <strong class='noun'> n. </strong> 1 law enacted. 2 process of enacting.</p>",
            },
        ],
    },
    {
        word: "Enamel",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enamel <strong class='noun'> —n. </strong> 1 glasslike opaque ornamental or preservative coating on metal etc. 2 a smooth hard coating. B a kind of hard gloss paint. C cosmetic simulating this, esp. Nail varnish. 3 hard coating of a tooth. 4 painting done in enamel. —v. (-ll-; us -l-) inlay, coat, or portray with enamel.</p>",
            },
        ],
    },
    {
        word: "Enamor",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enamor <strong class='verb'> v. </strong> (brit. Enamour) (usu. In passive; foll. By of) inspire with love or delight. [french amour love]</p>",
            },
        ],
    },
    {
        word: "Enamour",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enamour <strong class='verb'> v. </strong> (us enamor) (usu. In passive; foll. By of) inspire with love or delight. [french amour love]</p>",
            },
        ],
    },
    {
        word: "En bloc",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>En bloc <strong class='adv'> adv. </strong> In a block; all at the same time. [french]</p>",
            },
        ],
    },
    {
        word: "Encamp",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encamp <strong class='verb'> v. </strong> Settle in a (esp. Military) camp. encampment n.</p>",
            },
        ],
    },
    {
        word: "Encapsulate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encapsulate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 enclose in or as in a capsule. 2 express briefly, summarize. encapsulation <strong class='noun'> n. </strong> [related to *capsule]</p>",
            },
        ],
    },
    {
        word: "Encase",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encase <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> enclose in or as in a case. encasement n.</p>",
            },
        ],
    },
    {
        word: "Encaustic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encaustic <strong class='adj'> —adj.</strong> (of painting etc.) Using pigments mixed with hot wax, which are burned in as an inlay. <strong class='noun'> —n. </strong> 1 art of encaustic painting. 2 product of this. [greek: related to *caustic]</p>",
            },
        ],
    },
    {
        word: "-ence",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ence <strong class='slang_v'> suffix </strong>forming nouns expressing: 1 a quality or state or an instance of this (patience; an impertinence). 2 an action (reference). [french -ence, latin -erie]</p>",
            },
        ],
    },
    {
        word: "Encephalitis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encephalitis <strong class='noun'> n. </strong> Inflammation of the brain. [greek egkephalos brain]</p>",
            },
        ],
    },
    {
        word: "Encephalogram",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encephalogram <strong class='noun'> n. </strong> = *electroencephalo <strong class='gram'>gram.</strong> </p>",
            },
        ],
    },
    {
        word: "Encephalograph",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encephalograph <strong class='noun'> n. </strong> = *electroencephalograph.</p>",
            },
        ],
    },
    {
        word: "Enchant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enchant <strong class='verb'> v. </strong> 1 charm, delight. 2 bewitch. enchantedly <strong class='adv'> adv. </strong> Enchanting adj. Enchantingly <strong class='adv'> adv. </strong> Enchantment n.</p>",
            },
        ],
    },
    {
        word: "Enchanter",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enchanter <strong class='noun'> n. </strong> (fem. Enchantress) person who enchants, esp. By using magic.</p>",
            },
        ],
    },
    {
        word: "Encircle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encircle <strong class='verb'> v. </strong> (-ling) 1 surround. 2 form a circle round. encirclement n.</p>",
            },
        ],
    },
    {
        word: "Enclave",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enclave <strong class='noun'> n. </strong> Territory of one state surrounded by that of another. [latin clavis key]</p>",
            },
        ],
    },
    {
        word: "Enclose",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enclose <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 a surround with a wall, fence, etc. B shut in. 2 put in a receptacle (esp. In an envelope with a letter). 3 (usu. As enclosed adj.) Seclude (a religious community) from the outside world. [latin: related to *include]</p>",
            },
        ],
    },
    {
        word: "Enclosure",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enclosure <strong class='noun'> n. </strong> 1 act of enclosing. 2 enclosed space or area, esp. At a sporting event. 3 thing enclosed with a letter. [french: related to *enclose]</p>",
            },
        ],
    },
    {
        word: "Encode",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encode <strong class='verb'> v. </strong> (-ding) put into code.</p>",
            },
        ],
    },
    {
        word: "Encomium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encomium <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> formal or high-flown praise. [greek komos revelry]</p>",
            },
        ],
    },
    {
        word: "Encompass",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encompass <strong class='verb'> v. </strong> 1 contain; include. 2 surround.</p>",
            },
        ],
    },
    {
        word: "Encore",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encore <strong class='noun'> —n. </strong> 1 audience's demand for the repetition of an item, or for a further item. 2 such an item. —v. <strong class='adj'> (-ring) </strong> 1 call for the repetition of (an item). 2 call back (a performer) for this. <strong class='pl'> —int. </strong> Again, once more. [french, = once again]</p>",
            },
        ],
    },
    {
        word: "Encounter",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encounter <strong class='noun'> —v. </strong> 1 meet unexpectedly. 2 meet as an adversary. <strong class='noun'> —n. </strong> Meeting by chance or in conflict. [latin contra against]</p>",
            },
        ],
    },
    {
        word: "Encourage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encourage <strong class='verb'> v. </strong> (-ging) 1 give courage or confidence to. 2 urge. 3 promote. encouragement <strong class='noun'> n. </strong> [french: related to *en-1]</p>",
            },
        ],
    },
    {
        word: "Encroach",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encroach <strong class='verb'> v. </strong> 1 (foll. By on, upon) intrude on another's territory etc. 2 advance gradually beyond due limits. encroachment <strong class='noun'> n. </strong> [french croc *crook]</p>",
            },
        ],
    },
    {
        word: "Encrust",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encrust <strong class='verb'> v. </strong> 1 cover with or form a crust. 2 coat with a hard casing or deposit, sometimes for decoration. [french: related to *en-1]</p>",
            },
        ],
    },
    {
        word: "Encumber",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encumber <strong class='verb'> v. </strong> 1 be a burden to. 2 hamper. [french from romanic]</p>",
            },
        ],
    },
    {
        word: "Encumbrance",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encumbrance <strong class='noun'> n. </strong> 1 burden. 2 impediment.</p>",
            },
        ],
    },
    {
        word: "-ency",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ency <strong class='slang_v'> suffix </strong>forming nouns denoting quality or state (efficiency; fluency; presidency). [latin -entia]</p>",
            },
        ],
    },
    {
        word: "Encyclical",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encyclical <strong class='adj'> —adj.</strong> For wide circulation. <strong class='noun'> —n. </strong> Papal encyclical letter. [greek: related to *cycle]</p>",
            },
        ],
    },
    {
        word: "Encyclopedia",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encyclopedia <strong class='noun'> n. </strong> (also -paedia) book, often in a number of volumes, giving information on many subjects, or on many aspects of one subject. [greek egkuklios all-round, paideia education]</p>",
            },
        ],
    },
    {
        word: "Encyclopedic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Encyclopedic <strong class='adj'> adj. </strong> (also -paedic) (of knowledge or information) comprehensive.</p>",
            },
        ],
    },
    {
        word: "End",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>End <strong class='noun'> —n. </strong> 1 a extreme limit. B extremity (to the ends of the earth). 2 extreme part or surface of a thing (strip of wood with a nail in one end). 3 a finish (no end to his misery). B latter part. C death, destruction (met an untimely end). D result. 4 goal (will do anything to achieve his ends). 5 remnant (cigarette-end). 6 (prec.</p>",
            },
        ],
    },
    {
        word: "Endanger",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endanger <strong class='verb'> v. </strong> Place in danger.</p>",
            },
        ],
    },
    {
        word: "Endangered species",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endangered species <strong class='noun'> n. </strong> Species in danger of extinction.</p>",
            },
        ],
    },
    {
        word: "Endear",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endear <strong class='verb'> v. </strong> (usu. Foll. By to) make dear. endearing adj.</p>",
            },
        ],
    },
    {
        word: "Endearment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endearment <strong class='noun'> n. </strong> 1 an expression of affection. 2 liking, affection.</p>",
            },
        ],
    },
    {
        word: "Endeavor",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endeavor  <strong class='noun'>(brit. Endeavour)</strong> <strong class='noun'> —v. </strong>(foll. By to + infin.) Try earnestly. <strong class='noun'> —n. </strong> Earnest attempt. [from *en-1, french devoir owe]</p>",
            },
        ],
    },
    {
        word: "Endeavour",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endeavour  <strong class='noun'>(us endeavor)</strong> <strong class='noun'> —v. </strong>(foll. By to + infin.) Try earnestly. <strong class='noun'> —n. </strong> Earnest attempt. [from *en-1, french devoir owe]</p>",
            },
        ],
    },
    {
        word: "Endemic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endemic <strong class='adj'> adj. </strong> <strong class='adj'> (often foll. By to) </strong> regularly or only found among a particular people or in a particular region. endemically <strong class='adv'> adv. </strong> [greek en- in, demos the people]</p>",
            },
        ],
    },
    {
        word: "Ending",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ending <strong class='noun'> n. </strong> 1 end or final part, esp. Of a story. 2 inflected final part of a word.</p>",
            },
        ],
    },
    {
        word: "Endive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endive <strong class='noun'> n. </strong> Curly-leaved plant used in salads. [greek entubon]</p>",
            },
        ],
    },
    {
        word: "Endless",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endless <strong class='adj'> adj. </strong> 1 infinite; without end. 2 continual (endless complaints). 3 <strong class='colloq'> colloq. </strong> Innumerable. 4 (of a belt, chain, etc.) Having the ends joined for continuous action over wheels etc. endlessly <strong class='adv'> adv. </strong> [old english: related to *end]</p>",
            },
        ],
    },
    {
        word: "Endmost",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endmost <strong class='adj'> adj. </strong> Nearest the end.</p>",
            },
        ],
    },
    {
        word: "Endo-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endo- <strong class='n_pl'> comb. </strong> Form internal. [greek endon within]</p>",
            },
        ],
    },
    {
        word: "Endocrine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endocrine <strong class='adj'> adj. </strong> (of a gland) secreting directly into the blood. [greek krino sift]</p>",
            },
        ],
    },
    {
        word: "Endogenous",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endogenous <strong class='adj'> adj. </strong> Growing or originating from within.</p>",
            },
        ],
    },
    {
        word: "Endometrium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endometrium <strong class='noun'> n. </strong> Membrane lining the womb. [greek metra womb]</p>",
            },
        ],
    },
    {
        word: "Endomorph",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endomorph <strong class='noun'> n. </strong> Person with a soft round body. [greek morphe form]</p>",
            },
        ],
    },
    {
        word: "Endorse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endorse <strong class='verb'> v. </strong> (also indorse) <strong class='sing'>(-sing)</strong> 1 approve. 2 sign or write on (a document), esp. Sign the back of (a cheque). 3 enter details of a conviction for an offence on (a driving-licence). endorsement <strong class='noun'> n. </strong> [latin dorsum back]</p>",
            },
        ],
    },
    {
        word: "Endoscope",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endoscope <strong class='noun'> n. </strong> Instrument for viewing internal parts of the body.</p>",
            },
        ],
    },
    {
        word: "Endow",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endow <strong class='verb'> v. </strong> 1 bequeath or give a permanent income to (a person, institution, etc.). 2 (esp. As endowed adj.) Provide with talent, ability, etc. [anglo-french: related to *dower]</p>",
            },
        ],
    },
    {
        word: "Endowment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endowment <strong class='noun'> n. </strong> 1 endowing. 2 endowed income. 3 (attrib.) Denoting forms of life insurance with payment of a fixed sum on a specified date, or on the death of the insured person if earlier.</p>",
            },
        ],
    },
    {
        word: "Endowment mortgage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endowment mortgage <strong class='noun'> n. </strong> Mortgage linked to endowment insurance.</p>",
            },
        ],
    },
    {
        word: "Endpaper",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endpaper <strong class='noun'> n. </strong> Either of the blank leaves of paper at the beginning and end of a book.</p>",
            },
        ],
    },
    {
        word: "End-product",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>End-product <strong class='noun'> n. </strong> Final product of manufacture etc.</p>",
            },
        ],
    },
    {
        word: "Endue",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endue <strong class='verb'> v. </strong> (also indue) (-dues, -dued, -duing) (foll. By with) provide (a person) with (qualities etc.). [latin induo put on clothes]</p>",
            },
        ],
    },
    {
        word: "Endurance",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endurance <strong class='noun'> n. </strong> 1 power of enduring. 2 ability to withstand prolonged strain. [french: related to *endure]</p>",
            },
        ],
    },
    {
        word: "Endure",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endure <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 undergo (a difficulty etc.). 2 tolerate. 3 last. endurable adj. [latin durus hard]</p>",
            },
        ],
    },
    {
        word: "Endways",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Endways <strong class='adv'> adv. </strong> (also endwise) 1 with end uppermost or foremost. 2 end to end.</p>",
            },
        ],
    },
    {
        word: "Enema",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enema <strong class='noun'> n. </strong> 1 introduction of fluid etc. Into the rectum, esp. To flush out its contents. 2 fluid etc. Used for this. [greek hiemi send]</p>",
            },
        ],
    },
    {
        word: "Enemy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enemy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 person actively hostile to another. 2 a (often attrib.) Hostile nation or army. B member of this. 3 adversary or opponent (enemy of progress). [latin: related to *in-2, amicus friend]</p>",
            },
        ],
    },
    {
        word: "Energetic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Energetic <strong class='adj'> adj. </strong> Full of energy, vigorous. energetically <strong class='adv'> adv. </strong> [greek: related to *energy]</p>",
            },
        ],
    },
    {
        word: "Energize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Energize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 give energy to. 2 provide (a device) with energy for operation.</p>",
            },
        ],
    },
    {
        word: "Energy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Energy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 capacity for activity, force, vigour. 2 capacity of matter or radiation to do work. [greek ergon work]</p>",
            },
        ],
    },
    {
        word: "Enervate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enervate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> deprive of vigour or vitality. enervation <strong class='noun'> n. </strong> [latin: related to *nerve]</p>",
            },
        ],
    },
    {
        word: "En famille",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>En famille <strong class='adv'> adv. </strong> In or with one's family. [french, = in family]</p>",
            },
        ],
    },
    {
        word: "Enfant terrible",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enfant terrible <strong class='noun'> n. </strong> (pl. Enfants terribles pronunc. Same) indiscreet or unruly person. [french, = terrible child]</p>",
            },
        ],
    },
    {
        word: "Enfeeble",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enfeeble <strong class='verb'> v. </strong> (-ling) make feeble. enfeeblement n.</p>",
            },
        ],
    },
    {
        word: "Enfilade",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enfilade <strong class='noun'> —n. </strong> Gunfire directed along a line from end to end. —v. (-ding) direct an enfilade at. [french: related to *file1]</p>",
            },
        ],
    },
    {
        word: "Enfold",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enfold <strong class='verb'> v. </strong> 1 (usu. Foll. By in, with) wrap; envelop. 2 clasp, embrace.</p>",
            },
        ],
    },
    {
        word: "Enforce",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enforce <strong class='verb'> v. </strong> (-cing) 1 compel observance of (a law etc.). 2 (foll. By on) impose (an action or one's will, etc.) On. enforceable adj. Enforcement <strong class='noun'> n. </strong> Enforcer <strong class='noun'> n. </strong> [latin: related to *force1]</p>",
            },
        ],
    },
    {
        word: "Enfranchise",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enfranchise <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 give (a person) the right to vote. 2 give (a town) municipal rights, esp. Representation in parliament. 3 hist. Free (a slave etc.). enfranchisement <strong class='noun'> n. </strong> [french: related to *frank]</p>",
            },
        ],
    },
    {
        word: "Engage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engage <strong class='verb'> v. </strong> (-ging) 1 employ or hire (a person). 2 a (usu. In passive) occupy (are you engaged tomorrow?). B hold fast (a person's attention). 3 (usu. In passive) bind by a promise, esp. Of marriage. 4 arrange beforehand to occupy (a room, seat, etc.). 5 a interlock (parts of a gear etc.). B (of a gear etc.) Become interlocked. 6 a come into battle with. B bring (troops) into battle with. C come into battle with (an enemy etc.). 7 take part (engage in politics). 8 (foll. By that + clause or to + infin.) Undertake. [french: related to *gage1]</p>",
            },
        ],
    },
    {
        word: "Engaged",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engaged <strong class='adj'> adj. </strong> 1 pledged to marry. 2 (of a person) occupied, busy. 3 (of a telephone line, toilet, etc.) In use.</p>",
            },
        ],
    },
    {
        word: "Engagement",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engagement <strong class='noun'> n. </strong> 1 engaging or being engaged. 2 appointment with another person. 3 betrothal. 4 battle.</p>",
            },
        ],
    },
    {
        word: "Engaging",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engaging <strong class='adj'> adj. </strong> Attractive, charming. engagingly adv.</p>",
            },
        ],
    },
    {
        word: "Engender",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engender <strong class='verb'> v. </strong> Give rise to; produce (a feeling etc.). [related to *genus]</p>",
            },
        ],
    },
    {
        word: "Engine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engine <strong class='noun'> n. </strong> 1 mechanical contrivance of parts working together, esp. As a source of power (steam engine). 2 a railway locomotive. B = *fire-engine. [latin ingenium device]</p>",
            },
        ],
    },
    {
        word: "Engineer",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engineer <strong class='noun'> —n. </strong> 1 person skilled in a branch of engineering. 2 person who makes or is in charge of engines etc. (ship's engineer). 3 person who designs and constructs military works; soldier so trained. 4 contriver. —v. 1 contrive, bring about. 2 act as an engineer. 3 construct or manage as an engineer. [medieval latin: related to *engine]</p>",
            },
        ],
    },
    {
        word: "Engineering",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engineering <strong class='noun'> n. </strong> Application of science to the design, building, and use of machines etc. (civil engineering).</p>",
            },
        ],
    },
    {
        word: "English",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>English <strong class='adj'> —adj.</strong> Of england or its people or language. <strong class='noun'> —n. </strong> 1 language of england, now used in the uk, us, and most commonwealth countries. 2 (prec. By the; treated as pl.) The people of england. [old english]</p>",
            },
        ],
    },
    {
        word: "Englishman",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Englishman <strong class='noun'> n. </strong> (fem. Englishwoman) person who is english by birth or descent.</p>",
            },
        ],
    },
    {
        word: "Engorged",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engorged <strong class='adj'> adj. </strong> 1 crammed full. 2 congested with fluid, esp. Blood. [french: related to *en-1, *gorge]</p>",
            },
        ],
    },
    {
        word: "Engraft",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engraft <strong class='verb'> v. </strong> (also ingraft) 1 bot. (usu. Foll. By into, on) graft. 2 implant. 3 (usu. Foll. By into) incorporate.</p>",
            },
        ],
    },
    {
        word: "Engrave",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engrave <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> 1 (often foll. By on) carve (a text or design) on a hard surface. 2 inscribe (a surface) thus. 3 (often foll. By on) impress deeply (on a person's memory). engraver <strong class='noun'> n. </strong> [from *grave3]</p>",
            },
        ],
    },
    {
        word: "Engraving",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engraving <strong class='noun'> n. </strong> Print made from an engraved plate.</p>",
            },
        ],
    },
    {
        word: "Engross",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engross <strong class='verb'> v. </strong> 1 absorb the attention of; occupy fully. 2 write out in larger letters or in legal form. engrossment <strong class='noun'> n. </strong> [anglo-french: related to *en-1]</p>",
            },
        ],
    },
    {
        word: "Engulf",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Engulf <strong class='verb'> v. </strong> Flow over and swamp; overwhelm. engulfment n.</p>",
            },
        ],
    },
    {
        word: "Enhance",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enhance <strong class='verb'> v. </strong> (-cing) intensify (qualities, powers, etc.); improve (something already good). enhancement <strong class='noun'> n. </strong> [anglo-french from latin altus high]</p>",
            },
        ],
    },
    {
        word: "Enigma",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enigma <strong class='noun'> n. </strong> 1 puzzling thing or person. 2 riddle or paradox. enigmatic adj. Enigmatically <strong class='adv'> adv. </strong> [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Enjoin",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enjoin <strong class='verb'> v. </strong> 1 command or order. 2 (often foll. By on) impose (an action). 3 (usu. Foll. By from) law prohibit by injunction (from doing a thing). [latin injungo attach]</p>",
            },
        ],
    },
    {
        word: "Enjoy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enjoy <strong class='verb'> v. </strong> 1 take pleasure in. 2 have the use or benefit of. 3 experience (enjoy good health). enjoy oneself experience pleasure. enjoyment <strong class='noun'> n. </strong> [french]</p>",
            },
        ],
    },
    {
        word: "Enjoyable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enjoyable <strong class='adj'> adj. </strong> Pleasant. enjoyably adv.</p>",
            },
        ],
    },
    {
        word: "Enkephalin",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enkephalin <strong class='noun'> n. </strong> Either of two morphine-like peptides in the brain thought to control levels of pain. [greek egkephalos brain]</p>",
            },
        ],
    },
    {
        word: "Enkindle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enkindle <strong class='verb'> v. </strong> (-ling) cause to flare up, arouse.</p>",
            },
        ],
    },
    {
        word: "Enlarge",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enlarge <strong class='verb'> v. </strong> (-ging) 1 make or become larger or wider. 2 (often foll. By on, upon) describe in greater detail. 3 reproduce a photograph on a larger scale. enlargement <strong class='noun'> n. </strong> [french: related to *large]</p>",
            },
        ],
    },
    {
        word: "Enlarger",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enlarger <strong class='noun'> n. </strong> Apparatus for enlarging photographs.</p>",
            },
        ],
    },
    {
        word: "Enlighten",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enlighten <strong class='verb'> v. </strong> 1 (often foll. By on) inform (about a subject). 2 (as enlightened adj.) Progressive.</p>",
            },
        ],
    },
    {
        word: "Enlightenment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enlightenment <strong class='noun'> n. </strong> 1 enlightening or being enlightened. 2 (the enlightenment) 18th-c. Philosophy of reason and individualism.</p>",
            },
        ],
    },
    {
        word: "Enlist",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enlist <strong class='verb'> v. </strong> 1 enrol in the armed services. 2 secure as a means of help or support. enlistment n.</p>",
            },
        ],
    },
    {
        word: "Enliven",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enliven <strong class='verb'> v. </strong> Make lively or cheerful; brighten (a picture etc.); inspirit. enlivenment n.</p>",
            },
        ],
    },
    {
        word: "En masse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>En masse <strong class='adv'> adv. </strong> All together. [french]</p>",
            },
        ],
    },
    {
        word: "Enmesh",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enmesh <strong class='verb'> v. </strong> Entangle in or as in a net.</p>",
            },
        ],
    },
    {
        word: "Enmity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enmity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 state of being an enemy. 2 hostility. [romanic: related to *enemy]</p>",
            },
        ],
    },
    {
        word: "Ennoble",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ennoble <strong class='verb'> v. </strong> (-ling) 1 make noble. 2 make (a person) a noble. ennoblement <strong class='noun'> n. </strong> [french: related to *en-1]</p>",
            },
        ],
    },
    {
        word: "Ennui",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ennui <strong class='noun'> n. </strong> Mental weariness from idleness or lack of interest; boredom. [french: related to *annoy]</p>",
            },
        ],
    },
    {
        word: "Enormity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enormity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 monstrous wickedness; monstrous crime. 2 serious error. 3 great size. [latin enormitas] <strong class='usage'> Usage:- </strong>sense 3 is commonly found, but is regarded as incorrect by some people.</p>",
            },
        ],
    },
    {
        word: "Enormous",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enormous <strong class='adj'> adj. </strong> Extremely large. enormously <strong class='adv'> adv. </strong> [latin enormis: related to *norm]</p>",
            },
        ],
    },
    {
        word: "Enough",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enough <strong class='adj'> —adj.</strong> As much or as many as required (enough apples). <strong class='noun'> —n. </strong> Sufficient amount or quantity (we have enough). <strong class='adv'> —adv. </strong> 1 adequately (warm enough). 2 fairly (sings well enough). 3 quite (you know well enough what i mean). have had enough of want no more of; be satiated with or tired of. Sure enough as expected. [old english]</p>",
            },
        ],
    },
    {
        word: "En passant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>En passant <strong class='adv'> adv. </strong> In passing; casually (mentioned it en passant). [french, = in passing]</p>",
            },
        ],
    },
    {
        word: "Enprint",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enprint <strong class='noun'> n. </strong> Standard-sized photograph. [enlarged print]</p>",
            },
        ],
    },
    {
        word: "Enquire",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enquire <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 seek information; ask; ask a question. 2 = *inquire. 3 (foll. By after, for) ask about (a person, a person's health, etc.). enquirer <strong class='noun'> n. </strong> [latin quaero quaesit- seek]</p>",
            },
        ],
    },
    {
        word: "Enquiry",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enquiry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 act of asking or seeking information. 2 = *inquiry.</p>",
            },
        ],
    },
    {
        word: "Enrage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enrage <strong class='verb'> v. </strong> (-ging) make furious. [french: related to *en-1]</p>",
            },
        ],
    },
    {
        word: "Enrapture",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enrapture <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> delight intensely.</p>",
            },
        ],
    },
    {
        word: "Enrich",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enrich <strong class='verb'> v. </strong> 1 make rich or richer. 2 make more nutritive. 3 increase the strength, wealth, value, or contents of. enrichment <strong class='noun'> n. </strong> [french: related to *en-1]</p>",
            },
        ],
    },
    {
        word: "Enrol",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enrol <strong class='verb'> v. </strong> (us enroll) (-ll-) 1 enlist. 2 a write the name of (a person) on a list. B incorporate as a member. C enrol oneself, esp. For a course of study. enrolment <strong class='noun'> n. </strong> [french: related to *en-1]</p>",
            },
        ],
    },
    {
        word: "Enroll",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enroll <strong class='verb'> v. </strong> (brit. Enrol) (-ll-) 1 enlist. 2 a write the name of (a person) on a list. B incorporate as a member. C enrol oneself, esp. For a course of study. enrolment <strong class='noun'> n. </strong> [french: related to *en-1]</p>",
            },
        ],
    },
    {
        word: "En route",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>En route <strong class='adv'> adv. </strong> On the way. [french]</p>",
            },
        ],
    },
    {
        word: "Ensconce",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ensconce <strong class='verb'> v. </strong> (-cing) (usu. Refl. Or in passive) establish or settle comfortably. [from sconce small fortification]</p>",
            },
        ],
    },
    {
        word: "Ensemble",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ensemble <strong class='noun'> n. </strong> 1 a thing viewed as the sum of its parts. B general effect of this. 2 set of clothes worn together. 3 group of performers working together. 4 <strong class='mus'> mus. </strong> Concerted passage for an ensemble. [latin simul at the same time]</p>",
            },
        ],
    },
    {
        word: "Enshrine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enshrine <strong class='verb'> v. </strong> (-ning) 1 enclose in a shrine. 2 protect, make inviolable. enshrinement n.</p>",
            },
        ],
    },
    {
        word: "Enshroud",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enshroud <strong class='verb'> v. </strong> Literary 1 cover with or as with a shroud. 2 obscure.</p>",
            },
        ],
    },
    {
        word: "Ensign",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ensign <strong class='noun'> n. </strong> 1 banner or flag, esp. The military or naval flag of a nation. 2 standard-bearer. 3 a hist. Lowest commissioned infantry officer. B us lowest commissioned naval officer. [french: related to *insignia]</p>",
            },
        ],
    },
    {
        word: "Ensilage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ensilage <strong class='noun'> —n. </strong> = *silage. —v. (-ging) preserve (fodder) by ensilage. [french: related to *silo]</p>",
            },
        ],
    },
    {
        word: "Enslave",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enslave <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> make (a person) a slave. enslavement n.</p>",
            },
        ],
    },
    {
        word: "Ensnare",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ensnare <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> catch in or as in a snare. ensnarement n.</p>",
            },
        ],
    },
    {
        word: "Ensue",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ensue <strong class='verb'> v. </strong> (-sues, -sued, -suing) happen later or as a result. [latin sequor follow]</p>",
            },
        ],
    },
    {
        word: "En suite",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>En suite <strong class='adv'> —adv. </strong> Forming a single unit (bedroom with bathroom en suite). <strong class='adj'> —adj.</strong> 1 forming a single unit (en suite bathroom). 2 with a bathroom attached (seven en suite bedrooms). [french, = in sequence]</p>",
            },
        ],
    },
    {
        word: "Ensure",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ensure <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 make certain. 2 (usu. Foll. By against) make safe (ensure against risks). ensurer <strong class='noun'> n. </strong> [anglo-french: related to *assure]</p>",
            },
        ],
    },
    {
        word: "Ent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ent <strong class='sing'> abbr. </strong> Ear, nose, and throat.</p>",
            },
        ],
    },
    {
        word: "-ent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ent <strong class='slang_v'> suffix </strong>1 forming adjectives denoting attribution of an action (consequent) or state (existent). 2 forming agent nouns (president). [latin -ent- present participial stem of verbs]</p>",
            },
        ],
    },
    {
        word: "Entablature",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entablature <strong class='noun'> n. </strong> Upper part of a classical building supported by columns including an architrave, frieze, and cornice. [italian: related to *table]</p>",
            },
        ],
    },
    {
        word: "Entail",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entail <strong class='noun'> —v. </strong> 1 necessitate or involve unavoidably (entails much effort). 2 law bequeath (an estate) to a specified line of beneficiaries so that it cannot be sold or given away. <strong class='noun'> —n. </strong> Law 1 entailed estate. 2 succession to such an estate. [related to *tail2] </p>",
            },
        ],
    },
    {
        word: "Entangle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entangle <strong class='verb'> v. </strong> (-ling) 1 catch or hold fast in a snare, tangle, etc. 2 involve in difficulties. 3 complicate. entanglement n.</p>",
            },
        ],
    },
    {
        word: "Entente",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entente <strong class='noun'> n. </strong> Friendly understanding between states. [french]</p>",
            },
        ],
    },
    {
        word: "Entente cordiale",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entente cordiale <strong class='noun'> n. </strong> Entente, esp. Between britain and france from 1904.</p>",
            },
        ],
    },
    {
        word: "Enter",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enter <strong class='verb'> v. </strong> 1 go or come in or into. 2 come on stage (also as a direction: enter macbeth). 3 penetrate (bullet entered his arm). 4 write (name, details, etc.) In a list, book, etc. 5 register, record the name of as a competitor (entered for the long jump). 6 a become a member of (a society or profession). B enrol in a school etc. 7 make known; present for consideration (enter a protest). 8 record formally (before a court of law etc.). 9 (foll. By into) a engage in (conversation etc.). B subscribe to; bind oneself by (an agreement, contract, etc.). C form part of (a calculation, plan, etc.). D sympathize with (feelings). 10 (foll. By on, upon) a begin; begin to deal with. B assume the functions of (an office) or possession of (property). [latin intra within]</p>",
            },
        ],
    },
    {
        word: "Enteric",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enteric <strong class='adj'> adj. </strong> Of the intestines. enteritis <strong class='noun'> n. </strong> [greek enteron intestine]</p>",
            },
        ],
    },
    {
        word: "Entry",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a going or coming in. B liberty to do this. 2 place of entrance; door, gate, etc. 3 passage between buildings. 4 a item entered in a diary, list, etc. B recording of this. 5 a person or thing competing in a race etc. B list of competitors. [romanic: related to *enter]</p>",
            },
        ],
    },
    {
        word: "Entryphone",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entryphone <strong class='noun'> n. </strong> Propr. Intercom at the entrance of a building or flat for callers to identify themselves.</p>",
            },
        ],
    },
    {
        word: "Entwine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entwine <strong class='verb'> v. </strong> (-ning) twine round, interweave.</p>",
            },
        ],
    },
    {
        word: "E-number",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>E-number <strong class='noun'> n. </strong> E plus a number, the ec designation for food additives.</p>",
            },
        ],
    },
    {
        word: "Enumerate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enumerate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 specify (items). 2 count. enumeration <strong class='noun'> n. </strong> Enumerative adj. [latin: related to *number]</p>",
            },
        ],
    },
    {
        word: "Enumerator",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enumerator <strong class='noun'> n. </strong> Person employed in census-taking.</p>",
            },
        ],
    },
    {
        word: "Enunciate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enunciate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 pronounce (words) clearly. 2 express in definite terms. enunciation <strong class='noun'> n. </strong> [latin nuntio announce]</p>",
            },
        ],
    },
    {
        word: "Enuresis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enuresis <strong class='noun'> n. </strong> Involuntary urination. [greek enoureo urinate in]</p>",
            },
        ],
    },
    {
        word: "Envelop",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Envelop <strong class='verb'> v. </strong> (-p-) 1 wrap up or cover completely. 2 completely surround. envelopment <strong class='noun'> n. </strong> [french]</p>",
            },
        ],
    },
    {
        word: "Envelope",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Envelope <strong class='noun'> n. </strong> 1 folded paper container for a letter etc. 2 wrapper, covering. 3 gas container of a balloon or airship.</p>",
            },
        ],
    },
    {
        word: "Enviable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enviable <strong class='adj'> adj. </strong> Likely to excite envy, desirable. enviably adv.</p>",
            },
        ],
    },
    {
        word: "Envious",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Envious <strong class='adj'> adj. </strong> Feeling or showing envy. enviously <strong class='adv'> adv. </strong> [anglo-french: related to *envy]</p>",
            },
        ],
    },
    {
        word: "Environment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Environment <strong class='noun'> n. </strong> 1 surroundings, esp. As affecting lives. 2 circumstances of living. 3 computing overall structure within which a user, computer, or program operates. environmental adj. Environmentally <strong class='adv'> adv. </strong> [french environ surroundings]</p>",
            },
        ],
    },
    {
        word: "Environmentalist",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Environmentalist <strong class='noun'> n. </strong> Person concerned with the protection of the natural environment. environmentalism n.</p>",
            },
        ],
    },
    {
        word: "Environs",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Environs <strong class='n_pl'> n.pl. </strong> District round a town etc.</p>",
            },
        ],
    },
    {
        word: "Envisage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Envisage <strong class='verb'> v. </strong> (-ging) 1 have a mental picture of (a thing not yet existing). 2 imagine as possible or desirable. [french: related to *visage]</p>",
            },
        ],
    },
    {
        word: "Envoy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Envoy <strong class='noun'> n. </strong> 1 messenger or representative. 2 (in full envoy extraordinary) diplomatic agent ranking below ambassador. [french envoyer send, from latin via way]</p>",
            },
        ],
    },
    {
        word: "Envy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Envy <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 discontent aroused by another's better fortune etc. 2 object of this feeling. —v. (-ies, -ied) feel envy of (a person etc.). [latin invidia, from video see]</p>",
            },
        ],
    },
    {
        word: "Enwrap",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enwrap <strong class='verb'> v. </strong> (-pp-) (often foll. By in) literary wrap, enfold.</p>",
            },
        ],
    },
    {
        word: "Enzyme",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enzyme <strong class='noun'> n. </strong> Protein catalyst of a specific biochemical reaction. [greek enzumos leavened]</p>",
            },
        ],
    },
    {
        word: "Eocene",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eocene <strong class='geol'> geol. </strong> <strong class='adj'> —adj.</strong> Of the second epoch of the tertiary period. <strong class='noun'> —n. </strong> This epoch. [greek eos dawn, kainos new]</p>",
            },
        ],
    },
    {
        word: "Eolian harp",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eolian harp <strong class='noun'> n. </strong> (brit. Aeolian) stringed instrument or toy sounding when the wind passes through it. [latin aeolus wind-god, from greek]</p>",
            },
        ],
    },
    {
        word: "Eolithic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eolithic <strong class='adj'> adj. </strong> Of the period preceding the palaeolithic age. [greek eos dawn, lithos stone]</p>",
            },
        ],
    },
    {
        word: "Eon",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>Eon <strong class='var'> var. </strong> Of *aeon.</p>",
            },
        ],
    },
    {
        word: "Ep",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ep <strong class='sing'> abbr. </strong> Extended-play (gramophone record).</p>",
            },
        ],
    },
    {
        word: "Epaulet",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epaulet <strong class='noun'> n. </strong> (brit. Epaulette) ornamental shoulder-piece on a coat etc., esp. On a uniform. [french épaule shoulder]</p>",
            },
        ],
    },
    {
        word: "Epaulette",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epaulette <strong class='noun'> n. </strong> (us epaulet) ornamental shoulder-piece on a coat etc., esp. On a uniform. [french épaule shoulder]</p>",
            },
        ],
    },
    {
        word: "Épée",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Épée <strong class='noun'> n. </strong> Sharp-pointed sword, used (with the end blunted) in fencing. [french: related to *spathe]</p>",
            },
        ],
    },
    {
        word: "Ephedrine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ephedrine <strong class='noun'> n. </strong> Alkaloid drug used to relieve asthma, etc. [ephedra, genus of plants yielding it]</p>",
            },
        ],
    },
    {
        word: "Ephemera",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ephemera <strong class='n_pl'> n.pl. </strong> Things of only short-lived relevance. [latin: related to *ephemeral]</p>",
            },
        ],
    },
    {
        word: "Ephemeral",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ephemeral <strong class='adj'> adj. </strong> Lasting or of use for only a short time; transitory. [greek: related to *epi-, hemera day]</p>",
            },
        ],
    },
    {
        word: "Epi-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epi- <strong class='prefix '> prefix </strong> 1 upon. 2 above. 3 in addition. [greek]</p>",
            },
        ],
    },
    {
        word: "Epic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epic <strong class='noun'> —n. </strong> 1 long poem narrating the adventures or deeds of one or more heroic or legendary figures. 2 book or film based on an epic narrative. <strong class='adj'> —adj.</strong> 1 of or like an epic. 2 grand, heroic. [greek epos song]</p>",
            },
        ],
    },
    {
        word: "Epicene",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epicene <strong class='adj'> —adj.</strong> 1 of, for, denoting, or used by both sexes. 2 having characteristics of both sexes or of neither sex. <strong class='noun'> —n. </strong> Epicene person. [greek koinos common]</p>",
            },
        ],
    },
    {
        word: "Epicenter",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epicenter <strong class='noun'> n. </strong> (brit. Epicentre) 1 point at which an earthquake reaches the earth's surface. 2 central point of a difficulty. [greek: related to *centre]</p>",
            },
        ],
    },
    {
        word: "Epicentre",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epicentre <strong class='noun'> n. </strong> (us epicenter) 1 point at which an earthquake reaches the earth's surface. 2 central point of a difficulty. [greek: related to *centre]</p>",
            },
        ],
    },
    {
        word: "Epicure",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epicure <strong class='noun'> n. </strong> Person with refined tastes, esp. In food and drink. epicurism <strong class='noun'> n. </strong> [medieval latin: related to *epicurean]</p>",
            },
        ],
    },
    {
        word: "Epicurean",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epicurean <strong class='noun'> —n. </strong> 1 disciple or student of the greek philosopher epicurus. 2 (epicurean) devotee of (esp. Sensual) enjoyment. <strong class='adj'> —adj.</strong> 1 of epicurus or his ideas. 2 (epicurean) characteristic of an epicurean. epicureanism <strong class='noun'> n. </strong> [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Epidemic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epidemic <strong class='noun'> —n. </strong> Widespread occurrence of a disease in a community at a particular time. <strong class='adj'> —adj.</strong> In the nature of an epidemic. [greek epi against, demos the people]</p>",
            },
        ],
    },
    {
        word: "Epidemiology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epidemiology <strong class='noun'> n. </strong> The study of epidemic diseases and their control. epidemiologist n.</p>",
            },
        ],
    },
    {
        word: "Epidermis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epidermis <strong class='noun'> n. </strong> Outer layer of the skin. epidermal adj. [greek derma skin]</p>",
            },
        ],
    },
    {
        word: "Epidiascope",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epidiascope <strong class='noun'> n. </strong> Optical projector capable of giving images of both opaque and transparent objects. [from *epi-, *dia-, *-scope]</p>",
            },
        ],
    },
    {
        word: "Epidural",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epidural <strong class='adj'> —adj.</strong> (of an anaesthetic) introduced into the space around the dura mater of the spinal cord. <strong class='noun'> —n. </strong> Epidural anaesthetic. [from *epi-, *dura mater]</p>",
            },
        ],
    },
    {
        word: "Epiglottis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epiglottis <strong class='noun'> n. </strong> Flap of cartilage at the root of the tongue, depressed during swallowing to cover the windpipe. epiglottal adj. [greek glotta tongue]</p>",
            },
        ],
    },
    {
        word: "Epigram",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epigram <strong class='noun'> n. </strong> 1 short poem with a witty ending. 2 pointed saying. epigrammatic adj. [greek: related to *-gram]</p>",
            },
        ],
    },
    {
        word: "Epigraph",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epigraph <strong class='noun'> n. </strong> Inscription. [greek: related to *-graph]</p>",
            },
        ],
    },
    {
        word: "Epilepsy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epilepsy <strong class='noun'> n. </strong> Nervous disorder with convulsions and often loss of consciousness. [greek lambano take]</p>",
            },
        ],
    },
    {
        word: "Epileptic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epileptic <strong class='adj'> —adj.</strong> Of epilepsy. <strong class='noun'> —n. </strong> Person with epilepsy. [french: related to *epilepsy]</p>",
            },
        ],
    },
    {
        word: "Epilogue",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epilogue <strong class='noun'> n. </strong> 1 short piece ending a literary work. 2 speech addressed to the audience by an actor at the end of a play. [greek logos speech]</p>",
            },
        ],
    },
    {
        word: "Epiphany",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epiphany <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 (epiphany) a manifestation of christ to the magi. B festival of this on 6 january. 2 manifestation of a god or demigod. [greek phaino show]</p>",
            },
        ],
    },
    {
        word: "Episcopacy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Episcopacy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 government by bishops. 2 (prec. By the) the bishops.</p>",
            },
        ],
    },
    {
        word: "Episcopal",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Episcopal <strong class='adj'> adj. </strong> 1 of a bishop or bishops. 2 (of a church) governed by bishops. episcopally <strong class='adv'> adv. </strong> [church latin: related to *bishop]</p>",
            },
        ],
    },
    {
        word: "Episcopalian",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Episcopalian <strong class='adj'> —adj.</strong> 1 of episcopacy. 2 of an episcopal church or (episcopalian) the episcopal church. <strong class='noun'> —n. </strong> 1 adherent of episcopacy. 2 (episcopalian) member of the episcopal church. episcopalianism n.</p>",
            },
        ],
    },
    {
        word: "Episcopate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Episcopate <strong class='noun'> n. </strong> 1 the office or tenure of a bishop. 2 (prec. By the) the bishops collectively. [church latin: related to *bishop]</p>",
            },
        ],
    },
    {
        word: "Episiotomy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Episiotomy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> surgical cut made at the vaginal opening during childbirth, to aid delivery. [greek epision pubic region]</p>",
            },
        ],
    },
    {
        word: "Episode",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Episode <strong class='noun'> n. </strong> 1 event or group of events as part of a sequence. 2 each of the parts of a serial story or broadcast. 3 incident or set of incidents in a narrative. [greek eisodos entry]</p>",
            },
        ],
    },
    {
        word: "Episodic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Episodic <strong class='adj'> adj. </strong> 1 consisting of separate episodes. 2 irregular, sporadic. episodically adv.</p>",
            },
        ],
    },
    {
        word: "Epistemology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epistemology <strong class='noun'> n. </strong> Philosophy of knowledge. epistemological adj. [greek episteme knowledge]</p>",
            },
        ],
    },
    {
        word: "Epistle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epistle <strong class='noun'> n. </strong> 1 joc. Letter. 2 (epistle) any of the apostles' letters in the new testament. 3 poem etc. In the form of a letter. [greek epistole from stello send]</p>",
            },
        ],
    },
    {
        word: "Epistolary",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epistolary <strong class='adj'> adj. </strong> Of or in the form of a letter or letters. [latin: related to *epistle]</p>",
            },
        ],
    },
    {
        word: "Epitaph",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epitaph <strong class='noun'> n. </strong> Words written in memory of a dead person, esp. As a tomb inscription. [greek taphos tomb]</p>",
            },
        ],
    },
    {
        word: "Epithelium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epithelium <strong class='noun'> n. </strong> (pl. -s or -lia) tissue forming the outer layer of the body and lining many hollow structures. epithelial adj. [greek thele teat]</p>",
            },
        ],
    },
    {
        word: "Epithet",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epithet <strong class='noun'> n. </strong> 1 adjective etc. Expressing a quality or attribute. 2 this as a term of abuse. [greek tithemi place]</p>",
            },
        ],
    },
    {
        word: "Epitome",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epitome <strong class='noun'> n. </strong> 1 person or thing embodying a quality etc. 2 thing representing another in miniature. [greek temno cut]</p>",
            },
        ],
    },
    {
        word: "Epitomize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epitomize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) make or be a perfect example of (a quality etc.).</p>",
            },
        ],
    },
    {
        word: "Epns",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epns <strong class='sing'> abbr. </strong> Electroplated nickel silver.</p>",
            },
        ],
    },
    {
        word: "Epoch",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epoch <strong class='noun'> n. </strong> 1 period of history etc. Marked by notable events. 2 beginning of an era. 3 <strong class='geol'> geol. </strong> Division of a period, corresponding to a set of strata. epochal adj. [greek, = pause]</p>",
            },
        ],
    },
    {
        word: "Epoch-making",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epoch-making <strong class='adj'> adj. </strong> Remarkable; very important.</p>",
            },
        ],
    },
    {
        word: "Eponym",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eponym <strong class='noun'> n. </strong> 1 word, place-name, etc., derived from a person's name. 2 person whose name is used in this way. eponymous adj. [greek onoma name]</p>",
            },
        ],
    },
    {
        word: "Epos",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epos <strong class='sing'> abbr. </strong> Electronic point-of-sale (equipment recording stock, sales, etc. In shops).</p>",
            },
        ],
    },
    {
        word: "Epoxy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epoxy <strong class='adj'> adj. </strong> Relating to or derived from a compound with one oxygen atom and two carbon atoms bonded in a triangle. [from *epi-, *oxygen]</p>",
            },
        ],
    },
    {
        word: "Epoxy resin",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epoxy resin <strong class='noun'> n. </strong> Synthetic thermosetting resin.</p>",
            },
        ],
    },
    {
        word: "Epsilon",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epsilon <strong class='noun'> n. </strong> Fifth letter of the greek alphabet (e, e). [greek]</p>",
            },
        ],
    },
    {
        word: "Epsom salts",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Epsom salts <strong class='noun'> n. </strong> Magnesium sulphate used as a purgative etc. [epsom in s. England]</p>",
            },
        ],
    },
    {
        word: "Equable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equable <strong class='adj'> adj. </strong> 1 not varying. 2 moderate (equable climate). 3 (of a person) not easily disturbed. equably <strong class='adv'> adv. </strong> [related to *equal]</p>",
            },
        ],
    },
    {
        word: "Equal",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equal <strong class='adj'> —adj.</strong> 1 (often foll. By to, with) the same in quantity, quality, size, degree, level, etc. 2 evenly balanced (an equal contest). 3 having the same rights or status (human beings are essentially equal). 4 uniform in application or effect. <strong class='noun'> —n. </strong> Person or thing equal to another, esp. In rank or quality. —v. (-ll-; us -l-) 1 be equal to. 2 achieve something that is equal to. be equal to have the ability or resources for. [latin aequalis]</p>",
            },
        ],
    },
    {
        word: "Equality",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equality <strong class='noun'> n. </strong> Being equal. [latin: related to *equal]</p>",
            },
        ],
    },
    {
        word: "Equalize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 make or become equal. 2 reach one's opponent's score. equalization n.</p>",
            },
        ],
    },
    {
        word: "Equalizer",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equalizer <strong class='noun'> n. </strong> (also -iser) equalizing score or goal etc.</p>",
            },
        ],
    },
    {
        word: "Equally",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equally <strong class='adv'> adv. </strong> 1 in an equal manner (treated them equally). 2 to an equal degree (equally important). <strong class='usage'> Usage:- </strong>in sense 2, construction with as (e.g. Equally as important) is often found, but is considered incorrect by some people.</p>",
            },
        ],
    },
    {
        word: "Equal opportunity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equal opportunity <strong class='noun'> n. </strong> (often in pl.) Opportunity to compete on equal terms, regardless of sex, race, etc.</p>",
            },
        ],
    },
    {
        word: "Equanimity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equanimity <strong class='noun'> n. </strong> Composure, evenness of temper, esp. In adversity. [latin aequus even, animus mind]</p>",
            },
        ],
    },
    {
        word: "Equate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 (usu. Foll. By to, with) regard as equal or equivalent. 2 (foll. By with) be equal or equivalent to. equatable adj. [latin aequo aequat-: related to *equal]</p>",
            },
        ],
    },
    {
        word: "Equation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equation <strong class='noun'> n. </strong> 1 equating or making equal; being equal. 2 statement that two mathematical expressions are equal (indicated by the sign =). 3 formula indicating a chemical reaction by means of symbols.</p>",
            },
        ],
    },
    {
        word: "Equator",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equator <strong class='noun'> n. </strong> 1 imaginary line round the earth or other body, equidistant from the poles. 2 = *celestial equator. [medieval latin: related to *equate]</p>",
            },
        ],
    },
    {
        word: "Equatorial",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equatorial <strong class='adj'> adj. </strong> Of or near the equator.</p>",
            },
        ],
    },
    {
        word: "Equerry",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equerry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> officer attending the british royal family. [french esquierie stable]</p>",
            },
        ],
    },
    {
        word: "Equestrian",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equestrian <strong class='adj'> —adj.</strong> 1 of horse-riding. 2 on horseback. <strong class='noun'> —n. </strong> Rider or performer on horseback. equestrianism <strong class='noun'> n. </strong> [latin equestris from equus horse]</p>",
            },
        ],
    },
    {
        word: "Equi-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equi- <strong class='n_pl'> comb. </strong> Form equal. [latin: related to *equal]</p>",
            },
        ],
    },
    {
        word: "Equiangular",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equiangular <strong class='adj'> adj. </strong> Having equal angles.</p>",
            },
        ],
    },
    {
        word: "Equidistant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equidistant <strong class='adj'> adj. </strong> At equal distances.</p>",
            },
        ],
    },
    {
        word: "Equilateral",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equilateral <strong class='adj'> adj. </strong> Having all its sides equal in length.</p>",
            },
        ],
    },
    {
        word: "Equilibrium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equilibrium <strong class='noun'> n. </strong> (pl. -ria or -s) 1 state of physical balance. 2 state of composure. [latin libra balance]</p>",
            },
        ],
    },
    {
        word: "Equine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equine <strong class='adj'> adj. </strong> Of or like a horse. [latin equus horse]</p>",
            },
        ],
    },
    {
        word: "Equinoctial",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equinoctial <strong class='adj'> —adj.</strong> Happening at or near the time of an equinox. <strong class='noun'> —n. </strong> (in full equinoctial line) = *celestial equator. [latin: related to *equinox]</p>",
            },
        ],
    },
    {
        word: "Equinox",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equinox <strong class='noun'> n. </strong> Time or date (twice each year) at which the sun crosses the celestial equator, when day and night are of equal length. [latin nox noctis night]</p>",
            },
        ],
    },
    {
        word: "Enterprise",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enterprise <strong class='noun'> n. </strong> 1 undertaking, esp. A challenging one. 2 readiness to engage in such undertakings. 3 business firm or venture. [latin prehendo grasp]</p>",
            },
        ],
    },
    {
        word: "Enterprising",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enterprising <strong class='adj'> adj. </strong> Showing enterprise; resourceful, energetic. enterprisingly adv.</p>",
            },
        ],
    },
    {
        word: "Entertain",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entertain <strong class='verb'> v. </strong> 1 occupy agreeably. 2 a receive as a guest. B receive guests. 3 cherish, consider (an idea etc.). [latin teneo hold]</p>",
            },
        ],
    },
    {
        word: "Entertainer",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entertainer <strong class='noun'> n. </strong> Person who entertains, esp. Professionally.</p>",
            },
        ],
    },
    {
        word: "Entertaining",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entertaining <strong class='adj'> adj. </strong> Amusing, diverting. entertainingly adv.</p>",
            },
        ],
    },
    {
        word: "Entertainment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entertainment <strong class='noun'> n. </strong> 1 entertaining or being entertained. 2 thing that entertains; performance.</p>",
            },
        ],
    },
    {
        word: "Enthral",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enthral <strong class='verb'> v. </strong> (us enthrall) (-ll-) captivate, please greatly. enthralment <strong class='noun'> n. </strong> [from *en-1, *thrall]</p>",
            },
        ],
    },
    {
        word: "Enthrall",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enthrall <strong class='verb'> v. </strong> (brit. Enthral) (-ll-) captivate, please greatly. enthralment <strong class='noun'> n. </strong> [from *en-1, *thrall]</p>",
            },
        ],
    },
    {
        word: "Enthrone",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enthrone <strong class='verb'> v. </strong> (-ning) place on a throne, esp. Ceremonially. enthronement n.</p>",
            },
        ],
    },
    {
        word: "Enthuse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enthuse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> <strong class='colloq'> colloq. </strong> Be or make enthusiastic.</p>",
            },
        ],
    },
    {
        word: "Enthusiasm",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enthusiasm <strong class='noun'> n. </strong> 1 (often foll. By for, about) strong interest or admiration, great eagerness. 2 object of enthusiasm. [greek entheos inspired by a god]</p>",
            },
        ],
    },
    {
        word: "Enthusiast",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enthusiast <strong class='noun'> n. </strong> Person full of enthusiasm. [church latin: related to *enthusiasm]</p>",
            },
        ],
    },
    {
        word: "Enthusiastic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Enthusiastic <strong class='adj'> adj. </strong> Having enthusiasm. enthusiastically adv.</p>",
            },
        ],
    },
    {
        word: "Entice",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entice <strong class='verb'> v. </strong> (-cing) attract by the offer of pleasure or reward. enticement <strong class='noun'> n. </strong> Enticing adj. Enticingly <strong class='adv'> adv. </strong> [french enticier probably from romanic]</p>",
            },
        ],
    },
    {
        word: "Entire",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entire <strong class='adj'> adj. </strong> 1 whole, complete. 2 unbroken. 3 unqualified, absolute. 4 in one piece; continuous. [latin: related to *integer]</p>",
            },
        ],
    },
    {
        word: "Entirely",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entirely <strong class='adv'> adv. </strong> 1 wholly. 2 solely.</p>",
            },
        ],
    },
    {
        word: "Entirety",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entirety <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 completeness. 2 (usu. Foll. By of) sum total. in its entirety in its complete form.</p>",
            },
        ],
    },
    {
        word: "Entitle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entitle <strong class='verb'> v. </strong> (-ling) 1 (usu. Foll. By to) give (a person) a just claim or right. 2 give a title to. entitlement <strong class='noun'> n. </strong> [latin: related to *title]</p>",
            },
        ],
    },
    {
        word: "Entity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 thing with distinct existence. 2 thing's existence in itself. [latin ens ent- being]</p>",
            },
        ],
    },
    {
        word: "Entomb",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entomb <strong class='verb'> v. </strong> 1 place in a tomb. 2 serve as a tomb for. entombment <strong class='noun'> n. </strong> [french: related to *tomb]</p>",
            },
        ],
    },
    {
        word: "Entomology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entomology <strong class='noun'> n. </strong> The study of insects. entomological adj. Entomologist <strong class='noun'> n. </strong> [greek entomon insect]</p>",
            },
        ],
    },
    {
        word: "Entourage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entourage <strong class='noun'> n. </strong> People attending an important person. [french]</p>",
            },
        ],
    },
    {
        word: "Entr'acte",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entr'acte <strong class='noun'> n. </strong> 1 interval between acts of a play. 2 music or dance performed during this. [french]</p>",
            },
        ],
    },
    {
        word: "Entrails",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrails <strong class='n_pl'> n.pl. </strong> 1 bowels, intestines. 2 innermost parts of a thing. [latin inter among]</p>",
            },
        ],
    },
    {
        word: "Entrance1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrance1 <strong class='noun'> n. </strong> 1 place for entering. 2 going or coming in. 3 right of admission. 4 coming of an actor on stage. 5 (in full entrance fee) admission fee. [french: related to *enter]</p>",
            },
        ],
    },
    {
        word: "Entrance2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrance2 <strong class='verb'> v. </strong> (-cing) 1 enchant, delight. 2 put into a trance. entrancement <strong class='noun'> n. </strong> Entrancing adj. Entrancingly adv.</p>",
            },
        ],
    },
    {
        word: "Entrant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrant <strong class='noun'> n. </strong> Person who enters (an examination, profession, etc.). [french: related to *enter]</p>",
            },
        ],
    },
    {
        word: "Entrap",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrap <strong class='verb'> v. </strong> (-pp-) 1 catch in or as in a trap. 2 beguile. entrapment <strong class='noun'> n. </strong> [related to *en-1]</p>",
            },
        ],
    },
    {
        word: "Entreat",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entreat <strong class='verb'> v. </strong> Ask earnestly, beg. [related to *en-1]</p>",
            },
        ],
    },
    {
        word: "Entreaty",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entreaty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> earnest request.</p>",
            },
        ],
    },
    {
        word: "Entrecôte",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrecôte <strong class='noun'> n. </strong> Boned steak off the sirloin. [french, = between-rib]</p>",
            },
        ],
    },
    {
        word: "Entrée",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrée <strong class='noun'> n. </strong> 1 dish served between the fish and meat courses. 2 us main dish. 3 right of admission. [french]</p>",
            },
        ],
    },
    {
        word: "Entrench",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrench <strong class='verb'> v. </strong> 1 a establish firmly (in a position, office, etc.). B (as entrenched adj.) (of an attitude etc.) Not easily modified. 2 surround with a trench as a fortification. entrenchment n.</p>",
            },
        ],
    },
    {
        word: "Entrepôt",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrepôt <strong class='noun'> n. </strong> Warehouse for goods in transit. [french]</p>",
            },
        ],
    },
    {
        word: "Entrepreneur",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrepreneur <strong class='noun'> n. </strong> 1 person who undertakes a commercial venture. 2 contractor acting as an intermediary. entrepreneurial adj. Entrepreneurialism <strong class='noun'> n. </strong> (also entrepreneurism). [french: related to *enterprise]</p>",
            },
        ],
    },
    {
        word: "Entropy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entropy <strong class='noun'> n. </strong> 1 physics measure of the disorganization or degradation of the universe, resulting in a decrease in available energy. 2 physics measure of the unavailability of a system's thermal energy for conversion into mechanical work. [greek: related to *en-2, trope transformation]</p>",
            },
        ],
    },
    {
        word: "Entrust",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Entrust <strong class='verb'> v. </strong> (also intrust) 1 (foll. By to) give (a person or thing) into the care of a person. 2 (foll. By with) assign responsibility for (a person or thing) to (a person) (entrusted him with my camera).</p>",
            },
        ],
    },
    {
        word: "Equip",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equip <strong class='verb'> v. </strong> (-pp-) supply with what is needed. [old norse skipa to man a ship]</p>",
            },
        ],
    },
    {
        word: "Equipage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equipage <strong class='noun'> n. </strong> 1 archaic a requisites. B outfit. 2 hist. Carriage and horses with attendants. [french: related to *equip]</p>",
            },
        ],
    },
    {
        word: "Equipment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equipment <strong class='noun'> n. </strong> 1 necessary articles, clothing, etc. 2 equipping or being equipped. [french: related to *equip]</p>",
            },
        ],
    },
    {
        word: "Equipoise",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equipoise <strong class='noun'> n. </strong> 1 equilibrium. 2 counterbalancing thing.</p>",
            },
        ],
    },
    {
        word: "Equitable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equitable <strong class='adj'> adj. </strong> 1 fair, just. 2 law valid in equity as distinct from law. equitably <strong class='adv'> adv. </strong> [french: related to *equity]</p>",
            },
        ],
    },
    {
        word: "Equitation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equitation <strong class='noun'> n. </strong> Horsemanship; horse-riding. [latin equito ride a horse]</p>",
            },
        ],
    },
    {
        word: "Equity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 fairness. 2 principles of justice used to correct or supplement the law. 3 a value of the shares issued by a company. B (in pl.) Stocks and shares not bearing fixed interest. [latin aequitas: related to *equal]</p>",
            },
        ],
    },
    {
        word: "Equivalent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equivalent <strong class='adj'> —adj.</strong> 1 <strong class='adj'> (often foll. By to) </strong> equal in value, amount, importance, etc. 2 corresponding. 3 having the same meaning or result. <strong class='noun'> —n. </strong> Equivalent thing, amount, etc. equivalence <strong class='noun'> n. </strong> [latin: related to *value]</p>",
            },
        ],
    },
    {
        word: "Equivocal",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equivocal <strong class='adj'> adj. </strong> 1 of double or doubtful meaning. 2 of uncertain nature. 3 (of a person etc.) Questionable. equivocally <strong class='adv'> adv. </strong> [latin voco call]</p>",
            },
        ],
    },
    {
        word: "Equivocate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Equivocate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> use ambiguity to conceal the truth. equivocation <strong class='noun'> n. </strong> Equivocator <strong class='noun'> n. </strong> [latin: related to *equivocal]</p>",
            },
        ],
    },
    {
        word: "Er",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Er <strong class='sing'> abbr. </strong> Queen elizabeth. [latin elizabetha regina]</p>",
            },
        ],
    },
    {
        word: "Er",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>Er <strong class='prep'> symb. </strong> Erbium.</p>",
            },
        ],
    },
    {
        word: "Er",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Er <strong class='n_pl'> int. </strong> Expressing hesitation. [imitative]</p>",
            },
        ],
    },
    {
        word: "-er1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-er1 <strong class='slang_v'> suffix </strong>forming nouns from nouns, adjectives, and verbs, denoting: 1 person, animal, or thing that does (cobbler; poker). 2 person or thing that is (foreigner; four-wheeler). 3 person concerned with (hatter; geographer). 4 person from (villager; sixth-former). [old english]</p>",
            },
        ],
    },
    {
        word: "-er2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-er2 <strong class='slang_v'> suffix </strong>forming the comparative of adjectives (wider) and adverbs (faster). [old english]</p>",
            },
        ],
    },
    {
        word: "-er3",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-er3 <strong class='slang_v'> suffix </strong>used in a slang distortion of the word (rugger). [probably an extension of *-er1]</p>",
            },
        ],
    },
    {
        word: "Era",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Era <strong class='noun'> n. </strong> 1 system of chronology reckoning from a noteworthy event (christian era). 2 large period, esp. Regarded historically. 3 date at which an era begins. 4 major division of geological time. [latin, = number (pl. Of aes money)]</p>",
            },
        ],
    },
    {
        word: "Eradicate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eradicate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> root out; destroy completely. eradicable adj. Eradication <strong class='noun'> n. </strong> Eradicator <strong class='noun'> n. </strong> [latin radix -icis root]</p>",
            },
        ],
    },
    {
        word: "Erase",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erase <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 rub out; obliterate. 2 remove all traces of. 3 remove recorded material from (magnetic tape or disk). [latin rado ras- scrape]</p>",
            },
        ],
    },
    {
        word: "Eraser",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eraser <strong class='noun'> n. </strong> Thing that erases, esp. A piece of rubber etc. For removing pencil etc. Marks.</p>",
            },
        ],
    },
    {
        word: "Erasure",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erasure <strong class='noun'> n. </strong> 1 erasing. 2 erased word etc.</p>",
            },
        ],
    },
    {
        word: "Erbium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erbium <strong class='noun'> n. </strong> Metallic element of the lanthanide series. [ytterby in sweden]</p>",
            },
        ],
    },
    {
        word: "Ere",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ere <strong class='n_pl'> comb. </strong> & <strong class='conj'> conj. </strong> Poet. Or archaic before (of time) (ere noon; ere they come). [old english]</p>",
            },
        ],
    },
    {
        word: "Erect",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erect <strong class='adj'> —adj.</strong> 1 upright, vertical. 2 (of the penis etc.) Enlarged and rigid, esp. In sexual excitement. 3 (of hair) bristling. —v. 1 set up; build. 2 establish. erection <strong class='noun'> n. </strong> Erectly <strong class='adv'> adv. </strong> Erectness <strong class='noun'> n. </strong> [latin erigere erect- set up]</p>",
            },
        ],
    },
    {
        word: "Erectile",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erectile <strong class='adj'> adj. </strong> That can become erect (esp. Of body tissue in sexual excitement). [french: related to *erect]</p>",
            },
        ],
    },
    {
        word: "Erg",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erg <strong class='noun'> n. </strong> Unit of work or energy. [greek ergon work]</p>",
            },
        ],
    },
    {
        word: "Ergo",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ergo <strong class='adv'> adv. </strong> Therefore. [latin]</p>",
            },
        ],
    },
    {
        word: "Ergonomics",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ergonomics <strong class='noun'> n. </strong> The study of the relationship between people and their working environment. ergonomic adj. [greek ergon work]</p>",
            },
        ],
    },
    {
        word: "Ergot",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ergot <strong class='noun'> n. </strong> Disease of rye etc. Caused by a fungus. [french]</p>",
            },
        ],
    },
    {
        word: "Erin",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erin <strong class='noun'> n. </strong> Poet. Ireland. [irish]</p>",
            },
        ],
    },
    {
        word: "Erm",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erm <strong class='sing'> abbr. </strong> Exchange rate mechanism.</p>",
            },
        ],
    },
    {
        word: "Ermine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ermine <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> 1 stoat, esp. When white in winter. 2 its white fur, used to trim robes etc. [french]</p>",
            },
        ],
    },
    {
        word: "Ernie",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ernie <strong class='noun'> n. </strong> Device for drawing prize-winning numbers of premium bonds. [electronic random number indicator equipment]</p>",
            },
        ],
    },
    {
        word: "Erode",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erode <strong class='verb'> v. </strong> (-ding) wear away, destroy gradually. erosion <strong class='noun'> n. </strong> Erosive adj. [latin rodo ros- gnaw]</p>",
            },
        ],
    },
    {
        word: "Erogenous",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erogenous <strong class='adj'> adj. </strong> (of a part of the body) particularly sensitive to sexual stimulation. [greek (as *erotic), *-genous]</p>",
            },
        ],
    },
    {
        word: "Erotic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erotic <strong class='adj'> adj. </strong> Of or causing sexual love, esp. Tending to arouse sexual desire or excitement. erotically <strong class='adv'> adv. </strong> [greek eros sexual love]</p>",
            },
        ],
    },
    {
        word: "Erotica",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erotica <strong class='n_pl'> n.pl. </strong> Erotic literature or art.</p>",
            },
        ],
    },
    {
        word: "Eroticism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eroticism <strong class='noun'> n. </strong> 1 erotic character. 2 use of or response to erotic images or stimulation.</p>",
            },
        ],
    },
    {
        word: "Err",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Err <strong class='verb'> v. </strong> 1 be mistaken or incorrect. 2 do wrong; sin. [latin erro stray]</p>",
            },
        ],
    },
    {
        word: "Errand",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Errand <strong class='noun'> n. </strong> 1 short journey, esp. On another's behalf, to take a message, collect goods, etc. 2 object of such a journey. [old english]</p>",
            },
        ],
    },
    {
        word: "Errand of mercy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Errand of mercy <strong class='noun'> n. </strong> Journey to relieve suffering etc.</p>",
            },
        ],
    },
    {
        word: "Errant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Errant <strong class='adj'> adj. </strong> 1 erring. 2 literary or archaic travelling in search of adventure (knight errant). errantry <strong class='noun'> n. </strong> (in sense 2). [from *err: sense 2 ultimately from latin iter journey]</p>",
            },
        ],
    },
    {
        word: "Erratic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erratic <strong class='adj'> adj. </strong> 1 inconsistent in conduct, opinions, etc. 2 uncertain in movement. erratically <strong class='adv'> adv. </strong> [latin: related to *err]</p>",
            },
        ],
    },
    {
        word: "Erratum",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erratum <strong class='noun'> n. </strong> (pl. Errata) error in printing or writing. [latin: related to *err]</p>",
            },
        ],
    },
    {
        word: "Erroneous",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erroneous <strong class='adj'> adj. </strong> Incorrect. erroneously <strong class='adv'> adv. </strong> [latin: related to *err]</p>",
            },
        ],
    },
    {
        word: "Error",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Error <strong class='noun'> n. </strong> 1 mistake. 2 condition of being morally wrong (led into error). 3 degree of inaccuracy in a calculation etc. (2% error). [latin: related to *err]</p>",
            },
        ],
    },
    {
        word: "Ersatz",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ersatz <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> Substitute, imitation. [german]</p>",
            },
        ],
    },
    {
        word: "Erse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erse <strong class='adj'> —adj.</strong> Irish or highland gaelic. <strong class='noun'> —n. </strong> The gaelic language. [early scots form of *irish]</p>",
            },
        ],
    },
    {
        word: "Erstwhile",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erstwhile <strong class='adj'> —adj.</strong> Former, previous. <strong class='adv'> —adv. </strong> Archaic formerly. [related to *ere]</p>",
            },
        ],
    },
    {
        word: "Eructation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eructation <strong class='noun'> n. </strong> Formal belching. [latin ructo belch]</p>",
            },
        ],
    },
    {
        word: "Erudite",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erudite <strong class='adj'> adj. </strong> Learned. erudition <strong class='noun'> n. </strong> [latin eruditus instructed: related to *rude]</p>",
            },
        ],
    },
    {
        word: "Erupt",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erupt <strong class='verb'> v. </strong> 1 break out suddenly or dramatically. 2 (of a volcano) eject lava etc. 3 (of a rash etc.) Appear on the skin. eruption <strong class='noun'> n. </strong> Eruptive adj. [latin erumpo erupt- break out]</p>",
            },
        ],
    },
    {
        word: "-ery",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ery <strong class='slang_v'> suffix </strong>(also -ry) forming nouns denoting: 1 class or kind (greenery; machinery; citizenry). 2 employment; state or condition (dentistry; slavery). 3 place of work or cultivation or breeding (brewery; rookery). 4 behaviour (mimicry). 5 often derog. All that has to do with (popery). [french -erie]</p>",
            },
        ],
    },
    {
        word: "Erysipelas",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erysipelas <strong class='noun'> n. </strong> Disease causing fever and a deep red inflammation of the skin. [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Erythrocyte",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Erythrocyte <strong class='noun'> n. </strong> Red blood cell. [greek eruthros red, *-cyte]</p>",
            },
        ],
    },
    {
        word: "Es",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>Es <strong class='prep'> symb. </strong> Einsteinium.</p>",
            },
        ],
    },
    {
        word: "Escalate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escalate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 increase or develop (usu. Rapidly) by stages. 2 make or become more intense. escalation <strong class='noun'> n. </strong> [from *escalator]</p>",
            },
        ],
    },
    {
        word: "Escalator",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escalator <strong class='noun'> n. </strong> Moving staircase consisting of a circulating belt forming steps. [latin scala ladder]</p>",
            },
        ],
    },
    {
        word: "Escalope",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escalope <strong class='noun'> n. </strong> Thin slice of boneless meat, esp. Veal. [french, originally = shell]</p>",
            },
        ],
    },
    {
        word: "Escapade",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escapade <strong class='noun'> n. </strong> Piece of reckless behaviour. [french from provençal or spanish: related to *escape]</p>",
            },
        ],
    },
    {
        word: "Escape",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escape <strong class='noun'> —v. </strong> (-ping) 1 (often foll. By from) get free of restriction or control. 2 (of gas etc.) Leak. 3 succeed in avoiding punishment etc. 4 get free of (a person, grasp, etc.). 5 avoid (a commitment, danger, etc.). 6 elude the notice or memory of (nothing escapes you; name escaped me). 7 (of words etc.) Issue unawares from (a person etc.). <strong class='noun'> —n. </strong> 1 act or instance of escaping. 2 means of escaping (often attrib.: escape hatch). 3 leakage of gas etc. 4 temporary relief from unpleasant reality. [latin cappa cloak]</p>",
            },
        ],
    },
    {
        word: "Escape clause",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escape clause <strong class='noun'> n. </strong> Law clause specifying conditions under which a contracting party is free from an obligation.</p>",
            },
        ],
    },
    {
        word: "Escapee",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escapee <strong class='noun'> n. </strong> Person who has escaped.</p>",
            },
        ],
    },
    {
        word: "Escapement",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escapement <strong class='noun'> n. </strong> Part of a clock etc. That connects and regulates the motive power. [french: related to *escape]</p>",
            },
        ],
    },
    {
        word: "Escape velocity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escape velocity <strong class='noun'> n. </strong> Minimum velocity needed to escape from the gravitational field of a body.</p>",
            },
        ],
    },
    {
        word: "Escapism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escapism <strong class='noun'> n. </strong> Pursuit of distraction and relief from reality. escapist <strong class='noun'> n. </strong> & adj.</p>",
            },
        ],
    },
    {
        word: "Escapology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escapology <strong class='noun'> n. </strong> Techniques of escaping from confinement, esp. As entertainment. escapologist n.</p>",
            },
        ],
    },
    {
        word: "Escarpment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escarpment <strong class='noun'> n. </strong> Long steep slope at the edge of a plateau etc. [french from italian: related to *scarp]</p>",
            },
        ],
    },
    {
        word: "Eschatology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eschatology <strong class='noun'> n. </strong> Theology of death and final destiny. eschatological adj. [greek eskhatos last]</p>",
            },
        ],
    },
    {
        word: "Escheat  hist.",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escheat hist. <strong class='noun'> —n. </strong> 1 reversion of property to the state etc. In the absence of legal heirs. 2 property so affected. —v. 1 hand over (property) as an escheat. 2 confiscate. 3 revert by escheat. [latin cado fall]</p>",
            },
        ],
    },
    {
        word: "Eschew",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eschew <strong class='verb'> v. </strong> Formal avoid; abstain from. eschewal <strong class='noun'> n. </strong> [germanic: related to *shy1]</p>",
            },
        ],
    },
    {
        word: "Escort",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escort <strong class='noun'> —n. </strong> 1 one or more persons, vehicles, etc., accompanying a person, vehicle, etc., for protection or as a mark of status. 2 person accompanying a person of the opposite sex socially. —v. Act as an escort to. [french from italian]</p>",
            },
        ],
    },
    {
        word: "Escritoire",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escritoire <strong class='noun'> n. </strong> Writing-desk with drawers etc. [french from latin scriptorium writing-room]</p>",
            },
        ],
    },
    {
        word: "Escudo",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escudo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> chief monetary unit of portugal. [spanish and portuguese from latin scutum shield]</p>",
            },
        ],
    },
    {
        word: "Escutcheon",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Escutcheon <strong class='noun'> n. </strong> Shield or emblem bearing a coat of arms. [latin scutum shield]</p>",
            },
        ],
    },
    {
        word: "Eskimo",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eskimo <strong class='noun'> —n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> 1 member of a people inhabiting <strong class='noun'> n. </strong> Canada, alaska, greenland, and e. Siberia. 2 language of this people. <strong class='adj'> —adj.</strong> Of eskimos or their language. [algonquian] <strong class='usage'> Usage:- </strong>the eskimos of <strong class='noun'> n. </strong> America prefer the name inuit.</p>",
            },
        ],
    },
    {
        word: "Esn",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esn <strong class='sing'> abbr. </strong> Educationally subnormal.</p>",
            },
        ],
    },
    {
        word: "Esophagus",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esophagus <strong class='noun'> n. </strong> (brit. Oesophagus) (pl. -gi or -guses) passage from the mouth to the stomach; gullet. [greek]</p>",
            },
        ],
    },
    {
        word: "Esoteric",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esoteric <strong class='adj'> adj. </strong> Intelligible only to those with special knowledge. esoterically <strong class='adv'> adv. </strong> [greek eso within]</p>",
            },
        ],
    },
    {
        word: "Esp",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esp <strong class='sing'> abbr. </strong> Extrasensory perception.</p>",
            },
        ],
    },
    {
        word: "Espadrille",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Espadrille <strong class='noun'> n. </strong> Light canvas shoe with a plaited fibre sole. [provençal: related to *esparto]</p>",
            },
        ],
    },
    {
        word: "Espalier",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Espalier <strong class='noun'> n. </strong> 1 lattice-work along which the branches of a tree or shrub are trained. 2 tree or shrub so trained. [french from italian]</p>",
            },
        ],
    },
    {
        word: "Esparto",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esparto <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> (in full esparto grass) coarse grass of spain and <strong class='noun'> n. </strong> Africa, used to make good-quality paper etc. [greek sparton rope]</p>",
            },
        ],
    },
    {
        word: "Especial",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Especial <strong class='adj'> adj. </strong> Notable. [latin: related to *special]</p>",
            },
        ],
    },
    {
        word: "Especially",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Especially <strong class='adv'> adv. </strong> 1 in particular. 2 much more than in other cases. 3 particularly.</p>",
            },
        ],
    },
    {
        word: "Esperanto",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esperanto <strong class='noun'> n. </strong> An artificial language designed for universal use. [latin spero hope]</p>",
            },
        ],
    },
    {
        word: "Espionage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Espionage <strong class='noun'> n. </strong> Spying or use of spies. [french: related to *spy]</p>",
            },
        ],
    },
    {
        word: "Esplanade",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esplanade <strong class='noun'> n. </strong> 1 long open level area for walking on, esp. Beside the sea. 2 level space separating a fortress from a town. [latin planus level]</p>",
            },
        ],
    },
    {
        word: "Espousal",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Espousal <strong class='noun'> n. </strong> 1 (foll. By of) espousing of (a cause etc.). 2 archaic marriage, betrothal.</p>",
            },
        ],
    },
    {
        word: "Espouse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Espouse <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 adopt or support (a cause, doctrine, etc.). 2 archaic a (usu. Of a man) marry. B (usu. Foll. By to) give (a woman) in marriage. [latin spondeo betroth]</p>",
            },
        ],
    },
    {
        word: "Ex-servicewoman",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex-servicewoman <strong class='noun'> n. </strong> Woman formerly a member of the armed forces.</p>",
            },
        ],
    },
    {
        word: "Extant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extant <strong class='adj'> adj. </strong> Still existing. [latin ex(s)to exist]</p>",
            },
        ],
    },
    {
        word: "Extemporaneous",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extemporaneous <strong class='adj'> adj. </strong> Spoken or done without preparation. extemporaneously <strong class='adv'> adv. </strong> [from *extempore]</p>",
            },
        ],
    },
    {
        word: "Extemporary",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extemporary <strong class='adj'> adj. </strong> = *extemporaneous. extemporarily adv.</p>",
            },
        ],
    },
    {
        word: "Extempore",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extempore <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Without preparation. [latin]</p>",
            },
        ],
    },
    {
        word: "Extemporize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extemporize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) improvise. extemporization n.</p>",
            },
        ],
    },
    {
        word: "Extend",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extend <strong class='verb'> v. </strong> 1 lengthen or make larger in space or time. 2 stretch or lay out at full length. 3 (foll. By to, over) reach or be or make continuous over a specified area. 4 (foll. By to) have a specified scope (permit does not extend to camping). 5 offer or accord (an invitation, hospitality, kindness, etc.). 6 (usu. Refl. Or in passive) tax the powers of (an athlete, horse, etc.). extendible adj. (also extensible). [latin extendo -tens-: related to *tend1]</p>",
            },
        ],
    },
    {
        word: "Extended family",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extended family <strong class='noun'> n. </strong> Family including relatives living near.</p>",
            },
        ],
    },
    {
        word: "Extended-play",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extended-play <strong class='adj'> adj. </strong> (of a gramophone record) playing for somewhat longer than most singles.</p>",
            },
        ],
    },
    {
        word: "Extension",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extension <strong class='noun'> n. </strong> 1 extending or being extended. 2 part enlarging or added on to a main building etc. 3 additional part. 4 a subsidiary telephone on the same line as the main one. B its number. 5 additional period of time. 6 extramural instruction by a university or college.</p>",
            },
        ],
    },
    {
        word: "Extensive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extensive <strong class='adj'> adj. </strong> 1 covering a large area. 2 far-reaching. extensively <strong class='adv'> adv. </strong> Extensiveness <strong class='noun'> n. </strong> [latin: related to *extend]</p>",
            },
        ],
    },
    {
        word: "Extent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extent <strong class='noun'> n. </strong> 1 space over which a thing extends. 2 range, scope, degree. [anglo-french: related to *extend]</p>",
            },
        ],
    },
    {
        word: "Extenuate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extenuate <strong class='verb'> v. </strong> (often as extenuating adj.) Make (guilt or an offence) seem less serious by reference to another factor. extenuation <strong class='noun'> n. </strong> [latin tenuis thin]</p>",
            },
        ],
    },
    {
        word: "Exterior",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exterior <strong class='adj'> —adj.</strong> 1 of or on the outer side. 2 coming from outside. <strong class='noun'> —n. </strong> 1 outward aspect or surface of a building etc. 2 outward demeanour. 3 outdoor scene in filming. [latin]</p>",
            },
        ],
    },
    {
        word: "Exterminate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exterminate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> destroy utterly (esp. A living thing). extermination <strong class='noun'> n. </strong> Exterminator <strong class='noun'> n. </strong> [latin: related to *terminal]</p>",
            },
        ],
    },
    {
        word: "External",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>External <strong class='adj'> —adj.</strong> 1 a of or on the outside or visible part. B coming from the outside or an outside source. 2 relating to a country's foreign affairs. 3 outside the conscious subject (the external world). 4 (of medicine etc.) For use on the outside of the body. 5 for students taking the examinations of a university without attending it. <strong class='noun'> —n. </strong> (in pl.) 1 outward features or aspect. 2 external circumstances. 3 inessentials. externality <strong class='noun'> n. </strong> Externally <strong class='adv'> adv. </strong> [latin externus outer]</p>",
            },
        ],
    },
    {
        word: "Externalize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Externalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) give or attribute external existence to. externalization n.</p>",
            },
        ],
    },
    {
        word: "Extinct",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extinct <strong class='adj'> adj. </strong> 1 that has died out. 2 a no longer burning. B (of a volcano) that no longer erupts. 3 obsolete. [latin ex(s)tinguo -stinct- quench]</p>",
            },
        ],
    },
    {
        word: "Extinction",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extinction <strong class='noun'> n. </strong> 1 making or becoming extinct. 2 extinguishing or being extinguished. 3 total destruction or annihilation.</p>",
            },
        ],
    },
    {
        word: "Extinguish",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extinguish <strong class='verb'> v. </strong> 1 cause (a flame, light, etc.) To die out. 2 destroy. 3 terminate. 4 wipe out (a debt). extinguishable adj.</p>",
            },
        ],
    },
    {
        word: "Extinguisher",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extinguisher <strong class='noun'> n. </strong> = *fire extinguisher.</p>",
            },
        ],
    },
    {
        word: "Extirpate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extirpate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> root out; destroy completely. extirpation <strong class='noun'> n. </strong> [latin ex(s)tirpo from stirps stem of tree]</p>",
            },
        ],
    },
    {
        word: "Extol",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extol <strong class='verb'> v. </strong> (-ll-) praise enthusiastically. [latin tollo raise]</p>",
            },
        ],
    },
    {
        word: "Extort",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extort <strong class='verb'> v. </strong> Obtain by coercion. [latin torqueo tort- twist]</p>",
            },
        ],
    },
    {
        word: "Extortion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extortion <strong class='noun'> n. </strong> 1 act of extorting, esp. Money. 2 illegal exaction. extortioner <strong class='noun'> n. </strong> Extortionist n.</p>",
            },
        ],
    },
    {
        word: "Extortionate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extortionate <strong class='adj'> adj. </strong> (of a price etc.) Exorbitant. extortionately adv.</p>",
            },
        ],
    },
    {
        word: "Extra",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extra <strong class='adj'> —adj.</strong> Additional; more than usual or necessary or expected. <strong class='adv'> —adv. </strong> 1 more than usually. 2 additionally (was charged extra). <strong class='noun'> —n. </strong> 1 extra thing. 2 thing for which an extra charge is made. 3 person engaged temporarily for a minor part in a film. 4 special issue of a newspaper etc. 5 cricket run scored other than from a hit with the bat. [probably from *extraordinary]</p>",
            },
        ],
    },
    {
        word: "Extra-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extra- <strong class='n_pl'> comb. </strong> Form 1 outside, beyond. 2 beyond the scope of. [latin extra outside]</p>",
            },
        ],
    },
    {
        word: "Extra-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extra- <strong class='n_pl'> comb. </strong> Form 1 outside, beyond. 2 beyond the scope of. [latin extra outside]</p>",
            },
        ],
    },
    {
        word: "Extra cover",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extra cover <strong class='noun'> n. </strong> Cricket 1 fielding position on a line between cover-point and mid-off, but beyond these. 2 fielder at this position.</p>",
            },
        ],
    },
    {
        word: "Extract",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extract <strong class='noun'> —v. </strong> 1 remove or take out, esp. By effort or force. 2 obtain (money, an admission, etc.) Against a person's will. 3 obtain (a natural resource) from the earth. 4 select or reproduce for quotation or performance. 5 obtain (juice etc.) By pressure, distillation, etc. 6 derive (pleasure etc.). 7 find (the root of a number). <strong class='noun'> —n. </strong> 1 short passage from a book etc. 2 preparation containing a concentrated constituent of a substance (malt extract). [latin traho tract- draw]</p>",
            },
        ],
    },
    {
        word: "Extraction",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extraction <strong class='noun'> n. </strong> 1 extracting or being extracted. 2 removal of a tooth. 3 lineage, descent (of indian extraction). [latin: related to *extract]</p>",
            },
        ],
    },
    {
        word: "Extractive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extractive <strong class='adj'> adj. </strong> Of or involving extraction.</p>",
            },
        ],
    },
    {
        word: "Extractor",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extractor <strong class='noun'> n. </strong> 1 person or machine that extracts. 2 (attrib.) (of a device) that extracts bad air etc.</p>",
            },
        ],
    },
    {
        word: "Extracurricular",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extracurricular <strong class='adj'> adj. </strong> Not part of the normal curriculum.</p>",
            },
        ],
    },
    {
        word: "Extraditable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extraditable <strong class='adj'> adj. </strong> 1 liable to extradition. 2 (of a crime) warranting extradition.</p>",
            },
        ],
    },
    {
        word: "Extradite",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extradite <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> hand over (a person accused or convicted of a crime) to the foreign state etc. In which the crime was committed. extradition <strong class='noun'> n. </strong> [french: related to *tradition]</p>",
            },
        ],
    },
    {
        word: "Extramarital",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extramarital <strong class='adj'> adj. </strong> (esp. Of sexual relations) occurring outside marriage.</p>",
            },
        ],
    },
    {
        word: "Extramural",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extramural <strong class='adj'> adj. </strong> Additional to normal teaching or studies, esp. For non-resident students.</p>",
            },
        ],
    },
    {
        word: "Extraneous",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extraneous <strong class='adj'> adj. </strong> 1 of external origin. 2 <strong class='adj'> (often foll. By to) </strong> a separate from the object to which it is attached etc. B irrelevant, unrelated. [latin extraneus]</p>",
            },
        ],
    },
    {
        word: "Extraordinary",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extraordinary <strong class='adj'> adj. </strong> 1 unusual or remarkable. 2 unusually great. 3 (of a meeting, official, etc.) Additional; specially employed. extraordinarily <strong class='adv'> adv. </strong> [latin]</p>",
            },
        ],
    },
    {
        word: "Extrapolate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extrapolate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (also absol.) Calculate approximately from known data etc. (others which lie outside the range of those known). extrapolation <strong class='noun'> n. </strong> [from *extra-, *interpolate]</p>",
            },
        ],
    },
    {
        word: "Extrasensory",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extrasensory <strong class='adj'> adj. </strong> Derived by means other than the known senses, e.g. By telepathy.</p>",
            },
        ],
    },
    {
        word: "Extraterrestrial",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extraterrestrial <strong class='adj'> —adj.</strong> Outside the earth or its atmosphere. <strong class='noun'> —n. </strong> (in science fiction) being from outer space.</p>",
            },
        ],
    },
    {
        word: "Extravagant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extravagant <strong class='adj'> adj. </strong> 1 spending money excessively. 2 excessive; absurd. 3 costing much. extravagance <strong class='noun'> n. </strong> Extravagantly <strong class='adv'> adv. </strong> [latin vagor wander]</p>",
            },
        ],
    },
    {
        word: "Extravaganza",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extravaganza <strong class='noun'> n. </strong> 1 spectacular theatrical or television production. 2 fanciful literary, musical, or dramatic composition. [italian]</p>",
            },
        ],
    },
    {
        word: "Extreme",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extreme <strong class='adj'> —adj.</strong> 1 of a high, or the highest, degree (extreme danger). 2 severe (extreme measures). 3 outermost. 4 on the far left or right of a political party. 5 utmost; last. <strong class='noun'> —n. </strong> 1 (often in pl.) Either of two things as remote or as different as possible. 2 thing at either end. 3 highest degree. 4 math. First or last term of a ratio or series. go to extremes take an extreme course of action. In the extreme to an extreme degree. extremely <strong class='adv'> adv. </strong> [french from latin]</p>",
            },
        ],
    },
    {
        word: "Extreme unction",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extreme unction <strong class='noun'> n. </strong> Last rites in the roman catholic and orthodox churches.</p>",
            },
        ],
    },
    {
        word: "Extremist",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extremist <strong class='noun'> n. </strong> (also attrib.) Person with extreme views. extremism n.</p>",
            },
        ],
    },
    {
        word: "Extremity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extremity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 extreme point; very end. 2 (in pl.) The hands and feet. 3 condition of extreme adversity. [latin: related to *extreme]</p>",
            },
        ],
    },
    {
        word: "Extricate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extricate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (often foll. By from) free or disentangle from a difficulty etc. extricable adj. Extrication <strong class='noun'> n. </strong> [latin tricae perplexities]</p>",
            },
        ],
    },
    {
        word: "Extrinsic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extrinsic <strong class='adj'> adj. </strong> 1 not inherent or intrinsic. 2 <strong class='adj'> (often foll. By to) </strong> extraneous; not belonging. extrinsically <strong class='adv'> adv. </strong> [latin extrinsecus outwardly]</p>",
            },
        ],
    },
    {
        word: "Extrovert",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extrovert <strong class='noun'> —n. </strong> 1 outgoing person. 2 person mainly concerned with external things. <strong class='adj'> —adj.</strong> Typical of or with the nature of an extrovert. extroversion <strong class='noun'> n. </strong> Extroverted adj. [latin verto turn]</p>",
            },
        ],
    },
    {
        word: "Extrude",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Extrude <strong class='verb'> v. </strong> (-ding) 1 (foll. By from) thrust or force out. 2 shape metal, plastics, etc. By forcing them through a die. extrusion <strong class='noun'> n. </strong> Extrusive adj. [latin extrudo -trus- thrust out]</p>",
            },
        ],
    },
    {
        word: "Exuberant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exuberant <strong class='adj'> adj. </strong> 1 lively, high-spirited. 2 (of a plant etc.) Prolific. 3 (of feelings etc.) Abounding. exuberance <strong class='noun'> n. </strong> Exuberantly <strong class='adv'> adv. </strong> [latin uber fertile]</p>",
            },
        ],
    },
    {
        word: "Exude",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exude <strong class='verb'> v. </strong> (-ding) 1 ooze out. 2 emit (a smell). 3 display (an emotion etc.) Freely. exudation <strong class='noun'> n. </strong> [latin sudo sweat]</p>",
            },
        ],
    },
    {
        word: "Exult",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exult <strong class='verb'> v. </strong> Be joyful. exultation <strong class='noun'> n. </strong> Exultant adj. Exultantly <strong class='adv'> adv. </strong> [latin ex(s)ulto from salio salt- leap]</p>",
            },
        ],
    },
    {
        word: "-ey",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>-ey <strong class='var'> var. </strong> Of *-y2.</p>",
            },
        ],
    },
    {
        word: "Eye",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eye <strong class='noun'> —n. </strong> 1 organ of sight. 2 eye characterized by the colour of the iris (has blue eyes). 3 region round the eye (eyes swollen from weeping). 4 (in sing. Or pl.) Sight. 5 particular visual ability (a straight eye). 6 thing like an eye, esp.: a a spot on a peacock's tail. B a leaf bud of a potato. 7 calm region at the centre of a hurricane etc. 8 hole of a needle. —v. (eyes, eyed, eyeing or eying) (often foll. By up) watch or observe closely, esp. Admiringly or with suspicion. all eyes watching intently. An eye for an eye retaliation in kind. Have an eye for be discerning about. Have one's eye on wish or plan to procure. Have eyes for be interested in; wish to acquire. Keep an eye on 1 watch. 2 look after. Keep an eye open (or out) (often foll. By for) watch carefully. Keep one's eyes open (or peeled or skinned) watch out; be on the alert. Make eyes (or sheep's eyes) (foll. By at) look amorously or flirtatiously at. One in the eye (foll. By for) disappointment or setback. See eye to eye (often foll. By with) agree. Set eyes on see. Up to the (or one's) eyes in deeply engaged or involved in. With one's eyes shut (or closed) with little effort. With an eye to with a view to. [old english]</p>",
            },
        ],
    },
    {
        word: "Eyeball",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyeball <strong class='noun'> —n. </strong> Ball of the eye within the lids and socket. —v. Us slang look or stare (at).</p>",
            },
        ],
    },
    {
        word: "Eyeball to eyeball",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyeball to eyeball <strong class='adv'> adv. </strong> Colloq. Confronting closely.</p>",
            },
        ],
    },
    {
        word: "Eyebath",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyebath <strong class='noun'> n. </strong> Small vessel for applying lotion etc. To the eye.</p>",
            },
        ],
    },
    {
        word: "Eyebright",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyebright <strong class='noun'> n. </strong> Plant used as a remedy for weak eyes.</p>",
            },
        ],
    },
    {
        word: "Eyebrow",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyebrow <strong class='noun'> n. </strong> Line of hair on the ridge above the eye-socket. raise one's eyebrows show surprise, disbelief, or disapproval.</p>",
            },
        ],
    },
    {
        word: "Eye-catching",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eye-catching <strong class='adj'> adj. </strong> Colloq. Striking.</p>",
            },
        ],
    },
    {
        word: "Eyeful",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyeful <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='colloq'> colloq. </strong> 1 (esp. In phr. Get an eyeful (of)) good look; as much as the eye can take in. 2 visually striking person or thing. 3 thing thrown or blown into the eye.</p>",
            },
        ],
    },
    {
        word: "Eyeglass",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyeglass <strong class='noun'> n. </strong> Lens to assist defective sight.</p>",
            },
        ],
    },
    {
        word: "Eyehole",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyehole <strong class='noun'> n. </strong> Hole to look through.</p>",
            },
        ],
    },
    {
        word: "Eyelash",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyelash <strong class='noun'> n. </strong> Each of the hairs growing on the edges of the eyelids.</p>",
            },
        ],
    },
    {
        word: "Eyelet",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyelet <strong class='noun'> n. </strong> 1 small hole for string or rope etc. To pass through. 2 metal ring strengthening this. [french oillet from latin oculus]</p>",
            },
        ],
    },
    {
        word: "Eyelid",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyelid <strong class='noun'> n. </strong> Either of the folds of skin closing to cover the eye.</p>",
            },
        ],
    },
    {
        word: "Eye-liner",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eye-liner <strong class='noun'> n. </strong> Cosmetic applied as a line round the eye.</p>",
            },
        ],
    },
    {
        word: "Eye-opener",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eye-opener <strong class='noun'> n. </strong> Colloq. Enlightening experience; unexpected revelation.</p>",
            },
        ],
    },
    {
        word: "Eyepiece",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyepiece <strong class='noun'> n. </strong> Lens or lenses to which the eye is applied at the end of an optical instrument.</p>",
            },
        ],
    },
    {
        word: "Eye-shade",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eye-shade <strong class='noun'> n. </strong> Device to protect the eyes, esp. From strong light.</p>",
            },
        ],
    },
    {
        word: "Eye-shadow",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eye-shadow <strong class='noun'> n. </strong> Coloured cosmetic applied to the eyelids.</p>",
            },
        ],
    },
    {
        word: "Eyesight",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyesight <strong class='noun'> n. </strong> Faculty or power of seeing.</p>",
            },
        ],
    },
    {
        word: "Eyesore",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyesore <strong class='noun'> n. </strong> Ugly thing.</p>",
            },
        ],
    },
    {
        word: "Eye strain",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eye strain <strong class='noun'> n. </strong> Fatigue of the eye muscles.</p>",
            },
        ],
    },
    {
        word: "Eye-tooth",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eye-tooth <strong class='noun'> n. </strong> Canine tooth in the upper jaw just under the eye.</p>",
            },
        ],
    },
    {
        word: "Eyewash",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyewash <strong class='noun'> n. </strong> 1 lotion for the eyes. 2 slang nonsense; insincere talk.</p>",
            },
        ],
    },
    {
        word: "Eyewitness",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyewitness <strong class='noun'> n. </strong> Person who saw a thing happen and can tell of it.</p>",
            },
        ],
    },
    {
        word: "Eyrie",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eyrie <strong class='noun'> n. </strong> 1 nest of a bird of prey, esp. An eagle, built high up. 2 house etc. Perched high up. [french aire lair, from latin agrum piece of ground]</p>",
            },
        ],
    },
    {
        word: "Espresso",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Espresso <strong class='noun'> n. </strong> (also expresso) <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> strong black coffee made under steam pressure. [italian, = pressed out]</p>",
            },
        ],
    },
    {
        word: "Esprit",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esprit <strong class='noun'> n. </strong> Sprightliness, wit. esprit de corps devotion to and pride in one's group. [french: related to *spirit]</p>",
            },
        ],
    },
    {
        word: "Espy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Espy <strong class='verb'> v. </strong> (-ies, -ied) catch sight of. [french: related to *spy]</p>",
            },
        ],
    },
    {
        word: "Esq.",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>Esq. <strong class='abbr'> Abbr. </strong> Esquire.</p>",
            },
        ],
    },
    {
        word: "-esque",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-esque <strong class='slang_v'> suffix </strong>forming adjectives meaning ‘in the style of’ or ‘resembling’ (kafkaesque). [french from latin -iscus]</p>",
            },
        ],
    },
    {
        word: "Esquire",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esquire <strong class='noun'> n. </strong> 1 (usu. As abbr. Esq.) Title added to a man's surname when no other title is used, esp. As a form of address for letters. 2 archaic = *squire. [french from latin scutum shield]</p>",
            },
        ],
    },
    {
        word: "-ess",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ess <strong class='slang_v'> suffix </strong>forming nouns denoting females (actress; lioness). [greek -issa]</p>",
            },
        ],
    },
    {
        word: "Essay",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Essay <strong class='noun'> —n. </strong> 1 short piece of writing on a given subject. 2 (often foll. By at, in) formal attempt. —v. Attempt. essayist <strong class='noun'> n. </strong> [latin exigo weigh: cf. *assay]</p>",
            },
        ],
    },
    {
        word: "Essence",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Essence <strong class='noun'> n. </strong> 1 fundamental nature; inherent characteristics. 2 a extract got by distillation etc. B perfume. of the essence indispensable. In essence fundamentally. [latin esse be]</p>",
            },
        ],
    },
    {
        word: "Essential",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Essential <strong class='adj'> —adj.</strong> 1 necessary; indispensable. 2 of or constituting the essence of a person or thing. <strong class='noun'> —n. </strong> (esp. In pl.) Basic or indispensable element or thing. essentially <strong class='adv'> adv. </strong> [latin: related to *essence]</p>",
            },
        ],
    },
    {
        word: "Essential oil",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Essential oil <strong class='noun'> n. </strong> Volatile oil derived from a plant etc. With its characteristic odour.</p>",
            },
        ],
    },
    {
        word: "-est",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-est <strong class='slang_v'> suffix </strong>forming the superlative of adjectives (widest; nicest; happiest) and adverbs (soonest). [old english]</p>",
            },
        ],
    },
    {
        word: "Establish",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Establish <strong class='verb'> v. </strong> 1 set up (a business, system, etc.) On a permanent basis. 2 (foll. By in) settle (a person or oneself) in some capacity. 3 (esp. As established adj.) A achieve permanent acceptance for (a custom, belief, etc.). B place (a fact etc.) Beyond dispute. [latin stabilio make firm]</p>",
            },
        ],
    },
    {
        word: "Established church",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Established church <strong class='noun'> n. </strong> The church recognized by the state.</p>",
            },
        ],
    },
    {
        word: "Establishment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Establishment <strong class='noun'> n. </strong> 1 establishing or being established. 2 a business organization or public institution. B place of business. C residence. 3 a staff of an organization. B household. 4 organized body permanently maintained. 5 church system organized by law. 6 (the establishment) social group with authority or influence and resisting change.</p>",
            },
        ],
    },
    {
        word: "Estate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estate <strong class='noun'> n. </strong> 1 property consisting of much land and usu. A large house. 2 modern residential or industrial area with an integrated design or purpose. 3 person's assets and liabilities, esp. At death. 4 property where rubber, tea, grapes, etc., are cultivated. 5 order or class forming (or regarded as) part of the body politic. 6 archaic or literary state or position in life (the estate of holy matrimony). the three estates lords spiritual (the heads of the church), lords temporal (the peerage), and the commons. [french estat, from latin sto stat- stand]</p>",
            },
        ],
    },
    {
        word: "Estate agent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estate agent <strong class='noun'> n. </strong> Person whose business is the sale or lease of buildings and land on behalf of others.</p>",
            },
        ],
    },
    {
        word: "Estate car",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estate car <strong class='noun'> n. </strong> Car with a continuous area for rear passengers and luggage.</p>",
            },
        ],
    },
    {
        word: "Estate duty",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estate duty <strong class='noun'> n. </strong> Hist. Death duty. <strong class='usage'> Usage:- </strong>estate duty was replaced in 1975 by capital transfer tax and in 1986 by inheritance tax.</p>",
            },
        ],
    },
    {
        word: "Esteem",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Esteem <strong class='noun'> —v. </strong> 1 (usu. In passive) have a high regard for. 2 formal consider (esteemed it an honour). <strong class='noun'> —n. </strong> High regard; favour. [latin: related to *estimate]</p>",
            },
        ],
    },
    {
        word: "Ester",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ester <strong class='noun'> n. </strong> Chem. A compound produced by replacing the hydrogen of an acid by an organic radical. [german]</p>",
            },
        ],
    },
    {
        word: "Estimable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estimable <strong class='adj'> adj. </strong> Worthy of esteem; admirable. [latin: related to *esteem]</p>",
            },
        ],
    },
    {
        word: "Estimate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estimate <strong class='noun'> —n. </strong> 1 approximate judgement, esp. Of cost, value, size, etc. 2 statement of approximate charge for work to be undertaken. —v. <strong class='ting'> (-ting) </strong> (also absol.) 1 form an estimate or opinion of. 2 (foll. By that) make a rough calculation. 3 (often foll. By at) form an estimate; adjudge. estimator <strong class='noun'> n. </strong> [latin aestimo fix the price of]</p>",
            },
        ],
    },
    {
        word: "Estimation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estimation <strong class='noun'> n. </strong> 1 estimating. 2 judgement of worth. [latin: related to *estimate]</p>",
            },
        ],
    },
    {
        word: "Estonian",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estonian <strong class='noun'> —n. </strong> 1 a native or national of estonia in eastern europe. B person of estonian descent. 2 language of estonia. <strong class='adj'> —adj.</strong> Of estonia, its people, or language.</p>",
            },
        ],
    },
    {
        word: "Estrange",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estrange <strong class='verb'> v. </strong> (-ging) 1 (usu. In passive; often foll. By from) alienate; make hostile or indifferent. 2 (as estranged adj.) (of a husband or wife) no longer living with his or her spouse. estrangement <strong class='noun'> n. </strong> [latin: related to *strange]</p>",
            },
        ],
    },
    {
        word: "Estrogen",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estrogen <strong class='noun'> n. </strong> (brit. Oestrogen) 1 sex hormone developing and maintaining female characteristics of the body. 2 this produced artificially for use in medicine. [greek oistros frenzy, *-gen]</p>",
            },
        ],
    },
    {
        word: "Estrus",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estrus <strong class='noun'> n. </strong> (also estrum, brit. Oestrus) recurring period of sexual receptivity in many female mammals oestrous adj. [greek oistros frenzy]</p>",
            },
        ],
    },
    {
        word: "Estuary",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Estuary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> wide tidal river mouth. [latin aestus tide]</p>",
            },
        ],
    },
    {
        word: "Eta",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eta <strong class='sing'> abbr. </strong> Estimated time of arrival.</p>",
            },
        ],
    },
    {
        word: "Eta",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eta <strong class='noun'> n. </strong> Seventh letter of the greek alphabet (h, h). [greek]</p>",
            },
        ],
    },
    {
        word: "Et al.",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Et al. <strong class='abbr'> Abbr. </strong> And others. [latin et alii]</p>",
            },
        ],
    },
    {
        word: "Etc.",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Etc. <strong class='abbr'> Abbr. </strong> = *et cetera.</p>",
            },
        ],
    },
    {
        word: "Et cetera  (also etcetera)",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Et cetera  <strong class='abbr'>(also etcetera)</strong> <strong class='adv'> —adv. </strong> 1 and the rest. 2 and so on. <strong class='noun'> —n. </strong> (in pl.) The usual extras. [latin]</p>",
            },
        ],
    },
    {
        word: "Etch",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Etch <strong class='verb'> v. </strong> 1 a reproduce (a picture etc.) By engraving it on a metal plate with acid (esp. To print copies). B engrave (a plate) in this way. 2 practise this craft. 3 (foll. By on, upon) impress deeply (esp. On the mind). etcher <strong class='noun'> n. </strong> [dutch etsen]</p>",
            },
        ],
    },
    {
        word: "Etching",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Etching <strong class='noun'> n. </strong> 1 print made from an etched plate. 2 art of producing these plates.</p>",
            },
        ],
    },
    {
        word: "Eternal",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eternal <strong class='adj'> adj. </strong> 1 existing always; without an end or (usu.) Beginning. 2 unchanging. 3 <strong class='colloq'> colloq. </strong> Constant; too frequent (eternal nagging). eternally <strong class='adv'> adv. </strong> [latin aeternus]</p>",
            },
        ],
    },
    {
        word: "Eternal triangle",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eternal triangle <strong class='noun'> n. </strong> Two people of one sex and one person of the other involved in a complex emotional relationship.</p>",
            },
        ],
    },
    {
        word: "Eternity",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eternity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 infinite (esp. Future) time. 2 endless life after death. 3 being eternal. 4 <strong class='colloq'> colloq. </strong> (often prec. By an) a very long time. [latin: related to *eternal]</p>",
            },
        ],
    },
    {
        word: "Eternity ring",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eternity ring <strong class='noun'> n. </strong> Finger-ring esp. Set with gems all round.</p>",
            },
        ],
    },
    {
        word: "-eth",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>-eth <strong class='var'> var. </strong> Of *-th.</p>",
            },
        ],
    },
    {
        word: "Ethanal",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethanal <strong class='noun'> n. </strong> = *acetaldehyde.</p>",
            },
        ],
    },
    {
        word: "Ethane",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethane <strong class='noun'> n. </strong> Gaseous hydrocarbon of the alkane series. [from *ether]</p>",
            },
        ],
    },
    {
        word: "Ether",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ether <strong class='noun'> n. </strong> 1 chem. Colourless volatile organic liquid used as an anaesthetic or solvent. 2 clear sky; upper regions of the air. 3 hist. A medium formerly assumed to permeate all space. B medium through which electromagnetic waves were formerly thought to be transmitted. [greek aitho burn]</p>",
            },
        ],
    },
    {
        word: "Ethereal",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethereal <strong class='adj'> adj. </strong> 1 light, airy. 2 highly delicate, esp. In appearance. 3 heavenly. ethereally <strong class='adv'> adv. </strong> [greek: related to *ether]</p>",
            },
        ],
    },
    {
        word: "Ethic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethic <strong class='noun'> —n. </strong> Set of moral principles (the quaker ethic). <strong class='adj'> —adj.</strong> = *ethical. [greek: related to *ethos]</p>",
            },
        ],
    },
    {
        word: "Ethical",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethical <strong class='adj'> adj. </strong> 1 relating to morals, esp. As concerning human conduct. 2 morally correct. 3 (of a drug etc.) Not advertised to the general public, and usu. Available only on prescription. ethically adv.</p>",
            },
        ],
    },
    {
        word: "Ethics",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethics <strong class='n_pl'> n.pl. </strong> (also treated as sing.) 1 moral philosophy. 2 a moral principles. B set of these.</p>",
            },
        ],
    },
    {
        word: "Ethiopian",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethiopian <strong class='noun'> —n. </strong> 1 native or national of ethiopia in ne africa. 2 person of ethiopian descent. <strong class='adj'> —adj.</strong> Of ethiopia.</p>",
            },
        ],
    },
    {
        word: "Ethnic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethnic <strong class='adj'> adj. </strong> 1 a (of a social group) having a common national or cultural tradition. B (of music, clothing, etc.) Inspired by or resembling those of an exotic people. 2 denoting origin by birth or descent rather than nationality (ethnic turks). ethnically <strong class='adv'> adv. </strong> [greek ethnos nation]</p>",
            },
        ],
    },
    {
        word: "Ethnology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethnology <strong class='noun'> n. </strong> The comparative study of peoples. ethnological adj. Ethnologist n.</p>",
            },
        ],
    },
    {
        word: "Ethos",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethos <strong class='noun'> n. </strong> Characteristic spirit or attitudes of a community etc. [greek ethos character]</p>",
            },
        ],
    },
    {
        word: "Ethyl",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethyl <strong class='noun'> n. </strong> (attrib.) A radical derived from ethane, present in alcohol and ether. [german: related to *ether]</p>",
            },
        ],
    },
    {
        word: "Ethylene",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ethylene <strong class='noun'> n. </strong> A hydrocarbon of the alkene series.</p>",
            },
        ],
    },
    {
        word: "Etiolate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Etiolate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 make (a plant) pale by excluding light. 2 give a sickly colour to (a person). etiolation <strong class='noun'> n. </strong> [latin stipula straw]</p>",
            },
        ],
    },
    {
        word: "Etiology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Etiology <strong class='noun'> n. </strong> (brit. Aetiology) study of causation or of the causes of disease. aetiological adj. [greek aitia cause]</p>",
            },
        ],
    },
    {
        word: "Etiquette",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Etiquette <strong class='noun'> n. </strong> Conventional rules of social behaviour or professional conduct. [french: related to *ticket]</p>",
            },
        ],
    },
    {
        word: "Etruscan",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Etruscan <strong class='adj'> —adj.</strong> Of ancient etruria in italy. <strong class='noun'> —n. </strong> 1 native of etruria. 2 language of etruria. [latin etruscus]</p>",
            },
        ],
    },
    {
        word: "Et seq.",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Et seq. <strong class='abbr'> Abbr. </strong> (also et seqq.) And the following (pages etc.). [latin et sequentia]</p>",
            },
        ],
    },
    {
        word: "-ette",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>-ette <strong class='slang_v'> suffix </strong>forming nouns meaning: 1 small (kitchenette). 2 imitation or substitute (flannelette). 3 female (usherette). [french]</p>",
            },
        ],
    },
    {
        word: "Étude",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Étude <strong class='noun'> n. </strong> = *study <strong class='noun'> n. </strong> 6. [french, = study]</p>",
            },
        ],
    },
    {
        word: "Etymology",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Etymology <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a derivation and development of a word in form and meaning. B account of these. 2 the study of word origins. etymological adj. Etymologist <strong class='noun'> n. </strong> [greek etumos true]</p>",
            },
        ],
    },
    {
        word: "Eu",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning: "<p>Eu <strong class='prep'> symb. </strong> Europium.</p>",
            },
        ],
    },
    {
        word: "Eu-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eu- <strong class='n_pl'> comb. </strong> Form well, easily. [greek]</p>",
            },
        ],
    },
    {
        word: "Eucalyptus",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eucalyptus <strong class='noun'> n. </strong> (pl. -tuses or -ti) (also eucalypt <strong class='pl'> pl. </strong> -s) 1 tall evergreen australasian tree. 2 its oil, used as an antiseptic etc. [from *eu-, greek kaluptos covered]</p>",
            },
        ],
    },
    {
        word: "Eucharist",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eucharist <strong class='noun'> n. </strong> 1 christian sacrament in which consecrated bread and wine are consumed. 2 consecrated elements, esp. The bread. eucharistic adj. [greek, = thanksgiving]</p>",
            },
        ],
    },
    {
        word: "Eugenics",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eugenics <strong class='n_pl'> n.pl. </strong> (also treated as sing.) Improvement of the qualities of a race by control of inherited characteristics. eugenic adj. Eugenically <strong class='adv'> adv. </strong> [from *eu-, greek gen- produce]</p>",
            },
        ],
    },
    {
        word: "Eukaryote",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eukaryote <strong class='noun'> n. </strong> Organism consisting of a cell or cells in which the genetic material is contained within a distinct nucleus. eukaryotic adj. [from *eu-, karyo- from greek karuon kernel, -ote as in *zygote]</p>",
            },
        ],
    },
    {
        word: "Eulogize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eulogize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) praise in speech or writing. eulogistic adj.</p>",
            },
        ],
    },
    {
        word: "Eulogy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eulogy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 speech or writing in praise of a person. 2 expression of praise. [latin from greek]</p>",
            },
        ],
    },
    {
        word: "Eunuch",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eunuch <strong class='noun'> n. </strong> Castrated man, esp. One formerly employed at an oriental harem or court. [greek, = bedchamber attendant]</p>",
            },
        ],
    },
    {
        word: "Euphemism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Euphemism <strong class='noun'> n. </strong> 1 mild or vague expression substituted for a harsher or more direct one (e.g. Pass over for die). 2 use of such expressions. euphemistic adj. Euphemistically <strong class='adv'> adv. </strong> [greek pheme speaking]</p>",
            },
        ],
    },
    {
        word: "Euphonium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Euphonium <strong class='noun'> n. </strong> Brass instrument of the tuba family. [related to *euphony]</p>",
            },
        ],
    },
    {
        word: "Euphony",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Euphony <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 pleasantness of sound, esp. Of a word or phrase. 2 pleasant sound. euphonious adj. [greek phone sound]</p>",
            },
        ],
    },
    {
        word: "Euphoria",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Euphoria <strong class='noun'> n. </strong> Intense feeling of well-being and excitement. euphoric adj. [greek phero bear]</p>",
            },
        ],
    },
    {
        word: "Eurasian",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eurasian <strong class='adj'> —adj.</strong> 1 of mixed european and asian parentage. 2 of europe and asia. <strong class='noun'> —n. </strong> Eurasian person.</p>",
            },
        ],
    },
    {
        word: "Eureka",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eureka <strong class='n_pl'> int. </strong> I have found it! (announcing a discovery etc.). [greek heureka]</p>",
            },
        ],
    },
    {
        word: "Euro-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Euro- <strong class='n_pl'> comb. </strong> Form europe, european. [abbreviation]</p>",
            },
        ],
    },
    {
        word: "Eurodollar",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eurodollar <strong class='noun'> n. </strong> Dollar held in a bank outside the us.</p>",
            },
        ],
    },
    {
        word: "European",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>European <strong class='adj'> —adj.</strong> 1 of or in europe. 2 originating in, native to, or characteristic of europe. <strong class='noun'> —n. </strong> 1 a native or inhabitant of europe. B person descended from natives of europe. 2 person favouring european integration. [greek europe europe]</p>",
            },
        ],
    },
    {
        word: "Europium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Europium <strong class='noun'> n. </strong> Metallic element of the lanthanide series. [from the name europe]</p>",
            },
        ],
    },
    {
        word: "Eustachian tube",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eustachian tube <strong class='noun'> n. </strong> Tube from the pharynx to the cavity of the middle ear. [eustachio, name of an anatomist]</p>",
            },
        ],
    },
    {
        word: "Euthanasia",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Euthanasia <strong class='noun'> n. </strong> Bringing about of a gentle death in the case of incurable and painful disease. [greek thanatos death]</p>",
            },
        ],
    },
    {
        word: "Ev",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ev <strong class='sing'> abbr. </strong> Electronvolt.</p>",
            },
        ],
    },
    {
        word: "Evacuate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evacuate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a remove (people) from a place of danger. B empty (a place) in this way. 2 make empty. 3 (of troops) withdraw from (a place). 4 empty (the bowels etc.). evacuation <strong class='noun'> n. </strong> [latin vacuus empty]</p>",
            },
        ],
    },
    {
        word: "Evacuee",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evacuee <strong class='noun'> n. </strong> Person evacuated.</p>",
            },
        ],
    },
    {
        word: "Evade",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evade <strong class='verb'> v. </strong> (-ding) 1 a escape from, avoid, esp. By guile or trickery. B avoid doing (one's duty etc.). C avoid answering (a question). 2 avoid paying (tax). [latin evado escape]</p>",
            },
        ],
    },
    {
        word: "Evaluate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evaluate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 assess, appraise. 2 find or state the number or amount of. evaluation <strong class='noun'> n. </strong> [french: related to *value]</p>",
            },
        ],
    },
    {
        word: "Evanesce",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evanesce <strong class='verb'> v. </strong> (-cing) literary fade from sight. [latin vanus empty]</p>",
            },
        ],
    },
    {
        word: "Evanescent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evanescent <strong class='adj'> adj. </strong> Quickly fading. evanescence n.</p>",
            },
        ],
    },
    {
        word: "Evangelical",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evangelical <strong class='adj'> —adj.</strong> 1 of or according to the teaching of the gospel. 2 of the protestant school maintaining the doctrine of salvation by faith. <strong class='noun'> —n. </strong> Member of this. evangelicalism <strong class='noun'> n. </strong> Evangelically <strong class='adv'> adv. </strong> [greek: related to *eu-, *angel]</p>",
            },
        ],
    },
    {
        word: "Evangelism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evangelism <strong class='noun'> n. </strong> Preaching or spreading of the gospel.</p>",
            },
        ],
    },
    {
        word: "Evangelist",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evangelist <strong class='noun'> n. </strong> 1 writer of one of the four gospels. 2 preacher of the gospel. evangelistic adj.</p>",
            },
        ],
    },
    {
        word: "Evangelize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evangelize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 (also absol.) Preach the gospel to. 2 convert to christianity. evangelization n.</p>",
            },
        ],
    },
    {
        word: "Evaporate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evaporate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 turn from solid or liquid into vapour. 2 (cause to) lose moisture as vapour. 3 (cause to) disappear. evaporable adj. Evaporation <strong class='noun'> n. </strong> [latin: related to *vapour]</p>",
            },
        ],
    },
    {
        word: "Evaporated milk",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evaporated milk <strong class='noun'> n. </strong> Unsweetened milk concentrated by evaporation.</p>",
            },
        ],
    },
    {
        word: "Evasion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evasion <strong class='noun'> n. </strong> 1 evading. 2 evasive answer. [latin: related to *evade]</p>",
            },
        ],
    },
    {
        word: "Evasive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evasive <strong class='adj'> adj. </strong> 1 seeking to evade. 2 not direct in one's answers etc. evasively <strong class='adv'> adv. </strong> Evasiveness n.</p>",
            },
        ],
    },
    {
        word: "Eve",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eve <strong class='noun'> n. </strong> 1 evening or day before a festival etc. (christmas eve; eve of the funeral). 2 time just before an event (eve of the election). 3 archaic evening. [= *even2]</p>",
            },
        ],
    },
    {
        word: "Even1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Even1 <strong class='adj'> —adj.</strong> (evener, evenest) 1 level; smooth. 2 a uniform in quality; constant. B equal in amount or value etc. C equally balanced. 3 (of a person's temper etc.) Equable, calm. 4 a (of a number) divisible by two without a remainder. B bearing such a number (no parking on even dates). C not involving fractions; exact (in even dozens). <strong class='adv'> —adv. </strong> 1 inviting comparison of the assertion, negation, etc., with an implied one that is less strong or remarkable (never even opened [let alone read] the letter; ran even faster [not just as fast as before]). 2 introducing an extreme case (even you must realize it). —v. (often foll. By up) make or become even. even now 1 now as well as before. 2 at this very moment. Even so nevertheless. Even though despite the fact that. Get (or be) even with have one's revenge on. evenly <strong class='adv'> adv. </strong> Evenness <strong class='noun'> n. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Even2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Even2 <strong class='noun'> n. </strong> Poet. Evening. [old english]</p>",
            },
        ],
    },
    {
        word: "Even chance",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Even chance <strong class='noun'> n. </strong> Equal chance of success or failure.</p>",
            },
        ],
    },
    {
        word: "Even-handed",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Even-handed <strong class='adj'> adj. </strong> Impartial.</p>",
            },
        ],
    },
    {
        word: "Evening",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evening <strong class='noun'> n. </strong> End part of the day, esp. From about 6 p.m. To bedtime. [old english: related to *even2]</p>",
            },
        ],
    },
    {
        word: "Evening dress",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evening dress <strong class='noun'> n. </strong> Formal dress for evening wear.</p>",
            },
        ],
    },
    {
        word: "Evening primrose",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evening primrose <strong class='noun'> n. </strong> Plant with pale-yellow flowers that open in the evening.</p>",
            },
        ],
    },
    {
        word: "Evening star",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evening star <strong class='noun'> n. </strong> Planet, esp. Venus, conspicuous in the west after sunset.</p>",
            },
        ],
    },
    {
        word: "Even money",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Even money <strong class='noun'> n. </strong> Betting odds offering the gambler the chance of winning the amount staked.</p>",
            },
        ],
    },
    {
        word: "Evens",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evens <strong class='n_pl'> n.pl. </strong> = *even money.</p>",
            },
        ],
    },
    {
        word: "Evensong",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evensong <strong class='noun'> n. </strong> Service of evening prayer in the church of england. [from *even2]</p>",
            },
        ],
    },
    {
        word: "Event",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Event <strong class='noun'> n. </strong> 1 thing that happens. 2 fact of a thing's occurring. 3 item in a (esp. Sports) programme. at all events (or in any event) whatever happens. In the event as it turns (or turned) out. In the event of if (a specified thing) happens. In the event that if it happens that. [latin venio vent- come] <strong class='usage'> Usage:- </strong>the phrase in the event that is considered awkward by some people. It can usually be avoided by rephrasing, e.g. In the event that it rains can be replaced by in the event of rain.</p>",
            },
        ],
    },
    {
        word: "Eventful",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eventful <strong class='adj'> adj. </strong> Marked by noteworthy events. eventfully adv.</p>",
            },
        ],
    },
    {
        word: "Eventide",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eventide <strong class='noun'> n. </strong> Archaic or poet. = *evening. [related to *even2]</p>",
            },
        ],
    },
    {
        word: "Eventing",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eventing <strong class='noun'> n. </strong> Participation in equestrian competitions, esp. Dressage and showjumping. [see *event 3]</p>",
            },
        ],
    },
    {
        word: "Eventual",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eventual <strong class='adj'> adj. </strong> Occurring in due course, ultimate. eventually <strong class='adv'> adv. </strong> [from *event]</p>",
            },
        ],
    },
    {
        word: "Eventuality",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eventuality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> possible event or outcome.</p>",
            },
        ],
    },
    {
        word: "Eventuate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eventuate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (often foll. By in) result.</p>",
            },
        ],
    },
    {
        word: "Ever",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ever <strong class='adv'> adv. </strong> 1 at all times; always (ever hopeful; ever after). 2 at any time (have you ever smoked?; nothing ever happens). 3 (used for emphasis) in any way; at all (how ever did you do it?). 4 (in <strong class='n_pl'> comb. </strong> ) Constantly (ever-present). 5 (foll. By so, such) <strong class='colloq'> colloq. </strong> Very; very much (ever so easy; thanks ever so). did you ever? Colloq. Did you ever hear or see the like? Ever since throughout the period since. [old english] <strong class='usage'> Usage:- </strong>when ever is used with a question word for emphasis it is written separately (see sense 2). When used with a relative pronoun or adverb to give it indefinite or general force, ever is written as one word with the relative pronoun or adverb, e.g. However it's done, it's difficult.</p>",
            },
        ],
    },
    {
        word: "Evergreen",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evergreen <strong class='adj'> —adj.</strong> Retaining green leaves all year round. <strong class='noun'> —n. </strong> Evergreen plant.</p>",
            },
        ],
    },
    {
        word: "Everlasting",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Everlasting <strong class='adj'> —adj.</strong> 1 lasting for ever or for a long time. 2 (of flowers) keeping their shape and colour when dried. <strong class='noun'> —n. </strong> 1 eternity. 2 everlasting flower.</p>",
            },
        ],
    },
    {
        word: "Evermore",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evermore <strong class='adv'> adv. </strong> For ever; always.</p>",
            },
        ],
    },
    {
        word: "Every",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Every <strong class='adj'> adj. </strong> 1 each single (heard every word). 2 each at a specified interval in a series (comes every four days). 3 all possible (every prospect of success). every bit as <strong class='colloq'> colloq. </strong> (in comparisons) quite as. Every now and again (or then) from time to time. Every other each second in a series (every other day). Every so often occasionally. [old english: related to *ever, *each]</p>",
            },
        ],
    },
    {
        word: "Everybody",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Everybody <strong class='pron'> pron. </strong> Every person.</p>",
            },
        ],
    },
    {
        word: "Everyday",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Everyday <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> 1 occurring every day. 2 used on ordinary days. 3 commonplace.</p>",
            },
        ],
    },
    {
        word: "Everyman",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Everyman <strong class='noun'> n. </strong> Ordinary or typical human being. [name of a character in a 15th-c. Morality play]</p>",
            },
        ],
    },
    {
        word: "Everyone",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Everyone <strong class='pron'> pron. </strong> Everybody.</p>",
            },
        ],
    },
    {
        word: "Every one",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Every one <strong class='noun'> n. </strong> Each one.</p>",
            },
        ],
    },
    {
        word: "Everything",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Everything <strong class='pron'> pron. </strong> 1 all things. 2 most important thing (speed is everything).</p>",
            },
        ],
    },
    {
        word: "Everywhere",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Everywhere <strong class='adv'> adv. </strong> 1 in every place. 2 <strong class='colloq'> colloq. </strong> In many places.</p>",
            },
        ],
    },
    {
        word: "Evict",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evict <strong class='verb'> v. </strong> Expel (a tenant etc.) By legal process. eviction <strong class='noun'> n. </strong> [latin evinco evict- conquer]</p>",
            },
        ],
    },
    {
        word: "Evidence",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evidence <strong class='noun'> —n. </strong> 1 (often foll. By for, of) available facts, circumstances, etc. Indicating whether or not a thing is true or valid. 2 law a information tending to prove a fact or proposition. B statements or proofs admissible as testimony in a lawcourt. —v. (-cing) be evidence of. in evidence conspicuous. Queen's (or king's or state's) evidence law evidence for the prosecution given by a participant in the crime at issue. [latin video see]</p>",
            },
        ],
    },
    {
        word: "Evident",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evident <strong class='adj'> adj. </strong> Plain or obvious; manifest. [latin: related to *evidence]</p>",
            },
        ],
    },
    {
        word: "Evidential",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evidential <strong class='adj'> adj. </strong> Of or providing evidence.</p>",
            },
        ],
    },
    {
        word: "Evidently",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evidently <strong class='adv'> adv. </strong> 1 seemingly; as it appears. 2 as shown by evidence.</p>",
            },
        ],
    },
    {
        word: "Evil",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evil <strong class='adj'> —adj.</strong> 1 morally bad; wicked. 2 harmful. 3 disagreeable (evil temper). <strong class='noun'> —n. </strong> 1 evil thing. 2 wickedness. evilly <strong class='adv'> adv. </strong> [old english]</p>",
            },
        ],
    },
    {
        word: "Evildoer",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evildoer <strong class='noun'> n. </strong> Sinner. evildoing n.</p>",
            },
        ],
    },
    {
        word: "Evil eye",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evil eye <strong class='noun'> n. </strong> Gaze that is superstitiously believed to cause harm.</p>",
            },
        ],
    },
    {
        word: "Evince",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evince <strong class='verb'> v. </strong> (-cing) indicate, display (a quality, feeling, etc.). [latin: related to *evict]</p>",
            },
        ],
    },
    {
        word: "Eviscerate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Eviscerate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> disembowel. evisceration <strong class='noun'> n. </strong> [latin: related to *viscera]</p>",
            },
        ],
    },
    {
        word: "Evocative",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evocative <strong class='adj'> adj. </strong> Evoking (esp. Feelings or memories). evocatively <strong class='adv'> adv. </strong> Evocativeness n.</p>",
            },
        ],
    },
    {
        word: "Evoke",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evoke <strong class='verb'> v. </strong> (-king) inspire or draw forth (memories, a response, etc.). evocation <strong class='noun'> n. </strong> [latin voco call]</p>",
            },
        ],
    },
    {
        word: "Evolution",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evolution <strong class='noun'> n. </strong> 1 gradual development. 2 development of species from earlier forms, as an explanation of their origins. 3 unfolding of events etc. (evolution of the plot). 4 change in the disposition of troops or ships. evolutionary adj. [latin: related to *evolve]</p>",
            },
        ],
    },
    {
        word: "Evolutionist",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evolutionist <strong class='noun'> n. </strong> Person who regards evolution as explaining the origin of species.</p>",
            },
        ],
    },
    {
        word: "Evolve",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Evolve <strong class='verb'> v. </strong> <strong class='ving'> (-ving) </strong> 1 develop gradually and naturally. 2 devise (a theory, plan, etc.). 3 unfold. 4 give off (gas, heat, etc.). [latin volvo volut- roll]</p>",
            },
        ],
    },
    {
        word: "Ewe",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ewe <strong class='noun'> n. </strong> Female sheep. [old english]</p>",
            },
        ],
    },
    {
        word: "Ewer",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ewer <strong class='noun'> n. </strong> Water-jug with a wide mouth. [latin aqua water]</p>",
            },
        ],
    },
    {
        word: "Ex1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex1 <strong class='n_pl'> comb. </strong> (of goods) sold from (ex-works). [latin, = out of]</p>",
            },
        ],
    },
    {
        word: "Ex2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex2 <strong class='noun'> n. </strong> Colloq. Former husband or wife. [see *ex-1 2]</p>",
            },
        ],
    },
    {
        word: "Ex-1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex-1 <strong class='prefix '> prefix </strong> (also before some consonants e-, ef- before f) 1 forming verbs meaning: a out, forth (exclude; exit). B upward (extol). C thoroughly (excruciate). D bring into a state (exasperate). E remove or free from (expatriate; exonerate). 2 forming nouns from titles of office, status, etc., meaning ‘formerly’ (ex-president; ex-wife). [latin from ex out of]</p>",
            },
        ],
    },
    {
        word: "Ex-2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex-2 <strong class='prefix '> prefix </strong> out (exodus). [greek]</p>",
            },
        ],
    },
    {
        word: "Exacerbate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exacerbate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 make (pain etc.) Worse. 2 irritate (a person). exacerbation <strong class='noun'> n. </strong> [latin acerbus bitter]</p>",
            },
        ],
    },
    {
        word: "Exact",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exact <strong class='adj'> —adj.</strong> 1 accurate; correct in all details (exact description). 2 precise. —v. 1 demand and enforce payment of (money etc.). 2 demand; insist on; require. exactness <strong class='noun'> n. </strong> [latin exigo exact- require]</p>",
            },
        ],
    },
    {
        word: "Exacting",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exacting <strong class='adj'> adj. </strong> 1 making great demands. 2 requiring much effort.</p>",
            },
        ],
    },
    {
        word: "Exaction",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exaction <strong class='noun'> n. </strong> 1 exacting or being exacted. 2 a illegal or exorbitant demand; extortion. B sum or thing exacted.</p>",
            },
        ],
    },
    {
        word: "Exactitude",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exactitude <strong class='noun'> n. </strong> Exactness, precision.</p>",
            },
        ],
    },
    {
        word: "Exactly",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exactly <strong class='adv'> adv. </strong> 1 precisely. 2 (said in reply) i quite agree.</p>",
            },
        ],
    },
    {
        word: "Exact science",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exact science <strong class='noun'> n. </strong> A science in which absolute precision is possible.</p>",
            },
        ],
    },
    {
        word: "Exaggerate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exaggerate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 (also absol.) Make (a thing) seem larger or greater etc. Than it really is. 2 increase beyond normal or due proportions (exaggerated politeness). exaggeration <strong class='noun'> n. </strong> [latin agger heap]</p>",
            },
        ],
    },
    {
        word: "Exalt",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exalt <strong class='verb'> v. </strong> 1 raise in rank or power etc. 2 praise highly. 3 (usu. As exalted adj.) Make lofty or noble (exalted aims; exalted style). exaltation <strong class='noun'> n. </strong> [latin altus high]</p>",
            },
        ],
    },
    {
        word: "Exam",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exam <strong class='noun'> n. </strong> = *examination 3.</p>",
            },
        ],
    },
    {
        word: "Examination",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Examination <strong class='noun'> n. </strong> 1 examining or being examined. 2 detailed inspection. 3 test of proficiency or knowledge by questions. 4 formal questioning of a witness etc. In court.</p>",
            },
        ],
    },
    {
        word: "Examine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Examine <strong class='verb'> v. </strong> (-ning) 1 inquire into the nature or condition etc. Of. 2 look closely at. 3 test the proficiency of. 4 check the health of (a patient). 5 formally question in court. examinee <strong class='noun'> n. </strong> Examiner <strong class='noun'> n. </strong> [latin examen tongue of a balance]</p>",
            },
        ],
    },
    {
        word: "Example",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Example <strong class='noun'> n. </strong> 1 thing characteristic of its kind or illustrating a general rule. 2 person, thing, or piece of conduct, in terms of its fitness to be imitated. 3 circumstance or treatment seen as a warning to others. 4 problem or exercise designed to illustrate a rule. for example by way of illustration. [latin exemplum: related to *exempt]</p>",
            },
        ],
    },
    {
        word: "Exasperate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exasperate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> irritate intensely. exasperation <strong class='noun'> n. </strong> [latin asper rough]</p>",
            },
        ],
    },
    {
        word: "Ex cathedra",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex cathedra <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> With full authority (esp. Of a papal pronouncement). [latin, = from the chair]</p>",
            },
        ],
    },
    {
        word: "Excavate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excavate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a make (a hole or channel) by digging. B dig out material from (the ground). 2 reveal or extract by digging. 3 (also absol.) Archaeol. Dig systematically to explore (a site). excavation <strong class='noun'> n. </strong> Excavator <strong class='noun'> n. </strong> [latin excavo: related to *cave]</p>",
            },
        ],
    },
    {
        word: "Exceed",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exceed <strong class='verb'> v. </strong> 1 (often foll. By by an amount) be more or greater than. 2 go beyond or do more than is warranted by (a set limit, esp. Of one's authority, instructions, or rights). 3 surpass. [latin excedo -cess- go beyond]</p>",
            },
        ],
    },
    {
        word: "Exceedingly",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exceedingly <strong class='adv'> adv. </strong> Extremely.</p>",
            },
        ],
    },
    {
        word: "Excel",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excel <strong class='verb'> v. </strong> (-ll-) 1 surpass. 2 be pre-eminent. [latin excello be eminent]</p>",
            },
        ],
    },
    {
        word: "Excellence",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excellence <strong class='noun'> n. </strong> Outstanding merit or quality. [latin: related to *excel]</p>",
            },
        ],
    },
    {
        word: "Excellency",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excellency <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> (usu. Prec. By your, his, her, their) title used in addressing or referring to certain high officials.</p>",
            },
        ],
    },
    {
        word: "Excellent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excellent <strong class='adj'> adj. </strong> Extremely good.</p>",
            },
        ],
    },
    {
        word: "Excentric",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excentric <strong class='var'> var. </strong> Of *eccentric (in technical senses).</p>",
            },
        ],
    },
    {
        word: "Except",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Except <strong class='noun'> —v. </strong> Exclude from a general statement, condition, etc. <strong class='prep'> —prep. </strong> (often foll. By for) not including; other than (all failed except him; is all right except that it is too long). — <strong class='conj'> conj. </strong> Archaic unless (except he be born again). [latin excipio -cept- take out]</p>",
            },
        ],
    },
    {
        word: "Excepting",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excepting <strong class='n_pl'> comb. </strong> = *except <strong class='n_pl'> comb. </strong> <strong class='usage'> Usage:- </strong>excepting should be used only after not and always; otherwise, except should be used.</p>",
            },
        ],
    },
    {
        word: "Exception",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exception <strong class='noun'> n. </strong> 1 excepting or being excepted. 2 thing that has been or will be excepted. 3 instance that does not follow a rule. take exception <strong class='adj'> (often foll. By to) </strong> object. With the exception of except.</p>",
            },
        ],
    },
    {
        word: "Exceptionable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exceptionable <strong class='adj'> adj. </strong> Open to objection. <strong class='usage'> Usage:- </strong>exceptionable is sometimes confused with exceptional.</p>",
            },
        ],
    },
    {
        word: "Exceptional",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exceptional <strong class='adj'> adj. </strong> 1 forming an exception; unusual. 2 outstanding. exceptionally adv. <strong class='usage'> Usage:- </strong>see note at exceptionable.</p>",
            },
        ],
    },
    {
        word: "Excerpt",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excerpt <strong class='noun'> —n. </strong> Short extract from a book, film, etc. —v. (also absol.) Take excerpts from. excerption <strong class='noun'> n. </strong> [latin carpo pluck]</p>",
            },
        ],
    },
    {
        word: "Excess",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excess <strong class='noun'> —n. </strong> 1 exceeding. 2 amount by which one thing exceeds another. 3 a overstepping of accepted limits of moderation, esp. In eating or drinking. B (in pl.) Immoderate behaviour. 4 part of an insurance claim to be paid by the insured. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Usu. 1 that exceeds a limited or prescribed amount. 2 required as extra payment (excess postage). in (or to) excess exceeding the proper amount or degree.</p>",
            },
        ],
    },
    {
        word: "Excess baggage",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excess baggage <strong class='noun'> n. </strong> (also excess luggage) baggage exceeding a weight allowance and liable to an extra charge.</p>",
            },
        ],
    },
    {
        word: "Excessive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excessive <strong class='adj'> adj. </strong> Too much or too great. excessively adv.</p>",
            },
        ],
    },
    {
        word: "Exchange",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exchange <strong class='noun'> —n. </strong> 1 giving of one thing and receiving of another in its place. 2 giving of money for its equivalent in the money of the same or another country. 3 centre where telephone connections are made. 4 place where merchants, bankers, etc. Transact business. 5 a office where information is given or a service provided. B employment office. 6 system of settling debts without the use of money, by bills of exchange. 7 short conversation. —v. (-ging) 1 (often foll. By for) give or receive (one thing) in place of another. 2 give and receive as equivalents. 3 (often foll. By with) make an exchange. in exchange (often foll. By for) as a thing exchanged (for). exchangeable adj. [french: related to *change]</p>",
            },
        ],
    },
    {
        word: "Exchange rate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exchange rate <strong class='noun'> n. </strong> Value of one currency in terms of another.</p>",
            },
        ],
    },
    {
        word: "Exchequer",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exchequer <strong class='noun'> n. </strong> 1 former government department in charge of national revenue. 2 royal or national treasury. 3 money of a private individual or group. [medieval latin scaccarium chessboard] <strong class='usage'> Usage:- </strong>with reference to sense 1, the functions of this department in the uk now belong to the treasury, although the name formally survives, esp. In the title chancellor of the exchequer.</p>",
            },
        ],
    },
    {
        word: "Excise1",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excise1 <strong class='noun'> —n. </strong> 1 tax on goods produced or sold within the country of origin. 2 tax on certain licences. —v. <strong class='sing'>(-sing)</strong> 1 charge excise on. 2 force (a person) to pay excise. [dutch excijs from romanic: related to latin *census tax]</p>",
            },
        ],
    },
    {
        word: "Excise2",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excise2 <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 remove (a passage from a book etc.). 2 cut out (an organ etc.) By surgery. excision <strong class='noun'> n. </strong> [latin excido cut out]</p>",
            },
        ],
    },
    {
        word: "Excitable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excitable <strong class='adj'> adj. </strong> Easily excited. excitability <strong class='noun'> n. </strong> Excitably adv.</p>",
            },
        ],
    },
    {
        word: "Excite",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excite <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a rouse the emotions of (a person). B arouse (feelings etc.). C arouse sexually. 2 provoke (an action etc.). 3 stimulate (an organism, tissue, etc.) To activity. [latin cieo stir up]</p>",
            },
        ],
    },
    {
        word: "Excitement",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excitement <strong class='noun'> n. </strong> 1 excited state of mind. 2 exciting thing.</p>",
            },
        ],
    },
    {
        word: "Exciting",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exciting <strong class='adj'> adj. </strong> Arousing great interest or enthusiasm. excitingly adv.</p>",
            },
        ],
    },
    {
        word: "Exclaim",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exclaim <strong class='verb'> v. </strong> 1 cry out suddenly. 2 (foll. By that) utter by exclaiming. [latin: related to *claim]</p>",
            },
        ],
    },
    {
        word: "Exclamation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exclamation <strong class='noun'> n. </strong> 1 exclaiming. 2 word(s) exclaimed. [latin: related to *exclaim]</p>",
            },
        ],
    },
    {
        word: "Exclamation mark",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exclamation mark <strong class='noun'> n. </strong> Punctuation mark (!) Indicating exclamation.</p>",
            },
        ],
    },
    {
        word: "Exclamatory",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exclamatory <strong class='adj'> adj. </strong> Of or serving as an exclamation.</p>",
            },
        ],
    },
    {
        word: "Exclude",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exclude <strong class='verb'> v. </strong> (-ding) 1 keep out (a person or thing) from a place, group, privilege, etc. 2 remove from consideration (no theory can be excluded). 3 make impossible, preclude (excluded all doubt). exclusion <strong class='noun'> n. </strong> [latin excludo -clus- shut out]</p>",
            },
        ],
    },
    {
        word: "Exclusive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exclusive <strong class='adj'> —adj.</strong> 1 excluding other things. 2 (predic.; foll. By of) not including; except for. 3 tending to exclude others, esp. Socially. 4 high-class. 5 not obtainable elsewhere or not published elsewhere. <strong class='noun'> —n. </strong> Article etc. Published by only one newspaper etc. exclusively <strong class='adv'> adv. </strong> Exclusiveness <strong class='noun'> n. </strong> Exclusivity <strong class='noun'> n. </strong> [medieval latin: related to *exclude]</p>",
            },
        ],
    },
    {
        word: "Excommunicate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excommunicate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> officially exclude (a person) from membership and esp. Sacraments of the church. <strong class='adj'> —adj.</strong> Excommunicated. <strong class='noun'> —n. </strong> Excommunicated person. excommunication <strong class='noun'> n. </strong> [latin: related to *common]</p>",
            },
        ],
    },
    {
        word: "Excoriate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excoriate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 a remove skin from (a person etc.) By abrasion. B strip off (skin). 2 censure severely. excoriation <strong class='noun'> n. </strong> [latin corium hide]</p>",
            },
        ],
    },
    {
        word: "Excrement",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excrement <strong class='noun'> n. </strong> Faeces. excremental adj. [latin: related to *excrete]</p>",
            },
        ],
    },
    {
        word: "Excrescence",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excrescence <strong class='noun'> n. </strong> 1 abnormal or morbid outgrowth on the body or a plant. 2 ugly addition. excrescent adj. [latin cresco grow]</p>",
            },
        ],
    },
    {
        word: "Excreta",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excreta <strong class='n_pl'> n.pl. </strong> Faeces and urine. [latin: related to *excrete]</p>",
            },
        ],
    },
    {
        word: "Excrete",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excrete <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (of an animal or plant) expel (waste matter). excretion <strong class='noun'> n. </strong> Excretory adj. [latin cerno cret- sift]</p>",
            },
        ],
    },
    {
        word: "Excruciating",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excruciating <strong class='adj'> adj. </strong> Causing acute mental or physical pain. excruciatingly <strong class='adv'> adv. </strong> [latin crucio torment]</p>",
            },
        ],
    },
    {
        word: "Exculpate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exculpate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> formal (often foll. By from) free from blame; clear of a charge. exculpation <strong class='noun'> n. </strong> Exculpatory adj. [latin culpa blame]</p>",
            },
        ],
    },
    {
        word: "Excursion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excursion <strong class='noun'> n. </strong> Journey (usu. A day-trip) to a place and back, made for pleasure. [latin excurro run out]</p>",
            },
        ],
    },
    {
        word: "Excursive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excursive <strong class='adj'> adj. </strong> Literary digressive.</p>",
            },
        ],
    },
    {
        word: "Excuse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Excuse <strong class='noun'> —v. </strong> <strong class='sing'>(-sing)</strong> 1 try to lessen the blame attaching to (a person, act, or fault). 2 (of a fact) serve as a reason to judge (a person or act) less severely. 3 (often foll. By from) release (a person) from a duty etc. 4 forgive (a fault or offence). 5 (foll. By for) forgive (a person) for (a fault). 6 refl. Leave with apologies. <strong class='noun'> —n. </strong> 1 reason put forward to mitigate or justify an offence. 2 apology (made my excuses). be excused be allowed to leave the room etc. Or be absent. Excuse me polite preface to an interruption etc., or to disagreeing. excusable adj. [latin causa accusation]</p>",
            },
        ],
    },
    {
        word: "Ex-directory",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex-directory <strong class='adj'> adj. </strong> Not listed in a telephone directory, at one's own request.</p>",
            },
        ],
    },
    {
        word: "Execrable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Execrable <strong class='adj'> adj. </strong> Abominable. [latin: related to *execrate]</p>",
            },
        ],
    },
    {
        word: "Execrate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Execrate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 express or feel abhorrence for. 2 (also absol.) Curse (a person or thing). execration <strong class='noun'> n. </strong> [latin exsecror curse: related to *sacred]</p>",
            },
        ],
    },
    {
        word: "Execute",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Execute <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 carry out, perform (a plan, duty etc.). 2 carry out a design for (a product of art or skill). 3 carry out a death sentence on. 4 make (a legal instrument) valid by signing, sealing, etc. [latin sequor follow]</p>",
            },
        ],
    },
    {
        word: "Execution",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Execution <strong class='noun'> n. </strong> 1 carrying out; performance. 2 technique or style of performance in the arts, esp. Music. 3 carrying out of a death sentence. [latin: related to *execute]</p>",
            },
        ],
    },
    {
        word: "Executioner",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Executioner <strong class='noun'> n. </strong> Official who carries out a death sentence.</p>",
            },
        ],
    },
    {
        word: "Executive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Executive <strong class='noun'> —n. </strong> 1 person or body with managerial or administrative responsibility. 2 branch of a government etc. Concerned with executing laws, agreements, etc. <strong class='adj'> —adj.</strong> Concerned with executing laws, agreements, etc., or with other administration or management. [medieval latin: related to *execute]</p>",
            },
        ],
    },
    {
        word: "Executor",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Executor <strong class='noun'> n. </strong> (fem. Executrix) person appointed by a testator to administer his or her will. executorial adj.</p>",
            },
        ],
    },
    {
        word: "Exegesis",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exegesis <strong class='noun'> n. </strong> (pl. Exegeses) critical explanation of a text, esp. Of scripture. exegetic adj. [greek hegeomai lead]</p>",
            },
        ],
    },
    {
        word: "Exemplar",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exemplar <strong class='noun'> n. </strong> 1 model. 2 typical or parallel instance. [latin: related to *example]</p>",
            },
        ],
    },
    {
        word: "Exemplary",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exemplary <strong class='adj'> adj. </strong> 1 fit to be imitated; outstandingly good. 2 serving as a warning. 3 illustrative. [latin: related to *example]</p>",
            },
        ],
    },
    {
        word: "Exemplify",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exemplify <strong class='verb'> v. </strong> (-ies, -ied) 1 illustrate by example. 2 be an example of. exemplification n.</p>",
            },
        ],
    },
    {
        word: "Exempt",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exempt <strong class='adj'> —adj.</strong> (often foll. By from) free from an obligation or liability etc. Imposed on others. —v. (foll. By from) make exempt. exemption <strong class='noun'> n. </strong> [latin eximo -empt- take out]</p>",
            },
        ],
    },
    {
        word: "Exercise",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exercise <strong class='noun'> —n. </strong> 1 activity requiring physical effort, done to sustain or improve health. 2 mental or spiritual activity, esp. As practice to develop a faculty. 3 task devised as exercise. 4 a use or application of a mental faculty, right, etc. B practice of an ability, quality, etc. 5 (often in pl.) Military drill or manoeuvres. —v. <strong class='sing'>(-sing)</strong> 1 use or apply (a faculty, right, etc.). 2 perform (a function). 3 a take (esp. Physical) exercise. B provide (an animal) with exercise. 4 a tax the powers of. B perplex, worry. [latin exerceo keep busy]</p>",
            },
        ],
    },
    {
        word: "Exert",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exert <strong class='verb'> v. </strong> 1 bring to bear, use (a quality, force, influence, etc.). 2 refl. (often foll. By for, or to + infin.) Use one's efforts or endeavours; strive. exertion <strong class='noun'> n. </strong> [latin exsero exsert- put forth]</p>",
            },
        ],
    },
    {
        word: "Exert",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exert <strong class='verb'> v. </strong> 1 bring to bear, use (a quality, force, influence, etc.). 2 refl. (often foll. By for, or to + infin.) Use one's efforts or endeavours; strive. exertion <strong class='noun'> n. </strong> [latin exsero exsert- put forth]</p>",
            },
        ],
    },
    {
        word: "Exeunt",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exeunt <strong class='verb'> v. </strong> (as a stage direction) (actors) leave the stage. [latin: related to *exit]</p>",
            },
        ],
    },
    {
        word: "Exfoliate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exfoliate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 come off in scales or layers. 2 throw off layers of bark. exfoliation <strong class='noun'> n. </strong> [latin folium leaf]</p>",
            },
        ],
    },
    {
        word: "Ex gratia",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex gratia <strong class='adv'> —adv. </strong> As a favour; not from (esp. Legal) obligation. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Granted on this basis. [latin, = from favour]</p>",
            },
        ],
    },
    {
        word: "Exhale",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhale <strong class='verb'> v. </strong> (-ling) 1 breathe out. 2 give off or be given off in vapour. exhalation <strong class='noun'> n. </strong> [french from latin halo breathe]</p>",
            },
        ],
    },
    {
        word: "Exhaust",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhaust <strong class='noun'> —v. </strong> 1 consume or use up the whole of. 2 (often as exhausted adj. Or exhausting adj.) Tire out. 3 study or expound (a subject) completely. 4 (often foll. By of) empty (a vessel etc.) Of its contents. <strong class='noun'> —n. </strong> 1 waste gases etc. Expelled from an engine after combustion. 2 (also exhaust-pipe) pipe or system by which these are expelled. 3 process of expulsion of these gases. exhaustible adj. [latin haurio haust- drain]</p>",
            },
        ],
    },
    {
        word: "Exhaustion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhaustion <strong class='noun'> n. </strong> 1 exhausting or being exhausted. 2 total loss of strength.</p>",
            },
        ],
    },
    {
        word: "Exhaustive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhaustive <strong class='adj'> adj. </strong> Thorough, comprehensive. exhaustively <strong class='adv'> adv. </strong> Exhaustiveness n.</p>",
            },
        ],
    },
    {
        word: "Exhibit",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhibit <strong class='noun'> —v. </strong> (-t-) 1 show or reveal, esp. Publicly. 2 display (a quality etc.). <strong class='noun'> —n. </strong> Item displayed, esp. In an exhibition or as evidence in a lawcourt. exhibitor <strong class='noun'> n. </strong> [latin exhibeo -hibit-]</p>",
            },
        ],
    },
    {
        word: "Exhibition",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhibition <strong class='noun'> n. </strong> 1 display (esp. Public) of works of art etc. 2 exhibiting or being exhibited. 3 scholarship, esp. From the funds of a school, college, etc.</p>",
            },
        ],
    },
    {
        word: "Exhibitioner",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhibitioner <strong class='noun'> n. </strong> Student who has been awarded an exhibition.</p>",
            },
        ],
    },
    {
        word: "Exhibitionism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhibitionism <strong class='noun'> n. </strong> 1 tendency towards attention-seeking behaviour. 2 psychol. Compulsion to display one's genitals in public. exhibitionist n.</p>",
            },
        ],
    },
    {
        word: "Exhilarate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhilarate <strong class='verb'> v. </strong> (often as exhilarating adj. Or exhilarated adj.) Enliven, gladden; raise the spirits of. exhilaration <strong class='noun'> n. </strong> [latin hilaris cheerful]</p>",
            },
        ],
    },
    {
        word: "Exhort",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhort <strong class='verb'> v. </strong> (often foll. By to + infin.) Urge strongly or earnestly. exhortation <strong class='noun'> n. </strong> Exhortative adj. Exhortatory adj. [latin exhortor encourage]</p>",
            },
        ],
    },
    {
        word: "Exhume",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exhume <strong class='verb'> v. </strong> (-ming) dig up (esp. A buried corpse). exhumation <strong class='noun'> n. </strong> [latin humus ground]</p>",
            },
        ],
    },
    {
        word: "Exigency",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exigency <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> (also exigence) 1 urgent need or demand. 2 emergency. exigent adj. [latin exigo *exact]</p>",
            },
        ],
    },
    {
        word: "Exiguous",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exiguous <strong class='adj'> adj. </strong> Scanty, small. exiguity <strong class='noun'> n. </strong> [latin]</p>",
            },
        ],
    },
    {
        word: "Exile",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exile <strong class='noun'> —n. </strong> 1 expulsion from one's native land or (internal exile) native town etc. 2 long absence abroad. 3 exiled person. —v. (-ling) send into exile. [french from latin]</p>",
            },
        ],
    },
    {
        word: "Exist",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exist <strong class='verb'> v. </strong> 1 have a place in objective reality. 2 (of circumstances etc.) Occur; be found. 3 live with no pleasure. 4 continue in being. 5 live. [latin existo]</p>",
            },
        ],
    },
    {
        word: "Existence",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Existence <strong class='noun'> n. </strong> 1 fact or manner of being or existing. 2 continuance in life or being. 3 all that exists. existent adj.</p>",
            },
        ],
    },
    {
        word: "Existential",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Existential <strong class='adj'> adj. </strong> 1 of or relating to existence. 2 philos. Concerned with existence, esp. With human existence as viewed by existentialism. existentially adv.</p>",
            },
        ],
    },
    {
        word: "Existentialism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Existentialism <strong class='noun'> n. </strong> Philosophical theory emphasizing the existence of the individual as a free and self-determining agent. existentialist <strong class='noun'> n. </strong> & adj.</p>",
            },
        ],
    },
    {
        word: "Exit",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exit <strong class='noun'> —n. </strong> 1 passage or door by which to leave a room etc. 2 act or right of going out. 3 place where vehicles can leave a motorway etc. 4 actor's departure from the stage. —v. (-t-) 1 go out of a room etc. 2 leave the stage (also as a direction: exit macbeth). [latin exeo exit- go out]</p>",
            },
        ],
    },
    {
        word: "Exit poll",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exit poll <strong class='noun'> n. </strong> Poll of people leaving a polling-station, asking how they voted.</p>",
            },
        ],
    },
    {
        word: "Exo-",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exo- <strong class='n_pl'> comb. </strong> Form external. [greek exo outside]</p>",
            },
        ],
    },
    {
        word: "Exocrine",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exocrine <strong class='adj'> adj. </strong> (of a gland) secreting through a duct. [greek krino sift]</p>",
            },
        ],
    },
    {
        word: "Exodus",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exodus <strong class='noun'> n. </strong> 1 mass departure. 2 (exodus) biblical departure of the israelites from egypt. [greek hodos way]</p>",
            },
        ],
    },
    {
        word: "Ex officio",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex officio <strong class='adv'> adv. </strong> & <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> By virtue of one's office. [latin]</p>",
            },
        ],
    },
    {
        word: "Exonerate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exonerate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (often foll. By from) free or declare free from blame etc. exoneration <strong class='noun'> n. </strong> [latin onus oner- burden]</p>",
            },
        ],
    },
    {
        word: "Exorbitant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exorbitant <strong class='adj'> adj. </strong> (of a price, demand, etc.) Grossly excessive. [latin: related to *orbit]</p>",
            },
        ],
    },
    {
        word: "Exorcize",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exorcize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 expel (a supposed evil spirit) by prayers etc. 2 (often foll. By of) free (a person or place) in this way. exorcism <strong class='noun'> n. </strong> Exorcist <strong class='noun'> n. </strong> [greek horkos oath]</p>",
            },
        ],
    },
    {
        word: "Exordium",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exordium <strong class='noun'> n. </strong> (pl. -s or -dia) introductory part, esp. Of a discourse or treatise. [latin exordior begin]</p>",
            },
        ],
    },
    {
        word: "Exotic",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exotic <strong class='adj'> —adj.</strong> 1 introduced from a foreign country; not native. 2 strange or unusual. <strong class='noun'> —n. </strong> Exotic person or thing. exotically <strong class='adv'> adv. </strong> [greek exo outside]</p>",
            },
        ],
    },
    {
        word: "Exotica",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exotica <strong class='n_pl'> n.pl. </strong> Strange or rare objects.</p>",
            },
        ],
    },
    {
        word: "Expand",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expand <strong class='verb'> v. </strong> 1 increase in size or importance. 2 (often foll. By on) give a fuller account. 3 become more genial. 4 set or write out in full. 5 spread out flat. expandable adj. [latin pando pans- spread]</p>",
            },
        ],
    },
    {
        word: "Expanse",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expanse <strong class='noun'> n. </strong> Wide continuous area of land, space, etc.</p>",
            },
        ],
    },
    {
        word: "Expansible",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expansible <strong class='adj'> adj. </strong> That can be expanded.</p>",
            },
        ],
    },
    {
        word: "Expansion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expansion <strong class='noun'> n. </strong> 1 expanding or being expanded. 2 enlargement of the scale or scope of a business.</p>",
            },
        ],
    },
    {
        word: "Expansionism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expansionism <strong class='noun'> n. </strong> Advocacy of expansion, esp. Of a state's territory. expansionist <strong class='noun'> n. </strong> & adj.</p>",
            },
        ],
    },
    {
        word: "Expansive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expansive <strong class='adj'> adj. </strong> 1 able or tending to expand. 2 extensive. 3 (of a person etc.) Effusive, open. expansively <strong class='adv'> adv. </strong> Expansiveness n.</p>",
            },
        ],
    },
    {
        word: "Expat",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expat <strong class='noun'> n. </strong> & adj. Colloq. Expatriate. [abbreviation]</p>",
            },
        ],
    },
    {
        word: "Expatiate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expatiate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (usu. Foll. By on, upon) speak or write at length. expatiation <strong class='noun'> n. </strong> Expatiatory adj. [latin spatium *space]</p>",
            },
        ],
    },
    {
        word: "Expatriate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expatriate <strong class='adj'> —adj.</strong> 1 living abroad. 2 exiled. <strong class='noun'> —n. </strong> Expatriate person. —v. <strong class='ting'> (-ting) </strong> 1 expel (a person) from his or her native country. 2 refl. Renounce one's citizenship. expatriation <strong class='noun'> n. </strong> [latin patria native land]</p>",
            },
        ],
    },
    {
        word: "Expect",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expect <strong class='verb'> v. </strong> 1 a regard as likely. B look for as appropriate or one's due (i expect cooperation). 2 <strong class='colloq'> colloq. </strong> Think, suppose. be expecting <strong class='colloq'> colloq. </strong> Be pregnant (with). [latin specto look]</p>",
            },
        ],
    },
    {
        word: "Expectancy",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expectancy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 state of expectation. 2 prospect. 3 (foll. By of) prospective chance.</p>",
            },
        ],
    },
    {
        word: "Expectant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expectant <strong class='adj'> adj. </strong> 1 hopeful, expecting. 2 having an expectation. 3 pregnant. expectantly adv.</p>",
            },
        ],
    },
    {
        word: "Expectation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expectation <strong class='noun'> n. </strong> 1 expecting or anticipation. 2 thing expected. 3 (foll. By of) probability of an event. 4 (in pl.) One's prospects of inheritance.</p>",
            },
        ],
    },
    {
        word: "Expectorant",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expectorant <strong class='adj'> —adj.</strong> Causing expectoration. <strong class='noun'> —n. </strong> Expectorant medicine.</p>",
            },
        ],
    },
    {
        word: "Expectorate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expectorate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (also absol.) Cough or spit out (phlegm etc.). expectoration <strong class='noun'> n. </strong> [latin pectus pector- breast]</p>",
            },
        ],
    },
    {
        word: "Expedient",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expedient <strong class='adj'> —adj.</strong> Advantageous; advisable on practical rather than moral grounds. <strong class='noun'> —n. </strong> Means of attaining an end; resource. expedience <strong class='noun'> n. </strong> Expediency <strong class='noun'> n. </strong> [related to *expedite]</p>",
            },
        ],
    },
    {
        word: "Expedite",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expedite <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 assist the progress of. 2 accomplish (business) quickly. [latin expedio from pes ped- foot]</p>",
            },
        ],
    },
    {
        word: "Expedition",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expedition <strong class='noun'> n. </strong> 1 journey or voyage for a particular purpose, esp. Exploration. 2 people etc. Undertaking this. 3 speed. [latin: related to *expedite]</p>",
            },
        ],
    },
    {
        word: "Expeditionary",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expeditionary <strong class='adj'> adj. </strong> Of or used in an expedition.</p>",
            },
        ],
    },
    {
        word: "Expeditious",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expeditious <strong class='adj'> adj. </strong> Acting or done with speed and efficiency.</p>",
            },
        ],
    },
    {
        word: "Expel",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expel <strong class='verb'> v. </strong> (-ll-) (often foll. By from) 1 deprive (a person) of membership etc. Of a school, society, etc. 2 force out, eject. 3 order or force to leave a building etc. [latin pello puls- drive]</p>",
            },
        ],
    },
    {
        word: "Expend",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expend <strong class='verb'> v. </strong> Spend or use up (money, time, etc.). [latin pendo pens- weigh]</p>",
            },
        ],
    },
    {
        word: "Expendable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expendable <strong class='adj'> adj. </strong> That may be sacrificed or dispensed with; not worth preserving or saving.</p>",
            },
        ],
    },
    {
        word: "Expenditure",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expenditure <strong class='noun'> n. </strong> 1 spending or using up. 2 thing (esp. Money) expended.</p>",
            },
        ],
    },
    {
        word: "Expense",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expense <strong class='noun'> n. </strong> 1 cost incurred; payment of money. 2 (usu. In pl.) A costs incurred in doing a job etc. B amount paid to reimburse this. 3 thing on which money is spent. at the expense of so as to cause loss or harm to; costing. [latin expensa: related to *expend]</p>",
            },
        ],
    },
    {
        word: "Expense account",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expense account <strong class='noun'> n. </strong> List of an employee's expenses payable by the employer.</p>",
            },
        ],
    },
    {
        word: "Expensive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expensive <strong class='adj'> adj. </strong> Costing or charging much. expensively <strong class='adv'> adv. </strong> Expensiveness n.</p>",
            },
        ],
    },
    {
        word: "Experience",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Experience <strong class='noun'> —n. </strong> 1 observation of or practical acquaintance with facts or events. 2 knowledge or skill resulting from this. 3 event or activity participated in or observed (a rare experience). —v. (-cing) 1 have experience of; undergo. 2 feel. [latin experior -pert- try]</p>",
            },
        ],
    },
    {
        word: "Experienced",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Experienced <strong class='adj'> adj. </strong> 1 having had much experience. 2 skilled from experience (experienced driver).</p>",
            },
        ],
    },
    {
        word: "Experiential",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Experiential <strong class='adj'> adj. </strong> Involving or based on experience. experientially adv.</p>",
            },
        ],
    },
    {
        word: "Experiment",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Experiment <strong class='noun'> —n. </strong> Procedure adopted in the hope of success, or for testing a hypothesis etc., or to demonstrate a known fact. —v. (often foll. By on, with) make an experiment. experimentation <strong class='noun'> n. </strong> Experimenter <strong class='noun'> n. </strong> [latin: related to *experience]</p>",
            },
        ],
    },
    {
        word: "Experimental",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Experimental <strong class='adj'> adj. </strong> 1 based on or making use of experiment. 2 used in experiments. experimentalism <strong class='noun'> n. </strong> Experimentally adv.</p>",
            },
        ],
    },
    {
        word: "Expert",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expert <strong class='adj'> —adj.</strong> 1 (often foll. By at, in) having special knowledge of or skill in a subject. 2 (attrib.) Involving or resulting from this (expert advice). <strong class='noun'> —n. </strong> (often foll. By at, in) person with special knowledge or skill. expertly <strong class='adv'> adv. </strong> [latin: related to *experience]</p>",
            },
        ],
    },
    {
        word: "Expertise",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expertise <strong class='noun'> n. </strong> Expert skill, knowledge, or judgement. [french]</p>",
            },
        ],
    },
    {
        word: "Expiate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expiate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> pay the penalty for or make amends for (wrongdoing). expiable adj. Expiation <strong class='noun'> n. </strong> Expiatory adj. [latin expio: related to *pious]</p>",
            },
        ],
    },
    {
        word: "Expire",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expire <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 (of a period of time, validity, etc.) Come to an end. 2 cease to be valid. 3 die. 4 (also absol.) Breathe out (air etc.). expiration <strong class='noun'> n. </strong> Expiratory adj. (in sense 4). [latin spirare breathe]</p>",
            },
        ],
    },
    {
        word: "Expiry",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expiry <strong class='noun'> n. </strong> End of validity or duration.</p>",
            },
        ],
    },
    {
        word: "Explain",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explain <strong class='verb'> v. </strong> 1 a make clear or intelligible (also absol.: let me explain). B make known in detail. 2 (foll. By that) say by way of explanation. 3 account for (one's conduct etc.). explain away minimize the significance of by explanation. Explain oneself 1 make one's meaning clear. 2 give an account of one's motives or conduct. [latin explano from planus flat]</p>",
            },
        ],
    },
    {
        word: "Explanation",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explanation <strong class='noun'> n. </strong> 1 explaining. 2 statement or circumstance that explains something.</p>",
            },
        ],
    },
    {
        word: "Explanatory",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explanatory <strong class='adj'> adj. </strong> Serving or designed to explain.</p>",
            },
        ],
    },
    {
        word: "Expletive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expletive <strong class='noun'> n. </strong> Swear-word or exclamation. [latin expleo fill out]</p>",
            },
        ],
    },
    {
        word: "Explicable",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explicable <strong class='adj'> adj. </strong> That can be explained.</p>",
            },
        ],
    },
    {
        word: "Explicate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explicate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 develop the meaning of (an idea etc.). 2 explain (esp. A literary text). explication <strong class='noun'> n. </strong> [latin explico -plicat- unfold]</p>",
            },
        ],
    },
    {
        word: "Explicit",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explicit <strong class='adj'> adj. </strong> 1 expressly stated, not merely implied; stated in detail. 2 definite. 3 outspoken. explicitly <strong class='adv'> adv. </strong> Explicitness <strong class='noun'> n. </strong> [latin: related to *explicate]</p>",
            },
        ],
    },
    {
        word: "Explode",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explode <strong class='verb'> v. </strong> (-ding) 1 a expand suddenly with a loud noise owing to a release of internal energy. B cause (a bomb etc.) To explode. 2 give vent suddenly to emotion, esp. Anger. 3 (of a population etc.) Increase suddenly or rapidly. 4 show (a theory etc.) To be false or baseless. 5 (as exploded adj.) (of a drawing etc.) Showing the components of a mechanism somewhat separated but in the normal relative positions. [latin explodo -plos- hiss off the stage]</p>",
            },
        ],
    },
    {
        word: "Exploit",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exploit <strong class='noun'> —n. </strong> Daring feat. —v. 1 make use of (a resource etc.). 2 usu. Derog. Utilize or take advantage of (esp. A person) for one's own ends. exploitation <strong class='noun'> n. </strong> Exploitative adj. Exploiter <strong class='noun'> n. </strong> [latin: related to *explicate]</p>",
            },
        ],
    },
    {
        word: "Explore",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explore <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 travel through (a country etc.) To learn about it. 2 inquire into. 3 surgery examine (a part of the body) in detail. exploration <strong class='noun'> n. </strong> Exploratory adj. Explorer <strong class='noun'> n. </strong> [latin exploro search out]</p>",
            },
        ],
    },
    {
        word: "Explosion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explosion <strong class='noun'> n. </strong> 1 exploding. 2 loud noise caused by this. 3 sudden outbreak of feeling. 4 rapid or sudden increase. [latin: related to *explode]</p>",
            },
        ],
    },
    {
        word: "Explosive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Explosive <strong class='adj'> —adj.</strong> 1 able, tending, likely to explode. 2 likely to cause a violent outburst etc.; dangerously tense. <strong class='noun'> —n. </strong> Explosive substance. explosiveness n.</p>",
            },
        ],
    },
    {
        word: "Expo",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expo <strong class='noun'> n. </strong> (also expo) <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> large international exhibition. [abbreviation of *exposition 4]</p>",
            },
        ],
    },
    {
        word: "Exponent",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exponent <strong class='noun'> n. </strong> 1 person who promotes an idea etc. 2 practitioner of an activity, profession, etc. 3 person who explains or interprets something. 4 type or representative. 5 raised symbol beside a numeral indicating how many of the number are to be multiplied together (e.g. 23 = 2 x 2 x 2). [latin expono *expound]</p>",
            },
        ],
    },
    {
        word: "Exponential",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exponential <strong class='adj'> adj. </strong> 1 of or indicated by a mathematical exponent. 2 (of an increase etc.) More and more rapid.</p>",
            },
        ],
    },
    {
        word: "Export",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Export <strong class='noun'> —v. </strong> Sell or send (goods or services) to another country. <strong class='noun'> —n. </strong> 1 exporting. 2 a exported article or service. B (in pl.) Amount exported. exportation <strong class='noun'> n. </strong> Exporter <strong class='noun'> n. </strong> [latin porto carry]</p>",
            },
        ],
    },
    {
        word: "Expose",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expose <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> (esp. As exposed adj.) 1 leave uncovered or unprotected, esp. From the weather. 2 (foll. By to) a put at risk of. B subject to (an influence etc.). 3 photog. Subject (a film) to light, esp. By operation of a camera. 4 reveal the identity or fact of. 5 exhibit, display. expose oneself display one's body, esp. One's genitals, indecently in public. [latin pono put]</p>",
            },
        ],
    },
    {
        word: "Exposé",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exposé <strong class='noun'> n. </strong> 1 orderly statement of facts. 2 revelation of something discreditable. [french]</p>",
            },
        ],
    },
    {
        word: "Exposition",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exposition <strong class='noun'> n. </strong> 1 explanatory account. 2 explanation or commentary. 3 <strong class='mus'> mus. </strong> Part of a movement in which the principal themes are presented. 4 large public exhibition. [latin: related to *expound]</p>",
            },
        ],
    },
    {
        word: "Ex post facto",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex post facto <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> With retrospective action or force. [latin, = in the light of subsequent events]</p>",
            },
        ],
    },
    {
        word: "Expostulate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expostulate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> (often foll. By with a person) make a protest; remonstrate. expostulation <strong class='noun'> n. </strong> Expostulatory adj. [latin: related to *postulate]</p>",
            },
        ],
    },
    {
        word: "Exposure",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exposure <strong class='noun'> n. </strong> (foll. By to) 1 exposing or being exposed. 2 physical condition resulting from being exposed to the elements. 3 photog. A exposing a film etc. To the light. B duration of this. C section of film etc. Affected by it.</p>",
            },
        ],
    },
    {
        word: "Expound",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expound <strong class='verb'> v. </strong> 1 set out in detail. 2 explain or interpret. [latin pono posit- place]</p>",
            },
        ],
    },
    {
        word: "Express",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Express <strong class='noun'> —v. </strong> 1 represent or make known in words or by gestures, conduct, etc. 2 refl. Communicate what one thinks, feels, or means. 3 esp. Math. Represent by symbols. 4 squeeze out (liquid or air). 5 send by express service. <strong class='adj'> —adj.</strong> 1 operating at high speed. 2 also definitely stated. 3 delivered by a specially fast service. <strong class='adv'> —adv. </strong> 1 at high speed. 2 by express messenger or train. <strong class='noun'> —n. </strong> 1 express train etc. 2 us service for the rapid transport of parcels etc. expressible adj. Expressly <strong class='adv'> adv. </strong> (in sense 2 of adj.). [latin exprimo -press- squeeze out]</p>",
            },
        ],
    },
    {
        word: "Expression",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expression <strong class='noun'> n. </strong> 1 expressing or being expressed. 2 word or phrase expressed. 3 person's facial appearance, indicating feeling. 4 conveying of feeling in music, speaking, dance, etc. 5 depiction of feeling etc. In art. 6 math. Collection of symbols expressing a quantity. expressionless adj. [french: related to *express]</p>",
            },
        ],
    },
    {
        word: "Expressionism",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expressionism <strong class='noun'> n. </strong> Style of painting, music, drama, etc., seeking to express emotion rather than the external world. expressionist <strong class='noun'> n. </strong> & adj.</p>",
            },
        ],
    },
    {
        word: "Expressive",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expressive <strong class='adj'> adj. </strong> 1 full of expression (expressive look). 2 (foll. By of) serving to express. expressively <strong class='adv'> adv. </strong> Expressiveness n.</p>",
            },
        ],
    },
    {
        word: "Expresso",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expresso <strong class='var'> var. </strong> Of *espresso.</p>",
            },
        ],
    },
    {
        word: "Expressway",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expressway <strong class='noun'> n. </strong> Us motorway.</p>",
            },
        ],
    },
    {
        word: "Expropriate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expropriate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 take away (property) from its owner. 2 (foll. By from) dispossess. expropriation <strong class='noun'> n. </strong> Expropriator <strong class='noun'> n. </strong> [latin proprium property]</p>",
            },
        ],
    },
    {
        word: "Expulsion",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expulsion <strong class='noun'> n. </strong> Expelling or being expelled. expulsive adj. [latin: related to *expel]</p>",
            },
        ],
    },
    {
        word: "Expunge",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expunge <strong class='verb'> v. </strong> (-ging) erase, remove (objectionable matter) from a book etc. [latin expungo prick out (for deletion)]</p>",
            },
        ],
    },
    {
        word: "Expurgate",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Expurgate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 remove objectionable matter from (a book etc.). 2 remove (such matter). expurgation <strong class='noun'> n. </strong> Expurgator <strong class='noun'> n. </strong> [latin: related to *purge]</p>",
            },
        ],
    },
    {
        word: "Exquisite",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Exquisite <strong class='adj'> adj. </strong> 1 extremely beautiful or delicate. 2 keenly felt (exquisite pleasure). 3 highly sensitive (exquisite taste). exquisitely <strong class='adv'> adv. </strong> [latin exquiro -quisit- seek out]</p>",
            },
        ],
    },
    {
        word: "Ex-serviceman",
        language: [
            {
                language: "en",
                title: "Engish",
                meaning:
                    "<p>Ex-serviceman <strong class='noun'> n. </strong> Man formerly a member of the armed forces.</p>",
            },
        ],
    },
];

export const alphabet_E_Hindi = [
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ई 2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इ-<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रत्येक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक दूसरे<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हर तरीके से<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आतुर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्सुक ऊदबिलाव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गरुड़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईगल आई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उक़ाब का बच्चा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ई। और ओ। इ।<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Ear1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Ear2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कान का दर्द<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कान का परदा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झलकदार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>राजा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अर्ल मार्शल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल्दी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल्दी उठ कर काम शुरू करने वाला व्यक्ति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शुरुआती दिन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शुरुआत से ही<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दाग़ना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बयाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईरफ़ोन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इयरपीस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कर्ण भेदन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कान के प्लग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कान की बाली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>श्रवण सीमा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कान-तेज<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धरती<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सांसारिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिट्टी का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिट्टी के बरतन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धरती<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सांसारिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पृथ्वी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भूकंप<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पृथ्वी विज्ञान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पृथ्वी-टूट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिट्टी की खोदाई के काम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>केंचुआ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिट्टी की<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कर्कश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इयरविग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आराम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चित्रफलक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दिलजमई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सरलता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्वाभिमुख<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईस्ट एन्ड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईस्टर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईस्टरी अंडा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व की ओर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्वी चर्च<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्राच्यदेशवासी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व-उत्तर-पूर्व में<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व-दक्षिण-पूर्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व की ओर जानेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आसान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गुदगुदी आरम - कुरसी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आरामपसंद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आसान सड़क<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खाने योग्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भक्षक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सेब आदि खाना आदि<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>औ डे कलाण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कंगनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>छिपकर बातें सुनना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अवनति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आबनिट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आबनूस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उबलनेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चुनाव आयोग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विलक्षण व्यक्ति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>केक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पादरी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गिरिजाघर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईसीजी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>टोली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इकिडना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इचिनोडर्म<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गूंज<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गूंज कक्ष<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रतिध्वनित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एचोलोकातिओं<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सोनार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एख्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Éclair<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्लंप्षण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शोभा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उदार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग्रहण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्रांतिवृत्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नाकाम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पारिस्थितिकी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>परिस्थितिकी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आर्थिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>किफ़ायती<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अर्थशास्त्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अर्थशास्त्री<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बचत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अर्थव्यवस्था<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>किफायती वर्ग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अर्थव्यवस्था आकार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पारिस्थितिकी तंत्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>परमानंद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईसीटी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Ecto-<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ectomorph<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-मीटॉमी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्टोप्लाज्म<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईसीयू<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दुनियावी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खुजली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईडी।<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-ed1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-ed2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एडाम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एडी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एडलवाइज<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शोफ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईडन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गरदना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>किनारा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धारा से<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>किनारा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नुकीला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खाद्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अध्यादेश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भवन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपदेश देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपादन करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संस्करण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपादक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपादकीय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईडीपी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शिक्षित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शिक्षित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शिक्षा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शिक्षाविद्<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निष्कर्ष निकालना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एडवर्ड का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-ee<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आज्ञा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईईजी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बाम मछली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-eer<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भयानक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Ef-<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिटाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रभाव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रभावी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्रियाशील<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्रैण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जोश में आना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अशक्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रभावोत्पादक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दक्ष<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पुतला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फूल जाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रवाह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कोशिश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सरल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गुस्ताख़ी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दीप्तिमान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पुष्ट करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहाव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>असंयत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईएफएल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईएफटी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईएफटीए<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उदा।<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समानाधिकारवादी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंडा 1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंडा 2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंडे के लिए कप<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंडे का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंडमुख<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बैंगन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>eggshell<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंडे सा सफेद हिस्सा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अग्लंटिन गुलाब<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अहंकार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अहंकारपूर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अहंभाव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अहंकार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अहं यात्रा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रबल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निकास<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफ़ेद बगुला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिस्र के<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिसरशास्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऐडर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईडरडाउन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आठ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अठारह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आठ गुना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आठवाँ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आठवें<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अस्सी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आइंस्टिनियम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Eisteddfod<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>या<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बोल पड़ना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निकालें<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बेदखलदार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बेदखलदार सीट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्तार में बताना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अलंड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बीतना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लोचदार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>elasticated<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रबर बैण्ड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>elastomer<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उल्लसित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कोहनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कठोर परिश्रम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हाथ रखने की जगह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एल्डर 1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुजुर्ग 2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुजुर्ग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुज़ुर्ग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बड़े राजनेता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज्येष्ठ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Eldorado<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अलिकेंपेन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोनिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चुनाव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चुनाव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निर्वाचित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निर्वाचक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मतदाताओं<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युतीय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली का कम्बल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युतीय कुरसी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली का झटका देने वाली मच्छली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली की नज़र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत आग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत गिटार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली मिस्त्री<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत का झटका<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युतीकरण करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोकार्डियोग्राम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोकार्डियोग्राफ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत-<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत् से मारना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली का गतिविज्ञान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोएन्सेफलोग्राम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोएन्सेफलोग्राफ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोलीज़ करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोलीज़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोलाइट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोलाइज़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युतचुंबकीय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत चुंबकत्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली का रेल का इंजन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत प्रभावन बल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रॉन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोनिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रॉनिक मेल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रानिक्स<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रॉनिक टैगिंग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रॉन लेंस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रान सूक्ष्मदर्शी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रॉनवोल्ट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली से सोनाचांदी चढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युतदर्शी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत के झटके<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोस्टाटिक्स<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत -विज्ञान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सुरुचिपूर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शोक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शोकगीत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तत्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मौलिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्राथमिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्राथमिक कण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हाथी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फ़ीलपाँव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हाथी का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तरक्की<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊंचाई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिफ़्ट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग्यारह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग्यारहफोल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग्यारह प्लस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊंचाई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग्यारहवें<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>योगिनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>परि-संबंधी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रकाश में लाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>योग्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिजली से सोनाचांदी चढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युतदर्शी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत के झटके<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलेक्ट्रोस्टाटिक्स<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत -विज्ञान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विद्युत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सुरुचिपूर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शोक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शोकगीत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तत्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मौलिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्राथमिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्राथमिक कण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हाथी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फ़ीलपाँव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हाथी का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तरक्की<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊंचाई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिफ़्ट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग्यारह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग्यारहफोल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग्यारह प्लस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊंचाई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग्यारहवें<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>योगिनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>परि-संबंधी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रकाश में लाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>योग्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हटाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इलिजन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अभिजात वर्ग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Élitism<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अमृत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अलिज़बेटन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गोज़न<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पक्ष<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंडाकार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंडाकार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दीर्घवृत्ताभ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंडाकार का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एल्म<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाग्मिता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साथ भाग जाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाग्मिता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सुवक्ता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वरना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कहीं<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्पष्ट करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>टलना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मायावी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एल्वर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कल्पित बौने<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नटखट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नन्दन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईएम -1 2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दुर्बल करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईमेल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निर्गत होना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्वतंत्र करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रभावहीन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शवरक्षालेप करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तटबंध<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घाटबंधी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लगना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शर्मिंदा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दूतावास<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पंक्तिबद्ध<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एम्बेड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संवारना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंगार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एम्बर डेज़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पचाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कड़वा बनाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रशंसा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रतीक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अवतार लेना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रोत्साहित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दिल का आवेश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एम्बोलुस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उभारदार नक्क़ाशी करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुहाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अपनाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>झारोखा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लोशन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कढ़ाई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कढ़ाई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उलझाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भ्रूण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भ्रूणविज्ञान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ठीक करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पन्ना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पन्ना हरा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आयरलैंड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उभरना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आपातकालीन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एमेरिटस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कस्र्न पत्थर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कस्र्न पत्थर की फलक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उबकाई की<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईएमएफ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्प्रवासी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विदेशवास करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रवासी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>श्रेष्ठता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Éminence grise<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रख्यात<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अमीर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अमीरात<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दूत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फेंकना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धरना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वेतन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भाव का प्रकट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भावना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भावनात्मक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भावपूर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>को सूचीबद्ध<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सहानुभूति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सहानुभूति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सम्राट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शहंशाह पेंग्विन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़ोर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़ोर देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़ोरदार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वातस्फीति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साम्राज्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एम्पायर बिल्डिंग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रयोगसिद्ध<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क़ायम करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>काम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कर्मचारी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रोज़गार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रोजगार कार्यालय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिक्री भंडार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सशक्तिकरण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महारानी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खाली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ख़ाली हाथ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनाड़ी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्वर्ग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईएमएस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एमु<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एमु<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनुकरण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रासायनिक पायसी करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पायसन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इमल्शन पेंट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईएन -1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एन -2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-न<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सक्षम करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अभिनय करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कानून<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तामचीनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आनंदित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आनंदित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्ण - रूपेण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पड़ाव डालना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Encapsulate<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डिब्बे में बंद करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मटचिनिया<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-स<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इंसेफेलाइटिस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मस्तिष्क -गंदगी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एन्सेफैलोग्राफ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एंचेंट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जादूगार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घेरना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एन्क्लेव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लगा देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दीवार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एन्कोड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घनत्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धरना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दोहराना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सामना करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रोत्साहित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अतिक्रमण करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उलझाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-ता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>encyclical<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विश्वकोश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विश्वकोश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समाप्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जोखिम में डालना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लुप्तप्राय प्रजातियां<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्यार बचाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लाड़ प्यार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रयास<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रयास<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्थानिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समापन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विलायती<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनंत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंतिम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एंडो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंत: स्रावी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंतर्जात<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंतर्गर्भाशयकला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>endomorph<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समर्थन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एंडोस्कोप<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रदान करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अक्षय निधि<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बंदोबस्ती बंधक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंतपाह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आखरी उत्पाद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रदान करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सहनशीलता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सहना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एंडवे<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एनीमा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शत्रु<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शक्तिशाली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Energize<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऊर्जा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कमज़ोर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एन फेमिल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भयावह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ढीला छोड़ना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एन्फ़िलैड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लपेटना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लागू करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मताधिकार देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यस्त रखना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यस्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सगाई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मनोहन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पैदा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यन्त्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अभियंता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अभियांत्रिकी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंग्रेज़ी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंग्रेज़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लटके हुए<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>टीका लगाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्कीर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एनग्रेविंग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तल्लीन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निगल जाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पहेली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हुक्म चलाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आनंद लेना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सुखद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आग लगाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बड़े आकार में<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चित्रवर्धक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सूचित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रबोधन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भर्ती<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सजीव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जाल में फंसना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शत्रुता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उदात्त बनाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विरक्ति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दुष्टता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विशाल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पर्याप्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पसाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संलग्न करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूछताछ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जाँच करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्रुद्ध करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रफुल्ल करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समृद्ध<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भर्ती<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नामांकन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रस्ते में<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का होना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कलाकारों की टुकड़ी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रतिष्ठापित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ओढ़ना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रतीक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुँह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वश में रखना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फुसलाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पीछा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संलग्न<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सुनिश्चित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईएनटी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-कमाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इंतैबलमंत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आवश्यक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उलझाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंतंत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कड़ा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रवेश करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आंतों का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रवेश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रविष्टि<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गुथना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ई संख्या<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>की गणना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गणनाकार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उच्चारित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ढंक लेना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लिफ़ाफ़ा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गहरी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईर्ष्या<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पर्यावरण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पर्यावरणविद्<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पड़ोस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>परिकल्पना करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दूत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईर्ष्या<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ढकना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एनजाइम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इयोसीन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इओलियन हार्प<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वंशीय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कल्प<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एपि<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एपोलेट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एपोलेट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एपी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ephedrine<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्षणभंगुरता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अल्पकालिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महामारी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महाकाव्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आम के लिंग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपरिकेंद्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपरिकेंद्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रसिया<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एपिकुरे<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महामारी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महामारी विज्ञान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एपिडर्मिस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>epidiascope<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एपीड्यूरल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपकला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चुटकुला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सूक्ति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिरगी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिरगी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपसंहार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अहसास<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिशप का पद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिशप का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिशप का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिशपवाद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कटान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रकरण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रासंगिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज्ञानमीमांसा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पत्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पत्रकाव्यगत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समाधि-लेख<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपकला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विशेषण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रतीक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ख़ुलासा लिखना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईपीएनएस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महत्त्वपूर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नामस्त्रोत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महाकाव्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>epoxy<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एपॉक्सी रेजि़न<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एप्सिलॉन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एप्सोम नमक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हमवार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बराबर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समानता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बराबर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तुल्यकारक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समान रूप से<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समान अवसर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समभाव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समानता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समीकरण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भूमध्य रेखा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भूमध्यरेखीय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साईस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घुड़सवार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इक्वि-<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समकोणेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समान दूरी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समभुज<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संतुलन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घोड़े का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विषुव का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विषुव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उद्यम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उद्यमी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मनोरंजन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मनोरंजन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मनोरंजक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मनोरंजन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग़ुलाम बनाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग़ुलाम बनाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सिंहासनारूढ़ करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अति उत्साहित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जोश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सरगर्म<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्साही<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लुभाने<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपूर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूरी तरह से<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपूर्णता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समर्थ बनाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कंपनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दफ़न करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कीटविज्ञान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घेरा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का होना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंतड़ियों<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रवेश 1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रवेश द्वार 2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शामिल होनेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फंदे में फंसना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विनती करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विनती<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Entrecote<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रवेश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मोरचा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का होना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उद्यमी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एन्ट्रापी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सौंपना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लैस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चौपहिया गाड़ी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपकरण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पासंग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>न्यायसंगत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अश्वारोहण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हिस्सेदारी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बराबर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गोलमोल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गोलमोल बात कहना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-er1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-er2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-er3<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उन्मूलन करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिटाएं<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रबड़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मिटाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एर्बियम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पहले<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खड़ा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सीधा होने के लायक़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एर्ग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फलस्वरूप<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>श्रमदक्षता शास्त्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अरगट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आयलैंड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईआरएम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एमिन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एर्नी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खत्म<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वासनोत्तेजक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कामुक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रेमकाव्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कामवासना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग़लती होना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दूतकर्म<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दया का काम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भटकनेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनियमित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इरेटा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग़लत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गलती<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कृत्रिम वस्तु<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक प्रकार का होना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भूतपूर्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>डकार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वैज्ञानिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फूटना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-एरी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विसर्प<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एरिथ्रोसाइट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तों<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आगे बढ़ाएं<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चलती सीढ़ी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्कालोप<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हरकत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बच निकलना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भागने का खंड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फरार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भगदड़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्केप वेलोसिटी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पलायनवाद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्केपॉलोजी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ढलान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>परलोक सिद्धांत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Escheat hist।<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>त्याग करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनुरक्षण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्क्रितुअ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्कूडो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ढाल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्किमो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p><p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घेघा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गुप्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईएसपी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्पैड्रिल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>espalier<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एसपार्टो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अपवादभूत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विशेषकर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्पेरांतो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जासूसी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्पलेनैड<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साहभागिता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सहायता देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व सेवा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वर्तमान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अचिंतित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अचिंतित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अचिंतित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Extemporize<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्तृत परिवार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्सटेंडेड प्ले<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्तार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यापक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्षेत्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धीमा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बाहरी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विनाश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बाहरी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अमल में लाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दुर्लभ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विलुप्त होने<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुझा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आग बुझाने की कल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उखाड़ना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रशंसा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>धमकी देकर मांगना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जबरन वसूली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लुटेरू<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अतिरिक्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अतिरिक्त-<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अतिरिक्त-<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अतिरिक्त आवरण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निचोड़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निष्कर्षण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निष्कर्षण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चिमटा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पाठ्येतर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>देनेवाला अपराधी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अपराधी देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शादी के पहला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बाह्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बाहरी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>असाधारण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्सट्रपलेशन करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अतिरिक्त संवेदी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अलौकिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>फ़िजूल ख़र्च<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कल्पात्मक नाटक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चरम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चरम एकता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चरमपंथी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सिरा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हल करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अजनबी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहिर्मुखी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बाहर निकालना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उकसाने वाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पसीजना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मग्न होना।<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-यू<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आँख<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नेत्रगोलक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नेत्रगोलक को नेत्रगोलक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चकित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भौं<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>भौं<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आंख को पकड़ने<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चकित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ताल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>थैली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बरौनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सुराख़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पलक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आंख लाइनर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आंखें खोल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ऐपिस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आंख छाया<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आई शेडो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नज़र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आंख पर जोर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रदनक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गवाह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चोर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्प्रेसो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्प्रिट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>देख लेना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Esq।<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-esque<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साहब<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-ess<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निबंध<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़रूरी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आवश्यक तेल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-EST<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्थापित करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्थापित चर्च<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्थापना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जागीर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपत्ति एजेंट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपत्ति कार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपत्ति शुल्क<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आदर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्टर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आदर लायक़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आकलन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनुमान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्तोनियावासी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहिष्कार करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एस्ट्रोजन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुहाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईटा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईटा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>और अन्य।<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आदि।<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Et cetera<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खोदना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एचिंग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शास्वत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शाश्वत त्रिभुज<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनंतकाल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनंत काल की अंगूठी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-eth<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इथेनल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एटैन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईथर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईथर का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नैतिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नैतिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नीति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इथियोपियाई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संजाति विषयक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मानव जाति विज्ञान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रकृति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एथिल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईथीलीन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वंशावली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एटियलजि<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शिष्टाचार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इट्रस्केन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Et seq।<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>-ette<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तसवीर का ख़ाका<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शब्द-साधन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यूरोपीय संघ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यूरोपीय संघ-<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युकलिप्टुस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युहरिस्ट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युजनिक्स<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यूकेरियोट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>स्तुति करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रशंसा भाषण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हिजड़ा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यंजना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यूफोनियम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>श्रुतिमधुरता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्साह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यूरेशियन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यूरेका<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यूरो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यूरो डॉलर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यूरोपीय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>युरोपियम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कान का उपकरण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इच्छामृत्यु<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खाली करूँ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निष्क्रांत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बचना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मूल्यांकन करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग़ायब होना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क्षणभंगुर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इंजील का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इंजीलवाद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इंजीलवादी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इंजील का प्रचार करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लुप्त हो जाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वाष्पीकृत दूध<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>टालना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कपटपूर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व संध्या<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ESTH1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ESS2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यहां तक ​​कि मौका<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ग़ैरतरफ़दार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शाम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शाम की पोशाक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शाम का बसंती गुलाब<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शाम का सितारा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>यहाँ तक कि पैसे<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इवेंस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इवेंसोंग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आयोजन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घटनेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संध्या<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ईवेंटीग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंतिम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संभावना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चुकना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कभी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सदाबहार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चिरस्थायी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हमेशा के लिये<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हर एक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हर कोई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रोज रोज<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हर आदमी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हर कोई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हर कोई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हर चीज़<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हर जगह<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बेदख़ल करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रमाण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रत्यक्ष<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रत्यक्ष<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़रूर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बुराई<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कुकर्मी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शैतान की आँख<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जताना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंतड़ी निकालना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विचारोत्तेजक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>आह्वान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विकास<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विकासवादी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विकसित होना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एवै<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सुराही<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Ex1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Ex2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व 1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्स -2<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ख़राब करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सटीक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मांग करनेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>तक़ाज़ा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शुद्धता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिल्कुल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बिलकुल विज्ञान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अतिरंजना करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रशंसा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>परीक्षा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इंतिहान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>की जांच<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उदाहरण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चिढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व कैथेड्रा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खोदना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सीमा पार करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निहायत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्सेल<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्कृष्टता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मान्यवर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्कृष्ट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्सेंट्रिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>के अलावा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>के सिवा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अपवाद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एतराज़ करनेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>असाधारण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अंश<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अतिरिक्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अतिरिक्त सामान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अत्यधिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अदला बदली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विनिमय दर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कोष<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Excise1<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्पाद शुल्क<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्तेजनीय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्साइट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्तेजना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रोमांचक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>चिल्लाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्मयादिबोधक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्मयादिबोधक चिह्न<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्मयादिबोधक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निकालना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विशिष्ट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समाज से बहिष्कृत करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रगड़ डालना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मलमूत्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>रसौली<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मलमूत्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उगलना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कष्टदायी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सफ़ाई देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>सैर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हटनेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व-निर्देशक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घिनौना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नफ़रत करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निष्पादित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यान्वयन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जल्लाद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>कार्यकारिणी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निर्वाहक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>टीका<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नमूना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उदाहरणात्मक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उदाहरण देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुक्त करें<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यायाम<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खींचना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खींचना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उकसाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहिष्कृत करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनुग्रहपूर्वक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>साँस छोड़ना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>थका देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>थकावट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संपूर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दिखाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रदर्शनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>वृत्ति पानेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>नुमाइशबाजी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ख़ुश करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समझाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पृथ्वी खोद कर निकालना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़रूरत<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अल्प<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निर्वासन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अस्तित्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अस्तित्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अस्तित्व<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एग्ज़िस्टंत्सियनलिज़म<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बाहर निकलना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निर्गम मतानुमान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>Exo-<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बहि<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्सोदेस<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पद के अनुसार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हक़ बहाल करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अत्यधिक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>जादू देना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुक़दमा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विदेशी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्सोटिका<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रसार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ताननेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्तार<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>क़ब्ज़ा करने की नीति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रशस्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रवासी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>लंबी-चौड़ी बातें बढ़ाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रवासी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अपेक्षा करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उम्मीद<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इंतिज़ार करनेवाला<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अपेक्षा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>expectorant<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>गला साफ़ करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपाय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शीघ्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अभियान<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अभियान का<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शीघ्र<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निष्कासित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उपभोजित<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्यय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खर्च का हिसाब<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>महंगा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनुभव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनुभव<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अनुभवात्मक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रयोग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रयोगात्मक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विशेषज्ञ<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विशेषज्ञता<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निवृत्त करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समय सीमा समाप्त<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समाप्ति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समझाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्याख्या<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्याख्यात्मक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>दुर्वचन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समझाने के योग्य<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>बयान करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>मुखर<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्फोट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>शोषण करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अन्वेषण करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्फोट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>विस्फोटक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रदर्शनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रतिपादक<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>घातीय<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निर्यात करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खुलासा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>खुलासा<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>प्रदर्शनी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्वव्यापी<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समझाना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>संसर्ग<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>व्याख्या करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अभिव्यक्त करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अभिव्यक्ति<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>इक्सप्रेस्सियुनिज़म<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>अर्थपूर्ण<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्सप्रेसो<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>एक्सप्रेसवे<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>ज़ब्त करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>निष्कासन<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>हटाए<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>समाप्ति करना<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>उत्कृष्ट<p>",
    },
    {
        language: "hi",
        title: "Hindi",
        meaning: "<p>पूर्व-सेवा<p>",
    },
];