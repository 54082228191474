export const alphabet_J = [
  {
    word: "J",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>J </p>",
      },
    ],
  },
  {
    word: "J1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>J1 <strong class='noun'> n. </strong> (also j) (pl. Js or j's) tenth letter of the alphabet.</p>",
      },
    ],
  },
  {
    word: "J2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>J2 <strong class='sing'> abbr. </strong> (also j.) Joule(s).</p>",
      },
    ],
  },
  {
    word: "Jab",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jab <strong class='noun'> —v. </strong> (-bb-) 1 a poke roughly. B stab. 2 (foll. By into) thrust (a thing) hard or abruptly. <strong class='noun'> —n. </strong> 1 abrupt blow, thrust, or stab. 2 <strong class='colloq'> colloq. </strong> Hypodermic injection. [var. Of job = prod]</p>",
      },
    ],
  },
  {
    word: "Jabber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jabber <strong class='noun'> —v. </strong> 1 chatter volubly. 2 utter (words) in this way. <strong class='noun'> —n. </strong> Chatter; gabble. [imitative]</p>",
      },
    ],
  },
  {
    word: "Jabot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jabot <strong class='noun'> n. </strong> Ornamental frill etc. On the front of a shirt or blouse. [french]</p>",
      },
    ],
  },
  {
    word: "Jacaranda",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jacaranda <strong class='noun'> n. </strong> Tropical american tree with trumpet-shaped blue flowers or hard scented wood. [tupi]</p>",
      },
    ],
  },
  {
    word: "Jacinth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jacinth <strong class='noun'> n. </strong> Reddish-orange zircon used as a gem. [latin: related to *hyacinth]</p>",
      },
    ],
  },
  {
    word: "Jack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jack <strong class='noun'> —n. </strong> 1 device for raising heavy objects, esp. Vehicles. 2 court-card with a picture of a soldier, page, etc. 3 ship's flag, esp. Showing nationality. 4 device using a single-pronged plug to connect an electrical circuit. 5 small white target ball in bowls. 6 a = *jackstone. B (in pl.) Game of jackstones. 7 (jack) familiar form of john, esp. Typifying the common man, male animal, etc. (i'm all right, jack). —v. (usu. Foll. By up) 1 raise with or as with a jack (in sense 1). 2 <strong class='colloq'> colloq. </strong> Raise (e.g. Prices). every man jack every person. Jack in slang abandon (an attempt etc.). [familiar form of the name john]</p>",
      },
    ],
  },
  {
    word: "Jackal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jackal <strong class='noun'> n. </strong> 1 african or asian wild animal of the dog family, scavenging in packs for food. 2 <strong class='colloq'> colloq. </strong> Menial. [persian]</p>",
      },
    ],
  },
  {
    word: "Jackanapes",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jackanapes <strong class='noun'> n. </strong> Archaic rascal. [earlier jack napes, supposed to refer to the duke of suffolk]</p>",
      },
    ],
  },
  {
    word: "Jackass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jackass <strong class='noun'> n. </strong> 1 male ass. 2 stupid person.</p>",
      },
    ],
  },
  {
    word: "Jackboot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jackboot <strong class='noun'> n. </strong> 1 military boot reaching above the knee. 2 this as a militaristic or fascist symbol.</p>",
      },
    ],
  },
  {
    word: "Jackdaw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jackdaw <strong class='noun'> n. </strong> Grey-headed bird of the crow family.</p>",
      },
    ],
  },
  {
    word: "Jacket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jacket <strong class='noun'> n. </strong> 1 a short coat with sleeves. B protective or supporting garment (life-jacket). 2 casing or covering round a boiler etc. 3 = *dust-jacket. 4 skin of a potato. 5 animal's coat. [french]</p>",
      },
    ],
  },
  {
    word: "Jacket potato",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jacket potato <strong class='noun'> n. </strong> Potato baked in its skin.</p>",
      },
    ],
  },
  {
    word: "Jack frost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jack frost <strong class='noun'> n. </strong> Frost personified.</p>",
      },
    ],
  },
  {
    word: "Jack-in-the-box",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jack-in-the-box <strong class='noun'> n. </strong> Toy figure that springs out of a box.</p>",
      },
    ],
  },
  {
    word: "Jackknife",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jackknife <strong class='noun'> —n. </strong> 1 large clasp-knife. 2 dive in which the body is bent and then straightened. —v. (-fing) (of an articulated vehicle) fold against itself in an accident.</p>",
      },
    ],
  },
  {
    word: "Jack of all trades",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jack of all trades <strong class='noun'> n. </strong> Multi-skilled person.</p>",
      },
    ],
  },
  {
    word: "Jack-o'-lantern",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jack-o'-lantern <strong class='noun'> n. </strong> 1 will-o'-the wisp. 2 pumpkin lantern.</p>",
      },
    ],
  },
  {
    word: "Jack plane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jack plane <strong class='noun'> n. </strong> Medium-sized joinery plane.</p>",
      },
    ],
  },
  {
    word: "Jack plug",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jack plug <strong class='noun'> n. </strong> Plug for use with a jack (see *jack <strong class='noun'> n. </strong> 4).</p>",
      },
    ],
  },
  {
    word: "Jackpot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jackpot <strong class='noun'> n. </strong> Large prize, esp. Accumulated in a game, lottery, etc. hit the jackpot <strong class='colloq'> colloq. </strong> 1 win a large prize. 2 have remarkable luck or success.</p>",
      },
    ],
  },
  {
    word: "Jackrabbit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jackrabbit <strong class='noun'> n. </strong> Us large prairie hare.</p>",
      },
    ],
  },
  {
    word: "Jack russell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jack russell <strong class='noun'> n. </strong> Short-legged breed of terrier.</p>",
      },
    ],
  },
  {
    word: "Jackstone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jackstone <strong class='noun'> n. </strong> 1 metal etc. Piece used in tossing-games. 2 (in pl.) Game with a ball and jackstones.</p>",
      },
    ],
  },
  {
    word: "Jack tar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Jack tar <strong class='noun'> n. </strong> Sailor.</p>",
      },
    ],
  },
  {
    word: "Jacobean",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jacobean <strong class='adj'> —adj.</strong> 1 of the reign of james i. 2 (of furniture) heavy and dark in style. <strong class='noun'> —n. </strong> Jacobean person. [latin jacobus james]</p>",
      },
    ],
  },
  {
    word: "Jacobite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jacobite <strong class='noun'> n. </strong> Hist. Supporter of james ii after his flight, or of the stuarts.</p>",
      },
    ],
  },
  {
    word: "Jacquard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jacquard <strong class='noun'> n. </strong> 1 apparatus with perforated cards, for weaving figured fabrics. 2 (in full jacquard loom) loom with this. 3 fabric or article so made. [name of its inventor]</p>",
      },
    ],
  },
  {
    word: "Jacuzzi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jacuzzi <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> propr. Large bath with massaging underwater jets of water. [name of its inventor and manufacturers]</p>",
      },
    ],
  },
  {
    word: "Jade1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jade1 <strong class='noun'> n. </strong> 1 hard usu. Green stone used for ornaments etc. 2 green colour of jade. [spanish ijada from latin ilia flanks (named as a cure for colic)]</p>",
      },
    ],
  },
  {
    word: "Jade2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jade2 <strong class='noun'> n. </strong> 1 inferior or worn-out horse. 2 derog. Disreputable woman. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jaded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jaded <strong class='adj'> adj. </strong> Tired out; surfeited.</p>",
      },
    ],
  },
  {
    word: "J'adoube",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>J'adoube <strong class='n_pl'> int. </strong> Chess declaration of the intention to adjust a piece without moving it. [french, = i adjust]</p>",
      },
    ],
  },
  {
    word: "Jag1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jag1 <strong class='noun'> —n. </strong> Sharp projection of rock etc. —v. <strong class='gg'> (-gg-) </strong> 1 cut or tear unevenly. 2 make indentations in. [imitative]</p>",
      },
    ],
  },
  {
    word: "Jag2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jag2 <strong class='noun'> n. </strong> Slang 1 drinking bout. 2 period of indulgence in an activity, emotion, etc. [originally dial., = load]</p>",
      },
    ],
  },
  {
    word: "Jagged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jagged <strong class='adj'> adj. </strong> 1 unevenly cut or torn. 2 deeply indented. jaggedly <strong class='adv'> adv. </strong> Jaggedness n.</p>",
      },
    ],
  },
  {
    word: "Jaguar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jaguar <strong class='noun'> n. </strong> Large american flesh-eating spotted animal of the cat family. [tupi]</p>",
      },
    ],
  },
  {
    word: "Jail  (also gaol)",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jail (also gaol) <strong class='noun'> —n. </strong> 1 place for the detention of prisoners. 2 confinement in a jail. —v. Put in jail. [french jaiole, ultimately from latin cavea cage]</p>",
      },
    ],
  },
  {
    word: "Jailbird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jailbird <strong class='noun'> n. </strong> (also gaolbird) prisoner or habitual criminal.</p>",
      },
    ],
  },
  {
    word: "Jailbreak",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jailbreak <strong class='noun'> n. </strong> (also gaolbreak) escape from jail.</p>",
      },
    ],
  },
  {
    word: "Jailer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jailer <strong class='noun'> n. </strong> (also gaoler) person in charge of a jail or prisoners.</p>",
      },
    ],
  },
  {
    word: "Jain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jain <strong class='noun'> —n. </strong> Adherent of an indian religion resembling buddhism. <strong class='adj'> —adj.</strong> Of this religion. jainism <strong class='noun'> n. </strong> Jainist <strong class='noun'> n. </strong> & adj. [hindi]</p>",
      },
    ],
  },
  {
    word: "Jalap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jalap <strong class='noun'> n. </strong> Purgative drug from the tuberous roots of a mexican climbing plant. [spanish xalapa, name of a mexican city, from aztec]</p>",
      },
    ],
  },
  {
    word: "Jalopy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jalopy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> <strong class='colloq'> colloq. </strong> Dilapidated old vehicle. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jalousie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jalousie <strong class='noun'> n. </strong> Slatted blind or shutter to keep out rain etc. And control light. [french: related to *jealousy]</p>",
      },
    ],
  },
  {
    word: "Jam1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jam1 <strong class='noun'> —v. </strong> (-mm-) 1 a (usu. Foll. By into, together, etc.) Squeeze, cram, or wedge into a space. B become wedged. 2 cause (machinery etc.) To become wedged or (of machinery etc.) Become wedged and unworkable. 3 a block (a passage, road, etc.) By crowding etc. B (foll. By in) obstruct the exit of (was jammed in). 4 (usu. Foll. By on) apply (brakes etc.) Forcefully or abruptly. 5 make (a radio transmission) unintelligible by interference. 6 <strong class='colloq'> colloq. </strong> (in jazz etc.) Improvise with other musicians. <strong class='noun'> —n. </strong> 1 squeeze, crush. 2 crowded mass (traffic jam). 3 <strong class='colloq'> colloq. </strong> Predicament. 4 stoppage (of a machine etc.) Due to jamming. 5 (in full jam session) <strong class='colloq'> colloq. </strong> (in jazz etc.) Improvised ensemble playing. [imitative]</p>",
      },
    ],
  },
  {
    word: "Jam2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jam2 <strong class='noun'> n. </strong> 1 conserve of boiled fruit and sugar. 2 <strong class='colloq'> colloq. </strong> Easy or pleasant thing (money for jam). jam tomorrow promise of future treats etc. That never materialize. [perhaps from *jam1]</p>",
      },
    ],
  },
  {
    word: "Jamb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jamb <strong class='noun'> n. </strong> Side post or side face of a doorway, window, or fireplace. [french jambe leg, from latin]</p>",
      },
    ],
  },
  {
    word: "Jamboree",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jamboree <strong class='noun'> n. </strong> 1 celebration. 2 large rally of scouts. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jamjar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jamjar <strong class='noun'> n. </strong> Glass jar for jam.</p>",
      },
    ],
  },
  {
    word: "Jammy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jammy <strong class='adj'> adj. </strong> (-ier, -iest) 1 covered with jam. 2 <strong class='colloq'> colloq. </strong> A lucky. B profitable.</p>",
      },
    ],
  },
  {
    word: "Jam-packed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jam-packed <strong class='adj'> adj. </strong> Colloq. Full to capacity.</p>",
      },
    ],
  },
  {
    word: "Jan.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Jan. <strong class='abbr'> Abbr. </strong> January.</p>",
      },
    ],
  },
  {
    word: "Jangle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jangle <strong class='noun'> —v. </strong> (-ling) 1 (cause to) make a (esp. Harsh) metallic sound. 2 irritate (the nerves etc.) By discord etc. <strong class='noun'> —n. </strong> Harsh metallic sound. [french]</p>",
      },
    ],
  },
  {
    word: "Janitor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Janitor <strong class='noun'> n. </strong> 1 doorkeeper. 2 caretaker. [latin janua door]</p>",
      },
    ],
  },
  {
    word: "January",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>January <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> first month of the year. [latin janus, guardian god of doors]</p>",
      },
    ],
  },
  {
    word: "Jap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jap <strong class='noun'> n. </strong> & adj. Colloq. Often offens. = *japanese. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Japan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Japan <strong class='noun'> —n. </strong> Hard usu. Black varnish, <strong class='archaic_it_was'> orig. </strong> From japan. —v. (-nn-) 1 varnish with japan. 2 make black and glossy. [japan in e. Asia]</p>",
      },
    ],
  },
  {
    word: "Japanese",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Japanese <strong class='noun'> —n. </strong> <strong class='pl_same'> (pl. Same) </strong> 1 a native or national of japan. B person of japanese descent. 2 language of japan. <strong class='adj'> —adj.</strong> Of japan, its people, or its language.</p>",
      },
    ],
  },
  {
    word: "Jape",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jape <strong class='noun'> —n. </strong> Practical joke. —v. (-ping) play a joke. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Japonica",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Japonica <strong class='noun'> n. </strong> Flowering shrub with bright red flowers and round edible fruits. [latinized name for japanese]</p>",
      },
    ],
  },
  {
    word: "Jar1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jar1 <strong class='noun'> n. </strong> 1 a container, usu. Of glass and cylindrical. B contents of this. 2 <strong class='colloq'> colloq. </strong> Glass of beer. [french from arabic]</p>",
      },
    ],
  },
  {
    word: "Jar2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jar2 <strong class='noun'> —v. </strong> <strong class='adj'> (-rr-) </strong> 1 (often foll. By on) (of sound, manner, etc.) Sound discordant, grate (on the nerves etc.). 2 a (often foll. By against, on) (cause to) strike (esp. Part of the body) with vibration or shock (jarred his neck). B vibrate with shock etc. 3 (often foll. By with) be at variance or in conflict. <strong class='noun'> —n. </strong> 1 jarring sound or sensation. 2 physical shock or jolt. [imitative]</p>",
      },
    ],
  },
  {
    word: "Jar3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jar3 <strong class='noun'> n. </strong> on the jar ajar. [obsolete char turn: see *ajar, *char2]</p>",
      },
    ],
  },
  {
    word: "Jardinière",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jardinière <strong class='noun'> n. </strong> 1 ornamental pot or stand for plants. 2 dish of mixed vegetables. [french]</p>",
      },
    ],
  },
  {
    word: "Jargon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jargon <strong class='noun'> n. </strong> 1 words or expressions used by a particular group or profession (medical jargon). 2 debased or pretentious language. [french]</p>",
      },
    ],
  },
  {
    word: "Jasmine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jasmine <strong class='noun'> n. </strong> Ornamental shrub with white or yellow flowers. [french from arabic from persian]</p>",
      },
    ],
  },
  {
    word: "Jasper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jasper <strong class='noun'> n. </strong> Opaque quartz, usu. Red, yellow, or brown. [french from latin from greek iaspis]</p>",
      },
    ],
  },
  {
    word: "Jaundice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jaundice <strong class='noun'> —n. </strong> 1 yellowing of the skin etc. Caused by liver disease, bile disorder, etc. 2 disordered (esp. Mental) vision. 3 envy. —v. (-cing) 1 affect with jaundice. 2 (esp. As jaundiced adj.) Affect (a person) with envy, resentment, etc. [french jaune yellow]</p>",
      },
    ],
  },
  {
    word: "Jaunt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jaunt <strong class='noun'> —n. </strong> Short pleasure trip. —v. Take a jaunt. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jaunting car",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jaunting car <strong class='noun'> n. </strong> Light horse-drawn vehicle formerly used in ireland.</p>",
      },
    ],
  },
  {
    word: "Jaunty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jaunty <strong class='adj'> adj. </strong> (-ier, -iest) 1 cheerful and self-confident. 2 sprightly. jauntily <strong class='adv'> adv. </strong> Jauntiness <strong class='noun'> n. </strong> [french: related to *gentle]</p>",
      },
    ],
  },
  {
    word: "Javanese",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Javanese <strong class='noun'> —n. </strong> <strong class='pl_same'> (pl. Same) </strong> 1 a native of java. B person of javanese descent. 2 language of java. <strong class='adj'> —adj.</strong> (also javan) of java, its people, or its language. [java in indonesia]</p>",
      },
    ],
  },
  {
    word: "Javelin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Javelin <strong class='noun'> n. </strong> Light spear thrown in sport or, formerly, as a weapon. [french]</p>",
      },
    ],
  },
  {
    word: "Jaw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jaw <strong class='noun'> —n. </strong> 1 a upper or lower bony structure in vertebrates containing the teeth. B corresponding parts of certain invertebrates. 2 a (in pl.) The mouth with its bones and teeth. B narrow mouth of a valley, channel, etc. C gripping parts of a tool etc. D grip (jaws of death). 3 <strong class='colloq'> colloq. </strong> Tedious talk (hold your jaw). —v. Colloq. Speak, esp. At tedious length. [french]</p>",
      },
    ],
  },
  {
    word: "Jawbone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jawbone <strong class='noun'> n. </strong> Lower jaw in most mammals.</p>",
      },
    ],
  },
  {
    word: "Jaw-breaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jaw-breaker <strong class='noun'> n. </strong> Colloq. Long or hard word.</p>",
      },
    ],
  },
  {
    word: "Jay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jay <strong class='noun'> n. </strong> Noisy european bird of the crow family with vivid plumage. [latin gaius, gaia, perhaps from the name gaius: cf. Jackdaw, robin]</p>",
      },
    ],
  },
  {
    word: "Jaywalk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jaywalk <strong class='verb'> v. </strong> Cross a road carelessly or dangerously. jaywalker n.</p>",
      },
    ],
  },
  {
    word: "Jazz",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jazz <strong class='noun'> —n. </strong> 1 rhythmic syncopated esp. Improvised music of black us origin. 2 slang pretentious talk or behaviour (all that jazz). —v. Play or dance to jazz. jazz up brighten or enliven. jazzer <strong class='noun'> n. </strong> [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Jazzman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jazzman <strong class='noun'> n. </strong> Jazz-player.</p>",
      },
    ],
  },
  {
    word: "Jazzy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jazzy <strong class='adj'> adj. </strong> (-ier, -iest) 1 of or like jazz. 2 vivid, showy.</p>",
      },
    ],
  },
  {
    word: "Jcb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jcb <strong class='noun'> n. </strong> Propr. Mechanical excavator with a shovel and a digging arm. [j. C. Bamford, name of the makers]</p>",
      },
    ],
  },
  {
    word: "Jcr",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jcr <strong class='sing'> abbr. </strong> Junior common (or combination) room.</p>",
      },
    ],
  },
  {
    word: "Jealous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jealous <strong class='adj'> adj. </strong> 1 resentful of rivalry in love. 2 (often foll. By of) envious (of a person etc.). 3 (often foll. By of) fiercely protective (of rights etc.). 4 (of god) intolerant of disloyalty. 5 (of inquiry, supervision, etc.) Vigilant. jealously <strong class='adv'> adv. </strong> [medieval latin zelosus: related to *zeal]</p>",
      },
    ],
  },
  {
    word: "Jealousy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jealousy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 jealous state or feeling. 2 instance of this. [french: related to *jealous]</p>",
      },
    ],
  },
  {
    word: "Jeans",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeans <strong class='n_pl'> n.pl. </strong> Casual esp. Denim trousers. [earlier geane fustian, = material from genoa]</p>",
      },
    ],
  },
  {
    word: "Jeep",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeep <strong class='noun'> n. </strong> Propr. Small sturdy esp. Military vehicle with four-wheel drive. [originally us, from the initials of general purposes]</p>",
      },
    ],
  },
  {
    word: "Jeepers",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeepers <strong class='n_pl'> int. </strong> Us slang expressing surprise etc. [corruption of jesus]</p>",
      },
    ],
  },
  {
    word: "Jeer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeer <strong class='noun'> —v. </strong> (often foll. By at) scoff derisively; deride. <strong class='noun'> —n. </strong> Taunt. jeeringly <strong class='adv'> adv. </strong> [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jehad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Jehad <strong class='var'> var. </strong> Of *jihad.</p>",
      },
    ],
  },
  {
    word: "Jehad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Jehad <strong class='var'> var. </strong> Of *jihad.</p>",
      },
    ],
  },
  {
    word: "Jehovah",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jehovah <strong class='noun'> n. </strong> Hebrew name of god in the old testament. [hebrew yahveh]</p>",
      },
    ],
  },
  {
    word: "Jehovah's witness",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jehovah's witness <strong class='noun'> n. </strong> Member of a millenarian christian sect rejecting the supremacy of the state and religious institutions over personal conscience, faith, etc.</p>",
      },
    ],
  },
  {
    word: "Jejune",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jejune <strong class='adj'> adj. </strong> 1 intellectually unsatisfying; shallow, meagre, scanty, dry. 2 puerile. 3 (of land) barren. [latin jejunus]</p>",
      },
    ],
  },
  {
    word: "Jejunum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jejunum <strong class='noun'> n. </strong> Small intestine between the duodenum and ileum. [latin: related to *jejune]</p>",
      },
    ],
  },
  {
    word: "Jekyll and hyde",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jekyll and hyde <strong class='noun'> n. </strong> Person having opposing good and evil personalities. [names of a character in a story by r. L. Stevenson]</p>",
      },
    ],
  },
  {
    word: "Jell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jell <strong class='verb'> v. </strong> Colloq. 1 a set as jelly. B (of ideas etc.) Take a definite form. 2 cohere. [back-formation from *jelly]</p>",
      },
    ],
  },
  {
    word: "Jellaba",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jellaba <strong class='var'> var. </strong> Of *djellaba.</p>",
      },
    ],
  },
  {
    word: "Jellify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jellify <strong class='verb'> v. </strong> (-ies, -ied) turn into jelly; make or become like jelly. jellification n.</p>",
      },
    ],
  },
  {
    word: "Jelly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jelly <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 a (usu. Fruit-flavoured) translucent dessert set with gelatin. B similar preparation as a jam, condiment, or sweet (redcurrant jelly). C similar preparation from meat, bones, etc., and gelatin (marrowbone jelly). 2 any similar substance. 3 slang gelignite. —v. (-ies, -ied) (cause to) set as or in a jelly, congeal (jellied eels). jelly-like adj. [french gelée from latin gelo freeze]</p>",
      },
    ],
  },
  {
    word: "Jelly baby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jelly baby <strong class='noun'> n. </strong> Jelly-like baby-shaped sweet.</p>",
      },
    ],
  },
  {
    word: "Jellyfish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jellyfish <strong class='noun'> n. </strong> (pl. Same or -es) marine animal with a jelly-like body and stinging tentacles.</p>",
      },
    ],
  },
  {
    word: "Jemmy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jemmy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> burglar's short crowbar. [from the name james]</p>",
      },
    ],
  },
  {
    word: "Jenny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jenny <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 hist. = *spinning-jenny. 2 female donkey. [from the name janet]</p>",
      },
    ],
  },
  {
    word: "Jenny-wren",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jenny-wren <strong class='noun'> n. </strong> Female wren.</p>",
      },
    ],
  },
  {
    word: "Jeopardize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeopardize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) endanger.</p>",
      },
    ],
  },
  {
    word: "Jeopardy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeopardy <strong class='noun'> n. </strong> Danger, esp. Severe. [obsolete french iu parti divided play]</p>",
      },
    ],
  },
  {
    word: "Jerbil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerbil <strong class='var'> var. </strong> Of *gerbil.</p>",
      },
    ],
  },
  {
    word: "Jerboa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerboa <strong class='noun'> n. </strong> Small jumping desert rodent. [arabic]</p>",
      },
    ],
  },
  {
    word: "Jeremiad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeremiad <strong class='noun'> n. </strong> Doleful complaint or lamentation. [church latin: related to *jeremiah]</p>",
      },
    ],
  },
  {
    word: "Jeremiah",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeremiah <strong class='noun'> n. </strong> Dismal prophet, denouncer of the times. [lamentations of jeremiah, in the old testament]</p>",
      },
    ],
  },
  {
    word: "Jerk1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerk1 <strong class='noun'> —n. </strong> 1 sharp sudden pull, twist, twitch, start, etc. 2 spasmodic muscular twitch. 3 (in pl.) Colloq. Exercises (physical jerks). 4 slang fool. —v. Move, pull, thrust, twist, throw, etc., with a jerk. jerk off coarse slang masturbate. [imitative]</p>",
      },
    ],
  },
  {
    word: "Jerk2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerk2 <strong class='verb'> v. </strong> Cure (beef) by cutting it in long slices and drying it in the sun. [quechua echarqui dried fish in strips]</p>",
      },
    ],
  },
  {
    word: "Jerkin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerkin <strong class='noun'> n. </strong> 1 sleeveless jacket. 2 hist. Man's close-fitting, esp. Leather, jacket. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jerky",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerky <strong class='adj'> adj. </strong> (-ier, -iest) 1 moving suddenly or abruptly. 2 spasmodic. jerkily <strong class='adv'> adv. </strong> Jerkiness n.</p>",
      },
    ],
  },
  {
    word: "Jeroboam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeroboam <strong class='noun'> n. </strong> Wine bottle of 4–12 times the ordinary size. [jeroboam in the old testament]</p>",
      },
    ],
  },
  {
    word: "Jerry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> slang 1 german (esp. Soldier). 2 germans collectively. [probably an alteration of german]</p>",
      },
    ],
  },
  {
    word: "Jerry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> slang chamber-pot. [probably an abbreviation of *jeroboam]</p>",
      },
    ],
  },
  {
    word: "Jerry-builder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerry-builder <strong class='noun'> n. </strong> Incompetent builder using cheap materials. jerry-building <strong class='noun'> n. </strong> Jerry-built adj. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Jerrycan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerrycan <strong class='noun'> n. </strong> (also jerrican) a kind of (orig. German) petrol- or water-can. [from *jerry]</p>",
      },
    ],
  },
  {
    word: "Jersey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jersey <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 a knitted usu. Woollen pullover. B plain-knitted (orig. Woollen) fabric. 2 (jersey) light brown dairy cow from jersey. [jersey in the channel islands]</p>",
      },
    ],
  },
  {
    word: "Jerusalem artichoke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jerusalem artichoke <strong class='noun'> n. </strong> 1 a kind of sunflower with edible tubers. 2 this as a vegetable. [corruption of italian girasole sunflower]</p>",
      },
    ],
  },
  {
    word: "Jest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jest <strong class='noun'> —n. </strong> 1 joke; fun. 2 a raillery, banter. B object of derision. —v. Joke; fool about. in jest in fun. [latin gesta exploits]</p>",
      },
    ],
  },
  {
    word: "Jester",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jester <strong class='noun'> n. </strong> Hist. Professional clown at a medieval court etc.</p>",
      },
    ],
  },
  {
    word: "Jesuit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jesuit <strong class='noun'> n. </strong> Member of the society of jesus, a roman catholic order. [latin jesus, founder of the christian religion]</p>",
      },
    ],
  },
  {
    word: "Jesuitical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jesuitical <strong class='adj'> adj. </strong> 1 of the jesuits. 2 often offens. Equivocating, casuistic.</p>",
      },
    ],
  },
  {
    word: "Jesus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jesus <strong class='n_pl'> int. </strong> Colloq. Exclamation of surprise, dismay, etc. [name of the founder of the christian religion]</p>",
      },
    ],
  },
  {
    word: "Jet1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jet1 <strong class='noun'> —n. </strong> 1 stream of water, steam, gas, flame, etc., shot esp. From a small opening. 2 spout or nozzle for this purpose. 3 jet engine or jet plane. —v. <strong class='adj'> (-tt-) </strong> 1 spurt out in jets. 2 <strong class='colloq'> colloq. </strong> Send or travel by jet plane. [french jeter throw from latin jacto]</p>",
      },
    ],
  },
  {
    word: "Jet2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jet2 <strong class='noun'> n. </strong> (often attrib.) Hard black lignite often carved and highly polished. [french jaiet from gagai in asia minor]</p>",
      },
    ],
  },
  {
    word: "Jet black",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jet black <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> (as adj. Often hyphenated) deep glossy black.</p>",
      },
    ],
  },
  {
    word: "Jet engine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jet engine <strong class='noun'> n. </strong> Engine using jet propulsion, esp. Of an aircraft.</p>",
      },
    ],
  },
  {
    word: "Jet lag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jet lag <strong class='noun'> n. </strong> Exhaustion etc. Felt after a long flight across time zones.</p>",
      },
    ],
  },
  {
    word: "Jet plane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jet plane <strong class='noun'> n. </strong> Plane with a jet engine.</p>",
      },
    ],
  },
  {
    word: "Jet-propelled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jet-propelled <strong class='adj'> adj. </strong> 1 having jet propulsion. 2 very fast.</p>",
      },
    ],
  },
  {
    word: "Jet propulsion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jet propulsion <strong class='noun'> n. </strong> Propulsion by the backward ejection of a high-speed jet of gas etc.</p>",
      },
    ],
  },
  {
    word: "Jetsam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jetsam <strong class='noun'> n. </strong> Objects washed ashore, esp. Jettisoned from a ship. [contraction of *jettison]</p>",
      },
    ],
  },
  {
    word: "Jet set",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jet set <strong class='noun'> n. </strong> Wealthy people who travel widely, esp. For pleasure. jet-setter <strong class='noun'> n. </strong> Jet-setting <strong class='noun'> n. </strong> & <strong class='attrib'> attrib. </strong> Adj.</p>",
      },
    ],
  },
  {
    word: "Jettison",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jettison <strong class='noun'> —v. </strong> 1 a throw (esp. Heavy material) overboard to lighten a ship etc. B drop (goods) from an aircraft. 2 abandon; get rid of. <strong class='noun'> —n. </strong> Jettisoning. [anglo-french getteson: related to *jet1]</p>",
      },
    ],
  },
  {
    word: "Jetty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jetty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 pier or breakwater to protect or defend a harbour, coast, etc. 2 landing-pier. [french jetee: related to *jet1]</p>",
      },
    ],
  },
  {
    word: "Jew",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jew <strong class='noun'> n. </strong> 1 person of hebrew descent or whose religion is judaism. 2 slang offens. Miserly person. [greek ioudaios] <strong class='usage'> Usage:- </strong>the stereotype conveyed in sense 2 is deeply offensive. It arose from historical associations of jews as moneylenders in medieval england.</p>",
      },
    ],
  },
  {
    word: "Jewel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jewel <strong class='noun'> —n. </strong> 1 a precious stone. B this used in watchmaking. 2 jewelled personal ornament. 3 precious person or thing. —v. (-ll-; us -l-) (esp. As jewelled adj.) Adorn or set with jewels. [french]</p>",
      },
    ],
  },
  {
    word: "Jeweler",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeweler <strong class='noun'> n. </strong> (brit. Jeweller) maker of or dealer in jewels or jewellery.</p>",
      },
    ],
  },
  {
    word: "Jeweller",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jeweller <strong class='noun'> n. </strong> (us jeweler) maker of or dealer in jewels or jewellery.</p>",
      },
    ],
  },
  {
    word: "Jewellery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jewellery <strong class='noun'> n. </strong> (also jewelry) rings, brooches, necklaces, etc., regarded collectively.</p>",
      },
    ],
  },
  {
    word: "Jewess",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jewess <strong class='noun'> n. </strong> Often offens. Woman or girl of hebrew descent or whose religion is judaism.</p>",
      },
    ],
  },
  {
    word: "Jewish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jewish <strong class='adj'> adj. </strong> 1 of jews. 2 of judaism. jewishness n.</p>",
      },
    ],
  },
  {
    word: "Jewry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jewry <strong class='noun'> n. </strong> Jews collectively.</p>",
      },
    ],
  },
  {
    word: "Jew's harp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jew's harp <strong class='noun'> n. </strong> Small musical instrument held between the teeth.</p>",
      },
    ],
  },
  {
    word: "Jezebel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jezebel <strong class='noun'> n. </strong> Shameless or immoral woman. [jezebel in the old testament]</p>",
      },
    ],
  },
  {
    word: "Jib1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jib1 <strong class='noun'> n. </strong> 1 triangular staysail. 2 projecting arm of a crane. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jib2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jib2 <strong class='verb'> v. </strong> (-bb-) 1 (esp. Of a horse) stop and refuse to go on. 2 (foll. By at) show aversion to. jibber <strong class='noun'> n. </strong> [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jibe1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Jibe1 <strong class='var'> var. </strong> Of *gibe.</p>",
      },
    ],
  },
  {
    word: "Jibe2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jibe2 <strong class='verb'> v. </strong> (brit. Gybe) (-bing) 1 (of a fore-and-aft sail or boom) swing across. 2 cause (a sail) to do this. 3 (of a ship or its crew) change course so that this happens. [dutch]</p>",
      },
    ],
  },
  {
    word: "Jiff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jiff <strong class='noun'> n. </strong> (also jiffy, <strong class='pl'> pl. </strong> -ies) <strong class='colloq'> colloq. </strong> Short time; moment (in a jiffy). [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jiffy bag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jiffy bag <strong class='noun'> n. </strong> Propr. Padded envelope.</p>",
      },
    ],
  },
  {
    word: "Jig",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jig <strong class='noun'> —n. </strong> 1 a lively leaping dance. B music for this. 2 device that holds a piece of work and guides the tools operating on it. —v. <strong class='gg'> (-gg-) </strong> 1 dance a jig. 2 (often foll. By about) move quickly and jerkily up and down; fidget. 3 work on or equip with a jig or jigs. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jigger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jigger <strong class='noun'> n. </strong> 1 billiards <strong class='colloq'> colloq. </strong> Cue-rest. 2 a measure of spirits etc. B small glass holding this. [partly from *jig]</p>",
      },
    ],
  },
  {
    word: "Jiggered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jiggered <strong class='adj'> adj. </strong> Colloq. (as a mild oath) confounded (i'll be jiggered). [euphemism]</p>",
      },
    ],
  },
  {
    word: "Jiggery-pokery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jiggery-pokery <strong class='noun'> n. </strong> Colloq. Trickery; swindling. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Jiggle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jiggle <strong class='noun'> —v. </strong> (-ling) (often foll. By about etc.) Shake or jerk lightly; fidget. <strong class='noun'> —n. </strong> Light shake. [from *jig]</p>",
      },
    ],
  },
  {
    word: "Jigsaw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jigsaw <strong class='noun'> n. </strong> 1 a (in full jigsaw puzzle) picture on board or wood etc. Cut into irregular interlocking pieces to be reassembled as a pastime. B problem consisting of various pieces of information. 2 mechanical fretsaw with a fine blade.</p>",
      },
    ],
  },
  {
    word: "Jihad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jihad <strong class='noun'> n. </strong> (also jehad) muslim holy war against unbelievers. [arabic jihad]</p>",
      },
    ],
  },
  {
    word: "Jilt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jilt <strong class='verb'> v. </strong> Abruptly reject or abandon (esp. A lover). [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jim crow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jim crow <strong class='noun'> n. </strong> Us <strong class='colloq'> colloq. </strong> 1 segregation of blacks. 2 offens. A black. [nickname]</p>",
      },
    ],
  },
  {
    word: "Jim-jams",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jim-jams <strong class='n_pl'> n.pl. </strong> 1 slang = *delirium tremens. 2 <strong class='colloq'> colloq. </strong> Nervousness; depression. [fanciful reduplication]</p>",
      },
    ],
  },
  {
    word: "Jingle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jingle <strong class='noun'> —n. </strong> 1 mixed ringing or clinking noise. 2 a repetition of sounds in a phrase etc. B short catchy verse or song in advertising etc. —v. (-ling) 1 (cause to) make a jingling sound. 2 (of writing) be full of alliteration, rhymes, etc. [imitative]</p>",
      },
    ],
  },
  {
    word: "Jingo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jingo <strong class='noun'> n. </strong> (pl. -es) supporter of war; blustering patriot. by jingo! Mild oath. jingoism <strong class='noun'> n. </strong> Jingoist <strong class='noun'> n. </strong> Jingoistic adj. [conjuror's word]</p>",
      },
    ],
  },
  {
    word: "Jink",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jink <strong class='noun'> —v. </strong> 1 move elusively; dodge. 2 elude by dodging. <strong class='noun'> —n. </strong> Dodging or eluding. [originally scots: imitative]</p>",
      },
    ],
  },
  {
    word: "Jinnee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jinnee <strong class='noun'> n. </strong> (also jinn, djinn) (pl. Jinn or djinn) (in muslim mythology) spirit in human or animal form having power over people. [arabic]</p>",
      },
    ],
  },
  {
    word: "Jinx",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jinx <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Person or thing that seems to cause bad luck. —v. (esp. As jinxed adj.) Subject to bad luck. [perhaps <strong class='var'> var. </strong> Of jynx wryneck, charm]</p>",
      },
    ],
  },
  {
    word: "Jitter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jitter <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> (the jitters) extreme nervousness. —v. Be nervous; act nervously. jittery adj. Jitteriness <strong class='noun'> n. </strong> [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jitterbug",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jitterbug <strong class='noun'> —n. </strong> 1 nervous person. 2 hist. Fast popular dance. —v. <strong class='gg'> (-gg-) </strong> hist. Dance the jitterbug.</p>",
      },
    ],
  },
  {
    word: "Jiu-jitsu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jiu-jitsu <strong class='var'> var. </strong> Of *ju-jitsu.</p>",
      },
    ],
  },
  {
    word: "Jive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jive <strong class='noun'> —n. </strong> 1 lively dance popular esp. In the 1950s. 2 music for this. —v. <strong class='ving'> (-ving) </strong> dance to or play jive music. jiver <strong class='noun'> n. </strong> [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Jnr.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Jnr. <strong class='abbr'> Abbr. </strong> Junior.</p>",
      },
    ],
  },
  {
    word: "Job",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Job <strong class='noun'> —n. </strong> 1 piece of work to be done; task. 2 position in, or piece of, paid employment. 3 <strong class='colloq'> colloq. </strong> Difficult task (had a job to find it). 4 slang crime, esp. A robbery. 5 state of affairs etc. (bad job). —v. (-bb-) 1 do jobs; do piece-work. 2 deal in stocks; buy and sell (stocks or goods). 3 deal corruptly with (a matter). just the job <strong class='colloq'> colloq. </strong> Exactly what is wanted. Make a job (or good job) of do well. On the job <strong class='colloq'> colloq. </strong> 1 at work. 2 engaged in sexual intercourse. Out of a job unemployed. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jobber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jobber <strong class='noun'> n. </strong> 1 person who jobs. 2 hist. Principal or wholesaler on the stock exchange. <strong class='usage'> Usage:- </strong>up to oct. 1986 jobbers were permitted to deal only with brokers, not directly with the public. From oct. 1986 the name ceased to be in official use (see *broker 2).</p>",
      },
    ],
  },
  {
    word: "Jobbery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jobbery <strong class='noun'> n. </strong> Corrupt dealing.</p>",
      },
    ],
  },
  {
    word: "Jobbing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jobbing <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Freelance; pieceworking (jobbing gardener).</p>",
      },
    ],
  },
  {
    word: "Jobcentre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jobcentre <strong class='noun'> n. </strong> Local government office advertising available jobs.</p>",
      },
    ],
  },
  {
    word: "Job-hunt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Job-hunt <strong class='verb'> v. </strong> Colloq. Seek employment.</p>",
      },
    ],
  },
  {
    word: "Jobless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jobless <strong class='adj'> adj. </strong> Unemployed. joblessness n.</p>",
      },
    ],
  },
  {
    word: "Job lot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Job lot <strong class='noun'> n. </strong> Mixed lot bought at auction etc.</p>",
      },
    ],
  },
  {
    word: "Job's comforter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Job's comforter <strong class='noun'> n. </strong> Person who intends to comfort but increases distress. [job in the old testament]</p>",
      },
    ],
  },
  {
    word: "Jobs for the boys",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jobs for the boys <strong class='n_pl'> n.pl. </strong> Colloq. Appointments for members of one's own group etc.</p>",
      },
    ],
  },
  {
    word: "Job-sharing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Job-sharing <strong class='noun'> n. </strong> Sharing of a full-time job by two or more people. job-share <strong class='noun'> n. </strong> & v.</p>",
      },
    ],
  },
  {
    word: "Jobsheet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jobsheet <strong class='noun'> n. </strong> Sheet for recording details of jobs done.</p>",
      },
    ],
  },
  {
    word: "Jock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jock <strong class='noun'> n. </strong> Slang scotsman. [scots form of the name jack]</p>",
      },
    ],
  },
  {
    word: "Jockey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jockey <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> rider in horse-races, esp. Professional. —v. (-eys, -eyed) 1 trick, cheat, or outwit. 2 (foll. By away, out, into, etc.) Manoeuvre (a person). jockey for position manoeuvre for advantage. [diminutive of *jock]</p>",
      },
    ],
  },
  {
    word: "Jockstrap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jockstrap <strong class='noun'> n. </strong> Support or protection for the male genitals, worn esp. In sport. [slang jock genitals]</p>",
      },
    ],
  },
  {
    word: "Jocose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jocose <strong class='adj'> adj. </strong> Playful; jocular. jocosely <strong class='adv'> adv. </strong> Jocosity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. [latin jocus jest]</p>",
      },
    ],
  },
  {
    word: "Jocular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jocular <strong class='adj'> adj. </strong> 1 fond of joking. 2 humorous. jocularity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Jocularly adv.</p>",
      },
    ],
  },
  {
    word: "Jocund",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jocund <strong class='adj'> adj. </strong> Literary merry, cheerful. jocundity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Jocundly <strong class='adv'> adv. </strong> [french from latin jucundus pleasant]</p>",
      },
    ],
  },
  {
    word: "Jodhpurs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jodhpurs <strong class='n_pl'> n.pl. </strong> Riding breeches tight below the knee. [jodhpur in india]</p>",
      },
    ],
  },
  {
    word: "Joe bloggs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joe bloggs <strong class='noun'> n. </strong> Colloq. Hypothetical average man.</p>",
      },
    ],
  },
  {
    word: "Jog",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jog <strong class='noun'> —v. </strong> <strong class='gg'> (-gg-) </strong> 1 run slowly, esp. As exercise. 2 push or jerk, esp. Unsteadily. 3 nudge, esp. To alert. 4 stimulate (the memory). 5 (often foll. By on, along) trudge; proceed ploddingly (must jog on somehow). 6 (of a horse) trot. <strong class='noun'> —n. </strong> 1 spell of jogging; slow walk or trot. 2 push, jerk, or nudge. [probably imitative]</p>",
      },
    ],
  },
  {
    word: "Jogger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jogger <strong class='noun'> n. </strong> Person who jogs, esp. For exercise.</p>",
      },
    ],
  },
  {
    word: "Joggle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joggle <strong class='noun'> —v. </strong> (-ling) move in jerks. <strong class='noun'> —n. </strong> Slight shake.</p>",
      },
    ],
  },
  {
    word: "Jogtrot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jogtrot <strong class='noun'> n. </strong> Slow regular trot.</p>",
      },
    ],
  },
  {
    word: "John",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>John <strong class='noun'> n. </strong> Us slang lavatory. [from the name john]</p>",
      },
    ],
  },
  {
    word: "John bull",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>John bull <strong class='noun'> n. </strong> England or the typical englishman. [name of a character in an 18th-c. Satire]</p>",
      },
    ],
  },
  {
    word: "John dory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>John dory <strong class='noun'> n. </strong> (pl. Same or -ies) edible marine fish. [see *dory]</p>",
      },
    ],
  },
  {
    word: "Johnny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Johnny <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 slang condom. 2 <strong class='colloq'> colloq. </strong> Fellow; man. [diminutive of john]</p>",
      },
    ],
  },
  {
    word: "Johnny-come-lately",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Johnny-come-lately <strong class='noun'> n. </strong> Colloq. Newcomer; upstart.</p>",
      },
    ],
  },
  {
    word: "Joie de vivre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joie de vivre <strong class='noun'> n. </strong> Exuberance; high spirits. [french, = joy of living]</p>",
      },
    ],
  },
  {
    word: "Join",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Join <strong class='noun'> —v. </strong> 1 (often foll. By to, together) put together; fasten, unite (with one or several things or people). 2 connect (points) by a line etc. 3 become a member of (a club, organization, etc.). 4 a take one's place with (a person, group, etc.). B (foll. By in, for, etc.) Take part with (others) in an activity etc. (joined them in prayer). 5 (often foll. By with, to) come together; be united. 6 (of a river etc.) Be or become connected or continuous with. <strong class='noun'> —n. </strong> Point, line, or surface at which things are joined. join battle begin fighting. Join forces combine efforts. Join hands 1 clasp hands. 2 combine in an action etc. Join in (also absol.) Take part in (an activity). Join up 1 enlist for military service. 2 (often foll. By with) unite, connect. [latin jungo junct-]</p>",
      },
    ],
  },
  {
    word: "Joiner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joiner <strong class='noun'> n. </strong> 1 maker of finished wood fittings. 2 <strong class='colloq'> colloq. </strong> Person who joins an organization or who readily joins societies etc. joinery <strong class='noun'> n. </strong> (in sense 1).</p>",
      },
    ],
  },
  {
    word: "Joint",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joint <strong class='noun'> —n. </strong> 1 place at which two or more things or parts of a structure are joined; device for joining these. 2 point at which two bones fit together. 3 division of an animal carcass as meat. 4 slang restaurant, bar, etc. 5 slang marijuana cigarette. 6 <strong class='geol'> geol. </strong> Crack in rock. <strong class='adj'> —adj.</strong> 1 held, done by, or belonging to, two or more persons etc. (joint mortgage; joint action). 2 sharing with another (joint author; joint favourite). —v. 1 connect by joint(s). 2 divide at a joint or into joints. out of joint 1 (of a bone) dislocated. 2 out of order. jointly <strong class='adv'> adv. </strong> [french: related to *join]</p>",
      },
    ],
  },
  {
    word: "Joint stock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joint stock <strong class='noun'> n. </strong> Capital held jointly; common fund.</p>",
      },
    ],
  },
  {
    word: "Joint-stock company",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joint-stock company <strong class='noun'> n. </strong> Company formed on the basis of a joint stock.</p>",
      },
    ],
  },
  {
    word: "Jointure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jointure <strong class='noun'> —n. </strong> Estate settled on a wife by her husband for use after his death. —v. Provide with a jointure. [latin: related to *join]</p>",
      },
    ],
  },
  {
    word: "Joist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joist <strong class='noun'> n. </strong> Supporting beam in a floor, ceiling, etc. [french giste from latin jaceo lie]</p>",
      },
    ],
  },
  {
    word: "Jojoba",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jojoba <strong class='noun'> n. </strong> Plant with seeds yielding an oily extract used in cosmetics etc. [mexican spanish]</p>",
      },
    ],
  },
  {
    word: "Joke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joke <strong class='noun'> —n. </strong> 1 thing said or done to cause laughter; witticism. 2 ridiculous person or thing. —v. (-king) make jokes; tease (only joking). no joke <strong class='colloq'> colloq. </strong> Serious matter. jokingly <strong class='adv'> adv. </strong> Joky adj. (also jokey). Jokily <strong class='adv'> adv. </strong> Jokiness <strong class='noun'> n. </strong> [probably latin jocus jest]</p>",
      },
    ],
  },
  {
    word: "Joker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joker <strong class='noun'> n. </strong> 1 person who jokes. 2 slang person. 3 playing-card used in some games.</p>",
      },
    ],
  },
  {
    word: "Jollify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jollify <strong class='verb'> v. </strong> (-ies, -ied) make merry. jollification n.</p>",
      },
    ],
  },
  {
    word: "Jollity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jollity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> merrymaking; festivity. [french joliveté: related to *jolly1]</p>",
      },
    ],
  },
  {
    word: "Jolly1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jolly1 <strong class='adj'> —adj.</strong> (-ier, -iest) 1 cheerful; merry. 2 festive, jovial. 3 <strong class='colloq'> colloq. </strong> Pleasant, delightful. <strong class='adv'> —adv. </strong> Colloq. Very. —v. (-ies, -ied) (usu. Foll. By along) <strong class='colloq'> colloq. </strong> Coax or humour in a friendly way. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> <strong class='colloq'> colloq. </strong> Party or celebration. jollily <strong class='adv'> adv. </strong> Jolliness <strong class='noun'> n. </strong> [french jolif gay, pretty: perhaps related to *yule]</p>",
      },
    ],
  },
  {
    word: "Jolly2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jolly2 <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> (in full jolly boat) clinker-built ship's boat smaller than a cutter. [origin unknown: perhaps related to *yawl]</p>",
      },
    ],
  },
  {
    word: "Jolly roger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jolly roger <strong class='noun'> n. </strong> Pirates' black flag, usu. With skull and crossbones.</p>",
      },
    ],
  },
  {
    word: "Jolt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jolt <strong class='noun'> —v. </strong> 1 disturb or shake (esp. In a moving vehicle) with a jerk. 2 shock; perturb. 3 move along jerkily. <strong class='noun'> —n. </strong> 1 jerk. 2 surprise or shock. jolty adj. (-ier, -iest). [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Jonah",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jonah <strong class='noun'> n. </strong> Person who seems to bring bad luck. [jonah in the old testament]</p>",
      },
    ],
  },
  {
    word: "Jonquil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jonquil <strong class='noun'> n. </strong> Narcissus with small fragrant yellow or white flowers. [ultimately from latin juncus rush plant]</p>",
      },
    ],
  },
  {
    word: "Josh ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Josh  <strong class='slang_v'> slang —v. </strong> 1 tease, banter. 2 indulge in ridicule. <strong class='noun'> —n. </strong> Good-natured or teasing joke. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Joss",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joss <strong class='noun'> n. </strong> Chinese idol. [ultimately from latin deus god]</p>",
      },
    ],
  },
  {
    word: "Joss-stick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joss-stick <strong class='noun'> n. </strong> Incense-stick for burning.</p>",
      },
    ],
  },
  {
    word: "Jostle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jostle <strong class='noun'> —v. </strong> (-ling) 1 (often foll. By away, from, against, etc.) Push against; elbow, esp. Roughly or in a crowd. 2 (foll. By with) struggle roughly. <strong class='noun'> —n. </strong> Jostling. [from *joust]</p>",
      },
    ],
  },
  {
    word: "Jot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jot <strong class='noun'> —v. </strong> <strong class='adj'> (-tt-) </strong> (usu. Foll. By down) write briefly or hastily. <strong class='noun'> —n. </strong> Very small amount (not one jot). [greek *iota]</p>",
      },
    ],
  },
  {
    word: "Jotter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jotter <strong class='noun'> n. </strong> Small pad or notebook.</p>",
      },
    ],
  },
  {
    word: "Jotting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jotting <strong class='noun'> n. </strong> (usu. In pl.) Jotted note.</p>",
      },
    ],
  },
  {
    word: "Joule",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joule <strong class='noun'> n. </strong> Si unit of work or energy. [joule, name of a physicist]</p>",
      },
    ],
  },
  {
    word: "Journal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Journal <strong class='noun'> n. </strong> 1 newspaper or periodical. 2 daily record of events; diary. 3 book in which transactions and accounts are entered. 4 part of a shaft or axle that rests on bearings. [latin diurnalis *diurnal]</p>",
      },
    ],
  },
  {
    word: "Journalese",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Journalese <strong class='noun'> n. </strong> Hackneyed writing characteristic of newspapers.</p>",
      },
    ],
  },
  {
    word: "Journalism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Journalism <strong class='noun'> n. </strong> Profession of writing for or editing newspapers etc.</p>",
      },
    ],
  },
  {
    word: "Journalist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Journalist <strong class='noun'> n. </strong> Person writing for or editing newspapers etc. journalistic adj.</p>",
      },
    ],
  },
  {
    word: "Journey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Journey <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 act of going from one place to another, esp. At a long distance. 2 time taken for this (a day's journey). —v. (-s, -ed) make a journey. [french jornee day, day's work or travel, from latin diurnus daily]</p>",
      },
    ],
  },
  {
    word: "Journeyman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Journeyman <strong class='noun'> n. </strong> 1 qualified mechanic or artisan who works for another. 2 derog. Reliable but not outstanding worker.</p>",
      },
    ],
  },
  {
    word: "Joust  hist.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joust hist. <strong class='noun'> —n. </strong> Combat between two knights on horseback with lances. —v. Engage in a joust. jouster <strong class='noun'> n. </strong> [french jouste from latin juxta near]</p>",
      },
    ],
  },
  {
    word: "Jove",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jove <strong class='noun'> n. </strong> (in roman mythology) jupiter. by jove! Exclamation of surprise etc. [latin jupiter jov-]</p>",
      },
    ],
  },
  {
    word: "Jovial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jovial <strong class='adj'> adj. </strong> Merry, convivial, hearty. joviality <strong class='noun'> n. </strong> Jovially <strong class='adv'> adv. </strong> [latin jovialis: related to *jove]</p>",
      },
    ],
  },
  {
    word: "Jowl1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jowl1 <strong class='noun'> n. </strong> 1 jaw or jawbone. 2 cheek (cheek by jowl). [old english]</p>",
      },
    ],
  },
  {
    word: "Jowl2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jowl2 <strong class='noun'> n. </strong> Loose hanging skin on the throat or neck. jowly adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Joy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joy <strong class='noun'> n. </strong> 1 (often foll. By at, in) pleasure; extreme gladness. 2 thing causing joy. 3 <strong class='colloq'> colloq. </strong> Satisfaction, success (got no joy). joyful adj. Joyfully <strong class='adv'> adv. </strong> Joyfulness <strong class='noun'> n. </strong> Joyless adj. Joyous adj. Joyously <strong class='adv'> adv. </strong> [french joie from latin gaudium]</p>",
      },
    ],
  },
  {
    word: "Joyride",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joyride <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Pleasure ride in esp. A stolen car. —v. (-ding; past -rode; past part -ridden) go for a joyride. joyrider n.</p>",
      },
    ],
  },
  {
    word: "Joystick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Joystick <strong class='noun'> n. </strong> 1 <strong class='colloq'> colloq. </strong> Control column of an aircraft. 2 lever controlling movement of an image on a vdu screen etc.</p>",
      },
    ],
  },
  {
    word: "Jp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jp <strong class='sing'> abbr. </strong> Justice of the peace.</p>",
      },
    ],
  },
  {
    word: "Jr.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Jr. <strong class='abbr'> Abbr. </strong> Junior.</p>",
      },
    ],
  },
  {
    word: "Jubilant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jubilant <strong class='adj'> adj. </strong> Exultant, rejoicing. jubilance <strong class='noun'> n. </strong> Jubilantly <strong class='adv'> adv. </strong> Jubilation <strong class='noun'> n. </strong> [latin jubilo shout]</p>",
      },
    ],
  },
  {
    word: "Jubilee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jubilee <strong class='noun'> n. </strong> 1 anniversary, esp. The 25th or 50th. 2 time of rejoicing. [hebrew, ultimately, = ram's-horn trumpet]</p>",
      },
    ],
  },
  {
    word: "Judaic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judaic <strong class='adj'> adj. </strong> Of or characteristic of the jews. [greek: related to *jew]</p>",
      },
    ],
  },
  {
    word: "Judaism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judaism <strong class='noun'> n. </strong> Religion of the jews.</p>",
      },
    ],
  },
  {
    word: "Judas",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judas <strong class='noun'> n. </strong> Traitor. [judas iscariot who betrayed christ]</p>",
      },
    ],
  },
  {
    word: "Judder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judder <strong class='noun'> —v. </strong> Shake noisily or violently. <strong class='noun'> —n. </strong> Juddering. [imitative: cf. Shudder]</p>",
      },
    ],
  },
  {
    word: "Judge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judge <strong class='noun'> —n. </strong> 1 public official appointed to hear and try legal cases. 2 person appointed to decide in a contest, dispute, etc. 3 a person who decides a question. B person regarded as having judgement of a specified type (am no judge; good judge of art). —v. (-ging) 1 form an opinion or judgement (about); estimate, appraise. 2 act as a judge (of). 3 a try (a case) at law. B pronounce sentence on. 4 (often foll. By to + infin. Or that + clause) conclude, consider. [latin judex judic-]</p>",
      },
    ],
  },
  {
    word: "Judgement",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judgement <strong class='noun'> n. </strong> (also judgment) 1 critical faculty; discernment (error of judgement). 2 good sense. 3 opinion or estimate (in my judgement). 4 sentence of a court of justice. 5 often joc. Deserved misfortune. against one's better judgement contrary to what one really feels to be advisable.</p>",
      },
    ],
  },
  {
    word: "Judgemental",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judgemental <strong class='adj'> adj. </strong> (also judgmental) 1 of or by way of judgement. 2 condemning, critical. judgementally adv.</p>",
      },
    ],
  },
  {
    word: "Judgement day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judgement day <strong class='noun'> n. </strong> (in judaism, christianity, and islam) day on which mankind will be judged by god.</p>",
      },
    ],
  },
  {
    word: "Judicature",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judicature <strong class='noun'> n. </strong> 1 administration of justice. 2 judge's position. 3 judges collectively. [medieval latin judico judge]</p>",
      },
    ],
  },
  {
    word: "Judicial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judicial <strong class='adj'> adj. </strong> 1 of, done by, or proper to a court of law. 2 having the function of judgement (judicial assembly). 3 of or proper to a judge. 4 impartial. judicially <strong class='adv'> adv. </strong> [latin judicium judgement]</p>",
      },
    ],
  },
  {
    word: "Judiciary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judiciary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> judges of a state collectively.</p>",
      },
    ],
  },
  {
    word: "Judicious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judicious <strong class='adj'> adj. </strong> Sensible, prudent. judiciously adv.</p>",
      },
    ],
  },
  {
    word: "Judo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Judo <strong class='noun'> n. </strong> Sport derived from ju-jitsu. [japanese, = gentle way]</p>",
      },
    ],
  },
  {
    word: "Jug",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jug <strong class='noun'> —n. </strong> 1 deep vessel for liquids, with a handle and a lip for pouring. 2 contents of this. 3 slang prison. —v. <strong class='gg'> (-gg-) </strong> (usu. As jugged adj.) Stew or boil (esp. Hare) in a casserole etc. jugful <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong>. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Juggernaut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juggernaut <strong class='noun'> n. </strong> 1 large heavy lorry etc. 2 overwhelming force or object. [hindi jagannath, = lord of the world]</p>",
      },
    ],
  },
  {
    word: "Juggle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juggle <strong class='noun'> —v. </strong> (-ling) 1 a (often foll. By with) keep several objects in the air at once by throwing and catching. B perform such feats with (balls etc.). 2 deal with (several activities) at once. 3 (often foll. By with) misrepresent or rearrange (facts) adroitly. <strong class='noun'> —n. </strong> 1 juggling. 2 fraud. juggler <strong class='noun'> n. </strong> [french from latin jocus jest]</p>",
      },
    ],
  },
  {
    word: "Jugoslav",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jugoslav <strong class='var'> var. </strong> Of *yugoslav.</p>",
      },
    ],
  },
  {
    word: "Jugular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jugular <strong class='adj'> —adj.</strong> Of the neck or throat. <strong class='noun'> —n. </strong> = *jugular vein. [latin jugulum collar-bone]</p>",
      },
    ],
  },
  {
    word: "Jugular vein",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jugular vein <strong class='noun'> n. </strong> Any of several large veins in the neck carrying blood from the head.</p>",
      },
    ],
  },
  {
    word: "Juice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juice <strong class='noun'> n. </strong> 1 liquid part of vegetables or fruits. 2 animal fluid, esp. A secretion (gastric juice). 3 <strong class='colloq'> colloq. </strong> Petrol; electricity. [french from latin]</p>",
      },
    ],
  },
  {
    word: "Juicy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juicy <strong class='adj'> adj. </strong> (-ier, -iest) 1 full of juice; succulent. 2 <strong class='colloq'> colloq. </strong> Interesting; racy, scandalous. 3 <strong class='colloq'> colloq. </strong> Profitable. juicily <strong class='adv'> adv. </strong> Juiciness n.</p>",
      },
    ],
  },
  {
    word: "Ju-jitsu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ju-jitsu <strong class='noun'> n. </strong> (also jiu-jitsu, ju-jutsu) japanese system of unarmed combat and physical training. [japanese jujutsu gentle skill]</p>",
      },
    ],
  },
  {
    word: "Ju-ju",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ju-ju <strong class='noun'> n. </strong> 1 charm or fetish of some w. African peoples. 2 supernatural power attributed to this. [perhaps french joujou toy]</p>",
      },
    ],
  },
  {
    word: "Jujube",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jujube <strong class='noun'> n. </strong> Small flavoured jelly-like lozenge. [greek zizuphon]</p>",
      },
    ],
  },
  {
    word: "Ju-jutsu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ju-jutsu <strong class='var'> var. </strong> Of *ju-jitsu.</p>",
      },
    ],
  },
  {
    word: "Jukebox",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jukebox <strong class='noun'> n. </strong> Coin-operated record-playing machine. [black juke disorderly]</p>",
      },
    ],
  },
  {
    word: "Jul.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Jul. <strong class='abbr'> Abbr. </strong> July.</p>",
      },
    ],
  },
  {
    word: "Julep",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Julep <strong class='noun'> n. </strong> 1 a sweet drink, esp. As a vehicle for medicine. B medicated drink as a mild stimulant etc. 2 us iced and flavoured spirits and water (mint julep). [persian gulab rose-water]</p>",
      },
    ],
  },
  {
    word: "Julian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Julian <strong class='adj'> adj. </strong> Of julius caesar. [latin julius]</p>",
      },
    ],
  },
  {
    word: "Julian calendar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Julian calendar <strong class='noun'> n. </strong> Calendar introduced by julius caesar, with a year of 365 days, every fourth year having 366.</p>",
      },
    ],
  },
  {
    word: "Julienne",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Julienne <strong class='noun'> —n. </strong> Vegetables cut into short thin strips. <strong class='adj'> —adj.</strong> Cut into thin strips. [french from name jules or julien]</p>",
      },
    ],
  },
  {
    word: "Juliet cap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juliet cap <strong class='noun'> n. </strong> Small net skullcap worn by brides etc. [juliet in shakespeare's romeo & juliet]</p>",
      },
    ],
  },
  {
    word: "July",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>July <strong class='noun'> n. </strong> (pl. Julys) seventh month of the year. [latin julius caesar]</p>",
      },
    ],
  },
  {
    word: "Jumble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jumble <strong class='noun'> —v. </strong> (-ling) (often foll. By up) confuse; mix up; muddle. <strong class='noun'> —n. </strong> 1 confused state or heap; muddle. 2 articles in a jumble sale. [probably imitative]</p>",
      },
    ],
  },
  {
    word: "Jumble sale",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jumble sale <strong class='noun'> n. </strong> Sale of second-hand articles, esp. For charity.</p>",
      },
    ],
  },
  {
    word: "Jumbo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jumbo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='colloq'> colloq. </strong> 1 (often attrib.) Large animal (esp. An elephant), person, or thing (jumbo packet). 2 (in full jumbo jet) large airliner for several hundred passengers. [probably from *mumbo-jumbo] <strong class='usage'> Usage:- </strong>in sense 2, jumbo is usu. Applied specifically to the boeing 747.</p>",
      },
    ],
  },
  {
    word: "Jump",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jump <strong class='noun'> —v. </strong> 1 rise off the ground etc. By sudden muscular effort in the legs. 2 (often foll. By up, from, in, out, etc.) Move suddenly or hastily (jumped into the car). 3 jerk or twitch from shock or excitement etc. 4 a change, esp. Advance in status or rise, rapidly (prices jumped). B cause to do this. 5 (often foll. By about) change the subject etc. Rapidly. 6 pass over (an obstacle etc.) By jumping. 7 skip (a passage in a book etc.). 8 cause (a horse etc.) To jump. 9 (foll. By to, at) reach (a conclusion) hastily. 10 (of a train) leave (the rails). 11 pass (a red traffic-light etc.). 12 get on or off (a train etc.) Quickly, esp. Illegally or dangerously. 13 attack (a person) unexpectedly. <strong class='noun'> —n. </strong> 1 act of jumping. 2 sudden jerk caused by shock or excitement. 3 abrupt rise in amount, value, status, etc. 4 obstacle to be jumped. 5 a sudden transition. B gap in a series, logical sequence, etc. jump at accept eagerly. Jump bail fail to appear for trial having been released on bail. Jump down a person's throat <strong class='colloq'> colloq. </strong> Reprimand or contradict a person fiercely. Jump the gun <strong class='colloq'> colloq. </strong> Begin prematurely. Jump on <strong class='colloq'> colloq. </strong> Attack or criticize severely. Jump out of one's skin <strong class='colloq'> colloq. </strong> Be extremely startled. Jump the queue take unfair precedence. Jump ship (of a seaman) desert. Jump to it <strong class='colloq'> colloq. </strong> Act promptly. One jump ahead one stage further on than a rival etc. [imitative]</p>",
      },
    ],
  },
  {
    word: "Jumped-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jumped-up <strong class='adj'> adj. </strong> Colloq. Upstart.</p>",
      },
    ],
  },
  {
    word: "Jumper1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jumper1 <strong class='noun'> n. </strong> 1 knitted pullover. 2 loose outer jacket worn by sailors. 3 us pinafore dress. [probably jump short coat]</p>",
      },
    ],
  },
  {
    word: "Jumper2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jumper2 <strong class='noun'> n. </strong> 1 person or animal that jumps. 2 short wire used to make or break an electrical circuit.</p>",
      },
    ],
  },
  {
    word: "Jumping bean",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jumping bean <strong class='noun'> n. </strong> Seed of a mexican plant that jumps with the movement of a larva inside.</p>",
      },
    ],
  },
  {
    word: "Jump-jet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jump-jet <strong class='noun'> n. </strong> Vertical take-off jet aircraft.</p>",
      },
    ],
  },
  {
    word: "Jump-lead",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jump-lead <strong class='noun'> n. </strong> Cable for conveying current from the battery of one vehicle to that of another.</p>",
      },
    ],
  },
  {
    word: "Jump-off",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jump-off <strong class='noun'> n. </strong> Deciding round in showjumping.</p>",
      },
    ],
  },
  {
    word: "Jump-start",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jump-start <strong class='noun'> —v. </strong> Start (a vehicle) by pushing it or with jump-leads. <strong class='noun'> —n. </strong> Act of jump-starting.</p>",
      },
    ],
  },
  {
    word: "Jump suit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jump suit <strong class='noun'> n. </strong> One-piece garment for the whole body.</p>",
      },
    ],
  },
  {
    word: "Jumpy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jumpy <strong class='adj'> adj. </strong> (-ier, -iest) 1 nervous; easily startled. 2 making sudden movements. jumpiness n.</p>",
      },
    ],
  },
  {
    word: "Jun.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jun. <strong class='abbr'> Abbr. </strong> 1 june. 2 junior.</p>",
      },
    ],
  },
  {
    word: "Junction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junction <strong class='noun'> n. </strong> 1 joint; joining-point. 2 place where railway lines or roads meet. 3 joining. [latin: related to *join]</p>",
      },
    ],
  },
  {
    word: "Junction box",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junction box <strong class='noun'> n. </strong> Box containing a junction of electric cables etc.</p>",
      },
    ],
  },
  {
    word: "Juncture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juncture <strong class='noun'> n. </strong> 1 critical convergence of events; point of time (at this juncture). 2 joining-point. 3 joining.</p>",
      },
    ],
  },
  {
    word: "June",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>June <strong class='noun'> n. </strong> Sixth month of the year. [latin junius from juno, name of a goddess]</p>",
      },
    ],
  },
  {
    word: "Jungian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jungian <strong class='adj'> —adj.</strong> Of the swiss psychologist carl jung or his theories. <strong class='noun'> —n. </strong> Supporter of jung or of his theories.</p>",
      },
    ],
  },
  {
    word: "Jungle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jungle <strong class='noun'> n. </strong> 1 a land overgrown with tangled vegetation, esp. In the tropics. B an area of this. 2 wild tangled mass. 3 place of bewildering complexity, confusion, or struggle. law of the jungle state of ruthless competition. jungly adj. [hindi from sanskrit]</p>",
      },
    ],
  },
  {
    word: "Junior",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junior <strong class='adj'> —adj.</strong> 1 <strong class='adj'> (often foll. By to) </strong> inferior in age, standing, or position. 2 the younger (esp. Appended to the name of a son for distinction from his father). 3 of the lower or lowest position (junior partner). 4 (of a school) for younger pupils, usu. Aged 7–11. <strong class='noun'> —n. </strong> 1 junior person. 2 person at the lowest level (in an office etc.). [latin, comparative of juvenis young]</p>",
      },
    ],
  },
  {
    word: "Junior common room",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junior common room <strong class='noun'> n. </strong> (also junior combination room) 1 common-room for undergraduates in a college. 2 undergraduates of a college collectively.</p>",
      },
    ],
  },
  {
    word: "Juniper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juniper <strong class='noun'> n. </strong> Evergreen shrub or tree with prickly leaves and dark-purple berry-like cones. [latin juniperus]</p>",
      },
    ],
  },
  {
    word: "Junk1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junk1 <strong class='noun'> —n. </strong> 1 discarded articles; rubbish. 2 anything regarded as of little value. 3 slang narcotic drug, esp. Heroin. —v. Discard as junk. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Junk2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junk2 <strong class='noun'> n. </strong> Flat-bottomed sailing-vessel in the china seas. [javanese djong]</p>",
      },
    ],
  },
  {
    word: "Junk bond",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junk bond <strong class='noun'> n. </strong> Bond bearing high interest but deemed to be a risky investment.</p>",
      },
    ],
  },
  {
    word: "Junket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junket <strong class='noun'> —n. </strong> 1 pleasure outing. 2 official's tour at public expense. 3 sweetened and flavoured milk curds. 4 feast. —v. (-t-) feast, picnic. [french jonquette rush-basket (used for junket 3 and 4), from latin juncus rush]</p>",
      },
    ],
  },
  {
    word: "Junk food",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junk food <strong class='noun'> n. </strong> Food, such as sweets and crisps, with low nutritional value.</p>",
      },
    ],
  },
  {
    word: "Junkie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junkie <strong class='noun'> n. </strong> Slang drug addict.</p>",
      },
    ],
  },
  {
    word: "Junk mail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junk mail <strong class='noun'> n. </strong> Unsolicited advertising matter sent by post.</p>",
      },
    ],
  },
  {
    word: "Junk shop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junk shop <strong class='noun'> n. </strong> Second-hand or cheap antiques shop.</p>",
      },
    ],
  },
  {
    word: "Junta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Junta <strong class='noun'> n. </strong> (usu. Military) clique taking power in a coup d'état. [spanish: related to *join]</p>",
      },
    ],
  },
  {
    word: "Jural",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jural <strong class='adj'> adj. </strong> 1 of law. 2 of rights and obligations. [latin jus jur- law, right]</p>",
      },
    ],
  },
  {
    word: "Jurassic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jurassic <strong class='geol'> geol. </strong> <strong class='adj'> —adj.</strong> Of the second period of the mesozoic era. <strong class='noun'> —n. </strong> This era or system. [french from jura mountains]</p>",
      },
    ],
  },
  {
    word: "Juridical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juridical <strong class='noun'> n. </strong> 1 of judicial proceedings. 2 relating to the law. [latin jus jur- law, dico say]</p>",
      },
    ],
  },
  {
    word: "Jurisdiction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jurisdiction <strong class='noun'> n. </strong> 1 (often foll. By over, of) administration of justice. 2 a legal or other authority. B extent of this; territory it extends over. jurisdictional adj.</p>",
      },
    ],
  },
  {
    word: "Jurisprudence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jurisprudence <strong class='noun'> n. </strong> Science or philosophy of law. jurisprudential adj.</p>",
      },
    ],
  },
  {
    word: "Jurist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jurist <strong class='noun'> n. </strong> Expert in law. juristic adj.</p>",
      },
    ],
  },
  {
    word: "Juror",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juror <strong class='noun'> n. </strong> 1 member of a jury. 2 person taking an oath.</p>",
      },
    ],
  },
  {
    word: "Jury",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jury <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 body of usu. Twelve people giving a verdict in a court of justice. 2 body of people awarding prizes in a competition.</p>",
      },
    ],
  },
  {
    word: "Jury-box",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jury-box <strong class='noun'> n. </strong> Enclosure for the jury in a lawcourt.</p>",
      },
    ],
  },
  {
    word: "Jury-rigged",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jury-rigged <strong class='adj'> adj. </strong> Naut. Having temporary makeshift rigging. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Just",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Just <strong class='adj'> —adj.</strong> 1 morally right or fair. 2 (of treatment etc.) Deserved (just reward). 3 well-grounded; justified (just anger). 4 right in amount etc.; proper. <strong class='adv'> —adv. </strong> 1 exactly (just what i need). 2 a little time ago; very recently (has just seen them). 3 <strong class='colloq'> colloq. </strong> Simply, merely (just good friends; just doesn't make sense). 4 barely; no more than (just managed it). 5 <strong class='colloq'> colloq. </strong> Positively; indeed (just splendid; won't i just tell him!). 6 quite (not just yet). just about <strong class='colloq'> colloq. </strong> Almost exactly; almost completely. Just in case as a precaution. Just now 1 at this moment. 2 a little time ago. Just the same = all the same. Just so 1 exactly arranged (everything just so). 2 it is exactly as you say. justly <strong class='adv'> adv. </strong> Justness <strong class='noun'> n. </strong> [latin justus from jus right]</p>",
      },
    ],
  },
  {
    word: "Justice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Justice <strong class='noun'> n. </strong> 1 justness, fairness. 2 authority exercised in the maintenance of right. 3 judicial proceedings (brought to justice; court of justice). 4 magistrate; judge. do justice to 1 treat fairly. 2 appreciate properly. Do oneself justice perform at one's best. With justice reasonably. [latin justitia]</p>",
      },
    ],
  },
  {
    word: "Justice of the peace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Justice of the peace <strong class='noun'> n. </strong> Unpaid lay magistrate appointed to hear minor cases.</p>",
      },
    ],
  },
  {
    word: "Justifiable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Justifiable <strong class='adj'> adj. </strong> Able to be justified. justifiably adv.</p>",
      },
    ],
  },
  {
    word: "Justify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Justify <strong class='verb'> v. </strong> (-ies, -ied) 1 show the justice or correctness of (a person, act, assertion, etc.). 2 (esp. In passive) cite or constitute adequate grounds for (conduct, a claim, etc.); vindicate. 3 (as justified adj.) Just, right (justified in assuming). 4 printing adjust (a line of type) to give even margins. justification <strong class='noun'> n. </strong> Justificatory adj.</p>",
      },
    ],
  },
  {
    word: "Jut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jut <strong class='noun'> —v. </strong> <strong class='adj'> (-tt-) </strong> (often foll. By out, forth) protrude, project. <strong class='noun'> —n. </strong> Projection. [var. Of *jet1]</p>",
      },
    ],
  },
  {
    word: "Jute",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Jute <strong class='noun'> n. </strong> 1 fibre from the bark of an e. Indian plant, used esp. For sacking, mats, etc. 2 plant yielding this. [bengali]</p>",
      },
    ],
  },
  {
    word: "Juvenile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juvenile <strong class='adj'> —adj.</strong> 1 a youthful. B of or for young people. 2 often derog. Immature (juvenile behaviour). <strong class='noun'> —n. </strong> 1 young person. 2 actor playing a juvenile part. [latin juvenis young]</p>",
      },
    ],
  },
  {
    word: "Juvenile court",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juvenile court <strong class='noun'> n. </strong> Court for children under 17.</p>",
      },
    ],
  },
  {
    word: "Juvenile delinquency",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juvenile delinquency <strong class='noun'> n. </strong> Offences committed by people below the age of legal responsibility. juvenile delinquent n.</p>",
      },
    ],
  },
  {
    word: "Juvenilia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juvenilia <strong class='n_pl'> n.pl. </strong> Author's or artist's youthful works.</p>",
      },
    ],
  },
  {
    word: "Juxtapose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Juxtapose <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> 1 place (things) side by side. 2 (foll. By to, with) place (a thing) beside another. juxtaposition <strong class='noun'> n. </strong> Juxtapositional adj. [latin juxta next, pono put]</p>",
      },
    ],
  },
];

export const alphabet_J_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>J1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>J2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रहार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गपशप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झगड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>jacaranda</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जासिन्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सियार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छैला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुटने तक का जूता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार की पक्षी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैकेट आलू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाड़ा बाबा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉक्स में जैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैकनीफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कलाकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैक ओ लालटेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैक प्लग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैकपोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैकबिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैक रस्सेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैक का पत्थर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैक टार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैकोबीयन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेकोबीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैकर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जकूज़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jade1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jade2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्लांत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>J'adoube</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jag1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jag2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दांतेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का जानवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेल (गॉल भी)</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क़ैदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेल तोड़ो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जलिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैलप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुरानी गड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किवाड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>JAM1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>JAM2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौखट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्सव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैम जार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जम्मी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खचाखच भरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जनवरी।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झंकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौकीदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जनवरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जापानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जापान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जापानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मज़ाक करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jar1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>JAR2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jar3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जार्डिनियर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शब्दजाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमेली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूर्यकांत मणि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीलिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मनोरंजन की यात्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जालीदार कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अल्हड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जावानीस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जबड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जबड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जबड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीलकंठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जयवॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैज़मैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाज का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेसीबी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>JCR</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईर्ष्यालु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईर्ष्या द्वेष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीन्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीपर्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मज़ाक उड़ाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिहाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिहाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहोवा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेनोवा का गवाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेसूद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूखेपन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेकिल और हाईड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जमाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेलबा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जम जाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेली बेबी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेलिफ़िश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सर्व-कुंची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेनी-व्रन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़तरे में डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़तरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जर्बिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का जानवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेरमिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यिर्मयाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jerk1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jerk2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुरती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटकेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरा प्याला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैरी बिल्डर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेरी कर सकता है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जर्सी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूरजमूखी का पौधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हंसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विदूषक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेसुइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पाखंडी का सा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यीशु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jet1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jet2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूरा काला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेट इंजिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विमान यात्रा से हुई थकान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेट विमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेट से चलनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेट इंजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेट इंजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोझ गिराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घाट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहूदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गहना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जौहरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जौहरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आभूषण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहूदिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहूदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहूदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहूदियों की विना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईजेबेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jib1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jib2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jibe1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jibe2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिफट बैग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नमूना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीहू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जगाया हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुड़-pokery</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बजना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिहाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेवफा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिम क्रो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिम-जैम्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टनटनाहट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कौमपरस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनोद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदकिस्मती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घबराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीट्टेरबूर्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीउ जित्सु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूनियर।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दलाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कामुकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भ्रष्टाचार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौकरी केंद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौकरी की तलाश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौकरी बहुत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौकरी का काम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लड़कों के लिए नौकरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काम बाँटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काम की शीट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जॉकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जॉकस्ट्रैप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विनोदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रसिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रफुल्ल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोधपूर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जो ब्लॉग्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धकेलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुड़दौड़ का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इधर-उधर हिलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोगट्रॉट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जॉन बुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जॉन डोरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोकरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जॉनी आओ-हाल ही में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीने की ख़ुशी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>योजक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयुक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयुक्त भंडार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संयुक्त स्टॉक कंपनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्त्रीधन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धरन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोजोबा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चुटकुला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़ुश होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चहलपहल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jolly1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jolly2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जली रोजर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोनाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का नरगिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चीनी बुत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जॉस स्टिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संक्षेप में लिख देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संक्षेप लिखनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संक्षेप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जौल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्रिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्रिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्रकारिता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पत्रकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सफ़र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंख़्वाहदार मजदूर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जौ हिस्ट।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जौव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उल्लासपूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jowl1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jowl2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हर्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हूज़राइड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोस्टिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जेपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूनियर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उल्लसित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुबली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूदाईस्म का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहूदी धर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यहूदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जज करने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यायाधीश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रलय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फैसले का दिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महकमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदालती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यायतंत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उचित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूदो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुराही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाजीगरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुगोस्लाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गले का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गले का नस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रसीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूस-जुत्सु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जू जू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूजूबे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जु-जुत्सु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज्यूकबॉक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुलाई।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शर्बत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूलियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूलियन कैलेंडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूलीएन्ने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूलियट कैप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुलाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गड़बड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गड़बड़ी बिक्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दैत्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कूदना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर कूद गया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jumper1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Jumper2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कूदते हुए बीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जम्प जेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कूदना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कूद जान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कूदना शुरू करो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जम्पसुट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिड़चिड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जून।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संगम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंक्शन बॉक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुंगियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंगल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कनिष्ठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूनियर कॉमन रूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुनिपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>JUNK1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>JUNK2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंक बांड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दावत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंक फूड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जून्की</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंक मेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कबाड़ की दुकान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जून्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुरली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जुरासिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कानूनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्षेत्राधिकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विधिशास्त्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विधिवेत्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूरियों में एक व्यक्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पंचायत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूरी बॉक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूरी-धांधली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभी-अभी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्याय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यायिक अधिकारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तर्कसंगत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यायोचित ठहराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किशोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाल अदालत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाल अपराध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लड़की</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मिलाना</p>",
  },
];
