export const alphabet_B = [
  {
    word: "B",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>B</p>",
      },
    ],
  },
  {
    word: "B1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>B1 <strong class='noun'> n. </strong> (pl. Bs or b's) 1 (also b) second letter of the alphabet. 2 <strong class='mus'> mus. </strong> Seventh note of the diatonic scale of c major. 3 second hypothetical person or example. 4 second highest category (of roads, academic marks, etc.). 5 (usu. B) algebra second known quantity.</p>",
      },
    ],
  },
  {
    word: "B2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>B2 <strong class='prep'> symb. </strong> Boron.</p>",
      },
    ],
  },
  {
    word: "B3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>B3 <strong class='sing'> abbr. </strong> (also b.) Black (pencil-lead).</p>",
      },
    ],
  },
  {
    word: "B.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>B. <strong class='abbr'> Abbr. </strong> 1 born. 2 cricket a bowled by. B bye.</p>",
      },
    ],
  },
  {
    word: "Ba",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ba <strong class='sing'> abbr. </strong> 1 bachelor of arts. 2 british airways.</p>",
      },
    ],
  },
  {
    word: "Ba",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ba <strong class='prep'> symb. </strong> Barium.</p>",
      },
    ],
  },
  {
    word: "Baa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baa <strong class='noun'> —v. </strong> (baas, baaed or baa'd) bleat. <strong class='noun'> —n. </strong> Sheep's cry. [imitative]</p>",
      },
    ],
  },
  {
    word: "Babble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Babble <strong class='noun'> —v. </strong> (-ling) 1 a talk, chatter, or say incoherently or excessively. B (of a stream etc.) Murmur. 2 repeat or divulge foolishly. <strong class='noun'> —n. </strong> 1 babbling. 2 murmur of voices, water, etc. [imitative]</p>",
      },
    ],
  },
  {
    word: "Babe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Babe <strong class='noun'> n. </strong> 1 literary baby. 2 innocent or helpless person. 3 us slang young woman. [as *baby]</p>",
      },
    ],
  },
  {
    word: "Babel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Babel <strong class='noun'> n. </strong> 1 confused noise, esp. Of voices. 2 scene of confusion. [hebrew, = babylon (gen. 11)]</p>",
      },
    ],
  },
  {
    word: "Baboon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baboon <strong class='noun'> n. </strong> Large long-nosed african and arabian monkey. [french or medieval latin]</p>",
      },
    ],
  },
  {
    word: "Baby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baby <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 very young child. 2 childish person. 3 youngest member of a family etc. 4 (often attrib.) A very young animal. B small specimen. 5 slang sweetheart. 6 one's special concern etc. —v. (-ies, -ied) treat like a baby; pamper. babyhood <strong class='noun'> n. </strong> Babyish adj. [imitative of child's ba ba]</p>",
      },
    ],
  },
  {
    word: "Baby boom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baby boom <strong class='noun'> n. </strong> Colloq. Temporary increase in the birthrate.</p>",
      },
    ],
  },
  {
    word: "Baby buggy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baby buggy <strong class='noun'> n. </strong> Propr. A kind of child's pushchair.</p>",
      },
    ],
  },
  {
    word: "Baby carriage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baby carriage <strong class='noun'> n. </strong> Us pram.</p>",
      },
    ],
  },
  {
    word: "Baby grand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baby grand <strong class='noun'> n. </strong> Small grand piano.</p>",
      },
    ],
  },
  {
    word: "Babygro",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Babygro <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> propr. Stretchy all-in-one baby suit.</p>",
      },
    ],
  },
  {
    word: "Babysit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Babysit <strong class='verb'> v. </strong>  <strong class='see'>  <strong class='past_and_past_part'>(-tt-; past and  <strong class='archaic_it_was'>past part.</strong> -sat)</strong> </strong> look after a child while its parents are out. babysitter n.</p>",
      },
    ],
  },
  {
    word: "Baccalaureate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baccalaureate <strong class='noun'> n. </strong> Final secondary school examination in france and many international schools. [medieval latin baccalaureus bachelor]</p>",
      },
    ],
  },
  {
    word: "Baccarat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baccarat <strong class='noun'> n. </strong> Gambling card-game. [french]</p>",
      },
    ],
  },
  {
    word: "Bacchanal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bacchanal <strong class='noun'> —n. </strong> 1 drunken revelry or reveller. 2 priest or follower of bacchus. <strong class='adj'> —adj.</strong> 1 of or like bacchus. 2 drunkenly riotous. [latin bacchus from greek, god of wine]</p>",
      },
    ],
  },
  {
    word: "Bacchanalia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bacchanalia <strong class='n_pl'> n.pl. </strong> 1 roman festival of bacchus. 2 (bacchanalia) drunken revelry.</p>",
      },
    ],
  },
  {
    word: "Bacchant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bacchant <strong class='noun'> —n. </strong> (fem. Bacchante) 1 priest or follower of bacchus. 2 drunken reveller. <strong class='adj'> —adj.</strong> 1 of or like bacchus or his rites. 2 drunkenly riotous, roistering.</p>",
      },
    ],
  },
  {
    word: "Bacchic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bacchic <strong class='adj'> adj. </strong> = *bacchanal adj.</p>",
      },
    ],
  },
  {
    word: "Baccy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baccy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> <strong class='colloq'> colloq. </strong> Tobacco. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Bachelor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bachelor <strong class='noun'> n. </strong> 1 unmarried man. 2 person with a university first degree. bachelorhood <strong class='noun'> n. </strong> [related to *baccalaureate]</p>",
      },
    ],
  },
  {
    word: "Bachelor girl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bachelor girl <strong class='noun'> n. </strong> Independent young single woman.</p>",
      },
    ],
  },
  {
    word: "Bacillus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bacillus <strong class='noun'> n. </strong> (pl. Bacilli) rod-shaped bacterium, esp. One causing disease. bacillary adj. [latin, diminutive of baculus stick]</p>",
      },
    ],
  },
  {
    word: "Back",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back <strong class='noun'> —n. </strong> 1 a rear surface of the human body from shoulder to hip. B upper surface of an animal's body. C spine (broke his back). D keel of a ship. 2 backlike surface (back of the head, chair, shirt). 3 reverse or more distant part (back of the room; sat in the back; write it on the back). 4 defensive player in football etc. <strong class='adv'> —adv. </strong> 1 to the rear (go back a bit; looked back). 2 in or into a previous state, place, or time (came back; put it back; back in june). 3 at a distance (stand back). 4 in return (pay back). 5 in check (hold him back). —v. 1 a give moral or financial support to. B bet on (a horse etc.). 2 (often foll. By up) move backwards. 3 a put or serve as a back, background, or support to. B <strong class='mus'> mus. </strong> Accompany. 4 lie at the back of (beach backed by cliffs). 5 (of the wind) move anticlockwise. <strong class='adj'> —adj.</strong> 1 situated to the rear; remote, subsidiary (back teeth). 2 past; not current (back pay; back issue). 3 reversed (back flow). back and forth to and fro. Back down withdraw from confrontation. The back of beyond very remote place. Back off 1 draw back, retreat. 2 = back down. Back on to have its back adjoining (backs on to a field). Back out (often foll. By of) withdraw from a commitment. Back-pedal reverse one's action or opinion. Back to back with backs adjacent and facing each other (stood back to back). Back up 1 give (esp. Moral) support to. 2 computing make a backup of (data, a disk, etc.). Get (or put) a person's back up annoy a person. Get off a person's back stop troubling a person. Turn one's back on abandon; ignore. backer <strong class='noun'> n. </strong> (in sense 1 of v.). Backless adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Backache",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backache <strong class='noun'> n. </strong> Ache in the back.</p>",
      },
    ],
  },
  {
    word: "Back-bencher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-bencher <strong class='noun'> n. </strong> Mp not holding a senior office.</p>",
      },
    ],
  },
  {
    word: "Backbiting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backbiting <strong class='noun'> n. </strong> Malicious talk. backbite v.</p>",
      },
    ],
  },
  {
    word: "Back-boiler",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-boiler <strong class='noun'> n. </strong> Boiler behind a domestic fire.</p>",
      },
    ],
  },
  {
    word: "Backbone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backbone <strong class='noun'> n. </strong> 1 spine. 2 chief support. 3 firmness of character.</p>",
      },
    ],
  },
  {
    word: "Back-breaking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-breaking <strong class='adj'> adj. </strong> (esp. Of manual work) extremely hard.</p>",
      },
    ],
  },
  {
    word: "Back-burner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-burner <strong class='noun'> n. </strong> on the back-burner receiving little attention.</p>",
      },
    ],
  },
  {
    word: "Backchat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backchat <strong class='noun'> n. </strong> Colloq. Verbal insolence.</p>",
      },
    ],
  },
  {
    word: "Backcloth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backcloth <strong class='noun'> n. </strong> 1 painted cloth at the back of a stage. 2 background to a scene or situation.</p>",
      },
    ],
  },
  {
    word: "Backcomb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backcomb <strong class='verb'> v. </strong> Comb (the hair) towards the scalp to give it fullness.</p>",
      },
    ],
  },
  {
    word: "Back-crawl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-crawl <strong class='noun'> n. </strong> = *backstroke.</p>",
      },
    ],
  },
  {
    word: "Backdate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backdate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 make retrospectively valid. 2 put an earlier date to than the actual one.</p>",
      },
    ],
  },
  {
    word: "Back door",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back door <strong class='noun'> n. </strong> Secret or ingenious means.</p>",
      },
    ],
  },
  {
    word: "Backdrop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backdrop <strong class='noun'> n. </strong> = *backcloth.</p>",
      },
    ],
  },
  {
    word: "Backfire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backfire <strong class='verb'> v. </strong> <strong class='adj'> (-ring) </strong> 1 (of an engine or vehicle) ignite or explode too early in the cylinder or exhaust. 2 (of a plan etc.) Rebound adversely on its originator.</p>",
      },
    ],
  },
  {
    word: "Back-formation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-formation <strong class='noun'> n. </strong> 1 formation of a word from its seeming derivative (e.g. Laze from lazy). 2 word so formed.</p>",
      },
    ],
  },
  {
    word: "Backgammon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backgammon <strong class='noun'> n. </strong> Board-game with pieces moved according to throws of the dice. [from *back + obsolete form of *game1]</p>",
      },
    ],
  },
  {
    word: "Background",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Background <strong class='noun'> n. </strong> 1 part of a scene or picture furthest from the observer. 2 (often attrib.) Inconspicuous position (kept in the background; background music). 3 person's education, social circumstances, etc. 4 explanatory or contributory information or events.</p>",
      },
    ],
  },
  {
    word: "Backhand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backhand <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> (of a stroke) made with the hand across one's body. <strong class='noun'> —n. </strong> Such a stroke.</p>",
      },
    ],
  },
  {
    word: "Backhanded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backhanded <strong class='adj'> adj. </strong> 1 made with the back of the hand. 2 indirect; ambiguous (backhanded compliment).</p>",
      },
    ],
  },
  {
    word: "Backhander",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backhander <strong class='noun'> n. </strong> 1 a backhand stroke. B backhanded blow. 2 slang bribe.</p>",
      },
    ],
  },
  {
    word: "Backing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backing <strong class='noun'> n. </strong> 1 a support, esp. Financial or moral. B material used for a thing's back or support. 2 musical accompaniment, esp. To a pop singer.</p>",
      },
    ],
  },
  {
    word: "Backing track",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backing track <strong class='noun'> n. </strong> Recorded musical accompaniment.</p>",
      },
    ],
  },
  {
    word: "Backlash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backlash <strong class='noun'> n. </strong> 1 violent, usu. Hostile, reaction. 2 sudden recoil in a mechanism.</p>",
      },
    ],
  },
  {
    word: "Backlist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backlist <strong class='noun'> n. </strong> Publisher's list of books still in print.</p>",
      },
    ],
  },
  {
    word: "Backlog",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backlog <strong class='noun'> n. </strong> Arrears of work.</p>",
      },
    ],
  },
  {
    word: "Back number",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back number <strong class='noun'> n. </strong> 1 out-of-date issue of a periodical. 2 slang out-of-date person or thing.</p>",
      },
    ],
  },
  {
    word: "Backpack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backpack <strong class='noun'> —n. </strong> Rucksack. —v. Travel or hike with this. backpacker n.</p>",
      },
    ],
  },
  {
    word: "Back passage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back passage <strong class='noun'> n. </strong> Colloq. Rectum.</p>",
      },
    ],
  },
  {
    word: "Backrest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backrest <strong class='noun'> n. </strong> Support for the back.</p>",
      },
    ],
  },
  {
    word: "Back room",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back room <strong class='noun'> n. </strong> (often, with hyphen, attrib.) Place where secret work is done.</p>",
      },
    ],
  },
  {
    word: "Back seat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back seat <strong class='noun'> n. </strong> Less prominent or important position.</p>",
      },
    ],
  },
  {
    word: "Back-seat driver",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-seat driver <strong class='noun'> n. </strong> Person eager to advise without taking responsibility.</p>",
      },
    ],
  },
  {
    word: "Backside",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backside <strong class='noun'> n. </strong> Colloq. Buttocks.</p>",
      },
    ],
  },
  {
    word: "Back slang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back slang <strong class='noun'> n. </strong> Slang using words spelt backwards (e.g. Yob).</p>",
      },
    ],
  },
  {
    word: "Backslide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backslide <strong class='verb'> v. </strong> (-ding; past -slid;  <strong class='archaic_it_was'>past part.</strong> -slid or -slidden) return to bad habits etc.</p>",
      },
    ],
  },
  {
    word: "Backspace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backspace <strong class='verb'> v. </strong> (-cing) move a typewriter carriage etc. Back one or more spaces.</p>",
      },
    ],
  },
  {
    word: "Backspin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backspin <strong class='noun'> n. </strong> Backward spin making a ball bounce erratically.</p>",
      },
    ],
  },
  {
    word: "Backstage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backstage <strong class='adv'> adv. </strong> & adj. Behind the scenes.</p>",
      },
    ],
  },
  {
    word: "Backstairs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backstairs <strong class='noun'> —n. </strong> <strong class='pl'> pl. </strong> Rear or side stairs of a building. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> (also backstair) underhand; secret.</p>",
      },
    ],
  },
  {
    word: "Backstitch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backstitch <strong class='noun'> n. </strong> Sewing with each stitch starting behind the end of the previous one.</p>",
      },
    ],
  },
  {
    word: "Back-stop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-stop <strong class='noun'> n. </strong> 1 cricket etc. A position directly behind the wicket-keeper. B fielder in this position. 2 last resort.</p>",
      },
    ],
  },
  {
    word: "Backstreet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backstreet <strong class='noun'> —n. </strong> Side-street, alley. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Illicit; illegal (backstreet abortion).</p>",
      },
    ],
  },
  {
    word: "Backstroke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backstroke <strong class='noun'> n. </strong> Swimming stroke done on the back.</p>",
      },
    ],
  },
  {
    word: "Back-to-back",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-to-back <strong class='adj'> adj. </strong> (of houses) with a party wall at the rear.</p>",
      },
    ],
  },
  {
    word: "Back to front",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back to front <strong class='adj'> adj. </strong> 1 with back and front reversed. 2 in disorder.</p>",
      },
    ],
  },
  {
    word: "Back-to-nature",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Back-to-nature <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Seeking a simpler way of life.</p>",
      },
    ],
  },
  {
    word: "Backtrack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backtrack <strong class='verb'> v. </strong> 1 retrace one's steps. 2 reverse one's policy or opinion.</p>",
      },
    ],
  },
  {
    word: "Backup",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backup <strong class='noun'> n. </strong> (often attrib.) 1 support; reserve (back-up team). 2 computing a making of spare copies of data for safety. B copy so made.</p>",
      },
    ],
  },
  {
    word: "Backward",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backward <strong class='adv'> —adv. </strong> = *backwards. <strong class='adj'> —adj.</strong> 1 towards the rear or starting-point (backward look). 2 reversed (backward roll). 3 slow to develop or progress. 4 hesitant, shy.</p>",
      },
    ],
  },
  {
    word: "Backwards",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backwards <strong class='adv'> adv. </strong> 1 away from one's front (lean backwards). 2 a with the back foremost (walk backwards). B in reverse of the usual way (count backwards). 3 a into a worse state. B into the past. C (of motion) back towards the starting-point (roll backwards). backwards and forwards to and fro. Bend (or fall or lean) over backwards <strong class='colloq'> colloq. </strong> Make every effort, esp. To be fair or helpful.</p>",
      },
    ],
  },
  {
    word: "Backwash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backwash <strong class='noun'> n. </strong> 1 receding waves made by a ship etc. 2 repercussions.</p>",
      },
    ],
  },
  {
    word: "Backwater",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backwater <strong class='noun'> n. </strong> 1 peaceful, secluded, or dull place. 2 stagnant water fed from a stream.</p>",
      },
    ],
  },
  {
    word: "Backwoods",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backwoods <strong class='n_pl'> n.pl. </strong> 1 remote uncleared forest land. 2 remote region. backwoodsman n.</p>",
      },
    ],
  },
  {
    word: "Backyard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Backyard <strong class='noun'> n. </strong> Yard behind a house etc.</p>",
      },
    ],
  },
  {
    word: "Bacon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bacon <strong class='noun'> n. </strong> Cured meat from the back or sides of a pig. [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Bacteriology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bacteriology <strong class='noun'> n. </strong> The study of bacteria.</p>",
      },
    ],
  },
  {
    word: "Bacterium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bacterium <strong class='noun'> n. </strong> (pl. -ria) unicellular micro-organism lacking an organized nucleus, esp. Of a kind causing disease. bacterial adj. [greek, = little stick]</p>",
      },
    ],
  },
  {
    word: "Bad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bad <strong class='adj'> —adj.</strong> (worse, worst) 1 inadequate, defective (bad work, light). 2 unpleasant (bad weather). 3 harmful (is bad for you). 4 (of food) decayed. 5 <strong class='colloq'> colloq. </strong> Ill, injured (feeling bad today; a bad leg). 6 <strong class='colloq'> colloq. </strong> Regretful, guilty (feels bad about it). 7 serious, severe (a bad headache, mistake). 8 a morally unacceptable (bad man; bad language). B naughty. 9 not valid (a bad cheque). 10 (badder, baddest) esp. Us slang excellent. <strong class='noun'> —n. </strong> Ill fortune; ruin. <strong class='adv'> —adv. </strong> Us <strong class='colloq'> colloq. </strong> Badly. not (or not so) bad <strong class='colloq'> colloq. </strong> Fairly good. Too bad <strong class='colloq'> colloq. </strong> Regrettable. [old english]</p>",
      },
    ],
  },
  {
    word: "Bad blood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bad blood <strong class='noun'> n. </strong> Ill feeling.</p>",
      },
    ],
  },
  {
    word: "Bad books",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bad books <strong class='see'> see *</strong> book.</p>",
      },
    ],
  },
  {
    word: "Bad breath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bad breath <strong class='noun'> n. </strong> Unpleasant-smelling breath.</p>",
      },
    ],
  },
  {
    word: "Bad debt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bad debt <strong class='noun'> n. </strong> Debt that is not recoverable.</p>",
      },
    ],
  },
  {
    word: "Baddy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baddy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> <strong class='colloq'> colloq. </strong> Villain in a story, film, etc.</p>",
      },
    ],
  },
  {
    word: "Bade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bade <strong class='see'> see *</strong> bid.</p>",
      },
    ],
  },
  {
    word: "Bad egg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bad egg <strong class='see'> see *</strong> egg1.</p>",
      },
    ],
  },
  {
    word: "Bad faith",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bad faith <strong class='noun'> n. </strong> Intent to deceive.</p>",
      },
    ],
  },
  {
    word: "Badge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Badge <strong class='noun'> n. </strong> 1 small flat emblem worn to signify office, membership, etc., or as decoration. 2 thing that reveals a condition or quality. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Badger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Badger <strong class='noun'> —n. </strong> Nocturnal burrowing mammal with a black and white striped head. —v. Pester, harass. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Badinage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Badinage <strong class='noun'> n. </strong> Playful ridicule. [french]</p>",
      },
    ],
  },
  {
    word: "Bad lot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bad lot <strong class='noun'> n. </strong> Person of bad character.</p>",
      },
    ],
  },
  {
    word: "Badly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Badly <strong class='adv'> adv. </strong> (worse, worst) 1 in a bad manner. 2 <strong class='colloq'> colloq. </strong> Very much (wants it badly). 3 severely (badly defeated).</p>",
      },
    ],
  },
  {
    word: "Badminton",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Badminton <strong class='noun'> n. </strong> Game with rackets and a shuttlecock. [badminton in s. England]</p>",
      },
    ],
  },
  {
    word: "Bad-mouth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bad-mouth <strong class='verb'> v. </strong> Esp. Us slang abuse verbally, put down.</p>",
      },
    ],
  },
  {
    word: "Bad news",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bad news <strong class='noun'> n. </strong> Colloq. Unpleasant or troublesome person or thing.</p>",
      },
    ],
  },
  {
    word: "Bad-tempered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bad-tempered <strong class='adj'> adj. </strong> Irritable.</p>",
      },
    ],
  },
  {
    word: "Baffle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baffle <strong class='noun'> —v. </strong> (-ling) 1 perplex. 2 frustrate, hinder. <strong class='noun'> —n. </strong> Device that checks flow esp. Of fluid or sound waves. bafflement <strong class='noun'> n. </strong> [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Bafta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bafta <strong class='sing'> abbr. </strong> British association of film and television arts.</p>",
      },
    ],
  },
  {
    word: "Bag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bag <strong class='noun'> —n. </strong> 1 soft open-topped receptacle. 2 a piece of luggage. B woman's handbag. 3 (in pl.; usu. Foll. By of) <strong class='colloq'> colloq. </strong> Large amount (bags of time). 4 slang derog. Woman. 5 animal's sac. 6 amount of game shot by one person. 7 (usu. In pl.) Baggy skin under the eyes. 8 slang particular interest (folk music is not my bag). —v. <strong class='gg'> (-gg-) </strong> 1 <strong class='colloq'> colloq. </strong> A secure (bagged the best seat). B (often in phr. Bags i) <strong class='colloq'> colloq. </strong> Claim as being the first (bags i go next). 2 put in a bag. 3 (cause to) hang loosely; bulge. in the bag <strong class='colloq'> colloq. </strong> Achieved, secured. bagful <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong>. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bagatelle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bagatelle <strong class='noun'> n. </strong> 1 game in which small balls are struck into holes on a board. 2 mere trifle. 3 short piece of esp. Piano music. [french from italian]</p>",
      },
    ],
  },
  {
    word: "Bagel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bagel <strong class='noun'> n. </strong> Ring-shaped bread roll. [yiddish]</p>",
      },
    ],
  },
  {
    word: "Baggage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baggage <strong class='noun'> n. </strong> 1 luggage. 2 portable army equipment. 3 joc. Or derog. Girl or woman. 4 mental encumbrances. [french]</p>",
      },
    ],
  },
  {
    word: "Baggy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baggy <strong class='adj'> adj. </strong> (-ier, -iest) hanging loosely. baggily <strong class='adv'> adv. </strong> Bagginess n.</p>",
      },
    ],
  },
  {
    word: "Bagpipe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bagpipe <strong class='noun'> n. </strong> (usu. In pl.) Musical instrument consisting of a windbag connected to reeded pipes.</p>",
      },
    ],
  },
  {
    word: "Baguette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baguette <strong class='noun'> n. </strong> Long thin french loaf. [french]</p>",
      },
    ],
  },
  {
    word: "Bah",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bah <strong class='n_pl'> int. </strong> Expressing contempt or disbelief. [french]</p>",
      },
    ],
  },
  {
    word: "Baha'i",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baha'i <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> member of a monotheistic religion emphasizing religious unity and world peace. [persian bahá splendour]</p>",
      },
    ],
  },
  {
    word: "Bail1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bail1 <strong class='noun'> —n. </strong> 1 money etc. Pledged against the temporary release of an untried prisoner. 2 person(s) giving this. —v. (usu. Foll. By out) 1 release or secure the release of (a prisoner) on payment of bail. 2 release from a difficulty; rescue. on bail released after payment of bail. [latin bajulus carrier]</p>",
      },
    ],
  },
  {
    word: "Bail2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bail2 <strong class='noun'> n. </strong> 1 cricket either of two crosspieces bridging the stumps. 2 bar holding the paper against a typewriter platen. 3 bar separating horses in an open stable. [french]</p>",
      },
    ],
  },
  {
    word: "Bail3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bail3 <strong class='verb'> v. </strong> (also bale) 1 (usu. Foll. By out) scoop water out of (a boat etc.). 2 scoop (water etc.) Out. bail out <strong class='var'> var. </strong> Of bale out 1 (see *bale1). [french]</p>",
      },
    ],
  },
  {
    word: "Bail2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bail2 <strong class='noun'> n. </strong> 1 cricket either of two crosspieces bridging the stumps. 2 bar holding the paper against a typewriter platen. 3 bar separating horses in an open stable. [french]</p>",
      },
    ],
  },
  {
    word: "Bail3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bail3 <strong class='verb'> v. </strong> (also bale) 1 (usu. Foll. By out) scoop water out of (a boat etc.). 2 scoop (water etc.) Out. bail out <strong class='var'> var. </strong> Of bale out 1 (see *bale1). [french]</p>",
      },
    ],
  },
  {
    word: "Bailey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bailey <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 outer wall of a castle. 2 court enclosed by it. [french: related to *bail2]</p>",
      },
    ],
  },
  {
    word: "Bailey bridge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bailey bridge <strong class='noun'> n. </strong> Prefabricated military bridge for rapid assembly. [sir d. Bailey, name of its designer]</p>",
      },
    ],
  },
  {
    word: "Bailiff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bailiff <strong class='noun'> n. </strong> 1 sheriff's officer who executes writs and carries out distraints. 2 landlord's agent or steward. [french: related to *bail1]</p>",
      },
    ],
  },
  {
    word: "Bailiwick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bailiwick <strong class='noun'> n. </strong> 1 law district of a bailiff. 2 joc. Person's particular interest. [as *bailiff, obsolete wick district]</p>",
      },
    ],
  },
  {
    word: "Bain-marie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bain-marie <strong class='noun'> n. </strong> (pl. Bains-marie pronunc. Same) pan of hot water holding a pan containing sauce etc. For slow heating. [french, translation of medieval latin balneum mariae bath of maria (a supposed alchemist)]</p>",
      },
    ],
  },
  {
    word: "Bairn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bairn <strong class='noun'> n. </strong> Scot. & n.engl. Child. [old english: related to *bear1]</p>",
      },
    ],
  },
  {
    word: "Bait",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bait <strong class='noun'> —n. </strong> 1 food used to entice prey. 2 allurement. —v. 1 harass, torment, or annoy (a person or chained animal). 2 put bait on (a hook, trap, etc.). [old norse]</p>",
      },
    ],
  },
  {
    word: "Baize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baize <strong class='noun'> n. </strong> Usu. Green woollen felted material, used for coverings. [french <strong class='pl'> pl. </strong> Baies chestnut-coloured]</p>",
      },
    ],
  },
  {
    word: "Bake",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bake <strong class='verb'> v. </strong> (-king) 1 cook or become cooked by dry heat, esp. In an oven. 2 <strong class='colloq'> colloq. </strong> (usu. As be baking) (of weather, a person, etc.) Be very hot. 3 harden by heat. [old english]</p>",
      },
    ],
  },
  {
    word: "Baked beans",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baked beans <strong class='n_pl'> n.pl. </strong> Baked haricot beans, usu. Tinned in tomato sauce.</p>",
      },
    ],
  },
  {
    word: "Bakelite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bakelite <strong class='noun'> n. </strong> Propr. Plastic made from formaldehyde and phenol, used formerly for buttons, plates, etc. [german from baekeland, name of its inventor]</p>",
      },
    ],
  },
  {
    word: "Baker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baker <strong class='noun'> n. </strong> Person who bakes and sells bread, cakes, etc., esp. For a living.</p>",
      },
    ],
  },
  {
    word: "Baker day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baker day <strong class='noun'> n. </strong> Colloq. Day set aside for in-service training of teachers. [baker, name of the education secretary responsible for introducing them]</p>",
      },
    ],
  },
  {
    word: "Baker's dozen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baker's dozen <strong class='noun'> n. </strong> Thirteen.</p>",
      },
    ],
  },
  {
    word: "Bakery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bakery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> place where bread and cakes are made or sold.</p>",
      },
    ],
  },
  {
    word: "Bakewell tart",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bakewell tart <strong class='noun'> n. </strong> Open pastry case lined with jam and filled with almond paste. [bakewell in derbyshire]</p>",
      },
    ],
  },
  {
    word: "Baking-powder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baking-powder <strong class='noun'> n. </strong> Mixture of sodium bicarbonate, cream of tartar, etc., as a raising agent.</p>",
      },
    ],
  },
  {
    word: "Baking-soda",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baking-soda <strong class='noun'> n. </strong> Sodium bicarbonate.</p>",
      },
    ],
  },
  {
    word: "Baklava",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baklava <strong class='noun'> n. </strong> Rich sweetmeat of flaky pastry, honey, and nuts. [turkish]</p>",
      },
    ],
  },
  {
    word: "Baksheesh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baksheesh <strong class='noun'> n. </strong> Gratuity, tip. [persian]</p>",
      },
    ],
  },
  {
    word: "Balaclava",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balaclava <strong class='noun'> n. </strong> (in full balaclava helmet) usu. Woollen covering for the whole head and neck, except for the face. [balaclava in the crimea, the site of a battle in 1854]</p>",
      },
    ],
  },
  {
    word: "Balalaika",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balalaika <strong class='noun'> n. </strong> Guitar-like stringed instrument with a triangular body. [russian]</p>",
      },
    ],
  },
  {
    word: "Balance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balance <strong class='noun'> —n. </strong> 1 a even distribution of weight or amount. B stability of body or mind. 2 apparatus for weighing, esp. One with a central pivot, beam, and two scales. 3 a counteracting weight or force. B (in full balance-wheel) regulating device in a clock etc. 4 decisive weight or amount (balance of opinion). 5 a agreement or difference between credits and debits in an account. B amount still owing or outstanding (will pay the balance). C amount left over. 6 a art harmony and proportion. B <strong class='mus'> mus. </strong> Relative volume of sources of sound. 7 (the balance) zodiacal sign or constellation libra. —v. (-cing) 1 bring into, keep, or be in equilibrium (balanced a book on her head; balanced on one leg). 2 (often foll. By with, against) offset or compare (one thing) with another (balance the pros and cons). 3 counteract, equal, or neutralize the weight or importance of. 4 (usu. As balanced adj.) Make well-proportioned and harmonious (balanced diet; balanced opinion). 5 a compare and esp</p>",
      },
    ],
  },
  {
    word: "Balance of payments",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balance of payments <strong class='noun'> n. </strong> Difference in value between payments into and out of a country.</p>",
      },
    ],
  },
  {
    word: "Balance of power",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balance of power <strong class='noun'> n. </strong> 1 situation of roughly equal power among the chief states of the world. 2 power held by a small group when larger groups are of equal strength.</p>",
      },
    ],
  },
  {
    word: "Balance of trade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balance of trade <strong class='noun'> n. </strong> Difference in value between imports and exports.</p>",
      },
    ],
  },
  {
    word: "Balance sheet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balance sheet <strong class='noun'> n. </strong> Statement giving the balance of an account.</p>",
      },
    ],
  },
  {
    word: "Balcony",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balcony <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 usu. Balustraded platform on the outside of a building with access from an upper floor. 2 upper tier of seats in a theatre etc. balconied adj. [italian]</p>",
      },
    ],
  },
  {
    word: "Bald",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bald <strong class='adj'> adj. </strong> 1 lacking some or all hair on the scalp. 2 lacking the usual hair, feathers, leaves, etc. 3 <strong class='colloq'> colloq. </strong> With a worn surface (bald tyre). 4 plain, direct (bald statement, style). balding adj. (in senses 1–3). Baldly <strong class='adv'> adv. </strong> (in sense 4). Baldness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Balderdash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balderdash <strong class='noun'> n. </strong> Nonsense. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bale1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bale1 <strong class='noun'> —n. </strong> Tightly bound bundle of merchandise or hay. —v. (-ling) make up into bales. bale out 1 (also bail out) (of an airman) make an emergency parachute descent. 2 <strong class='var'> var. </strong> Of *bail1 v. 2. [dutch: related to *ball1]</p>",
      },
    ],
  },
  {
    word: "Bale2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bale2 <strong class='var'> var. </strong> Of *bail3.</p>",
      },
    ],
  },
  {
    word: "Baleen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baleen <strong class='noun'> n. </strong> Whalebone. [latin balaena whale]</p>",
      },
    ],
  },
  {
    word: "Baleful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baleful <strong class='adj'> adj. </strong> 1 menacing in look, manner, etc. 2 malignant, destructive. balefully <strong class='adv'> adv. </strong> [archaic bale evil]</p>",
      },
    ],
  },
  {
    word: "Balk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Balk <strong class='var'> var. </strong> Of *baulk.</p>",
      },
    ],
  },
  {
    word: "Balkan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balkan <strong class='adj'> adj. </strong> 1 of the region of se europe bounded by the adriatic, aegean, and black sea. 2 of its peoples or countries. [turkish]</p>",
      },
    ],
  },
  {
    word: "Ball1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ball1 <strong class='noun'> —n. </strong> 1 sphere, esp. For use in a game. 2 a ball-shaped object; material in the shape of a ball (ball of snow, wool). B rounded part of the body (ball of the foot). 3 cannon-ball. 4 single delivery or pass of a ball in cricket, baseball, football, etc. 5 (in pl.) Coarse slang a testicles. B (usu. As int.) Nonsense. C = *balls-up. D courage, ‘guts’. —v. Form into a ball. balls up coarse slang bungle; make a mess of. On the ball <strong class='colloq'> colloq. </strong> Alert. [old norse]</p>",
      },
    ],
  },
  {
    word: "Ball2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ball2 <strong class='noun'> n. </strong> 1 formal social gathering for dancing. 2 slang enjoyable time (esp. Have a ball). [greek ballo throw]</p>",
      },
    ],
  },
  {
    word: "Ballad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballad <strong class='noun'> n. </strong> 1 poem or song narrating a popular story. 2 slow sentimental song. [provençal: related to *ball2]</p>",
      },
    ],
  },
  {
    word: "Balladry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balladry <strong class='noun'> n. </strong> Ballad poetry.</p>",
      },
    ],
  },
  {
    word: "Ball-and-socket joint",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ball-and-socket joint <strong class='noun'> n. </strong> Joint in which a rounded end lies in a concave socket.</p>",
      },
    ],
  },
  {
    word: "Ballast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballast <strong class='noun'> —n. </strong> 1 heavy material stabilizing a ship, the car of a balloon, etc. 2 coarse stone etc. As the bed of a railway track or road. 3 mixture of coarse and fine aggregate for making concrete. —v. Provide with ballast. [low german or scandinavian]</p>",
      },
    ],
  },
  {
    word: "Ball-bearing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ball-bearing <strong class='noun'> n. </strong> 1 bearing in which the two halves are separated by a ring of small balls. 2 one of these balls.</p>",
      },
    ],
  },
  {
    word: "Ballboy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballboy <strong class='noun'> n. </strong> (fem. Ballgirl) (in tennis) boy or girl who retrieves balls.</p>",
      },
    ],
  },
  {
    word: "Ballcock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballcock <strong class='noun'> n. </strong> Floating ball on a hinged arm controlling the water level in a cistern.</p>",
      },
    ],
  },
  {
    word: "Ballerina",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballerina <strong class='noun'> n. </strong> Female ballet-dancer. [italian: related to *ball2]</p>",
      },
    ],
  },
  {
    word: "Ballet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballet <strong class='noun'> n. </strong> 1 dramatic or representational style of dancing to music. 2 particular piece or performance of ballet. balletic adj. [french: related to *ball2]</p>",
      },
    ],
  },
  {
    word: "Ballet-dancer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballet-dancer <strong class='noun'> n. </strong> Dancer of ballet.</p>",
      },
    ],
  },
  {
    word: "Ball game",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ball game <strong class='noun'> n. </strong> 1 a game played with a ball. B us baseball game. 2 esp. Us <strong class='colloq'> colloq. </strong> Affair; matter (a whole new ball game).</p>",
      },
    ],
  },
  {
    word: "Ballista",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballista <strong class='noun'> n. </strong> (pl. -stae) (in ancient warfare) catapult for hurling large stones etc. [latin from greek ballo throw]</p>",
      },
    ],
  },
  {
    word: "Ballistic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballistic <strong class='adj'> adj. </strong> Of projectiles.</p>",
      },
    ],
  },
  {
    word: "Ballistic missile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballistic missile <strong class='noun'> n. </strong> Missile that is powered and guided but falls by gravity.</p>",
      },
    ],
  },
  {
    word: "Ballistics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballistics <strong class='n_pl'> n.pl. </strong> (usu. Treated as sing.) Science of projectiles and firearms.</p>",
      },
    ],
  },
  {
    word: "Ballocking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballocking <strong class='var'> var. </strong> Of *bollocking.</p>",
      },
    ],
  },
  {
    word: "Ballocks",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballocks <strong class='var'> var. </strong> Of *bollocks.</p>",
      },
    ],
  },
  {
    word: "Balloon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balloon <strong class='noun'> —n. </strong> 1 small inflatable rubber toy or decoration. 2 large usu. Round inflatable flying bag, often carrying a basket for passengers. 3 <strong class='colloq'> colloq. </strong> Balloon shape enclosing dialogue etc. In a comic strip or cartoon. —v. 1 (cause to) swell out like a balloon. 2 travel by balloon. balloonist <strong class='noun'> n. </strong> [french or italian, = large ball]</p>",
      },
    ],
  },
  {
    word: "Ballot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballot <strong class='noun'> —n. </strong> 1 occasion or system of voting, in writing and usu. Secret. 2 total of such votes. 3 paper etc. Used in voting. —v. (-t-) 1 (usu. Foll. By for) a hold a ballot; give a vote. B draw lots for precedence etc. 2 take a ballot of (balloted the members). [italian ballotta: related to *balloon]</p>",
      },
    ],
  },
  {
    word: "Ballot-box",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballot-box <strong class='noun'> n. </strong> Sealed box for completed ballot-papers.</p>",
      },
    ],
  },
  {
    word: "Ballot-paper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballot-paper <strong class='noun'> n. </strong> = *ballot <strong class='noun'> n. </strong> 3.</p>",
      },
    ],
  },
  {
    word: "Ballpark",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballpark <strong class='noun'> n. </strong> Us 1 baseball ground. 2 <strong class='colloq'> colloq. </strong> Sphere of activity, etc. 3 (attrib.) Colloq. Approximate. in the right ballpark <strong class='colloq'> colloq. </strong> Approximately correct.</p>",
      },
    ],
  },
  {
    word: "Ball-point",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ball-point <strong class='noun'> n. </strong> (in full ball-point pen) pen with a tiny ball as its writing point.</p>",
      },
    ],
  },
  {
    word: "Ballroom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballroom <strong class='noun'> n. </strong> Large room for dancing.</p>",
      },
    ],
  },
  {
    word: "Ballroom dancing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballroom dancing <strong class='noun'> n. </strong> Formal social dancing.</p>",
      },
    ],
  },
  {
    word: "Balls-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balls-up <strong class='noun'> n. </strong> Coarse slang bungle, mess.</p>",
      },
    ],
  },
  {
    word: "Bally",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bally <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Slang mild form of bloody (see *bloody adj. 3). [alteration of *bloody]</p>",
      },
    ],
  },
  {
    word: "Ballyhoo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ballyhoo <strong class='noun'> n. </strong> 1 loud noise or fuss. 2 noisy publicity. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Balm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balm <strong class='noun'> n. </strong> 1 aromatic ointment. 2 fragrant oil or resin exuded from certain trees and plants. 3 thing that heals or soothes. 4 aromatic herb. [latin: related to *balsam]</p>",
      },
    ],
  },
  {
    word: "Balmy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balmy <strong class='adj'> adj. </strong> (-ier, -iest) 1 mild and fragrant; soothing. 2 slang = *barmy. balmily <strong class='adv'> adv. </strong> Balminess n.</p>",
      },
    ],
  },
  {
    word: "Baloney",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baloney <strong class='var'> var. </strong> Of *boloney.</p>",
      },
    ],
  },
  {
    word: "Balsa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balsa <strong class='noun'> n. </strong> 1 (in full balsa-wood) tough lightweight wood used for making models etc. 2 tropical american tree yielding it. [spanish, = raft]</p>",
      },
    ],
  },
  {
    word: "Balsam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balsam <strong class='noun'> n. </strong> 1 resin exuded from various trees and shrubs. 2 ointment, esp. Containing oil or turpentine. 3 tree or shrub yielding balsam. 4 any of several flowering plants. balsamic adj. [latin balsamum]</p>",
      },
    ],
  },
  {
    word: "Baluster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baluster <strong class='noun'> n. </strong> Short post or pillar supporting a rail. [greek balaustion wild-pomegranate flower] <strong class='usage'> Usage:- </strong>baluster is often confused with banister. A baluster is usually part of a balustrade whereas a banister supports a stair handrail.</p>",
      },
    ],
  },
  {
    word: "Balustrade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Balustrade <strong class='noun'> n. </strong> Railing supported by balusters, esp. On a balcony. balustraded adj.</p>",
      },
    ],
  },
  {
    word: "Bamboo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bamboo <strong class='noun'> n. </strong> 1 tropical giant woody grass. 2 its stem, used for canes, furniture, etc. [dutch from malay]</p>",
      },
    ],
  },
  {
    word: "Bamboo shoot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bamboo shoot <strong class='noun'> n. </strong> Young shoot of bamboo, eaten as a vegetable.</p>",
      },
    ],
  },
  {
    word: "Bamboozle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bamboozle <strong class='verb'> v. </strong> (-ling) <strong class='colloq'> colloq. </strong> Cheat; mystify. bamboozlement <strong class='noun'> n. </strong> [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Ban",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ban <strong class='noun'> —v. </strong> (-nn-) forbid, prohibit, esp. Formally. <strong class='noun'> —n. </strong> Formal prohibition (ban on smoking). [old english, = summon]</p>",
      },
    ],
  },
  {
    word: "Banal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banal <strong class='adj'> adj. </strong> Trite, commonplace. banality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Banally <strong class='adv'> adv. </strong> [french, related to *ban: originally = compulsory, hence = common]</p>",
      },
    ],
  },
  {
    word: "Banana",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banana <strong class='noun'> n. </strong> 1 long curved soft fruit with a yellow skin. 2 treelike plant bearing it. go bananas slang go mad. [portuguese or spanish, from an african name]</p>",
      },
    ],
  },
  {
    word: "Banana republic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banana republic <strong class='noun'> n. </strong> Derog. Small state, esp. In central america, dependent on foreign capital.</p>",
      },
    ],
  },
  {
    word: "Band",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Band <strong class='noun'> —n. </strong> 1 flat, thin strip or loop of paper, metal, cloth, etc., put round something esp. To hold or decorate it. 2 a strip of material on a garment. B stripe. 3 group of esp. Non-classical musicians. 4 organized group of criminals etc. 5 range of frequencies, wavelengths, or values. 6 belt connecting wheels or pulleys. —v. 1 (usu. Foll. By together) unite. 2 put a band on. 3 mark with stripes. [old norse (related to *bind) and french]</p>",
      },
    ],
  },
  {
    word: "Bandage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandage <strong class='noun'> —n. </strong> Strip of material used to bind a wound etc. —v. (-ging) bind with a bandage. [french: related to *band]</p>",
      },
    ],
  },
  {
    word: "Bandanna",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandanna <strong class='noun'> n. </strong> Large patterned handkerchief or neckerchief. [portuguese from hindi]</p>",
      },
    ],
  },
  {
    word: "B. & b.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>B. & b. <strong class='abbr'> Abbr. </strong> Bed and breakfast.</p>",
      },
    ],
  },
  {
    word: "Bandbox",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bandbox <strong class='noun'> n. </strong> Hatbox.</p>",
      },
    ],
  },
  {
    word: "Bandeau",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandeau <strong class='noun'> n. </strong> (pl. -x) narrow headband. [french]</p>",
      },
    ],
  },
  {
    word: "Banderole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banderole <strong class='noun'> n. </strong> 1 long narrow flag with a cleft end. 2 ribbon-like inscribed scroll. [italian: related to *banner]</p>",
      },
    ],
  },
  {
    word: "Bandicoot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandicoot <strong class='noun'> n. </strong> 1 catlike australian marsupial. 2 (in full bandicoot rat) destructive rat in india. [telugu, = pig-rat]</p>",
      },
    ],
  },
  {
    word: "Bandit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandit <strong class='noun'> n. </strong> Robber or outlaw, esp. One attacking travellers etc. banditry <strong class='noun'> n. </strong> [italian]</p>",
      },
    ],
  },
  {
    word: "Bandmaster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandmaster <strong class='noun'> n. </strong> Conductor of a band.</p>",
      },
    ],
  },
  {
    word: "Bandog",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandog <strong class='noun'> n. </strong> Fighting-dog bred for its strength and ferocity. [from *band, *dog]</p>",
      },
    ],
  },
  {
    word: "Bandolier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandolier <strong class='noun'> n. </strong> (also bandoleer) shoulder belt with loops or pockets for cartridges. [dutch or french]</p>",
      },
    ],
  },
  {
    word: "Band-saw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Band-saw <strong class='noun'> n. </strong> Mechanical saw with a blade formed by an endless toothed band.</p>",
      },
    ],
  },
  {
    word: "Bandsman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandsman <strong class='noun'> n. </strong> Player in a band.</p>",
      },
    ],
  },
  {
    word: "Bandstand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandstand <strong class='noun'> n. </strong> Outdoor platform for musicians.</p>",
      },
    ],
  },
  {
    word: "Bandwagon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandwagon <strong class='noun'> n. </strong> climb (or jump) on the bandwagon join a popular or successful cause etc.</p>",
      },
    ],
  },
  {
    word: "Bandwidth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandwidth <strong class='noun'> n. </strong> Range of frequencies within a given band.</p>",
      },
    ],
  },
  {
    word: "Bandy1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandy1 <strong class='adj'> adj. </strong> (-ier, -iest) 1 (of the legs) curved so as to be wide apart at the knees. 2 (also bandy-legged) having bandy legs. [perhaps from obsolete bandy curved stick]</p>",
      },
    ],
  },
  {
    word: "Bandy2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bandy2 <strong class='verb'> v. </strong> (-ies, -ied) 1 (often foll. By about) a pass (a story, rumour, etc.) To and fro. B discuss disparagingly (bandied her name about). 2 (often foll. By with) exchange (blows, insults, etc.). [perhaps from french]</p>",
      },
    ],
  },
  {
    word: "Bane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bane <strong class='noun'> n. </strong> 1 cause of ruin or trouble. 2 poet. Ruin. 3 archaic (except in <strong class='n_pl'> comb. </strong> ) Poison (ratsbane). baneful adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Bang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bang <strong class='noun'> —n. </strong> 1 loud short sound. 2 sharp blow. 3 coarse slang act of sexual intercourse. 4 us fringe cut straight across the forehead. —v. 1 strike or shut noisily (banged the door). 2 (cause to) make a bang. 3 coarse slang have sexual intercourse (with). <strong class='adv'> —adv. </strong> 1 with a bang. 2 <strong class='colloq'> colloq. </strong> Exactly (bang in the middle). bang on <strong class='colloq'> colloq. </strong> Exactly right. Go bang 1 shut noisily. 2 explode. 3 (as bang goes etc.) Colloq. Be suddenly lost (bang go my hopes). [imitative]</p>",
      },
    ],
  },
  {
    word: "Banger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banger <strong class='noun'> n. </strong> 1 slang sausage. 2 slang noisy old car. 3 firework designed to go bang.</p>",
      },
    ],
  },
  {
    word: "Bangle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bangle <strong class='noun'> n. </strong> Rigid bracelet or anklet. [hindi bangri]</p>",
      },
    ],
  },
  {
    word: "Banian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banian <strong class='var'> var. </strong> Of *banyan.</p>",
      },
    ],
  },
  {
    word: "Banish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banish <strong class='verb'> v. </strong> 1 condemn to exile. 2 dismiss (esp. From one's mind). banishment <strong class='noun'> n. </strong> [germanic: related to *ban]</p>",
      },
    ],
  },
  {
    word: "Banister",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banister <strong class='noun'> n. </strong> (also bannister) (usu. In pl.) Uprights and handrail beside a staircase. [corruption of *baluster] <strong class='usage'> Usage:- </strong>see note at baluster.</p>",
      },
    ],
  },
  {
    word: "Banjo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banjo <strong class='noun'> n. </strong> (pl. -s or -es) guitar-like stringed instrument with a circular body. banjoist <strong class='noun'> n. </strong> [us southern corruption of bandore from greek pandoura lute]</p>",
      },
    ],
  },
  {
    word: "Bank1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bank1 <strong class='noun'> —n. </strong> 1 sloping ground beside a river. 2 raised area, esp. In the sea; slope. 3 mass of cloud, fog, snow, etc. —v. 1 (often foll. By up) heap or rise into banks. 2 pack (a fire) tightly for slow burning. 3 a (of a vehicle, aircraft, etc.) Round a curve with one side higher than the other. B cause to do this. [old norse: related to *bench]</p>",
      },
    ],
  },
  {
    word: "Bank2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bank2 <strong class='noun'> —n. </strong> 1 establishment for depositing, withdrawing, and borrowing money. 2 kitty in some gambling games. 3 storage place (blood bank). —v. 1 deposit (money etc.) In a bank. 2 (often foll. By at, with) keep money (at a bank). bank on <strong class='colloq'> colloq. </strong> Rely on (i'm banking on you). [french banque or italian banca: related to *bank1]</p>",
      },
    ],
  },
  {
    word: "Banknote",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banknote <strong class='noun'> n. </strong> Piece of paper money.</p>",
      },
    ],
  },
  {
    word: "Bankrupt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bankrupt <strong class='adj'> —adj.</strong> 1 legally declared insolvent. 2 (often foll. By of) exhausted or drained (of emotion etc.). <strong class='noun'> —n. </strong> Insolvent person, esp. One whose assets are used to repay creditors. —v. Make bankrupt. bankruptcy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. [italian banca rotta broken bench: related to *bank2]</p>",
      },
    ],
  },
  {
    word: "Banksia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banksia <strong class='noun'> n. </strong> Australian evergreen flowering shrub. [banks, name of a naturalist]</p>",
      },
    ],
  },
  {
    word: "Banner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banner <strong class='noun'> n. </strong> 1 large sign bearing a slogan or design, esp. In a demonstration or procession; flag. 2 slogan, esp. Political. [latin bandum standard]</p>",
      },
    ],
  },
  {
    word: "Banner headline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banner headline <strong class='noun'> n. </strong> Large, esp. Front-page, newspaper headline.</p>",
      },
    ],
  },
  {
    word: "Bannister",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bannister <strong class='var'> var. </strong> Of *banister.</p>",
      },
    ],
  },
  {
    word: "Bannock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bannock <strong class='noun'> n. </strong> Scot. & n.engl. Round flat loaf, usu. Unleavened. [old english]</p>",
      },
    ],
  },
  {
    word: "Banns",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banns <strong class='n_pl'> n.pl. </strong> Notice announcing an intended marriage, read out in a parish church. [pl. Of *ban]</p>",
      },
    ],
  },
  {
    word: "Banquet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banquet <strong class='noun'> —n. </strong> Sumptuous, esp. Formal, feast or dinner. —v. (-t-) attend, or entertain with, a banquet; feast. [french diminutive of banc bench]</p>",
      },
    ],
  },
  {
    word: "Banquette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banquette <strong class='noun'> n. </strong> Upholstered bench, esp. In a restaurant or bar. [french from italian]</p>",
      },
    ],
  },
  {
    word: "Banshee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banshee <strong class='noun'> n. </strong> Ir. & scot. Wailing female spirit warning of death in a house. [irish, = fairy woman]</p>",
      },
    ],
  },
  {
    word: "Bantam",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bantam <strong class='noun'> n. </strong> 1 a kind of small domestic fowl. 2 small but aggressive person. [apparently from bantan in java]</p>",
      },
    ],
  },
  {
    word: "Bantamweight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bantamweight <strong class='noun'> n. </strong> 1 weight in certain sports between flyweight and featherweight, in amateur boxing 51–4 kg. 2 sportsman of this weight.</p>",
      },
    ],
  },
  {
    word: "Banter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banter <strong class='noun'> —n. </strong> Good-humoured teasing. —v. 1 tease. 2 exchange banter. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bantu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bantu <strong class='noun'> —n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> 1 often offens. Member of a large group of central and southern african blacks. 2 group of languages spoken by them. <strong class='adj'> —adj.</strong> Of these peoples or languages. [bantu, = people]</p>",
      },
    ],
  },
  {
    word: "Bantustan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bantustan <strong class='noun'> n. </strong> S.afr. Often offens. = *homeland 2.</p>",
      },
    ],
  },
  {
    word: "Banyan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Banyan <strong class='noun'> n. </strong> (also banian) indian fig tree with self-rooting branches. [portuguese from sanskrit, = trader]</p>",
      },
    ],
  },
  {
    word: "Baobab",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baobab <strong class='noun'> n. </strong> African tree with a massive trunk and large pulpy fruit. [probably african dial.]</p>",
      },
    ],
  },
  {
    word: "Bap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bap <strong class='noun'> n. </strong> Soft flattish bread roll. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Baptism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baptism <strong class='noun'> n. </strong> Symbolic admission to the christian church, with water and usu. Name-giving. baptismal adj. [greek baptizo baptize]</p>",
      },
    ],
  },
  {
    word: "Baptism of fire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baptism of fire <strong class='noun'> n. </strong> 1 initiation into battle. 2 painful initiation into an activity.</p>",
      },
    ],
  },
  {
    word: "Baptist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baptist <strong class='noun'> n. </strong> 1 person who baptizes, esp. John the baptist. 2 (baptist) christian advocating baptism by total immersion.</p>",
      },
    ],
  },
  {
    word: "Baptistery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baptistery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a part of a church used for baptism. B hist. Separate building used for baptism. 2 (in a baptist chapel) receptacle used for immersion.</p>",
      },
    ],
  },
  {
    word: "Baptize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baptize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 administer baptism to. 2 give a name or nickname to.</p>",
      },
    ],
  },
  {
    word: "Bar1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bar1 <strong class='noun'> —n. </strong> 1 long piece of rigid material, esp. Used to confine or obstruct. 2 a something of similar form (bar of soap; bar of chocolate). B band of colour or light. C heating element of an electric fire. D metal strip below the clasp of a medal, awarded as an extra distinction. E heraldry narrow horizontal stripe across a shield. 3 a counter for serving alcohol etc. On. B room or building containing it. C small shop or stall serving refreshments (snack bar). D counter for a special service (heel bar). 4 a barrier. B restriction (colour bar; bar to promotion). 5 prisoner's enclosure in a lawcourt. 6 any of the sections into which a piece of music is divided by vertical lines. 7 (the bar) law a barristers collectively. B profession of barrister. —v. <strong class='adj'> (-rr-) </strong> 1 a fasten with a bar or bars. B (usu. Foll. By in, out) shut or keep in or out. 2 obstruct, prevent. 3 (usu. Foll. By from) prohibit, exclude. 4 mark with stripes. <strong class='prep'> —prep. </strong> Except. be called to the bar be admitted as barrister. Behind bars in prison. [french]</p>",
      },
    ],
  },
  {
    word: "Bar2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bar2 <strong class='noun'> n. </strong> Esp. Meteorol. Unit of pressure, 105 newtons per square metre, approx. One atmosphere. [greek baros weight]</p>",
      },
    ],
  },
  {
    word: "Barathea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barathea <strong class='noun'> n. </strong> Fine wool cloth. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Barb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barb <strong class='noun'> —n. </strong> 1 secondary backward-facing projection from an arrow, fish-hook, etc. 2 hurtful remark. 3 fleshy filament at the mouth of some fish. —v. 1 fit with a barb. 2 (as barbed adj.) (of a remark etc.) Deliberately hurtful. [latin barba beard]</p>",
      },
    ],
  },
  {
    word: "Barbarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbarian <strong class='noun'> —n. </strong> 1 uncultured or brutish person. 2 member of a primitive tribe etc. <strong class='adj'> —adj.</strong> 1 rough and uncultured. 2 uncivilized. [greek barbaros foreign]</p>",
      },
    ],
  },
  {
    word: "Barbaric",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbaric <strong class='adj'> adj. </strong> 1 uncultured; brutal, cruel. 2 primitive.</p>",
      },
    ],
  },
  {
    word: "Barbarism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbarism <strong class='noun'> n. </strong> 1 barbaric state or act. 2 non-standard word or expression.</p>",
      },
    ],
  },
  {
    word: "Barbarity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbarity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 savage cruelty. 2 brutal act.</p>",
      },
    ],
  },
  {
    word: "Barbarous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbarous <strong class='adj'> adj. </strong> = *barbaric 1.</p>",
      },
    ],
  },
  {
    word: "Barbecue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbecue <strong class='noun'> —n. </strong> 1 a meal cooked over charcoal etc. Out of doors. B party for this. 2 grill etc. Used for this. —v. (-ues, -ued, -uing) cook on a barbecue. [spanish from haitian]</p>",
      },
    ],
  },
  {
    word: "Barbed wire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbed wire <strong class='noun'> n. </strong> Wire with interwoven sharp spikes, used in fences and barriers.</p>",
      },
    ],
  },
  {
    word: "Barbel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbel <strong class='noun'> n. </strong> 1 freshwater fish with barbs. 2 = *barb <strong class='noun'> n. </strong> 3. [latin: related to *barb]</p>",
      },
    ],
  },
  {
    word: "Barbell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbell <strong class='noun'> n. </strong> Iron bar with removable weights at each end, used for weightlifting.</p>",
      },
    ],
  },
  {
    word: "Barber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barber <strong class='noun'> n. </strong> Person who cuts men's hair etc. By profession. [medieval latin barba beard]</p>",
      },
    ],
  },
  {
    word: "Barberry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barberry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 shrub with yellow flowers and red berries. 2 its berry. [french berberis]</p>",
      },
    ],
  },
  {
    word: "Barber-shop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barber-shop <strong class='noun'> n. </strong> Colloq. Close harmony singing for four male voices.</p>",
      },
    ],
  },
  {
    word: "Barber's pole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barber's pole <strong class='noun'> n. </strong> Pole with spiral red and white stripes as a barber's sign.</p>",
      },
    ],
  },
  {
    word: "Barbican",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbican <strong class='noun'> n. </strong> Outer defence, esp. A double tower above a gate or drawbridge. [french]</p>",
      },
    ],
  },
  {
    word: "Barbie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbie <strong class='noun'> n. </strong> Austral. Slang barbecue. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Bar billiards",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bar billiards <strong class='n_pl'> n.pl. </strong> Form of billiards with holes in the table.</p>",
      },
    ],
  },
  {
    word: "Barbiturate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbiturate <strong class='noun'> n. </strong> Soporific or sedative drug from barbituric acid. [german, from the name barbara]</p>",
      },
    ],
  },
  {
    word: "Barbituric acid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbituric acid <strong class='noun'> n. </strong> Organic acid from which barbiturates are derived.</p>",
      },
    ],
  },
  {
    word: "Barbour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barbour <strong class='noun'> n. </strong> Propr. Type of green waxed jacket. [barbour, name of a draper]</p>",
      },
    ],
  },
  {
    word: "Barcarole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barcarole <strong class='noun'> n. </strong> 1 gondoliers' song. 2 music imitating this. [italian barca boat]</p>",
      },
    ],
  },
  {
    word: "Bar code",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bar code <strong class='noun'> n. </strong> Machine-readable striped code on packaging etc.</p>",
      },
    ],
  },
  {
    word: "Bard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bard <strong class='noun'> n. </strong> 1 poet. Poet. 2 a hist. Celtic minstrel. B prizewinner at an eisteddfod. bardic adj. [celtic]</p>",
      },
    ],
  },
  {
    word: "Bare",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bare <strong class='adj'> —adj.</strong> 1 unclothed or uncovered. 2 leafless; unfurnished; empty. 3 plain, unadorned (the bare truth; bare facts). 4 (attrib.) Scanty, just sufficient (a bare majority; bare necessities). —v. <strong class='adj'> (-ring) </strong> uncover, reveal (bared his teeth; bared his soul). [old english]</p>",
      },
    ],
  },
  {
    word: "Bareback",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bareback <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Without a saddle.</p>",
      },
    ],
  },
  {
    word: "Barefaced",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barefaced <strong class='adj'> adj. </strong> Shameless, impudent.</p>",
      },
    ],
  },
  {
    word: "Barefoot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barefoot <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> (also barefooted) wearing nothing on the feet.</p>",
      },
    ],
  },
  {
    word: "Bareheaded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bareheaded <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Wearing nothing on the head.</p>",
      },
    ],
  },
  {
    word: "Barely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barely <strong class='adv'> adv. </strong> 1 scarcely (barely escaped). 2 scantily (barely furnished).</p>",
      },
    ],
  },
  {
    word: "Bargain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bargain <strong class='noun'> —n. </strong> 1 a agreement on the terms of a sale etc. B this from the buyer's viewpoint (a bad bargain). 2 cheap thing. —v. (often foll. By with, for) discuss the terms of a sale etc. (bargained with me; bargain for the table). bargain for (or <strong class='colloq'> colloq. </strong> On) be prepared for; expect. Bargain on rely on. Into the bargain moreover. [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Barge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barge <strong class='noun'> —n. </strong> 1 long flat-bottomed cargo boat on a canal or river. 2 long ornamental pleasure boat. —v. (-ging) 1 (foll. By in, into) a intrude rudely or awkwardly (barged in on him). B collide with (barged into her). 2 (often foll. By around) move clumsily about. [french: related to *barque]</p>",
      },
    ],
  },
  {
    word: "Bargeboard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bargeboard <strong class='noun'> n. </strong> Board fixed to the gable-end of a roof to hide the ends of the roof timbers. [perhaps from medieval latin bargus gallows]</p>",
      },
    ],
  },
  {
    word: "Bargee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bargee <strong class='noun'> n. </strong> Person sailing a barge.</p>",
      },
    ],
  },
  {
    word: "Bargepole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bargepole <strong class='noun'> n. </strong> would not touch with a bargepole refuse to be associated or concerned with.</p>",
      },
    ],
  },
  {
    word: "Baritone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baritone <strong class='noun'> n. </strong> 1 a second-lowest adult male singing voice. B singer with this voice. 2 instrument pitched second-lowest in its family. [greek barus heavy, tonos tone]</p>",
      },
    ],
  },
  {
    word: "Barium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barium <strong class='noun'> n. </strong> White soft metallic element. [from *baryta]</p>",
      },
    ],
  },
  {
    word: "Barium meal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barium meal <strong class='noun'> n. </strong> Mixture swallowed to reveal the abdomen in x-rays.</p>",
      },
    ],
  },
  {
    word: "Bark1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bark1 <strong class='noun'> —n. </strong> 1 sharp explosive cry of a dog, fox, etc. 2 sound like this. —v. 1 (of a dog etc.) Give a bark. 2 speak or utter sharply or brusquely. 3 <strong class='colloq'> colloq. </strong> Cough harshly. bark up the wrong tree make false assumptions. [old english]</p>",
      },
    ],
  },
  {
    word: "Bark2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bark2 <strong class='noun'> —n. </strong> Tough outer skin of tree-trunks, branches, etc. —v. 1 graze (one's shin etc.). 2 strip bark from. [scandinavian]</p>",
      },
    ],
  },
  {
    word: "Barker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barker <strong class='noun'> n. </strong> Tout at an auction, sideshow, etc. [from *bark1]</p>",
      },
    ],
  },
  {
    word: "Barley",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barley <strong class='noun'> n. </strong> 1 cereal used as food and in spirits. 2 (also barleycorn) its grain. [old english]</p>",
      },
    ],
  },
  {
    word: "Barley sugar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barley sugar <strong class='noun'> n. </strong> Sweet made from sugar, usu. In twisted sticks.</p>",
      },
    ],
  },
  {
    word: "Barley water",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barley water <strong class='noun'> n. </strong> Drink made from a boiled barley mixture.</p>",
      },
    ],
  },
  {
    word: "Barm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barm <strong class='noun'> n. </strong> Froth on fermenting malt liquor. [old english]</p>",
      },
    ],
  },
  {
    word: "Barmaid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barmaid <strong class='noun'> n. </strong> Woman serving in a pub etc.</p>",
      },
    ],
  },
  {
    word: "Barman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barman <strong class='noun'> n. </strong> Man serving in a pub etc.</p>",
      },
    ],
  },
  {
    word: "Bar mitzvah",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bar mitzvah <strong class='noun'> n. </strong> 1 religious initiation ceremony of a jewish boy at 13. 2 boy undergoing this. [hebrew, = son of the commandment]</p>",
      },
    ],
  },
  {
    word: "Barmy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barmy <strong class='adj'> adj. </strong> (-ier, -iest) slang crazy, stupid. [from *barm: earlier, = frothy]</p>",
      },
    ],
  },
  {
    word: "Barn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barn <strong class='noun'> n. </strong> Large farm building for storing grain etc. [old english, = barley house]</p>",
      },
    ],
  },
  {
    word: "Barnacle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barnacle <strong class='noun'> n. </strong> 1 marine crustacean clinging to rocks, ships' bottoms, etc. 2 tenacious attendant or follower. [french or medieval latin]</p>",
      },
    ],
  },
  {
    word: "Barnacle goose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barnacle goose <strong class='noun'> n. </strong> Arctic goose.</p>",
      },
    ],
  },
  {
    word: "Barn dance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barn dance <strong class='noun'> n. </strong> 1 informal gathering for country dancing. 2 a kind of country dance.</p>",
      },
    ],
  },
  {
    word: "Barney",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barney <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='colloq'> colloq. </strong> Noisy quarrel. [perhaps dial.]</p>",
      },
    ],
  },
  {
    word: "Barn-owl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barn-owl <strong class='noun'> n. </strong> A kind of owl frequenting barns.</p>",
      },
    ],
  },
  {
    word: "Barnstorm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barnstorm <strong class='verb'> v. </strong> Tour rural areas as an actor or political campaigner. barnstormer n.</p>",
      },
    ],
  },
  {
    word: "Barnyard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barnyard <strong class='noun'> n. </strong> Area around a barn.</p>",
      },
    ],
  },
  {
    word: "Barograph",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barograph <strong class='noun'> n. </strong> Barometer equipped to record its readings. [greek baros weight]</p>",
      },
    ],
  },
  {
    word: "Barometer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barometer <strong class='noun'> n. </strong> 1 instrument measuring atmospheric pressure, used in meteorology. 2 anything which reflects change. barometric adj. [related to *barograph]</p>",
      },
    ],
  },
  {
    word: "Baron",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baron <strong class='noun'> n. </strong> 1 member of the lowest order of the british or foreign nobility. 2 powerful businessman, entrepreneur, etc. 3 hist. Person holding lands from the sovereign. baronial adj. [medieval latin, = man]</p>",
      },
    ],
  },
  {
    word: "Baroness",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baroness <strong class='noun'> n. </strong> 1 woman holding the rank of baron. 2 baron's wife or widow.</p>",
      },
    ],
  },
  {
    word: "Baronet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baronet <strong class='noun'> n. </strong> Member of the lowest hereditary titled british order. baronetcy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>.</p>",
      },
    ],
  },
  {
    word: "Baron of beef",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baron of beef <strong class='noun'> n. </strong> Double sirloin.</p>",
      },
    ],
  },
  {
    word: "Barony",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barony <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> domain or rank of a baron.</p>",
      },
    ],
  },
  {
    word: "Baroque",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baroque <strong class='adj'> —adj.</strong> 1 highly ornate and extravagant in style, esp. Of european art etc. Of the 17th and 18th c. 2 of this period. <strong class='noun'> —n. </strong> Baroque style or art. [portuguese, originally = misshapen pearl]</p>",
      },
    ],
  },
  {
    word: "Bar person",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bar person <strong class='noun'> n. </strong> Barmaid or barman.</p>",
      },
    ],
  },
  {
    word: "Barque",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barque <strong class='noun'> n. </strong> 1 sailing-ship with the rear mast fore-and-aft rigged and other masts square-rigged. 2 poet. Boat. [provençal from latin barca]</p>",
      },
    ],
  },
  {
    word: "Barrack1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrack1 <strong class='noun'> —n. </strong> (usu. In pl., often treated as sing.) 1 housing for soldiers. 2 large bleak building. —v. Lodge (soldiers etc.) In barracks. [italian or spanish]</p>",
      },
    ],
  },
  {
    word: "Barrack2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrack2 <strong class='verb'> v. </strong> 1 shout or jeer at (players, a speaker, etc.). 2 (foll. By for) cheer for, encourage (a team etc.). [perhaps from australian slang borak banter]</p>",
      },
    ],
  },
  {
    word: "Barracouta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barracouta <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> long slender fish of southern oceans. [var. Of *barracuda]</p>",
      },
    ],
  },
  {
    word: "Barracuda",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barracuda <strong class='noun'> n. </strong> <strong class='pl_same_or_s'> (pl. Same or -s) </strong> large tropical marine fish. [spanish]</p>",
      },
    ],
  },
  {
    word: "Barrage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrage <strong class='noun'> n. </strong> 1 concentrated artillery bombardment. 2 rapid succession of questions or criticisms. 3 artificial barrier in a river etc. [french barrer *bar1]</p>",
      },
    ],
  },
  {
    word: "Barrage balloon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrage balloon <strong class='noun'> n. </strong> Large anchored balloon used as a defence against low-flying aircraft.</p>",
      },
    ],
  },
  {
    word: "Barratry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barratry <strong class='noun'> n. </strong> Fraud or gross negligence by a ship's master or crew. [french barat deceit]</p>",
      },
    ],
  },
  {
    word: "Barre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barre <strong class='noun'> n. </strong> Horizontal bar at waist level, used in dance exercises. [french]</p>",
      },
    ],
  },
  {
    word: "Barré",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barré <strong class='noun'> n. </strong> Method of playing a chord on the guitar etc. With a finger laid across the strings at a particular fret. [french barrer bar]</p>",
      },
    ],
  },
  {
    word: "Barrel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrel <strong class='noun'> —n. </strong> 1 cylindrical usu. Convex container. 2 its contents. 3 measure of capacity (30 to 40 gallons). 4 cylindrical tube forming part of an object, e.g. A gun or a pen. —v.</p>",
      },
    ],
  },
  {
    word: "Barrel-organ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrel-organ <strong class='noun'> n. </strong> Mechanical musical instrument with a rotating pin-studded cylinder.</p>",
      },
    ],
  },
  {
    word: "Barren",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barren <strong class='adj'> adj. </strong> (-er, -est) 1 a unable to bear young. B (of land, a tree, etc.) Unproductive. 2 unprofitable, dull. barrenness <strong class='noun'> n. </strong> [french]</p>",
      },
    ],
  },
  {
    word: "Barricade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barricade <strong class='noun'> —n. </strong> Barrier, esp. Improvised. —v. (-ding) block or defend with this. [french barrique cask]</p>",
      },
    ],
  },
  {
    word: "Barrier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrier <strong class='noun'> n. </strong> 1 fence etc. That bars advance or access. 2 obstacle (class barriers). [romanic: related to *bar1]</p>",
      },
    ],
  },
  {
    word: "Barrier cream",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrier cream <strong class='noun'> n. </strong> Protective skin cream.</p>",
      },
    ],
  },
  {
    word: "Barrier reef",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrier reef <strong class='noun'> n. </strong> Coral reef separated from the shore by a channel.</p>",
      },
    ],
  },
  {
    word: "Barring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barring <strong class='n_pl'> comb. </strong> Except, not including.</p>",
      },
    ],
  },
  {
    word: "Barrister",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrister <strong class='noun'> n. </strong> Advocate entitled to practise in the higher courts. [from *bar1: cf. *minister]</p>",
      },
    ],
  },
  {
    word: "Barrow1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrow1 <strong class='noun'> n. </strong> 1 two-wheeled handcart. 2 = *wheelbarrow. [old english: related to *bear1]</p>",
      },
    ],
  },
  {
    word: "Barrow2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barrow2 <strong class='noun'> n. </strong> Ancient grave-mound. [old english]</p>",
      },
    ],
  },
  {
    word: "Bar sinister",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bar sinister <strong class='noun'> n. </strong> = *bend sinister.</p>",
      },
    ],
  },
  {
    word: "Bartender",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bartender <strong class='noun'> n. </strong> Person serving in a pub etc.</p>",
      },
    ],
  },
  {
    word: "Barter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barter <strong class='noun'> —v. </strong> 1 trade in goods without using money. 2 exchange (goods). <strong class='noun'> —n. </strong> Trade by bartering. [perhaps from french]</p>",
      },
    ],
  },
  {
    word: "Baryon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baryon <strong class='noun'> n. </strong> Heavy elementary particle (i.e. A nucleon or a hyperon). [greek barus heavy]</p>",
      },
    ],
  },
  {
    word: "Baryta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baryta <strong class='noun'> n. </strong> Barium oxide or hydroxide. [from *barytes]</p>",
      },
    ],
  },
  {
    word: "Barytes",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Barytes <strong class='noun'> n. </strong> Mineral form of barium sulphate. [greek barus heavy]</p>",
      },
    ],
  },
  {
    word: "Basal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basal <strong class='adj'> adj. </strong> Of, at, or forming a base.</p>",
      },
    ],
  },
  {
    word: "Basalt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basalt <strong class='noun'> n. </strong> A dark volcanic rock. basaltic adj. [latin basaltes from greek]</p>",
      },
    ],
  },
  {
    word: "Base1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Base1 <strong class='noun'> —n. </strong> 1 a part supporting from beneath or serving as a foundation. B notional support or foundation (power base). 2 principle or starting-point. 3 esp. Mil. Headquarters. 4 main or important ingredient. 5 number in terms of which other numbers or logarithms are expressed. 6 substance capable of combining with an acid to form a salt. 7 baseball etc. Each of the four stations on a pitch. —v. <strong class='sing'>(-sing)</strong> 1 (usu. Foll. By on, upon) found or establish (a theory, hope, etc.). 2 station (troops based in malta). [greek basis stepping]</p>",
      },
    ],
  },
  {
    word: "Base2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Base2 <strong class='adj'> adj. </strong> 1 cowardly, despicable. 2 menial. 3 alloyed (base coin). 4 (of a metal) low in value. [latin bassus]</p>",
      },
    ],
  },
  {
    word: "Baseball",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baseball <strong class='noun'> n. </strong> 1 game played esp. In the us with a circuit of four bases which batsmen must complete. 2 ball used in this.</p>",
      },
    ],
  },
  {
    word: "Baseless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baseless <strong class='adj'> adj. </strong> Unfounded, groundless.</p>",
      },
    ],
  },
  {
    word: "Baseline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baseline <strong class='noun'> n. </strong> 1 line used as a base or starting-point. 2 line marking each end of a tennis-court.</p>",
      },
    ],
  },
  {
    word: "Basement",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basement <strong class='noun'> n. </strong> Floor of a building below ground level.</p>",
      },
    ],
  },
  {
    word: "Base rate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Base rate <strong class='noun'> n. </strong> Interest rate set by the bank of england, used as the basis for other banks' rates.</p>",
      },
    ],
  },
  {
    word: "Bases",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bases <strong class='pl'> pl. </strong> Of *base1, *basis.</p>",
      },
    ],
  },
  {
    word: "Bash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bash <strong class='noun'> —v. </strong> 1 a strike bluntly or heavily. B (often foll. By up) <strong class='colloq'> colloq. </strong> Attack violently. C (often foll. By down, in, etc.) Damage or break by striking forcibly. 2 (foll. By into) collide with. <strong class='noun'> —n. </strong> 1 heavy blow. 2 slang attempt. [imitative]</p>",
      },
    ],
  },
  {
    word: "Bashful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bashful <strong class='adj'> adj. </strong> Shy, diffident. bashfully <strong class='adv'> adv. </strong> [as *abashed]</p>",
      },
    ],
  },
  {
    word: "Basic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basic <strong class='noun'> n. </strong> Computer programming language using familiar english words. [beginner's all-purpose symbolic instruction code]</p>",
      },
    ],
  },
  {
    word: "Basic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basic <strong class='adj'> —adj.</strong> 1 serving as a base; fundamental. 2 a simplest or lowest in level (basic pay, needs). B vulgar (basic humour). <strong class='noun'> —n. </strong> (usu. In pl.) Fundamental facts or principles. basically adv.</p>",
      },
    ],
  },
  {
    word: "Basic slag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basic slag <strong class='noun'> n. </strong> Fertilizer containing phosphates formed as a by-product in steel manufacture.</p>",
      },
    ],
  },
  {
    word: "Basil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basil <strong class='noun'> n. </strong> Aromatic herb used as flavouring. [greek basilikos royal]</p>",
      },
    ],
  },
  {
    word: "Basilica",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basilica <strong class='noun'> n. </strong> 1 ancient roman hall with an apse and colonnades, used as a lawcourt etc. 2 similar building as a christian church. [greek basilike (stoa) royal (portico)]</p>",
      },
    ],
  },
  {
    word: "Basilisk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basilisk <strong class='noun'> n. </strong> 1 mythical reptile with lethal breath and glance. 2 small american crested lizard. [greek, diminutive of basileus king]</p>",
      },
    ],
  },
  {
    word: "Basin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basin <strong class='noun'> n. </strong> 1 round open vessel for holding liquids or preparing food in. 2 = *wash-basin. 3 hollow depression. 4 sheltered mooring area. 5 round valley. 6 area drained by a river. basinful <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong>. [medieval latin ba(s)cinus]</p>",
      },
    ],
  },
  {
    word: "Basis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basis <strong class='noun'> n. </strong> (pl. Bases) 1 foundation or support. 2 main principle or ingredient (on a friendly basis). 3 starting-point for a discussion etc. [greek: related to *base1]</p>",
      },
    ],
  },
  {
    word: "Bask",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bask <strong class='verb'> v. </strong> 1 relax in warmth and light. 2 (foll. By in) revel in (basking in glory). [old norse: related to *bathe]</p>",
      },
    ],
  },
  {
    word: "Basket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basket <strong class='noun'> n. </strong> 1 container made of interwoven cane, reed, wire, etc. 2 amount held by this. 3 the goal in basketball, or a goal scored. 4 econ. Group or range (of currencies). [french]</p>",
      },
    ],
  },
  {
    word: "Basketball",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basketball <strong class='noun'> n. </strong> 1 game in which goals are scored by putting the ball through high nets. 2 ball used in this.</p>",
      },
    ],
  },
  {
    word: "Basketry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basketry <strong class='noun'> n. </strong> 1 art of weaving cane etc. 2 work so produced.</p>",
      },
    ],
  },
  {
    word: "Basket weave",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basket weave <strong class='noun'> n. </strong> Weave like wickerwork.</p>",
      },
    ],
  },
  {
    word: "Basketwork",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basketwork <strong class='noun'> n. </strong> = *basketry.</p>",
      },
    ],
  },
  {
    word: "Basking shark",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basking shark <strong class='noun'> n. </strong> Large shark which lies near the surface of the sea.</p>",
      },
    ],
  },
  {
    word: "Basque",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basque <strong class='noun'> —n. </strong> 1 member of a people of the western pyrenees. 2 their language. <strong class='adj'> —adj.</strong> Of the basques or their language. [latin vasco]</p>",
      },
    ],
  },
  {
    word: "Bas-relief",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bas-relief <strong class='noun'> n. </strong> Sculpture or carving with figures projecting slightly from the background. [french and italian]</p>",
      },
    ],
  },
  {
    word: "Bass1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bass1 <strong class='noun'> —n. </strong> 1 a lowest adult male singing voice. B singer with this voice. 2 instrument pitched lowest in its family. 3 <strong class='colloq'> colloq. </strong> Bass guitar or double-bass. 4 low-frequency output of a radio, record-player, etc. <strong class='adj'> —adj.</strong> 1 lowest in musical pitch. 2 deep-sounding. bassist <strong class='noun'> n. </strong> (in sense 3). [from *base2 altered after italian basso]</p>",
      },
    ],
  },
  {
    word: "Bass2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bass2 <strong class='noun'> n. </strong> (pl. Same or -es) 1 common perch. 2 other spiny-finned fish of the perch family. [old english]</p>",
      },
    ],
  },
  {
    word: "Bass clef",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bass clef <strong class='noun'> n. </strong> Clef placing f below middle c on the second highest line of the staff.</p>",
      },
    ],
  },
  {
    word: "Basset",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basset <strong class='noun'> n. </strong> (in full basset-hound) sturdy hunting-dog with a long body and short legs. [french diminutive of bas low]</p>",
      },
    ],
  },
  {
    word: "Bass guitar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bass guitar <strong class='noun'> n. </strong> Electric guitar tuned as a double-bass.</p>",
      },
    ],
  },
  {
    word: "Bassinet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bassinet <strong class='noun'> n. </strong> Child's wicker cradle, usu. Hooded. [french diminutive of bassin *basin]</p>",
      },
    ],
  },
  {
    word: "Basso",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Basso <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> singer with a bass voice. [italian, = *bass1]</p>",
      },
    ],
  },
  {
    word: "Bassoon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bassoon <strong class='noun'> n. </strong> Bass instrument of the oboe family. bassoonist <strong class='noun'> n. </strong> [italian: related to *bass1]</p>",
      },
    ],
  },
  {
    word: "Bast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bast <strong class='noun'> n. </strong> Fibre from the inner bark of a tree (esp. The lime). [old english]</p>",
      },
    ],
  },
  {
    word: "Bastard  often offens.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bastard often offens. <strong class='noun'> —n. </strong> 1 person born of an unmarried mother. 2 slang a unpleasant or despicable person. B person of a specified kind (poor, lucky, bastard). 3 slang difficult or awkward thing. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> 1 illegitimate by birth. 2 unauthorized, counterfeit, hybrid. bastardy <strong class='noun'> n. </strong> (in sense 1 of n.). [french from medieval latin]</p>",
      },
    ],
  },
  {
    word: "Bastardize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bastardize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 corrupt, debase. 2 declare (a person) illegitimate.</p>",
      },
    ],
  },
  {
    word: "Baste1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baste1 <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 moisten (meat) with fat etc. During cooking. 2 beat, thrash. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Baste2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baste2 <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> sew with large loose stitches, tack. [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Bastinado",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bastinado <strong class='noun'> —n. </strong> Beating with a stick on the soles of the feet. —v. (-es, -ed) punish in this way. [spanish baston stick]</p>",
      },
    ],
  },
  {
    word: "Bastion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bastion <strong class='noun'> n. </strong> 1 projecting part of a fortification. 2 thing regarded as protecting (bastion of freedom). [italian bastire build]</p>",
      },
    ],
  },
  {
    word: "Bat1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bat1 <strong class='noun'> —n. </strong> 1 implement with a handle, used for hitting balls in games. 2 turn with this. 3 batsman. —v. <strong class='adj'> (-tt-) </strong> 1 hit with or as with a bat. 2 take a turn at batting. off one's own bat unprompted, unaided. [old english from french]</p>",
      },
    ],
  },
  {
    word: "Bat2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bat2 <strong class='noun'> n. </strong> Mouselike nocturnal flying mammal. [scandinavian]</p>",
      },
    ],
  },
  {
    word: "Bat3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bat3 <strong class='verb'> v. </strong> <strong class='adj'> (-tt-) </strong> not (or never) bat an eyelid <strong class='colloq'> colloq. </strong> Show no reaction or emotion. [var. Of obsolete bate flutter]</p>",
      },
    ],
  },
  {
    word: "Batch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batch <strong class='noun'> —n. </strong> 1 group of things or persons considered or dealt with together; instalment. 2 loaves produced at one baking. 3 computing group of records processed as one unit. —v. Arrange or deal with in batches. [related to *bake]</p>",
      },
    ],
  },
  {
    word: "Bated",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bated <strong class='adj'> adj. </strong> with bated breath very anxiously. [as *abate]</p>",
      },
    ],
  },
  {
    word: "Bath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bath <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 a (usu. Plumbed-in) container for sitting in and washing the body. B its contents. 2 act of washing in it (have a bath). 3 (usu. In pl.) Public building with baths or a swimming-pool. 4 a vessel containing liquid for immersing something, e.g. A film for developing. B its contents. —v. 1 wash (esp. A baby) in a bath. 2 take a bath. [old english]</p>",
      },
    ],
  },
  {
    word: "Bath bun",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bath bun <strong class='noun'> n. </strong> Round spiced bun with currants, often iced. [bath in s. England]</p>",
      },
    ],
  },
  {
    word: "Bath chair",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bath chair <strong class='noun'> n. </strong> Wheelchair for invalids.</p>",
      },
    ],
  },
  {
    word: "Bath cube",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bath cube <strong class='noun'> n. </strong> Cube of soluble substance for scenting or softening bath-water.</p>",
      },
    ],
  },
  {
    word: "Bathe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bathe <strong class='noun'> —v. </strong> (-thing) 1 immerse oneself in water, esp. To swim or wash oneself. 2 immerse in, wash, or treat with liquid. 3 (of sunlight etc.) Envelop. <strong class='noun'> —n. </strong> Swim. [old english]</p>",
      },
    ],
  },
  {
    word: "Bathhouse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bathhouse <strong class='noun'> n. </strong> Public building with baths.</p>",
      },
    ],
  },
  {
    word: "Bathing-costume",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bathing-costume <strong class='noun'> n. </strong> (also bathing-suit) garment worn for swimming.</p>",
      },
    ],
  },
  {
    word: "Bathos",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bathos <strong class='noun'> n. </strong> Lapse in mood from the sublime to the absurd or trivial; anticlimax. bathetic adj. Bathotic adj. [greek, = depth]</p>",
      },
    ],
  },
  {
    word: "Bathrobe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bathrobe <strong class='noun'> n. </strong> Esp. Us dressing-gown, esp. Of towelling.</p>",
      },
    ],
  },
  {
    word: "Bathroom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bathroom <strong class='noun'> n. </strong> 1 room with a bath, wash-basin, etc. 2 us room with a lavatory.</p>",
      },
    ],
  },
  {
    word: "Bath salts",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bath salts <strong class='n_pl'> n.pl. </strong> Soluble powder or crystals for scenting or softening bath-water.</p>",
      },
    ],
  },
  {
    word: "Bathyscaphe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bathyscaphe <strong class='noun'> n. </strong> Manned vessel for deep-sea diving. [greek bathus deep, skaphos ship]</p>",
      },
    ],
  },
  {
    word: "Bathysphere",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bathysphere <strong class='noun'> n. </strong> Vessel for deep-sea observation. [greek bathus deep, *sphere]</p>",
      },
    ],
  },
  {
    word: "Batik",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batik <strong class='noun'> n. </strong> 1 method of dyeing textiles by applying wax to parts to be left uncoloured. 2 cloth so treated. [javanese, = painted]</p>",
      },
    ],
  },
  {
    word: "Batiste",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batiste <strong class='noun'> n. </strong> Fine linen or cotton cloth. [french from baptiste, name of the first maker]</p>",
      },
    ],
  },
  {
    word: "Batman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batman <strong class='noun'> n. </strong> Army officer's servant. [bat pack-saddle, from french]</p>",
      },
    ],
  },
  {
    word: "Baton",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baton <strong class='noun'> n. </strong> 1 thin stick for conducting an orchestra etc. 2 short stick passed on in a relay race. 3 stick carried by a drum major. 4 staff of office. [french from latin]</p>",
      },
    ],
  },
  {
    word: "Baton round",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baton round <strong class='noun'> n. </strong> Rubber or plastic bullet.</p>",
      },
    ],
  },
  {
    word: "Batrachian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batrachian <strong class='noun'> —n. </strong> Amphibian that discards its gills and tail, esp. A frog or toad. <strong class='adj'> —adj.</strong> Of batrachians. [greek batrakhos frog]</p>",
      },
    ],
  },
  {
    word: "Bats",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bats <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> Slang crazy. [originally <strong class='pl'> pl. </strong> Of *bat2]</p>",
      },
    ],
  },
  {
    word: "Batsman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batsman <strong class='noun'> n. </strong> Person who bats, esp. In cricket.</p>",
      },
    ],
  },
  {
    word: "Battalion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battalion <strong class='noun'> n. </strong> 1 army unit usu. Of 300–1000 men. 2 large group with a common aim. [italian battaglia *battle]</p>",
      },
    ],
  },
  {
    word: "Batten1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batten1 <strong class='noun'> —n. </strong> 1 a long flat strip of squared timber. B horizontal strip of wood to which laths, tiles, etc., are fastened. 2 strip for securing tarpaulin over a ship's hatchway. —v. Strengthen or (often foll. By down) fasten with battens. [french: related to *batter1]</p>",
      },
    ],
  },
  {
    word: "Batten2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batten2 <strong class='verb'> v. </strong> (foll. By on) thrive at the expense of (another). [old norse]</p>",
      },
    ],
  },
  {
    word: "Battenberg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battenberg <strong class='noun'> n. </strong> Oblong sponge cake, usu. Of two colours and covered with marzipan. [battenberg in germany]</p>",
      },
    ],
  },
  {
    word: "Batter1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batter1 <strong class='verb'> v. </strong> 1 a strike hard and repeatedly. B (often foll. By against, at, etc.) Pound insistently (batter at the door). 2 (often in passive) a subject to long-term violence (battered baby, wife). B criticize severely. batterer <strong class='noun'> n. </strong> [french battre beat: related to *battle]</p>",
      },
    ],
  },
  {
    word: "Batter2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batter2 <strong class='noun'> n. </strong> Mixture of flour, egg, and milk or water, used for pancakes etc. [french: related to *batter1]</p>",
      },
    ],
  },
  {
    word: "Battered",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battered <strong class='adj'> adj. </strong> Coated in batter and deep-fried.</p>",
      },
    ],
  },
  {
    word: "Battering-ram",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battering-ram <strong class='noun'> n. </strong> Hist. Beam used in breaching fortifications.</p>",
      },
    ],
  },
  {
    word: "Battery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 usu. Portable container of an electrically charged cell or cells as a source of current. 2 (often attrib.) Series of cages for the intensive breeding and rearing of poultry or cattle. 3 set of similar units of equipment; series, sequence. 4 emplacement for heavy guns. 5 law unlawful physical violence against a person. [latin: related to *battle]</p>",
      },
    ],
  },
  {
    word: "Battle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battle <strong class='noun'> —n. </strong> 1 prolonged fight between armed forces. 2 difficult struggle; contest (battle for supremacy; battle of wits). —v. (-ling) engage in battle; fight. half the battle key to the success of an undertaking. [latin battuo beat]</p>",
      },
    ],
  },
  {
    word: "Battleaxe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battleaxe <strong class='noun'> n. </strong> 1 large axe used in ancient warfare. 2 <strong class='colloq'> colloq. </strong> Formidable older woman.</p>",
      },
    ],
  },
  {
    word: "Battlebus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battlebus <strong class='noun'> n. </strong> Colloq. Bus used by a politician during an election campaign as a mobile centre of operations.</p>",
      },
    ],
  },
  {
    word: "Battle-cruiser",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battle-cruiser <strong class='noun'> n. </strong> Hist. Warship of higher speed and lighter armour than a battleship.</p>",
      },
    ],
  },
  {
    word: "Battle-cry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battle-cry <strong class='noun'> n. </strong> Cry or slogan used in a battle or contest.</p>",
      },
    ],
  },
  {
    word: "Battledore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battledore <strong class='noun'> n. </strong> Hist. 1 (in full battledore and shuttlecock) game played with a shuttlecock and rackets. 2 racket used in this. [perhaps from provençal batedor beater]</p>",
      },
    ],
  },
  {
    word: "Battledress",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battledress <strong class='noun'> n. </strong> Everyday uniform of a soldier.</p>",
      },
    ],
  },
  {
    word: "Battlefield",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battlefield <strong class='noun'> n. </strong> (also battleground) scene of a battle.</p>",
      },
    ],
  },
  {
    word: "Battlement",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battlement <strong class='noun'> n. </strong> (usu. In pl.) Recessed parapet along the top of a wall, as part of a fortification. [french batailler fortify]</p>",
      },
    ],
  },
  {
    word: "Battle royal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battle royal <strong class='noun'> n. </strong> 1 battle of many combatants; free fight. 2 heated argument.</p>",
      },
    ],
  },
  {
    word: "Battleship",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Battleship <strong class='noun'> n. </strong> Heavily armoured warship.</p>",
      },
    ],
  },
  {
    word: "Batty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batty <strong class='adj'> adj. </strong> (-ier, -iest) slang crazy. [from *bat2]</p>",
      },
    ],
  },
  {
    word: "Batwing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Batwing <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (esp. Of a sleeve) shaped like a bat's wing.</p>",
      },
    ],
  },
  {
    word: "Bauble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bauble <strong class='noun'> n. </strong> Showy worthless trinket or toy. [french ba(u)bel toy]</p>",
      },
    ],
  },
  {
    word: "Baulk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Baulk <strong class='general'>(also balk)</strong>  <strong class='noun'> —v. </strong>1 (often foll. By at) jib, hesitate. 2 a thwart, hinder. B disappoint. 3 miss, let slip (a chance etc.). <strong class='noun'> —n. </strong> 1 hindrance; stumbling-block. 2 roughly-squared timber beam. [old english]</p>",
      },
    ],
  },
  {
    word: "Bauxite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bauxite <strong class='noun'> n. </strong> Claylike mineral, the chief source of aluminium. [french from les baux in s. France]</p>",
      },
    ],
  },
  {
    word: "Bawdy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bawdy <strong class='adj'> —adj.</strong> (-ier, -iest) humorously indecent. <strong class='noun'> —n. </strong> Such talk or writing. [bawd brothel-keeper from french baudetrot]</p>",
      },
    ],
  },
  {
    word: "Bawdy-house",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bawdy-house <strong class='noun'> n. </strong> Brothel.</p>",
      },
    ],
  },
  {
    word: "Bawl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bawl <strong class='verb'> v. </strong> 1 speak or shout noisily. 2 weep loudly. bawl out <strong class='colloq'> colloq. </strong> Reprimand angrily. [imitative]</p>",
      },
    ],
  },
  {
    word: "Bay1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bay1 <strong class='noun'> n. </strong> Broad curving inlet of the sea. [spanish bahia]</p>",
      },
    ],
  },
  {
    word: "Bay2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bay2 <strong class='noun'> n. </strong> 1 laurel with deep green leaves. 2 (in pl.) Bay wreath, for a victor or poet. [latin baca berry]</p>",
      },
    ],
  },
  {
    word: "Bay3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bay3 <strong class='noun'> n. </strong> 1 recess; alcove in a wall. 2 compartment (bomb bay). 3 area specially allocated (loading bay). [french baer gape]</p>",
      },
    ],
  },
  {
    word: "Bay4",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bay4 <strong class='adj'> —adj.</strong> (esp. Of a horse) dark reddish-brown. <strong class='noun'> —n. </strong> Bay horse. [latin badius]</p>",
      },
    ],
  },
  {
    word: "Bay5",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bay5 <strong class='noun'> —v. </strong> Bark or howl loudly and plaintively. <strong class='noun'> —n. </strong> Sound of this, esp. Of hounds in close pursuit. at bay cornered, unable to escape. Keep at bay hold off (a pursuer). [french bayer to bark]</p>",
      },
    ],
  },
  {
    word: "Bayberry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bayberry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> fragrant <strong class='noun'> n. </strong> American tree.</p>",
      },
    ],
  },
  {
    word: "Bay-leaf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bay-leaf <strong class='noun'> n. </strong> Leaf of the bay-tree, used for flavouring.</p>",
      },
    ],
  },
  {
    word: "Bayonet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bayonet <strong class='noun'> —n. </strong> 1 stabbing blade attachable to the muzzle of a rifle. 2 electrical fitting pushed into a socket and twisted. —v. (-t-) stab with bayonet. [french, perhaps from bayonne in sw france]</p>",
      },
    ],
  },
  {
    word: "Bay rum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bay rum <strong class='noun'> n. </strong> Perfume distilled <strong class='archaic_it_was'> orig. </strong> From bayberry leaves in rum.</p>",
      },
    ],
  },
  {
    word: "Bay window",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bay window <strong class='noun'> n. </strong> Window projecting outwards from a wall.</p>",
      },
    ],
  },
  {
    word: "Bazaar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bazaar <strong class='noun'> n. </strong> 1 oriental market. 2 fund-raising sale of goods, esp. For charity. [persian]</p>",
      },
    ],
  },
  {
    word: "Bazooka",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bazooka <strong class='noun'> n. </strong> Anti-tank rocket-launcher. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p> <strong class='sing'> abbr. </strong> Double-black (pencil-lead).</p>",
      },
    ],
  },
  {
    word: "Bbc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bbc <strong class='sing'> abbr. </strong> British broadcasting corporation.</p>",
      },
    ],
  },
  {
    word: "Bc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bc <strong class='sing'> abbr. </strong> British columbia.</p>",
      },
    ],
  },
  {
    word: "Bc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bc <strong class='sing'> abbr. </strong> Before christ.</p>",
      },
    ],
  },
  {
    word: "Bcg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bcg <strong class='sing'> abbr. </strong> Bacillus calmette-guérin, an anti-tuberculosis vaccine.</p>",
      },
    ],
  },
  {
    word: "Bd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bd <strong class='sing'> abbr. </strong> Bachelor of divinity.</p>",
      },
    ],
  },
  {
    word: "Bdellium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bdellium <strong class='noun'> n. </strong> 1 tree yielding resin. 2 this used in perfumes. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Be",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Be <strong class='prep'> symb. </strong> Beryllium.</p>",
      },
    ],
  },
  {
    word: "Be",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Be <strong class='verb'> v. </strong> (sing. Present am; are; is; past was; were; pres. Part. Being;  <strong class='archaic_it_was'>past part.</strong> Been) 1 exist, live (i think, therefore i am; there is no god). 2 a occur; take place (dinner is at eight).</p>",
      },
    ],
  },
  {
    word: "Bevel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bevel <strong class='noun'> —n. </strong> 1 slope from the horizontal or vertical in carpentry etc.; sloping surface or edge. 2 tool for marking angles. —v. (-ll-; us -l-) 1 reduce (a square edge) to a sloping edge. 2 slope at an angle. [french]</p>",
      },
    ],
  },
  {
    word: "Bevel gear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bevel gear <strong class='noun'> n. </strong> Gear working another at an angle to it.</p>",
      },
    ],
  },
  {
    word: "Beverage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Beverage <strong class='noun'> n. </strong> Formal drink. [latin bibo drink]</p>",
      },
    ],
  },
  {
    word: "Bevy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bevy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> company (of quails, larks, women, etc.). [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bewail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bewail <strong class='verb'> v. </strong> Lament; wail over.</p>",
      },
    ],
  },
  {
    word: "Beware",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Beware <strong class='verb'> v. </strong> (only in imper. Or infin.; often foll. By of) be cautious (of) (beware of the dog; beware the ides of march). [from *be, ware cautious]</p>",
      },
    ],
  },
  {
    word: "Bewilder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bewilder <strong class='verb'> v. </strong> Perplex, confuse. bewildering adj. Bewilderment <strong class='noun'> n. </strong> [from *be-, obsolete wilder lose one's way]</p>",
      },
    ],
  },
  {
    word: "Bewitch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bewitch <strong class='verb'> v. </strong> 1 enchant. 2 cast a spell on.</p>",
      },
    ],
  },
  {
    word: "Beyond",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Beyond <strong class='prep'> —prep. </strong> 1 at or to the further side of. 2 outside the scope or understanding of (beyond repair; it is beyond me). 3 more than. <strong class='adv'> —adv. </strong> 1 at or to the further side. 2 further on. <strong class='noun'> —n. </strong> (prec. By the) the unknown after death. [old english: related to *by, *yon]</p>",
      },
    ],
  },
  {
    word: "Bezel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bezel <strong class='noun'> n. </strong> 1 sloped edge of a chisel. 2 oblique faces of a cut gem. 3 groove holding a watch-glass or gem. [french]</p>",
      },
    ],
  },
  {
    word: "Bezique",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bezique <strong class='noun'> n. </strong> Card-game for two. [french]</p>",
      },
    ],
  },
  {
    word: "B.f.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>B.f. <strong class='abbr'> Abbr. </strong> 1 <strong class='colloq'> colloq. </strong> Bloody fool. 2 brought forward.</p>",
      },
    ],
  },
  {
    word: "Bhang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bhang <strong class='noun'> n. </strong> Indian hemp used as a narcotic. [portuguese from sanskrit]</p>",
      },
    ],
  },
  {
    word: "B.h.p.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>B.h.p. <strong class='abbr'> Abbr. </strong> Brake horsepower.</p>",
      },
    ],
  },
  {
    word: "Bi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bi <strong class='prep'> symb. </strong> Bismuth.</p>",
      },
    ],
  },
  {
    word: "Bi-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bi- <strong class='n_pl'> comb. </strong> Form forming nouns, adjectives, and verbs, meaning: 1 division into two (biplane; bisect). 2 a occurring twice in every one or once in every two (bi-weekly). B lasting for two (biennial). 3 chem. Substance having a double proportion of what is indicated by the simple word (bicarbonate). 4 bot. & zool. Having divided parts which are themselves similarly divided (bipinnate). [latin]</p>",
      },
    ],
  },
  {
    word: "Biannual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biannual <strong class='adj'> adj. </strong> Occurring etc. Twice a year.</p>",
      },
    ],
  },
  {
    word: "Bias",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bias <strong class='noun'> —n. </strong> 1 (often foll. By towards, against) predisposition or prejudice. 2 statistics distortion of a statistical result due to a neglected factor. 3 edge cut obliquely across the weave of a fabric. 4 sport a irregular shape given to a bowl. B oblique course this causes it to run. —v. (-s- or -ss-) 1 (esp. As biased adj.) Influence (usu. Unfairly); prejudice. 2 give a bias to. on the bias obliquely, diagonally. [french]</p>",
      },
    ],
  },
  {
    word: "Bias binding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bias binding <strong class='noun'> n. </strong> Strip of fabric cut obliquely and used to bind edges.</p>",
      },
    ],
  },
  {
    word: "Biathlon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biathlon <strong class='noun'> n. </strong> Athletic contest in skiing and shooting or cycling and running. [from *bi-, after *pentathlon]</p>",
      },
    ],
  },
  {
    word: "Bib",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bib <strong class='noun'> n. </strong> 1 piece of cloth etc. Fastened round a child's neck while eating. 2 top front part of an apron, dungarees, etc. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Bib-cock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bib-cock <strong class='noun'> n. </strong> Tap with a bent nozzle. [perhaps from *bib]</p>",
      },
    ],
  },
  {
    word: "Bible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bible <strong class='noun'> n. </strong> 1 a (prec. By the) christian scriptures of old and new testaments. B (bible) copy of these. 2 (bible) <strong class='colloq'> colloq. </strong> Authoritative book. biblical adj. [greek biblia books]</p>",
      },
    ],
  },
  {
    word: "Bible-bashing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bible-bashing <strong class='noun'> n. </strong> (also bible-thumping) slang aggressive fundamentalist preaching. bible-basher <strong class='noun'> n. </strong> (also -thumper).</p>",
      },
    ],
  },
  {
    word: "Bibliography",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bibliography <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 list of books on a specific subject, by a particular author, etc.; book containing this. 2 the study of books, their authorship, editions, etc. bibliographer <strong class='noun'> n. </strong> Bibliographical adj. [greek: related to *bible]</p>",
      },
    ],
  },
  {
    word: "Bibliophile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bibliophile <strong class='noun'> n. </strong> Lover or collector of books.</p>",
      },
    ],
  },
  {
    word: "Bibulous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bibulous <strong class='adj'> adj. </strong> Tending to drink alcohol. [latin bibo drink]</p>",
      },
    ],
  },
  {
    word: "Bicameral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bicameral <strong class='adj'> adj. </strong> (of a legislative body) having two chambers. [from *bi-, latin camera chamber]</p>",
      },
    ],
  },
  {
    word: "Bicarb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bicarb <strong class='noun'> n. </strong> Colloq. = *bicarbonate 2. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Bicarbonate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bicarbonate <strong class='noun'> n. </strong> 1 any acid salt of carbonic acid. 2 (in full bicarbonate of soda) sodium bicarbonate used as an antacid or in baking-powder.</p>",
      },
    ],
  },
  {
    word: "Bicentenary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bicentenary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 two-hundredth anniversary. 2 celebration of this.</p>",
      },
    ],
  },
  {
    word: "Bicentennial  esp. Us",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bicentennial esp. Us <strong class='noun'> —n. </strong> Bicentenary. <strong class='adj'> —adj.</strong> Occurring every two hundred years.</p>",
      },
    ],
  },
  {
    word: "Biceps",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biceps <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> muscle with two heads or attachments, esp. That bending the elbow. [latin caput head]</p>",
      },
    ],
  },
  {
    word: "Bicker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bicker <strong class='verb'> v. </strong> Argue pettily. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bicuspid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bicuspid <strong class='adj'> —adj.</strong> Having two cusps. <strong class='noun'> —n. </strong> The premolar tooth in humans. [from *bi-, *cusp]</p>",
      },
    ],
  },
  {
    word: "Bicycle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bicycle <strong class='noun'> —n. </strong> Pedal-driven two-wheeled vehicle. —v. (-ling) ride a bicycle. [greek kuklos wheel]</p>",
      },
    ],
  },
  {
    word: "Bid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bid <strong class='noun'> —v. </strong> (-dd-; past bid, archaic bade;  <strong class='archaic_it_was'>past part.</strong> Bid, archaic bidden) 1 (past and  <strong class='archaic_it_was'>past part.</strong> Bid) a (esp. At an auction) make an offer (of) (bid for the vase; bid £20). B offer a service for a stated price. 2 literary command; invite (bid the soldiers shoot; bade her start). 3 literary utter (a greeting or farewell) to (i bade him welcome). 4 (past and  <strong class='archaic_it_was'>past part.</strong> Bid) cards state before play how many tricks one intends to make. <strong class='noun'> —n. </strong> 1 act of bidding. 2 amount bid. 3 <strong class='colloq'> colloq. </strong> Attempt; effort (bid for power). bidder <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Biddable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biddable <strong class='adj'> adj. </strong> Obedient.</p>",
      },
    ],
  },
  {
    word: "Bidding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bidding <strong class='noun'> n. </strong> 1 command, request, or invitation. 2 bids at an auction or in a card-game.</p>",
      },
    ],
  },
  {
    word: "Biddy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biddy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> slang woman (esp. Old biddy). [a form of the name bridget]</p>",
      },
    ],
  },
  {
    word: "Bide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bide <strong class='verb'> v. </strong> (-ding) bide one's time wait for a good opportunity. [old english]</p>",
      },
    ],
  },
  {
    word: "Bidet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bidet <strong class='noun'> n. </strong> Low basin for sitting on to wash the genital area. [french, = pony]</p>",
      },
    ],
  },
  {
    word: "Biennial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biennial <strong class='adj'> —adj.</strong> Lasting, or recurring every, two years. <strong class='noun'> —n. </strong> Plant that grows from seed one year and flowers and dies the following. [latin annus year]</p>",
      },
    ],
  },
  {
    word: "Bier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bier <strong class='noun'> n. </strong> Movable frame on which a coffin or corpse rests. [old english]</p>",
      },
    ],
  },
  {
    word: "Biff  slang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biff slang <strong class='noun'> —n. </strong> Sharp blow. —v. Strike (a person). [imitative]</p>",
      },
    ],
  },
  {
    word: "Bifid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bifid <strong class='adj'> adj. </strong> Divided by a deep cleft into two parts. [latin findo cleave]</p>",
      },
    ],
  },
  {
    word: "Bifocal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bifocal <strong class='adj'> —adj.</strong> Having two focuses, esp. Of a lens with a part for distant and a part for near vision. <strong class='noun'> —n. </strong> (in pl.) Bifocal spectacles.</p>",
      },
    ],
  },
  {
    word: "Bifurcate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bifurcate <strong class='noun'> —v. </strong> <strong class='ting'> (-ting) </strong> fork. <strong class='adj'> —adj.</strong> Forked; branched. bifurcation <strong class='noun'> n. </strong> [latin furca fork]</p>",
      },
    ],
  },
  {
    word: "Big",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big <strong class='adj'> —adj.</strong> (bigger, biggest) 1 a of considerable size, amount, intensity, etc. B of a large or the largest size (big toe). 2 important (my big day). 3 adult, elder (big sister). 4 <strong class='colloq'> colloq. </strong> A boastful (big words). B often iron. Generous (big of him). C ambitious (big ideas). 5 (usu. Foll. By with) advanced in pregnancy (big with child). <strong class='adv'> —adv. </strong> Colloq. Impressively or grandly (think big). in a big way <strong class='colloq'> colloq. </strong> With great enthusiasm, display, etc. biggish adj. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bigamy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bigamy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> crime of marrying while still married to another person. bigamist <strong class='noun'> n. </strong> Bigamous adj. [greek gamos marriage]</p>",
      },
    ],
  },
  {
    word: "Big apple",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big apple <strong class='noun'> n. </strong> Us slang new york city.</p>",
      },
    ],
  },
  {
    word: "Big bang theory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big bang theory <strong class='noun'> n. </strong> Theory that the universe began with the explosion of dense matter.</p>",
      },
    ],
  },
  {
    word: "Big brother",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big brother <strong class='noun'> n. </strong> Supposedly benevolent watchful dictator.</p>",
      },
    ],
  },
  {
    word: "Big end",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big end <strong class='noun'> n. </strong> (in a vehicle) end of the connecting-rod, encircling the crankpin.</p>",
      },
    ],
  },
  {
    word: "Big-head",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big-head <strong class='noun'> n. </strong> Colloq. Conceited person. big-headed adj.</p>",
      },
    ],
  },
  {
    word: "Big-hearted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big-hearted <strong class='adj'> adj. </strong> Generous.</p>",
      },
    ],
  },
  {
    word: "Bight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bight <strong class='noun'> n. </strong> 1 bay, inlet, etc. 2 loop of rope. [old english]</p>",
      },
    ],
  },
  {
    word: "Big money",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big money <strong class='noun'> n. </strong> Large amounts of money.</p>",
      },
    ],
  },
  {
    word: "Big noise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big noise <strong class='noun'> n. </strong> (also big shot) <strong class='colloq'> colloq. </strong> = *bigwig.</p>",
      },
    ],
  },
  {
    word: "Bigot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bigot <strong class='noun'> n. </strong> Obstinate believer who is intolerant of others. bigoted adj. Bigotry <strong class='noun'> n. </strong> [french]</p>",
      },
    ],
  },
  {
    word: "Big stick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big stick <strong class='noun'> n. </strong> Colloq. Display of force.</p>",
      },
    ],
  },
  {
    word: "Big time",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big time <strong class='noun'> n. </strong> (prec. By the) slang success, esp. In show business. big-timer n.</p>",
      },
    ],
  },
  {
    word: "Big top",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big top <strong class='noun'> n. </strong> Main tent in a circus.</p>",
      },
    ],
  },
  {
    word: "Big wheel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Big wheel <strong class='noun'> n. </strong> Ferris wheel.</p>",
      },
    ],
  },
  {
    word: "Bigwig",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bigwig <strong class='noun'> n. </strong> Colloq. Important person.</p>",
      },
    ],
  },
  {
    word: "Bijou",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bijou <strong class='noun'> —n. </strong> (pl. -x pronunc. Same) jewel; trinket. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> (bijou) small and elegant. [french]</p>",
      },
    ],
  },
  {
    word: "Bike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bike <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Bicycle or motor cycle. —v. (-king) ride a bike. biker <strong class='noun'> n. </strong> [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Bikini",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bikini <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> two-piece swimsuit for women. [bikini, pacific atoll]</p>",
      },
    ],
  },
  {
    word: "Bilateral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bilateral <strong class='adj'> adj. </strong> 1 of, on, or with two sides. 2 affecting or between two parties, countries, etc. bilaterally adv.</p>",
      },
    ],
  },
  {
    word: "Bilberry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bilberry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 hardy <strong class='noun'> n. </strong> European shrub of heaths and mountains. 2 its small dark-blue edible berry. [scandinavian]</p>",
      },
    ],
  },
  {
    word: "Bile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bile <strong class='noun'> n. </strong> 1 bitter digestive fluid secreted by the liver. 2 bad temper; peevish anger. [latin bilis]</p>",
      },
    ],
  },
  {
    word: "Bilge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bilge <strong class='noun'> n. </strong> 1 a the almost flat part of a ship's bottom. B (in full bilge-water) filthy water that collects there. 2 slang nonsense. [probably <strong class='var'> var. </strong> Of *bulge]</p>",
      },
    ],
  },
  {
    word: "Bilharzia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bilharzia <strong class='noun'> n. </strong> Chronic tropical disease caused by a parasitic flatworm. [bilharz, name of a physician]</p>",
      },
    ],
  },
  {
    word: "Biliary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biliary <strong class='adj'> adj. </strong> Of the bile. [french: related to *bile]</p>",
      },
    ],
  },
  {
    word: "Bilingual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bilingual <strong class='adj'> —adj.</strong> 1 able to speak two languages. 2 spoken or written in two languages. <strong class='noun'> —n. </strong> Bilingual person. bilingualism <strong class='noun'> n. </strong> [latin lingua tongue]</p>",
      },
    ],
  },
  {
    word: "Bilious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bilious <strong class='adj'> adj. </strong> 1 affected by a disorder of the bile. 2 bad-tempered. [latin: related to *bile]</p>",
      },
    ],
  },
  {
    word: "Bilk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bilk <strong class='verb'> v. </strong> Slang 1 cheat. 2 elude. 3 avoid paying (a creditor or debt). [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Bill",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bill <strong class='noun'> n. </strong> Slang = *old bill. [diminutive of william]</p>",
      },
    ],
  },
  {
    word: "Bill1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bill1 <strong class='noun'> —n. </strong> 1 statement of charges for goods or services. 2 draft of a proposed law. 3 poster, placard. 4 programme of entertainment. 5 us banknote. —v. 1 send a statement of charges to. 2 put in the programme; announce. 3 (foll. By as) advertise as. [medieval latin bulla seal]</p>",
      },
    ],
  },
  {
    word: "Bill2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bill2 <strong class='noun'> —n. </strong> 1 bird's beak. 2 narrow promontory. —v. (of doves etc.) Stroke bills. bill and coo exchange caresses. [old english]</p>",
      },
    ],
  },
  {
    word: "Bill3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bill3 <strong class='noun'> n. </strong> 1 hist. Weapon with a hooked blade. 2 = *billhook. [old english]</p>",
      },
    ],
  },
  {
    word: "Billabong",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billabong <strong class='noun'> n. </strong> (in australia) backwater of a river. [aboriginal]</p>",
      },
    ],
  },
  {
    word: "Billboard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billboard <strong class='noun'> n. </strong> Large outdoor advertising hoarding.</p>",
      },
    ],
  },
  {
    word: "Billet1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billet1 <strong class='noun'> —n. </strong> 1 a place where troops etc. Are lodged. B order to provide this. 2 <strong class='colloq'> colloq. </strong> Job. —v. (-t-) (usu. Foll. By on, in, at) quarter (soldiers etc.). [anglo-french diminutive of *bill1]</p>",
      },
    ],
  },
  {
    word: "Billet2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billet2 <strong class='noun'> n. </strong> 1 thick piece of firewood. 2 small metal bar. [french diminutive of bille tree-trunk]</p>",
      },
    ],
  },
  {
    word: "Billet-doux",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billet-doux <strong class='noun'> n. </strong> (pl. Billets-doux) often joc. Love-letter. [french, = sweet note]</p>",
      },
    ],
  },
  {
    word: "Billhook",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billhook <strong class='noun'> n. </strong> Pruning tool with a hooked blade.</p>",
      },
    ],
  },
  {
    word: "Billiards",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billiards <strong class='noun'> n. </strong> 1 game played on a table, with three balls struck with cues. 2 (billiard) (in <strong class='n_pl'> comb. </strong> ) Used in billiards (billiard-ball). [french: related to *billet2]</p>",
      },
    ],
  },
  {
    word: "Billion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billion <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> (pl. Same or (in sense 3) -s) 1 a thousand million (109). 2 (now less often) a million million (1012). 3 (in pl.) Colloq. A very large number (billions of years). billionth adj. & <strong class='noun'> n. </strong> [french]</p>",
      },
    ],
  },
  {
    word: "Billionaire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billionaire <strong class='noun'> n. </strong> Person who has over a billion pounds, dollars, etc. [after *millionaire]</p>",
      },
    ],
  },
  {
    word: "Bill of exchange",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bill of exchange <strong class='noun'> n. </strong> Written order to pay a sum of money on a given date to the drawer or to a named payee.</p>",
      },
    ],
  },
  {
    word: "Bill of fare",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bill of fare <strong class='noun'> n. </strong> Menu.</p>",
      },
    ],
  },
  {
    word: "Bill of lading",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bill of lading <strong class='noun'> n. </strong> Detailed list of a ship's cargo.</p>",
      },
    ],
  },
  {
    word: "Billow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billow <strong class='noun'> —n. </strong> 1 wave. 2 any large mass. —v. Rise, fill, or surge in billows. billowy adj. [old norse]</p>",
      },
    ],
  },
  {
    word: "Billposter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billposter <strong class='noun'> n. </strong> (also billsticker) person who pastes up advertisements on hoardings.</p>",
      },
    ],
  },
  {
    word: "Billy1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billy1 <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> (in full billycan) austral. Tin or enamel outdoor cooking-pot. [perhaps from aboriginal billa water]</p>",
      },
    ],
  },
  {
    word: "Billy2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Billy2 <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> (in full billy-goat) male goat. [from the name billy]</p>",
      },
    ],
  },
  {
    word: "Bimbo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bimbo <strong class='noun'> n. </strong> (pl. -s or -es) slang usu. Derog. Attractive but unintelligent young woman. [italian, = little child]</p>",
      },
    ],
  },
  {
    word: "Bimetallic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bimetallic <strong class='adj'> adj. </strong> Using or made of two metals. [french]</p>",
      },
    ],
  },
  {
    word: "Bin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bin <strong class='noun'> n. </strong> Large receptacle for rubbish or storage. [old english]</p>",
      },
    ],
  },
  {
    word: "Binary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binary <strong class='adj'> —adj.</strong> 1 of two parts, dual. 2 of the binary system. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> 1 something having two parts. 2 binary number. [latin bini two together]</p>",
      },
    ],
  },
  {
    word: "Binary star",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binary star <strong class='noun'> n. </strong> System of two stars orbiting each other.</p>",
      },
    ],
  },
  {
    word: "Binary system",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binary system <strong class='noun'> n. </strong> System using the digits 0 and 1 to code information, esp. In computing.</p>",
      },
    ],
  },
  {
    word: "Binaural",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binaural <strong class='adj'> adj. </strong> 1 of or used with both ears. 2 (of sound) recorded using two microphones and usu. Transmitted separately to the two ears. [from *bi-, *aural]</p>",
      },
    ],
  },
  {
    word: "Bind",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bind <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Bound) 1 tie or fasten tightly. 2 restrain forcibly. 3 (cause to) cohere. 4 compel; impose a duty on. 5 a edge with braid etc. B fasten (the pages of a book) in a cover. 6 constipate. 7 ratify (a bargain, agreement, etc.). 8 (often foll. By up) bandage. <strong class='noun'> —n. </strong> Colloq. Nuisance; restriction. bind over law order (a person) to do something, esp. Keep the peace. [old english]</p>",
      },
    ],
  },
  {
    word: "Binder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binder <strong class='noun'> n. </strong> 1 cover for loose papers etc. 2 substance that binds things together. 3 hist. Reaping-machine that binds grain into sheaves. 4 bookbinder.</p>",
      },
    ],
  },
  {
    word: "Bindery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bindery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> bookbinder's workshop.</p>",
      },
    ],
  },
  {
    word: "Binding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binding <strong class='noun'> —n. </strong> Thing that binds, esp. The covers, glue, etc., of a book. <strong class='adj'> —adj.</strong> Obligatory.</p>",
      },
    ],
  },
  {
    word: "Bindweed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bindweed <strong class='noun'> n. </strong> 1 convolvulus. 2 honeysuckle or other climber.</p>",
      },
    ],
  },
  {
    word: "Bine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bine <strong class='noun'> n. </strong> 1 twisting stem of a climbing plant, esp. The hop. 2 flexible shoot. [dial. Form of *bind]</p>",
      },
    ],
  },
  {
    word: "Bin end",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bin end <strong class='noun'> n. </strong> One of the last bottles from a bin of wine, usu. Sold at a reduced price.</p>",
      },
    ],
  },
  {
    word: "Binge  slang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binge slang <strong class='noun'> —n. </strong> Bout of excessive eating, drinking, etc.; spree. —v. (-ging) indulge in a binge. [probably dial., = soak]</p>",
      },
    ],
  },
  {
    word: "Bingo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bingo <strong class='noun'> n. </strong> Gambling game in which each player has a card with numbers to be marked off as they are called. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Bin-liner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bin-liner <strong class='noun'> n. </strong> Bag for lining a rubbish bin.</p>",
      },
    ],
  },
  {
    word: "Binman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binman <strong class='noun'> n. </strong> Colloq. Dustman.</p>",
      },
    ],
  },
  {
    word: "Binnacle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binnacle <strong class='noun'> n. </strong> Case for a ship's compass. [latin habitaculum dwelling]</p>",
      },
    ],
  },
  {
    word: "Binocular",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binocular <strong class='adj'> adj. </strong> For both eyes. [latin bini two together, oculus eye]</p>",
      },
    ],
  },
  {
    word: "Binoculars",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binoculars <strong class='n_pl'> n.pl. </strong> Instrument with a lens for each eye, for viewing distant objects.</p>",
      },
    ],
  },
  {
    word: "Binomial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binomial <strong class='noun'> —n. </strong> Algebraic expression of the sum or the difference of two terms. <strong class='adj'> —adj.</strong> Of two terms. [greek nomos part]</p>",
      },
    ],
  },
  {
    word: "Binomial theorem",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Binomial theorem <strong class='noun'> n. </strong> Formula for finding any power of a binomial.</p>",
      },
    ],
  },
  {
    word: "Bint",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bint <strong class='noun'> n. </strong> Slang, usu. Offens. Girl or woman. [arabic]</p>",
      },
    ],
  },
  {
    word: "Bio-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bio- <strong class='n_pl'> comb. </strong> Form 1 life (biography). 2 biological; of living things. [greek bios life]</p>",
      },
    ],
  },
  {
    word: "Biochemistry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biochemistry <strong class='noun'> n. </strong> The study of the chemistry of living organisms. biochemical adj. Biochemist n.</p>",
      },
    ],
  },
  {
    word: "Biodegradable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biodegradable <strong class='adj'> adj. </strong> Capable of being decomposed by bacteria or other living organisms.</p>",
      },
    ],
  },
  {
    word: "Bioengineering",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bioengineering <strong class='noun'> n. </strong> 1 the application of engineering techniques to biological processes. 2 the use of artificial tissues, organs, etc. To replace parts of the body, e.g. Artificial limbs, pacemakers, etc.</p>",
      },
    ],
  },
  {
    word: "Biogenesis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biogenesis <strong class='noun'> n. </strong> 1 hypothesis that a living organism arises only from a similar living organism. 2 synthesis of substances by living organisms.</p>",
      },
    ],
  },
  {
    word: "Biography",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biography <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 account of a person's life, written usu. By another. 2 these as a literary genre. biographer <strong class='noun'> n. </strong> Biographical adj. [french: related to *bio-]</p>",
      },
    ],
  },
  {
    word: "Biological",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biological <strong class='adj'> adj. </strong> Of biology or living organisms. biologically adv.</p>",
      },
    ],
  },
  {
    word: "Biological clock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biological clock <strong class='noun'> n. </strong> Innate mechanism controlling an organism's rhythmic physiological activities.</p>",
      },
    ],
  },
  {
    word: "Biological warfare",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biological warfare <strong class='noun'> n. </strong> Use of toxins or micro-organisms against an enemy.</p>",
      },
    ],
  },
  {
    word: "Biology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biology <strong class='noun'> n. </strong> The study of living organisms. biologist <strong class='noun'> n. </strong> [german: related to *bio-]</p>",
      },
    ],
  },
  {
    word: "Bionic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bionic <strong class='adj'> adj. </strong> Having electronically operated body parts or the resulting superhuman powers. [from *bio- after *electronic]</p>",
      },
    ],
  },
  {
    word: "Bionics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bionics <strong class='n_pl'> n.pl. </strong> (treated as sing.) The study of mechanical systems that function like living organisms.</p>",
      },
    ],
  },
  {
    word: "Biophysics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biophysics <strong class='n_pl'> n.pl. </strong> (treated as sing.) Science of the application of the laws of physics to biological phenomena. biophysical adj. Biophysicist n.</p>",
      },
    ],
  },
  {
    word: "Biopsy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biopsy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> examination of severed tissue for diagnosis. [greek bios life, opsis sight]</p>",
      },
    ],
  },
  {
    word: "Biorhythm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biorhythm <strong class='noun'> n. </strong> Any recurring biological cycle thought to affect one's physical or mental state.</p>",
      },
    ],
  },
  {
    word: "Biosphere",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biosphere <strong class='noun'> n. </strong> Regions of the earth's crust and atmosphere occupied by living things. [german: related to *bio-]</p>",
      },
    ],
  },
  {
    word: "Biosynthesis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biosynthesis <strong class='noun'> n. </strong> Production of organic molecules by living organisms. biosynthetic adj.</p>",
      },
    ],
  },
  {
    word: "Biotechnology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biotechnology <strong class='noun'> n. </strong> Branch of technology exploiting biological processes, esp. Using micro-organisms, in industry, medicine, etc.</p>",
      },
    ],
  },
  {
    word: "Biotin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biotin <strong class='noun'> n. </strong> Vitamin of the b complex, found in egg-yolk, liver, and yeast. [greek bios life]</p>",
      },
    ],
  },
  {
    word: "Bipartisan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bipartisan <strong class='adj'> adj. </strong> Of or involving two parties.</p>",
      },
    ],
  },
  {
    word: "Bipartite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bipartite <strong class='adj'> adj. </strong> 1 of two parts. 2 shared by or involving two parties. [latin bipartio divide in two]</p>",
      },
    ],
  },
  {
    word: "Biped",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biped <strong class='noun'> —n. </strong> Two-footed animal. <strong class='adj'> —adj.</strong> Two-footed. bipedal adj. [latin bipes -edis]</p>",
      },
    ],
  },
  {
    word: "Biplane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biplane <strong class='noun'> n. </strong> Aeroplane with two sets of wings, one above the other.</p>",
      },
    ],
  },
  {
    word: "Bipolar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bipolar <strong class='adj'> adj. </strong> Having two poles or extremities.</p>",
      },
    ],
  },
  {
    word: "Birch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birch <strong class='noun'> —n. </strong> 1 tree with pale hard wood and thin peeling bark, bearing catkins. 2 bundle of birch twigs used for flogging. —v. Beat with a birch. [old english]</p>",
      },
    ],
  },
  {
    word: "Bird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bird <strong class='noun'> n. </strong> 1 two-legged feathered winged vertebrate, egg-laying and usu. Able to fly. 2 slang young woman. 3 slang person. 4 slang prison; prison sentence. a bird in the hand something secured or certain. The birds and the bees euphem. Sexual activity and reproduction. Birds of a feather similar people. For the birds <strong class='colloq'> colloq. </strong> Trivial, uninteresting. Get the bird slang be rejected, esp. By an audience. [old english]</p>",
      },
    ],
  },
  {
    word: "Bird-bath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bird-bath <strong class='noun'> n. </strong> Basin with water for birds to bathe in.</p>",
      },
    ],
  },
  {
    word: "Birdbrain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birdbrain <strong class='noun'> n. </strong> Colloq. Stupid or flighty person. birdbrained adj.</p>",
      },
    ],
  },
  {
    word: "Birdcage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birdcage <strong class='noun'> n. </strong> Cage for birds.</p>",
      },
    ],
  },
  {
    word: "Birdie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birdie <strong class='noun'> n. </strong> 1 <strong class='colloq'> colloq. </strong> Little bird. 2 golf hole played in one under par.</p>",
      },
    ],
  },
  {
    word: "Birdlime",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birdlime <strong class='noun'> n. </strong> Sticky substance spread to trap birds.</p>",
      },
    ],
  },
  {
    word: "Bird-nesting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bird-nesting <strong class='noun'> n. </strong> Hunting for birds' eggs.</p>",
      },
    ],
  },
  {
    word: "Bird of paradise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bird of paradise <strong class='noun'> n. </strong> Bird, the male of which has brilliant plumage.</p>",
      },
    ],
  },
  {
    word: "Bird of passage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bird of passage <strong class='noun'> n. </strong> 1 migrant. 2 habitual traveller.</p>",
      },
    ],
  },
  {
    word: "Bird of prey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bird of prey <strong class='noun'> n. </strong> Bird which hunts animals for food.</p>",
      },
    ],
  },
  {
    word: "Birdseed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birdseed <strong class='noun'> n. </strong> Blend of seeds for caged birds.</p>",
      },
    ],
  },
  {
    word: "Bird's-eye view",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bird's-eye view <strong class='noun'> n. </strong> Detached view from above.</p>",
      },
    ],
  },
  {
    word: "Birdsong",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birdsong <strong class='noun'> n. </strong> Musical cry of birds.</p>",
      },
    ],
  },
  {
    word: "Bird table",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bird table <strong class='noun'> n. </strong> Platform on which food for birds is placed.</p>",
      },
    ],
  },
  {
    word: "Bird-watcher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bird-watcher <strong class='noun'> n. </strong> Person who observes wild birds as a hobby. bird-watching n.</p>",
      },
    ],
  },
  {
    word: "Biretta",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biretta <strong class='noun'> n. </strong> Square usu. Black cap worn by roman catholic priests. [latin birrus cape]</p>",
      },
    ],
  },
  {
    word: "Biro",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biro <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> propr. A kind of ball-point pen. [biró, name of its inventor]</p>",
      },
    ],
  },
  {
    word: "Birth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birth <strong class='noun'> n. </strong> 1 emergence of a baby or young from its mother's body. 2 beginning (birth of civilization). 3 a ancestry (of noble birth). B high or noble birth; inherited position. give birth to 1 produce (young). 2 be the cause of. [old norse]</p>",
      },
    ],
  },
  {
    word: "Birth certificate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birth certificate <strong class='noun'> n. </strong> Official document detailing a person's birth.</p>",
      },
    ],
  },
  {
    word: "Birth control",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birth control <strong class='noun'> n. </strong> Contraception.</p>",
      },
    ],
  },
  {
    word: "Birthday",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birthday <strong class='noun'> n. </strong> 1 day on which one was born. 2 anniversary of this.</p>",
      },
    ],
  },
  {
    word: "Birthing pool",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birthing pool <strong class='noun'> n. </strong> Large bath for giving birth in.</p>",
      },
    ],
  },
  {
    word: "Birthmark",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birthmark <strong class='noun'> n. </strong> Unusual coloured mark on one's body at or from birth.</p>",
      },
    ],
  },
  {
    word: "Birthplace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birthplace <strong class='noun'> n. </strong> Place where one was born.</p>",
      },
    ],
  },
  {
    word: "Birth rate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birth rate <strong class='noun'> n. </strong> Number of live births per thousand of population per year.</p>",
      },
    ],
  },
  {
    word: "Birthright",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birthright <strong class='noun'> n. </strong> Inherited, esp. Property, rights.</p>",
      },
    ],
  },
  {
    word: "Birthstone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Birthstone <strong class='noun'> n. </strong> Gem popularly associated with the month of one's birth.</p>",
      },
    ],
  },
  {
    word: "Biscuit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biscuit <strong class='noun'> n. </strong> 1 flat thin unleavened cake, usu. Crisp and sweet. 2 fired unglazed pottery. 3 light brown colour. [latin bis twice, coquo cook]</p>",
      },
    ],
  },
  {
    word: "Bisect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bisect <strong class='verb'> v. </strong> Divide into two (strictly, equal) parts. bisection <strong class='noun'> n. </strong> Bisector <strong class='noun'> n. </strong> [from *bi-, latin seco sect- cut]</p>",
      },
    ],
  },
  {
    word: "Bisexual",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bisexual <strong class='adj'> —adj.</strong> 1 feeling or involving sexual attraction to people of both sexes. 2 hermaphrodite. <strong class='noun'> —n. </strong> Bisexual person. bisexuality n.</p>",
      },
    ],
  },
  {
    word: "Bishop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bishop <strong class='noun'> n. </strong> 1 senior clergyman in charge of a diocese. 2 mitre-shaped chess piece. [greek episkopos overseer]</p>",
      },
    ],
  },
  {
    word: "Bishopric",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bishopric <strong class='noun'> n. </strong> Office or diocese of a bishop.</p>",
      },
    ],
  },
  {
    word: "Bismuth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bismuth <strong class='noun'> n. </strong> 1 reddish-white metallic element used in alloys etc. 2 compound of it used medicinally. [german]</p>",
      },
    ],
  },
  {
    word: "Bison",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bison <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> wild hump-backed ox of europe or <strong class='noun'> n. </strong> America. [latin from germanic]</p>",
      },
    ],
  },
  {
    word: "Bisque1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bisque1 <strong class='noun'> n. </strong> Rich soup, esp. Of lobster. [french]</p>",
      },
    ],
  },
  {
    word: "Bisque2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bisque2 <strong class='noun'> n. </strong> Advantage of one free point or stroke in certain games. [french]</p>",
      },
    ],
  },
  {
    word: "Bisque3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bisque3 <strong class='noun'> n. </strong> = *biscuit 2.</p>",
      },
    ],
  },
  {
    word: "Bister",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bister <strong class='noun'> n. </strong> (brit. Bistre) brownish pigment from wood soot. [french]</p>",
      },
    ],
  },
  {
    word: "Bistre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bistre <strong class='noun'> n. </strong> (us bister) brownish pigment from wood soot. [french]</p>",
      },
    ],
  },
  {
    word: "Bistro",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bistro <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> small informal restaurant. [french]</p>",
      },
    ],
  },
  {
    word: "Bit1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bit1 <strong class='noun'> n. </strong> 1 small piece or quantity. 2 (prec. By a) fair amount (sold quite a bit). 3 often <strong class='colloq'> colloq. </strong> Short or small time, distance, or amount (wait a bit; move up a bit; a bit tired; a bit of an idiot). bit by bit gradually. Do one's bit <strong class='colloq'> colloq. </strong> Make a useful contribution. [old english]</p>",
      },
    ],
  },
  {
    word: "Bit2  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bit2  <strong class='slang_v'>past of *</strong> bite.</p>",
      },
    ],
  },
  {
    word: "Bit3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bit3 <strong class='noun'> n. </strong> 1 metal mouthpiece of a bridle. 2 tool or piece for boring or drilling. 3 cutting or gripping part of a plane, pincers, etc. [old english]</p>",
      },
    ],
  },
  {
    word: "Bit4",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bit4 <strong class='noun'> n. </strong> Computing unit of information expressed as a choice between two possibilities. [binary digit]</p>",
      },
    ],
  },
  {
    word: "Bitch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bitch <strong class='noun'> —n. </strong> 1 female dog or other canine animal. 2 slang offens. Spiteful woman. 3 slang unpleasant or difficult thing. —v. 1 speak scathingly or spitefully. 2 complain. [old english]</p>",
      },
    ],
  },
  {
    word: "Bitchy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bitchy <strong class='adj'> adj. </strong> (-ier, -iest) slang spiteful. bitchily <strong class='adv'> adv. </strong> Bitchiness n.</p>",
      },
    ],
  },
  {
    word: "Bite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bite <strong class='noun'> —v. </strong> (-ting; past bit;  <strong class='archaic_it_was'>past part.</strong> Bitten) 1 cut or puncture with the teeth. 2 (foll. By off, away, etc.) Detach thus. 3 (of an insect etc.) Sting. 4 (of a wheel etc.) Grip, penetrate. 5 accept bait or an inducement. 6 be harsh in effect, esp. Intentionally. 7 (in passive) a swindle. B (foll. By by, with, etc.) Be infected by (enthusiasm etc.). 8 <strong class='colloq'> colloq. </strong> Worry, perturb. 9 cause smarting pain (biting wind). 10 be sharp or effective (biting wit). 11 (foll. By at) snap at. <strong class='noun'> —n. </strong> 1 act of biting. 2 wound etc. Made by biting. 3 a mouthful of food. B snack. 4 taking of bait by a fish. 5 pungency (esp. Of flavour). 6 incisiveness, sharpness. 7 position of the teeth when the jaws are closed. bite the dust slang die. Bite a person's head off <strong class='colloq'> colloq. </strong> Respond angrily. Bite one's lip repress emotion etc. [old english]</p>",
      },
    ],
  },
  {
    word: "Bit on the side",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bit on the side <strong class='noun'> n. </strong> Slang sexual relationship involving infidelity.</p>",
      },
    ],
  },
  {
    word: "Bit part",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bit part <strong class='noun'> n. </strong> Minor role.</p>",
      },
    ],
  },
  {
    word: "Bitter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bitter <strong class='adj'> —adj.</strong> 1 having a sharp pungent taste; not sweet. 2 causing, showing, or feeling mental pain or resentment (bitter memories). 3 a harsh; virulent (bitter animosity). B piercingly cold. <strong class='noun'> —n. </strong> 1 beer flavoured with hops and tasting slightly bitter. 2 (in pl.) Liquor flavoured esp. With wormwood, used in cocktails. to the bitter end to the very end in spite of difficulties. bitterly <strong class='adv'> adv. </strong> Bitterness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Bittern",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bittern <strong class='noun'> n. </strong> Wading bird of the heron family. [french butor from latin butio]</p>",
      },
    ],
  },
  {
    word: "Bitter-sweet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bitter-sweet <strong class='adj'> —adj.</strong> Sweet with a bitter aftertaste. <strong class='noun'> —n. </strong> 1 such sweetness. 2 = *woody nightshade.</p>",
      },
    ],
  },
  {
    word: "Bitty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bitty <strong class='adj'> adj. </strong> (-ier, -iest) made up of bits; scrappy.</p>",
      },
    ],
  },
  {
    word: "Bitumen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bitumen <strong class='noun'> n. </strong> Tarlike mixture of hydrocarbons derived from petroleum. [latin]</p>",
      },
    ],
  },
  {
    word: "Bituminous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bituminous <strong class='adj'> adj. </strong> Of or like bitumen.</p>",
      },
    ],
  },
  {
    word: "Bituminous coal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bituminous coal <strong class='noun'> n. </strong> Coal burning with a smoky flame.</p>",
      },
    ],
  },
  {
    word: "Bivalve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bivalve <strong class='noun'> —n. </strong> Aquatic mollusc with a hinged double shell, e.g. The oyster and mussel. <strong class='adj'> —adj.</strong> With such a shell.</p>",
      },
    ],
  },
  {
    word: "Bivouac",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bivouac <strong class='noun'> —n. </strong> Temporary open encampment without tents. —v. (-ck-) make, or camp in, a bivouac. [french, probably from german]</p>",
      },
    ],
  },
  {
    word: "Biz",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Biz <strong class='noun'> n. </strong> Colloq. Business. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Bizarre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bizarre <strong class='adj'> adj. </strong> Strange; eccentric; grotesque. [french]</p>",
      },
    ],
  },
  {
    word: "Bk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bk <strong class='prep'> symb. </strong> Berkelium.</p>",
      },
    ],
  },
  {
    word: "Bl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bl <strong class='sing'> abbr. </strong> British library.</p>",
      },
    ],
  },
  {
    word: "Blab",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blab <strong class='verb'> v. </strong> (-bb-) 1 talk foolishly or indiscreetly. 2 reveal (a secret etc.); confess. [imitative]</p>",
      },
    ],
  },
  {
    word: "Blabber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blabber <strong class='noun'> —n. </strong> (also blabbermouth) person who blabs. —v. (often foll. By on) talk foolishly or inconsequentially.</p>",
      },
    ],
  },
  {
    word: "Black",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black <strong class='adj'> —adj.</strong> 1 reflecting no light, colourless from lack of light (like coal or soot); completely dark. 2 (black) of the human group with dark-coloured skin, esp. African. 3 (of the sky etc.) Heavily overcast. 4 angry; gloomy (black look, mood). 5 implying disgrace etc. (in his black books). 6 wicked, sinister, deadly. 7 portending trouble (things look black). 8 comic but sinister (black comedy). 9 (of tea or coffee) without milk. 10 (of industrial labour or its products) boycotted, esp. By a trade union, in a strike etc. <strong class='noun'> —n. </strong> 1 black colour or pigment. 2 black clothes or material (dressed in black). 3 a (in a game) black piece, ball, etc. B player of this. 4 credit side of an account (in the black).</p>",
      },
    ],
  },
  {
    word: "Black",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black <strong class='adj'> —adj.</strong> 1 reflecting no light, colourless from lack of light (like coal or soot); completely dark. 2 (black) of the human group with dark-coloured skin, esp. African. 3 (of the sky etc.) Heavily overcast. 4 angry; gloomy (black look, mood). 5 implying disgrace etc. (in his black books). 6 wicked, sinister, deadly. 7 portending trouble (things look black). 8 comic but sinister (black comedy). 9 (of tea or coffee) without milk. 10 (of industrial labour or its products) boycotted, esp. By a trade union, in a strike etc. <strong class='noun'> —n. </strong> 1 black colour or pigment. 2 black clothes or material (dressed in black). 3 a (in a game) black piece, ball, etc. B player of this. 4 credit side of an account (in the black). 5 (black) member of a dark-skinned race, esp. An african. —v. 1 make black (blacked his boots). 2 declare (goods etc.) ‘black’. black out 1 effect a blackout on. 2 undergo a blackout. [old english]</p>",
      },
    ],
  },
  {
    word: "Black and blue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black and blue <strong class='adj'> adj. </strong> Bruised.</p>",
      },
    ],
  },
  {
    word: "Black and white",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black and white <strong class='noun'> —n. </strong> Writing or printing (in black and white). <strong class='adj'> —adj.</strong> 1 (of a film etc.) Monochrome. 2 consisting of extremes only, oversimplified.</p>",
      },
    ],
  },
  {
    word: "Black art",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black art <strong class='noun'> n. </strong> = *black magic.</p>",
      },
    ],
  },
  {
    word: "Blackball",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackball <strong class='verb'> v. </strong> Reject (a candidate) in a ballot.</p>",
      },
    ],
  },
  {
    word: "Black beetle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black beetle <strong class='noun'> n. </strong> The common cockroach.</p>",
      },
    ],
  },
  {
    word: "Black belt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black belt <strong class='noun'> n. </strong> 1 highest grade of proficiency in judo, karate, etc. 2 holder of this grade, entitled to wear a black belt.</p>",
      },
    ],
  },
  {
    word: "Blackberry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackberry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> black fleshy edible fruit of the bramble.</p>",
      },
    ],
  },
  {
    word: "Blackbird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackbird <strong class='noun'> n. </strong> Common thrush of which the male is black with an orange beak.</p>",
      },
    ],
  },
  {
    word: "Blackboard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackboard <strong class='noun'> n. </strong> Board with a smooth dark surface for writing on with chalk.</p>",
      },
    ],
  },
  {
    word: "Black box",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black box <strong class='noun'> n. </strong> Flight-recorder.</p>",
      },
    ],
  },
  {
    word: "Blackcap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackcap <strong class='noun'> n. </strong> Small warbler, the male of which has a black-topped head.</p>",
      },
    ],
  },
  {
    word: "Black country",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black country <strong class='noun'> n. </strong> (prec. By the) industrial area of the midlands.</p>",
      },
    ],
  },
  {
    word: "Blackcurrant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackcurrant <strong class='noun'> n. </strong> 1 cultivated flowering shrub. 2 its small dark edible berry.</p>",
      },
    ],
  },
  {
    word: "Black death",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black death <strong class='noun'> n. </strong> (prec. By the) 14th-c. Plague in europe.</p>",
      },
    ],
  },
  {
    word: "Black economy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black economy <strong class='noun'> n. </strong> Unofficial and untaxed trade.</p>",
      },
    ],
  },
  {
    word: "Blacken",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blacken <strong class='verb'> v. </strong> 1 make or become black or dark. 2 defame, slander.</p>",
      },
    ],
  },
  {
    word: "Black eye",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black eye <strong class='noun'> n. </strong> Bruised skin around the eye.</p>",
      },
    ],
  },
  {
    word: "Black flag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black flag <strong class='noun'> n. </strong> Flag of piracy.</p>",
      },
    ],
  },
  {
    word: "Blackfly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackfly <strong class='noun'> n. </strong> 1 dark coloured thrips or aphid. 2 these collectively.</p>",
      },
    ],
  },
  {
    word: "Black forest gateau",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black forest gateau <strong class='noun'> n. </strong> Chocolate sponge with cherries and whipped cream.</p>",
      },
    ],
  },
  {
    word: "Black friar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black friar <strong class='noun'> n. </strong> Dominican friar.</p>",
      },
    ],
  },
  {
    word: "Blackguard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackguard <strong class='noun'> n. </strong> Villain, scoundrel. blackguardly adj. [originally = menial]</p>",
      },
    ],
  },
  {
    word: "Blackhead",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackhead <strong class='noun'> n. </strong> Black-topped pimple on the skin.</p>",
      },
    ],
  },
  {
    word: "Black hole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black hole <strong class='noun'> n. </strong> Region of space from which matter and radiation cannot escape.</p>",
      },
    ],
  },
  {
    word: "Black ice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black ice <strong class='noun'> n. </strong> Thin hard transparent ice on a road etc.</p>",
      },
    ],
  },
  {
    word: "Blacking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blacking <strong class='noun'> n. </strong> Black polish, esp. For shoes.</p>",
      },
    ],
  },
  {
    word: "Blackjack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackjack <strong class='noun'> n. </strong> = *pontoon1.</p>",
      },
    ],
  },
  {
    word: "Blacklead",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blacklead <strong class='noun'> n. </strong> Graphite.</p>",
      },
    ],
  },
  {
    word: "Blackleg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackleg <strong class='noun'> —n. </strong> Derog. Person refusing to join a strike etc. —v. <strong class='gg'> (-gg-) </strong> act as a blackleg.</p>",
      },
    ],
  },
  {
    word: "Blacklist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blacklist <strong class='noun'> —n. </strong> List of people in disfavour etc. —v. Put on a blacklist.</p>",
      },
    ],
  },
  {
    word: "Black magic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black magic <strong class='noun'> n. </strong> Magic supposed to invoke evil spirits.</p>",
      },
    ],
  },
  {
    word: "Blackmail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackmail <strong class='noun'> —n. </strong> 1 a extortion of payment in return for silence. B payment so extorted. 2 use of threats or moral pressure. —v. 1 (try to) extort money etc. From by blackmail. 2 threaten, coerce. blackmailer <strong class='noun'> n. </strong> [obsolete mail rent]</p>",
      },
    ],
  },
  {
    word: "Black maria",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black maria <strong class='noun'> n. </strong> Slang police van.</p>",
      },
    ],
  },
  {
    word: "Black mark",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black mark <strong class='noun'> n. </strong> Mark of discredit.</p>",
      },
    ],
  },
  {
    word: "Black market",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black market <strong class='noun'> n. </strong> Illicit trade in rationed, prohibited, or scarce commodities. black marketeer n.</p>",
      },
    ],
  },
  {
    word: "Black mass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black mass <strong class='noun'> n. </strong> Travesty of the mass, in worship of satan.</p>",
      },
    ],
  },
  {
    word: "Blackout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackout <strong class='noun'> n. </strong> 1 temporary loss of consciousness or memory. 2 loss of electric power, radio reception, etc. 3 compulsory darkness as a precaution against air raids. 4 temporary suppression of news. 5 sudden darkening of a theatre stage.</p>",
      },
    ],
  },
  {
    word: "Black pepper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black pepper <strong class='noun'> n. </strong> Pepper made by grinding the whole dried pepper berry including the outer husk.</p>",
      },
    ],
  },
  {
    word: "Black power",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black power <strong class='noun'> n. </strong> Movement for black rights and political power.</p>",
      },
    ],
  },
  {
    word: "Black pudding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black pudding <strong class='noun'> n. </strong> Sausage of pork, dried pig's blood, suet, etc.</p>",
      },
    ],
  },
  {
    word: "Black rod",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black rod <strong class='noun'> n. </strong> Principal usher of the house of lords etc.</p>",
      },
    ],
  },
  {
    word: "Black sheep",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black sheep <strong class='noun'> n. </strong> Colloq. Member of a family, group, etc. Regarded as a disgrace or failure.</p>",
      },
    ],
  },
  {
    word: "Blackshirt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackshirt <strong class='noun'> n. </strong> Hist.member of a fascist organization.</p>",
      },
    ],
  },
  {
    word: "Blacksmith",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blacksmith <strong class='noun'> n. </strong> Smith who works in iron.</p>",
      },
    ],
  },
  {
    word: "Black spot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black spot <strong class='noun'> n. </strong> 1 place of danger or trouble. 2 plant disease producing black spots.</p>",
      },
    ],
  },
  {
    word: "Black tea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black tea <strong class='noun'> n. </strong> Tea that is fully fermented before drying.</p>",
      },
    ],
  },
  {
    word: "Blackthorn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blackthorn <strong class='noun'> n. </strong> Thorny shrub bearing white blossom and sloes.</p>",
      },
    ],
  },
  {
    word: "Black tie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black tie <strong class='noun'> n. </strong> 1 black bow-tie worn with a dinner jacket. 2 <strong class='colloq'> colloq. </strong> Man's formal evening dress.</p>",
      },
    ],
  },
  {
    word: "Black velvet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black velvet <strong class='noun'> n. </strong> Mixture of stout and champagne.</p>",
      },
    ],
  },
  {
    word: "Black watch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black watch <strong class='noun'> n. </strong> (prec. By the) royal highland regiment.</p>",
      },
    ],
  },
  {
    word: "Black widow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Black widow <strong class='noun'> n. </strong> Venomous spider of which the female devours the male.</p>",
      },
    ],
  },
  {
    word: "Bladder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bladder <strong class='noun'> n. </strong> 1 a sac in some animals, esp. That holding urine. B this adapted for various uses. 2 inflated blister in seaweed etc. [old english]</p>",
      },
    ],
  },
  {
    word: "Bladderwrack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bladderwrack <strong class='noun'> n. </strong> Brown seaweed with air bladders.</p>",
      },
    ],
  },
  {
    word: "Blade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blade <strong class='noun'> n. </strong> 1 cutting part of a knife etc. 2 flattened part of an oar, propeller, etc. 3 a flat narrow leaf of grass etc. B broad thin part of a leaf. 4 flat bone, e.g. In the shoulder. [old english]</p>",
      },
    ],
  },
  {
    word: "Blame",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blame <strong class='noun'> —v. </strong> (-ming) 1 assign fault or responsibility to. 2 (foll. By on) fix responsibility for (an error etc.) On (blamed it on his brother). <strong class='noun'> —n. </strong> 1 responsibility for an error etc. 2 blaming or attributing of responsibility (got all the blame). be to blame be responsible; deserve censure. blameable adj. Blameless adj. Blameworthy adj. [french: related to *blaspheme]</p>",
      },
    ],
  },
  {
    word: "Blanch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blanch <strong class='verb'> v. </strong> 1 make or become white or pale. 2 a peel (almonds etc.) By scalding. B immerse (vegetables etc.) Briefly in boiling water. 3 whiten (a plant) by depriving it of light. [french: related to *blank]</p>",
      },
    ],
  },
  {
    word: "Blancmange",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blancmange <strong class='noun'> n. </strong> Sweet opaque jelly of flavoured cornflour and milk. [french, = white food]</p>",
      },
    ],
  },
  {
    word: "Bland",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bland <strong class='adj'> adj. </strong> 1 a mild, not irritating. B tasteless; insipid. 2 gentle in manner; suave. blandly <strong class='adv'> adv. </strong> Blandness <strong class='noun'> n. </strong> [latin blandus smooth]</p>",
      },
    ],
  },
  {
    word: "Blandish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blandish <strong class='verb'> v. </strong> Flatter; coax. blandishment <strong class='noun'> n. </strong> (usu. In pl.). [latin: related to *bland]</p>",
      },
    ],
  },
  {
    word: "Blank",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blank <strong class='adj'> —adj.</strong> 1 a (of paper) not written or printed on. B (of a document) with spaces left for a signature or details. 2 a empty (blank space). B unrelieved (blank wall). 3 a without interest, result, or expression (blank face). B having (temporarily) no knowledge etc. (mind went blank). 4 complete (a blank refusal; blank despair). <strong class='noun'> —n. </strong> 1 a unfilled space, esp. In a document. B document having blank spaces. 2 (in full blank cartridge) cartridge containing gunpowder but no bullet. 3 dash written instead of a word or letter. —v. (usu. Foll. By off, out) screen, obscure. draw a blank get no response; fail. blankly <strong class='adv'> adv. </strong> Blankness <strong class='noun'> n. </strong> [french blanc white, from germanic]</p>",
      },
    ],
  },
  {
    word: "Blank cheque",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blank cheque <strong class='noun'> n. </strong> 1 cheque left for the payee to fill in. 2 <strong class='colloq'> colloq. </strong> Unlimited freedom of action.</p>",
      },
    ],
  },
  {
    word: "Blanket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blanket <strong class='noun'> —n. </strong> 1 large esp. Woollen sheet used as a bed-covering etc. 2 thick covering mass or layer. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Covering everything; inclusive. —v. (-t-) 1 cover. 2 stifle, suppress. [french: related to *blank]</p>",
      },
    ],
  },
  {
    word: "Blanket bath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blanket bath <strong class='noun'> n. </strong> Body wash given to a bedridden patient.</p>",
      },
    ],
  },
  {
    word: "Blanket stitch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blanket stitch <strong class='noun'> n. </strong> Stitch used to finish the edges of a blanket etc.</p>",
      },
    ],
  },
  {
    word: "Blank verse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blank verse <strong class='noun'> n. </strong> Unrhymed verse, esp. Iambic pentameters.</p>",
      },
    ],
  },
  {
    word: "Blare",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blare <strong class='noun'> —v. </strong> <strong class='adj'> (-ring) </strong> 1 sound or utter loudly. 2 make the sound of a trumpet. <strong class='noun'> —n. </strong> Blaring sound. [low german or dutch, imitative]</p>",
      },
    ],
  },
  {
    word: "Blarney",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blarney <strong class='noun'> —n. </strong> Cajoling talk; flattery. —v. (-eys, -eyed) flatter, cajole. [blarney, castle near cork]</p>",
      },
    ],
  },
  {
    word: "Blasé",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blasé <strong class='adj'> adj. </strong> Bored or indifferent through over-familiarity. [french]</p>",
      },
    ],
  },
  {
    word: "Blaspheme",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blaspheme <strong class='verb'> v. </strong> (-ming) 1 use religious names irreverently; treat a religious or sacred subject irreverently. 2 talk irreverently about; use blasphemy against. [greek blasphemeo]</p>",
      },
    ],
  },
  {
    word: "Blasphemy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blasphemy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 irreverent talk or treatment of a religious or sacred thing. 2 instance of this. blasphemous adj.</p>",
      },
    ],
  },
  {
    word: "Blast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blast <strong class='noun'> —n. </strong> 1 strong gust of air. 2 a explosion. B destructive wave of air from this. 3 loud note from a wind instrument, car horn, etc. 4 <strong class='colloq'> colloq. </strong> Severe reprimand. —v. 1 blow up with explosives. 2 wither, blight (blasted oak; blasted her hopes). 3 (cause to) make a loud noise. <strong class='pl'> —int. </strong> Expressing annoyance. at full blast <strong class='colloq'> colloq. </strong> At maximum volume, speed, etc. Blast off take off from a launching site. [old english]</p>",
      },
    ],
  },
  {
    word: "Blasted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blasted <strong class='colloq'> colloq. </strong> <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Damned; annoying. <strong class='adv'> —adv. </strong> Damned; extremely.</p>",
      },
    ],
  },
  {
    word: "Blast-furnace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blast-furnace <strong class='noun'> n. </strong> Smelting furnace into which hot air is driven.</p>",
      },
    ],
  },
  {
    word: "Blast-off",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blast-off <strong class='noun'> n. </strong> Launching of a rocket etc.</p>",
      },
    ],
  },
  {
    word: "Blatant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blatant <strong class='adj'> adj. </strong> 1 flagrant, unashamed. 2 loudly obtrusive. blatantly <strong class='adv'> adv. </strong> [coined by spenser]</p>",
      },
    ],
  },
  {
    word: "Blather",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blather <strong class='general'> (also blether)</strong>  <strong class='noun'> —n. </strong> Foolish talk. —v. Talk foolishly. [old norse]</p>",
      },
    ],
  },
  {
    word: "Blaze1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blaze1 <strong class='noun'> —n. </strong> 1 bright flame or fire. 2 violent outburst (of passion etc.). 3 brilliant display (blaze of scarlet, of glory). —v. (-zing) 1 burn or shine brightly or fiercely. 2 be consumed with anger, excitement, etc. blaze away (often foll. By at) 1 shoot continuously. 2 work vigorously. [old english, = torch]</p>",
      },
    ],
  },
  {
    word: "Blaze2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blaze2 <strong class='noun'> —n. </strong> 1 white mark on an animal's face. 2 mark cut on a tree, esp. To show a route. —v. (-zing) mark (a tree or a path) with blazes. blaze a trail show the way for others. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Blazer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blazer <strong class='noun'> n. </strong> Jacket without matching trousers, esp. Lightweight and often part of a uniform. [from *blaze1]</p>",
      },
    ],
  },
  {
    word: "Blazon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blazon <strong class='noun'> —v. </strong> 1 proclaim (esp. Blazon abroad). 2 heraldry describe or paint (arms). <strong class='noun'> —n. </strong> Heraldry shield or coat of arms. blazonment <strong class='noun'> n. </strong> Blazonry <strong class='noun'> n. </strong> [french, originally = shield]</p>",
      },
    ],
  },
  {
    word: "Bleach",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleach <strong class='noun'> —v. </strong> Whiten in sunlight or by a chemical process. <strong class='noun'> —n. </strong> Bleaching substance or process. [old english]</p>",
      },
    ],
  },
  {
    word: "Bleak",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleak <strong class='adj'> adj. </strong> 1 exposed, windswept. 2 dreary, grim. [old norse]</p>",
      },
    ],
  },
  {
    word: "Bleary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleary <strong class='adj'> adj. </strong> (-ier, -iest) 1 dim; blurred. 2 indistinct. [low german]</p>",
      },
    ],
  },
  {
    word: "Bleary-eyed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleary-eyed <strong class='adj'> adj. </strong> Having dim sight.</p>",
      },
    ],
  },
  {
    word: "Bleat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleat <strong class='noun'> —v. </strong> 1 (of a sheep, goat, or calf) make a wavering cry. 2 (often foll. By out) speak or say plaintively. <strong class='noun'> —n. </strong> Bleating cry. [old english]</p>",
      },
    ],
  },
  {
    word: "Bleed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleed <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Bled) 1 emit blood. 2 draw blood from surgically. 3 <strong class='colloq'> colloq. </strong> Extort money from. 4 (often foll. By for) suffer wounds or violent death. 5 a emit sap. B (of dye) come out in water. 6 empty (a system) of excess air or fluid. <strong class='noun'> —n. </strong> Act of bleeding. one's heart bleeds usu. Iron. One is very sorrowful. [old english]</p>",
      },
    ],
  },
  {
    word: "Bleeder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleeder <strong class='noun'> n. </strong> Coarse slang unpleasant or contemptible person.</p>",
      },
    ],
  },
  {
    word: "Bleeding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleeding <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Coarse slang expressing annoyance or antipathy.</p>",
      },
    ],
  },
  {
    word: "Bleep",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleep <strong class='noun'> —n. </strong> Intermittent high-pitched electronic sound. —v. 1 make a bleep. 2 summon with a bleeper. [imitative]</p>",
      },
    ],
  },
  {
    word: "Bleeper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bleeper <strong class='noun'> n. </strong> Small electronic device bleeping to contact the carrier.</p>",
      },
    ],
  },
  {
    word: "Blemish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blemish <strong class='noun'> —n. </strong> Flaw, defect, or stain. —v. Spoil, mark, or stain. [french]</p>",
      },
    ],
  },
  {
    word: "Blench",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blench <strong class='verb'> v. </strong> Flinch, quail. [old english]</p>",
      },
    ],
  },
  {
    word: "Blend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blend <strong class='noun'> —v. </strong> 1 mix together as required. 2 become one. 3 (often foll. By with, in) mingle; mix thoroughly. 4 (esp. Of colours) merge imperceptibly; harmonize. <strong class='noun'> —n. </strong> Mixture. [old norse]</p>",
      },
    ],
  },
  {
    word: "Blender",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blender <strong class='noun'> n. </strong> Machine for liquidizing, chopping, or puréeing food.</p>",
      },
    ],
  },
  {
    word: "Blenny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blenny <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> small spiny-finned scaleless marine fish. [greek blennos mucus]</p>",
      },
    ],
  },
  {
    word: "Bless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bless <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Blessed, poet. Blest) 1 ask god to look favourably on, esp. By making the sign of the cross over. 2 consecrate (food etc.). 3 glorify (god). 4 attribute one's good luck to (stars etc.); thank. 5 (usu. In passive) make happy or successful (blessed with children). bless me (or my soul) exclamation of surprise etc. Bless you! Exclamation of endearment, gratitude, etc., or to a person who has just sneezed. [old english]</p>",
      },
    ],
  },
  {
    word: "Blessed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blessed <strong class='adj'> adj. </strong> (also poet. Blest) 1 holy. 2 euphem. Cursed (blessed nuisance!). 3 rc ch. Beatified. blessedness n.</p>",
      },
    ],
  },
  {
    word: "Blessing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blessing <strong class='noun'> n. </strong> 1 invocation of (esp. Divine) favour. 2 grace said at a meal. 3 benefit.</p>",
      },
    ],
  },
  {
    word: "Blether",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blether <strong class='var'> var. </strong> Of *blather.</p>",
      },
    ],
  },
  {
    word: "Blew  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blew  <strong class='slang_v'>past of *</strong> blow1.</p>",
      },
    ],
  },
  {
    word: "Blether",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blether <strong class='var'> var. </strong> Of *blather.</p>",
      },
    ],
  },
  {
    word: "Blew  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blew  <strong class='slang_v'>past of *</strong> blow1.</p>",
      },
    ],
  },
  {
    word: "Blight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blight <strong class='noun'> —n. </strong> 1 plant disease caused by insects etc. 2 such an insect etc. 3 harmful or destructive force. 4 ugly urban area. —v. 1 affect with blight. 2 harm, destroy. 3 spoil. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Blighter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blighter <strong class='noun'> n. </strong> Colloq. Contemptible or annoying person.</p>",
      },
    ],
  },
  {
    word: "Blighty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blighty <strong class='noun'> n. </strong> Mil. Slang england; home. [hindustani, = foreign]</p>",
      },
    ],
  },
  {
    word: "Blimey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blimey <strong class='n_pl'> int. </strong> Coarse slang expression of surprise, contempt, etc. [(god) blind me!]</p>",
      },
    ],
  },
  {
    word: "Blimp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blimp <strong class='noun'> n. </strong> 1 (also (colonel) blimp) reactionary person. 2 small non-rigid airship. 3 soundproof cover for a cine-camera. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Blind",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blind <strong class='adj'> —adj.</strong> 1 lacking the power of sight. 2 a without adequate foresight, discernment, or information (blind effort). B <strong class='adj'> (often foll. By to) </strong> unwilling or unable to appreciate a factor etc. (blind to argument). 3 not governed by purpose or reason (blind forces). 4 reckless (blind hitting). 5 a concealed (blind ditch). B closed at one end. 6 (of flying) using instruments only. 7 cookery (of a flan case etc.) Baked without a filling. —v. 1 deprive of sight. 2 rob of judgement; deceive; overawe. 3 slang go recklessly. <strong class='noun'> —n. </strong> 1 screen for a window; awning. 2 thing used to hide the truth. 3 obstruction to sight or light. <strong class='adv'> —adv. </strong> Blindly. blindly <strong class='adv'> adv. </strong> Blindness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Blind alley",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blind alley <strong class='noun'> n. </strong> 1 alley closed at one end. 2 futile course.</p>",
      },
    ],
  },
  {
    word: "Blind date",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blind date <strong class='noun'> n. </strong> Colloq. Date between two people who have not previously met.</p>",
      },
    ],
  },
  {
    word: "Blind drunk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blind drunk <strong class='adj'> adj. </strong> Colloq. Extremely drunk.</p>",
      },
    ],
  },
  {
    word: "Blindfold",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blindfold <strong class='noun'> —v. </strong> Cover the eyes of (a person) with a tied cloth etc. <strong class='noun'> —n. </strong> Cloth etc. So used. <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> 1 with eyes covered. 2 without due care. [originally blindfelled = struck blind]</p>",
      },
    ],
  },
  {
    word: "Blind man's buff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blind man's buff <strong class='noun'> n. </strong> Game in which a blindfold player tries to catch others.</p>",
      },
    ],
  },
  {
    word: "Blind spot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blind spot <strong class='noun'> n. </strong> 1 point on the retina insensitive to light. 2 area where vision or understanding is lacking.</p>",
      },
    ],
  },
  {
    word: "Blindworm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blindworm <strong class='noun'> n. </strong> = *slow-worm.</p>",
      },
    ],
  },
  {
    word: "Blink",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blink <strong class='noun'> —v. </strong> 1 shut and open the eyes quickly. 2 (often foll. By back) prevent (tears) by blinking. 3 shine unsteadily, flicker. <strong class='noun'> —n. </strong> 1 act of blinking. 2 momentary gleam or glimpse. blink at 1 look at while blinking. 2 ignore, shirk. On the blink slang not working properly; out of order. [dutch, <strong class='var'> var. </strong> Of *blench]</p>",
      },
    ],
  },
  {
    word: "Blinker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blinker <strong class='noun'> —n. </strong> 1 (usu. In pl.) Each of two screens on a bridle preventing lateral vision. 2 device that blinks. —v. 1 obscure with blinkers. 2 (as blinkered adj.) Having narrow and prejudiced views.</p>",
      },
    ],
  },
  {
    word: "Blinking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blinking <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Slang expressing annoyance etc. (it's blinking stupid).</p>",
      },
    ],
  },
  {
    word: "Blip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blip <strong class='noun'> —n. </strong> 1 minor deviation or error. 2 quick popping sound. 3 small image on a radar screen. —v. (-pp-) make a blip. [imitative]</p>",
      },
    ],
  },
  {
    word: "Bliss",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bliss <strong class='noun'> n. </strong> 1 perfect joy. 2 being in heaven. blissful adj. Blissfully <strong class='adv'> adv. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Blister",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blister <strong class='noun'> —n. </strong> 1 small bubble on the skin filled with watery fluid and caused by heat or friction. 2 similar swelling on plastic, wood, etc. —v. 1 come up in blisters. 2 raise a blister on. 3 attack sharply. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Blithe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blithe <strong class='adj'> adj. </strong> 1 cheerful, happy. 2 careless, casual. blithely <strong class='adv'> adv. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Blithering",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blithering <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Colloq. Hopeless; contemptible (esp. In blithering idiot). [blither, <strong class='var'> var. </strong> Of *blather]</p>",
      },
    ],
  },
  {
    word: "Blitt.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blitt. <strong class='abbr'> Abbr. </strong> Bachelor of letters. [latin baccalaureus litterarum]</p>",
      },
    ],
  },
  {
    word: "Blitz",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blitz <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> 1 a intensive or sudden (esp. Aerial) attack. B intensive period of work etc. (must have a blitz on this room). 2 (the blitz) german air raids on london in 1940. —v. Inflict a blitz on. [abbreviation of *blitzkrieg]</p>",
      },
    ],
  },
  {
    word: "Blitzkrieg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blitzkrieg <strong class='noun'> n. </strong> Intense military campaign intended to bring about a swift victory. [german, = lightning war]</p>",
      },
    ],
  },
  {
    word: "Blizzard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blizzard <strong class='noun'> n. </strong> Severe snowstorm. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bloat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloat <strong class='verb'> v. </strong> 1 inflate, swell. 2 (as bloated adj.) Inflated with pride, wealth, or food. 3 cure (a herring) by salting and smoking lightly. [old norse]</p>",
      },
    ],
  },
  {
    word: "Bloater",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloater <strong class='noun'> n. </strong> Bloated herring.</p>",
      },
    ],
  },
  {
    word: "Blob",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blob <strong class='noun'> n. </strong> Small drop or spot. [imitative]</p>",
      },
    ],
  },
  {
    word: "Bloc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloc <strong class='noun'> n. </strong> Group of governments etc. Sharing a common purpose. [french: related to *block]</p>",
      },
    ],
  },
  {
    word: "Block",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Block <strong class='noun'> —n. </strong> 1 solid piece of hard material, esp. Stone or wood. 2 this as a base for chopping etc., as a stand, or for mounting a horse from. 3 a large building, esp. When subdivided. B group of buildings between streets. 4 obstruction. 5 two or more pulleys mounted in a case. 6 piece of wood or metal engraved for printing. 7 slang head. 8 (often attrib.) Number of things as a unit, e.g. Shares, theatre seats (block booking). 9 sheets of paper glued along one edge. —v. 1 a (often foll. By up) obstruct. B impede. 2 restrict the use of. 3 cricket stop (a ball) with a bat defensively. block in 1 sketch roughly; plan. 2 confine. Block out 1 shut out (light, noise, a memory, view, etc.). 2 sketch roughly; plan. Block up confine; enclose. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Blockade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blockade <strong class='noun'> —n. </strong> Surrounding or blocking of a place by an enemy to prevent entry and exit. —v. (-ding) subject to a blockade.</p>",
      },
    ],
  },
  {
    word: "Blockage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blockage <strong class='noun'> n. </strong> Obstruction.</p>",
      },
    ],
  },
  {
    word: "Block and tackle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Block and tackle <strong class='noun'> n. </strong> System of pulleys and ropes, esp. For lifting.</p>",
      },
    ],
  },
  {
    word: "Blockbuster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blockbuster <strong class='noun'> n. </strong> Slang 1 thing of great power, esp. A very successful film, book, etc. 2 highly destructive bomb.</p>",
      },
    ],
  },
  {
    word: "Block capitals",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Block capitals <strong class='n_pl'> n.pl. </strong> (also block letters) letters printed without serifs, or written with each letter separate and in capitals.</p>",
      },
    ],
  },
  {
    word: "Blockhead",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blockhead <strong class='noun'> n. </strong> Stupid person.</p>",
      },
    ],
  },
  {
    word: "Blockhouse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blockhouse <strong class='noun'> n. </strong> 1 reinforced concrete shelter. 2 hist. Small fort of timber.</p>",
      },
    ],
  },
  {
    word: "Block vote",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Block vote <strong class='noun'> n. </strong> Vote proportional in power to the number of people a delegate represents.</p>",
      },
    ],
  },
  {
    word: "Bloke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloke <strong class='noun'> n. </strong> Slang man, fellow. [shelta]</p>",
      },
    ],
  },
  {
    word: "Blond  (of a woman usu. Blonde)",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blond  <strong class='general'>(of a woman usu. Blonde)</strong>  <strong class='adj'> —adj.</strong> (of a person, hair, or complexion) light-coloured, fair. <strong class='noun'> —n. </strong> Blond person. [latin blondus yellow]</p>",
      },
    ],
  },
  {
    word: "Blood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood <strong class='noun'> —n. </strong> 1 usu. Red fluid circulating in the arteries and veins of animals. 2 bloodshed, esp. Killing. 3 passion, temperament. 4 race, descent, parentage (of the same blood). 5 relationship; relations (blood is thicker than water). 6 dandy. —v. 1 give (a hound) a first taste of blood. 2 initiate (a person). in one's blood inherent in one's character. [old english]</p>",
      },
    ],
  },
  {
    word: "Blood bank",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood bank <strong class='noun'> n. </strong> Store of blood for transfusion.</p>",
      },
    ],
  },
  {
    word: "Blood bath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood bath <strong class='noun'> n. </strong> Massacre.</p>",
      },
    ],
  },
  {
    word: "Blood count",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood count <strong class='noun'> n. </strong> Number of corpuscles in a specific amount of blood.</p>",
      },
    ],
  },
  {
    word: "Blood-curdling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood-curdling <strong class='adj'> adj. </strong> Horrifying.</p>",
      },
    ],
  },
  {
    word: "Blood donor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood donor <strong class='noun'> n. </strong> Person giving blood for transfusion.</p>",
      },
    ],
  },
  {
    word: "Blood group",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood group <strong class='noun'> n. </strong> Any of the types of human blood.</p>",
      },
    ],
  },
  {
    word: "Blood-heat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood-heat <strong class='noun'> n. </strong> Normal human temperature, about 37 °c or 98.4 °f.</p>",
      },
    ],
  },
  {
    word: "Bloodhound",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloodhound <strong class='noun'> n. </strong> Large keen-scented dog used in tracking.</p>",
      },
    ],
  },
  {
    word: "Bloodless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloodless <strong class='adj'> adj. </strong> 1 without blood or bloodshed. 2 unemotional. 3 pale. 4 feeble.</p>",
      },
    ],
  },
  {
    word: "Blood-letting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood-letting <strong class='noun'> n. </strong> Surgical removal of blood.</p>",
      },
    ],
  },
  {
    word: "Blood-money",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood-money <strong class='noun'> n. </strong> 1 money paid as compensation for a death. 2 money paid to a killer.</p>",
      },
    ],
  },
  {
    word: "Blood orange",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood orange <strong class='noun'> n. </strong> Red-fleshed orange.</p>",
      },
    ],
  },
  {
    word: "Blood-poisoning",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood-poisoning <strong class='noun'> n. </strong> Diseased condition caused by micro-organisms in the blood.</p>",
      },
    ],
  },
  {
    word: "Blood pressure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood pressure <strong class='noun'> n. </strong> Pressure of the blood in the arteries etc., measured for diagnosis.</p>",
      },
    ],
  },
  {
    word: "Blood relation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood relation <strong class='noun'> n. </strong> (also blood relative) relative by birth.</p>",
      },
    ],
  },
  {
    word: "Bloodshed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bloodshed <strong class='noun'> n. </strong> Killing.</p>",
      },
    ],
  },
  {
    word: "Bloodshot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloodshot <strong class='adj'> adj. </strong> (of an eyeball) inflamed.</p>",
      },
    ],
  },
  {
    word: "Blood sport",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood sport <strong class='noun'> n. </strong> Sport involving the killing or wounding of animals.</p>",
      },
    ],
  },
  {
    word: "Bloodstain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloodstain <strong class='noun'> n. </strong> Stain caused by blood. bloodstained adj.</p>",
      },
    ],
  },
  {
    word: "Bloodstream",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloodstream <strong class='noun'> n. </strong> Blood in circulation.</p>",
      },
    ],
  },
  {
    word: "Bloodsucker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloodsucker <strong class='noun'> n. </strong> 1 leech. 2 extortioner. bloodsucking adj.</p>",
      },
    ],
  },
  {
    word: "Blood sugar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood sugar <strong class='noun'> n. </strong> Amount of glucose in the blood.</p>",
      },
    ],
  },
  {
    word: "Blood test",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood test <strong class='noun'> n. </strong> Examination of blood, esp. For diagnosis.</p>",
      },
    ],
  },
  {
    word: "Bloodthirsty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloodthirsty <strong class='adj'> adj. </strong> (-ier, -iest) eager for bloodshed.</p>",
      },
    ],
  },
  {
    word: "Blood-vessel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blood-vessel <strong class='noun'> n. </strong> Vein, artery, or capillary carrying blood.</p>",
      },
    ],
  },
  {
    word: "Bloody",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloody <strong class='adj'> —adj.</strong> (-ier, -iest) 1 of, like, running with, or smeared with blood. 2 a involving bloodshed. B bloodthirsty, cruel. 3 coarse slang expressing annoyance or antipathy, or as an intensifier (bloody fool; a bloody sight better). 4 red. <strong class='adv'> —adv. </strong> Coarse slang as an intensifier (bloody awful). —v. (-ies, -ied) stain with blood.</p>",
      },
    ],
  },
  {
    word: "Bloody mary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloody mary <strong class='noun'> n. </strong> Mixture of vodka and tomato juice.</p>",
      },
    ],
  },
  {
    word: "Bloody-minded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloody-minded <strong class='adj'> adj. </strong> Colloq. Deliberately uncooperative.</p>",
      },
    ],
  },
  {
    word: "Bloom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloom <strong class='noun'> —n. </strong> 1 a flower, esp. Cultivated. B state of flowering (in bloom). 2 one's prime (in full bloom). 3 a healthy glow of the complexion. B fine powder on fresh fruit and leaves. —v. 1 bear flowers; be in flower. 2 be in one's prime; flourish. [old norse]</p>",
      },
    ],
  },
  {
    word: "Bloomer1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloomer1 <strong class='noun'> n. </strong> 1 slang blunder. 2 plant that blooms in a specified way.</p>",
      },
    ],
  },
  {
    word: "Bloomer2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloomer2 <strong class='noun'> n. </strong> Long loaf with diagonal marks. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Bloomers",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bloomers <strong class='n_pl'> n.pl. </strong> 1 women's long loose knickers. 2 hist. Women's loose knee-length trousers. [mrs a. Bloomer, name of the originator]</p>",
      },
    ],
  },
  {
    word: "Blooming",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blooming <strong class='adj'> —adj.</strong> 1 flourishing; healthy. 2 slang an intensifier (blooming miracle). <strong class='adv'> —adv. </strong> Slang an intensifier (blooming difficult).</p>",
      },
    ],
  },
  {
    word: "Blossom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blossom <strong class='noun'> —n. </strong> 1 flower or mass of flowers, esp. Of a fruit-tree. 2 promising stage (blossom of youth). —v. 1 open into flower. 2 mature, thrive. [old english]</p>",
      },
    ],
  },
  {
    word: "Blot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blot <strong class='noun'> —n. </strong> 1 spot or stain of ink etc. 2 disgraceful act or quality. 3 blemish. —v. <strong class='adj'> (-tt-) </strong> 1 make a blot on, stain. 2 dry with blotting-paper. blot one's copybook damage one's reputation. Blot out 1 obliterate. 2 obscure (a view, sound, etc.). [probably scandinavian]</p>",
      },
    ],
  },
  {
    word: "Blotch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blotch <strong class='noun'> —n. </strong> 1 discoloured or inflamed patch on the skin. 2 irregular patch of colour. —v. Cover with blotches. blotchy adj. (-ier, -iest). [obsolete plotch, *blot]</p>",
      },
    ],
  },
  {
    word: "Blotter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blotter <strong class='noun'> n. </strong> Pad of blotting-paper.</p>",
      },
    ],
  },
  {
    word: "Blotting-paper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blotting-paper <strong class='noun'> n. </strong> Absorbent paper for drying wet ink.</p>",
      },
    ],
  },
  {
    word: "Blotto",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blotto <strong class='adj'> adj. </strong> Slang very drunk. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Blouse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blouse <strong class='noun'> —n. </strong> 1 woman's garment like a shirt. 2 upper part of a military uniform. —v. <strong class='sing'>(-sing)</strong> make (a bodice etc.) Full like a blouse. [french]</p>",
      },
    ],
  },
  {
    word: "Blouson",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blouson <strong class='noun'> n. </strong> Short blouse-shaped jacket. [french]</p>",
      },
    ],
  },
  {
    word: "Blow1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blow1 <strong class='noun'> —v. </strong> (past blew;  <strong class='archaic_it_was'>past part.</strong> Blown) 1 direct a current of air (at) esp. From the mouth. 2 drive or be driven by blowing (blew the door open). 3 (esp. Of the wind) move rapidly. 4 expel by breathing (blew smoke). 5 sound or be sounded by blowing. 6 (past part. Blowed) slang (esp. In imper.) Curse, confound (i'm blowed if i know; blow it!). 7 clear (the nose) by blowing. 8 puff, pant. 9 slang depart suddenly (from). 10 shatter etc. By an explosion. 11 make or shape (glass or a bubble) by blowing. 12 a melt from overloading (the fuse has blown). B break or burst suddenly. 13 (of a whale) eject air and water. 14 break into with explosives. 15 slang a squander (blew £20). B bungle (an opportunity etc.). C reveal (a secret etc.). <strong class='noun'> —n. </strong> 1 act of blowing. 2 a gust of wind or air. B exposure to fresh air. be blowed if one will <strong class='colloq'> colloq. </strong> Be unwilling to. Blow a gasket slang lose one's temper. Blow hot and cold <strong class='colloq'> colloq. </strong> Vacillate. Blow in 1 break inwards by an explosion. 2 <strong class='colloq'> colloq. </strong> Arrive unexpectedly. Blow a person's mind slang cause to have hallucinations etc.; astound. Blow off 1 escape or allow (steam etc.) To escape forcibly. 2 slang break wind noisily. Blow out 1 extinguish by blowing. 2 send outwards by an explosion. Blow over (of trouble etc.) Fade away. Blow one's top <strong class='colloq'> colloq. </strong> Explode in rage. Blow up 1 explode. 2 <strong class='colloq'> colloq. </strong> Rebuke strongly. 3 inflate (a tyre etc.). 4 <strong class='colloq'> colloq. </strong> A enlarge (a photograph). B exaggerate. 5 <strong class='colloq'> colloq. </strong> Arise, happen. 6 <strong class='colloq'> colloq. </strong> Lose one's temper. [old english]</p>",
      },
    ],
  },
  {
    word: "Blow2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blow2 <strong class='noun'> n. </strong> 1 hard stroke with a hand or weapon. 2 sudden shock or misfortune. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Blow-by-blow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blow-by-blow <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of a narrative etc.) Detailed.</p>",
      },
    ],
  },
  {
    word: "Blow-dry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blow-dry <strong class='noun'> —v. </strong> Arrange (the hair) while drying it. <strong class='noun'> —n. </strong> Act of doing this.</p>",
      },
    ],
  },
  {
    word: "Blower",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blower <strong class='noun'> n. </strong> 1 device for blowing. 2 <strong class='colloq'> colloq. </strong> Telephone.</p>",
      },
    ],
  },
  {
    word: "Blowfly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blowfly <strong class='noun'> n. </strong> Bluebottle.</p>",
      },
    ],
  },
  {
    word: "Blow-hole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blow-hole <strong class='noun'> n. </strong> 1 nostril of a whale. 2 hole (esp. In ice) for breathing or fishing through. 3 vent for air, smoke, etc.</p>",
      },
    ],
  },
  {
    word: "Blow-job",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blow-job <strong class='noun'> n. </strong> Coarse slang instance of fellatio or cunnilingus.</p>",
      },
    ],
  },
  {
    word: "Blowlamp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blowlamp <strong class='noun'> n. </strong> Device with a very hot flame for burning off paint, plumbing, etc.</p>",
      },
    ],
  },
  {
    word: "Blown  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blown  <strong class='archaic_it_was'> <strong class='archaic_it_was'>past part. Of *</strong> blow1. </strong> </p>",
      },
    ],
  },
  {
    word: "Blow-out",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blow-out <strong class='noun'> n. </strong> Colloq. 1 burst tyre. 2 melted fuse. 3 huge meal.</p>",
      },
    ],
  },
  {
    word: "Blowpipe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blowpipe <strong class='noun'> n. </strong> 1 tube for blowing air through, esp. To intensify a flame or to blow glass. 2 tube for propelling poisoned darts etc. By blowing.</p>",
      },
    ],
  },
  {
    word: "Blowtorch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blowtorch <strong class='noun'> n. </strong> Us = *blowlamp.</p>",
      },
    ],
  },
  {
    word: "Blow-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blow-up <strong class='noun'> n. </strong> 1 <strong class='colloq'> colloq. </strong> Enlargement (of a photograph etc.). 2 explosion.</p>",
      },
    ],
  },
  {
    word: "Blowy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blowy <strong class='adj'> adj. </strong> (-ier, -iest) windy.</p>",
      },
    ],
  },
  {
    word: "Blowzy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blowzy <strong class='adj'> adj. </strong> (-ier, -iest) 1 coarse-looking; red-faced. 2 slovenly. [obsolete blowze beggar's wench]</p>",
      },
    ],
  },
  {
    word: "Blub",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blub <strong class='verb'> v. </strong> (-bb-) slang sob. [shortening of *blubber]</p>",
      },
    ],
  },
  {
    word: "Blubber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blubber <strong class='noun'> —n. </strong> Whale fat. —v. 1 sob loudly. 2 sob out (words). <strong class='adj'> —adj.</strong> Swollen, thick. [probably imitative]</p>",
      },
    ],
  },
  {
    word: "Bludgeon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bludgeon <strong class='noun'> —n. </strong> Heavy club. —v. 1 beat with this. 2 coerce. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Blue",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue <strong class='adj'> —adj.</strong> (bluer, bluest) 1 having the colour of a clear sky. 2 sad, depressed. 3 pornographic (a blue film). 4 politically conservative. <strong class='noun'> —n. </strong> 1 blue colour or pigment. 2 blue clothes or material (dressed in blue). 3 person who represents a university in a sport, esp. Oxford or cambridge. 4 conservative party supporter. —v. (blues, blued, bluing or blueing) 1 make blue. 2 slang squander. once in a blue moon very rarely. Out of the blue unexpectedly. [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Blue baby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue baby <strong class='noun'> n. </strong> Baby with a blue complexion due to a congenital heart defect.</p>",
      },
    ],
  },
  {
    word: "Bluebell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bluebell <strong class='noun'> n. </strong> Woodland plant with bell-shaped blue flowers.</p>",
      },
    ],
  },
  {
    word: "Blueberry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blueberry <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> small blue-black edible fruit of various plants.</p>",
      },
    ],
  },
  {
    word: "Blue blood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue blood <strong class='noun'> n. </strong> Noble birth.</p>",
      },
    ],
  },
  {
    word: "Blue book",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue book <strong class='noun'> n. </strong> Report issued by parliament or the privy council.</p>",
      },
    ],
  },
  {
    word: "Bluebottle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bluebottle <strong class='noun'> n. </strong> Large buzzing fly; blowfly.</p>",
      },
    ],
  },
  {
    word: "Blue cheese",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue cheese <strong class='noun'> n. </strong> Cheese with veins of blue mould.</p>",
      },
    ],
  },
  {
    word: "Blue-collar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue-collar <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of a worker or work) manual; industrial.</p>",
      },
    ],
  },
  {
    word: "Blue-eyed boy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue-eyed boy <strong class='noun'> n. </strong> Colloq. Favourite.</p>",
      },
    ],
  },
  {
    word: "Blue funk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue funk <strong class='noun'> n. </strong> Colloq. Terror or panic.</p>",
      },
    ],
  },
  {
    word: "Bluegrass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bluegrass <strong class='noun'> n. </strong> A kind of instrumental country-and-western music.</p>",
      },
    ],
  },
  {
    word: "Blue-pencil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue-pencil <strong class='verb'> v. </strong> Censor or cut (a manuscript, film, etc.).</p>",
      },
    ],
  },
  {
    word: "Blue peter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue peter <strong class='noun'> n. </strong> Blue flag with a white square flown by a ship about to leave port.</p>",
      },
    ],
  },
  {
    word: "Blueprint",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blueprint <strong class='noun'> n. </strong> 1 photographic print of plans in white on a blue background. 2 detailed plan.</p>",
      },
    ],
  },
  {
    word: "Blue rinse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue rinse <strong class='noun'> n. </strong> Bluish dye for grey hair.</p>",
      },
    ],
  },
  {
    word: "Blues",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blues <strong class='n_pl'> n.pl. </strong> 1 (prec. By the) bout of depression. 2 a (prec. By the; often treated as sing.) Melancholic music of black american origin, usu. In a twelve-bar sequence. B <strong class='pl_same'> (pl. Same) </strong> (as sing.) Piece of such music (played a blues).</p>",
      },
    ],
  },
  {
    word: "Bluestocking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bluestocking <strong class='noun'> n. </strong> Usu. Derog. Intellectual or literary woman. [18th-c. Blue stocking society]</p>",
      },
    ],
  },
  {
    word: "Blue tit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue tit <strong class='noun'> n. </strong> Common tit with a blue crest.</p>",
      },
    ],
  },
  {
    word: "Blue whale",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blue whale <strong class='noun'> n. </strong> Rorqual, the largest known living mammal.</p>",
      },
    ],
  },
  {
    word: "Bluff1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bluff1 <strong class='noun'> —v. </strong> Pretend strength, confidence, etc. <strong class='noun'> —n. </strong> Act of bluffing. call a person's bluff challenge a person to prove a claim. [dutch bluffen brag]</p>",
      },
    ],
  },
  {
    word: "Bluff2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bluff2 <strong class='adj'> —adj.</strong> 1 blunt, frank, hearty. 2 vertical or steep and broad in front. <strong class='noun'> —n. </strong> Steep cliff or headland. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bluish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bluish <strong class='adj'> adj. </strong> Fairly blue.</p>",
      },
    ],
  },
  {
    word: "Blunder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blunder <strong class='noun'> —n. </strong> Serious or foolish mistake. —v. 1 make a blunder. 2 move clumsily; stumble. [probably scandinavian]</p>",
      },
    ],
  },
  {
    word: "Blunderbuss",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blunderbuss <strong class='noun'> n. </strong> Hist. Short large-bored gun. [dutch donderbus thunder gun]</p>",
      },
    ],
  },
  {
    word: "Blunt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blunt <strong class='adj'> —adj.</strong> 1 not sharp or pointed. 2 direct, outspoken. —v. Make blunt or less sharp. bluntly <strong class='adv'> adv. </strong> (in sense 2 of adj.). Bluntness <strong class='noun'> n. </strong> [probably scandinavian]</p>",
      },
    ],
  },
  {
    word: "Blur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blur <strong class='noun'> —v. </strong> <strong class='adj'> (-rr-) </strong> make or become unclear or less distinct; smear. <strong class='noun'> —n. </strong> Blurred object, sound, memory, etc. [perhaps related to *bleary]</p>",
      },
    ],
  },
  {
    word: "Blurb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blurb <strong class='noun'> n. </strong> Promotional description, esp. Of a book. [coined by g. Burgess 1907]</p>",
      },
    ],
  },
  {
    word: "Blurt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blurt <strong class='verb'> v. </strong> (usu. Foll. By out) utter abruptly, thoughtlessly, or tactlessly. [imitative]</p>",
      },
    ],
  },
  {
    word: "Blush",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Blush <strong class='noun'> —v. </strong> 1 a become pink in the face from embarrassment or shame. B (of the face) redden thus. 2 feel embarrassed or ashamed. 3 redden. <strong class='noun'> —n. </strong> 1 act of blushing. 2 pink tinge. [old english]</p>",
      },
    ],
  },
  {
    word: "Blusher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Blusher <strong class='noun'> n. </strong> Rouge.</p>",
      },
    ],
  },
  {
    word: "Bluster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bluster <strong class='noun'> —v. </strong> 1 behave pompously or boisterously. 2 (of the wind etc.) Blow fiercely. <strong class='noun'> —n. </strong> Bombastic talk; empty threats. blustery adj. [imitative]</p>",
      },
    ],
  },
  {
    word: "Bm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bm <strong class='sing'> abbr. </strong> 1 british museum. 2 bachelor of medicine.</p>",
      },
    ],
  },
  {
    word: "Bma",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bma <strong class='sing'> abbr. </strong> British medical association.</p>",
      },
    ],
  },
  {
    word: "B.mus.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>B.mus. <strong class='abbr'> Abbr. </strong> Bachelor of music.</p>",
      },
    ],
  },
  {
    word: "Bmx",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bmx <strong class='noun'> n. </strong> 1 organized bicycle-racing on a dirt-track. 2 bicycle used for this. [abbreviation of bicycle moto-cross]</p>",
      },
    ],
  },
  {
    word: "Bo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bo <strong class='sing'> abbr. </strong> Colloq. Body odour.</p>",
      },
    ],
  },
  {
    word: "Boa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boa <strong class='noun'> n. </strong> 1 large snake which kills by crushing and suffocating. 2 long stole of feathers or fur. [latin]</p>",
      },
    ],
  },
  {
    word: "Boa constrictor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boa constrictor <strong class='noun'> n. </strong> Species of boa.</p>",
      },
    ],
  },
  {
    word: "Boar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boar <strong class='noun'> n. </strong> 1 male wild pig. 2 uncastrated male pig. [old english]</p>",
      },
    ],
  },
  {
    word: "Board",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Board <strong class='noun'> —n. </strong> 1 a flat thin piece of sawn timber, usu. Long and narrow. B material resembling this, of compressed fibres. C thin slab of wood etc. D thick stiff card used in bookbinding. 2 provision of regular meals, usu. With accommodation, for payment. 3 directors of a company; official administrative body. 4 (in pl.) Stage of a theatre. 5 side of a ship. —v. 1 go on board (a ship, train, etc.). 2 receive, or provide with, meals and usu. Lodging. 3 (usu. Foll. By up) cover with boards; seal or close. go by the board be neglected or discarded. On board on or on to a ship, aircraft, oil rig, etc. Take on board consider, take notice of; accept. [old english]</p>",
      },
    ],
  },
  {
    word: "Boarder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boarder <strong class='noun'> n. </strong> 1 person who boards, esp. At a boarding-school. 2 person who boards a ship, esp. An enemy.</p>",
      },
    ],
  },
  {
    word: "Board-game",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Board-game <strong class='noun'> n. </strong> Game played on a board.</p>",
      },
    ],
  },
  {
    word: "Boarding-house",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boarding-house <strong class='noun'> n. </strong> Unlicensed establishment providing board and lodging, esp. To holiday-makers.</p>",
      },
    ],
  },
  {
    word: "Boarding-school",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boarding-school <strong class='noun'> n. </strong> School in which pupils live in term-time.</p>",
      },
    ],
  },
  {
    word: "Boardroom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boardroom <strong class='noun'> n. </strong> Room in which a board of directors etc. Meets regularly.</p>",
      },
    ],
  },
  {
    word: "Boast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boast <strong class='noun'> —v. </strong> 1 declare one's virtues, wealth, etc. With excessive pride. 2 own or have with pride (hotel boasts a ballroom). <strong class='noun'> —n. </strong> 1 act of boasting. 2 thing one is proud of. [anglo-french]</p>",
      },
    ],
  },
  {
    word: "Boastful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boastful <strong class='adj'> adj. </strong> Given to boasting. boastfully adv.</p>",
      },
    ],
  },
  {
    word: "Boat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boat <strong class='noun'> —n. </strong> 1 small vessel propelled on water by an engine, oars, or sails. 2 any ship. 3 long low jug for sauce etc. —v. Go in a boat, esp. For pleasure. in the same boat having the same problems. [old english]</p>",
      },
    ],
  },
  {
    word: "Boater",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boater <strong class='noun'> n. </strong> Flat-topped straw hat with a brim.</p>",
      },
    ],
  },
  {
    word: "Boat-hook",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boat-hook <strong class='noun'> n. </strong> Long hooked pole for moving boats.</p>",
      },
    ],
  },
  {
    word: "Boat-house",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boat-house <strong class='noun'> n. </strong> Waterside shed for housing boats.</p>",
      },
    ],
  },
  {
    word: "Boating",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boating <strong class='noun'> n. </strong> Rowing or sailing as recreation.</p>",
      },
    ],
  },
  {
    word: "Boatman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boatman <strong class='noun'> n. </strong> Person who hires out boats or provides transport by boat.</p>",
      },
    ],
  },
  {
    word: "Boat people",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boat people <strong class='n_pl'> n.pl. </strong> Refugees travelling by sea.</p>",
      },
    ],
  },
  {
    word: "Boatswain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boatswain <strong class='noun'> n. </strong> (also bosun, bo'sun) ship's officer in charge of equipment and crew.</p>",
      },
    ],
  },
  {
    word: "Boat-train",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boat-train <strong class='noun'> n. </strong> Train scheduled to meet or go on a boat.</p>",
      },
    ],
  },
  {
    word: "Bob1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bob1 <strong class='noun'> —v. </strong> (-bb-) 1 move quickly up and down. 2 (usu. Foll. By back, up) bounce or emerge buoyantly or suddenly. 3 cut (the hair) in a bob. 4 curtsy. <strong class='noun'> —n. </strong> 1 jerking or bouncing movement, esp. Upward. 2 hairstyle with the hair hanging evenly above the shoulders. 3 weight on a pendulum etc. 4 horse's docked tail. 5 curtsy. [imitative]</p>",
      },
    ],
  },
  {
    word: "Bob2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bob2 <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> hist. Slang shilling (now = 5 pence). [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bob3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bob3 <strong class='noun'> n. </strong> bob's your uncle slang expression of completion or success. [pet form of robert]</p>",
      },
    ],
  },
  {
    word: "Bobbin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bobbin <strong class='noun'> n. </strong> Spool or reel for thread etc. [french]</p>",
      },
    ],
  },
  {
    word: "Bobble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bobble <strong class='noun'> n. </strong> Small woolly ball on a hat etc. [diminutive of *bob1]</p>",
      },
    ],
  },
  {
    word: "Bobby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bobby <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> <strong class='colloq'> colloq. </strong> Police officer. [sir robert peel, 19th-c. Statesman]</p>",
      },
    ],
  },
  {
    word: "Bob-sled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bob-sled <strong class='noun'> n. </strong> Us = *bob-sleigh.</p>",
      },
    ],
  },
  {
    word: "Bob-sleigh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bob-sleigh <strong class='noun'> —n. </strong> Mechanically-steered and -braked racing sledge. —v. Race in a bob-sleigh.</p>",
      },
    ],
  },
  {
    word: "Bobtail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bobtail <strong class='noun'> n. </strong> 1 docked tail. 2 horse or dog with this.</p>",
      },
    ],
  },
  {
    word: "Boche",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boche <strong class='noun'> n. </strong> Slang derog. German, esp. A soldier. [french]</p>",
      },
    ],
  },
  {
    word: "Bod",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bod <strong class='noun'> n. </strong> Colloq. Person. [shortening of *body]</p>",
      },
    ],
  },
  {
    word: "Bode",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bode <strong class='verb'> v. </strong> (-ding) be a sign of, portend. bode well (or ill) be a good (or bad) sign. [old english]</p>",
      },
    ],
  },
  {
    word: "Bodega",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bodega <strong class='noun'> n. </strong> Cellar or shop selling wine. [spanish]</p>",
      },
    ],
  },
  {
    word: "Bodge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bodge <strong class='var'> var. </strong> Of *botch.</p>",
      },
    ],
  },
  {
    word: "Bodice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bodice <strong class='noun'> n. </strong> 1 part of a woman's dress above the waist. 2 woman's vest-like undergarment. [originally pair of bodies]</p>",
      },
    ],
  },
  {
    word: "Bodily",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bodily <strong class='adj'> —adj.</strong> Of the body. <strong class='adv'> —adv. </strong> 1 as a whole body (threw them bodily). 2 in the flesh, in person.</p>",
      },
    ],
  },
  {
    word: "Bodkin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bodkin <strong class='noun'> n. </strong> Blunt thick needle for drawing tape etc. Through a hem. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Body",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Body <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 whole physical structure, including the bones, flesh, and organs, of a person or an animal, whether dead or alive. 2 = *trunk 2. 3 main or central part; bulk or majority (body of opinion). 4 a group regarded as a unit. B (usu. Foll. By of) collection (body of facts). 5 quantity (body of water). 6 piece of matter (heavenly body). 7 <strong class='colloq'> colloq. </strong> Person. 8 full or substantial quality of flavour, tone, etc. in a body all together. [old english]</p>",
      },
    ],
  },
  {
    word: "Body-blow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Body-blow <strong class='noun'> n. </strong> Severe setback.</p>",
      },
    ],
  },
  {
    word: "Body-building",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Body-building <strong class='noun'> n. </strong> Exercises to enlarge and strengthen the muscles.</p>",
      },
    ],
  },
  {
    word: "Bodyguard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bodyguard <strong class='noun'> n. </strong> Person or group escorting and protecting another.</p>",
      },
    ],
  },
  {
    word: "Body language",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Body language <strong class='noun'> n. </strong> Communication through gestures and poses.</p>",
      },
    ],
  },
  {
    word: "Body odour",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Body odour <strong class='noun'> n. </strong> Smell of the human body, esp. When unpleasant.</p>",
      },
    ],
  },
  {
    word: "Body politic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Body politic <strong class='noun'> n. </strong> Nation or state as a corporate body.</p>",
      },
    ],
  },
  {
    word: "Body shop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Body shop <strong class='noun'> n. </strong> Workshop where bodywork is repaired.</p>",
      },
    ],
  },
  {
    word: "Body stocking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Body stocking <strong class='noun'> n. </strong> Woman's undergarment covering the torso.</p>",
      },
    ],
  },
  {
    word: "Bodysuit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bodysuit <strong class='noun'> n. </strong> Close-fitting all-in-one garment for women, worn esp. For sport.</p>",
      },
    ],
  },
  {
    word: "Bodywork",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bodywork <strong class='noun'> n. </strong> Outer shell of a vehicle.</p>",
      },
    ],
  },
  {
    word: "Boer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boer <strong class='noun'> —n. </strong> South african of dutch descent. <strong class='adj'> —adj.</strong> Of the boers. [dutch, = farmer]</p>",
      },
    ],
  },
  {
    word: "Boffin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boffin <strong class='noun'> n. </strong> Colloq. Research scientist. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bog",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bog <strong class='noun'> —n. </strong> 1 a wet spongy ground. B stretch of this. 2 slang lavatory. —v. <strong class='gg'> (-gg-) </strong> (foll. By down; usu. In passive) impede (bogged down by snow). boggy adj. (-ier, -iest). [irish or gaelic bogach]</p>",
      },
    ],
  },
  {
    word: "Bogey1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bogey1 <strong class='noun'> n. </strong> (pl. -eys) golf 1 score of one stroke more than par at any hole. 2 (formerly) par. [perhaps from bogey, as an imaginary player]</p>",
      },
    ],
  },
  {
    word: "Bogey2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bogey2 <strong class='noun'> n. </strong> (also bogy) (pl. -eys or -ies) 1 evil or mischievous spirit; devil. 2 awkward thing. 3 slang piece of dried nasal mucus. [originally (old) bogey the devil]</p>",
      },
    ],
  },
  {
    word: "Bogeyman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bogeyman <strong class='noun'> n. </strong> (also bogyman) frightening person etc.</p>",
      },
    ],
  },
  {
    word: "Boggle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boggle <strong class='verb'> v. </strong> (-ling) <strong class='colloq'> colloq. </strong> Be startled or baffled (esp. The mind boggles). [probably dial. Boggle *bogey2]</p>",
      },
    ],
  },
  {
    word: "Bogie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bogie <strong class='noun'> n. </strong> Wheeled undercarriage below a locomotive etc. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bogus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bogus <strong class='adj'> adj. </strong> Sham, spurious. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bogy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bogy <strong class='var'> var. </strong> Of *bogey2.</p>",
      },
    ],
  },
  {
    word: "Bogyman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bogyman <strong class='var'> var. </strong> Of *bogeyman.</p>",
      },
    ],
  },
  {
    word: "Bohemian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bohemian <strong class='noun'> —n. </strong> 1 native of bohemia, a czech. 2 (also bohemian) socially unconventional person, esp. An artist or writer. <strong class='adj'> —adj.</strong> 1 of bohemia or its people. 2 (also bohemian) socially unconventional. bohemianism <strong class='noun'> n. </strong> [bohemia, part of czechoslovakia]</p>",
      },
    ],
  },
  {
    word: "Boil1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boil1 <strong class='noun'> —v. </strong> 1 a (of a liquid) start to bubble up and turn into vapour on reaching a certain temperature. B (of a vessel) contain boiling liquid (kettle is boiling). 2 a bring to boiling point. B cook in boiling liquid. C subject to boiling water, e.g. To clean. 3 a move or seethe like boiling water. B be very angry. <strong class='noun'> —n. </strong> Act or process of boiling; boiling-point (on the boil; bring to the boil). boil down 1 reduce in volume by boiling. 2 reduce to essentials. 3 (foll. By to) amount to. Boil over 1 spill over in boiling. 2 lose one's temper. [latin bullio to bubble]</p>",
      },
    ],
  },
  {
    word: "Boil2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boil2 <strong class='noun'> n. </strong> Inflamed pus-filled swelling under the skin. [old english]</p>",
      },
    ],
  },
  {
    word: "Boiler",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boiler <strong class='noun'> n. </strong> 1 apparatus for heating a hot-water supply. 2 tank for heating water or turning it to steam. 3 tub for boiling laundry etc. 4 fowl etc. For boiling.</p>",
      },
    ],
  },
  {
    word: "Boiler-room",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boiler-room <strong class='noun'> n. </strong> Room with a boiler and other heating equipment, esp. In a basement.</p>",
      },
    ],
  },
  {
    word: "Boiler suit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boiler suit <strong class='noun'> n. </strong> Protective outer garment of trousers and jacket in one.</p>",
      },
    ],
  },
  {
    word: "Boiling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boiling <strong class='adj'> adj. </strong> Colloq. Very hot.</p>",
      },
    ],
  },
  {
    word: "Boiling point",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boiling point <strong class='noun'> n. </strong> 1 temperature at which a liquid begins to boil. 2 great excitement.</p>",
      },
    ],
  },
  {
    word: "Boisterous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boisterous <strong class='adj'> adj. </strong> 1 noisily exuberant, rough. 2 (of the sea etc.) Stormy. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bold",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bold <strong class='adj'> adj. </strong> 1 confidently assertive; adventurous, brave. 2 impudent. 3 vivid (bold colours). make (or be) so bold as to presume to; venture to. boldly <strong class='adv'> adv. </strong> Boldness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Bole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bole <strong class='noun'> n. </strong> Trunk of a tree. [old norse]</p>",
      },
    ],
  },
  {
    word: "Bolero",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bolero <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 spanish dance, or the music for it, in triple time. 2 woman's short open jacket. [spanish]</p>",
      },
    ],
  },
  {
    word: "Boll",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boll <strong class='noun'> n. </strong> Round seed-vessel of cotton, flax, etc. [dutch]</p>",
      },
    ],
  },
  {
    word: "Bollard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bollard <strong class='noun'> n. </strong> 1 short post in the road, esp. On a traffic island. 2 short post on a quay or ship for securing a rope. [perhaps related to *bole]</p>",
      },
    ],
  },
  {
    word: "Bollocking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bollocking <strong class='noun'> n. </strong> (also ballocking) coarse slang severe reprimand.</p>",
      },
    ],
  },
  {
    word: "Bollocks",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bollocks <strong class='noun'> n. </strong> (also ballocks) coarse slang 1 (usu. As int.) Nonsense. 2 testicles. [old english: related to *ball1]</p>",
      },
    ],
  },
  {
    word: "Boloney",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boloney <strong class='noun'> n. </strong> (also baloney) slang nonsense. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Bolshevik",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bolshevik <strong class='noun'> —n. </strong> 1 hist. Member of the radical faction of the russian social democratic party becoming the communist party in 1918. 2 russian communist. 3 any revolutionary socialist. <strong class='adj'> —adj.</strong> 1 of the bolsheviks. 2 communist. bolshevism <strong class='noun'> n. </strong> Bolshevist <strong class='noun'> n. </strong> [russian, = member of the majority]</p>",
      },
    ],
  },
  {
    word: "Bolshie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bolshie  <strong class='adj'>(also bolshy)</strong> slang <strong class='adj'> —adj.</strong> (usu. Bolshie) 1 uncooperative; bad-tempered. 2 left-wing. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> bolshevik. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Bolster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bolster <strong class='noun'> —n. </strong> Long cylindrical pillow. —v. (usu. Foll. By up) encourage, support, prop up. [old english]</p>",
      },
    ],
  },
  {
    word: "Bolt1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bolt1 <strong class='noun'> —n. </strong> 1 sliding bar and socket used to fasten a door etc. 2 large metal pin with a thread, usu. Used with a nut, to hold things together. 3 discharge of lightning. 4 act of bolting. —v. 1 fasten with a bolt. 2 (foll. By in, out) keep (a person etc.) In or out by bolting a door. 3 fasten together with bolts. 4 a dash off suddenly. B (of a horse) suddenly gallop out of control. 5 gulp down (food) unchewed. 6 (of a plant) run to seed. <strong class='adv'> —adv. </strong> (usu. In bolt upright) rigidly, stiffly. bolt from the blue complete surprise. [old english]</p>",
      },
    ],
  },
  {
    word: "Bolt2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bolt2 <strong class='verb'> v. </strong> (also boult) sift (flour etc.). [french]</p>",
      },
    ],
  },
  {
    word: "Bolt-hole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bolt-hole <strong class='noun'> n. </strong> Means of escape.</p>",
      },
    ],
  },
  {
    word: "Bomb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bomb <strong class='noun'> —n. </strong> 1 container filled with explosive, incendiary material, etc., designed to explode and cause damage. 2 (prec. By the) the atomic or hydrogen bomb. 3 slang large sum of money (cost a bomb). —v. 1 attack with bombs; drop bombs on. 2 (usu. Foll. By along, off) <strong class='colloq'> colloq. </strong> Go very quickly. like a bomb <strong class='colloq'> colloq. </strong> 1 very successfully. 2 very fast. [greek bombos hum]</p>",
      },
    ],
  },
  {
    word: "Bombard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bombard <strong class='verb'> v. </strong> 1 attack with heavy guns or bombs etc. 2 (often foll. By with) question or abuse persistently. 3 physics direct a stream of high-speed particles at. bombardment <strong class='noun'> n. </strong> [latin: related to *bomb]</p>",
      },
    ],
  },
  {
    word: "Bombardier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bombardier <strong class='noun'> n. </strong> 1 non-commissioned officer in the artillery. 2 us crew member in an aircraft who aims and releases bombs.</p>",
      },
    ],
  },
  {
    word: "Bombast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bombast <strong class='noun'> n. </strong> Pompous language; hyperbole. bombastic adj. [earlier bombace cotton wool]</p>",
      },
    ],
  },
  {
    word: "Bombay duck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bombay duck <strong class='noun'> n. </strong> Dried fish as a relish, esp. With curry. [corruption of bombil, native name of fish]</p>",
      },
    ],
  },
  {
    word: "Bombazine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bombazine <strong class='noun'> n. </strong> Twilled worsted dress-material. [greek bombux silk]</p>",
      },
    ],
  },
  {
    word: "Bomber",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bomber <strong class='noun'> n. </strong> 1 aircraft equipped to drop bombs. 2 person using bombs, esp. Illegally.</p>",
      },
    ],
  },
  {
    word: "Bomber jacket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bomber jacket <strong class='noun'> n. </strong> Jacket gathered at the waist and cuffs.</p>",
      },
    ],
  },
  {
    word: "Bombshell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bombshell <strong class='noun'> n. </strong> 1 overwhelming surprise or disappointment. 2 artillery bomb. 3 slang very attractive woman.</p>",
      },
    ],
  },
  {
    word: "Bomb-site",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bomb-site <strong class='noun'> n. </strong> Area where bombs have caused destruction.</p>",
      },
    ],
  },
  {
    word: "Bona fide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bona fide <strong class='adj'> —adj.</strong> Genuine; sincere. <strong class='adv'> —adv. </strong> Genuinely; sincerely. [latin]</p>",
      },
    ],
  },
  {
    word: "Bonanza",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonanza <strong class='noun'> n. </strong> 1 source of wealth or prosperity. 2 large output (esp. Of a mine). [spanish, = fair weather]</p>",
      },
    ],
  },
  {
    word: "Bon-bon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bon-bon <strong class='noun'> n. </strong> Sweet. [french bon good]</p>",
      },
    ],
  },
  {
    word: "Bond",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bond <strong class='noun'> —n. </strong> 1 thing or force that unites or (usu. In pl.) Restrains. 2 binding agreement. 3 commerce certificate issued by a government or a company promising to repay borrowed money at a fixed rate of interest. 4 adhesiveness. 5 law deed binding a person to make payment to another. 6 chem. Linkage between atoms in a molecule. —v. 1 hold or tie together. 2 connect or reinforce with a bond. 3 place (goods) in bond.</p>",
      },
    ],
  },
  {
    word: "Bondage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bondage <strong class='noun'> n. </strong> 1 slavery. 2 subjection to constraint etc. 3 sexual practices involving constraint. [anglo-latin: related to *bondsman]</p>",
      },
    ],
  },
  {
    word: "Bonded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonded <strong class='adj'> adj. </strong> 1 stored in or for storing in bond (bonded whisky, warehouse). 2 (of a debt) secured by bonds.</p>",
      },
    ],
  },
  {
    word: "Bond paper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bond paper <strong class='noun'> n. </strong> High-quality writing-paper.</p>",
      },
    ],
  },
  {
    word: "Bondsman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bondsman <strong class='noun'> n. </strong> Serf, slave. [old english bonda husbandman]</p>",
      },
    ],
  },
  {
    word: "Bone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bone <strong class='noun'> —n. </strong> 1 any piece of hard tissue making up the skeleton in vertebrates. 2 (in pl.) A skeleton, esp. As remains. B body. 3 material of bones or similar material, e.g. Ivory. 4 thing made of bone. 5 (in pl.) Essentials (the bones of an agreement). 6 strip of stiffening in a corset etc. —v. (-ning) 1 remove the bones from. 2 stiffen with bone etc. bone up (often foll. By on) <strong class='colloq'> colloq. </strong> Study intensively. Have a bone to pick (usu. Foll. By with) have cause for dispute (with a person). Make no bones about 1 be frank about. 2 not hesitate or scruple. boneless adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Bone china",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bone china <strong class='noun'> n. </strong> Fine china made of clay mixed with bone ash.</p>",
      },
    ],
  },
  {
    word: "Bone-dry",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bone-dry <strong class='adj'> adj. </strong> Completely dry.</p>",
      },
    ],
  },
  {
    word: "Bone-idle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bone-idle <strong class='adj'> adj. </strong> Utterly idle.</p>",
      },
    ],
  },
  {
    word: "Bone-marrow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bone-marrow <strong class='noun'> n. </strong> = *marrow 2.</p>",
      },
    ],
  },
  {
    word: "Bone-meal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bone-meal <strong class='noun'> n. </strong> Crushed bones, esp. As a fertilizer.</p>",
      },
    ],
  },
  {
    word: "Bone of contention",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bone of contention <strong class='noun'> n. </strong> Source of dispute.</p>",
      },
    ],
  },
  {
    word: "Boneshaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boneshaker <strong class='noun'> n. </strong> Decrepit or uncomfortable old vehicle.</p>",
      },
    ],
  },
  {
    word: "Bonfire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonfire <strong class='noun'> n. </strong> Large open-air fire, esp. For burning rubbish. [from *bone (because bones were once used), *fire]</p>",
      },
    ],
  },
  {
    word: "Bongo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bongo <strong class='noun'> n. </strong> (pl. -s or -es) either of a pair of small drums usu. Held between the knees and played with the fingers. [american spanish]</p>",
      },
    ],
  },
  {
    word: "Bonhomie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonhomie <strong class='noun'> n. </strong> Good-natured friendliness. [french]</p>",
      },
    ],
  },
  {
    word: "Bonk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonk <strong class='noun'> —v. </strong> 1 bang, bump. 2 coarse slang have sexual intercourse (with). <strong class='noun'> —n. </strong> Instance of bonking (bonk on the head). [imitative]</p>",
      },
    ],
  },
  {
    word: "Bonkers",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonkers <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> Slang crazy. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bon mot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bon mot <strong class='noun'> n. </strong> (pl. Bons mots) witty saying. [french]</p>",
      },
    ],
  },
  {
    word: "Bonnet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonnet <strong class='noun'> n. </strong> 1 a hat tied under the chin, worn esp. By babies. B scotsman's floppy beret. 2 hinged cover over a vehicle's engine. [french]</p>",
      },
    ],
  },
  {
    word: "Bonny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonny <strong class='adj'> adj. </strong> (-ier, -iest) esp. Scot. & n.engl. 1 a physically attractive. B healthy-looking. 2 good, pleasant. [perhaps from french bon good]</p>",
      },
    ],
  },
  {
    word: "Bonsai",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonsai <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> 1 dwarfed tree or shrub. 2 art of growing these. [japanese]</p>",
      },
    ],
  },
  {
    word: "Bonus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bonus <strong class='noun'> n. </strong> Extra benefit or payment. [latin, = good]</p>",
      },
    ],
  },
  {
    word: "Bon vivant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bon vivant <strong class='noun'> n. </strong> (pl. Bon or bons vivants pronunc. Same) person fond of good food and drink. [french]</p>",
      },
    ],
  },
  {
    word: "Bon voyage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bon voyage <strong class='n_pl'> int. </strong> Expression of good wishes to a departing traveller. [french]</p>",
      },
    ],
  },
  {
    word: "Bony",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bony <strong class='adj'> adj. </strong> (-ier, -iest) 1 thin with prominent bones. 2 having many bones. 3 of or like bone. boniness n.</p>",
      },
    ],
  },
  {
    word: "Boo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boo <strong class='pl'> —int. </strong> 1 expression of disapproval etc. 2 sound intended to surprise. <strong class='noun'> —n. </strong> Utterance of boo, esp. To a performer etc. —v. (boos, booed) 1 utter boos. 2 jeer at by booing. [imitative]</p>",
      },
    ],
  },
  {
    word: "Boob1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boob1 <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> 1 silly mistake. 2 foolish person. —v. Make a silly mistake. [shortening of *booby]</p>",
      },
    ],
  },
  {
    word: "Boob2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boob2 <strong class='noun'> n. </strong> Slang woman's breast. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Booby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booby <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> stupid or childish person. [spanish bobo]</p>",
      },
    ],
  },
  {
    word: "Booby prize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booby prize <strong class='noun'> n. </strong> Prize given for coming last.</p>",
      },
    ],
  },
  {
    word: "Booby trap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booby trap <strong class='noun'> n. </strong> 1 practical joke in the form of a trap. 2 disguised explosive device triggered by the unknowing victim.</p>",
      },
    ],
  },
  {
    word: "Boodle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boodle <strong class='noun'> n. </strong> Slang money, esp. Gained or used dishonestly. [dutch boedel possessions]</p>",
      },
    ],
  },
  {
    word: "Boogie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boogie <strong class='verb'> v. </strong> (-ies, -ied, -ieing) slang dance to pop music.</p>",
      },
    ],
  },
  {
    word: "Boogie-woogie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boogie-woogie <strong class='noun'> n. </strong> Style of playing blues or jazz on the piano. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Book",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Book <strong class='noun'> —n. </strong> 1 a written or printed work with pages bound along one side. B work intended for publication. 2 bound blank sheets for notes, records, etc. 3 bound set of tickets, stamps, matches, etc. 4 (in pl.) Set of records or accounts. 5 main division of a large literary work. 6 telephone directory. 7 <strong class='colloq'> colloq. </strong> Magazine. 8 libretto, script, etc. 9 record of bets. —v. 1 a (also absol.) Reserve (a seat etc.) In advance. B engage (an entertainer etc.). 2 a take the personal details of (an offender or rule-breaker). B enter in a book or list. book in register at a hotel etc. Book up 1 buy tickets in advance. 2 (as booked up) with all places reserved. Bring to book call to account. Go by the book proceed by the rules. In a person's good (or bad) books in (or out of) favour with a person. [old english]</p>",
      },
    ],
  },
  {
    word: "Bookbinder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookbinder <strong class='noun'> n. </strong> Person who binds books for a living. bookbinding n.</p>",
      },
    ],
  },
  {
    word: "Bookcase",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookcase <strong class='noun'> n. </strong> Cabinet of shelves for books.</p>",
      },
    ],
  },
  {
    word: "Book club",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Book club <strong class='noun'> n. </strong> Society in which selected books are available cheaply.</p>",
      },
    ],
  },
  {
    word: "Book-end",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Book-end <strong class='noun'> n. </strong> Prop used to keep books upright.</p>",
      },
    ],
  },
  {
    word: "Bookie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookie <strong class='noun'> n. </strong> Colloq. = *bookmaker. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Booking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booking <strong class='noun'> n. </strong> Reservation or engagement.</p>",
      },
    ],
  },
  {
    word: "Booking-hall",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booking-hall <strong class='noun'> n. </strong> (also booking-office) ticket office at a railway station etc.</p>",
      },
    ],
  },
  {
    word: "Bookish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookish <strong class='adj'> adj. </strong> 1 studious; fond of reading. 2 having knowledge mainly from books.</p>",
      },
    ],
  },
  {
    word: "Bookkeeper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookkeeper <strong class='noun'> n. </strong> Person who keeps accounts, esp. For a living. bookkeeping n.</p>",
      },
    ],
  },
  {
    word: "Booklet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booklet <strong class='noun'> n. </strong> Small book usu. With a paper cover.</p>",
      },
    ],
  },
  {
    word: "Bookmaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookmaker <strong class='noun'> n. </strong> Professional taker of bets. bookmaking n.</p>",
      },
    ],
  },
  {
    word: "Bookmark",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookmark <strong class='noun'> n. </strong> Thing used to mark a reader's place.</p>",
      },
    ],
  },
  {
    word: "Book-plate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Book-plate <strong class='noun'> n. </strong> Decorative personalized label stuck in a book.</p>",
      },
    ],
  },
  {
    word: "Bookseller",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookseller <strong class='noun'> n. </strong> Dealer in books.</p>",
      },
    ],
  },
  {
    word: "Bookshop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookshop <strong class='noun'> n. </strong> Shop selling books.</p>",
      },
    ],
  },
  {
    word: "Bookstall",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookstall <strong class='noun'> n. </strong> Stand selling books, newspapers, etc.</p>",
      },
    ],
  },
  {
    word: "Book token",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Book token <strong class='noun'> n. </strong> Voucher exchangeable for books.</p>",
      },
    ],
  },
  {
    word: "Bookworm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bookworm <strong class='noun'> n. </strong> 1 <strong class='colloq'> colloq. </strong> Devoted reader. 2 larva feeding on the paper and glue in books.</p>",
      },
    ],
  },
  {
    word: "Boolean",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boolean <strong class='adj'> adj. </strong> Denoting a system of algebraic notation to represent logical propositions. [boole, name of a mathematician]</p>",
      },
    ],
  },
  {
    word: "Boolean logic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boolean logic <strong class='noun'> n. </strong> Use of ‘and’, ‘or’, and ‘not’ in retrieving information from a database.</p>",
      },
    ],
  },
  {
    word: "Boom1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boom1 <strong class='noun'> —n. </strong> Deep resonant sound. —v. Make or speak with a boom. [imitative]</p>",
      },
    ],
  },
  {
    word: "Boom2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boom2 <strong class='noun'> —n. </strong> Period of economic prosperity or activity. —v. Be suddenly prosperous. [perhaps from *boom1]</p>",
      },
    ],
  },
  {
    word: "Boom3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boom3 <strong class='noun'> n. </strong> 1 pivoted spar to which a sail is attached. 2 long pole carrying a microphone, camera, etc. 3 barrier across a harbour etc. [dutch, = *beam]</p>",
      },
    ],
  },
  {
    word: "Boomerang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boomerang <strong class='noun'> —n. </strong> 1 flat v-shaped hardwood missile used esp. By australian aboriginals, able to return to its thrower. 2 plan that recoils on its originator. —v. (of a plan etc.) Backfire. [aboriginal]</p>",
      },
    ],
  },
  {
    word: "Boon1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boon1 <strong class='noun'> n. </strong> Advantage; blessing. [old norse]</p>",
      },
    ],
  },
  {
    word: "Boon2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boon2 <strong class='adj'> adj. </strong> Intimate, favourite (usu. Boon companion). [french bon from latin bonus good]</p>",
      },
    ],
  },
  {
    word: "Boor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boor <strong class='noun'> n. </strong> Ill-mannered person. boorish adj. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Boost",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boost <strong class='colloq'> colloq. </strong> —v. 1 promote or encourage. 2 increase, assist. 3 push from below. <strong class='noun'> —n. </strong> Act or result of boosting. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Booster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booster <strong class='noun'> n. </strong> 1 device for increasing power or voltage. 2 auxiliary engine or rocket for initial speed. 3 dose, injection, etc. Renewing the effect of an earlier one.</p>",
      },
    ],
  },
  {
    word: "Boot1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boot1 <strong class='noun'> —n. </strong> 1 outer foot-covering reaching above the ankle. 2 luggage compartment of a car. 3 <strong class='colloq'> colloq. </strong> A firm kick. B (prec. By the) dismissal (got the boot). —v. 1 kick. 2 (often foll. By out) eject forcefully. 3 (usu. Foll. By up) make (a computer) ready. put the boot in 1 kick brutally. 2 harm a person. [old norse]</p>",
      },
    ],
  },
  {
    word: "Boot2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boot2 <strong class='noun'> n. </strong> to boot as well, in addition. [old english]</p>",
      },
    ],
  },
  {
    word: "Bootblack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bootblack <strong class='noun'> n. </strong> Us person who polishes boots and shoes.</p>",
      },
    ],
  },
  {
    word: "Bootee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bootee <strong class='noun'> n. </strong> Baby's soft shoe.</p>",
      },
    ],
  },
  {
    word: "Booth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booth <strong class='noun'> n. </strong> 1 small temporary structure used esp. As a market stall. 2 enclosure for telephoning, voting, etc. 3 cubicle in a restaurant etc. [old norse]</p>",
      },
    ],
  },
  {
    word: "Bootleg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bootleg <strong class='adj'> —adj.</strong> (esp. Of alcohol) smuggled, illicit. —v. <strong class='gg'> (-gg-) </strong> illicitly make or deal in (alcohol etc.). bootlegger n.</p>",
      },
    ],
  },
  {
    word: "Bootlicker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bootlicker <strong class='noun'> n. </strong> Colloq. Toady.</p>",
      },
    ],
  },
  {
    word: "Boots",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boots <strong class='noun'> n. </strong> Hotel servant who cleans shoes etc.</p>",
      },
    ],
  },
  {
    word: "Bootstrap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bootstrap <strong class='noun'> n. </strong> Loop used to pull a boot on. pull oneself up by one's bootstraps better oneself.</p>",
      },
    ],
  },
  {
    word: "Booty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booty <strong class='noun'> n. </strong> 1 loot, spoil. 2 <strong class='colloq'> colloq. </strong> Prize or gain. [german]</p>",
      },
    ],
  },
  {
    word: "Booze",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booze <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Alcoholic drink. —v. (-zing) drink alcohol, esp. To excess. boozy adj. (-ier, -iest). [dutch]</p>",
      },
    ],
  },
  {
    word: "Boozer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boozer <strong class='noun'> n. </strong> Colloq. 1 habitual drinker. 2 public house.</p>",
      },
    ],
  },
  {
    word: "Booze-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Booze-up <strong class='noun'> n. </strong> Slang drinking bout.</p>",
      },
    ],
  },
  {
    word: "Bop1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bop1 <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> 1 a spell of dancing, esp. To pop music. B social occasion for this. 2 = *bebop. —v. (-pp-) dance, esp. To pop music. bopper <strong class='noun'> n. </strong> [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Bop2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bop2 <strong class='colloq'> colloq. </strong> —v. (-pp-) hit or punch, esp. Lightly. <strong class='noun'> —n. </strong> Esp. Light blow or hit. [imitative]</p>",
      },
    ],
  },
  {
    word: "Boracic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Boracic <strong class='adj'> adj. </strong> Of borax.</p>",
      },
    ],
  },
  {
    word: "Boracic acid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boracic acid <strong class='noun'> n. </strong> = *boric acid.</p>",
      },
    ],
  },
  {
    word: "Borage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borage <strong class='noun'> n. </strong> Plant with leaves used as flavouring. [french ultimately from arabic]</p>",
      },
    ],
  },
  {
    word: "Borax",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borax <strong class='noun'> n. </strong> Salt used in making glass and china, and as an antiseptic. [french ultimately from persian]</p>",
      },
    ],
  },
  {
    word: "Bordeaux",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bordeaux <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> wine (esp. Red) from the bordeaux district in sw france.</p>",
      },
    ],
  },
  {
    word: "Border",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Border <strong class='noun'> —n. </strong> 1 edge or boundary, or the part near it. 2 a line or region separating two countries. B (the border) boundary between scotland and england (usu. The borders), or <strong class='noun'> n. </strong> Ireland and the irish republic. 3 esp. Ornamental strip round an edge. 4 long narrow flower-bed (herbaceous border). —v. 1 be a border to. 2 provide with a border. 3 (usu. Foll. By on, upon) a adjoin; come close to being. B resemble. [french from germanic: related to *board]</p>",
      },
    ],
  },
  {
    word: "Border collie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Border collie <strong class='noun'> n. </strong> Sheepdog of the north country.</p>",
      },
    ],
  },
  {
    word: "Borderer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borderer <strong class='noun'> n. </strong> Person living near a border.</p>",
      },
    ],
  },
  {
    word: "Borderland",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borderland <strong class='noun'> n. </strong> 1 district near a border. 2 condition between two extremes. 3 area for debate.</p>",
      },
    ],
  },
  {
    word: "Borderline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borderline <strong class='noun'> —n. </strong> 1 line dividing two conditions. 2 line marking a boundary. <strong class='adj'> —adj.</strong> 1 on the borderline. 2 barely acceptable.</p>",
      },
    ],
  },
  {
    word: "Border terrier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Border terrier <strong class='noun'> n. </strong> Small rough-haired terrier.</p>",
      },
    ],
  },
  {
    word: "Bore1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bore1 <strong class='noun'> —v. </strong> <strong class='adj'> (-ring) </strong> 1 make (a hole), esp. With a revolving tool. 2 make a hole in, hollow out. <strong class='noun'> —n. </strong> 1 hollow of a firearm barrel or of a cylinder in an internal-combustion engine. 2 diameter of this. 3 deep hole made esp. To find water. [old english]</p>",
      },
    ],
  },
  {
    word: "Bore2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bore2 <strong class='noun'> —n. </strong> Tiresome or dull person or thing. —v. <strong class='adj'> (-ring) </strong> weary by tedious talk or dullness. bored adj. Boring adj. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bore3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bore3 <strong class='noun'> n. </strong> High tidal wave in an estuary. [scandinavian]</p>",
      },
    ],
  },
  {
    word: "Bore4  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bore4  <strong class='slang_v'>past of *</strong> bear1.</p>",
      },
    ],
  },
  {
    word: "Boredom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boredom <strong class='noun'> n. </strong> State of being bored. [from *bore2]</p>",
      },
    ],
  },
  {
    word: "Boric acid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boric acid <strong class='noun'> n. </strong> Acid derived from borax, used as an antiseptic.</p>",
      },
    ],
  },
  {
    word: "Born",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Born <strong class='adj'> adj. </strong> 1 existing as a result of birth. 2 a of natural ability or quality (a born leader). B (usu. Foll. By to + infin.) Destined (born lucky; born to be king). 3 (in <strong class='n_pl'> comb. </strong> ) Of a certain status by birth (french-born; well-born). [past part. Of *bear1] </p>",
      },
    ],
  },
  {
    word: "Born-again",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Born-again <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Converted (esp. To fundamentalist christianity).</p>",
      },
    ],
  },
  {
    word: "Borne ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borne  <strong class='archaic_it_was'>past part. Of *</strong> bear1. <strong class='adj'> —adj.</strong> (in <strong class='n_pl'> comb. </strong> ) Carried by (airborne).</p>",
      },
    ],
  },
  {
    word: "Boron",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boron <strong class='noun'> n. </strong> Non-metallic usu. Crystalline element. [from *borax, after carbon]</p>",
      },
    ],
  },
  {
    word: "Borough",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borough <strong class='noun'> n. </strong> 1 a town represented in the house of commons. B town or district granted the status of a borough. 2 hist. Town with a municipal corporation conferred by a royal charter. [old english]</p>",
      },
    ],
  },
  {
    word: "Borrow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borrow <strong class='verb'> v. </strong> 1 a acquire temporarily, promising or intending to return. B obtain money thus. 2 use (another's idea, invention, etc.); plagiarize. borrower <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Borstal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borstal <strong class='noun'> n. </strong> Hist. Residential institution for youth custody. [borstal in kent] <strong class='usage'> Usage:- </strong>this term has now been replaced by detention centre and youth custody centre.</p>",
      },
    ],
  },
  {
    word: "Bortsch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bortsch <strong class='noun'> n. </strong> Russian soup of beetroot, cabbage, etc. [russian]</p>",
      },
    ],
  },
  {
    word: "Borzoi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Borzoi <strong class='noun'> n. </strong> Large silky-coated dog. [russian, = swift]</p>",
      },
    ],
  },
  {
    word: "Bosh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bosh <strong class='noun'> n. </strong> & int. Slang nonsense. [turkish, = empty]</p>",
      },
    ],
  },
  {
    word: "Bosom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bosom <strong class='noun'> n. </strong> 1 a person's (esp. Woman's) breast. B <strong class='colloq'> colloq. </strong> Each of a woman's breasts. C enclosure formed by the breast and arms. 2 emotional centre (bosom of one's family). [old english]</p>",
      },
    ],
  },
  {
    word: "Bosom friend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bosom friend <strong class='noun'> n. </strong> Intimate friend.</p>",
      },
    ],
  },
  {
    word: "Boss1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boss1 <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Employer, manager, or supervisor. —v. (usu. Foll. By about, around) give orders to; order about. [dutch baas]</p>",
      },
    ],
  },
  {
    word: "Boss2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boss2 <strong class='noun'> n. </strong> 1 round knob, stud, etc., esp. On the centre of a shield. 2 archit. Ornamental carving etc. At the junction of the ribs in a vault. [french]</p>",
      },
    ],
  },
  {
    word: "Bossa nova",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bossa nova <strong class='noun'> n. </strong> 1 dance like the samba. 2 music for this. [portuguese, = new flair]</p>",
      },
    ],
  },
  {
    word: "Boss-eyed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boss-eyed <strong class='adj'> adj. </strong> Colloq. 1 cross-eyed; blind in one eye. 2 crooked. [boss = bad shot, origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bossy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bossy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Domineering. bossiness n.</p>",
      },
    ],
  },
  {
    word: "Bosun",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bosun  <strong class='general'> <strong class='general'>(also bo'sun)</strong> </strong>  <strong class='var'> var. </strong> Of *boatswain.</p>",
      },
    ],
  },
  {
    word: "Botany",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Botany <strong class='noun'> n. </strong> The study of plants. botanic adj. Botanical adj. Botanist <strong class='noun'> n. </strong> [greek botane plant]</p>",
      },
    ],
  },
  {
    word: "Botch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Botch  <strong class='general'>(also bodge)</strong>  <strong class='noun'> —v. </strong>1 bungle; do badly. 2 patch clumsily. <strong class='noun'> —n. </strong> Bungled or spoilt work. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Both",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Both <strong class='adj'> —adj.</strong> & <strong class='pron'> pron. </strong> The two, not only one (both boys; both the boys; both of the boys; i like both). <strong class='adv'> —adv. </strong> With equal truth in two cases (is both hot and dry). [old norse]</p>",
      },
    ],
  },
  {
    word: "Bother",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bother <strong class='noun'> —v. </strong> 1 trouble; worry, disturb. 2 (often foll. By about, with, or to + infin.) Take the time or trouble (didn't bother to tell me; shan't bother with dessert). <strong class='noun'> —n. </strong> 1 a person or thing that bothers. B minor nuisance. 2 trouble, worry. <strong class='pl'> —int. </strong> Expressing irritation. [irish bodhraim deafen]</p>",
      },
    ],
  },
  {
    word: "Botheration",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Botheration <strong class='noun'> n. </strong> & int. Colloq. = *bother n., int.</p>",
      },
    ],
  },
  {
    word: "Bothersome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bothersome <strong class='adj'> adj. </strong> Causing bother.</p>",
      },
    ],
  },
  {
    word: "Bottle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottle <strong class='noun'> —n. </strong> 1 container, esp. Glass or plastic, for storing liquid. 2 amount filling it. 3 baby's feeding-bottle. 4 = *hot-water bottle. 5 metal cylinder for liquefied gas. 6 slang courage. —v. (-ling) 1 put into, or preserve in, bottles or jars. 2 (foll. By up) conceal or restrain (esp. A feeling). hit the bottle slang drink heavily. [medieval latin: related to *butt4]</p>",
      },
    ],
  },
  {
    word: "Bottle bank",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottle bank <strong class='noun'> n. </strong> Place for depositing bottles for recycling.</p>",
      },
    ],
  },
  {
    word: "Bottle-feed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottle-feed <strong class='verb'> v. </strong> Feed (a baby) from a bottle as opposed to the breast.</p>",
      },
    ],
  },
  {
    word: "Bottle green",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottle green <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> (as adj. Often hyphenated) dark green.</p>",
      },
    ],
  },
  {
    word: "Bottleneck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottleneck <strong class='noun'> n. </strong> 1 narrow congested area, esp. On a road. 2 impeding thing.</p>",
      },
    ],
  },
  {
    word: "Bottlenose dolphin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottlenose dolphin <strong class='noun'> n. </strong> Dolphin with a bottle-shaped snout.</p>",
      },
    ],
  },
  {
    word: "Bottle party",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottle party <strong class='noun'> n. </strong> Party to which guests bring bottles of drink.</p>",
      },
    ],
  },
  {
    word: "Bottom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottom <strong class='noun'> —n. </strong> 1 a lowest point or part. B base. C underneath part. D furthest or inmost part. 2 <strong class='colloq'> colloq. </strong> A buttocks. B seat of a chair etc. 3 a less honourable end of a table, class, etc. B person occupying this (he's bottom of the class). 4 ground below water. 5 basis or origin. 6 essential character. <strong class='adj'> —adj.</strong> Lowest, last. —v. 1 put a bottom to (a chair etc.). 2 find the extent of. 3 touch the bottom or lowest point (of). at bottom basically. Be at the bottom of have caused. Bottom out reach the lowest level. Get to the bottom of fully investigate and explain. [old english]</p>",
      },
    ],
  },
  {
    word: "Bottom drawer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottom drawer <strong class='noun'> n. </strong> Linen etc. Stored by a woman for marriage.</p>",
      },
    ],
  },
  {
    word: "Bottomless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottomless <strong class='adj'> adj. </strong> 1 without a bottom. 2 inexhaustible.</p>",
      },
    ],
  },
  {
    word: "Bottom line",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bottom line <strong class='noun'> n. </strong> Colloq. Underlying truth; ultimate, esp. Financial, criterion.</p>",
      },
    ],
  },
  {
    word: "Botulism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Botulism <strong class='noun'> n. </strong> Poisoning caused by a bacillus in badly preserved food. [latin botulus sausage]</p>",
      },
    ],
  },
  {
    word: "Bouclé",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bouclé <strong class='noun'> n. </strong> 1 looped or curled yarn (esp. Wool). 2 fabric made of this. [french, = curled]</p>",
      },
    ],
  },
  {
    word: "Boudoir",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boudoir <strong class='noun'> n. </strong> Woman's private room. [french bouder sulk]</p>",
      },
    ],
  },
  {
    word: "Bouffant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bouffant <strong class='adj'> adj. </strong> (of a dress, hair, etc.) Puffed out. [french]</p>",
      },
    ],
  },
  {
    word: "Bougainvillaea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bougainvillaea <strong class='noun'> n. </strong> Tropical plant with large coloured bracts. [bougainville, name of a navigator]</p>",
      },
    ],
  },
  {
    word: "Bough",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bough <strong class='noun'> n. </strong> Main branch of a tree. [old english]</p>",
      },
    ],
  },
  {
    word: "Bought  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bought  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>buy.</p>",
      },
    ],
  },
  {
    word: "Bouillon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bouillon <strong class='noun'> n. </strong> Clear broth. [french bouillir to boil]</p>",
      },
    ],
  },
  {
    word: "Boulder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boulder <strong class='noun'> n. </strong> Large smooth rock. [scandinavian]</p>",
      },
    ],
  },
  {
    word: "Boule",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boule <strong class='noun'> n. </strong> (also boules pronunc. Same) french form of bowls played on rough ground. [french]</p>",
      },
    ],
  },
  {
    word: "Boulevard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boulevard <strong class='noun'> n. </strong> 1 broad tree-lined avenue. 2 esp. Us broad main road. [french from german]</p>",
      },
    ],
  },
  {
    word: "Boult",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Boult <strong class='var'> var. </strong> Of *bolt2.</p>",
      },
    ],
  },
  {
    word: "Boult",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Boult <strong class='var'> var. </strong> Of *bolt2.</p>",
      },
    ],
  },
  {
    word: "Bounce",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bounce <strong class='noun'> —v. </strong> (-cing) 1 (cause to) rebound. 2 slang (of a cheque) be returned by a bank when there are no funds to meet it. 3 (foll. By about, up, in, out, etc.) Jump, move, or rush boisterously. <strong class='noun'> —n. </strong> 1 a rebound. B power of rebounding. 2 <strong class='colloq'> colloq. </strong> A swagger, self-confidence. B liveliness. bounce back recover well after a setback. bouncy adj. (-ier, -iest). [imitative]</p>",
      },
    ],
  },
  {
    word: "Bouncer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bouncer <strong class='noun'> n. </strong> 1 slang doorman ejecting troublemakers from a dancehall, club, etc. 2 = *bumper 3.</p>",
      },
    ],
  },
  {
    word: "Bouncing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bouncing <strong class='adj'> adj. </strong> (esp. Of a baby) big and healthy.</p>",
      },
    ],
  },
  {
    word: "Bound1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bound1 <strong class='noun'> —v. </strong> 1 spring, leap. 2 (of a ball etc.) Bounce. <strong class='noun'> —n. </strong> 1 springy leap. 2 bounce. [french bondir from latin bombus hum]</p>",
      },
    ],
  },
  {
    word: "Bound2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bound2 <strong class='noun'> —n. </strong> (usu. In pl.) 1 limitation; restriction. 2 border, boundary. —v. 1 limit. 2 be the boundary of. out of bounds outside a permitted area. [french from medieval latin]</p>",
      },
    ],
  },
  {
    word: "Bound3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bound3 <strong class='adj'> adj. </strong> 1 (usu. Foll. By for) starting or having started (bound for stardom). 2 (in <strong class='n_pl'> comb. </strong> ) In a specified direction (northbound). [old norse, = ready]</p>",
      },
    ],
  },
  {
    word: "Bound4  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bound4  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>bind. bound to certain to (he's bound to come). Bound up with closely associated with.</p>",
      },
    ],
  },
  {
    word: "Boundary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boundary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 line marking the limits of an area etc. 2 cricket hit crossing the limits of the field, scoring 4 or 6 runs. [related to *bound2]</p>",
      },
    ],
  },
  {
    word: "Bounden duty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bounden duty <strong class='noun'> n. </strong> Formal solemn responsibility. [archaic <strong class='archaic_it_was'>past part. Of *</strong> bind]</p>",
      },
    ],
  },
  {
    word: "Bounder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bounder <strong class='noun'> n. </strong> Colloq. Cad.</p>",
      },
    ],
  },
  {
    word: "Boundless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boundless <strong class='adj'> adj. </strong> Unlimited.</p>",
      },
    ],
  },
  {
    word: "Bounteous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bounteous <strong class='adj'> adj. </strong> Poet. = *bountiful. [french: related to *bounty]</p>",
      },
    ],
  },
  {
    word: "Bountiful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bountiful <strong class='adj'> adj. </strong> 1 generous. 2 ample.</p>",
      },
    ],
  },
  {
    word: "Bounty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bounty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 generosity. 2 reward, esp. From the state. 3 gift. [french from latin bonus good]</p>",
      },
    ],
  },
  {
    word: "Bouquet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bouquet <strong class='noun'> n. </strong> 1 bunch of flowers, esp. Professionally arranged. 2 scent of wine etc. 3 compliment. [french bois wood]</p>",
      },
    ],
  },
  {
    word: "Bouquet garni",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bouquet garni <strong class='noun'> n. </strong> (pl. Bouquets garnis) bunch or bag of herbs for seasoning.</p>",
      },
    ],
  },
  {
    word: "Bourbon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bourbon <strong class='noun'> n. </strong> Us whisky from maize and rye. [bourbon county, kentucky]</p>",
      },
    ],
  },
  {
    word: "Bourgeois  often derog.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bourgeois often derog. <strong class='adj'> —adj.</strong> 1 a conventionally middle-class. B materialistic. 2 capitalist. <strong class='noun'> —n. </strong> <strong class='pl_same'> (pl. Same) </strong> bourgeois person. [french]</p>",
      },
    ],
  },
  {
    word: "Bourgeoisie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bourgeoisie <strong class='noun'> n. </strong> 1 capitalist class. 2 middle class. [french]</p>",
      },
    ],
  },
  {
    word: "Bourn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bourn <strong class='noun'> n. </strong> Small stream. [var. Of *burn2]</p>",
      },
    ],
  },
  {
    word: "Bourse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bourse <strong class='noun'> n. </strong> 1 (bourse) paris stock exchange. 2 money-market. [french: related to *purse]</p>",
      },
    ],
  },
  {
    word: "Bout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bout <strong class='noun'> n. </strong> 1 (often foll. By of) a spell (of work or activity). B attack (bout of flu). 2 wrestling- or boxing-match. [obsolete bought bending]</p>",
      },
    ],
  },
  {
    word: "Boutique",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boutique <strong class='noun'> n. </strong> Small shop selling esp. Fashionable clothes. [french]</p>",
      },
    ],
  },
  {
    word: "Bouzouki",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bouzouki <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> greek form of mandolin. [modern greek]</p>",
      },
    ],
  },
  {
    word: "Bovine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bovine <strong class='adj'> adj. </strong> 1 of cattle. 2 stupid, dull. [latin bos ox]</p>",
      },
    ],
  },
  {
    word: "Bovine spongiform encephalopathy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bovine spongiform encephalopathy <strong class='see'> see *</strong> bse.</p>",
      },
    ],
  },
  {
    word: "Bow1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bow1 <strong class='noun'> —n. </strong> 1 a slip-knot with a double loop. B ribbon etc. So tied. 2 curved piece of wood etc. With a string stretched across its ends, for shooting arrows. 3 rod with horsehair stretched along its length, for playing the violin etc. 4 shallow curve or bend; thing of this form. —v. (also absol.) Use a bow on (a violin etc.). [old english]</p>",
      },
    ],
  },
  {
    word: "Bow2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bow2 <strong class='noun'> —v. </strong> 1 incline the head or body, esp. In greeting or acknowledgement. 2 submit (bowed to the inevitable). 3 cause (the head etc.) To incline. <strong class='noun'> —n. </strong> Act of bowing. bow and scrape toady. Bow down 1 bend or kneel esp. In submission or reverence. 2 make stoop; crush (bowed down by care). Bow out 1 exit (esp. Formally). 2 withdraw; retire. Take a bow acknowledge applause. [old english]</p>",
      },
    ],
  },
  {
    word: "Bow3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bow3 <strong class='noun'> n. </strong> 1 (often in pl.) Front end of a boat. 2 rower nearest this. [low german or dutch: related to *bough]</p>",
      },
    ],
  },
  {
    word: "Bowdlerize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowdlerize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) expurgate (a book etc.). bowdlerization <strong class='noun'> n. </strong> [bowdler, name of an expurgator of shakespeare]</p>",
      },
    ],
  },
  {
    word: "Bowel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowel <strong class='noun'> n. </strong> 1 (often in pl.) = *intestine. 2 (in pl.) Innermost parts. [latin botulus sausage]</p>",
      },
    ],
  },
  {
    word: "Bower",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bower <strong class='noun'> n. </strong> 1 arbour; summer-house. 2 poet. Inner room. [old english, = dwelling]</p>",
      },
    ],
  },
  {
    word: "Bowerbird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowerbird <strong class='noun'> n. </strong> Australasian bird, the male of which constructs elaborate runs.</p>",
      },
    ],
  },
  {
    word: "Bowie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowie <strong class='noun'> n. </strong> (in full bowie knife) a kind of long hunting-knife. [bowie, name of an american soldier]</p>",
      },
    ],
  },
  {
    word: "Bowl1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowl1 <strong class='noun'> n. </strong> 1 a usu. Round deep basin for food or liquid. B contents of a bowl. 2 hollow part of a tobacco-pipe, spoon, etc. bowlful <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong>. [old english]</p>",
      },
    ],
  },
  {
    word: "Bowl2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowl2 <strong class='noun'> —n. </strong> 1 hard heavy ball, made with a bias to run in a curve. 2 (in pl.; usu. Treated as sing.) Game played with these on grass. 3 spell or turn of bowling in cricket. —v. 1 a roll (a ball etc.). B play bowls. 2 (also absol.) Cricket etc. A deliver (a ball, over, etc.). B (often foll. By out) dismiss (a batsman) by knocking down the wicket with a ball. 3 (often foll. By along) go along rapidly. bowl out cricket etc. Dismiss (a batsman or a side). Bowl over 1 knock down. 2 <strong class='colloq'> colloq. </strong> Impress greatly, overwhelm. [latin bulla bubble]</p>",
      },
    ],
  },
  {
    word: "Bow-legs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bow-legs <strong class='n_pl'> n.pl. </strong> Bandy legs. bow-legged adj.</p>",
      },
    ],
  },
  {
    word: "Bowler1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowler1 <strong class='noun'> n. </strong> 1 cricket etc. Player who bowls. 2 bowls-player.</p>",
      },
    ],
  },
  {
    word: "Bowler2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowler2 <strong class='noun'> n. </strong> (in full bowler hat) man's hard round felt hat. [bowler, name of a hatter]</p>",
      },
    ],
  },
  {
    word: "Bowline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowline <strong class='noun'> n. </strong> 1 rope from a ship's bow keeping the sail taut against the wind. 2 knot forming a non-slipping loop at the end of a rope.</p>",
      },
    ],
  },
  {
    word: "Bowling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowling <strong class='noun'> n. </strong> The game of skittles, tenpin bowling, or bowls.</p>",
      },
    ],
  },
  {
    word: "Bowling-alley",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowling-alley <strong class='noun'> n. </strong> 1 long enclosure for skittles or tenpin bowling. 2 building with these.</p>",
      },
    ],
  },
  {
    word: "Bowling-green",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowling-green <strong class='noun'> n. </strong> Lawn for playing bowls.</p>",
      },
    ],
  },
  {
    word: "Bowman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bowman <strong class='noun'> n. </strong> Archer.</p>",
      },
    ],
  },
  {
    word: "Bowsprit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowsprit <strong class='noun'> n. </strong> Spar running forward from a ship's bow.</p>",
      },
    ],
  },
  {
    word: "Bowstring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bowstring <strong class='noun'> n. </strong> String of an archer's bow.</p>",
      },
    ],
  },
  {
    word: "Bow-tie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bow-tie <strong class='noun'> n. </strong> Necktie in the form of a bow.</p>",
      },
    ],
  },
  {
    word: "Bow-window",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bow-window <strong class='noun'> n. </strong> Curved bay window.</p>",
      },
    ],
  },
  {
    word: "Bow-wow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bow-wow <strong class='pl'> —int. </strong> Imitation of a dog's bark. <strong class='noun'> —n. </strong> Colloq. Dog. [imitative]</p>",
      },
    ],
  },
  {
    word: "Box1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box1 <strong class='noun'> —n. </strong> 1 container, usu. Flat-sided and firm. 2 amount contained in a box. 3 compartment, e.g. In a theatre or lawcourt. 4 receptacle or kiosk for a special purpose (often in <strong class='n_pl'> comb. </strong> : money box; telephone box). 5 facility at a newspaper office for receiving replies to an advertisement. 6 (prec. By the) <strong class='colloq'> colloq. </strong> Television. 7 enclosed area or space. 8 area of print enclosed by a border. 9 light shield for the genitals in cricket etc. 10 (prec. By the) football <strong class='colloq'> colloq. </strong> Penalty area. —v. 1 put in or provide with a box. 2 (foll. By in, up) confine. [latin buxis: related to *box3]</p>",
      },
    ],
  },
  {
    word: "Box2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box2 <strong class='noun'> —v. </strong> 1 a take part in boxing. B fight (an opponent) at boxing. 2 slap (esp. A person's ears). <strong class='noun'> —n. </strong> Hard slap, esp. On the ears. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Box3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box3 <strong class='noun'> n. </strong> 1 small evergreen tree with dark green leaves. 2 its fine hard wood. [latin buxus, greek puxos]</p>",
      },
    ],
  },
  {
    word: "Box and cox",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box and cox <strong class='noun'> n. </strong> Two people sharing accommodation etc. In shifts. [names of characters in a play (1847)]</p>",
      },
    ],
  },
  {
    word: "Box camera",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box camera <strong class='noun'> n. </strong> Simple box-shaped camera.</p>",
      },
    ],
  },
  {
    word: "Boxer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boxer <strong class='noun'> n. </strong> 1 person who boxes, esp. As a sport. 2 medium-size short-haired dog with a puglike face.</p>",
      },
    ],
  },
  {
    word: "Boxer shorts",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boxer shorts <strong class='n_pl'> n.pl. </strong> Men's loose underpants like shorts.</p>",
      },
    ],
  },
  {
    word: "Box girder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box girder <strong class='noun'> n. </strong> Hollow girder square in cross-section.</p>",
      },
    ],
  },
  {
    word: "Boxing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boxing <strong class='noun'> n. </strong> Fighting with the fists, esp. As a sport.</p>",
      },
    ],
  },
  {
    word: "Boxing day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boxing day <strong class='noun'> n. </strong> First weekday after christmas. [from *box1, from the custom of giving christmas-boxes]</p>",
      },
    ],
  },
  {
    word: "Boxing glove",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boxing glove <strong class='noun'> n. </strong> Each of a pair of heavily padded gloves worn in boxing.</p>",
      },
    ],
  },
  {
    word: "Box junction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box junction <strong class='noun'> n. </strong> Road area marked with a yellow grid, which a vehicle should enter only if its exit is clear.</p>",
      },
    ],
  },
  {
    word: "Box number",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box number <strong class='noun'> n. </strong> Number for replies to a private advertisement in a newspaper.</p>",
      },
    ],
  },
  {
    word: "Box office",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box office <strong class='noun'> n. </strong> Ticket-office at a theatre etc.</p>",
      },
    ],
  },
  {
    word: "Box pleat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box pleat <strong class='noun'> n. </strong> Arrangement of parallel pleats folding in alternate directions.</p>",
      },
    ],
  },
  {
    word: "Boxroom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boxroom <strong class='noun'> n. </strong> Small room for storing boxes, cases, etc.</p>",
      },
    ],
  },
  {
    word: "Box spring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Box spring <strong class='noun'> n. </strong> Each of a set of vertical springs in a frame, e.g. In a mattress.</p>",
      },
    ],
  },
  {
    word: "Boxwood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Boxwood <strong class='noun'> n. </strong> = *box3 2.</p>",
      },
    ],
  },
  {
    word: "Boxy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boxy <strong class='adj'> adj. </strong> (-ier, -iest) cramped.</p>",
      },
    ],
  },
  {
    word: "Boy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boy <strong class='noun'> —n. </strong> 1 male child, son. 2 young man. 3 male servant etc. <strong class='pl'> —int. </strong> Expressing pleasure, surprise, etc. boyhood <strong class='noun'> n. </strong> Boyish adj. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Boycott",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boycott <strong class='noun'> —v. </strong> 1 refuse to have social or commercial relations with (a person, country, etc.). 2 refuse to handle (goods). <strong class='noun'> —n. </strong> Such a refusal. [capt. Boycott, so treated from 1880]</p>",
      },
    ],
  },
  {
    word: "Boyfriend",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boyfriend <strong class='noun'> n. </strong> Person's regular male companion or lover.</p>",
      },
    ],
  },
  {
    word: "Boyo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boyo <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> welsh & ir. Colloq. (esp. As a form of address) boy, mate.</p>",
      },
    ],
  },
  {
    word: "Boy scout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Boy scout <strong class='noun'> n. </strong> = *scout <strong class='noun'> n. </strong> 4.</p>",
      },
    ],
  },
  {
    word: "Bp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bp <strong class='sing'> abbr. </strong> 1 boiling-point. 2 blood pressure. 3 before the present (era). 4 british petroleum. 5 british pharmacopoeia.</p>",
      },
    ],
  },
  {
    word: "Bq",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bq <strong class='sing'> abbr. </strong> Becquerel.</p>",
      },
    ],
  },
  {
    word: "Br",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Br <strong class='sing'> abbr. </strong> British rail.</p>",
      },
    ],
  },
  {
    word: "Br",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Br <strong class='prep'> symb. </strong> Bromine.</p>",
      },
    ],
  },
  {
    word: "Bra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bra <strong class='noun'> n. </strong> Undergarment worn by women to support the breasts. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Brace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brace <strong class='noun'> —n. </strong> 1 device that clamps or fastens tightly. 2 timber etc. Strengthening a framework. 3 (in pl.) Straps supporting trousers from the shoulders. 4 wire device for straightening the teeth. 5 <strong class='pl_same'> (pl. Same) </strong> pair (esp. Of game). 6 rope for trimming a sail. 7 connecting mark { or } in printing. —v. (-cing) 1 make steady by supporting. 2 fasten tightly to make firm. 3 (esp. As bracing adj.) Invigorate, refresh. 4 (often refl.) Prepare for a difficulty, shock, etc. [latin bracchia arms]</p>",
      },
    ],
  },
  {
    word: "Brace and bit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brace and bit <strong class='noun'> n. </strong> Revolving tool for boring, with a d-shaped central handle.</p>",
      },
    ],
  },
  {
    word: "Bracelet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bracelet <strong class='noun'> n. </strong> 1 ornamental band or chain worn on the wrist or arm. 2 slang handcuff.</p>",
      },
    ],
  },
  {
    word: "Brachiosaurus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brachiosaurus <strong class='noun'> n. </strong> (pl. -ruses) plant-eating dinosaur with forelegs longer than its hind legs. [latin from greek brakhion arm, sauros lizard]</p>",
      },
    ],
  },
  {
    word: "Bracken",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bracken <strong class='noun'> n. </strong> 1 large coarse fern. 2 mass of these. [old norse]</p>",
      },
    ],
  },
  {
    word: "Bracket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bracket <strong class='noun'> —n. </strong> 1 (esp. Angled) support projecting from a vertical surface. 2 shelf fixed to a wall with this. 3 each of a pair of marks () [] {} enclosing words or figures. 4 group or classification (income bracket). —v. (-t-) 1 enclose in brackets. 2 group or classify together. [latin bracae breeches]</p>",
      },
    ],
  },
  {
    word: "Brackish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brackish <strong class='adj'> adj. </strong> (of water etc.) Slightly salty. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Bract",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bract <strong class='noun'> n. </strong> Leaf-like and often brightly coloured part of a plant, growing before the flower. [latin bractea thin sheet]</p>",
      },
    ],
  },
  {
    word: "Brad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brad <strong class='noun'> n. </strong> Thin flat nail with a head on only one side. [old norse]</p>",
      },
    ],
  },
  {
    word: "Bradawl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bradawl <strong class='noun'> n. </strong> Small pointed tool for boring holes by hand.</p>",
      },
    ],
  },
  {
    word: "Brae",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brae <strong class='noun'> n. </strong> Scot. Hillside. [old norse]</p>",
      },
    ],
  },
  {
    word: "Brag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brag <strong class='noun'> —v. </strong> <strong class='gg'> (-gg-) </strong> talk boastfully. <strong class='noun'> —n. </strong> 1 card-game like poker. 2 boastful statement or talk. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Braggart",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Braggart <strong class='noun'> —n. </strong> Boastful person. <strong class='adj'> —adj.</strong> Boastful.</p>",
      },
    ],
  },
  {
    word: "Brahma",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brahma <strong class='noun'> n. </strong> 1 hindu creator. 2 supreme divine hindu reality. [sanskrit, = creator]</p>",
      },
    ],
  },
  {
    word: "Brahman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brahman <strong class='noun'> n. </strong> (also brahman) <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> 1 (also brahmin) member of the highest or priestly hindu caste. 2 = *brahma 2. brahmanic adj. Brahmanism n.</p>",
      },
    ],
  },
  {
    word: "Braid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Braid <strong class='noun'> —n. </strong> 1 woven band as edging or trimming. 2 us plait of hair. —v. 1 us plait. 2 trim with braid. braiding <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Braille",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Braille <strong class='noun'> —n. </strong> System of writing and printing for the blind, with patterns of raised dots. —v. (-ling) print or transcribe in braille. [braille, name of its inventor]</p>",
      },
    ],
  },
  {
    word: "Brain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brain <strong class='noun'> —n. </strong> 1 organ of soft nervous tissue in the skull of vertebrates, the centre of sensation and of intellectual and nervous activity. 2 a <strong class='colloq'> colloq. </strong> Intelligent person. B (often in pl.) Intelligence. 3 (usu. In pl.; prec. By the) <strong class='colloq'> colloq. </strong> Cleverest person in a group; mastermind. 4 electronic device functioning like a brain. —v. 1 dash out the brains of. 2 <strong class='colloq'> colloq. </strong> Strike hard on the head. on the brain <strong class='colloq'> colloq. </strong> Obsessively in one's thoughts. [old english]</p>",
      },
    ],
  },
  {
    word: "Brainchild",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brainchild <strong class='noun'> n. </strong> Colloq. Person's clever idea or invention.</p>",
      },
    ],
  },
  {
    word: "Brain death",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brain death <strong class='noun'> n. </strong> Irreversible brain damage causing the end of independent respiration, regarded as indicative of death. brain-dead adj.</p>",
      },
    ],
  },
  {
    word: "Brain drain",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brain drain <strong class='noun'> n. </strong> Colloq. Loss of skilled personnel by emigration.</p>",
      },
    ],
  },
  {
    word: "Brainless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brainless <strong class='adj'> adj. </strong> Foolish.</p>",
      },
    ],
  },
  {
    word: "Brainpower",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brainpower <strong class='noun'> n. </strong> Mental ability or intelligence.</p>",
      },
    ],
  },
  {
    word: "Brainstorm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brainstorm <strong class='noun'> n. </strong> 1 sudden mental disturbance. 2 <strong class='colloq'> colloq. </strong> Mental lapse. 3 us brainwave. 4 pooling of spontaneous ideas about a problem etc. brainstorming <strong class='noun'> n. </strong> (in sense 4).</p>",
      },
    ],
  },
  {
    word: "Brains trust",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brains trust <strong class='noun'> n. </strong> Group of experts answering questions, usu. Publicly and impromptu.</p>",
      },
    ],
  },
  {
    word: "Brainwash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brainwash <strong class='verb'> v. </strong> Implant ideas or esp. Ideology into (a person) by repetition etc. brainwashing n.</p>",
      },
    ],
  },
  {
    word: "Brainwave",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brainwave <strong class='noun'> n. </strong> 1 (usu. In pl.) Electrical impulse in the brain. 2 <strong class='colloq'> colloq. </strong> Sudden bright idea.</p>",
      },
    ],
  },
  {
    word: "Brainy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brainy <strong class='adj'> adj. </strong> (-ier, -iest) intellectually clever.</p>",
      },
    ],
  },
  {
    word: "Braise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Braise <strong class='verb'> v. </strong> <strong class='sing'>(-sing)</strong> stew slowly with a little liquid in a closed container. [french braise live coals]</p>",
      },
    ],
  },
  {
    word: "Brake1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brake1 <strong class='noun'> —n. </strong> 1 (often in pl.) Device for stopping or slowing a wheel, vehicle, etc. 2 thing that impedes. —v. (-king) 1 apply a brake. 2 slow or stop with a brake. [probably obsolete brake = curb]</p>",
      },
    ],
  },
  {
    word: "Brake2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brake2 <strong class='noun'> n. </strong> Large estate car. [var. Of *break]</p>",
      },
    ],
  },
  {
    word: "Brake3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brake3 <strong class='noun'> —n. </strong> 1 toothed instrument for crushing flax and hemp. 2 (in full brake harrow) heavy harrow. —v. (-king) crush (flax or hemp). [low german or dutch: related to *break]</p>",
      },
    ],
  },
  {
    word: "Brake4",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brake4 <strong class='noun'> n. </strong> Thicket or clump of brushwood. [old english]</p>",
      },
    ],
  },
  {
    word: "Brake drum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brake drum <strong class='noun'> n. </strong> Cylinder attached to a wheel, on which the brake shoes press to brake.</p>",
      },
    ],
  },
  {
    word: "Brake horsepower",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brake horsepower <strong class='noun'> n. </strong> Power of an engine measured by the force needed to brake it.</p>",
      },
    ],
  },
  {
    word: "Brake lining",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brake lining <strong class='noun'> n. </strong> Strip of fabric increasing the friction of a brake shoe.</p>",
      },
    ],
  },
  {
    word: "Brake shoe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brake shoe <strong class='noun'> n. </strong> Long curved block which presses on a brake drum to brake.</p>",
      },
    ],
  },
  {
    word: "Bramble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bramble <strong class='noun'> n. </strong> Wild thorny shrub, esp. The blackberry. brambly adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Brambling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brambling <strong class='noun'> n. </strong> The speckled finch. [german: related to *bramble]</p>",
      },
    ],
  },
  {
    word: "Bran",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bran <strong class='noun'> n. </strong> Grain husks separated from flour. [french]</p>",
      },
    ],
  },
  {
    word: "Branch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Branch <strong class='noun'> —n. </strong> 1 limb of a tree or bough. 2 lateral extension or subdivision, esp. Of a river, road, or railway. 3 subdivision of a family, knowledge, etc. 4 local office etc. Of a large business. —v. (often foll. By off) 1 diverge. 2 divide into branches. branch out extend one's field of interest. [latin branca paw]</p>",
      },
    ],
  },
  {
    word: "Brand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brand <strong class='noun'> —n. </strong> 1 a particular make of goods. B identifying trade mark, label, etc. 2 (usu. Foll. By of) characteristic kind (brand of humour). 3 identifying mark burned esp. On livestock. 4 iron used for this. 5 piece of burning or charred wood. 6 stigma; mark of disgrace. 7 poet. Torch. —v. 1 mark with a hot iron. 2 stigmatize (branded him a liar). 3 impress unforgettably. 4 assign a trademark etc. To. [old english]</p>",
      },
    ],
  },
  {
    word: "Brandish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brandish <strong class='verb'> v. </strong> Wave or flourish as a threat or display. [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Brand-new",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brand-new <strong class='adj'> adj. </strong> Completely new.</p>",
      },
    ],
  },
  {
    word: "Brandy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brandy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> strong alcoholic spirit distilled from wine or fermented fruit juice. [dutch brandewijn]</p>",
      },
    ],
  },
  {
    word: "Brandy butter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brandy butter <strong class='noun'> n. </strong> Mixture of brandy, butter, and sugar.</p>",
      },
    ],
  },
  {
    word: "Brandy-snap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brandy-snap <strong class='noun'> n. </strong> Crisp rolled gingerbread wafer usu. Filled with cream.</p>",
      },
    ],
  },
  {
    word: "Bran-tub",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bran-tub <strong class='noun'> n. </strong> Lucky dip with prizes hidden in bran.</p>",
      },
    ],
  },
  {
    word: "Brash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brash <strong class='adj'> adj. </strong> Vulgarly self-assertive; impudent. brashly <strong class='adv'> adv. </strong> Brashness <strong class='noun'> n. </strong> [dial.]</p>",
      },
    ],
  },
  {
    word: "Brass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brass <strong class='noun'> —n. </strong> 1 yellow alloy of copper and zinc. 2 brass objects collectively. 3 brass wind instruments. 4 slang money. 5 brass memorial tablet. 6 <strong class='colloq'> colloq. </strong> Effrontery. <strong class='adj'> —adj.</strong> Made of brass. brassed off slang fed up. [old english]</p>",
      },
    ],
  },
  {
    word: "Brass band",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brass band <strong class='noun'> n. </strong> Band of brass instruments.</p>",
      },
    ],
  },
  {
    word: "Brasserie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brasserie <strong class='noun'> n. </strong> Restaurant, <strong class='archaic_it_was'> orig. </strong> One serving beer with food. [french brasser brew]</p>",
      },
    ],
  },
  {
    word: "Brassica",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brassica <strong class='noun'> n. </strong> Plant of the cabbage family. [latin, = cabbage]</p>",
      },
    ],
  },
  {
    word: "Brassière",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brassière <strong class='noun'> n. </strong> = *bra. [french]</p>",
      },
    ],
  },
  {
    word: "Brass monkey",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brass monkey <strong class='noun'> n. </strong> Coarse slang used in various phrases to indicate extreme cold.</p>",
      },
    ],
  },
  {
    word: "Brass-rubbing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brass-rubbing <strong class='noun'> n. </strong> 1 practice of taking impressions by rubbing heelball etc. Over paper laid on engraved brasses. 2 impression obtained by this.</p>",
      },
    ],
  },
  {
    word: "Brass tacks",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brass tacks <strong class='n_pl'> n.pl. </strong> Slang essential details.</p>",
      },
    ],
  },
  {
    word: "Brassy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brassy <strong class='adj'> adj. </strong> (-ier, -iest) 1 of or like brass. 2 impudent. 3 vulgarly showy. 4 loud and blaring.</p>",
      },
    ],
  },
  {
    word: "Brat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brat <strong class='noun'> n. </strong> Usu. Derog. Child, esp. An ill-behaved one. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bravado",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bravado <strong class='noun'> n. </strong> Show of boldness. [spanish]</p>",
      },
    ],
  },
  {
    word: "Brave",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brave <strong class='adj'> —adj.</strong> 1 able or ready to face and endure danger, disgrace, or pain. 2 formal splendid, spectacular. <strong class='noun'> —n. </strong> American indian warrior. —v. <strong class='ving'> (-ving) </strong> face bravely or defiantly. bravely <strong class='adv'> adv. </strong> Braveness <strong class='noun'> n. </strong> Bravery <strong class='noun'> n. </strong> [ultimately latin barbarus barbarian]</p>",
      },
    ],
  },
  {
    word: "Bravo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bravo <strong class='pl'> —int. </strong> Expressing approval. <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> cry of ‘bravo’. [french from italian]</p>",
      },
    ],
  },
  {
    word: "Bravura",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bravura <strong class='noun'> n. </strong> 1 brilliance of execution. 2 (often attrib.) Passage of (esp. Vocal) music requiring brilliant technique. [italian]</p>",
      },
    ],
  },
  {
    word: "Brawl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brawl <strong class='noun'> —n. </strong> Noisy quarrel or fight. —v. 1 engage in a brawl. 2 (of a stream) run noisily. [provençal]</p>",
      },
    ],
  },
  {
    word: "Brawn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brawn <strong class='noun'> n. </strong> 1 muscular strength. 2 muscle; lean flesh. 3 jellied meat made from a pig's head. brawny adj. (-ier, -iest). [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Bray",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bray <strong class='noun'> —n. </strong> 1 cry of a donkey. 2 harsh sound like this. —v. 1 make a bray. 2 utter harshly. [french braire]</p>",
      },
    ],
  },
  {
    word: "Braze",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Braze <strong class='verb'> v. </strong> (-zing) solder with an alloy of brass and zinc. [french braser]</p>",
      },
    ],
  },
  {
    word: "Brazen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brazen <strong class='adj'> —adj.</strong> 1 shameless; insolent. 2 of or like brass. 3 harsh in sound. —v. (foll. By out) face or undergo defiantly (brazen it out). brazenly <strong class='adv'> adv. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Brazier1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brazier1 <strong class='noun'> n. </strong> Metal pan or stand holding burning coals etc. [french: related to *braise]</p>",
      },
    ],
  },
  {
    word: "Brazier2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brazier2 <strong class='noun'> n. </strong> Worker in brass. [probably from *brass]</p>",
      },
    ],
  },
  {
    word: "Brazil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brazil <strong class='noun'> n. </strong> 1 tall s. American tree. 2 (in full brazil nut) its large three-sided nut. [brazil in s. America]</p>",
      },
    ],
  },
  {
    word: "Breach",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breach <strong class='noun'> —n. </strong> 1 (often foll. By of) breaking or non-observation of a law, contract, etc. 2 breaking of relations; quarrel. 3 opening, gap. —v. 1 break through; make a gap in. 2 break (a law, contract, etc.). step into the breach help in a crisis, esp. As a replacement. [germanic: related to *break]</p>",
      },
    ],
  },
  {
    word: "Breach of promise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breach of promise <strong class='noun'> n. </strong> Breaking of a promise, esp. To marry.</p>",
      },
    ],
  },
  {
    word: "Breach of the peace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breach of the peace <strong class='noun'> n. </strong> Crime of causing a public disturbance.</p>",
      },
    ],
  },
  {
    word: "Bread",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bread <strong class='noun'> —n. </strong> 1 baked dough of flour and water, usu. Leavened with yeast. 2 necessary food. 3 slang money. —v. Coat with breadcrumbs for cooking. [old english]</p>",
      },
    ],
  },
  {
    word: "Bread and butter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bread and butter <strong class='noun'> —n. </strong> One's livelihood. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> (bread-and-butter) done or produced to earn a basic living.</p>",
      },
    ],
  },
  {
    word: "Breadboard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breadboard <strong class='noun'> n. </strong> 1 board for cutting bread on. 2 board for making an experimental model of an electric circuit.</p>",
      },
    ],
  },
  {
    word: "Breadcrumb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breadcrumb <strong class='noun'> n. </strong> Small fragment of bread, esp. (in pl.) For use in cooking.</p>",
      },
    ],
  },
  {
    word: "Breadfruit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breadfruit <strong class='noun'> n. </strong> 1 fruit which resembles new bread when roasted. 2 tropical evergreen tree bearing it.</p>",
      },
    ],
  },
  {
    word: "Breadline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breadline <strong class='noun'> n. </strong> Subsistence level (esp. On the breadline).</p>",
      },
    ],
  },
  {
    word: "Bread sauce",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bread sauce <strong class='noun'> n. </strong> White sauce thickened with breadcrumbs.</p>",
      },
    ],
  },
  {
    word: "Breadth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breadth <strong class='noun'> n. </strong> 1 distance or measurement from side to side of a thing. 2 freedom from prejudice or intolerance. [old english: related to *broad]</p>",
      },
    ],
  },
  {
    word: "Breadwinner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breadwinner <strong class='noun'> n. </strong> Person who works to support a family.</p>",
      },
    ],
  },
  {
    word: "Break",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Break <strong class='noun'> —v. </strong> (past broke;  <strong class='archaic_it_was'>past part.</strong> Broken) 1 a separate into pieces under a blow or strain; shatter. B make or become inoperative. C break a bone in or dislocate (part of the body). 2 a interrupt (broke our journey). B have an interval (broke for tea). 3 fail to keep (a law, promise, etc.). 4 a make or become subdued or weak; (cause to) yield; destroy. B weaken the effect of (a fall, blow, etc.). C = break in 3c. 5 surpass (a record). 6 (foll. By with) end a friendship with (a person etc.). 7 a be no longer subject to (a habit). B (foll. By of) free (a person) from a habit (broke them of their addiction). 8 reveal or be revealed (broke the news; story broke). 9 a (of fine weather) change suddenly. B (of waves) curl over and foam. C (of the day) dawn. D (of clouds) move apart. E (of a storm) begin violently. 10 electr. Disconnect (a circuit). 11 a (of the voice) change with emotion. B (of a boy's voice) change at puberty. 12 a (often foll. By up) divide (a set etc.). B change (a banknote etc.) For coins. 13 ruin financially (see also *broke adj.). 14 penetrate (e.g. A safe) by force. 15 decipher (a code). 16 make (a way, path, etc.) By force. 17 burst forth (sun broke through). 18 a (of troops) disperse in confusion. B rupture (ranks). 19 a (usu. Foll.</p>",
      },
    ],
  },
  {
    word: "Breakable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breakable <strong class='adj'> —adj.</strong> Easily broken. <strong class='noun'> —n. </strong> (esp. In pl.) Breakable thing.</p>",
      },
    ],
  },
  {
    word: "Breakage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breakage <strong class='noun'> n. </strong> 1 a broken thing. B damage caused by breaking. 2 act or instance of breaking.</p>",
      },
    ],
  },
  {
    word: "Breakaway",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breakaway <strong class='noun'> n. </strong> (often attrib.) Breaking away; secession (breakaway group).</p>",
      },
    ],
  },
  {
    word: "Break-dancing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Break-dancing <strong class='noun'> n. </strong> Acrobatic style of street-dancing.</p>",
      },
    ],
  },
  {
    word: "Breakdown",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breakdown <strong class='noun'> n. </strong> 1 a mechanical failure. B loss of (esp. Mental) health. 2 collapse (breakdown of communication). 3 analysis (of statistics etc.).</p>",
      },
    ],
  },
  {
    word: "Breaker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breaker <strong class='noun'> n. </strong> 1 heavy breaking wave. 2 person or thing that breaks something, esp. Disused machinery.</p>",
      },
    ],
  },
  {
    word: "Breakfast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breakfast <strong class='noun'> —n. </strong> First meal of the day. —v. Have breakfast.</p>",
      },
    ],
  },
  {
    word: "Break-in",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Break-in <strong class='noun'> n. </strong> Illegal forced entry, esp. With criminal intent.</p>",
      },
    ],
  },
  {
    word: "Breaking and entering",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breaking and entering <strong class='noun'> n. </strong> (formerly) the illegal entering of a building with intent to commit a felony.</p>",
      },
    ],
  },
  {
    word: "Breaking-point",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breaking-point <strong class='noun'> n. </strong> Point of greatest strain.</p>",
      },
    ],
  },
  {
    word: "Breakneck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breakneck <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> (of speed) dangerously fast.</p>",
      },
    ],
  },
  {
    word: "Break-out",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Break-out <strong class='noun'> n. </strong> Forcible escape.</p>",
      },
    ],
  },
  {
    word: "Breakthrough",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breakthrough <strong class='noun'> n. </strong> 1 major advance or discovery. 2 act of breaking through an obstacle etc.</p>",
      },
    ],
  },
  {
    word: "Breakup",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breakup <strong class='noun'> n. </strong> 1 disintegration or collapse. 2 dispersal.</p>",
      },
    ],
  },
  {
    word: "Breakwater",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breakwater <strong class='noun'> n. </strong> Barrier breaking the force of waves.</p>",
      },
    ],
  },
  {
    word: "Bream",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bream <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> 1 yellowish arch-backed freshwater fish. 2 (in full sea bream) similar marine fish. [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Breast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breast <strong class='noun'> —n. </strong> 1 a either of two milk-secreting organs on a woman's chest. B corresponding part of a man's body. 2 a chest. B corresponding part of an animal. 3 part of a garment that covers the breast. 4 breast as a source of nourishment or emotion. —v. 1 contend with. 2 reach the top of (a hill). make a clean breast of confess fully. [old english]</p>",
      },
    ],
  },
  {
    word: "Breastbone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breastbone <strong class='noun'> n. </strong> Thin flat vertical bone in the chest between the ribs.</p>",
      },
    ],
  },
  {
    word: "Breast-feed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breast-feed <strong class='verb'> v. </strong> Feed (a baby) from the breast.</p>",
      },
    ],
  },
  {
    word: "Breastplate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breastplate <strong class='noun'> n. </strong> Armour covering the breast.</p>",
      },
    ],
  },
  {
    word: "Breast-stroke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breast-stroke <strong class='noun'> n. </strong> Swimming stroke made by extending both arms forward and sweeping them back.</p>",
      },
    ],
  },
  {
    word: "Breastwork",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breastwork <strong class='noun'> n. </strong> Low temporary defence or parapet.</p>",
      },
    ],
  },
  {
    word: "Breath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breath <strong class='noun'> n. </strong> 1 a air drawn into or expelled from the lungs. B one respiration of air. C breath as perceived by the senses. 2 a slight movement of air. B whiff (of perfume etc.). 3 whisper, murmur (esp. Of scandal). catch one's breath 1 cease breathing momentarily in surprise etc. 2 rest to restore normal breathing. Hold one's breath cease breathing temporarily. Out of breath gasping for air, esp. After exercise. Take one's breath away surprise, delight, etc. Under one's breath in a whisper. [old english]</p>",
      },
    ],
  },
  {
    word: "Breathalyser",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breathalyser <strong class='noun'> n. </strong> (also -lyzer) propr. Instrument for measuring alcohol levels in the breath exhaled into it. breathalyse v. (also -lyze) (-sing or -zing). [from *breath, *analyse]</p>",
      },
    ],
  },
  {
    word: "Breathe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breathe <strong class='verb'> v. </strong> (-thing) 1 draw air into and expel it from the lungs. 2 be or seem alive. 3 a utter or sound (esp. Quietly). B express (breathed defiance). 4 pause. 5 send out or take in (as if) with the breath (breathed new life into them; breathed whisky). 6 (of wine etc.) Be exposed to the air. breathe again (or freely) feel relief.</p>",
      },
    ],
  },
  {
    word: "Breather",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breather <strong class='noun'> n. </strong> 1 <strong class='colloq'> colloq. </strong> Brief pause for rest. 2 brief period in the fresh air.</p>",
      },
    ],
  },
  {
    word: "Breathing-space",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breathing-space <strong class='noun'> n. </strong> Time to recover; pause.</p>",
      },
    ],
  },
  {
    word: "Breathless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breathless <strong class='adj'> adj. </strong> 1 panting, out of breath. 2 holding the breath. 3 still, windless. breathlessly adv.</p>",
      },
    ],
  },
  {
    word: "Breathtaking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breathtaking <strong class='adj'> adj. </strong> Astounding; awe-inspiring. breathtakingly adv.</p>",
      },
    ],
  },
  {
    word: "Breath test",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breath test <strong class='noun'> n. </strong> Test with a breathalyser.</p>",
      },
    ],
  },
  {
    word: "Bred  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bred  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>breed.</p>",
      },
    ],
  },
  {
    word: "Breech",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breech <strong class='noun'> n. </strong> Back part of a rifle or gun barrel. [old english]</p>",
      },
    ],
  },
  {
    word: "Breech birth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breech birth <strong class='noun'> n. </strong> (also breech delivery) delivery of a baby with the buttocks or feet foremost.</p>",
      },
    ],
  },
  {
    word: "Breeches",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breeches <strong class='n_pl'> n.pl. </strong> Short trousers, esp. Fastened below the knee.</p>",
      },
    ],
  },
  {
    word: "Breeches buoy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breeches buoy <strong class='noun'> n. </strong> Lifebuoy with canvas breeches for the user's legs.</p>",
      },
    ],
  },
  {
    word: "Breed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breed <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Bred) 1 (of animals) produce young. 2 propagate; raise (animals). 3 yield; result in. 4 arise; spread. 5 bring up; train. 6 create (fissile material) by nuclear reaction. <strong class='noun'> —n. </strong> 1 stock of similar animals or plants within a species, usu. Developed by deliberate selection. 2 race; lineage. 3 sort, kind. breeder <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Breeder reactor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breeder reactor <strong class='noun'> n. </strong> Nuclear reactor creating surplus fissile material.</p>",
      },
    ],
  },
  {
    word: "Breeding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breeding <strong class='noun'> n. </strong> 1 raising of offspring; propagation. 2 social behaviour; ancestry.</p>",
      },
    ],
  },
  {
    word: "Breeze1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breeze1 <strong class='noun'> —n. </strong> 1 gentle wind. 2 <strong class='colloq'> colloq. </strong> Quarrel. 3 esp. Us <strong class='colloq'> colloq. </strong> Easy task. —v. (-zing) (foll. By in, out, along, etc.) Colloq. Saunter casually. [probably spanish and portuguese briza]</p>",
      },
    ],
  },
  {
    word: "Breeze2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breeze2 <strong class='noun'> n. </strong> Small cinders. [french: related to *braise]</p>",
      },
    ],
  },
  {
    word: "Breeze-block",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breeze-block <strong class='noun'> n. </strong> Lightweight building block, esp. Of breeze mixed with sand and cement.</p>",
      },
    ],
  },
  {
    word: "Breezy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breezy <strong class='adj'> adj. </strong> (-ier, -iest) 1 slightly windy. 2 <strong class='colloq'> colloq. </strong> Cheerful, light-hearted, casual.</p>",
      },
    ],
  },
  {
    word: "Bren",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bren <strong class='noun'> n. </strong> (in full bren gun) lightweight quick-firing machine-gun. [brno in czechoslovakia, enfield in england]</p>",
      },
    ],
  },
  {
    word: "Brent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brent <strong class='noun'> n. </strong> (in full brent-goose) small migratory goose. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Brethren",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Brethren <strong class='see'> see *</strong> brother.</p>",
      },
    ],
  },
  {
    word: "Breton",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breton <strong class='noun'> —n. </strong> 1 native of brittany. 2 celtic language of brittany. <strong class='adj'> —adj.</strong> Of brittany, its people, or language. [french, = *briton]</p>",
      },
    ],
  },
  {
    word: "Breve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breve <strong class='noun'> n. </strong> 1 <strong class='mus'> mus. </strong> Note twice the length of a semibreve. 2 mark ( ) indicating a short or unstressed vowel. [var. Of *brief]</p>",
      },
    ],
  },
  {
    word: "Breviary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Breviary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> book containing the roman catholic daily office. [latin: related to *brief]</p>",
      },
    ],
  },
  {
    word: "Brevity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brevity <strong class='noun'> n. </strong> 1 economy of expression; conciseness. 2 shortness (of time etc.). [anglo-french: related to *brief]</p>",
      },
    ],
  },
  {
    word: "Brew",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brew <strong class='noun'> —v. </strong> 1 a make (beer etc.) By infusion, boiling, and fermentation. B make (tea etc.) By infusion. 2 undergo these processes. 3 gather force; threaten (storm is brewing). 4 concoct (a plan etc.). <strong class='noun'> —n. </strong> 1 liquid or amount brewed; concoction. 2 process of brewing. brew up make tea. brewer <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Brewery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brewery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> factory for brewing beer etc.</p>",
      },
    ],
  },
  {
    word: "Brew-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brew-up <strong class='noun'> n. </strong> Instance of making tea.</p>",
      },
    ],
  },
  {
    word: "Briar1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Briar1 <strong class='var'> var. </strong> Of *brier1.</p>",
      },
    ],
  },
  {
    word: "Briar2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Briar2 <strong class='var'> var. </strong> Of *brier2.</p>",
      },
    ],
  },
  {
    word: "Bribe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bribe <strong class='noun'> —v. </strong> (-bing) (often foll. By to + infin.) Persuade to act improperly in one's favour by a gift of money etc. <strong class='noun'> —n. </strong> Money or services offered in bribing. bribery <strong class='noun'> n. </strong> [french briber beg]</p>",
      },
    ],
  },
  {
    word: "Bric-à-brac",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bric-à-brac <strong class='noun'> n. </strong> (also bric-a-brac) cheap ornaments, trinkets, etc. [french]</p>",
      },
    ],
  },
  {
    word: "Brick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brick <strong class='noun'> —n. </strong> 1 a small usu. Rectangular block of fired or sun-dried clay used in building. B material of this. 2 child's toy block. 3 brick-shaped thing. 4 slang generous or loyal person. —v. (foll. By in, up) close or block with brickwork. <strong class='adj'> —adj.</strong> 1 built of brick (brick wall). 2 (also brick-red) dull red. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Brickbat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brickbat <strong class='noun'> n. </strong> 1 piece of brick, esp. As a missile. 2 insult.</p>",
      },
    ],
  },
  {
    word: "Brickie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brickie <strong class='noun'> n. </strong> Slang bricklayer.</p>",
      },
    ],
  },
  {
    word: "Bricklayer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bricklayer <strong class='noun'> n. </strong> Person who builds with bricks, esp. For a living. bricklaying n.</p>",
      },
    ],
  },
  {
    word: "Brickwork",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brickwork <strong class='noun'> n. </strong> Building or work in brick.</p>",
      },
    ],
  },
  {
    word: "Brickyard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brickyard <strong class='noun'> n. </strong> Place where bricks are made.</p>",
      },
    ],
  },
  {
    word: "Bridal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridal <strong class='adj'> adj. </strong> Of a bride or wedding. [old english]</p>",
      },
    ],
  },
  {
    word: "Bride",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bride <strong class='noun'> n. </strong> Woman on her wedding day and during the period just before and after it. [old english]</p>",
      },
    ],
  },
  {
    word: "Bridegroom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridegroom <strong class='noun'> n. </strong> Man on his wedding day and during the period just before and after it. [old english]</p>",
      },
    ],
  },
  {
    word: "Bridesmaid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridesmaid <strong class='noun'> n. </strong> Girl or unmarried woman attending a bride at her wedding.</p>",
      },
    ],
  },
  {
    word: "Bridge1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridge1 <strong class='noun'> —n. </strong> 1 a structure providing a way across a river, road, railway, etc. B thing joining or connecting. 2 operational superstructure on a ship. 3 upper bony part of the nose. 4 piece of wood on a violin etc. Over which the strings are stretched. 5 = *bridgework. —v. (-ging) 1 be or make a bridge over. 2 reduce (a gap, deficiency, etc.). [old english]</p>",
      },
    ],
  },
  {
    word: "Bridge2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridge2 <strong class='noun'> n. </strong> Card-game derived from whist. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bridgehead",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridgehead <strong class='noun'> n. </strong> Fortified position held on the enemy's side of a river etc.</p>",
      },
    ],
  },
  {
    word: "Bridge roll",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridge roll <strong class='noun'> n. </strong> Small soft bread roll.</p>",
      },
    ],
  },
  {
    word: "Bridgework",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridgework <strong class='noun'> n. </strong> Dentistry dental structure covering a gap, joined to the teeth on either side.</p>",
      },
    ],
  },
  {
    word: "Bridging loan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridging loan <strong class='noun'> n. </strong> Loan to cover the interval between buying a house etc. And selling another.</p>",
      },
    ],
  },
  {
    word: "Bridle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridle <strong class='noun'> —n. </strong> 1 headgear for controlling a horse, including reins and bit. 2 restraining thing. —v. (-ling) 1 put a bridle on. 2 curb, restrain. 3 (often foll. By up) express anger, offence, etc., esp. By throwing up the head and drawing in the chin. [old english]</p>",
      },
    ],
  },
  {
    word: "Bridle-path",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bridle-path <strong class='noun'> n. </strong> (also bridle-way) rough path for riders or walkers.</p>",
      },
    ],
  },
  {
    word: "Brie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brie <strong class='noun'> n. </strong> A kind of soft cheese. [brie in <strong class='noun'> n. </strong> France]</p>",
      },
    ],
  },
  {
    word: "Brief",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brief <strong class='adj'> —adj.</strong> 1 of short duration. 2 concise; abrupt, brusque. 3 scanty (brief skirt). <strong class='noun'> —n. </strong> 1 (in pl.) Short pants. 2 a summary of a case drawn up for counsel. B piece of work for a barrister. 3 instructions for a task. 4 papal letter on discipline. —v. 1 instruct (a barrister) by brief. 2 inform or instruct in advance. hold a brief for argue in favour of. In brief to sum up. briefly <strong class='adv'> adv. </strong> Briefness <strong class='noun'> n. </strong> [latin brevis short]</p>",
      },
    ],
  },
  {
    word: "Briefcase",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Briefcase <strong class='noun'> n. </strong> Flat document case.</p>",
      },
    ],
  },
  {
    word: "Brier1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brier1 <strong class='noun'> n. </strong> (also briar) wild rose or other prickly bush. [old english]</p>",
      },
    ],
  },
  {
    word: "Brier2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brier2 <strong class='noun'> n. </strong> (also briar) 1 white heath of s. Europe. 2 tobacco pipe made from its root. [french bruyère]</p>",
      },
    ],
  },
  {
    word: "Brig.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brig. <strong class='abbr'> Abbr. </strong> Brigadier.</p>",
      },
    ],
  },
  {
    word: "Brig1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brig1 <strong class='noun'> n. </strong> Two-masted square-rigged ship. [abbreviation of *brigantine]</p>",
      },
    ],
  },
  {
    word: "Brig2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brig2 <strong class='noun'> n. </strong> Scot. & n.engl. Bridge. [var. Of *bridge1]</p>",
      },
    ],
  },
  {
    word: "Brigade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brigade <strong class='noun'> n. </strong> 1 military unit, usu. Three battalions, as part of a division. 2 group organized for a special purpose. [italian briga strife]</p>",
      },
    ],
  },
  {
    word: "Brigadier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brigadier <strong class='noun'> n. </strong> 1 officer commanding a brigade. 2 staff officer of similar standing.</p>",
      },
    ],
  },
  {
    word: "Brigand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brigand <strong class='noun'> n. </strong> Member of a robber band; bandit. brigandage <strong class='noun'> n. </strong> [italian brigante: related to *brigade]</p>",
      },
    ],
  },
  {
    word: "Brigantine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brigantine <strong class='noun'> n. </strong> Two-masted ship with a square-rigged foremast and a fore-and-aft rigged mainmast. [french or italian: related to *brigand]</p>",
      },
    ],
  },
  {
    word: "Bright",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bright <strong class='adj'> —adj.</strong> 1 emitting or reflecting much light; shining. 2 intense, vivid. 3 clever. 4 cheerful. <strong class='adv'> —adv. </strong> Esp. Poet. Brightly. brightly <strong class='adv'> adv. </strong> Brightness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Brighten",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brighten <strong class='verb'> v. </strong> Make or become brighter.</p>",
      },
    ],
  },
  {
    word: "Bright's disease",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bright's disease <strong class='noun'> n. </strong> Kidney disease. [bright, name of a physician]</p>",
      },
    ],
  },
  {
    word: "Brill1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brill1 <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> european flat-fish. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Brill2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brill2 <strong class='adj'> adj. </strong> Colloq. = *brilliant adj. 4. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Brilliant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brilliant <strong class='adj'> —adj.</strong> 1 very bright; sparkling. 2 outstandingly talented. 3 showy. 4 <strong class='colloq'> colloq. </strong> Excellent. <strong class='noun'> —n. </strong> Diamond of the finest cut with many facets. brilliance <strong class='noun'> n. </strong> Brilliantly <strong class='adv'> adv. </strong> [french briller shine, from italian]</p>",
      },
    ],
  },
  {
    word: "Brilliantine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brilliantine <strong class='noun'> n. </strong> Dressing for making the hair glossy. [french: related to *brilliant]</p>",
      },
    ],
  },
  {
    word: "Brim",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brim <strong class='noun'> —n. </strong> 1 edge or lip of a vessel. 2 projecting edge of a hat. —v. (-mm-) fill or be full to the brim. brim over overflow. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Brim-full",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brim-full <strong class='adj'> adj. </strong> (also brimful) filled to the brim.</p>",
      },
    ],
  },
  {
    word: "Brimstone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brimstone <strong class='noun'> n. </strong> Archaic sulphur. [from *burn1, *stone]</p>",
      },
    ],
  },
  {
    word: "Brindled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brindled <strong class='adj'> adj. </strong> (esp. Of domestic animals) brown or tawny with streaks of another colour. [scandinavian]</p>",
      },
    ],
  },
  {
    word: "Brine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brine <strong class='noun'> n. </strong> 1 water saturated or strongly impregnated with salt. 2 sea water. [old english]</p>",
      },
    ],
  },
  {
    word: "Bring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bring <strong class='verb'> v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Brought) 1 come carrying; lead, accompany; convey. 2 cause or result in (war brings misery). 3 be sold for; produce as income. 4 a prefer (a charge). B initiate (legal action). 5 cause to become or to reach a state (brings me alive; cannot bring myself to agree). 6 adduce (evidence, an argument, etc.). bring about cause to happen. Bring back call to mind. Bring down 1 cause to fall. 2 lower (a price). Bring forth 1 give birth to. 2 cause. Bring forward 1 move to an earlier time. 2 transfer from the previous page or account. 3 draw attention to. Bring home to cause to realize fully. Bring the house down receive rapturous applause. Bring in 1 introduce. 2 yield as income or profit. Bring off achieve successfully. Bring on cause to happen, appear, or make progress. Bring out 1 emphasize; make evident. 2 publish. Bring over convert to one's own side. Bring round 1 restore to consciousness. 2 persuade. Bring through aid (a person) through adversity, esp. Illness. Bring to restore to consciousness (brought him to). Bring up 1 rear (a child). 2 vomit. 3 call attention to. 4 (absol.) Stop suddenly. [old english]</p>",
      },
    ],
  },
  {
    word: "Bring-and-buy sale",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bring-and-buy sale <strong class='noun'> n. </strong> Charity sale at which people bring items for sale and buy those brought by others.</p>",
      },
    ],
  },
  {
    word: "Brink",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brink <strong class='noun'> n. </strong> 1 extreme edge of land before a precipice, river, etc. 2 furthest point before danger, discovery, etc. on the brink of about to experience or suffer; in imminent danger of. [old norse]</p>",
      },
    ],
  },
  {
    word: "Brinkmanship",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brinkmanship <strong class='noun'> n. </strong> Pursuit (esp. Habitual) of danger etc. To the brink of catastrophe.</p>",
      },
    ],
  },
  {
    word: "Briny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Briny <strong class='adj'> —adj.</strong> (-ier, -iest) of brine or the sea; salty. <strong class='noun'> —n. </strong> (prec. By the) slang the sea.</p>",
      },
    ],
  },
  {
    word: "Briquette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Briquette <strong class='noun'> n. </strong> Block of compressed coal-dust as fuel. [french diminutive: related to *brick]</p>",
      },
    ],
  },
  {
    word: "Brisk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brisk <strong class='adj'> —adj.</strong> 1 quick, lively, keen (brisk pace, trade). 2 enlivening (brisk wind). —v. (often foll. By up) make or grow brisk. briskly <strong class='adv'> adv. </strong> Briskness <strong class='noun'> n. </strong> [probably french *brusque]</p>",
      },
    ],
  },
  {
    word: "Brisket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brisket <strong class='noun'> n. </strong> Animal's breast, esp. As a joint of meat. [french]</p>",
      },
    ],
  },
  {
    word: "Brisling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brisling <strong class='noun'> n. </strong> Small herring or sprat. [norwegian and danish]</p>",
      },
    ],
  },
  {
    word: "Bristle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bristle <strong class='noun'> —n. </strong> Short stiff hair, esp. One on an animal's back, used in brushes. —v. (-ling) 1 a (of hair) stand upright. B make (hair) do this. 2 show irritation. 3 (usu. Foll. By with) be covered or abundant (in). bristly adj. (-ier, -iest). [old english]</p>",
      },
    ],
  },
  {
    word: "Brit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brit <strong class='noun'> n. </strong> Colloq. British person. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Britannia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Britannia <strong class='noun'> n. </strong> Personification of britain, esp. As a helmeted woman with shield and trident. [latin]</p>",
      },
    ],
  },
  {
    word: "Britannia metal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Britannia metal <strong class='noun'> n. </strong> Silvery alloy of tin, antimony, and copper.</p>",
      },
    ],
  },
  {
    word: "Britannic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Britannic <strong class='adj'> adj. </strong> (esp. In his or her britannic majesty) of britain.</p>",
      },
    ],
  },
  {
    word: "Briticism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Briticism <strong class='noun'> n. </strong> Idiom used only in britain. [after gallicism]</p>",
      },
    ],
  },
  {
    word: "British",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>British <strong class='adj'> —adj.</strong> Of great britain, the british commonwealth, or their people. <strong class='noun'> —n. </strong> (prec. By the; treated as pl.) The british people. [old english]</p>",
      },
    ],
  },
  {
    word: "British english",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>British english <strong class='noun'> n. </strong> English as used in great britain.</p>",
      },
    ],
  },
  {
    word: "British legion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>British legion <strong class='noun'> n. </strong> = *royal british legion.</p>",
      },
    ],
  },
  {
    word: "British summer time",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>British summer time <strong class='noun'> n. </strong> = *summer time.</p>",
      },
    ],
  },
  {
    word: "British thermal unit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>British thermal unit <strong class='noun'> n. </strong> Amount of heat needed to raise 1 lb of water through one degree fahrenheit, equivalent to 1.055 x 103 joules.</p>",
      },
    ],
  },
  {
    word: "Briton",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Briton <strong class='noun'> n. </strong> 1 inhabitant of s. Britain before the roman conquest. 2 native or inhabitant of great britain. [latin britto -onis]</p>",
      },
    ],
  },
  {
    word: "Brittle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brittle <strong class='adj'> adj. </strong> Hard and fragile; apt to break. brittlely <strong class='adv'> adv. </strong> (also brittly). [old english]</p>",
      },
    ],
  },
  {
    word: "Brittle-bone disease",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brittle-bone disease <strong class='noun'> n. </strong> = *osteoporosis.</p>",
      },
    ],
  },
  {
    word: "Broach",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broach <strong class='noun'> —v. </strong> 1 raise for discussion. 2 pierce (a cask) to draw liquor. 3 open and start using. <strong class='noun'> —n. </strong> 1 bit for boring. 2 roasting-spit. [latin broccus projecting]</p>",
      },
    ],
  },
  {
    word: "Broad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broad <strong class='adj'> —adj.</strong> 1 large in extent from one side to the other; wide. 2 in breadth (two metres broad). 3 extensive (broad acres). 4 full and clear (broad daylight). 5 explicit (broad hint). 6 general (broad intentions, facts). 7 tolerant, liberal (broad view). 8 coarse (broad humour). 9 markedly regional (broad scots). <strong class='noun'> —n. </strong> 1 broad part (broad of the back). 2 us slang woman. 3 (the broads) large areas of water in e. Anglia, formed where rivers widen. broadly <strong class='adv'> adv. </strong> Broadness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Broad bean",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broad bean <strong class='noun'> n. </strong> 1 bean with large edible flat seeds. 2 one such seed.</p>",
      },
    ],
  },
  {
    word: "Broadcast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broadcast <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Broadcast) 1 transmit by radio or television. 2 take part in such a transmission. 3 scatter (seed etc.). 4 disseminate (information) widely. <strong class='noun'> —n. </strong> Radio or television programme or transmission. broadcaster <strong class='noun'> n. </strong> Broadcasting n.</p>",
      },
    ],
  },
  {
    word: "Broadcloth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broadcloth <strong class='noun'> n. </strong> Fine cloth of wool, cotton, or silk.</p>",
      },
    ],
  },
  {
    word: "Broaden",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broaden <strong class='verb'> v. </strong> Make or become broader.</p>",
      },
    ],
  },
  {
    word: "Broad gauge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broad gauge <strong class='noun'> n. </strong> Railway track with a wider than standard gauge.</p>",
      },
    ],
  },
  {
    word: "Broad-leaved",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broad-leaved <strong class='adj'> adj. </strong> (of a tree) deciduous and hard-timbered.</p>",
      },
    ],
  },
  {
    word: "Broadloom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broadloom <strong class='adj'> adj. </strong> (esp. Of carpet) woven in broad widths.</p>",
      },
    ],
  },
  {
    word: "Broad-minded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broad-minded <strong class='adj'> adj. </strong> Tolerant, liberal.</p>",
      },
    ],
  },
  {
    word: "Broadsheet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broadsheet <strong class='noun'> n. </strong> 1 large-sized newspaper. 2 large sheet of paper printed on one side only.</p>",
      },
    ],
  },
  {
    word: "Broadside",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broadside <strong class='noun'> n. </strong> 1 vigorous verbal attack. 2 simultaneous firing of all guns from one side of a ship. 3 side of a ship above the water between the bow and quarter. broadside on sideways on.</p>",
      },
    ],
  },
  {
    word: "Broadsword",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broadsword <strong class='noun'> n. </strong> Broad-bladed sword, for cutting rather than thrusting.</p>",
      },
    ],
  },
  {
    word: "Brocade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brocade <strong class='noun'> —n. </strong> Rich fabric woven with a raised pattern. —v. (-ding) weave in this way. [italian brocco twisted thread]</p>",
      },
    ],
  },
  {
    word: "Broccoli",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broccoli <strong class='noun'> n. </strong> Brassica with greenish flower-heads. [italian]</p>",
      },
    ],
  },
  {
    word: "Brochure",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brochure <strong class='noun'> n. </strong> Pamphlet or booklet, esp. With descriptive information. [french brocher stitch]</p>",
      },
    ],
  },
  {
    word: "Broderie anglaise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broderie anglaise <strong class='noun'> n. </strong> Open embroidery on white linen etc. [french, = english embroidery]</p>",
      },
    ],
  },
  {
    word: "Brogue1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brogue1 <strong class='noun'> n. </strong> 1 strong outdoor shoe with ornamental perforations. 2 rough shoe of untanned leather. [gaelic and irish brog from old norse]</p>",
      },
    ],
  },
  {
    word: "Brogue2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brogue2 <strong class='noun'> n. </strong> Marked accent, esp. Irish. [perhaps related to *brogue1]</p>",
      },
    ],
  },
  {
    word: "Broil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broil <strong class='verb'> v. </strong> Esp. Us 1 grill (meat). 2 make or become very hot, esp. From the sun. [french bruler burn]</p>",
      },
    ],
  },
  {
    word: "Broiler",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broiler <strong class='noun'> n. </strong> Young chicken for broiling or roasting.</p>",
      },
    ],
  },
  {
    word: "Broke  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broke  <strong class='slang_v'>past of *</strong> break. <strong class='predic'> —predic. </strong> <strong class='adj'> Adj. </strong> Colloq. Having no money.</p>",
      },
    ],
  },
  {
    word: "Broken ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broken  <strong class='archaic_it_was'>past part. Of *</strong> break. <strong class='adj'> —adj.</strong> 1 having been broken. 2 reduced to despair; beaten. 3 (of language) badly spoken, esp. By a foreigner. 4 interrupted (broken sleep).</p>",
      },
    ],
  },
  {
    word: "Broken-down",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broken-down <strong class='adj'> adj. </strong> 1 worn out by age, use, etc. 2 not functioning.</p>",
      },
    ],
  },
  {
    word: "Broken-hearted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broken-hearted <strong class='adj'> adj. </strong> Overwhelmed with grief.</p>",
      },
    ],
  },
  {
    word: "Broken home",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broken home <strong class='noun'> n. </strong> Family disrupted by divorce or separation.</p>",
      },
    ],
  },
  {
    word: "Broker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broker <strong class='noun'> n. </strong> 1 agent; middleman. 2 member of the stock exchange dealing in stocks and shares. 3 official appointed to sell or appraise distrained goods. broking <strong class='noun'> n. </strong> [anglo-french] <strong class='usage'> Usage:- </strong>in sense 2, brokers have officially been called broker-dealers in the uk since oct. 1986, and entitled to act as agents and principals in share dealings.</p>",
      },
    ],
  },
  {
    word: "Brokerage",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brokerage <strong class='noun'> n. </strong> Broker's fee or commission.</p>",
      },
    ],
  },
  {
    word: "Brolly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brolly <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> <strong class='colloq'> colloq. </strong> Umbrella. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Bromide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bromide <strong class='noun'> n. </strong> 1 any binary compound of bromine. 2 trite remark. 3 reproduction or proof on paper coated with silver bromide emulsion.</p>",
      },
    ],
  },
  {
    word: "Bromine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bromine <strong class='noun'> n. </strong> Poisonous liquid element with a choking smell. [greek bromos stink]</p>",
      },
    ],
  },
  {
    word: "Bronchial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bronchial <strong class='adj'> adj. </strong> Of the bronchi (see *bronchus) or of the smaller tubes into which they divide.</p>",
      },
    ],
  },
  {
    word: "Bronchitis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bronchitis <strong class='noun'> n. </strong> Inflammation of the mucous membrane in the bronchial tubes.</p>",
      },
    ],
  },
  {
    word: "Bronchus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bronchus <strong class='noun'> n. </strong> (pl. -chi) either of the two main divisions of the windpipe. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Bronco",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bronco <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> wild or half-tamed horse of the western us. [spanish, = rough]</p>",
      },
    ],
  },
  {
    word: "Brontosaurus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brontosaurus <strong class='noun'> n. </strong> (pl. -ruses) large plant-eating dinosaur with a long whiplike tail. [greek bronte thunder, sauros lizard]</p>",
      },
    ],
  },
  {
    word: "Bronze",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bronze <strong class='noun'> —n. </strong> 1 alloy of copper and tin. 2 its brownish colour. 3 thing of bronze, esp. A sculpture. <strong class='adj'> —adj.</strong> Made of or coloured like bronze. —v. (-zing) make or become brown; tan. [french from italian]</p>",
      },
    ],
  },
  {
    word: "Bronze age",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bronze age <strong class='noun'> n. </strong> Archaeol. Period when weapons and tools were usu. Made of bronze.</p>",
      },
    ],
  },
  {
    word: "Bronze medal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bronze medal <strong class='noun'> n. </strong> Medal, usu. Awarded as third prize.</p>",
      },
    ],
  },
  {
    word: "Brooch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brooch <strong class='noun'> n. </strong> Ornamental hinged pin. [french broche: related to *broach]</p>",
      },
    ],
  },
  {
    word: "Brood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brood <strong class='noun'> —n. </strong> 1 young of esp. A bird born or hatched at one time. 2 <strong class='colloq'> colloq. </strong> Children in a family. —v. 1 worry or ponder (esp. Resentfully). 2 (of a bird) sit on eggs to hatch them. [old english]</p>",
      },
    ],
  },
  {
    word: "Broody",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broody <strong class='adj'> adj. </strong> (-ier, -iest) 1 (of a hen) wanting to brood. 2 sullenly thoughtful. 3 <strong class='colloq'> colloq. </strong> (of a woman) wanting pregnancy.</p>",
      },
    ],
  },
  {
    word: "Brook1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brook1 <strong class='noun'> n. </strong> Small stream. [old english]</p>",
      },
    ],
  },
  {
    word: "Brook2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brook2 <strong class='verb'> v. </strong> (usu. With neg.) Literary tolerate, allow. [old english]</p>",
      },
    ],
  },
  {
    word: "Broom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broom <strong class='noun'> n. </strong> 1 long-handled brush for sweeping. 2 shrub with bright yellow flowers. [old english]</p>",
      },
    ],
  },
  {
    word: "Broomstick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broomstick <strong class='noun'> n. </strong> Handle of a broom.</p>",
      },
    ],
  },
  {
    word: "Bros.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bros. <strong class='abbr'> Abbr. </strong> Brothers (esp. In the name of a firm).</p>",
      },
    ],
  },
  {
    word: "Broth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Broth <strong class='noun'> n. </strong> Thin soup of meat or fish stock. [old english]</p>",
      },
    ],
  },
  {
    word: "Brothel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brothel <strong class='noun'> n. </strong> Premises for prostitution. [originally = worthless fellow, from old english]</p>",
      },
    ],
  },
  {
    word: "Brother",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brother <strong class='noun'> n. </strong> 1 man or boy in relation to his siblings. 2 close male friend or associate. 3 (pl. Also brethren) a member of a male religious order, esp. A monk. B fellow christian etc. 4 fellow human being. brotherly adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Brother german",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brother german <strong class='see'> see *</strong> german.</p>",
      },
    ],
  },
  {
    word: "Brotherhood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brotherhood <strong class='noun'> n. </strong> 1 relationship between brothers. 2 association of people with a common interest. 3 community of feeling between human beings.</p>",
      },
    ],
  },
  {
    word: "Brother-in-law",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brother-in-law <strong class='noun'> n. </strong> (pl. Brothers-in-law) 1 one's wife's or husband's brother. 2 one's sister's or sister-in-law's husband.</p>",
      },
    ],
  },
  {
    word: "Brought  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brought  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>bring.</p>",
      },
    ],
  },
  {
    word: "Brouhaha",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brouhaha <strong class='noun'> n. </strong> Commotion; sensation. [french]</p>",
      },
    ],
  },
  {
    word: "Brow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brow <strong class='noun'> n. </strong> 1 forehead. 2 eyebrow. 3 summit of a hill etc. 4 edge of a cliff etc. [old english]</p>",
      },
    ],
  },
  {
    word: "Browbeat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Browbeat <strong class='verb'> v. </strong> (past -beat;  <strong class='archaic_it_was'>past part.</strong> -beaten) intimidate, bully.</p>",
      },
    ],
  },
  {
    word: "Brown",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brown <strong class='adj'> —adj.</strong> 1 having the colour of dark wood or rich soil. 2 dark-skinned or suntanned. 3 (of bread) made from wholemeal or wheatmeal flour. <strong class='noun'> —n. </strong> 1 brown colour or pigment. 2 brown clothes or material. —v. Make or become brown. browned off <strong class='colloq'> colloq. </strong> Fed up, disheartened.</p>",
      },
    ],
  },
  {
    word: "Brown bear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brown bear <strong class='noun'> n. </strong> Large <strong class='noun'> n. </strong> American brown bear.</p>",
      },
    ],
  },
  {
    word: "Brown coal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brown coal <strong class='noun'> n. </strong> = *lignite.</p>",
      },
    ],
  },
  {
    word: "Brownie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brownie <strong class='noun'> n. </strong> 1 (in full brownie guide) junior guide. 2 (brownie) small square of chocolate cake with nuts. 3 (brownie) benevolent elf.</p>",
      },
    ],
  },
  {
    word: "Brownie point",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brownie point <strong class='noun'> n. </strong> Colloq. Notional mark awarded for good conduct etc.</p>",
      },
    ],
  },
  {
    word: "Browning",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Browning <strong class='noun'> n. </strong> Additive to colour gravy.</p>",
      },
    ],
  },
  {
    word: "Brown owl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brown owl <strong class='noun'> n. </strong> 1 any of various owls, esp. The tawny owl. 2 (brown owl) adult leader of brownie guides.</p>",
      },
    ],
  },
  {
    word: "Brown rice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brown rice <strong class='noun'> n. </strong> Unpolished rice.</p>",
      },
    ],
  },
  {
    word: "Brown sugar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brown sugar <strong class='noun'> n. </strong> Unrefined or partially refined sugar.</p>",
      },
    ],
  },
  {
    word: "Browse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Browse <strong class='noun'> —v. </strong> <strong class='sing'>(-sing)</strong> 1 read desultorily or look over goods for sale. 2 (often foll. By on) feed on leaves, twigs, etc. <strong class='noun'> —n. </strong> 1 twigs, shoots, etc. As fodder. 2 act of browsing. [french brost bud]</p>",
      },
    ],
  },
  {
    word: "Brucellosis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brucellosis <strong class='noun'> n. </strong> Bacterial disease, esp. Of cattle. [sir d. Bruce, name of a physician]</p>",
      },
    ],
  },
  {
    word: "Bruise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bruise <strong class='noun'> —n. </strong> 1 discolouration of the skin caused esp. By a blow. 2 similar damage on a fruit etc. —v. <strong class='sing'>(-sing)</strong> 1 a inflict a bruise on. B hurt mentally. 2 be susceptible to bruising. [originally = crush, from old english]</p>",
      },
    ],
  },
  {
    word: "Bruiser",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bruiser <strong class='noun'> n. </strong> Colloq. 1 large tough-looking person. 2 professional boxer.</p>",
      },
    ],
  },
  {
    word: "Bruit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bruit <strong class='verb'> v. </strong> (often foll. By abroad, about) spread (a report or rumour). [french, = noise]</p>",
      },
    ],
  },
  {
    word: "Brunch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brunch <strong class='noun'> n. </strong> Combined breakfast and lunch. [portmanteau word]</p>",
      },
    ],
  },
  {
    word: "Brunette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brunette <strong class='noun'> n. </strong> Woman with dark brown hair. [french diminutive]</p>",
      },
    ],
  },
  {
    word: "Brunt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brunt <strong class='noun'> n. </strong> Chief impact of an attack, task, etc. (esp. Bear the brunt of). [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Brush",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brush <strong class='noun'> —n. </strong> 1 implement with bristles, hair, wire, etc. Set into a block, for cleaning, painting, arranging the hair, etc. 2 act of brushing. 3 (usu. Foll. By with) short esp. Unpleasant encounter. 4 fox's bushy tail. 5 piece of carbon or metal as an electrical contact esp. With a moving part. 6 = *brushwood 2. —v. 1 sweep, scrub, treat, or tidy with a brush. 2 remove or apply with a brush. 3 graze in passing. brush aside dismiss curtly or lightly. Brush off dismiss abruptly. Brush up 1 clean up or smarten. 2 revise (a subject). [french]</p>",
      },
    ],
  },
  {
    word: "Brush-off",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brush-off <strong class='noun'> n. </strong> Abrupt dismissal.</p>",
      },
    ],
  },
  {
    word: "Brush-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brush-up <strong class='noun'> n. </strong> Act of brushing up.</p>",
      },
    ],
  },
  {
    word: "Brushwood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brushwood <strong class='noun'> n. </strong> 1 undergrowth, thicket. 2 cut or broken twigs etc.</p>",
      },
    ],
  },
  {
    word: "Brushwork",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brushwork <strong class='noun'> n. </strong> 1 use of the brush in painting. 2 painter's style in this.</p>",
      },
    ],
  },
  {
    word: "Brusque",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brusque <strong class='adj'> adj. </strong> Abrupt or offhand. brusquely <strong class='adv'> adv. </strong> Brusqueness <strong class='noun'> n. </strong> [italian brusco sour]</p>",
      },
    ],
  },
  {
    word: "Brussels sprout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brussels sprout <strong class='noun'> n. </strong> 1 brassica with small cabbage-like buds on a stem. 2 such a bud. [brussels in belgium]</p>",
      },
    ],
  },
  {
    word: "Brutal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brutal <strong class='adj'> adj. </strong> 1 savagely cruel. 2 harsh, merciless. brutality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong>. Brutally <strong class='adv'> adv. </strong> [french: related to *brute]</p>",
      },
    ],
  },
  {
    word: "Brutalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brutalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 make brutal. 2 treat brutally.</p>",
      },
    ],
  },
  {
    word: "Brute",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Brute <strong class='noun'> —n. </strong> 1 a brutal or violent person. B <strong class='colloq'> colloq. </strong> Unpleasant person or difficult thing. 2 animal. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> 1 unthinking (brute force). 2 cruel; stupid; sensual. brutish adj. Brutishly <strong class='adv'> adv. </strong> Brutishness <strong class='noun'> n. </strong> [latin brutus stupid]</p>",
      },
    ],
  },
  {
    word: "Bryony",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bryony <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> climbing plant with red berries. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Bs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bs <strong class='sing'> abbr. </strong> 1 bachelor of surgery. 2 british standard(s).</p>",
      },
    ],
  },
  {
    word: "B.sc.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>B.sc. <strong class='abbr'> Abbr. </strong> Bachelor of science.</p>",
      },
    ],
  },
  {
    word: "Bse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bse <strong class='sing'> abbr. </strong> Bovine spongiform encephalopathy, a usu. Fatal cattle disease.</p>",
      },
    ],
  },
  {
    word: "Bsi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bsi <strong class='sing'> abbr. </strong> British standards institution.</p>",
      },
    ],
  },
  {
    word: "Bst",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bst <strong class='sing'> abbr. </strong> 1 british summer time. 2 bovine somatotrophin, a growth hormone added to cattle-feed to boost milk production.</p>",
      },
    ],
  },
  {
    word: "Bt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bt <strong class='sing'> abbr. </strong> British telecom.</p>",
      },
    ],
  },
  {
    word: "Bt.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Bt. <strong class='abbr'> Abbr. </strong> Baronet.</p>",
      },
    ],
  },
  {
    word: "B.th.u.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>B.th.u. <strong class='abbr'> Abbr. </strong> (also b.t.u., btu, b.th.u.) British thermal unit(s).</p>",
      },
    ],
  },
  {
    word: "Bubble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bubble <strong class='noun'> —n. </strong> 1 a thin sphere of liquid enclosing air etc. B air-filled cavity in a liquid or solidified liquid. 2 transparent domed canopy. 3 visionary or unrealistic project. —v. (-ling) 1 rise in or send up bubbles. 2 make the sound of boiling. bubble over (often foll. By with) be exuberant. [imitative]</p>",
      },
    ],
  },
  {
    word: "Bubble and squeak",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bubble and squeak <strong class='noun'> n. </strong> Cooked cabbage etc. Fried with cooked potatoes.</p>",
      },
    ],
  },
  {
    word: "Bubble bath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bubble bath <strong class='noun'> n. </strong> Foaming preparation for adding to bath water.</p>",
      },
    ],
  },
  {
    word: "Bubble car",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bubble car <strong class='noun'> n. </strong> Small domed car.</p>",
      },
    ],
  },
  {
    word: "Bubble gum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bubble gum <strong class='noun'> n. </strong> Chewing-gum that can be blown into bubbles.</p>",
      },
    ],
  },
  {
    word: "Bubbly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bubbly <strong class='adj'> —adj.</strong> (-ier, -iest) 1 having or like bubbles. 2 exuberant. <strong class='noun'> —n. </strong> Colloq. Champagne.</p>",
      },
    ],
  },
  {
    word: "Bubo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bubo <strong class='noun'> n. </strong> (pl. -es) inflamed swelling in the armpit or groin. [greek boubon groin]</p>",
      },
    ],
  },
  {
    word: "Bubonic plague",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bubonic plague <strong class='noun'> n. </strong> Contagious disease with buboes.</p>",
      },
    ],
  },
  {
    word: "Buccaneer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buccaneer <strong class='noun'> n. </strong> 1 pirate. 2 unscrupulous adventurer. buccaneering <strong class='noun'> n. </strong> & adj. [french]</p>",
      },
    ],
  },
  {
    word: "Buck1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buck1 <strong class='noun'> —n. </strong> 1 male deer, hare, rabbit, etc. 2 archaic dandy. 3 (attrib.) Slang male. —v. 1 (of a horse) jump upwards with its back arched. 2 (usu. Foll. By off) throw (a rider) in this way. 3 (usu. Foll. By up) <strong class='colloq'> colloq. </strong> A cheer up. B hurry up; make an effort. [old english]</p>",
      },
    ],
  },
  {
    word: "Buck2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buck2 <strong class='noun'> n. </strong> Us slang dollar. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Buck3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buck3 <strong class='noun'> n. </strong> Slang (in poker) article placed before the next dealer. pass the buck <strong class='colloq'> colloq. </strong> Shift responsibility (to another). [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bucket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bucket <strong class='noun'> —n. </strong> 1 a round open container with a handle, for carrying or drawing water etc. B amount contained in this. 2 (in pl.) Colloq. Large quantities, esp. Of rain or tears. 3 scoop in a water wheel, dredger, etc. —v. (-t-) <strong class='colloq'> colloq. </strong> 1 (often foll. By down) (esp. Of rain) pour heavily. 2 (often foll. By along) move or drive fast or bumpily. [anglo-french]</p>",
      },
    ],
  },
  {
    word: "Bucket seat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bucket seat <strong class='noun'> n. </strong> Seat with a rounded back for one person, esp. In a car.</p>",
      },
    ],
  },
  {
    word: "Bucket-shop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bucket-shop <strong class='noun'> n. </strong> 1 unregistered broking agency. 2 <strong class='colloq'> colloq. </strong> Travel agency specializing in cheap air tickets.</p>",
      },
    ],
  },
  {
    word: "Buckle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buckle <strong class='noun'> —n. </strong> Clasp with a hinged pin for securing a belt, strap, etc. —v. (-ling) 1 (often foll. By up, on, etc.) Fasten with a buckle. 2 (often foll. By up) (cause to) crumple under pressure. buckle down make a determined effort. [latin buccula cheek-strap]</p>",
      },
    ],
  },
  {
    word: "Buckler",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buckler <strong class='noun'> n. </strong> Hist. Small round shield.</p>",
      },
    ],
  },
  {
    word: "Buckram",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buckram <strong class='noun'> n. </strong> Coarse linen etc. Stiffened with paste etc. [french boquerant]</p>",
      },
    ],
  },
  {
    word: "Buck's fizz",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buck's fizz <strong class='noun'> n. </strong> Cocktail of champagne and orange juice. [buck's club in london]</p>",
      },
    ],
  },
  {
    word: "Buckshee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buckshee <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Slang free of charge. [corruption of *baksheesh]</p>",
      },
    ],
  },
  {
    word: "Buckshot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buckshot <strong class='noun'> n. </strong> Coarse lead shot.</p>",
      },
    ],
  },
  {
    word: "Buckskin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buckskin <strong class='noun'> n. </strong> 1 leather from a buck's skin. 2 thick smooth cotton or woollen cloth.</p>",
      },
    ],
  },
  {
    word: "Buckthorn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buckthorn <strong class='noun'> n. </strong> Thorny shrub with berries formerly used as a purgative.</p>",
      },
    ],
  },
  {
    word: "Buck-tooth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buck-tooth <strong class='noun'> n. </strong> Upper projecting tooth.</p>",
      },
    ],
  },
  {
    word: "Buckwheat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buckwheat <strong class='noun'> n. </strong> Seed of a plant related to rhubarb, used to make flour, or as an alternative to rice. [dutch, = beech-wheat]</p>",
      },
    ],
  },
  {
    word: "Bucolic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bucolic <strong class='adj'> —adj.</strong> Of shepherds; rustic, pastoral. <strong class='noun'> —n. </strong> (usu. In pl.) Pastoral poem or poetry. [greek boukolos herdsman]</p>",
      },
    ],
  },
  {
    word: "Bud",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bud <strong class='noun'> —n. </strong> 1 a knoblike shoot from which a stem, leaf, or flower develops. B flower or leaf not fully open. 2 asexual outgrowth from an organism separating to form a new individual. —v. (-dd-) 1 form buds. 2 begin to grow or develop (budding artist). 3 graft a bud of (a plant) on to another. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Buddha",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buddha <strong class='noun'> n. </strong> 1 title of the indian philosopher gautama (5th c. Bc) and his successors. 2 sculpture etc. Of buddha. [sanskrit, = enlightened]</p>",
      },
    ],
  },
  {
    word: "Buddhism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buddhism <strong class='noun'> n. </strong> Asian religion or philosophy founded by gautama buddha. buddhist <strong class='noun'> n. </strong> & adj.</p>",
      },
    ],
  },
  {
    word: "Buddleia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buddleia <strong class='noun'> n. </strong> Shrub with fragrant flowers attractive to butterflies. [buddle, name of a botanist]</p>",
      },
    ],
  },
  {
    word: "Buddy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buddy <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> esp. Us <strong class='colloq'> colloq. </strong> Friend or mate. [perhaps from *brother]</p>",
      },
    ],
  },
  {
    word: "Budge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Budge <strong class='verb'> v. </strong> (-ging) (usu. With neg.) 1 move slightly. 2 (cause to) change an opinion. budge up (or over) make room for another person by moving. [french bouger]</p>",
      },
    ],
  },
  {
    word: "Budgerigar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Budgerigar <strong class='noun'> n. </strong> Small parrot, often kept as a cage-bird. [aboriginal]</p>",
      },
    ],
  },
  {
    word: "Budget",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Budget <strong class='noun'> —n. </strong> 1 amount of money needed or available. 2 a (the budget) government's annual estimate or plan of revenue and expenditure. B similar estimate by a company etc. 3 (attrib.) Inexpensive. —v. (-t-) (often foll. By for) allow or arrange for in a budget. budgetary adj. [latin bulga bag]</p>",
      },
    ],
  },
  {
    word: "Budgie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Budgie <strong class='noun'> n. </strong> Colloq. = *budgerigar. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Buff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buff <strong class='adj'> —adj.</strong> Of a yellowish beige colour (buff envelope). <strong class='noun'> —n. </strong> 1 this colour. 2 (in <strong class='n_pl'> comb. </strong> ) Colloq. Enthusiast (railway buff). 3 velvety dull-yellow ox-leather. —v. 1 polish (metal etc.). 2 make (leather) velvety. in the buff <strong class='colloq'> colloq. </strong> Naked. [originally = buffalo, from french buffle]</p>",
      },
    ],
  },
  {
    word: "Buffalo",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buffalo <strong class='noun'> n. </strong> (pl. Same or -es) 1 wild ox of africa or asia. 2 <strong class='noun'> n. </strong> American bison. [greek boubalos ox]</p>",
      },
    ],
  },
  {
    word: "Buffer1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buffer1 <strong class='noun'> n. </strong> 1 thing that deadens impact, esp. A device on a train or at the end of a track. 2 substance that maintains the constant acidity of a solution. 3 computing temporary memory area or queue for data. [imitative]</p>",
      },
    ],
  },
  {
    word: "Buffer2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buffer2 <strong class='noun'> n. </strong> Slang silly or incompetent old man. [perhaps from *buffer1]</p>",
      },
    ],
  },
  {
    word: "Buffer state",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buffer state <strong class='noun'> n. </strong> Small state between two larger ones, regarded as reducing friction.</p>",
      },
    ],
  },
  {
    word: "Buffet1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buffet1 <strong class='noun'> n. </strong> 1 room or counter where refreshments are sold. 2 self-service meal of several dishes set out at once. 3 also sideboard or recessed cupboard. [french, = stool]</p>",
      },
    ],
  },
  {
    word: "Buffet2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buffet2 <strong class='noun'> —v. </strong> (-t-) 1 strike repeatedly. 2 contend with (waves etc.). <strong class='noun'> —n. </strong> 1 blow, esp. Of the hand. 2 shock. [french diminutive of bufe blow]</p>",
      },
    ],
  },
  {
    word: "Buffet car",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buffet car <strong class='noun'> n. </strong> Railway coach serving refreshments.</p>",
      },
    ],
  },
  {
    word: "Buffoon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buffoon <strong class='noun'> n. </strong> Clownish or stupid person. buffoonery <strong class='noun'> n. </strong> [latin buffo clown]</p>",
      },
    ],
  },
  {
    word: "Bug",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bug <strong class='noun'> —n. </strong> 1 a any of various insects with mouthparts modified for piercing and sucking. B esp. Us small insect. 2 slang virus; infection. 3 slang concealed microphone. 4 slang error in a computer program or system etc. 5 slang obsession, enthusiasm, etc. —v. <strong class='gg'> (-gg-) </strong> 1 slang conceal a microphone in. 2 slang annoy. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bugbear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bugbear <strong class='noun'> n. </strong> 1 cause of annoyance. 2 object of baseless fear. [bug = bogey]</p>",
      },
    ],
  },
  {
    word: "Bugger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bugger <strong class='general'> coarse slang</strong> (except in sense 2 of <strong class='noun'> n. </strong> And 3 of v.) <strong class='noun'> —n. </strong> 1 a unpleasant or awkward person or thing. B person of a specified kind (clever bugger!). 2 person who commits buggery. —v. 1 as an exclamation of annoyance (bugger it!). 2 (often foll. By up) a ruin; spoil. B exhaust. 3 commit buggery with. <strong class='pl'> —int. </strong> Expressing annoyance. bugger-all nothing. Bugger about (or around) (often foll. By with) mess about. Bugger off (often in imper.) Go away. [latin bulgarus bulgarian heretic]</p>",
      },
    ],
  },
  {
    word: "Buggery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buggery <strong class='noun'> n. </strong> 1 anal intercourse. 2 = *bestiality 2.</p>",
      },
    ],
  },
  {
    word: "Buggy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buggy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 small, sturdy, esp. Open, motor vehicle. 2 lightweight push-chair. 3 light, horse-drawn vehicle for one or two people. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bugle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bugle <strong class='noun'> —n. </strong> Brass military instrument like a small trumpet. —v. (-ling) 1 sound a bugle. 2 sound (a call etc.) On a bugle. bugler <strong class='noun'> n. </strong> [latin buculus young bull]</p>",
      },
    ],
  },
  {
    word: "Bugloss",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bugloss <strong class='noun'> n. </strong> Plant with bright blue tubular flowers, related to borage. [french buglosse from greek, = ox-tongued]</p>",
      },
    ],
  },
  {
    word: "Build",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Build <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Built) 1 construct or cause to be constructed. 2 a (often foll. By up) establish or develop (built the business up). B (often foll. By on) base (hopes, theories, etc.). 3 (as built adj.) Of specified build (sturdily built). <strong class='noun'> —n. </strong> 1 physical proportions (slim build). 2 style of construction; make. build in incorporate. Build on add (an extension etc.). Build up 1 increase in size or strength. 2 praise; boost. 3 gradually become established. [old english]</p>",
      },
    ],
  },
  {
    word: "Builder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Builder <strong class='noun'> n. </strong> Person who builds, esp. A building contractor.</p>",
      },
    ],
  },
  {
    word: "Building",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Building <strong class='noun'> n. </strong> 1 permanent fixed structure e.g. A house, factory, or stable. 2 constructing of these.</p>",
      },
    ],
  },
  {
    word: "Building society",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Building society <strong class='noun'> n. </strong> Public finance company paying interest to investors and lending capital for mortgages etc.</p>",
      },
    ],
  },
  {
    word: "Build-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Build-up <strong class='noun'> n. </strong> 1 favourable advance publicity. 2 gradual approach to a climax. 3 accumulation or increase.</p>",
      },
    ],
  },
  {
    word: "Built  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Built  <strong class='see'>past and <strong class='archaic_it_was'>past part. Of *</strong> </strong>build.</p>",
      },
    ],
  },
  {
    word: "Built-in",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Built-in <strong class='adj'> adj. </strong> Integral.</p>",
      },
    ],
  },
  {
    word: "Built-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Built-up <strong class='adj'> adj. </strong> 1 (of a locality) densely developed. 2 increased in height etc. By addition. 3 made of prefabricated parts.</p>",
      },
    ],
  },
  {
    word: "Bulb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulb <strong class='noun'> n. </strong> 1 a globular base of the stem of some plants, sending roots downwards and leaves upwards. B plant grown from this, e.g. A daffodil. 2 = *light-bulb. 3 object or part shaped like a bulb. [latin bulbus from greek, = onion]</p>",
      },
    ],
  },
  {
    word: "Bulbous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulbous <strong class='adj'> adj. </strong> Bulb-shaped; fat or bulging.</p>",
      },
    ],
  },
  {
    word: "Bulge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulge <strong class='noun'> —n. </strong> 1 irregular swelling. 2 <strong class='colloq'> colloq. </strong> Temporary increase (baby bulge). —v. (-ging) swell outwards. bulgy adj. [latin bulga bag]</p>",
      },
    ],
  },
  {
    word: "Bulimia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulimia <strong class='noun'> n. </strong> (in full bulimia nervosa) disorder in which overeating alternates with self-induced vomiting, fasting, etc. [greek bous ox, limos hunger]</p>",
      },
    ],
  },
  {
    word: "Bulk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulk <strong class='noun'> —n. </strong> 1 a size; magnitude (esp. Large). B large mass, body, etc. C large quantity. 2 (treated as <strong class='pl'> pl. </strong> & usu. Prec. By the) greater part or number (the bulk of the applicants are women). 3 roughage. —v. 1 seem (in size or importance) (bulks large). 2 make (a book etc.) Thicker etc. in bulk in large quantities. [old norse]</p>",
      },
    ],
  },
  {
    word: "Bulk buying",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulk buying <strong class='noun'> n. </strong> Buying in quantity at a discount.</p>",
      },
    ],
  },
  {
    word: "Bulkhead",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulkhead <strong class='noun'> n. </strong> Upright partition in a ship, aircraft, etc.</p>",
      },
    ],
  },
  {
    word: "Bulky",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulky <strong class='adj'> adj. </strong> (-ier, -iest) awkwardly large. bulkiness n.</p>",
      },
    ],
  },
  {
    word: "Bull1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bull1 <strong class='noun'> n. </strong> 1 a uncastrated male bovine animal. B male of the whale, elephant, etc. 2 (the bull) zodiacal sign or constellation taurus. 3 bull's-eye of a target. 4 person who buys shares hoping to sell them at a profit. take the bull by the horns face danger or a challenge boldly. bullish adj. [old norse]</p>",
      },
    ],
  },
  {
    word: "Bull2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bull2 <strong class='noun'> n. </strong> Papal edict. [latin bulla seal]</p>",
      },
    ],
  },
  {
    word: "Bull3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bull3 <strong class='noun'> n. </strong> 1 slang a nonsense. B unnecessary routine tasks. 2 absurdly illogical statement. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bulldog",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulldog <strong class='noun'> n. </strong> 1 short-haired heavy-jowled sturdy dog. 2 tenacious and courageous person.</p>",
      },
    ],
  },
  {
    word: "Bulldog clip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulldog clip <strong class='noun'> n. </strong> Strong sprung clip for papers.</p>",
      },
    ],
  },
  {
    word: "Bulldoze",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulldoze <strong class='verb'> v. </strong> (-zing) 1 clear with a bulldozer. 2 <strong class='colloq'> colloq. </strong> A intimidate. B make (one's way) forcibly.</p>",
      },
    ],
  },
  {
    word: "Bulldozer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulldozer <strong class='noun'> n. </strong> Powerful tractor with a broad vertical blade at the front for clearing ground.</p>",
      },
    ],
  },
  {
    word: "Bullet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bullet <strong class='noun'> n. </strong> Small pointed missile fired from a rifle, revolver, etc. [french diminutive of boule ball]</p>",
      },
    ],
  },
  {
    word: "Bulletin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulletin <strong class='noun'> n. </strong> 1 short official news report. 2 society's regular list of information etc. [italian diminutive: related to *bull2]</p>",
      },
    ],
  },
  {
    word: "Bulletproof",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulletproof <strong class='adj'> adj. </strong> Designed to protect from bullets.</p>",
      },
    ],
  },
  {
    word: "Bullfight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bullfight <strong class='noun'> n. </strong> Public baiting, and usu. Killing, of bulls. bullfighter <strong class='noun'> n. </strong> Bullfighting n.</p>",
      },
    ],
  },
  {
    word: "Bullfinch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bullfinch <strong class='noun'> n. </strong> Pink and black finch.</p>",
      },
    ],
  },
  {
    word: "Bullfrog",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bullfrog <strong class='noun'> n. </strong> Large <strong class='noun'> n. </strong> American frog with a booming croak.</p>",
      },
    ],
  },
  {
    word: "Bull-headed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bull-headed <strong class='noun'> n. </strong> Obstinate, blundering.</p>",
      },
    ],
  },
  {
    word: "Bullion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bullion <strong class='noun'> n. </strong> Gold or silver in bulk before coining, or valued by weight. [french: related to *boil1]</p>",
      },
    ],
  },
  {
    word: "Bullock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bullock <strong class='noun'> n. </strong> Castrated male of domestic cattle. [old english diminutive of *bull1]</p>",
      },
    ],
  },
  {
    word: "Bullring",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bullring <strong class='noun'> n. </strong> Arena for bullfights.</p>",
      },
    ],
  },
  {
    word: "Bull's-eye",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bull's-eye <strong class='noun'> n. </strong> 1 centre of a target. 2 hard minty sweet. 3 hemispherical ship's window. 4 small circular window. 5 a hemispherical lens. B lantern with this. 6 boss of glass in a blown glass sheet.</p>",
      },
    ],
  },
  {
    word: "Bullshit  coarse slang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bullshit <strong class='general'> coarse slang</strong>  <strong class='noun'> —n. </strong> (often as int.) Nonsense; pretended knowledge. —v. <strong class='adj'> (-tt-) </strong> talk nonsense or as if one has specialist knowledge (to). bullshitter <strong class='noun'> n. </strong> [from *bull3]</p>",
      },
    ],
  },
  {
    word: "Bull-terrier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bull-terrier <strong class='noun'> n. </strong> Cross between a bulldog and a terrier.</p>",
      },
    ],
  },
  {
    word: "Bully1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bully1 <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> person coercing others by fear. —v. (-ies, -ied) persecute or oppress by force or threats. <strong class='pl'> —int. </strong> (foll. By for) often iron. Expressing approval (bully for you). [dutch]</p>",
      },
    ],
  },
  {
    word: "Bully2  (in full bully off)",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bully2 (in full bully off) <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> start of play in hockey in which two opponents strike each other's sticks three times and then go for the ball. —v. (-ies, -ied) start play in this way. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bully3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bully3 <strong class='noun'> n. </strong> (in full bully beef) corned beef. [french: related to *boil1]</p>",
      },
    ],
  },
  {
    word: "Bulrush",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulrush <strong class='noun'> n. </strong> 1 a kind of tall rush. 2 bibl. Papyrus. [perhaps from *bull1 = coarse + *rush2]</p>",
      },
    ],
  },
  {
    word: "Bulwark",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bulwark <strong class='noun'> n. </strong> 1 defensive wall, esp. Of earth. 2 protecting person or thing. 3 (usu. In pl.) Ship's side above deck. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Bum1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bum1 <strong class='noun'> n. </strong> Slang buttocks. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Bum2  us slang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bum2 us slang <strong class='noun'> —n. </strong> Loafer or tramp; dissolute person. —v. (-mm-) 1 (often foll. By around) loaf or wander around. 2 cadge. <strong class='attrib'> attrib. </strong> . <strong class='adj'> Adj. </strong> Of poor quality. [german bummler loafer]</p>",
      },
    ],
  },
  {
    word: "Bum-bag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bum-bag <strong class='noun'> n. </strong> Slang small pouch worn on a belt round the waist or hips.</p>",
      },
    ],
  },
  {
    word: "Bumble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bumble <strong class='verb'> v. </strong> (-ling) 1 (foll. By on) speak in a rambling way. 2 (often as bumbling adj.) Be inept; blunder. [from *boom1]</p>",
      },
    ],
  },
  {
    word: "Bumble-bee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bumble-bee <strong class='noun'> n. </strong> Large bee with a loud hum.</p>",
      },
    ],
  },
  {
    word: "Bumf",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bumf <strong class='noun'> n. </strong> Colloq. Usu. Derog. Papers, documents. [abbreviation of bum-fodder = toilet-paper]</p>",
      },
    ],
  },
  {
    word: "Bump",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bump <strong class='noun'> —n. </strong> 1 dull-sounding blow or collision. 2 swelling or dent so caused. 3 uneven patch on a road etc. 4 prominence on the skull thought to indicate a mental faculty. —v. 1 a hit or come against with a bump. B (often foll. By against, into) collide. 2 (often foll. By against, on) hurt or damage by striking (bumped my head, the car). 3 (usu. Foll. By along) move along with jolts. <strong class='adv'> —adv. </strong> With a bump; suddenly; violently. bump into <strong class='colloq'> colloq. </strong> Meet by chance. Bump off slang murder. Bump up <strong class='colloq'> colloq. </strong> Increase (prices etc.). bumpy adj. (-ier, -iest). [imitative]</p>",
      },
    ],
  },
  {
    word: "Bumper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bumper <strong class='noun'> n. </strong> 1 horizontal bar at the front or back of a motor vehicle, reducing damage in a collision. 2 (usu. Attrib.) Unusually large or fine example (bumper crop). 3 cricket ball rising high after pitching. 4 brim-full glass.</p>",
      },
    ],
  },
  {
    word: "Bumper car",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bumper car <strong class='noun'> n. </strong> = *dodgem.</p>",
      },
    ],
  },
  {
    word: "Bumpkin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bumpkin <strong class='noun'> n. </strong> Rustic or socially inept person. [dutch]</p>",
      },
    ],
  },
  {
    word: "Bumptious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bumptious <strong class='adj'> adj. </strong> Offensively self-assertive or conceited. [from *bump, after fractious]</p>",
      },
    ],
  },
  {
    word: "Bun",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bun <strong class='noun'> n. </strong> 1 small sweet bread roll or cake, often with dried fruit. 2 hair coiled and pinned to the head. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bunch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunch <strong class='noun'> —n. </strong> 1 things gathered together. 2 collection; lot (best of the bunch). 3 <strong class='colloq'> colloq. </strong> Group; gang. —v. 1 make into a bunch; gather into close folds. 2 form into a group or crowd. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bundle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bundle <strong class='noun'> —n. </strong> 1 things tied or fastened together. 2 set of nerve fibres etc. 3 slang large amount of money. —v. (-ling) 1 (usu. Foll. By up) tie or make into a bundle. 2 (usu. Foll. By into) throw or move carelessly. 3 (usu. Foll. By out, off, away, etc.) Send away hurriedly. be a bundle of nerves (or fun etc.) Be extremely nervous (or amusing etc.). Go a bundle on slang admire; like. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Bun fight",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bun fight <strong class='noun'> n. </strong> Slang tea party.</p>",
      },
    ],
  },
  {
    word: "Bung",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bung <strong class='noun'> —n. </strong> Stopper, esp. For a cask. —v. 1 stop with a bung. 2 slang throw. bunged up blocked up. [dutch]</p>",
      },
    ],
  },
  {
    word: "Bungalow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bungalow <strong class='noun'> n. </strong> One-storeyed house. [gujarati, = of bengal]</p>",
      },
    ],
  },
  {
    word: "Bungee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bungee <strong class='noun'> n. </strong> (in full bungee cord, rope) elasticated cord or rope used for securing baggage or in bungee jumping.</p>",
      },
    ],
  },
  {
    word: "Bungee jumping",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bungee jumping <strong class='noun'> n. </strong> Sport of jumping from a height while secured by a bungee from the ankles or a harness.</p>",
      },
    ],
  },
  {
    word: "Bungle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bungle <strong class='noun'> —v. </strong> (-ling) 1 mismanage or fail at (a task). 2 work badly or clumsily. <strong class='noun'> —n. </strong> Bungled attempt or work. [imitative]</p>",
      },
    ],
  },
  {
    word: "Bunion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunion <strong class='noun'> n. </strong> Swelling on the foot, esp. On the big toe. [french]</p>",
      },
    ],
  },
  {
    word: "Bunk1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunk1 <strong class='noun'> n. </strong> Shelflike bed against a wall, esp. In a ship. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bunk2 ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunk2  <strong class='slang_v'> slang —v. </strong> (often foll. By off) play truant (from). <strong class='noun'> —n. </strong> (in do a bunk) leave or abscond hurriedly. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bunk3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunk3 <strong class='noun'> n. </strong> Slang nonsense, humbug. [shortening of *bunkum]</p>",
      },
    ],
  },
  {
    word: "Bunk-bed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunk-bed <strong class='noun'> n. </strong> Each of two or more tiered beds forming a unit.</p>",
      },
    ],
  },
  {
    word: "Bunker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunker <strong class='noun'> n. </strong> 1 container for fuel. 2 reinforced underground shelter. 3 sandy hollow in a golf-course. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bunkum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunkum <strong class='noun'> n. </strong> Nonsense, humbug. [buncombe in us]</p>",
      },
    ],
  },
  {
    word: "Bunny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunny <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 child's name for a rabbit. 2 (in full bunny girl) club hostess, waitress, etc., wearing rabbit ears and tail. [dial. Bun rabbit]</p>",
      },
    ],
  },
  {
    word: "Bunsen burner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunsen burner <strong class='noun'> n. </strong> Small adjustable gas burner used in a laboratory. [bunsen, name of a chemist]</p>",
      },
    ],
  },
  {
    word: "Bunting1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunting1 <strong class='noun'> n. </strong> Small bird related to the finches. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bunting2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunting2 <strong class='noun'> n. </strong> 1 flags and other decorations. 2 loosely-woven fabric for these. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bunting2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bunting2 <strong class='noun'> n. </strong> 1 flags and other decorations. 2 loosely-woven fabric for these. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Buoy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buoy <strong class='noun'> —n. </strong> 1 anchored float as a navigation mark etc. 2 lifebuoy. —v. 1 (usu. Foll. By up) a keep afloat. B encourage, uplift. 2 (often foll. By out) mark with a buoy. [dutch, perhaps from latin boia collar]</p>",
      },
    ],
  },
  {
    word: "Buoyant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buoyant <strong class='adj'> adj. </strong> 1 able or apt to keep afloat. 2 resilient; exuberant. buoyancy <strong class='noun'> n. </strong> [french or spanish: related to *buoy]</p>",
      },
    ],
  },
  {
    word: "Bupa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bupa <strong class='sing'> abbr. </strong> British united provident association, a private health insurance organization.</p>",
      },
    ],
  },
  {
    word: "Bur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bur <strong class='noun'> n. </strong> 1 a prickly clinging seed-case or flower-head. B any plant having these. 2 clinging person. 3 <strong class='var'> var. </strong> Of *burr <strong class='noun'> n. </strong> 2. [scandinavian]</p>",
      },
    ],
  },
  {
    word: "Burble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burble <strong class='verb'> v. </strong> 1 talk ramblingly. 2 make a bubbling sound. [imitative]</p>",
      },
    ],
  },
  {
    word: "Burbot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burbot <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> eel-like freshwater fish. [french]</p>",
      },
    ],
  },
  {
    word: "Burden",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burden <strong class='noun'> —n. </strong> 1 load, esp. A heavy one. 2 oppressive duty, expense, emotion, etc. 3 bearing of loads (beast of burden). 4 a refrain of a song. B chief theme of a speech, book, etc. —v. Load with a burden; oppress. burdensome adj. [old english: related to *birth]</p>",
      },
    ],
  },
  {
    word: "Burden of proof",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burden of proof <strong class='noun'> n. </strong> Obligation to prove one's case.</p>",
      },
    ],
  },
  {
    word: "Burdock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burdock <strong class='noun'> n. </strong> Plant with prickly flowers and docklike leaves. [from *bur, *dock3]</p>",
      },
    ],
  },
  {
    word: "Bureau",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bureau <strong class='noun'> n. </strong> (pl. -x or -s) 1 a desk with drawers and usu. An angled hinged top. B us chest of drawers. 2 a office or department for specific business. B government department. [french, originally = baize]</p>",
      },
    ],
  },
  {
    word: "Bureaucracy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bureaucracy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a government by central administration. B state etc. So governed. 2 government officials, esp. Regarded as oppressive and inflexible. 3 conduct typical of these.</p>",
      },
    ],
  },
  {
    word: "Bureaucrat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bureaucrat <strong class='noun'> n. </strong> 1 official in a bureaucracy. 2 inflexible administrator. bureaucratic adj. Bureaucratically adv.</p>",
      },
    ],
  },
  {
    word: "Buret",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buret <strong class='noun'> n. </strong> (brit. Burette) graduated glass tube with an end-tap for measuring liquid in chemical analysis. [french]</p>",
      },
    ],
  },
  {
    word: "Burette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burette <strong class='noun'> n. </strong> (us buret) graduated glass tube with an end-tap for measuring liquid in chemical analysis. [french]</p>",
      },
    ],
  },
  {
    word: "Burgeon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burgeon <strong class='verb'> v. </strong> Literary grow rapidly; flourish. [latin burra wool]</p>",
      },
    ],
  },
  {
    word: "Burger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burger <strong class='noun'> n. </strong> Colloq. Hamburger. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Burgher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burgher <strong class='noun'> n. </strong> Citizen of a continental town. [german or dutch]</p>",
      },
    ],
  },
  {
    word: "Burglar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burglar <strong class='noun'> n. </strong> Person who commits burglary. [anglo-french]</p>",
      },
    ],
  },
  {
    word: "Burglary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burglary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 illegal entry with intent to commit theft, do bodily harm, or do damage. 2 instance of this. <strong class='usage'> Usage:- </strong>before 1968 in english law, burglary was a crime under statute and common law; since 1968 it has been a statutory crime only; cf. *housebreaking.</p>",
      },
    ],
  },
  {
    word: "Burgle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burgle <strong class='verb'> v. </strong> (-ling) commit burglary (on).</p>",
      },
    ],
  },
  {
    word: "Burgomaster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burgomaster <strong class='noun'> n. </strong> Mayor of a dutch or flemish town. [dutch]</p>",
      },
    ],
  },
  {
    word: "Burgundy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burgundy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 (also burgundy) a red or white wine from burgundy in e. France. B hist. Similar wine from elsewhere. 2 dark red colour of this.</p>",
      },
    ],
  },
  {
    word: "Burial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burial <strong class='noun'> n. </strong> 1 a burying of a corpse. B funeral. 2 archaeol. Grave or its remains.</p>",
      },
    ],
  },
  {
    word: "Burin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burin <strong class='noun'> n. </strong> 1 tool for engraving copper or wood. 2 archaeol. Chisel-pointed flint tool. [french]</p>",
      },
    ],
  },
  {
    word: "Burk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Burk <strong class='var'> var. </strong> Of *berk.</p>",
      },
    ],
  },
  {
    word: "Burlesque",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burlesque <strong class='noun'> —n. </strong> 1 a comic imitation, parody. B this as a genre. 2 us variety show, esp. With striptease. <strong class='adj'> —adj.</strong> Of or using burlesque. —v. (-ques, -qued, -quing) parody. [italian burla mockery]</p>",
      },
    ],
  },
  {
    word: "Burly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burly <strong class='adj'> adj. </strong> (-ier, -iest) large and sturdy. [old english]</p>",
      },
    ],
  },
  {
    word: "Burn1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burn1 <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Burnt or burned) 1 (cause to) be consumed or destroyed by fire. 2 blaze or glow with fire. 3 (cause to) be injured or damaged by fire, heat, radiation, acid, etc. 4 use or be used as fuel etc. 5 char in cooking. 6 produce (a hole, mark, etc.) By fire or heat. 7 a heat (clay, chalk, etc.). B harden (bricks) by fire. 8 colour, tan, or parch with heat or light. 9 (be) put to death by fire. 10 cauterize, brand. 11 make, be, or feel hot, esp. Painfully. 12 (often foll. By with) (cause to) feel great emotion or passion (burn with shame). 13 slang drive fast. <strong class='noun'> —n. </strong> Mark or injury caused by burning. burn one's boats (or bridges) commit oneself irrevocably. Burn the candle at both ends work etc. Excessively.</p>",
      },
    ],
  },
  {
    word: "Burn2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burn2 <strong class='noun'> n. </strong> Scot. Brook. [old english]</p>",
      },
    ],
  },
  {
    word: "Burner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burner <strong class='noun'> n. </strong> Part of a gas cooker, lamp, etc. That emits the flame.</p>",
      },
    ],
  },
  {
    word: "Burning",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burning <strong class='adj'> adj. </strong> 1 ardent, intense. 2 hotly discussed, vital, urgent.</p>",
      },
    ],
  },
  {
    word: "Burning-glass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burning-glass <strong class='noun'> n. </strong> Lens for concentrating the sun's rays to produce a flame.</p>",
      },
    ],
  },
  {
    word: "Burnish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burnish <strong class='verb'> v. </strong> Polish by rubbing. [french brunir from brun brown]</p>",
      },
    ],
  },
  {
    word: "Burnous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burnous <strong class='noun'> n. </strong> Arab or moorish hooded cloak. [arabic from greek]</p>",
      },
    ],
  },
  {
    word: "Burn-out",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burn-out <strong class='noun'> n. </strong> Exhaustion. burnt-out adj.</p>",
      },
    ],
  },
  {
    word: "Burnt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Burnt <strong class='see'> see *</strong> burn1.</p>",
      },
    ],
  },
  {
    word: "Burnt ochre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burnt ochre <strong class='noun'> n. </strong> (also burnt sienna or umber) pigment darkened by burning.</p>",
      },
    ],
  },
  {
    word: "Burnt offering",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burnt offering <strong class='noun'> n. </strong> Offering burnt on an altar as a sacrifice.</p>",
      },
    ],
  },
  {
    word: "Burp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burp <strong class='colloq'> colloq. </strong> —v. 1 belch. 2 make (a baby) belch. <strong class='noun'> —n. </strong> Belch. [imitative]</p>",
      },
    ],
  },
  {
    word: "Burr",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burr <strong class='noun'> —n. </strong> 1 a whirring sound. B rough sounding of the letter r. 2 (also bur) a rough edge on metal or paper. B surgeon's or dentist's small drill. 3 <strong class='var'> var. </strong> Of *bur 1, 2. —v. Make a burr. [imitative]</p>",
      },
    ],
  },
  {
    word: "Burrow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burrow <strong class='noun'> —n. </strong> Hole or tunnel dug by a rabbit etc. As a dwelling or shelter. —v. 1 make a burrow. 2 make (a hole, one's way, etc.) (as) by digging. 3 (foll. By into) investigate, search. [apparently <strong class='var'> var. </strong> Of *borough]</p>",
      },
    ],
  },
  {
    word: "Bursar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bursar <strong class='noun'> n. </strong> 1 treasurer, esp. Of a college. 2 holder of a bursary. [medieval latin bursarius from bursa purse]</p>",
      },
    ],
  },
  {
    word: "Bursary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bursary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> grant, esp. A scholarship. [medieval latin: related to *bursar]</p>",
      },
    ],
  },
  {
    word: "Burst",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burst <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Burst) 1 (cause to) break violently apart; open forcibly from within. 2 a (usu. Foll. By in, out) make one's way suddenly or by force. B break away from or through (river burst its banks). 3 be full to overflowing. 4 appear or come suddenly (burst into flame). 5 (foll. By into) suddenly begin to shed (tears) or utter. 6 seem about to burst from effort, excitement, etc. <strong class='noun'> —n. </strong> 1 act of bursting. 2 sudden issue or outbreak (burst of flame; burst of applause). 3 sudden effort, spurt. burst out 1 suddenly begin (burst out laughing). 2 exclaim. [old english]</p>",
      },
    ],
  },
  {
    word: "Burton",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Burton <strong class='noun'> n. </strong> go for a burton slang be lost, destroyed, or killed. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Bury",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bury <strong class='verb'> v. </strong> (-ies, -ied) 1 place (a corpse) in the earth, a tomb, or the sea. 2 lose by death (buried two sons). 3 a put or hide under ground. B cover up; conceal. 4 consign to obscurity; forget. 5 (refl. Or passive) involve deeply (buried in a book). bury the hatchet cease to quarrel. [old english]</p>",
      },
    ],
  },
  {
    word: "Bus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bus <strong class='noun'> —n. </strong> (pl. Buses or us busses) 1 large esp. Public passenger vehicle, usu. Travelling a fixed route. 2 <strong class='colloq'> colloq. </strong> Car, aeroplane, etc. —v. (buses or busses, bussed, bussing) 1 go by bus. 2 us transport by bus, esp. To aid racial integration. [abbreviation of *omnibus]</p>",
      },
    ],
  },
  {
    word: "Busby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Busby <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> tall fur hat worn by hussars etc. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bush1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bush1 <strong class='noun'> n. </strong> 1 shrub or clump of shrubs. 2 thing like a bush, esp. A clump of hair. 3 (esp. In australia and africa) uncultivated area; woodland or forest. [old english and old norse]</p>",
      },
    ],
  },
  {
    word: "Bush2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bush2 <strong class='noun'> —n. </strong> 1 metal lining for a hole enclosing a revolving shaft etc. 2 sleeve giving electrical insulation. —v. Fit with a bush. [dutch busse box]</p>",
      },
    ],
  },
  {
    word: "Bush-baby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bush-baby <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> small african lemur.</p>",
      },
    ],
  },
  {
    word: "Bushed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bushed <strong class='adj'> adj. </strong> Colloq. Tired out.</p>",
      },
    ],
  },
  {
    word: "Bushel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bushel <strong class='noun'> n. </strong> Measure of capacity for corn, fruit, etc. (8 gallons or 36.4 litres). [french]</p>",
      },
    ],
  },
  {
    word: "Bushfire",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bushfire <strong class='noun'> n. </strong> Forest or scrub fire often spreading widely.</p>",
      },
    ],
  },
  {
    word: "Bushman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bushman <strong class='noun'> n. </strong> 1 traveller or dweller in the australian bush. 2 (bushman) member or language of a s.african aboriginal people.</p>",
      },
    ],
  },
  {
    word: "Bush telegraph",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bush telegraph <strong class='noun'> n. </strong> Rapid spreading of information, rumour, etc.</p>",
      },
    ],
  },
  {
    word: "Bushy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bushy <strong class='adj'> adj. </strong> (-ier, -iest) 1 growing thickly like a bush. 2 having many bushes.</p>",
      },
    ],
  },
  {
    word: "Business",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Business <strong class='noun'> n. </strong> 1 one's regular occupation or profession. 2 one's own concern. 3 task or duty. 4 serious work or activity. 5 (difficult or unpleasant) matter or affair. 6 thing(s) needing attention or discussion. 7 buying and selling; trade. 8 commercial firm. mind one's own business not meddle. [old english: related to *busy]</p>",
      },
    ],
  },
  {
    word: "Businesslike",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Businesslike <strong class='adj'> adj. </strong> Efficient, systematic.</p>",
      },
    ],
  },
  {
    word: "Businessman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Businessman <strong class='noun'> n. </strong> (fem. Businesswoman) man or woman engaged in trade or commerce.</p>",
      },
    ],
  },
  {
    word: "Business park",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Business park <strong class='noun'> n. </strong> Area designed for commerce and light industry.</p>",
      },
    ],
  },
  {
    word: "Business person",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Business person <strong class='noun'> n. </strong> Businessman or businesswoman.</p>",
      },
    ],
  },
  {
    word: "Busk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Busk <strong class='verb'> v. </strong> Perform esp. Music in the street etc. For tips. busker <strong class='noun'> n. </strong> [obsolete busk peddle]</p>",
      },
    ],
  },
  {
    word: "Bus lane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bus lane <strong class='noun'> n. </strong> Part of a road mainly for use by buses.</p>",
      },
    ],
  },
  {
    word: "Busman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Busman <strong class='noun'> n. </strong> Bus driver.</p>",
      },
    ],
  },
  {
    word: "Busman's holiday",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Busman's holiday <strong class='noun'> n. </strong> Holiday spent in an activity similar to one's regular work.</p>",
      },
    ],
  },
  {
    word: "Bus shelter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bus shelter <strong class='noun'> n. </strong> Shelter beside a bus-stop.</p>",
      },
    ],
  },
  {
    word: "Bus station",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bus station <strong class='noun'> n. </strong> Centre where buses depart and arrive.</p>",
      },
    ],
  },
  {
    word: "Bus-stop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bus-stop <strong class='noun'> n. </strong> 1 regular stopping-place of a bus. 2 sign marking this.</p>",
      },
    ],
  },
  {
    word: "Bust1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bust1 <strong class='noun'> n. </strong> 1 human chest, esp. Of a woman; bosom. 2 sculpture of a person's head, shoulders, and chest. busty adj. (-ier, -iest). [french from italian]</p>",
      },
    ],
  },
  {
    word: "Bust2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bust2 <strong class='noun'> —v. </strong> (past and  <strong class='archaic_it_was'>past part.</strong> Busted or bust) <strong class='colloq'> colloq. </strong> 1 break, burst. 2 a raid, search. B arrest. <strong class='adj'> —adj.</strong> (also busted) 1 broken, burst. 2 bankrupt. bust up 1 collapse. 2 (esp. Of a married couple) separate. [var. Of *burst]</p>",
      },
    ],
  },
  {
    word: "Bustard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bustard <strong class='noun'> n. </strong> Large land bird that can run very fast. [latin avis tarda slow bird (‘slow’ unexplained)]</p>",
      },
    ],
  },
  {
    word: "Buster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buster <strong class='noun'> n. </strong> Esp. Us slang mate; fellow. [from *bust2]</p>",
      },
    ],
  },
  {
    word: "Bustier",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bustier <strong class='noun'> n. </strong> Strapless close-fitting bodice. [french]</p>",
      },
    ],
  },
  {
    word: "Bustle1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bustle1 <strong class='noun'> —v. </strong> (-ling) 1 (often foll. By about) (cause to) move busily and energetically. 2 (as bustling adj.) Active, lively. <strong class='noun'> —n. </strong> Excited or energetic activity. [perhaps from obsolete busk prepare]</p>",
      },
    ],
  },
  {
    word: "Bustle2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bustle2 <strong class='noun'> n. </strong> Hist. Padding worn under a skirt to puff it out behind. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Bust-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bust-up <strong class='noun'> n. </strong> 1 quarrel. 2 collapse.</p>",
      },
    ],
  },
  {
    word: "Busy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Busy <strong class='adj'> —adj.</strong> (-ier, -iest) 1 occupied or engaged in work etc. 2 full of activity; fussy (busy evening, street; busy design). 3 esp. Us (of a telephone line) engaged. —v. (-ies, -ied) (often refl.) Keep busy; occupy. busily <strong class='adv'> adv. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Busybody",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Busybody <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> meddlesome person.</p>",
      },
    ],
  },
  {
    word: "Busy lizzie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Busy lizzie <strong class='noun'> n. </strong> Plant with abundant esp. Red, pink, or white flowers.</p>",
      },
    ],
  },
  {
    word: "But  —",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>But — <strong class='conj'> conj. </strong> 1 a nevertheless, however (tried but failed). B on the other hand; on the contrary (i am old but you are young). 2 except, otherwise than (cannot choose but do it; what could we do but run?). 3 without the result that (it never rains but it pours). <strong class='prep'> —prep. </strong> Except; apart from; other than (all cried but me; nothing but trouble). <strong class='adv'> —adv. </strong> 1 only; no more than; only just (we can but try; is but a child; had but arrived). 2 in emphatic repetition; definitely (would see nobody, but nobody). —rel. <strong class='pron'> Pron. </strong> Who not; that not (not a man but feels pity). <strong class='noun'> —n. </strong> Objection (ifs and buts). but for without the help or hindrance etc. Of (but for you i'd be rich). But one (or two etc.) Excluding one (or two etc.) From the number (next door but one; last but one). But then however (i won, but then i am older). [old english]</p>",
      },
    ],
  },
  {
    word: "Butane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butane <strong class='noun'> n. </strong> Gaseous alkane hydrocarbon, used in liquefied form as fuel. [from *butyl]</p>",
      },
    ],
  },
  {
    word: "Butch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butch <strong class='adj'> adj. </strong> Slang masculine; tough-looking. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Butcher",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butcher <strong class='noun'> —n. </strong> 1 a person who deals in meat. B slaughterer. 2 brutal murderer. —v. 1 slaughter or cut up (an animal) for food. 2 kill wantonly or cruelly. 3 <strong class='colloq'> colloq. </strong> Ruin through incompetence. [french boc *buck1]</p>",
      },
    ],
  },
  {
    word: "Butchery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butchery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 needless or cruel slaughter (of people). 2 butcher's trade.</p>",
      },
    ],
  },
  {
    word: "Butler",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butler <strong class='noun'> n. </strong> Principal manservant of a household. [french bouteille bottle]</p>",
      },
    ],
  },
  {
    word: "Butt1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butt1 <strong class='noun'> —v. </strong> 1 push or strike with the head or horns. 2 (cause to) meet edge to edge. <strong class='noun'> —n. </strong> 1 push with the head. 2 join of two edges. butt in interrupt, meddle. [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Butt2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butt2 <strong class='noun'> n. </strong> 1 (often foll. By of) object of ridicule etc. 2 a mound behind a target. B (in pl.) Shooting-range. [french but goal]</p>",
      },
    ],
  },
  {
    word: "Butt3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butt3 <strong class='noun'> n. </strong> 1 thicker end, esp. Of a tool or weapon. 2 stub of a cigarette etc. 3 esp. Us slang buttocks. [dutch]</p>",
      },
    ],
  },
  {
    word: "Butt4",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butt4 <strong class='noun'> n. </strong> Cask. [latin buttis]</p>",
      },
    ],
  },
  {
    word: "Butter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butter <strong class='noun'> —n. </strong> 1 solidified churned cream, used as a spread and in cooking. 2 substance of similar texture (peanut butter). —v. Spread, cook, or serve with butter. butter up <strong class='colloq'> colloq. </strong> Flatter. [greek bouturon]</p>",
      },
    ],
  },
  {
    word: "Butter-bean",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butter-bean <strong class='noun'> n. </strong> 1 flat, dried, white lima bean. 2 yellow-podded bean.</p>",
      },
    ],
  },
  {
    word: "Butter-cream",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butter-cream <strong class='noun'> n. </strong> Mixture of butter, icing sugar, etc., as a filling etc. For a cake.</p>",
      },
    ],
  },
  {
    word: "Buttercup",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buttercup <strong class='noun'> n. </strong> Wild plant with yellow cup-shaped flowers.</p>",
      },
    ],
  },
  {
    word: "Butterfat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butterfat <strong class='noun'> n. </strong> Essential fats of pure butter.</p>",
      },
    ],
  },
  {
    word: "Butter-fingers",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butter-fingers <strong class='noun'> n. </strong> Colloq. Person prone to drop things.</p>",
      },
    ],
  },
  {
    word: "Butterfly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butterfly <strong class='noun'> n. </strong> (pl. -flies) 1 insect with four usu. Brightly coloured wings. 2 (in pl.) Colloq. Nervous sensation in the stomach.</p>",
      },
    ],
  },
  {
    word: "Butterfly nut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butterfly nut <strong class='noun'> n. </strong> A kind of wing-nut.</p>",
      },
    ],
  },
  {
    word: "Butterfly stroke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butterfly stroke <strong class='noun'> n. </strong> Stroke in swimming, with arms raised and lifted forwards together.</p>",
      },
    ],
  },
  {
    word: "Butter-icing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butter-icing <strong class='noun'> n. </strong> = *butter-cream.</p>",
      },
    ],
  },
  {
    word: "Buttermilk",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buttermilk <strong class='noun'> n. </strong> Liquid left after churning butter.</p>",
      },
    ],
  },
  {
    word: "Butter muslin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butter muslin <strong class='noun'> n. </strong> Thin loosely-woven cloth, <strong class='archaic_it_was'> orig. </strong> For wrapping butter.</p>",
      },
    ],
  },
  {
    word: "Butterscotch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butterscotch <strong class='noun'> n. </strong> Brittle toffee made from butter, brown sugar, etc.</p>",
      },
    ],
  },
  {
    word: "Buttery1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buttery1 <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> food store, esp. In a college; snack-bar etc. [related to *butt4]</p>",
      },
    ],
  },
  {
    word: "Buttery2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buttery2 <strong class='adj'> adj. </strong> Like or containing butter.</p>",
      },
    ],
  },
  {
    word: "Buttock",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buttock <strong class='noun'> n. </strong> 1 each of the two fleshy protuberances at the rear of the human trunk. 2 corresponding part of an animal. [butt ridge]</p>",
      },
    ],
  },
  {
    word: "Button",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Button <strong class='noun'> —n. </strong> 1 small disc etc. Sewn to a garment as a fastener or worn as an ornament. 2 small round knob etc. Pressed to operate electronic equipment. —v. = button up 1. button up 1 fasten with buttons. 2 <strong class='colloq'> colloq. </strong> Complete satisfactorily. 3 <strong class='colloq'> colloq. </strong> Be silent. [french from germanic]</p>",
      },
    ],
  },
  {
    word: "Buttonhole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buttonhole <strong class='noun'> —n. </strong> 1 slit in cloth for a button. 2 flower etc. Worn in a lapel buttonhole. —v. (-ling) <strong class='colloq'> colloq. </strong> Accost and detain (a reluctant listener).</p>",
      },
    ],
  },
  {
    word: "Button mushroom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Button mushroom <strong class='noun'> n. </strong> Young unopened mushroom.</p>",
      },
    ],
  },
  {
    word: "Buttress",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buttress <strong class='noun'> —n. </strong> 1 projecting support built against a wall. 2 source of help etc. —v. (often foll. By up) 1 support with a buttress. 2 support by argument etc. (buttressed by facts). [related to *butt1]</p>",
      },
    ],
  },
  {
    word: "Butty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> n.engl. Sandwich. [from *butter]</p>",
      },
    ],
  },
  {
    word: "Butyl",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Butyl <strong class='noun'> n. </strong> The univalent alkyl radical c4h9. [latin butyrum *butter]</p>",
      },
    ],
  },
  {
    word: "Buxom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buxom <strong class='adj'> adj. </strong> (esp. Of a woman) plump and rosy; busty. [earlier = pliant: related to *bow2]</p>",
      },
    ],
  },
  {
    word: "Buy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buy <strong class='noun'> —v. </strong> (buys, buying; past and  <strong class='archaic_it_was'>past part.</strong> Bought) 1 a obtain for money etc. B serve to obtain (money can't buy happiness; the best that money can buy). 2 a procure by bribery etc. B bribe. 3 get by sacrifice etc. 4 slang believe in, accept. 5 be a buyer for a store etc. <strong class='noun'> —n. </strong> Colloq. Purchase. buy in buy a stock of. Buy into pay for a share in (an enterprise). Buy off pay to get rid of. Buy oneself out obtain one's release (esp. From the armed services) by payment. Buy out pay (a person) for ownership, an interest, etc. Buy up 1 buy as much as possible of. 2 absorb (a firm etc.) By purchase. [old english]</p>",
      },
    ],
  },
  {
    word: "Buyer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buyer <strong class='noun'> n. </strong> 1 person employed to purchase stock for a large store etc. 2 purchaser, customer.</p>",
      },
    ],
  },
  {
    word: "Buyer's market",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buyer's market <strong class='noun'> n. </strong> (also buyers' market) trading conditions favourable to buyers.</p>",
      },
    ],
  },
  {
    word: "Buyout",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buyout <strong class='noun'> n. </strong> Purchase of a controlling share in a company etc.</p>",
      },
    ],
  },
  {
    word: "Buzz",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buzz <strong class='noun'> —n. </strong> 1 hum of a bee etc. 2 sound of a buzzer. 3 a low murmur as of conversation. B stir; hurried activity (buzz of excitement). 4 slang telephone call. 5 slang thrill. —v. 1 hum. 2 a summon with a buzzer. B slang telephone. 3 a (often foll. By about) move busily. B (of a place) appear busy or full of excitement. buzz off slang go or hurry away. [imitative]</p>",
      },
    ],
  },
  {
    word: "Buzzard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buzzard <strong class='noun'> n. </strong> Large bird of the hawk family. [latin buteo falcon]</p>",
      },
    ],
  },
  {
    word: "Buzzer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buzzer <strong class='noun'> n. </strong> Electrical buzzing device as a signal.</p>",
      },
    ],
  },
  {
    word: "Buzz-word",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Buzz-word <strong class='noun'> n. </strong> Colloq. Fashionable technical or specialist word; catchword.</p>",
      },
    ],
  },
  {
    word: "By",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>By <strong class='prep'> —prep. </strong> 1 near, beside (sit by me; path by the river). 2 through the agency or means of (by proxy; poem by donne; by bus; by cheating; divide by two; killed by robbers). 3 not later than (by next week). 4 a past, beyond (drove by the church). B through; via (went by paris). 5 during (by day; by daylight). 6 to the extent of (missed by a foot; better by far). 7 according to; using as a standard or unit (judge by appearances; paid by the hour). 8 with the succession of (worse by the minute; day by day). 9 concerning; in respect of (did our duty by them; smith by name). 10 used in mild oaths (by god). 11 expressing dimensions of an area etc. (three feet by two). 12 avoiding, ignoring (passed us by). 13 inclining to (north by north-west). <strong class='adv'> —adv. </strong> 1 near (sat by). 2 aside; in reserve (put £5 by). 3 past (marched by). <strong class='noun'> —n. </strong> (pl. Byes) = *bye1. by and by before long; eventually. By and large on the whole. By the by (or bye) incidentally. By oneself 1 a unaided. B unprompted. 2 alone. [old english]</p>",
      },
    ],
  },
  {
    word: "By-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>By- <strong class='prefix '> prefix </strong> subordinate, incidental (by-effect; byroad).</p>",
      },
    ],
  },
  {
    word: "Bye1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bye1 <strong class='noun'> n. </strong> 1 cricket run scored from a ball that passes the batsman without being hit. 2 status of an unpaired competitor in a sport, who proceeds to the next round by default. [from *by as a noun]</p>",
      },
    ],
  },
  {
    word: "Bye2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bye2 <strong class='n_pl'> int. </strong> (also bye-bye) <strong class='colloq'> colloq. </strong> = *goodbye. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "By-election",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>By-election <strong class='noun'> n. </strong> Election to fill a vacancy arising between general elections.</p>",
      },
    ],
  },
  {
    word: "Byelorussian  (also belorussian)",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Byelorussian (also belorussian) <strong class='noun'> —n. </strong> Native or language of byelorussia in eastern europe. <strong class='adj'> —adj.</strong> Of byelorussia, its people, or language. [russian from belyi white, russiya russia]</p>",
      },
    ],
  },
  {
    word: "Bygone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bygone <strong class='adj'> —adj.</strong> Past, antiquated. <strong class='noun'> —n. </strong> (in phr. Let bygones be bygones) forgive and forget past quarrels.</p>",
      },
    ],
  },
  {
    word: "By-law",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>By-law <strong class='noun'> n. </strong> Regulation made by a local authority or corporation. [obsolete by town]</p>",
      },
    ],
  },
  {
    word: "Byline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Byline <strong class='noun'> n. </strong> 1 line naming the writer of a newspaper article etc. 2 secondary line of work. 3 goal-line or touch-line.</p>",
      },
    ],
  },
  {
    word: "Bypass",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bypass <strong class='noun'> —n. </strong> 1 main road passing round a town or its centre. 2 a secondary channel or pipe etc. Used in emergencies. B alternative passage for the circulation of blood through the heart. —v. Avoid, go round (a town, difficulty, etc.).</p>",
      },
    ],
  },
  {
    word: "Byplay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Byplay <strong class='noun'> n. </strong> Secondary action, esp. In a play.</p>",
      },
    ],
  },
  {
    word: "By-product",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>By-product <strong class='noun'> n. </strong> 1 incidental product made in the manufacture of something else. 2 secondary result.</p>",
      },
    ],
  },
  {
    word: "Byre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Byre <strong class='noun'> n. </strong> Cowshed. [old english]</p>",
      },
    ],
  },
  {
    word: "Byroad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Byroad <strong class='noun'> n. </strong> Minor road.</p>",
      },
    ],
  },
  {
    word: "Byroad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Byroad <strong class='noun'> n. </strong> Minor road.</p>",
      },
    ],
  },
  {
    word: "Byssinosis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Byssinosis <strong class='noun'> n. </strong> Lung disease caused by textile fibre dust. [greek bussinos made of linen]</p>",
      },
    ],
  },
  {
    word: "Bystander",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Bystander <strong class='noun'> n. </strong> Person present but not taking part; onlooker.</p>",
      },
    ],
  },
  {
    word: "Byte",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Byte <strong class='noun'> n. </strong> Computing group of eight binary digits, often representing one character. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Byway",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Byway <strong class='noun'> n. </strong> 1 byroad or secluded path. 2 minor activity.</p>",
      },
    ],
  },
  {
    word: "Byword",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Byword <strong class='noun'> n. </strong> 1 person or thing as a notable example (is a byword for luxury). 2 familiar saying.</p>",
      },
    ],
  },
  {
    word: "Byzantine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Byzantine <strong class='adj'> —adj.</strong> 1 of byzantium or the e. Roman empire. 2 of its highly decorated style of architecture. 3 (of a political situation etc.) Complex, inflexible, or underhand. <strong class='noun'> —n. </strong> Citizen of byzantium or the e. Roman empire. byzantinism <strong class='noun'> n. </strong> Byzantinist <strong class='noun'> n. </strong> [latin byzantium, now istanbul]</p>",
      },
    ],
  },
];
export const alphabet_B_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी ० ए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी ० ए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मिमियाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रलाप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोलाहल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लंगूर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिशु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चे की शोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चे की छोटी गाड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चा गाड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चा भव्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेबीग्रो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकारेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भदचलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीना पिलाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकचेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऐयाश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाकिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अविवाहित पुरुष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्नातक लड़की</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोग-कीट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीछे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीठ दर्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैक-बैरिस्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुराई करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैक-बोइलर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रीड की हड्डी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वापस तोड़ने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ठंडे बस्ते में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चुगली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकक्लोथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैककोम्ब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीठ का क्रॉल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकडेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीछे का दरवाजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पृष्ठभूमि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जवाबी हमला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वापस गठन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकगैमोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पार्श्वभूमि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकहैंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यंग भरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकहैंडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समर्थन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आधार पटरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिक्रिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिछलीसूची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकाया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीछे लिखा अंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सड़क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाक़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीठ का कमरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीछे की सीट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चालक को अनावश्यक सलाह देने वाला यात्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीछे की ओर झुकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीछे हट जाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकस्पेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकस्पिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेपथ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोरव्दार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टांका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वापस-स्टॉप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकस्ट्रीट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जवाबी चोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक के पीछे एक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फिर से आगे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकृति की ओर वापसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देख-भाल करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैकअप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिछड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीछे की ओर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मेड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुनसार जंगल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिछवाड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवाणुतत्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवाणु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीच वर्ण का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरी किताबें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदबूदार सांस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डूबंत ऋण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदमाश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खराब अंडा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरा विश्वास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिज्जू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिल्लगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत बुरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरी तरह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैडमिंटन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरा मुंह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरी खबर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उतावला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चकित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाफ्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थैला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटी बात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैगल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊलजलूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मशक बाजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Baguette</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जमानत 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BALL2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जमानत 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BALL2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जमानत 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंगन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेली ब्रिज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कारिदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैन मारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिशु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रलोभन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेंकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेका हुआ बीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का प्लास्टिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकर, नानबाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकर दिवस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नानबाई का दर्जन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकवेल टार्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकिंग पाउडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मीठा सोडा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकलावा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घूस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बालाक्लावा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बालालय्का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संतुलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भुगतान संतुलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शक्ति का संतुलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यापार का संतुलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुलन पत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बालकनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकवास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bale1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bale2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हक्रले का मूंछ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हानिकारक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मेंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बलकानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Ball1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉल 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गाथागीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गाथागीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉल और सॉकेट जॉइंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गिट्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉल बियरिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गेंद वाला बच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉलकॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैले नृत्यकत्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैले</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैले नृतकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गेंद के खेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉलिस्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैलिस्टिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैलिस्टिक मिसाइल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोलिस्टीक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुड़िया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुड़िया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुब्बारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मतदान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मतदान बॉक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मतपत्र, मत - पर्ची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉलपार्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गेंद सूत्रीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉलरूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉलरूम नृत्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गेंदों पर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेअदबी से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़ुशबूदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकवास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाल्सा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुल मेहँदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटा खंभा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कटघरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बांस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैम्बू शूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धोखा देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिबंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुच्छ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>केला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बनाना गणतंत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पट्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बन्डाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी एंड बी।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंडबॉक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिर बंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पट्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का चूहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डाकू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डाकू अगुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंडोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कारतूस का थैला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पट्टी आरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंडवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चबूतरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गाड़ी में सवार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंडविड्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bandy1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bandy2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फटकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टकराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंगर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वट-वृक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्वासित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रेलिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंजो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bank11</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bank2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिवालिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंसिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैनर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैनर शीर्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैनिस्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चपाटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विवाह की सूचना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भोज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दावत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Banshee</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैंटम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेंटमवेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मज़ाक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंटू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंटस्टन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरगद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाओबाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बपतिस्मा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बपतिस्मा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आग का बपतिस्मा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बपतिस्मा-दाता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नहाने की जगाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बपतिस्मा देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BAR1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BAR2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बारथिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कंटिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंगली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असभ्यता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्बता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्बर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बारबेक्यू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कांटेदार तार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>barbel</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहे का दंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दारुहल्दी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाई की दुकान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्बर का पोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बारबिकन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्बी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार बिलियर्ड्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्बीट्युरेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्बिट्यूरिक एसिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्बर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाविक का गित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार कोड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नंगा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेस्बियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढीठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नंगे पाँव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नंगे सिर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुश्किल से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मोलभाव करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बजरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बारगबोर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्गी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्ज पोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मध्यम आवाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरियम भोजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्क 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्क 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रिवाल्वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जौ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जौ की चीनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्ली वॉटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़मीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मधुबाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भौजनशाला का नौकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार मित्ज़वाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झागदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खलिहान है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थकानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खलिहान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्न डांस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार्नी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खलिहान का उल्लू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्नस्टॉर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वायु दाब लेखी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरोमीटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरोनेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरानेत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गोमांस का बैरन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरोनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार -बार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरक 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरक 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाराकौटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाराकुडा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैराज गुब्बारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दगाबाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्रे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरल ऑर्गन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंजर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरिकेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रुकावट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाधा क्रीम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवरोधक चट्टान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>को छोड़कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरिस्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरो 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैरो 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार की भयावह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भौजनशाला का नौकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वस्तु-विनिमय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरिऑन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेराइटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैराइट्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुनियादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाजालत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेस 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेस 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेसबॉल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निराधार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आधारभूत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेसमेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आधार दर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अड्डों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दे घुमा के</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संकोची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुनियादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुनियादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूल स्लैग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुलसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बासीलीक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बासीलीक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घाटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आधार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बास्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टोकरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बास्केटबाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टोकरीसाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टोकरी बुनाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टोकरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बास्किंग शॉर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बस्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत कम उभरा नक्रकाशी का काम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bass1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बास 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वरों का प्रतीक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बासेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेस गिटार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चों की गाड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बस्सो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलगोजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बास्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमीने अक्सर बंद हो जाते हैं।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमीना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Baste1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Baste2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पांव के तलवों पर मार लगाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुर्ज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BAT1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BAT2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BAT3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंग करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्नान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाथ बन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाथ -कुर्सी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाथ क्यूब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्नान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्नानगृह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नहाने का कपड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अचानक पतन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाथरोब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्नानघर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्नान लवण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाटिस्काफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाथिसफेयर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाटिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किमरिख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैटमैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैटन राउंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बतराचियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमगादड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बल्लेबाज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटालियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Batten1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Batten2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटनबर्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैटर 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैटर 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तख्तों का घर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैटरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लड़ाई कुल्हाड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैटलबस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युद्ध-क्रूजर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युद्ध का नारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटुला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लड़ाई का मैदान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दांत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लड़ाई शाही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युद्धपोत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बावला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैटविंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटी बात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोक-थाम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाक्साइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंदा घर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिल्लाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बे 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बे 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बे 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बे 4</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बे 5</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेबेरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बे पत्ती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संगीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बे रम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बे खिड़की</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाजार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>bazooka</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी बी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीबीसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीसीजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुग्गल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झुकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेवल गियर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पेय पदार्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झुंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शोक मनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खबरदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भुलाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मोहित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आगे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फलक के</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेज़िक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी.एफ.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीएच.पी.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्वि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दो-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साल में दो बार का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पक्षपात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूर्वाग्रह बाइंडिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैथलॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अकसर पीना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिब-कॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाइबिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाइबिल को कोसने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ग्रन्थसूची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुस्तकों का प्यार करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराबी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दो खाने का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्वि - कार्बोहाइड्रेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिकारबोनिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दो सौ साल का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाइसेन्टेनियल एस्प। हम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मछलियां</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कल-कल के साथ बहना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाइकस्पिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साइकिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोली लगाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आज्ञाकारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिडिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिड्डी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रहना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>bidet</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विवाषिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टिकठी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिफ स्लैंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दो शाखाओंवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विनाभित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दो शाखाओं में बांटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विविवाह का प्रथा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यू यॉर्क सिटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिग बैंग थ्योरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा भाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा अंत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घमंडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़े दिलवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खलीज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत पैसा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा शोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कट्टर व्यक्रित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ी छड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा समय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़े शीर्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा पहिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहम शख्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साइकिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिकिनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विपक्षीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लूबेरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पित्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तोड़ लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिलहरज़िया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पैत्तिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विभाषिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विध्रुवीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अदायगी से बचना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिल 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिल 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिल3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिलबोंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Billet1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Billet2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिलेट-doux</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिलहुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिलियर्ड्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक अरब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाखपति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक्सचेंज का बिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किराये का बिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लदान बिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तरंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिलपोस्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिली 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिली 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिम्बो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विधात्वीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बायनरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाइनरी स्टार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बायनरी सिस्टम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाइनॉरल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाँधना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिल्दसाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिल्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाइंडिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंकुर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिन एंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्वि घातुमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिंगो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कूड़े की थैली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डब्बे वाला आदमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिन्नेकल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दूरबीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विनेत्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विपद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विपद प्रमेय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिन्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीव रसायन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाइओडिग्रेड्डबल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैव अभियांत्रिकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवजनन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैविक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैविक घड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैविक युद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीवविज्ञान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीओनिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बायोनिक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जीव पदाथ-विद्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बायोप्सी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Biorhythm</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीओस्फिअ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैवसंश्लेषण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैव प्रौद्योगिकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बायोटिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विदलीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विपक्षीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दो पैरों का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीप्लैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विध्रुवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सन्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चिड़िया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पक्षी स्नान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्डब्रेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पिंजरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पक्षी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्डलाइम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्ड-नेस्टिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वर्ग की चिड़िया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पारित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिकारी पक्षी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्डसीड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विहंगम दृश्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्डसॉन्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्ड टेबल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पक्षी द्रष्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिरेटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्म प्रमाणपत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्म नियंत्रण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्मदिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्थिंग पूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्म चिह्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्मस्थल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्म दर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्मसिद्ध अधिकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्थस्टोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिस्कुट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्विविभाजित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उभयलिंगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिशप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिशप का पद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विस्मुट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिजोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bisque1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bisqu2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bisque3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झलक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिस्ट्रे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिस्टरो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bit1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bit2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bit3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bit4</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुतिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मादा का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दांत से काटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिट पर बिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिट पार्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कसैला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिटर्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कड़वा-मीठा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्फ़ाल्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिटुमिन का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिटुमिनस कोयला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोपटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पड़ाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विचित्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीके</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गप्पी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झक्की</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला और नीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला और सफेद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला जादू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली बीटल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लैक बेल्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लैकबेरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लेकबेर्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लैक बॉक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Blackcap</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला देश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>blackcurrant</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली मौत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अश्वेत अर्थव्यवस्था</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली आँख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला झंडा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली मक्खी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लैक फॉरेस्ट केक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदमाश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुहासा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लैक होल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली बर्फ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पालिश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डांडा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला लेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ठग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला सूची में डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला जादू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भयादोहन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लैक मारिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला निशान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला बाजार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला पिंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंधकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली मिर्च</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला जादू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खूनी सॉसेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार की गली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुलकलंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली कमीज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लोहार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला धब्बा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली चाय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लेकसोर्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली टाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला कोमल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लैक वॉच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काली माई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूत्राशय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूत्राशय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सफेद करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नरम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नरम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोरा चेक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कंबल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कंबल स्नान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कंबल स्टिच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेतुकी कविता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुरही बजाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चापलूसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उबा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निन्दा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईश - निंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विस्फोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विस्फोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आग की भट्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नष्ट करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़बरदस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकवास करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Blaze1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लेज़ 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रंगीन जाकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लांछन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विरंजित करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेरंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट आंखों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मिमियाहट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लीड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धमकी देकर हासिल करने की कोशिश करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खून बह रहा है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लीपर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सफेद करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मिलाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लेंडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लेनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आशीर्वाद देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाग्यवान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकवास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विस्फोट से उड़ा दिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकवास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विस्फोट से उड़ा दिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नुक़सान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हानि करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नुक़सान से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धमाकेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लींप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंधी गली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दो अजनबियों की मुलाकात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नशे में नशे में धुत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंखों पर पट्टी से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंखमिचौली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट जगह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लाइंडवॉर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झपकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लिंकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पलक झपकाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लिप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमानंद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़िंदादिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जी बहलानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लिट।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बम बरसाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बमवर्षा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्फानी तूफान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धब्बा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लॉब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवरोध पैदा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाकाबंदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रुकावट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवरूद्ध करें और निपटे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फिल्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़े अक्षर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लोकहाउस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लॉक वोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लड़का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गोरा (एक महिला usu। गोरा)</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्त बैंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खून का स्नान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्त कोशिकाओं की गणना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिल दहलानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्त दाता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लड ग्रुप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खून से लथपथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोजी कुत्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्तहिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्त दे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खून से पैसा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाल नारंगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्त - विषाक्तता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्त चाप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खून का रिश्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्तपात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्तमय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खूनी खेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लाडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खून में शक्कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्त परीक्षण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>bloodthirsty</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रक्तरंजित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लडी मैरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मक्कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bloomer1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BLOOMER2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पतलून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रस्फुटन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खिलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दाग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुहासा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्याहीचट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सोख्ता काग़ज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मदहोश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लाउज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लूसन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Blow1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लो 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक के बाद एक प्रहार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटके से सुखाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लोअर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मक्खी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटका छेद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटका नौकरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टांका लगाने का यंत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुझाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टांका लगाने का यंत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झटका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तेज हवा का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाल गालों का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धब्बा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीला बच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घंटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लूबेरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुलीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीली किताब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लूबोटल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फफूंदी लगा पनीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीला कॉलर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीली आँखों वाला लड़का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीले रंग की दुर्गंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लूग्रास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीली पेंसिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लू पीटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खाका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीली कुल्ला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लूज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धब्बा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीले रंग का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीली व्हेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bluff1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bluff2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीला सा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ी भूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धमाकेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुंद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कलंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विज्ञापन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोर से बोलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्लशर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धमकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी.एम.</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीएमए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेशर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीएमएक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोआ कंस्ट्रिकटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूअर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तख्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning:
      "<p>विशेष प्रकार के बोर्ड या पट्टे के खेल जैसे शतरंज, साँप सीढ़ी आदि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवासीय घर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर्डिंग - स्कूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर्डरूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डींग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डींग मारने का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मांझी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाव हुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाव घर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौका विहार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>केवट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाव के लोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोट्सवैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोट-ट्रेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bob1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bob2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bob3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अटेरन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बौबली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुलिसमैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बौबदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉब-स्लीघ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कट दुम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीओडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भविष्यव्दाणी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोडेगा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बसाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शारीरिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सलाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शरीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉडी ब्लो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शरीर का गठन बढ़ाने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंगरक्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शरीर की भाषा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शरीर की दुर्गंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉडी पॉलिटिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गराज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ऊपर से नीचे तक का चुस्त पहनावा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>bodysuit</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकाय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोअर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोफिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दलदल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bogey1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bogey2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हौवा खड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संदेह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दरानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोगीमैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोहेनिया का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Boil1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Boil2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बायलर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बायलर कक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉयलर सूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उबलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्वथनांक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उद्दी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साहसिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ठूंठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोलेरो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झोंपड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोलक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगड़-बगड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोल्शेविक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोलशी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सिलेंडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोल्ट 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोल्ट 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोल्ट छिद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बौछार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बम गिरानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आडंबर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉम्बे डक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बमबारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बमवर्षक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉम्बर जैकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आकस्मिकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बम-स्थल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रामाणिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपहार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉन-बॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गहरा संबंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दासता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंधुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बांड कागज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दासा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हड्डी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोन चाइना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूखी हड्डी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार हड्डी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थि मज्जा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थि चूर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विवाद का विषय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोनशेकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>होलिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोंगो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खुशमिज़ाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बौंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोनर्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अच्छे शब्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढक्कन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वास्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोन्साई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बक्शीश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉन विविंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉन यात्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Boob1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Boob2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूबी प्राइज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उल्लू का जाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मेला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूगी वूगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किताब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिल्दसाज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किताबों की अलमारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुक क्लब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुस्तक के अंत में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सट्टेबाज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुकिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुकिंग-हॉल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किताबी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहीखाता लिखनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुस्तिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूक मेय्केड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुकमार्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुक-प्लेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किताब बेचनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किताबों का दुकान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुकस्टाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुस्तक टोकन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुस्ताकों का कीड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूलियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूलियन तर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Boom1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूम 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Boom3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुमेरांग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बून 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बून 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंवार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बढ़ावा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूस्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Boot1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूट 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूटब्लैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवैध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चापलूस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुटनों तक पहने जाने वाले जूते</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूटस्ट्रैप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लूट का माल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराब पीना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराबी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराब-अप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bop1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BOP2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोरा का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोरेजिक एसिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोरेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोरेक्रस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BORDEAUX</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमा की कोल्ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमावर्ती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परदेश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमावर्ती टेरियर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर 4</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोरिक एसिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पैदा होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुनर्जन्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोरान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नगर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उधार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर्स्टल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर्ट्सच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Borzoi</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकवास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छाती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जिगरी दोस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉस 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉस 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोसा नोवा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉस-आइड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गौ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोसुन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वनस्पति विज्ञान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ढिलाई से काम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोनों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परेशान करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परेशानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेचैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोतल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोतल बैंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉटल से पिलाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोतल हरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टोंटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोतल जैसी नाक वाली डॉल्फ़िन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोतल पार्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे का दराज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेबुनियाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जमीनी स्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोटुलिज़्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाउक्ल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्री का बैठने का कमरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गूढ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोगेनविला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खरीदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शोरबा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोल्डर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाउल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलेवार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोल्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोल्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उछलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शेख़ीबाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उछाल वाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाउंड 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाउंड 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाउंड 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाउंड 4</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाध्य कर्तव्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मक्खीचूस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असीम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विपुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रचुर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुष्प गुच्छ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुलदस्ता गर्नी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्बन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुर्जुआ अक्सर अपमानित होता है।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूंजीपति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोर्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूटिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुज़ौकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साँड़ का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पागल गायों को होने वाला रोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Boy1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बो 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बो 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाउडलर बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आंतें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुंज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोवरबर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉवी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाउल 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाउल 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोनों पैर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गेंदबाज 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गेंदबाज 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोव्लींग का स्थान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉलिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोलिंग एले</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गेंदबाजी का हरा मैदान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निशानेबाज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बगुला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज्या</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बो टाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बो विंडो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाह धनुष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Box1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Box2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Box3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉक्स और कॉक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉक्स कैमरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुकाबला करने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉक्सर शॉर्ट्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डिब्बा गर्डर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुक्केबाज़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुक्केबाजी दिवस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुक्केबाज़ी का दस्ताना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉक्स जंक्शन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डिब्बा नंबर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉक्स ऑफिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बक्सी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉक्स कक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉक्स स्प्रिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोकसवुद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुरझाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लड़का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहिष्कार करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बॉयो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोय - स्काउट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीक्यू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीआर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीआर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेस और बिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेसलेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रैकियोसौरस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक वन वृक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रैकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नुनखरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हरित दल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चपटी कील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भव्यता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डींग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डींगमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रह्मा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रह्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिमाग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मौलिक विचार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिमागी मौत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रतिभा पलायन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेवक़ूफ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेनपावर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मंथन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेन ट्रस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डिमाग धोना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>brainwave</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्धिमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दम करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BRAKE1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BRAKE2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BRAKE3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>BRAKE4</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेक ड्रम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेक हॉर्स पावर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेक लाइनिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेक शू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेंबल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कसना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शाखा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रैंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धमकी देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकदम नया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रांडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रांडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रांडी-स्नैप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाज़ुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीतल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रास बैंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रैसिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रासिएर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रास बंदर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीतल-रगड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीतल की कील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तांबे का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बव्वा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाहवाही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहादुर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वाहवाही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उनकी कुशलता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मांसपेशी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूर करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टांकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तांबे का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brazier1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brazier2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्राज़िल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उल्लंघन करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वादा का उल्लंघन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शांति का उल्लंघन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेड और मक्खन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेड बोर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेडक्रम्ब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेडफ्रूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेडलाइन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेड सॉस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौड़ाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कमानेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टूटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भंगुर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टूटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेक अवे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाचने का एक तरीका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टूट - फूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तोड़ने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाश्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डाका डालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अत्यंत तनावग्रस्त स्थिति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़तरनाक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फैलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दरार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संबंध विच्छेद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बांध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छाती के बीच वाली हड्डी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तन पिलाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छाती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्तन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सांस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सांस लेने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साँस लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मोहलत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विश्राम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेदम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लुभावनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्वास टेस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नस्ल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीछे का भाग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पेंदे का जन्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जांघिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीच बुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नस्ल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीडर रिएक्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीडिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Breeze1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीज़ 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीज़-ब्लॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समीरिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रेटन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आह्निका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीविटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराब बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराब की भठ्ठी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Briar1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Briar2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घूस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिक-à-Brac</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिकबैट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईंट बिछाने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईंट का काम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिकयार्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शादी का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुल्हन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर वधु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bridge1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bridd2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बांस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिज रोल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिजवर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जोड़ने वाला ऋण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उछालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लगाम पथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संक्षिप्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीफ़केस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brier1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brier2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिगेडियर।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brig1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brig2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिगेड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिगेडियर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिगेंटाइन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमकदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रौशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उज्ज्वल रोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brill1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brill2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत खूब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिलिएंटीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किनारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उछलना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंधक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चितकबरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नमकीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाओ-और-खरीद बिक्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कगार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थिरता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सागर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पशु की छाती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिसलिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाल खड़े</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिटानिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिटानिया मेटल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रीटन्नीअ का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिटिज्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंग्रेजों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिटिश अंग्रेजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिटिश सेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिटिश समर टाइम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रिटिश थर्मल यूनिट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंग्रेज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाज़ुक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भंगुर अस्थि रोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चौड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाकला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रसारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रॉडक्लोथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यापक बनाने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यापक गेज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रॉड-त्यागा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रॉडलूम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रॉड दिमाग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रॉडशीट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाव का पहलू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तलवार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जरी वस्त्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रॉकली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विवरणिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रोडीरी एंग्लाइस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brogue1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brogue2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विवाद करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टूट गया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टूटी हुई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टूटा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टूटे हुए दिल से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टूटा हुआ घर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दलाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दलाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छाता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रोमाइड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रोमिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रांकाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रोंकाइटिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्वसनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंगली घोड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भोंसें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पीतल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कांस्य युग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कांस्य पदक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रोच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चेवाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brook1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Brook2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झाड़ू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रदर्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शोरबा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वेश्यालय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भइया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाईचारे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहनोई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लाया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रोहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भौंह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धमकाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूरे भालू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूरा कोयला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्राउनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्राउनी प्वाइंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तमंचा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूरे रंग का उल्लू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भूरे रंग के चावल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्राउन शुगर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्राउज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रूसिलोसिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खरोंच लगने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुचना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रंच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>श्यामला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इनकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रश करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झाड़-झंखाड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रशवर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रसल स्प्राउट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रूर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रूरता बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पशु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्रायनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बी एस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीएससी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीएसई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीएसआई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीएसटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीटी।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीटीयू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलबुला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलबुला और चीख़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बबल स्नान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बबल कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बबल गम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बबली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुबो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टाऊन प्लेग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डाकू करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बक1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बक2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बक 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाल्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाल्टी कुर्सी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकेट-शॉप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकसुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाकलेर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कठोरनियमनिष्ठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिरन का फ़िज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकषिष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिरन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिरन का चमड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिरन का सींग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिरन-दाँत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनाज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ग्राम्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्धा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्ध धर्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बडलिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साथी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पशु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बजट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भैंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बफ़र 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बफ़र 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बफर अवस्था</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Buffet1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Buffet2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुफे कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विदूषक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कीड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डरावना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डरावना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बग्गी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छोटी गाड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिगुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बग्लोस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्माता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>इमारत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाज निर्माण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बनाया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>में निर्मित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बना हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बल्ब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उभड़ा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उभाड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्युलिमिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोक में ख़रीदारी करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिवार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुल 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुल 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुल 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार का कुत्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलड़ोग क्लिप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलडोज़र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलडोज़र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गोली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलेटिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलेटप्रूफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साँड़ की लड़ाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक प्रकार की पक्षी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलफ्रॉग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हठीला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बैल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलरिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुल की आंख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलशिट मोटे स्लैंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिकारी कुत्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bully1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bully2 (पूर्ण धमकाने में बंद)</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bully3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सरकंडा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बांध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bum1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bum2 यूएस स्लैंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूतड़ बैग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुम्बल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भंवरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बम्फ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उभार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बम्पर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टक्कर देने वाली कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेढंग आदमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़ुद-एतमाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुच्छा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंडल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रोटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डाट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंगला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रस्सी बांधकर कूदना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घपला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गोखरू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंक 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंक 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंक 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शायिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकवास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>करगोश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लेम्प बर्नर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bunting1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bunting2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bunting2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बांधना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रसन्नचित्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूपा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उछालना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरबोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बोझ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सबूत के बोझ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्डॉक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्यूरो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौकरशाही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौकरशाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुर्ज</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>burette</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरुजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्गर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नगरवासि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेंधमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सेंध मारना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्गोमास्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बरगंडी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दफ़न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खोदनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कारटून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बलवान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्न 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्न 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्नर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जलता हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जलाने वाला कांच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पालिश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जला हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खराब हुए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जला हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जला हुआ गेरू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अग्नि को दी गई आहुति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्प</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बूर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मांद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्सार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>bursary</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फोड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बर्टन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दफ़नाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बस्बी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुश 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुश 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाजायज़ बच्चा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झाड़ीदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुशल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झाड़ी में आग लगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भगोड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुश टेलीग्राफ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जंगली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यवसाय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कारोबार की तरह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यवसायी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यवसाय स्थल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यापारिक व्यक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बसक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बस लेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बसमैन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बसमैन की छुट्टी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बस आश्रय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बस स्टेशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बस स्टॉप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bust1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bust2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उल्लू का पट्टा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बस्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवाक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bustle1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bustle2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असफल होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यस्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पराए काम में वाधा डालनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>व्यस्त लिजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परंतु -</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुटान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कसाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कसाईखाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बट 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बट 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बट 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बट 4</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मक्खन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटर बीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मक्खन मलाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटरकप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मक्खन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मक्खन उंगलियों</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तितली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तितली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तितली प्रहार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मक्खन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छाछ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटर मसलिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटरस्कॉच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Buttery1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटर 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूतड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटन मशरुम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुश्ता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ब्यूटाइल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बक्सोम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खरीदना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रेता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खरीदार बाजार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खरीद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भनभनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बज़ार्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बजर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चर्चा-शब्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्वारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्वारा-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bye1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Bye2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपनिर्वाचन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Byelorussian (Belorussian भी)</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुराना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>द्वारा जी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रेखा से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपमार्ग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाईप्ले</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपोत्पाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गोशाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सड़क द्वारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सड़क द्वारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाईसिनोसिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दर्शक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाइट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वैसे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घृणा का पात्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीजान्टिन</p>",
  }
];
