export const alphabet_X = [
  {
    alpha: "<!-- X <p> start here </p> -->",
  },
  {
    word: "X",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p> </p>",
      },
    ],
  },
  {
    word: "X1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>X1 <strong class='noun'> n. </strong> (also x) (pl. Xs or x's) 1 twenty-fourth letter of the alphabet. 2 (as a roman numeral) ten. 3 (usu. X) algebra first unknown quantity. 4 unknown or unspecified number or person etc. 5 cross-shaped symbol used esp. To indicate position (x marks the spot) or incorrectness, or to symbolize a kiss or a vote, or as the signature of a person who cannot write.</p>",
      },
    ],
  },
  {
    word: "X2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>X2 <strong class='prep'> symb. </strong> (of films) classified as suitable for adults only. <strong class='usage'> Usage:- </strong>this symbol was superseded in the uk in 1983 by 18, but it is still used in the us.</p>",
      },
    ],
  },
  {
    word: "X chromosome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>X chromosome <strong class='noun'> n. </strong> (in humans and some other mammals) sex chromosome of which the number in female cells is twice that in male cells. [x as an arbitrary label]</p>",
      },
    ],
  },
  {
    word: "Xe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Xe <strong class='prep'> symb. </strong> Xenon.</p>",
      },
    ],
  },
  {
    word: "Xenon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Xenon <strong class='noun'> n. </strong> Heavy inert gaseous element. [greek, neuter of xenos strange]</p>",
      },
    ],
  },
  {
    word: "Xenophobia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Xenophobia <strong class='noun'> n. </strong> Hatred or fear of foreigners. xenophobic adj. [greek xenos strange, stranger]</p>",
      },
    ],
  },
  {
    word: "Xerography",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Xerography <strong class='noun'> n. </strong> Dry copying process in which powder adheres to areas remaining electrically charged after exposure of the surface to light from an image of the document to be copied. [greek xeros dry]</p>",
      },
    ],
  },
  {
    word: "Xerox",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Xerox <strong class='noun'> —n. </strong> Propr. 1 machine for copying by xerography. 2 copy thus made. —v. (xerox) reproduce by this process.</p>",
      },
    ],
  },
  {
    word: "Xi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Xi <strong class='noun'> n. </strong> Fourteenth letter of the greek alphabet (x, x). [greek]</p>",
      },
    ],
  },
  {
    word: "-xion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-xion <strong class='see'>  <strong class='see'> see *</strong> -ion. </strong> </p>",
      },
    ],
  },
  {
    word: "Xmas",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Xmas <strong class='noun'> n. </strong> Colloq. = *christmas. [abbreviation, with x for the initial chi of greek khristos christ]</p>",
      },
    ],
  },
  {
    word: "X-ray",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>X-ray <strong class='noun'> —n. </strong> 1 (in pl.) Electromagnetic radiation of short wavelength, able to pass through opaque bodies. 2 photograph made by x-rays, esp. Showing the position of bones etc. By their greater absorption of the rays. —v. Photograph, examine, or treat with x-rays. [x, originally with ref. To the unknown nature of the rays]</p>",
      },
    ],
  },
  {
    word: "Xylem",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Xylem <strong class='noun'> n. </strong> Bot. Woody tissue. [greek]</p>",
      },
    ],
  },
  {
    word: "Xylophone",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Xylophone <strong class='noun'> n. </strong> Musical instrument of graduated wooden or metal bars struck with small wooden hammers. xylophonist <strong class='noun'> n. </strong> [greek xulon wood]</p>",
      },
    ],
  },
];

export const alphabet_X_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक्स<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>X1<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>X2<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक्स गुणसूत्र<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक्सई<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्सीनन<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>विदेशी लोगों को न पसन्द करना<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जैरोग्राफ़ी<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ीरक्सा<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्सी<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-xion<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रिसमस<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एक्स-रे<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाइलम<p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सिलाफ़न<p>",
  },
];
