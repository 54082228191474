export const alphabet_N = [
  {
    word: "N",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p> </p>",
      },
    ],
  },
  {
    word: "N1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>N1 <strong class='noun'> n. </strong> (also n) (pl. Ns or n's) 1 fourteenth letter of the alphabet. 2 (usu. N) indefinite number. to the nth degree to the utmost.</p>",
      },
    ],
  },
  {
    word: "N2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>N2 <strong class='sing'> abbr. </strong> (also n.) 1 north; northern. 2 new.</p>",
      },
    ],
  },
  {
    word: "N3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>N3 <strong class='prep'> symb. </strong> Nitrogen.</p>",
      },
    ],
  },
  {
    word: "N",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p> <strong class='sing'> abbr. </strong> (also n.) 1 name. 2 neuter.</p>",
      },
    ],
  },
  {
    word: "Na",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Na <strong class='prep'> symb. </strong> Sodium.</p>",
      },
    ],
  },
  {
    word: "Naafi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naafi <strong class='sing'> abbr. </strong> 1 navy, army, and air force institutes. 2 canteen for servicemen run by the naafi.</p>",
      },
    ],
  },
  {
    word: "Nab",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nab <strong class='verb'> v. </strong> (-bb-) slang 1 arrest; catch in wrongdoing. 2 grab. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nacho",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nacho <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> tortilla chip, usu. Topped with melted cheese and spices etc. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Nacre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nacre <strong class='noun'> n. </strong> Mother-of-pearl from any shelled mollusc. nacreous adj. [french]</p>",
      },
    ],
  },
  {
    word: "Nadir",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nadir <strong class='noun'> n. </strong> 1 part of the celestial sphere directly below an observer. 2 lowest point; time of deep despair. [arabic, = opposite]</p>",
      },
    ],
  },
  {
    word: "Naevus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naevus <strong class='noun'> n. </strong> (us nevus) (pl. Naevi) 1 raised red birthmark. 2 = *mole2. [latin]</p>",
      },
    ],
  },
  {
    word: "Naff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naff <strong class='adj'> adj. </strong> Slang 1 unfashionable. 2 rubbishy. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nag1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nag1 <strong class='verb'> v. </strong> <strong class='gg'> (-gg-) </strong> 1 a persistently criticize or scold. B (often foll. By at) find fault or urge, esp. Persistently. 2 (of a pain) be persistent. [originally a dial. Word]</p>",
      },
    ],
  },
  {
    word: "Nag2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nag2 <strong class='noun'> n. </strong> Colloq. Horse. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Naiad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naiad <strong class='noun'> n. </strong> Water-nymph. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Nail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nail <strong class='noun'> —n. </strong> 1 small metal spike hammered in to join things together or as a peg or decoration. 2 horny covering on the upper surface of the tip of the human finger or toe. —v. 1 fasten with a nail or nails. 2 secure or get hold of (a person or thing). 3 keep (attention etc.) Fixed. 4 expose or discover (a lie or liar). nail down 1 bind (a person) to a promise etc. 2 define precisely. 3 fasten (a thing) with nails. Nail in a person's coffin something thought to increase the risk of death. On the nail (esp. Of payment) without delay. [old english]</p>",
      },
    ],
  },
  {
    word: "Nail-file",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nail-file <strong class='noun'> n. </strong> Roughened metal or emery strip used for smoothing the nails.</p>",
      },
    ],
  },
  {
    word: "Nail polish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nail polish <strong class='noun'> n. </strong> (also nail varnish) varnish, usu. Coloured, applied to the nails.</p>",
      },
    ],
  },
  {
    word: "Naïve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naïve <strong class='adj'> adj. </strong> (also naive) 1 innocent; unaffected. 2 foolishly credulous. 3 (of art) produced in a sophisticated society but lacking conventional expertise. naïvely <strong class='adv'> adv. </strong> Naïvety <strong class='noun'> n. </strong> (also naïveté). [latin nativus *native]</p>",
      },
    ],
  },
  {
    word: "Naked",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naked <strong class='adj'> adj. </strong> 1 without clothes; nude. 2 without its usual covering. 3 undisguised (the naked truth). 4 (of a light, flame, sword, etc.) Unprotected or unsheathed. nakedly <strong class='adv'> adv. </strong> Nakedness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Naked eye",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naked eye <strong class='noun'> n. </strong> (prec. By the) unassisted vision, e.g. Without a telescope etc.</p>",
      },
    ],
  },
  {
    word: "Namby-pamby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Namby-pamby <strong class='adj'> —adj.</strong> Insipidly pretty or sentimental; weak. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> namby-pamby person. [fanciful formulation on the name of the writer ambrose philips]</p>",
      },
    ],
  },
  {
    word: "Name",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Name <strong class='noun'> —n. </strong> 1 word by which an individual person, family, animal, place, or thing is spoken of etc. 2 a (usu. Abusive) term used of a person etc. (called him names). B word denoting an object or esp. A class of objects etc. (what is the name of those flowers?). 3 famous person. 4 reputation, esp. A good one. —v. (-ming) 1 give a name to. 2 state the name of. 3 mention; specify; cite. 4 nominate. have to one's name possess. In the name of as representing; by virtue of (in the name of the law). In name only not in reality. Make a name for oneself become famous. nameable adj. [old english]</p>",
      },
    ],
  },
  {
    word: "Name-day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Name-day <strong class='noun'> n. </strong> Feast-day of the saint after whom a person is named.</p>",
      },
    ],
  },
  {
    word: "Name-dropping",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Name-dropping <strong class='noun'> n. </strong> Familiar mention of famous people as a form of boasting.</p>",
      },
    ],
  },
  {
    word: "Nameless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nameless <strong class='adj'> adj. </strong> 1 having or showing no name. 2 unnamed (our informant, who shall be nameless). 3 too horrific to be named (nameless vices).</p>",
      },
    ],
  },
  {
    word: "Namely",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Namely <strong class='adv'> adv. </strong> That is to say; in other words.</p>",
      },
    ],
  },
  {
    word: "Name-plate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Name-plate <strong class='noun'> n. </strong> Plate or panel bearing the name of an occupant of a room etc.</p>",
      },
    ],
  },
  {
    word: "Namesake",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Namesake <strong class='noun'> n. </strong> Person or thing having the same name as another. [probably from for the name's sake]</p>",
      },
    ],
  },
  {
    word: "Nan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nan <strong class='noun'> n. </strong> (also nana, nanna) <strong class='colloq'> colloq. </strong> Grandmother. [childish pronunciation]</p>",
      },
    ],
  },
  {
    word: "Nancy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nancy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> (in full nancy boy) slang offens. Effeminate man, esp. A homosexual. [pet form of ann]</p>",
      },
    ],
  },
  {
    word: "Nanny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nanny <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 child's nurse. 2 <strong class='colloq'> colloq. </strong> Grandmother. 3 (in full nanny-goat) female goat. [related to *nancy]</p>",
      },
    ],
  },
  {
    word: "Nano-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nano- <strong class='n_pl'> comb. </strong> Form denoting a factor of 10-9 (nanosecond). [greek nanos dwarf]</p>",
      },
    ],
  },
  {
    word: "Nap1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nap1 <strong class='noun'> —v. </strong> (-pp-) sleep lightly or briefly. <strong class='noun'> —n. </strong> Short sleep or doze, esp. By day. catch a person napping detect in negligence etc; catch off guard. [old english]</p>",
      },
    ],
  },
  {
    word: "Nap2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nap2 <strong class='noun'> n. </strong> Raised pile on textiles, esp. Velvet. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Nap3",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nap3 <strong class='noun'> —n. </strong> 1 form of whist in which players declare the number of tricks they expect to take. 2 racing tip claimed to be almost a certainty. —v. (-pp-) name (a horse etc.) As a probable winner. go nap 1 attempt to take all five tricks in nap.</p>",
      },
    ],
  },
  {
    word: "Napalm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Napalm <strong class='noun'> —n. </strong> Thick jellied hydrocarbon mixture used in bombs. —v. Attack with napalm bombs. [from *naphthalene, *palm1]</p>",
      },
    ],
  },
  {
    word: "Nape",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nape <strong class='noun'> n. </strong> Back of the neck. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Naphtha",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naphtha <strong class='noun'> n. </strong> Inflammable hydrocarbon distilled from coal etc. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Naphthalene",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naphthalene <strong class='noun'> n. </strong> White crystalline substance produced by distilling coal tar.</p>",
      },
    ],
  },
  {
    word: "Napkin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Napkin <strong class='noun'> n. </strong> 1 piece of linen etc. For wiping the lips, fingers, etc. At meals. 2 baby's nappy. [french nappe from latin mappa *map]</p>",
      },
    ],
  },
  {
    word: "Nappy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nappy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> piece of towelling etc. Wrapped round a baby to absorb or retain urine and faeces. [from *napkin]</p>",
      },
    ],
  },
  {
    word: "Narcissism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narcissism <strong class='noun'> n. </strong> Excessive or erotic interest in oneself. narcissistic adj. [narkissos, name of a youth in greek myth who fell in love with his reflection]</p>",
      },
    ],
  },
  {
    word: "Narcissus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narcissus <strong class='noun'> n. </strong> (pl. -cissi) any of several flowering bulbs, including the daffodil. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Narcosis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narcosis <strong class='noun'> n. </strong> 1 state of insensibility. 2 induction of this. [greek narke numbness]</p>",
      },
    ],
  },
  {
    word: "Narcotic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narcotic <strong class='adj'> —adj.</strong> 1 (of a substance) inducing drowsiness etc. 2 (of a drug) affecting the mind. <strong class='noun'> —n. </strong> Narcotic substance, drug, or influence. [greek narkotikos]</p>",
      },
    ],
  },
  {
    word: "Nark  slang",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nark slang <strong class='noun'> —n. </strong> Police informer or decoy. —v. Annoy. [romany nak nose]</p>",
      },
    ],
  },
  {
    word: "Narrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narrate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 give a continuous story or account of. 2 provide a spoken accompaniment for (a film etc.). narration <strong class='noun'> n. </strong> Narrator <strong class='noun'> n. </strong> [latin narro]</p>",
      },
    ],
  },
  {
    word: "Narrative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narrative <strong class='noun'> —n. </strong> Ordered account of connected events. <strong class='adj'> —adj.</strong> Of or by narration.</p>",
      },
    ],
  },
  {
    word: "Narrow",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narrow <strong class='adj'> —adj.</strong> (-er, -est) 1 a of small width. B confined or confining (within narrow bounds). 2 of limited scope (in the narrowest sense). 3 with little margin (narrow escape). 4 precise; exact. 5 = *narrow-minded. <strong class='noun'> —n. </strong> (usu. In pl.) Narrow part of a strait, river, pass, street, etc. —v. Become or make narrow; contract; lessen. narrowly <strong class='adv'> adv. </strong> Narrowness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Narrow boat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narrow boat <strong class='noun'> n. </strong> Canal boat.</p>",
      },
    ],
  },
  {
    word: "Narrow-minded",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narrow-minded <strong class='adj'> adj. </strong> Rigid or restricted in one's views, intolerant. narrow-mindedness n.</p>",
      },
    ],
  },
  {
    word: "Narwhal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Narwhal <strong class='noun'> n. </strong> Arctic white whale, the male of which has a long tusk. [dutch from danish]</p>",
      },
    ],
  },
  {
    word: "Nasa",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nasa <strong class='sing'> abbr. </strong> (in the us) national aeronautics and space administration.</p>",
      },
    ],
  },
  {
    word: "Nasal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nasal <strong class='adj'> —adj.</strong> 1 of the nose. 2 (of a letter or a sound) pronounced with the breath passing through the nose, e.g. M, n, ng. 3 (of the voice or speech) having many nasal sounds. <strong class='noun'> —n. </strong> Nasal letter or sound. nasalize v. (also -ise) (-zing or -sing). Nasally <strong class='adv'> adv. </strong> [latin nasus nose]</p>",
      },
    ],
  },
  {
    word: "Nascent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nascent <strong class='adj'> adj. </strong> 1 in the act of being born. 2 just beginning to be; not yet mature. nascency <strong class='noun'> n. </strong> [latin: related to *natal]</p>",
      },
    ],
  },
  {
    word: "Nasturtium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nasturtium <strong class='noun'> n. </strong> Trailing plant with edible leaves and bright orange, yellow, or red flowers. [latin]</p>",
      },
    ],
  },
  {
    word: "Nasty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nasty <strong class='adj'> —adj.</strong> (-ier, -iest) 1 highly unpleasant. 2 difficult to negotiate. 3 (of a person or animal) ill-natured. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> <strong class='colloq'> colloq. </strong> Horror film, esp. One on video and depicting cruelty or killing. nastily <strong class='adv'> adv. </strong> Nastiness <strong class='noun'> n. </strong> [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nasty piece of work",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nasty piece of work <strong class='noun'> n. </strong> Colloq. Unpleasant or contemptible person.</p>",
      },
    ],
  },
  {
    word: "Nat.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nat. <strong class='abbr'> Abbr. </strong> 1 national. 2 nationalist. 3 natural.</p>",
      },
    ],
  },
  {
    word: "Natal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natal <strong class='adj'> adj. </strong> Of or from one's birth. [latin natalis from nascor nat- be born]</p>",
      },
    ],
  },
  {
    word: "Nation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nation <strong class='noun'> n. </strong> Community of people of mainly common descent, history, language, etc., forming a state or inhabiting a territory. [latin: related to *natal]</p>",
      },
    ],
  },
  {
    word: "National",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National <strong class='adj'> —adj.</strong> 1 of a, or the, nation. 2 characteristic of a particular nation. <strong class='noun'> —n. </strong> 1 citizen of a specified country. 2 fellow-countryman. 3 (the national) = *grand national. nationally adv.</p>",
      },
    ],
  },
  {
    word: "National anthem",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National anthem <strong class='noun'> n. </strong> Song adopted by a nation, intended to inspire patriotism.</p>",
      },
    ],
  },
  {
    word: "National curriculum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National curriculum <strong class='noun'> n. </strong> Common programme of study for pupils in the maintained schools of england and wales, with tests at specified ages.</p>",
      },
    ],
  },
  {
    word: "National debt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National debt <strong class='noun'> n. </strong> Money owed by a state because of loans to it.</p>",
      },
    ],
  },
  {
    word: "National front",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National front <strong class='noun'> n. </strong> Uk political party with extreme reactionary views on immigration etc.</p>",
      },
    ],
  },
  {
    word: "National grid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National grid <strong class='noun'> n. </strong> 1 network of high-voltage electric power lines between major power stations. 2 metric system of geographical coordinates used in maps of the british isles.</p>",
      },
    ],
  },
  {
    word: "National health",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National health <strong class='noun'> n. </strong> (also national health service) system of national medical care paid for mainly by taxation.</p>",
      },
    ],
  },
  {
    word: "National insurance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National insurance <strong class='noun'> n. </strong> System of compulsory payments by employed persons (supplemented by employers) to provide state assistance in sickness etc.</p>",
      },
    ],
  },
  {
    word: "Nationalism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nationalism <strong class='noun'> n. </strong> 1 patriotic feeling, principles, etc. 2 policy of national independence. nationalist <strong class='noun'> n. </strong> & adj. Nationalistic adj.</p>",
      },
    ],
  },
  {
    word: "Nationality",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nationality <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 status of belonging to a particular nation (has british nationality). 2 condition of being national; distinctive national qualities. 3 ethnic group forming a part of one or more political nations.</p>",
      },
    ],
  },
  {
    word: "Nationalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nationalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 take (railways, industry, land, etc.) Into state ownership. 2 make national. nationalization n.</p>",
      },
    ],
  },
  {
    word: "National park",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National park <strong class='noun'> n. </strong> Area of natural beauty protected by the state for the use of the public.</p>",
      },
    ],
  },
  {
    word: "National service",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>National service <strong class='noun'> n. </strong> Hist. Conscripted peacetime military service.</p>",
      },
    ],
  },
  {
    word: "Nationwide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nationwide <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Extending over the whole nation.</p>",
      },
    ],
  },
  {
    word: "Native",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Native <strong class='noun'> —n. </strong> 1 a (usu. Foll. By of) person born in a specified place. B local inhabitant. 2 often offens. Member of a non-white indigenous people, as regarded by colonial settlers. 3 (usu. Foll. By of) indigenous animal or plant. <strong class='adj'> —adj.</strong> 1 inherent; innate. 2 of one's birth (native country). 3 (usu. Foll. By to) belonging to a specified place. 4 (esp. Of a non-european) indigenous; born in a place. 5 (of metal etc.) Found in a pure or uncombined state. [latin: related to *natal]</p>",
      },
    ],
  },
  {
    word: "Nativity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nativity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 (esp. The nativity) a christ's birth. B festival of christ's birth. 2 birth. [latin: related to *native]</p>",
      },
    ],
  },
  {
    word: "Nato",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nato <strong class='sing'> abbr. </strong> (also nato) north atlantic treaty organization.</p>",
      },
    ],
  },
  {
    word: "Natter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natter <strong class='colloq'> colloq. </strong> —v. Chatter idly. <strong class='noun'> —n. </strong> Aimless chatter. [imitative, originally dial.]</p>",
      },
    ],
  },
  {
    word: "Natterjack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natterjack <strong class='noun'> n. </strong> A kind of small toad. [perhaps from *natter]</p>",
      },
    ],
  },
  {
    word: "Natty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natty <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Trim; smart. nattily <strong class='adv'> adv. </strong> [cf. *neat]</p>",
      },
    ],
  },
  {
    word: "Natural",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natural <strong class='adj'> —adj.</strong> 1 a existing in or caused by nature (natural landscape). B uncultivated (in its natural state). 2 in the course of nature (died of natural causes). 3 not surprising; to be expected (natural for her to be upset). 4 unaffected, spontaneous. 5 innate (natural talent for music). 6 not disguised or altered (as by make-up etc.). 7 likely or suited by its or their nature to be such (natural enemies; natural leader). 8 physically existing (the natural world). 9 illegitimate. 10 <strong class='mus'> mus. </strong> (of a note) not sharpened or flattened (b natural). <strong class='noun'> —n. </strong> 1 <strong class='colloq'> colloq. </strong> (usu. Foll. By for) person or thing naturally suitable, adept, etc. 2 <strong class='mus'> mus. </strong> A sign denoting a return to natural pitch. B natural note. naturalness <strong class='noun'> n. </strong> [latin: related to *nature]</p>",
      },
    ],
  },
  {
    word: "Natural gas",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natural gas <strong class='noun'> n. </strong> Gas found in the earth's crust, not manufactured.</p>",
      },
    ],
  },
  {
    word: "Natural history",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natural history <strong class='noun'> n. </strong> The study of animals or plants.</p>",
      },
    ],
  },
  {
    word: "Naturalism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naturalism <strong class='noun'> n. </strong> 1 theory or practice in art and literature of realistic representation. 2 a theory of the world that excludes the supernatural or spiritual. B moral or religious system based on this. naturalistic adj.</p>",
      },
    ],
  },
  {
    word: "Naturalist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naturalist <strong class='noun'> n. </strong> 1 person who studies natural history. 2 adherent of naturalism.</p>",
      },
    ],
  },
  {
    word: "Naturalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naturalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 admit (a foreigner) to citizenship. 2 successfully introduce (an animal, plant, etc.) Into another region. 3 adopt (a foreign word, custom, etc.). naturalization n.</p>",
      },
    ],
  },
  {
    word: "Natural law",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natural law <strong class='noun'> n. </strong> 1 unchanging moral principles common to all human beings. 2 correct statement of an invariable sequence between specified conditions and a specified phenomenon.</p>",
      },
    ],
  },
  {
    word: "Naturally",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naturally <strong class='adv'> adv. </strong> 1 in a natural manner. 2 (qualifying a whole sentence) as might be expected; of course.</p>",
      },
    ],
  },
  {
    word: "Natural number",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natural number <strong class='noun'> n. </strong> Whole number greater than 0.</p>",
      },
    ],
  },
  {
    word: "Natural resources",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natural resources <strong class='n_pl'> n.pl. </strong> Materials or conditions occurring in nature and capable of economic exploitation.</p>",
      },
    ],
  },
  {
    word: "Natural science",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natural science <strong class='noun'> n. </strong> 1 the study of the natural or physical world. 2 (in pl.) Sciences used for this.</p>",
      },
    ],
  },
  {
    word: "Natural selection",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natural selection <strong class='noun'> n. </strong> Darwinian theory of the survival and propagation of organisms best adapted to their environment.</p>",
      },
    ],
  },
  {
    word: "Nature",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nature <strong class='noun'> n. </strong> 1 thing's or person's innate or essential qualities or character. 2 (often nature) a physical power causing all material phenomena. B these phenomena. 3 kind or class (things of this nature). 4 inherent impulses determining character or action. by nature innately. In (or by) the nature of things 1 inevitable. 2 inevitably. [latin natura: related to *natal]</p>",
      },
    ],
  },
  {
    word: "Natured",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Natured <strong class='adj'> adj. </strong> (in <strong class='n_pl'> comb. </strong> ) Having a specified disposition (good-natured).</p>",
      },
    ],
  },
  {
    word: "Nature reserve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nature reserve <strong class='noun'> n. </strong> Tract of land managed so as to preserve its flora, fauna, physical features, etc.</p>",
      },
    ],
  },
  {
    word: "Nature trail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nature trail <strong class='noun'> n. </strong> Signposted path through the countryside designed to draw attention to natural phenomena.</p>",
      },
    ],
  },
  {
    word: "Naturism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naturism <strong class='noun'> n. </strong> Nudism. naturist n.</p>",
      },
    ],
  },
  {
    word: "Naught  archaic or literary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naught archaic or literary <strong class='noun'> —n. </strong> Nothing, nought. <strong class='adj'> —adj.</strong> (usu. Predic.) Worthless; useless. come to naught come to nothing, fail. Set at naught despise. [old english: related to *no2, *wight]</p>",
      },
    ],
  },
  {
    word: "Naughty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naughty <strong class='adj'> adj. </strong> (-ier, -iest) 1 (esp. Of children) disobedient; badly behaved. 2 <strong class='colloq'> colloq. </strong> Joc. Indecent. naughtily <strong class='adv'> adv. </strong> Naughtiness <strong class='noun'> n. </strong> [from *naught]</p>",
      },
    ],
  },
  {
    word: "Nausea",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nausea <strong class='noun'> n. </strong> 1 inclination to vomit. 2 revulsion. [greek naus ship]</p>",
      },
    ],
  },
  {
    word: "Nauseate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nauseate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> affect with nausea. nauseating adj. Nauseatingly adv.</p>",
      },
    ],
  },
  {
    word: "Nauseous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nauseous <strong class='adj'> adj. </strong> 1 causing nausea. 2 inclined to vomit (feel nauseous). 3 disgusting; loathsome.</p>",
      },
    ],
  },
  {
    word: "Nautical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nautical <strong class='adj'> adj. </strong> Of sailors or navigation. [greek nautes sailor]</p>",
      },
    ],
  },
  {
    word: "Nautical mile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nautical mile <strong class='noun'> n. </strong> Unit of approx. 2,025 yards (1,852 metres).</p>",
      },
    ],
  },
  {
    word: "Nautilus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nautilus <strong class='noun'> n. </strong> (pl. Nautiluses or nautili) cephalopod mollusc with a spiral shell, esp. (pearly nautilus) one having a chambered shell. [greek nautilos: related to *nautical]</p>",
      },
    ],
  },
  {
    word: "Naval",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Naval <strong class='adj'> adj. </strong> 1 of the or a navy. 2 of ships. [latin navis ship]</p>",
      },
    ],
  },
  {
    word: "Nave1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nave1 <strong class='noun'> n. </strong> Central part of a church, usu. From the west door to the chancel excluding the side aisles. [latin navis ship]</p>",
      },
    ],
  },
  {
    word: "Nave2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nave2 <strong class='noun'> n. </strong> Hub of a wheel. [old english]</p>",
      },
    ],
  },
  {
    word: "Navel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Navel <strong class='noun'> n. </strong> Depression in the centre of the belly marking the site of attachment of the umbilical cord. [old english]</p>",
      },
    ],
  },
  {
    word: "Navel orange",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Navel orange <strong class='noun'> n. </strong> Orange with a navel-like formation at the top.</p>",
      },
    ],
  },
  {
    word: "Navigable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Navigable <strong class='adj'> adj. </strong> 1 (of a river etc.) Suitable for ships to pass through. 2 seaworthy. 3 steerable. navigability <strong class='noun'> n. </strong> [latin: related to *navigate]</p>",
      },
    ],
  },
  {
    word: "Navigate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Navigate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 manage or direct the course of (a ship or aircraft) using maps and instruments. 2 a sail on (a sea, river, etc.). B fly through (the air). 3 (in a car etc.) Assist the driver by map-reading etc. 4 sail a ship; sail in a ship. navigator <strong class='noun'> n. </strong> [latin navigo from navis]</p>",
      },
    ],
  },
  {
    word: "Navigation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Navigation <strong class='noun'> n. </strong> 1 act or process of navigating. 2 art or science of navigating. navigational adj.</p>",
      },
    ],
  },
  {
    word: "Navvy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Navvy <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> labourer employed in building or excavating roads, canals, etc. —v. (-ies, -ied) work as a navvy. [abbreviation of navigator]</p>",
      },
    ],
  },
  {
    word: "Navy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Navy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 (often the navy) a whole body of a state's ships of war, including crews, maintenance systems, etc. B officers and men of a navy. 2 (in full navy blue) dark-blue colour as of naval uniforms. 3 poet. Fleet of ships. [romanic navia ship: related to *naval]</p>",
      },
    ],
  },
  {
    word: "Nay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nay <strong class='adv'> —adv. </strong> 1 or rather; and even; and more than that (large, nay, huge). 2 archaic = *no2 <strong class='adv'> adv. </strong> 1. <strong class='noun'> —n. </strong> Utterance of ‘nay’; ‘no’ vote. [old norse, = not ever]</p>",
      },
    ],
  },
  {
    word: "Nazarene",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nazarene <strong class='noun'> —n. </strong> 1 a (prec. By the) christ. B (esp. In jewish or muslim use) christian. 2 native or inhabitant of nazareth. <strong class='adj'> —adj.</strong> Of nazareth. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Nazi",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nazi <strong class='noun'> —n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> hist. Member of the german national socialist party. <strong class='adj'> —adj.</strong> Of the nazis or nazism. nazism <strong class='noun'> n. </strong> [representing pronunciation of nati- in german nationalsozialist]</p>",
      },
    ],
  },
  {
    word: "Nb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nb <strong class='sing'> abbr. </strong> Note well. [latin nota bene]</p>",
      },
    ],
  },
  {
    word: "Nb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Nb <strong class='prep'> symb. </strong> Niobium.</p>",
      },
    ],
  },
  {
    word: "Ncb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ncb <strong class='sing'> abbr. </strong> Hist. National coal board. <strong class='usage'> Usage:- </strong>since 1987 the official name has been british coal.</p>",
      },
    ],
  },
  {
    word: "Nco",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nco <strong class='sing'> abbr. </strong> Non-commissioned officer.</p>",
      },
    ],
  },
  {
    word: "Ncp",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ncp <strong class='sing'> abbr. </strong> National car parks.</p>",
      },
    ],
  },
  {
    word: "Nd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Nd <strong class='prep'> symb. </strong> Neodymium.</p>",
      },
    ],
  },
  {
    word: "Ne",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ne <strong class='sing'> abbr. </strong> 1 north-east. 2 north-eastern.</p>",
      },
    ],
  },
  {
    word: "Ne",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ne <strong class='prep'> symb. </strong> Neon.</p>",
      },
    ],
  },
  {
    word: "Neanderthal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neanderthal <strong class='adj'> adj. </strong> Of the type of human widely distributed in palaeolithic europe, with a retreating forehead and massive brow-ridges. [region in w. Germany]</p>",
      },
    ],
  },
  {
    word: "Neap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neap <strong class='noun'> n. </strong> (in full neap tide) tide at the times of the month when there is least difference between high and low water. [old english]</p>",
      },
    ],
  },
  {
    word: "Neapolitan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neapolitan <strong class='noun'> —n. </strong> Native or citizen of naples. <strong class='adj'> —adj.</strong> Of naples. [greek neapolis naples]</p>",
      },
    ],
  },
  {
    word: "Near",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Near <strong class='adv'> —adv. </strong> 1 <strong class='adj'> (often foll. By to) </strong> to or at a short distance in space or time. 2 closely (as near as one can guess). <strong class='prep'> —prep. </strong> 1 to or at a short distance from (in space, time, condition, or resemblance). 2 (in <strong class='n_pl'> comb. </strong> ) Almost (near-hysterical). <strong class='adj'> —adj.</strong> 1 close (to), not far (in place or time) (my flat's very near; the man nearest you; in the near future). 2 a closely related. B intimate. 3 (of a part of a vehicle, animal, or road) on the left side. 4 close; narrow (near escape). 5 similar (to) (is nearer the original). 6 <strong class='colloq'> colloq. </strong> Niggardly. —v. Approach; draw near to. come (or go) near (foll. By verbal noun, or to + verbal noun) be on the point of, almost succeed in. Near at hand within easy reach. Near the knuckle <strong class='colloq'> colloq. </strong> Verging on the indecent. nearish adj. Nearness <strong class='noun'> n. </strong> [old norse, originally = nigher: related to *nigh]</p>",
      },
    ],
  },
  {
    word: "Nearby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nearby <strong class='adj'> —adj.</strong> Near in position. <strong class='adv'> —adv. </strong> Close; not far away.</p>",
      },
    ],
  },
  {
    word: "Near east",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Near east <strong class='noun'> n. </strong> (prec. By the) region comprising the countries of the eastern mediterranean. near eastern adj.</p>",
      },
    ],
  },
  {
    word: "Nearly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nearly <strong class='adv'> adv. </strong> 1 almost. 2 closely. not nearly nothing like.</p>",
      },
    ],
  },
  {
    word: "Near miss",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Near miss <strong class='noun'> n. </strong> 1 bomb etc. Falling close to the target. 2 narrowly avoided collision. 3 not quite successful attempt.</p>",
      },
    ],
  },
  {
    word: "Nearside",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nearside <strong class='noun'> n. </strong> (often attrib.) Left side of a vehicle, animal, etc.</p>",
      },
    ],
  },
  {
    word: "Near-sighted",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Near-sighted <strong class='adj'> adj. </strong> = *short-sighted.</p>",
      },
    ],
  },
  {
    word: "Near thing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Near thing <strong class='noun'> n. </strong> Narrow escape.</p>",
      },
    ],
  },
  {
    word: "Neat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neat <strong class='adj'> adj. </strong> 1 tidy and methodical. 2 elegantly simple. 3 brief, clear, and pointed. 4 a cleverly executed. B dexterous. 5 (of esp. Alcoholic liquor) undiluted. neatly <strong class='adv'> adv. </strong> Neatness <strong class='noun'> n. </strong> [french net from latin nitidus shining]</p>",
      },
    ],
  },
  {
    word: "Neaten",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Neaten <strong class='verb'> v. </strong> Make neat.</p>",
      },
    ],
  },
  {
    word: "Neath",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neath <strong class='n_pl'> comb. </strong> Poet. Beneath. [from *beneath]</p>",
      },
    ],
  },
  {
    word: "Nebula",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nebula <strong class='noun'> n. </strong> (pl. Nebulae) cloud of gas and dust seen in the night sky, sometimes glowing and sometimes appearing as a dark silhouette.  nebular adj. [latin, = mist]</p>",
      },
    ],
  },
  {
    word: "Nebulous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nebulous <strong class='adj'> adj. </strong> 1 cloudlike. 2 indistinct, vague. [latin: related to *nebula]</p>",
      },
    ],
  },
  {
    word: "Nec",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nec <strong class='sing'> abbr. </strong> National executive committee.</p>",
      },
    ],
  },
  {
    word: "Necessary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necessary <strong class='adj'> —adj.</strong> 1 requiring to be done; requisite, essential. 2 determined, existing, or happening by natural laws etc., not by free will; inevitable. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> (usu. In pl.) Any of the basic requirements of life. the necessary <strong class='colloq'> colloq. </strong> 1 money. 2 an action etc. Needed for a purpose. necessarily <strong class='adv'> adv. </strong> [latin necesse needful]</p>",
      },
    ],
  },
  {
    word: "Necessitarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necessitarian <strong class='noun'> —n. </strong> Person who holds that all action is predetermined and free will is impossible. <strong class='adj'> —adj.</strong> Of such a person or theory. necessitarianism n.</p>",
      },
    ],
  },
  {
    word: "Necessitate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necessitate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> make necessary (esp. As a result) (will necessitate some sacrifice).</p>",
      },
    ],
  },
  {
    word: "Necessitous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necessitous <strong class='adj'> adj. </strong> Poor; needy.</p>",
      },
    ],
  },
  {
    word: "Necessity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necessity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 indispensable thing. 2 pressure of circumstances. 3 imperative need. 4 want; poverty. 5 constraint or compulsion regarded as a natural law governing all human action. of necessity unavoidably.</p>",
      },
    ],
  },
  {
    word: "Neck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neck <strong class='noun'> —n. </strong> 1 a part of the body connecting the head to the shoulders. B part of a garment round the neck. 2 something resembling a neck; narrow part of a cavity, vessel, or object such as a bottle or violin. 3 length of a horse's head and neck as a measure of its lead in a race. 4 flesh of an animal's neck as food. 5 slang impudence. —v. Colloq. Kiss and caress amorously. get it in the neck <strong class='colloq'> colloq. </strong> 1 be severely reprimanded or punished. 2 suffer a severe blow. Up to one's neck (often foll. By in) <strong class='colloq'> colloq. </strong> Very deeply involved; very busy. [old english]</p>",
      },
    ],
  },
  {
    word: "Neck and neck",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neck and neck <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> (running) level in a race etc.</p>",
      },
    ],
  },
  {
    word: "Neckband",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neckband <strong class='noun'> n. </strong> Strip of material round the neck of a garment.</p>",
      },
    ],
  },
  {
    word: "Neckerchief",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neckerchief <strong class='noun'> n. </strong> Square of cloth worn round the neck. [from *kerchief]</p>",
      },
    ],
  },
  {
    word: "Necklace",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necklace <strong class='noun'> n. </strong> 1 chain or string of beads, precious stones, etc., worn round the neck. 2 s.afr. Tyre soaked or filled with petrol, placed round a victim's neck, and set alight.</p>",
      },
    ],
  },
  {
    word: "Neckline",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neckline <strong class='noun'> n. </strong> Edge or shape of a garment-opening at the neck.</p>",
      },
    ],
  },
  {
    word: "Necktie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necktie <strong class='noun'> n. </strong> Esp. Us = *tie <strong class='noun'> n. </strong> 2.</p>",
      },
    ],
  },
  {
    word: "Necro-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necro- <strong class='n_pl'> comb. </strong> Form corpse. [greek nekros corpse]</p>",
      },
    ],
  },
  {
    word: "Necromancy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necromancy <strong class='noun'> n. </strong> 1 divination by supposed communication with the dead. 2 magic. necromancer <strong class='noun'> n. </strong> [from *necro-, mantis seer]</p>",
      },
    ],
  },
  {
    word: "Necrophilia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necrophilia <strong class='noun'> n. </strong> Morbid and esp. Sexual attraction to corpses.</p>",
      },
    ],
  },
  {
    word: "Necropolis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necropolis <strong class='noun'> n. </strong> Ancient cemetery or burial place. [greek: related to *necro-, polis city]</p>",
      },
    ],
  },
  {
    word: "Necrosis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Necrosis <strong class='noun'> n. </strong> Death of tissue. necrotic adj. [greek nekroo kill]</p>",
      },
    ],
  },
  {
    word: "Nectar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nectar <strong class='noun'> n. </strong> 1 sugary substance produced by plants and made into honey by bees. 2 (in greek and roman mythology) the drink of the gods. 3 drink compared to this. nectarous adj. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Nectarine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nectarine <strong class='noun'> n. </strong> Smooth-skinned variety of peach. [from *nectar]</p>",
      },
    ],
  },
  {
    word: "Nedc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nedc <strong class='sing'> abbr. </strong> National economic development council.</p>",
      },
    ],
  },
  {
    word: "Neddy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neddy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> <strong class='colloq'> colloq. </strong> 1 donkey. 2 (neddy) = *nedc. [pet form of edward]</p>",
      },
    ],
  },
  {
    word: "Nee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nee <strong class='adj'> adj. </strong> (brit. Née) (used in adding a married woman's maiden name after her surname) born (mrs ann hall, née brown). [french, feminine  <strong class='archaic_it_was'>past part.</strong> Of naître be born]</p>",
      },
    ],
  },
  {
    word: "Née",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Née <strong class='adj'> adj. </strong> (us nee) (used in adding a married woman's maiden name after her surname) born (mrs ann hall, née brown). [french, feminine  <strong class='archaic_it_was'>past part.</strong> Of naître be born]</p>",
      },
    ],
  },
  {
    word: "Need",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Need <strong class='noun'> —v. </strong> 1 stand in want of; require. 2 (foll. By to + infin.; <strong class='archaic_it_was'> 3rd sing. </strong>Present neg. Or interrog. Need without to) be under the necessity or obligation (needs to be done well; he need not come; need you ask?). <strong class='noun'> —n. </strong> 1 requirement (my needs are few). 2 circumstances requiring some course of action (no need to worry; if need be).</p>",
      },
    ],
  },
  {
    word: "Needful",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Needful <strong class='adj'> adj. </strong> Requisite. needfully adv.</p>",
      },
    ],
  },
  {
    word: "Needle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Needle <strong class='noun'> —n. </strong> 1 a very thin pointed rod of smooth steel etc. With a slit (‘eye’) for thread at the blunt end, used in sewing. B larger plastic, wooden, etc. Slender rod without an eye, used in knitting etc. 2 pointer on a dial. 3 any of several small thin pointed instruments, esp.: a the end of a hypodermic syringe. B = *stylus 1. 4 a obelisk (cleopatra's needle). B pointed rock or peak. 5 leaf of a fir or pine tree. 6 (the needle) slang fit of bad temper or nervousness. —v. (-ling) <strong class='colloq'> colloq. </strong> Irritate; provoke. [old english]</p>",
      },
    ],
  },
  {
    word: "Needlecord",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Needlecord <strong class='noun'> n. </strong> Fine-ribbed corduroy fabric.</p>",
      },
    ],
  },
  {
    word: "Needle-point",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Needle-point <strong class='noun'> n. </strong> 1 lace made with needles, not bobbins. 2 = *gros point or *petit point.</p>",
      },
    ],
  },
  {
    word: "Needless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Needless <strong class='adj'> adj. </strong> 1 unnecessary. 2 uncalled for. needlessly adv.</p>",
      },
    ],
  },
  {
    word: "Needlewoman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Needlewoman <strong class='noun'> n. </strong> 1 seamstress. 2 woman or girl with specified sewing skill.</p>",
      },
    ],
  },
  {
    word: "Needlework",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Needlework <strong class='noun'> n. </strong> Sewing or embroidery.</p>",
      },
    ],
  },
  {
    word: "Needs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Needs <strong class='adv'> adv. </strong> Archaic (usu. Prec. Or foll. By must) of necessity.</p>",
      },
    ],
  },
  {
    word: "Needy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Needy <strong class='adj'> adj. </strong> (-ier, -iest) poor; destitute. neediness n.</p>",
      },
    ],
  },
  {
    word: "Ne'er",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ne'er <strong class='adv'> adv. </strong> Poet. = *never. [contraction]</p>",
      },
    ],
  },
  {
    word: "Ne'er-do-well",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ne'er-do-well <strong class='noun'> —n. </strong> Good-for-nothing person. <strong class='adj'> —adj.</strong> Good-for-nothing.</p>",
      },
    ],
  },
  {
    word: "Nefarious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nefarious <strong class='adj'> adj. </strong> Wicked. [latin nefas wrong n.]</p>",
      },
    ],
  },
  {
    word: "Neg.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neg. <strong class='abbr'> Abbr. </strong> Esp. Photog. Negative.</p>",
      },
    ],
  },
  {
    word: "Negate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 nullify. 2 assert or imply the non-existence of. [latin nego deny]</p>",
      },
    ],
  },
  {
    word: "Negation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negation <strong class='noun'> n. </strong> 1 absence or opposite of something actual or positive. 2 act of denying. 3 negative statement. 4 negative or unreal thing.</p>",
      },
    ],
  },
  {
    word: "Negative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negative <strong class='adj'> —adj.</strong> 1 expressing or implying denial, prohibition, or refusal (negative answer). 2 (of a person or attitude) lacking positive attributes. 3 marked by the absence of qualities (negative reaction). 4 of the opposite nature to a thing regarded as positive. 5 (of a quantity) less than zero, to be subtracted from others or from zero. 6 electr. A of the kind of charge carried by electrons. B containing or producing such a charge. <strong class='noun'> —n. </strong> 1 negative statement or word. 2 photog. A image with black and white reversed or colours replaced by complementary ones, from which positive pictures are obtained. B developed film or plate bearing such an image. —v. <strong class='ving'> (-ving) </strong> 1 refuse to accept or countenance; veto. 2 disprove. 3 contradict (a statement). 4 neutralize (an effect). in the negative with negative effect. negatively <strong class='adv'> adv. </strong> Negativity n.</p>",
      },
    ],
  },
  {
    word: "Negativism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negativism <strong class='noun'> n. </strong> Negative attitude; extreme scepticism.</p>",
      },
    ],
  },
  {
    word: "Neglect",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neglect <strong class='noun'> —v. </strong> 1 fail to care for or to do; be remiss about. 2 (foll. By to + infin.) Fail; overlook the need to. 3 not pay attention to; disregard. <strong class='noun'> —n. </strong> 1 negligence. 2 neglecting or being neglected. 3 (usu. Foll. By of) disregard. neglectful adj. Neglectfully <strong class='adv'> adv. </strong> [latin neglego neglect-]</p>",
      },
    ],
  },
  {
    word: "Negligée",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negligée <strong class='noun'> n. </strong> (also negligee, négligé) woman's flimsy dressing-gown. [french,  <strong class='archaic_it_was'>past part.</strong> Of négliger *neglect]</p>",
      },
    ],
  },
  {
    word: "Negligence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negligence <strong class='noun'> n. </strong> 1 lack of proper care and attention. 2 culpable carelessness. negligent adj. Negligently <strong class='adv'> adv. </strong> [latin: related to *neglect]</p>",
      },
    ],
  },
  {
    word: "Negligible",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negligible <strong class='adj'> adj. </strong> Not worth considering; insignificant. negligibly <strong class='adv'> adv. </strong> [french: related to *neglect]</p>",
      },
    ],
  },
  {
    word: "Negotiable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negotiable <strong class='adj'> adj. </strong> 1 open to discussion. 2 able to be negotiated.</p>",
      },
    ],
  },
  {
    word: "Negotiate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negotiate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 (usu. Foll. By with) confer in order to reach an agreement. 2 arrange (an affair) or bring about (a result) by negotiating. 3 find a way over, through, etc. (an obstacle, difficulty, etc.). 4 convert (a cheque etc.) Into money. negotiation <strong class='noun'> n. </strong> Negotiator <strong class='noun'> n. </strong> [latin negotium business]</p>",
      },
    ],
  },
  {
    word: "Negress",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negress <strong class='noun'> n. </strong> Female negro. <strong class='usage'> Usage:- </strong>the term negress is often considered offensive; black is usually preferred.</p>",
      },
    ],
  },
  {
    word: "Negritude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negritude <strong class='noun'> n. </strong> 1 state of being black. 2 affirmation of black culture. [french]</p>",
      },
    ],
  },
  {
    word: "Negro",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negro <strong class='noun'> —n. </strong> (pl. -es) member of a dark-skinned race <strong class='archaic_it_was'> orig. </strong> Native to africa. <strong class='adj'> —adj.</strong> 1 of negroes. 2 (as negro) zool. Black or dark. [latin niger nigri black] <strong class='usage'> Usage:- </strong>the term negro is often considered offensive; black is usually preferred.</p>",
      },
    ],
  },
  {
    word: "Negroid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Negroid <strong class='adj'> —adj.</strong> (of physical features etc.) Characteristic of black people. <strong class='noun'> —n. </strong> Black.</p>",
      },
    ],
  },
  {
    word: "Neigh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neigh <strong class='noun'> —n. </strong> Cry of a horse. —v. Make a neigh. [old english]</p>",
      },
    ],
  },
  {
    word: "Neighbor  (brit. Neighbour)",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neighbor (brit. Neighbour) <strong class='noun'> —n. </strong> 1 person living next door to or near or nearest another. 2 fellow human being. 3 person or thing near or next to another. —v. Border on; adjoin. [old english: related to *nigh, *boor]</p>",
      },
    ],
  },
  {
    word: "Neighborhood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neighborhood <strong class='noun'> n. </strong> (brit. Neighbourhood) 1 district; vicinity. 2 people of a district. in the neighbourhood of roughly; about.</p>",
      },
    ],
  },
  {
    word: "Neighborly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neighborly <strong class='adj'> adj. </strong> (brit. Neighbourly) like a good neighbour; friendly; kind. neighbourliness n.</p>",
      },
    ],
  },
  {
    word: "Neighbour  (us neighbor)",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neighbour (us neighbor) <strong class='noun'> —n. </strong> 1 person living next door to or near or nearest another. 2 fellow human being. 3 person or thing near or next to another. —v. Border on; adjoin. [old english: related to *nigh, *boor]</p>",
      },
    ],
  },
  {
    word: "Neighbourhood",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neighbourhood <strong class='noun'> n. </strong> (us neighborhood) 1 district; vicinity. 2 people of a district. in the neighbourhood of roughly; about.</p>",
      },
    ],
  },
  {
    word: "Neighbourhood watch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neighbourhood watch <strong class='noun'> n. </strong> Organized local vigilance by householders to discourage crime.</p>",
      },
    ],
  },
  {
    word: "Neighbourly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neighbourly <strong class='adj'> adj. </strong> (us neighborly) like a good neighbour; friendly; kind. neighbourliness n.</p>",
      },
    ],
  },
  {
    word: "Neither",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neither <strong class='adj'> —adj.</strong> & <strong class='pron'> pron. </strong> (foll. By sing. Verb) not the one nor the other (of two things); not either (neither of the accusations is true; neither of them knows; neither wish was granted; neither went to the fair). <strong class='adv'> —adv. </strong> 1 not either; not on the one hand (foll. By nor; introducing the first of two or more things in the negative: neither knowing nor caring; neither the teachers nor the parents nor the children). 2 also not (if you do not, neither shall i). — <strong class='conj'> conj. </strong> Archaic nor yet; nor (i know not, neither can i guess). [old english: related to *no2, *whether]</p>",
      },
    ],
  },
  {
    word: "Nelson",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nelson <strong class='noun'> n. </strong> Wrestling-hold in which one arm is passed under the opponent's arm from behind and the hand is applied to the neck (half nelson), or both arms and hands are applied (full nelson). [apparently from the name nelson]</p>",
      },
    ],
  },
  {
    word: "Nematode",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nematode <strong class='noun'> n. </strong> Worm with a slender unsegmented cylindrical shape. [greek nema thread]</p>",
      },
    ],
  },
  {
    word: "Nem. Con.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nem. Con. <strong class='abbr'> Abbr. </strong> With no one dissenting. [latin nemine contradicente]</p>",
      },
    ],
  },
  {
    word: "Nemesis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nemesis <strong class='noun'> n. </strong> (pl. Nemeses) 1 retributive justice. 2 downfall caused by this. [greek, = retribution]</p>",
      },
    ],
  },
  {
    word: "Neo-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neo- <strong class='n_pl'> comb. </strong> Form 1 new, modern. 2 new form of. [greek neos new]</p>",
      },
    ],
  },
  {
    word: "Neoclassicism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neoclassicism <strong class='noun'> n. </strong> Revival of classical style or treatment in the arts. neoclassical adj.</p>",
      },
    ],
  },
  {
    word: "Neodymium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neodymium <strong class='noun'> n. </strong> Metallic element of the lanthanide series. [from *neo-, greek didumos twin]</p>",
      },
    ],
  },
  {
    word: "Neolithic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neolithic <strong class='adj'> adj. </strong> Of the later part of the stone age. [greek lithos stone]</p>",
      },
    ],
  },
  {
    word: "Neologism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neologism <strong class='noun'> n. </strong> 1 new word. 2 coining of new words. [greek logos word]</p>",
      },
    ],
  },
  {
    word: "Neon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neon <strong class='noun'> n. </strong> Inert gaseous element giving an orange glow when electricity is passed through it. [greek, = new]</p>",
      },
    ],
  },
  {
    word: "Neophyte",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neophyte <strong class='noun'> n. </strong> 1 new convert. 2 rc ch. Novice of a religious order. 3 beginner. [greek phuton plant]</p>",
      },
    ],
  },
  {
    word: "Nephew",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nephew <strong class='noun'> n. </strong> Son of one's brother or sister or of one's spouse's brother or sister. [latin nepos]</p>",
      },
    ],
  },
  {
    word: "Nephritic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nephritic <strong class='adj'> adj. </strong> 1 of or in the kidneys. 2 of nephritis. [greek nephros kidney]</p>",
      },
    ],
  },
  {
    word: "Nephritis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nephritis <strong class='noun'> n. </strong> Inflammation of the kidneys.</p>",
      },
    ],
  },
  {
    word: "Ne plus ultra",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ne plus ultra <strong class='noun'> n. </strong> 1 furthest attainable point. 2 acme, perfection. [latin, = not further beyond]</p>",
      },
    ],
  },
  {
    word: "Nepotism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nepotism <strong class='noun'> n. </strong> Favouritism shown to relatives in conferring offices. [italian nepote nephew]</p>",
      },
    ],
  },
  {
    word: "Neptunium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neptunium <strong class='noun'> n. </strong> Transuranic metallic element produced when uranium atoms absorb bombarding neutrons. [neptune, name of a planet]</p>",
      },
    ],
  },
  {
    word: "Nerd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nerd <strong class='noun'> n. </strong> (also nurd) esp. Us slang foolish, feeble, or uninteresting person. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Nereid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nereid <strong class='noun'> n. </strong> Sea-nymph. [latin from greek]</p>",
      },
    ],
  },
  {
    word: "Nerve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nerve <strong class='noun'> —n. </strong> 1 a fibre or bundle of fibres that transmits impulses of sensation or motion between the brain or spinal cord and other parts of the body. B material constituting these. 2 a coolness in danger; bravery. B <strong class='colloq'> colloq. </strong> Impudence. 3 (in pl.) Nervousness; mental or physical stress. —v. <strong class='ving'> (-ving) </strong> 1 (usu. Refl.) Brace (oneself) to face danger etc. 2 give strength, vigour, or courage to. get on a person's nerves irritate a person. [latin nervus sinew, bowstring]</p>",
      },
    ],
  },
  {
    word: "Nerve-cell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nerve-cell <strong class='noun'> n. </strong> Cell transmitting impulses in nerve tissue.</p>",
      },
    ],
  },
  {
    word: "Nerve-centre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nerve-centre <strong class='noun'> n. </strong> 1 group of closely connected nerve-cells. 2 centre of control.</p>",
      },
    ],
  },
  {
    word: "Nerve gas",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nerve gas <strong class='noun'> n. </strong> Poisonous gas affecting the nervous system.</p>",
      },
    ],
  },
  {
    word: "Nerveless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nerveless <strong class='adj'> adj. </strong> 1 lacking vigour. 2 (of style) diffuse.</p>",
      },
    ],
  },
  {
    word: "Nerve-racking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nerve-racking <strong class='adj'> adj. </strong> Causing mental strain.</p>",
      },
    ],
  },
  {
    word: "Nervous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nervous <strong class='adj'> adj. </strong> 1 easily upset, timid, highly strung. 2 anxious. 3 affecting the nerves. 4 (foll. By of + verbal noun) afraid (am nervous of meeting them). nervously <strong class='adv'> adv. </strong> Nervousness n.</p>",
      },
    ],
  },
  {
    word: "Nervous breakdown",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nervous breakdown <strong class='noun'> n. </strong> Period of mental illness, usu. Resulting from severe stress.</p>",
      },
    ],
  },
  {
    word: "Nervous system",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nervous system <strong class='noun'> n. </strong> Body's network of nerve cells.</p>",
      },
    ],
  },
  {
    word: "Nervy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nervy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Nervous; easily excited.</p>",
      },
    ],
  },
  {
    word: "Nescient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nescient <strong class='adj'> adj. </strong> Literary (foll. By of) lacking knowledge. nescience <strong class='noun'> n. </strong> [latin ne- not, scio know]</p>",
      },
    ],
  },
  {
    word: "-ness",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-ness <strong class='slang_v'> suffix </strong>forming nouns from adjectives, expressing: 1 state or condition, or an instance of this (happiness; a kindness). 2 something in a certain state (wilderness). [old english]</p>",
      },
    ],
  },
  {
    word: "Nest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nest <strong class='noun'> —n. </strong> 1 structure or place where a bird lays eggs and shelters its young. 2 any creature's breeding-place or lair. 3 snug retreat or shelter. 4 brood or swarm. 5 group or set of similar objects, often of different sizes and fitting one inside the other (nest of tables). —v. 1 use or build a nest. 2 take wild birds' nests or eggs. 3 (of objects) fit together or one inside another. [old english]</p>",
      },
    ],
  },
  {
    word: "Nest egg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nest egg <strong class='noun'> n. </strong> Sum of money saved for the future.</p>",
      },
    ],
  },
  {
    word: "Nestle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nestle <strong class='verb'> v. </strong> (-ling) 1 (often foll. By down, in, etc.) Settle oneself comfortably. 2 press oneself against another in affection etc. 3 (foll. By in, into, etc.) Push (a head or shoulder etc.) Affectionately or snugly. 4 lie half hidden or embedded. [old english]</p>",
      },
    ],
  },
  {
    word: "Nestling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nestling <strong class='noun'> n. </strong> Bird too young to leave its nest.</p>",
      },
    ],
  },
  {
    word: "Net1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Net1 <strong class='noun'> —n. </strong> 1 open-meshed fabric of cord, rope, etc. 2 piece of net used esp. To restrain, contain, or delimit, or to catch fish etc. 3 structure with a net used in various games. —v. <strong class='adj'> (-tt-) </strong> 1 a cover, confine, or catch with a net. B procure as with a net. 2 hit (a ball) into the net, esp. Of a goal. [old english]</p>",
      },
    ],
  },
  {
    word: "Net2  (also nett)",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Net2 (also nett) <strong class='adj'> —adj.</strong> 1 (esp. Of money) remaining after all necessary deductions. 2 (of a price) not reducible. 3 (of a weight) excluding that of the packaging etc. 4 (of an effect, result, etc.) Ultimate, actual. —v. <strong class='adj'> (-tt-) </strong> gain or yield (a sum) as net profit. [french: related to *neat]</p>",
      },
    ],
  },
  {
    word: "Netball",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Netball <strong class='noun'> n. </strong> Team game in which goals are scored by throwing a ball through a high horizontal ring from which a net hangs.</p>",
      },
    ],
  },
  {
    word: "Nether",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nether <strong class='adj'> adj. </strong> Archaic = *lower1. [old english]</p>",
      },
    ],
  },
  {
    word: "Nether regions",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nether regions <strong class='n_pl'> n.pl. </strong> (also nether world) hell; the underworld.</p>",
      },
    ],
  },
  {
    word: "Net profit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Net profit <strong class='noun'> n. </strong> Actual gain after working expenses have been paid.</p>",
      },
    ],
  },
  {
    word: "Nett",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Nett <strong class='var'> var. </strong> Of *net2.</p>",
      },
    ],
  },
  {
    word: "Netting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Netting <strong class='noun'> n. </strong> 1 netted fabric. 2 piece of this.</p>",
      },
    ],
  },
  {
    word: "Nettle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nettle <strong class='noun'> —n. </strong> 1 plant with jagged leaves covered with stinging hairs. 2 plant resembling this. —v. (-ling) irritate, provoke. [old english]</p>",
      },
    ],
  },
  {
    word: "Nettle-rash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nettle-rash <strong class='noun'> n. </strong> Skin eruption like nettle stings.</p>",
      },
    ],
  },
  {
    word: "Network",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Network <strong class='noun'> —n. </strong> 1 arrangement of intersecting horizontal and vertical lines. 2 complex system of railways etc. 3 people connected by the exchange of information etc., professionally or socially. 4 system of connected electrical conductors. 5 group of broadcasting stations connected for the simultaneous broadcast of a programme. 6 chain of interconnected computers. —v. Broadcast on a network.</p>",
      },
    ],
  },
  {
    word: "Neural",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neural <strong class='adj'> adj. </strong> Of a nerve or the central nervous system. [greek neuron nerve]</p>",
      },
    ],
  },
  {
    word: "Neuralgia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neuralgia <strong class='noun'> n. </strong> Intense pain along a nerve, esp. In the head or face. neuralgic adj.</p>",
      },
    ],
  },
  {
    word: "Neuritis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neuritis <strong class='noun'> n. </strong> Inflammation of a nerve or nerves.</p>",
      },
    ],
  },
  {
    word: "Neuro-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neuro- <strong class='n_pl'> comb. </strong> Form nerve or nerves. [greek neuron nerve]</p>",
      },
    ],
  },
  {
    word: "Neurology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neurology <strong class='noun'> n. </strong> The study of nerve systems. neurological adj. Neurologist n.</p>",
      },
    ],
  },
  {
    word: "Neuron",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neuron <strong class='noun'> n. </strong> (also neurone) nerve-cell.</p>",
      },
    ],
  },
  {
    word: "Neurosis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neurosis <strong class='noun'> n. </strong> (pl. Neuroses) irrational or disturbed behaviour pattern, associated with nervous distress.</p>",
      },
    ],
  },
  {
    word: "Neurosurgery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neurosurgery <strong class='noun'> n. </strong> Surgery on the nervous system, esp. The brain or spinal cord. neurosurgeon <strong class='noun'> n. </strong> Neurosurgical adj.</p>",
      },
    ],
  },
  {
    word: "Neurotic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neurotic <strong class='adj'> —adj.</strong> 1 caused by or relating to neurosis. 2 suffering from neurosis. 3 <strong class='colloq'> colloq. </strong> Abnormally sensitive or obsessive. <strong class='noun'> —n. </strong> Neurotic person. neurotically adv.</p>",
      },
    ],
  },
  {
    word: "Neuter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neuter <strong class='adj'> —adj.</strong> 1 neither masculine nor feminine. 2 (of a plant) having neither pistils nor stamen. 3 (of an insect) sexually undeveloped. <strong class='noun'> —n. </strong> 1 neuter gender or word. 2 a non-fertile insect, esp. A worker bee or ant. B castrated animal. —v. Castrate or spay. [latin]</p>",
      },
    ],
  },
  {
    word: "Neutral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neutral <strong class='adj'> —adj.</strong> 1 not supporting either of two opposing sides, impartial. 2 belonging to a neutral state etc. (neutral ships). 3 indistinct, vague, indeterminate. 4 (of a gear) in which the engine is disconnected from the driven parts. 5 (of colours) not strong or positive; grey or beige. 6 chem. Neither acid nor alkaline. 7 electr. Neither positive nor negative. 8 biol. Sexually undeveloped; asexual. <strong class='noun'> —n. </strong> 1 a neutral state or person. B citizen of a neutral state. 2 neutral gear. neutrality <strong class='noun'> n. </strong> [latin neutralis of neuter gender]</p>",
      },
    ],
  },
  {
    word: "Neutralize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neutralize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 make neutral. 2 make ineffective by an opposite force or effect. 3 exempt or exclude (a place) from the sphere of hostilities. neutralization n.</p>",
      },
    ],
  },
  {
    word: "Neutrino",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neutrino <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> elementary particle with zero electric charge and probably zero mass. [italian, diminutive of neutro neutral: related to *neuter]</p>",
      },
    ],
  },
  {
    word: "Neutron",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neutron <strong class='noun'> n. </strong> Elementary particle of about the same mass as a proton but without an electric charge. [from *neutral]</p>",
      },
    ],
  },
  {
    word: "Neutron bomb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Neutron bomb <strong class='noun'> n. </strong> Bomb producing neutrons and little blast, destroying life but not property.</p>",
      },
    ],
  },
  {
    word: "Never",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Never <strong class='adv'> adv. </strong> 1 a at no time; on no occasion; not ever. B <strong class='colloq'> colloq. </strong> As an emphatic negative (i never heard you come in). 2 not at all (never fear). 3 <strong class='colloq'> colloq. </strong> (expressing surprise) surely not (you never left the door open!). well i never! Expressing great surprise. [old english, = not ever]</p>",
      },
    ],
  },
  {
    word: "Nevermore",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nevermore <strong class='adv'> adv. </strong> At no future time.</p>",
      },
    ],
  },
  {
    word: "Never-never",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Never-never <strong class='noun'> n. </strong> (often prec. By the) <strong class='colloq'> colloq. </strong> Hire purchase.</p>",
      },
    ],
  },
  {
    word: "Nevertheless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nevertheless <strong class='adv'> adv. </strong> In spite of that; notwithstanding.</p>",
      },
    ],
  },
  {
    word: "Nevus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nevus <strong class='noun'> n. </strong> (brit. Naevus) (pl. Naevi) 1 raised red birthmark. 2 = *mole2. [latin]</p>",
      },
    ],
  },
  {
    word: "New",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New <strong class='adj'> —adj.</strong> 1 a of recent origin or arrival. B made, discovered, acquired, or experienced recently or now for the first time. 2 in original condition; not worn or used. 3 a renewed; reformed (new life; the new order). B reinvigorated (felt like a new person). 4 different from a recent previous one (has a new job). 5 <strong class='adj'> (often foll. By to) </strong> unfamiliar or strange (all new to me). 6 (usu. Prec. By the) often derog. A later, modern. B newfangled. C given to new or modern ideas. D recently affected by social change (the new rich). 7 (often prec. By the) advanced in method or theory. 8 (in place-names) discovered or founded later than and named after (new york). <strong class='adv'> —adv. </strong> (usu. In <strong class='n_pl'> comb. </strong> ) Newly, recently (new-found; new-baked). newish adj. Newness <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "New age",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New age <strong class='noun'> n. </strong> Set of beliefs replacing traditional western culture, with alternative approaches to religion, medicine, the environment, etc.</p>",
      },
    ],
  },
  {
    word: "New arrival",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New arrival <strong class='noun'> n. </strong> Colloq. Newborn child.</p>",
      },
    ],
  },
  {
    word: "Newborn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newborn <strong class='adj'> adj. </strong> Recently born.</p>",
      },
    ],
  },
  {
    word: "New broom",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New broom <strong class='noun'> n. </strong> New employee etc. Eager to make changes.</p>",
      },
    ],
  },
  {
    word: "Newcomer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newcomer <strong class='noun'> n. </strong> 1 person who has recently arrived. 2 beginner in some activity.</p>",
      },
    ],
  },
  {
    word: "Newel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newel <strong class='noun'> n. </strong> 1 supporting central post of winding stairs. 2 (also newel post) top or bottom supporting post of a stair-rail. [latin nodus knot]</p>",
      },
    ],
  },
  {
    word: "Newfangled",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newfangled <strong class='adj'> adj. </strong> Derog. Different from what one is used to; objectionably new. [= new taken]</p>",
      },
    ],
  },
  {
    word: "Newly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newly <strong class='adv'> adv. </strong> 1 recently. 2 afresh, anew.</p>",
      },
    ],
  },
  {
    word: "Newly-wed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newly-wed <strong class='noun'> n. </strong> Recently married person.</p>",
      },
    ],
  },
  {
    word: "New mathematics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New mathematics <strong class='n_pl'> n.pl. </strong> (also new maths) (also treated as sing.) System of elementary maths teaching with an emphasis on investigation and set theory.</p>",
      },
    ],
  },
  {
    word: "New moon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New moon <strong class='noun'> n. </strong> 1 moon when first seen as a crescent after conjunction with the sun. 2 time of its appearance.</p>",
      },
    ],
  },
  {
    word: "New potatoes",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New potatoes <strong class='n_pl'> n.pl. </strong> Earliest potatoes of a new crop.</p>",
      },
    ],
  },
  {
    word: "News",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>News <strong class='n_pl'> n.pl. </strong> (usu. Treated as sing.) 1 information about important or interesting recent events, esp. When published or broadcast. 2 (prec. By the) broadcast report of news. 3 newly received or noteworthy information. [from *new]</p>",
      },
    ],
  },
  {
    word: "Newsagent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newsagent <strong class='noun'> n. </strong> Seller of or shop selling newspapers etc.</p>",
      },
    ],
  },
  {
    word: "Newscast",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newscast <strong class='noun'> n. </strong> Radio or television broadcast of news reports.</p>",
      },
    ],
  },
  {
    word: "Newscaster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newscaster <strong class='noun'> n. </strong> = *newsreader.</p>",
      },
    ],
  },
  {
    word: "News conference",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>News conference <strong class='noun'> n. </strong> Press conference.</p>",
      },
    ],
  },
  {
    word: "Newsflash",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newsflash <strong class='noun'> n. </strong> Single item of important news, broadcast urgently and often interrupting other programmes.</p>",
      },
    ],
  },
  {
    word: "Newsletter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newsletter <strong class='noun'> n. </strong> Informal printed report issued periodically to members of a club etc.</p>",
      },
    ],
  },
  {
    word: "Newspaper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newspaper <strong class='noun'> n. </strong> 1 printed publication of loose folded sheets containing news, advertisements, correspondence, etc. 2 paper forming this (wrapped in newspaper).</p>",
      },
    ],
  },
  {
    word: "Newspeak",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newspeak <strong class='noun'> n. </strong> Ambiguous euphemistic language used esp. In political propaganda. [an artificial official language in orwell's nineteen eighty-four]</p>",
      },
    ],
  },
  {
    word: "Newsprint",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newsprint <strong class='noun'> n. </strong> Low-quality paper on which newspapers are printed.</p>",
      },
    ],
  },
  {
    word: "Newsreader",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newsreader <strong class='noun'> n. </strong> Person who reads out broadcast news bulletins.</p>",
      },
    ],
  },
  {
    word: "Newsreel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newsreel <strong class='noun'> n. </strong> Short cinema film of recent events.</p>",
      },
    ],
  },
  {
    word: "News room",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>News room <strong class='noun'> n. </strong> Room in a newspaper or broadcasting office where news is processed.</p>",
      },
    ],
  },
  {
    word: "News-sheet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>News-sheet <strong class='noun'> n. </strong> Simple form of newspaper; newsletter.</p>",
      },
    ],
  },
  {
    word: "News-stand",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>News-stand <strong class='noun'> n. </strong> Stall for the sale of newspapers.</p>",
      },
    ],
  },
  {
    word: "New star",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>New star <strong class='noun'> n. </strong> Nova.</p>",
      },
    ],
  },
  {
    word: "New style",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New style <strong class='noun'> n. </strong> Dating reckoned by the gregorian calendar.</p>",
      },
    ],
  },
  {
    word: "News-vendor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>News-vendor <strong class='noun'> n. </strong> Newspaper-seller.</p>",
      },
    ],
  },
  {
    word: "Newsworthy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newsworthy <strong class='adj'> adj. </strong> Topical; noteworthy as news.</p>",
      },
    ],
  },
  {
    word: "Newsy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newsy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Full of news.</p>",
      },
    ],
  },
  {
    word: "Newt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newt <strong class='noun'> n. </strong> Small amphibian with a well-developed tail. [ewt, with n from an: <strong class='var'> var. </strong> Of evet *eft]</p>",
      },
    ],
  },
  {
    word: "New testament",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New testament <strong class='noun'> n. </strong> Part of the bible concerned with the life and teachings of christ and his earliest followers.</p>",
      },
    ],
  },
  {
    word: "Newton",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Newton <strong class='noun'> n. </strong> Si unit of force that, acting on a mass of one kilogram, increases its velocity by one metre per second every second. [newton, name of a scientist]</p>",
      },
    ],
  },
  {
    word: "New town",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New town <strong class='noun'> n. </strong> Town planned and built all at once with government funds.</p>",
      },
    ],
  },
  {
    word: "New wave",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New wave <strong class='noun'> n. </strong> A style of rock music.</p>",
      },
    ],
  },
  {
    word: "New world",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New world <strong class='noun'> n. </strong> North and south america.</p>",
      },
    ],
  },
  {
    word: "New year",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New year <strong class='noun'> n. </strong> Year just begun or about to begin; first few days of a year.</p>",
      },
    ],
  },
  {
    word: "New year's day",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New year's day <strong class='noun'> n. </strong> 1 january.</p>",
      },
    ],
  },
  {
    word: "New year's eve",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>New year's eve <strong class='noun'> n. </strong> 31 december.</p>",
      },
    ],
  },
  {
    word: "Next",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Next <strong class='adj'> —adj.</strong> 1 <strong class='adj'> (often foll. By to) </strong> being, positioned, or living nearest. 2 nearest in order of time; soonest encountered (next friday; ask the next person you see). <strong class='adv'> —adv. </strong> 1 <strong class='adj'> (often foll. By to) </strong> in the nearest place or degree (put it next to mine). 2 on the first or soonest occasion (when we next meet). <strong class='noun'> —n. </strong> Next person or thing. <strong class='prep'> —prep. </strong> Colloq. Next to. next to almost (next to nothing left). [old english, superlative of *nigh]</p>",
      },
    ],
  },
  {
    word: "Next-best",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Next-best <strong class='adj'> adj. </strong> The next in order of preference.</p>",
      },
    ],
  },
  {
    word: "Next door",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Next door <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> (as adj. Often hyphenated) in the next house or room.</p>",
      },
    ],
  },
  {
    word: "Next of kin  n.sing. &",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Next of kin n.sing. & <strong class='pl'> pl. </strong> Closest living relative(s).</p>",
      },
    ],
  },
  {
    word: "Next world",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Next world <strong class='noun'> n. </strong> (prec. By the) life after death.</p>",
      },
    ],
  },
  {
    word: "Nexus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nexus <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> connected group or series. [latin necto nex- bind]</p>",
      },
    ],
  },
  {
    word: "Nhs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nhs <strong class='sing'> abbr. </strong> National health service.</p>",
      },
    ],
  },
  {
    word: "Ni",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ni <strong class='sing'> abbr. </strong> 1 northern ireland. 2 national insurance.</p>",
      },
    ],
  },
  {
    word: "Ni",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ni <strong class='prep'> symb. </strong> Nickel.</p>",
      },
    ],
  },
  {
    word: "Niacin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Niacin <strong class='noun'> n. </strong> = *nicotinic acid. [shortening]</p>",
      },
    ],
  },
  {
    word: "Nib",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nib <strong class='noun'> n. </strong> 1 pen-point. 2 (in pl.) Shelled and crushed coffee or cocoa beans. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Nibble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nibble <strong class='noun'> —v. </strong> (-ling) 1 (foll. By at) a take small bites at. B take cautious interest in. 2 eat in small amounts. 3 bite at gently, cautiously, or playfully. <strong class='noun'> —n. </strong> 1 act of nibbling. 2 very small amount of food. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Nibs",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nibs <strong class='noun'> n. </strong> his nibs joc. Colloq. Mock title used with reference to an important or self-important person. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nice <strong class='adj'> adj. </strong> 1 pleasant, satisfactory. 2 (of a person) kind, good-natured. 3 iron. Bad or awkward (nice mess). 4 fine or subtle (nice distinction). 5 fastidious; delicately sensitive. 6 (foll. By an adj., often with and) satisfactory in terms of the quality described (a nice long time; nice and warm). nicely <strong class='adv'> adv. </strong> Niceness <strong class='noun'> n. </strong> Nicish adj. (also niceish). [originally = foolish, from latin nescius ignorant]</p>",
      },
    ],
  },
  {
    word: "Nicety",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nicety <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 subtle distinction or detail. 2 precision. to a nicety with exactness.</p>",
      },
    ],
  },
  {
    word: "Niche",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Niche <strong class='noun'> n. </strong> 1 shallow recess, esp. In a wall. 2 comfortable or apt position in life or employment. 3 position from which an entrepreneur exploits a gap in the market; profitable corner of the market. [latin nidus nest]</p>",
      },
    ],
  },
  {
    word: "Nick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nick <strong class='noun'> —n. </strong> 1 small cut or notch. 2 slang a prison. B police station. 3 <strong class='colloq'> colloq. </strong> Condition (in good nick). —v. 1 make a nick or nicks in. 2 slang a steal. B arrest, catch. in the nick of time only just in time. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Nickel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nickel <strong class='noun'> n. </strong> 1 silver-white metallic element, used esp. In magnetic alloys. 2 <strong class='colloq'> colloq. </strong> Us five-cent coin. [german]</p>",
      },
    ],
  },
  {
    word: "Nickel silver",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nickel silver <strong class='noun'> n. </strong> = *german silver.</p>",
      },
    ],
  },
  {
    word: "Nickel steel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nickel steel <strong class='noun'> n. </strong> Type of stainless steel with chromium and nickel.</p>",
      },
    ],
  },
  {
    word: "Nicker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nicker <strong class='noun'> n. </strong> <strong class='pl_same'> (pl. Same) </strong> slang pound sterling. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nick-nack",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nick-nack <strong class='var'> var. </strong> Of *knick-knack.</p>",
      },
    ],
  },
  {
    word: "Nickname",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nickname <strong class='noun'> —n. </strong> Familiar or humorous name given to a person or thing instead of or as well as the real name. —v. (-ming) 1 give a nickname to. 2 call by a nickname. [earlier eke-name, with n from an: eke = addition, from old english: related to *eke]</p>",
      },
    ],
  },
  {
    word: "Nicotine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nicotine <strong class='noun'> n. </strong> Poisonous alkaloid present in tobacco. [french from nicot, introducer of tobacco into france]</p>",
      },
    ],
  },
  {
    word: "Nicotinic acid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nicotinic acid <strong class='noun'> n. </strong> Vitamin of the b complex.</p>",
      },
    ],
  },
  {
    word: "Nictitate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nictitate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> blink or wink. nictitation <strong class='noun'> n. </strong> [latin]</p>",
      },
    ],
  },
  {
    word: "Nictitating membrane",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nictitating membrane <strong class='noun'> n. </strong> Transparent third eyelid in amphibians, birds, and some other animals.</p>",
      },
    ],
  },
  {
    word: "Niece",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Niece <strong class='noun'> n. </strong> Daughter of one's brother or sister or of one's spouse's brother or sister. [latin neptis granddaughter]</p>",
      },
    ],
  },
  {
    word: "Niff",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Niff <strong class='noun'> n. </strong> & v. Colloq. Smell, stink. niffy adj. (-ier, -iest). [originally a dial. Word]</p>",
      },
    ],
  },
  {
    word: "Nifty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nifty <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> 1 clever, adroit. 2 smart, stylish. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Niggard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Niggard <strong class='noun'> n. </strong> Stingy person. [probably of scandinavian origin]</p>",
      },
    ],
  },
  {
    word: "Niggardly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Niggardly <strong class='adj'> adj. </strong> Stingy. niggardliness n.</p>",
      },
    ],
  },
  {
    word: "Nigger",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nigger <strong class='noun'> n. </strong> Offens. Black or dark-skinned person. [spanish *negro]</p>",
      },
    ],
  },
  {
    word: "Niggle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Niggle <strong class='verb'> v. </strong> (-ling) 1 be over-attentive to details. 2 find fault in a petty way. 3 <strong class='colloq'> colloq. </strong> Irritate; nag pettily. niggling adj. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nigh  adv.,",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nigh adv., <strong class='n_pl'> comb. </strong> , & adj. Archaic or dial. Near. [old english]</p>",
      },
    ],
  },
  {
    word: "Night",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Night <strong class='noun'> n. </strong> 1 period of darkness between one day and the next; time from sunset to sunrise. 2 nightfall. 3 darkness of night. 4 night or evening appointed for some activity regarded in a certain way (last night of the proms). [old english]</p>",
      },
    ],
  },
  {
    word: "Nightbird",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightbird <strong class='noun'> n. </strong> Person who is most active at night.</p>",
      },
    ],
  },
  {
    word: "Nightcap",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightcap <strong class='noun'> n. </strong> 1 hist. Cap worn in bed. 2 hot or alcoholic drink taken at bedtime.</p>",
      },
    ],
  },
  {
    word: "Nightclub",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightclub <strong class='noun'> n. </strong> Club providing refreshment and entertainment late at night.</p>",
      },
    ],
  },
  {
    word: "Nightdress",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightdress <strong class='noun'> n. </strong> Woman's or child's loose garment worn in bed.</p>",
      },
    ],
  },
  {
    word: "Nightfall",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightfall <strong class='noun'> n. </strong> End of daylight.</p>",
      },
    ],
  },
  {
    word: "Nightgown",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightgown <strong class='noun'> n. </strong> = *nightdress.</p>",
      },
    ],
  },
  {
    word: "Nightie",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightie <strong class='noun'> n. </strong> Colloq. Nightdress.</p>",
      },
    ],
  },
  {
    word: "Nightingale",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightingale <strong class='noun'> n. </strong> Small reddish-brown bird, of which the male sings melodiously, esp. At night. [old english, = night-singer]</p>",
      },
    ],
  },
  {
    word: "Nightjar",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightjar <strong class='noun'> n. </strong> Nocturnal bird with a characteristic harsh cry.</p>",
      },
    ],
  },
  {
    word: "Night-life",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Night-life <strong class='noun'> n. </strong> Entertainment available at night in a town.</p>",
      },
    ],
  },
  {
    word: "Night-light",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Night-light <strong class='noun'> n. </strong> Dim light kept burning in a bedroom at night.</p>",
      },
    ],
  },
  {
    word: "Night-long",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Night-long <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> Throughout the night.</p>",
      },
    ],
  },
  {
    word: "Nightly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightly <strong class='adj'> —adj.</strong> 1 happening, done, or existing in the night. 2 recurring every night. <strong class='adv'> —adv. </strong> Every night.</p>",
      },
    ],
  },
  {
    word: "Nightmare",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightmare <strong class='noun'> n. </strong> 1 frightening dream. 2 <strong class='colloq'> colloq. </strong> Frightening or unpleasant experience or situation. 3 haunting fear. nightmarish adj. [evil spirit (incubus) once thought to lie on and suffocate sleepers: old english mære incubus]</p>",
      },
    ],
  },
  {
    word: "Night safe",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Night safe <strong class='noun'> n. </strong> Safe with access from the outer wall of a bank for the deposit of money etc. When the bank is closed.</p>",
      },
    ],
  },
  {
    word: "Night school",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Night school <strong class='noun'> n. </strong> Institution providing classes in the evening.</p>",
      },
    ],
  },
  {
    word: "Nightshade",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightshade <strong class='noun'> n. </strong> Any of various plants with poisonous berries. [old english]</p>",
      },
    ],
  },
  {
    word: "Nightshirt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightshirt <strong class='noun'> n. </strong> Long shirt worn in bed.</p>",
      },
    ],
  },
  {
    word: "Nightspot",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nightspot <strong class='noun'> n. </strong> Nightclub.</p>",
      },
    ],
  },
  {
    word: "Night-time",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Night-time <strong class='noun'> n. </strong> Time of darkness.</p>",
      },
    ],
  },
  {
    word: "Night-watchman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Night-watchman <strong class='noun'> n. </strong> 1 person employed to keep watch at night. 2 cricket inferior batsman sent in near the close of a day's play.</p>",
      },
    ],
  },
  {
    word: "Nihilism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nihilism <strong class='noun'> n. </strong> 1 rejection of all religious and moral principles. 2 belief that nothing really exists. nihilist <strong class='noun'> n. </strong> Nihilistic adj. [latin nihil nothing]</p>",
      },
    ],
  },
  {
    word: "-nik",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>-nik <strong class='slang_v'> suffix </strong>forming nouns denoting a person associated with a specified thing or quality (beatnik). [russian (as *sputnik) and yiddish]</p>",
      },
    ],
  },
  {
    word: "Nikkei index",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nikkei index <strong class='noun'> n. </strong> (also nikkei average) a figure indicating the relative price of representative shares on the tokyo stock exchange. [japanese]</p>",
      },
    ],
  },
  {
    word: "Nil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nil <strong class='noun'> n. </strong> Nothing; no number or amount (esp. As a score in games). [latin]</p>",
      },
    ],
  },
  {
    word: "Nimble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nimble <strong class='adj'> adj. </strong> (-bler, -blest) quick and light in movement or function; agile. nimbly <strong class='adv'> adv. </strong> [old english, = quick to seize]</p>",
      },
    ],
  },
  {
    word: "Nimbus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nimbus <strong class='noun'> n. </strong> (pl. Nimbi or nimbuses) 1 halo. 2 rain-cloud. [latin, = cloud]</p>",
      },
    ],
  },
  {
    word: "Nimby",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nimby <strong class='adj'> —adj.</strong> Objecting to the siting of unpleasant developments in one's own locality. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> person who so objects. [not in my back yard]</p>",
      },
    ],
  },
  {
    word: "Nincompoop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nincompoop <strong class='noun'> n. </strong> Foolish person. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nine <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 one more than eight. 2 symbol for this (9, ix, ix). 3 size etc. Denoted by nine. [old english]</p>",
      },
    ],
  },
  {
    word: "Nine days' wonder",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nine days' wonder <strong class='noun'> n. </strong> Person or thing that is briefly famous.</p>",
      },
    ],
  },
  {
    word: "Ninefold",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ninefold <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> 1 nine times as much or as many. 2 consisting of nine parts.</p>",
      },
    ],
  },
  {
    word: "Ninepin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ninepin <strong class='noun'> n. </strong> 1 (in pl.; usu. Treated as sing.) Game in which nine pins are bowled at. 2 pin used in this game.</p>",
      },
    ],
  },
  {
    word: "Nineteen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nineteen <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 one more than eighteen. 2 symbol for this (19, xix, xix). 3 size etc. Denoted by nineteen. talk nineteen to the dozen see *dozen. nineteenth adj. & <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Ninety",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ninety <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 product of nine and ten. 2 symbol for this (90, xc, xc). 3 (in pl.) Numbers from 90 to 99, esp. The years of a century or of a person's life. ninetieth adj. & <strong class='noun'> n. </strong> [old english]</p>",
      },
    ],
  },
  {
    word: "Ninny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ninny <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> foolish person. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Ninth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Ninth <strong class='adj'> adj. </strong> & <strong class='noun'> n. </strong> 1 next after eighth. 2 any of nine equal parts of a thing. ninthly adv.</p>",
      },
    ],
  },
  {
    word: "Niobium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Niobium <strong class='noun'> n. </strong> Rare metallic element occurring naturally. [niobe in greek legend]</p>",
      },
    ],
  },
  {
    word: "Nip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nip <strong class='noun'> n. </strong> Slang offens. Japanese person. [abbreviation of nipponese from japanese nippon japan]</p>",
      },
    ],
  },
  {
    word: "Nip1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nip1 <strong class='noun'> —v. </strong> (-pp-) 1 pinch, squeeze, or bite sharply. 2 (often foll. By off) remove by pinching etc. 3 (of the cold etc.) Cause pain or harm to. 4 (foll. By in, out, etc.) Colloq. Go nimbly or quickly. <strong class='noun'> —n. </strong> 1 a pinch, sharp squeeze. B bite. 2 biting cold. nip in the bud suppress or destroy (esp. An idea) at an early stage. [low german or dutch]</p>",
      },
    ],
  },
  {
    word: "Nip2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nip2 <strong class='noun'> n. </strong> Small quantity of spirits. [from nipperkin small measure]</p>",
      },
    ],
  },
  {
    word: "Nipper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nipper <strong class='noun'> n. </strong> 1 person or thing that nips. 2 claw of a crab etc. 3 <strong class='colloq'> colloq. </strong> Young child. 4 (in pl.) Any tool for gripping or cutting.</p>",
      },
    ],
  },
  {
    word: "Nipple",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nipple <strong class='noun'> n. </strong> 1 small projection in which the mammary ducts of either sex of mammals terminate and from which in females milk is secreted for the young. 2 teat of a feeding-bottle. 3 device like a nipple in function. 4 nipple-like protuberance. [perhaps from neb tip]</p>",
      },
    ],
  },
  {
    word: "Nippy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nippy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> 1 quick, nimble. 2 chilly. [from *nip1]</p>",
      },
    ],
  },
  {
    word: "Nirvana",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nirvana <strong class='noun'> n. </strong> (in buddhism) perfect bliss attained by the extinction of individuality. [sanskrit, = extinction]</p>",
      },
    ],
  },
  {
    word: "Nissen hut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nissen hut <strong class='noun'> n. </strong> Tunnel-shaped hut of corrugated iron with a cement floor. [nissen, name of an engineer]</p>",
      },
    ],
  },
  {
    word: "Nit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nit <strong class='noun'> n. </strong> 1 egg or young form of a louse or other parasitic insect. 2 slang stupid person. [old english]</p>",
      },
    ],
  },
  {
    word: "Niter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Niter <strong class='noun'> n. </strong> (brit. Nitre) saltpetre. [greek nitron]</p>",
      },
    ],
  },
  {
    word: "Nit-picking",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nit-picking <strong class='noun'> n. </strong> & adj. Colloq. Fault-finding in a petty manner.</p>",
      },
    ],
  },
  {
    word: "Nitrate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitrate <strong class='noun'> —n. </strong> 1 any salt or ester of nitric acid. 2 potassium or sodium nitrate as a fertilizer. —v. <strong class='ting'> (-ting) </strong> treat, combine, or impregnate with nitric acid. nitration <strong class='noun'> n. </strong> [french: related to *nitre]</p>",
      },
    ],
  },
  {
    word: "Nitre",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitre <strong class='noun'> n. </strong> (us niter) saltpetre. [greek nitron]</p>",
      },
    ],
  },
  {
    word: "Nitric",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitric <strong class='adj'> adj. </strong> Of or containing nitrogen.</p>",
      },
    ],
  },
  {
    word: "Nitric acid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitric acid <strong class='noun'> n. </strong> Colourless corrosive poisonous liquid.</p>",
      },
    ],
  },
  {
    word: "Nitride",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitride <strong class='noun'> n. </strong> Binary compound of nitrogen. [from *nitre]</p>",
      },
    ],
  },
  {
    word: "Nitrify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitrify <strong class='verb'> v. </strong> (-ies, -ied) 1 impregnate with nitrogen. 2 convert into nitrites or nitrates. nitrification <strong class='noun'> n. </strong> [french: related to *nitre]</p>",
      },
    ],
  },
  {
    word: "Nitrite",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitrite <strong class='noun'> n. </strong> Any salt or ester of nitrous acid. [from *nitre]</p>",
      },
    ],
  },
  {
    word: "Nitro-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitro- <strong class='n_pl'> comb. </strong> Form of or containing nitric acid, nitre, or nitrogen. [greek: related to *nitre]</p>",
      },
    ],
  },
  {
    word: "Nitrogen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitrogen <strong class='noun'> n. </strong> Gaseous element that forms four-fifths of the atmosphere. nitrogenous adj. [french]</p>",
      },
    ],
  },
  {
    word: "Nitroglycerin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitroglycerin <strong class='noun'> n. </strong> (brit. Nitroglycerine) explosive yellow liquid made by reacting glycerol with a mixture of concentrated sulphuric and nitric acids.</p>",
      },
    ],
  },
  {
    word: "Nitroglycerine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitroglycerine <strong class='noun'> n. </strong> (us nitroglycerin) explosive yellow liquid made by reacting glycerol with a mixture of concentrated sulphuric and nitric acids.</p>",
      },
    ],
  },
  {
    word: "Nitrous oxide",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitrous oxide <strong class='noun'> n. </strong> Colourless gas used as an anaesthetic. [latin: related to *nitre]</p>",
      },
    ],
  },
  {
    word: "Nitty-gritty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitty-gritty <strong class='noun'> n. </strong> Slang realities or practical details of a matter. [origin uncertain]</p>",
      },
    ],
  },
  {
    word: "Nitwit",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nitwit <strong class='noun'> n. </strong> Colloq. Stupid person. [perhaps from *nit, *wit]</p>",
      },
    ],
  },
  {
    word: "Nne",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nne <strong class='sing'> abbr. </strong> North-north-east.</p>",
      },
    ],
  },
  {
    word: "Nnw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nnw <strong class='sing'> abbr. </strong> North-north-west.</p>",
      },
    ],
  },
  {
    word: "No1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>No1 <strong class='prep'> symb. </strong> Nobelium.</p>",
      },
    ],
  },
  {
    word: "No2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>No2 <strong class='var'> var. </strong> Of *noh.</p>",
      },
    ],
  },
  {
    word: "No.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>No. <strong class='abbr'> Abbr. </strong> Number. [latin numero, ablative of numerus number]</p>",
      },
    ],
  },
  {
    word: "No1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>No1 <strong class='adj'> adj. </strong> 1 not any (there is no excuse). 2 not a, quite other than (is no fool). 3 hardly any (did it in no time). 4 used elliptically in a notice etc., to forbid etc. The thing specified (no parking). no way <strong class='colloq'> colloq. </strong> 1 it is impossible. 2 i will not agree etc. No wonder see *wonder. [related to *none]</p>",
      },
    ],
  },
  {
    word: "No2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>No2 <strong class='adv'> —adv. </strong> 1 indicating that the answer to the question is negative, the statement etc. Made or course of action intended or conclusion arrived at is not correct or satisfactory, the request or command will not be complied with, or the negative statement made is correct. 2 (foll. By compar.) By no amount; not at all (no better than before). <strong class='noun'> —n. </strong> (pl. Noes) 1 utterance of the word no. 2 denial or refusal. 3 ‘no’ vote. no longer not now or henceforth as formerly. Or no or not (pleasant or no, it is true). [old english]</p>",
      },
    ],
  },
  {
    word: "Nob1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nob1 <strong class='noun'> n. </strong> Slang person of wealth or high social position. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nob2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nob2 <strong class='noun'> n. </strong> Slang head. [from *knob]</p>",
      },
    ],
  },
  {
    word: "No-ball",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>No-ball <strong class='noun'> n. </strong> Cricket unlawfully delivered ball.</p>",
      },
    ],
  },
  {
    word: "Nobble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nobble <strong class='verb'> v. </strong> (-ling) slang 1 try to influence (e.g. A judge), esp. Unfairly. 2 tamper with (a racehorse) to prevent its winning. 3 steal. 4 seize, catch. [dial. Knobble beat]</p>",
      },
    ],
  },
  {
    word: "Nobelium",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nobelium <strong class='noun'> n. </strong> Artificially produced radioactive transuranic metallic element. [from nobel: see *nobel prize]</p>",
      },
    ],
  },
  {
    word: "Nobel prize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nobel prize <strong class='noun'> n. </strong> Any of six international prizes awarded annually for physics, chemistry, physiology or medicine, literature, economics, and the promotion of peace. [from nobel, swedish chemist and engineer, who endowed them]</p>",
      },
    ],
  },
  {
    word: "Nobility",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nobility <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 nobleness of character, mind, birth, or rank. 2 class of nobles, highest social class.</p>",
      },
    ],
  },
  {
    word: "Noble",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noble <strong class='adj'> —adj.</strong> (nobler, noblest) 1 belonging to the aristocracy. 2 of excellent character; magnanimous. 3 of imposing appearance. <strong class='noun'> —n. </strong> Nobleman, noblewoman. nobleness <strong class='noun'> n. </strong> Nobly <strong class='adv'> adv. </strong> [latin (g)nobilis]</p>",
      },
    ],
  },
  {
    word: "Noble gas",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noble gas <strong class='noun'> n. </strong> Any of a group of gaseous elements that almost never combine with other elements.</p>",
      },
    ],
  },
  {
    word: "Nobleman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Nobleman <strong class='noun'> n. </strong> Peer.</p>",
      },
    ],
  },
  {
    word: "Noblesse oblige",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noblesse oblige <strong class='noun'> n. </strong> Privilege entails responsibility. [french]</p>",
      },
    ],
  },
  {
    word: "Noblewoman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noblewoman <strong class='noun'> n. </strong> Peeress.</p>",
      },
    ],
  },
  {
    word: "Nobody",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nobody <strong class='pron'> — <strong class='pron'> pron. </strong> </strong> No person. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> person of no importance.</p>",
      },
    ],
  },
  {
    word: "No claim bonus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>No claim bonus <strong class='noun'> n. </strong> (also no claims bonus) reduction of an insurance premium after an agreed period without a claim.</p>",
      },
    ],
  },
  {
    word: "Nocturnal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nocturnal <strong class='adj'> adj. </strong> Of or in the night; done or active by night. [latin nox noct- night]</p>",
      },
    ],
  },
  {
    word: "Nocturne",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nocturne <strong class='noun'> n. </strong> 1 <strong class='mus'> mus. </strong> Short romantic composition, usu. For piano. 2 picture of a night scene. [french]</p>",
      },
    ],
  },
  {
    word: "Nod",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nod <strong class='noun'> —v. </strong> (-dd-) 1 incline one's head slightly and briefly in assent, greeting, or command. 2 let one's head fall forward in drowsiness; be drowsy. 3 incline (one's head). 4 signify (assent etc.) By a nod. 5 (of flowers, plumes, etc.) Bend downwards and sway. 6 make a mistake due to a momentary lack of alertness or attention. <strong class='noun'> —n. </strong> Nodding of the head. nod off <strong class='colloq'> colloq. </strong> Fall asleep. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Noddle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noddle <strong class='noun'> n. </strong> Colloq. Head. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Noddy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noddy <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 simpleton. 2 tropical sea bird. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Node",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Node <strong class='noun'> n. </strong> 1 a part of a plant stem from which leaves emerge. B knob on a root or branch. 2 natural swelling. 3 either of two points at which a planet's orbit intersects the plane of the ecliptic or the celestial equator. 4 point of minimum disturbance in a standing wave system. 5 point at which a curve intersects itself. 6 component in a computer network. nodal adj. [latin nodus knot]</p>",
      },
    ],
  },
  {
    word: "Nodule",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nodule <strong class='noun'> n. </strong> 1 small rounded lump of anything. 2 small tumour, node, or ganglion, or a swelling on the root of a legume containing bacteria etc. nodular adj. [latin diminutive: related to *node]</p>",
      },
    ],
  },
  {
    word: "Noel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noel <strong class='noun'> n. </strong> Christmas. [latin: related to *natal]</p>",
      },
    ],
  },
  {
    word: "Noggin",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noggin <strong class='noun'> n. </strong> 1 small mug. 2 small measure, usu. 1/4 pint, of spirits. 3 slang head. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "No go",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>No go <strong class='adj'> adj. </strong> (usu. Hyphenated when attrib.) Colloq. Impossible, hopeless; forbidden (tried to get him to agree, but it was clearly no go; no-go area).</p>",
      },
    ],
  },
  {
    word: "Noh",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noh <strong class='noun'> n. </strong> (also no) traditional japanese drama. [japanese]</p>",
      },
    ],
  },
  {
    word: "Noise",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noise <strong class='noun'> —n. </strong> 1 sound, esp. A loud or unpleasant one. 2 series or confusion of loud sounds. 3 irregular fluctuations accompanying a transmitted signal. 4 (in pl.) Conventional remarks, or speechlike sounds without actual words (made sympathetic noises). —v. <strong class='sing'>(-sing)</strong> (usu. In passive) make public; spread abroad (a person's fame or a fact). [latin *nausea]</p>",
      },
    ],
  },
  {
    word: "Noiseless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noiseless <strong class='adj'> adj. </strong> Making little or no noise. noiselessly adv.</p>",
      },
    ],
  },
  {
    word: "Noisome",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noisome <strong class='adj'> adj. </strong> Literary 1 harmful, noxious. 2 evil-smelling. [from *annoy]</p>",
      },
    ],
  },
  {
    word: "Noisy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noisy <strong class='adj'> adj. </strong> (-ier, -iest) 1 making much noise. 2 full of noise. noisily <strong class='adv'> adv. </strong> Noisiness n.</p>",
      },
    ],
  },
  {
    word: "Nomad",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nomad <strong class='noun'> n. </strong> 1 member of a tribe roaming from place to place for pasture. 2 wanderer. nomadic adj. [greek nomas nomad- from nemo to pasture]</p>",
      },
    ],
  },
  {
    word: "No man's land",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>No man's land <strong class='noun'> n. </strong> 1 space between two opposing armies. 2 area not assigned to any owner.</p>",
      },
    ],
  },
  {
    word: "Nom de plume",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nom de plume <strong class='noun'> n. </strong> (pl. Noms de plume pronunc. Same) writer's assumed name. [sham french, = pen-name]</p>",
      },
    ],
  },
  {
    word: "Nomen",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nomen <strong class='noun'> n. </strong> Ancient roman's second or family name, as in marcus tullius cicero. [latin, = name]</p>",
      },
    ],
  },
  {
    word: "Nomenclature",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nomenclature <strong class='noun'> n. </strong> 1 person's or community's system of names for things. 2 terminology of a science etc. [latin nomen name, calo call]</p>",
      },
    ],
  },
  {
    word: "Nominal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nominal <strong class='adj'> adj. </strong> 1 existing in name only; not real or actual. 2 (of a sum of money etc.) Very small. 3 of or in names (nominal and essential distinctions). 4 of, as, or like a noun. nominally <strong class='adv'> adv. </strong> [latin nomen name]</p>",
      },
    ],
  },
  {
    word: "Nominalism",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nominalism <strong class='noun'> n. </strong> Doctrine that universals or general ideas are mere names. nominalist <strong class='noun'> n. </strong> Nominalistic adj.</p>",
      },
    ],
  },
  {
    word: "Nominal value",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nominal value <strong class='noun'> n. </strong> Face value.</p>",
      },
    ],
  },
  {
    word: "Nominate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nominate <strong class='verb'> v. </strong> <strong class='ting'> (-ting) </strong> 1 propose (a candidate) for election. 2 appoint to an office. 3 name or appoint (a date or place). nomination <strong class='noun'> n. </strong> Nominator <strong class='noun'> n. </strong> [latin: related to *nominal]</p>",
      },
    ],
  },
  {
    word: "Nominative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nominative <strong class='gram'>gram.</strong> <strong class='noun'> —n. </strong> Case expressing the subject of a verb. <strong class='adj'> —adj.</strong> Of or in this case.</p>",
      },
    ],
  },
  {
    word: "Nominee",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nominee <strong class='noun'> n. </strong> Person who is nominated.</p>",
      },
    ],
  },
  {
    word: "Non-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non- <strong class='prefix '> prefix </strong> giving the negative sense of words with which it is combined. [latin non not]</p>",
      },
    ],
  },
  {
    word: "Non-",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non- <strong class='prefix '> prefix </strong> giving the negative sense of words with which it is combined. [latin non not] <strong class='usage'> Usage:- </strong>the number of words that can be formed from the suffix non- is unlimited; consequently, only the most current and noteworthy can be given here.</p>",
      },
    ],
  },
  {
    word: "Nonagenarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonagenarian <strong class='noun'> n. </strong> Person from 90 to 99 years old. [latin nonageni ninety each]</p>",
      },
    ],
  },
  {
    word: "Non-aggression",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-aggression <strong class='noun'> n. </strong> Lack of or restraint from aggression (often attrib.: non-aggression pact).</p>",
      },
    ],
  },
  {
    word: "Nonagon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonagon <strong class='noun'> n. </strong> Plane figure with nine sides and angles. [latin nonus ninth, after *hexagon]</p>",
      },
    ],
  },
  {
    word: "Non-alcoholic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-alcoholic <strong class='adj'> adj. </strong> Containing no alcohol.</p>",
      },
    ],
  },
  {
    word: "Non-aligned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-aligned <strong class='adj'> adj. </strong> (of a state) not aligned with a major power. non-alignment n.</p>",
      },
    ],
  },
  {
    word: "Non-belligerent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-belligerent <strong class='adj'> —adj.</strong> Not engaged in hostilities. <strong class='noun'> —n. </strong> Non-belligerent state etc.</p>",
      },
    ],
  },
  {
    word: "Nonce",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonce <strong class='noun'> n. </strong> for the nonce for the time being; for the present occasion. [from for than anes = for the one]</p>",
      },
    ],
  },
  {
    word: "Nonce-word",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonce-word <strong class='noun'> n. </strong> Word coined for one occasion.</p>",
      },
    ],
  },
  {
    word: "Nonchalant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonchalant <strong class='adj'> adj. </strong> Calm and casual. nonchalance <strong class='noun'> n. </strong> Nonchalantly <strong class='adv'> adv. </strong> [french chaloir be concerned]</p>",
      },
    ],
  },
  {
    word: "Non-com",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-com <strong class='noun'> n. </strong> Colloq. Non-commissioned officer. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Non-combatant",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-combatant <strong class='noun'> n. </strong> Person not fighting in a war, esp. A civilian, army chaplain, etc.</p>",
      },
    ],
  },
  {
    word: "Non-commissioned",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-commissioned <strong class='adj'> adj. </strong> (of an officer) not holding a commission.</p>",
      },
    ],
  },
  {
    word: "Noncommittal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noncommittal <strong class='adj'> adj. </strong> Avoiding commitment to a definite opinion or course of action.</p>",
      },
    ],
  },
  {
    word: "Non compos mentis",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non compos mentis <strong class='adj'> adj. </strong> (also non compos) not in one's right mind. [latin, = not having control of one's mind]</p>",
      },
    ],
  },
  {
    word: "Non-conductor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-conductor <strong class='noun'> n. </strong> Substance that does not conduct heat or electricity.</p>",
      },
    ],
  },
  {
    word: "Nonconformist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonconformist <strong class='noun'> n. </strong> 1 person who does not conform to the doctrine or discipline of an established church, esp. (nonconformist) member of a (usu. Protestant) sect dissenting from the anglican church. 2 person who does not conform to a prevailing principle.</p>",
      },
    ],
  },
  {
    word: "Nonconformity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonconformity <strong class='noun'> n. </strong> 1 nonconformists as a body, or their principles. 2 (usu. Foll. By to) failure to conform. 3 lack of correspondence between things.</p>",
      },
    ],
  },
  {
    word: "Non-contributory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-contributory <strong class='adj'> adj. </strong> Not involving contributions.</p>",
      },
    ],
  },
  {
    word: "Non-cooperation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-cooperation <strong class='noun'> n. </strong> Failure to cooperate.</p>",
      },
    ],
  },
  {
    word: "Nondescript",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nondescript <strong class='adj'> —adj.</strong> Lacking distinctive characteristics, not easily classified. <strong class='noun'> —n. </strong> Nondescript person or thing. [related to *describe]</p>",
      },
    ],
  },
  {
    word: "Non-drinker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-drinker <strong class='noun'> n. </strong> Person who does not drink alcoholic liquor.</p>",
      },
    ],
  },
  {
    word: "Non-driver",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-driver <strong class='noun'> n. </strong> Person who does not drive a motor vehicle.</p>",
      },
    ],
  },
  {
    word: "None",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>None <strong class='pron'> — <strong class='pron'> pron. </strong> </strong> 1 (foll. By of) a not any of (none of this concerns me; none of them have found it). B not any one of (none of them has come). 2 a no persons (none but fools believe it). B no person (none but a fool believes it). 3 (usu. With the preceding noun implied) not any (you have money and i have none). <strong class='adv'> —adv. </strong> (foll. By the + compar., or so, too) by no amount; not at all (am none the wiser). [old english, = not one] <strong class='usage'> Usage:- </strong>in sense 1b, the verb following none can be singular or plural according to meaning.</p>",
      },
    ],
  },
  {
    word: "Nonentity",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonentity <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 person or thing of no importance. 2 a non-existence. B non-existent thing. [medieval latin]</p>",
      },
    ],
  },
  {
    word: "Nones",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nones <strong class='n_pl'> n.pl. </strong> Day of the ancient roman month (the 7th day of march, may, july, and october, the 5th of other months). [latin nonus ninth]</p>",
      },
    ],
  },
  {
    word: "Non-essential",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-essential <strong class='adj'> —adj.</strong> Not essential. <strong class='noun'> —n. </strong> Non-essential thing.</p>",
      },
    ],
  },
  {
    word: "Nonetheless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonetheless <strong class='adv'> adv. </strong> (also none the less) nevertheless.</p>",
      },
    ],
  },
  {
    word: "Non-event",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-event <strong class='noun'> n. </strong> Insignificant event, esp. Contrary to hopes or expectations.</p>",
      },
    ],
  },
  {
    word: "Non-existent",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-existent <strong class='adj'> adj. </strong> Not existing.</p>",
      },
    ],
  },
  {
    word: "Non-fattening",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-fattening <strong class='adj'> adj. </strong> (of food) not containing many calories.</p>",
      },
    ],
  },
  {
    word: "Non-ferrous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-ferrous <strong class='adj'> adj. </strong> (of a metal) other than iron or steel.</p>",
      },
    ],
  },
  {
    word: "Non-fiction",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-fiction <strong class='noun'> n. </strong> Literary work other than fiction.</p>",
      },
    ],
  },
  {
    word: "Non-flammable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-flammable <strong class='adj'> adj. </strong> Not inflammable.</p>",
      },
    ],
  },
  {
    word: "Non-interference",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-interference <strong class='noun'> n. </strong> = *non-intervention.</p>",
      },
    ],
  },
  {
    word: "Non-intervention",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-intervention <strong class='noun'> n. </strong> (esp. Political) principle or practice of not becoming involved in others' affairs.</p>",
      },
    ],
  },
  {
    word: "Non-member",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-member <strong class='noun'> n. </strong> Person who is not a member.</p>",
      },
    ],
  },
  {
    word: "Non-nuclear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-nuclear <strong class='adj'> adj. </strong> 1 not involving nuclei or nuclear energy. 2 (of a state etc.) Not having nuclear weapons.</p>",
      },
    ],
  },
  {
    word: "Non-observance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-observance <strong class='noun'> n. </strong> Failure to observe (an agreement, requirement, etc.).</p>",
      },
    ],
  },
  {
    word: "Non-operational",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-operational <strong class='adj'> adj. </strong> 1 that does not operate. 2 out of order.</p>",
      },
    ],
  },
  {
    word: "Nonpareil",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonpareil <strong class='adj'> —adj.</strong> Unrivalled or unique. <strong class='noun'> —n. </strong> Such a person or thing. [french pareil]</p>",
      },
    ],
  },
  {
    word: "Non-partisan",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-partisan <strong class='adj'> adj. </strong> Not partisan.</p>",
      },
    ],
  },
  {
    word: "Non-party",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-party <strong class='adj'> adj. </strong> Independent of political parties.</p>",
      },
    ],
  },
  {
    word: "Non-payment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-payment <strong class='noun'> n. </strong> Failure to pay; lack of payment.</p>",
      },
    ],
  },
  {
    word: "Nonplus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonplus <strong class='verb'> v. </strong> (-ss-) completely perplex. [latin non plus not more]</p>",
      },
    ],
  },
  {
    word: "Non-profit-making",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-profit-making <strong class='adj'> adj. </strong> (of an enterprise) not conducted primarily to make a profit.</p>",
      },
    ],
  },
  {
    word: "Non-proliferation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-proliferation <strong class='noun'> n. </strong> Prevention of an increase in something, esp. Possession of nuclear weapons.</p>",
      },
    ],
  },
  {
    word: "Non-resident",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-resident <strong class='adj'> —adj.</strong> 1 not residing in a particular place. 2 (of a post) not requiring the holder to reside at the place of work. <strong class='noun'> —n. </strong> Non-resident person. non-residential adj.</p>",
      },
    ],
  },
  {
    word: "Non-resistance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-resistance <strong class='noun'> n. </strong> Practice or principle of not resisting authority.</p>",
      },
    ],
  },
  {
    word: "Non-returnable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-returnable <strong class='adj'> adj. </strong> That is not to be returned.</p>",
      },
    ],
  },
  {
    word: "Non-sectarian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-sectarian <strong class='adj'> adj. </strong> Not sectarian.</p>",
      },
    ],
  },
  {
    word: "Nonsense",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nonsense <strong class='noun'> n. </strong> 1 (often as int.) Absurd or meaningless words or ideas. 2 foolish or extravagant conduct. nonsensical adj. Nonsensically adv.</p>",
      },
    ],
  },
  {
    word: "Non sequitur",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non sequitur <strong class='noun'> n. </strong> Conclusion that does not logically follow from the premisses. [latin, = it does not follow]</p>",
      },
    ],
  },
  {
    word: "Non-slip",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-slip <strong class='adj'> adj. </strong> 1 that does not slip. 2 that inhibits slipping.</p>",
      },
    ],
  },
  {
    word: "Non-smoker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-smoker <strong class='noun'> n. </strong> 1 person who does not smoke. 2 train compartment etc. Where smoking is forbidden. non-smoking adj.</p>",
      },
    ],
  },
  {
    word: "Non-specialist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-specialist <strong class='noun'> n. </strong> Person who is not a specialist (in a particular subject).</p>",
      },
    ],
  },
  {
    word: "Non-specific",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-specific <strong class='adj'> adj. </strong> That cannot be specified.</p>",
      },
    ],
  },
  {
    word: "Non-standard",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-standard <strong class='adj'> adj. </strong> Not standard.</p>",
      },
    ],
  },
  {
    word: "Non-starter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-starter <strong class='noun'> n. </strong> Colloq. Person or scheme that is unlikely to succeed.</p>",
      },
    ],
  },
  {
    word: "Non-stick",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-stick <strong class='adj'> adj. </strong> That does not allow things to stick to it.</p>",
      },
    ],
  },
  {
    word: "Non-stop",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-stop <strong class='adj'> —adj.</strong> 1 (of a train etc.) Not stopping at intermediate places. 2 done without a stop or intermission. <strong class='adv'> —adv. </strong> Without stopping.</p>",
      },
    ],
  },
  {
    word: "Non-swimmer",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-swimmer <strong class='noun'> n. </strong> Person who cannot swim.</p>",
      },
    ],
  },
  {
    word: "Non-toxic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-toxic <strong class='adj'> adj. </strong> Not toxic.</p>",
      },
    ],
  },
  {
    word: "Non-transferable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-transferable <strong class='adj'> adj. </strong> That may not be transferred.</p>",
      },
    ],
  },
  {
    word: "Non-u",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-u <strong class='adj'> adj. </strong> Colloq. Not characteristic of the upper class. [from *u2]</p>",
      },
    ],
  },
  {
    word: "Non-union",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-union <strong class='adj'> adj. </strong> 1 not belonging to a trade union. 2 not done or made by trade-union members.</p>",
      },
    ],
  },
  {
    word: "Non-verbal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-verbal <strong class='adj'> adj. </strong> Not involving words or speech.</p>",
      },
    ],
  },
  {
    word: "Non-violence",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-violence <strong class='noun'> n. </strong> Avoidance of violence, esp. As a principle. non-violent adj.</p>",
      },
    ],
  },
  {
    word: "Non-voting",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-voting <strong class='adj'> adj. </strong> 1 not having or using a vote. 2 (of shares) not entitling the holder to vote.</p>",
      },
    ],
  },
  {
    word: "Non-white",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Non-white <strong class='adj'> —adj.</strong> Not white. <strong class='noun'> —n. </strong> Non-white person.</p>",
      },
    ],
  },
  {
    word: "Noodle1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noodle1 <strong class='noun'> n. </strong> Strip or ring of pasta. [german]</p>",
      },
    ],
  },
  {
    word: "Noodle2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noodle2 <strong class='noun'> n. </strong> 1 simpleton. 2 slang head. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nook",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nook <strong class='noun'> n. </strong> Corner or recess; secluded place. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Noon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noon <strong class='noun'> n. </strong> Twelve o'clock in the day, midday. [latin nona (hora) ninth (hour): originally = 3 p.m.]</p>",
      },
    ],
  },
  {
    word: "Noonday",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Noonday <strong class='noun'> n. </strong> Midday.</p>",
      },
    ],
  },
  {
    word: "No one",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>No one <strong class='noun'> n. </strong> No person; nobody.</p>",
      },
    ],
  },
  {
    word: "Noose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noose <strong class='noun'> —n. </strong> 1 loop with a running knot. 2 snare, bond. —v. <strong class='sing'>(-sing)</strong> catch with or enclose in a noose. [french no(u)s from latin nodus *node]</p>",
      },
    ],
  },
  {
    word: "Nor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nor <strong class='conj'> conj. </strong> And not; and not either (neither one thing nor the other; can neither read nor write). [contraction of obsolete nother: related to *no2, *whether]</p>",
      },
    ],
  },
  {
    word: "Nor'  n., adj., &",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nor' n., adj., & <strong class='adv'> adv. </strong> (esp. In compounds) = *north (nor'wester). [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Nordic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nordic <strong class='adj'> —adj.</strong> Of the tall blond long-headed germanic people of scandinavia. <strong class='noun'> —n. </strong> Nordic person. [french nord north]</p>",
      },
    ],
  },
  {
    word: "Norfolk jacket",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Norfolk jacket <strong class='noun'> n. </strong> Man's loose belted jacket with box pleats. [norfolk in england]</p>",
      },
    ],
  },
  {
    word: "Norm",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Norm <strong class='noun'> n. </strong> 1 standard, pattern, or type. 2 standard amount of work etc. 3 customary behaviour etc. [latin norma carpenter's square]</p>",
      },
    ],
  },
  {
    word: "Normal",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Normal <strong class='adj'> —adj.</strong> 1 conforming to a standard; regular, usual, typical. 2 free from mental or emotional disorder. 3 geom. (of a line) at right angles, perpendicular. <strong class='noun'> —n. </strong> 1 a normal value of a temperature etc. B usual state, level, etc. 2 line at right angles. normalcy <strong class='noun'> n. </strong> Esp. Us. Normality <strong class='noun'> n. </strong> [latin normalis: related to *norm]</p>",
      },
    ],
  },
  {
    word: "Normal distribution",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Normal distribution <strong class='noun'> n. </strong> Function that represents the distribution of many random variables as a symmetrical bell-shaped graph.</p>",
      },
    ],
  },
  {
    word: "Normalize",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Normalize <strong class='verb'> v. </strong> (also -ise) (-zing or -sing) 1 make or become normal. 2 cause to conform. normalization n.</p>",
      },
    ],
  },
  {
    word: "Normally",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Normally <strong class='adv'> adv. </strong> 1 in a normal manner. 2 usually.</p>",
      },
    ],
  },
  {
    word: "Norman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Norman <strong class='noun'> —n. </strong> 1 native or inhabitant of medieval normandy. 2 descendant of the people of mixed scandinavian and frankish origin established there in the 10th c. 3 norman french. 4 style of architecture found in britain under the normans. <strong class='adj'> —adj.</strong> 1 of the normans. 2 of the norman style of architecture. [old norse, = *northman]</p>",
      },
    ],
  },
  {
    word: "Norman conquest",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Norman conquest <strong class='noun'> n. </strong> Conquest of england by william of normandy in 1066.</p>",
      },
    ],
  },
  {
    word: "Norman french",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Norman french <strong class='noun'> n. </strong> French as spoken by the normans or (after 1066) in english lawcourts.</p>",
      },
    ],
  },
  {
    word: "Normative",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Normative <strong class='adj'> adj. </strong> Of or establishing a norm. [latin: related to *norm]</p>",
      },
    ],
  },
  {
    word: "Norn",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Norn <strong class='noun'> n. </strong> Any of three goddesses of destiny in scandinavian mythology. [old norse]</p>",
      },
    ],
  },
  {
    word: "Norse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Norse <strong class='noun'> —n. </strong> 1 norwegian language. 2 scandinavian language-group. <strong class='adj'> —adj.</strong> Of ancient scandinavia, esp. Norway. norseman <strong class='noun'> n. </strong> [dutch noor(d)sch northern]</p>",
      },
    ],
  },
  {
    word: "North",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North <strong class='noun'> —n. </strong> 1 a point of the horizon 90° anticlockwise from east. B compass point corresponding to this. C direction in which this lies. 2 (usu. The north) a part of a country or town lying to the north. B the industrialized nations. <strong class='adj'> —adj.</strong> 1 towards, at, near, or facing the north. 2 from the north (north wind). <strong class='adv'> —adv. </strong> 1 towards, at, or near the north. 2 (foll. By of) further north than. to the north (often foll. By of) in a northerly direction. [old english]</p>",
      },
    ],
  },
  {
    word: "North american",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North american <strong class='adj'> —adj.</strong> Of north america. <strong class='noun'> —n. </strong> Native or inhabitant of north america, esp. A citizen of the us or canada.</p>",
      },
    ],
  },
  {
    word: "Northbound",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northbound <strong class='adj'> adj. </strong> Travelling or leading northwards.</p>",
      },
    ],
  },
  {
    word: "North country",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North country <strong class='noun'> n. </strong> Northern england.</p>",
      },
    ],
  },
  {
    word: "North-east",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-east <strong class='noun'> n. </strong> Part of a country or town to the north-east.</p>",
      },
    ],
  },
  {
    word: "North-east",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-east <strong class='noun'> —n. </strong> 1 point of the horizon midway between north and east. 2 direction in which this lies. <strong class='adj'> —adj.</strong> Of, towards, or coming from the north-east. <strong class='adv'> —adv. </strong> Towards, at, or near the north-east.</p>",
      },
    ],
  },
  {
    word: "Northeaster",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northeaster <strong class='noun'> n. </strong> North-east wind.</p>",
      },
    ],
  },
  {
    word: "North-easterly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-easterly <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> = *north-east.</p>",
      },
    ],
  },
  {
    word: "North-eastern",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-eastern <strong class='adj'> adj. </strong> On the north-east side.</p>",
      },
    ],
  },
  {
    word: "Northerly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northerly <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> 1 in a northern position or direction. 2 (of wind) from the north. <strong class='noun'> —n. </strong> <strong class='adj'> <strong class='pl_ies'> (pl. -ies) </strong> </strong> such a wind.</p>",
      },
    ],
  },
  {
    word: "Northern",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northern <strong class='adj'> adj. </strong> Of or in the north. northernmost <strong class='adj'> adj. </strong>[old english]</p>",
      },
    ],
  },
  {
    word: "Northerner",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northerner <strong class='noun'> n. </strong> Native or inhabitant of the north.</p>",
      },
    ],
  },
  {
    word: "Northern hemisphere",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northern hemisphere <strong class='noun'> n. </strong> The half of the earth north of the equator.</p>",
      },
    ],
  },
  {
    word: "Northern lights",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northern lights <strong class='n_pl'> n.pl. </strong> Aurora borealis.</p>",
      },
    ],
  },
  {
    word: "Northman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northman <strong class='noun'> n. </strong> Native of scandinavia, esp. Norway. [old english]</p>",
      },
    ],
  },
  {
    word: "North-north-east",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-north-east <strong class='noun'> n. </strong> Point or direction midway between north and north-east.</p>",
      },
    ],
  },
  {
    word: "North-north-west",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-north-west <strong class='noun'> n. </strong> Point or direction midway between north and north-west.</p>",
      },
    ],
  },
  {
    word: "North pole",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North pole <strong class='noun'> n. </strong> Northernmost point of the earth's axis of rotation.</p>",
      },
    ],
  },
  {
    word: "North star",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North star <strong class='noun'> n. </strong> Pole star.</p>",
      },
    ],
  },
  {
    word: "Northward",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northward <strong class='adj'> —adj.</strong> & <strong class='adv'> adv. </strong> (also northwards) towards the north. <strong class='noun'> —n. </strong> Northward direction or region.</p>",
      },
    ],
  },
  {
    word: "North-west",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-west <strong class='noun'> n. </strong> Part of a country or town to the north-west.</p>",
      },
    ],
  },
  {
    word: "North-west",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-west <strong class='noun'> —n. </strong> 1 point of the horizon midway between north and west. 2 direction in which this lies. <strong class='adj'> —adj.</strong> Of, towards, or coming from the north-west. <strong class='adv'> —adv. </strong> Towards, at, or near the north-west.</p>",
      },
    ],
  },
  {
    word: "Northwester",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Northwester <strong class='noun'> n. </strong> North-west wind.</p>",
      },
    ],
  },
  {
    word: "North-westerly",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-westerly <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> = *north-west.</p>",
      },
    ],
  },
  {
    word: "North-western",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>North-western <strong class='adj'> adj. </strong> On the north-west side.</p>",
      },
    ],
  },
  {
    word: "Norwegian",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Norwegian <strong class='noun'> —n. </strong> 1 a native or national of norway. B person of norwegian descent. 2 language of norway. <strong class='adj'> —adj.</strong> Of or relating to norway. [medieval latin norvegia from old norse, = northway]</p>",
      },
    ],
  },
  {
    word: "Nor'wester",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nor'wester <strong class='noun'> n. </strong> Northwester.</p>",
      },
    ],
  },
  {
    word: "Nos.  ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nos.  <strong class='archaic_it_was'>Pl. Of *no.</strong> </p>",
      },
    ],
  },
  {
    word: "Nose",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nose <strong class='noun'> —n. </strong> 1 organ above the mouth of a human or animal, used for smelling and breathing. 2 a sense of smell. B ability to detect a particular thing (a nose for scandal). 3 odour or perfume of wine etc. 4 front end or projecting part of a thing, e.g. Of a car or aircraft. —v. <strong class='sing'>(-sing)</strong> 1 (usu. Foll. By about, around, etc.) Pry or search. 2 (often foll. By out) a perceive the smell of, discover by smell. B detect. 3 thrust one's nose against or into. 4 make one's way cautiously forward. by a nose by a very narrow margin.</p>",
      },
    ],
  },
  {
    word: "Nosebag",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nosebag <strong class='noun'> n. </strong> Bag containing fodder, hung on a horse's head.</p>",
      },
    ],
  },
  {
    word: "Noseband",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noseband <strong class='noun'> n. </strong> Lower band of a bridle, passing over the horse's nose.</p>",
      },
    ],
  },
  {
    word: "Nosebleed",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nosebleed <strong class='noun'> n. </strong> Bleeding from the nose.</p>",
      },
    ],
  },
  {
    word: "Nosedive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nosedive <strong class='noun'> —n. </strong> 1 steep downward plunge by an aeroplane. 2 sudden plunge or drop. —v. <strong class='ving'> (-ving) </strong> make a nosedive.</p>",
      },
    ],
  },
  {
    word: "Nosegay",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nosegay <strong class='noun'> n. </strong> Small bunch of flowers.</p>",
      },
    ],
  },
  {
    word: "Nose-to-tail",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nose-to-tail <strong class='adj'> adj. </strong> & <strong class='adv'> adv. </strong> (of vehicles) one close behind another.</p>",
      },
    ],
  },
  {
    word: "Nosh ",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nosh  <strong class='slang_v'> slang —v. </strong> Eat. <strong class='noun'> —n. </strong> 1 food or drink. 2 us snack. [yiddish]</p>",
      },
    ],
  },
  {
    word: "Nosh-up",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nosh-up <strong class='noun'> n. </strong> Slang large meal.</p>",
      },
    ],
  },
  {
    word: "Nostalgia",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nostalgia <strong class='noun'> n. </strong> 1 (often foll. By for) yearning for a past period. 2 severe homesickness. nostalgic adj. Nostalgically <strong class='adv'> adv. </strong> [greek nostos return home]</p>",
      },
    ],
  },
  {
    word: "Nostril",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nostril <strong class='noun'> n. </strong> Either of the two openings in the nose. [old english, = nose-hole]</p>",
      },
    ],
  },
  {
    word: "Nostrum",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nostrum <strong class='noun'> n. </strong> 1 quack remedy, patent medicine. 2 pet scheme, esp. For political or social reform. [latin, = ‘of our own make’]</p>",
      },
    ],
  },
  {
    word: "Nosy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nosy <strong class='adj'> adj. </strong> (-ier, -iest) <strong class='colloq'> colloq. </strong> Inquisitive, prying. nosily <strong class='adv'> adv. </strong> Nosiness n.</p>",
      },
    ],
  },
  {
    word: "Nosy parker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nosy parker <strong class='noun'> n. </strong> Colloq. Busybody.</p>",
      },
    ],
  },
  {
    word: "Not",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Not <strong class='adv'> adv. </strong> Expressing negation, esp.: 1 (also n't joined to a preceding verb) following an auxiliary verb or be or (in a question) the subject of such a verb (i cannot say; she isn't there; am i not right?). 2 used elliptically for a negative phrase etc. (is she coming? — i hope not; do you want it? — certainly not!). not at all (in polite reply to thanks) there is no need for thanks. Not half see *half. Not quite 1 almost. 2 noticeably not (not quite proper). [contraction of *nought] <strong class='usage'> Usage:- </strong>the use of not with verbs other than auxiliaries or be is now archaic except with participles and infinitives (not knowing, i cannot say; we asked them not to come).</p>",
      },
    ],
  },
  {
    word: "Notable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notable <strong class='adj'> —adj.</strong> Worthy of note; remarkable, eminent. <strong class='noun'> —n. </strong> Eminent person. notability <strong class='noun'> n. </strong> Notably <strong class='adv'> adv. </strong> [latin noto *note]</p>",
      },
    ],
  },
  {
    word: "Notary",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notary <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> (in full notary public) solicitor etc. Who attests or certifies deeds etc. notarial adj. [latin notarius secretary]</p>",
      },
    ],
  },
  {
    word: "Notation",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notation <strong class='noun'> n. </strong> 1 representation of numbers, quantities, the pitch and duration of musical notes, etc., by symbols. 2 any set of such symbols. [latin: related to *note]</p>",
      },
    ],
  },
  {
    word: "Notch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notch <strong class='noun'> —n. </strong> V-shaped indentation on an edge or surface. —v. 1 make notches in. 2 (usu. Foll. By up) record or score with or as with notches. [anglo-french]</p>",
      },
    ],
  },
  {
    word: "Note",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Note <strong class='noun'> —n. </strong> 1 brief written record as an aid to memory (often in pl.: make notes). 2 observation, usu. Unwritten, of experiences etc. (compare notes). 3 short or informal letter. 4 formal diplomatic communication. 5 short annotation or additional explanation in a book etc. 6 a = *banknote. B written promise of payment. 7 a notice, attention (worthy of note). B eminence (person of note). 8 a single musical tone of definite pitch. B written sign representing its pitch and duration. C key of a piano etc. 9 quality or tone of speaking, expressing mood or attitude etc. (note of optimism). —v. <strong class='ting'> (-ting) </strong> 1 observe, notice; give attention to. 2 (often foll. By down) record as a thing to be remembered or observed. 3 (in passive; often foll. By for) be well known. hit (or strike) the right note speak or act in exactly the right manner. [latin nota mark (n.), noto mark (v.)]</p>",
      },
    ],
  },
  {
    word: "Notebook",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notebook <strong class='noun'> n. </strong> Small book for making notes in.</p>",
      },
    ],
  },
  {
    word: "Notecase",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notecase <strong class='noun'> n. </strong> Wallet for holding banknotes.</p>",
      },
    ],
  },
  {
    word: "Notelet",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notelet <strong class='noun'> n. </strong> Small folded usu. Decorated sheet of paper for an informal letter.</p>",
      },
    ],
  },
  {
    word: "Notepaper",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notepaper <strong class='noun'> n. </strong> Paper for writing letters.</p>",
      },
    ],
  },
  {
    word: "Noteworthy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noteworthy <strong class='adj'> adj. </strong> Worthy of attention; remarkable.</p>",
      },
    ],
  },
  {
    word: "Nothing",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nothing <strong class='noun'> —n. </strong> 1 not anything (nothing has been done). 2 no thing (often foll. By compl.: i see nothing that i want). 3 person or thing of no importance. 4 non-existence; what does not exist. 5 no amount; nought. <strong class='adv'> —adv. </strong> Not at all, in no way. be (or have) nothing to do with 1 have no connection with. 2 not be involved or associated with. For nothing 1 at no cost. 2 to no purpose. Have nothing on 1 be naked. 2 have no engagements. Nothing doing <strong class='colloq'> colloq. </strong> 1 no prospect of success or agreement. 2 i refuse. [old english: related to *no1, *thing]</p>",
      },
    ],
  },
  {
    word: "Nothingness",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nothingness <strong class='noun'> n. </strong> 1 non-existence. 2 worthlessness, triviality.</p>",
      },
    ],
  },
  {
    word: "Notice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notice <strong class='noun'> —n. </strong> 1 attention, observation (escaped my notice). 2 displayed sheet etc. Bearing an announcement. 3 a intimation or warning, esp. A formal one. B formal announcement or declaration of intention to end an agreement or leave employment at a specified time. 4 short published review of a new play, book, etc. —v. (-cing) (often foll. By that, how, etc.) Perceive, observe. at short (or a moment's) notice with little warning. Take notice (or no notice) show signs (or no signs) of interest. Take notice of 1 observe. 2 act upon. [latin notus known]</p>",
      },
    ],
  },
  {
    word: "Noticeable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noticeable <strong class='adj'> adj. </strong> Perceptible; noteworthy. noticeably adv.</p>",
      },
    ],
  },
  {
    word: "Notice-board",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notice-board <strong class='noun'> n. </strong> Board for displaying notices.</p>",
      },
    ],
  },
  {
    word: "Notifiable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notifiable <strong class='adj'> adj. </strong> (of a disease etc.) That must be notified to the health authorities.</p>",
      },
    ],
  },
  {
    word: "Notify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notify <strong class='verb'> v. </strong> (-ies, -ied) 1 (often foll. By of or that) inform or give formal notice to (a person). 2 make known. notification <strong class='noun'> n. </strong> [latin notus known]</p>",
      },
    ],
  },
  {
    word: "Notion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notion <strong class='noun'> n. </strong> 1 a concept or idea; conception. B opinion. C vague view or understanding. 2 inclination or intention. [latin notio: related to *notify]</p>",
      },
    ],
  },
  {
    word: "Notional",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notional <strong class='adj'> adj. </strong> Hypothetical, imaginary. notionally adv.</p>",
      },
    ],
  },
  {
    word: "Notorious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notorious <strong class='adj'> adj. </strong> Well-known, esp. Unfavourably. notoriety <strong class='noun'> n. </strong> Notoriously <strong class='adv'> adv. </strong> [latin notus known]</p>",
      },
    ],
  },
  {
    word: "Notwithstanding",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Notwithstanding <strong class='prep'> —prep. </strong> In spite of; without prevention by. <strong class='adv'> —adv. </strong> Nevertheless. [from *not, *withstand]</p>",
      },
    ],
  },
  {
    word: "Nougat",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nougat <strong class='noun'> n. </strong> Sweet made from sugar or honey, nuts, and egg-white. [french from provençal]</p>",
      },
    ],
  },
  {
    word: "Nought",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nought <strong class='noun'> n. </strong> 1 digit 0; cipher. 2 poet. Or archaic nothing. [old english: related to *not, *aught]</p>",
      },
    ],
  },
  {
    word: "Noughts and crosses",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noughts and crosses <strong class='n_pl'> n.pl. </strong> Pencil-and-paper game in which players seek to complete a row of three noughts or three crosses.</p>",
      },
    ],
  },
  {
    word: "Noun",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noun <strong class='noun'> n. </strong> Word used to name a person, place, or thing. [latin nomen name]</p>",
      },
    ],
  },
  {
    word: "Nourish",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nourish <strong class='verb'> v. </strong> 1 sustain with food. 2 foster or cherish (a feeling etc.). nourishing adj. [latin nutrio to feed]</p>",
      },
    ],
  },
  {
    word: "Nourishment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nourishment <strong class='noun'> n. </strong> Sustenance, food.</p>",
      },
    ],
  },
  {
    word: "Nous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nous <strong class='noun'> n. </strong> 1 <strong class='colloq'> colloq. </strong> Common sense; gumption. 2 philos. Mind, intellect. [greek]</p>",
      },
    ],
  },
  {
    word: "Nouveau riche",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nouveau riche <strong class='noun'> n. </strong> (pl. Nouveaux riches pronunc. Same) person who has recently acquired (usu. Ostentatious) wealth. [french, = new rich]</p>",
      },
    ],
  },
  {
    word: "Nouvelle cuisine",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nouvelle cuisine <strong class='noun'> n. </strong> Modern style of cookery avoiding heaviness and emphasizing presentation. [french, = new cookery]</p>",
      },
    ],
  },
  {
    word: "Nov.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Nov. <strong class='abbr'> Abbr. </strong> November.</p>",
      },
    ],
  },
  {
    word: "Nova",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nova <strong class='noun'> n. </strong> (pl. Novae or -s) star showing a sudden burst of brightness and then subsiding. [latin, = new]</p>",
      },
    ],
  },
  {
    word: "Novel1",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Novel1 <strong class='noun'> n. </strong> Fictitious prose story of book length. [latin novus new]</p>",
      },
    ],
  },
  {
    word: "Novel2",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Novel2 <strong class='adj'> adj. </strong> Of a new kind or nature. [latin novus new]</p>",
      },
    ],
  },
  {
    word: "Novelette",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Novelette <strong class='noun'> n. </strong> Short novel.</p>",
      },
    ],
  },
  {
    word: "Novelist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Novelist <strong class='noun'> n. </strong> Writer of novels.</p>",
      },
    ],
  },
  {
    word: "Novella",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Novella <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> short novel or narrative story. [italian: related to *novel1]</p>",
      },
    ],
  },
  {
    word: "Novelty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Novelty <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 newness. 2 new or unusual thing or occurrence. 3 small toy or trinket. [related to *novel2]</p>",
      },
    ],
  },
  {
    word: "November",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>November <strong class='noun'> n. </strong> Eleventh month of the year. [latin novem nine, originally the 9th month of the roman year]</p>",
      },
    ],
  },
  {
    word: "Novena",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Novena <strong class='noun'> n. </strong> Rc ch. Devotion consisting of special prayers or services on nine successive days. [latin novem nine]</p>",
      },
    ],
  },
  {
    word: "Novice",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Novice <strong class='noun'> n. </strong> 1 a probationary member of a religious order. B new convert. 2 beginner. [latin novicius, from novus new]</p>",
      },
    ],
  },
  {
    word: "Noviciate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noviciate <strong class='noun'> n. </strong> (also novitiate) 1 period of being a novice. 2 religious novice. 3 novices' quarters. [medieval latin: related to *novice]</p>",
      },
    ],
  },
  {
    word: "Now",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Now <strong class='adv'> —adv. </strong> 1 at the present or mentioned time. 2 immediately (i must go now). 3 by this time. 4 under the present circumstances (i cannot now agree). 5 on this further occasion (what do you want now?). 6 in the immediate past (just now). 7 (esp. In a narrative) then, next (the police now arrived). 8 (without reference to time, giving various tones to a sentence) surely, i insist, i wonder, etc. (now what do you mean by that?; oh come now!). — <strong class='conj'> conj. </strong> (often foll. By that) as a consequence of the fact (now that i am older). <strong class='noun'> —n. </strong> This time; the present. for now until a later time (goodbye for now). Now and again (or then) from time to time; intermittently. [old english]</p>",
      },
    ],
  },
  {
    word: "Nowadays",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nowadays <strong class='adv'> —adv. </strong> At the present time or age; in these times. <strong class='noun'> —n. </strong> The present time.</p>",
      },
    ],
  },
  {
    word: "Nowhere",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nowhere <strong class='adv'> —adv. </strong> In or to no place. <strong class='pron'> — <strong class='pron'> pron. </strong> </strong> No place. get nowhere make no progress. Nowhere near not nearly. [old english]</p>",
      },
    ],
  },
  {
    word: "No-win",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>No-win <strong class='attrib'> attrib. </strong> <strong class='adj'> Adj. </strong> Of or designating a situation in which success is impossible.</p>",
      },
    ],
  },
  {
    word: "Nowt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nowt <strong class='noun'> n. </strong> Colloq. Or dial. Nothing. [from *nought]</p>",
      },
    ],
  },
  {
    word: "Noxious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Noxious <strong class='adj'> adj. </strong> Harmful, unwholesome. [latin noxa harm]</p>",
      },
    ],
  },
  {
    word: "Nozzle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nozzle <strong class='noun'> n. </strong> Spout on a hose etc. From which a jet issues. [diminutive of *nose]</p>",
      },
    ],
  },
  {
    word: "Np",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Np <strong class='prep'> symb. </strong> Neptunium.</p>",
      },
    ],
  },
  {
    word: "Nr.",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Nr. <strong class='abbr'> Abbr. </strong> Near.</p>",
      },
    ],
  },
  {
    word: "Ns",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ns <strong class='sing'> abbr. </strong> New style.</p>",
      },
    ],
  },
  {
    word: "Nspcc",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nspcc <strong class='sing'> abbr. </strong> National society for the prevention of cruelty to children.</p>",
      },
    ],
  },
  {
    word: "Nsw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nsw <strong class='sing'> abbr. </strong> New south wales.</p>",
      },
    ],
  },
  {
    word: "Nt",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nt <strong class='sing'> abbr. </strong> 1 new testament. 2 northern territory (of australia). 3 national trust.</p>",
      },
    ],
  },
  {
    word: "N't",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>N't <strong class='see'> see *</strong> not.</p>",
      },
    ],
  },
  {
    word: "Nth",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Nth <strong class='see'> see *</strong> n1.</p>",
      },
    ],
  },
  {
    word: "Nu",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nu <strong class='noun'> n. </strong> Thirteenth letter of the greek alphabet (n, n). [greek]</p>",
      },
    ],
  },
  {
    word: "Nuance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuance <strong class='noun'> n. </strong> Subtle shade of meaning, feeling, colour, etc. [latin nubes cloud]</p>",
      },
    ],
  },
  {
    word: "Nub",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nub <strong class='noun'> n. </strong> 1 point or gist (of a matter or story). 2 (also nubble) small lump, esp. Of coal. nubbly adj. [related to *knob]</p>",
      },
    ],
  },
  {
    word: "Nubile",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nubile <strong class='adj'> adj. </strong> (of a woman) marriageable or sexually attractive. nubility <strong class='noun'> n. </strong> [latin nubo become the wife of]</p>",
      },
    ],
  },
  {
    word: "Nuclear",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear <strong class='adj'> adj. </strong> 1 of, relating to, or constituting a nucleus. 2 using nuclear energy.</p>",
      },
    ],
  },
  {
    word: "Nuclear bomb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear bomb <strong class='noun'> n. </strong> Bomb using the release of energy by nuclear fission or fusion or both.</p>",
      },
    ],
  },
  {
    word: "Nuclear energy",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear energy <strong class='noun'> n. </strong> Energy obtained by nuclear fission or fusion.</p>",
      },
    ],
  },
  {
    word: "Nuclear family",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear family <strong class='noun'> n. </strong> A couple and their child or children.</p>",
      },
    ],
  },
  {
    word: "Nuclear fission",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear fission <strong class='noun'> n. </strong> Nuclear reaction in which a heavy nucleus splits spontaneously or on impact with another particle, with the release of energy.</p>",
      },
    ],
  },
  {
    word: "Nuclear fuel",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear fuel <strong class='noun'> n. </strong> Source of nuclear energy.</p>",
      },
    ],
  },
  {
    word: "Nuclear fusion",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear fusion <strong class='noun'> n. </strong> Nuclear reaction in which atomic nuclei of low atomic number fuse to form a heavier nucleus with the release of energy.</p>",
      },
    ],
  },
  {
    word: "Nuclear physics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear physics <strong class='n_pl'> n.pl. </strong> (treated as sing.) Physics of atomic nuclei.</p>",
      },
    ],
  },
  {
    word: "Nuclear power",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear power <strong class='noun'> n. </strong> 1 power generated by a nuclear reactor. 2 country that has nuclear weapons.</p>",
      },
    ],
  },
  {
    word: "Nuclear reactor",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear reactor <strong class='noun'> n. </strong> Device in which a nuclear fission chain reaction is used to produce energy.</p>",
      },
    ],
  },
  {
    word: "Nuclear weapon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuclear weapon <strong class='noun'> n. </strong> Weapon using the release of energy by nuclear fission or fusion or both.</p>",
      },
    ],
  },
  {
    word: "Nucleate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nucleate <strong class='adj'> —adj.</strong> Having a nucleus. —v. <strong class='ting'> (-ting) </strong> form or form into a nucleus. [latin: related to *nucleus]</p>",
      },
    ],
  },
  {
    word: "Nucleic acid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nucleic acid <strong class='noun'> n. </strong> Either of two complex organic molecules (dna and rna), present in all living cells.</p>",
      },
    ],
  },
  {
    word: "Nucleon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nucleon <strong class='noun'> n. </strong> Proton or neutron.</p>",
      },
    ],
  },
  {
    word: "Nucleus",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nucleus <strong class='noun'> n. </strong> (pl. Nuclei) 1 a central part or thing round which others are collected. B kernel of an aggregate or mass. 2 initial part meant to receive additions. 3 central core of an atom. 4 large dense part of a cell, containing the genetic material. [latin, = kernel, diminutive of nux nuc- nut]</p>",
      },
    ],
  },
  {
    word: "Nude",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nude <strong class='adj'> —adj.</strong> Naked, bare, unclothed. <strong class='noun'> —n. </strong> 1 painting, sculpture, etc. Of a nude human figure. 2 nude person. in the nude naked. nudity <strong class='noun'> n. </strong> [latin nudus]</p>",
      },
    ],
  },
  {
    word: "Nudge",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nudge <strong class='noun'> —v. </strong> (-ging) 1 prod gently with the elbow to attract attention. 2 push gradually. <strong class='noun'> —n. </strong> Prod; gentle push. [origin unknown]</p>",
      },
    ],
  },
  {
    word: "Nudist",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nudist <strong class='noun'> n. </strong> Person who advocates or practises going unclothed. nudism n.</p>",
      },
    ],
  },
  {
    word: "Nugatory",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nugatory <strong class='adj'> adj. </strong> 1 futile, trifling. 2 inoperative; not valid. [latin nugae jests]</p>",
      },
    ],
  },
  {
    word: "Nugget",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nugget <strong class='noun'> n. </strong> 1 lump of gold etc., as found in the earth. 2 lump of anything. 3 something valuable. [apparently from dial. Nug lump]</p>",
      },
    ],
  },
  {
    word: "Nuisance",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuisance <strong class='noun'> n. </strong> Person, thing, or circumstance causing trouble or annoyance. [french, = hurt, from nuire nuis- injure, from latin noceo to hurt]</p>",
      },
    ],
  },
  {
    word: "Nuke",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuke <strong class='colloq'> colloq. </strong> <strong class='noun'> —n. </strong> Nuclear weapon. —v. (-king) attack with nuclear weapons. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Null",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Null <strong class='adj'> adj. </strong> 1 (esp. Null and void) invalid. 2 non-existent. 3 without character or expression. nullity <strong class='noun'> n. </strong> [latin nullus none]</p>",
      },
    ],
  },
  {
    word: "Nullify",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nullify <strong class='verb'> v. </strong> (-ies, -ied) neutralize, invalidate. nullification n.</p>",
      },
    ],
  },
  {
    word: "Numb",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numb <strong class='adj'> —adj.</strong> (often foll. By with) deprived of feeling; paralysed. —v. 1 make numb. 2 stupefy, paralyse. numbness <strong class='noun'> n. </strong> [obsolete nome  <strong class='archaic_it_was'>past part.</strong> Of nim take: related to *nimble]</p>",
      },
    ],
  },
  {
    word: "Number",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Number <strong class='noun'> —n. </strong> 1 a arithmetical value representing a particular quantity. B word, symbol, or figure representing this. C arithmetical value showing position in a series (registration number). 2 (often foll. By of) total count or aggregate (the number of accidents has decreased). 3 numerical reckoning (the laws of number). 4 a (in sing. Or pl.) Quantity, amount (a large number of people; only in small numbers). B (a number of) several (of). C (in pl.) Numerical preponderance (force of numbers). 5 person or thing having a place in a series, esp. A single issue of a magazine, an item in a programme, etc. 6 company, collection, group (among our number). 7 <strong class='gram'>gram.</strong> A classification of words by their singular or plural forms. B such a form. —v. 1 include (i number you among my friends). 2 assign a number or numbers to. 3 amount to (a specified number). 4 count. one's days are numbered one does not have long to live. Have a person's number <strong class='colloq'> colloq. </strong> Understand a person's real motives, character, etc. One's number is up <strong class='colloq'> colloq. </strong> One is doomed to die soon. Without number innumerable. [latin numerus] <strong class='usage'> Usage:- </strong>in sense 4b, a number of is normally used with a plural verb: a number of problems remain.</p>",
      },
    ],
  },
  {
    word: "Number crunching",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Number crunching <strong class='noun'> n. </strong> Colloq. Process of making complex calculations.</p>",
      },
    ],
  },
  {
    word: "Numberless",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numberless <strong class='adj'> adj. </strong> Innumerable.</p>",
      },
    ],
  },
  {
    word: "Number one",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Number one <strong class='noun'> —n. </strong> Colloq. Oneself. <strong class='adj'> —adj.</strong> Most important (the number one priority).</p>",
      },
    ],
  },
  {
    word: "Number-plate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Number-plate <strong class='noun'> n. </strong> Plate on a vehicle showing its registration number.</p>",
      },
    ],
  },
  {
    word: "Numerable",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numerable <strong class='adj'> adj. </strong> That can be counted. [latin: related to *number]</p>",
      },
    ],
  },
  {
    word: "Numeral",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numeral <strong class='noun'> —n. </strong> Symbol or group of symbols denoting a number. <strong class='adj'> —adj.</strong> Of or denoting a number. [latin: related to *number]</p>",
      },
    ],
  },
  {
    word: "Numerate",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numerate <strong class='adj'> adj. </strong> Acquainted with the basic principles of mathematics. numeracy <strong class='noun'> n. </strong> [latin numerus number, after literate]</p>",
      },
    ],
  },
  {
    word: "Numeration",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numeration <strong class='noun'> n. </strong> 1 method or process of numbering. 2 calculation. [latin: related to *number]</p>",
      },
    ],
  },
  {
    word: "Numerator",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numerator <strong class='noun'> n. </strong> Number above the line in a vulgar fraction showing how many of the parts indicated by the denominator are taken (e.g. 2 in 2/3). [latin: related to *number]</p>",
      },
    ],
  },
  {
    word: "Numerical",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numerical <strong class='adj'> adj. </strong> Of or relating to a number or numbers. numerically <strong class='adv'> adv. </strong> [medieval latin: related to *number]</p>",
      },
    ],
  },
  {
    word: "Numerology",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numerology <strong class='noun'> n. </strong> The study of the supposed occult significance of numbers.</p>",
      },
    ],
  },
  {
    word: "Numerous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numerous <strong class='adj'> adj. </strong> 1 many. 2 consisting of many. [latin: related to *number]</p>",
      },
    ],
  },
  {
    word: "Numinous",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numinous <strong class='adj'> adj. </strong> 1 indicating the presence of a divinity. 2 spiritual, awe-inspiring. [latin numen deity]</p>",
      },
    ],
  },
  {
    word: "Numismatic",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numismatic <strong class='adj'> adj. </strong> Of or relating to coins or medals. [greek nomisma coin]</p>",
      },
    ],
  },
  {
    word: "Numismatics",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numismatics <strong class='n_pl'> n.pl. </strong> (usu. Treated as sing.) The study of coins or medals. numismatist n.</p>",
      },
    ],
  },
  {
    word: "Numskull",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Numskull <strong class='noun'> n. </strong> Stupid person. [from *numb]</p>",
      },
    ],
  },
  {
    word: "Nun",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nun <strong class='noun'> n. </strong> Member of a religious community of women living under certain vows. [latin nonna]</p>",
      },
    ],
  },
  {
    word: "Nuncio",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuncio <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> papal ambassador. [latin nuntius messenger]</p>",
      },
    ],
  },
  {
    word: "Nunnery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nunnery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> religious house of nuns.</p>",
      },
    ],
  },
  {
    word: "Nuptial",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuptial <strong class='adj'> —adj.</strong> Of marriage or weddings. <strong class='noun'> —n. </strong> (usu. In pl.) Wedding. [latin nubo nupt- wed]</p>",
      },
    ],
  },
  {
    word: "Nurd",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Nurd <strong class='var'> var. </strong> Of *nerd.</p>",
      },
    ],
  },
  {
    word: "Nurse",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nurse <strong class='noun'> —n. </strong> 1 person trained to care for the sick or infirm and assist doctors or dentists. 2 = *nursemaid. —v. <strong class='sing'>(-sing)</strong> 1 a work as a nurse. B attend to (a sick person). 2 feed or be fed at the breast. 3 hold or treat carefully. 4 a foster; promote the development of. B harbour (a grievance etc.). [latin: related to *nourish]</p>",
      },
    ],
  },
  {
    word: "Nurseling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nurseling <strong class='var'> var. </strong> Of *nursling.</p>",
      },
    ],
  },
  {
    word: "Nursemaid",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nursemaid <strong class='noun'> n. </strong> Woman in charge of a child or children.</p>",
      },
    ],
  },
  {
    word: "Nursery",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nursery <strong class='noun'> n. </strong><strong class='pl_ies'> (pl. -ies) </strong> 1 a a room or place equipped for young children. B = *day nursery. 2 place where plants are reared for sale. [probably anglo-french: related to *nurse]</p>",
      },
    ],
  },
  {
    word: "Nurseryman",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nurseryman <strong class='noun'> n. </strong> Owner of or worker in a plant nursery.</p>",
      },
    ],
  },
  {
    word: "Nursery rhyme",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nursery rhyme <strong class='noun'> n. </strong> Simple traditional song or rhyme for children.</p>",
      },
    ],
  },
  {
    word: "Nursery school",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nursery school <strong class='noun'> n. </strong> School for children between the ages of three and five.</p>",
      },
    ],
  },
  {
    word: "Nursery slopes",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nursery slopes <strong class='n_pl'> n.pl. </strong> Gentle slopes for novice skiers.</p>",
      },
    ],
  },
  {
    word: "Nursing home",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nursing home <strong class='noun'> n. </strong> Privately run hospital or home for invalids, old people, etc.</p>",
      },
    ],
  },
  {
    word: "Nursling",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nursling <strong class='noun'> n. </strong> (also nurseling) infant that is being suckled.</p>",
      },
    ],
  },
  {
    word: "Nurture",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nurture <strong class='noun'> —n. </strong> 1 bringing up, fostering care. 2 nourishment. —v. <strong class='adj'> (-ring) </strong> bring up; rear. [french: related to *nourish]</p>",
      },
    ],
  },
  {
    word: "Nut",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nut <strong class='noun'> n. </strong> 1 a fruit consisting of a hard or tough shell around an edible kernel. B this kernel. 2 pod containing hard seeds. 3 small usu. Hexagonal flat piece of metal etc. With a threaded hole through it for screwing on the end of a bolt to secure it. 4 slang person's head. 5 slang crazy or eccentric person. 6 small lump (of coal etc.). 7 (in pl.) Coarse slang testicles. do one's nut slang be extremely angry. [old english]</p>",
      },
    ],
  },
  {
    word: "Nutcase",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutcase <strong class='noun'> n. </strong> Slang crazy person.</p>",
      },
    ],
  },
  {
    word: "Nutcracker",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutcracker <strong class='noun'> n. </strong> (usu. In pl.) Device for cracking nuts.</p>",
      },
    ],
  },
  {
    word: "Nuthatch",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuthatch <strong class='noun'> n. </strong> Small bird which climbs up and down tree-trunks.</p>",
      },
    ],
  },
  {
    word: "Nutmeg",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutmeg <strong class='noun'> n. </strong> 1 hard aromatic seed used as a spice and in medicine. 2 e. Indian tree bearing this. [french nois nut, mugue *musk]</p>",
      },
    ],
  },
  {
    word: "Nutria",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutria <strong class='noun'> n. </strong> Coypu fur. [spanish, = otter]</p>",
      },
    ],
  },
  {
    word: "Nutrient",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutrient <strong class='noun'> —n. </strong> Substance that provides essential nourishment. <strong class='adj'> —adj.</strong> Serving as or providing nourishment. [latin nutrio nourish]</p>",
      },
    ],
  },
  {
    word: "Nutriment",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutriment <strong class='noun'> n. </strong> 1 nourishing food. 2 intellectual or artistic etc. Nourishment.</p>",
      },
    ],
  },
  {
    word: "Nutrition",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutrition <strong class='noun'> n. </strong> Food, nourishment. nutritional adj. Nutritionist n.</p>",
      },
    ],
  },
  {
    word: "Nutritious",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutritious <strong class='adj'> adj. </strong> Efficient as food.</p>",
      },
    ],
  },
  {
    word: "Nutritive",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutritive <strong class='adj'> adj. </strong> 1 of nutrition. 2 nutritious.</p>",
      },
    ],
  },
  {
    word: "Nuts",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuts <strong class='predic'> predic. </strong> <strong class='adj'> Adj. </strong> Slang crazy, mad. be nuts about (or on) <strong class='colloq'> colloq. </strong> Be very fond of. [pl. Of *nut]</p>",
      },
    ],
  },
  {
    word: "Nuts and bolts",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuts and bolts <strong class='n_pl'> n.pl. </strong> Colloq. Practical details.</p>",
      },
    ],
  },
  {
    word: "Nutshell",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutshell <strong class='noun'> n. </strong> Hard exterior covering of a nut. in a nutshell in a few words.</p>",
      },
    ],
  },
  {
    word: "Nutter",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutter <strong class='noun'> n. </strong> Slang crazy person.</p>",
      },
    ],
  },
  {
    word: "Nutty",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nutty <strong class='adj'> adj. </strong> (-ier, -iest) 1 a full of nuts. B tasting like nuts. 2 slang crazy. nuttiness n.</p>",
      },
    ],
  },
  {
    word: "Nux vomica",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nux vomica <strong class='noun'> n. </strong> 1 e. Indian tree. 2 seeds of this tree, containing strychnine. [latin, = abscess nut]</p>",
      },
    ],
  },
  {
    word: "Nuzzle",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nuzzle <strong class='verb'> v. </strong> (-ling) 1 prod or rub gently with the nose. 2 (foll. By into, against, up to) press the nose gently. 3 nestle; lie snug. [from *nose]</p>",
      },
    ],
  },
  {
    word: "Nw",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nw <strong class='sing'> abbr. </strong> 1 north-west. 2 north-western.</p>",
      },
    ],
  },
  {
    word: "Ny",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Ny <strong class='sing'> abbr. </strong> Us new york.</p>",
      },
    ],
  },
  {
    word: "Nylon",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nylon <strong class='noun'> n. </strong> 1 tough light elastic synthetic fibre. 2 nylon fabric. 3 (in pl.) Stockings of nylon. [invented word]</p>",
      },
    ],
  },
  {
    word: "Nymph",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nymph <strong class='noun'> n. </strong> 1 mythological semi-divine spirit regarded as a maiden and associated with an aspect of nature, esp. Rivers and woods. 2 poet. Beautiful young woman. 3 immature form of some insects. [greek numphe nymph, bride]</p>",
      },
    ],
  },
  {
    word: "Nympho",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nympho <strong class='noun'> n. </strong> <strong class='pl_s'>  <strong class='pl_s'>(pl. -s)</strong> </strong> <strong class='colloq'> colloq. </strong> Nymphomaniac. [abbreviation]</p>",
      },
    ],
  },
  {
    word: "Nymphomania",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning:
          "<p>Nymphomania <strong class='noun'> n. </strong> Excessive sexual desire in a woman. nymphomaniac <strong class='noun'> n. </strong> & adj. [from *nymph, *-mania]</p>",
      },
    ],
  },
  {
    word: "Nz",
    language: [
      {
        language: "en",
        title: "Engish",
        meaning: "<p>Nz <strong class='sing'> abbr. </strong> New zealand.</p>",
      },
    ],
  },
];

export const alphabet_N_Hindi = [
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एन 1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एन 2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एन 3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाफी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनएबी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाचो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पतन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नावस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नफ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nag1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nag2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नैयाड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाखून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाखून घिसनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेल पॉलिश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनाड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नंगा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नग्न आंख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नख़रेबाज़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्मतिथि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाम छोड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेनाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>यानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाम-प्लेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हमनाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नैंसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नैनो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nap1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nap2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nap3</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नापलम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>डब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मिट्टी का तेल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेफ़थलीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नैपकिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रूमाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहंकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नार्किसस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नींद लानेवाली औषधि से होनेवाली बेहोशी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मादक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नर्क स्लैंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बयान करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कथा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संकीर्ण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संकरी नाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संकीर्ण सोच वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाउल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नासा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाक का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नवजात शिशु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नस्टाशयम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत खराब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काम का बुरा टुकड़ा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नट।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जन्म का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रगान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रीय पाठ्यक्रम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रीय ऋण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेशनल फ्रंट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रीय ग्रिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रीय स्वास्थ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्टरिय बीमा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रीयता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रीय बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रीय उद्यान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रीय सेवा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राष्ट्रव्यापी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देशी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क्रिसमस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाटो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुनगुनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नटजैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वच्छ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राकृतिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राकृतिक गैस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राकृतिक इतिहास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकृतिवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकृतिवादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घुला-मिला लेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राकृतिक कानून</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सहज रूप में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राकृतिक संख्या</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राकृतिक संसाधन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राकृतिक विज्ञान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्राकृतिक चयन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकृति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वभाव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आरक्षित प्रकृति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रकृति के निशान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्वाभाविकता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शून्य पुरातन या साहित्यिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बदमाश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जी मिचलाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घृणा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वमनजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समुद्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समुद्री मील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉटिलस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नवल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nave1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nave2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाभि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाभि नारंगी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जहाज़-रानी का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेविगेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मार्गदर्शन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेलदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौसेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्वीकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नासरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नायब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नायब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनसीबी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनसीओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>राकांपा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निएंडरथल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नियपोलिटन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पास ही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूर्व के पास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लगभग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निशाना ख़ाली होना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकटपक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकट-दृष्टि वाले</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकटता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>साफ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीथ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाब्युला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्पष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़रूरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आवश्यक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जरूरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दरिद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़रूरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गरदन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गर्दन और गर्दन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कालर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुलूबंद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गले का हार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गर्दन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेकटाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेक्रो-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काला जादू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शव-मैथुन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>क़ब्रिस्तान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गल जाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अमृत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>nectarine</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>NEDC</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उर्फ़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जरुरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़रूरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीडलकॉर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुई सूत्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सीवन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़रूरत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दरिद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कदापि नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ख़राब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुटिल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नकारात्मक।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निगेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नकारात्मक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वास्तविकता का इनकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपेक्षा करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नगणि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लापरवाही</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नगण्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बातचीत योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मोल-भाव करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीग्रो स्री</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीरसता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीग्रो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीग्रोइड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिनहिनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पड़ोसी (ब्रिट। पड़ोसी)</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अड़ोस-पड़ोस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोस्ताना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पड़ोसी (हम पड़ोसी)</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अड़ोस-पड़ोस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पड़ोस की घड़ी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोस्ताना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेल्सन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निमेटोड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेम। कोन।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेमसिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नियोक्लासिज्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Neodymium</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निओलिथिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निओलगिज़्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीयन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौसिखिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भांजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुरदे का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेफ्रैटिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनई प्लस अल्ट्रा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भाई-भतीजावाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नैप्टुनियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पढ़ाकू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेरीड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चेता कोष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंत्रिका-केंद्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नर्व गैस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हलका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नर्वस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बे चै न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंत्रिका अवरोध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंत्रिका तंत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़ोरदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनभिज्ञा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-नेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घोंसला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रलोभन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पनाह देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पनाह देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Net1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>NET2 (भी नेट)</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेटबॉल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निचले</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीटर क्षेत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शुद्ध लाभ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिच्छू बूटी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पित्ती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेटवर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंत्रिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंत्रिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्युरैटिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूरो-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तंत्रिका-विज्ञान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूरॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्युरोसिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूरोसर्जरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्युरोटिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नपुंसक लिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तटस्थ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेअसर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्युट्रीनो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूट्रॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूट्रॉन बम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कभी नहीँ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कदापि नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कभी नहीं, कभी नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फिर भी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नेवस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया जमाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया आगमन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नवजात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया झाड़ू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थम्बा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फै़शनवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नए नए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नवविवाहित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया गणित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया चाँद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नए आलू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाचार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>newsagent</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाचार प्रसारण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रेडियो-अनाउन्सर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाचार सम्मेलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूज़फ्लैश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाचार पत्रिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखबार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखरोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखबारी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूज़रीडर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखबार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाचार -पत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाचार-पत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाचार स्टैंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया तारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नई शैली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाचार-पत्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>समाचार योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कौतूहलजनक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नए करार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूटन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया शहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नई लहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया संसार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया साल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नए साल का दिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नववर्ष की पूर्वसंध्या</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगली सबसे अच्छा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगले घर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किन एन। एस। और</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अगली दुनिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंधन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एन एच एस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नियासिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुतरना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महत्वपूर्ण व्यक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अच्छा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुनुकमिज़ाजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ताक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>छेद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकेल चांदी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकेल स्टील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>खिलखिलाकर हंसना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निक नैक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपनाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकोटीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निकोटिनिक एसिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झपकना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>झिल्ली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भांजी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नफ़र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गंधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कंजूस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थोड़े में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चाकलेट का रंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुच्छ चीज़ करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nigh Adv।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाईट बर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात की टोपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट क्लब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे पहनने की रात की क़मीज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सांझ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइटगाउन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुलबुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नैटजा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात का जीवन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात का चिराग़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात भर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हर रात को</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुरा सपना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात सुरक्षित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात का स्कूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नैटशाइड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीचे पहनने की रात की क़मीज़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइटस्पॉट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात का समय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात का पहरेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइलीज़्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>-निक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निक्केई सूचकांक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चतुर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चमक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फुंसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौ दिन का आश्चर्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौ बार का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइनपिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उन्नीस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नब्बे</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौवां</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइओबियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जापानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nip1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>NIP2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जापानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>चूची</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पालेदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्वाण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निसेन हट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लीख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शोरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनआईटी पिकिंग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्रेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शोरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्रिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्रिक एसिड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्राइड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्रिफाई करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्राट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्रो-</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्रोजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्रोग्लिसरीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्रोग्लिसरीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाइट्रस ऑक्साइड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुनियादी तथ्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मूर्ख</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नानी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नं .1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>NO2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नहीं।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नं .1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>NO2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nob1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Nob2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नो बॉल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अपराधी को पकड़ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉबेलियम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोबेल पुरुस्कार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुलीनता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>महान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोबल गैस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रईस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ताकत के साथ ज़िम्मेदारी आती है</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुलीन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोई नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोई दावा बोनस नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>रात का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोक्टाँन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सिर हिलाकर सहमति देना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>घिसना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भोला-भाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गांठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोएल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोगिन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नही जाओ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शोर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नीरव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दुर्गंध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोलाहलयुक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बंजारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>किसी की भूमि नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपनाम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोई आदमी नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नामपद्धति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाममात्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोमिनलिज़्म</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंकित मूल्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नामजद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नियुक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नामांकित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नव्वे साल के वायु आदमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनाक्रमण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉनगोन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर - मादक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गुट निरपेक्ष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>युद्ध में शामिल न होनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थायी रूप से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्थायी रूप से-शब्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेपरवाह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉन-कॉम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अयोद्धा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर कमीशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉन कम्पोज़ मेंटिस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नांकंडक्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संप्रदायवादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अवज्ञा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर अंशदायी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>असहयोग</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वर्णनातीत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर वाली</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर ड्राइवर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोई भी नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>तुच्छता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाउंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़रा सी बात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहरहाल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निराशाजनक घटना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न के बराबर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर मेद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अलौह</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर-काल्पनिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर ज्वलनशील</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहस्तक्षेप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बीच में न आना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर - सदस्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर परमाणु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पालन ​​​​न करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परिचालन नहीं या काम में नहीं लिया जा रहा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अतुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्दलीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निर्दलीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भुगतान न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अस्त -व्यस्त हो जाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर लाभ कमाने</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनुशासन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अनिवासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहिंसवाद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नहीं लौटनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर-संप्रदायवादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बकवास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्रासंगिक जवाब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फिसलन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धूम्रपान न करने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर विशेषज्ञ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर विशिष्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर मानक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्टार्ट न करनेवाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न चिपकने वाला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बिना रुके</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर-नौकायन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर-विषाक्त</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहस्तांतरणीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर यू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर संघ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर मौखिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अहिंस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गैर मतदान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अश्वेत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Noodle1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Noodle2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नुक्कड़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोपहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दोपहर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कोई नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फंदा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न ही 'n। adj। और</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉर्डिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोरफोक जैकेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आदर्श</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामान्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामान्य वितरण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामान्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सामान्य रूप से</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉर्मन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉर्मन विजय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉर्मन फ्रेंच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मानक का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नार्वेजियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर अमेरिकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर की ओर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर देश</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईशान कोण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ईशान कोण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पूर्वोत्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर-पूर्वी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर-ईस्टर्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तरवासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तरी गोलार्द्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तरी लाइट्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाथमान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर-उत्तर-पूर्व</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर-उत्तर पश्चिम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तरी ध्रुव</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर सितारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर की ओर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर पश्चिम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर पश्चिम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉर्थवेस्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर-पश्चिमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उत्तर-पश्चिमी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नार्वेजियन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शराब का पात्र</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोस।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोकबैंड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नकसीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ग़ोता मारना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>फूलों का गुच्छ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाक-टू-पूंछ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नशे में</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उदासी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नथना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोस्ट्रम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुगंधित</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुगंधित पार्कर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>प्रसिद्ध</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोटरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोटेशन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निशान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>टिप्पणी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>स्मरण पुस्तक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोटकैस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोटलेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लिखने का पन्ना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ध्यान देने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुछ भी तो नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ध्यान देने योग्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचना पट्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दर्ज करना पड़ा हुआ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूचित करें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>धारणा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>काल्पनिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुख्यात</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बावजूद</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नूगा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गाठें और चौराहें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संज्ञा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लालन-पालन करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पोषण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बुद्धि</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नए अमीर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नालिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नवम्बर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नया तारा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Novel1</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>Novel2</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>लघु उपन्यास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपन्यासकार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>उपन्यास</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नवीनता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नवंबर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोवेना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नौसिखिए</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोविसिएट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अब</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>आजकल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कहीं भी नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभी इसमें</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अभी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हानिकारक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनपी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनआर।</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनएस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनएसपीसीसी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एनएसडब्ल्यू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नोक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नहीं</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>वां</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यू</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अति सूक्ष्म अंतर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>थक्का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दांपत्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाभिकीय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु बम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु ऊर्जा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>एकल परिवार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु विखंडन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु ईंधन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु संलयन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु भौतिकी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु शक्ति</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु रिऐक्टर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु हथियार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाभिक बनाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूक्लिक अम्ल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाभिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाभिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नंगा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कुहनी से हलका धक्का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यडिस्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हेच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सोने का डला</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बाधा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>परमाणु</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शून्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मंसूख़</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सुन्न</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संख्या</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>हिसाब लगाना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बेशुमार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नंबर एक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नंबर प्लेट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संख्येय</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गिनना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>गिनती</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मीटर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूमेरिकल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अंकज्योतिष</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बहुत</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दिव्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मुद्रा संग्रहण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूमिज़माटिक्स</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नंस्कुल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>मठवासिनी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नानशिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>ज़नाना मठ</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शादी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नूर्ड</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>देखभाल करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शागिर्द</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>दाई</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नर्सरी</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पौधा-घरदार</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>बच्चों की कविता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नर्सरी स्कूल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नर्सरी ढलान</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नर्सिंग होम</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>शिष्य</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पालन ​​- पोषण करना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>कड़े छिलके वाला फल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नटकेस</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सरौता</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नाटहेच</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>जायफल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूट्रिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुष्टिकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>भोजन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पोषण</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पौष्टिक</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पुष्टिकर</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>पागल</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नट और बोल्ट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>संक्षेप</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखरोट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अखरोट के स्वाद का</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नक्स वोमिका</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>सूंघना</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नॉट</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूयॉर्क</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>नायलॉन</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>अप्सरा</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निंफ़ो</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>निम्फ़ोमानिया</p>",
  },
  {
    language: "hi",
    title: "Hindi",
    meaning: "<p>न्यूजीलैंड</p>",
  },
];