const allLng = {
  text: [
    {
      language: "Afrikaans",
      code: "af",
    },
    {
      language: "Albanian",
      code: "sq",
    },
    {
      language: "Amharic",
      code: "am",
    },
    {
      language: "Arabic",
      code: "ar",
    },
    {
      language: "Armenian",
      code: "hy",
    },
    {
      language: "Azerbaijani",
      code: "az",
    },
    {
      language: "Basque",
      code: "eu",
    },
    {
      language: "Belarusian",
      code: "be",
    },
    {
      language: "Bengali",
      code: "bn",
    },
    {
      language: "Bosnian",
      code: "bs",
    },
    {
      language: "Bulgarian",
      code: "bg",
    },
    {
      language: "Catalan",
      code: "ca",
    },
    {
      language: "Cebuano",
      code: "ceb",
    },
    {
      language: "Chinese (Simplified)",
      code: "zh-CN",
    },
    {
      language: "Chinese (Traditional)",
      code: "zh-TW",
    },
    {
      language: "Corsican",
      code: "co",
    },
    {
      language: "Croatian",
      code: "hr",
    },
    {
      language: "Czech",
      code: "cs",
    },
    {
      language: "Danish",
      code: "da",
    },
    {
      language: "Dutch",
      code: "nl",
    },
    {
      language: "English",
      code: "en",
    },
    {
      language: "Esperanto",
      code: "eo",
    },
    {
      language: "Estonian",
      code: "et",
    },
    {
      language: "Finnish",
      code: "fi",
    },
    {
      language: "French",
      code: "fr",
    },
    {
      language: "Frisian",
      code: "fy",
    },
    {
      language: "Galician",
      code: "gl",
    },
    {
      language: "Georgian",
      code: "ka",
    },
    {
      language: "German",
      code: "de",
    },
    {
      language: "Greek",
      code: "el",
    },
    {
      language: "Gujarati",
      code: "gu",
    },
    {
      language: "Haitian Creole",
      code: "ht",
    },
    {
      language: "Hausa",
      code: "ha",
    },
    {
      language: "Hawaiian",
      code: "haw",
    },
    {
      language: "Hebrew",
      code: "iw",
    },
    {
      language: "Hindi",
      code: "hi",
    },
    {
      language: "Hmong",
      code: "hmn",
    },
    {
      language: "Hungarian",
      code: "hu",
    },
    {
      language: "Icelandic",
      code: "is",
    },
    {
      language: "Igbo",
      code: "ig",
    },
    {
      language: "Indonesian",
      code: "id",
    },
    {
      language: "Irish",
      code: "ga",
    },
    {
      language: "Italian",
      code: "it",
    },
    {
      language: "Japanese",
      code: "ja",
    },
    {
      language: "Javanese",
      code: "jw",
    },
    {
      language: "Kannada",
      code: "kn",
    },
    {
      language: "Kazakh",
      code: "kk",
    },
    {
      language: "Khmer",
      code: "km",
    },
    {
      language: "Korean",
      code: "ko",
    },
    {
      language: "Kurdish",
      code: "ku",
    },
    {
      language: "Kyrgyz",
      code: "ky",
    },
    {
      language: "Lao",
      code: "lo",
    },
    {
      language: "Latin",
      code: "la",
    },
    {
      language: "Latvian",
      code: "lv",
    },
    {
      language: "Lithuanian",
      code: "lt",
    },
    {
      language: "Luxembourgish",
      code: "lb",
    },
    {
      language: "Macedonian",
      code: "mk",
    },
    {
      language: "Malagasy",
      code: "mg",
    },
    {
      language: "Malay",
      code: "ms",
    },
    {
      language: "Malayalam",
      code: "ml",
    },
    {
      language: "Maltese",
      code: "mt",
    },
    {
      language: "Maori",
      code: "mi",
    },
    {
      language: "Marathi",
      code: "mr",
    },
    {
      language: "Mongolian",
      code: "mn",
    },
    {
      language: "Myanmar (Burmese)",
      code: "my",
    },
    {
      language: "Nepali",
      code: "ne",
    },
    {
      language: "Norwegian",
      code: "no",
    },
    {
      language: "Nyanja (Chichewa)",
      code: "ny",
    },
    {
      language: "Pashto",
      code: "ps",
    },
    {
      language: "Persian",
      code: "fa",
    },
    {
      language: "Polish",
      code: "pl",
    },
    {
      language: "Portuguese (Portugal, Brazil)",
      code: "pt",
    },
    {
      language: "Punjabi",
      code: "pa",
    },
    {
      language: "Romanian",
      code: "ro",
    },
    {
      language: "Russian",
      code: "ru",
    },
    {
      language: "Samoan",
      code: "sm",
    },
    {
      language: "Scots Gaelic",
      code: "gd",
    },
    {
      language: "Serbian",
      code: "sr",
    },
    {
      language: "Sesotho",
      code: "st",
    },
    {
      language: "Shona",
      code: "sn",
    },
    {
      language: "Sindhi",
      code: "sd",
    },
    {
      language: "Sinhala (Sinhalese)",
      code: "si",
    },
    {
      language: "Slovak",
      code: "sk",
    },
    {
      language: "Slovenian",
      code: "sl",
    },
    {
      language: "Somali",
      code: "so",
    },
    {
      language: "Spanish",
      code: "es",
    },
    {
      language: "Sundanese",
      code: "su",
    },
    {
      language: "Swahili",
      code: "sw",
    },
    {
      language: "Swedish",
      code: "sv",
    },
    {
      language: "Tagalog (Filipino)",
      code: "tl",
    },
    {
      language: "Tajik",
      code: "tg",
    },
    {
      language: "Tamil",
      code: "ta",
    },
    {
      language: "Telugu",
      code: "te",
    },
    {
      language: "Thai",
      code: "th",
    },
    {
      language: "Turkish",
      code: "tr",
    },
    {
      language: "Ukrainian",
      code: "uk",
    },
    {
      language: "Urdu",
      code: "ur",
    },
    {
      language: "Uzbek",
      code: "uz",
    },
    {
      language: "Vietnamese",
      code: "vi",
    },
    {
      language: "Welsh",
      code: "cy",
    },
    {
      language: "Xhosa",
      code: "xh",
    },
    {
      language: "Yiddish",
      code: "yi",
    },
    {
      language: "Yoruba",
      code: "yo",
    },
    {
      language: "Zulu",
      code: "zu",
    },
  ],
  tts: [
    {
      language: "Afrikaans (South Africa)",
      code: "af-ZA",
    },
    {
      language: "Amharic (Ethiopia)",
      code: "am-ET",
    },
    {
      language: "Armenian (Armenia)",
      code: "hy-AM",
    },
    {
      language: "Azerbaijani (Azerbaijan)",
      code: "az-AZ",
    },
    {
      language: "Indonesian (Indonesia)",
      code: "id-ID",
    },
    {
      language: "Malay (Malaysia)",
      code: "ms-MY",
    },
    {
      language: "Bengali (Bangladesh)",
      code: "bn-BD",
    },
    {
      language: "Bengali (India)",
      code: "bn-IN",
    },
    {
      language: "Catalan (Spain)",
      code: "ca-ES",
    },
    {
      language: "Czech (Czech Republic)",
      code: "cs-CZ",
    },
    {
      language: "Danish (Denmark)",
      code: "da-DK",
    },
    {
      language: "German (Germany)",
      code: "de-DE",
    },
    {
      language: "English (Australia)",
      code: "en-AU",
    },
    {
      language: "English (Canada)",
      code: "en-CA",
    },
    {
      language: "English (Ghana)",
      code: "en-GH",
    },
    {
      language: "English (United Kingdom)",
      code: "en-GB",
    },
    {
      language: "English (India)",
      code: "en-IN",
    },
    {
      language: "English (Ireland)",
      code: "en-IE",
    },
    {
      language: "English (Kenya)",
      code: "en-KE",
    },
    {
      language: "English (New Zealand)",
      code: "en-NZ",
    },
    {
      language: "English (Nigeria)",
      code: "en-NG",
    },
    {
      language: "English (Philippines)",
      code: "en-PH",
    },
    {
      language: "English (Singapore)",
      code: "en-SG",
    },
    {
      language: "English (South Africa)",
      code: "en-ZA",
    },
    {
      language: "English (Tanzania)",
      code: "en-TZ",
    },
    {
      language: "English (United States)",
      code: "en-US",
    },
    {
      language: "Spanish (Argentina)",
      code: "es-AR",
    },
    {
      language: "Spanish (Bolivia)",
      code: "es-BO",
    },
    {
      language: "Spanish (Chile)",
      code: "es-CL",
    },
    {
      language: "Spanish (Colombia)",
      code: "es-CO",
    },
    {
      language: "Spanish (Costa Rica)",
      code: "es-CR",
    },
    {
      language: "Spanish (Ecuador)",
      code: "es-EC",
    },
    {
      language: "Spanish (El Salvador)",
      code: "es-SV",
    },
    {
      language: "Spanish (Spain)",
      code: "es-ES",
    },
    {
      language: "Spanish (United States)",
      code: "es-US",
    },
    {
      language: "Spanish (Guatemala)",
      code: "es-GT",
    },
    {
      language: "Spanish (Honduras)",
      code: "es-HN",
    },
    {
      language: "Spanish (Mexico)",
      code: "es-MX",
    },
    {
      language: "Spanish (Nicaragua)",
      code: "es-NI",
    },
    {
      language: "Spanish (Panama)",
      code: "es-PA",
    },
    {
      language: "Spanish (Paraguay)",
      code: "es-PY",
    },
    {
      language: "Spanish (Peru)",
      code: "es-PE",
    },
    {
      language: "Spanish (Puerto Rico)",
      code: "es-PR",
    },
    {
      language: "Spanish (Dominican Republic)",
      code: "es-DO",
    },
    {
      language: "Spanish (Uruguay)",
      code: "es-UY",
    },
    {
      language: "Spanish (Venezuela)",
      code: "es-VE",
    },
    {
      language: "Basque (Spain)",
      code: "eu-ES",
    },
    {
      language: "Filipino (Philippines)",
      code: "fil-PH",
    },
    {
      language: "French (Canada)",
      code: "fr-CA",
    },
    {
      language: "French (France)",
      code: "fr-FR",
    },
    {
      language: "Galician (Spain)",
      code: "gl-ES",
    },
    {
      language: "Georgian (Georgia)",
      code: "ka-GE",
    },
    {
      language: "Gujarati (India)",
      code: "gu-IN",
    },
    {
      language: "Croatian (Croatia)",
      code: "hr-HR",
    },
    {
      language: "Zulu (South Africa)",
      code: "zu-ZA",
    },
    {
      language: "Icelandic (Iceland)",
      code: "is-IS",
    },
    {
      language: "Italian (Italy)",
      code: "it-IT",
    },
    {
      language: "Javanese (Indonesia)",
      code: "jv-ID",
    },
    {
      language: "Kannada (India)",
      code: "kn-IN",
    },
    {
      language: "Khmer (Cambodia)",
      code: "km-KH",
    },
    {
      language: "Lao (Laos)",
      code: "lo-LA",
    },
    {
      language: "Latvian (Latvia)",
      code: "lv-LV",
    },
    {
      language: "Lithuanian (Lithuania)",
      code: "lt-LT",
    },
    {
      language: "Hungarian (Hungary)",
      code: "hu-HU",
    },
    {
      language: "Malayalam (India)",
      code: "ml-IN",
    },
    {
      language: "Marathi (India)",
      code: "mr-IN",
    },
    {
      language: "Dutch (Netherlands)",
      code: "nl-NL",
    },
    {
      language: "Nepali (Nepal)",
      code: "ne-NP",
    },
    {
      language: "Norwegian Bokmål (Norway)",
      code: "nb-NO",
    },
    {
      language: "Polish (Poland)",
      code: "pl-PL",
    },
    {
      language: "Portuguese (Brazil)",
      code: "pt-BR",
    },
    {
      language: "Portuguese (Portugal)",
      code: "pt-PT",
    },
    {
      language: "Romanian (Romania)",
      code: "ro-RO",
    },
    {
      language: "Sinhala (Sri Lanka)",
      code: "si-LK",
    },
    {
      language: "Slovak (Slovakia)",
      code: "sk-SK",
    },
    {
      language: "Slovenian (Slovenia)",
      code: "sl-SI",
    },
    {
      language: "Sundanese (Indonesia)",
      code: "su-ID",
    },
    {
      language: "Swahili (Tanzania)",
      code: "sw-TZ",
    },
    {
      language: "Swahili (Kenya)",
      code: "sw-KE",
    },
    {
      language: "Finnish (Finland)",
      code: "fi-FI",
    },
    {
      language: "Swedish (Sweden)",
      code: "sv-SE",
    },
    {
      language: "Tamil (India)",
      code: "ta-IN",
    },
    {
      language: "Tamil (Singapore)",
      code: "ta-SG",
    },
    {
      language: "Tamil (Sri Lanka)",
      code: "ta-LK",
    },
    {
      language: "Tamil (Malaysia)",
      code: "ta-MY",
    },
    {
      language: "Telugu (India)",
      code: "te-IN",
    },
    {
      language: "Vietnamese (Vietnam)",
      code: "vi-VN",
    },
    {
      language: "Turkish (Turkey)",
      code: "tr-TR",
    },
    {
      language: "Urdu (Pakistan)",
      code: "ur-PK",
    },
    {
      language: "Urdu (India)",
      code: "ur-IN",
    },
    {
      language: "Greek (Greece)",
      code: "el-GR",
    },
    {
      language: "Bulgarian (Bulgaria)",
      code: "bg-BG",
    },
    {
      language: "Russian (Russia)",
      code: "ru-RU",
    },
    {
      language: "Serbian (Serbia)",
      code: "sr-RS",
    },
    {
      language: "Ukrainian (Ukraine)",
      code: "uk-UA",
    },
    {
      language: "Hebrew (Israel)",
      code: "he-IL",
    },
    {
      language: "Arabic (Israel)",
      code: "ar-IL",
    },
    {
      language: "Arabic (Jordan)",
      code: "ar-JO",
    },
    {
      language: "Arabic (United Arab Emirates)",
      code: "ar-AE",
    },
    {
      language: "Arabic (Bahrain)",
      code: "ar-BH",
    },
    {
      language: "Arabic (Algeria)",
      code: "ar-DZ",
    },
    {
      language: "Arabic (Saudi Arabia)",
      code: "ar-SA",
    },
    {
      language: "Arabic (Iraq)",
      code: "ar-IQ",
    },
    {
      language: "Arabic (Kuwait)",
      code: "ar-KW",
    },
    {
      language: "Arabic (Morocco)",
      code: "ar-MA",
    },
    {
      language: "Arabic (Tunisia)",
      code: "ar-TN",
    },
    {
      language: "Arabic (Oman)",
      code: "ar-OM",
    },
    {
      language: "Arabic (State of Palestine)",
      code: "ar-PS",
    },
    {
      language: "Arabic (Qatar)",
      code: "ar-QA",
    },
    {
      language: "Arabic (Lebanon)",
      code: "ar-LB",
    },
    {
      language: "Arabic (Egypt)",
      code: "ar-EG",
    },
    {
      language: "Persian (Iran)",
      code: "fa-IR",
    },
    {
      language: "Hindi (India)",
      code: "hi-IN",
    },
    {
      language: "Thai (Thailand)",
      code: "th-TH",
    },
    {
      language: "Korean (South Korea)",
      code: "ko-KR",
    },
    {
      language: "Chinese, Mandarin (Traditional, Taiwan)",
      code: "zh-TW",
    },
    {
      language: "Chinese, Cantonese (Traditional, Hong Kong)",
      code: "yue-Hant-HK",
    },
    {
      language: "Japanese (Japan)",
      code: "ja-JP",
    },
    {
      language: "Chinese, Mandarin (Simplified, Hong Kong)",
      code: "zh-HK",
    },
    {
      language: "Chinese, Mandarin (Simplified, China)",
      code: "zh",
    },
  ],
  words: [
    {
      word: "value",
      meaning: "Price of things",
    },
    {
      word: "A",
      meaning:
        "- prefix => (also an- before a vowel sound) not, without (amoral). [greek]",
    },
    {
      word: "Aa",
      meaning:
        "Abbr. => 1 automobile association. 2 alcoholics anonymous. 3 anti-aircraft.",
    },
    {
      word: "Aardvark",
      meaning:
        "n. => Mammal with a tubular snout and a long tongue, feeding on termites. [afrikaans]",
    },
    { word: "Ab", meaning: "- prefix => off, away, from (abduct). [latin]" },
    {
      word: "Aback",
      meaning: "adv. => take aback surprise, disconcert. [old english",
    },
    {
      word: "Abacus",
      meaning:
        "n. => (pl. -cuses) 1 frame with wires along which beads are slid for calculating. 2 archit. Flat slab on top of a capital. [latin from greek from hebrew]",
    },
    {
      word: "Abaft",
      meaning:
        "naut. —adv. => In the stern half of a ship. —prep. Nearer the stern than. [from *a2, -baft",
    },
    {
      word: "Abandon",
      meaning:
        "-v. => 1 give up. 2 forsake, desert. 3 (often foll. By to; often refl.) Yield to a passion, another's control, etc. —n. Freedom from inhibitions. abandonment",
    },
    {
      word: "Abandoned",
      meaning: "adj. => 1 deserted, forsaken. 2 unrestrained, profligate.",
    },
    {
      word: "Abase",
      meaning: "v. => (-sing) (also refl.) Humiliate, degrade. abasement",
    },
    {
      word: "Abashed",
      meaning:
        "predic. Adj. => Embarrassed, disconcerted. [french es- *ex-1, baïr astound]",
    },
    {
      word: "Abate",
      meaning:
        "v. => (-ting) make or become less strong etc.; diminish. abatement",
    },
    {
      word: "Abattoir",
      meaning: "n. => Slaughterhouse. [french abatre fell, as *abate]",
    },
    {
      word: "Abbacy",
      meaning:
        "n. => (pl. -ies) office or jurisdiction of an abbot or abbess. [latin",
    },
    {
      word: "Abbé",
      meaning: "n. => (in france) abbot or priest. [french from latin",
    },
    { word: "Abbess", meaning: "n. => Head of a community of nuns." },
    {
      word: "Abbey",
      meaning:
        "n. => (pl. -s) 1 building(s) occupied by a community of monks or nuns. 2 the community itself. 3 building that was once an abbey.",
    },
    {
      word: "Abbot",
      meaning:
        "n. => Head of a community of monks. [old english from latin abbas]",
    },
    {
      word: "Abbreviate",
      meaning:
        "v. => (-ting) shorten, esp. Represent (a word etc.) By a part of it. abbreviation",
    },
    {
      word: "Abc",
      meaning:
        "n. => 1 the alphabet. 2 rudiments of a subject. 3 alphabetical guide.",
    },
    {
      word: "Abdicate",
      meaning:
        "v. => (-ting) 1 (usu. Absol.) Give up or renounce (the throne). 2 renounce (a duty, right, etc.). abdication",
    },
    {
      word: "Abdomen",
      meaning:
        "n. => 1 the belly, including the stomach, bowels, etc. 2 the hinder part of an insect etc. abdominal adj. [latin]",
    },
    {
      word: "Abduct",
      meaning: "v. => Carry off or kidnap illegally. abduction n. Abductor",
    },
    {
      word: "Abeam",
      meaning: "adv. => At right angles to a ship's or an aircraft's length.",
    },
    {
      word: "Aberdeen",
      meaning:
        "angus	n. => Animal of a scottish breed of hornless black cattle. [aberdeen in scotland]",
    },
    { word: "Aberdonian", meaning: "=> —int. " },
    {
      word: "Aberrant",
      meaning: "adj. => Deviating from what is normal or accepted. [latin",
    },
    {
      word: "Aberration",
      meaning:
        "n. => 1 aberrant behaviour; moral or mental lapse. 2 biol. Deviation from a normal type. 3 distortion of an image because of a defect in a lens or mirror. 4 astron. Apparent displacement of a celestial body.",
    },
    {
      word: "Abet",
      meaning:
        "v. => (-tt-) (usu. In aid and abet) encourage or assist (an offender or offence). [french",
    },
    {
      word: "Abeyance",
      meaning: "n. => (usu. Prec. By in, into) temporary disuse. [french",
    },
    {
      word: "Abhor",
      meaning: "v. => (-rr-) detest; regard with disgust. [latin",
    },
    { word: "Abhorrence", meaning: "n. => Disgust; detestation." },
    {
      word: "Abhorrent",
      meaning: "adj. => (often foll. By to) disgusting or hateful.",
    },
    {
      word: "Abide",
      meaning:
        "v. => (-ding; past abided or rarely abode) 1 (usu. In neg.) Tolerate, endure (can't abide him). 2 (foll. By by) a act in accordance with (abide by the rules). B keep (a promise). 3 archaic remain, continue. [old english a- intensive prefix, *bide]",
    },
    { word: "Abiding", meaning: "adj. => Enduring, permanent." },
    {
      word: "Ability",
      meaning:
        "n. => (pl. -ies) 1 (often foll. By to + infin.) Capacity or power. 2 cleverness, talent. [french",
    },
    { word: "Ab", meaning: "initio	adv. => From the beginning. [latin]" },
    {
      word: "Abject",
      meaning: "adj. => Miserable, wretched; degraded; despicable. abjection",
    },
    {
      word: "Abjure",
      meaning:
        "v. => (-ring) renounce on oath (an opinion, cause, etc.). abjuration",
    },
    { word: "Ablative", meaning: "=> gram. —n." },
    { word: "Ablaze", meaning: "=> predic. Adj." },
    { word: "Able", meaning: "adj. => (abler, ablest)" },
    { word: "God", meaning: "n. 1. a (in many religions) superhuman being or spirit worshipped as having power over nature, human fortunes, etc. B image, idol, etc., symbolizing a god. 2 (god) (in christian and other monotheistic religions) creator and ruler of the universe. 3 adored or greatly admired person. 4 (in pl.) Theatr. Gallery.  god forbid may it not happen! God knows 1 it is beyond all knowledge. 2 i call god to witness that. God willing if providence allows. [old english]" },
  ],
};
export default allLng;
