import { alphabet_A, alphabet_A_Hindi } from "./A.js";
import { alphabet_B, alphabet_B_Hindi } from "./B.js";
import { alphabet_C, alphabet_C_Hindi } from "./C.js";
import { alphabet_D, alphabet_D_Hindi } from "./D.js";
import { alphabet_E, alphabet_E_Hindi } from "./E.js";
import { alphabet_F, alphabet_F_Hindi } from "./F.js";
import { alphabet_G, alphabet_G_Hindi } from "./G.js";
import { alphabet_H, alphabet_H_Hindi } from "./H.js";
import { alphabet_I, alphabet_I_Hindi } from "./I.js";
import { alphabet_J, alphabet_J_Hindi } from "./J.js";
import { alphabet_K, alphabet_K_Hindi } from "./K.js";
import { alphabet_L, alphabet_L_Hindi } from "./L.js";
import { alphabet_M, alphabet_M_Hindi } from "./M.js";
import { alphabet_N, alphabet_N_Hindi } from "./N.js";
import { alphabet_O, alphabet_O_Hindi } from "./O.js";
import { alphabet_P, alphabet_P_Hindi } from "./P.js";
import { alphabet_Q, alphabet_Q_Hindi } from "./Q.js";
import { alphabet_R, alphabet_R_Hindi } from "./R.js";
import { alphabet_S, alphabet_S_Hindi } from "./S.js";
import { alphabet_T, alphabet_T_Hindi } from "./T.js";
import { alphabet_U, alphabet_U_Hindi } from "./U.js";
import { alphabet_V, alphabet_V_Hindi } from "./V.js";
import { alphabet_W, alphabet_W_Hindi } from "./W.js";
import { alphabet_X, alphabet_X_Hindi } from "./X.js";
import { alphabet_Y, alphabet_Y_Hindi } from "./Y.js";
import { alphabet_Z, alphabet_Z_Hindi } from "./Z.js";

export function wordsArrayMeaningWithHindi(wordsArray, sourceLang) {
  //   console.log("Worrrrrrrrrrrrrddddddddddd", wordsArray);
  let groupArray = groupOfWordsObject(wordsArray);
  // console.log("alphabet_W", alphabet_W, "alphabet_W_Hindi", alphabet_W_Hindi);
  // console.log("alphabet_W", groupArray);
  let meaningArray = meaningFinder(groupArray, sourceLang);
  return meaningArray;
}

function groupOfWordsObject(wordsArray) {
  let resultObj = {};
  for (let i = 0; i < wordsArray.length; i++) {
    let currentWord = wordsArray[i];
    let firstChar = currentWord.charAt(0).toLowerCase();
    let innerArr = [];
    if (resultObj[firstChar] === undefined) {
      innerArr.push(currentWord);
      resultObj[firstChar] = innerArr;
    } else {
      resultObj[firstChar].push(currentWord);
    }
  }
  // console.log("resultObj", resultObj);
  return resultObj;
}

async function meaningFinder(groupArray, sourceLang) {
  // let inputTextFirstLatterIndex = [];
  let dictonaryWordsListArray = {};
  let tableMeaningData = [];
  // console.log("dictonaryWordsListArray", groupArray);
  for (let wordKey in groupArray) {
    // console.log("wordKey", wordKey);
    dictonaryWordsListArray = await dictonaryFileFinder(wordKey);
    // console.log("alphabet_A", alphabet_A)
    let index = 0;
    let wordList = [];
    // console.log(
    //   "worddddddddddd",
    //   groupArray[wordKey],
    //   wordKey,
    //   dictonaryWordsListArray
    // );
    if (wordKey.length) {
      for (let j = 0; j < groupArray[wordKey].length; j++) {
        if (
          dictonaryWordsListArray.englishWords &&
          dictonaryWordsListArray.englishWords.length
        ) {
          for (let wordMeaning of dictonaryWordsListArray.englishWords) {
            // this.wordList.push(wordMeaning.word);
            wordList.push(wordMeaning.word);
            if (
              wordMeaning.word &&
              groupArray[wordKey][j] &&
              wordMeaning.word.toLowerCase() ===
                groupArray[wordKey][j].toLowerCase()
            ) {
              let meaning = wordMeaning.language[0].meaning;
              meaning = meaning.replace(wordMeaning.word, "");
              if (meaning.length > 0) {
                wordMeaning.language[0].meaning = meaning;
              } else {
                wordMeaning.language[0].meaning = "No meaning found";
              }
              // console.log("wordMeaning00000000000", wordMeaning.word);
              if (
                !tableMeaningData.find(
                  (latter) =>
                    latter.word &&
                    wordMeaning.word &&
                    latter.word.toLowerCase() === wordMeaning.word.toLowerCase()
                )
              ) {
                //   console.log("dictonaryWordsListArray+++++",index, dictonaryWordsListArray.hindiWords[index]);
                // console.log("sourceLang", sourceLang);
                if (
                  dictonaryWordsListArray.hindiWords &&
                  dictonaryWordsListArray.hindiWords[index] && sourceLang.length && sourceLang === "hi"
                ) {
                  // console.log("sourceLang+++", sourceLang);
                  wordMeaning.language[1] =
                    dictonaryWordsListArray.hindiWords[index];
                }
                // else {
                //   wordMeaning.language[1] = "Not found";
                // }
                //   console.log("wordMeaningQQQQQQQQQQQQQQQ", wordMeaning);
                tableMeaningData.push(wordMeaning);
              } else {
                // console.log("dictonaryWordsListArray__________", dictonaryWordsListArray.hindiWords[index]);
                tableMeaningData.push(wordMeaning);
              }
            }
            index++;
          }
        }
      }
    }
    // console.log("wordList+++++", wordList);
  }
  // console.log("tableMeaningData", tableMeaningData);
  return tableMeaningData;
}
function dictonaryFileFinder(wordKey) {
  let groupOfAllWordObject = {
    alphabet_A,
    alphabet_A_Hindi,
    alphabet_B,
    alphabet_B_Hindi,
    alphabet_C,
    alphabet_C_Hindi,
    alphabet_D,
    alphabet_D_Hindi,
    alphabet_E,
    alphabet_E_Hindi,
    alphabet_F,
    alphabet_F_Hindi,
    alphabet_G,
    alphabet_G_Hindi,
    alphabet_H,
    alphabet_H_Hindi,
    alphabet_I,
    alphabet_I_Hindi,
    alphabet_J,
    alphabet_J_Hindi,
    alphabet_K,
    alphabet_K_Hindi,
    alphabet_L,
    alphabet_L_Hindi,
    alphabet_M,
    alphabet_M_Hindi,
    alphabet_N,
    alphabet_N_Hindi,
    alphabet_O,
    alphabet_O_Hindi,
    alphabet_P,
    alphabet_P_Hindi,
    alphabet_Q,
    alphabet_Q_Hindi,
    alphabet_R,
    alphabet_R_Hindi,
    alphabet_S,
    alphabet_S_Hindi,
    alphabet_T,
    alphabet_T_Hindi,
    alphabet_U,
    alphabet_U_Hindi,
    alphabet_V,
    alphabet_V_Hindi,
    alphabet_W,
    alphabet_W_Hindi,
    alphabet_X,
    alphabet_X_Hindi,
    alphabet_Y,
    alphabet_Y_Hindi,
    alphabet_Z,
    alphabet_Z_Hindi,
  };
  if (wordKey) {
    let keyName = "alphabet_" + wordKey.toUpperCase();
    let object = {
      englishWords: groupOfAllWordObject[keyName],
      hindiWords: groupOfAllWordObject[keyName + "_Hindi"],
    };
    // console.log("object", object);
    return object;
  }
}
