<template>
  <div class="hemant">
    <div
      @click="btnClickedHandler(displayValue)"
      v-html="displayValue"
      class="col-12 p-0"
    ></div>
    <div>
      <TextToSpeech
        :languageObject="{ code: 'hi', language: 'Hindi', meaning: 'meaning' }"
        :speechText="displayValue[0]"
      />
    </div>
  </div>
  <!-- <div @click="btnClickedHandler()" v-html="displayValue" class="col-12 p-0"></div> -->
  <!-- <template v-html="displayValue"></template> -->
</template>

<script>
// import Vue from "Vue";
import TextToSpeech from "./text-to-speech.vue";
export default {
  name: "BtnCellRenderer",
  components: {
    TextToSpeech,
  },
  mounted() {},
  methods: {
    // Write your function here
    btnClickedHandler(displayValue) {
      console.log("displayValue", displayValue);
    },
  },
  setup(props) {
    const displayValue = new Array(
      props.params && props.params.value
        ? props.params.value
        : "Somthing is wrong!"
    );
    return {
      displayValue,
    };
  },
};
</script>
